export const cat = [
    {
        "title": "Алюминий, сплав алюминия",
        "submenu": [
            {
                "title": "Алюминиевый антифрикционный сплав",
                "submenu": [
                    {
                        "title": "АМСТ"
                    },
                    {
                        "title": "АН-2.5"
                    },
                    {
                        "title": "АО20-1"
                    },
                    {
                        "title": "АО3-1"
                    },
                    {
                        "title": "АО3-7"
                    },
                    {
                        "title": "АО6-1"
                    },
                    {
                        "title": "АО9-1"
                    },
                    {
                        "title": "АО9-2"
                    },
                    {
                        "title": "АО9-2Б"
                    },
                    {
                        "title": "АСМ"
                    }
                ]
            },
            {
                "title": "Алюминиевый деформируемый сплав",
                "submenu": [
                    {
                        "title": "1201"
                    },
                    {
                        "title": "1420"
                    },
                    {
                        "title": "АВ"
                    },
                    {
                        "title": "АД31"
                    },
                    {
                        "title": "АД33"
                    },
                    {
                        "title": "АД35"
                    },
                    {
                        "title": "АК4"
                    },
                    {
                        "title": "АК4-1"
                    },
                    {
                        "title": "АК6"
                    },
                    {
                        "title": "АК8"
                    },
                    {
                        "title": "АМг1"
                    },
                    {
                        "title": "АМг2"
                    },
                    {
                        "title": "АМг3"
                    },
                    {
                        "title": "АМг3С"
                    },
                    {
                        "title": "АМг4"
                    },
                    {
                        "title": "АМг4.5"
                    },
                    {
                        "title": "АМг5"
                    },
                    {
                        "title": "АМг5П"
                    },
                    {
                        "title": "АМг6"
                    },
                    {
                        "title": "АМц"
                    },
                    {
                        "title": "АМцС"
                    },
                    {
                        "title": "АЦпл"
                    },
                    {
                        "title": "В65"
                    },
                    {
                        "title": "В93"
                    },
                    {
                        "title": "В94"
                    },
                    {
                        "title": "В95"
                    },
                    {
                        "title": "В95П"
                    },
                    {
                        "title": "В96"
                    },
                    {
                        "title": "В96Ц1"
                    },
                    {
                        "title": "В96ц"
                    },
                    {
                        "title": "ВД17"
                    },
                    {
                        "title": "Д1"
                    },
                    {
                        "title": "Д12"
                    },
                    {
                        "title": "Д16"
                    },
                    {
                        "title": "Д16П"
                    },
                    {
                        "title": "Д18"
                    },
                    {
                        "title": "Д19"
                    },
                    {
                        "title": "Д1П"
                    },
                    {
                        "title": "Д20"
                    },
                    {
                        "title": "Д21"
                    },
                    {
                        "title": "ММ"
                    }
                ]
            },
            {
                "title": "Алюминиевый литейный сплав",
                "submenu": [
                    {
                        "title": "АК21М2.5Н2.5"
                    },
                    {
                        "title": "АК4М4"
                    },
                    {
                        "title": "АК5М2"
                    },
                    {
                        "title": "АК5М7"
                    },
                    {
                        "title": "АК7"
                    },
                    {
                        "title": "АК7М2"
                    },
                    {
                        "title": "АК9"
                    },
                    {
                        "title": "АЛ1"
                    },
                    {
                        "title": "АЛ11"
                    },
                    {
                        "title": "АЛ13"
                    },
                    {
                        "title": "АЛ19"
                    },
                    {
                        "title": "АЛ2"
                    },
                    {
                        "title": "АЛ21"
                    },
                    {
                        "title": "АЛ22"
                    },
                    {
                        "title": "АЛ23"
                    },
                    {
                        "title": "АЛ23-1"
                    },
                    {
                        "title": "АЛ24"
                    },
                    {
                        "title": "АЛ25"
                    },
                    {
                        "title": "АЛ26"
                    },
                    {
                        "title": "АЛ27"
                    },
                    {
                        "title": "АЛ27-1"
                    },
                    {
                        "title": "АЛ28"
                    },
                    {
                        "title": "АЛ29"
                    },
                    {
                        "title": "АЛ3"
                    },
                    {
                        "title": "АЛ30"
                    },
                    {
                        "title": "АЛ32"
                    },
                    {
                        "title": "АЛ33"
                    },
                    {
                        "title": "АЛ34"
                    },
                    {
                        "title": "АЛ4"
                    },
                    {
                        "title": "АЛ4-1"
                    },
                    {
                        "title": "АЛ4М"
                    },
                    {
                        "title": "АЛ5"
                    },
                    {
                        "title": "АЛ5-1"
                    },
                    {
                        "title": "АЛ6"
                    },
                    {
                        "title": "АЛ7"
                    },
                    {
                        "title": "АЛ7-4"
                    },
                    {
                        "title": "АЛ8"
                    },
                    {
                        "title": "АЛ9"
                    },
                    {
                        "title": "АЛ9-1"
                    },
                    {
                        "title": "В124"
                    },
                    {
                        "title": "В2616"
                    },
                    {
                        "title": "ВАЛ10"
                    },
                    {
                        "title": "ВАЛ10М"
                    },
                    {
                        "title": "ВАЛ11"
                    },
                    {
                        "title": "ВАЛ12"
                    },
                    {
                        "title": "ВАЛ8"
                    }
                ]
            },
            {
                "title": "Алюминий для раскисления",
                "submenu": [
                    {
                        "title": "АВ86"
                    },
                    {
                        "title": "АВ86Ф"
                    },
                    {
                        "title": "АВ88"
                    },
                    {
                        "title": "АВ88Ф"
                    },
                    {
                        "title": "АВ91"
                    },
                    {
                        "title": "АВ91Ф"
                    },
                    {
                        "title": "АВ92"
                    },
                    {
                        "title": "АВ92Ф"
                    },
                    {
                        "title": "АВ97"
                    },
                    {
                        "title": "АВ97Ф"
                    }
                ]
            },
            {
                "title": "Алюминий первичный",
                "submenu": [
                    {
                        "title": "А0"
                    },
                    {
                        "title": "А5"
                    },
                    {
                        "title": "А5Е"
                    },
                    {
                        "title": "А6"
                    },
                    {
                        "title": "А7"
                    },
                    {
                        "title": "А7Е"
                    },
                    {
                        "title": "А8"
                    },
                    {
                        "title": "А85"
                    },
                    {
                        "title": "А95"
                    },
                    {
                        "title": "А97"
                    },
                    {
                        "title": "А99"
                    },
                    {
                        "title": "А995"
                    },
                    {
                        "title": "А999"
                    }
                ]
            },
            {
                "title": "Алюминий технический",
                "submenu": [
                    {
                        "title": "АД"
                    },
                    {
                        "title": "АД0"
                    },
                    {
                        "title": "АД00"
                    },
                    {
                        "title": "АД000"
                    },
                    {
                        "title": "АД00Е"
                    },
                    {
                        "title": "АД0Е"
                    },
                    {
                        "title": "АД1"
                    },
                    {
                        "title": "АДС"
                    },
                    {
                        "title": "АДоч"
                    },
                    {
                        "title": "АДч"
                    }
                ]
            }
        ]
    },
    {
        "title": "Бронза",
        "submenu": [
            {
                "title": "Бронза безоловянная литейная",
                "submenu": [
                    {
                        "title": "БрА10Ж3Мц2"
                    },
                    {
                        "title": "БрА10Ж4Н4Л"
                    },
                    {
                        "title": "БрА10Мц2Л"
                    },
                    {
                        "title": "БрА11Ж6Н6"
                    },
                    {
                        "title": "БрА7Ж1.5С1.5"
                    },
                    {
                        "title": "БрА7Мц15Ж3Н2Ц2"
                    },
                    {
                        "title": "БрА9Ж3Л"
                    },
                    {
                        "title": "БрА9Ж4"
                    },
                    {
                        "title": "БрА9Ж4Н4Мц1"
                    },
                    {
                        "title": "БрА9Мц2Л"
                    },
                    {
                        "title": "БрС30"
                    },
                    {
                        "title": "БрС60Н2.5"
                    },
                    {
                        "title": "БрСу3Н3Ц3С20Ф"
                    },
                    {
                        "title": "БрСу6Н2"
                    },
                    {
                        "title": "БрСу6С12Ф0.3"
                    },
                    {
                        "title": "БрСу6Ф1"
                    }
                ]
            },
            {
                "title": "Бронза безоловянная, обрабатываемая давлением",
                "submenu": [
                    {
                        "title": "БрА5"
                    },
                    {
                        "title": "БрА7"
                    },
                    {
                        "title": "БрАЖ9-4"
                    },
                    {
                        "title": "БрАЖМц10-3-1.5"
                    },
                    {
                        "title": "БрАЖН10-4-4"
                    },
                    {
                        "title": "БрАЖНМц9-4-4-1"
                    },
                    {
                        "title": "БрАМц10-2"
                    },
                    {
                        "title": "БрАМц9-2"
                    },
                    {
                        "title": "БрБ2"
                    },
                    {
                        "title": "БрБ2.5"
                    },
                    {
                        "title": "БрБНТ1.7"
                    },
                    {
                        "title": "БрБНТ1.9"
                    },
                    {
                        "title": "БрБНТ1.9Мг"
                    },
                    {
                        "title": "БрКМц3-1"
                    },
                    {
                        "title": "БрКН1-3"
                    },
                    {
                        "title": "БрКХКо0.4-0.6-1.6"
                    },
                    {
                        "title": "БрМц5"
                    },
                    {
                        "title": "БрСр0.1"
                    },
                    {
                        "title": "БрХ1"
                    },
                    {
                        "title": "БрХЦр0.3-0.09"
                    },
                    {
                        "title": "БрХЦр0.6-0.05"
                    }
                ]
            },
            {
                "title": "Бронза оловянная литейная",
                "submenu": [
                    {
                        "title": "БрО10"
                    },
                    {
                        "title": "БрО10С10"
                    },
                    {
                        "title": "БрО10С12Н3"
                    },
                    {
                        "title": "БрО10Ф1"
                    },
                    {
                        "title": "БрО10Ц2"
                    },
                    {
                        "title": "БрО19"
                    },
                    {
                        "title": "БрО3.5Ц7С5"
                    },
                    {
                        "title": "БрО3Ц12С5"
                    },
                    {
                        "title": "БрО3Ц7С5Н"
                    },
                    {
                        "title": "БрО3Ц7С5Н1"
                    },
                    {
                        "title": "БрО4Ц4С17"
                    },
                    {
                        "title": "БрО4Ц7С5"
                    },
                    {
                        "title": "БрО5С25"
                    },
                    {
                        "title": "БрО5Ц5С5"
                    },
                    {
                        "title": "БрО6С6Ц3"
                    },
                    {
                        "title": "БрО6Ц6С3"
                    },
                    {
                        "title": "БрО8Н4Ц2"
                    },
                    {
                        "title": "БрО8С12"
                    },
                    {
                        "title": "БрО8Ц4"
                    }
                ]
            },
            {
                "title": "Бронза оловянная литейная в чушках",
                "submenu": [
                    {
                        "title": "БрО3Ц13С4"
                    },
                    {
                        "title": "БрО3Ц8С4Н1"
                    },
                    {
                        "title": "БрО5Ц6С5"
                    }
                ]
            },
            {
                "title": "Бронза оловянная, обрабатываемая давлением",
                "submenu": [
                    {
                        "title": "БрОФ2-0.25"
                    },
                    {
                        "title": "БрОФ4-0.25"
                    },
                    {
                        "title": "БрОФ6.5-0.15"
                    },
                    {
                        "title": "БрОФ6.5-0.4"
                    },
                    {
                        "title": "БрОФ7-0.2"
                    },
                    {
                        "title": "БрОФ8-0.3"
                    },
                    {
                        "title": "БрОЦ4-3"
                    },
                    {
                        "title": "БрОЦС4-4-2.5"
                    },
                    {
                        "title": "БрОЦС4-4-4"
                    }
                ]
            }
        ]
    },
    {
        "title": "Магний, сплав магния",
        "submenu": [
            {
                "title": "Магниево -  литиевый сверхлегкий сплав",
                "submenu": [
                    {
                        "title": "МА18"
                    },
                    {
                        "title": "МА21"
                    }
                ]
            },
            {
                "title": "Магниевый  литейный сплав",
                "submenu": [
                    {
                        "title": "МЛ10"
                    },
                    {
                        "title": "МЛ11"
                    },
                    {
                        "title": "МЛ12"
                    },
                    {
                        "title": "МЛ15"
                    },
                    {
                        "title": "МЛ19"
                    },
                    {
                        "title": "МЛ2"
                    },
                    {
                        "title": "МЛ3"
                    },
                    {
                        "title": "МЛ4"
                    },
                    {
                        "title": "МЛ4пч"
                    },
                    {
                        "title": "МЛ5"
                    },
                    {
                        "title": "МЛ5он"
                    },
                    {
                        "title": "МЛ5пч"
                    },
                    {
                        "title": "МЛ6"
                    },
                    {
                        "title": "МЛ8"
                    },
                    {
                        "title": "МЛ9"
                    }
                ]
            },
            {
                "title": "Магниевый  сплав с особыми свойствами",
                "submenu": [
                    {
                        "title": "МА17"
                    },
                    {
                        "title": "МА2-2"
                    },
                    {
                        "title": "МЛ16"
                    },
                    {
                        "title": "МЛ16вч"
                    },
                    {
                        "title": "МЛ16пч"
                    },
                    {
                        "title": "МЛ4вч"
                    },
                    {
                        "title": "МЦИ"
                    }
                ]
            },
            {
                "title": "Магниевый деформируемый сплав",
                "submenu": [
                    {
                        "title": "МА1"
                    },
                    {
                        "title": "МА11"
                    },
                    {
                        "title": "МА12"
                    },
                    {
                        "title": "МА14"
                    },
                    {
                        "title": "МА15"
                    },
                    {
                        "title": "МА19"
                    },
                    {
                        "title": "МА2"
                    },
                    {
                        "title": "МА5"
                    },
                    {
                        "title": "МА8"
                    },
                    {
                        "title": "Ма2-1"
                    }
                ]
            },
            {
                "title": "Магний первичный",
                "submenu": [
                    {
                        "title": "Мг90"
                    },
                    {
                        "title": "Мг95"
                    },
                    {
                        "title": "Мг96"
                    }
                ]
            }
        ]
    },
    {
        "title": "Медно-цинковый сплав (латунь)",
        "submenu": [
            {
                "title": "Латунь литейная",
                "submenu": [
                    {
                        "title": "ЛЦ14К3С3"
                    },
                    {
                        "title": "ЛЦ16К4"
                    },
                    {
                        "title": "ЛЦ23А6Ж3Мц2"
                    },
                    {
                        "title": "ЛЦ25С2"
                    },
                    {
                        "title": "ЛЦ30А3"
                    },
                    {
                        "title": "ЛЦ37Мц2С2К"
                    },
                    {
                        "title": "ЛЦ38Мц2С2"
                    },
                    {
                        "title": "ЛЦ40АЖ"
                    },
                    {
                        "title": "ЛЦ40Мц1.5"
                    },
                    {
                        "title": "ЛЦ40Мц3А"
                    },
                    {
                        "title": "ЛЦ40Мц3Ж"
                    },
                    {
                        "title": "ЛЦ40С"
                    },
                    {
                        "title": "ЛЦ40СД"
                    }
                ]
            },
            {
                "title": "Латунь литейная в чушках",
                "submenu": [
                    {
                        "title": "ЛА"
                    },
                    {
                        "title": "ЛАЖМц"
                    },
                    {
                        "title": "ЛК"
                    },
                    {
                        "title": "ЛК1"
                    },
                    {
                        "title": "ЛК2"
                    },
                    {
                        "title": "ЛКС"
                    },
                    {
                        "title": "ЛМцЖ"
                    },
                    {
                        "title": "ЛМцС"
                    },
                    {
                        "title": "ЛМцСК"
                    },
                    {
                        "title": "ЛОС"
                    },
                    {
                        "title": "ЛС"
                    },
                    {
                        "title": "ЛСд"
                    }
                ]
            },
            {
                "title": "Латунь, обрабатываемая давлением",
                "submenu": [
                    {
                        "title": "Л59"
                    },
                    {
                        "title": "Л60"
                    },
                    {
                        "title": "Л63"
                    },
                    {
                        "title": "Л66"
                    },
                    {
                        "title": "Л68"
                    },
                    {
                        "title": "Л70"
                    },
                    {
                        "title": "Л75"
                    },
                    {
                        "title": "Л80"
                    },
                    {
                        "title": "Л85"
                    },
                    {
                        "title": "Л90"
                    },
                    {
                        "title": "Л96"
                    },
                    {
                        "title": "ЛА77-2"
                    },
                    {
                        "title": "ЛА85-0.5"
                    },
                    {
                        "title": "ЛАЖ60-1-1"
                    },
                    {
                        "title": "ЛАМш77-2-0.05"
                    },
                    {
                        "title": "ЛАН59-3-2"
                    },
                    {
                        "title": "ЛАНКМц75-2-2.5-0.5-0.5"
                    },
                    {
                        "title": "ЛЖМц59-1-1"
                    },
                    {
                        "title": "ЛЖС58-1-1"
                    },
                    {
                        "title": "ЛК80-3"
                    },
                    {
                        "title": "ЛКС65-1.5-3"
                    },
                    {
                        "title": "ЛМц58-2"
                    },
                    {
                        "title": "ЛМцА57-3-1"
                    },
                    {
                        "title": "ЛМш68-0.05"
                    },
                    {
                        "title": "ЛН65-5"
                    },
                    {
                        "title": "ЛО60-1"
                    },
                    {
                        "title": "ЛО62-1"
                    },
                    {
                        "title": "ЛО70-1"
                    },
                    {
                        "title": "ЛО90-1"
                    },
                    {
                        "title": "ЛОМш70-1-0.05"
                    },
                    {
                        "title": "ЛС59-1"
                    },
                    {
                        "title": "ЛС59-1В"
                    },
                    {
                        "title": "ЛС60-1"
                    },
                    {
                        "title": "ЛС63-3"
                    },
                    {
                        "title": "ЛС64-2"
                    },
                    {
                        "title": "ЛС74-3"
                    }
                ]
            }
        ]
    },
    {
        "title": "Медь, сплав меди",
        "submenu": [
            {
                "title": "Медно-никелевый сплав",
                "submenu": [
                    {
                        "title": "МН0.6"
                    },
                    {
                        "title": "МН10"
                    },
                    {
                        "title": "МН16"
                    },
                    {
                        "title": "МН19"
                    },
                    {
                        "title": "МН25"
                    },
                    {
                        "title": "МН95-5"
                    },
                    {
                        "title": "МНА13-3"
                    },
                    {
                        "title": "МНА6-1.5"
                    },
                    {
                        "title": "МНЖ5-1"
                    },
                    {
                        "title": "МНЖКТ5-1-0.2-0.2"
                    },
                    {
                        "title": "МНЖМц10-1-1"
                    },
                    {
                        "title": "МНЖМц30-1-1"
                    },
                    {
                        "title": "МНМц3-12"
                    },
                    {
                        "title": "МНМц40-1.5"
                    },
                    {
                        "title": "МНМц43-0.5"
                    },
                    {
                        "title": "МНМцАЖ3-12-0.3-0.3"
                    },
                    {
                        "title": "МНЦ12-24"
                    },
                    {
                        "title": "МНЦ15-20"
                    },
                    {
                        "title": "МНЦ18-20"
                    },
                    {
                        "title": "МНЦ18-27"
                    },
                    {
                        "title": "МНЦС16-29-1.8"
                    },
                    {
                        "title": "НМЖМц28-2.5-1.5"
                    }
                ]
            },
            {
                "title": "Медь",
                "submenu": [
                    {
                        "title": "М0"
                    },
                    {
                        "title": "М00"
                    },
                    {
                        "title": "М00б"
                    },
                    {
                        "title": "М0б"
                    },
                    {
                        "title": "М0к"
                    },
                    {
                        "title": "М1"
                    },
                    {
                        "title": "М1к"
                    },
                    {
                        "title": "М1р"
                    },
                    {
                        "title": "М1ф"
                    },
                    {
                        "title": "М2"
                    },
                    {
                        "title": "М2р"
                    },
                    {
                        "title": "М3"
                    },
                    {
                        "title": "М3р"
                    }
                ]
            },
            {
                "title": "Сплав меди жаропрочный",
                "submenu": [
                    {
                        "title": "Cu"
                    },
                    {
                        "title": "БрКБ2.5-0.5"
                    },
                    {
                        "title": "БрКд1"
                    },
                    {
                        "title": "БрМВТ"
                    },
                    {
                        "title": "БрМг0.3"
                    },
                    {
                        "title": "БрМг0.5"
                    },
                    {
                        "title": "БрМг0.8"
                    },
                    {
                        "title": "БрНБТ"
                    },
                    {
                        "title": "БрНХК"
                    },
                    {
                        "title": "БрНХК2.5-0.7-0.6"
                    },
                    {
                        "title": "БрХ"
                    },
                    {
                        "title": "БрХВЦр"
                    },
                    {
                        "title": "БрХНб"
                    },
                    {
                        "title": "БрХЦр"
                    },
                    {
                        "title": "БрЦр0.2"
                    },
                    {
                        "title": "БрЦр0.3"
                    },
                    {
                        "title": "БрЦр0.4"
                    },
                    {
                        "title": "БрЦр0.7"
                    },
                    {
                        "title": "МК"
                    },
                    {
                        "title": "МКБ"
                    }
                ]
            },
            {
                "title": "Сплав медно-фосфористый",
                "submenu": [
                    {
                        "title": "МФ10"
                    },
                    {
                        "title": "МФ9"
                    }
                ]
            }
        ]
    },
    {
        "title": "Никель, сплав никеля",
        "submenu": [
            {
                "title": "Медно-никелевый сплав",
                "submenu": [
                    {
                        "title": "МН0.6"
                    },
                    {
                        "title": "МН10"
                    },
                    {
                        "title": "МН16"
                    },
                    {
                        "title": "МН19"
                    },
                    {
                        "title": "МН25"
                    },
                    {
                        "title": "МН95-5"
                    },
                    {
                        "title": "МНА13-3"
                    },
                    {
                        "title": "МНА6-1.5"
                    },
                    {
                        "title": "МНЖ5-1"
                    },
                    {
                        "title": "МНЖКТ5-1-0.2-0.2"
                    },
                    {
                        "title": "МНЖМц10-1-1"
                    },
                    {
                        "title": "МНЖМц30-1-1"
                    },
                    {
                        "title": "МНМц3-12"
                    },
                    {
                        "title": "МНМц40-1.5"
                    },
                    {
                        "title": "МНМц43-0.5"
                    },
                    {
                        "title": "МНМцАЖ3-12-0.3-0.3"
                    },
                    {
                        "title": "МНЦ12-24"
                    },
                    {
                        "title": "МНЦ15-20"
                    },
                    {
                        "title": "МНЦ18-20"
                    },
                    {
                        "title": "МНЦ18-27"
                    },
                    {
                        "title": "МНЦС16-29-1.8"
                    },
                    {
                        "title": "НМЖМц28-2.5-1.5"
                    }
                ]
            },
            {
                "title": "Медь",
                "submenu": [
                    {
                        "title": "М0"
                    },
                    {
                        "title": "М00"
                    },
                    {
                        "title": "М00б"
                    },
                    {
                        "title": "М0б"
                    },
                    {
                        "title": "М0к"
                    },
                    {
                        "title": "М1"
                    },
                    {
                        "title": "М1к"
                    },
                    {
                        "title": "М1р"
                    },
                    {
                        "title": "М1ф"
                    },
                    {
                        "title": "М2"
                    },
                    {
                        "title": "М2р"
                    },
                    {
                        "title": "М3"
                    },
                    {
                        "title": "М3р"
                    }
                ]
            },
            {
                "title": "Сплав меди жаропрочный",
                "submenu": [
                    {
                        "title": "Cu"
                    },
                    {
                        "title": "БрКБ2.5-0.5"
                    },
                    {
                        "title": "БрКд1"
                    },
                    {
                        "title": "БрМВТ"
                    },
                    {
                        "title": "БрМг0.3"
                    },
                    {
                        "title": "БрМг0.5"
                    },
                    {
                        "title": "БрМг0.8"
                    },
                    {
                        "title": "БрНБТ"
                    },
                    {
                        "title": "БрНХК"
                    },
                    {
                        "title": "БрНХК2.5-0.7-0.6"
                    },
                    {
                        "title": "БрХ"
                    },
                    {
                        "title": "БрХВЦр"
                    },
                    {
                        "title": "БрХНб"
                    },
                    {
                        "title": "БрХЦр"
                    },
                    {
                        "title": "БрЦр0.2"
                    },
                    {
                        "title": "БрЦр0.3"
                    },
                    {
                        "title": "БрЦр0.4"
                    },
                    {
                        "title": "БрЦр0.7"
                    },
                    {
                        "title": "МК"
                    },
                    {
                        "title": "МКБ"
                    }
                ]
            },
            {
                "title": "Сплав медно-фосфористый",
                "submenu": [
                    {
                        "title": "МФ10"
                    },
                    {
                        "title": "МФ9"
                    }
                ]
            }
        ]
    },
    {
        "title": "Олово, сплав олова",
        "submenu": [
            {
                "title": "Олово",
                "submenu": [
                    {
                        "title": "О1"
                    },
                    {
                        "title": "О1пч"
                    },
                    {
                        "title": "О2"
                    },
                    {
                        "title": "О3"
                    },
                    {
                        "title": "О4"
                    }
                ]
            },
            {
                "title": "Оловянные баббиты",
                "submenu": [
                    {
                        "title": "Б83"
                    },
                    {
                        "title": "Б83С"
                    },
                    {
                        "title": "Б88"
                    }
                ]
            }
        ]
    },
    {
        "title": "Свинец, сплав свинца",
        "submenu": [
            {
                "title": "Кальциевые баббиты",
                "submenu": [
                    {
                        "title": "БК2"
                    },
                    {
                        "title": "БК2Ш"
                    },
                    {
                        "title": "БКА"
                    }
                ]
            },
            {
                "title": "Свинец",
                "submenu": [
                    {
                        "title": "С0"
                    },
                    {
                        "title": "С1"
                    },
                    {
                        "title": "С1С"
                    },
                    {
                        "title": "С2"
                    },
                    {
                        "title": "С2С"
                    },
                    {
                        "title": "С3"
                    },
                    {
                        "title": "С3С"
                    }
                ]
            },
            {
                "title": "Свинцовые  баббиты",
                "submenu": [
                    {
                        "title": "Б16"
                    },
                    {
                        "title": "БН"
                    },
                    {
                        "title": "БС6"
                    }
                ]
            }
        ]
    },
    {
        "title": "Сплав прецизионный",
        "submenu": [
            {
                "title": "Сплав прецизионный магнитно-мягкий",
                "submenu": [
                    {
                        "title": "16Х"
                    },
                    {
                        "title": "27КХ"
                    },
                    {
                        "title": "34НКМ"
                    },
                    {
                        "title": "35НКХСП"
                    },
                    {
                        "title": "36КНМ"
                    },
                    {
                        "title": "40Н"
                    },
                    {
                        "title": "40НКМ"
                    },
                    {
                        "title": "45Н"
                    },
                    {
                        "title": "47НК"
                    },
                    {
                        "title": "47НКХ"
                    },
                    {
                        "title": "49К2Ф"
                    },
                    {
                        "title": "49К2ФА"
                    },
                    {
                        "title": "49КФ"
                    },
                    {
                        "title": "50Н"
                    },
                    {
                        "title": "50НХС"
                    },
                    {
                        "title": "50ХНС"
                    },
                    {
                        "title": "64Н"
                    },
                    {
                        "title": "68НМ"
                    },
                    {
                        "title": "76НХД"
                    },
                    {
                        "title": "77НМД"
                    },
                    {
                        "title": "79Н3М"
                    },
                    {
                        "title": "79НМ"
                    },
                    {
                        "title": "80Н2М"
                    },
                    {
                        "title": "80НХС"
                    },
                    {
                        "title": "81НМА"
                    },
                    {
                        "title": "83НФ"
                    },
                    {
                        "title": "83НФ-Ш"
                    }
                ]
            },
            {
                "title": "Сплав прецизионный магнитно-твердый",
                "submenu": [
                    {
                        "title": "25КФ14Н"
                    },
                    {
                        "title": "35КФ10Н"
                    },
                    {
                        "title": "35КХ4Ф"
                    },
                    {
                        "title": "35КХ6Ф"
                    },
                    {
                        "title": "35КХ8Ф"
                    },
                    {
                        "title": "52К10Ф"
                    },
                    {
                        "title": "52К11Ф"
                    },
                    {
                        "title": "52К12Ф"
                    },
                    {
                        "title": "52К13Ф"
                    },
                    {
                        "title": "ЕВ6"
                    },
                    {
                        "title": "ЕХ3"
                    },
                    {
                        "title": "ЕХ5К5"
                    },
                    {
                        "title": "ЕХ9К15М2"
                    }
                ]
            },
            {
                "title": "Сплав прецизионный с высоким электрическим сопротивлением",
                "submenu": [
                    {
                        "title": "Н50К10"
                    },
                    {
                        "title": "Н80ХЮД-ВИ"
                    },
                    {
                        "title": "Х13Ю4"
                    },
                    {
                        "title": "Х15Н60"
                    },
                    {
                        "title": "Х15Н60-ВИ"
                    },
                    {
                        "title": "Х15Н60-Н"
                    },
                    {
                        "title": "Х15Ю5"
                    },
                    {
                        "title": "Х20Н73ЮМ-ВИ"
                    },
                    {
                        "title": "Х20Н80"
                    },
                    {
                        "title": "Х20Н80-ВИ"
                    },
                    {
                        "title": "Х20Н80-Н"
                    },
                    {
                        "title": "Х23Ю5"
                    },
                    {
                        "title": "Х23Ю5Т"
                    },
                    {
                        "title": "Х25Н20"
                    },
                    {
                        "title": "Х27Ю5Т"
                    },
                    {
                        "title": "ХН20ЮС"
                    },
                    {
                        "title": "ХН70Ю-Н"
                    }
                ]
            },
            {
                "title": "Сплав прецизионный с заданным ТКЛР",
                "submenu": [
                    {
                        "title": "29НК"
                    },
                    {
                        "title": "30НКД"
                    },
                    {
                        "title": "32НК-ВИ"
                    },
                    {
                        "title": "32НКД"
                    },
                    {
                        "title": "33НК"
                    },
                    {
                        "title": "34НК"
                    },
                    {
                        "title": "35НКТ"
                    },
                    {
                        "title": "36Н"
                    },
                    {
                        "title": "36НХ"
                    },
                    {
                        "title": "38НКД"
                    },
                    {
                        "title": "39Н"
                    },
                    {
                        "title": "42Н"
                    },
                    {
                        "title": "42НА-ВИ"
                    },
                    {
                        "title": "47Н3Х"
                    },
                    {
                        "title": "47НД"
                    },
                    {
                        "title": "47НХ"
                    },
                    {
                        "title": "47НХР"
                    },
                    {
                        "title": "48НХ"
                    },
                    {
                        "title": "52Н"
                    },
                    {
                        "title": "58Н-ВИ"
                    }
                ]
            },
            {
                "title": "Сплав прецизионный с заданными свойствами упругости",
                "submenu": [
                    {
                        "title": "17ХНГТ"
                    },
                    {
                        "title": "36НХТЮ"
                    },
                    {
                        "title": "36НХТЮ5М"
                    },
                    {
                        "title": "36НХТЮ8М"
                    },
                    {
                        "title": "40КНХМВТЮ"
                    },
                    {
                        "title": "40КХНМ"
                    },
                    {
                        "title": "42НХТЮ"
                    },
                    {
                        "title": "42НХТЮА"
                    },
                    {
                        "title": "43НКТЮ"
                    },
                    {
                        "title": "44НХТЮ"
                    },
                    {
                        "title": "68НХВКТЮ"
                    },
                    {
                        "title": "97НЛ"
                    }
                ]
            },
            {
                "title": "Сплав прецизионный, составляющие термобиметаллов",
                "submenu": [
                    {
                        "title": "19НХ"
                    },
                    {
                        "title": "20НГ"
                    },
                    {
                        "title": "24НХ"
                    },
                    {
                        "title": "45НХ"
                    },
                    {
                        "title": "46Н"
                    },
                    {
                        "title": "75ГНД"
                    }
                ]
            }
        ]
    },
    {
        "title": "Сталь для отливок",
        "submenu": [
            {
                "title": "Сталь для отливок обыкновенная",
                "submenu": [
                    {
                        "title": "03Н12Х5М3ТЛ"
                    },
                    {
                        "title": "03Н12Х5М3ТЮЛ"
                    },
                    {
                        "title": "08ГДНФЛ"
                    },
                    {
                        "title": "08Х17Н34В5Т3Ю2Л"
                    },
                    {
                        "title": "110Г13Л"
                    },
                    {
                        "title": "120Г13Х2БЛ"
                    },
                    {
                        "title": "12ДН2ФЛ"
                    },
                    {
                        "title": "12ДХН1МФЛ"
                    },
                    {
                        "title": "12Х7Г3СЛ"
                    },
                    {
                        "title": "13НДФТЛ"
                    },
                    {
                        "title": "13ХНДФТЛ"
                    },
                    {
                        "title": "14Х2ГМРЛ"
                    },
                    {
                        "title": "15ГЛ"
                    },
                    {
                        "title": "15ГНЛ"
                    },
                    {
                        "title": "15Л"
                    },
                    {
                        "title": "20Г1ФЛ"
                    },
                    {
                        "title": "20ГЛ"
                    },
                    {
                        "title": "20ГНМФЛ"
                    },
                    {
                        "title": "20ГСЛ"
                    },
                    {
                        "title": "20ДХЛ"
                    },
                    {
                        "title": "20Л"
                    },
                    {
                        "title": "20ФЛ"
                    },
                    {
                        "title": "20ХГСНДМЛ"
                    },
                    {
                        "title": "20ХГСФЛ"
                    },
                    {
                        "title": "20ХМЛ"
                    },
                    {
                        "title": "20ХМФЛ"
                    },
                    {
                        "title": "23ХГС2МФЛ"
                    },
                    {
                        "title": "25ГСЛ"
                    },
                    {
                        "title": "25Л"
                    },
                    {
                        "title": "25Х2Г2ФЛ"
                    },
                    {
                        "title": "25Х2ГНМФЛ"
                    },
                    {
                        "title": "25Х2НМЛ"
                    },
                    {
                        "title": "27Х5ГСМЛ"
                    },
                    {
                        "title": "30ГЛ"
                    },
                    {
                        "title": "30ГСЛ"
                    },
                    {
                        "title": "30Л"
                    },
                    {
                        "title": "30Х3С3ГМЛ"
                    },
                    {
                        "title": "30ХГСФЛ"
                    },
                    {
                        "title": "30ХГФРЛ"
                    },
                    {
                        "title": "30ХНМЛ"
                    },
                    {
                        "title": "32Х06Л"
                    },
                    {
                        "title": "35ГЛ"
                    },
                    {
                        "title": "35Л"
                    },
                    {
                        "title": "35НГМЛ"
                    },
                    {
                        "title": "35ХГСЛ"
                    },
                    {
                        "title": "35ХМЛ"
                    },
                    {
                        "title": "35ХМФЛ"
                    },
                    {
                        "title": "35ХН2МЛ"
                    },
                    {
                        "title": "35ХНЛ"
                    },
                    {
                        "title": "40Л"
                    },
                    {
                        "title": "40ХЛ"
                    },
                    {
                        "title": "45ГЛ"
                    },
                    {
                        "title": "45Л"
                    },
                    {
                        "title": "45ФЛ"
                    },
                    {
                        "title": "50Л"
                    },
                    {
                        "title": "55Л"
                    },
                    {
                        "title": "80ГСЛ"
                    }
                ]
            },
            {
                "title": "Сталь для отливок с особыми свойствами",
                "submenu": [
                    {
                        "title": "07Х17Н16ТЛ"
                    },
                    {
                        "title": "07Х18Н9Л"
                    },
                    {
                        "title": "08Х14Н7МЛ"
                    },
                    {
                        "title": "08Х14НДЛ"
                    },
                    {
                        "title": "08Х15Н4ДМЛ"
                    },
                    {
                        "title": "08Х17Н34В5Т3Ю2РЛ"
                    },
                    {
                        "title": "09Х16Н4БЛ"
                    },
                    {
                        "title": "09Х17Н3СЛ"
                    },
                    {
                        "title": "10Х12НДЛ"
                    },
                    {
                        "title": "10Х14НДЛ"
                    },
                    {
                        "title": "10Х17Н10Г4МБЛ"
                    },
                    {
                        "title": "10Х18Н11БЛ"
                    },
                    {
                        "title": "10Х18Н3Г3Д2Л"
                    },
                    {
                        "title": "10Х18Н9Л"
                    },
                    {
                        "title": "110Г13ФТЛ"
                    },
                    {
                        "title": "110Г13Х2БРЛ"
                    },
                    {
                        "title": "120Г10ФЛ"
                    },
                    {
                        "title": "12Х18Н12БЛ"
                    },
                    {
                        "title": "12Х18Н12М3ТЛ"
                    },
                    {
                        "title": "12Х18Н9ТЛ"
                    },
                    {
                        "title": "12Х25Н5ТМФЛ"
                    },
                    {
                        "title": "130Г14ХМФАЛ"
                    },
                    {
                        "title": "14Х18Н4Г4Л"
                    },
                    {
                        "title": "15Х13Л"
                    },
                    {
                        "title": "15Х18Н22В6М2Л"
                    },
                    {
                        "title": "15Х18Н22В6М2РЛ"
                    },
                    {
                        "title": "15Х23Н18Л"
                    },
                    {
                        "title": "15Х25ТЛ"
                    },
                    {
                        "title": "16Х18Н12С4ТЮЛ"
                    },
                    {
                        "title": "18Х25Н19СЛ"
                    },
                    {
                        "title": "20Х12ВНМФЛ"
                    },
                    {
                        "title": "20Х13Л"
                    },
                    {
                        "title": "20Х20Н14С2Л"
                    },
                    {
                        "title": "20Х21Н46В8Л"
                    },
                    {
                        "title": "20Х21Н46В8РЛ"
                    },
                    {
                        "title": "20Х25Н19С2Л"
                    },
                    {
                        "title": "20Х5МЛ"
                    },
                    {
                        "title": "20Х5ТЛ"
                    },
                    {
                        "title": "20Х8ВЛ"
                    },
                    {
                        "title": "31Х19Н9МВБТЛ"
                    },
                    {
                        "title": "35Х18Н24С2Л"
                    },
                    {
                        "title": "35Х23Н7СЛ"
                    },
                    {
                        "title": "40Х24Н12СЛ"
                    },
                    {
                        "title": "40Х9С2Л"
                    },
                    {
                        "title": "45Х17Г13Н3ЮЛ"
                    },
                    {
                        "title": "55Х18Г14С2ТЛ"
                    },
                    {
                        "title": "85Х4М5Ф2В6Л"
                    },
                    {
                        "title": "90Х4М4Ф2В6Л"
                    }
                ]
            }
        ]
    },
    {
        "title": "Сталь инструментальная",
        "submenu": [
            {
                "title": "Сталь  инструментальная  углеродистая",
                "submenu": [
                    {
                        "title": "У10"
                    },
                    {
                        "title": "У10А"
                    },
                    {
                        "title": "У11"
                    },
                    {
                        "title": "У11А"
                    },
                    {
                        "title": "У12"
                    },
                    {
                        "title": "У12А"
                    },
                    {
                        "title": "У13"
                    },
                    {
                        "title": "У13А"
                    },
                    {
                        "title": "У7"
                    },
                    {
                        "title": "У7А"
                    },
                    {
                        "title": "У8"
                    },
                    {
                        "title": "У8А"
                    },
                    {
                        "title": "У8Г"
                    },
                    {
                        "title": "У8ГА"
                    },
                    {
                        "title": "У9"
                    },
                    {
                        "title": "У9А"
                    }
                ]
            },
            {
                "title": "Сталь инструментальная  легированная",
                "submenu": [
                    {
                        "title": "05Х12Н6Д2МФСГТ"
                    },
                    {
                        "title": "11Х4В2МФ3С2"
                    },
                    {
                        "title": "11ХФ"
                    },
                    {
                        "title": "12Х1"
                    },
                    {
                        "title": "13Х"
                    },
                    {
                        "title": "3Х2МНФ"
                    },
                    {
                        "title": "4ХМНФС"
                    },
                    {
                        "title": "4ХС"
                    },
                    {
                        "title": "5ХВ2СФ"
                    },
                    {
                        "title": "5ХНВ"
                    },
                    {
                        "title": "5ХНВС"
                    },
                    {
                        "title": "6Х3МФС"
                    },
                    {
                        "title": "6Х4М2ФС"
                    },
                    {
                        "title": "6Х6В3МФС"
                    },
                    {
                        "title": "7ХФ"
                    },
                    {
                        "title": "8Х4В2МФС2"
                    },
                    {
                        "title": "8Х6НФТ"
                    },
                    {
                        "title": "8ХФ"
                    },
                    {
                        "title": "9Г2Ф"
                    },
                    {
                        "title": "9Х1"
                    },
                    {
                        "title": "9Х5ВФ"
                    },
                    {
                        "title": "9ХВГ"
                    },
                    {
                        "title": "9ХС"
                    },
                    {
                        "title": "9ХФ"
                    },
                    {
                        "title": "9ХФМ"
                    },
                    {
                        "title": "В2Ф"
                    },
                    {
                        "title": "Х"
                    },
                    {
                        "title": "ХВ4"
                    },
                    {
                        "title": "ХВ4Ф"
                    },
                    {
                        "title": "ХВГ"
                    },
                    {
                        "title": "ХВСГ"
                    },
                    {
                        "title": "ХВСГФ"
                    },
                    {
                        "title": "ХГС"
                    }
                ]
            },
            {
                "title": "Сталь инструментальная  штамповая",
                "submenu": [
                    {
                        "title": "27Х2Н2М1Ф"
                    },
                    {
                        "title": "2Х6В8М2К8"
                    },
                    {
                        "title": "3Х2В8Ф"
                    },
                    {
                        "title": "3Х2Н2МВФ"
                    },
                    {
                        "title": "3Х3М3Ф"
                    },
                    {
                        "title": "40Х5МФ"
                    },
                    {
                        "title": "4Х2В5МФ"
                    },
                    {
                        "title": "4Х2НМФ"
                    },
                    {
                        "title": "4Х3ВМФ"
                    },
                    {
                        "title": "4Х4ВМФС"
                    },
                    {
                        "title": "4Х5В2ФС"
                    },
                    {
                        "title": "4Х5МФ1С"
                    },
                    {
                        "title": "4Х5МФС"
                    },
                    {
                        "title": "4ХВ2С"
                    },
                    {
                        "title": "4ХМФС"
                    },
                    {
                        "title": "5Х2МНФ"
                    },
                    {
                        "title": "5Х3В3МФС"
                    },
                    {
                        "title": "5ХВ2С"
                    },
                    {
                        "title": "5ХГМ"
                    },
                    {
                        "title": "5ХНМ"
                    },
                    {
                        "title": "6ХВ2С"
                    },
                    {
                        "title": "6ХВГ"
                    },
                    {
                        "title": "6ХС"
                    },
                    {
                        "title": "7Х3"
                    },
                    {
                        "title": "7ХГ2ВМ"
                    },
                    {
                        "title": "7ХГ2ВМФ"
                    },
                    {
                        "title": "8Х3"
                    },
                    {
                        "title": "8Х4В3М3Ф2"
                    },
                    {
                        "title": "Х12"
                    },
                    {
                        "title": "Х12ВМ"
                    },
                    {
                        "title": "Х12ВМФ"
                    },
                    {
                        "title": "Х12М"
                    },
                    {
                        "title": "Х12МФ"
                    },
                    {
                        "title": "Х12Ф1"
                    },
                    {
                        "title": "Х6ВФ"
                    },
                    {
                        "title": "Х6Ф4М"
                    }
                ]
            },
            {
                "title": "Сталь инструментальная быстрорежущая",
                "submenu": [
                    {
                        "title": "11М5Ф"
                    },
                    {
                        "title": "11Р3АМ3Ф2"
                    },
                    {
                        "title": "9Х4М3Ф2АГСТ"
                    },
                    {
                        "title": "Р10Ф5К5"
                    },
                    {
                        "title": "Р12"
                    },
                    {
                        "title": "Р12Ф3"
                    },
                    {
                        "title": "Р14Ф4"
                    },
                    {
                        "title": "Р18"
                    },
                    {
                        "title": "Р18К5Ф2"
                    },
                    {
                        "title": "Р18Ф2"
                    },
                    {
                        "title": "Р18Ф2К5"
                    },
                    {
                        "title": "Р2АМ9К5"
                    },
                    {
                        "title": "Р2М5"
                    },
                    {
                        "title": "Р6М3"
                    },
                    {
                        "title": "Р6М5"
                    },
                    {
                        "title": "Р6М5К5"
                    },
                    {
                        "title": "Р6М5Ф3"
                    },
                    {
                        "title": "Р9"
                    },
                    {
                        "title": "Р9К10"
                    },
                    {
                        "title": "Р9К5"
                    },
                    {
                        "title": "Р9М4К8"
                    },
                    {
                        "title": "Р9Ф5"
                    }
                ]
            },
            {
                "title": "Сталь инструментальная валковая",
                "submenu": [
                    {
                        "title": "45ХНМ"
                    },
                    {
                        "title": "55Х"
                    },
                    {
                        "title": "60Х2СМФ"
                    },
                    {
                        "title": "60ХГ"
                    },
                    {
                        "title": "60ХН"
                    },
                    {
                        "title": "60ХСМФ"
                    },
                    {
                        "title": "75ХМ"
                    },
                    {
                        "title": "75ХМФ"
                    },
                    {
                        "title": "75ХСМФ"
                    },
                    {
                        "title": "7Х2СМФ"
                    },
                    {
                        "title": "90ХМФ"
                    },
                    {
                        "title": "90ХФ"
                    },
                    {
                        "title": "9Х2"
                    },
                    {
                        "title": "9Х2МФ"
                    }
                ]
            }
        ]
    },
    {
        "title": "Сталь конструкционная",
        "submenu": [
            {
                "title": "Сталь конструкционная высокопрочная высоколегированная",
                "submenu": [
                    {
                        "title": "Н12К12М10ТЮ"
                    },
                    {
                        "title": "Н12К12М7В7"
                    },
                    {
                        "title": "Н12К15М10"
                    },
                    {
                        "title": "Н12К16М12"
                    },
                    {
                        "title": "Н12К8М3Г2"
                    },
                    {
                        "title": "Н12К8М4Г2"
                    },
                    {
                        "title": "Н13К15М10"
                    },
                    {
                        "title": "Н13К16М10"
                    },
                    {
                        "title": "Н15К9М5ТЮ"
                    },
                    {
                        "title": "Н16К11М3Т2"
                    },
                    {
                        "title": "Н16К15В9М2"
                    },
                    {
                        "title": "Н16К4М5Т2Ю"
                    },
                    {
                        "title": "Н17К10М2В10Т"
                    },
                    {
                        "title": "Н17К11М4Т2Ю"
                    },
                    {
                        "title": "Н17К12М5Т"
                    },
                    {
                        "title": "Н18К12М3Т2"
                    },
                    {
                        "title": "Н18К12М4Т2"
                    },
                    {
                        "title": "Н18К14М5Т"
                    },
                    {
                        "title": "Н18К3М4Т"
                    },
                    {
                        "title": "Н18К4М7ТС"
                    },
                    {
                        "title": "Н18К7М5Т"
                    },
                    {
                        "title": "Н18К8М3Т"
                    },
                    {
                        "title": "Н18К8М5Т"
                    },
                    {
                        "title": "Н18К9М5Т"
                    },
                    {
                        "title": "Н18Ф6М3"
                    },
                    {
                        "title": "Н18Ф6М6"
                    },
                    {
                        "title": "Н8К18М14"
                    }
                ]
            },
            {
                "title": "Сталь конструкционная криогенная",
                "submenu": [
                    {
                        "title": "03Х13Н9Д2ТМ"
                    },
                    {
                        "title": "03Х17Н14М3"
                    },
                    {
                        "title": "03Х19Г10Н7М2"
                    },
                    {
                        "title": "03Х20Н16АГ6"
                    },
                    {
                        "title": "07Х21Г7АН5"
                    },
                    {
                        "title": "0Н6"
                    },
                    {
                        "title": "0Н6А"
                    },
                    {
                        "title": "0Н9"
                    },
                    {
                        "title": "0Н9А"
                    },
                    {
                        "title": "10Х14Г14Н4Т"
                    },
                    {
                        "title": "12Х18Н10Т"
                    }
                ]
            },
            {
                "title": "Сталь конструкционная легированная",
                "submenu": [
                    {
                        "title": "10Г2"
                    },
                    {
                        "title": "10Х2М"
                    },
                    {
                        "title": "12Г2"
                    },
                    {
                        "title": "12Х2Н4А"
                    },
                    {
                        "title": "12ХН"
                    },
                    {
                        "title": "12ХН2"
                    },
                    {
                        "title": "12ХН2А"
                    },
                    {
                        "title": "12ХН3А"
                    },
                    {
                        "title": "14Х2ГМР"
                    },
                    {
                        "title": "14Х2Н3МА"
                    },
                    {
                        "title": "14ХГН"
                    },
                    {
                        "title": "15Г"
                    },
                    {
                        "title": "15Н2М"
                    },
                    {
                        "title": "15Х"
                    },
                    {
                        "title": "15ХА"
                    },
                    {
                        "title": "15ХГН2ТА"
                    },
                    {
                        "title": "15ХФ"
                    },
                    {
                        "title": "16Г2"
                    },
                    {
                        "title": "16ХСН"
                    },
                    {
                        "title": "18Х2Н4ВА"
                    },
                    {
                        "title": "18Х2Н4МА"
                    },
                    {
                        "title": "18ХГ"
                    },
                    {
                        "title": "18ХГТ"
                    },
                    {
                        "title": "19ХГН"
                    },
                    {
                        "title": "20Г"
                    },
                    {
                        "title": "20Г2"
                    },
                    {
                        "title": "20Н2М"
                    },
                    {
                        "title": "20Х"
                    },
                    {
                        "title": "20Х2Н4А"
                    },
                    {
                        "title": "20ХГНМ"
                    },
                    {
                        "title": "20ХГНР"
                    },
                    {
                        "title": "20ХГНТР"
                    },
                    {
                        "title": "20ХГР"
                    },
                    {
                        "title": "20ХГСА"
                    },
                    {
                        "title": "20ХМ"
                    },
                    {
                        "title": "20ХН"
                    },
                    {
                        "title": "20ХН2М"
                    },
                    {
                        "title": "20ХН3А"
                    },
                    {
                        "title": "20ХН4ФА"
                    },
                    {
                        "title": "20ХНР"
                    },
                    {
                        "title": "20ХФ"
                    },
                    {
                        "title": "25Г"
                    },
                    {
                        "title": "25Х2ГНТА"
                    },
                    {
                        "title": "25Х2Н4МА"
                    },
                    {
                        "title": "25ХГМ"
                    },
                    {
                        "title": "25ХГНМТ"
                    },
                    {
                        "title": "25ХГСА"
                    },
                    {
                        "title": "25ХГТ"
                    },
                    {
                        "title": "27ХГР"
                    },
                    {
                        "title": "30Г"
                    },
                    {
                        "title": "30Г2"
                    },
                    {
                        "title": "30Х"
                    },
                    {
                        "title": "30Х3МФ"
                    },
                    {
                        "title": "30ХГС"
                    },
                    {
                        "title": "30ХГСА"
                    },
                    {
                        "title": "30ХГСН2А"
                    },
                    {
                        "title": "30ХГТ"
                    },
                    {
                        "title": "30ХН2МА"
                    },
                    {
                        "title": "30ХН2МФА"
                    },
                    {
                        "title": "30ХН3А"
                    },
                    {
                        "title": "30ХН3М2ФА"
                    },
                    {
                        "title": "30ХРА"
                    },
                    {
                        "title": "33ХС"
                    },
                    {
                        "title": "34ХН1М"
                    },
                    {
                        "title": "34ХН1МА"
                    },
                    {
                        "title": "34ХН3М"
                    },
                    {
                        "title": "34ХН3МА"
                    },
                    {
                        "title": "35Г"
                    },
                    {
                        "title": "35Г2"
                    },
                    {
                        "title": "35Х"
                    },
                    {
                        "title": "35ХГ2"
                    },
                    {
                        "title": "35ХГН2"
                    },
                    {
                        "title": "35ХГСА"
                    },
                    {
                        "title": "35ХГФ"
                    },
                    {
                        "title": "35ХН1М2ФА"
                    },
                    {
                        "title": "36Х2Н2МФА"
                    },
                    {
                        "title": "38Х2Н2МА"
                    },
                    {
                        "title": "38Х2Н3М"
                    },
                    {
                        "title": "38Х2НМ"
                    },
                    {
                        "title": "38Х2НМФ"
                    },
                    {
                        "title": "38Х2Ю"
                    },
                    {
                        "title": "38ХА"
                    },
                    {
                        "title": "38ХГМ"
                    },
                    {
                        "title": "38ХГН"
                    },
                    {
                        "title": "38ХГНМ"
                    },
                    {
                        "title": "38ХМ"
                    },
                    {
                        "title": "38ХМА"
                    },
                    {
                        "title": "38ХН3МА"
                    },
                    {
                        "title": "38ХН3МФА"
                    },
                    {
                        "title": "38ХС"
                    },
                    {
                        "title": "40Г"
                    },
                    {
                        "title": "40Г2"
                    },
                    {
                        "title": "40ГР"
                    },
                    {
                        "title": "40Х"
                    },
                    {
                        "title": "40Х2Н2МА"
                    },
                    {
                        "title": "40ХГНМ"
                    },
                    {
                        "title": "40ХГТР"
                    },
                    {
                        "title": "40ХМФА"
                    },
                    {
                        "title": "40ХН"
                    },
                    {
                        "title": "40ХН2МА"
                    },
                    {
                        "title": "40ХС"
                    },
                    {
                        "title": "40ХСН2МА"
                    },
                    {
                        "title": "40ХФА"
                    },
                    {
                        "title": "45Г"
                    },
                    {
                        "title": "45Г2"
                    },
                    {
                        "title": "45Х"
                    },
                    {
                        "title": "45ХН"
                    },
                    {
                        "title": "45ХН2МФА"
                    },
                    {
                        "title": "47ГТ"
                    },
                    {
                        "title": "50Г"
                    },
                    {
                        "title": "50Г2"
                    },
                    {
                        "title": "50Х"
                    },
                    {
                        "title": "50ХН"
                    }
                ]
            },
            {
                "title": "Сталь конструкционная низколегированная для сварных конструкций",
                "submenu": [
                    {
                        "title": "06Г2СЮ"
                    },
                    {
                        "title": "06ХГСЮ"
                    },
                    {
                        "title": "08Г2С"
                    },
                    {
                        "title": "09Г2"
                    },
                    {
                        "title": "09Г2Д"
                    },
                    {
                        "title": "09Г2С"
                    },
                    {
                        "title": "09Г2СД"
                    },
                    {
                        "title": "10Г2Б"
                    },
                    {
                        "title": "10Г2БД"
                    },
                    {
                        "title": "10Г2С1"
                    },
                    {
                        "title": "10Г2С1Д"
                    },
                    {
                        "title": "10ГС2"
                    },
                    {
                        "title": "10ГТ"
                    },
                    {
                        "title": "10ХГСН1Д"
                    },
                    {
                        "title": "10ХНДП"
                    },
                    {
                        "title": "10ХСНД"
                    },
                    {
                        "title": "12Г2Б"
                    },
                    {
                        "title": "12Г2СМФ"
                    },
                    {
                        "title": "12ГН2МФАЮ"
                    },
                    {
                        "title": "12ГС"
                    },
                    {
                        "title": "12ХГН2МФБАЮ"
                    },
                    {
                        "title": "14Г2"
                    },
                    {
                        "title": "14Г2АФ"
                    },
                    {
                        "title": "14Г2АФД"
                    },
                    {
                        "title": "14ХГС"
                    },
                    {
                        "title": "15Г2АФД"
                    },
                    {
                        "title": "15Г2АФДпс"
                    },
                    {
                        "title": "15Г2СФ"
                    },
                    {
                        "title": "15Г2СФД"
                    },
                    {
                        "title": "15ГС"
                    },
                    {
                        "title": "15ГФ"
                    },
                    {
                        "title": "15ГФД"
                    },
                    {
                        "title": "15ХСНД"
                    },
                    {
                        "title": "16Г2АФ"
                    },
                    {
                        "title": "16Г2АФД"
                    },
                    {
                        "title": "16ГС"
                    },
                    {
                        "title": "16Д"
                    },
                    {
                        "title": "17Г1С"
                    },
                    {
                        "title": "17ГС"
                    },
                    {
                        "title": "18Г2АФ"
                    },
                    {
                        "title": "18Г2АФД"
                    },
                    {
                        "title": "18Г2АФДпс"
                    },
                    {
                        "title": "18Г2АФпс"
                    },
                    {
                        "title": "18Г2С"
                    },
                    {
                        "title": "1х2м1"
                    },
                    {
                        "title": "20ГС"
                    },
                    {
                        "title": "20ГС2"
                    },
                    {
                        "title": "20Х2Г2СР"
                    },
                    {
                        "title": "20ХГ2Т"
                    },
                    {
                        "title": "20ХГ2Ц"
                    },
                    {
                        "title": "20ХГС2"
                    },
                    {
                        "title": "22Х2Г2АЮ"
                    },
                    {
                        "title": "22Х2Г2Р"
                    },
                    {
                        "title": "23Х2Г2Т"
                    },
                    {
                        "title": "23Х2Г2Ц"
                    },
                    {
                        "title": "25Г2С"
                    },
                    {
                        "title": "25ГС"
                    },
                    {
                        "title": "25С2Р"
                    },
                    {
                        "title": "28С"
                    },
                    {
                        "title": "30ХС2"
                    },
                    {
                        "title": "32Г2Рпс"
                    },
                    {
                        "title": "35ГС"
                    },
                    {
                        "title": "6Г2АФ"
                    },
                    {
                        "title": "80С"
                    }
                ]
            },
            {
                "title": "Сталь конструкционная повышенной обрабатываемости",
                "submenu": [
                    {
                        "title": "А11"
                    },
                    {
                        "title": "А12"
                    },
                    {
                        "title": "А20"
                    },
                    {
                        "title": "А30"
                    },
                    {
                        "title": "А35"
                    },
                    {
                        "title": "А35Е"
                    },
                    {
                        "title": "А40Г"
                    },
                    {
                        "title": "А40ХЕ"
                    },
                    {
                        "title": "А45Е"
                    },
                    {
                        "title": "АС11"
                    },
                    {
                        "title": "АС12ХН"
                    },
                    {
                        "title": "АС14"
                    },
                    {
                        "title": "АС14ХГН"
                    },
                    {
                        "title": "АС19ХГН"
                    },
                    {
                        "title": "АС20ХГНМ"
                    },
                    {
                        "title": "АС30ХМ"
                    },
                    {
                        "title": "АС35Г2"
                    },
                    {
                        "title": "АС38ХГМ"
                    },
                    {
                        "title": "АС40"
                    },
                    {
                        "title": "АС40ХГНМ"
                    },
                    {
                        "title": "АС45Г2"
                    },
                    {
                        "title": "АСЦ30ХМ"
                    },
                    {
                        "title": "АЦ20ХГНМ"
                    }
                ]
            },
            {
                "title": "Сталь конструкционная подшипниковая",
                "submenu": [
                    {
                        "title": "11Х18М-ШД"
                    },
                    {
                        "title": "8Х4В9Ф2-Ш"
                    },
                    {
                        "title": "ШХ15"
                    },
                    {
                        "title": "ШХ15СГ"
                    },
                    {
                        "title": "ШХ20СГ"
                    },
                    {
                        "title": "ШХ4"
                    }
                ]
            },
            {
                "title": "Сталь конструкционная рессорно-пружинная",
                "submenu": [
                    {
                        "title": "50ХГ"
                    },
                    {
                        "title": "50ХГА"
                    },
                    {
                        "title": "50ХГФА"
                    },
                    {
                        "title": "50ХСА"
                    },
                    {
                        "title": "50ХФА"
                    },
                    {
                        "title": "51ХФА"
                    },
                    {
                        "title": "55С2"
                    },
                    {
                        "title": "55С2А"
                    },
                    {
                        "title": "55С2ГФ"
                    },
                    {
                        "title": "55ХГР"
                    },
                    {
                        "title": "60Г"
                    },
                    {
                        "title": "60С2"
                    },
                    {
                        "title": "60С2А"
                    },
                    {
                        "title": "60С2Г"
                    },
                    {
                        "title": "60С2Н2А"
                    },
                    {
                        "title": "60С2ХА"
                    },
                    {
                        "title": "60С2ХФА"
                    },
                    {
                        "title": "65"
                    },
                    {
                        "title": "65Г"
                    },
                    {
                        "title": "65ГА"
                    },
                    {
                        "title": "65С2ВА"
                    },
                    {
                        "title": "68А"
                    },
                    {
                        "title": "68ГА"
                    },
                    {
                        "title": "70"
                    },
                    {
                        "title": "70Г"
                    },
                    {
                        "title": "70С2ХА"
                    },
                    {
                        "title": "70С3А"
                    },
                    {
                        "title": "75"
                    },
                    {
                        "title": "80"
                    },
                    {
                        "title": "85"
                    }
                ]
            },
            {
                "title": "Сталь конструкционная углеродистая качественная",
                "submenu": [
                    {
                        "title": "05кп"
                    },
                    {
                        "title": "08"
                    },
                    {
                        "title": "08Фкп"
                    },
                    {
                        "title": "08Ю"
                    },
                    {
                        "title": "08кп"
                    },
                    {
                        "title": "08пс"
                    },
                    {
                        "title": "10"
                    },
                    {
                        "title": "10кп"
                    },
                    {
                        "title": "10пс"
                    },
                    {
                        "title": "11кп"
                    },
                    {
                        "title": "12к"
                    },
                    {
                        "title": "15"
                    },
                    {
                        "title": "15К"
                    },
                    {
                        "title": "15кп"
                    },
                    {
                        "title": "15пс"
                    },
                    {
                        "title": "16К"
                    },
                    {
                        "title": "18К"
                    },
                    {
                        "title": "18кп"
                    },
                    {
                        "title": "20"
                    },
                    {
                        "title": "20К"
                    },
                    {
                        "title": "20кп"
                    },
                    {
                        "title": "20пс"
                    },
                    {
                        "title": "22К"
                    },
                    {
                        "title": "25"
                    },
                    {
                        "title": "30"
                    },
                    {
                        "title": "35"
                    },
                    {
                        "title": "40"
                    },
                    {
                        "title": "45"
                    },
                    {
                        "title": "50"
                    },
                    {
                        "title": "55"
                    },
                    {
                        "title": "58"
                    },
                    {
                        "title": "60"
                    },
                    {
                        "title": "ОсВ"
                    }
                ]
            },
            {
                "title": "Сталь конструкционная углеродистая обыкновенного качества",
                "submenu": [
                    {
                        "title": "ВСт2кп"
                    },
                    {
                        "title": "ВСт2пс"
                    },
                    {
                        "title": "ВСт2сп"
                    },
                    {
                        "title": "ВСт3Гпс"
                    },
                    {
                        "title": "ВСт3кп"
                    },
                    {
                        "title": "ВСт3пс"
                    },
                    {
                        "title": "ВСт3сп"
                    },
                    {
                        "title": "ВСт4кп"
                    },
                    {
                        "title": "ВСт4пс"
                    },
                    {
                        "title": "ВСт5пс"
                    },
                    {
                        "title": "ВСт5сп"
                    },
                    {
                        "title": "ВСт6пс"
                    },
                    {
                        "title": "ВСт6сп"
                    },
                    {
                        "title": "Ст0"
                    },
                    {
                        "title": "Ст1"
                    },
                    {
                        "title": "Ст1кп"
                    },
                    {
                        "title": "Ст1пс"
                    },
                    {
                        "title": "Ст1сп"
                    },
                    {
                        "title": "Ст2кп"
                    },
                    {
                        "title": "Ст2пс"
                    },
                    {
                        "title": "Ст2сп"
                    },
                    {
                        "title": "Ст3Гпс"
                    },
                    {
                        "title": "Ст3Гсп"
                    },
                    {
                        "title": "Ст3кп"
                    },
                    {
                        "title": "Ст3пс"
                    },
                    {
                        "title": "Ст3сп"
                    },
                    {
                        "title": "Ст4кп"
                    },
                    {
                        "title": "Ст4пс"
                    },
                    {
                        "title": "Ст4сп"
                    },
                    {
                        "title": "Ст5Гпс"
                    },
                    {
                        "title": "Ст5пс"
                    },
                    {
                        "title": "Ст5сп"
                    },
                    {
                        "title": "Ст6пс"
                    },
                    {
                        "title": "Ст6сп"
                    }
                ]
            }
        ]
    },
    {
        "title": "Сталь специального  назначения",
        "submenu": [
            {
                "title": "Сталь для строительных конструкций",
                "submenu": [
                    {
                        "title": "С235"
                    },
                    {
                        "title": "С245"
                    },
                    {
                        "title": "С255"
                    },
                    {
                        "title": "С275"
                    },
                    {
                        "title": "С285"
                    },
                    {
                        "title": "С345"
                    },
                    {
                        "title": "С345Д"
                    },
                    {
                        "title": "С345К"
                    },
                    {
                        "title": "С375"
                    },
                    {
                        "title": "С375Д"
                    },
                    {
                        "title": "С390"
                    },
                    {
                        "title": "С390Д"
                    },
                    {
                        "title": "С390К"
                    },
                    {
                        "title": "С440"
                    },
                    {
                        "title": "С440Д"
                    },
                    {
                        "title": "С590"
                    },
                    {
                        "title": "С590К"
                    }
                ]
            },
            {
                "title": "Сталь для судостроения",
                "submenu": [
                    {
                        "title": "A36"
                    },
                    {
                        "title": "A40"
                    },
                    {
                        "title": "D"
                    },
                    {
                        "title": "D32"
                    },
                    {
                        "title": "D36"
                    },
                    {
                        "title": "D40"
                    },
                    {
                        "title": "E36"
                    },
                    {
                        "title": "E40"
                    },
                    {
                        "title": "А"
                    },
                    {
                        "title": "А32"
                    },
                    {
                        "title": "В"
                    },
                    {
                        "title": "Е"
                    },
                    {
                        "title": "Е32"
                    }
                ]
            },
            {
                "title": "Сталь рельсовая",
                "submenu": [
                    {
                        "title": "К63"
                    },
                    {
                        "title": "М74"
                    },
                    {
                        "title": "М74Т"
                    },
                    {
                        "title": "М74Ц"
                    },
                    {
                        "title": "М76"
                    },
                    {
                        "title": "М76В"
                    },
                    {
                        "title": "М76ВТ"
                    },
                    {
                        "title": "М76Т"
                    },
                    {
                        "title": "М76Ц"
                    }
                ]
            }
        ]
    },
    {
        "title": "Сталь электротехническая",
        "submenu": [
            {
                "title": "Сталь электротехническая нелегированная",
                "submenu": [
                    {
                        "title": "10832"
                    },
                    {
                        "title": "10848"
                    },
                    {
                        "title": "10864"
                    },
                    {
                        "title": "10880"
                    },
                    {
                        "title": "10895"
                    },
                    {
                        "title": "11832"
                    },
                    {
                        "title": "11848"
                    },
                    {
                        "title": "11864"
                    },
                    {
                        "title": "11880"
                    },
                    {
                        "title": "11895"
                    },
                    {
                        "title": "20832"
                    },
                    {
                        "title": "20848"
                    },
                    {
                        "title": "20864"
                    },
                    {
                        "title": "20880"
                    },
                    {
                        "title": "20895"
                    },
                    {
                        "title": "21832"
                    },
                    {
                        "title": "21848"
                    },
                    {
                        "title": "21864"
                    },
                    {
                        "title": "21880"
                    },
                    {
                        "title": "21895"
                    }
                ]
            },
            {
                "title": "Сталь электротехническая сернистая",
                "submenu": [
                    {
                        "title": "1211"
                    },
                    {
                        "title": "1212"
                    },
                    {
                        "title": "1213"
                    },
                    {
                        "title": "1311"
                    },
                    {
                        "title": "1312"
                    },
                    {
                        "title": "1313"
                    },
                    {
                        "title": "1411"
                    },
                    {
                        "title": "1412"
                    },
                    {
                        "title": "1413"
                    },
                    {
                        "title": "1511"
                    },
                    {
                        "title": "1512"
                    },
                    {
                        "title": "1513"
                    },
                    {
                        "title": "1514"
                    },
                    {
                        "title": "1521"
                    },
                    {
                        "title": "1561"
                    },
                    {
                        "title": "1562"
                    },
                    {
                        "title": "1571"
                    },
                    {
                        "title": "1572"
                    },
                    {
                        "title": "2011"
                    },
                    {
                        "title": "2012"
                    },
                    {
                        "title": "2013"
                    },
                    {
                        "title": "2111"
                    },
                    {
                        "title": "2112"
                    },
                    {
                        "title": "2211"
                    },
                    {
                        "title": "2212"
                    },
                    {
                        "title": "2311"
                    },
                    {
                        "title": "2312"
                    },
                    {
                        "title": "2411"
                    },
                    {
                        "title": "2412"
                    },
                    {
                        "title": "3404"
                    },
                    {
                        "title": "3405"
                    },
                    {
                        "title": "3406"
                    },
                    {
                        "title": "3411"
                    },
                    {
                        "title": "3412"
                    },
                    {
                        "title": "3413"
                    },
                    {
                        "title": "3414"
                    },
                    {
                        "title": "3415"
                    },
                    {
                        "title": "3416"
                    },
                    {
                        "title": "3421"
                    },
                    {
                        "title": "3422"
                    },
                    {
                        "title": "3423"
                    },
                    {
                        "title": "3424"
                    },
                    {
                        "title": "3425"
                    }
                ]
            }
        ]
    },
    {
        "title": "Сталь, сплав жаропрочные",
        "submenu": [
            {
                "title": "Сплав жаропрочный",
                "submenu": [
                    {
                        "title": "10Х15Н35В3ТЮ"
                    },
                    {
                        "title": "ХН28ВМАБ"
                    },
                    {
                        "title": "ХН32Т"
                    },
                    {
                        "title": "ХН35ВТ"
                    },
                    {
                        "title": "ХН35ВТР"
                    },
                    {
                        "title": "ХН35ВТЮ"
                    },
                    {
                        "title": "ХН38ВБ"
                    },
                    {
                        "title": "ХН38ВТ"
                    },
                    {
                        "title": "ХН45Ю"
                    },
                    {
                        "title": "ХН55ВМКЮ"
                    },
                    {
                        "title": "ХН55ВМТКЮ"
                    },
                    {
                        "title": "ХН55МВЮ"
                    },
                    {
                        "title": "ХН56ВМКЮ"
                    },
                    {
                        "title": "ХН56ВМТЮ"
                    },
                    {
                        "title": "ХН57МТВЮ"
                    },
                    {
                        "title": "ХН60ВТ"
                    },
                    {
                        "title": "ХН60Ю"
                    },
                    {
                        "title": "ХН62МВКЮ"
                    },
                    {
                        "title": "ХН65ВМТЮ"
                    },
                    {
                        "title": "ХН70ВМТЮ"
                    },
                    {
                        "title": "ХН70ВМТЮФ"
                    },
                    {
                        "title": "ХН70ВМЮТ"
                    },
                    {
                        "title": "ХН70МВТЮБ"
                    },
                    {
                        "title": "ХН70Ю"
                    },
                    {
                        "title": "ХН75ВМЮ"
                    },
                    {
                        "title": "ХН75МБТЮ"
                    },
                    {
                        "title": "ХН77ТЮР"
                    },
                    {
                        "title": "ХН77ТЮРУ"
                    },
                    {
                        "title": "ХН78Т"
                    },
                    {
                        "title": "ХН80ТБЮ"
                    }
                ]
            },
            {
                "title": "Сталь жаропрочная высоколегированная",
                "submenu": [
                    {
                        "title": "08Х15Н24В4ТР"
                    },
                    {
                        "title": "08Х15Н25М3ТЮБ"
                    },
                    {
                        "title": "08Х16Н11М3"
                    },
                    {
                        "title": "08Х16Н13М2Б"
                    },
                    {
                        "title": "08Х20Н14С2"
                    },
                    {
                        "title": "08Х21Н6М2Т"
                    },
                    {
                        "title": "09Х14Н16Б"
                    },
                    {
                        "title": "09Х14Н19В2БР"
                    },
                    {
                        "title": "09Х14Н19В2БР1"
                    },
                    {
                        "title": "09Х16Н15М3Б"
                    },
                    {
                        "title": "09Х16Н16МВ2БР"
                    },
                    {
                        "title": "10Х11Н20Т2Р"
                    },
                    {
                        "title": "10Х11Н20Т3Р"
                    },
                    {
                        "title": "10Х11Н23Т3МР"
                    },
                    {
                        "title": "10Х13СЮ"
                    },
                    {
                        "title": "10Х15Н25В3ТЮ"
                    },
                    {
                        "title": "10Х15Н25М3В3ТЮК"
                    },
                    {
                        "title": "10Х18Н18Ю4Д"
                    },
                    {
                        "title": "10Х23Н18"
                    },
                    {
                        "title": "10Х25Н25ТР"
                    },
                    {
                        "title": "10Х7МВФБР"
                    },
                    {
                        "title": "11Х11Н2В2МФ"
                    },
                    {
                        "title": "12Х12МВФБР"
                    },
                    {
                        "title": "12Х14Н14В2М"
                    },
                    {
                        "title": "12Х25Н16Г7АР"
                    },
                    {
                        "title": "12Х2МВ8ФБ"
                    },
                    {
                        "title": "12Х8ВФ"
                    },
                    {
                        "title": "13Х11Н2В2МФ"
                    },
                    {
                        "title": "13Х12Н2В2МФ"
                    },
                    {
                        "title": "13Х14Н3В2ФР"
                    },
                    {
                        "title": "15Х11МФ"
                    },
                    {
                        "title": "15Х12ВНМФ"
                    },
                    {
                        "title": "15Х18СЮ"
                    },
                    {
                        "title": "16Х11Н2В2МФ"
                    },
                    {
                        "title": "18Х11МНФБ"
                    },
                    {
                        "title": "18Х11МФБ"
                    },
                    {
                        "title": "18Х12ВМБФР"
                    },
                    {
                        "title": "20Х12ВНМФ"
                    },
                    {
                        "title": "20Х12Н2В2МФ"
                    },
                    {
                        "title": "20Х20Н14С2"
                    },
                    {
                        "title": "20Х23Н13"
                    },
                    {
                        "title": "20Х23Н18"
                    },
                    {
                        "title": "20Х25Н20С2"
                    },
                    {
                        "title": "2Х12Н2ВМФ"
                    },
                    {
                        "title": "30Х13Н7С2"
                    },
                    {
                        "title": "31Х19Н9МВБТ"
                    },
                    {
                        "title": "36Х18Н25С2"
                    },
                    {
                        "title": "37Х12Н8Г8МФБ"
                    },
                    {
                        "title": "40Х10С2М"
                    },
                    {
                        "title": "40Х15Н7Г7Ф2МС"
                    },
                    {
                        "title": "40Х9С2"
                    },
                    {
                        "title": "45Х14Н14В2М"
                    },
                    {
                        "title": "45Х22Н4М3"
                    },
                    {
                        "title": "4Х14Н14В2М"
                    },
                    {
                        "title": "4Х15Н7Г7Ф2МС"
                    },
                    {
                        "title": "55Х20Г9АН4"
                    }
                ]
            },
            {
                "title": "Сталь жаропрочная низколегированная",
                "submenu": [
                    {
                        "title": "12МХ"
                    },
                    {
                        "title": "12Х1МФ"
                    },
                    {
                        "title": "12Х2МФБ"
                    },
                    {
                        "title": "12Х2МФСР"
                    },
                    {
                        "title": "12ХМ"
                    },
                    {
                        "title": "15Х1М1Ф"
                    },
                    {
                        "title": "15Х2М2ФБС"
                    },
                    {
                        "title": "15Х5"
                    },
                    {
                        "title": "15Х5ВФ"
                    },
                    {
                        "title": "15Х5М"
                    },
                    {
                        "title": "15Х6СЮ"
                    },
                    {
                        "title": "15ХМ"
                    },
                    {
                        "title": "15ХМФКР"
                    },
                    {
                        "title": "16ГНМ"
                    },
                    {
                        "title": "18Х3МФ"
                    }
                ]
            },
            {
                "title": "Сталь жаропрочная релаксационностойкая",
                "submenu": [
                    {
                        "title": "20Х1М1Ф1БТ"
                    },
                    {
                        "title": "20Х1М1Ф1ТР"
                    },
                    {
                        "title": "20Х3МВФ"
                    },
                    {
                        "title": "20ХМФБР"
                    },
                    {
                        "title": "25Х1М1Ф"
                    },
                    {
                        "title": "25Х1МФ"
                    },
                    {
                        "title": "25Х2М1Ф"
                    },
                    {
                        "title": "30ХМ"
                    },
                    {
                        "title": "30ХМА"
                    },
                    {
                        "title": "35ХМ"
                    },
                    {
                        "title": "38Х2МЮА"
                    }
                ]
            }
        ]
    },
    {
        "title": "Сплав жаропрочный",
        "submenu": [
            {
                "title": "10Х15Н35В3ТЮ"
            },
            {
                "title": "ХН28ВМАБ"
            },
            {
                "title": "ХН32Т"
            },
            {
                "title": "ХН35ВТ"
            },
            {
                "title": "ХН35ВТР"
            },
            {
                "title": "ХН35ВТЮ"
            },
            {
                "title": "ХН38ВБ"
            },
            {
                "title": "ХН38ВТ"
            },
            {
                "title": "ХН45Ю"
            },
            {
                "title": "ХН55ВМКЮ"
            },
            {
                "title": "ХН55ВМТКЮ"
            },
            {
                "title": "ХН55МВЮ"
            },
            {
                "title": "ХН56ВМКЮ"
            },
            {
                "title": "ХН56ВМТЮ"
            },
            {
                "title": "ХН57МТВЮ"
            },
            {
                "title": "ХН60ВТ"
            },
            {
                "title": "ХН60Ю"
            },
            {
                "title": "ХН62МВКЮ"
            },
            {
                "title": "ХН65ВМТЮ"
            },
            {
                "title": "ХН70ВМТЮ"
            },
            {
                "title": "ХН70ВМТЮФ"
            },
            {
                "title": "ХН70ВМЮТ"
            },
            {
                "title": "ХН70МВТЮБ"
            },
            {
                "title": "ХН70Ю"
            },
            {
                "title": "ХН75ВМЮ"
            },
            {
                "title": "ХН75МБТЮ"
            },
            {
                "title": "ХН77ТЮР"
            },
            {
                "title": "ХН77ТЮРУ"
            },
            {
                "title": "ХН78Т"
            },
            {
                "title": "ХН80ТБЮ"
            }
        ]
    },
    {
        "title": "Сталь, сплав коррозионно-стойкие",
        "submenu": [
            {
                "title": "Сплав коррозионно-стойкий",
                "submenu": [
                    {
                        "title": "03ХН28МДТ"
                    },
                    {
                        "title": "06ХН28МДТ"
                    },
                    {
                        "title": "06ХН28МТ"
                    },
                    {
                        "title": "Н70МФ"
                    },
                    {
                        "title": "Н70МФВ"
                    },
                    {
                        "title": "ХН58В"
                    },
                    {
                        "title": "ХН65МВ"
                    },
                    {
                        "title": "ХН65МВУ"
                    }
                ]
            },
            {
                "title": "Сталь коррозионно-стойкая жаропрочная",
                "submenu": [
                    {
                        "title": "08Х13"
                    },
                    {
                        "title": "08Х17Т"
                    },
                    {
                        "title": "08Х18Н10"
                    },
                    {
                        "title": "08Х18Н10Т"
                    },
                    {
                        "title": "08Х18Т1"
                    },
                    {
                        "title": "12Х13"
                    },
                    {
                        "title": "12Х17"
                    },
                    {
                        "title": "12Х18Н12Т"
                    },
                    {
                        "title": "12Х18Н9"
                    },
                    {
                        "title": "12Х18Н9Т"
                    },
                    {
                        "title": "14Х17Н2"
                    },
                    {
                        "title": "15Х25Т"
                    },
                    {
                        "title": "15Х28"
                    },
                    {
                        "title": "20Х13"
                    },
                    {
                        "title": "30Х13"
                    },
                    {
                        "title": "40Х13"
                    }
                ]
            },
            {
                "title": "Сталь коррозионно-стойкая обыкновенная",
                "submenu": [
                    {
                        "title": "03Х16Н15М3"
                    },
                    {
                        "title": "03Х16Н15М3Б"
                    },
                    {
                        "title": "03Х18Н11"
                    },
                    {
                        "title": "03Х18Н12"
                    },
                    {
                        "title": "03Х21Н21М4ГБ"
                    },
                    {
                        "title": "03Х22Н6М2"
                    },
                    {
                        "title": "03Х23Н6"
                    },
                    {
                        "title": "04Х18Н10"
                    },
                    {
                        "title": "06Х18Н11"
                    },
                    {
                        "title": "07Х16Н6"
                    },
                    {
                        "title": "08Х10Н20Т2"
                    },
                    {
                        "title": "08Х17Н13М2Т"
                    },
                    {
                        "title": "08Х17Н15М3Т"
                    },
                    {
                        "title": "08Х17Н5М3"
                    },
                    {
                        "title": "08Х17Н6Т"
                    },
                    {
                        "title": "08Х18Г8Н2Т"
                    },
                    {
                        "title": "08Х18Н12Б"
                    },
                    {
                        "title": "08Х18Н12Т"
                    },
                    {
                        "title": "08Х18Тч"
                    },
                    {
                        "title": "08Х22Н6Т"
                    },
                    {
                        "title": "09Х15Н8Ю"
                    },
                    {
                        "title": "09Х16Н4Б"
                    },
                    {
                        "title": "09Х17Н7Ю"
                    },
                    {
                        "title": "09Х17Н7Ю1"
                    },
                    {
                        "title": "10Х14АГ15"
                    },
                    {
                        "title": "10Х14Г14Н3"
                    },
                    {
                        "title": "10Х17Н13М2Т"
                    },
                    {
                        "title": "10Х17Н13М3Т"
                    },
                    {
                        "title": "12Х17Г9АН4"
                    },
                    {
                        "title": "12Х18Н10Е"
                    },
                    {
                        "title": "12Х21Н5Т"
                    },
                    {
                        "title": "15Х17АГ14"
                    },
                    {
                        "title": "15Х18Н12С4ТЮ"
                    },
                    {
                        "title": "17Х18Н9"
                    },
                    {
                        "title": "20Х13Н4Г9"
                    },
                    {
                        "title": "20Х17Н2"
                    },
                    {
                        "title": "25Х13Н2"
                    },
                    {
                        "title": "30Х10Г10"
                    },
                    {
                        "title": "95Х18"
                    }
                ]
            }
        ]
    },
    {
        "title": "Титан, сплав титана",
        "submenu": [
            {
                "title": "Титан технический",
                "submenu": [
                    {
                        "title": "ВТ1-0"
                    },
                    {
                        "title": "ВТ1-00"
                    },
                    {
                        "title": "ВТ1-1"
                    }
                ]
            },
            {
                "title": "Титановая губка",
                "submenu": [
                    {
                        "title": "ТГ-100"
                    },
                    {
                        "title": "ТГ-110"
                    },
                    {
                        "title": "ТГ-120"
                    },
                    {
                        "title": "ТГ-130"
                    },
                    {
                        "title": "ТГ-150"
                    },
                    {
                        "title": "ТГ-90"
                    },
                    {
                        "title": "ТГ-Тв"
                    }
                ]
            },
            {
                "title": "Титановый деформируемый сплав",
                "submenu": [
                    {
                        "title": "АТ-6"
                    },
                    {
                        "title": "ВТ14"
                    },
                    {
                        "title": "ВТ15"
                    },
                    {
                        "title": "ВТ16"
                    },
                    {
                        "title": "ВТ20"
                    },
                    {
                        "title": "ВТ22"
                    },
                    {
                        "title": "ВТ23"
                    },
                    {
                        "title": "ВТ3-1"
                    },
                    {
                        "title": "ВТ5"
                    },
                    {
                        "title": "ВТ5-1"
                    },
                    {
                        "title": "ВТ6"
                    },
                    {
                        "title": "ВТ6С"
                    },
                    {
                        "title": "ВТ9"
                    },
                    {
                        "title": "ОТ4"
                    },
                    {
                        "title": "ОТ4-0"
                    },
                    {
                        "title": "ОТ4-1"
                    },
                    {
                        "title": "ПТ3В"
                    },
                    {
                        "title": "ПТ7М"
                    },
                    {
                        "title": "ТС6"
                    }
                ]
            },
            {
                "title": "Титановый литейный сплав",
                "submenu": [
                    {
                        "title": "ВТ14Л"
                    },
                    {
                        "title": "ВТ1Л"
                    },
                    {
                        "title": "ВТ20Л"
                    },
                    {
                        "title": "ВТ21Л"
                    },
                    {
                        "title": "ВТ3-1Л"
                    },
                    {
                        "title": "ВТ5Л"
                    },
                    {
                        "title": "ВТ6Л"
                    },
                    {
                        "title": "ВТ9Л"
                    }
                ]
            }
        ]
    },
    {
        "title": "Цинк, сплав цинка",
        "submenu": [
            {
                "title": "Цинк первичный",
                "submenu": [
                    {
                        "title": "Ц0"
                    },
                    {
                        "title": "Ц0А"
                    },
                    {
                        "title": "Ц1"
                    },
                    {
                        "title": "Ц1С"
                    },
                    {
                        "title": "Ц2"
                    },
                    {
                        "title": "Ц2С"
                    },
                    {
                        "title": "Ц3"
                    },
                    {
                        "title": "Ц3С"
                    },
                    {
                        "title": "ЦВ"
                    },
                    {
                        "title": "ЦВ0"
                    },
                    {
                        "title": "ЦВ00"
                    }
                ]
            },
            {
                "title": "Цинковый  деформируемый сплав",
                "submenu": [
                    {
                        "title": "ЦАМ0.2-4"
                    },
                    {
                        "title": "ЦАМ10-2"
                    },
                    {
                        "title": "ЦАМ15"
                    },
                    {
                        "title": "ЦАМ2-5"
                    },
                    {
                        "title": "ЦМ1"
                    }
                ]
            },
            {
                "title": "Цинковый антифрикционный сплав",
                "submenu": [
                    {
                        "title": "ЦАМ9-1.5"
                    },
                    {
                        "title": "Цам10-5"
                    }
                ]
            },
            {
                "title": "Цинковый литейный сплав",
                "submenu": [
                    {
                        "title": "ЦА4"
                    },
                    {
                        "title": "ЦАМ4-1"
                    }
                ]
            }
        ]
    },
    {
        "title": "Чугун",
        "submenu": [
            {
                "title": "Чугун антифрикционный",
                "submenu": [
                    {
                        "title": "АЧВ-1"
                    },
                    {
                        "title": "АЧВ-2"
                    },
                    {
                        "title": "АЧК-1"
                    },
                    {
                        "title": "АЧК-2"
                    },
                    {
                        "title": "АЧС-1"
                    },
                    {
                        "title": "АЧС-2"
                    },
                    {
                        "title": "АЧС-3"
                    },
                    {
                        "title": "АЧС-4"
                    },
                    {
                        "title": "АЧС-5"
                    },
                    {
                        "title": "АЧС-6"
                    }
                ]
            },
            {
                "title": "Чугун высоколегированный",
                "submenu": [
                    {
                        "title": "ЧГ6С3Ш"
                    },
                    {
                        "title": "ЧГ7Х4"
                    },
                    {
                        "title": "ЧГ8Д3"
                    },
                    {
                        "title": "ЧН11Г7Ш"
                    },
                    {
                        "title": "ЧН15Д3Ш"
                    },
                    {
                        "title": "ЧН15Д7"
                    },
                    {
                        "title": "ЧН19Х3Ш"
                    },
                    {
                        "title": "ЧН20Д2Ш"
                    },
                    {
                        "title": "ЧН4Х2"
                    },
                    {
                        "title": "ЧС13"
                    },
                    {
                        "title": "ЧС15"
                    },
                    {
                        "title": "ЧС15М4"
                    },
                    {
                        "title": "ЧС17"
                    },
                    {
                        "title": "ЧС17М3"
                    },
                    {
                        "title": "ЧХ16"
                    },
                    {
                        "title": "ЧХ16М2"
                    },
                    {
                        "title": "ЧХ22"
                    },
                    {
                        "title": "ЧХ22С"
                    },
                    {
                        "title": "ЧХ28"
                    },
                    {
                        "title": "ЧХ28Д2"
                    },
                    {
                        "title": "ЧХ28П"
                    },
                    {
                        "title": "ЧХ32"
                    },
                    {
                        "title": "ЧХ9Н5"
                    },
                    {
                        "title": "ЧЮ22Ш"
                    },
                    {
                        "title": "ЧЮ30"
                    },
                    {
                        "title": "ЧЮ6С5"
                    },
                    {
                        "title": "ЧЮ7Х2"
                    }
                ]
            },
            {
                "title": "Чугун высоконикелевый",
                "submenu": [
                    {
                        "title": "L-Ni35"
                    },
                    {
                        "title": "L-NiCr20-2"
                    },
                    {
                        "title": "L-NiCr20-3"
                    },
                    {
                        "title": "L-NiCr30-3"
                    },
                    {
                        "title": "L-NiCuCr15-6-2"
                    },
                    {
                        "title": "L-NiCuCr15-6-3"
                    },
                    {
                        "title": "L-NiMn13-7"
                    },
                    {
                        "title": "L-NiSiCr20-5-3"
                    },
                    {
                        "title": "L-NiSiCr30-5-5"
                    },
                    {
                        "title": "S-Ni22"
                    },
                    {
                        "title": "S-Ni35"
                    },
                    {
                        "title": "S-NiCr20-2"
                    },
                    {
                        "title": "S-NiCr20-3"
                    },
                    {
                        "title": "S-NiCr30-1"
                    },
                    {
                        "title": "S-NiCr30-3"
                    },
                    {
                        "title": "S-NiCr35-3"
                    },
                    {
                        "title": "S-NiMn13-7"
                    },
                    {
                        "title": "S-NiMn23-4"
                    },
                    {
                        "title": "S-NiSiCr20-5-2"
                    },
                    {
                        "title": "S-NiSiCr30-5-5"
                    }
                ]
            },
            {
                "title": "Чугун ковкий",
                "submenu": [
                    {
                        "title": "КЧ30-6"
                    },
                    {
                        "title": "КЧ33-8"
                    },
                    {
                        "title": "КЧ35-10"
                    },
                    {
                        "title": "КЧ37-12"
                    },
                    {
                        "title": "КЧ45-7"
                    },
                    {
                        "title": "КЧ50-5"
                    },
                    {
                        "title": "КЧ55-4"
                    },
                    {
                        "title": "КЧ60-3"
                    },
                    {
                        "title": "КЧ65-3"
                    },
                    {
                        "title": "КЧ70-2"
                    },
                    {
                        "title": "КЧ80-1.5"
                    }
                ]
            },
            {
                "title": "Чугун литейный",
                "submenu": [
                    {
                        "title": "Л1"
                    },
                    {
                        "title": "Л2"
                    },
                    {
                        "title": "Л3"
                    },
                    {
                        "title": "Л4"
                    },
                    {
                        "title": "Л5"
                    },
                    {
                        "title": "Л6"
                    },
                    {
                        "title": "ЛР1"
                    },
                    {
                        "title": "ЛР2"
                    },
                    {
                        "title": "ЛР3"
                    },
                    {
                        "title": "ЛР4"
                    },
                    {
                        "title": "ЛР5"
                    },
                    {
                        "title": "ЛР6"
                    },
                    {
                        "title": "ЛР7"
                    }
                ]
            },
            {
                "title": "Чугун низколегированный",
                "submenu": [
                    {
                        "title": "ЧН2Х"
                    },
                    {
                        "title": "ЧН3ХМДШ"
                    },
                    {
                        "title": "ЧНМШ"
                    },
                    {
                        "title": "ЧНХМД"
                    },
                    {
                        "title": "ЧНХМДШ"
                    },
                    {
                        "title": "ЧНХТ"
                    },
                    {
                        "title": "ЧС5"
                    },
                    {
                        "title": "ЧС5Ш"
                    },
                    {
                        "title": "ЧХ1"
                    },
                    {
                        "title": "ЧХ2"
                    },
                    {
                        "title": "ЧХ3"
                    },
                    {
                        "title": "ЧХ3Т"
                    },
                    {
                        "title": "ЧЮХШ"
                    }
                ]
            },
            {
                "title": "Чугун передельный",
                "submenu": [
                    {
                        "title": "П1"
                    },
                    {
                        "title": "П2"
                    },
                    {
                        "title": "ПВК1"
                    },
                    {
                        "title": "ПВК2"
                    },
                    {
                        "title": "ПВК3"
                    },
                    {
                        "title": "ПЛ1"
                    },
                    {
                        "title": "ПЛ2"
                    },
                    {
                        "title": "ПФ1"
                    },
                    {
                        "title": "ПФ2"
                    },
                    {
                        "title": "ПФ3"
                    }
                ]
            },
            {
                "title": "Чугун с вермикулярным графитом для отливок",
                "submenu": [
                    {
                        "title": "ЧВГ30"
                    },
                    {
                        "title": "ЧВГ35"
                    },
                    {
                        "title": "ЧВГ40"
                    },
                    {
                        "title": "ЧВГ45"
                    }
                ]
            },
            {
                "title": "Чугун с шаровидным графитом",
                "submenu": [
                    {
                        "title": "ВЧ100"
                    },
                    {
                        "title": "ВЧ35"
                    },
                    {
                        "title": "ВЧ40"
                    },
                    {
                        "title": "ВЧ45"
                    },
                    {
                        "title": "ВЧ50"
                    },
                    {
                        "title": "ВЧ60"
                    },
                    {
                        "title": "ВЧ70"
                    },
                    {
                        "title": "ВЧ80"
                    }
                ]
            },
            {
                "title": "Чугун серый",
                "submenu": [
                    {
                        "title": "СЧ10"
                    },
                    {
                        "title": "СЧ15"
                    },
                    {
                        "title": "СЧ20"
                    },
                    {
                        "title": "СЧ25"
                    },
                    {
                        "title": "СЧ30"
                    },
                    {
                        "title": "СЧ35"
                    }
                ]
            }
        ]
    },
];
