import React, {useState} from 'react';
import styled from "styled-components";
import {useSetRecoilState} from "recoil";
import {authState} from "../state/atoms";

import ReCAPTCHA from "react-google-recaptcha";
import Navbar from "../components/Navbar/Navbar";


const LoginPage = () => {
    const setAuth = useSetRecoilState(authState);

    const [login, setLogin] = useState('');
    const [pass, setPass] = useState('');
    const [err, setErr] = useState('');
    const [captchaValue, setCaptchaValue] = useState(false);  //swap

    const onChange = (value) => {
        setCaptchaValue(value);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            login,
            pass
        }
        if (captchaValue) {
            try {
                setErr('');
                const res = await fetch('/api/auth/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                });
                const resJson = await res.json();
                if (res.status === 200) {
                    localStorage.setItem('authToken', resJson.token);
                    setAuth(true);
                }
                else {
                    setErr(resJson.message);
                    setPass('');
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            setErr('Необходимо пройти капчу.')
        }

    }

    return (
        <>
        <Navbar/>
        <Wrapper>
            <LoginWrapper>
                <Title>
                    Вход
                </Title>
                {err.length !== 0 && <Error>{err}</Error>}
                <form onSubmit={(e) => handleSubmit(e)}>
                <InputWrapper>
                    <p>Логин</p>
                    <StyledInput placeholder='Введите логин' type={'text'} required value={login} onChange={(e)=> {
                        setLogin(e.target.value);
                        setErr('');
                    }}/>
                </InputWrapper>
                <InputWrapper>
                    <p>Пароль</p>
                    <StyledInput placeholder='Введите пароль' type={'password'} required value={pass} onChange={(e)=> {
                        setPass(e.target.value);
                        setErr('');
                    }}/>
                </InputWrapper>
                <ReCAPTCHA
                    sitekey="6Ldth_cpAAAAAEMPgfGJYx4hRXn-R_x_DxP4AUdw"
                    onChange={onChange}
                />


                <LogBtn>Войти</LogBtn>
                </form>
            </LoginWrapper>
        </Wrapper>
        </>
    );
};

export default LoginPage;
const Error = styled.div`
  color: #fa6161;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
`
const Title = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
`
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media screen and (max-width: 960px){
    height: calc(100vh - 92px);
    padding: 0 12px;
  }
  
`
const LoginWrapper = styled.div`
  background: #070707;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 24px;
  gap:16px;
  align-items: center;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  width: 100%;
  
`
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: #fff;
    }
`
const StyledInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 8px;
  background: none;
  border: 1px solid #afacac;
  color: #fff;
`
const LogBtn = styled.button`
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #070707;
  background: #fff;
  text-align: center;
  border-radius: 16px;
  padding: 12px;
  width: 250px;
  margin-top: 16px;
  transition: all 0.4s ease;
  border: none;
  font-family: 'Roboto', sans-serif;
  &:hover {
    background: rgb(110, 110, 110);
  }
`