import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Roboto";
  src: url("https://db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.eot");
  src: url("https://db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/0033a2fbc0329740fb86c1cfd134cb94.svg#Roboto")format("svg");
}
@font-face {        
  font-family: "Aeonik";
  src: url("https://db.onlinewebfonts.com/t/d95bf2f30035a050142565e03d44da71.eot");
  src: url("https://db.onlinewebfonts.com/t/d95bf2f30035a050142565e03d44da71.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/d95bf2f30035a050142565e03d44da71.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/d95bf2f30035a050142565e03d44da71.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/d95bf2f30035a050142565e03d44da71.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/d95bf2f30035a050142565e03d44da71.svg#Aeonik")format("svg");
}
@font-face {
  font-family: "Aeonik Medium";
  src: url("https://db.onlinewebfonts.com/t/757ff6da05e4f945c71c9751b485a9ad.eot");
  src: url("https://db.onlinewebfonts.com/t/757ff6da05e4f945c71c9751b485a9ad.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/757ff6da05e4f945c71c9751b485a9ad.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/757ff6da05e4f945c71c9751b485a9ad.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/757ff6da05e4f945c71c9751b485a9ad.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/757ff6da05e4f945c71c9751b485a9ad.svg#Aeonik Medium")format("svg");
}
@font-face {
  font-family: "Aeonik Bold";
  src: url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.eot");
  src: url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/362636484f8ad521fec5a297fdc0ab12.svg#Aeonik Pro Bold")format("svg");
}
@font-face {
  font-family: "Aeonik Light";
  src: url("https://db.onlinewebfonts.com/t/c6da2799f8877386e9a261e8744b2885.eot");
  src: url("https://db.onlinewebfonts.com/t/c6da2799f8877386e9a261e8744b2885.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/c6da2799f8877386e9a261e8744b2885.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/c6da2799f8877386e9a261e8744b2885.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/c6da2799f8877386e9a261e8744b2885.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/c6da2799f8877386e9a261e8744b2885.svg#Aeonik Pro Light")format("svg");
}
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    -webkit-text-size-adjust: 100%;
    font-feature-settings: normal;
    background: #F0F0F2;
  }
`;

export default GlobalStyle;