import {atom} from "recoil";

export const selectedCategoryId = atom({
    key: 'cat_id',
    default: null,
});
export const filterModal = atom({
    key: 'filter',
    default: false,
});
export const isMenuWrap = atom({
    key: 'isMenuWrapped',
    default: false,
});

export const allFilters = atom({
    key: 'filters',
    default: {},
});

export const loadDom = atom({
    key: 'load',
    default: true,
});
export const authState = atom({
    key: 'auth',
    default: false,
});
export const openTreeState = atom({
    key: 'openSubmenusState',
    default: [],
  });