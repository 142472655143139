import React, {useEffect, useState} from 'react';
import Category from "../components/Category/Category";
import {category} from '../data/data'
import styled from "styled-components";
import {useRecoilValue} from "recoil";
import { selectedCategoryId} from "../state/atoms";
import Preloader from "../components/Preloader/Preloader";
import TableComponents from "../components/Table/Table";
import Logout from "../utils/Logout";
import Navbar from "../components/Navbar/Navbar";
import menu from "../assets/menu.svg";
import logoWhite from "../assets/logo_white.svg";

const TablePage = () => {

    const catId = useRecoilValue(selectedCategoryId);
    const [load, setLoad] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        // Определение асинхронной функции внутри useEffect
        const fetchData = async () => {
            if (catId) {
                setLoad(true);
                try {
                    const response = await fetch(`/api/table/${catId}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                        }
                    });
                    if (response.status === 403) {
                        Logout();
                    }
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    if (response.status === 201) {
                        setTableData([]);
                    }
                    const data = await response.json();
                    setTableData(data);
                } catch (error) {
                    console.log(error);
                } finally {
                    setTimeout(()=>{
                        setLoad(false);
                    }, 700)
                }
            }
        }
        fetchData();
    }, [catId]);

    return (
        <>
            <Navbar/>
            <Wrapper>
                <Category menuData={category} show={showMenu} setShowMenu={setShowMenu}/>
                <TableWrapper>
                    <MobileNav>
                        <img src={menu} alt="menu" onClick={()=> setShowMenu(true)}/>
                        <img src={logoWhite} alt="logo"/>
                    </MobileNav>
                    {catId ?
                        (load ?
                            <Preloader/>
                            :
                            <TableComponents data={tableData}/>)
                        :
                        <WelcomeText/>
                    }
                </TableWrapper>
            </Wrapper>
        </>
    );
};

const WelcomeText = () => {
    return (
         <div style={{display: 'flex', flexDirection: 'column',  alignItems: 'center', height: '100%', paddingTop: '32px', fontFamily: 'Roboto, sans-serif'}}>
             <StyledP>Выберите необходимый раздел, чтобы приступить к использованию</StyledP>
         </div>
    );
};


export default TablePage;

const StyledP = styled.p`
  font-weight: 500;
  font-size: 22px;
  display: flex;
  align-items: center;
  gap: 24px;
  color: #363636;
`

const TableWrapper = styled.div`
  width: 100%;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 0 20px;
 @media screen and (max-width: 1370px){
    padding: 0 16px;
  }
  @media screen and (max-width: 960px){
    padding: 40px 12px 96px;
  }
`
const Wrapper = styled.div`
    display: flex;
  width: 100%;
`
const MobileNav = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: black;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  align-items: center;
  justify-content: center;
  z-index: 998;
  img:first-child {
    position: absolute;
    left: 20px;
  }
  @media screen and (max-width: 960px){
    display: flex;
  }
`