export const koefs = {
    "Черный металлопрокат": {
        "Арматура А1": [
            {
                "size": 6,
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": "6.5",
                "koef": "0.00026",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.000395",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.000616",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000888",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001208",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.001998",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.002466",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.002984",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.003853",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.004834",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.005549",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.006313",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.00799",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.009865",
                "metrs": 1
            }
        ],
        "Арматура А3": [
            {
                "size": 4,
                "koef": "0.000099",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.000154",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": "7.5",
                "koef": "0.000347",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.000395",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.000616",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000888",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001208",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.001998",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.002466",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.002984",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.003853",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.004834",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.006313",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.00799",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.009865",
                "metrs": 1
            }
        ],
        "Арматура Ат800": [
            {
                "size": 10,
                "koef": "0.000616",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000888",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001208",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.001578",
                "metrs": 1
            }
        ],
        "Арматура Ат1000": [
            {
                "size": 10,
                "koef": "-",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "-",
                "metrs": 1
            }
        ],
        "Балка двутавровая": [
            {
                "size": 10,
                "koef": "0.00946",
                "metrs": 1
            },
            {
                "size": "10Б1",
                "koef": "0.0081",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.0115",
                "metrs": 1
            },
            {
                "size": "12Б1",
                "koef": "0.0087",
                "metrs": 1
            },
            {
                "size": "12Б2",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.0137",
                "metrs": 1
            },
            {
                "size": "14Б1",
                "koef": "0.0105",
                "metrs": 1
            },
            {
                "size": "14Б2",
                "koef": "0.0129",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "16Б1",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": "16Б2",
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.0184",
                "metrs": 1
            },
            {
                "size": "18Б1",
                "koef": "0.0154",
                "metrs": 1
            },
            {
                "size": "18Б2",
                "koef": "0.0188",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": "20Б1",
                "koef": "0.0213",
                "metrs": 1
            },
            {
                "size": "20Б2",
                "koef": "0.0253",
                "metrs": 1
            },
            {
                "size": "20К1",
                "koef": "0.0414",
                "metrs": 1
            },
            {
                "size": "20К2",
                "koef": "0.0499",
                "metrs": 1
            },
            {
                "size": "20К3",
                "koef": "0.0578",
                "metrs": 1
            },
            {
                "size": "20Ш0",
                "koef": "0.0244",
                "metrs": 1
            },
            {
                "size": "20Ш1",
                "koef": "0.0306",
                "metrs": 1
            },
            {
                "size": "20Ш2",
                "koef": "0.0388",
                "metrs": 1
            },
            {
                "size": "20Ш3",
                "koef": "0.047",
                "metrs": 1
            },
            {
                "size": "24М",
                "koef": "0.0383",
                "metrs": 1
            },
            {
                "size": "25Б1",
                "koef": "0.0257",
                "metrs": 1
            },
            {
                "size": "25Б2",
                "koef": "0.0296",
                "metrs": 1
            },
            {
                "size": "25Б3",
                "koef": "0.0374",
                "metrs": 1
            },
            {
                "size": "25К1",
                "koef": "0.0626",
                "metrs": 1
            },
            {
                "size": "25К2",
                "koef": "0.0724",
                "metrs": 1
            },
            {
                "size": "25К3",
                "koef": "0.0802",
                "metrs": 1
            },
            {
                "size": "25К4",
                "koef": "0.0901",
                "metrs": 1
            },
            {
                "size": "25К5",
                "koef": "0.103",
                "metrs": 1
            },
            {
                "size": "25К6",
                "koef": "0.1155",
                "metrs": 1
            },
            {
                "size": "25Ш0",
                "koef": "0.0368",
                "metrs": 1
            },
            {
                "size": "25Ш1",
                "koef": "0.0442",
                "metrs": 1
            },
            {
                "size": "25Ш2",
                "koef": "0.0538",
                "metrs": 1
            },
            {
                "size": "25Ш3",
                "koef": "0.0673",
                "metrs": 1
            },
            {
                "size": "25Ш4",
                "koef": "0.0844",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.0365",
                "metrs": 1
            },
            {
                "size": "30Б1",
                "koef": "0.032",
                "metrs": 1
            },
            {
                "size": "30Б2",
                "koef": "0.0367",
                "metrs": 1
            },
            {
                "size": "30К1",
                "koef": "0.087",
                "metrs": 1
            },
            {
                "size": "30К2",
                "koef": "0.094",
                "metrs": 1
            },
            {
                "size": "30К3",
                "koef": "0.1058",
                "metrs": 1
            },
            {
                "size": "30К4",
                "koef": "0.1058",
                "metrs": 1
            },
            {
                "size": "30К5",
                "koef": "0.1174",
                "metrs": 1
            },
            {
                "size": "30М",
                "koef": "0.0502",
                "metrs": 1
            },
            {
                "size": "30Ш1",
                "koef": "0.0568",
                "metrs": 1
            },
            {
                "size": "30Ш2",
                "koef": "0.0686",
                "metrs": 1
            },
            {
                "size": "30Ш3",
                "koef": "0.0829",
                "metrs": 1
            },
            {
                "size": 33,
                "koef": "0.0422",
                "metrs": 1
            },
            {
                "size": "35Б1",
                "koef": "0.0414",
                "metrs": 1
            },
            {
                "size": "35Б2",
                "koef": "0.0496",
                "metrs": 1
            },
            {
                "size": "35К1",
                "koef": "0.1091",
                "metrs": 1
            },
            {
                "size": "35К2",
                "koef": "0.1365",
                "metrs": 1
            },
            {
                "size": "35К3",
                "koef": "0.1542",
                "metrs": 1
            },
            {
                "size": "35Ш1",
                "koef": "0.0653",
                "metrs": 1
            },
            {
                "size": "35Ш2",
                "koef": "0.0797",
                "metrs": 1
            },
            {
                "size": "35Ш3",
                "koef": "0.0989",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.0486",
                "metrs": 1
            },
            {
                "size": "36М",
                "koef": "0.0579",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.057",
                "metrs": 1
            },
            {
                "size": "40Б1",
                "koef": "0.0566",
                "metrs": 1
            },
            {
                "size": "40Б2",
                "koef": "0.066",
                "metrs": 1
            },
            {
                "size": "40Б4",
                "koef": "0.0943",
                "metrs": 1
            },
            {
                "size": "40К1",
                "koef": "0.1467",
                "metrs": 1
            },
            {
                "size": "40К2",
                "koef": "0.1717",
                "metrs": 1
            },
            {
                "size": "40К3",
                "koef": "0.2001",
                "metrs": 1
            },
            {
                "size": "40К4",
                "koef": "0.2319",
                "metrs": 1
            },
            {
                "size": "40К5",
                "koef": "0.2908",
                "metrs": 1
            },
            {
                "size": "40Ш1",
                "koef": "0.0886",
                "metrs": 1
            },
            {
                "size": "40Ш2",
                "koef": "0.1067",
                "metrs": 1
            },
            {
                "size": "40Ш3",
                "koef": "0.1294",
                "metrs": 1
            },
            {
                "size": "40Ш4",
                "koef": "0.1586",
                "metrs": 1
            },
            {
                "size": "40Ш5",
                "koef": "0.198",
                "metrs": 1
            },
            {
                "size": "40Ш6",
                "koef": "0.2381",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.0665",
                "metrs": 1
            },
            {
                "size": "45Б1",
                "koef": "0.0662",
                "metrs": 1
            },
            {
                "size": "45Б2",
                "koef": "0.076",
                "metrs": 1
            },
            {
                "size": "45М",
                "koef": "0.0776",
                "metrs": 1
            },
            {
                "size": "45Ш1",
                "koef": "0.1236",
                "metrs": 1
            },
            {
                "size": "45Ш2",
                "koef": "0.1447",
                "metrs": 1
            },
            {
                "size": "45Ш3",
                "koef": "0.166",
                "metrs": 1
            },
            {
                "size": "50Б1",
                "koef": "0.0725",
                "metrs": 1
            },
            {
                "size": "50Б2",
                "koef": "0.0795",
                "metrs": 1
            },
            {
                "size": "50Б3",
                "koef": "0.0897",
                "metrs": 1
            },
            {
                "size": "50Ш1",
                "koef": "0.1142",
                "metrs": 1
            },
            {
                "size": "50Ш2",
                "koef": "0.1384",
                "metrs": 1
            },
            {
                "size": "50Ш3",
                "koef": "0.1561",
                "metrs": 1
            },
            {
                "size": "50Ш4",
                "koef": "0.1738",
                "metrs": 1
            },
            {
                "size": "50Ш5",
                "koef": "0.2047",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.0926",
                "metrs": 1
            },
            {
                "size": "55Б1",
                "koef": "0.089",
                "metrs": 1
            },
            {
                "size": "55Б2",
                "koef": "0.0979",
                "metrs": 1
            },
            {
                "size": "55Б3",
                "koef": "0.1167",
                "metrs": 1
            },
            {
                "size": "60Б1",
                "koef": "0.0946",
                "metrs": 1
            },
            {
                "size": "60Б2",
                "koef": "0.1055",
                "metrs": 1
            },
            {
                "size": "60Ш1",
                "koef": "0.137",
                "metrs": 1
            },
            {
                "size": "60Ш2",
                "koef": "0.1707",
                "metrs": 1
            },
            {
                "size": "60Ш3",
                "koef": "0.1981",
                "metrs": 1
            },
            {
                "size": "60Ш4",
                "koef": "0.2256",
                "metrs": 1
            },
            {
                "size": "70Б0",
                "koef": "0.1201",
                "metrs": 1
            },
            {
                "size": "70Б1",
                "koef": "0.1293",
                "metrs": 1
            },
            {
                "size": "70Б2",
                "koef": "0.1442",
                "metrs": 1
            },
            {
                "size": "70Б3",
                "koef": "0.1651",
                "metrs": 1
            },
            {
                "size": "70Б4",
                "koef": "0.1948",
                "metrs": 1
            },
            {
                "size": "70Ш1",
                "koef": "0.166",
                "metrs": 1
            },
            {
                "size": "70Ш2",
                "koef": "0.1904",
                "metrs": 1
            },
            {
                "size": "70Ш3",
                "koef": "0.2269",
                "metrs": 1
            },
            {
                "size": "70Ш4",
                "koef": "0.2586",
                "metrs": 1
            },
            {
                "size": "70Ш5",
                "koef": "0.2949",
                "metrs": 1
            },
            {
                "size": "80Б1",
                "koef": "0.1595",
                "metrs": 1
            },
            {
                "size": "80Б2",
                "koef": "0.1779",
                "metrs": 1
            },
            {
                "size": "80Ш1",
                "koef": "0.1646",
                "metrs": 1
            },
            {
                "size": "80Ш2",
                "koef": "0.1911",
                "metrs": 1
            },
            {
                "size": "90Б1",
                "koef": "0.194",
                "metrs": 1
            },
            {
                "size": "90Б2",
                "koef": "0.2138",
                "metrs": 1
            },
            {
                "size": "90Ш1",
                "koef": "0.1915",
                "metrs": 1
            },
            {
                "size": "90Ш2",
                "koef": "0.2126",
                "metrs": 1
            },
            {
                "size": "100Б1",
                "koef": "0.2306",
                "metrs": 1
            },
            {
                "size": "100Б2",
                "koef": "0.2582",
                "metrs": 1
            },
            {
                "size": "100Б3",
                "koef": "0.2857",
                "metrs": 1
            },
            {
                "size": "100Б4",
                "koef": "0.3145",
                "metrs": 1
            },
            {
                "size": "100Ш1",
                "koef": "0.2306",
                "metrs": 1
            },
            {
                "size": "100Ш2",
                "koef": "0.2582",
                "metrs": 1
            },
            {
                "size": "100Ш3",
                "koef": "0.2857",
                "metrs": 1
            },
            {
                "size": "100Ш4",
                "koef": "0.3145",
                "metrs": 1
            }
        ],
        "Балка б/у": [
            {
                "size": 10,
                "koef": "0.00946",
                "metrs": 1
            },
            {
                "size": " 10Б1",
                "koef": "0.0081",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.0115",
                "metrs": 1
            },
            {
                "size": " 12Б1",
                "koef": "0.0087",
                "metrs": 1
            },
            {
                "size": " 12Б2",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.0137",
                "metrs": 1
            },
            {
                "size": " 14Б1",
                "koef": "0.0105",
                "metrs": 1
            },
            {
                "size": " 14Б2",
                "koef": "0.0129",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": " 16Б1",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": " 16Б2",
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.0184",
                "metrs": 1
            },
            {
                "size": " 18Б1",
                "koef": "0.0154",
                "metrs": 1
            },
            {
                "size": " 18Б2",
                "koef": "0.0188",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": " 20Б1",
                "koef": "0.0213",
                "metrs": 1
            },
            {
                "size": " 20Б2",
                "koef": "0.0253",
                "metrs": 1
            },
            {
                "size": " 20К1\n",
                "koef": "0.0414",
                "metrs": 1
            },
            {
                "size": " 20К2",
                "koef": "0.0499",
                "metrs": 1
            },
            {
                "size": " 20Ш1",
                "koef": "0.0306",
                "metrs": 1
            },
            {
                "size": " 20Ш2",
                "koef": "0.0388",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.024",
                "metrs": 1
            },
            {
                "size": " 23Б1",
                "koef": "0.0258",
                "metrs": 1
            },
            {
                "size": " 23К1",
                "koef": "0.0522",
                "metrs": 1
            },
            {
                "size": " 23Ш1",
                "koef": "0.0362",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.0273",
                "metrs": 1
            },
            {
                "size": " 24М",
                "koef": "0.0383",
                "metrs": 1
            },
            {
                "size": " 25Б1",
                "koef": "0.0257",
                "metrs": 1
            },
            {
                "size": " 25Б2",
                "koef": "0.0296",
                "metrs": 1
            },
            {
                "size": " 25К1",
                "koef": "0.0626",
                "metrs": 1
            },
            {
                "size": " 25К2",
                "koef": "0.0724",
                "metrs": 1
            },
            {
                "size": " 25К3",
                "koef": "0.0802",
                "metrs": 1
            },
            {
                "size": " 25Ш1",
                "koef": "0.0442",
                "metrs": 1
            },
            {
                "size": " 26Б1",
                "koef": "0.026",
                "metrs": 1
            },
            {
                "size": " 26Ш1",
                "koef": "0.0427",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0.0315",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.0365",
                "metrs": 1
            },
            {
                "size": " 30Б1",
                "koef": "0.032",
                "metrs": 1
            },
            {
                "size": " 30Б2",
                "koef": "0.0367",
                "metrs": 1
            },
            {
                "size": " 30К1",
                "koef": "0.087",
                "metrs": 1
            },
            {
                "size": " 30К2",
                "koef": "0.094",
                "metrs": 1
            },
            {
                "size": " 30К3",
                "koef": "0.1058",
                "metrs": 1
            },
            {
                "size": " 30М",
                "koef": "0.0502",
                "metrs": 1
            },
            {
                "size": " 30Ш1",
                "koef": "0.0568",
                "metrs": 1
            },
            {
                "size": " 30Ш2",
                "koef": "0.0686",
                "metrs": 1
            },
            {
                "size": " 35Б1",
                "koef": "0.0414",
                "metrs": 1
            },
            {
                "size": " 35Б2",
                "koef": "0.0496",
                "metrs": 1
            },
            {
                "size": " 35К1",
                "koef": "0.1091",
                "metrs": 1
            },
            {
                "size": " 35К2",
                "koef": "0.1365",
                "metrs": 1
            },
            {
                "size": " 35К3",
                "koef": "0.1542",
                "metrs": 1
            },
            {
                "size": " 35Ш1",
                "koef": "0.0653",
                "metrs": 1
            },
            {
                "size": " 35Ш2",
                "koef": "0.0797",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.0486",
                "metrs": 1
            },
            {
                "size": " 36М",
                "koef": "0.0579",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.057",
                "metrs": 1
            },
            {
                "size": " 40Б1",
                "koef": "0.0566",
                "metrs": 1
            },
            {
                "size": " 40Б2",
                "koef": "0.066",
                "metrs": 1
            },
            {
                "size": " 40К1",
                "koef": "0.1467",
                "metrs": 1
            },
            {
                "size": " 40К2",
                "koef": "0.1717",
                "metrs": 1
            },
            {
                "size": " 40Ш1",
                "koef": "0.0886",
                "metrs": 1
            },
            {
                "size": " 40Ш2",
                "koef": "0.1067",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.0665",
                "metrs": 1
            },
            {
                "size": " 45Б1",
                "koef": "0.0662",
                "metrs": 1
            },
            {
                "size": " 45Б2",
                "koef": "0.076",
                "metrs": 1
            },
            {
                "size": " 45М",
                "koef": "0.0776",
                "metrs": 1
            },
            {
                "size": " 45Ш1",
                "koef": "0.1236",
                "metrs": 1
            },
            {
                "size": " 45Ш2",
                "koef": "0.1447",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.0785",
                "metrs": 1
            },
            {
                "size": " 50Б1",
                "koef": "0.0725",
                "metrs": 1
            },
            {
                "size": " 50Б2",
                "koef": "0.0795",
                "metrs": 1
            },
            {
                "size": " 50Ш1",
                "koef": "0.1142",
                "metrs": 1
            },
            {
                "size": " 50Ш2",
                "koef": "0.1384",
                "metrs": 1
            },
            {
                "size": " 50Ш3",
                "koef": "0.1561",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.0926",
                "metrs": 1
            },
            {
                "size": " 55Б1",
                "koef": "0.089",
                "metrs": 1
            },
            {
                "size": " 55Б2",
                "koef": "0.0979",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.108",
                "metrs": 1
            },
            {
                "size": " 60Б1",
                "koef": "0.0946",
                "metrs": 1
            },
            {
                "size": " 60Б2",
                "koef": "0.1055",
                "metrs": 1
            },
            {
                "size": " 60Ш1",
                "koef": "0.137",
                "metrs": 1
            },
            {
                "size": " 60Ш2",
                "koef": "0.1707",
                "metrs": 1
            },
            {
                "size": " 60Ш3",
                "koef": "0.1981",
                "metrs": 1
            },
            {
                "size": " 60Ш4",
                "koef": "0.2256",
                "metrs": 1
            },
            {
                "size": " 61У1А",
                "koef": "0.082",
                "metrs": 1
            },
            {
                "size": " 70Б1",
                "koef": "0.1293",
                "metrs": 1
            },
            {
                "size": " 70Б2",
                "koef": "0.1442",
                "metrs": 1
            },
            {
                "size": " 70Ш1",
                "koef": "0.166",
                "metrs": 1
            },
            {
                "size": " 70Ш2",
                "koef": "0.1904",
                "metrs": 1
            },
            {
                "size": " 70Ш3",
                "koef": "0.2269",
                "metrs": 1
            },
            {
                "size": " 80Б1",
                "koef": "0.1595",
                "metrs": 1
            },
            {
                "size": " 90Б1",
                "koef": "0.194",
                "metrs": 1
            },
            {
                "size": " 100Б1",
                "koef": "0.2306",
                "metrs": 1
            },
            {
                "size": " 100Б2",
                "koef": "0.2582",
                "metrs": 1
            }
        ],
        "Канат": [
            {
                "size": "0.9",
                "koef": "0.0000043",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.0000056",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.0000116",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.0000207",
                "metrs": 1
            },
            {
                "size": "2.4",
                "koef": "0.0000294",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0.0000314",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.0000465",
                "metrs": 1
            },
            {
                "size": "3.1",
                "koef": "0.0000492",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0.0000512",
                "metrs": 1
            },
            {
                "size": "3.6",
                "koef": "0.0000641",
                "metrs": 1
            },
            {
                "size": "3.7",
                "koef": "0.0000705",
                "metrs": 1
            },
            {
                "size": "3.8",
                "koef": "0.0000551",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.0000812",
                "metrs": 1
            },
            {
                "size": "4.1",
                "koef": "0.0000641",
                "metrs": 1
            },
            {
                "size": "4.2",
                "koef": "0.0000896",
                "metrs": 1
            },
            {
                "size": "4.5",
                "koef": "0.0000978",
                "metrs": 1
            },
            {
                "size": "4.6",
                "koef": "0.0001096",
                "metrs": 1
            },
            {
                "size": "4.7",
                "koef": "0.0000877",
                "metrs": 1
            },
            {
                "size": "4.8",
                "koef": "0.0001135",
                "metrs": 1
            },
            {
                "size": "4.9",
                "koef": "0.0001246",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.0001293",
                "metrs": 1
            },
            {
                "size": "5.1",
                "koef": "0.0000955",
                "metrs": 1
            },
            {
                "size": "5.6",
                "koef": "0.0001596",
                "metrs": 1
            },
            {
                "size": "5.8",
                "koef": "0.0001365",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.0001755",
                "metrs": 1
            },
            {
                "size": "6.2",
                "koef": "0.0001416",
                "metrs": 1
            },
            {
                "size": "6.3",
                "koef": "0.000134",
                "metrs": 1
            },
            {
                "size": "6.4",
                "koef": "0.0001975",
                "metrs": 1
            },
            {
                "size": "6.5",
                "koef": "0.0001425",
                "metrs": 1
            },
            {
                "size": "6.7",
                "koef": "0.0001535",
                "metrs": 1
            },
            {
                "size": "6.9",
                "koef": "0.0001766",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.0002484",
                "metrs": 1
            },
            {
                "size": "7.6",
                "koef": "0.000211",
                "metrs": 1
            },
            {
                "size": "7.8",
                "koef": "0.0003004",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.0003091",
                "metrs": 1
            },
            {
                "size": "8.1",
                "koef": "0.00033",
                "metrs": 1
            },
            {
                "size": "8.3",
                "koef": "0.000256",
                "metrs": 1
            },
            {
                "size": "8.6",
                "koef": "0.0003821",
                "metrs": 1
            },
            {
                "size": "8.8",
                "koef": "0.0002936",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0.0002735",
                "metrs": 1
            },
            {
                "size": "9.1",
                "koef": "0.000305",
                "metrs": 1
            },
            {
                "size": "9.5",
                "koef": "0.000436",
                "metrs": 1
            },
            {
                "size": "9.6",
                "koef": "0.0003586",
                "metrs": 1
            },
            {
                "size": "9.7",
                "koef": "0.000335",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.000519",
                "metrs": 1
            },
            {
                "size": "10.5",
                "koef": "0.000562",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0.0004616",
                "metrs": 1
            },
            {
                "size": "11.5",
                "koef": "0.0007005",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000527",
                "metrs": 1
            },
            {
                "size": "12.5",
                "koef": "0.000806",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0.0005966",
                "metrs": 1
            },
            {
                "size": "13.5",
                "koef": "0.0007635",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.000728",
                "metrs": 1
            },
            {
                "size": "14.5",
                "koef": "0.0007455",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.000844",
                "metrs": 1
            },
            {
                "size": "15.5",
                "koef": "0,0012",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0,00132",
                "metrs": 1
            },
            {
                "size": "16.5",
                "koef": "0,001025",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0,00149",
                "metrs": 1
            },
            {
                "size": "17.5",
                "koef": "0,00107",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0,00122",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0,001855",
                "metrs": 1
            },
            {
                "size": "19.5",
                "koef": "0,001405",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0,001955",
                "metrs": 1
            },
            {
                "size": "20.5",
                "koef": "0,00155",
                "metrs": 1
            },
            {
                "size": 21,
                "koef": "0,001635",
                "metrs": 1
            },
            {
                "size": "21.5",
                "koef": "0,00167",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0,002065",
                "metrs": 1
            },
            {
                "size": "22.5",
                "koef": "0,00185",
                "metrs": 1
            },
            {
                "size": 23,
                "koef": "0,001915",
                "metrs": 1
            },
            {
                "size": "23.5",
                "koef": "0,002275",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0,00211",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0,002245",
                "metrs": 1
            },
            {
                "size": "25.5",
                "koef": "0,00239",
                "metrs": 1
            },
            {
                "size": "26.5",
                "koef": "0,002135",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0,002685",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0,00291",
                "metrs": 1
            },
            {
                "size": 29,
                "koef": "0,0029685",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0,00399",
                "metrs": 1
            },
            {
                "size": "30.5",
                "koef": "0,00349",
                "metrs": 1
            },
            {
                "size": 31,
                "koef": "0,00403",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0,003845",
                "metrs": 1
            },
            {
                "size": "32.5",
                "koef": "0,00399",
                "metrs": 1
            },
            {
                "size": 33,
                "koef": "0,003905",
                "metrs": 1
            },
            {
                "size": "33.5",
                "koef": "0,00422",
                "metrs": 1
            },
            {
                "size": "36.5",
                "koef": "0,004965",
                "metrs": 1
            },
            {
                "size": 37,
                "koef": "0,005015",
                "metrs": 1
            },
            {
                "size": 39,
                "koef": "0,005475",
                "metrs": 1
            },
            {
                "size": "39.5",
                "koef": "0,00574",
                "metrs": 1
            },
            {
                "size": 42,
                "koef": "0,006535",
                "metrs": 1
            },
            {
                "size": "45.5",
                "koef": "0,008605",
                "metrs": 1
            },
            {
                "size": "50.5",
                "koef": "0,00994",
                "metrs": 1
            },
            {
                "size": 52,
                "koef": "0,00991",
                "metrs": 1
            }
        ],
        "Канат арматурный": [
            {
                "size": 12,
                "koef": "0,000736",
                "metrs": 1
            },
            {
                "size": "12.5",
                "koef": "0,0007263",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0,0001099",
                "metrs": 1
            },
            {
                "size": "15.2",
                "koef": "0,001086",
                "metrs": 1
            }
        ],
        "Канат оцинкованный": [
            {
                "size": "0.65",
                "koef": "0,0000024",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0,0000033",
                "metrs": 1
            },
            {
                "size": "0.9",
                "koef": "0,0000043",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0,0000056",
                "metrs": 1
            },
            {
                "size": "1.1",
                "koef": "0,0000062",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0,0000079",
                "metrs": 1
            },
            {
                "size": "1.4",
                "koef": "0,00001",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0,0000116",
                "metrs": 1
            },
            {
                "size": "1.6",
                "koef": "0,0000132",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0,000016",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0,0000207",
                "metrs": 1
            },
            {
                "size": "2.2",
                "koef": "0,0000237",
                "metrs": 1
            },
            {
                "size": "2.4",
                "koef": "0,0000294",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0,0000314",
                "metrs": 1
            },
            {
                "size": "2.9",
                "koef": "0,0000298",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0,0000465",
                "metrs": 1
            },
            {
                "size": "3.1",
                "koef": "0,0000492",
                "metrs": 1
            },
            {
                "size": "3.2",
                "koef": "0,000054",
                "metrs": 1
            },
            {
                "size": "3.3",
                "koef": "0,0000546",
                "metrs": 1
            },
            {
                "size": "3.4",
                "koef": "0,0000594",
                "metrs": 1
            },
            {
                "size": "3.6",
                "koef": "0,0000641",
                "metrs": 1
            },
            {
                "size": "3.9",
                "koef": "0,000051",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0,0000812",
                "metrs": 1
            },
            {
                "size": "4.1",
                "koef": "0,0000641",
                "metrs": 1
            },
            {
                "size": "4.5",
                "koef": "0,0000978",
                "metrs": 1
            },
            {
                "size": "4.6",
                "koef": "0,0001096",
                "metrs": 1
            },
            {
                "size": "4.8",
                "koef": "0,0001135",
                "metrs": 1
            },
            {
                "size": "4.9",
                "koef": "0,0001246",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0,0001293",
                "metrs": 1
            },
            {
                "size": "5.1",
                "koef": "0,0000955",
                "metrs": 1
            },
            {
                "size": "5.2",
                "koef": "0,0001405",
                "metrs": 1
            },
            {
                "size": "5.6",
                "koef": "0,0001596",
                "metrs": 1
            },
            {
                "size": "5.8",
                "koef": "0,0001365",
                "metrs": 1
            },
            {
                "size": "5.9",
                "koef": "0,0001422",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0,0001755",
                "metrs": 1
            },
            {
                "size": "6.1",
                "koef": "0,000186",
                "metrs": 1
            },
            {
                "size": "6.2",
                "koef": "0,0001416",
                "metrs": 1
            },
            {
                "size": "6.4",
                "koef": "0,0001975",
                "metrs": 1
            },
            {
                "size": "6.5",
                "koef": "0,0001425",
                "metrs": 1
            },
            {
                "size": "6.8",
                "koef": "0,000238",
                "metrs": 1
            },
            {
                "size": "6.9",
                "koef": "0,0001766",
                "metrs": 1
            },
            {
                "size": "7.4",
                "koef": "0,0002826",
                "metrs": 1
            },
            {
                "size": "7.5",
                "koef": "0,000277",
                "metrs": 1
            },
            {
                "size": "7.6",
                "koef": "0,000211",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0,0003091",
                "metrs": 1
            },
            {
                "size": "8.1",
                "koef": "0,00033",
                "metrs": 1
            },
            {
                "size": "8.2",
                "koef": "0,000288",
                "metrs": 1
            },
            {
                "size": "8.3",
                "koef": "0,000256",
                "metrs": 1
            },
            {
                "size": "8.6",
                "koef": "0,0003821",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0,0002735",
                "metrs": 1
            },
            {
                "size": "9.1",
                "koef": "0,000305",
                "metrs": 1
            },
            {
                "size": "9.2",
                "koef": "0,0004385",
                "metrs": 1
            },
            {
                "size": "9.5",
                "koef": "0,000436",
                "metrs": 1
            },
            {
                "size": "9.6",
                "koef": "0,0003586",
                "metrs": 1
            },
            {
                "size": "9.7",
                "koef": "0,000335",
                "metrs": 1
            },
            {
                "size": "9.9",
                "koef": "0,000488",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0,000519",
                "metrs": 1
            },
            {
                "size": "10.5",
                "koef": "0,000562",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0,0004616",
                "metrs": 1
            },
            {
                "size": "11.5",
                "koef": "0,0007005",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0,000527",
                "metrs": 1
            },
            {
                "size": "12.5",
                "koef": "0,000806",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0,0005966",
                "metrs": 1
            },
            {
                "size": "13.5",
                "koef": "0,0007635",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0,000728",
                "metrs": 1
            },
            {
                "size": "14.5",
                "koef": "0,0007455",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0,000844",
                "metrs": 1
            },
            {
                "size": "15.5",
                "koef": "0,0012",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0,00132",
                "metrs": 1
            },
            {
                "size": "16.5",
                "koef": "0,001025",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0,00149",
                "metrs": 1
            },
            {
                "size": "17.5",
                "koef": "0,00107",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0,00122",
                "metrs": 1
            },
            {
                "size": "19.5",
                "koef": "0,001405",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0,001955",
                "metrs": 1
            },
            {
                "size": 21,
                "koef": "0,001635",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0,002065",
                "metrs": 1
            },
            {
                "size": 23,
                "koef": "0,001915",
                "metrs": 1
            },
            {
                "size": "23.5",
                "koef": "0,002275",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0,00211",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0,002245",
                "metrs": 1
            },
            {
                "size": "25.5",
                "koef": "0,00239",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0,00555",
                "metrs": 1
            },
            {
                "size": "36.5",
                "koef": "0,004965",
                "metrs": 1
            }
        ],
        "Катанка": [
            {
                "size": "5.5",
                "koef": "0,000186",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0,000222",
                "metrs": 1
            },
            {
                "size": "6.5",
                "koef": "0,00026",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0,000395",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0,000499",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0,000616",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0,000888",
                "metrs": 1
            }
        ],
        "Квадрат": [
            {
                "size": 4,
                "koef": "0,000126",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0,000196",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0,000283",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0,000385",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0,000502",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0,000785",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0,00113",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0,001539",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0,00201",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0,002269",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0,002543",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0,002834",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0,00314",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0,003799",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0,004522",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0,004906",
                "metrs": 1
            },
            {
                "size": 26,
                "koef": "0,005307",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0,005723",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0,006154",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0,007065",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0,008038",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0,01256",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0,0159",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0,01963",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0,02826",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0,03847",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0,05024",
                "metrs": 1
            },
            {
                "size": 85,
                "koef": "0,05672",
                "metrs": 1
            },
            {
                "size": 125,
                "koef": "0,1227",
                "metrs": 1
            },
            {
                "size": 130,
                "koef": "0,1327",
                "metrs": 1
            },
            {
                "size": 160,
                "koef": "0,201",
                "metrs": 1
            },
            {
                "size": 180,
                "koef": "0,2543",
                "metrs": 1
            },
            {
                "size": 190,
                "koef": "0,2834",
                "metrs": 1
            },
            {
                "size": 200,
                "koef": "0,314",
                "metrs": 1
            },
            {
                "size": 210,
                "koef": "0,3462",
                "metrs": 1
            },
            {
                "size": 220,
                "koef": "0,3799",
                "metrs": 1
            },
            {
                "size": 250,
                "koef": "0,4906",
                "metrs": 1
            },
            {
                "size": 260,
                "koef": "0,5307",
                "metrs": 1
            },
            {
                "size": 300,
                "koef": "0,7065",
                "metrs": 1
            },
            {
                "size": 350,
                "koef": "0,9616",
                "metrs": 1
            },
            {
                "size": 400,
                "koef": "1,256",
                "metrs": 1
            },
            {
                "size": 450,
                "koef": "1,59",
                "metrs": 1
            },
            {
                "size": 500,
                "koef": "1,963",
                "metrs": 1
            }
        ],
        "Круг": [
            {
                "size": 2,
                "koef": "0,000025",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0,000038",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0,000055",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0,000075",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0,000099",
                "metrs": 1
            },
            {
                "size": "4.5",
                "koef": "0,000125",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0,000154",
                "metrs": 1
            },
            {
                "size": "5.5",
                "koef": "0,000186",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0,000222",
                "metrs": 1
            },
            {
                "size": "6.5",
                "koef": "0,00026",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0,000302",
                "metrs": 1
            },
            {
                "size": "7.5",
                "koef": "0,000347",
                "metrs": 1
            },
            {
                "size": "7.8",
                "koef": "0,000375",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0,000395",
                "metrs": 1
            },
            {
                "size": "8.5",
                "koef": "0,000445",
                "metrs": 1
            },
            {
                "size": "8.8",
                "koef": "0,000477",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0,000499",
                "metrs": 1
            },
            {
                "size": "9.5",
                "koef": "0,000556",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0,000616",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0,000746",
                "metrs": 1
            },
            {
                "size": "11.2",
                "koef": "0,000773",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0,000888",
                "metrs": 1
            },
            {
                "size": "12.3",
                "koef": "0,000933",
                "metrs": 1
            },
            {
                "size": "12.5",
                "koef": "0,000963",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0,001042",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0,001208",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0,001387",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0,001578",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0,001782",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0,001998",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0,002226",
                "metrs": 1
            },
            {
                "size": "19.5",
                "koef": "0,002344",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0,002466",
                "metrs": 1
            },
            {
                "size": 21,
                "koef": "0,002719",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0,002984",
                "metrs": 1
            },
            {
                "size": 23,
                "koef": "0,003261",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0,003551",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0,003853",
                "metrs": 1
            },
            {
                "size": 26,
                "koef": "0,004168",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0,004495",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0,004834",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0,005549",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0,006313",
                "metrs": 1
            },
            {
                "size": 33,
                "koef": "0,006714",
                "metrs": 1
            },
            {
                "size": 34,
                "koef": "0,007127",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0,007553",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0,00799",
                "metrs": 1
            },
            {
                "size": 38,
                "koef": "0.008903",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0,009865",
                "metrs": 1
            },
            {
                "size": 41,
                "koef": "0,01036",
                "metrs": 1
            },
            {
                "size": 42,
                "koef": "0,01088",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0,01248",
                "metrs": 1
            },
            {
                "size": 46,
                "koef": "0,01305",
                "metrs": 1
            },
            {
                "size": 48,
                "koef": "0,01421",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0,01541",
                "metrs": 1
            },
            {
                "size": 52,
                "koef": "0,01667",
                "metrs": 1
            },
            {
                "size": 53,
                "koef": "0,01732",
                "metrs": 1
            },
            {
                "size": 54,
                "koef": "0,01798",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0,01865",
                "metrs": 1
            },
            {
                "size": 56,
                "koef": "0,01934",
                "metrs": 1
            },
            {
                "size": 58,
                "koef": "0,02074",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0,02219",
                "metrs": 1
            },
            {
                "size": 62,
                "koef": "0,0237",
                "metrs": 1
            },
            {
                "size": 63,
                "koef": "0,02447",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0,02605",
                "metrs": 1
            },
            {
                "size": 68,
                "koef": "0,02851",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0,03021",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0,03468",
                "metrs": 1
            },
            {
                "size": 77,
                "koef": "0,03656",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0,03946",
                "metrs": 1
            },
            {
                "size": 83,
                "koef": "0,04247",
                "metrs": 1
            },
            {
                "size": 85,
                "koef": "0,04454",
                "metrs": 1
            },
            {
                "size": 90,
                "koef": "0,04994",
                "metrs": 1
            },
            {
                "size": 95,
                "koef": "0,05564",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0,06165",
                "metrs": 1
            },
            {
                "size": 102,
                "koef": "0,06414",
                "metrs": 1
            },
            {
                "size": 105,
                "koef": "0,06797",
                "metrs": 1
            },
            {
                "size": 108,
                "koef": "0,07191",
                "metrs": 1
            },
            {
                "size": 110,
                "koef": "0,0746",
                "metrs": 1
            },
            {
                "size": 115,
                "koef": "0,08154",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "0,08878",
                "metrs": 1
            },
            {
                "size": 125,
                "koef": "0,09633",
                "metrs": 1
            },
            {
                "size": 130,
                "koef": "0,1042",
                "metrs": 1
            },
            {
                "size": 132,
                "koef": "0,1074",
                "metrs": 1
            },
            {
                "size": 135,
                "koef": "0,1124",
                "metrs": 1
            },
            {
                "size": 140,
                "koef": "0,1208",
                "metrs": 1
            },
            {
                "size": 145,
                "koef": "0,1296",
                "metrs": 1
            },
            {
                "size": 150,
                "koef": "0,1387",
                "metrs": 1
            },
            {
                "size": 155,
                "koef": "0.1481",
                "metrs": 1
            },
            {
                "size": 160,
                "koef": "0,1578",
                "metrs": 1
            },
            {
                "size": 163,
                "koef": "0,1638",
                "metrs": 1
            },
            {
                "size": 165,
                "koef": "0,1679",
                "metrs": 1
            },
            {
                "size": 170,
                "koef": "0,1782",
                "metrs": 1
            },
            {
                "size": 172,
                "koef": "0.1824",
                "metrs": 1
            },
            {
                "size": 175,
                "koef": "0,1888",
                "metrs": 1
            },
            {
                "size": 180,
                "koef": "0,1998",
                "metrs": 1
            },
            {
                "size": 182,
                "koef": "0,2042",
                "metrs": 1
            },
            {
                "size": 185,
                "koef": "0,211",
                "metrs": 1
            },
            {
                "size": 190,
                "koef": "0,2226",
                "metrs": 1
            },
            {
                "size": 200,
                "koef": "0,2466",
                "metrs": 1
            },
            {
                "size": 205,
                "koef": "0,2591",
                "metrs": 1
            },
            {
                "size": 210,
                "koef": "0,2719",
                "metrs": 1
            },
            {
                "size": 215,
                "koef": "0,285",
                "metrs": 1
            },
            {
                "size": 220,
                "koef": "0.2984",
                "metrs": 1
            },
            {
                "size": 230,
                "koef": "0,3261",
                "metrs": 1
            },
            {
                "size": 240,
                "koef": "0,3551",
                "metrs": 1
            },
            {
                "size": 245,
                "koef": "0,3701",
                "metrs": 1
            },
            {
                "size": 250,
                "koef": "0,3853",
                "metrs": 1
            },
            {
                "size": 260,
                "koef": "0,4168",
                "metrs": 1
            },
            {
                "size": 270,
                "koef": "0,4495",
                "metrs": 1
            },
            {
                "size": 280,
                "koef": "0,4834",
                "metrs": 1
            },
            {
                "size": 290,
                "koef": "0,5185",
                "metrs": 1
            },
            {
                "size": 300,
                "koef": "0,5549",
                "metrs": 1
            },
            {
                "size": 310,
                "koef": "0,5925",
                "metrs": 1
            },
            {
                "size": 320,
                "koef": "0,6313",
                "metrs": 1
            },
            {
                "size": 330,
                "koef": "0,6714",
                "metrs": 1
            },
            {
                "size": 340,
                "koef": "0,7127",
                "metrs": 1
            },
            {
                "size": 345,
                "koef": "0,7338",
                "metrs": 1
            },
            {
                "size": 350,
                "koef": "0,7553",
                "metrs": 1
            },
            {
                "size": 355,
                "koef": "0,777",
                "metrs": 1
            },
            {
                "size": 360,
                "koef": "0,799",
                "metrs": 1
            },
            {
                "size": 370,
                "koef": "0,844",
                "metrs": 1
            },
            {
                "size": 380,
                "koef": "0,8903",
                "metrs": 1
            },
            {
                "size": 390,
                "koef": "0,9378",
                "metrs": 1
            },
            {
                "size": 400,
                "koef": "0,9865",
                "metrs": 1
            },
            {
                "size": 410,
                "koef": "1,036",
                "metrs": 1
            },
            {
                "size": 415,
                "koef": "1,062",
                "metrs": 1
            },
            {
                "size": 420,
                "koef": "1,088",
                "metrs": 1
            },
            {
                "size": 430,
                "koef": "1,14",
                "metrs": 1
            },
            {
                "size": 440,
                "koef": "1,194",
                "metrs": 1
            },
            {
                "size": 450,
                "koef": "1.248",
                "metrs": 1
            },
            {
                "size": 460,
                "koef": "1.305",
                "metrs": 1
            },
            {
                "size": 470,
                "koef": "1.362",
                "metrs": 1
            },
            {
                "size": 480,
                "koef": "1.421",
                "metrs": 1
            },
            {
                "size": 490,
                "koef": "1,48",
                "metrs": 1
            },
            {
                "size": 500,
                "koef": "1,541",
                "metrs": 1
            },
            {
                "size": 520,
                "koef": "1,667",
                "metrs": 1
            },
            {
                "size": 530,
                "koef": "1,732",
                "metrs": 1
            },
            {
                "size": 540,
                "koef": "1,798",
                "metrs": 1
            },
            {
                "size": 550,
                "koef": "1,865",
                "metrs": 1
            },
            {
                "size": 560,
                "koef": "1,933",
                "metrs": 1
            },
            {
                "size": 570,
                "koef": "2,003",
                "metrs": 1
            },
            {
                "size": 580,
                "koef": "2,074",
                "metrs": 1
            },
            {
                "size": 590,
                "koef": "2,146",
                "metrs": 1
            },
            {
                "size": 595,
                "koef": "2,183",
                "metrs": 1
            },
            {
                "size": 600,
                "koef": "2,22",
                "metrs": 1
            },
            {
                "size": 650,
                "koef": "2,605",
                "metrs": 1
            },
            {
                "size": 680,
                "koef": "2,851",
                "metrs": 1
            },
            {
                "size": 700,
                "koef": "3,021",
                "metrs": 1
            },
            {
                "size": 750,
                "koef": "3,468",
                "metrs": 1
            },
            {
                "size": 880,
                "koef": "4,774",
                "metrs": 1
            }
        ],
        "Круг оцинкованный": [
            {
                "size": 6,
                "koef": "0,000229",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0,000407",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0,000634",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0,000915",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0,001244",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0,001625",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0,002058",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0,00254",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0,003074",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0,003969",
                "metrs": 1
            }
        ],
        "Лента/штрипс ": [
            {
                "size": "0.1",
                "koef": "0,000785",
                "metrs": 1
            },
            {
                "size": "0.2",
                "koef": "0,00157",
                "metrs": 1
            },
            {
                "size": "0.25",
                "koef": "0,001962",
                "metrs": 1
            },
            {
                "size": "0.28",
                "koef": "0,002198",
                "metrs": 1
            },
            {
                "size": "0.3",
                "koef": "0,002355",
                "metrs": 1
            },
            {
                "size": "0.35",
                "koef": "0,002747",
                "metrs": 1
            },
            {
                "size": "0.38",
                "koef": "0,002983",
                "metrs": 1
            },
            {
                "size": "0.4",
                "koef": "0,00314",
                "metrs": 1
            },
            {
                "size": "0.45",
                "koef": "0,003532",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0,003925",
                "metrs": 1
            },
            {
                "size": "0.55",
                "koef": "0,004318",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0,00471",
                "metrs": 1
            },
            {
                "size": "0.65",
                "koef": "0,005103",
                "metrs": 1
            },
            {
                "size": "0.68",
                "koef": "0,005338",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0,005495",
                "metrs": 1
            },
            {
                "size": "0.78",
                "koef": "0,006123",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0,00628",
                "metrs": 1
            },
            {
                "size": "0.9",
                "koef": "0,007065",
                "metrs": 1
            },
            {
                "size": "0.95",
                "koef": "0,007458",
                "metrs": 1
            },
            {
                "size": "0.97",
                "koef": "0,007615",
                "metrs": 1
            },
            {
                "size": "0.98",
                "koef": "0,007693",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0,00785",
                "metrs": 1
            },
            {
                "size": "1.1",
                "koef": "0,008635",
                "metrs": 1
            },
            {
                "size": "1.15",
                "koef": "0,009027",
                "metrs": 1
            },
            {
                "size": "1.17",
                "koef": "0,009185",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0,00942",
                "metrs": 1
            },
            {
                "size": "1.35",
                "koef": "0,0106",
                "metrs": 1
            },
            {
                "size": "1.4",
                "koef": "0,01099",
                "metrs": 1
            },
            {
                "size": "1.45",
                "koef": "0,01138",
                "metrs": 1
            },
            {
                "size": "1.47",
                "koef": "0,01154",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0,01178",
                "metrs": 1
            },
            {
                "size": "1.51",
                "koef": "0,01185",
                "metrs": 1
            },
            {
                "size": "1.78",
                "koef": "0,01397",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0,01413",
                "metrs": 1
            },
            {
                "size": "1.9",
                "koef": "0,01492",
                "metrs": 1
            },
            {
                "size": "1.95",
                "koef": "0,01531",
                "metrs": 1
            },
            {
                "size": "1.96",
                "koef": "0,01539",
                "metrs": 1
            },
            {
                "size": "1.98",
                "koef": "0,01554",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0,0157",
                "metrs": 1
            },
            {
                "size": "2.46",
                "koef": "0,01931",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0,01963",
                "metrs": 1
            },
            {
                "size": "2.8",
                "koef": "0,02198",
                "metrs": 1
            },
            {
                "size": "2.9",
                "koef": "0,02276",
                "metrs": 1
            },
            {
                "size": "2.95",
                "koef": "0,02316",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0,02355",
                "metrs": 1
            },
            {
                "size": "3.2",
                "koef": "0,02512",
                "metrs": 1
            }
        ],
        "Лента/штрипс окрашенный": [
            {
                "size": "0.3",
                "koef": "0,002455",
                "metrs": 1
            },
            {
                "size": "0.35",
                "koef": "0,002847",
                "metrs": 1
            },
            {
                "size": "0.4",
                "koef": "0,00324",
                "metrs": 1
            },
            {
                "size": "0.45",
                "koef": "0,003633",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0,004025",
                "metrs": 1
            },
            {
                "size": "0.55",
                "koef": "0,004418",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0,00481",
                "metrs": 1
            },
            {
                "size": "0.65",
                "koef": "0,005203",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0,005595",
                "metrs": 1
            },
            {
                "size": "0.75",
                "koef": "0,005987",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0,00638",
                "metrs": 1
            },
            {
                "size": "0.85",
                "koef": "0,006772",
                "metrs": 1
            },
            {
                "size": "0.9",
                "koef": "0,007165",
                "metrs": 1
            },
            {
                "size": "0.95",
                "koef": "0,007557",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0,00795",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0,01187",
                "metrs": 1
            }
        ],
        "Лента/штрипс оцинкованный": [
            {
                "size": "0.23",
                "koef": "0,001906",
                "metrs": 1
            },
            {
                "size": "0.25",
                "koef": "0,002063",
                "metrs": 1
            },
            {
                "size": "0.27",
                "koef": "0,00222",
                "metrs": 1
            },
            {
                "size": "0.3",
                "koef": "0,002455",
                "metrs": 1
            },
            {
                "size": "0.33",
                "koef": "0,002691",
                "metrs": 1
            },
            {
                "size": "0.35",
                "koef": "0,002847",
                "metrs": 1
            },
            {
                "size": "0.38",
                "koef": "0,003083",
                "metrs": 1
            },
            {
                "size": "0.4",
                "koef": "0,00324",
                "metrs": 1
            },
            {
                "size": "0.42",
                "koef": "0,003397",
                "metrs": 1
            },
            {
                "size": "0.45",
                "koef": "0,003633",
                "metrs": 1
            },
            {
                "size": "0.47",
                "koef": "0,00379",
                "metrs": 1
            },
            {
                "size": "0.48",
                "koef": "0,003868",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0,004025",
                "metrs": 1
            },
            {
                "size": "0.55",
                "koef": "0,004418",
                "metrs": 1
            },
            {
                "size": "0.57",
                "koef": "0,004574",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0,00481",
                "metrs": 1
            },
            {
                "size": "0.65",
                "koef": "0,005203",
                "metrs": 1
            },
            {
                "size": "0.67",
                "koef": "0,00536",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0,005595",
                "metrs": 1
            },
            {
                "size": "0.75",
                "koef": "0,005987",
                "metrs": 1
            },
            {
                "size": "0.79",
                "koef": "0,006302",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0,00638",
                "metrs": 1
            },
            {
                "size": "0.85",
                "koef": "0,006772",
                "metrs": 1
            },
            {
                "size": "0.86",
                "koef": "0,006851",
                "metrs": 1
            },
            {
                "size": "0.9",
                "koef": "0,007165",
                "metrs": 1
            },
            {
                "size": "0.96",
                "koef": "0,007636",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0,00795",
                "metrs": 1
            },
            {
                "size": "1.1",
                "koef": "0,008735",
                "metrs": 1
            },
            {
                "size": "1.15",
                "koef": "0,009127",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0,00952",
                "metrs": 1
            },
            {
                "size": "1.25",
                "koef": "0,009913",
                "metrs": 1
            },
            {
                "size": "1.3",
                "koef": "0,01031",
                "metrs": 1
            },
            {
                "size": "1.35",
                "koef": "0,0107",
                "metrs": 1
            },
            {
                "size": "1.37",
                "koef": "0,01085",
                "metrs": 1
            },
            {
                "size": "1.4",
                "koef": "0,01109",
                "metrs": 1
            },
            {
                "size": "1.45",
                "koef": "0,01148",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0,01187",
                "metrs": 1
            },
            {
                "size": "1.6",
                "koef": "0,01266",
                "metrs": 1
            },
            {
                "size": "1.65",
                "koef": "0,01305",
                "metrs": 1
            },
            {
                "size": "1.7",
                "koef": "0,01344",
                "metrs": 1
            },
            {
                "size": "1.75",
                "koef": "0,01384",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0,01423",
                "metrs": 1
            },
            {
                "size": "1.85",
                "koef": "0,01462",
                "metrs": 1
            },
            {
                "size": "1.9",
                "koef": "0,01501",
                "metrs": 1
            },
            {
                "size": "1.95",
                "koef": "0,01541",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0,0158",
                "metrs": 1
            },
            {
                "size": "2.1",
                "koef": "0,01659",
                "metrs": 1
            },
            {
                "size": "2.17",
                "koef": "0,01713",
                "metrs": 1
            },
            {
                "size": "2.2",
                "koef": "0,01737",
                "metrs": 1
            },
            {
                "size": "2.3",
                "koef": "0,01815",
                "metrs": 1
            },
            {
                "size": "2.35",
                "koef": "0,01855",
                "metrs": 1
            },
            {
                "size": "2.45",
                "koef": "0,01933",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0,01973",
                "metrs": 1
            },
            {
                "size": "2.52",
                "koef": "0,01988",
                "metrs": 1
            },
            {
                "size": "2.6",
                "koef": "0,02051",
                "metrs": 1
            },
            {
                "size": "2.62",
                "koef": "0,02067",
                "metrs": 1
            },
            {
                "size": "2.7",
                "koef": "0,0213",
                "metrs": 1
            },
            {
                "size": "2.8",
                "koef": "0,02208",
                "metrs": 1
            },
            {
                "size": "2.9",
                "koef": "0,02287",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0,02365",
                "metrs": 1
            },
            {
                "size": "3.2",
                "koef": "0,02522",
                "metrs": 1
            },
            {
                "size": "3.45",
                "koef": "0,02718",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0,02758",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0,0315",
                "metrs": 1
            }
        ],
        "Лист/рулон г/к": [
            {
                "size": "0.8",
                "koef": "0,00628",
                "metrs": 1
            },
            {
                "size": "1.3",
                "koef": "0,01021",
                "metrs": 1
            },
            {
                "size": "1.4",
                "koef": "0,01099",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0,01178",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0,01413",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0,0157",
                "metrs": 1
            },
            {
                "size": "2.4",
                "koef": "0,01884",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0,01963",
                "metrs": 1
            },
            {
                "size": "2.8",
                "koef": "0,02198",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0,02355",
                "metrs": 1
            },
            {
                "size": "3.2",
                "koef": "0,02512",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0,02748",
                "metrs": 1
            },
            {
                "size": "3.7",
                "koef": "0,02905",
                "metrs": 1
            },
            {
                "size": "3.75",
                "koef": "0,02944",
                "metrs": 1
            },
            {
                "size": "3.9",
                "koef": "0,03062",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0,0314",
                "metrs": 1
            },
            {
                "size": "4.3",
                "koef": "0,03375",
                "metrs": 1
            },
            {
                "size": "4.5",
                "koef": "0,03532",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0,03925",
                "metrs": 1
            },
            {
                "size": "5.5",
                "koef": "0,04318",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0,0471",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0,05495",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0,0628",
                "metrs": 1
            },
            {
                "size": "8.5",
                "koef": "0,06672",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0,07065",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0,0785",
                "metrs": 1
            },
            {
                "size": "11\n",
                "koef": "0,08635",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0,0942",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0,1021",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0,1099",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0,1178",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0,1256",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0,1413",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0,1492",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0,157",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0,1727",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0,1884",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0,1963",
                "metrs": 1
            },
            {
                "size": 26,
                "koef": "0,2041",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0,2198",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0,2355",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0,2512",
                "metrs": 1
            },
            {
                "size": 34,
                "koef": "0,2669",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0,2748",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0,2826",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0,314",
                "metrs": 1
            },
            {
                "size": 42,
                "koef": "0,3297",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0,3533",
                "metrs": 1
            },
            {
                "size": 48,
                "koef": "0,3768",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0,3925",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0,4318",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0,471",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0,5103",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0,5495",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0,5888",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0,628",
                "metrs": 1
            },
            {
                "size": 85,
                "koef": "0,6673",
                "metrs": 1
            },
            {
                "size": 90,
                "koef": "0,7065",
                "metrs": 1
            },
            {
                "size": 95,
                "koef": "0,7458",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0,785",
                "metrs": 1
            },
            {
                "size": 105,
                "koef": "0,8243",
                "metrs": 1
            },
            {
                "size": 110,
                "koef": "0,8635",
                "metrs": 1
            },
            {
                "size": 115,
                "koef": "0,9028",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "0,942",
                "metrs": 1
            },
            {
                "size": 125,
                "koef": "0,9813",
                "metrs": 1
            },
            {
                "size": 130,
                "koef": "1,021",
                "metrs": 1
            },
            {
                "size": 140,
                "koef": "1,099",
                "metrs": 1
            },
            {
                "size": 150,
                "koef": "1,178",
                "metrs": 1
            },
            {
                "size": 155,
                "koef": "1,217",
                "metrs": 1
            },
            {
                "size": 160,
                "koef": "1,256",
                "metrs": 1
            },
            {
                "size": 180,
                "koef": "1,413",
                "metrs": 1
            }
        ],
        "Лист/рулон окрашенный": [
            {
                "size": "0.25",
                "koef": "0,002063",
                "metrs": 1
            },
            {
                "size": "0.3",
                "koef": "0,002455",
                "metrs": 1
            },
            {
                "size": "0.35",
                "koef": "0,002847",
                "metrs": 1
            },
            {
                "size": "0.4",
                "koef": "0,00324",
                "metrs": 1
            },
            {
                "size": "0.45",
                "koef": "0,003633",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0,004025",
                "metrs": 1
            },
            {
                "size": "0.55",
                "koef": "0,004418",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0,00481",
                "metrs": 1
            },
            {
                "size": "0.65",
                "koef": "0.005203",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0.005595",
                "metrs": 1
            },
            {
                "size": "0.75",
                "koef": "0.005987",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.00638",
                "metrs": 1
            },
            {
                "size": "0.85",
                "koef": "0.006772",
                "metrs": 1
            },
            {
                "size": "0.9",
                "koef": "0.007165",
                "metrs": 1
            },
            {
                "size": "0.95",
                "koef": "0.007557",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.00795",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.00952",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.01187",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.0158",
                "metrs": 1
            }
        ],
        "Лист/рулон оцинкованный": [
            {
                "size": "0.25",
                "koef": "0.002063",
                "metrs": 1
            },
            {
                "size": "0.3",
                "koef": "0.002455",
                "metrs": 1
            },
            {
                "size": "0.33",
                "koef": "0.002691",
                "metrs": 1
            },
            {
                "size": "0.35",
                "koef": "0.002847",
                "metrs": 1
            },
            {
                "size": "0.38",
                "koef": "0.003083",
                "metrs": 1
            },
            {
                "size": "0.4",
                "koef": "0.00324",
                "metrs": 1
            },
            {
                "size": "0.45",
                "koef": "0.003633",
                "metrs": 1
            },
            {
                "size": "0.47",
                "koef": "0.00379",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0.004025",
                "metrs": 1
            },
            {
                "size": "0.53",
                "koef": "0.00426",
                "metrs": 1
            },
            {
                "size": "0.55",
                "koef": "0.004418",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0.00481",
                "metrs": 1
            },
            {
                "size": "0.65",
                "koef": "0.005203",
                "metrs": 1
            },
            {
                "size": "0.67",
                "koef": "0.00536",
                "metrs": 1
            },
            {
                "size": " 0,68\n",
                "koef": "0.005438",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0.005595",
                "metrs": 1
            },
            {
                "size": "0.75",
                "koef": "0.005987",
                "metrs": 1
            },
            {
                "size": "0.77",
                "koef": "0.006145",
                "metrs": 1
            },
            {
                "size": "0.78",
                "koef": "0.006223",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.00638",
                "metrs": 1
            },
            {
                "size": "0.85",
                "koef": "0.006772",
                "metrs": 1
            },
            {
                "size": "0.9",
                "koef": "0.007165",
                "metrs": 1
            },
            {
                "size": "1\n",
                "koef": "0.00795",
                "metrs": 1
            },
            {
                "size": "1.1",
                "koef": "0.008735",
                "metrs": 1
            },
            {
                "size": " 1,15\n",
                "koef": "0.009127",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.00952",
                "metrs": 1
            },
            {
                "size": " 1,25\n",
                "koef": "0.009913",
                "metrs": 1
            },
            {
                "size": "1.3",
                "koef": "0.01031",
                "metrs": 1
            },
            {
                "size": "1.35",
                "koef": "0.0107",
                "metrs": 1
            },
            {
                "size": "1.4",
                "koef": "0.01109",
                "metrs": 1
            },
            {
                "size": "1.45",
                "koef": "0.01148",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.01187",
                "metrs": 1
            },
            {
                "size": "1.6",
                "koef": "0.01266",
                "metrs": 1
            },
            {
                "size": "1.64",
                "koef": "0.01297",
                "metrs": 1
            },
            {
                "size": "1.65",
                "koef": "0.01305",
                "metrs": 1
            },
            {
                "size": "1.7",
                "koef": "0.01344",
                "metrs": 1
            },
            {
                "size": "1.75",
                "koef": "0.01384",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0.01423",
                "metrs": 1
            },
            {
                "size": "1.85",
                "koef": "0.01462",
                "metrs": 1
            },
            {
                "size": "1.9",
                "koef": "0.01501",
                "metrs": 1
            },
            {
                "size": "1.95",
                "koef": "0.01541",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": "2.3",
                "koef": "0.01815",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0.01973",
                "metrs": 1
            },
            {
                "size": "2.7",
                "koef": "0.0213",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.02365",
                "metrs": 1
            },
            {
                "size": "3.2",
                "koef": "0.02522",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0.02758",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.0315",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.0472",
                "metrs": 1
            }
        ],
        "Лист/рулон х/к": [
            {
                "size": "0.25",
                "koef": "0.001962",
                "metrs": 1
            },
            {
                "size": "0.28",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": "0,3\n",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": "0.35",
                "koef": "0.002747",
                "metrs": 1
            },
            {
                "size": "0.36",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": "0.4",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": "0.43",
                "koef": "0.003375",
                "metrs": 1
            },
            {
                "size": "0.45",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": "0.48",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": "0.49",
                "koef": "0.003846",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0.003925",
                "metrs": 1
            },
            {
                "size": "0.55",
                "koef": "0.004318",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "0.65",
                "koef": "0.005103",
                "metrs": 1
            },
            {
                "size": "0.68",
                "koef": "0.005338",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": "0.75",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": "0.78",
                "koef": "0.006123",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": "0.85",
                "koef": "0.006673",
                "metrs": 1
            },
            {
                "size": "0.9",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": "0.95",
                "koef": "0.007458",
                "metrs": 1
            },
            {
                "size": "0.97",
                "koef": "0.007615",
                "metrs": 1
            },
            {
                "size": "0.98",
                "koef": "0.007693",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": "1.1",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": "1.15",
                "koef": "0.009027",
                "metrs": 1
            },
            {
                "size": "1.17",
                "koef": "0.009185",
                "metrs": 1
            },
            {
                "size": "1.18",
                "koef": "0.009263",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "1.3",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": "1.35",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": "1.4",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": "1.45",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": "1.48",
                "koef": "0.01162",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "1,6\n",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": "1.7",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": "1.75",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": "1.78",
                "koef": "0.01397",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "1.9",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": "1,95\n",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": "1.98",
                "koef": "0.01554",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": "2.2",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": "2.45",
                "koef": "0.01923",
                "metrs": 1
            },
            {
                "size": "2.48",
                "koef": "0.01947",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": "2.7",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "2.8",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "2.95",
                "koef": "0.02316",
                "metrs": 1
            },
            {
                "size": "2.98",
                "koef": "0.02339",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "3.1",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "3.2",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "3.9",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "4.5",
                "koef": "0.03532",
                "metrs": 1
            }
        ],
        "Лист просечно-вытяжной ": [
            {
                "size": 406,
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": 408,
                "koef": "0.01924",
                "metrs": 1
            },
            {
                "size": 410,
                "koef": "0.02652",
                "metrs": 1
            },
            {
                "size": 506,
                "koef": "0.0164",
                "metrs": 1
            },
            {
                "size": 508,
                "koef": "0.0209",
                "metrs": 1
            },
            {
                "size": 510,
                "koef": "0.0247",
                "metrs": 1
            },
            {
                "size": 606,
                "koef": "0.0173",
                "metrs": 1
            },
            {
                "size": "608\n",
                "koef": "0.0219",
                "metrs": 1
            },
            {
                "size": 610,
                "koef": "0.026",
                "metrs": 1
            }
        ],
        "Лист просечно-вытяжной оцинкованный": [
            {
                "size": 406,
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": 408,
                "koef": "0.01934",
                "metrs": 1
            },
            {
                "size": 410,
                "koef": "0.02662",
                "metrs": 1
            },
            {
                "size": 506,
                "koef": "0.0165",
                "metrs": 1
            },
            {
                "size": 508,
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": 510,
                "koef": "0.0248",
                "metrs": 1
            },
            {
                "size": 606,
                "koef": "0.0174",
                "metrs": 1
            },
            {
                "size": 608,
                "koef": "0.022",
                "metrs": 1
            },
            {
                "size": 610,
                "koef": "0.0261",
                "metrs": 1
            }
        ],
        "Лист б/у": [
            {
                "size": 4,
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.05495",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.0628",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0.07065",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.0785",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0.08635",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.0942",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0.1021",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.1099",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.1178",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.1256",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.1334",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.1413",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.1492",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.157",
                "metrs": 1
            },
            {
                "size": 21,
                "koef": "0.1649",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.1727",
                "metrs": 1
            },
            {
                "size": 23,
                "koef": "0.1805",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.1884",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.1963",
                "metrs": 1
            },
            {
                "size": 26,
                "koef": "0.2041",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.2198",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.2355",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.2512",
                "metrs": 1
            },
            {
                "size": 34,
                "koef": "0.2669",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.2748",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.2826",
                "metrs": 1
            },
            {
                "size": 38,
                "koef": "0.2983",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.314",
                "metrs": 1
            }
        ],
        "Лист рифленый": [
            {
                "size": "2.5",
                "koef": "0.0201",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.0242",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.0322",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.0405",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.0485",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.0649",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.0809",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.0968",
                "metrs": 1
            }
        ],
        "Полоса": [
            {
                "size": " 1х11",
                "koef": "0.000086",
                "metrs": 1
            },
            {
                "size": " 1х110",
                "koef": "0.000863",
                "metrs": 1
            },
            {
                "size": " 1,5х10",
                "koef": "0.000118",
                "metrs": 1
            },
            {
                "size": " 1,5х20",
                "koef": "0.000235",
                "metrs": 1
            },
            {
                "size": " 1,5х22",
                "koef": "0.000259",
                "metrs": 1
            },
            {
                "size": " 1,5х35",
                "koef": "0.000412",
                "metrs": 1
            },
            {
                "size": " 1,5х36",
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": " 1,5х40",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": " 1,5х50",
                "koef": "0.000589",
                "metrs": 1
            },
            {
                "size": " 1,5х59",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": " 1,5х60",
                "koef": "0.000706",
                "metrs": 1
            },
            {
                "size": " 1,5х102",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 1,5х317",
                "koef": "0.003733",
                "metrs": 1
            },
            {
                "size": " 1,8х45",
                "koef": "0.000636",
                "metrs": 1
            },
            {
                "size": " 1,8х50",
                "koef": "0.000706",
                "metrs": 1
            },
            {
                "size": " 2х20",
                "koef": "0.000314",
                "metrs": 1
            },
            {
                "size": " 2х25",
                "koef": "0.000393",
                "metrs": 1
            },
            {
                "size": " 2х30",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": " 2х35",
                "koef": "0.00055",
                "metrs": 1
            },
            {
                "size": " 2х40",
                "koef": "0.000628",
                "metrs": 1
            },
            {
                "size": " 2х45",
                "koef": "0.000706",
                "metrs": 1
            },
            {
                "size": " 2х50",
                "koef": "0.000785",
                "metrs": 1
            },
            {
                "size": " 2х55",
                "koef": "0.000863",
                "metrs": 1
            },
            {
                "size": " 2х60",
                "koef": "0.000942",
                "metrs": 1
            },
            {
                "size": " 2х65",
                "koef": "0.00102",
                "metrs": 1
            },
            {
                "size": " 2х70",
                "koef": "0.001099",
                "metrs": 1
            },
            {
                "size": " 2х75",
                "koef": "0.001178",
                "metrs": 1
            },
            {
                "size": " 2х80",
                "koef": "0.001256",
                "metrs": 1
            },
            {
                "size": " 2х85",
                "koef": "0.001335",
                "metrs": 1
            },
            {
                "size": " 2х90",
                "koef": "0.001413",
                "metrs": 1
            },
            {
                "size": " 2х95",
                "koef": "0.001492",
                "metrs": 1
            },
            {
                "size": " 2х100",
                "koef": "0.00157",
                "metrs": 1
            },
            {
                "size": " 2х105",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 2х110",
                "koef": "0.001727",
                "metrs": 1
            },
            {
                "size": " 2х120",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": " 2х125",
                "koef": "0.001962",
                "metrs": 1
            },
            {
                "size": " 2х130",
                "koef": "0.002041",
                "metrs": 1
            },
            {
                "size": " 2х140",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": " 2х150",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": " 2х155",
                "koef": "0.002434",
                "metrs": 1
            },
            {
                "size": " 2х160",
                "koef": "0.002512",
                "metrs": 1
            },
            {
                "size": " 2х170",
                "koef": "0.002669",
                "metrs": 1
            },
            {
                "size": " 2х180",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": " 2х190",
                "koef": "0.002983",
                "metrs": 1
            },
            {
                "size": " 2х200",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": " 2х210",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 2х220",
                "koef": "0.003454",
                "metrs": 1
            },
            {
                "size": " 2х230",
                "koef": "0.003611",
                "metrs": 1
            },
            {
                "size": " 2х240",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": " 2х250",
                "koef": "0.003925",
                "metrs": 1
            },
            {
                "size": " 2х300",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 2х350",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": " 2х400",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 2х450",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": " 2х500",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 2,5х24",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": " 2,5х25,2",
                "koef": "0.000495",
                "metrs": 1
            },
            {
                "size": " 2,5х30",
                "koef": "0.000589",
                "metrs": 1
            },
            {
                "size": " 2,5х32",
                "koef": "0.000628",
                "metrs": 1
            },
            {
                "size": " 2,5х35",
                "koef": "0.000687",
                "metrs": 1
            },
            {
                "size": " 2,5х40",
                "koef": "0.000785",
                "metrs": 1
            },
            {
                "size": " 2,5х50",
                "koef": "0.000981",
                "metrs": 1
            },
            {
                "size": " 2,5х55",
                "koef": "0.001079",
                "metrs": 1
            },
            {
                "size": " 2,5х60",
                "koef": "0.001178",
                "metrs": 1
            },
            {
                "size": " 2,5х65",
                "koef": "0.001276",
                "metrs": 1
            },
            {
                "size": " 2,5х100",
                "koef": "0.001962",
                "metrs": 1
            },
            {
                "size": " 3х20",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": " 3х25",
                "koef": "0.000589",
                "metrs": 1
            },
            {
                "size": " 3х30",
                "koef": "0.000706",
                "metrs": 1
            },
            {
                "size": " 3х35",
                "koef": "0.000824",
                "metrs": 1
            },
            {
                "size": " 3х40",
                "koef": "0.000942",
                "metrs": 1
            },
            {
                "size": " 3х45",
                "koef": "0.00106",
                "metrs": 1
            },
            {
                "size": " 3х50",
                "koef": "0.001178",
                "metrs": 1
            },
            {
                "size": " 3х55",
                "koef": "0.001295",
                "metrs": 1
            },
            {
                "size": " 3х60",
                "koef": "0.001413",
                "metrs": 1
            },
            {
                "size": " 3х65",
                "koef": "0.001531",
                "metrs": 1
            },
            {
                "size": " 3х70",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 3х75",
                "koef": "0.001766",
                "metrs": 1
            },
            {
                "size": " 3х80",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": " 3х85",
                "koef": "0.002002",
                "metrs": 1
            },
            {
                "size": " 3х90",
                "koef": "0.00212",
                "metrs": 1
            },
            {
                "size": " 3х95",
                "koef": "0.002237",
                "metrs": 1
            },
            {
                "size": " 3х100",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": " 3х110",
                "koef": "0.002591",
                "metrs": 1
            },
            {
                "size": " 3х120",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": " 3х130",
                "koef": "0.003062",
                "metrs": 1
            },
            {
                "size": " 3х140",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 3х150",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": " 3х160",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": " 3х170",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": " 3х180",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": " 3х190",
                "koef": "0.004474",
                "metrs": 1
            },
            {
                "size": " 3х200",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 3х210",
                "koef": "0.004946",
                "metrs": 1
            },
            {
                "size": " 3х220",
                "koef": "0.005181",
                "metrs": 1
            },
            {
                "size": " 3х230",
                "koef": "0.005416",
                "metrs": 1
            },
            {
                "size": " 3х240",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": " 3х250",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": " 3х300",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": " 3х350",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": " 3х400",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 3х500",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": " 3,2х60",
                "koef": "0.001507",
                "metrs": 1
            },
            {
                "size": " 3,5х50",
                "koef": "0.001374",
                "metrs": 1
            },
            {
                "size": " 4х12",
                "koef": "0.000377",
                "metrs": 1
            },
            {
                "size": " 4х16",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": " 4х17",
                "koef": "0.000534",
                "metrs": 1
            },
            {
                "size": " 4х20",
                "koef": "0.000628",
                "metrs": 1
            },
            {
                "size": " 4х25",
                "koef": "0.000785",
                "metrs": 1
            },
            {
                "size": " 4х30",
                "koef": "0.000942",
                "metrs": 1
            },
            {
                "size": " 4х35",
                "koef": "0.001099",
                "metrs": 1
            },
            {
                "size": " 4х36",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": " 4х40",
                "koef": "0.001256",
                "metrs": 1
            },
            {
                "size": " 4х45",
                "koef": "0.001413",
                "metrs": 1
            },
            {
                "size": " 4х50",
                "koef": "0.00157",
                "metrs": 1
            },
            {
                "size": " 4х55",
                "koef": "0.001727",
                "metrs": 1
            },
            {
                "size": " 4х60",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": " 4х65",
                "koef": "0.002041",
                "metrs": 1
            },
            {
                "size": " 4х70",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": " 4х75",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": " 4х80",
                "koef": "0.002512",
                "metrs": 1
            },
            {
                "size": " 4х85",
                "koef": "0.002669",
                "metrs": 1
            },
            {
                "size": " 4х90",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": " 4х95",
                "koef": "0.002983",
                "metrs": 1
            },
            {
                "size": " 4х100",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": " 4х110",
                "koef": "0.003454",
                "metrs": 1
            },
            {
                "size": " 4х111",
                "koef": "0.003485",
                "metrs": 1
            },
            {
                "size": " 4х120",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": " 4х130",
                "koef": "0.004082",
                "metrs": 1
            },
            {
                "size": " 4х140",
                "koef": "0.004396",
                "metrs": 1
            },
            {
                "size": " 4х150",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 4х160",
                "koef": "0.005024",
                "metrs": 1
            },
            {
                "size": " 4х170",
                "koef": "0.005338",
                "metrs": 1
            },
            {
                "size": " 4х176",
                "koef": "0.005526",
                "metrs": 1
            },
            {
                "size": " 4х180",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": " 4х186",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": " 4х190",
                "koef": "0.005966",
                "metrs": 1
            },
            {
                "size": " 4х200",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 4х210",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": " 4х220",
                "koef": "0.006908",
                "metrs": 1
            },
            {
                "size": " 4х230",
                "koef": "0.007222",
                "metrs": 1
            },
            {
                "size": " 4х240",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": " 4х250",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 4х260",
                "koef": "0.008164",
                "metrs": 1
            },
            {
                "size": " 4х270",
                "koef": "0.008478",
                "metrs": 1
            },
            {
                "size": " 4х280",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": " 4х290",
                "koef": "0.009106",
                "metrs": 1
            },
            {
                "size": " 4х300",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 4х350",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": " 4х380",
                "koef": "0.01193",
                "metrs": 1
            },
            {
                "size": " 4х400",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": " 4х450",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 4х500",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 4,5х70",
                "koef": "0.002473",
                "metrs": 1
            },
            {
                "size": " 5х20",
                "koef": "0.000785",
                "metrs": 1
            },
            {
                "size": " 5х25",
                "koef": "0.000981",
                "metrs": 1
            },
            {
                "size": " 5х30",
                "koef": "0.001178",
                "metrs": 1
            },
            {
                "size": " 5х35",
                "koef": "0.001374",
                "metrs": 1
            },
            {
                "size": " 5х36",
                "koef": "0.001413",
                "metrs": 1
            },
            {
                "size": " 5х40",
                "koef": "0.00157",
                "metrs": 1
            },
            {
                "size": " 5х45",
                "koef": "0.001766",
                "metrs": 1
            },
            {
                "size": " 5х50",
                "koef": "0.001962",
                "metrs": 1
            },
            {
                "size": " 5х55",
                "koef": "0.002159",
                "metrs": 1
            },
            {
                "size": " 5х60",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": " 5х65",
                "koef": "0.002551",
                "metrs": 1
            },
            {
                "size": " 5х70",
                "koef": "0.002747",
                "metrs": 1
            },
            {
                "size": " 5х75",
                "koef": "0.002944",
                "metrs": 1
            },
            {
                "size": " 5х80",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": " 5х85",
                "koef": "0.003336",
                "metrs": 1
            },
            {
                "size": " 5х90",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": " 5х95",
                "koef": "0.003729",
                "metrs": 1
            },
            {
                "size": " 5х100",
                "koef": "0.003925",
                "metrs": 1
            },
            {
                "size": " 5х110",
                "koef": "0.004318",
                "metrs": 1
            },
            {
                "size": " 5х115",
                "koef": "0.004514",
                "metrs": 1
            },
            {
                "size": " 5х120",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 5х130",
                "koef": "0.005103",
                "metrs": 1
            },
            {
                "size": " 5х140",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": " 5х150",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": " 5х160",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 5х170",
                "koef": "0.006673",
                "metrs": 1
            },
            {
                "size": " 5х180",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": " 5х190",
                "koef": "0.007458",
                "metrs": 1
            },
            {
                "size": " 5х200",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 5х210",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": " 5х220",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": " 5х230",
                "koef": "0.009027",
                "metrs": 1
            },
            {
                "size": " 5х240",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 5х250",
                "koef": "0.009813",
                "metrs": 1
            },
            {
                "size": " 5х260",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": " 5х270",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": " 5х280",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": " 5х290",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": " 5х300",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": " 5х350",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": " 5х400",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 5х450",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": " 5х500",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": " 6х10",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": " 6х12",
                "koef": "0.000565",
                "metrs": 1
            },
            {
                "size": " 6х20",
                "koef": "0.000942",
                "metrs": 1
            },
            {
                "size": " 6х21,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 6х22",
                "koef": "0.001036",
                "metrs": 1
            },
            {
                "size": " 6х24",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": " 6х25",
                "koef": "0.001178",
                "metrs": 1
            },
            {
                "size": " 6х30",
                "koef": "0.001413",
                "metrs": 1
            },
            {
                "size": " 6х36",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": " 6х40",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": " 6х45",
                "koef": "0.00212",
                "metrs": 1
            },
            {
                "size": " 6х50",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": " 6х55",
                "koef": "0.002591",
                "metrs": 1
            },
            {
                "size": " 6х60",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": " 6х65",
                "koef": "0.003062",
                "metrs": 1
            },
            {
                "size": " 6х70",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 6х75",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": " 6х80",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": " 6х85",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": " 6х90",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": " 6х95",
                "koef": "0.004474",
                "metrs": 1
            },
            {
                "size": " 6х96",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": " 6х100",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 6х110",
                "koef": "0.005181",
                "metrs": 1
            },
            {
                "size": " 6х114",
                "koef": "0.005369",
                "metrs": 1
            },
            {
                "size": " 6х120",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": " 6х125",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": " 6х130",
                "koef": "0.006123",
                "metrs": 1
            },
            {
                "size": " 6х140",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": " 6х150",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": " 6х160",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": " 6х165",
                "koef": "0.007772",
                "metrs": 1
            },
            {
                "size": " 6х170",
                "koef": "0.008007",
                "metrs": 1
            },
            {
                "size": " 6х175",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": " 6х180",
                "koef": "0.008478",
                "metrs": 1
            },
            {
                "size": " 6х190",
                "koef": "0.008949",
                "metrs": 1
            },
            {
                "size": " 6х200",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 6х210",
                "koef": "0.009891",
                "metrs": 1
            },
            {
                "size": " 6х220",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": " 6х230",
                "koef": "0.01083",
                "metrs": 1
            },
            {
                "size": " 6х240",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": " 6х250",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": " 6х260",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": " 6х270",
                "koef": "0.01272",
                "metrs": 1
            },
            {
                "size": " 6х280",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": " 6х290",
                "koef": "0.01366",
                "metrs": 1
            },
            {
                "size": " 6х300",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 6х350",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": " 6х400",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 6х450",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": " 6х500",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 7х8",
                "koef": "0.00044",
                "metrs": 1
            },
            {
                "size": " 7х20",
                "koef": "0.001099",
                "metrs": 1
            },
            {
                "size": " 7х24",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 7х30",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 7х40",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": " 7х50",
                "koef": "0.002747",
                "metrs": 1
            },
            {
                "size": " 7х60",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 7х70",
                "koef": "0.003846",
                "metrs": 1
            },
            {
                "size": " 7х75",
                "koef": "0.004121",
                "metrs": 1
            },
            {
                "size": " 7х80",
                "koef": "0.004396",
                "metrs": 1
            },
            {
                "size": " 7х85",
                "koef": "0.004671",
                "metrs": 1
            },
            {
                "size": " 7х90",
                "koef": "0.004946",
                "metrs": 1
            },
            {
                "size": " 7х95",
                "koef": "0.00522",
                "metrs": 1
            },
            {
                "size": " 7х100",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": " 7х110",
                "koef": "0.006045",
                "metrs": 1
            },
            {
                "size": " 7х120",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": " 7х130",
                "koef": "0.007144",
                "metrs": 1
            },
            {
                "size": " 7х140",
                "koef": "0.007693",
                "metrs": 1
            },
            {
                "size": " 7х150",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": " 7х160",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": " 7х170",
                "koef": "0.009342",
                "metrs": 1
            },
            {
                "size": " 7х180",
                "koef": "0.009891",
                "metrs": 1
            },
            {
                "size": " 7х190",
                "koef": "0.01044",
                "metrs": 1
            },
            {
                "size": " 7х200",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": " 7х210",
                "koef": "0.01154",
                "metrs": 1
            },
            {
                "size": " 7х220",
                "koef": "0.01209",
                "metrs": 1
            },
            {
                "size": " 7х230",
                "koef": "0.01264",
                "metrs": 1
            },
            {
                "size": " 7х240",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": " 7х250",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": " 7х300",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": " 7х500",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": " 8х10",
                "koef": "0.000628",
                "metrs": 1
            },
            {
                "size": " 8х12",
                "koef": "0.000754",
                "metrs": 1
            },
            {
                "size": " 8х16",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 8х18",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": " 8х20",
                "koef": "0.001256",
                "metrs": 1
            },
            {
                "size": " 8х25",
                "koef": "0.00157",
                "metrs": 1
            },
            {
                "size": " 8х30",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": " 8х35",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": " 8х36",
                "koef": "0.002261",
                "metrs": 1
            },
            {
                "size": " 8х40",
                "koef": "0.002512",
                "metrs": 1
            },
            {
                "size": " 8х45",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": " 8х50",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": " 8х55",
                "koef": "0.003454",
                "metrs": 1
            },
            {
                "size": " 8х60",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": " 8х65",
                "koef": "0.004082",
                "metrs": 1
            },
            {
                "size": " 8х70",
                "koef": "0.004396",
                "metrs": 1
            },
            {
                "size": " 8х75",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 8х80",
                "koef": "0.005024",
                "metrs": 1
            },
            {
                "size": " 8х85",
                "koef": "0.005338",
                "metrs": 1
            },
            {
                "size": " 8х90",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": " 8х95",
                "koef": "0.005966",
                "metrs": 1
            },
            {
                "size": " 8х100",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 8х110",
                "koef": "0.006908",
                "metrs": 1
            },
            {
                "size": " 8х120",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": " 8х124",
                "koef": "0.007787",
                "metrs": 1
            },
            {
                "size": " 8х130",
                "koef": "0.008164",
                "metrs": 1
            },
            {
                "size": " 8х140",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": " 8х150",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 8х160",
                "koef": "0.01005",
                "metrs": 1
            },
            {
                "size": " 8х170",
                "koef": "0.01068",
                "metrs": 1
            },
            {
                "size": " 8х180",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": " 8х190",
                "koef": "0.01193",
                "metrs": 1
            },
            {
                "size": " 8х200",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": " 8х210",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": " 8х220",
                "koef": "0.01382",
                "metrs": 1
            },
            {
                "size": " 8х230",
                "koef": "0.01444",
                "metrs": 1
            },
            {
                "size": " 8х240",
                "koef": "0.01507",
                "metrs": 1
            },
            {
                "size": " 8х250",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 8х260",
                "koef": "0.01633",
                "metrs": 1
            },
            {
                "size": " 8х270",
                "koef": "0.01696",
                "metrs": 1
            },
            {
                "size": " 8х280",
                "koef": "0.01758",
                "metrs": 1
            },
            {
                "size": " 8х290",
                "koef": "0.01821",
                "metrs": 1
            },
            {
                "size": " 8х300",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 8х315",
                "koef": "0.01978",
                "metrs": 1
            },
            {
                "size": " 8х350",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 8х400",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 8х450",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 8х500",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 8х510",
                "koef": "0.03203",
                "metrs": 1
            },
            {
                "size": " 8х610",
                "koef": "0.03831",
                "metrs": 1
            },
            {
                "size": " 8х800",
                "koef": "0.05024",
                "metrs": 1
            },
            {
                "size": " 9х14",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": " 9х240",
                "koef": "0.01696",
                "metrs": 1
            },
            {
                "size": " 10х16",
                "koef": "0.001256",
                "metrs": 1
            },
            {
                "size": " 10х20",
                "koef": "0.00157",
                "metrs": 1
            },
            {
                "size": " 10х25",
                "koef": "0.001962",
                "metrs": 1
            },
            {
                "size": " 10х30",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": " 10х36",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": " 10х40",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": " 10х45",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": " 10х50",
                "koef": "0.003925",
                "metrs": 1
            },
            {
                "size": " 10х55",
                "koef": "0.004318",
                "metrs": 1
            },
            {
                "size": " 10х60",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 10х65",
                "koef": "0.005103",
                "metrs": 1
            },
            {
                "size": " 10х70",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": " 10х75",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": " 10х80",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 10х85",
                "koef": "0.006673",
                "metrs": 1
            },
            {
                "size": " 10х90",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": " 10х95",
                "koef": "0.007458",
                "metrs": 1
            },
            {
                "size": " 10х100",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 10х110",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": " 10х120",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 10х130",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": " 10х140",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": " 10х145",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": " 10х150",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": " 10х160",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": " 10х170",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": " 10х180",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 10х190",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": " 10х197",
                "koef": "0.01546",
                "metrs": 1
            },
            {
                "size": " 10х200",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 10х205",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": " 10х210",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": " 10х220",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": " 10х230",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": " 10х240",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 10х245",
                "koef": "0.01923",
                "metrs": 1
            },
            {
                "size": " 10х250",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": " 10х260",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": " 10х270",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": " 10х280",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 10х290",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": " 10х300",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 10х350",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": " 10х400",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 10х450",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": " 10х465",
                "koef": "0.0365",
                "metrs": 1
            },
            {
                "size": " 10х500",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": " 10х610",
                "koef": "0.04789",
                "metrs": 1
            },
            {
                "size": " 11х18",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": " 12х20",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": " 12х25",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": " 12х30",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": " 12х36",
                "koef": "0.003391",
                "metrs": 1
            },
            {
                "size": " 12х40",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": " 12х45",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": " 12х50",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 12х60",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": " 12х65",
                "koef": "0.006123",
                "metrs": 1
            },
            {
                "size": " 12х70",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": " 12х75",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": " 12х80",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": " 12х85",
                "koef": "0.008007",
                "metrs": 1
            },
            {
                "size": " 12х90",
                "koef": "0.008478",
                "metrs": 1
            },
            {
                "size": " 12х95",
                "koef": "0.008949",
                "metrs": 1
            },
            {
                "size": " 12х100",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 12х110",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": " 12х120",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": " 12х125",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": " 12х130",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": " 12х140",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": " 12х150",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 12х160",
                "koef": "0.01507",
                "metrs": 1
            },
            {
                "size": " 12х170",
                "koef": "0.01601",
                "metrs": 1
            },
            {
                "size": " 12х180",
                "koef": "0.01696",
                "metrs": 1
            },
            {
                "size": " 12х190",
                "koef": "0.0179",
                "metrs": 1
            },
            {
                "size": " 12х200",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 12х210",
                "koef": "0.01978",
                "metrs": 1
            },
            {
                "size": " 12х220",
                "koef": "0.02072",
                "metrs": 1
            },
            {
                "size": " 12х230",
                "koef": "0.02167",
                "metrs": 1
            },
            {
                "size": " 12х240",
                "koef": "0.02261",
                "metrs": 1
            },
            {
                "size": " 12х250",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 12х300",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 12х350",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": " 12х360",
                "koef": "0.03391",
                "metrs": 1
            },
            {
                "size": " 12х400",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": " 12х450",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": " 12х500",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": " 12х560",
                "koef": "0.05275",
                "metrs": 1
            },
            {
                "size": " 14х20",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": " 14х22",
                "koef": "0.002418",
                "metrs": 1
            },
            {
                "size": " 14х24",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": " 14х25",
                "koef": "0.002747",
                "metrs": 1
            },
            {
                "size": " 14х30",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 14х40",
                "koef": "0.004396",
                "metrs": 1
            },
            {
                "size": " 14х45",
                "koef": "0.004946",
                "metrs": 1
            },
            {
                "size": " 14х50",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": " 14х60",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": " 14х65",
                "koef": "0.007144",
                "metrs": 1
            },
            {
                "size": " 14х70",
                "koef": "0.007693",
                "metrs": 1
            },
            {
                "size": " 14х80",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": " 14х90",
                "koef": "0.009891",
                "metrs": 1
            },
            {
                "size": " 14х100",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": " 14х110",
                "koef": "0.01209",
                "metrs": 1
            },
            {
                "size": " 14х120",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": " 14х130",
                "koef": "0.01429",
                "metrs": 1
            },
            {
                "size": " 14х140",
                "koef": "0.01539",
                "metrs": 1
            },
            {
                "size": " 14х150",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": " 14х160",
                "koef": "0.01758",
                "metrs": 1
            },
            {
                "size": " 14х170",
                "koef": "0.01868",
                "metrs": 1
            },
            {
                "size": " 14х180",
                "koef": "0.01978",
                "metrs": 1
            },
            {
                "size": " 14х190",
                "koef": "0.02088",
                "metrs": 1
            },
            {
                "size": " 14х200",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 14х210",
                "koef": "0.02308",
                "metrs": 1
            },
            {
                "size": " 14х220",
                "koef": "0.02418",
                "metrs": 1
            },
            {
                "size": " 14х230",
                "koef": "0.02528",
                "metrs": 1
            },
            {
                "size": " 14х240",
                "koef": "0.02638",
                "metrs": 1
            },
            {
                "size": " 14х250",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": " 14х260",
                "koef": "0.02857",
                "metrs": 1
            },
            {
                "size": " 14х270",
                "koef": "0.02967",
                "metrs": 1
            },
            {
                "size": " 14х280",
                "koef": "0.03077",
                "metrs": 1
            },
            {
                "size": " 14х290",
                "koef": "0.03187",
                "metrs": 1
            },
            {
                "size": " 14х300",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": " 14х310",
                "koef": "0.03407",
                "metrs": 1
            },
            {
                "size": " 14х350",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": " 14х400",
                "koef": "0.04396",
                "metrs": 1
            },
            {
                "size": " 14х500",
                "koef": "0.05495",
                "metrs": 1
            },
            {
                "size": " 14х610",
                "koef": "0.06704",
                "metrs": 1
            },
            {
                "size": " 15х210",
                "koef": "0.02473",
                "metrs": 1
            },
            {
                "size": " 16х20",
                "koef": "0.002512",
                "metrs": 1
            },
            {
                "size": " 16х25",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": " 16х28",
                "koef": "0.003517",
                "metrs": 1
            },
            {
                "size": " 16х30",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": " 16х40",
                "koef": "0.005024",
                "metrs": 1
            },
            {
                "size": " 16х45",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": " 16х50",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 16х52",
                "koef": "0.006531",
                "metrs": 1
            },
            {
                "size": " 16х56",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": " 16х60",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": " 16х70",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": " 16х80",
                "koef": "0.01005",
                "metrs": 1
            },
            {
                "size": " 16х85",
                "koef": "0.01068",
                "metrs": 1
            },
            {
                "size": " 16х90",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": " 16х100",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": " 16х110",
                "koef": "0.01382",
                "metrs": 1
            },
            {
                "size": " 16х120",
                "koef": "0.01507",
                "metrs": 1
            },
            {
                "size": " 16х150",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 16х160",
                "koef": "0.0201",
                "metrs": 1
            },
            {
                "size": " 16х170",
                "koef": "0.02135",
                "metrs": 1
            },
            {
                "size": " 16х180",
                "koef": "0.02261",
                "metrs": 1
            },
            {
                "size": " 16х190",
                "koef": "0.02386",
                "metrs": 1
            },
            {
                "size": " 16х200",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 16х210",
                "koef": "0.02638",
                "metrs": 1
            },
            {
                "size": " 16х220",
                "koef": "0.02763",
                "metrs": 1
            },
            {
                "size": " 16х230",
                "koef": "0.02889",
                "metrs": 1
            },
            {
                "size": " 16х240",
                "koef": "0.03014",
                "metrs": 1
            },
            {
                "size": " 16х250",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 16х270",
                "koef": "0.03391",
                "metrs": 1
            },
            {
                "size": " 16х280",
                "koef": "0.03517",
                "metrs": 1
            },
            {
                "size": " 16х300",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": " 16х320",
                "koef": "0.04019",
                "metrs": 1
            },
            {
                "size": " 16х350",
                "koef": "0.04396",
                "metrs": 1
            },
            {
                "size": " 16х400",
                "koef": "0.05024",
                "metrs": 1
            },
            {
                "size": " 16х450",
                "koef": "0.05652",
                "metrs": 1
            },
            {
                "size": " 16х500",
                "koef": "0.0628",
                "metrs": 1
            },
            {
                "size": " 16х610",
                "koef": "0.07662",
                "metrs": 1
            },
            {
                "size": " 18х30",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": " 18х32",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": " 18х50",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": " 18х60",
                "koef": "0.008478",
                "metrs": 1
            },
            {
                "size": " 18х65",
                "koef": "0.009185",
                "metrs": 1
            },
            {
                "size": " 18х70",
                "koef": "0.009891",
                "metrs": 1
            },
            {
                "size": " 18х80",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": " 18х90",
                "koef": "0.01272",
                "metrs": 1
            },
            {
                "size": " 18х100",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 18х110",
                "koef": "0.01554",
                "metrs": 1
            },
            {
                "size": " 18х120",
                "koef": "0.01696",
                "metrs": 1
            },
            {
                "size": " 18х150",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": " 18х160",
                "koef": "0.02261",
                "metrs": 1
            },
            {
                "size": " 18х170",
                "koef": "0.02402",
                "metrs": 1
            },
            {
                "size": " 18х180",
                "koef": "0.02543",
                "metrs": 1
            },
            {
                "size": " 18х190",
                "koef": "0.02685",
                "metrs": 1
            },
            {
                "size": " 18х200",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 18х210",
                "koef": "0.02967",
                "metrs": 1
            },
            {
                "size": " 18х220",
                "koef": "0.03109",
                "metrs": 1
            },
            {
                "size": " 18х250",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": " 18х270",
                "koef": "0.03815",
                "metrs": 1
            },
            {
                "size": " 18х300",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": " 18х350",
                "koef": "0.04946",
                "metrs": 1
            },
            {
                "size": " 18х500",
                "koef": "0.07065",
                "metrs": 1
            },
            {
                "size": " 19х254",
                "koef": "0.03788",
                "metrs": 1
            },
            {
                "size": " 20х25",
                "koef": "0.003925",
                "metrs": 1
            },
            {
                "size": " 20х30",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 20х32",
                "koef": "0.005024",
                "metrs": 1
            },
            {
                "size": " 20х35",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": " 20х36",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": " 20х40",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 20х50",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 20х60",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 20х70",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": " 20х80",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": " 20х90",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 20х100",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 20х110",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": " 20х120",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 20х130",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": " 20х140",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 20х150",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 20х160",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 20х170",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": " 20х180",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 20х190",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": " 20х200",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 20х210",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": " 20х220",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": " 20х230",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": " 20х240",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": " 20х250",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": " 20х300",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": " 20х350",
                "koef": "0.05495",
                "metrs": 1
            },
            {
                "size": " 20х400",
                "koef": "0.0628",
                "metrs": 1
            },
            {
                "size": " 20х450",
                "koef": "0.07065",
                "metrs": 1
            },
            {
                "size": " 20х500",
                "koef": "0.0785",
                "metrs": 1
            },
            {
                "size": " 20х605",
                "koef": "0.09499",
                "metrs": 1
            },
            {
                "size": " 20х610",
                "koef": "0.09577",
                "metrs": 1
            },
            {
                "size": " 25х32",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 25х40",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 25х50",
                "koef": "0.009813",
                "metrs": 1
            },
            {
                "size": " 25х60",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": " 25х70",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": " 25х80",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 25х83",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": " 25х90",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": " 25х100",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": " 25х150",
                "koef": "0.02944",
                "metrs": 1
            },
            {
                "size": " 25х160",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 25х170",
                "koef": "0.03336",
                "metrs": 1
            },
            {
                "size": " 25х180",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": " 25х190",
                "koef": "0.03729",
                "metrs": 1
            },
            {
                "size": " 25х200",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": " 25х210",
                "koef": "0.04121",
                "metrs": 1
            },
            {
                "size": " 25х220",
                "koef": "0.04318",
                "metrs": 1
            },
            {
                "size": " 25х230",
                "koef": "0.04514",
                "metrs": 1
            },
            {
                "size": " 25х240",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": " 25х250",
                "koef": "0.04906",
                "metrs": 1
            },
            {
                "size": " 25х300",
                "koef": "0.05888",
                "metrs": 1
            },
            {
                "size": " 25х350",
                "koef": "0.06869",
                "metrs": 1
            },
            {
                "size": " 25х400",
                "koef": "0.0785",
                "metrs": 1
            },
            {
                "size": " 25х450",
                "koef": "0.08831",
                "metrs": 1
            },
            {
                "size": " 25х495",
                "koef": "0.09714",
                "metrs": 1
            },
            {
                "size": " 25х500",
                "koef": "0.09813",
                "metrs": 1
            },
            {
                "size": " 25х610",
                "koef": "0.1197",
                "metrs": 1
            },
            {
                "size": " 30х40",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 30х50",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": " 30х60",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 30х70",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": " 30х80",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 30х90",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": " 30х100",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 30х150",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": " 30х160",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": " 30х170",
                "koef": "0.04003",
                "metrs": 1
            },
            {
                "size": " 30х180",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": " 30х190",
                "koef": "0.04475",
                "metrs": 1
            },
            {
                "size": " 30х200",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": " 30х210",
                "koef": "0.04946",
                "metrs": 1
            },
            {
                "size": " 30х220",
                "koef": "0.05181",
                "metrs": 1
            },
            {
                "size": " 30х230",
                "koef": "0.05417",
                "metrs": 1
            },
            {
                "size": " 30х240",
                "koef": "0.05652",
                "metrs": 1
            },
            {
                "size": " 30х250",
                "koef": "0.05888",
                "metrs": 1
            },
            {
                "size": " 30х300",
                "koef": "0.07065",
                "metrs": 1
            },
            {
                "size": " 30х350",
                "koef": "0.08243",
                "metrs": 1
            },
            {
                "size": " 30х400",
                "koef": "0.0942",
                "metrs": 1
            },
            {
                "size": " 30х415",
                "koef": "0.09773",
                "metrs": 1
            },
            {
                "size": " 30х450",
                "koef": "0.106",
                "metrs": 1
            },
            {
                "size": " 30х500",
                "koef": "0.1178",
                "metrs": 1
            },
            {
                "size": " 30х610",
                "koef": "0.1437",
                "metrs": 1
            },
            {
                "size": " 32х40",
                "koef": "0.01005",
                "metrs": 1
            },
            {
                "size": " 35х95",
                "koef": "0.0261",
                "metrs": 1
            },
            {
                "size": " 35х100",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": " 36х50",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 40х50",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 40х60",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 40х70",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 40х80",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 40х90",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 40х100",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 40х150",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": " 40х200",
                "koef": "0.0628",
                "metrs": 1
            },
            {
                "size": " 40х250",
                "koef": "0.0785",
                "metrs": 1
            },
            {
                "size": " 40х300",
                "koef": "0.0942",
                "metrs": 1
            },
            {
                "size": " 40х320",
                "koef": "0.1005",
                "metrs": 1
            },
            {
                "size": " 40х350",
                "koef": "0.1099",
                "metrs": 1
            },
            {
                "size": " 40х400",
                "koef": "0.1256",
                "metrs": 1
            },
            {
                "size": " 40х450",
                "koef": "0.1413",
                "metrs": 1
            },
            {
                "size": " 40х500",
                "koef": "0.157",
                "metrs": 1
            },
            {
                "size": " 40х610",
                "koef": "0.1915",
                "metrs": 1
            },
            {
                "size": " 42х800",
                "koef": "0.2638",
                "metrs": 1
            },
            {
                "size": " 50х50",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": " 50х60",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 50х70",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": " 50х80",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 50х90",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": " 50х100",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": " 50х150",
                "koef": "0.05888",
                "metrs": 1
            },
            {
                "size": " 50х200",
                "koef": "0.0785",
                "metrs": 1
            },
            {
                "size": " 50х250",
                "koef": "0.09813",
                "metrs": 1
            },
            {
                "size": " 50х300",
                "koef": "0.1178",
                "metrs": 1
            },
            {
                "size": " 50х350",
                "koef": "0.1374",
                "metrs": 1
            },
            {
                "size": " 50х390",
                "koef": "0.1531",
                "metrs": 1
            },
            {
                "size": " 50х400",
                "koef": "0.157",
                "metrs": 1
            },
            {
                "size": " 50х450",
                "koef": "0.1766",
                "metrs": 1
            },
            {
                "size": " 50х500",
                "koef": "0.1963",
                "metrs": 1
            },
            {
                "size": " 50х510",
                "koef": "0.2002",
                "metrs": 1
            },
            {
                "size": " 50х610",
                "koef": "0.2394",
                "metrs": 1
            },
            {
                "size": " 60х150",
                "koef": "0.07065",
                "metrs": 1
            },
            {
                "size": " 60х190",
                "koef": "0.08949",
                "metrs": 1
            },
            {
                "size": " 60х200",
                "koef": "0.0942",
                "metrs": 1
            },
            {
                "size": " 60х250",
                "koef": "0.1178",
                "metrs": 1
            },
            {
                "size": " 60х300",
                "koef": "0.1413",
                "metrs": 1
            },
            {
                "size": " 60х350",
                "koef": "0.1649",
                "metrs": 1
            },
            {
                "size": " 60х400",
                "koef": "0.1884",
                "metrs": 1
            },
            {
                "size": " 60х450",
                "koef": "0.212",
                "metrs": 1
            },
            {
                "size": " 60х500",
                "koef": "0.2355",
                "metrs": 1
            },
            {
                "size": " 60х610",
                "koef": "0.2873",
                "metrs": 1
            },
            {
                "size": " 70х150",
                "koef": "0.08243",
                "metrs": 1
            },
            {
                "size": " 70х200",
                "koef": "0.1099",
                "metrs": 1
            },
            {
                "size": " 70х250",
                "koef": "0.1374",
                "metrs": 1
            },
            {
                "size": " 70х300",
                "koef": "0.1649",
                "metrs": 1
            },
            {
                "size": " 70х350",
                "koef": "0.1923",
                "metrs": 1
            },
            {
                "size": " 70х380",
                "koef": "0.2088",
                "metrs": 1
            },
            {
                "size": " 70х400",
                "koef": "0.2198",
                "metrs": 1
            },
            {
                "size": " 70х440",
                "koef": "0.2418",
                "metrs": 1
            },
            {
                "size": " 70х450",
                "koef": "0.2473",
                "metrs": 1
            },
            {
                "size": " 70х500",
                "koef": "0.2748",
                "metrs": 1
            },
            {
                "size": " 70х610",
                "koef": "0.3352",
                "metrs": 1
            },
            {
                "size": " 75х435",
                "koef": "0.2561",
                "metrs": 1
            },
            {
                "size": " 80х140",
                "koef": "0.08792",
                "metrs": 1
            },
            {
                "size": " 80х150",
                "koef": "0.0942",
                "metrs": 1
            },
            {
                "size": " 80х200",
                "koef": "0.1256",
                "metrs": 1
            },
            {
                "size": " 80х220",
                "koef": "0.1382",
                "metrs": 1
            },
            {
                "size": " 80х250",
                "koef": "0.157",
                "metrs": 1
            },
            {
                "size": " 80х300",
                "koef": "0.1884",
                "metrs": 1
            },
            {
                "size": " 80х350",
                "koef": "0.2198",
                "metrs": 1
            },
            {
                "size": " 80х400",
                "koef": "0.2512",
                "metrs": 1
            },
            {
                "size": " 80х450",
                "koef": "0.2826",
                "metrs": 1
            },
            {
                "size": " 80х500",
                "koef": "0.314",
                "metrs": 1
            },
            {
                "size": " 80х510",
                "koef": "0.3203",
                "metrs": 1
            },
            {
                "size": " 80х610",
                "koef": "0.3831",
                "metrs": 1
            },
            {
                "size": " 85х440",
                "koef": "0.2936",
                "metrs": 1
            },
            {
                "size": " 90х150",
                "koef": "0.106",
                "metrs": 1
            },
            {
                "size": " 90х200",
                "koef": "0.1413",
                "metrs": 1
            },
            {
                "size": " 90х250",
                "koef": "0.1766",
                "metrs": 1
            },
            {
                "size": " 90х300",
                "koef": "0.212",
                "metrs": 1
            },
            {
                "size": " 90х350",
                "koef": "0.2473",
                "metrs": 1
            },
            {
                "size": " 90х400",
                "koef": "0.2826",
                "metrs": 1
            },
            {
                "size": " 90х450",
                "koef": "0.3179",
                "metrs": 1
            },
            {
                "size": " 90х500",
                "koef": "0.3533",
                "metrs": 1
            },
            {
                "size": " 100х150",
                "koef": "0.1178",
                "metrs": 1
            },
            {
                "size": " 100х200",
                "koef": "0.157",
                "metrs": 1
            },
            {
                "size": " 100х250",
                "koef": "0.1963",
                "metrs": 1
            },
            {
                "size": " 100х300",
                "koef": "0.2355",
                "metrs": 1
            },
            {
                "size": " 100х350",
                "koef": "0.2748",
                "metrs": 1
            },
            {
                "size": " 100х380",
                "koef": "0.2983",
                "metrs": 1
            },
            {
                "size": " 100х400",
                "koef": "0.314",
                "metrs": 1
            },
            {
                "size": " 100х450",
                "koef": "0.3533",
                "metrs": 1
            },
            {
                "size": " 100х500",
                "koef": "0.3925",
                "metrs": 1
            },
            {
                "size": " 120х510",
                "koef": "0.4804",
                "metrs": 1
            },
            {
                "size": " 120х600",
                "koef": "0.5652",
                "metrs": 1
            },
            {
                "size": " 120х610",
                "koef": "0.5746",
                "metrs": 1
            },
            {
                "size": " 150х610",
                "koef": "0.7183",
                "metrs": 1
            },
            {
                "size": " 200х610",
                "koef": "0.9577",
                "metrs": 1
            },
            {
                "size": " 250х610",
                "koef": "1.197",
                "metrs": 1
            },
            {
                "size": " 300х610",
                "koef": "1.437",
                "metrs": 1
            }
        ],
        "Полоса б/у": [
            {
                "size": "6х10",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": "6х12",
                "koef": "0.000565",
                "metrs": 1
            },
            {
                "size": "6х20",
                "koef": "0.000942",
                "metrs": 1
            },
            {
                "size": "6х24",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": "6х25",
                "koef": "0.001178",
                "metrs": 1
            },
            {
                "size": "6х30",
                "koef": "0.001413",
                "metrs": 1
            },
            {
                "size": "6х36",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "6х40",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": "6х45",
                "koef": "0.00212",
                "metrs": 1
            },
            {
                "size": "6х50",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": "6х55",
                "koef": "0.002591",
                "metrs": 1
            },
            {
                "size": "6х60",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": "6х65",
                "koef": "0.003062",
                "metrs": 1
            },
            {
                "size": "6х70",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": "6х75",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": "6х80",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": "6х85",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": "6х90",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": "6х95",
                "koef": "0.004474",
                "metrs": 1
            },
            {
                "size": "6х100",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "6х110",
                "koef": "0.005181",
                "metrs": 1
            },
            {
                "size": "6х114",
                "koef": "0.005369",
                "metrs": 1
            },
            {
                "size": "6х120",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": "6х130",
                "koef": "0.006123",
                "metrs": 1
            },
            {
                "size": "6х140",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": "6х150",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": "6х160",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": "6х170",
                "koef": "0.008007",
                "metrs": 1
            },
            {
                "size": "6х180",
                "koef": "0.008478",
                "metrs": 1
            },
            {
                "size": "6х190",
                "koef": "0.008949",
                "metrs": 1
            },
            {
                "size": "6х200",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "6х210",
                "koef": "0.009891",
                "metrs": 1
            },
            {
                "size": "6х220",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": "6х230",
                "koef": "0.01083",
                "metrs": 1
            },
            {
                "size": "6х240",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": "6х250",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "6х260",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": "6х270",
                "koef": "0.01272",
                "metrs": 1
            },
            {
                "size": "6х280",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": "6х290",
                "koef": "0.01366",
                "metrs": 1
            },
            {
                "size": "6х300",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "6х350",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": "6х400",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "6х450",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "6х500",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "7х8",
                "koef": "0.00044",
                "metrs": 1
            },
            {
                "size": "7х20",
                "koef": "0.001099",
                "metrs": 1
            },
            {
                "size": "7х24",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": "7х30",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": "7х40",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": "7х50",
                "koef": "0.002747",
                "metrs": 1
            },
            {
                "size": "7х60",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": "7х70",
                "koef": "0.003846",
                "metrs": 1
            },
            {
                "size": "7х75",
                "koef": "0.004121",
                "metrs": 1
            },
            {
                "size": "7х80",
                "koef": "0.004396",
                "metrs": 1
            },
            {
                "size": "7х85",
                "koef": "0.004671",
                "metrs": 1
            },
            {
                "size": "7х90",
                "koef": "0.004946",
                "metrs": 1
            },
            {
                "size": "7х95",
                "koef": "0.00522",
                "metrs": 1
            },
            {
                "size": "7х100",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": "7х110",
                "koef": "0.006045",
                "metrs": 1
            },
            {
                "size": "7х120",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": "7х130",
                "koef": "0.007144",
                "metrs": 1
            },
            {
                "size": "7х140",
                "koef": "0.007693",
                "metrs": 1
            },
            {
                "size": "7х150",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": "7х160",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": "7х170",
                "koef": "0.009342",
                "metrs": 1
            },
            {
                "size": "7х180",
                "koef": "0.009891",
                "metrs": 1
            },
            {
                "size": "7х190",
                "koef": "0.01044",
                "metrs": 1
            },
            {
                "size": "7х200",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": "7х210",
                "koef": "0.01154",
                "metrs": 1
            },
            {
                "size": "7х220",
                "koef": "0.01209",
                "metrs": 1
            },
            {
                "size": "7х230",
                "koef": "0.01264",
                "metrs": 1
            },
            {
                "size": "7х240",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": "7х250",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": "7х300",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": "7х500",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "8х10",
                "koef": "0.000628",
                "metrs": 1
            },
            {
                "size": "8х12",
                "koef": "0.000754",
                "metrs": 1
            },
            {
                "size": "8х16",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": "8х18",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": "8х20",
                "koef": "0.001256",
                "metrs": 1
            },
            {
                "size": "8х25",
                "koef": "0.00157",
                "metrs": 1
            },
            {
                "size": "8х30",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": "8х35",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": "8х36",
                "koef": "0.002261",
                "metrs": 1
            },
            {
                "size": "8х40",
                "koef": "0.002512",
                "metrs": 1
            },
            {
                "size": "8х45",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": "8х50",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": "8х55",
                "koef": "0.003454",
                "metrs": 1
            },
            {
                "size": "8х60",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": "8х65",
                "koef": "0.004082",
                "metrs": 1
            },
            {
                "size": "8х70",
                "koef": "0.004396",
                "metrs": 1
            },
            {
                "size": "8х75",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "8х80",
                "koef": "0.005024",
                "metrs": 1
            },
            {
                "size": "8х85",
                "koef": "0.005338",
                "metrs": 1
            },
            {
                "size": "8х90",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": "8х95",
                "koef": "0.005966",
                "metrs": 1
            },
            {
                "size": "8х100",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": "8х110",
                "koef": "0.006908",
                "metrs": 1
            },
            {
                "size": "8х120",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": "8х124",
                "koef": "0.007787",
                "metrs": 1
            },
            {
                "size": "8х130",
                "koef": "0.008164",
                "metrs": 1
            },
            {
                "size": "8х140",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": "8х150",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "8х160",
                "koef": "0.01005",
                "metrs": 1
            },
            {
                "size": "8х170",
                "koef": "0.01068",
                "metrs": 1
            },
            {
                "size": "8х180",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": "8х190",
                "koef": "0.01193",
                "metrs": 1
            },
            {
                "size": "8х200",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": "8х210",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": "8х220",
                "koef": "0.01382",
                "metrs": 1
            },
            {
                "size": "8х230",
                "koef": "0.01444",
                "metrs": 1
            },
            {
                "size": "8х240",
                "koef": "0.01507",
                "metrs": 1
            },
            {
                "size": "8х250",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": "8х260",
                "koef": "0.01633",
                "metrs": 1
            },
            {
                "size": "8х270",
                "koef": "0.01696",
                "metrs": 1
            },
            {
                "size": "8х280",
                "koef": "0.01758",
                "metrs": 1
            },
            {
                "size": "8х290",
                "koef": "0.01821",
                "metrs": 1
            },
            {
                "size": "8х300",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "8х350",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "8х400",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "8х450",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "8х500",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "8х510",
                "koef": "0.03203",
                "metrs": 1
            },
            {
                "size": "9х14",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "9х240",
                "koef": "0.01696",
                "metrs": 1
            },
            {
                "size": "10х16",
                "koef": "0.001256",
                "metrs": 1
            },
            {
                "size": "10х20",
                "koef": "0.00157",
                "metrs": 1
            },
            {
                "size": "10х25",
                "koef": "0.001962",
                "metrs": 1
            },
            {
                "size": "10х30",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": "10х36",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": "10х40",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": "10х45",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": "10х50",
                "koef": "0.003925",
                "metrs": 1
            },
            {
                "size": "10х55",
                "koef": "0.004318",
                "metrs": 1
            },
            {
                "size": "10х60",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "10х65",
                "koef": "0.005103",
                "metrs": 1
            },
            {
                "size": "10х70",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": "10х75",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": "10х80",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": "10х85",
                "koef": "0.006673",
                "metrs": 1
            },
            {
                "size": "10х90",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": "10х95",
                "koef": "0.007458",
                "metrs": 1
            },
            {
                "size": "10х100",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": "10х110",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": "10х120",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "10х130",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": "10х140",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": "10х150",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "10х160",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": "10х170",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": "10х180",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "10х190",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": "10х197",
                "koef": "0.01546",
                "metrs": 1
            },
            {
                "size": "10х200",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": "10х205",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": "10х210",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": "10х220",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": "10х230",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": "10х240",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "10х250",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": "10х260",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": "10х270",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "10х280",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "10х290",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "10х300",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "10х350",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "10х400",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "10х450",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "10х500",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": "10х610",
                "koef": "0.04789",
                "metrs": 1
            },
            {
                "size": "11х18",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": "12х20",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": "12х25",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": "12х30",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": "12х36",
                "koef": "0.003391",
                "metrs": 1
            },
            {
                "size": "12х40",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": "12х45",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": "12х50",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "12х60",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": "12х65",
                "koef": "0.006123",
                "metrs": 1
            },
            {
                "size": "12х70",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": "12х75",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": "12х80",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": "12х85",
                "koef": "0.008007",
                "metrs": 1
            },
            {
                "size": "12х90",
                "koef": "0.008478",
                "metrs": 1
            },
            {
                "size": "12х95",
                "koef": "0.008949",
                "metrs": 1
            },
            {
                "size": "12х100",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "12х110",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": "12х120",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": "12х125",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "12х130",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": "12х140",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": "12х150",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "12х160",
                "koef": "0.01507",
                "metrs": 1
            },
            {
                "size": "12х170",
                "koef": "0.01601",
                "metrs": 1
            },
            {
                "size": "12х180",
                "koef": "0.01696",
                "metrs": 1
            },
            {
                "size": "12х190",
                "koef": "0.0179",
                "metrs": 1
            },
            {
                "size": "12х200",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "12х210",
                "koef": "0.01978",
                "metrs": 1
            },
            {
                "size": "12х220",
                "koef": "0.02072",
                "metrs": 1
            },
            {
                "size": "12х230",
                "koef": "0.02167",
                "metrs": 1
            },
            {
                "size": "12х240",
                "koef": "0.02261",
                "metrs": 1
            },
            {
                "size": "12х250",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "12х300",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "12х350",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "12х360",
                "koef": "0.03391",
                "metrs": 1
            },
            {
                "size": "12х400",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": "12х450",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": "12х500",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": "12х560",
                "koef": "0.05275",
                "metrs": 1
            },
            {
                "size": "14х20",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": "14х22",
                "koef": "0.002418",
                "metrs": 1
            },
            {
                "size": "14х24",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "14х25",
                "koef": "0.002747",
                "metrs": 1
            },
            {
                "size": "14х30",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": "14х40",
                "koef": "0.004396",
                "metrs": 1
            },
            {
                "size": "14х45",
                "koef": "0.004946",
                "metrs": 1
            },
            {
                "size": "14х50",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": "14х60",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": "14х65",
                "koef": "0.007144",
                "metrs": 1
            },
            {
                "size": "14х70",
                "koef": "0.007693",
                "metrs": 1
            },
            {
                "size": "14х80",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": "14х90",
                "koef": "0.009891",
                "metrs": 1
            },
            {
                "size": "14х100",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": "14х110",
                "koef": "0.01209",
                "metrs": 1
            },
            {
                "size": "14х120",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": "14х130",
                "koef": "0.01429",
                "metrs": 1
            },
            {
                "size": "14х140",
                "koef": "0.01539",
                "metrs": 1
            },
            {
                "size": "14х150",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": "14х160",
                "koef": "0.01758",
                "metrs": 1
            },
            {
                "size": "14х170",
                "koef": "0.01868",
                "metrs": 1
            },
            {
                "size": "14х180",
                "koef": "0.01978",
                "metrs": 1
            },
            {
                "size": "14х190",
                "koef": "0.02088",
                "metrs": 1
            },
            {
                "size": "14х200",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "14х210",
                "koef": "0.02308",
                "metrs": 1
            },
            {
                "size": "14х220",
                "koef": "0.02418",
                "metrs": 1
            },
            {
                "size": "14х230",
                "koef": "0.02528",
                "metrs": 1
            },
            {
                "size": "14х240",
                "koef": "0.02638",
                "metrs": 1
            },
            {
                "size": "14х250",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "14х260",
                "koef": "0.02857",
                "metrs": 1
            },
            {
                "size": "14х270",
                "koef": "0.02967",
                "metrs": 1
            },
            {
                "size": "14х280",
                "koef": "0.03077",
                "metrs": 1
            },
            {
                "size": "14х290",
                "koef": "0.03187",
                "metrs": 1
            },
            {
                "size": "14х300",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "14х310",
                "koef": "0.03407",
                "metrs": 1
            },
            {
                "size": "14х350",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": "14х500",
                "koef": "0.05495",
                "metrs": 1
            },
            {
                "size": "16х20",
                "koef": "0.002512",
                "metrs": 1
            },
            {
                "size": "16х25",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": "16х28",
                "koef": "0.003517",
                "metrs": 1
            },
            {
                "size": "16х30",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": "16х40",
                "koef": "0.005024",
                "metrs": 1
            },
            {
                "size": "16х45",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": "16х50",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": "16х52",
                "koef": "0.006531",
                "metrs": 1
            },
            {
                "size": "16х56",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": "16х60",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": "16х70",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": "16х80",
                "koef": "0.01005",
                "metrs": 1
            },
            {
                "size": "16х85",
                "koef": "0.01068",
                "metrs": 1
            },
            {
                "size": "16х90",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": "16х100",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": "16х120",
                "koef": "0.01507",
                "metrs": 1
            },
            {
                "size": "16х150",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "16х160",
                "koef": "0.0201",
                "metrs": 1
            },
            {
                "size": "16х170",
                "koef": "0.02135",
                "metrs": 1
            },
            {
                "size": "16х180",
                "koef": "0.02261",
                "metrs": 1
            },
            {
                "size": "16х190",
                "koef": "0.02386",
                "metrs": 1
            },
            {
                "size": "16х200",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "16х210",
                "koef": "0.02638",
                "metrs": 1
            },
            {
                "size": "16х220",
                "koef": "0.02763",
                "metrs": 1
            },
            {
                "size": "16х230",
                "koef": "0.02889",
                "metrs": 1
            },
            {
                "size": "16х240",
                "koef": "0.03014",
                "metrs": 1
            },
            {
                "size": "16х250",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "16х280",
                "koef": "0.03517",
                "metrs": 1
            },
            {
                "size": "16х300",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": "16х320",
                "koef": "0.04019",
                "metrs": 1
            },
            {
                "size": "16х350",
                "koef": "0.04396",
                "metrs": 1
            },
            {
                "size": "16х400",
                "koef": "0.05024",
                "metrs": 1
            },
            {
                "size": "16х450",
                "koef": "0.05652",
                "metrs": 1
            },
            {
                "size": "16х500",
                "koef": "0.0628",
                "metrs": 1
            },
            {
                "size": "16х610",
                "koef": "0.07662",
                "metrs": 1
            },
            {
                "size": "18х30",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": "18х32",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "18х60",
                "koef": "0.008478",
                "metrs": 1
            },
            {
                "size": "18х65",
                "koef": "0.009185",
                "metrs": 1
            },
            {
                "size": "18х80",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": "18х100",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "18х150",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "18х200",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "19х254",
                "koef": "0.03788",
                "metrs": 1
            },
            {
                "size": "20х25",
                "koef": "0.003925",
                "metrs": 1
            },
            {
                "size": "20х30",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "20х32",
                "koef": "0.005024",
                "metrs": 1
            },
            {
                "size": "20х36",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": "20х40",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": "20х50",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": "20х60",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "20х70",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": "20х80",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": "20х90",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "20х100",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": "20х150",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "20х160",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "20х170",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "20х180",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "20х190",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "20х200",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "20х210",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "20х220",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": "20х230",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": "20х240",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": "20х250",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": "20х300",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": "20х350",
                "koef": "0.05495",
                "metrs": 1
            },
            {
                "size": "20х400",
                "koef": "0.0628",
                "metrs": 1
            },
            {
                "size": "20х450",
                "koef": "0.07065",
                "metrs": 1
            },
            {
                "size": "20х500",
                "koef": "0.0785",
                "metrs": 1
            },
            {
                "size": "20х610",
                "koef": "0.09577",
                "metrs": 1
            },
            {
                "size": "25х40",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": "25х70",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": "25х80",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": "25х83",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "25х150",
                "koef": "0.02944",
                "metrs": 1
            },
            {
                "size": "25х160",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "25х170",
                "koef": "0.03336",
                "metrs": 1
            },
            {
                "size": "25х180",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "25х190",
                "koef": "0.03729",
                "metrs": 1
            },
            {
                "size": "25х200",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": "25х210",
                "koef": "0.04121",
                "metrs": 1
            },
            {
                "size": "25х220",
                "koef": "0.04318",
                "metrs": 1
            },
            {
                "size": "25х230",
                "koef": "0.04514",
                "metrs": 1
            },
            {
                "size": "25х240",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": "25х250",
                "koef": "0.04906",
                "metrs": 1
            },
            {
                "size": "25х300",
                "koef": "0.05888",
                "metrs": 1
            },
            {
                "size": "25х350",
                "koef": "0.06869",
                "metrs": 1
            },
            {
                "size": "25х400",
                "koef": "0.0785",
                "metrs": 1
            },
            {
                "size": "25х450",
                "koef": "0.08831",
                "metrs": 1
            },
            {
                "size": "25х500",
                "koef": "0.09813",
                "metrs": 1
            },
            {
                "size": "25х610",
                "koef": "0.1197",
                "metrs": 1
            }
        ],
        "Полоса оцинкованная": [
            {
                "size": "1,2х30",
                "koef": "0.000291",
                "metrs": 1
            },
            {
                "size": "1,2х32",
                "koef": "0.00031",
                "metrs": 1
            },
            {
                "size": "1,2х35",
                "koef": "0.00034",
                "metrs": 1
            },
            {
                "size": "1,2х36,5",
                "koef": "0.000354",
                "metrs": 1
            },
            {
                "size": "1,2х44",
                "koef": "0.000427",
                "metrs": 1
            },
            {
                "size": "1,2х59",
                "koef": "0.000572",
                "metrs": 1
            },
            {
                "size": "1,3х35",
                "koef": "0.000368",
                "metrs": 1
            },
            {
                "size": "1,5х26",
                "koef": "0.000315",
                "metrs": 1
            },
            {
                "size": "1,5х28",
                "koef": "0.00034",
                "metrs": 1
            },
            {
                "size": "1,5х30",
                "koef": "0.000364",
                "metrs": 1
            },
            {
                "size": "1,5х36",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": "1,5х40",
                "koef": "0.000485",
                "metrs": 1
            },
            {
                "size": "1,5х45",
                "koef": "0.000546",
                "metrs": 1
            },
            {
                "size": "1,5х46",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": "1,5х48",
                "koef": "0.000582",
                "metrs": 1
            },
            {
                "size": "1,5х66",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": "1,8х26",
                "koef": "0.000378",
                "metrs": 1
            },
            {
                "size": "1,8х28",
                "koef": "0.000408",
                "metrs": 1
            },
            {
                "size": "1,8х30",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": "1,8х36",
                "koef": "0.000524",
                "metrs": 1
            },
            {
                "size": "1,8х40",
                "koef": "0.000582",
                "metrs": 1
            },
            {
                "size": "1,8х45",
                "koef": "0.000655",
                "metrs": 1
            },
            {
                "size": "1,8х46",
                "koef": "0.000669",
                "metrs": 1
            },
            {
                "size": "1,8х48",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "1,8х66",
                "koef": "0.000961",
                "metrs": 1
            },
            {
                "size": "2х22",
                "koef": "0.000356",
                "metrs": 1
            },
            {
                "size": "2х24",
                "koef": "0.000388",
                "metrs": 1
            },
            {
                "size": "2х28",
                "koef": "0.000453",
                "metrs": 1
            },
            {
                "size": "2х30",
                "koef": "0.000485",
                "metrs": 1
            },
            {
                "size": "2х40",
                "koef": "0.000647",
                "metrs": 1
            },
            {
                "size": "2х54",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": "2х67",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": "2,3х28",
                "koef": "0.000521",
                "metrs": 1
            },
            {
                "size": "2,3х36",
                "koef": "0.000669",
                "metrs": 1
            },
            {
                "size": "2,35х36",
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": "2,45х30",
                "koef": "0.000594",
                "metrs": 1
            },
            {
                "size": "2,45х40",
                "koef": "0.000792",
                "metrs": 1
            },
            {
                "size": "2,45х185",
                "koef": "0.003665",
                "metrs": 1
            },
            {
                "size": "2,5х20",
                "koef": "0.000404",
                "metrs": 1
            },
            {
                "size": "2,5х22,2",
                "koef": "0.000449",
                "metrs": 1
            },
            {
                "size": "2,5х24",
                "koef": "0.000485",
                "metrs": 1
            },
            {
                "size": "2,5х25",
                "koef": "0.000505",
                "metrs": 1
            },
            {
                "size": "2,5х25,2",
                "koef": "0.000509",
                "metrs": 1
            },
            {
                "size": "2,5х28,2",
                "koef": "0.00057",
                "metrs": 1
            },
            {
                "size": "2,5х30",
                "koef": "0.000606",
                "metrs": 1
            },
            {
                "size": "2,5х32",
                "koef": "0.000647",
                "metrs": 1
            },
            {
                "size": "2,5х35",
                "koef": "0.000707",
                "metrs": 1
            },
            {
                "size": "2,5х36",
                "koef": "0.000728",
                "metrs": 1
            },
            {
                "size": "2,5х40",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": "2,5х50",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": "2,5х60",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "2,7х36",
                "koef": "0.000786",
                "metrs": 1
            },
            {
                "size": "2,7х88",
                "koef": "0.001921",
                "metrs": 1
            },
            {
                "size": "2,7х93",
                "koef": "0.00203",
                "metrs": 1
            },
            {
                "size": "2,8х28",
                "koef": "0.000634",
                "metrs": 1
            },
            {
                "size": "3х20",
                "koef": "0.000485",
                "metrs": 1
            },
            {
                "size": "3х22,2",
                "koef": "0.000538",
                "metrs": 1
            },
            {
                "size": "3х24",
                "koef": "0.000582",
                "metrs": 1
            },
            {
                "size": "3х25",
                "koef": "0.000606",
                "metrs": 1
            },
            {
                "size": "3х25,2",
                "koef": "0.000611",
                "metrs": 1
            },
            {
                "size": "3х26",
                "koef": "0.000631",
                "metrs": 1
            },
            {
                "size": "3х27",
                "koef": "0.000655",
                "metrs": 1
            },
            {
                "size": "3х30",
                "koef": "0.000728",
                "metrs": 1
            },
            {
                "size": "3х35",
                "koef": "0.000849",
                "metrs": 1
            },
            {
                "size": "3х40",
                "koef": "0.00097",
                "metrs": 1
            },
            {
                "size": "3х50",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "3,5х25,2",
                "koef": "0.000713",
                "metrs": 1
            },
            {
                "size": "3,5х36",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": "3,5х55",
                "koef": "0.001556",
                "metrs": 1
            },
            {
                "size": "3,5х65",
                "koef": "0.001839",
                "metrs": 1
            },
            {
                "size": "3,5х93",
                "koef": "0.002632",
                "metrs": 1
            },
            {
                "size": "4х20",
                "koef": "0.000647",
                "metrs": 1
            },
            {
                "size": "4х25",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": "4х30",
                "koef": "0.00097",
                "metrs": 1
            },
            {
                "size": "4х40",
                "koef": "0.001294",
                "metrs": 1
            },
            {
                "size": "4х50",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "4х60",
                "koef": "0.001941",
                "metrs": 1
            },
            {
                "size": "4х68",
                "koef": "0.002199",
                "metrs": 1
            },
            {
                "size": "4х80",
                "koef": "0.002587",
                "metrs": 1
            },
            {
                "size": "4х90",
                "koef": "0.002911",
                "metrs": 1
            },
            {
                "size": "4х100",
                "koef": "0.003234",
                "metrs": 1
            },
            {
                "size": "5х25",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": "5х30",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "5х40",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "5х50",
                "koef": "0.002021",
                "metrs": 1
            },
            {
                "size": "5х60",
                "koef": "0.002426",
                "metrs": 1
            },
            {
                "size": "5х70",
                "koef": "0.00283",
                "metrs": 1
            },
            {
                "size": "5х80",
                "koef": "0.003234",
                "metrs": 1
            },
            {
                "size": "5х100",
                "koef": "0.004043",
                "metrs": 1
            },
            {
                "size": "6х40",
                "koef": "0.001941",
                "metrs": 1
            },
            {
                "size": "6х50",
                "koef": "0.002426",
                "metrs": 1
            },
            {
                "size": "6х60",
                "koef": "0.002911",
                "metrs": 1
            },
            {
                "size": "6х70",
                "koef": "0.003396",
                "metrs": 1
            },
            {
                "size": "6х100",
                "koef": "0.004851",
                "metrs": 1
            },
            {
                "size": "8х40",
                "koef": "0.002587",
                "metrs": 1
            },
            {
                "size": "8х100",
                "koef": "0.006468",
                "metrs": 1
            }
        ],
        "Проволока": [
            {
                "size": "0.3",
                "koef": "0.000055",
                "metrs": 100
            },
            {
                "size": "0.4",
                "koef": "0.000099",
                "metrs": 100
            },
            {
                "size": "0.45",
                "koef": "0.000125",
                "metrs": 100
            },
            {
                "size": "0.5",
                "koef": "0.000154",
                "metrs": 100
            },
            {
                "size": "0.6",
                "koef": "0.000222",
                "metrs": 100
            },
            {
                "size": "0.7",
                "koef": "0.000302",
                "metrs": 100
            },
            {
                "size": "0.8",
                "koef": "0.000395",
                "metrs": 100
            },
            {
                "size": "0.85",
                "koef": "0.000445",
                "metrs": 100
            },
            {
                "size": "0.9",
                "koef": "0.000499",
                "metrs": 100
            },
            {
                "size": 1,
                "koef": "0.000617",
                "metrs": 100
            },
            {
                "size": "1.1",
                "koef": "0.000746",
                "metrs": 100
            },
            {
                "size": "1.2",
                "koef": "0.000888",
                "metrs": 100
            },
            {
                "size": "1.3",
                "koef": "0.001042",
                "metrs": 100
            },
            {
                "size": "1.4",
                "koef": "0.001208",
                "metrs": 100
            },
            {
                "size": "1.5",
                "koef": "0.001387",
                "metrs": 100
            },
            {
                "size": "1.6",
                "koef": "0.001578",
                "metrs": 100
            },
            {
                "size": "1.7",
                "koef": "0.001782",
                "metrs": 100
            },
            {
                "size": "1.8",
                "koef": "0.001998",
                "metrs": 100
            },
            {
                "size": "1.9",
                "koef": "0.002226",
                "metrs": 100
            },
            {
                "size": 2,
                "koef": "0.002466",
                "metrs": 100
            },
            {
                "size": "2.1",
                "koef": "0.002719",
                "metrs": 100
            },
            {
                "size": "2.2",
                "koef": "0.002984",
                "metrs": 100
            },
            {
                "size": "2.3",
                "koef": "0.003261",
                "metrs": 100
            },
            {
                "size": "2.4",
                "koef": "0.003551",
                "metrs": 100
            },
            {
                "size": "2.5",
                "koef": "0.003853",
                "metrs": 100
            },
            {
                "size": "2.6",
                "koef": "0.004168",
                "metrs": 100
            },
            {
                "size": "2.7",
                "koef": "0.004495",
                "metrs": 100
            },
            {
                "size": "2.8",
                "koef": "0.004834",
                "metrs": 100
            },
            {
                "size": "2.9",
                "koef": "0.005185",
                "metrs": 100
            },
            {
                "size": "2.95",
                "koef": "0.005365",
                "metrs": 100
            },
            {
                "size": 3,
                "koef": "0.005549",
                "metrs": 100
            },
            {
                "size": "3.1",
                "koef": "0.005925",
                "metrs": 100
            },
            {
                "size": "3.2",
                "koef": "0.006313",
                "metrs": 100
            },
            {
                "size": "3.3",
                "koef": "0.006714",
                "metrs": 100
            },
            {
                "size": "3.4",
                "koef": "0.007127",
                "metrs": 100
            },
            {
                "size": "3.5",
                "koef": "0.007553",
                "metrs": 100
            },
            {
                "size": "3.6",
                "koef": "0.00799",
                "metrs": 100
            },
            {
                "size": "3.7",
                "koef": "0.00844",
                "metrs": 100
            },
            {
                "size": "3.8",
                "koef": "0.008903",
                "metrs": 100
            },
            {
                "size": "3.85",
                "koef": "0.009139",
                "metrs": 100
            },
            {
                "size": "3.9",
                "koef": "0.009378",
                "metrs": 100
            },
            {
                "size": 4,
                "koef": "0.009865",
                "metrs": 100
            },
            {
                "size": "4.2",
                "koef": "0.01088",
                "metrs": 100
            },
            {
                "size": "4.38",
                "koef": "0.01183",
                "metrs": 100
            },
            {
                "size": "4.4",
                "koef": "0.01194",
                "metrs": 100
            },
            {
                "size": "4.5",
                "koef": "0.01248",
                "metrs": 100
            },
            {
                "size": "4.6",
                "koef": "0.01305",
                "metrs": 100
            },
            {
                "size": "4.7",
                "koef": "0.01362",
                "metrs": 100
            },
            {
                "size": "4.8",
                "koef": "0.01421",
                "metrs": 100
            },
            {
                "size": 5,
                "koef": "0.01541",
                "metrs": 100
            },
            {
                "size": "5.2",
                "koef": "0.01667",
                "metrs": 100
            },
            {
                "size": "5.3",
                "koef": "0.01732",
                "metrs": 100
            },
            {
                "size": "5.5",
                "koef": "0.01865",
                "metrs": 100
            },
            {
                "size": "5.7",
                "koef": "0.02003",
                "metrs": 100
            },
            {
                "size": 6,
                "koef": "0.0222",
                "metrs": 100
            },
            {
                "size": "6.5",
                "koef": "0.02605",
                "metrs": 100
            },
            {
                "size": "6.9",
                "koef": "0.02935",
                "metrs": 100
            },
            {
                "size": 7,
                "koef": "0.03021",
                "metrs": 100
            },
            {
                "size": "7.5",
                "koef": "0.03468",
                "metrs": 100
            },
            {
                "size": 8,
                "koef": "0.000395",
                "metrs": 100
            },
            {
                "size": "8.5",
                "koef": "0.000445",
                "metrs": 100
            },
            {
                "size": 9,
                "koef": "0.04994",
                "metrs": 100
            },
            {
                "size": 10,
                "koef": "0.06165",
                "metrs": 100
            },
            {
                "size": 11,
                "koef": "0.0746",
                "metrs": 100
            },
            {
                "size": 12,
                "koef": "0.08878",
                "metrs": 100
            },
            {
                "size": 13,
                "koef": "0.1042",
                "metrs": 100
            },
            {
                "size": 14,
                "koef": "0.1208",
                "metrs": 100
            }
        ],
        "Проволока оцинкованная": [
            {
                "size": "0.5",
                "koef": "0.000159",
                "metrs": 100
            },
            {
                "size": "0.6",
                "koef": "0.000229",
                "metrs": 100
            },
            {
                "size": "0.7",
                "koef": "0.000311",
                "metrs": 100
            },
            {
                "size": "0.8",
                "koef": "0.000406",
                "metrs": 100
            },
            {
                "size": "0.9",
                "koef": "0.000514",
                "metrs": 100
            },
            {
                "size": 1,
                "koef": "0.000635",
                "metrs": 100
            },
            {
                "size": "1.1",
                "koef": "0.000768",
                "metrs": 100
            },
            {
                "size": "1.2",
                "koef": "0.000914",
                "metrs": 100
            },
            {
                "size": "1.3",
                "koef": "0.001073",
                "metrs": 100
            },
            {
                "size": "1.4",
                "koef": "0.001245",
                "metrs": 100
            },
            {
                "size": "1.5",
                "koef": "0.001429",
                "metrs": 100
            },
            {
                "size": "1.6",
                "koef": "0.001626",
                "metrs": 100
            },
            {
                "size": "1.7",
                "koef": "0.001835",
                "metrs": 100
            },
            {
                "size": "1.8",
                "koef": "0.002058",
                "metrs": 100
            },
            {
                "size": "1.9",
                "koef": "0.002292",
                "metrs": 100
            },
            {
                "size": 2,
                "koef": "0.00254",
                "metrs": 100
            },
            {
                "size": "2.1",
                "koef": "0.0028",
                "metrs": 100
            },
            {
                "size": "2.2",
                "koef": "0.003074",
                "metrs": 100
            },
            {
                "size": "2.3",
                "koef": "0.003359",
                "metrs": 100
            },
            {
                "size": "2.4",
                "koef": "0.003658",
                "metrs": 100
            },
            {
                "size": "2.5",
                "koef": "0.003969",
                "metrs": 100
            },
            {
                "size": "2.6",
                "koef": "0.004293",
                "metrs": 100
            },
            {
                "size": "2.7",
                "koef": "0.004629",
                "metrs": 100
            },
            {
                "size": "2.8",
                "koef": "0.004979",
                "metrs": 100
            },
            {
                "size": "2.9",
                "koef": "0.005341",
                "metrs": 100
            },
            {
                "size": 3,
                "koef": "0.005715",
                "metrs": 100
            },
            {
                "size": "3.1",
                "koef": "0.006103",
                "metrs": 100
            },
            {
                "size": "3.2",
                "koef": "0.006503",
                "metrs": 100
            },
            {
                "size": "3.3",
                "koef": "0.006916",
                "metrs": 100
            },
            {
                "size": "3.4",
                "koef": "0.007341",
                "metrs": 100
            },
            {
                "size": "3.5",
                "koef": "0.007779",
                "metrs": 100
            },
            {
                "size": "3.6",
                "koef": "0.00823",
                "metrs": 100
            },
            {
                "size": "3.7",
                "koef": "0.008694",
                "metrs": 100
            },
            {
                "size": "3.8",
                "koef": "0.00917",
                "metrs": 100
            },
            {
                "size": "3.9",
                "koef": "0.009659",
                "metrs": 100
            },
            {
                "size": 4,
                "koef": "0.01016",
                "metrs": 100
            },
            {
                "size": "4.5",
                "koef": "0.01286",
                "metrs": 100
            },
            {
                "size": "4.8",
                "koef": "0.01463",
                "metrs": 100
            },
            {
                "size": 5,
                "koef": "0.01588",
                "metrs": 100
            },
            {
                "size": "5.5",
                "koef": "0.01921",
                "metrs": 100
            },
            {
                "size": 6,
                "koef": "0.02286",
                "metrs": 100
            },
            {
                "size": 8,
                "koef": "0.04064",
                "metrs": 100
            },
            {
                "size": "9.9",
                "koef": "0.06224",
                "metrs": 100
            }
        ],
        "Рельс": [
            {
                "size": " КР100",
                "koef": "0.08905",
                "metrs": 1
            },
            {
                "size": " Р65",
                "koef": "0.06488",
                "metrs": 1
            }
        ],
        "Сутунка": [
            {
                "size": " 50х500",
                "koef": "0.1963",
                "metrs": 1
            },
            {
                "size": " 60х500",
                "koef": "0.2355",
                "metrs": 1
            },
            {
                "size": " 70х500",
                "koef": "0.2748",
                "metrs": 1
            }
        ],
        "Труба ВГП": [
            {
                "size": " 10х2,8",
                "koef": "0.00098",
                "metrs": 1
            },
            {
                "size": " 15х2,35",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 15х2,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": " 15х2,8",
                "koef": "0.00128",
                "metrs": 1
            },
            {
                "size": " 15х3,2",
                "koef": "0.00143",
                "metrs": 1
            },
            {
                "size": " 20х2,35",
                "koef": "0.00142",
                "metrs": 1
            },
            {
                "size": " 20х2,5",
                "koef": "0.0015",
                "metrs": 1
            },
            {
                "size": " 20х2,8",
                "koef": "0.00166",
                "metrs": 1
            },
            {
                "size": " 20х3,2",
                "koef": "0.00186",
                "metrs": 1
            },
            {
                "size": " 25х2,8",
                "koef": "0.00212",
                "metrs": 1
            },
            {
                "size": " 25х3,2",
                "koef": "0.00239",
                "metrs": 1
            },
            {
                "size": " 25х4",
                "koef": "0.00291",
                "metrs": 1
            },
            {
                "size": " 32х2,8",
                "koef": "0.00273",
                "metrs": 1
            },
            {
                "size": " 32х3,2",
                "koef": "0.00309",
                "metrs": 1
            },
            {
                "size": " 32х4",
                "koef": "0.00378",
                "metrs": 1
            },
            {
                "size": " 40х3",
                "koef": "0.00333",
                "metrs": 1
            },
            {
                "size": " 40х3,5",
                "koef": "0.00384",
                "metrs": 1
            },
            {
                "size": " 40х4",
                "koef": "0.00434",
                "metrs": 1
            },
            {
                "size": " 50х3",
                "koef": "0.00422",
                "metrs": 1
            },
            {
                "size": " 50х3,5",
                "koef": "0.00488",
                "metrs": 1
            },
            {
                "size": " 50х4",
                "koef": "0.00616",
                "metrs": 1
            },
            {
                "size": " 65х3,2",
                "koef": "0.00571",
                "metrs": 1
            },
            {
                "size": " 65х4",
                "koef": "0.00705",
                "metrs": 1
            },
            {
                "size": " 65х4,5",
                "koef": "0.00788",
                "metrs": 1
            },
            {
                "size": " 80х3,5",
                "koef": "0.00734",
                "metrs": 1
            },
            {
                "size": " 80х4",
                "koef": "0.00834",
                "metrs": 1
            },
            {
                "size": " 80х4,5",
                "koef": "0.00932",
                "metrs": 1
            },
            {
                "size": " 100х4",
                "koef": "0.01085",
                "metrs": 1
            },
            {
                "size": " 100х4,5",
                "koef": "0.01215",
                "metrs": 1
            },
            {
                "size": " 100х5",
                "koef": "0.01344",
                "metrs": 1
            }
        ],
        "Труба ВГП оцинкованная": [
            {
                "size": " 15х2,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 15х2,8",
                "koef": "0.001318",
                "metrs": 1
            },
            {
                "size": " 15х3,2",
                "koef": "0.001473",
                "metrs": 1
            },
            {
                "size": " 20х2,5",
                "koef": "0.001545",
                "metrs": 1
            },
            {
                "size": " 20х2,8",
                "koef": "0.00171",
                "metrs": 1
            },
            {
                "size": " 25х2,8",
                "koef": "0.002184",
                "metrs": 1
            },
            {
                "size": " 25х3,2",
                "koef": "0.002462",
                "metrs": 1
            },
            {
                "size": " 32х2,8",
                "koef": "0.002812",
                "metrs": 1
            },
            {
                "size": " 32х3,2",
                "koef": "0.003183",
                "metrs": 1
            },
            {
                "size": " 40х3",
                "koef": "0.00343",
                "metrs": 1
            },
            {
                "size": " 40х3,5",
                "koef": "0.003955",
                "metrs": 1
            },
            {
                "size": " 50х3",
                "koef": "0.004347",
                "metrs": 1
            },
            {
                "size": " 50х3,5",
                "koef": "0.005026",
                "metrs": 1
            },
            {
                "size": " 65х3,2",
                "koef": "0.005881",
                "metrs": 1
            },
            {
                "size": " 65х4",
                "koef": "0.007261",
                "metrs": 1
            },
            {
                "size": " 65х4,5",
                "koef": "0.008116",
                "metrs": 1
            },
            {
                "size": " 80х3,5",
                "koef": "0.00756",
                "metrs": 1
            },
            {
                "size": " 80х4",
                "koef": "0.00859",
                "metrs": 1
            },
            {
                "size": " 80х4,5",
                "koef": "0.0096",
                "metrs": 1
            },
            {
                "size": " 100х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": " 100х4,5",
                "koef": "0.01251",
                "metrs": 1
            }
        ],
        "Труба ПНД": [
            {
                "size": " 20х2",
                "koef": "0.000116",
                "metrs": 1
            },
            {
                "size": " 20х2,3",
                "koef": "0.000134",
                "metrs": 1
            },
            {
                "size": " 25х2",
                "koef": "0.000151",
                "metrs": 1
            },
            {
                "size": " 25х2,3",
                "koef": "0.000151",
                "metrs": 1
            },
            {
                "size": " 25х2,8",
                "koef": "0.000201",
                "metrs": 1
            },
            {
                "size": " 32х2",
                "koef": "0.000193",
                "metrs": 1
            },
            {
                "size": " 32х2,3",
                "koef": "0.000277",
                "metrs": 1
            },
            {
                "size": " 32х2,4",
                "koef": "0.000233",
                "metrs": 1
            },
            {
                "size": " 32х3",
                "koef": "0.000277",
                "metrs": 1
            },
            {
                "size": " 32х3,6",
                "koef": "0.000329",
                "metrs": 1
            },
            {
                "size": " 40х2",
                "koef": "0.000249",
                "metrs": 1
            },
            {
                "size": " 40х2,3",
                "koef": "0.000249",
                "metrs": 1
            },
            {
                "size": " 40х2,4",
                "koef": "0.000292",
                "metrs": 1
            },
            {
                "size": " 40х3",
                "koef": "0.000358",
                "metrs": 1
            },
            {
                "size": " 40х3,7",
                "koef": "0.000427",
                "metrs": 1
            },
            {
                "size": " 40х4,5",
                "koef": "0.000511",
                "metrs": 1
            },
            {
                "size": " 50х2",
                "koef": "0.000308",
                "metrs": 1
            },
            {
                "size": " 50х2,4",
                "koef": "0.000376",
                "metrs": 1
            },
            {
                "size": " 50х2,9",
                "koef": "0.000436",
                "metrs": 1
            },
            {
                "size": " 50х3",
                "koef": "0.000449",
                "metrs": 1
            },
            {
                "size": " 50х3,7",
                "koef": "0.000552",
                "metrs": 1
            },
            {
                "size": " 50х4,6",
                "koef": "0.000663",
                "metrs": 1
            },
            {
                "size": " 50х5,6",
                "koef": "0.000798",
                "metrs": 1
            },
            {
                "size": " 63х2,5",
                "koef": "0.000488",
                "metrs": 1
            },
            {
                "size": " 63х3",
                "koef": "0.000582",
                "metrs": 1
            },
            {
                "size": " 63х3,6",
                "koef": "0.000682",
                "metrs": 1
            },
            {
                "size": " 63х3,8",
                "koef": "0.000715",
                "metrs": 1
            },
            {
                "size": " 63х4,7",
                "koef": "0.000885",
                "metrs": 1
            },
            {
                "size": " 63х5,8",
                "koef": "0.00105",
                "metrs": 1
            },
            {
                "size": " 63х7,1",
                "koef": "0.00127",
                "metrs": 1
            },
            {
                "size": " 75х2,9",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 75х3,6",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 75х4,3",
                "koef": "0.00097",
                "metrs": 1
            },
            {
                "size": " 75х4,5",
                "koef": "0.00101",
                "metrs": 1
            },
            {
                "size": " 75х5,6",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 75х6,8",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": " 75х8,4",
                "koef": "0.00179",
                "metrs": 1
            },
            {
                "size": " 90х3,5",
                "koef": "0.000969",
                "metrs": 1
            },
            {
                "size": " 90х4,3",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 90х5,1",
                "koef": "0.0014",
                "metrs": 1
            },
            {
                "size": " 90х5,2",
                "koef": "0.0014",
                "metrs": 1
            },
            {
                "size": " 90х5,4",
                "koef": "0.00145",
                "metrs": 1
            },
            {
                "size": " 90х6,7",
                "koef": "0.0018",
                "metrs": 1
            },
            {
                "size": " 90х8,2",
                "koef": "0.00212",
                "metrs": 1
            },
            {
                "size": " 90х10,1",
                "koef": "0.00259",
                "metrs": 1
            },
            {
                "size": " 110х4,2",
                "koef": "0.00142",
                "metrs": 1
            },
            {
                "size": " 110х5,3",
                "koef": "0.00178",
                "metrs": 1
            },
            {
                "size": " 110х6,3",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 110х6,6",
                "koef": "0.00216",
                "metrs": 1
            },
            {
                "size": " 110х8,1",
                "koef": "0.00266",
                "metrs": 1
            },
            {
                "size": " 110х10",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": " 110х12,3",
                "koef": "0.00384",
                "metrs": 1
            },
            {
                "size": " 110х15,1",
                "koef": "0.00449",
                "metrs": 1
            },
            {
                "size": " 125х4,8",
                "koef": "0.00183",
                "metrs": 1
            },
            {
                "size": " 125х6",
                "koef": "0.00229",
                "metrs": 1
            },
            {
                "size": " 125х7,1",
                "koef": "0.00266",
                "metrs": 1
            },
            {
                "size": " 125х7,4",
                "koef": "0.00275",
                "metrs": 1
            },
            {
                "size": " 125х9,2",
                "koef": "0.00342",
                "metrs": 1
            },
            {
                "size": " 125х11,4",
                "koef": "0.00408",
                "metrs": 1
            },
            {
                "size": " 125х14",
                "koef": "0.00496",
                "metrs": 1
            },
            {
                "size": " 125х17,1",
                "koef": "0.00578",
                "metrs": 1
            },
            {
                "size": " 140х5,4",
                "koef": "0.00231",
                "metrs": 1
            },
            {
                "size": " 140х6,7",
                "koef": "0.00289",
                "metrs": 1
            },
            {
                "size": " 140х8",
                "koef": "0.00335",
                "metrs": 1
            },
            {
                "size": " 140х8,3",
                "koef": "0.00346",
                "metrs": 1
            },
            {
                "size": " 140х10,3",
                "koef": "0.00429",
                "metrs": 1
            },
            {
                "size": " 140х12,7",
                "koef": "0.00508",
                "metrs": 1
            },
            {
                "size": " 140х15,7",
                "koef": "0.00624",
                "metrs": 1
            },
            {
                "size": " 140х19,2",
                "koef": "0.00727",
                "metrs": 1
            },
            {
                "size": " 160х6,2",
                "koef": "0.00303",
                "metrs": 1
            },
            {
                "size": " 160х7,7",
                "koef": "0.00377",
                "metrs": 1
            },
            {
                "size": " 160х9,1",
                "koef": "0.00435",
                "metrs": 1
            },
            {
                "size": " 160х9,5",
                "koef": "0.00451",
                "metrs": 1
            },
            {
                "size": " 160х11,8",
                "koef": "0.00561",
                "metrs": 1
            },
            {
                "size": " 160х14,6",
                "koef": "0.00667",
                "metrs": 1
            },
            {
                "size": " 160х17,9",
                "koef": "0.00813",
                "metrs": 1
            },
            {
                "size": " 160х21,9",
                "koef": "0.00946",
                "metrs": 1
            },
            {
                "size": " 180х6,9",
                "koef": "0.00378",
                "metrs": 1
            },
            {
                "size": " 180х8,6",
                "koef": "0.00466",
                "metrs": 1
            },
            {
                "size": " 180х10,2",
                "koef": "0.00547",
                "metrs": 1
            },
            {
                "size": " 180х10,7",
                "koef": "0.00571",
                "metrs": 1
            },
            {
                "size": " 180х13,3",
                "koef": "0.00698",
                "metrs": 1
            },
            {
                "size": " 180х16,4",
                "koef": "0.00843",
                "metrs": 1
            },
            {
                "size": " 180х20,1",
                "koef": "0.0101",
                "metrs": 1
            },
            {
                "size": " 180х24,6",
                "koef": "0.012",
                "metrs": 1
            },
            {
                "size": " 200х7,7",
                "koef": "0.00468",
                "metrs": 1
            },
            {
                "size": " 200х9,6",
                "koef": "0.00577",
                "metrs": 1
            },
            {
                "size": " 200х11,4",
                "koef": "0.00678",
                "metrs": 1
            },
            {
                "size": " 200х11,9",
                "koef": "0.00704",
                "metrs": 1
            },
            {
                "size": " 200х14,7",
                "koef": "0.00856",
                "metrs": 1
            },
            {
                "size": " 200х18,2",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": " 200х22,4",
                "koef": "0.0125",
                "metrs": 1
            },
            {
                "size": " 200х27,4",
                "koef": "0.0148",
                "metrs": 1
            },
            {
                "size": " 225х6,9",
                "koef": "0.00476",
                "metrs": 1
            },
            {
                "size": " 225х8,6",
                "koef": "0.00588",
                "metrs": 1
            },
            {
                "size": " 225х10,8",
                "koef": "0.00729",
                "metrs": 1
            },
            {
                "size": " 225х12,8",
                "koef": "0.00855",
                "metrs": 1
            },
            {
                "size": " 225х13,4",
                "koef": "0.00894",
                "metrs": 1
            },
            {
                "size": " 225х16,6",
                "koef": "0.0109",
                "metrs": 1
            },
            {
                "size": " 225х20,5",
                "koef": "0.0132",
                "metrs": 1
            },
            {
                "size": " 225х25,2",
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": " 225х30,8",
                "koef": "0.0187",
                "metrs": 1
            },
            {
                "size": " 250х7,7",
                "koef": "0.0059",
                "metrs": 1
            },
            {
                "size": " 250х9,6",
                "koef": "0.00729",
                "metrs": 1
            },
            {
                "size": " 250х11,9",
                "koef": "0.00892",
                "metrs": 1
            },
            {
                "size": " 250х14,2",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": " 250х14,8",
                "koef": "0.011",
                "metrs": 1
            },
            {
                "size": " 250х18,4",
                "koef": "0.0134",
                "metrs": 1
            },
            {
                "size": " 250х22,7",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": " 250х27,9",
                "koef": "0.0194",
                "metrs": 1
            },
            {
                "size": " 250х34,2",
                "koef": "0.0231",
                "metrs": 1
            },
            {
                "size": " 280х8,6",
                "koef": "0.00738",
                "metrs": 1
            },
            {
                "size": " 280х10,7",
                "koef": "0.00909",
                "metrs": 1
            },
            {
                "size": " 280х13,4",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": " 280х15,9",
                "koef": "0.0132",
                "metrs": 1
            },
            {
                "size": " 280х16,6",
                "koef": "0.0138",
                "metrs": 1
            },
            {
                "size": " 280х20,6",
                "koef": "0.0168",
                "metrs": 1
            },
            {
                "size": " 280х25,4",
                "koef": "0.0203",
                "metrs": 1
            },
            {
                "size": " 280х31,3",
                "koef": "0.0244",
                "metrs": 1
            },
            {
                "size": " 280х38,3",
                "koef": "0.0289",
                "metrs": 1
            },
            {
                "size": " 315х9,7",
                "koef": "0.00935",
                "metrs": 1
            },
            {
                "size": " 315х12,1",
                "koef": "0.0116",
                "metrs": 1
            },
            {
                "size": " 315х15",
                "koef": "0.0142",
                "metrs": 1
            },
            {
                "size": " 315х17,9",
                "koef": "0.0167",
                "metrs": 1
            },
            {
                "size": " 315х18,7",
                "koef": "0.0174",
                "metrs": 1
            },
            {
                "size": " 315х23,2",
                "koef": "0.0213",
                "metrs": 1
            },
            {
                "size": " 315х28,6",
                "koef": "0.0257",
                "metrs": 1
            },
            {
                "size": " 315х35,2",
                "koef": "0.0308",
                "metrs": 1
            },
            {
                "size": " 315х43,1",
                "koef": "0.0366",
                "metrs": 1
            },
            {
                "size": " 355х10,9",
                "koef": "0.0118",
                "metrs": 1
            },
            {
                "size": " 355х13,6",
                "koef": "0.0146",
                "metrs": 1
            },
            {
                "size": " 355х16,9",
                "koef": "0.018",
                "metrs": 1
            },
            {
                "size": " 355х20,1",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": " 355х21,1",
                "koef": "0.0222",
                "metrs": 1
            },
            {
                "size": " 355х26,1",
                "koef": "0.027",
                "metrs": 1
            },
            {
                "size": " 355х32,2",
                "koef": "0.0326",
                "metrs": 1
            },
            {
                "size": " 355х39,7",
                "koef": "0.0392",
                "metrs": 1
            },
            {
                "size": " 355х48,5",
                "koef": "0.0464",
                "metrs": 1
            },
            {
                "size": " 400х12,3",
                "koef": "0.0151",
                "metrs": 1
            },
            {
                "size": " 400х15,3",
                "koef": "0.0186",
                "metrs": 1
            },
            {
                "size": " 400х19,1",
                "koef": "0.0229",
                "metrs": 1
            },
            {
                "size": " 400х22,7",
                "koef": "0.0269",
                "metrs": 1
            },
            {
                "size": " 400х23,7",
                "koef": "0.028",
                "metrs": 1
            },
            {
                "size": " 400х29,4",
                "koef": "0.0342",
                "metrs": 1
            },
            {
                "size": " 400х36,3",
                "koef": "0.0414",
                "metrs": 1
            },
            {
                "size": " 400х44,7",
                "koef": "0.0497",
                "metrs": 1
            },
            {
                "size": " 400х54,7",
                "koef": "0.059",
                "metrs": 1
            },
            {
                "size": " 450х13,8",
                "koef": "0.019",
                "metrs": 1
            },
            {
                "size": " 450х17,2",
                "koef": "0.0235",
                "metrs": 1
            },
            {
                "size": " 450х21,5",
                "koef": "0.029",
                "metrs": 1
            },
            {
                "size": " 450х25,5",
                "koef": "0.034",
                "metrs": 1
            },
            {
                "size": " 450х26,7",
                "koef": "0.0355",
                "metrs": 1
            },
            {
                "size": " 450х33,1",
                "koef": "0.0433",
                "metrs": 1
            },
            {
                "size": " 450х40,9",
                "koef": "0.0524",
                "metrs": 1
            },
            {
                "size": " 500х15,3",
                "koef": "0.0234",
                "metrs": 1
            },
            {
                "size": " 500х19,1",
                "koef": "0.029",
                "metrs": 1
            },
            {
                "size": " 500х23,9",
                "koef": "0.0358",
                "metrs": 1
            },
            {
                "size": " 500х28,3",
                "koef": "0.042",
                "metrs": 1
            },
            {
                "size": " 500х29,7",
                "koef": "0.0439",
                "metrs": 1
            },
            {
                "size": " 500х36,8",
                "koef": "0.0535",
                "metrs": 1
            },
            {
                "size": " 500х45,4",
                "koef": "0.0647",
                "metrs": 1
            },
            {
                "size": " 560х17,2",
                "koef": "0.0294",
                "metrs": 1
            },
            {
                "size": " 560х21,4",
                "koef": "0.0363",
                "metrs": 1
            },
            {
                "size": " 560х26,7",
                "koef": "0.0448",
                "metrs": 1
            },
            {
                "size": " 560х31,7",
                "koef": "0.0526",
                "metrs": 1
            },
            {
                "size": " 560х33,2",
                "koef": "0.055",
                "metrs": 1
            },
            {
                "size": " 560х41,2",
                "koef": "0.0671",
                "metrs": 1
            },
            {
                "size": " 560х50,8",
                "koef": "0.081",
                "metrs": 1
            },
            {
                "size": " 630х19",
                "koef": "0.0371",
                "metrs": 1
            },
            {
                "size": " 630х24,1",
                "koef": "0.046",
                "metrs": 1
            },
            {
                "size": " 630х30",
                "koef": "0.0565",
                "metrs": 1
            },
            {
                "size": " 630х35,7",
                "koef": "0.0666",
                "metrs": 1
            },
            {
                "size": " 630х37,4",
                "koef": "0.0696",
                "metrs": 1
            },
            {
                "size": " 630х46,3",
                "koef": "0.0848",
                "metrs": 1
            },
            {
                "size": " 630х57,2",
                "koef": "0.103",
                "metrs": 1
            },
            {
                "size": " 710х27,2",
                "koef": "0.0585",
                "metrs": 1
            },
            {
                "size": " 710х33,9",
                "koef": "0.0721",
                "metrs": 1
            },
            {
                "size": " 710х40,2",
                "koef": "0.0847",
                "metrs": 1
            },
            {
                "size": " 710х42,1",
                "koef": "0.0884",
                "metrs": 1
            },
            {
                "size": " 710х52,2",
                "koef": "0.108",
                "metrs": 1
            },
            {
                "size": " 710х64,5",
                "koef": "0.131",
                "metrs": 1
            },
            {
                "size": " 800х30,6",
                "koef": "0.0741",
                "metrs": 1
            },
            {
                "size": " 800х38,1",
                "koef": "0.0914",
                "metrs": 1
            },
            {
                "size": " 800х45,3",
                "koef": "0.108",
                "metrs": 1
            },
            {
                "size": " 800х47,4",
                "koef": "0.112",
                "metrs": 1
            },
            {
                "size": " 800х58,8",
                "koef": "0.137",
                "metrs": 1
            },
            {
                "size": " 900х34,4",
                "koef": "0.0938",
                "metrs": 1
            },
            {
                "size": " 900х42,9",
                "koef": "0.116",
                "metrs": 1
            },
            {
                "size": " 900х51",
                "koef": "0.136",
                "metrs": 1
            },
            {
                "size": " 900х53,3",
                "koef": "0.142",
                "metrs": 1
            },
            {
                "size": " 900х66,1",
                "koef": "0.173",
                "metrs": 1
            },
            {
                "size": " 1000х38,2",
                "koef": "0.116",
                "metrs": 1
            },
            {
                "size": " 1000х47,7",
                "koef": "0.143",
                "metrs": 1
            },
            {
                "size": " 1000х56,6",
                "koef": "0.168",
                "metrs": 1
            },
            {
                "size": " 1000х59,3",
                "koef": "0.175",
                "metrs": 1
            },
            {
                "size": " 1000х73,5",
                "koef": "0.214",
                "metrs": 1
            },
            {
                "size": " 1200х45,9",
                "koef": "0.167",
                "metrs": 1
            },
            {
                "size": " 1200х52,2",
                "koef": "0.206",
                "metrs": 1
            },
            {
                "size": " 1200х68",
                "koef": "0.242",
                "metrs": 1
            },
            {
                "size": " 1200х71,1",
                "koef": "0.252",
                "metrs": 1
            },
            {
                "size": " 1400х53,5",
                "koef": "0.227",
                "metrs": 1
            },
            {
                "size": " 1400х66,7",
                "koef": "0.28",
                "metrs": 1
            },
            {
                "size": " 1600х61,2",
                "koef": "0.296",
                "metrs": 1
            }
        ],
        "Труба Б/У круглая": [
            {
                "size": " 48х3,5",
                "koef": "0.003841",
                "metrs": 1
            },
            {
                "size": " 57х3",
                "koef": "0.003995",
                "metrs": 1
            },
            {
                "size": " 57х3,5",
                "koef": "0.004618",
                "metrs": 1
            },
            {
                "size": " 57х4",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": " 57х5",
                "koef": "0.006412",
                "metrs": 1
            },
            {
                "size": " 60х5",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": " 73х5,5",
                "koef": "0.009156",
                "metrs": 1
            },
            {
                "size": " 76х3",
                "koef": "0.005401",
                "metrs": 1
            },
            {
                "size": " 76х3,5",
                "koef": "0.006258",
                "metrs": 1
            },
            {
                "size": " 76х4",
                "koef": "0.007103",
                "metrs": 1
            },
            {
                "size": " 76х5",
                "koef": "0.008755",
                "metrs": 1
            },
            {
                "size": " 76х6",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": " 89х3",
                "koef": "0.006363",
                "metrs": 1
            },
            {
                "size": " 89х3,2",
                "koef": "0.006771",
                "metrs": 1
            },
            {
                "size": " 89х3,5",
                "koef": "0.00738",
                "metrs": 1
            },
            {
                "size": " 89х4",
                "koef": "0.008385",
                "metrs": 1
            },
            {
                "size": " 89х4,5",
                "koef": "0.009378",
                "metrs": 1
            },
            {
                "size": " 89х5",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": " 89х6",
                "koef": "0.01228",
                "metrs": 1
            },
            {
                "size": " 89х6,5",
                "koef": "0.01322",
                "metrs": 1
            },
            {
                "size": " 89х7",
                "koef": "0.01416",
                "metrs": 1
            },
            {
                "size": " 89х8",
                "koef": "0.01598",
                "metrs": 1
            },
            {
                "size": " 102х4",
                "koef": "0.009667",
                "metrs": 1
            },
            {
                "size": " 102х5",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": " 102х6",
                "koef": "0.01421",
                "metrs": 1
            },
            {
                "size": " 102х6,5",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": " 108х3",
                "koef": "0.007768",
                "metrs": 1
            },
            {
                "size": " 108х3,5",
                "koef": "0.00902",
                "metrs": 1
            },
            {
                "size": " 108х4",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": " 108х4,5",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": " 108х5",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": " 108х6",
                "koef": "0.01509",
                "metrs": 1
            },
            {
                "size": " 108х7",
                "koef": "0.01744",
                "metrs": 1
            },
            {
                "size": " 108х8",
                "koef": "0.01973",
                "metrs": 1
            },
            {
                "size": " 114х4",
                "koef": "0.01085",
                "metrs": 1
            },
            {
                "size": " 114х4,5",
                "koef": "0.01215",
                "metrs": 1
            },
            {
                "size": " 114х5",
                "koef": "0.01344",
                "metrs": 1
            },
            {
                "size": " 114х6",
                "koef": "0.01598",
                "metrs": 1
            },
            {
                "size": " 114х7",
                "koef": "0.01847",
                "metrs": 1
            },
            {
                "size": " 114х7,5",
                "koef": "0.0197",
                "metrs": 1
            },
            {
                "size": " 114х8",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": " 114х9",
                "koef": "0.02331",
                "metrs": 1
            },
            {
                "size": " 114х10",
                "koef": "0.02565",
                "metrs": 1
            },
            {
                "size": " 120х6",
                "koef": "0.01687",
                "metrs": 1
            },
            {
                "size": " 127х4,5",
                "koef": "0.01359",
                "metrs": 1
            },
            {
                "size": " 127х5",
                "koef": "0.01504",
                "metrs": 1
            },
            {
                "size": " 127х6",
                "koef": "0.0179",
                "metrs": 1
            },
            {
                "size": " 133х4",
                "koef": "0.01273",
                "metrs": 1
            },
            {
                "size": " 133х5",
                "koef": "0.01578",
                "metrs": 1
            },
            {
                "size": " 133х6",
                "koef": "0.01879",
                "metrs": 1
            },
            {
                "size": " 133х7",
                "koef": "0.02175",
                "metrs": 1
            },
            {
                "size": " 133х8",
                "koef": "0.02466",
                "metrs": 1
            },
            {
                "size": " 140х5",
                "koef": "0.01665",
                "metrs": 1
            },
            {
                "size": " 140х6",
                "koef": "0.01983",
                "metrs": 1
            },
            {
                "size": " 146х5",
                "koef": "0.01739",
                "metrs": 1
            },
            {
                "size": " 146х6",
                "koef": "0.02072",
                "metrs": 1
            },
            {
                "size": " 146х7",
                "koef": "0.024",
                "metrs": 1
            },
            {
                "size": " 146х8",
                "koef": "0.02723",
                "metrs": 1
            },
            {
                "size": " 146х9",
                "koef": "0.03041",
                "metrs": 1
            },
            {
                "size": " 146х10",
                "koef": "0.03354",
                "metrs": 1
            },
            {
                "size": " 159х4",
                "koef": "0.01529",
                "metrs": 1
            },
            {
                "size": " 159х4,5",
                "koef": "0.01715",
                "metrs": 1
            },
            {
                "size": " 159х5",
                "koef": "0.01899",
                "metrs": 1
            },
            {
                "size": " 159х6",
                "koef": "0.02264",
                "metrs": 1
            },
            {
                "size": " 159х7",
                "koef": "0.02624",
                "metrs": 1
            },
            {
                "size": " 159х8",
                "koef": "0.02979",
                "metrs": 1
            },
            {
                "size": " 159х9",
                "koef": "0.03329",
                "metrs": 1
            },
            {
                "size": " 159х10",
                "koef": "0.03675",
                "metrs": 1
            },
            {
                "size": " 168х4",
                "koef": "0.01618",
                "metrs": 1
            },
            {
                "size": " 168х4,5",
                "koef": "0.01814",
                "metrs": 1
            },
            {
                "size": " 168х5",
                "koef": "0.0201",
                "metrs": 1
            },
            {
                "size": " 168х6",
                "koef": "0.02397",
                "metrs": 1
            },
            {
                "size": " 168х7",
                "koef": "0.02779",
                "metrs": 1
            },
            {
                "size": " 168х8",
                "koef": "0.03157",
                "metrs": 1
            },
            {
                "size": " 168х9",
                "koef": "0.03529",
                "metrs": 1
            },
            {
                "size": " 168х9,5",
                "koef": "0.03713",
                "metrs": 1
            },
            {
                "size": " 168х10",
                "koef": "0.03897",
                "metrs": 1
            },
            {
                "size": " 168х11",
                "koef": "0.04259",
                "metrs": 1
            },
            {
                "size": " 178х6",
                "koef": "0.02545",
                "metrs": 1
            },
            {
                "size": " 178х7",
                "koef": "0.02952",
                "metrs": 1
            },
            {
                "size": " 178х8",
                "koef": "0.03354",
                "metrs": 1
            },
            {
                "size": " 178х8,5",
                "koef": "0.03553",
                "metrs": 1
            },
            {
                "size": " 178х9",
                "koef": "0.03751",
                "metrs": 1
            },
            {
                "size": " 178х10",
                "koef": "0.04143",
                "metrs": 1
            },
            {
                "size": " 203х21",
                "koef": "0.09426",
                "metrs": 1
            },
            {
                "size": " 219х4",
                "koef": "0.02121",
                "metrs": 1
            },
            {
                "size": " 219х4,5",
                "koef": "0.0238",
                "metrs": 1
            },
            {
                "size": " 219х5",
                "koef": "0.02639",
                "metrs": 1
            },
            {
                "size": " 219х6",
                "koef": "0.03152",
                "metrs": 1
            },
            {
                "size": " 219х6,5",
                "koef": "0.03406",
                "metrs": 1
            },
            {
                "size": " 219х6,7",
                "koef": "0.03508",
                "metrs": 1
            },
            {
                "size": " 219х7",
                "koef": "0.0366",
                "metrs": 1
            },
            {
                "size": " 219х8",
                "koef": "0.04163",
                "metrs": 1
            },
            {
                "size": " 219х8,5",
                "koef": "0.04413",
                "metrs": 1
            },
            {
                "size": " 219х9",
                "koef": "0.04661",
                "metrs": 1
            },
            {
                "size": " 219х10",
                "koef": "0.05154",
                "metrs": 1
            },
            {
                "size": " 219х11",
                "koef": "0.05643",
                "metrs": 1
            },
            {
                "size": " 219х12",
                "koef": "0.06126",
                "metrs": 1
            },
            {
                "size": " 219х13",
                "koef": "0.06604",
                "metrs": 1
            },
            {
                "size": " 219х14",
                "koef": "0.07078",
                "metrs": 1
            },
            {
                "size": " 219х15",
                "koef": "0.07546",
                "metrs": 1
            },
            {
                "size": " 219х19",
                "koef": "0.09371",
                "metrs": 1
            },
            {
                "size": " 240х10",
                "koef": "0.05672",
                "metrs": 1
            },
            {
                "size": " 245х7",
                "koef": "0.04109",
                "metrs": 1
            },
            {
                "size": " 245х8",
                "koef": "0.04676",
                "metrs": 1
            },
            {
                "size": " 245х9",
                "koef": "0.05238",
                "metrs": 1
            },
            {
                "size": " 245х10",
                "koef": "0.05795",
                "metrs": 1
            },
            {
                "size": " 245х12",
                "koef": "0.06895",
                "metrs": 1
            },
            {
                "size": " 273х4",
                "koef": "0.02654",
                "metrs": 1
            },
            {
                "size": " 273х4,5",
                "koef": "0.0298",
                "metrs": 1
            },
            {
                "size": " 273х5",
                "koef": "0.03305",
                "metrs": 1
            },
            {
                "size": " 273х6",
                "koef": "0.03951",
                "metrs": 1
            },
            {
                "size": " 273х7",
                "koef": "0.04592",
                "metrs": 1
            },
            {
                "size": " 273х8",
                "koef": "0.05228",
                "metrs": 1
            },
            {
                "size": " 273х8,5",
                "koef": "0.05545",
                "metrs": 1
            },
            {
                "size": " 273х9",
                "koef": "0.0586",
                "metrs": 1
            },
            {
                "size": " 273х9,5",
                "koef": "0.06173",
                "metrs": 1
            },
            {
                "size": " 273х10",
                "koef": "0.06486",
                "metrs": 1
            },
            {
                "size": " 273х11",
                "koef": "0.07107",
                "metrs": 1
            },
            {
                "size": " 273х12",
                "koef": "0.07724",
                "metrs": 1
            },
            {
                "size": " 299х4",
                "koef": "0.0291",
                "metrs": 1
            },
            {
                "size": " 299х4,5",
                "koef": "0.03268",
                "metrs": 1
            },
            {
                "size": " 299х5",
                "koef": "0.03625",
                "metrs": 1
            },
            {
                "size": " 299х6",
                "koef": "0.04335",
                "metrs": 1
            },
            {
                "size": " 299х7",
                "koef": "0.05041",
                "metrs": 1
            },
            {
                "size": " 299х8",
                "koef": "0.05741",
                "metrs": 1
            },
            {
                "size": " 299х9",
                "koef": "0.06437",
                "metrs": 1
            },
            {
                "size": " 325х4",
                "koef": "0.03167",
                "metrs": 1
            },
            {
                "size": " 325х4,5",
                "koef": "0.03557",
                "metrs": 1
            },
            {
                "size": " 325х5",
                "koef": "0.03946",
                "metrs": 1
            },
            {
                "size": " 325х5,5",
                "koef": "0.04334",
                "metrs": 1
            },
            {
                "size": " 325х6",
                "koef": "0.0472",
                "metrs": 1
            },
            {
                "size": " 325х7",
                "koef": "0.0549",
                "metrs": 1
            },
            {
                "size": " 325х7,5",
                "koef": "0.05873",
                "metrs": 1
            },
            {
                "size": " 325х8",
                "koef": "0.06254",
                "metrs": 1
            },
            {
                "size": " 325х8,5",
                "koef": "0.06635",
                "metrs": 1
            },
            {
                "size": " 325х9",
                "koef": "0.07014",
                "metrs": 1
            },
            {
                "size": " 325х9,1",
                "koef": "0.07089",
                "metrs": 1
            },
            {
                "size": " 325х9,5",
                "koef": "0.07392",
                "metrs": 1
            },
            {
                "size": " 325х10",
                "koef": "0.07768",
                "metrs": 1
            },
            {
                "size": " 325х11",
                "koef": "0.08518",
                "metrs": 1
            },
            {
                "size": " 325х12",
                "koef": "0.09263",
                "metrs": 1
            },
            {
                "size": " 351х18",
                "koef": "0.1478",
                "metrs": 1
            },
            {
                "size": " 377х5",
                "koef": "0.04587",
                "metrs": 1
            },
            {
                "size": " 377х6",
                "koef": "0.0549",
                "metrs": 1
            },
            {
                "size": " 377х7",
                "koef": "0.06387",
                "metrs": 1
            },
            {
                "size": " 377х8",
                "koef": "0.0728",
                "metrs": 1
            },
            {
                "size": " 377х9",
                "koef": "0.08168",
                "metrs": 1
            },
            {
                "size": " 377х9,5",
                "koef": "0.0861",
                "metrs": 1
            },
            {
                "size": " 377х10",
                "koef": "0.09051",
                "metrs": 1
            },
            {
                "size": " 377х11",
                "koef": "0.09929",
                "metrs": 1
            },
            {
                "size": " 377х12",
                "koef": "0.108",
                "metrs": 1
            },
            {
                "size": " 408х25",
                "koef": "0.2361",
                "metrs": 1
            },
            {
                "size": " 426х5",
                "koef": "0.05191",
                "metrs": 1
            },
            {
                "size": " 426х6",
                "koef": "0.06215",
                "metrs": 1
            },
            {
                "size": " 426х7",
                "koef": "0.07233",
                "metrs": 1
            },
            {
                "size": " 426х8",
                "koef": "0.08247",
                "metrs": 1
            },
            {
                "size": " 426х9",
                "koef": "0.09255",
                "metrs": 1
            },
            {
                "size": " 426х9,1",
                "koef": "0.09356",
                "metrs": 1
            },
            {
                "size": " 426х9,5",
                "koef": "0.09758",
                "metrs": 1
            },
            {
                "size": " 426х10",
                "koef": "0.1026",
                "metrs": 1
            },
            {
                "size": " 426х10,5",
                "koef": "0.1076",
                "metrs": 1
            },
            {
                "size": " 426х11",
                "koef": "0.1126",
                "metrs": 1
            },
            {
                "size": " 426х12",
                "koef": "0.1225",
                "metrs": 1
            },
            {
                "size": " 426х13",
                "koef": "0.1324",
                "metrs": 1
            },
            {
                "size": " 426х14",
                "koef": "0.1422",
                "metrs": 1
            },
            {
                "size": " 426х18",
                "koef": "0.1811",
                "metrs": 1
            },
            {
                "size": " 429х9",
                "koef": "0.09322",
                "metrs": 1
            },
            {
                "size": " 478х9",
                "koef": "0.1041",
                "metrs": 1
            },
            {
                "size": " 478х10",
                "koef": "0.1154",
                "metrs": 1
            },
            {
                "size": " 508х6",
                "koef": "0.07428",
                "metrs": 1
            },
            {
                "size": " 508х7",
                "koef": "0.08649",
                "metrs": 1
            },
            {
                "size": " 508х8",
                "koef": "0.09865",
                "metrs": 1
            },
            {
                "size": " 508х9",
                "koef": "0.1108",
                "metrs": 1
            },
            {
                "size": " 508х9,5",
                "koef": "0.1168",
                "metrs": 1
            },
            {
                "size": " 508х10",
                "koef": "0.1228",
                "metrs": 1
            },
            {
                "size": " 508х11",
                "koef": "0.1348",
                "metrs": 1
            },
            {
                "size": " 508х12",
                "koef": "0.1468",
                "metrs": 1
            },
            {
                "size": " 529х7",
                "koef": "0.09011",
                "metrs": 1
            },
            {
                "size": " 529х8",
                "koef": "0.1028",
                "metrs": 1
            },
            {
                "size": " 529х9",
                "koef": "0.1154",
                "metrs": 1
            },
            {
                "size": " 529х10",
                "koef": "0.128",
                "metrs": 1
            },
            {
                "size": " 530х5",
                "koef": "0.06474",
                "metrs": 1
            },
            {
                "size": " 530х6",
                "koef": "0.07754",
                "metrs": 1
            },
            {
                "size": " 530х6,5",
                "koef": "0.08392",
                "metrs": 1
            },
            {
                "size": " 530х7",
                "koef": "0.09029",
                "metrs": 1
            },
            {
                "size": " 530х7,5",
                "koef": "0.09664",
                "metrs": 1
            },
            {
                "size": " 530х8",
                "koef": "0.103",
                "metrs": 1
            },
            {
                "size": " 530х9",
                "koef": "0.1156",
                "metrs": 1
            },
            {
                "size": " 530х10",
                "koef": "0.1282",
                "metrs": 1
            },
            {
                "size": " 530х11",
                "koef": "0.1408",
                "metrs": 1
            },
            {
                "size": " 530х12",
                "koef": "0.1533",
                "metrs": 1
            },
            {
                "size": " 530х14",
                "koef": "0.1782",
                "metrs": 1
            },
            {
                "size": " 600х11",
                "koef": "0.1598",
                "metrs": 1
            },
            {
                "size": " 630х5",
                "koef": "0.07707",
                "metrs": 1
            },
            {
                "size": " 630х6",
                "koef": "0.09233",
                "metrs": 1
            },
            {
                "size": " 630х6,5",
                "koef": "0.09995",
                "metrs": 1
            },
            {
                "size": " 630х7",
                "koef": "0.1075",
                "metrs": 1
            },
            {
                "size": " 630х7,5",
                "koef": "0.1151",
                "metrs": 1
            },
            {
                "size": " 630х8",
                "koef": "0.1227",
                "metrs": 1
            },
            {
                "size": " 630х8,5",
                "koef": "0.1303",
                "metrs": 1
            },
            {
                "size": " 630х9",
                "koef": "0.1378",
                "metrs": 1
            },
            {
                "size": " 630х9,5",
                "koef": "0.1454",
                "metrs": 1
            },
            {
                "size": " 630х10",
                "koef": "0.1529",
                "metrs": 1
            },
            {
                "size": " 630х11",
                "koef": "0.1679",
                "metrs": 1
            },
            {
                "size": " 630х12",
                "koef": "0.1829",
                "metrs": 1
            },
            {
                "size": " 630х14",
                "koef": "0.2127",
                "metrs": 1
            },
            {
                "size": " 630х16",
                "koef": "0.2423",
                "metrs": 1
            },
            {
                "size": " 630х18",
                "koef": "0.2717",
                "metrs": 1
            },
            {
                "size": " 630х20",
                "koef": "0.3009",
                "metrs": 1
            },
            {
                "size": " 630х22",
                "koef": "0.3299",
                "metrs": 1
            },
            {
                "size": " 630х24",
                "koef": "0.3587",
                "metrs": 1
            },
            {
                "size": " 630х26",
                "koef": "0.3873",
                "metrs": 1
            },
            {
                "size": " 630х28",
                "koef": "0.4157",
                "metrs": 1
            },
            {
                "size": " 630х30",
                "koef": "0.4439",
                "metrs": 1
            },
            {
                "size": " 630х32",
                "koef": "0.4719",
                "metrs": 1
            },
            {
                "size": " 720х7",
                "koef": "0.1231",
                "metrs": 1
            },
            {
                "size": " 720х7,5",
                "koef": "0.1318",
                "metrs": 1
            },
            {
                "size": " 720х8",
                "koef": "0.1405",
                "metrs": 1
            },
            {
                "size": " 720х8,5",
                "koef": "0.1491",
                "metrs": 1
            },
            {
                "size": " 720х9",
                "koef": "0.1578",
                "metrs": 1
            },
            {
                "size": " 720х9,5",
                "koef": "0.1665",
                "metrs": 1
            },
            {
                "size": " 720х10",
                "koef": "0.1751",
                "metrs": 1
            },
            {
                "size": " 720х11",
                "koef": "0.1923",
                "metrs": 1
            },
            {
                "size": " 720х12",
                "koef": "0.2095",
                "metrs": 1
            },
            {
                "size": " 720х13",
                "koef": "0.2267",
                "metrs": 1
            },
            {
                "size": " 720х14",
                "koef": "0.2438",
                "metrs": 1
            },
            {
                "size": " 720х15",
                "koef": "0.2608",
                "metrs": 1
            },
            {
                "size": " 720х16",
                "koef": "0.2778",
                "metrs": 1
            },
            {
                "size": " 720х17",
                "koef": "0.2947",
                "metrs": 1
            },
            {
                "size": " 720х18",
                "koef": "0.3116",
                "metrs": 1
            },
            {
                "size": " 720х20",
                "koef": "0.3453",
                "metrs": 1
            },
            {
                "size": " 720х22",
                "koef": "0.3787",
                "metrs": 1
            },
            {
                "size": " 720х24",
                "koef": "0.4119",
                "metrs": 1
            },
            {
                "size": " 720х26",
                "koef": "0.445",
                "metrs": 1
            },
            {
                "size": " 720х28",
                "koef": "0.4778",
                "metrs": 1
            },
            {
                "size": " 720х30",
                "koef": "0.5105",
                "metrs": 1
            },
            {
                "size": " 720х32",
                "koef": "0.5429",
                "metrs": 1
            },
            {
                "size": " 820х7",
                "koef": "0.1403",
                "metrs": 1
            },
            {
                "size": " 820х8",
                "koef": "0.1602",
                "metrs": 1
            },
            {
                "size": " 820х9",
                "koef": "0.18",
                "metrs": 1
            },
            {
                "size": " 820х9,5",
                "koef": "0.1899",
                "metrs": 1
            },
            {
                "size": " 820х10",
                "koef": "0.1998",
                "metrs": 1
            },
            {
                "size": " 820х11",
                "koef": "0.2195",
                "metrs": 1
            },
            {
                "size": " 820х12",
                "koef": "0.2391",
                "metrs": 1
            },
            {
                "size": " 820х14",
                "koef": "0.2783",
                "metrs": 1
            },
            {
                "size": " 820х16",
                "koef": "0.3172",
                "metrs": 1
            },
            {
                "size": " 820х18",
                "koef": "0.356",
                "metrs": 1
            },
            {
                "size": " 820х20",
                "koef": "0.3946",
                "metrs": 1
            },
            {
                "size": " 820х22",
                "koef": "0.433",
                "metrs": 1
            },
            {
                "size": " 820х24",
                "koef": "0.4711",
                "metrs": 1
            },
            {
                "size": " 820х26",
                "koef": "0.5091",
                "metrs": 1
            },
            {
                "size": " 820х28",
                "koef": "0.5469",
                "metrs": 1
            },
            {
                "size": " 820х30",
                "koef": "0.5845",
                "metrs": 1
            },
            {
                "size": " 820х32",
                "koef": "0.6219",
                "metrs": 1
            },
            {
                "size": " 920х7",
                "koef": "0.1576",
                "metrs": 1
            },
            {
                "size": " 920х8",
                "koef": "0.1799",
                "metrs": 1
            },
            {
                "size": " 920х9",
                "koef": "0.2022",
                "metrs": 1
            },
            {
                "size": " 920х9,5",
                "koef": "0.2133",
                "metrs": 1
            },
            {
                "size": " 920х10",
                "koef": "0.2244",
                "metrs": 1
            },
            {
                "size": " 920х10,5",
                "koef": "0.2355",
                "metrs": 1
            },
            {
                "size": " 920х11",
                "koef": "0.2466",
                "metrs": 1
            },
            {
                "size": " 920х12",
                "koef": "0.2687",
                "metrs": 1
            },
            {
                "size": " 920х13",
                "koef": "0.2908",
                "metrs": 1
            },
            {
                "size": " 920х14",
                "koef": "0.3128",
                "metrs": 1
            },
            {
                "size": " 920х15",
                "koef": "0.3348",
                "metrs": 1
            },
            {
                "size": " 920х16",
                "koef": "0.3567",
                "metrs": 1
            },
            {
                "size": " 920х18",
                "koef": "0.4004",
                "metrs": 1
            },
            {
                "size": " 920х20",
                "koef": "0.4439",
                "metrs": 1
            },
            {
                "size": " 920х22",
                "koef": "0.4872",
                "metrs": 1
            },
            {
                "size": " 920х24",
                "koef": "0.5303",
                "metrs": 1
            },
            {
                "size": " 920х26",
                "koef": "0.5732",
                "metrs": 1
            },
            {
                "size": " 920х28",
                "koef": "0.6159",
                "metrs": 1
            },
            {
                "size": " 920х30",
                "koef": "0.6585",
                "metrs": 1
            },
            {
                "size": " 920х32",
                "koef": "0.7008",
                "metrs": 1
            },
            {
                "size": " 1020х9",
                "koef": "0.2244",
                "metrs": 1
            },
            {
                "size": " 1020х9,5",
                "koef": "0.2367",
                "metrs": 1
            },
            {
                "size": " 1020х10",
                "koef": "0.2491",
                "metrs": 1
            },
            {
                "size": " 1020х10,5",
                "koef": "0.2614",
                "metrs": 1
            },
            {
                "size": " 1020х11",
                "koef": "0.2737",
                "metrs": 1
            },
            {
                "size": " 1020х12",
                "koef": "0.2983",
                "metrs": 1
            },
            {
                "size": " 1020х13",
                "koef": "0.3228",
                "metrs": 1
            },
            {
                "size": " 1020х14",
                "koef": "0.3473",
                "metrs": 1
            },
            {
                "size": " 1020х15",
                "koef": "0.3718",
                "metrs": 1
            },
            {
                "size": " 1020х15,7",
                "koef": "0.3889",
                "metrs": 1
            },
            {
                "size": " 1020х16",
                "koef": "0.3962",
                "metrs": 1
            },
            {
                "size": " 1020х17",
                "koef": "0.4205",
                "metrs": 1
            },
            {
                "size": " 1020х18",
                "koef": "0.4448",
                "metrs": 1
            },
            {
                "size": " 1020х20",
                "koef": "0.4932",
                "metrs": 1
            },
            {
                "size": " 1020х22",
                "koef": "0.5415",
                "metrs": 1
            },
            {
                "size": " 1020х24",
                "koef": "0.5895",
                "metrs": 1
            },
            {
                "size": " 1020х26",
                "koef": "0.6374",
                "metrs": 1
            },
            {
                "size": " 1020х28",
                "koef": "0.685",
                "metrs": 1
            },
            {
                "size": " 1020х30",
                "koef": "0.7324",
                "metrs": 1
            },
            {
                "size": " 1020х32",
                "koef": "0.7797",
                "metrs": 1
            },
            {
                "size": " 1120х11",
                "koef": "0.3008",
                "metrs": 1
            },
            {
                "size": " 1120х12",
                "koef": "0.3279",
                "metrs": 1
            },
            {
                "size": " 1220х9",
                "koef": "0.2688",
                "metrs": 1
            },
            {
                "size": " 1220х10",
                "koef": "0.2984",
                "metrs": 1
            },
            {
                "size": " 1220х11",
                "koef": "0.328",
                "metrs": 1
            },
            {
                "size": " 1220х12",
                "koef": "0.3575",
                "metrs": 1
            },
            {
                "size": " 1220х13",
                "koef": "0.387",
                "metrs": 1
            },
            {
                "size": " 1220х14",
                "koef": "0.4164",
                "metrs": 1
            },
            {
                "size": " 1220х15",
                "koef": "0.4458",
                "metrs": 1
            },
            {
                "size": " 1220х15,7",
                "koef": "0.4663",
                "metrs": 1
            },
            {
                "size": " 1220х16",
                "koef": "0.4751",
                "metrs": 1
            },
            {
                "size": " 1220х16,8",
                "koef": "0.4985",
                "metrs": 1
            },
            {
                "size": " 1220х17",
                "koef": "0.5044",
                "metrs": 1
            },
            {
                "size": " 1220х18",
                "koef": "0.5336",
                "metrs": 1
            },
            {
                "size": " 1220х19",
                "koef": "0.5628",
                "metrs": 1
            },
            {
                "size": " 1220х20",
                "koef": "0.5919",
                "metrs": 1
            },
            {
                "size": " 1220х22",
                "koef": "0.65",
                "metrs": 1
            },
            {
                "size": " 1220х24",
                "koef": "0.7079",
                "metrs": 1
            },
            {
                "size": " 1220х26",
                "koef": "0.7656",
                "metrs": 1
            },
            {
                "size": " 1220х28",
                "koef": "0.8231",
                "metrs": 1
            },
            {
                "size": " 1220х30",
                "koef": "0.8804",
                "metrs": 1
            },
            {
                "size": " 1220х32",
                "koef": "0.9375",
                "metrs": 1
            },
            {
                "size": " 1320х10",
                "koef": "0.3231",
                "metrs": 1
            },
            {
                "size": " 1320х12",
                "koef": "0.3871",
                "metrs": 1
            },
            {
                "size": " 1320х14",
                "koef": "0.4509",
                "metrs": 1
            },
            {
                "size": " 1320х16",
                "koef": "0.5145",
                "metrs": 1
            },
            {
                "size": " 1320х18",
                "koef": "0.578",
                "metrs": 1
            },
            {
                "size": " 1320х20",
                "koef": "0.6412",
                "metrs": 1
            },
            {
                "size": " 1320х22",
                "koef": "0.7042",
                "metrs": 1
            },
            {
                "size": " 1320х24",
                "koef": "0.7671",
                "metrs": 1
            },
            {
                "size": " 1320х26",
                "koef": "0.8297",
                "metrs": 1
            },
            {
                "size": " 1320х28",
                "koef": "0.8922",
                "metrs": 1
            },
            {
                "size": " 1320х30",
                "koef": "0.9544",
                "metrs": 1
            },
            {
                "size": " 1320х32",
                "koef": "1.016",
                "metrs": 1
            },
            {
                "size": " 1420х10",
                "koef": "0.3477",
                "metrs": 1
            },
            {
                "size": " 1420х12",
                "koef": "0.4167",
                "metrs": 1
            },
            {
                "size": " 1420х13",
                "koef": "0.4511",
                "metrs": 1
            },
            {
                "size": " 1420х14",
                "koef": "0.4854",
                "metrs": 1
            },
            {
                "size": " 1420х15",
                "koef": "0.5197",
                "metrs": 1
            },
            {
                "size": " 1420х15,7",
                "koef": "0.5437",
                "metrs": 1
            },
            {
                "size": " 1420х16",
                "koef": "0.554",
                "metrs": 1
            },
            {
                "size": " 1420х16,5",
                "koef": "0.5711",
                "metrs": 1
            },
            {
                "size": " 1420х16,8",
                "koef": "0.5814",
                "metrs": 1
            },
            {
                "size": " 1420х17",
                "koef": "0.5882",
                "metrs": 1
            },
            {
                "size": " 1420х18",
                "koef": "0.6224",
                "metrs": 1
            },
            {
                "size": " 1420х18,7",
                "koef": "0.6462",
                "metrs": 1
            },
            {
                "size": " 1420х19",
                "koef": "0.6565",
                "metrs": 1
            },
            {
                "size": " 1420х20",
                "koef": "0.6905",
                "metrs": 1
            },
            {
                "size": " 1420х21",
                "koef": "0.7245",
                "metrs": 1
            },
            {
                "size": " 1420х22",
                "koef": "0.7585",
                "metrs": 1
            },
            {
                "size": " 1420х23",
                "koef": "0.7924",
                "metrs": 1
            },
            {
                "size": " 1420х24",
                "koef": "0.8263",
                "metrs": 1
            },
            {
                "size": " 1420х25",
                "koef": "0.8601",
                "metrs": 1
            },
            {
                "size": " 1420х25,7",
                "koef": "0.8837",
                "metrs": 1
            },
            {
                "size": " 1420х26",
                "koef": "0.8938",
                "metrs": 1
            },
            {
                "size": " 1420х27",
                "koef": "0.9275",
                "metrs": 1
            },
            {
                "size": " 1420х28",
                "koef": "0.9612",
                "metrs": 1
            },
            {
                "size": " 1420х30",
                "koef": "1.028",
                "metrs": 1
            },
            {
                "size": " 1420х32",
                "koef": "1.095",
                "metrs": 1
            },
            {
                "size": " 1520х10",
                "koef": "0.3724",
                "metrs": 1
            },
            {
                "size": " 1520х12",
                "koef": "0.4463",
                "metrs": 1
            },
            {
                "size": " 1520х14",
                "koef": "0.52",
                "metrs": 1
            },
            {
                "size": " 1520х16",
                "koef": "0.5935",
                "metrs": 1
            },
            {
                "size": " 1520х18",
                "koef": "0.6667",
                "metrs": 1
            },
            {
                "size": " 1520х20",
                "koef": "0.7398",
                "metrs": 1
            },
            {
                "size": " 1520х22",
                "koef": "0.8127",
                "metrs": 1
            },
            {
                "size": " 1520х24",
                "koef": "0.8854",
                "metrs": 1
            },
            {
                "size": " 1520х26",
                "koef": "0.958",
                "metrs": 1
            },
            {
                "size": " 1520х28",
                "koef": "1,03",
                "metrs": 1
            },
            {
                "size": " 1520х30",
                "koef": "1.102",
                "metrs": 1
            },
            {
                "size": " 1520х32",
                "koef": "1.174",
                "metrs": 1
            },
            {
                "size": " 1620х10",
                "koef": "0.397",
                "metrs": 1
            },
            {
                "size": " 1620х12",
                "koef": "0.4759",
                "metrs": 1
            },
            {
                "size": " 1620х14",
                "koef": "0.5545",
                "metrs": 1
            },
            {
                "size": " 1620х14",
                "koef": "0.5545",
                "metrs": 1
            },
            {
                "size": " 1620х16",
                "koef": "0.6329",
                "metrs": 1
            },
            {
                "size": " 1620х18",
                "koef": "0.7111",
                "metrs": 1
            },
            {
                "size": " 1620х20",
                "koef": "0.7892",
                "metrs": 1
            },
            {
                "size": " 1620х22",
                "koef": "0.867",
                "metrs": 1
            },
            {
                "size": " 1620х24",
                "koef": "0.9446",
                "metrs": 1
            },
            {
                "size": " 1620х26",
                "koef": "1.022",
                "metrs": 1
            },
            {
                "size": " 1620х28",
                "koef": "1.099",
                "metrs": 1
            },
            {
                "size": " 1620х30",
                "koef": "1.176",
                "metrs": 1
            },
            {
                "size": " 1620х32",
                "koef": "1.253",
                "metrs": 1
            },
            {
                "size": " 1720х10",
                "koef": "0.4217",
                "metrs": 1
            },
            {
                "size": " 1720х12",
                "koef": "0.5055",
                "metrs": 1
            },
            {
                "size": " 1720х14",
                "koef": "0.589",
                "metrs": 1
            },
            {
                "size": " 1720х16",
                "koef": "0.6724",
                "metrs": 1
            },
            {
                "size": " 1720х18",
                "koef": "0.7555",
                "metrs": 1
            },
            {
                "size": " 1720х20",
                "koef": "0.8385",
                "metrs": 1
            },
            {
                "size": " 1720х22",
                "koef": "0.9213",
                "metrs": 1
            },
            {
                "size": " 1720х24",
                "koef": "1.004",
                "metrs": 1
            },
            {
                "size": " 1720х26",
                "koef": "1.086",
                "metrs": 1
            },
            {
                "size": " 1720х28",
                "koef": "1.168",
                "metrs": 1
            },
            {
                "size": " 1720х30",
                "koef": "1,25",
                "metrs": 1
            },
            {
                "size": " 1720х32",
                "koef": "1.332",
                "metrs": 1
            },
            {
                "size": " 1820х10",
                "koef": "0.4464",
                "metrs": 1
            },
            {
                "size": " 1820х12",
                "koef": "0.5351",
                "metrs": 1
            },
            {
                "size": " 1820х14",
                "koef": "0.6235",
                "metrs": 1
            },
            {
                "size": " 1820х16",
                "koef": "0.7118",
                "metrs": 1
            },
            {
                "size": " 1820х18",
                "koef": "0.7999",
                "metrs": 1
            },
            {
                "size": " 1820х20",
                "koef": "0.8878",
                "metrs": 1
            },
            {
                "size": " 1820х22",
                "koef": "0.9755",
                "metrs": 1
            },
            {
                "size": " 1820х24",
                "koef": "1.063",
                "metrs": 1
            },
            {
                "size": " 1820х26",
                "koef": "1,15",
                "metrs": 1
            },
            {
                "size": " 1820х28",
                "koef": "1.237",
                "metrs": 1
            },
            {
                "size": " 1820х30",
                "koef": "1.324",
                "metrs": 1
            },
            {
                "size": " 1820х32",
                "koef": "1.411",
                "metrs": 1
            },
            {
                "size": " 1920х10",
                "koef": "0.471",
                "metrs": 1
            },
            {
                "size": " 1920х12",
                "koef": "0.5646",
                "metrs": 1
            },
            {
                "size": " 1920х14",
                "koef": "0.6581",
                "metrs": 1
            },
            {
                "size": " 1920х16",
                "koef": "0.7513",
                "metrs": 1
            },
            {
                "size": " 1920х18",
                "koef": "0.8443",
                "metrs": 1
            },
            {
                "size": " 1920х20",
                "koef": "0.9371",
                "metrs": 1
            },
            {
                "size": " 1920х22",
                "koef": "1,03",
                "metrs": 1
            },
            {
                "size": " 1920х24",
                "koef": "1.122",
                "metrs": 1
            },
            {
                "size": " 1920х26",
                "koef": "1.214",
                "metrs": 1
            },
            {
                "size": " 1920х28",
                "koef": "1.306",
                "metrs": 1
            },
            {
                "size": " 1920х30",
                "koef": "1.398",
                "metrs": 1
            },
            {
                "size": " 1920х32",
                "koef": "1,49",
                "metrs": 1
            },
            {
                "size": " 2020х10",
                "koef": "0.4957",
                "metrs": 1
            },
            {
                "size": " 2020х12",
                "koef": "0.5942",
                "metrs": 1
            },
            {
                "size": " 2020х14",
                "koef": "0.6926",
                "metrs": 1
            },
            {
                "size": " 2020х16",
                "koef": "0.7907",
                "metrs": 1
            },
            {
                "size": " 2020х18",
                "koef": "0.8887",
                "metrs": 1
            },
            {
                "size": " 2020х20",
                "koef": "0.9865",
                "metrs": 1
            },
            {
                "size": " 2020х22",
                "koef": "1.084",
                "metrs": 1
            },
            {
                "size": " 2020х24",
                "koef": "1.181",
                "metrs": 1
            },
            {
                "size": " 2020х26",
                "koef": "1.279",
                "metrs": 1
            },
            {
                "size": " 2020х28",
                "koef": "1.376",
                "metrs": 1
            },
            {
                "size": " 2020х30",
                "koef": "1.472",
                "metrs": 1
            },
            {
                "size": " 2020х32",
                "koef": "1.569",
                "metrs": 1
            }
        ],
        "Труба Б/У профильная": [
            {
                "size": " 100х100х5",
                "koef": "0.01458",
                "metrs": 1
            },
            {
                "size": " 120х120х4",
                "koef": "0.01425",
                "metrs": 1
            },
            {
                "size": " 120х120х5",
                "koef": "0.01755",
                "metrs": 1
            },
            {
                "size": " 140х100х6",
                "koef": "0.02099",
                "metrs": 1
            },
            {
                "size": " 150х100х5",
                "koef": "0.0185",
                "metrs": 1
            },
            {
                "size": " 150х100х6",
                "koef": "0.02193",
                "metrs": 1
            },
            {
                "size": " 180х180х6",
                "koef": "0.03205",
                "metrs": 1
            },
            {
                "size": " 180х180х8",
                "koef": "0.04234",
                "metrs": 1
            },
            {
                "size": " 200х160х6",
                "koef": "0.0323",
                "metrs": 1
            },
            {
                "size": " 250х250х7",
                "koef": "0.05209",
                "metrs": 1
            },
            {
                "size": " 250х250х8",
                "koef": "0.05907",
                "metrs": 1
            },
            {
                "size": " 250х250х9",
                "koef": "0.06592",
                "metrs": 1
            }
        ],
        "Труба бесшовная горячедеформированная": [
            {
                "size": " 32х4",
                "koef": "0.002762",
                "metrs": 1
            },
            {
                "size": " 33,7х3,5",
                "koef": "0.002607",
                "metrs": 1
            },
            {
                "size": " 33,7х4,5",
                "koef": "0.003241",
                "metrs": 1
            },
            {
                "size": " 38х3",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 38х3,2",
                "koef": "0.002746",
                "metrs": 1
            },
            {
                "size": " 38х3,5",
                "koef": "0.002978",
                "metrs": 1
            },
            {
                "size": " 38х4",
                "koef": "0.003354",
                "metrs": 1
            },
            {
                "size": " 38х5",
                "koef": "0.004069",
                "metrs": 1
            },
            {
                "size": " 42х3",
                "koef": "0.002885",
                "metrs": 1
            },
            {
                "size": " 42х3,2",
                "koef": "0.003062",
                "metrs": 1
            },
            {
                "size": " 42х3,5",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": " 42х4",
                "koef": "0.003749",
                "metrs": 1
            },
            {
                "size": " 42х4,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": " 42х5",
                "koef": "0.004562",
                "metrs": 1
            },
            {
                "size": " 42х6",
                "koef": "0.005327",
                "metrs": 1
            },
            {
                "size": " 45х3",
                "koef": "0.003107",
                "metrs": 1
            },
            {
                "size": " 45х3,5",
                "koef": "0.003582",
                "metrs": 1
            },
            {
                "size": " 45х4",
                "koef": "0.004044",
                "metrs": 1
            },
            {
                "size": " 45х4,5",
                "koef": "0.004495",
                "metrs": 1
            },
            {
                "size": " 45х5",
                "koef": "0.004932",
                "metrs": 1
            },
            {
                "size": " 45х6",
                "koef": "0.005771",
                "metrs": 1
            },
            {
                "size": " 48х3",
                "koef": "0.003329",
                "metrs": 1
            },
            {
                "size": " 48х3,5",
                "koef": "0.003841",
                "metrs": 1
            },
            {
                "size": " 48х4",
                "koef": "0.00434",
                "metrs": 1
            },
            {
                "size": " 48х4,5",
                "koef": "0.004827",
                "metrs": 1
            },
            {
                "size": " 48х5",
                "koef": "0.005302",
                "metrs": 1
            },
            {
                "size": " 48х6",
                "koef": "0.006215",
                "metrs": 1
            },
            {
                "size": " 48,3х3",
                "koef": "0.003351",
                "metrs": 1
            },
            {
                "size": " 48,3х3,5",
                "koef": "0.003867",
                "metrs": 1
            },
            {
                "size": " 48,3х4",
                "koef": "0.00437",
                "metrs": 1
            },
            {
                "size": " 50х3",
                "koef": "0.003477",
                "metrs": 1
            },
            {
                "size": " 50х3,5",
                "koef": "0.004014",
                "metrs": 1
            },
            {
                "size": " 50х4",
                "koef": "0.004538",
                "metrs": 1
            },
            {
                "size": " 50х5",
                "koef": "0.005549",
                "metrs": 1
            },
            {
                "size": " 50х6",
                "koef": "0.006511",
                "metrs": 1
            },
            {
                "size": " 51х2,5",
                "koef": "0.00299",
                "metrs": 1
            },
            {
                "size": " 51х3,5",
                "koef": "0.0041",
                "metrs": 1
            },
            {
                "size": " 51х4",
                "koef": "0.004636",
                "metrs": 1
            },
            {
                "size": " 51х5",
                "koef": "0.005672",
                "metrs": 1
            },
            {
                "size": " 51х6",
                "koef": "0.006659",
                "metrs": 1
            },
            {
                "size": " 54х3,2",
                "koef": "0.004009",
                "metrs": 1
            },
            {
                "size": " 54х3,5",
                "koef": "0.004359",
                "metrs": 1
            },
            {
                "size": " 54х4",
                "koef": "0.004932",
                "metrs": 1
            },
            {
                "size": " 54х5",
                "koef": "0.006042",
                "metrs": 1
            },
            {
                "size": " 54х6",
                "koef": "0.007103",
                "metrs": 1
            },
            {
                "size": " 57х3,2",
                "koef": "0.004246",
                "metrs": 1
            },
            {
                "size": " 57х3,5",
                "koef": "0.004618",
                "metrs": 1
            },
            {
                "size": " 57х4",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": " 57х4,5",
                "koef": "0.005826",
                "metrs": 1
            },
            {
                "size": " 57х5",
                "koef": "0.006412",
                "metrs": 1
            },
            {
                "size": " 57х6",
                "koef": "0.007546",
                "metrs": 1
            },
            {
                "size": " 57х7",
                "koef": "0.008632",
                "metrs": 1
            },
            {
                "size": " 57х8",
                "koef": "0.009667",
                "metrs": 1
            },
            {
                "size": " 57х10",
                "koef": "0.01159",
                "metrs": 1
            },
            {
                "size": " 57х12",
                "koef": "0.01332",
                "metrs": 1
            },
            {
                "size": " 57х13",
                "koef": "0.01411",
                "metrs": 1
            },
            {
                "size": " 57х14",
                "koef": "0.01485",
                "metrs": 1
            },
            {
                "size": " 60х3,2",
                "koef": "0.004482",
                "metrs": 1
            },
            {
                "size": " 60х3,5",
                "koef": "0.004877",
                "metrs": 1
            },
            {
                "size": " 60х4",
                "koef": "0.005524",
                "metrs": 1
            },
            {
                "size": " 60х4,5",
                "koef": "0.006159",
                "metrs": 1
            },
            {
                "size": " 60х5",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": " 60х5,5",
                "koef": "0.007392",
                "metrs": 1
            },
            {
                "size": " 60х6",
                "koef": "0.00799",
                "metrs": 1
            },
            {
                "size": " 60х7",
                "koef": "0.009149",
                "metrs": 1
            },
            {
                "size": " 60х8",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": " 60х10",
                "koef": "0.01233",
                "metrs": 1
            },
            {
                "size": " 60х11",
                "koef": "0.01329",
                "metrs": 1
            },
            {
                "size": " 60х12",
                "koef": "0.01421",
                "metrs": 1
            },
            {
                "size": " 60х14",
                "koef": "0.01588",
                "metrs": 1
            },
            {
                "size": " 60,3х3,2",
                "koef": "0.004506",
                "metrs": 1
            },
            {
                "size": " 60,3х3,5",
                "koef": "0.004903",
                "metrs": 1
            },
            {
                "size": " 60,3х4",
                "koef": "0.005554",
                "metrs": 1
            },
            {
                "size": " 60,3х5",
                "koef": "0.006819",
                "metrs": 1
            },
            {
                "size": " 60,3х6",
                "koef": "0.008035",
                "metrs": 1
            },
            {
                "size": " 60,3х8",
                "koef": "0.01032",
                "metrs": 1
            },
            {
                "size": " 60,3х9",
                "koef": "0.01139",
                "metrs": 1
            },
            {
                "size": " 60,3х10",
                "koef": "0.0124",
                "metrs": 1
            },
            {
                "size": " 60,3х12",
                "koef": "0.01429",
                "metrs": 1
            },
            {
                "size": " 60,3х14",
                "koef": "0.01599",
                "metrs": 1
            },
            {
                "size": " 63,5х3,5",
                "koef": "0.005179",
                "metrs": 1
            },
            {
                "size": " 63,5х4",
                "koef": "0.005869",
                "metrs": 1
            },
            {
                "size": " 63,5х4,5",
                "koef": "0.006548",
                "metrs": 1
            },
            {
                "size": " 63,5х5",
                "koef": "0.007213",
                "metrs": 1
            },
            {
                "size": " 63,5х6",
                "koef": "0.008508",
                "metrs": 1
            },
            {
                "size": " 63,5х7",
                "koef": "0.009754",
                "metrs": 1
            },
            {
                "size": " 63,5х8",
                "koef": "0.01095",
                "metrs": 1
            },
            {
                "size": " 63,5х9",
                "koef": "0.0121",
                "metrs": 1
            },
            {
                "size": " 63,5х10",
                "koef": "0.01319",
                "metrs": 1
            },
            {
                "size": " 63,5х12",
                "koef": "0.01524",
                "metrs": 1
            },
            {
                "size": " 63,5х14",
                "koef": "0.01709",
                "metrs": 1
            },
            {
                "size": " 68х3,5",
                "koef": "0.005567",
                "metrs": 1
            },
            {
                "size": " 68х4",
                "koef": "0.006313",
                "metrs": 1
            },
            {
                "size": " 68х4,5",
                "koef": "0.007047",
                "metrs": 1
            },
            {
                "size": " 68х5",
                "koef": "0.007768",
                "metrs": 1
            },
            {
                "size": " 68х6",
                "koef": "0.009174",
                "metrs": 1
            },
            {
                "size": " 68х7",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": " 68х8",
                "koef": "0.01184",
                "metrs": 1
            },
            {
                "size": " 68х9",
                "koef": "0.0131",
                "metrs": 1
            },
            {
                "size": " 68х10",
                "koef": "0.0143",
                "metrs": 1
            },
            {
                "size": " 68х11",
                "koef": "0.01546",
                "metrs": 1
            },
            {
                "size": " 68х12",
                "koef": "0.01657",
                "metrs": 1
            },
            {
                "size": " 68х14",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": " 68х16",
                "koef": "0.02052",
                "metrs": 1
            },
            {
                "size": " 70х3,5",
                "koef": "0.00574",
                "metrs": 1
            },
            {
                "size": " 70х4",
                "koef": "0.006511",
                "metrs": 1
            },
            {
                "size": " 70х4,5",
                "koef": "0.007269",
                "metrs": 1
            },
            {
                "size": " 70х5",
                "koef": "0.008015",
                "metrs": 1
            },
            {
                "size": " 70х6",
                "koef": "0.00947",
                "metrs": 1
            },
            {
                "size": " 70х7",
                "koef": "0.01088",
                "metrs": 1
            },
            {
                "size": " 70х8",
                "koef": "0.01223",
                "metrs": 1
            },
            {
                "size": " 70х10",
                "koef": "0.0148",
                "metrs": 1
            },
            {
                "size": " 70х12",
                "koef": "0.01716",
                "metrs": 1
            },
            {
                "size": " 70х14",
                "koef": "0.01933",
                "metrs": 1
            },
            {
                "size": " 70х15",
                "koef": "0.02035",
                "metrs": 1
            },
            {
                "size": " 70х16",
                "koef": "0.02131",
                "metrs": 1
            },
            {
                "size": " 73х3,5",
                "koef": "0.005999",
                "metrs": 1
            },
            {
                "size": " 73х4",
                "koef": "0.006807",
                "metrs": 1
            },
            {
                "size": " 73х4,5",
                "koef": "0.007602",
                "metrs": 1
            },
            {
                "size": " 73х5",
                "koef": "0.008385",
                "metrs": 1
            },
            {
                "size": " 73х5,5",
                "koef": "0.009156",
                "metrs": 1
            },
            {
                "size": " 73х6",
                "koef": "0.009914",
                "metrs": 1
            },
            {
                "size": " 73х7",
                "koef": "0.01139",
                "metrs": 1
            },
            {
                "size": " 73х7,5",
                "koef": "0.01211",
                "metrs": 1
            },
            {
                "size": " 73х8",
                "koef": "0.01282",
                "metrs": 1
            },
            {
                "size": " 73х9",
                "koef": "0.01421",
                "metrs": 1
            },
            {
                "size": " 73х10",
                "koef": "0.01554",
                "metrs": 1
            },
            {
                "size": " 73х11",
                "koef": "0.01682",
                "metrs": 1
            },
            {
                "size": " 73х12",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": " 73х13",
                "koef": "0.01924",
                "metrs": 1
            },
            {
                "size": " 73х14",
                "koef": "0.02037",
                "metrs": 1
            },
            {
                "size": " 73х15",
                "koef": "0.02146",
                "metrs": 1
            },
            {
                "size": " 73х16",
                "koef": "0.02249",
                "metrs": 1
            },
            {
                "size": " 73х18",
                "koef": "0.02441",
                "metrs": 1
            },
            {
                "size": " 73х19",
                "koef": "0.0253",
                "metrs": 1
            },
            {
                "size": " 76х3,5",
                "koef": "0.006258",
                "metrs": 1
            },
            {
                "size": " 76х4",
                "koef": "0.007103",
                "metrs": 1
            },
            {
                "size": " 76х4,5",
                "koef": "0.007935",
                "metrs": 1
            },
            {
                "size": " 76х5",
                "koef": "0.008755",
                "metrs": 1
            },
            {
                "size": " 76х6",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": " 76х7",
                "koef": "0.01191",
                "metrs": 1
            },
            {
                "size": " 76х8",
                "koef": "0.01342",
                "metrs": 1
            },
            {
                "size": " 76х9",
                "koef": "0.01487",
                "metrs": 1
            },
            {
                "size": " 76х10",
                "koef": "0.01628",
                "metrs": 1
            },
            {
                "size": " 76х11",
                "koef": "0.01763",
                "metrs": 1
            },
            {
                "size": " 76х12",
                "koef": "0.01894",
                "metrs": 1
            },
            {
                "size": " 76х14",
                "koef": "0.02141",
                "metrs": 1
            },
            {
                "size": " 76х15",
                "koef": "0.02257",
                "metrs": 1
            },
            {
                "size": " 76х16",
                "koef": "0.02368",
                "metrs": 1
            },
            {
                "size": " 76х17",
                "koef": "0.02474",
                "metrs": 1
            },
            {
                "size": " 76х18",
                "koef": "0.02575",
                "metrs": 1
            },
            {
                "size": " 76х20",
                "koef": "0.02762",
                "metrs": 1
            },
            {
                "size": " 78х12",
                "koef": "0.01953",
                "metrs": 1
            },
            {
                "size": " 83х3,5",
                "koef": "0.006862",
                "metrs": 1
            },
            {
                "size": " 83х4",
                "koef": "0.007793",
                "metrs": 1
            },
            {
                "size": " 83х5",
                "koef": "0.009618",
                "metrs": 1
            },
            {
                "size": " 83х6",
                "koef": "0.01139",
                "metrs": 1
            },
            {
                "size": " 83х8",
                "koef": "0.0148",
                "metrs": 1
            },
            {
                "size": " 83х8,5",
                "koef": "0.01562",
                "metrs": 1
            },
            {
                "size": " 83х9",
                "koef": "0.01642",
                "metrs": 1
            },
            {
                "size": " 83х10",
                "koef": "0.018",
                "metrs": 1
            },
            {
                "size": " 83х11",
                "koef": "0.01953",
                "metrs": 1
            },
            {
                "size": " 83х12",
                "koef": "0.02101",
                "metrs": 1
            },
            {
                "size": " 83х13",
                "koef": "0.02244",
                "metrs": 1
            },
            {
                "size": " 83х14",
                "koef": "0.02382",
                "metrs": 1
            },
            {
                "size": " 83х15",
                "koef": "0.02515",
                "metrs": 1
            },
            {
                "size": " 83х16",
                "koef": "0.02644",
                "metrs": 1
            },
            {
                "size": " 83х18",
                "koef": "0.02885",
                "metrs": 1
            },
            {
                "size": " 83х19",
                "koef": "0.02999",
                "metrs": 1
            },
            {
                "size": " 83х20",
                "koef": "0.03107",
                "metrs": 1
            },
            {
                "size": " 83х22",
                "koef": "0.0331",
                "metrs": 1
            },
            {
                "size": " 85х11",
                "koef": "0.02007",
                "metrs": 1
            },
            {
                "size": " 89х3,5",
                "koef": "0.00738",
                "metrs": 1
            },
            {
                "size": " 89х4",
                "koef": "0.008385",
                "metrs": 1
            },
            {
                "size": " 89х4,5",
                "koef": "0.009378",
                "metrs": 1
            },
            {
                "size": " 89х5",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": " 89х5,5",
                "koef": "0.01133",
                "metrs": 1
            },
            {
                "size": " 89х6",
                "koef": "0.01228",
                "metrs": 1
            },
            {
                "size": " 89х6,5",
                "koef": "0.01322",
                "metrs": 1
            },
            {
                "size": " 89х7",
                "koef": "0.01416",
                "metrs": 1
            },
            {
                "size": " 89х8",
                "koef": "0.01598",
                "metrs": 1
            },
            {
                "size": " 89х9",
                "koef": "0.01776",
                "metrs": 1
            },
            {
                "size": " 89х10",
                "koef": "0.01948",
                "metrs": 1
            },
            {
                "size": " 89х11",
                "koef": "0.02116",
                "metrs": 1
            },
            {
                "size": " 89х12",
                "koef": "0.02279",
                "metrs": 1
            },
            {
                "size": " 89х13",
                "koef": "0.02437",
                "metrs": 1
            },
            {
                "size": " 89х14",
                "koef": "0.02589",
                "metrs": 1
            },
            {
                "size": " 89х15",
                "koef": "0.02737",
                "metrs": 1
            },
            {
                "size": " 89х16",
                "koef": "0.0288",
                "metrs": 1
            },
            {
                "size": " 89х17",
                "koef": "0.03019",
                "metrs": 1
            },
            {
                "size": " 89х18",
                "koef": "0.03152",
                "metrs": 1
            },
            {
                "size": " 89х19",
                "koef": "0.0328",
                "metrs": 1
            },
            {
                "size": " 89х20",
                "koef": "0.03403",
                "metrs": 1
            },
            {
                "size": " 89х22",
                "koef": "0.03635",
                "metrs": 1
            },
            {
                "size": " 89х24",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": " 91х14,5",
                "koef": "0.02736",
                "metrs": 1
            },
            {
                "size": " 92х22",
                "koef": "0.03798",
                "metrs": 1
            },
            {
                "size": " 93х9",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": " 93х9,5",
                "koef": "0.01956",
                "metrs": 1
            },
            {
                "size": " 95х4",
                "koef": "0.008977",
                "metrs": 1
            },
            {
                "size": " 95х4,5",
                "koef": "0.01004",
                "metrs": 1
            },
            {
                "size": " 95х5",
                "koef": "0.0111",
                "metrs": 1
            },
            {
                "size": " 95х6",
                "koef": "0.01317",
                "metrs": 1
            },
            {
                "size": " 95х7",
                "koef": "0.01519",
                "metrs": 1
            },
            {
                "size": " 95х8",
                "koef": "0.01716",
                "metrs": 1
            },
            {
                "size": " 95х9",
                "koef": "0.01909",
                "metrs": 1
            },
            {
                "size": " 95х10",
                "koef": "0.02096",
                "metrs": 1
            },
            {
                "size": " 95х11",
                "koef": "0.02279",
                "metrs": 1
            },
            {
                "size": " 95х12",
                "koef": "0.02456",
                "metrs": 1
            },
            {
                "size": " 95х13",
                "koef": "0.02629",
                "metrs": 1
            },
            {
                "size": " 95х14",
                "koef": "0.02797",
                "metrs": 1
            },
            {
                "size": " 95х16",
                "koef": "0.03117",
                "metrs": 1
            },
            {
                "size": " 95х18",
                "koef": "0.03418",
                "metrs": 1
            },
            {
                "size": " 95х19",
                "koef": "0.03561",
                "metrs": 1
            },
            {
                "size": " 95х20",
                "koef": "0.03699",
                "metrs": 1
            },
            {
                "size": " 95х22",
                "koef": "0.03961",
                "metrs": 1
            },
            {
                "size": " 95х24",
                "koef": "0.04202",
                "metrs": 1
            },
            {
                "size": " 100х13",
                "koef": "0.02789",
                "metrs": 1
            },
            {
                "size": " 102х4",
                "koef": "0.009667",
                "metrs": 1
            },
            {
                "size": " 102х4,5",
                "koef": "0.01082",
                "metrs": 1
            },
            {
                "size": " 102х5",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": " 102х6",
                "koef": "0.01421",
                "metrs": 1
            },
            {
                "size": " 102х6,5",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": " 102х7",
                "koef": "0.0164",
                "metrs": 1
            },
            {
                "size": " 102х8",
                "koef": "0.01855",
                "metrs": 1
            },
            {
                "size": " 102х9",
                "koef": "0.02064",
                "metrs": 1
            },
            {
                "size": " 102х10",
                "koef": "0.02269",
                "metrs": 1
            },
            {
                "size": " 102х11",
                "koef": "0.02469",
                "metrs": 1
            },
            {
                "size": " 102х12",
                "koef": "0.02663",
                "metrs": 1
            },
            {
                "size": " 102х14",
                "koef": "0.03038",
                "metrs": 1
            },
            {
                "size": " 102х15",
                "koef": "0.03218",
                "metrs": 1
            },
            {
                "size": " 102х16",
                "koef": "0.03393",
                "metrs": 1
            },
            {
                "size": " 102х17",
                "koef": "0.03564",
                "metrs": 1
            },
            {
                "size": " 102х18",
                "koef": "0.03729",
                "metrs": 1
            },
            {
                "size": " 102х19",
                "koef": "0.03889",
                "metrs": 1
            },
            {
                "size": " 102х20",
                "koef": "0.04044",
                "metrs": 1
            },
            {
                "size": " 102х22",
                "koef": "0.0434",
                "metrs": 1
            },
            {
                "size": " 102х23",
                "koef": "0.04481",
                "metrs": 1
            },
            {
                "size": " 102х24",
                "koef": "0.04617",
                "metrs": 1
            },
            {
                "size": " 102х25",
                "koef": "0.04747",
                "metrs": 1
            },
            {
                "size": " 102х26",
                "koef": "0.04873",
                "metrs": 1
            },
            {
                "size": " 102х27",
                "koef": "0.04994",
                "metrs": 1
            },
            {
                "size": " 102х28",
                "koef": "0.0511",
                "metrs": 1
            },
            {
                "size": " 103х22,5",
                "koef": "0.04467",
                "metrs": 1
            },
            {
                "size": " 104х12",
                "koef": "0.02723",
                "metrs": 1
            },
            {
                "size": " 108х4",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": " 108х4,5",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": " 108х5",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": " 108х6",
                "koef": "0.01509",
                "metrs": 1
            },
            {
                "size": " 108х6,5",
                "koef": "0.01627",
                "metrs": 1
            },
            {
                "size": " 108х7",
                "koef": "0.01744",
                "metrs": 1
            },
            {
                "size": " 108х8",
                "koef": "0.01973",
                "metrs": 1
            },
            {
                "size": " 108х9",
                "koef": "0.02197",
                "metrs": 1
            },
            {
                "size": " 108х10",
                "koef": "0.02417",
                "metrs": 1
            },
            {
                "size": " 108х11",
                "koef": "0.02631",
                "metrs": 1
            },
            {
                "size": " 108х12",
                "koef": "0.02841",
                "metrs": 1
            },
            {
                "size": " 108х13",
                "koef": "0.03046",
                "metrs": 1
            },
            {
                "size": " 108х14",
                "koef": "0.03245",
                "metrs": 1
            },
            {
                "size": " 108х15",
                "koef": "0.0344",
                "metrs": 1
            },
            {
                "size": " 108х16",
                "koef": "0.0363",
                "metrs": 1
            },
            {
                "size": " 108х17",
                "koef": "0.03815",
                "metrs": 1
            },
            {
                "size": " 108х18",
                "koef": "0.03995",
                "metrs": 1
            },
            {
                "size": " 108х20",
                "koef": "0.0434",
                "metrs": 1
            },
            {
                "size": " 108х22",
                "koef": "0.04666",
                "metrs": 1
            },
            {
                "size": " 108х24",
                "koef": "0.04972",
                "metrs": 1
            },
            {
                "size": " 108х25",
                "koef": "0.05117",
                "metrs": 1
            },
            {
                "size": " 108х26",
                "koef": "0.05258",
                "metrs": 1
            },
            {
                "size": " 108х28",
                "koef": "0.05524",
                "metrs": 1
            },
            {
                "size": " 108х30",
                "koef": "0.05771",
                "metrs": 1
            },
            {
                "size": " 110х15",
                "koef": "0.03514",
                "metrs": 1
            },
            {
                "size": " 110х26",
                "koef": "0.05386",
                "metrs": 1
            },
            {
                "size": " 110х30",
                "koef": "0.05919",
                "metrs": 1
            },
            {
                "size": " 114х4",
                "koef": "0.01085",
                "metrs": 1
            },
            {
                "size": " 114х4,5",
                "koef": "0.01215",
                "metrs": 1
            },
            {
                "size": " 114х5",
                "koef": "0.01344",
                "metrs": 1
            },
            {
                "size": " 114х5,5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": " 114х6",
                "koef": "0.01598",
                "metrs": 1
            },
            {
                "size": " 114х7",
                "koef": "0.01847",
                "metrs": 1
            },
            {
                "size": " 114х7,5",
                "koef": "0.0197",
                "metrs": 1
            },
            {
                "size": " 114х8",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": " 114х9",
                "koef": "0.02331",
                "metrs": 1
            },
            {
                "size": " 114х10",
                "koef": "0.02565",
                "metrs": 1
            },
            {
                "size": " 114х11",
                "koef": "0.02794",
                "metrs": 1
            },
            {
                "size": " 114х12",
                "koef": "0.03019",
                "metrs": 1
            },
            {
                "size": " 114х13",
                "koef": "0.03238",
                "metrs": 1
            },
            {
                "size": " 114х14",
                "koef": "0.03453",
                "metrs": 1
            },
            {
                "size": " 114х15",
                "koef": "0.03662",
                "metrs": 1
            },
            {
                "size": " 114х16",
                "koef": "0.03867",
                "metrs": 1
            },
            {
                "size": " 114х17",
                "koef": "0.04067",
                "metrs": 1
            },
            {
                "size": " 114х18",
                "koef": "0.04262",
                "metrs": 1
            },
            {
                "size": " 114х20",
                "koef": "0.04636",
                "metrs": 1
            },
            {
                "size": " 114х22",
                "koef": "0.04991",
                "metrs": 1
            },
            {
                "size": " 114х23",
                "koef": "0.05162",
                "metrs": 1
            },
            {
                "size": " 114х24",
                "koef": "0.05327",
                "metrs": 1
            },
            {
                "size": " 114х25",
                "koef": "0.05487",
                "metrs": 1
            },
            {
                "size": " 114х27",
                "koef": "0.05793",
                "metrs": 1
            },
            {
                "size": " 114х27",
                "koef": "0.05793",
                "metrs": 1
            },
            {
                "size": " 114х28",
                "koef": "0.05938",
                "metrs": 1
            },
            {
                "size": " 114х29",
                "koef": "0.06079",
                "metrs": 1
            },
            {
                "size": " 114х30",
                "koef": "0.06215",
                "metrs": 1
            },
            {
                "size": " 114х32",
                "koef": "0.06471",
                "metrs": 1
            },
            {
                "size": " 118х17",
                "koef": "0.04234",
                "metrs": 1
            },
            {
                "size": " 121х5",
                "koef": "0.0143",
                "metrs": 1
            },
            {
                "size": " 121х6",
                "koef": "0.01702",
                "metrs": 1
            },
            {
                "size": " 121х7",
                "koef": "0.01968",
                "metrs": 1
            },
            {
                "size": " 121х8",
                "koef": "0.02229",
                "metrs": 1
            },
            {
                "size": " 121х9",
                "koef": "0.02486",
                "metrs": 1
            },
            {
                "size": " 121х10",
                "koef": "0.02737",
                "metrs": 1
            },
            {
                "size": " 121х11",
                "koef": "0.02984",
                "metrs": 1
            },
            {
                "size": " 121х12",
                "koef": "0.03226",
                "metrs": 1
            },
            {
                "size": " 121х13",
                "koef": "0.03462",
                "metrs": 1
            },
            {
                "size": " 121х14",
                "koef": "0.03694",
                "metrs": 1
            },
            {
                "size": " 121х15",
                "koef": "0.03921",
                "metrs": 1
            },
            {
                "size": " 121х16",
                "koef": "0.04143",
                "metrs": 1
            },
            {
                "size": " 121х17",
                "koef": "0.0436",
                "metrs": 1
            },
            {
                "size": " 121х18",
                "koef": "0.04572",
                "metrs": 1
            },
            {
                "size": " 121х20",
                "koef": "0.04982",
                "metrs": 1
            },
            {
                "size": " 121х22",
                "koef": "0.05371",
                "metrs": 1
            },
            {
                "size": " 121х24",
                "koef": "0.05741",
                "metrs": 1
            },
            {
                "size": " 121х25",
                "koef": "0.05919",
                "metrs": 1
            },
            {
                "size": " 121х26",
                "koef": "0.06091",
                "metrs": 1
            },
            {
                "size": " 121х27",
                "koef": "0.06259",
                "metrs": 1
            },
            {
                "size": " 121х28",
                "koef": "0.06422",
                "metrs": 1
            },
            {
                "size": " 121х29",
                "koef": "0.0658",
                "metrs": 1
            },
            {
                "size": " 121х30",
                "koef": "0.06733",
                "metrs": 1
            },
            {
                "size": " 121х31",
                "koef": "0.06881",
                "metrs": 1
            },
            {
                "size": " 121х32",
                "koef": "0.07024",
                "metrs": 1
            },
            {
                "size": " 121х33",
                "koef": "0.07162",
                "metrs": 1
            },
            {
                "size": " 125х30",
                "koef": "0.07029",
                "metrs": 1
            },
            {
                "size": " 127х4",
                "koef": "0.01213",
                "metrs": 1
            },
            {
                "size": " 127х5",
                "koef": "0.01504",
                "metrs": 1
            },
            {
                "size": " 127х6",
                "koef": "0.0179",
                "metrs": 1
            },
            {
                "size": " 127х7",
                "koef": "0.02072",
                "metrs": 1
            },
            {
                "size": " 127х8",
                "koef": "0.02348",
                "metrs": 1
            },
            {
                "size": " 127х9",
                "koef": "0.02619",
                "metrs": 1
            },
            {
                "size": " 127х10",
                "koef": "0.02885",
                "metrs": 1
            },
            {
                "size": " 127х11",
                "koef": "0.03147",
                "metrs": 1
            },
            {
                "size": " 127х12",
                "koef": "0.03403",
                "metrs": 1
            },
            {
                "size": " 127х13",
                "koef": "0.03655",
                "metrs": 1
            },
            {
                "size": " 127х14",
                "koef": "0.03901",
                "metrs": 1
            },
            {
                "size": " 127х15",
                "koef": "0.04143",
                "metrs": 1
            },
            {
                "size": " 127х16",
                "koef": "0.0438",
                "metrs": 1
            },
            {
                "size": " 127х17",
                "koef": "0.04612",
                "metrs": 1
            },
            {
                "size": " 127х18",
                "koef": "0.04839",
                "metrs": 1
            },
            {
                "size": " 127х20",
                "koef": "0.05278",
                "metrs": 1
            },
            {
                "size": " 127х22",
                "koef": "0.05697",
                "metrs": 1
            },
            {
                "size": " 127х23",
                "koef": "0.05899",
                "metrs": 1
            },
            {
                "size": " 127х24",
                "koef": "0.06096",
                "metrs": 1
            },
            {
                "size": " 127х25",
                "koef": "0.06289",
                "metrs": 1
            },
            {
                "size": " 127х27",
                "koef": "0.06659",
                "metrs": 1
            },
            {
                "size": " 127х28",
                "koef": "0.06836",
                "metrs": 1
            },
            {
                "size": " 127х30",
                "koef": "0.07176",
                "metrs": 1
            },
            {
                "size": " 127х32",
                "koef": "0.07497",
                "metrs": 1
            },
            {
                "size": " 127х33",
                "koef": "0.0765",
                "metrs": 1
            },
            {
                "size": " 127х34",
                "koef": "0.07798",
                "metrs": 1
            },
            {
                "size": " 127х34,5",
                "koef": "0.0787",
                "metrs": 1
            },
            {
                "size": " 127х35",
                "koef": "0.07941",
                "metrs": 1
            },
            {
                "size": " 131х19",
                "koef": "0.05248",
                "metrs": 1
            },
            {
                "size": " 133х4",
                "koef": "0.01273",
                "metrs": 1
            },
            {
                "size": " 133х4,5",
                "koef": "0.01426",
                "metrs": 1
            },
            {
                "size": " 133х5",
                "koef": "0.01578",
                "metrs": 1
            },
            {
                "size": " 133х6",
                "koef": "0.01879",
                "metrs": 1
            },
            {
                "size": " 133х7",
                "koef": "0.02175",
                "metrs": 1
            },
            {
                "size": " 133х8",
                "koef": "0.02466",
                "metrs": 1
            },
            {
                "size": " 133х9",
                "koef": "0.02752",
                "metrs": 1
            },
            {
                "size": " 133х10",
                "koef": "0.03033",
                "metrs": 1
            },
            {
                "size": " 133х11",
                "koef": "0.0331",
                "metrs": 1
            },
            {
                "size": " 133х12",
                "koef": "0.03581",
                "metrs": 1
            },
            {
                "size": " 133х13",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": " 133х14",
                "koef": "0.04109",
                "metrs": 1
            },
            {
                "size": " 133х15",
                "koef": "0.04365",
                "metrs": 1
            },
            {
                "size": " 133х16",
                "koef": "0.04617",
                "metrs": 1
            },
            {
                "size": " 133х17",
                "koef": "0.04863",
                "metrs": 1
            },
            {
                "size": " 133х18",
                "koef": "0.05105",
                "metrs": 1
            },
            {
                "size": " 133х20",
                "koef": "0.05573",
                "metrs": 1
            },
            {
                "size": " 133х22",
                "koef": "0.06022",
                "metrs": 1
            },
            {
                "size": " 133х25",
                "koef": "0.06659",
                "metrs": 1
            },
            {
                "size": " 133х26",
                "koef": "0.06861",
                "metrs": 1
            },
            {
                "size": " 133х28",
                "koef": "0.0725",
                "metrs": 1
            },
            {
                "size": " 133х30",
                "koef": "0.0762",
                "metrs": 1
            },
            {
                "size": " 133х32",
                "koef": "0.07971",
                "metrs": 1
            },
            {
                "size": " 133х34",
                "koef": "0.08301",
                "metrs": 1
            },
            {
                "size": " 133х35",
                "koef": "0.08459",
                "metrs": 1
            },
            {
                "size": " 133х36",
                "koef": "0.08612",
                "metrs": 1
            },
            {
                "size": " 135х17",
                "koef": "0.04947",
                "metrs": 1
            },
            {
                "size": " 135х20",
                "koef": "0.05672",
                "metrs": 1
            },
            {
                "size": " 135х23",
                "koef": "0.06353",
                "metrs": 1
            },
            {
                "size": " 140х5",
                "koef": "0.01665",
                "metrs": 1
            },
            {
                "size": " 140х6",
                "koef": "0.01983",
                "metrs": 1
            },
            {
                "size": " 140х7",
                "koef": "0.02296",
                "metrs": 1
            },
            {
                "size": " 140х8",
                "koef": "0.02604",
                "metrs": 1
            },
            {
                "size": " 140х9",
                "koef": "0.02908",
                "metrs": 1
            },
            {
                "size": " 140х10",
                "koef": "0.03206",
                "metrs": 1
            },
            {
                "size": " 140х11",
                "koef": "0.03499",
                "metrs": 1
            },
            {
                "size": " 140х12",
                "koef": "0.03788",
                "metrs": 1
            },
            {
                "size": " 140х13",
                "koef": "0.04072",
                "metrs": 1
            },
            {
                "size": " 140х14",
                "koef": "0.0435",
                "metrs": 1
            },
            {
                "size": " 140х15",
                "koef": "0.04624",
                "metrs": 1
            },
            {
                "size": " 140х16",
                "koef": "0.04893",
                "metrs": 1
            },
            {
                "size": " 140х17",
                "koef": "0.05157",
                "metrs": 1
            },
            {
                "size": " 140х18",
                "koef": "0.05416",
                "metrs": 1
            },
            {
                "size": " 140х20",
                "koef": "0.05919",
                "metrs": 1
            },
            {
                "size": " 140х22",
                "koef": "0.06402",
                "metrs": 1
            },
            {
                "size": " 140х24",
                "koef": "0.06866",
                "metrs": 1
            },
            {
                "size": " 140х25",
                "koef": "0.0709",
                "metrs": 1
            },
            {
                "size": " 140х26",
                "koef": "0.0731",
                "metrs": 1
            },
            {
                "size": " 140х28",
                "koef": "0.07734",
                "metrs": 1
            },
            {
                "size": " 140х30",
                "koef": "0.08138",
                "metrs": 1
            },
            {
                "size": " 140х31",
                "koef": "0.08333",
                "metrs": 1
            },
            {
                "size": " 140х32",
                "koef": "0.08523",
                "metrs": 1
            },
            {
                "size": " 140х34",
                "koef": "0.08888",
                "metrs": 1
            },
            {
                "size": " 140х35",
                "koef": "0.09063",
                "metrs": 1
            },
            {
                "size": " 140х36",
                "koef": "0.09233",
                "metrs": 1
            },
            {
                "size": " 140х37",
                "koef": "0.09398",
                "metrs": 1
            },
            {
                "size": " 145х22",
                "koef": "0.06673",
                "metrs": 1
            },
            {
                "size": " 146х5",
                "koef": "0.01739",
                "metrs": 1
            },
            {
                "size": " 146х6",
                "koef": "0.02072",
                "metrs": 1
            },
            {
                "size": " 146х6,5",
                "koef": "0.02236",
                "metrs": 1
            },
            {
                "size": " 146х7",
                "koef": "0.024",
                "metrs": 1
            },
            {
                "size": " 146х8",
                "koef": "0.02723",
                "metrs": 1
            },
            {
                "size": " 146х9",
                "koef": "0.03041",
                "metrs": 1
            },
            {
                "size": " 146х10",
                "koef": "0.03354",
                "metrs": 1
            },
            {
                "size": " 146х11",
                "koef": "0.03662",
                "metrs": 1
            },
            {
                "size": " 146х12",
                "koef": "0.03966",
                "metrs": 1
            },
            {
                "size": " 146х13",
                "koef": "0.04264",
                "metrs": 1
            },
            {
                "size": " 146х14",
                "koef": "0.04557",
                "metrs": 1
            },
            {
                "size": " 146х15",
                "koef": "0.04846",
                "metrs": 1
            },
            {
                "size": " 146х16",
                "koef": "0.0513",
                "metrs": 1
            },
            {
                "size": " 146х17",
                "koef": "0.05408",
                "metrs": 1
            },
            {
                "size": " 146х18",
                "koef": "0.05682",
                "metrs": 1
            },
            {
                "size": " 146х20",
                "koef": "0.06215",
                "metrs": 1
            },
            {
                "size": " 146х22",
                "koef": "0.06728",
                "metrs": 1
            },
            {
                "size": " 146х25",
                "koef": "0.0746",
                "metrs": 1
            },
            {
                "size": " 146х26",
                "koef": "0.07694",
                "metrs": 1
            },
            {
                "size": " 146х28",
                "koef": "0.08148",
                "metrs": 1
            },
            {
                "size": " 146х30",
                "koef": "0.08582",
                "metrs": 1
            },
            {
                "size": " 146х32",
                "koef": "0.08997",
                "metrs": 1
            },
            {
                "size": " 146х36",
                "koef": "0.09766",
                "metrs": 1
            },
            {
                "size": " 146х38",
                "koef": "0.1012",
                "metrs": 1
            },
            {
                "size": " 150х28",
                "koef": "0.08424",
                "metrs": 1
            },
            {
                "size": " 152х5",
                "koef": "0.01813",
                "metrs": 1
            },
            {
                "size": " 152х6",
                "koef": "0.0216",
                "metrs": 1
            },
            {
                "size": " 152х7",
                "koef": "0.02503",
                "metrs": 1
            },
            {
                "size": " 152х8",
                "koef": "0.02841",
                "metrs": 1
            },
            {
                "size": " 152х9",
                "koef": "0.03174",
                "metrs": 1
            },
            {
                "size": " 152х10",
                "koef": "0.03502",
                "metrs": 1
            },
            {
                "size": " 152х11",
                "koef": "0.03825",
                "metrs": 1
            },
            {
                "size": " 152х12",
                "koef": "0.04143",
                "metrs": 1
            },
            {
                "size": " 152х13",
                "koef": "0.04456",
                "metrs": 1
            },
            {
                "size": " 152х14",
                "koef": "0.04765",
                "metrs": 1
            },
            {
                "size": " 152х15",
                "koef": "0.05068",
                "metrs": 1
            },
            {
                "size": " 152х16",
                "koef": "0.05366",
                "metrs": 1
            },
            {
                "size": " 152х17",
                "koef": "0.0566",
                "metrs": 1
            },
            {
                "size": " 152х18",
                "koef": "0.05948",
                "metrs": 1
            },
            {
                "size": " 152х20",
                "koef": "0.06511",
                "metrs": 1
            },
            {
                "size": " 152х22",
                "koef": "0.07053",
                "metrs": 1
            },
            {
                "size": " 152х25",
                "koef": "0.0783",
                "metrs": 1
            },
            {
                "size": " 152х28",
                "koef": "0.08562",
                "metrs": 1
            },
            {
                "size": " 152х30",
                "koef": "0.09026",
                "metrs": 1
            },
            {
                "size": " 152х32",
                "koef": "0.0947",
                "metrs": 1
            },
            {
                "size": " 152х33",
                "koef": "0.09685",
                "metrs": 1
            },
            {
                "size": " 152х34",
                "koef": "0.09894",
                "metrs": 1
            },
            {
                "size": " 152х35",
                "koef": "0.101",
                "metrs": 1
            },
            {
                "size": " 152х36",
                "koef": "0.103",
                "metrs": 1
            },
            {
                "size": " 152х37",
                "koef": "0.1049",
                "metrs": 1
            },
            {
                "size": " 152х39",
                "koef": "0.1087",
                "metrs": 1
            },
            {
                "size": " 152х40",
                "koef": "0.1105",
                "metrs": 1
            },
            {
                "size": " 155х22",
                "koef": "0.07216",
                "metrs": 1
            },
            {
                "size": " 157х43",
                "koef": "0.1209",
                "metrs": 1
            },
            {
                "size": " 158х22",
                "koef": "0.07379",
                "metrs": 1
            },
            {
                "size": " 159х4,5",
                "koef": "0.01715",
                "metrs": 1
            },
            {
                "size": " 159х5",
                "koef": "0.01899",
                "metrs": 1
            },
            {
                "size": " 159х6",
                "koef": "0.02264",
                "metrs": 1
            },
            {
                "size": " 159х7",
                "koef": "0.02624",
                "metrs": 1
            },
            {
                "size": " 159х8",
                "koef": "0.02979",
                "metrs": 1
            },
            {
                "size": " 159х9",
                "koef": "0.03329",
                "metrs": 1
            },
            {
                "size": " 159х10",
                "koef": "0.03675",
                "metrs": 1
            },
            {
                "size": " 159х11",
                "koef": "0.04015",
                "metrs": 1
            },
            {
                "size": " 159х12",
                "koef": "0.0435",
                "metrs": 1
            },
            {
                "size": " 159х13",
                "koef": "0.04681",
                "metrs": 1
            },
            {
                "size": " 159х14",
                "koef": "0.05006",
                "metrs": 1
            },
            {
                "size": " 159х15",
                "koef": "0.05327",
                "metrs": 1
            },
            {
                "size": " 159х16",
                "koef": "0.05643",
                "metrs": 1
            },
            {
                "size": " 159х17",
                "koef": "0.05953",
                "metrs": 1
            },
            {
                "size": " 159х18",
                "koef": "0.06259",
                "metrs": 1
            },
            {
                "size": " 159х20",
                "koef": "0.06856",
                "metrs": 1
            },
            {
                "size": " 159х22",
                "koef": "0.07433",
                "metrs": 1
            },
            {
                "size": " 159х25",
                "koef": "0.08262",
                "metrs": 1
            },
            {
                "size": " 159х28",
                "koef": "0.09046",
                "metrs": 1
            },
            {
                "size": " 159х30",
                "koef": "0.09544",
                "metrs": 1
            },
            {
                "size": " 159х32",
                "koef": "0.1002",
                "metrs": 1
            },
            {
                "size": " 159х34",
                "koef": "0.1048",
                "metrs": 1
            },
            {
                "size": " 159х35",
                "koef": "0.107",
                "metrs": 1
            },
            {
                "size": " 159х36",
                "koef": "0.1092",
                "metrs": 1
            },
            {
                "size": " 159х39",
                "koef": "0.1154",
                "metrs": 1
            },
            {
                "size": " 159х42",
                "koef": "0.1212",
                "metrs": 1
            },
            {
                "size": " 168х5",
                "koef": "0.0201",
                "metrs": 1
            },
            {
                "size": " 168х6",
                "koef": "0.02397",
                "metrs": 1
            },
            {
                "size": " 168х7",
                "koef": "0.02779",
                "metrs": 1
            },
            {
                "size": " 168х8",
                "koef": "0.03157",
                "metrs": 1
            },
            {
                "size": " 168х9",
                "koef": "0.03529",
                "metrs": 1
            },
            {
                "size": " 168х9,5",
                "koef": "0.03713",
                "metrs": 1
            },
            {
                "size": " 168х10",
                "koef": "0.03897",
                "metrs": 1
            },
            {
                "size": " 168х11",
                "koef": "0.04259",
                "metrs": 1
            },
            {
                "size": " 168х12",
                "koef": "0.04617",
                "metrs": 1
            },
            {
                "size": " 168х13",
                "koef": "0.04969",
                "metrs": 1
            },
            {
                "size": " 168х14",
                "koef": "0.05317",
                "metrs": 1
            },
            {
                "size": " 168х15",
                "koef": "0.0566",
                "metrs": 1
            },
            {
                "size": " 168х16",
                "koef": "0.05998",
                "metrs": 1
            },
            {
                "size": " 168х17",
                "koef": "0.06331",
                "metrs": 1
            },
            {
                "size": " 168х18",
                "koef": "0.06659",
                "metrs": 1
            },
            {
                "size": " 168х20",
                "koef": "0.073",
                "metrs": 1
            },
            {
                "size": " 168х22",
                "koef": "0.07921",
                "metrs": 1
            },
            {
                "size": " 168х23",
                "koef": "0.08225",
                "metrs": 1
            },
            {
                "size": " 168х25",
                "koef": "0.08816",
                "metrs": 1
            },
            {
                "size": " 168х26",
                "koef": "0.09105",
                "metrs": 1
            },
            {
                "size": " 168х28",
                "koef": "0.09667",
                "metrs": 1
            },
            {
                "size": " 168х30",
                "koef": "0.1021",
                "metrs": 1
            },
            {
                "size": " 168х32",
                "koef": "0.1073",
                "metrs": 1
            },
            {
                "size": " 168х36",
                "koef": "0.1172",
                "metrs": 1
            },
            {
                "size": " 168х38",
                "koef": "0.1218",
                "metrs": 1
            },
            {
                "size": " 168х40",
                "koef": "0.1263",
                "metrs": 1
            },
            {
                "size": " 168х42",
                "koef": "0.1305",
                "metrs": 1
            },
            {
                "size": " 168х45",
                "koef": "0.1365",
                "metrs": 1
            },
            {
                "size": " 170х22",
                "koef": "0.0803",
                "metrs": 1
            },
            {
                "size": " 170х25",
                "koef": "0.0894",
                "metrs": 1
            },
            {
                "size": " 172х22",
                "koef": "0.08138",
                "metrs": 1
            },
            {
                "size": " 178х19",
                "koef": "0.0745",
                "metrs": 1
            },
            {
                "size": " 178х35",
                "koef": "0.1234",
                "metrs": 1
            },
            {
                "size": " 178х36",
                "koef": "0.1261",
                "metrs": 1
            },
            {
                "size": " 180х5",
                "koef": "0.02158",
                "metrs": 1
            },
            {
                "size": " 180х6",
                "koef": "0.02575",
                "metrs": 1
            },
            {
                "size": " 180х7",
                "koef": "0.02987",
                "metrs": 1
            },
            {
                "size": " 180х8",
                "koef": "0.03393",
                "metrs": 1
            },
            {
                "size": " 180х9",
                "koef": "0.03795",
                "metrs": 1
            },
            {
                "size": " 180х10",
                "koef": "0.04192",
                "metrs": 1
            },
            {
                "size": " 180х12",
                "koef": "0.04972",
                "metrs": 1
            },
            {
                "size": " 180х14",
                "koef": "0.05731",
                "metrs": 1
            },
            {
                "size": " 180х15",
                "koef": "0.06104",
                "metrs": 1
            },
            {
                "size": " 180х16",
                "koef": "0.06471",
                "metrs": 1
            },
            {
                "size": " 180х18",
                "koef": "0.07191",
                "metrs": 1
            },
            {
                "size": " 180х20",
                "koef": "0.07892",
                "metrs": 1
            },
            {
                "size": " 180х21",
                "koef": "0.08234",
                "metrs": 1
            },
            {
                "size": " 180х22",
                "koef": "0.08572",
                "metrs": 1
            },
            {
                "size": " 180х24",
                "koef": "0.09233",
                "metrs": 1
            },
            {
                "size": " 180х25",
                "koef": "0.09556",
                "metrs": 1
            },
            {
                "size": " 180х26",
                "koef": "0.09874",
                "metrs": 1
            },
            {
                "size": " 180х28",
                "koef": "0.105",
                "metrs": 1
            },
            {
                "size": " 180х30",
                "koef": "0.111",
                "metrs": 1
            },
            {
                "size": " 180х32",
                "koef": "0.1168",
                "metrs": 1
            },
            {
                "size": " 180х35",
                "koef": "0.1252",
                "metrs": 1
            },
            {
                "size": " 180х36",
                "koef": "0.1278",
                "metrs": 1
            },
            {
                "size": " 180х40",
                "koef": "0.1381",
                "metrs": 1
            },
            {
                "size": " 180х45",
                "koef": "0.1498",
                "metrs": 1
            },
            {
                "size": " 185х25",
                "koef": "0.09865",
                "metrs": 1
            },
            {
                "size": " 185х30",
                "koef": "0.1147",
                "metrs": 1
            },
            {
                "size": " 185х36",
                "koef": "0.1323",
                "metrs": 1
            },
            {
                "size": " 185х45",
                "koef": "0.1554",
                "metrs": 1
            },
            {
                "size": " 186х20",
                "koef": "0.08188",
                "metrs": 1
            },
            {
                "size": " 192х25",
                "koef": "0.103",
                "metrs": 1
            },
            {
                "size": " 194х6",
                "koef": "0.02782",
                "metrs": 1
            },
            {
                "size": " 194х7",
                "koef": "0.03228",
                "metrs": 1
            },
            {
                "size": " 194х8",
                "koef": "0.0367",
                "metrs": 1
            },
            {
                "size": " 194х10",
                "koef": "0.04538",
                "metrs": 1
            },
            {
                "size": " 194х12",
                "koef": "0.05386",
                "metrs": 1
            },
            {
                "size": " 194х14",
                "koef": "0.06215",
                "metrs": 1
            },
            {
                "size": " 194х16",
                "koef": "0.07024",
                "metrs": 1
            },
            {
                "size": " 194х18",
                "koef": "0.07813",
                "metrs": 1
            },
            {
                "size": " 194х20",
                "koef": "0.08582",
                "metrs": 1
            },
            {
                "size": " 194х22",
                "koef": "0.09332",
                "metrs": 1
            },
            {
                "size": " 194х23",
                "koef": "0.09699",
                "metrs": 1
            },
            {
                "size": " 194х24",
                "koef": "0.1006",
                "metrs": 1
            },
            {
                "size": " 194х25",
                "koef": "0.1042",
                "metrs": 1
            },
            {
                "size": " 194х26",
                "koef": "0.1077",
                "metrs": 1
            },
            {
                "size": " 194х28",
                "koef": "0.1146",
                "metrs": 1
            },
            {
                "size": " 194х30",
                "koef": "0.1213",
                "metrs": 1
            },
            {
                "size": " 194х32",
                "koef": "0.1278",
                "metrs": 1
            },
            {
                "size": " 194х34",
                "koef": "0.1342",
                "metrs": 1
            },
            {
                "size": " 194х35",
                "koef": "0.1372",
                "metrs": 1
            },
            {
                "size": " 194х36",
                "koef": "0.1403",
                "metrs": 1
            },
            {
                "size": " 194х38",
                "koef": "0.1462",
                "metrs": 1
            },
            {
                "size": " 194х40",
                "koef": "0.1519",
                "metrs": 1
            },
            {
                "size": " 194х45",
                "koef": "0.1654",
                "metrs": 1
            },
            {
                "size": " 194х50",
                "koef": "0.1776",
                "metrs": 1
            },
            {
                "size": " 195х20",
                "koef": "0.08632",
                "metrs": 1
            },
            {
                "size": " 203х8",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": " 203х10",
                "koef": "0.0476",
                "metrs": 1
            },
            {
                "size": " 203х12",
                "koef": "0.05652",
                "metrs": 1
            },
            {
                "size": " 203х14",
                "koef": "0.06525",
                "metrs": 1
            },
            {
                "size": " 203х16",
                "koef": "0.07379",
                "metrs": 1
            },
            {
                "size": " 203х18",
                "koef": "0.08212",
                "metrs": 1
            },
            {
                "size": " 203х19",
                "koef": "0.08622",
                "metrs": 1
            },
            {
                "size": " 203х20",
                "koef": "0.09026",
                "metrs": 1
            },
            {
                "size": " 203х22",
                "koef": "0.0982",
                "metrs": 1
            },
            {
                "size": " 203х24",
                "koef": "0.1059",
                "metrs": 1
            },
            {
                "size": " 203х25",
                "koef": "0.1097",
                "metrs": 1
            },
            {
                "size": " 203х26",
                "koef": "0.1135",
                "metrs": 1
            },
            {
                "size": " 203х27",
                "koef": "0.1172",
                "metrs": 1
            },
            {
                "size": " 203х28",
                "koef": "0.1208",
                "metrs": 1
            },
            {
                "size": " 203х30",
                "koef": "0.128",
                "metrs": 1
            },
            {
                "size": " 203х32",
                "koef": "0.1349",
                "metrs": 1
            },
            {
                "size": " 203х34",
                "koef": "0.1417",
                "metrs": 1
            },
            {
                "size": " 203х35",
                "koef": "0.145",
                "metrs": 1
            },
            {
                "size": " 203х36",
                "koef": "0.1483",
                "metrs": 1
            },
            {
                "size": " 203х40",
                "koef": "0.1608",
                "metrs": 1
            },
            {
                "size": " 203х42",
                "koef": "0.1668",
                "metrs": 1
            },
            {
                "size": " 203х45",
                "koef": "0.1753",
                "metrs": 1
            },
            {
                "size": " 203х46",
                "koef": "0.1781",
                "metrs": 1
            },
            {
                "size": " 203х47",
                "koef": "0.1808",
                "metrs": 1
            },
            {
                "size": " 203х50",
                "koef": "0.1887",
                "metrs": 1
            },
            {
                "size": " 205х30",
                "koef": "0.1295",
                "metrs": 1
            },
            {
                "size": " 219х6",
                "koef": "0.03152",
                "metrs": 1
            },
            {
                "size": " 219х7",
                "koef": "0.0366",
                "metrs": 1
            },
            {
                "size": " 219х8",
                "koef": "0.04163",
                "metrs": 1
            },
            {
                "size": " 219х9",
                "koef": "0.04661",
                "metrs": 1
            },
            {
                "size": " 219х10",
                "koef": "0.05154",
                "metrs": 1
            },
            {
                "size": " 219х11",
                "koef": "0.05643",
                "metrs": 1
            },
            {
                "size": " 219х12",
                "koef": "0.06126",
                "metrs": 1
            },
            {
                "size": " 219х13",
                "koef": "0.06604",
                "metrs": 1
            },
            {
                "size": " 219х14",
                "koef": "0.07078",
                "metrs": 1
            },
            {
                "size": " 219х15",
                "koef": "0.07546",
                "metrs": 1
            },
            {
                "size": " 219х16",
                "koef": "0.0801",
                "metrs": 1
            },
            {
                "size": " 219х17",
                "koef": "0.08469",
                "metrs": 1
            },
            {
                "size": " 219х18",
                "koef": "0.08923",
                "metrs": 1
            },
            {
                "size": " 219х19",
                "koef": "0.09371",
                "metrs": 1
            },
            {
                "size": " 219х20",
                "koef": "0.09815",
                "metrs": 1
            },
            {
                "size": " 219х22",
                "koef": "0.1069",
                "metrs": 1
            },
            {
                "size": " 219х25",
                "koef": "0.1196",
                "metrs": 1
            },
            {
                "size": " 219х26",
                "koef": "0.1238",
                "metrs": 1
            },
            {
                "size": " 219х28",
                "koef": "0.1319",
                "metrs": 1
            },
            {
                "size": " 219х30",
                "koef": "0.1398",
                "metrs": 1
            },
            {
                "size": " 219х32",
                "koef": "0.1476",
                "metrs": 1
            },
            {
                "size": " 219х34",
                "koef": "0.1551",
                "metrs": 1
            },
            {
                "size": " 219х36",
                "koef": "0.1625",
                "metrs": 1
            },
            {
                "size": " 219х40",
                "koef": "0.1766",
                "metrs": 1
            },
            {
                "size": " 219х45",
                "koef": "0.1931",
                "metrs": 1
            },
            {
                "size": " 219х50",
                "koef": "0.2084",
                "metrs": 1
            },
            {
                "size": " 245х6",
                "koef": "0.03536",
                "metrs": 1
            },
            {
                "size": " 245х7",
                "koef": "0.04109",
                "metrs": 1
            },
            {
                "size": " 245х8",
                "koef": "0.04676",
                "metrs": 1
            },
            {
                "size": " 245х9",
                "koef": "0.05238",
                "metrs": 1
            },
            {
                "size": " 245х10",
                "koef": "0.05795",
                "metrs": 1
            },
            {
                "size": " 245х12",
                "koef": "0.06895",
                "metrs": 1
            },
            {
                "size": " 245х14",
                "koef": "0.07976",
                "metrs": 1
            },
            {
                "size": " 245х16",
                "koef": "0.09036",
                "metrs": 1
            },
            {
                "size": " 245х18",
                "koef": "0.1008",
                "metrs": 1
            },
            {
                "size": " 245х20",
                "koef": "0.111",
                "metrs": 1
            },
            {
                "size": " 245х22",
                "koef": "0.121",
                "metrs": 1
            },
            {
                "size": " 245х25",
                "koef": "0.1356",
                "metrs": 1
            },
            {
                "size": " 245х30",
                "koef": "0.1591",
                "metrs": 1
            },
            {
                "size": " 245х32",
                "koef": "0.1681",
                "metrs": 1
            },
            {
                "size": " 245х36",
                "koef": "0.1856",
                "metrs": 1
            },
            {
                "size": " 245х40",
                "koef": "0.2022",
                "metrs": 1
            },
            {
                "size": " 245х45",
                "koef": "0.222",
                "metrs": 1
            },
            {
                "size": " 245х50",
                "koef": "0.2404",
                "metrs": 1
            },
            {
                "size": " 273х6",
                "koef": "0.03951",
                "metrs": 1
            },
            {
                "size": " 273х7",
                "koef": "0.04592",
                "metrs": 1
            },
            {
                "size": " 273х8",
                "koef": "0.05228",
                "metrs": 1
            },
            {
                "size": " 273х9",
                "koef": "0.0586",
                "metrs": 1
            },
            {
                "size": " 273х10",
                "koef": "0.06486",
                "metrs": 1
            },
            {
                "size": " 273х11",
                "koef": "0.07107",
                "metrs": 1
            },
            {
                "size": " 273х12",
                "koef": "0.07724",
                "metrs": 1
            },
            {
                "size": " 273х13",
                "koef": "0.08336",
                "metrs": 1
            },
            {
                "size": " 273х14",
                "koef": "0.08942",
                "metrs": 1
            },
            {
                "size": " 273х15",
                "koef": "0.09544",
                "metrs": 1
            },
            {
                "size": " 273х16",
                "koef": "0.1014",
                "metrs": 1
            },
            {
                "size": " 273х17",
                "koef": "0.1073",
                "metrs": 1
            },
            {
                "size": " 273х18",
                "koef": "0.1132",
                "metrs": 1
            },
            {
                "size": " 273х19",
                "koef": "0.119",
                "metrs": 1
            },
            {
                "size": " 273х20",
                "koef": "0.1248",
                "metrs": 1
            },
            {
                "size": " 273х21",
                "koef": "0.1305",
                "metrs": 1
            },
            {
                "size": " 273х22",
                "koef": "0.1362",
                "metrs": 1
            },
            {
                "size": " 273х30",
                "koef": "0.1798",
                "metrs": 1
            },
            {
                "size": " 273х32",
                "koef": "0.1902",
                "metrs": 1
            },
            {
                "size": " 273х36",
                "koef": "0.2104",
                "metrs": 1
            },
            {
                "size": " 273х40",
                "koef": "0.2298",
                "metrs": 1
            },
            {
                "size": " 273х45",
                "koef": "0.253",
                "metrs": 1
            },
            {
                "size": " 273х50",
                "koef": "0.275",
                "metrs": 1
            },
            {
                "size": " 299х6",
                "koef": "0.04335",
                "metrs": 1
            },
            {
                "size": " 299х7",
                "koef": "0.05041",
                "metrs": 1
            },
            {
                "size": " 299х8",
                "koef": "0.05741",
                "metrs": 1
            },
            {
                "size": " 299х9",
                "koef": "0.06437",
                "metrs": 1
            },
            {
                "size": " 299х10",
                "koef": "0.07127",
                "metrs": 1
            },
            {
                "size": " 299х12",
                "koef": "0.08493",
                "metrs": 1
            },
            {
                "size": " 299х14",
                "koef": "0.0984",
                "metrs": 1
            },
            {
                "size": " 299х16",
                "koef": "0.1117",
                "metrs": 1
            },
            {
                "size": " 299х18",
                "koef": "0.1247",
                "metrs": 1
            },
            {
                "size": " 299х20",
                "koef": "0.1376",
                "metrs": 1
            },
            {
                "size": " 299х22",
                "koef": "0.1503",
                "metrs": 1
            },
            {
                "size": " 299х25",
                "koef": "0.1689",
                "metrs": 1
            },
            {
                "size": " 299х28",
                "koef": "0.1871",
                "metrs": 1
            },
            {
                "size": " 299х30",
                "koef": "0.199",
                "metrs": 1
            },
            {
                "size": " 299х32",
                "koef": "0.2107",
                "metrs": 1
            },
            {
                "size": " 299х36",
                "koef": "0.2335",
                "metrs": 1
            },
            {
                "size": " 299х40",
                "koef": "0.2555",
                "metrs": 1
            },
            {
                "size": " 299х45",
                "koef": "0.2819",
                "metrs": 1
            },
            {
                "size": " 299х50",
                "koef": "0.307",
                "metrs": 1
            },
            {
                "size": " 325х6",
                "koef": "0.0472",
                "metrs": 1
            },
            {
                "size": " 325х7",
                "koef": "0.0549",
                "metrs": 1
            },
            {
                "size": " 325х8",
                "koef": "0.06254",
                "metrs": 1
            },
            {
                "size": " 325х9",
                "koef": "0.07014",
                "metrs": 1
            },
            {
                "size": " 325х10",
                "koef": "0.07768",
                "metrs": 1
            },
            {
                "size": " 325х11",
                "koef": "0.08518",
                "metrs": 1
            },
            {
                "size": " 325х12",
                "koef": "0.09263",
                "metrs": 1
            },
            {
                "size": " 325х13",
                "koef": "0.1",
                "metrs": 1
            },
            {
                "size": " 325х14",
                "koef": "0.1074",
                "metrs": 1
            },
            {
                "size": " 325х15",
                "koef": "0.1147",
                "metrs": 1
            },
            {
                "size": " 325х16",
                "koef": "0.1219",
                "metrs": 1
            },
            {
                "size": " 325х17",
                "koef": "0.1291",
                "metrs": 1
            },
            {
                "size": " 325х18",
                "koef": "0.1363",
                "metrs": 1
            },
            {
                "size": " 325х19",
                "koef": "0.1434",
                "metrs": 1
            },
            {
                "size": " 325х20",
                "koef": "0.1504",
                "metrs": 1
            },
            {
                "size": " 325х21",
                "koef": "0.1574",
                "metrs": 1
            },
            {
                "size": " 325х22",
                "koef": "0.1644",
                "metrs": 1
            },
            {
                "size": " 325х28",
                "koef": "0.2051",
                "metrs": 1
            },
            {
                "size": " 325х30",
                "koef": "0.2183",
                "metrs": 1
            },
            {
                "size": " 325х32",
                "koef": "0.2312",
                "metrs": 1
            },
            {
                "size": " 325х36",
                "koef": "0.2566",
                "metrs": 1
            },
            {
                "size": " 325х40",
                "koef": "0.2811",
                "metrs": 1
            },
            {
                "size": " 351х8",
                "koef": "0.06767",
                "metrs": 1
            },
            {
                "size": " 351х9",
                "koef": "0.07591",
                "metrs": 1
            },
            {
                "size": " 351х10",
                "koef": "0.0841",
                "metrs": 1
            },
            {
                "size": " 351х11",
                "koef": "0.09223",
                "metrs": 1
            },
            {
                "size": " 351х12",
                "koef": "0.1003",
                "metrs": 1
            },
            {
                "size": " 351х14",
                "koef": "0.1164",
                "metrs": 1
            },
            {
                "size": " 351х20",
                "koef": "0.1633",
                "metrs": 1
            },
            {
                "size": " 351х25",
                "koef": "0.201",
                "metrs": 1
            },
            {
                "size": " 351х30",
                "koef": "0.2375",
                "metrs": 1
            },
            {
                "size": " 351х32",
                "koef": "0.2517",
                "metrs": 1
            },
            {
                "size": " 351х36",
                "koef": "0.2797",
                "metrs": 1
            },
            {
                "size": " 351х40",
                "koef": "0.3068",
                "metrs": 1
            },
            {
                "size": " 351х45",
                "koef": "0.3396",
                "metrs": 1
            },
            {
                "size": " 351х50",
                "koef": "0.3712",
                "metrs": 1
            },
            {
                "size": " 377х5",
                "koef": "0.04587",
                "metrs": 1
            },
            {
                "size": " 377х6",
                "koef": "0.0549",
                "metrs": 1
            },
            {
                "size": " 377х7",
                "koef": "0.06387",
                "metrs": 1
            },
            {
                "size": " 377х8",
                "koef": "0.0728",
                "metrs": 1
            },
            {
                "size": " 377х9",
                "koef": "0.08168",
                "metrs": 1
            },
            {
                "size": " 377х10",
                "koef": "0.09051",
                "metrs": 1
            },
            {
                "size": " 377х11",
                "koef": "0.09929",
                "metrs": 1
            },
            {
                "size": " 377х12",
                "koef": "0.108",
                "metrs": 1
            },
            {
                "size": " 377х13",
                "koef": "0.1167",
                "metrs": 1
            },
            {
                "size": " 377х14",
                "koef": "0.1253",
                "metrs": 1
            },
            {
                "size": " 377х15",
                "koef": "0.1339",
                "metrs": 1
            },
            {
                "size": " 377х16",
                "koef": "0.1424",
                "metrs": 1
            },
            {
                "size": " 377х17",
                "koef": "0.1509",
                "metrs": 1
            },
            {
                "size": " 377х18",
                "koef": "0.1594",
                "metrs": 1
            },
            {
                "size": " 377х19",
                "koef": "0.1677",
                "metrs": 1
            },
            {
                "size": " 377х20",
                "koef": "0.1761",
                "metrs": 1
            },
            {
                "size": " 377х21",
                "koef": "0.1844",
                "metrs": 1
            },
            {
                "size": " 377х22",
                "koef": "0.1926",
                "metrs": 1
            },
            {
                "size": " 377х23",
                "koef": "0.2008",
                "metrs": 1
            },
            {
                "size": " 377х24",
                "koef": "0.2089",
                "metrs": 1
            },
            {
                "size": " 377х25",
                "koef": "0.217",
                "metrs": 1
            },
            {
                "size": " 377х36",
                "koef": "0.3027",
                "metrs": 1
            },
            {
                "size": " 377х40",
                "koef": "0.3324",
                "metrs": 1
            },
            {
                "size": " 377х45",
                "koef": "0.3684",
                "metrs": 1
            },
            {
                "size": " 377х50",
                "koef": "0.4032",
                "metrs": 1
            },
            {
                "size": " 402х20",
                "koef": "0.1884",
                "metrs": 1
            },
            {
                "size": " 402х25",
                "koef": "0.2324",
                "metrs": 1
            },
            {
                "size": " 402х40",
                "koef": "0.3571",
                "metrs": 1
            },
            {
                "size": " 402х45",
                "koef": "0.3962",
                "metrs": 1
            },
            {
                "size": " 402х50",
                "koef": "0.434",
                "metrs": 1
            },
            {
                "size": " 426х8",
                "koef": "0.08247",
                "metrs": 1
            },
            {
                "size": " 426х9",
                "koef": "0.09255",
                "metrs": 1
            },
            {
                "size": " 426х10",
                "koef": "0.1026",
                "metrs": 1
            },
            {
                "size": " 426х11",
                "koef": "0.1126",
                "metrs": 1
            },
            {
                "size": " 426х12",
                "koef": "0.1225",
                "metrs": 1
            },
            {
                "size": " 426х13",
                "koef": "0.1324",
                "metrs": 1
            },
            {
                "size": " 426х14",
                "koef": "0.1422",
                "metrs": 1
            },
            {
                "size": " 426х15",
                "koef": "0.152",
                "metrs": 1
            },
            {
                "size": " 426х16",
                "koef": "0.1618",
                "metrs": 1
            },
            {
                "size": " 426х17",
                "koef": "0.1715",
                "metrs": 1
            },
            {
                "size": " 426х18",
                "koef": "0.1811",
                "metrs": 1
            },
            {
                "size": " 426х19",
                "koef": "0.1907",
                "metrs": 1
            },
            {
                "size": " 426х20",
                "koef": "0.2003",
                "metrs": 1
            },
            {
                "size": " 426х21",
                "koef": "0.2097",
                "metrs": 1
            },
            {
                "size": " 426х22",
                "koef": "0.2192",
                "metrs": 1
            },
            {
                "size": " 426х23",
                "koef": "0.2286",
                "metrs": 1
            },
            {
                "size": " 426х24",
                "koef": "0.2379",
                "metrs": 1
            },
            {
                "size": " 426х25",
                "koef": "0.2472",
                "metrs": 1
            },
            {
                "size": " 426х30",
                "koef": "0.293",
                "metrs": 1
            },
            {
                "size": " 426х40",
                "koef": "0.3808",
                "metrs": 1
            },
            {
                "size": " 426х50",
                "koef": "0.4636",
                "metrs": 1
            },
            {
                "size": " 450х20",
                "koef": "0.2121",
                "metrs": 1
            },
            {
                "size": " 450х25",
                "koef": "0.262",
                "metrs": 1
            },
            {
                "size": " 450х30",
                "koef": "0.3107",
                "metrs": 1
            },
            {
                "size": " 450х40",
                "koef": "0.4044",
                "metrs": 1
            },
            {
                "size": " 450х50",
                "koef": "0.4932",
                "metrs": 1
            },
            {
                "size": " 480х25",
                "koef": "0.2805",
                "metrs": 1
            },
            {
                "size": " 480х30",
                "koef": "0.3329",
                "metrs": 1
            },
            {
                "size": " 530х8",
                "koef": "0.103",
                "metrs": 1
            },
            {
                "size": " 530х10",
                "koef": "0.1282",
                "metrs": 1
            },
            {
                "size": " 530х12",
                "koef": "0.1533",
                "metrs": 1
            },
            {
                "size": " 530х20",
                "koef": "0.2515",
                "metrs": 1
            },
            {
                "size": " 530х25",
                "koef": "0.3114",
                "metrs": 1
            },
            {
                "size": " 530х30",
                "koef": "0.3699",
                "metrs": 1
            },
            {
                "size": " 530х40",
                "koef": "0.4834",
                "metrs": 1
            },
            {
                "size": " 550х25",
                "koef": "0.3237",
                "metrs": 1
            },
            {
                "size": " 550х40",
                "koef": "0.5031",
                "metrs": 1
            },
            {
                "size": " 550х50",
                "koef": "0.6165",
                "metrs": 1
            }
        ],
        "Труба бесшовная горячедеформированная оцинкованная": [
            {
                "size": "38х3",
                "koef": "0.002667",
                "metrs": 1
            },
            {
                "size": "42х4",
                "koef": "0.003861",
                "metrs": 1
            },
            {
                "size": "45х3",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "45х4",
                "koef": "0.004166",
                "metrs": 1
            },
            {
                "size": "45х5",
                "koef": "0.00508",
                "metrs": 1
            },
            {
                "size": "51х3,5",
                "koef": "0.004223",
                "metrs": 1
            },
            {
                "size": "57х3,5",
                "koef": "0.004756",
                "metrs": 1
            },
            {
                "size": "57х4",
                "koef": "0.005385",
                "metrs": 1
            },
            {
                "size": "57х4,5",
                "koef": "0.006001",
                "metrs": 1
            },
            {
                "size": "57х6",
                "koef": "0.007773",
                "metrs": 1
            },
            {
                "size": "60х3,5",
                "koef": "0.005023",
                "metrs": 1
            },
            {
                "size": "60х4",
                "koef": "0.00569",
                "metrs": 1
            },
            {
                "size": "60х6",
                "koef": "0.00823",
                "metrs": 1
            },
            {
                "size": "68х5",
                "koef": "0.008001",
                "metrs": 1
            },
            {
                "size": "70х8",
                "koef": "0.0126",
                "metrs": 1
            },
            {
                "size": "76х4",
                "koef": "0.007316",
                "metrs": 1
            },
            {
                "size": "76х5",
                "koef": "0.009017",
                "metrs": 1
            },
            {
                "size": "76х6",
                "koef": "0.01067",
                "metrs": 1
            },
            {
                "size": "76х7",
                "koef": "0.01227",
                "metrs": 1
            },
            {
                "size": "89х3,5",
                "koef": "0.007601",
                "metrs": 1
            },
            {
                "size": "89х4",
                "koef": "0.008636",
                "metrs": 1
            },
            {
                "size": "89х4,5",
                "koef": "0.009659",
                "metrs": 1
            },
            {
                "size": "89х5",
                "koef": "0.01067",
                "metrs": 1
            },
            {
                "size": "89х6",
                "koef": "0.01265",
                "metrs": 1
            },
            {
                "size": "89х8",
                "koef": "0.01646",
                "metrs": 1
            },
            {
                "size": "108х4",
                "koef": "0.01057",
                "metrs": 1
            },
            {
                "size": "108х4,5",
                "koef": "0.01183",
                "metrs": 1
            },
            {
                "size": "108х5",
                "koef": "0.01308",
                "metrs": 1
            },
            {
                "size": "114х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "133х5",
                "koef": "0.01626",
                "metrs": 1
            },
            {
                "size": "159х5",
                "koef": "0.01956",
                "metrs": 1
            },
            {
                "size": "219х6",
                "koef": "0.03246",
                "metrs": 1
            },
            {
                "size": "273х12",
                "koef": "0.07956",
                "metrs": 1
            },
            {
                "size": "325х12",
                "koef": "0.09541",
                "metrs": 1
            }
        ],
        "Труба котельная бесшовная ": [
            {
                "size": " 14х2",
                "koef": "0.000589",
                "metrs": 1
            },
            {
                "size": " 16х2",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 16х2,5",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": " 16х3",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 16х3,5",
                "koef": "0.001075",
                "metrs": 1
            },
            {
                "size": " 16х4",
                "koef": "0.001185",
                "metrs": 1
            },
            {
                "size": " 18х2",
                "koef": "0.000793",
                "metrs": 1
            },
            {
                "size": " 25х2",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 25х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 25х3,5",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": " 25х4",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 28х3",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": " 28х4",
                "koef": "0.002371",
                "metrs": 1
            },
            {
                "size": " 28х4,5",
                "koef": "0.002606",
                "metrs": 1
            },
            {
                "size": " 28х5",
                "koef": "0.002834",
                "metrs": 1
            },
            {
                "size": " 28х6",
                "koef": "0.003258",
                "metrs": 1
            },
            {
                "size": " 32х2",
                "koef": "0.001476",
                "metrs": 1
            },
            {
                "size": " 32х3",
                "koef": "0.002143",
                "metrs": 1
            },
            {
                "size": " 32х3,5",
                "koef": "0.002457",
                "metrs": 1
            },
            {
                "size": " 32х4",
                "koef": "0.002763",
                "metrs": 1
            },
            {
                "size": " 32х4,5",
                "koef": "0.003054",
                "metrs": 1
            },
            {
                "size": " 32х5",
                "koef": "0.003328",
                "metrs": 1
            },
            {
                "size": " 32х6",
                "koef": "0.003846",
                "metrs": 1
            },
            {
                "size": " 36х6",
                "koef": "0.004435",
                "metrs": 1
            },
            {
                "size": " 36х8",
                "koef": "0.005526",
                "metrs": 1
            },
            {
                "size": " 38х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": " 38х3",
                "koef": "0.002591",
                "metrs": 1
            },
            {
                "size": " 38х3,5",
                "koef": "0.002975",
                "metrs": 1
            },
            {
                "size": " 38х4",
                "koef": "0.003352",
                "metrs": 1
            },
            {
                "size": " 38х4,5",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": " 38х5",
                "koef": "0.004066",
                "metrs": 1
            },
            {
                "size": " 38х6",
                "koef": "0.004734",
                "metrs": 1
            },
            {
                "size": " 42х3,5",
                "koef": "0.003321",
                "metrs": 1
            },
            {
                "size": " 42х4",
                "koef": "0.003752",
                "metrs": 1
            },
            {
                "size": " 42х4,5",
                "koef": "0.004161",
                "metrs": 1
            },
            {
                "size": " 42х5",
                "koef": "0.004561",
                "metrs": 1
            },
            {
                "size": " 42х6",
                "koef": "0.00533",
                "metrs": 1
            },
            {
                "size": " 42х7",
                "koef": "0.006045",
                "metrs": 1
            },
            {
                "size": " 42х10",
                "koef": "0.007889",
                "metrs": 1
            },
            {
                "size": " 45х2,5",
                "koef": "0.002622",
                "metrs": 1
            },
            {
                "size": " 48х6",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 57х3",
                "koef": "0.003996",
                "metrs": 1
            },
            {
                "size": " 57х3,5",
                "koef": "0.004616",
                "metrs": 1
            },
            {
                "size": " 57х4",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": " 57х4,5",
                "koef": "0.005825",
                "metrs": 1
            },
            {
                "size": " 57х5",
                "koef": "0.006413",
                "metrs": 1
            },
            {
                "size": " 57х6",
                "koef": "0.007544",
                "metrs": 1
            },
            {
                "size": " 57х9",
                "koef": "0.01065",
                "metrs": 1
            },
            {
                "size": " 57х12",
                "koef": "0.01331",
                "metrs": 1
            },
            {
                "size": " 60х3,5",
                "koef": "0.004875",
                "metrs": 1
            },
            {
                "size": " 60х4",
                "koef": "0.005526",
                "metrs": 1
            },
            {
                "size": " 60х5",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": " 60х5,5",
                "koef": "0.007395",
                "metrs": 1
            },
            {
                "size": " 60х6",
                "koef": "0.007991",
                "metrs": 1
            },
            {
                "size": " 76х3",
                "koef": "0.005401",
                "metrs": 1
            },
            {
                "size": " 76х4",
                "koef": "0.007104",
                "metrs": 1
            },
            {
                "size": " 76х6",
                "koef": "0.01035",
                "metrs": 1
            },
            {
                "size": " 76х9",
                "koef": "0.01487",
                "metrs": 1
            },
            {
                "size": " 76х10",
                "koef": "0.01627",
                "metrs": 1
            },
            {
                "size": " 76х11",
                "koef": "0.01763",
                "metrs": 1
            },
            {
                "size": " 76х13",
                "koef": "0.0202",
                "metrs": 1
            },
            {
                "size": " 83х3,5",
                "koef": "0.006861",
                "metrs": 1
            },
            {
                "size": " 83х4",
                "koef": "0.007795",
                "metrs": 1
            },
            {
                "size": " 89х3,5",
                "koef": "0.007379",
                "metrs": 1
            },
            {
                "size": " 89х4",
                "koef": "0.008384",
                "metrs": 1
            },
            {
                "size": " 89х5",
                "koef": "0.01035",
                "metrs": 1
            },
            {
                "size": " 89х6",
                "koef": "0.01229",
                "metrs": 1
            },
            {
                "size": " 89х8",
                "koef": "0.01598",
                "metrs": 1
            },
            {
                "size": " 108х4",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": " 108х4,5",
                "koef": "0.01148",
                "metrs": 1
            },
            {
                "size": " 108х5",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": " 108х6",
                "koef": "0.0151",
                "metrs": 1
            },
            {
                "size": " 108х7",
                "koef": "0.01743",
                "metrs": 1
            },
            {
                "size": " 108х8",
                "koef": "0.01973",
                "metrs": 1
            },
            {
                "size": " 108х10",
                "koef": "0.02417",
                "metrs": 1
            },
            {
                "size": " 114х12",
                "koef": "0.03018",
                "metrs": 1
            },
            {
                "size": " 133х4",
                "koef": "0.01272",
                "metrs": 1
            },
            {
                "size": " 133х5",
                "koef": "0.01579",
                "metrs": 1
            },
            {
                "size": " 133х10",
                "koef": "0.03033",
                "metrs": 1
            },
            {
                "size": " 133х11",
                "koef": "0.0331",
                "metrs": 1
            },
            {
                "size": " 133х13",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": " 133х17",
                "koef": "0.04863",
                "metrs": 1
            },
            {
                "size": " 133х18",
                "koef": "0.05105",
                "metrs": 1
            },
            {
                "size": " 133х20",
                "koef": "0.05573",
                "metrs": 1
            },
            {
                "size": " 133х25",
                "koef": "0.06658",
                "metrs": 1
            },
            {
                "size": " 133х28",
                "koef": "0.0725",
                "metrs": 1
            },
            {
                "size": " 140х36",
                "koef": "0.09233",
                "metrs": 1
            },
            {
                "size": " 159х5",
                "koef": "0.01899",
                "metrs": 1
            },
            {
                "size": " 159х6",
                "koef": "0.02264",
                "metrs": 1
            },
            {
                "size": " 159х7",
                "koef": "0.02624",
                "metrs": 1
            },
            {
                "size": " 159х9",
                "koef": "0.03329",
                "metrs": 1
            },
            {
                "size": " 159х15",
                "koef": "0.05327",
                "metrs": 1
            },
            {
                "size": " 159х18",
                "koef": "0.06259",
                "metrs": 1
            },
            {
                "size": " 159х20",
                "koef": "0.06856",
                "metrs": 1
            },
            {
                "size": " 168х22",
                "koef": "0.07921",
                "metrs": 1
            },
            {
                "size": " 219х7",
                "koef": "0.0366",
                "metrs": 1
            },
            {
                "size": " 219х8",
                "koef": "0.04163",
                "metrs": 1
            },
            {
                "size": " 219х16",
                "koef": "0.0801",
                "metrs": 1
            },
            {
                "size": " 219х18",
                "koef": "0.08922",
                "metrs": 1
            },
            {
                "size": " 219х19",
                "koef": "0.09371",
                "metrs": 1
            },
            {
                "size": " 219х20",
                "koef": "0.09816",
                "metrs": 1
            },
            {
                "size": " 219х22",
                "koef": "0.1069",
                "metrs": 1
            },
            {
                "size": " 219х25",
                "koef": "0.1196",
                "metrs": 1
            },
            {
                "size": " 219х26",
                "koef": "0.1237",
                "metrs": 1
            },
            {
                "size": " 219х32",
                "koef": "0.1476",
                "metrs": 1
            },
            {
                "size": " 219х36",
                "koef": "0.1625",
                "metrs": 1
            },
            {
                "size": " 219х40",
                "koef": "0.1766",
                "metrs": 1
            },
            {
                "size": " 245х15",
                "koef": "0.08508",
                "metrs": 1
            },
            {
                "size": " 245х20",
                "koef": "0.111",
                "metrs": 1
            },
            {
                "size": " 245х28",
                "koef": "0.1498",
                "metrs": 1
            },
            {
                "size": " 245х45",
                "koef": "0.222",
                "metrs": 1
            },
            {
                "size": " 245х48",
                "koef": "0.2332",
                "metrs": 1
            },
            {
                "size": " 273х8",
                "koef": "0.05228",
                "metrs": 1
            },
            {
                "size": " 273х10",
                "koef": "0.06486",
                "metrs": 1
            },
            {
                "size": " 273х13",
                "koef": "0.08336",
                "metrs": 1
            },
            {
                "size": " 273х16",
                "koef": "0.1014",
                "metrs": 1
            },
            {
                "size": " 273х20",
                "koef": "0.1248",
                "metrs": 1
            },
            {
                "size": " 273х24",
                "koef": "0.1474",
                "metrs": 1
            },
            {
                "size": " 273х32",
                "koef": "0.1902",
                "metrs": 1
            },
            {
                "size": " 273х36",
                "koef": "0.2104",
                "metrs": 1
            },
            {
                "size": " 273х40",
                "koef": "0.2298",
                "metrs": 1
            },
            {
                "size": " 273х45",
                "koef": "0.253",
                "metrs": 1
            },
            {
                "size": " 273х50",
                "koef": "0.275",
                "metrs": 1
            },
            {
                "size": " 325х8",
                "koef": "0.06254",
                "metrs": 1
            },
            {
                "size": " 325х13",
                "koef": "0.1",
                "metrs": 1
            },
            {
                "size": " 325х26",
                "koef": "0.1917",
                "metrs": 1
            },
            {
                "size": " 325х28",
                "koef": "0.2051",
                "metrs": 1
            },
            {
                "size": " 325х32",
                "koef": "0.2312",
                "metrs": 1
            },
            {
                "size": " 325х42",
                "koef": "0.2931",
                "metrs": 1
            },
            {
                "size": " 325х45",
                "koef": "0.3107",
                "metrs": 1
            },
            {
                "size": " 325х50",
                "koef": "0.3391",
                "metrs": 1
            },
            {
                "size": " 325х60",
                "koef": "0.3921",
                "metrs": 1
            },
            {
                "size": " 377х9",
                "koef": "0.08168",
                "metrs": 1
            },
            {
                "size": " 377х13",
                "koef": "0.1167",
                "metrs": 1
            },
            {
                "size": " 377х17",
                "koef": "0.1509",
                "metrs": 1
            },
            {
                "size": " 377х36",
                "koef": "0.3027",
                "metrs": 1
            },
            {
                "size": " 377х50",
                "koef": "0.4032",
                "metrs": 1
            },
            {
                "size": " 377х60",
                "koef": "0.4691",
                "metrs": 1
            },
            {
                "size": " 426х9",
                "koef": "0.09255",
                "metrs": 1
            },
            {
                "size": " 426х14",
                "koef": "0.1422",
                "metrs": 1
            },
            {
                "size": " 426х22",
                "koef": "0.2192",
                "metrs": 1
            },
            {
                "size": " 426х24",
                "koef": "0.2379",
                "metrs": 1
            },
            {
                "size": " 426х36",
                "koef": "0.3462",
                "metrs": 1
            },
            {
                "size": " 465х19",
                "koef": "0.209",
                "metrs": 1
            },
            {
                "size": " 530х20",
                "koef": "0.2515",
                "metrs": 1
            },
            {
                "size": " 530х25",
                "koef": "0.3114",
                "metrs": 1
            },
            {
                "size": " 530х40",
                "koef": "0.4834",
                "metrs": 1
            }
        ],
        "Труба бесшовная холоднодеформированная ": [
            {
                "size": "2,5х0,3",
                "koef": "0.000016",
                "metrs": 1
            },
            {
                "size": "2,5х0,4",
                "koef": "0.000021",
                "metrs": 1
            },
            {
                "size": "2,5х0,5",
                "koef": "0.000025",
                "metrs": 1
            },
            {
                "size": "4х0,2",
                "koef": "0.000019",
                "metrs": 1
            },
            {
                "size": "4х0,3",
                "koef": "0.000027",
                "metrs": 1
            },
            {
                "size": "4х0,4",
                "koef": "0.000036",
                "metrs": 1
            },
            {
                "size": "4х0,5",
                "koef": "0.000043",
                "metrs": 1
            },
            {
                "size": "4х0,6",
                "koef": "0.00005",
                "metrs": 1
            },
            {
                "size": "4х0,8",
                "koef": "0.000063",
                "metrs": 1
            },
            {
                "size": "4х1",
                "koef": "0.000074",
                "metrs": 1
            },
            {
                "size": "5х0,2",
                "koef": "0.000024",
                "metrs": 1
            },
            {
                "size": "5х0,3",
                "koef": "0.000035",
                "metrs": 1
            },
            {
                "size": "5х0,4",
                "koef": "0.000045",
                "metrs": 1
            },
            {
                "size": "5х0,5",
                "koef": "0.000055",
                "metrs": 1
            },
            {
                "size": "5х0,6",
                "koef": "0.000065",
                "metrs": 1
            },
            {
                "size": "5х0,8",
                "koef": "0.000083",
                "metrs": 1
            },
            {
                "size": "5х1",
                "koef": "0.000099",
                "metrs": 1
            },
            {
                "size": "5х1,2",
                "koef": "0.000112",
                "metrs": 1
            },
            {
                "size": "5х1,4",
                "koef": "0.000124",
                "metrs": 1
            },
            {
                "size": "5х1,5",
                "koef": "0.000129",
                "metrs": 1
            },
            {
                "size": "5х1,6",
                "koef": "0.000134",
                "metrs": 1
            },
            {
                "size": "5х1,8",
                "koef": "0.000142",
                "metrs": 1
            },
            {
                "size": "6х0,2",
                "koef": "0.000029",
                "metrs": 1
            },
            {
                "size": "6х0,3",
                "koef": "0.000042",
                "metrs": 1
            },
            {
                "size": "6х0,4",
                "koef": "0.000055",
                "metrs": 1
            },
            {
                "size": "6х0,5",
                "koef": "0.000068",
                "metrs": 1
            },
            {
                "size": "6х0,6",
                "koef": "0.00008",
                "metrs": 1
            },
            {
                "size": "6х0,8",
                "koef": "0.000103",
                "metrs": 1
            },
            {
                "size": "6х1",
                "koef": "0.000123",
                "metrs": 1
            },
            {
                "size": "6х1,2",
                "koef": "0.000142",
                "metrs": 1
            },
            {
                "size": "6х1,4",
                "koef": "0.000159",
                "metrs": 1
            },
            {
                "size": "6х1,5",
                "koef": "0.000166",
                "metrs": 1
            },
            {
                "size": "6х1,6",
                "koef": "0.000174",
                "metrs": 1
            },
            {
                "size": "6х1,8",
                "koef": "0.000186",
                "metrs": 1
            },
            {
                "size": "6х2",
                "koef": "0.000197",
                "metrs": 1
            },
            {
                "size": "7х0,2",
                "koef": "0.000034",
                "metrs": 1
            },
            {
                "size": "7х0,3",
                "koef": "0.00005",
                "metrs": 1
            },
            {
                "size": "7х0,4",
                "koef": "0.000065",
                "metrs": 1
            },
            {
                "size": "7х0,5",
                "koef": "0.00008",
                "metrs": 1
            },
            {
                "size": "7х0,6",
                "koef": "0.000095",
                "metrs": 1
            },
            {
                "size": "7х0,8",
                "koef": "0.000122",
                "metrs": 1
            },
            {
                "size": "7х1",
                "koef": "0.000148",
                "metrs": 1
            },
            {
                "size": "7х1,2",
                "koef": "0.000172",
                "metrs": 1
            },
            {
                "size": "7х1,4",
                "koef": "0.000193",
                "metrs": 1
            },
            {
                "size": "7х1,5",
                "koef": "0.000203",
                "metrs": 1
            },
            {
                "size": "7х1,6",
                "koef": "0.000213",
                "metrs": 1
            },
            {
                "size": "7х1,8",
                "koef": "0.000231",
                "metrs": 1
            },
            {
                "size": "7х2",
                "koef": "0.000247",
                "metrs": 1
            },
            {
                "size": "7х2,2",
                "koef": "0.00026",
                "metrs": 1
            },
            {
                "size": "7х2,5",
                "koef": "0.000277",
                "metrs": 1
            },
            {
                "size": "8х0,2",
                "koef": "0.000038",
                "metrs": 1
            },
            {
                "size": "8х0,3",
                "koef": "0.000057",
                "metrs": 1
            },
            {
                "size": "8х0,4",
                "koef": "0.000075",
                "metrs": 1
            },
            {
                "size": "8х0,5",
                "koef": "0.000092",
                "metrs": 1
            },
            {
                "size": "8х0,6",
                "koef": "0.000109",
                "metrs": 1
            },
            {
                "size": "8х0,8",
                "koef": "0.000142",
                "metrs": 1
            },
            {
                "size": "8х1",
                "koef": "0.000173",
                "metrs": 1
            },
            {
                "size": "8х1,2",
                "koef": "0.000201",
                "metrs": 1
            },
            {
                "size": "8х1,4",
                "koef": "0.000228",
                "metrs": 1
            },
            {
                "size": "8х1,5",
                "koef": "0.00024",
                "metrs": 1
            },
            {
                "size": "8х1,6",
                "koef": "0.000253",
                "metrs": 1
            },
            {
                "size": "8х1,8",
                "koef": "0.000275",
                "metrs": 1
            },
            {
                "size": "8х2",
                "koef": "0.000296",
                "metrs": 1
            },
            {
                "size": "8х2,2",
                "koef": "0.000315",
                "metrs": 1
            },
            {
                "size": "8х2,5",
                "koef": "0.000339",
                "metrs": 1
            },
            {
                "size": "9х0,2",
                "koef": "0.000043",
                "metrs": 1
            },
            {
                "size": "9х0,3",
                "koef": "0.000064",
                "metrs": 1
            },
            {
                "size": "9х0,4",
                "koef": "0.000085",
                "metrs": 1
            },
            {
                "size": "9х0,5",
                "koef": "0.000105",
                "metrs": 1
            },
            {
                "size": "9х0,6",
                "koef": "0.000124",
                "metrs": 1
            },
            {
                "size": "9х0,8",
                "koef": "0.000162",
                "metrs": 1
            },
            {
                "size": "9х1",
                "koef": "0.000197",
                "metrs": 1
            },
            {
                "size": "9х1,2",
                "koef": "0.000231",
                "metrs": 1
            },
            {
                "size": "9х1,4",
                "koef": "0.000262",
                "metrs": 1
            },
            {
                "size": "9х1,5",
                "koef": "0.000277",
                "metrs": 1
            },
            {
                "size": "9х1,6",
                "koef": "0.000292",
                "metrs": 1
            },
            {
                "size": "9х1,8",
                "koef": "0.00032",
                "metrs": 1
            },
            {
                "size": "9х2",
                "koef": "0.000345",
                "metrs": 1
            },
            {
                "size": "9х2,2",
                "koef": "0.000369",
                "metrs": 1
            },
            {
                "size": "9х2,5",
                "koef": "0.000401",
                "metrs": 1
            },
            {
                "size": "9х2,8",
                "koef": "0.000428",
                "metrs": 1
            },
            {
                "size": "9х3",
                "koef": "0.000444",
                "metrs": 1
            },
            {
                "size": "9х3,2",
                "koef": "0.000458",
                "metrs": 1
            },
            {
                "size": "9х3,5",
                "koef": "0.000475",
                "metrs": 1
            },
            {
                "size": "9х4",
                "koef": "0.000493",
                "metrs": 1
            },
            {
                "size": "10х0,2",
                "koef": "0.000048",
                "metrs": 1
            },
            {
                "size": "10х0,3",
                "koef": "0.000072",
                "metrs": 1
            },
            {
                "size": "10х0,4",
                "koef": "0.000095",
                "metrs": 1
            },
            {
                "size": "10х0,5",
                "koef": "0.000117",
                "metrs": 1
            },
            {
                "size": "10х0,6",
                "koef": "0.000139",
                "metrs": 1
            },
            {
                "size": "10х0,8",
                "koef": "0.000182",
                "metrs": 1
            },
            {
                "size": "10х1",
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": "10х1,2",
                "koef": "0.00026",
                "metrs": 1
            },
            {
                "size": "10х1,4",
                "koef": "0.000297",
                "metrs": 1
            },
            {
                "size": "10х1,5",
                "koef": "0.000314",
                "metrs": 1
            },
            {
                "size": "10х1,6",
                "koef": "0.000331",
                "metrs": 1
            },
            {
                "size": "10х1,8",
                "koef": "0.000364",
                "metrs": 1
            },
            {
                "size": "10х2",
                "koef": "0.000395",
                "metrs": 1
            },
            {
                "size": "10х2,2",
                "koef": "0.000423",
                "metrs": 1
            },
            {
                "size": "10х2,5",
                "koef": "0.000462",
                "metrs": 1
            },
            {
                "size": "10х2,8",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": "10х3",
                "koef": "0.000518",
                "metrs": 1
            },
            {
                "size": "10х3,2",
                "koef": "0.000537",
                "metrs": 1
            },
            {
                "size": "10х3,5",
                "koef": "0.000561",
                "metrs": 1
            },
            {
                "size": "10х4",
                "koef": "0.000592",
                "metrs": 1
            },
            {
                "size": "11х0,2",
                "koef": "0.000053",
                "metrs": 1
            },
            {
                "size": "11х0,3",
                "koef": "0.000079",
                "metrs": 1
            },
            {
                "size": "11х0,4",
                "koef": "0.000105",
                "metrs": 1
            },
            {
                "size": "11х0,5",
                "koef": "0.000129",
                "metrs": 1
            },
            {
                "size": "11х0,6",
                "koef": "0.000154",
                "metrs": 1
            },
            {
                "size": "11х0,8",
                "koef": "0.000201",
                "metrs": 1
            },
            {
                "size": "11х1",
                "koef": "0.000247",
                "metrs": 1
            },
            {
                "size": "11х1,2",
                "koef": "0.00029",
                "metrs": 1
            },
            {
                "size": "11х1,4",
                "koef": "0.000331",
                "metrs": 1
            },
            {
                "size": "11х1,5",
                "koef": "0.000351",
                "metrs": 1
            },
            {
                "size": "11х1,6",
                "koef": "0.000371",
                "metrs": 1
            },
            {
                "size": "11х1,8",
                "koef": "0.000408",
                "metrs": 1
            },
            {
                "size": "11х2",
                "koef": "0.000444",
                "metrs": 1
            },
            {
                "size": "11х2,2",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": "11х2,5",
                "koef": "0.000524",
                "metrs": 1
            },
            {
                "size": "11х2,8",
                "koef": "0.000566",
                "metrs": 1
            },
            {
                "size": "11х3",
                "koef": "0.000592",
                "metrs": 1
            },
            {
                "size": "11х3,2",
                "koef": "0.000616",
                "metrs": 1
            },
            {
                "size": "11х3,5",
                "koef": "0.000647",
                "metrs": 1
            },
            {
                "size": "11х4",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": "12х0,2",
                "koef": "0.000058",
                "metrs": 1
            },
            {
                "size": "12х0,3",
                "koef": "0.000087",
                "metrs": 1
            },
            {
                "size": "12х0,4",
                "koef": "0.000114",
                "metrs": 1
            },
            {
                "size": "12х0,5",
                "koef": "0.000142",
                "metrs": 1
            },
            {
                "size": "12х0,6",
                "koef": "0.000169",
                "metrs": 1
            },
            {
                "size": "12х0,8",
                "koef": "0.000221",
                "metrs": 1
            },
            {
                "size": "12х1",
                "koef": "0.000271",
                "metrs": 1
            },
            {
                "size": "12х1,2",
                "koef": "0.00032",
                "metrs": 1
            },
            {
                "size": "12х1,4",
                "koef": "0.000366",
                "metrs": 1
            },
            {
                "size": "12х1,5",
                "koef": "0.000388",
                "metrs": 1
            },
            {
                "size": "12х1,6",
                "koef": "0.00041",
                "metrs": 1
            },
            {
                "size": "12х1,8",
                "koef": "0.000453",
                "metrs": 1
            },
            {
                "size": "12х2",
                "koef": "0.000493",
                "metrs": 1
            },
            {
                "size": "12х2,2",
                "koef": "0.000532",
                "metrs": 1
            },
            {
                "size": "12х2,5",
                "koef": "0.000586",
                "metrs": 1
            },
            {
                "size": "12х2,8",
                "koef": "0.000635",
                "metrs": 1
            },
            {
                "size": "12х3",
                "koef": "0.000666",
                "metrs": 1
            },
            {
                "size": "12х3,2",
                "koef": "0.000694",
                "metrs": 1
            },
            {
                "size": "12х3,5",
                "koef": "0.000734",
                "metrs": 1
            },
            {
                "size": "13х0,2",
                "koef": "0.000063",
                "metrs": 1
            },
            {
                "size": "13х0,3",
                "koef": "0.000094",
                "metrs": 1
            },
            {
                "size": "13х0,4",
                "koef": "0.000124",
                "metrs": 1
            },
            {
                "size": "13х0,5",
                "koef": "0.000154",
                "metrs": 1
            },
            {
                "size": "13х0,6",
                "koef": "0.000183",
                "metrs": 1
            },
            {
                "size": "13х0,8",
                "koef": "0.000241",
                "metrs": 1
            },
            {
                "size": "13х1",
                "koef": "0.000296",
                "metrs": 1
            },
            {
                "size": "13х1,2",
                "koef": "0.000349",
                "metrs": 1
            },
            {
                "size": "13х1,4",
                "koef": "0.000401",
                "metrs": 1
            },
            {
                "size": "13х1,5",
                "koef": "0.000425",
                "metrs": 1
            },
            {
                "size": "13х1,6",
                "koef": "0.00045",
                "metrs": 1
            },
            {
                "size": "13х1,8",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": "13х2",
                "koef": "0.000543",
                "metrs": 1
            },
            {
                "size": "13х2,2",
                "koef": "0.000586",
                "metrs": 1
            },
            {
                "size": "13х2,5",
                "koef": "0.000647",
                "metrs": 1
            },
            {
                "size": "13х2,8",
                "koef": "0.000704",
                "metrs": 1
            },
            {
                "size": "13х3",
                "koef": "0.00074",
                "metrs": 1
            },
            {
                "size": "13х3,2",
                "koef": "0.000773",
                "metrs": 1
            },
            {
                "size": "13х3,5",
                "koef": "0.00082",
                "metrs": 1
            },
            {
                "size": "13х4",
                "koef": "0.000888",
                "metrs": 1
            },
            {
                "size": "13х4,5",
                "koef": "0.000943",
                "metrs": 1
            },
            {
                "size": "13х5",
                "koef": "0.000986",
                "metrs": 1
            },
            {
                "size": "14х0,2",
                "koef": "0.000068",
                "metrs": 1
            },
            {
                "size": "14х0,3",
                "koef": "0.000101",
                "metrs": 1
            },
            {
                "size": "14х0,4",
                "koef": "0.000134",
                "metrs": 1
            },
            {
                "size": "14х0,5",
                "koef": "0.000166",
                "metrs": 1
            },
            {
                "size": "14х0,6",
                "koef": "0.000198",
                "metrs": 1
            },
            {
                "size": "14х0,8",
                "koef": "0.00026",
                "metrs": 1
            },
            {
                "size": "14х1",
                "koef": "0.000321",
                "metrs": 1
            },
            {
                "size": "14х1,2",
                "koef": "0.000379",
                "metrs": 1
            },
            {
                "size": "14х1,4",
                "koef": "0.000435",
                "metrs": 1
            },
            {
                "size": "14х1,5",
                "koef": "0.000462",
                "metrs": 1
            },
            {
                "size": "14х1,6",
                "koef": "0.000489",
                "metrs": 1
            },
            {
                "size": "14х1,8",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "14х2",
                "koef": "0.000592",
                "metrs": 1
            },
            {
                "size": "14х2,2",
                "koef": "0.00064",
                "metrs": 1
            },
            {
                "size": "14х2,5",
                "koef": "0.000709",
                "metrs": 1
            },
            {
                "size": "14х2,8",
                "koef": "0.000773",
                "metrs": 1
            },
            {
                "size": "14х3",
                "koef": "0.000814",
                "metrs": 1
            },
            {
                "size": "14х3,2",
                "koef": "0.000852",
                "metrs": 1
            },
            {
                "size": "14х3,5",
                "koef": "0.000906",
                "metrs": 1
            },
            {
                "size": "14х4",
                "koef": "0.000986",
                "metrs": 1
            },
            {
                "size": "14х4,5",
                "koef": "0.001054",
                "metrs": 1
            },
            {
                "size": "14х5",
                "koef": "0.00111",
                "metrs": 1
            },
            {
                "size": "15х0,2",
                "koef": "0.000073",
                "metrs": 1
            },
            {
                "size": "15х0,3",
                "koef": "0.000109",
                "metrs": 1
            },
            {
                "size": "15х0,4",
                "koef": "0.000144",
                "metrs": 1
            },
            {
                "size": "15х0,5",
                "koef": "0.000179",
                "metrs": 1
            },
            {
                "size": "15х0,6",
                "koef": "0.000213",
                "metrs": 1
            },
            {
                "size": "15х0,8",
                "koef": "0.00028",
                "metrs": 1
            },
            {
                "size": "15х1",
                "koef": "0.000345",
                "metrs": 1
            },
            {
                "size": "15х1,2",
                "koef": "0.000408",
                "metrs": 1
            },
            {
                "size": "15х1,4",
                "koef": "0.00047",
                "metrs": 1
            },
            {
                "size": "15х1,5",
                "koef": "0.000499",
                "metrs": 1
            },
            {
                "size": "15х1,6",
                "koef": "0.000529",
                "metrs": 1
            },
            {
                "size": "15х1,8",
                "koef": "0.000586",
                "metrs": 1
            },
            {
                "size": "15х2",
                "koef": "0.000641",
                "metrs": 1
            },
            {
                "size": "15х2,2",
                "koef": "0.000694",
                "metrs": 1
            },
            {
                "size": "15х2,5",
                "koef": "0.000771",
                "metrs": 1
            },
            {
                "size": "15х2,8",
                "koef": "0.000842",
                "metrs": 1
            },
            {
                "size": "15х3",
                "koef": "0.000888",
                "metrs": 1
            },
            {
                "size": "15х3,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "15х3,5",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": "15х4",
                "koef": "0.001085",
                "metrs": 1
            },
            {
                "size": "15х4,5",
                "koef": "0.001165",
                "metrs": 1
            },
            {
                "size": "15х5",
                "koef": "0.001233",
                "metrs": 1
            },
            {
                "size": "16х0,2",
                "koef": "0.000078",
                "metrs": 1
            },
            {
                "size": "16х0,3",
                "koef": "0.000116",
                "metrs": 1
            },
            {
                "size": "16х0,4",
                "koef": "0.000154",
                "metrs": 1
            },
            {
                "size": "16х0,5",
                "koef": "0.000191",
                "metrs": 1
            },
            {
                "size": "16х0,6",
                "koef": "0.000228",
                "metrs": 1
            },
            {
                "size": "16х0,8",
                "koef": "0.0003",
                "metrs": 1
            },
            {
                "size": "16х1",
                "koef": "0.00037",
                "metrs": 1
            },
            {
                "size": "16х1,2",
                "koef": "0.000438",
                "metrs": 1
            },
            {
                "size": "16х1,4",
                "koef": "0.000504",
                "metrs": 1
            },
            {
                "size": "16х1,5",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": "16х1,6",
                "koef": "0.000568",
                "metrs": 1
            },
            {
                "size": "16х1,8",
                "koef": "0.00063",
                "metrs": 1
            },
            {
                "size": "16х2",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": "16х2,2",
                "koef": "0.000749",
                "metrs": 1
            },
            {
                "size": "16х2,5",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": "16х2,8",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "16х3",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": "16х3,2",
                "koef": "0.00101",
                "metrs": 1
            },
            {
                "size": "16х3,5",
                "koef": "0.001079",
                "metrs": 1
            },
            {
                "size": "16х4",
                "koef": "0.001184",
                "metrs": 1
            },
            {
                "size": "16х4,5",
                "koef": "0.001276",
                "metrs": 1
            },
            {
                "size": "16х5",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": "16х5,5",
                "koef": "0.001424",
                "metrs": 1
            },
            {
                "size": "16х6",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": "17х0,2",
                "koef": "0.000083",
                "metrs": 1
            },
            {
                "size": "17х0,3",
                "koef": "0.000124",
                "metrs": 1
            },
            {
                "size": "17х0,4",
                "koef": "0.000164",
                "metrs": 1
            },
            {
                "size": "17х0,5",
                "koef": "0.000203",
                "metrs": 1
            },
            {
                "size": "17х0,6",
                "koef": "0.000243",
                "metrs": 1
            },
            {
                "size": "17х0,8",
                "koef": "0.00032",
                "metrs": 1
            },
            {
                "size": "17х1",
                "koef": "0.000395",
                "metrs": 1
            },
            {
                "size": "17х1,2",
                "koef": "0.000468",
                "metrs": 1
            },
            {
                "size": "17х1,4",
                "koef": "0.000539",
                "metrs": 1
            },
            {
                "size": "17х1,5",
                "koef": "0.000573",
                "metrs": 1
            },
            {
                "size": "17х1,6",
                "koef": "0.000608",
                "metrs": 1
            },
            {
                "size": "17х1,8",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": "17х2",
                "koef": "0.00074",
                "metrs": 1
            },
            {
                "size": "17х2,2",
                "koef": "0.000803",
                "metrs": 1
            },
            {
                "size": "17х2,5",
                "koef": "0.000894",
                "metrs": 1
            },
            {
                "size": "17х2,8",
                "koef": "0.000981",
                "metrs": 1
            },
            {
                "size": "17х3",
                "koef": "0.001036",
                "metrs": 1
            },
            {
                "size": "17х3,2",
                "koef": "0.001089",
                "metrs": 1
            },
            {
                "size": "17х3,5",
                "koef": "0.001165",
                "metrs": 1
            },
            {
                "size": "17х4",
                "koef": "0.001282",
                "metrs": 1
            },
            {
                "size": "17х4,5",
                "koef": "0.001387",
                "metrs": 1
            },
            {
                "size": "17х5",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": "17х5,5",
                "koef": "0.00156",
                "metrs": 1
            },
            {
                "size": "17х6",
                "koef": "0.001628",
                "metrs": 1
            },
            {
                "size": "18х0,2",
                "koef": "0.000088",
                "metrs": 1
            },
            {
                "size": "18х0,3",
                "koef": "0.000131",
                "metrs": 1
            },
            {
                "size": "18х0,4",
                "koef": "0.000174",
                "metrs": 1
            },
            {
                "size": "18х0,5",
                "koef": "0.000216",
                "metrs": 1
            },
            {
                "size": "18х0,6",
                "koef": "0.000257",
                "metrs": 1
            },
            {
                "size": "18х0,8",
                "koef": "0.000339",
                "metrs": 1
            },
            {
                "size": "18х1",
                "koef": "0.000419",
                "metrs": 1
            },
            {
                "size": "18х1,2",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": "18х1,4",
                "koef": "0.000573",
                "metrs": 1
            },
            {
                "size": "18х1,5",
                "koef": "0.00061",
                "metrs": 1
            },
            {
                "size": "18х1,6",
                "koef": "0.000647",
                "metrs": 1
            },
            {
                "size": "18х1,8",
                "koef": "0.000719",
                "metrs": 1
            },
            {
                "size": "18х2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "18х2,2",
                "koef": "0.000857",
                "metrs": 1
            },
            {
                "size": "18х2,5",
                "koef": "0.000956",
                "metrs": 1
            },
            {
                "size": "18х2,8",
                "koef": "0.00105",
                "metrs": 1
            },
            {
                "size": "18х3",
                "koef": "0.00111",
                "metrs": 1
            },
            {
                "size": "18х3,2",
                "koef": "0.001168",
                "metrs": 1
            },
            {
                "size": "18х3,5",
                "koef": "0.001252",
                "metrs": 1
            },
            {
                "size": "18х4",
                "koef": "0.001381",
                "metrs": 1
            },
            {
                "size": "18х4,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": "18х5",
                "koef": "0.001603",
                "metrs": 1
            },
            {
                "size": "18х5,5",
                "koef": "0.001695",
                "metrs": 1
            },
            {
                "size": "18х6",
                "koef": "0.001776",
                "metrs": 1
            },
            {
                "size": "19х0,2",
                "koef": "0.000093",
                "metrs": 1
            },
            {
                "size": "19х0,3",
                "koef": "0.000138",
                "metrs": 1
            },
            {
                "size": "19х0,4",
                "koef": "0.000183",
                "metrs": 1
            },
            {
                "size": "19х0,5",
                "koef": "0.000228",
                "metrs": 1
            },
            {
                "size": "19х0,6",
                "koef": "0.000272",
                "metrs": 1
            },
            {
                "size": "19х0,8",
                "koef": "0.000359",
                "metrs": 1
            },
            {
                "size": "19х1",
                "koef": "0.000444",
                "metrs": 1
            },
            {
                "size": "19х1,2",
                "koef": "0.000527",
                "metrs": 1
            },
            {
                "size": "19х1,4",
                "koef": "0.000608",
                "metrs": 1
            },
            {
                "size": "19х1,5",
                "koef": "0.000647",
                "metrs": 1
            },
            {
                "size": "19х1,6",
                "koef": "0.000687",
                "metrs": 1
            },
            {
                "size": "19х1,8",
                "koef": "0.000764",
                "metrs": 1
            },
            {
                "size": "19х2",
                "koef": "0.000838",
                "metrs": 1
            },
            {
                "size": "19х2,2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "19х2,5",
                "koef": "0.001017",
                "metrs": 1
            },
            {
                "size": "19х2,8",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "19х3",
                "koef": "0.001184",
                "metrs": 1
            },
            {
                "size": "19х3,2",
                "koef": "0.001247",
                "metrs": 1
            },
            {
                "size": "19х3,5",
                "koef": "0.001338",
                "metrs": 1
            },
            {
                "size": "19х4",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": "19х4,5",
                "koef": "0.001609",
                "metrs": 1
            },
            {
                "size": "19х5",
                "koef": "0.001726",
                "metrs": 1
            },
            {
                "size": "19х5,5",
                "koef": "0.001831",
                "metrs": 1
            },
            {
                "size": "19х6",
                "koef": "0.001924",
                "metrs": 1
            },
            {
                "size": "20х0,4",
                "koef": "0.000193",
                "metrs": 1
            },
            {
                "size": "20х0,5",
                "koef": "0.00024",
                "metrs": 1
            },
            {
                "size": "20х0,6",
                "koef": "0.000287",
                "metrs": 1
            },
            {
                "size": "20х0,8",
                "koef": "0.000379",
                "metrs": 1
            },
            {
                "size": "20х1",
                "koef": "0.000469",
                "metrs": 1
            },
            {
                "size": "20х1,2",
                "koef": "0.000556",
                "metrs": 1
            },
            {
                "size": "20х1,4",
                "koef": "0.000642",
                "metrs": 1
            },
            {
                "size": "20х1,5",
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": "20х1,6",
                "koef": "0.000726",
                "metrs": 1
            },
            {
                "size": "20х1,8",
                "koef": "0.000808",
                "metrs": 1
            },
            {
                "size": "20х2",
                "koef": "0.000888",
                "metrs": 1
            },
            {
                "size": "20х2,2",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": "20х2,5",
                "koef": "0.001079",
                "metrs": 1
            },
            {
                "size": "20х2,8",
                "koef": "0.001188",
                "metrs": 1
            },
            {
                "size": "20х3",
                "koef": "0.001258",
                "metrs": 1
            },
            {
                "size": "20х3,2",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": "20х3,5",
                "koef": "0.001424",
                "metrs": 1
            },
            {
                "size": "20х4",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "20х4,5",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": "20х5",
                "koef": "0.00185",
                "metrs": 1
            },
            {
                "size": "20х5,5",
                "koef": "0.001967",
                "metrs": 1
            },
            {
                "size": "20х6",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": "21х0,4",
                "koef": "0.000203",
                "metrs": 1
            },
            {
                "size": "21х0,5",
                "koef": "0.000253",
                "metrs": 1
            },
            {
                "size": "21х0,6",
                "koef": "0.000302",
                "metrs": 1
            },
            {
                "size": "21х0,8",
                "koef": "0.000399",
                "metrs": 1
            },
            {
                "size": "21х1",
                "koef": "0.000493",
                "metrs": 1
            },
            {
                "size": "21х1,2",
                "koef": "0.000586",
                "metrs": 1
            },
            {
                "size": "21х1,4",
                "koef": "0.000677",
                "metrs": 1
            },
            {
                "size": "21х1,5",
                "koef": "0.000721",
                "metrs": 1
            },
            {
                "size": "21х1,6",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": "21х1,8",
                "koef": "0.000852",
                "metrs": 1
            },
            {
                "size": "21х2",
                "koef": "0.000937",
                "metrs": 1
            },
            {
                "size": "21х2,2",
                "koef": "0.00102",
                "metrs": 1
            },
            {
                "size": "21х2,5",
                "koef": "0.001141",
                "metrs": 1
            },
            {
                "size": "21х2,8",
                "koef": "0.001257",
                "metrs": 1
            },
            {
                "size": "21х3",
                "koef": "0.001332",
                "metrs": 1
            },
            {
                "size": "21х3,2",
                "koef": "0.001405",
                "metrs": 1
            },
            {
                "size": "21х3,5",
                "koef": "0.001511",
                "metrs": 1
            },
            {
                "size": "21х4",
                "koef": "0.001677",
                "metrs": 1
            },
            {
                "size": "21х4,5",
                "koef": "0.001831",
                "metrs": 1
            },
            {
                "size": "21х5",
                "koef": "0.001973",
                "metrs": 1
            },
            {
                "size": "21х5,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "21х6",
                "koef": "0.00222",
                "metrs": 1
            },
            {
                "size": "22х0,4",
                "koef": "0.000213",
                "metrs": 1
            },
            {
                "size": "22х0,5",
                "koef": "0.000265",
                "metrs": 1
            },
            {
                "size": "22х0,6",
                "koef": "0.000317",
                "metrs": 1
            },
            {
                "size": "22х0,8",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": "22х1",
                "koef": "0.000518",
                "metrs": 1
            },
            {
                "size": "22х1,2",
                "koef": "0.000616",
                "metrs": 1
            },
            {
                "size": "22х1,4",
                "koef": "0.000711",
                "metrs": 1
            },
            {
                "size": "22х1,5",
                "koef": "0.000758",
                "metrs": 1
            },
            {
                "size": "22х1,6",
                "koef": "0.000805",
                "metrs": 1
            },
            {
                "size": "22х1,8",
                "koef": "0.000897",
                "metrs": 1
            },
            {
                "size": "22х2",
                "koef": "0.000986",
                "metrs": 1
            },
            {
                "size": "22х2,2",
                "koef": "0.001074",
                "metrs": 1
            },
            {
                "size": "22х2,5",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": "22х2,7",
                "koef": "0.001285",
                "metrs": 1
            },
            {
                "size": "22х2,8",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": "22х3",
                "koef": "0.001406",
                "metrs": 1
            },
            {
                "size": "22х3,2",
                "koef": "0.001484",
                "metrs": 1
            },
            {
                "size": "22х3,5",
                "koef": "0.001597",
                "metrs": 1
            },
            {
                "size": "22х4",
                "koef": "0.001776",
                "metrs": 1
            },
            {
                "size": "22х4,5",
                "koef": "0.001942",
                "metrs": 1
            },
            {
                "size": "22х5",
                "koef": "0.002096",
                "metrs": 1
            },
            {
                "size": "22х5,5",
                "koef": "0.002238",
                "metrs": 1
            },
            {
                "size": "22х6",
                "koef": "0.002368",
                "metrs": 1
            },
            {
                "size": "23х0,5",
                "koef": "0.000277",
                "metrs": 1
            },
            {
                "size": "23х0,6",
                "koef": "0.000331",
                "metrs": 1
            },
            {
                "size": "23х0,8",
                "koef": "0.000438",
                "metrs": 1
            },
            {
                "size": "23х1",
                "koef": "0.000543",
                "metrs": 1
            },
            {
                "size": "23х1,2",
                "koef": "0.000645",
                "metrs": 1
            },
            {
                "size": "23х1,4",
                "koef": "0.000746",
                "metrs": 1
            },
            {
                "size": "23х1,5",
                "koef": "0.000795",
                "metrs": 1
            },
            {
                "size": "23х1,6",
                "koef": "0.000844",
                "metrs": 1
            },
            {
                "size": "23х1,8",
                "koef": "0.000941",
                "metrs": 1
            },
            {
                "size": "23х2",
                "koef": "0.001036",
                "metrs": 1
            },
            {
                "size": "23х2,2",
                "koef": "0.001129",
                "metrs": 1
            },
            {
                "size": "23х2,5",
                "koef": "0.001264",
                "metrs": 1
            },
            {
                "size": "23х2,8",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "23х3",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": "23х3,2",
                "koef": "0.001563",
                "metrs": 1
            },
            {
                "size": "23х3,5",
                "koef": "0.001683",
                "metrs": 1
            },
            {
                "size": "23х4",
                "koef": "0.001874",
                "metrs": 1
            },
            {
                "size": "23х4,5",
                "koef": "0.002053",
                "metrs": 1
            },
            {
                "size": "23х5",
                "koef": "0.00222",
                "metrs": 1
            },
            {
                "size": "23х5,5",
                "koef": "0.002374",
                "metrs": 1
            },
            {
                "size": "23х6",
                "koef": "0.002515",
                "metrs": 1
            },
            {
                "size": "23х6,5",
                "koef": "0.002645",
                "metrs": 1
            },
            {
                "size": "23х7",
                "koef": "0.002762",
                "metrs": 1
            },
            {
                "size": "24х0,5",
                "koef": "0.00029",
                "metrs": 1
            },
            {
                "size": "24х0,6",
                "koef": "0.000346",
                "metrs": 1
            },
            {
                "size": "24х0,8",
                "koef": "0.000458",
                "metrs": 1
            },
            {
                "size": "24х1",
                "koef": "0.000567",
                "metrs": 1
            },
            {
                "size": "24х1,2",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": "24х1,4",
                "koef": "0.00078",
                "metrs": 1
            },
            {
                "size": "24х1,5",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": "24х1,6",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "24х1,8",
                "koef": "0.000985",
                "metrs": 1
            },
            {
                "size": "24х2",
                "koef": "0.001085",
                "metrs": 1
            },
            {
                "size": "24х2,2",
                "koef": "0.001183",
                "metrs": 1
            },
            {
                "size": "24х2,5",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": "24х2,8",
                "koef": "0.001464",
                "metrs": 1
            },
            {
                "size": "24х3",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": "24х3,2",
                "koef": "0.001641",
                "metrs": 1
            },
            {
                "size": "24х3,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": "24х4",
                "koef": "0.001973",
                "metrs": 1
            },
            {
                "size": "24х4,5",
                "koef": "0.002164",
                "metrs": 1
            },
            {
                "size": "24х5",
                "koef": "0.002343",
                "metrs": 1
            },
            {
                "size": "24х5,5",
                "koef": "0.002509",
                "metrs": 1
            },
            {
                "size": "24х6",
                "koef": "0.002663",
                "metrs": 1
            },
            {
                "size": "24х6,5",
                "koef": "0.002805",
                "metrs": 1
            },
            {
                "size": "24х7",
                "koef": "0.002935",
                "metrs": 1
            },
            {
                "size": "25х0,5",
                "koef": "0.000302",
                "metrs": 1
            },
            {
                "size": "25х0,6",
                "koef": "0.000361",
                "metrs": 1
            },
            {
                "size": "25х0,8",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": "25х1",
                "koef": "0.000592",
                "metrs": 1
            },
            {
                "size": "25х1,2",
                "koef": "0.000704",
                "metrs": 1
            },
            {
                "size": "25х1,4",
                "koef": "0.000815",
                "metrs": 1
            },
            {
                "size": "25х1,5",
                "koef": "0.000869",
                "metrs": 1
            },
            {
                "size": "25х1,6",
                "koef": "0.000923",
                "metrs": 1
            },
            {
                "size": "25х1,8",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "25х2",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": "25х2,2",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": "25х2,5",
                "koef": "0.001387",
                "metrs": 1
            },
            {
                "size": "25х2,8",
                "koef": "0.001533",
                "metrs": 1
            },
            {
                "size": "25х3",
                "koef": "0.001628",
                "metrs": 1
            },
            {
                "size": "25х3,2",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": "25х3,5",
                "koef": "0.001856",
                "metrs": 1
            },
            {
                "size": "25х4",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": "25х4,5",
                "koef": "0.002275",
                "metrs": 1
            },
            {
                "size": "25х5",
                "koef": "0.002466",
                "metrs": 1
            },
            {
                "size": "25х5,5",
                "koef": "0.002645",
                "metrs": 1
            },
            {
                "size": "25х6",
                "koef": "0.002811",
                "metrs": 1
            },
            {
                "size": "25х6,5",
                "koef": "0.002966",
                "metrs": 1
            },
            {
                "size": "25х7",
                "koef": "0.003107",
                "metrs": 1
            },
            {
                "size": "25х7,5",
                "koef": "0.003237",
                "metrs": 1
            },
            {
                "size": "26х0,5",
                "koef": "0.000314",
                "metrs": 1
            },
            {
                "size": "26х0,6",
                "koef": "0.000376",
                "metrs": 1
            },
            {
                "size": "26х0,8",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": "26х1",
                "koef": "0.000617",
                "metrs": 1
            },
            {
                "size": "26х1,2",
                "koef": "0.000734",
                "metrs": 1
            },
            {
                "size": "26х1,4",
                "koef": "0.000849",
                "metrs": 1
            },
            {
                "size": "26х1,5",
                "koef": "0.000906",
                "metrs": 1
            },
            {
                "size": "26х1,6",
                "koef": "0.000963",
                "metrs": 1
            },
            {
                "size": "26х1,8",
                "koef": "0.001074",
                "metrs": 1
            },
            {
                "size": "26х2",
                "koef": "0.001184",
                "metrs": 1
            },
            {
                "size": "26х2,2",
                "koef": "0.001291",
                "metrs": 1
            },
            {
                "size": "26х2,5",
                "koef": "0.001449",
                "metrs": 1
            },
            {
                "size": "26х2,8",
                "koef": "0.001602",
                "metrs": 1
            },
            {
                "size": "26х3",
                "koef": "0.001702",
                "metrs": 1
            },
            {
                "size": "26х3,2",
                "koef": "0.001799",
                "metrs": 1
            },
            {
                "size": "26х3,5",
                "koef": "0.001942",
                "metrs": 1
            },
            {
                "size": "26х4",
                "koef": "0.00217",
                "metrs": 1
            },
            {
                "size": "26х4,5",
                "koef": "0.002386",
                "metrs": 1
            },
            {
                "size": "26х5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": "26х5,5",
                "koef": "0.002781",
                "metrs": 1
            },
            {
                "size": "26х6",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": "26х6,5",
                "koef": "0.003126",
                "metrs": 1
            },
            {
                "size": "26х7",
                "koef": "0.00328",
                "metrs": 1
            },
            {
                "size": "27х0,5",
                "koef": "0.000327",
                "metrs": 1
            },
            {
                "size": "27х0,6",
                "koef": "0.000391",
                "metrs": 1
            },
            {
                "size": "27х0,8",
                "koef": "0.000517",
                "metrs": 1
            },
            {
                "size": "27х1",
                "koef": "0.000641",
                "metrs": 1
            },
            {
                "size": "27х1,2",
                "koef": "0.000764",
                "metrs": 1
            },
            {
                "size": "27х1,4",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "27х1,5",
                "koef": "0.000943",
                "metrs": 1
            },
            {
                "size": "27х1,6",
                "koef": "0.001002",
                "metrs": 1
            },
            {
                "size": "27х1,8",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "27х2",
                "koef": "0.001233",
                "metrs": 1
            },
            {
                "size": "27х2,2",
                "koef": "0.001346",
                "metrs": 1
            },
            {
                "size": "27х2,5",
                "koef": "0.001511",
                "metrs": 1
            },
            {
                "size": "27х2,8",
                "koef": "0.001671",
                "metrs": 1
            },
            {
                "size": "27х3",
                "koef": "0.001776",
                "metrs": 1
            },
            {
                "size": "27х3,2",
                "koef": "0.001878",
                "metrs": 1
            },
            {
                "size": "27х3,5",
                "koef": "0.002028",
                "metrs": 1
            },
            {
                "size": "27х4",
                "koef": "0.002269",
                "metrs": 1
            },
            {
                "size": "27х4,5",
                "koef": "0.002497",
                "metrs": 1
            },
            {
                "size": "27х5",
                "koef": "0.002713",
                "metrs": 1
            },
            {
                "size": "27х5,5",
                "koef": "0.002916",
                "metrs": 1
            },
            {
                "size": "27х6",
                "koef": "0.003107",
                "metrs": 1
            },
            {
                "size": "27х6,5",
                "koef": "0.003286",
                "metrs": 1
            },
            {
                "size": "27х7",
                "koef": "0.003453",
                "metrs": 1
            },
            {
                "size": "28х0,5",
                "koef": "0.000339",
                "metrs": 1
            },
            {
                "size": "28х0,6",
                "koef": "0.000405",
                "metrs": 1
            },
            {
                "size": "28х0,8",
                "koef": "0.000537",
                "metrs": 1
            },
            {
                "size": "28х1",
                "koef": "0.000666",
                "metrs": 1
            },
            {
                "size": "28х1,2",
                "koef": "0.000793",
                "metrs": 1
            },
            {
                "size": "28х1,4",
                "koef": "0.000918",
                "metrs": 1
            },
            {
                "size": "28х1,5",
                "koef": "0.00098",
                "metrs": 1
            },
            {
                "size": "28х1,6",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "28х1,8",
                "koef": "0.001163",
                "metrs": 1
            },
            {
                "size": "28х2",
                "koef": "0.001282",
                "metrs": 1
            },
            {
                "size": "28х2,2",
                "koef": "0.0014",
                "metrs": 1
            },
            {
                "size": "28х2,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "28х2,8",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": "28х3",
                "koef": "0.00185",
                "metrs": 1
            },
            {
                "size": "28х3,2",
                "koef": "0.001957",
                "metrs": 1
            },
            {
                "size": "28х3,5",
                "koef": "0.002115",
                "metrs": 1
            },
            {
                "size": "28х4",
                "koef": "0.002368",
                "metrs": 1
            },
            {
                "size": "28х4,5",
                "koef": "0.002608",
                "metrs": 1
            },
            {
                "size": "28х5",
                "koef": "0.002836",
                "metrs": 1
            },
            {
                "size": "28х5,5",
                "koef": "0.003052",
                "metrs": 1
            },
            {
                "size": "28х6",
                "koef": "0.003255",
                "metrs": 1
            },
            {
                "size": "28х6,5",
                "koef": "0.003446",
                "metrs": 1
            },
            {
                "size": "28х7",
                "koef": "0.003625",
                "metrs": 1
            },
            {
                "size": "28х7,5",
                "koef": "0.003792",
                "metrs": 1
            },
            {
                "size": "28х8",
                "koef": "0.003946",
                "metrs": 1
            },
            {
                "size": "30х0,4",
                "koef": "0.000292",
                "metrs": 1
            },
            {
                "size": "30х0,5",
                "koef": "0.000364",
                "metrs": 1
            },
            {
                "size": "30х0,6",
                "koef": "0.000435",
                "metrs": 1
            },
            {
                "size": "30х0,8",
                "koef": "0.000576",
                "metrs": 1
            },
            {
                "size": "30х1",
                "koef": "0.000715",
                "metrs": 1
            },
            {
                "size": "30х1,2",
                "koef": "0.000852",
                "metrs": 1
            },
            {
                "size": "30х1,4",
                "koef": "0.000987",
                "metrs": 1
            },
            {
                "size": "30х1,5",
                "koef": "0.001054",
                "metrs": 1
            },
            {
                "size": "30х1,6",
                "koef": "0.001121",
                "metrs": 1
            },
            {
                "size": "30х1,8",
                "koef": "0.001252",
                "metrs": 1
            },
            {
                "size": "30х2",
                "koef": "0.001381",
                "metrs": 1
            },
            {
                "size": "30х2,2",
                "koef": "0.001508",
                "metrs": 1
            },
            {
                "size": "30х2,5",
                "koef": "0.001695",
                "metrs": 1
            },
            {
                "size": "30х2,8",
                "koef": "0.001878",
                "metrs": 1
            },
            {
                "size": "30х3",
                "koef": "0.001998",
                "metrs": 1
            },
            {
                "size": "30х3,2",
                "koef": "0.002115",
                "metrs": 1
            },
            {
                "size": "30х3,5",
                "koef": "0.002287",
                "metrs": 1
            },
            {
                "size": "30х4",
                "koef": "0.002565",
                "metrs": 1
            },
            {
                "size": "30х4,5",
                "koef": "0.00283",
                "metrs": 1
            },
            {
                "size": "30х5",
                "koef": "0.003083",
                "metrs": 1
            },
            {
                "size": "30х5,5",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": "30х6",
                "koef": "0.003551",
                "metrs": 1
            },
            {
                "size": "30х6,5",
                "koef": "0.003767",
                "metrs": 1
            },
            {
                "size": "30х7",
                "koef": "0.00397",
                "metrs": 1
            },
            {
                "size": "30х7,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": "30х8",
                "koef": "0.00434",
                "metrs": 1
            },
            {
                "size": "32х0,5",
                "koef": "0.000388",
                "metrs": 1
            },
            {
                "size": "32х0,6",
                "koef": "0.000465",
                "metrs": 1
            },
            {
                "size": "32х0,8",
                "koef": "0.000616",
                "metrs": 1
            },
            {
                "size": "32х1",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": "32х1,2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "32х1,4",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": "32х1,5",
                "koef": "0.001128",
                "metrs": 1
            },
            {
                "size": "32х1,6",
                "koef": "0.0012",
                "metrs": 1
            },
            {
                "size": "32х1,8",
                "koef": "0.001341",
                "metrs": 1
            },
            {
                "size": "32х2",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": "32х2,2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "32х2,5",
                "koef": "0.001819",
                "metrs": 1
            },
            {
                "size": "32х2,8",
                "koef": "0.002016",
                "metrs": 1
            },
            {
                "size": "32х3",
                "koef": "0.002146",
                "metrs": 1
            },
            {
                "size": "32х3,2",
                "koef": "0.002273",
                "metrs": 1
            },
            {
                "size": "32х3,5",
                "koef": "0.00246",
                "metrs": 1
            },
            {
                "size": "32х4",
                "koef": "0.002762",
                "metrs": 1
            },
            {
                "size": "32х4,5",
                "koef": "0.003052",
                "metrs": 1
            },
            {
                "size": "32х5",
                "koef": "0.003329",
                "metrs": 1
            },
            {
                "size": "32х5,5",
                "koef": "0.003594",
                "metrs": 1
            },
            {
                "size": "32х6",
                "koef": "0.003847",
                "metrs": 1
            },
            {
                "size": "32х6,5",
                "koef": "0.004088",
                "metrs": 1
            },
            {
                "size": "32х7",
                "koef": "0.004316",
                "metrs": 1
            },
            {
                "size": "32х7,5",
                "koef": "0.004532",
                "metrs": 1
            },
            {
                "size": "32х8",
                "koef": "0.004735",
                "metrs": 1
            },
            {
                "size": "32х8,5",
                "koef": "0.004926",
                "metrs": 1
            },
            {
                "size": "34х0,5",
                "koef": "0.000413",
                "metrs": 1
            },
            {
                "size": "34х0,6",
                "koef": "0.000494",
                "metrs": 1
            },
            {
                "size": "34х0,8",
                "koef": "0.000655",
                "metrs": 1
            },
            {
                "size": "34х1",
                "koef": "0.000814",
                "metrs": 1
            },
            {
                "size": "34х1,2",
                "koef": "0.000971",
                "metrs": 1
            },
            {
                "size": "34х1,4",
                "koef": "0.001126",
                "metrs": 1
            },
            {
                "size": "34х1,5",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": "34х1,6",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "34х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": "34х2",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "34х2,2",
                "koef": "0.001725",
                "metrs": 1
            },
            {
                "size": "34х2,5",
                "koef": "0.001942",
                "metrs": 1
            },
            {
                "size": "34х2,8",
                "koef": "0.002154",
                "metrs": 1
            },
            {
                "size": "34х3",
                "koef": "0.002294",
                "metrs": 1
            },
            {
                "size": "34х3,2",
                "koef": "0.002431",
                "metrs": 1
            },
            {
                "size": "34х3,5",
                "koef": "0.002633",
                "metrs": 1
            },
            {
                "size": "34х4",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": "34х4,5",
                "koef": "0.003274",
                "metrs": 1
            },
            {
                "size": "34х5",
                "koef": "0.003576",
                "metrs": 1
            },
            {
                "size": "34х5,5",
                "koef": "0.003866",
                "metrs": 1
            },
            {
                "size": "34х6",
                "koef": "0.004143",
                "metrs": 1
            },
            {
                "size": "34х6,5",
                "koef": "0.004408",
                "metrs": 1
            },
            {
                "size": "34х7",
                "koef": "0.004661",
                "metrs": 1
            },
            {
                "size": "34х7,5",
                "koef": "0.004901",
                "metrs": 1
            },
            {
                "size": "34х8",
                "koef": "0.00513",
                "metrs": 1
            },
            {
                "size": "34х9",
                "koef": "0.005549",
                "metrs": 1
            },
            {
                "size": "35х0,5",
                "koef": "0.000425",
                "metrs": 1
            },
            {
                "size": "35х0,6",
                "koef": "0.000509",
                "metrs": 1
            },
            {
                "size": "35х0,8",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": "35х1",
                "koef": "0.000838",
                "metrs": 1
            },
            {
                "size": "35х1,2",
                "koef": "0.001",
                "metrs": 1
            },
            {
                "size": "35х1,4",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "35х1,5",
                "koef": "0.001239",
                "metrs": 1
            },
            {
                "size": "35х1,6",
                "koef": "0.001318",
                "metrs": 1
            },
            {
                "size": "35х1,8",
                "koef": "0.001474",
                "metrs": 1
            },
            {
                "size": "35х2",
                "koef": "0.001628",
                "metrs": 1
            },
            {
                "size": "35х2,2",
                "koef": "0.00178",
                "metrs": 1
            },
            {
                "size": "35х2,5",
                "koef": "0.002004",
                "metrs": 1
            },
            {
                "size": "35х2,8",
                "koef": "0.002223",
                "metrs": 1
            },
            {
                "size": "35х3",
                "koef": "0.002368",
                "metrs": 1
            },
            {
                "size": "35х3,2",
                "koef": "0.00251",
                "metrs": 1
            },
            {
                "size": "35х3,5",
                "koef": "0.002719",
                "metrs": 1
            },
            {
                "size": "35х4",
                "koef": "0.003058",
                "metrs": 1
            },
            {
                "size": "35х4,5",
                "koef": "0.003385",
                "metrs": 1
            },
            {
                "size": "35х5",
                "koef": "0.003699",
                "metrs": 1
            },
            {
                "size": "35х5,5",
                "koef": "0.004001",
                "metrs": 1
            },
            {
                "size": "35х6",
                "koef": "0.004291",
                "metrs": 1
            },
            {
                "size": "35х6,5",
                "koef": "0.004569",
                "metrs": 1
            },
            {
                "size": "35х7",
                "koef": "0.004834",
                "metrs": 1
            },
            {
                "size": "35х7,5",
                "koef": "0.005086",
                "metrs": 1
            },
            {
                "size": "35х8",
                "koef": "0.005327",
                "metrs": 1
            },
            {
                "size": "36х0,5",
                "koef": "0.000438",
                "metrs": 1
            },
            {
                "size": "36х0,6",
                "koef": "0.000524",
                "metrs": 1
            },
            {
                "size": "36х0,8",
                "koef": "0.000694",
                "metrs": 1
            },
            {
                "size": "36х1",
                "koef": "0.000863",
                "metrs": 1
            },
            {
                "size": "36х1,2",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "36х1,4",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": "36х1,5",
                "koef": "0.001276",
                "metrs": 1
            },
            {
                "size": "36х1,6",
                "koef": "0.001357",
                "metrs": 1
            },
            {
                "size": "36х1,8",
                "koef": "0.001518",
                "metrs": 1
            },
            {
                "size": "36х2",
                "koef": "0.001677",
                "metrs": 1
            },
            {
                "size": "36х2,2",
                "koef": "0.001834",
                "metrs": 1
            },
            {
                "size": "36х2,5",
                "koef": "0.002065",
                "metrs": 1
            },
            {
                "size": "36х2,8",
                "koef": "0.002293",
                "metrs": 1
            },
            {
                "size": "36х3",
                "koef": "0.002441",
                "metrs": 1
            },
            {
                "size": "36х3,2",
                "koef": "0.002588",
                "metrs": 1
            },
            {
                "size": "36х3,5",
                "koef": "0.002805",
                "metrs": 1
            },
            {
                "size": "36х4",
                "koef": "0.003157",
                "metrs": 1
            },
            {
                "size": "36х4,5",
                "koef": "0.003496",
                "metrs": 1
            },
            {
                "size": "36х5",
                "koef": "0.003823",
                "metrs": 1
            },
            {
                "size": "36х5,5",
                "koef": "0.004137",
                "metrs": 1
            },
            {
                "size": "36х6",
                "koef": "0.004439",
                "metrs": 1
            },
            {
                "size": "36х6,5",
                "koef": "0.004729",
                "metrs": 1
            },
            {
                "size": "36х7",
                "koef": "0.005006",
                "metrs": 1
            },
            {
                "size": "36х7,5",
                "koef": "0.005271",
                "metrs": 1
            },
            {
                "size": "36х8",
                "koef": "0.005524",
                "metrs": 1
            },
            {
                "size": "36х10",
                "koef": "0.006412",
                "metrs": 1
            },
            {
                "size": "38х0,5",
                "koef": "0.000462",
                "metrs": 1
            },
            {
                "size": "38х0,6",
                "koef": "0.000553",
                "metrs": 1
            },
            {
                "size": "38х0,8",
                "koef": "0.000734",
                "metrs": 1
            },
            {
                "size": "38х1",
                "koef": "0.000912",
                "metrs": 1
            },
            {
                "size": "38х1,2",
                "koef": "0.001089",
                "metrs": 1
            },
            {
                "size": "38х1,4",
                "koef": "0.001264",
                "metrs": 1
            },
            {
                "size": "38х1,5",
                "koef": "0.00135",
                "metrs": 1
            },
            {
                "size": "38х1,6",
                "koef": "0.001436",
                "metrs": 1
            },
            {
                "size": "38х1,8",
                "koef": "0.001607",
                "metrs": 1
            },
            {
                "size": "38х2",
                "koef": "0.001776",
                "metrs": 1
            },
            {
                "size": "38х2,2",
                "koef": "0.001942",
                "metrs": 1
            },
            {
                "size": "38х2,5",
                "koef": "0.002189",
                "metrs": 1
            },
            {
                "size": "38х2,8",
                "koef": "0.002431",
                "metrs": 1
            },
            {
                "size": "38х3",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": "38х3,2",
                "koef": "0.002746",
                "metrs": 1
            },
            {
                "size": "38х3,5",
                "koef": "0.002978",
                "metrs": 1
            },
            {
                "size": "38х4",
                "koef": "0.003354",
                "metrs": 1
            },
            {
                "size": "38х4,5",
                "koef": "0.003718",
                "metrs": 1
            },
            {
                "size": "38х5",
                "koef": "0.004069",
                "metrs": 1
            },
            {
                "size": "38х5,5",
                "koef": "0.004408",
                "metrs": 1
            },
            {
                "size": "38х6",
                "koef": "0.004735",
                "metrs": 1
            },
            {
                "size": "38х6,5",
                "koef": "0.005049",
                "metrs": 1
            },
            {
                "size": "38х7",
                "koef": "0.005352",
                "metrs": 1
            },
            {
                "size": "38х7,5",
                "koef": "0.005641",
                "metrs": 1
            },
            {
                "size": "38х8",
                "koef": "0.005919",
                "metrs": 1
            },
            {
                "size": "38х8,5",
                "koef": "0.006184",
                "metrs": 1
            },
            {
                "size": "38х9",
                "koef": "0.006437",
                "metrs": 1
            },
            {
                "size": "38х9,5",
                "koef": "0.006677",
                "metrs": 1
            },
            {
                "size": "38х10",
                "koef": "0.006905",
                "metrs": 1
            },
            {
                "size": "40х0,5",
                "koef": "0.000487",
                "metrs": 1
            },
            {
                "size": "40х0,6",
                "koef": "0.000583",
                "metrs": 1
            },
            {
                "size": "40х0,8",
                "koef": "0.000773",
                "metrs": 1
            },
            {
                "size": "40х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": "40х1,2",
                "koef": "0.001148",
                "metrs": 1
            },
            {
                "size": "40х1,4",
                "koef": "0.001333",
                "metrs": 1
            },
            {
                "size": "40х1,5",
                "koef": "0.001424",
                "metrs": 1
            },
            {
                "size": "40х1,6",
                "koef": "0.001515",
                "metrs": 1
            },
            {
                "size": "40х1,8",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "40х2",
                "koef": "0.001874",
                "metrs": 1
            },
            {
                "size": "40х2,2",
                "koef": "0.002051",
                "metrs": 1
            },
            {
                "size": "40х2,5",
                "koef": "0.002312",
                "metrs": 1
            },
            {
                "size": "40х2,8",
                "koef": "0.002569",
                "metrs": 1
            },
            {
                "size": "40х3",
                "koef": "0.002737",
                "metrs": 1
            },
            {
                "size": "40х3,2",
                "koef": "0.002904",
                "metrs": 1
            },
            {
                "size": "40х3,5",
                "koef": "0.003151",
                "metrs": 1
            },
            {
                "size": "40х4",
                "koef": "0.003551",
                "metrs": 1
            },
            {
                "size": "40х4,5",
                "koef": "0.00394",
                "metrs": 1
            },
            {
                "size": "40х5",
                "koef": "0.004316",
                "metrs": 1
            },
            {
                "size": "40х5,5",
                "koef": "0.00468",
                "metrs": 1
            },
            {
                "size": "40х6",
                "koef": "0.005031",
                "metrs": 1
            },
            {
                "size": "40х6,5",
                "koef": "0.00537",
                "metrs": 1
            },
            {
                "size": "40х7",
                "koef": "0.005697",
                "metrs": 1
            },
            {
                "size": "40х7,5",
                "koef": "0.006011",
                "metrs": 1
            },
            {
                "size": "40х8",
                "koef": "0.006313",
                "metrs": 1
            },
            {
                "size": "40х8,5",
                "koef": "0.006603",
                "metrs": 1
            },
            {
                "size": "40х9",
                "koef": "0.006881",
                "metrs": 1
            },
            {
                "size": "40х9,5",
                "koef": "0.007146",
                "metrs": 1
            },
            {
                "size": "40х10",
                "koef": "0.007398",
                "metrs": 1
            },
            {
                "size": "40х11",
                "koef": "0.007867",
                "metrs": 1
            },
            {
                "size": "40х12",
                "koef": "0.008286",
                "metrs": 1
            },
            {
                "size": "42х0,8",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": "42х1",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": "42х1,2",
                "koef": "0.001207",
                "metrs": 1
            },
            {
                "size": "42х1,4",
                "koef": "0.001402",
                "metrs": 1
            },
            {
                "size": "42х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": "42х1,6",
                "koef": "0.001594",
                "metrs": 1
            },
            {
                "size": "42х1,8",
                "koef": "0.001785",
                "metrs": 1
            },
            {
                "size": "42х2",
                "koef": "0.001973",
                "metrs": 1
            },
            {
                "size": "42х2,2",
                "koef": "0.002159",
                "metrs": 1
            },
            {
                "size": "42х2,5",
                "koef": "0.002435",
                "metrs": 1
            },
            {
                "size": "42х2,8",
                "koef": "0.002707",
                "metrs": 1
            },
            {
                "size": "42х3",
                "koef": "0.002885",
                "metrs": 1
            },
            {
                "size": "42х3,2",
                "koef": "0.003062",
                "metrs": 1
            },
            {
                "size": "42х3,5",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": "42х4",
                "koef": "0.003749",
                "metrs": 1
            },
            {
                "size": "42х4,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": "42х5",
                "koef": "0.004562",
                "metrs": 1
            },
            {
                "size": "42х5,5",
                "koef": "0.004951",
                "metrs": 1
            },
            {
                "size": "42х6",
                "koef": "0.005327",
                "metrs": 1
            },
            {
                "size": "42х6,5",
                "koef": "0.005691",
                "metrs": 1
            },
            {
                "size": "42х7",
                "koef": "0.006042",
                "metrs": 1
            },
            {
                "size": "42х7,5",
                "koef": "0.006381",
                "metrs": 1
            },
            {
                "size": "42х8",
                "koef": "0.006708",
                "metrs": 1
            },
            {
                "size": "42х8,5",
                "koef": "0.007022",
                "metrs": 1
            },
            {
                "size": "42х9",
                "koef": "0.007324",
                "metrs": 1
            },
            {
                "size": "42х9,5",
                "koef": "0.007614",
                "metrs": 1
            },
            {
                "size": "42х10",
                "koef": "0.007892",
                "metrs": 1
            },
            {
                "size": "45х0,8",
                "koef": "0.000872",
                "metrs": 1
            },
            {
                "size": "45х1",
                "koef": "0.001085",
                "metrs": 1
            },
            {
                "size": "45х1,2",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": "45х1,4",
                "koef": "0.001505",
                "metrs": 1
            },
            {
                "size": "45х1,5",
                "koef": "0.001609",
                "metrs": 1
            },
            {
                "size": "45х1,6",
                "koef": "0.001712",
                "metrs": 1
            },
            {
                "size": "45х1,8",
                "koef": "0.001918",
                "metrs": 1
            },
            {
                "size": "45х2",
                "koef": "0.002121",
                "metrs": 1
            },
            {
                "size": "45х2,2",
                "koef": "0.002322",
                "metrs": 1
            },
            {
                "size": "45х2,5",
                "koef": "0.00262",
                "metrs": 1
            },
            {
                "size": "45х2,8",
                "koef": "0.002914",
                "metrs": 1
            },
            {
                "size": "45х3",
                "koef": "0.003107",
                "metrs": 1
            },
            {
                "size": "45х3,2",
                "koef": "0.003299",
                "metrs": 1
            },
            {
                "size": "45х3,5",
                "koef": "0.003582",
                "metrs": 1
            },
            {
                "size": "45х4",
                "koef": "0.004044",
                "metrs": 1
            },
            {
                "size": "45х4,5",
                "koef": "0.004495",
                "metrs": 1
            },
            {
                "size": "45х5",
                "koef": "0.004932",
                "metrs": 1
            },
            {
                "size": "45х5,5",
                "koef": "0.005358",
                "metrs": 1
            },
            {
                "size": "45х6",
                "koef": "0.005771",
                "metrs": 1
            },
            {
                "size": "45х6,5",
                "koef": "0.006172",
                "metrs": 1
            },
            {
                "size": "45х7",
                "koef": "0.00656",
                "metrs": 1
            },
            {
                "size": "45х7,5",
                "koef": "0.006936",
                "metrs": 1
            },
            {
                "size": "45х8",
                "koef": "0.0073",
                "metrs": 1
            },
            {
                "size": "45х8,5",
                "koef": "0.007651",
                "metrs": 1
            },
            {
                "size": "45х9",
                "koef": "0.00799",
                "metrs": 1
            },
            {
                "size": "45х9,5",
                "koef": "0.008317",
                "metrs": 1
            },
            {
                "size": "45х10",
                "koef": "0.008632",
                "metrs": 1
            },
            {
                "size": "48х0,8",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "48х1",
                "koef": "0.001159",
                "metrs": 1
            },
            {
                "size": "48х1,2",
                "koef": "0.001385",
                "metrs": 1
            },
            {
                "size": "48х1,4",
                "koef": "0.001609",
                "metrs": 1
            },
            {
                "size": "48х1,5",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": "48х1,6",
                "koef": "0.001831",
                "metrs": 1
            },
            {
                "size": "48х1,8",
                "koef": "0.002051",
                "metrs": 1
            },
            {
                "size": "48х2",
                "koef": "0.002269",
                "metrs": 1
            },
            {
                "size": "48х2,2",
                "koef": "0.002485",
                "metrs": 1
            },
            {
                "size": "48х2,5",
                "koef": "0.002805",
                "metrs": 1
            },
            {
                "size": "48х2,8",
                "koef": "0.003121",
                "metrs": 1
            },
            {
                "size": "48х3",
                "koef": "0.003329",
                "metrs": 1
            },
            {
                "size": "48х3,2",
                "koef": "0.003535",
                "metrs": 1
            },
            {
                "size": "48х3,5",
                "koef": "0.003841",
                "metrs": 1
            },
            {
                "size": "48х4",
                "koef": "0.00434",
                "metrs": 1
            },
            {
                "size": "48х4,5",
                "koef": "0.004827",
                "metrs": 1
            },
            {
                "size": "48х5",
                "koef": "0.005302",
                "metrs": 1
            },
            {
                "size": "48х5,5",
                "koef": "0.005765",
                "metrs": 1
            },
            {
                "size": "48х6",
                "koef": "0.006215",
                "metrs": 1
            },
            {
                "size": "48х6,5",
                "koef": "0.006652",
                "metrs": 1
            },
            {
                "size": "48х7",
                "koef": "0.007078",
                "metrs": 1
            },
            {
                "size": "48х7,5",
                "koef": "0.007491",
                "metrs": 1
            },
            {
                "size": "48х8",
                "koef": "0.007892",
                "metrs": 1
            },
            {
                "size": "48х8,5",
                "koef": "0.00828",
                "metrs": 1
            },
            {
                "size": "48х9",
                "koef": "0.008656",
                "metrs": 1
            },
            {
                "size": "48х9,5",
                "koef": "0.00902",
                "metrs": 1
            },
            {
                "size": "48х10",
                "koef": "0.009371",
                "metrs": 1
            },
            {
                "size": "48х11",
                "koef": "0.01004",
                "metrs": 1
            },
            {
                "size": "50х0,8",
                "koef": "0.000971",
                "metrs": 1
            },
            {
                "size": "50х1",
                "koef": "0.001208",
                "metrs": 1
            },
            {
                "size": "50х1,2",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": "50х1,4",
                "koef": "0.001678",
                "metrs": 1
            },
            {
                "size": "50х1,5",
                "koef": "0.001794",
                "metrs": 1
            },
            {
                "size": "50х1,6",
                "koef": "0.00191",
                "metrs": 1
            },
            {
                "size": "50х1,8",
                "koef": "0.00214",
                "metrs": 1
            },
            {
                "size": "50х2",
                "koef": "0.002368",
                "metrs": 1
            },
            {
                "size": "50х2,2",
                "koef": "0.002593",
                "metrs": 1
            },
            {
                "size": "50х2,5",
                "koef": "0.002929",
                "metrs": 1
            },
            {
                "size": "50х2,8",
                "koef": "0.003259",
                "metrs": 1
            },
            {
                "size": "50х3",
                "koef": "0.003477",
                "metrs": 1
            },
            {
                "size": "50х3,2",
                "koef": "0.003693",
                "metrs": 1
            },
            {
                "size": "50х3,5",
                "koef": "0.004014",
                "metrs": 1
            },
            {
                "size": "50х4",
                "koef": "0.004538",
                "metrs": 1
            },
            {
                "size": "50х4,5",
                "koef": "0.005049",
                "metrs": 1
            },
            {
                "size": "50х5",
                "koef": "0.005549",
                "metrs": 1
            },
            {
                "size": "50х5,5",
                "koef": "0.006036",
                "metrs": 1
            },
            {
                "size": "50х6",
                "koef": "0.006511",
                "metrs": 1
            },
            {
                "size": "50х6,5",
                "koef": "0.006973",
                "metrs": 1
            },
            {
                "size": "50х7",
                "koef": "0.007423",
                "metrs": 1
            },
            {
                "size": "50х7,5",
                "koef": "0.007861",
                "metrs": 1
            },
            {
                "size": "50х8",
                "koef": "0.008286",
                "metrs": 1
            },
            {
                "size": "50х8,5",
                "koef": "0.008699",
                "metrs": 1
            },
            {
                "size": "50х9",
                "koef": "0.0091",
                "metrs": 1
            },
            {
                "size": "50х9,5",
                "koef": "0.009489",
                "metrs": 1
            },
            {
                "size": "50х10",
                "koef": "0.009865",
                "metrs": 1
            },
            {
                "size": "50х11",
                "koef": "0.01058",
                "metrs": 1
            },
            {
                "size": "50х12",
                "koef": "0.01125",
                "metrs": 1
            },
            {
                "size": "51х0,8",
                "koef": "0.00099",
                "metrs": 1
            },
            {
                "size": "51х1",
                "koef": "0.001233",
                "metrs": 1
            },
            {
                "size": "51х1,2",
                "koef": "0.001474",
                "metrs": 1
            },
            {
                "size": "51х1,4",
                "koef": "0.001712",
                "metrs": 1
            },
            {
                "size": "51х1,5",
                "koef": "0.001831",
                "metrs": 1
            },
            {
                "size": "51х1,6",
                "koef": "0.001949",
                "metrs": 1
            },
            {
                "size": "51х1,8",
                "koef": "0.002184",
                "metrs": 1
            },
            {
                "size": "51х2",
                "koef": "0.002417",
                "metrs": 1
            },
            {
                "size": "51х2,2",
                "koef": "0.002648",
                "metrs": 1
            },
            {
                "size": "51х2,5",
                "koef": "0.00299",
                "metrs": 1
            },
            {
                "size": "51х2,8",
                "koef": "0.003328",
                "metrs": 1
            },
            {
                "size": "51х3",
                "koef": "0.003551",
                "metrs": 1
            },
            {
                "size": "51х3,2",
                "koef": "0.003772",
                "metrs": 1
            },
            {
                "size": "51х3,5",
                "koef": "0.0041",
                "metrs": 1
            },
            {
                "size": "51х4",
                "koef": "0.004636",
                "metrs": 1
            },
            {
                "size": "51х4,5",
                "koef": "0.00516",
                "metrs": 1
            },
            {
                "size": "51х5",
                "koef": "0.005672",
                "metrs": 1
            },
            {
                "size": "51х5,5",
                "koef": "0.006172",
                "metrs": 1
            },
            {
                "size": "51х6",
                "koef": "0.006659",
                "metrs": 1
            },
            {
                "size": "51х6,5",
                "koef": "0.007133",
                "metrs": 1
            },
            {
                "size": "51х7",
                "koef": "0.007596",
                "metrs": 1
            },
            {
                "size": "51х7,5",
                "koef": "0.008046",
                "metrs": 1
            },
            {
                "size": "51х8",
                "koef": "0.008484",
                "metrs": 1
            },
            {
                "size": "51х8,5",
                "koef": "0.008909",
                "metrs": 1
            },
            {
                "size": "51х9",
                "koef": "0.009322",
                "metrs": 1
            },
            {
                "size": "51х9,5",
                "koef": "0.009723",
                "metrs": 1
            },
            {
                "size": "51х10",
                "koef": "0.01011",
                "metrs": 1
            },
            {
                "size": "51х11",
                "koef": "0.01085",
                "metrs": 1
            },
            {
                "size": "51х12",
                "koef": "0.01154",
                "metrs": 1
            },
            {
                "size": "53х2,5",
                "koef": "0.003114",
                "metrs": 1
            },
            {
                "size": "53х2,8",
                "koef": "0.003466",
                "metrs": 1
            },
            {
                "size": "53х3",
                "koef": "0.003699",
                "metrs": 1
            },
            {
                "size": "53х3,2",
                "koef": "0.00393",
                "metrs": 1
            },
            {
                "size": "53х3,5",
                "koef": "0.004273",
                "metrs": 1
            },
            {
                "size": "53х4",
                "koef": "0.004834",
                "metrs": 1
            },
            {
                "size": "53х4,5",
                "koef": "0.005382",
                "metrs": 1
            },
            {
                "size": "53х5",
                "koef": "0.005919",
                "metrs": 1
            },
            {
                "size": "53х5,5",
                "koef": "0.006443",
                "metrs": 1
            },
            {
                "size": "53х6",
                "koef": "0.006955",
                "metrs": 1
            },
            {
                "size": "53х6,5",
                "koef": "0.007454",
                "metrs": 1
            },
            {
                "size": "53х7",
                "koef": "0.007941",
                "metrs": 1
            },
            {
                "size": "53х7,5",
                "koef": "0.008416",
                "metrs": 1
            },
            {
                "size": "53х8",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "53х8,5",
                "koef": "0.009328",
                "metrs": 1
            },
            {
                "size": "53х9",
                "koef": "0.009766",
                "metrs": 1
            },
            {
                "size": "53х9,5",
                "koef": "0.01019",
                "metrs": 1
            },
            {
                "size": "53х10",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": "53х11",
                "koef": "0.01139",
                "metrs": 1
            },
            {
                "size": "53х12",
                "koef": "0.01213",
                "metrs": 1
            },
            {
                "size": "54х0,8",
                "koef": "0.00105",
                "metrs": 1
            },
            {
                "size": "54х1",
                "koef": "0.001307",
                "metrs": 1
            },
            {
                "size": "54х1,2",
                "koef": "0.001563",
                "metrs": 1
            },
            {
                "size": "54х1,4",
                "koef": "0.001816",
                "metrs": 1
            },
            {
                "size": "54х1,5",
                "koef": "0.001942",
                "metrs": 1
            },
            {
                "size": "54х1,6",
                "koef": "0.002068",
                "metrs": 1
            },
            {
                "size": "54х1,8",
                "koef": "0.002317",
                "metrs": 1
            },
            {
                "size": "54х2",
                "koef": "0.002565",
                "metrs": 1
            },
            {
                "size": "54х2,2",
                "koef": "0.00281",
                "metrs": 1
            },
            {
                "size": "54х2,5",
                "koef": "0.003175",
                "metrs": 1
            },
            {
                "size": "54х2,8",
                "koef": "0.003535",
                "metrs": 1
            },
            {
                "size": "54х3",
                "koef": "0.003773",
                "metrs": 1
            },
            {
                "size": "54х3,2",
                "koef": "0.004009",
                "metrs": 1
            },
            {
                "size": "54х3,5",
                "koef": "0.004359",
                "metrs": 1
            },
            {
                "size": "54х4",
                "koef": "0.004932",
                "metrs": 1
            },
            {
                "size": "54х4,5",
                "koef": "0.005493",
                "metrs": 1
            },
            {
                "size": "54х5",
                "koef": "0.006042",
                "metrs": 1
            },
            {
                "size": "54х5,5",
                "koef": "0.006578",
                "metrs": 1
            },
            {
                "size": "54х6",
                "koef": "0.007103",
                "metrs": 1
            },
            {
                "size": "54х6,5",
                "koef": "0.007614",
                "metrs": 1
            },
            {
                "size": "54х7",
                "koef": "0.008114",
                "metrs": 1
            },
            {
                "size": "54х7,5",
                "koef": "0.008601",
                "metrs": 1
            },
            {
                "size": "54х8",
                "koef": "0.009075",
                "metrs": 1
            },
            {
                "size": "54х8,5",
                "koef": "0.009538",
                "metrs": 1
            },
            {
                "size": "54х9",
                "koef": "0.009988",
                "metrs": 1
            },
            {
                "size": "54х9,5",
                "koef": "0.01043",
                "metrs": 1
            },
            {
                "size": "54х10",
                "koef": "0.01085",
                "metrs": 1
            },
            {
                "size": "54х11",
                "koef": "0.01166",
                "metrs": 1
            },
            {
                "size": "54х12",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "56х0,8",
                "koef": "0.001089",
                "metrs": 1
            },
            {
                "size": "56х1",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": "56х1,2",
                "koef": "0.001622",
                "metrs": 1
            },
            {
                "size": "56х1,4",
                "koef": "0.001885",
                "metrs": 1
            },
            {
                "size": "56х1,5",
                "koef": "0.002016",
                "metrs": 1
            },
            {
                "size": "56х1,6",
                "koef": "0.002147",
                "metrs": 1
            },
            {
                "size": "56х1,8",
                "koef": "0.002406",
                "metrs": 1
            },
            {
                "size": "56х2",
                "koef": "0.002663",
                "metrs": 1
            },
            {
                "size": "56х2,2",
                "koef": "0.002919",
                "metrs": 1
            },
            {
                "size": "56х2,5",
                "koef": "0.003298",
                "metrs": 1
            },
            {
                "size": "56х2,8",
                "koef": "0.003674",
                "metrs": 1
            },
            {
                "size": "56х3",
                "koef": "0.003921",
                "metrs": 1
            },
            {
                "size": "56х3,2",
                "koef": "0.004167",
                "metrs": 1
            },
            {
                "size": "56х3,5",
                "koef": "0.004532",
                "metrs": 1
            },
            {
                "size": "56х4",
                "koef": "0.00513",
                "metrs": 1
            },
            {
                "size": "56х4,5",
                "koef": "0.005715",
                "metrs": 1
            },
            {
                "size": "56х5",
                "koef": "0.006289",
                "metrs": 1
            },
            {
                "size": "56х5,5",
                "koef": "0.00685",
                "metrs": 1
            },
            {
                "size": "56х6",
                "koef": "0.007398",
                "metrs": 1
            },
            {
                "size": "56х6,5",
                "koef": "0.007935",
                "metrs": 1
            },
            {
                "size": "56х7",
                "koef": "0.008459",
                "metrs": 1
            },
            {
                "size": "56х7,5",
                "koef": "0.008971",
                "metrs": 1
            },
            {
                "size": "56х8",
                "koef": "0.00947",
                "metrs": 1
            },
            {
                "size": "56х8,5",
                "koef": "0.009957",
                "metrs": 1
            },
            {
                "size": "56х9",
                "koef": "0.01043",
                "metrs": 1
            },
            {
                "size": "56х9,5",
                "koef": "0.01089",
                "metrs": 1
            },
            {
                "size": "56х10",
                "koef": "0.01134",
                "metrs": 1
            },
            {
                "size": "56х11",
                "koef": "0.01221",
                "metrs": 1
            },
            {
                "size": "56х12",
                "koef": "0.01302",
                "metrs": 1
            },
            {
                "size": "57х0,8",
                "koef": "0.001109",
                "metrs": 1
            },
            {
                "size": "57х1",
                "koef": "0.001381",
                "metrs": 1
            },
            {
                "size": "57х1,2",
                "koef": "0.001651",
                "metrs": 1
            },
            {
                "size": "57х1,4",
                "koef": "0.00192",
                "metrs": 1
            },
            {
                "size": "57х1,5",
                "koef": "0.002053",
                "metrs": 1
            },
            {
                "size": "57х1,6",
                "koef": "0.002186",
                "metrs": 1
            },
            {
                "size": "57х1,8",
                "koef": "0.00245",
                "metrs": 1
            },
            {
                "size": "57х2",
                "koef": "0.002713",
                "metrs": 1
            },
            {
                "size": "57х2,2",
                "koef": "0.002973",
                "metrs": 1
            },
            {
                "size": "57х2,5",
                "koef": "0.00336",
                "metrs": 1
            },
            {
                "size": "57х2,8",
                "koef": "0.003743",
                "metrs": 1
            },
            {
                "size": "57х3",
                "koef": "0.003995",
                "metrs": 1
            },
            {
                "size": "57х3,2",
                "koef": "0.004246",
                "metrs": 1
            },
            {
                "size": "57х3,5",
                "koef": "0.004618",
                "metrs": 1
            },
            {
                "size": "57х4",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": "57х4,5",
                "koef": "0.005826",
                "metrs": 1
            },
            {
                "size": "57х5",
                "koef": "0.006412",
                "metrs": 1
            },
            {
                "size": "57х5,5",
                "koef": "0.006985",
                "metrs": 1
            },
            {
                "size": "57х6",
                "koef": "0.007546",
                "metrs": 1
            },
            {
                "size": "57х6,5",
                "koef": "0.008095",
                "metrs": 1
            },
            {
                "size": "57х7",
                "koef": "0.008632",
                "metrs": 1
            },
            {
                "size": "57х7,5",
                "koef": "0.009156",
                "metrs": 1
            },
            {
                "size": "57х8",
                "koef": "0.009667",
                "metrs": 1
            },
            {
                "size": "57х8,5",
                "koef": "0.01017",
                "metrs": 1
            },
            {
                "size": "57х9",
                "koef": "0.01065",
                "metrs": 1
            },
            {
                "size": "57х9,5",
                "koef": "0.01113",
                "metrs": 1
            },
            {
                "size": "57х10",
                "koef": "0.01159",
                "metrs": 1
            },
            {
                "size": "57х11",
                "koef": "0.01248",
                "metrs": 1
            },
            {
                "size": "57х12",
                "koef": "0.01332",
                "metrs": 1
            },
            {
                "size": "60х0,8",
                "koef": "0.001168",
                "metrs": 1
            },
            {
                "size": "60х1",
                "koef": "0.001455",
                "metrs": 1
            },
            {
                "size": "60х1,2",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": "60х1,4",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": "60х1,5",
                "koef": "0.002164",
                "metrs": 1
            },
            {
                "size": "60х1,6",
                "koef": "0.002304",
                "metrs": 1
            },
            {
                "size": "60х1,8",
                "koef": "0.002584",
                "metrs": 1
            },
            {
                "size": "60х2",
                "koef": "0.002861",
                "metrs": 1
            },
            {
                "size": "60х2,2",
                "koef": "0.003136",
                "metrs": 1
            },
            {
                "size": "60х2,5",
                "koef": "0.003545",
                "metrs": 1
            },
            {
                "size": "60х2,8",
                "koef": "0.00395",
                "metrs": 1
            },
            {
                "size": "60х3",
                "koef": "0.004217",
                "metrs": 1
            },
            {
                "size": "60х3,2",
                "koef": "0.004482",
                "metrs": 1
            },
            {
                "size": "60х3,5",
                "koef": "0.004877",
                "metrs": 1
            },
            {
                "size": "60х4",
                "koef": "0.005524",
                "metrs": 1
            },
            {
                "size": "60х4,5",
                "koef": "0.006159",
                "metrs": 1
            },
            {
                "size": "60х5",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "60х5,5",
                "koef": "0.007392",
                "metrs": 1
            },
            {
                "size": "60х6",
                "koef": "0.00799",
                "metrs": 1
            },
            {
                "size": "60х6,5",
                "koef": "0.008576",
                "metrs": 1
            },
            {
                "size": "60х7",
                "koef": "0.009149",
                "metrs": 1
            },
            {
                "size": "60х7,5",
                "koef": "0.00971",
                "metrs": 1
            },
            {
                "size": "60х8",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": "60х8,5",
                "koef": "0.0108",
                "metrs": 1
            },
            {
                "size": "60х9",
                "koef": "0.01132",
                "metrs": 1
            },
            {
                "size": "60х9,5",
                "koef": "0.01183",
                "metrs": 1
            },
            {
                "size": "60х10",
                "koef": "0.01233",
                "metrs": 1
            },
            {
                "size": "60х11",
                "koef": "0.01329",
                "metrs": 1
            },
            {
                "size": "60х12",
                "koef": "0.01421",
                "metrs": 1
            },
            {
                "size": "63х1",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "63х1,2",
                "koef": "0.001829",
                "metrs": 1
            },
            {
                "size": "63х1,4",
                "koef": "0.002127",
                "metrs": 1
            },
            {
                "size": "63х1,5",
                "koef": "0.002275",
                "metrs": 1
            },
            {
                "size": "63х1,6",
                "koef": "0.002423",
                "metrs": 1
            },
            {
                "size": "63х1,8",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": "63х2",
                "koef": "0.003009",
                "metrs": 1
            },
            {
                "size": "63х2,2",
                "koef": "0.003299",
                "metrs": 1
            },
            {
                "size": "63х2,5",
                "koef": "0.00373",
                "metrs": 1
            },
            {
                "size": "63х2,8",
                "koef": "0.004157",
                "metrs": 1
            },
            {
                "size": "63х3",
                "koef": "0.004439",
                "metrs": 1
            },
            {
                "size": "63х3,2",
                "koef": "0.004719",
                "metrs": 1
            },
            {
                "size": "63х3,5",
                "koef": "0.005136",
                "metrs": 1
            },
            {
                "size": "63х4",
                "koef": "0.00582",
                "metrs": 1
            },
            {
                "size": "63х4,5",
                "koef": "0.006492",
                "metrs": 1
            },
            {
                "size": "63х5",
                "koef": "0.007152",
                "metrs": 1
            },
            {
                "size": "63х5,5",
                "koef": "0.007799",
                "metrs": 1
            },
            {
                "size": "63х6",
                "koef": "0.008434",
                "metrs": 1
            },
            {
                "size": "63х6,5",
                "koef": "0.009057",
                "metrs": 1
            },
            {
                "size": "63х7",
                "koef": "0.009667",
                "metrs": 1
            },
            {
                "size": "63х7,5",
                "koef": "0.01027",
                "metrs": 1
            },
            {
                "size": "63х8",
                "koef": "0.01085",
                "metrs": 1
            },
            {
                "size": "63х8,5",
                "koef": "0.01142",
                "metrs": 1
            },
            {
                "size": "63х9",
                "koef": "0.01199",
                "metrs": 1
            },
            {
                "size": "63х9,5",
                "koef": "0.01253",
                "metrs": 1
            },
            {
                "size": "63х10",
                "koef": "0.01307",
                "metrs": 1
            },
            {
                "size": "63х11",
                "koef": "0.01411",
                "metrs": 1
            },
            {
                "size": "63х12",
                "koef": "0.01509",
                "metrs": 1
            },
            {
                "size": "65х1",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "65х1,2",
                "koef": "0.001888",
                "metrs": 1
            },
            {
                "size": "65х1,4",
                "koef": "0.002196",
                "metrs": 1
            },
            {
                "size": "65х1,5",
                "koef": "0.002349",
                "metrs": 1
            },
            {
                "size": "65х1,6",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "65х1,8",
                "koef": "0.002805",
                "metrs": 1
            },
            {
                "size": "65х2",
                "koef": "0.003107",
                "metrs": 1
            },
            {
                "size": "65х2,2",
                "koef": "0.003407",
                "metrs": 1
            },
            {
                "size": "65х2,5",
                "koef": "0.003853",
                "metrs": 1
            },
            {
                "size": "65х2,8",
                "koef": "0.004295",
                "metrs": 1
            },
            {
                "size": "65х3",
                "koef": "0.004587",
                "metrs": 1
            },
            {
                "size": "65х3,2",
                "koef": "0.004877",
                "metrs": 1
            },
            {
                "size": "65х3,5",
                "koef": "0.005308",
                "metrs": 1
            },
            {
                "size": "65х4",
                "koef": "0.006017",
                "metrs": 1
            },
            {
                "size": "65х4,5",
                "koef": "0.006714",
                "metrs": 1
            },
            {
                "size": "65х5",
                "koef": "0.007398",
                "metrs": 1
            },
            {
                "size": "65х5,5",
                "koef": "0.00807",
                "metrs": 1
            },
            {
                "size": "65х6",
                "koef": "0.00873",
                "metrs": 1
            },
            {
                "size": "65х6,5",
                "koef": "0.009378",
                "metrs": 1
            },
            {
                "size": "65х7",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "65х7,5",
                "koef": "0.01064",
                "metrs": 1
            },
            {
                "size": "65х8",
                "koef": "0.01125",
                "metrs": 1
            },
            {
                "size": "65х8,5",
                "koef": "0.01184",
                "metrs": 1
            },
            {
                "size": "65х9",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "65х9,5",
                "koef": "0.013",
                "metrs": 1
            },
            {
                "size": "65х10",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": "65х11",
                "koef": "0.01465",
                "metrs": 1
            },
            {
                "size": "65х12",
                "koef": "0.01568",
                "metrs": 1
            },
            {
                "size": "68х0,8",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": "68х1",
                "koef": "0.001652",
                "metrs": 1
            },
            {
                "size": "68х1,2",
                "koef": "0.001977",
                "metrs": 1
            },
            {
                "size": "68х1,4",
                "koef": "0.002299",
                "metrs": 1
            },
            {
                "size": "68х1,5",
                "koef": "0.00246",
                "metrs": 1
            },
            {
                "size": "68х1,6",
                "koef": "0.00262",
                "metrs": 1
            },
            {
                "size": "68х1,8",
                "koef": "0.002939",
                "metrs": 1
            },
            {
                "size": "68х2",
                "koef": "0.003255",
                "metrs": 1
            },
            {
                "size": "68х2,2",
                "koef": "0.00357",
                "metrs": 1
            },
            {
                "size": "68х2,5",
                "koef": "0.004038",
                "metrs": 1
            },
            {
                "size": "68х2,8",
                "koef": "0.004502",
                "metrs": 1
            },
            {
                "size": "68х3",
                "koef": "0.004809",
                "metrs": 1
            },
            {
                "size": "68х3,2",
                "koef": "0.005114",
                "metrs": 1
            },
            {
                "size": "68х3,5",
                "koef": "0.005567",
                "metrs": 1
            },
            {
                "size": "68х4",
                "koef": "0.006313",
                "metrs": 1
            },
            {
                "size": "68х4,5",
                "koef": "0.007047",
                "metrs": 1
            },
            {
                "size": "68х5",
                "koef": "0.007768",
                "metrs": 1
            },
            {
                "size": "68х5,5",
                "koef": "0.008477",
                "metrs": 1
            },
            {
                "size": "68х6",
                "koef": "0.009174",
                "metrs": 1
            },
            {
                "size": "68х6,5",
                "koef": "0.009858",
                "metrs": 1
            },
            {
                "size": "68х7",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": "68х7,5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "68х8",
                "koef": "0.01184",
                "metrs": 1
            },
            {
                "size": "68х8,5",
                "koef": "0.01247",
                "metrs": 1
            },
            {
                "size": "68х9",
                "koef": "0.0131",
                "metrs": 1
            },
            {
                "size": "68х9,5",
                "koef": "0.01371",
                "metrs": 1
            },
            {
                "size": "68х10",
                "koef": "0.0143",
                "metrs": 1
            },
            {
                "size": "68х11",
                "koef": "0.01546",
                "metrs": 1
            },
            {
                "size": "68х12",
                "koef": "0.01657",
                "metrs": 1
            },
            {
                "size": "70х0,8",
                "koef": "0.001365",
                "metrs": 1
            },
            {
                "size": "70х1",
                "koef": "0.001702",
                "metrs": 1
            },
            {
                "size": "70х1,2",
                "koef": "0.002036",
                "metrs": 1
            },
            {
                "size": "70х1,4",
                "koef": "0.002368",
                "metrs": 1
            },
            {
                "size": "70х1,5",
                "koef": "0.002534",
                "metrs": 1
            },
            {
                "size": "70х1,6",
                "koef": "0.002699",
                "metrs": 1
            },
            {
                "size": "70х1,8",
                "koef": "0.003027",
                "metrs": 1
            },
            {
                "size": "70х2",
                "koef": "0.003354",
                "metrs": 1
            },
            {
                "size": "70х2,2",
                "koef": "0.003679",
                "metrs": 1
            },
            {
                "size": "70х2,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": "70х2,8",
                "koef": "0.00464",
                "metrs": 1
            },
            {
                "size": "70х3",
                "koef": "0.004957",
                "metrs": 1
            },
            {
                "size": "70х3,2",
                "koef": "0.005272",
                "metrs": 1
            },
            {
                "size": "70х3,5",
                "koef": "0.00574",
                "metrs": 1
            },
            {
                "size": "70х4",
                "koef": "0.006511",
                "metrs": 1
            },
            {
                "size": "70х4,5",
                "koef": "0.007269",
                "metrs": 1
            },
            {
                "size": "70х5",
                "koef": "0.008015",
                "metrs": 1
            },
            {
                "size": "70х5,5",
                "koef": "0.008749",
                "metrs": 1
            },
            {
                "size": "70х6",
                "koef": "0.00947",
                "metrs": 1
            },
            {
                "size": "70х6,5",
                "koef": "0.01018",
                "metrs": 1
            },
            {
                "size": "70х7",
                "koef": "0.01088",
                "metrs": 1
            },
            {
                "size": "70х7,5",
                "koef": "0.01156",
                "metrs": 1
            },
            {
                "size": "70х8",
                "koef": "0.01223",
                "metrs": 1
            },
            {
                "size": "70х8,5",
                "koef": "0.01289",
                "metrs": 1
            },
            {
                "size": "70х9",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "70х9,5",
                "koef": "0.01417",
                "metrs": 1
            },
            {
                "size": "70х10",
                "koef": "0.0148",
                "metrs": 1
            },
            {
                "size": "70х11",
                "koef": "0.01601",
                "metrs": 1
            },
            {
                "size": "70х12",
                "koef": "0.01716",
                "metrs": 1
            },
            {
                "size": "73х1,4",
                "koef": "0.002472",
                "metrs": 1
            },
            {
                "size": "73х1,5",
                "koef": "0.002645",
                "metrs": 1
            },
            {
                "size": "73х1,6",
                "koef": "0.002817",
                "metrs": 1
            },
            {
                "size": "73х1,8",
                "koef": "0.003161",
                "metrs": 1
            },
            {
                "size": "73х2",
                "koef": "0.003502",
                "metrs": 1
            },
            {
                "size": "73х2,2",
                "koef": "0.003841",
                "metrs": 1
            },
            {
                "size": "73х2,5",
                "koef": "0.004347",
                "metrs": 1
            },
            {
                "size": "73х2,8",
                "koef": "0.004847",
                "metrs": 1
            },
            {
                "size": "73х3",
                "koef": "0.005179",
                "metrs": 1
            },
            {
                "size": "73х3,2",
                "koef": "0.005508",
                "metrs": 1
            },
            {
                "size": "73х3,5",
                "koef": "0.005999",
                "metrs": 1
            },
            {
                "size": "73х4",
                "koef": "0.006807",
                "metrs": 1
            },
            {
                "size": "73х4,5",
                "koef": "0.007602",
                "metrs": 1
            },
            {
                "size": "73х5",
                "koef": "0.008385",
                "metrs": 1
            },
            {
                "size": "73х5,5",
                "koef": "0.009156",
                "metrs": 1
            },
            {
                "size": "73х6",
                "koef": "0.009914",
                "metrs": 1
            },
            {
                "size": "73х6,5",
                "koef": "0.01066",
                "metrs": 1
            },
            {
                "size": "73х7",
                "koef": "0.01139",
                "metrs": 1
            },
            {
                "size": "73х7,5",
                "koef": "0.01211",
                "metrs": 1
            },
            {
                "size": "73х8",
                "koef": "0.01282",
                "metrs": 1
            },
            {
                "size": "73х8,5",
                "koef": "0.01352",
                "metrs": 1
            },
            {
                "size": "73х9",
                "koef": "0.01421",
                "metrs": 1
            },
            {
                "size": "73х9,5",
                "koef": "0.01488",
                "metrs": 1
            },
            {
                "size": "73х10",
                "koef": "0.01554",
                "metrs": 1
            },
            {
                "size": "73х11",
                "koef": "0.01682",
                "metrs": 1
            },
            {
                "size": "73х12",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": "75х1,4",
                "koef": "0.002541",
                "metrs": 1
            },
            {
                "size": "75х2",
                "koef": "0.003601",
                "metrs": 1
            },
            {
                "size": "75х2,2",
                "koef": "0.00395",
                "metrs": 1
            },
            {
                "size": "75х2,5",
                "koef": "0.00447",
                "metrs": 1
            },
            {
                "size": "75х2,8",
                "koef": "0.004986",
                "metrs": 1
            },
            {
                "size": "75х3",
                "koef": "0.005327",
                "metrs": 1
            },
            {
                "size": "75х3,2",
                "koef": "0.005666",
                "metrs": 1
            },
            {
                "size": "75х3,5",
                "koef": "0.006172",
                "metrs": 1
            },
            {
                "size": "75х4",
                "koef": "0.007004",
                "metrs": 1
            },
            {
                "size": "75х4,5",
                "koef": "0.007824",
                "metrs": 1
            },
            {
                "size": "75х5",
                "koef": "0.008632",
                "metrs": 1
            },
            {
                "size": "75х5,5",
                "koef": "0.009427",
                "metrs": 1
            },
            {
                "size": "75х6",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": "75х6,5",
                "koef": "0.01098",
                "metrs": 1
            },
            {
                "size": "75х7",
                "koef": "0.01174",
                "metrs": 1
            },
            {
                "size": "75х7,5",
                "koef": "0.01248",
                "metrs": 1
            },
            {
                "size": "75х8",
                "koef": "0.01322",
                "metrs": 1
            },
            {
                "size": "75х8,5",
                "koef": "0.01394",
                "metrs": 1
            },
            {
                "size": "75х9",
                "koef": "0.01465",
                "metrs": 1
            },
            {
                "size": "75х9,5",
                "koef": "0.01535",
                "metrs": 1
            },
            {
                "size": "75х10",
                "koef": "0.01603",
                "metrs": 1
            },
            {
                "size": "75х11",
                "koef": "0.01736",
                "metrs": 1
            },
            {
                "size": "75х12",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": "76х1,5",
                "koef": "0.002756",
                "metrs": 1
            },
            {
                "size": "76х2",
                "koef": "0.00365",
                "metrs": 1
            },
            {
                "size": "76х2,2",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": "76х2,5",
                "koef": "0.004532",
                "metrs": 1
            },
            {
                "size": "76х2,8",
                "koef": "0.005055",
                "metrs": 1
            },
            {
                "size": "76х3",
                "koef": "0.005401",
                "metrs": 1
            },
            {
                "size": "76х3,2",
                "koef": "0.005745",
                "metrs": 1
            },
            {
                "size": "76х3,5",
                "koef": "0.006258",
                "metrs": 1
            },
            {
                "size": "76х4",
                "koef": "0.007103",
                "metrs": 1
            },
            {
                "size": "76х4,5",
                "koef": "0.007935",
                "metrs": 1
            },
            {
                "size": "76х5",
                "koef": "0.008755",
                "metrs": 1
            },
            {
                "size": "76х5,5",
                "koef": "0.009562",
                "metrs": 1
            },
            {
                "size": "76х6",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": "76х6,5",
                "koef": "0.01114",
                "metrs": 1
            },
            {
                "size": "76х7",
                "koef": "0.01191",
                "metrs": 1
            },
            {
                "size": "76х7,5",
                "koef": "0.01267",
                "metrs": 1
            },
            {
                "size": "76х8",
                "koef": "0.01342",
                "metrs": 1
            },
            {
                "size": "76х8,5",
                "koef": "0.01415",
                "metrs": 1
            },
            {
                "size": "76х9",
                "koef": "0.01487",
                "metrs": 1
            },
            {
                "size": "76х9,5",
                "koef": "0.01558",
                "metrs": 1
            },
            {
                "size": "76х10",
                "koef": "0.01628",
                "metrs": 1
            },
            {
                "size": "76х11",
                "koef": "0.01763",
                "metrs": 1
            },
            {
                "size": "76х12",
                "koef": "0.01894",
                "metrs": 1
            },
            {
                "size": "80х1,4",
                "koef": "0.002714",
                "metrs": 1
            },
            {
                "size": "80х2",
                "koef": "0.003847",
                "metrs": 1
            },
            {
                "size": "80х2,2",
                "koef": "0.004221",
                "metrs": 1
            },
            {
                "size": "80х2,5",
                "koef": "0.004778",
                "metrs": 1
            },
            {
                "size": "80х2,8",
                "koef": "0.005331",
                "metrs": 1
            },
            {
                "size": "80х3",
                "koef": "0.005697",
                "metrs": 1
            },
            {
                "size": "80х3,2",
                "koef": "0.006061",
                "metrs": 1
            },
            {
                "size": "80х3,5",
                "koef": "0.006603",
                "metrs": 1
            },
            {
                "size": "80х4",
                "koef": "0.007497",
                "metrs": 1
            },
            {
                "size": "80х4,5",
                "koef": "0.008379",
                "metrs": 1
            },
            {
                "size": "80х5",
                "koef": "0.009248",
                "metrs": 1
            },
            {
                "size": "80х5,5",
                "koef": "0.01011",
                "metrs": 1
            },
            {
                "size": "80х6",
                "koef": "0.01095",
                "metrs": 1
            },
            {
                "size": "80х6,5",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "80х7",
                "koef": "0.0126",
                "metrs": 1
            },
            {
                "size": "80х7,5",
                "koef": "0.01341",
                "metrs": 1
            },
            {
                "size": "80х8",
                "koef": "0.01421",
                "metrs": 1
            },
            {
                "size": "80х8,5",
                "koef": "0.01499",
                "metrs": 1
            },
            {
                "size": "80х9",
                "koef": "0.01576",
                "metrs": 1
            },
            {
                "size": "80х9,5",
                "koef": "0.01652",
                "metrs": 1
            },
            {
                "size": "80х10",
                "koef": "0.01726",
                "metrs": 1
            },
            {
                "size": "80х11",
                "koef": "0.01872",
                "metrs": 1
            },
            {
                "size": "80х12",
                "koef": "0.02012",
                "metrs": 1
            },
            {
                "size": "83х2",
                "koef": "0.003995",
                "metrs": 1
            },
            {
                "size": "83х2,2",
                "koef": "0.004384",
                "metrs": 1
            },
            {
                "size": "83х2,5",
                "koef": "0.004963",
                "metrs": 1
            },
            {
                "size": "83х2,8",
                "koef": "0.005538",
                "metrs": 1
            },
            {
                "size": "83х3",
                "koef": "0.005919",
                "metrs": 1
            },
            {
                "size": "83х3,2",
                "koef": "0.006298",
                "metrs": 1
            },
            {
                "size": "83х3,5",
                "koef": "0.006862",
                "metrs": 1
            },
            {
                "size": "83х4",
                "koef": "0.007793",
                "metrs": 1
            },
            {
                "size": "83х4,5",
                "koef": "0.008712",
                "metrs": 1
            },
            {
                "size": "83х5",
                "koef": "0.009618",
                "metrs": 1
            },
            {
                "size": "83х5,5",
                "koef": "0.01051",
                "metrs": 1
            },
            {
                "size": "83х6",
                "koef": "0.01139",
                "metrs": 1
            },
            {
                "size": "83х6,5",
                "koef": "0.01226",
                "metrs": 1
            },
            {
                "size": "83х7",
                "koef": "0.01312",
                "metrs": 1
            },
            {
                "size": "83х7,5",
                "koef": "0.01396",
                "metrs": 1
            },
            {
                "size": "83х8",
                "koef": "0.0148",
                "metrs": 1
            },
            {
                "size": "83х8,5",
                "koef": "0.01562",
                "metrs": 1
            },
            {
                "size": "83х9",
                "koef": "0.01642",
                "metrs": 1
            },
            {
                "size": "83х9,5",
                "koef": "0.01722",
                "metrs": 1
            },
            {
                "size": "83х10",
                "koef": "0.018",
                "metrs": 1
            },
            {
                "size": "83х11",
                "koef": "0.01953",
                "metrs": 1
            },
            {
                "size": "83х12",
                "koef": "0.02101",
                "metrs": 1
            },
            {
                "size": "83х14",
                "koef": "0.02382",
                "metrs": 1
            },
            {
                "size": "85х1,4",
                "koef": "0.002886",
                "metrs": 1
            },
            {
                "size": "85х2",
                "koef": "0.004094",
                "metrs": 1
            },
            {
                "size": "85х2,2",
                "koef": "0.004492",
                "metrs": 1
            },
            {
                "size": "85х2,5",
                "koef": "0.005086",
                "metrs": 1
            },
            {
                "size": "85х2,8",
                "koef": "0.005676",
                "metrs": 1
            },
            {
                "size": "85х3",
                "koef": "0.006067",
                "metrs": 1
            },
            {
                "size": "85х3,2",
                "koef": "0.006455",
                "metrs": 1
            },
            {
                "size": "85х3,5",
                "koef": "0.007035",
                "metrs": 1
            },
            {
                "size": "85х4",
                "koef": "0.00799",
                "metrs": 1
            },
            {
                "size": "85х4,5",
                "koef": "0.008934",
                "metrs": 1
            },
            {
                "size": "85х5",
                "koef": "0.009865",
                "metrs": 1
            },
            {
                "size": "85х5,5",
                "koef": "0.01078",
                "metrs": 1
            },
            {
                "size": "85х6",
                "koef": "0.01169",
                "metrs": 1
            },
            {
                "size": "85х6,5",
                "koef": "0.01258",
                "metrs": 1
            },
            {
                "size": "85х7",
                "koef": "0.01347",
                "metrs": 1
            },
            {
                "size": "85х7,5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "85х8",
                "koef": "0.01519",
                "metrs": 1
            },
            {
                "size": "85х8,5",
                "koef": "0.01604",
                "metrs": 1
            },
            {
                "size": "85х9",
                "koef": "0.01687",
                "metrs": 1
            },
            {
                "size": "85х9,5",
                "koef": "0.01769",
                "metrs": 1
            },
            {
                "size": "85х10",
                "koef": "0.0185",
                "metrs": 1
            },
            {
                "size": "85х11",
                "koef": "0.02007",
                "metrs": 1
            },
            {
                "size": "85х12",
                "koef": "0.0216",
                "metrs": 1
            },
            {
                "size": "89х2",
                "koef": "0.004291",
                "metrs": 1
            },
            {
                "size": "89х2,2",
                "koef": "0.004709",
                "metrs": 1
            },
            {
                "size": "89х2,5",
                "koef": "0.005333",
                "metrs": 1
            },
            {
                "size": "89х2,8",
                "koef": "0.005952",
                "metrs": 1
            },
            {
                "size": "89х3",
                "koef": "0.006363",
                "metrs": 1
            },
            {
                "size": "89х3,2",
                "koef": "0.006771",
                "metrs": 1
            },
            {
                "size": "89х3,5",
                "koef": "0.00738",
                "metrs": 1
            },
            {
                "size": "89х4",
                "koef": "0.008385",
                "metrs": 1
            },
            {
                "size": "89х4,5",
                "koef": "0.009378",
                "metrs": 1
            },
            {
                "size": "89х5",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": "89х5,5",
                "koef": "0.01133",
                "metrs": 1
            },
            {
                "size": "89х6",
                "koef": "0.01228",
                "metrs": 1
            },
            {
                "size": "89х6,5",
                "koef": "0.01322",
                "metrs": 1
            },
            {
                "size": "89х7",
                "koef": "0.01416",
                "metrs": 1
            },
            {
                "size": "89х7,5",
                "koef": "0.01507",
                "metrs": 1
            },
            {
                "size": "89х8",
                "koef": "0.01598",
                "metrs": 1
            },
            {
                "size": "89х8,5",
                "koef": "0.01687",
                "metrs": 1
            },
            {
                "size": "89х9",
                "koef": "0.01776",
                "metrs": 1
            },
            {
                "size": "89х9,5",
                "koef": "0.01863",
                "metrs": 1
            },
            {
                "size": "89х10",
                "koef": "0.01948",
                "metrs": 1
            },
            {
                "size": "89х11",
                "koef": "0.02116",
                "metrs": 1
            },
            {
                "size": "89х12",
                "koef": "0.02279",
                "metrs": 1
            },
            {
                "size": "90х2",
                "koef": "0.00434",
                "metrs": 1
            },
            {
                "size": "90х2,2",
                "koef": "0.004764",
                "metrs": 1
            },
            {
                "size": "90х2,5",
                "koef": "0.005395",
                "metrs": 1
            },
            {
                "size": "90х2,8",
                "koef": "0.006021",
                "metrs": 1
            },
            {
                "size": "90х3",
                "koef": "0.006437",
                "metrs": 1
            },
            {
                "size": "90х3,2",
                "koef": "0.00685",
                "metrs": 1
            },
            {
                "size": "90х3,5",
                "koef": "0.007466",
                "metrs": 1
            },
            {
                "size": "90х4",
                "koef": "0.008484",
                "metrs": 1
            },
            {
                "size": "90х4,5",
                "koef": "0.009489",
                "metrs": 1
            },
            {
                "size": "90х5",
                "koef": "0.01048",
                "metrs": 1
            },
            {
                "size": "90х5,5",
                "koef": "0.01146",
                "metrs": 1
            },
            {
                "size": "90х6",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "90х6,5",
                "koef": "0.01339",
                "metrs": 1
            },
            {
                "size": "90х7",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "90х7,5",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "90х8",
                "koef": "0.01618",
                "metrs": 1
            },
            {
                "size": "90х8,5",
                "koef": "0.01708",
                "metrs": 1
            },
            {
                "size": "90х9",
                "koef": "0.01798",
                "metrs": 1
            },
            {
                "size": "90х9,5",
                "koef": "0.01886",
                "metrs": 1
            },
            {
                "size": "90х10",
                "koef": "0.01973",
                "metrs": 1
            },
            {
                "size": "90х11",
                "koef": "0.02143",
                "metrs": 1
            },
            {
                "size": "90х12",
                "koef": "0.02308",
                "metrs": 1
            },
            {
                "size": "95х2",
                "koef": "0.004587",
                "metrs": 1
            },
            {
                "size": "95х2,2",
                "koef": "0.005035",
                "metrs": 1
            },
            {
                "size": "95х2,5",
                "koef": "0.005703",
                "metrs": 1
            },
            {
                "size": "95х2,8",
                "koef": "0.006367",
                "metrs": 1
            },
            {
                "size": "95х3",
                "koef": "0.006807",
                "metrs": 1
            },
            {
                "size": "95х3,2",
                "koef": "0.007245",
                "metrs": 1
            },
            {
                "size": "95х3,5",
                "koef": "0.007898",
                "metrs": 1
            },
            {
                "size": "95х4",
                "koef": "0.008977",
                "metrs": 1
            },
            {
                "size": "95х4,5",
                "koef": "0.01004",
                "metrs": 1
            },
            {
                "size": "95х5",
                "koef": "0.0111",
                "metrs": 1
            },
            {
                "size": "95х5,5",
                "koef": "0.01214",
                "metrs": 1
            },
            {
                "size": "95х6",
                "koef": "0.01317",
                "metrs": 1
            },
            {
                "size": "95х6,5",
                "koef": "0.01419",
                "metrs": 1
            },
            {
                "size": "95х7",
                "koef": "0.01519",
                "metrs": 1
            },
            {
                "size": "95х7,5",
                "koef": "0.01618",
                "metrs": 1
            },
            {
                "size": "95х8",
                "koef": "0.01716",
                "metrs": 1
            },
            {
                "size": "95х8,5",
                "koef": "0.01813",
                "metrs": 1
            },
            {
                "size": "95х9",
                "koef": "0.01909",
                "metrs": 1
            },
            {
                "size": "95х9,5",
                "koef": "0.02003",
                "metrs": 1
            },
            {
                "size": "95х10",
                "koef": "0.02096",
                "metrs": 1
            },
            {
                "size": "95х11",
                "koef": "0.02279",
                "metrs": 1
            },
            {
                "size": "95х12",
                "koef": "0.02456",
                "metrs": 1
            },
            {
                "size": "100х2",
                "koef": "0.004834",
                "metrs": 1
            },
            {
                "size": "100х2,2",
                "koef": "0.005306",
                "metrs": 1
            },
            {
                "size": "100х2,5",
                "koef": "0.006011",
                "metrs": 1
            },
            {
                "size": "100х2,8",
                "koef": "0.006712",
                "metrs": 1
            },
            {
                "size": "100х3",
                "koef": "0.007176",
                "metrs": 1
            },
            {
                "size": "100х3,2",
                "koef": "0.007639",
                "metrs": 1
            },
            {
                "size": "100х3,5",
                "koef": "0.008329",
                "metrs": 1
            },
            {
                "size": "100х4",
                "koef": "0.00947",
                "metrs": 1
            },
            {
                "size": "100х4,5",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": "100х5",
                "koef": "0.01171",
                "metrs": 1
            },
            {
                "size": "100х5,5",
                "koef": "0.01282",
                "metrs": 1
            },
            {
                "size": "100х6",
                "koef": "0.01391",
                "metrs": 1
            },
            {
                "size": "100х6,5",
                "koef": "0.01499",
                "metrs": 1
            },
            {
                "size": "100х7",
                "koef": "0.01605",
                "metrs": 1
            },
            {
                "size": "100х7,5",
                "koef": "0.01711",
                "metrs": 1
            },
            {
                "size": "100х8",
                "koef": "0.01815",
                "metrs": 1
            },
            {
                "size": "100х8,5",
                "koef": "0.01918",
                "metrs": 1
            },
            {
                "size": "100х9",
                "koef": "0.0202",
                "metrs": 1
            },
            {
                "size": "100х9,5",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "100х10",
                "koef": "0.0222",
                "metrs": 1
            },
            {
                "size": "100х11",
                "koef": "0.02414",
                "metrs": 1
            },
            {
                "size": "100х12",
                "koef": "0.02604",
                "metrs": 1
            },
            {
                "size": "102х2,2",
                "koef": "0.005415",
                "metrs": 1
            },
            {
                "size": "102х2,5",
                "koef": "0.006135",
                "metrs": 1
            },
            {
                "size": "102х2,8",
                "koef": "0.00685",
                "metrs": 1
            },
            {
                "size": "102х3",
                "koef": "0.007324",
                "metrs": 1
            },
            {
                "size": "102х3,2",
                "koef": "0.007797",
                "metrs": 1
            },
            {
                "size": "102х3,5",
                "koef": "0.008502",
                "metrs": 1
            },
            {
                "size": "102х4",
                "koef": "0.009667",
                "metrs": 1
            },
            {
                "size": "102х4,5",
                "koef": "0.01082",
                "metrs": 1
            },
            {
                "size": "102х5",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "102х5,5",
                "koef": "0.01309",
                "metrs": 1
            },
            {
                "size": "102х6",
                "koef": "0.01421",
                "metrs": 1
            },
            {
                "size": "102х6,5",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": "102х7",
                "koef": "0.0164",
                "metrs": 1
            },
            {
                "size": "102х7,5",
                "koef": "0.01748",
                "metrs": 1
            },
            {
                "size": "102х8",
                "koef": "0.01855",
                "metrs": 1
            },
            {
                "size": "102х8,5",
                "koef": "0.0196",
                "metrs": 1
            },
            {
                "size": "102х9",
                "koef": "0.02064",
                "metrs": 1
            },
            {
                "size": "102х9,5",
                "koef": "0.02167",
                "metrs": 1
            },
            {
                "size": "102х10",
                "koef": "0.02269",
                "metrs": 1
            },
            {
                "size": "102х11",
                "koef": "0.02469",
                "metrs": 1
            },
            {
                "size": "102х12",
                "koef": "0.02663",
                "metrs": 1
            },
            {
                "size": "108х2,5",
                "koef": "0.006504",
                "metrs": 1
            },
            {
                "size": "108х2,8",
                "koef": "0.007264",
                "metrs": 1
            },
            {
                "size": "108х3",
                "koef": "0.007768",
                "metrs": 1
            },
            {
                "size": "108х3,2",
                "koef": "0.00827",
                "metrs": 1
            },
            {
                "size": "108х3,5",
                "koef": "0.00902",
                "metrs": 1
            },
            {
                "size": "108х4",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": "108х4,5",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "108х5",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": "108х5,5",
                "koef": "0.0139",
                "metrs": 1
            },
            {
                "size": "108х6",
                "koef": "0.01509",
                "metrs": 1
            },
            {
                "size": "108х6,5",
                "koef": "0.01627",
                "metrs": 1
            },
            {
                "size": "108х7",
                "koef": "0.01744",
                "metrs": 1
            },
            {
                "size": "108х7,5",
                "koef": "0.01859",
                "metrs": 1
            },
            {
                "size": "108х8",
                "koef": "0.01973",
                "metrs": 1
            },
            {
                "size": "108х8,5",
                "koef": "0.02086",
                "metrs": 1
            },
            {
                "size": "108х9",
                "koef": "0.02197",
                "metrs": 1
            },
            {
                "size": "108х9,5",
                "koef": "0.02308",
                "metrs": 1
            },
            {
                "size": "108х10",
                "koef": "0.02417",
                "metrs": 1
            },
            {
                "size": "108х11",
                "koef": "0.02631",
                "metrs": 1
            },
            {
                "size": "108х12",
                "koef": "0.02841",
                "metrs": 1
            },
            {
                "size": "110х2",
                "koef": "0.005327",
                "metrs": 1
            },
            {
                "size": "110х2,2",
                "koef": "0.005849",
                "metrs": 1
            },
            {
                "size": "110х2,5",
                "koef": "0.006628",
                "metrs": 1
            },
            {
                "size": "110х2,8",
                "koef": "0.007402",
                "metrs": 1
            },
            {
                "size": "110х3",
                "koef": "0.007916",
                "metrs": 1
            },
            {
                "size": "110х3,2",
                "koef": "0.008428",
                "metrs": 1
            },
            {
                "size": "110х3,5",
                "koef": "0.009193",
                "metrs": 1
            },
            {
                "size": "110х4",
                "koef": "0.01046",
                "metrs": 1
            },
            {
                "size": "110х4,5",
                "koef": "0.01171",
                "metrs": 1
            },
            {
                "size": "110х5",
                "koef": "0.01295",
                "metrs": 1
            },
            {
                "size": "110х5,5",
                "koef": "0.01417",
                "metrs": 1
            },
            {
                "size": "110х6",
                "koef": "0.01539",
                "metrs": 1
            },
            {
                "size": "110х6,5",
                "koef": "0.01659",
                "metrs": 1
            },
            {
                "size": "110х7",
                "koef": "0.01778",
                "metrs": 1
            },
            {
                "size": "110х7,5",
                "koef": "0.01896",
                "metrs": 1
            },
            {
                "size": "110х8",
                "koef": "0.02012",
                "metrs": 1
            },
            {
                "size": "110х8,5",
                "koef": "0.02128",
                "metrs": 1
            },
            {
                "size": "110х9",
                "koef": "0.02242",
                "metrs": 1
            },
            {
                "size": "110х9,5",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "110х10",
                "koef": "0.02466",
                "metrs": 1
            },
            {
                "size": "110х11",
                "koef": "0.02686",
                "metrs": 1
            },
            {
                "size": "110х12",
                "koef": "0.029",
                "metrs": 1
            },
            {
                "size": "120х2",
                "koef": "0.00582",
                "metrs": 1
            },
            {
                "size": "120х2,2",
                "koef": "0.006391",
                "metrs": 1
            },
            {
                "size": "120х2,5",
                "koef": "0.007244",
                "metrs": 1
            },
            {
                "size": "120х2,8",
                "koef": "0.008093",
                "metrs": 1
            },
            {
                "size": "120х3",
                "koef": "0.008656",
                "metrs": 1
            },
            {
                "size": "120х3,2",
                "koef": "0.009217",
                "metrs": 1
            },
            {
                "size": "120х3,5",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "120х4",
                "koef": "0.01144",
                "metrs": 1
            },
            {
                "size": "120х4,5",
                "koef": "0.01282",
                "metrs": 1
            },
            {
                "size": "120х5",
                "koef": "0.01418",
                "metrs": 1
            },
            {
                "size": "120х5,5",
                "koef": "0.01553",
                "metrs": 1
            },
            {
                "size": "120х6",
                "koef": "0.01687",
                "metrs": 1
            },
            {
                "size": "120х6,5",
                "koef": "0.01819",
                "metrs": 1
            },
            {
                "size": "120х7",
                "koef": "0.01951",
                "metrs": 1
            },
            {
                "size": "120х7,5",
                "koef": "0.02081",
                "metrs": 1
            },
            {
                "size": "120х8",
                "koef": "0.0221",
                "metrs": 1
            },
            {
                "size": "120х8,5",
                "koef": "0.02337",
                "metrs": 1
            },
            {
                "size": "120х9",
                "koef": "0.02464",
                "metrs": 1
            },
            {
                "size": "120х9,5",
                "koef": "0.02589",
                "metrs": 1
            },
            {
                "size": "120х10",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "120х11",
                "koef": "0.02957",
                "metrs": 1
            },
            {
                "size": "120х12",
                "koef": "0.03196",
                "metrs": 1
            },
            {
                "size": "125х2",
                "koef": "0.006067",
                "metrs": 1
            },
            {
                "size": "125х2,2",
                "koef": "0.006663",
                "metrs": 1
            },
            {
                "size": "125х2,5",
                "koef": "0.007553",
                "metrs": 1
            },
            {
                "size": "125х2,8",
                "koef": "0.008438",
                "metrs": 1
            },
            {
                "size": "125х3",
                "koef": "0.009026",
                "metrs": 1
            },
            {
                "size": "125х3,2",
                "koef": "0.009612",
                "metrs": 1
            },
            {
                "size": "125х3,5",
                "koef": "0.01049",
                "metrs": 1
            },
            {
                "size": "125х4",
                "koef": "0.01194",
                "metrs": 1
            },
            {
                "size": "125х4,5",
                "koef": "0.01337",
                "metrs": 1
            },
            {
                "size": "125х5",
                "koef": "0.0148",
                "metrs": 1
            },
            {
                "size": "125х5,5",
                "koef": "0.01621",
                "metrs": 1
            },
            {
                "size": "125х6",
                "koef": "0.01761",
                "metrs": 1
            },
            {
                "size": "125х6,5",
                "koef": "0.019",
                "metrs": 1
            },
            {
                "size": "125х7",
                "koef": "0.02037",
                "metrs": 1
            },
            {
                "size": "125х7,5",
                "koef": "0.02173",
                "metrs": 1
            },
            {
                "size": "125х8",
                "koef": "0.02308",
                "metrs": 1
            },
            {
                "size": "125х8,5",
                "koef": "0.02442",
                "metrs": 1
            },
            {
                "size": "125х9",
                "koef": "0.02575",
                "metrs": 1
            },
            {
                "size": "125х9,5",
                "koef": "0.02706",
                "metrs": 1
            },
            {
                "size": "125х10",
                "koef": "0.02836",
                "metrs": 1
            },
            {
                "size": "125х11",
                "koef": "0.03093",
                "metrs": 1
            },
            {
                "size": "125х12",
                "koef": "0.03344",
                "metrs": 1
            },
            {
                "size": "130х2,5",
                "koef": "0.007861",
                "metrs": 1
            },
            {
                "size": "130х2,8",
                "koef": "0.008783",
                "metrs": 1
            },
            {
                "size": "130х3",
                "koef": "0.009396",
                "metrs": 1
            },
            {
                "size": "130х3,2",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "130х3,5",
                "koef": "0.01092",
                "metrs": 1
            },
            {
                "size": "130х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "130х4,5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "130х5",
                "koef": "0.01541",
                "metrs": 1
            },
            {
                "size": "130х5,5",
                "koef": "0.01689",
                "metrs": 1
            },
            {
                "size": "130х6",
                "koef": "0.01835",
                "metrs": 1
            },
            {
                "size": "130х6,5",
                "koef": "0.0198",
                "metrs": 1
            },
            {
                "size": "130х7",
                "koef": "0.02123",
                "metrs": 1
            },
            {
                "size": "130х7,5",
                "koef": "0.02266",
                "metrs": 1
            },
            {
                "size": "130х8",
                "koef": "0.02407",
                "metrs": 1
            },
            {
                "size": "130х8,5",
                "koef": "0.02547",
                "metrs": 1
            },
            {
                "size": "130х9",
                "koef": "0.02686",
                "metrs": 1
            },
            {
                "size": "130х9,5",
                "koef": "0.02823",
                "metrs": 1
            },
            {
                "size": "130х10",
                "koef": "0.02959",
                "metrs": 1
            },
            {
                "size": "130х11",
                "koef": "0.03228",
                "metrs": 1
            },
            {
                "size": "130х12",
                "koef": "0.03492",
                "metrs": 1
            },
            {
                "size": "133х2",
                "koef": "0.006461",
                "metrs": 1
            },
            {
                "size": "133х2,2",
                "koef": "0.007097",
                "metrs": 1
            },
            {
                "size": "133х2,5",
                "koef": "0.008046",
                "metrs": 1
            },
            {
                "size": "133х2,8",
                "koef": "0.008991",
                "metrs": 1
            },
            {
                "size": "133х3",
                "koef": "0.009618",
                "metrs": 1
            },
            {
                "size": "133х3,2",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "133х3,5",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "133х4",
                "koef": "0.01273",
                "metrs": 1
            },
            {
                "size": "133х4,5",
                "koef": "0.01426",
                "metrs": 1
            },
            {
                "size": "133х5",
                "koef": "0.01578",
                "metrs": 1
            },
            {
                "size": "133х5,5",
                "koef": "0.01729",
                "metrs": 1
            },
            {
                "size": "133х6",
                "koef": "0.01879",
                "metrs": 1
            },
            {
                "size": "133х6,5",
                "koef": "0.02028",
                "metrs": 1
            },
            {
                "size": "133х7",
                "koef": "0.02175",
                "metrs": 1
            },
            {
                "size": "133х7,5",
                "koef": "0.02321",
                "metrs": 1
            },
            {
                "size": "133х8",
                "koef": "0.02466",
                "metrs": 1
            },
            {
                "size": "133х8,5",
                "koef": "0.0261",
                "metrs": 1
            },
            {
                "size": "133х9",
                "koef": "0.02752",
                "metrs": 1
            },
            {
                "size": "133х9,5",
                "koef": "0.02893",
                "metrs": 1
            },
            {
                "size": "133х10",
                "koef": "0.03033",
                "metrs": 1
            },
            {
                "size": "133х11",
                "koef": "0.0331",
                "metrs": 1
            },
            {
                "size": "133х12",
                "koef": "0.03581",
                "metrs": 1
            },
            {
                "size": "140х3",
                "koef": "0.01014",
                "metrs": 1
            },
            {
                "size": "140х3,2",
                "koef": "0.0108",
                "metrs": 1
            },
            {
                "size": "140х3,5",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "140х4",
                "koef": "0.01342",
                "metrs": 1
            },
            {
                "size": "140х4,5",
                "koef": "0.01504",
                "metrs": 1
            },
            {
                "size": "140х5",
                "koef": "0.01665",
                "metrs": 1
            },
            {
                "size": "140х5,5",
                "koef": "0.01824",
                "metrs": 1
            },
            {
                "size": "140х6",
                "koef": "0.01983",
                "metrs": 1
            },
            {
                "size": "140х6,5",
                "koef": "0.0214",
                "metrs": 1
            },
            {
                "size": "140х7",
                "koef": "0.02296",
                "metrs": 1
            },
            {
                "size": "140х7,5",
                "koef": "0.02451",
                "metrs": 1
            },
            {
                "size": "140х8",
                "koef": "0.02604",
                "metrs": 1
            },
            {
                "size": "140х8,5",
                "koef": "0.02757",
                "metrs": 1
            },
            {
                "size": "140х9",
                "koef": "0.02908",
                "metrs": 1
            },
            {
                "size": "140х9,5",
                "koef": "0.03057",
                "metrs": 1
            },
            {
                "size": "140х10",
                "koef": "0.03206",
                "metrs": 1
            },
            {
                "size": "140х11",
                "koef": "0.03499",
                "metrs": 1
            },
            {
                "size": "140х12",
                "koef": "0.03788",
                "metrs": 1
            },
            {
                "size": "146х16",
                "koef": "0.0513",
                "metrs": 1
            },
            {
                "size": "150х3",
                "koef": "0.01088",
                "metrs": 1
            },
            {
                "size": "150х3,2",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "150х3,5",
                "koef": "0.01265",
                "metrs": 1
            },
            {
                "size": "150х4",
                "koef": "0.0144",
                "metrs": 1
            },
            {
                "size": "150х4,5",
                "koef": "0.01615",
                "metrs": 1
            },
            {
                "size": "150х5",
                "koef": "0.01788",
                "metrs": 1
            },
            {
                "size": "150х5,5",
                "koef": "0.0196",
                "metrs": 1
            },
            {
                "size": "150х6",
                "koef": "0.02131",
                "metrs": 1
            },
            {
                "size": "150х6,5",
                "koef": "0.023",
                "metrs": 1
            },
            {
                "size": "150х7",
                "koef": "0.02469",
                "metrs": 1
            },
            {
                "size": "150х7,5",
                "koef": "0.02636",
                "metrs": 1
            },
            {
                "size": "150х8",
                "koef": "0.02802",
                "metrs": 1
            },
            {
                "size": "150х8,5",
                "koef": "0.02966",
                "metrs": 1
            },
            {
                "size": "150х9",
                "koef": "0.0313",
                "metrs": 1
            },
            {
                "size": "150х9,5",
                "koef": "0.03292",
                "metrs": 1
            },
            {
                "size": "150х10",
                "koef": "0.03453",
                "metrs": 1
            },
            {
                "size": "150х11",
                "koef": "0.03771",
                "metrs": 1
            },
            {
                "size": "150х12",
                "koef": "0.04084",
                "metrs": 1
            },
            {
                "size": "152х10",
                "koef": "0.03502",
                "metrs": 1
            },
            {
                "size": "160х3",
                "koef": "0.01162",
                "metrs": 1
            },
            {
                "size": "160х3,2",
                "koef": "0.01237",
                "metrs": 1
            },
            {
                "size": "160х3,5",
                "koef": "0.01351",
                "metrs": 1
            },
            {
                "size": "160х4",
                "koef": "0.01539",
                "metrs": 1
            },
            {
                "size": "160х4,5",
                "koef": "0.01726",
                "metrs": 1
            },
            {
                "size": "160х5",
                "koef": "0.01911",
                "metrs": 1
            },
            {
                "size": "160х5,5",
                "koef": "0.02096",
                "metrs": 1
            },
            {
                "size": "160х6",
                "koef": "0.02279",
                "metrs": 1
            },
            {
                "size": "160х6,5",
                "koef": "0.02461",
                "metrs": 1
            },
            {
                "size": "160х7",
                "koef": "0.02641",
                "metrs": 1
            },
            {
                "size": "160х7,5",
                "koef": "0.02821",
                "metrs": 1
            },
            {
                "size": "160х8",
                "koef": "0.02999",
                "metrs": 1
            },
            {
                "size": "160х8,5",
                "koef": "0.03176",
                "metrs": 1
            },
            {
                "size": "160х9",
                "koef": "0.03351",
                "metrs": 1
            },
            {
                "size": "160х9,5",
                "koef": "0.03526",
                "metrs": 1
            },
            {
                "size": "160х10",
                "koef": "0.03699",
                "metrs": 1
            },
            {
                "size": "160х11",
                "koef": "0.04042",
                "metrs": 1
            },
            {
                "size": "160х12",
                "koef": "0.0438",
                "metrs": 1
            },
            {
                "size": "170х3",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "170х3,2",
                "koef": "0.01316",
                "metrs": 1
            },
            {
                "size": "170х3,5",
                "koef": "0.01437",
                "metrs": 1
            },
            {
                "size": "170х4",
                "koef": "0.01638",
                "metrs": 1
            },
            {
                "size": "170х4,5",
                "koef": "0.01837",
                "metrs": 1
            },
            {
                "size": "170х5",
                "koef": "0.02035",
                "metrs": 1
            },
            {
                "size": "170х5,5",
                "koef": "0.02231",
                "metrs": 1
            },
            {
                "size": "170х6",
                "koef": "0.02427",
                "metrs": 1
            },
            {
                "size": "170х6,5",
                "koef": "0.02621",
                "metrs": 1
            },
            {
                "size": "170х7",
                "koef": "0.02814",
                "metrs": 1
            },
            {
                "size": "170х7,5",
                "koef": "0.03006",
                "metrs": 1
            },
            {
                "size": "170х8",
                "koef": "0.03196",
                "metrs": 1
            },
            {
                "size": "170х8,5",
                "koef": "0.03385",
                "metrs": 1
            },
            {
                "size": "170х9",
                "koef": "0.03573",
                "metrs": 1
            },
            {
                "size": "170х9,5",
                "koef": "0.0376",
                "metrs": 1
            },
            {
                "size": "170х10",
                "koef": "0.03946",
                "metrs": 1
            },
            {
                "size": "170х11",
                "koef": "0.04313",
                "metrs": 1
            },
            {
                "size": "170х12",
                "koef": "0.04676",
                "metrs": 1
            },
            {
                "size": "180х3",
                "koef": "0.0131",
                "metrs": 1
            },
            {
                "size": "180х3,2",
                "koef": "0.01395",
                "metrs": 1
            },
            {
                "size": "180х3,5",
                "koef": "0.01523",
                "metrs": 1
            },
            {
                "size": "180х4",
                "koef": "0.01736",
                "metrs": 1
            },
            {
                "size": "180х4,5",
                "koef": "0.01948",
                "metrs": 1
            },
            {
                "size": "180х5",
                "koef": "0.02158",
                "metrs": 1
            },
            {
                "size": "180х5,5",
                "koef": "0.02367",
                "metrs": 1
            },
            {
                "size": "180х6",
                "koef": "0.02575",
                "metrs": 1
            },
            {
                "size": "180х6,5",
                "koef": "0.02781",
                "metrs": 1
            },
            {
                "size": "180х7",
                "koef": "0.02987",
                "metrs": 1
            },
            {
                "size": "180х7,5",
                "koef": "0.03191",
                "metrs": 1
            },
            {
                "size": "180х8",
                "koef": "0.03393",
                "metrs": 1
            },
            {
                "size": "180х8,5",
                "koef": "0.03595",
                "metrs": 1
            },
            {
                "size": "180х9",
                "koef": "0.03795",
                "metrs": 1
            },
            {
                "size": "180х9,5",
                "koef": "0.03995",
                "metrs": 1
            },
            {
                "size": "180х10",
                "koef": "0.04192",
                "metrs": 1
            },
            {
                "size": "180х11",
                "koef": "0.04585",
                "metrs": 1
            },
            {
                "size": "180х12",
                "koef": "0.04972",
                "metrs": 1
            },
            {
                "size": "193х6",
                "koef": "0.02767",
                "metrs": 1
            },
            {
                "size": "200х4",
                "koef": "0.01933",
                "metrs": 1
            },
            {
                "size": "200х5",
                "koef": "0.02404",
                "metrs": 1
            },
            {
                "size": "200х6",
                "koef": "0.02871",
                "metrs": 1
            },
            {
                "size": "200х8",
                "koef": "0.03788",
                "metrs": 1
            },
            {
                "size": "200х10",
                "koef": "0.04686",
                "metrs": 1
            },
            {
                "size": "200х12",
                "koef": "0.05564",
                "metrs": 1
            },
            {
                "size": "220х4",
                "koef": "0.02131",
                "metrs": 1
            },
            {
                "size": "220х5",
                "koef": "0.02651",
                "metrs": 1
            },
            {
                "size": "220х6",
                "koef": "0.03167",
                "metrs": 1
            },
            {
                "size": "220х8",
                "koef": "0.04183",
                "metrs": 1
            },
            {
                "size": "220х10",
                "koef": "0.05179",
                "metrs": 1
            },
            {
                "size": "220х12",
                "koef": "0.06156",
                "metrs": 1
            },
            {
                "size": "245х15",
                "koef": "0.08508",
                "metrs": 1
            },
            {
                "size": "250х5",
                "koef": "0.03021",
                "metrs": 1
            },
            {
                "size": "250х6",
                "koef": "0.0361",
                "metrs": 1
            },
            {
                "size": "250х8",
                "koef": "0.04774",
                "metrs": 1
            },
            {
                "size": "250х10",
                "koef": "0.05919",
                "metrs": 1
            },
            {
                "size": "250х12",
                "koef": "0.07043",
                "metrs": 1
            },
            {
                "size": "250х16",
                "koef": "0.09233",
                "metrs": 1
            }
        ],
        "Труба профильная квадратная": [
            {
                "size": " 8х8х0,5",
                "koef": "0.000114",
                "metrs": 1
            },
            {
                "size": " 8х8х0,6",
                "koef": "0.000135",
                "metrs": 1
            },
            {
                "size": " 8х8х0,7",
                "koef": "0.000154",
                "metrs": 1
            },
            {
                "size": " 8х8х0,8",
                "koef": "0.000172",
                "metrs": 1
            },
            {
                "size": " 8х8х0,9",
                "koef": "0.00019",
                "metrs": 1
            },
            {
                "size": " 8х8х1",
                "koef": "0.000206",
                "metrs": 1
            },
            {
                "size": " 8х8х1,1",
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": " 8х8х1,2",
                "koef": "0.000237",
                "metrs": 1
            },
            {
                "size": " 8х8х1,3",
                "koef": "0.000251",
                "metrs": 1
            },
            {
                "size": " 8х8х1,5",
                "koef": "0.000276",
                "metrs": 1
            },
            {
                "size": " 10х10х0,5",
                "koef": "0.000146",
                "metrs": 1
            },
            {
                "size": " 10х10х0,6",
                "koef": "0.000172",
                "metrs": 1
            },
            {
                "size": " 10х10х0,7",
                "koef": "0.000198",
                "metrs": 1
            },
            {
                "size": " 10х10х0,8",
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": " 10х10х0,9",
                "koef": "0.000246",
                "metrs": 1
            },
            {
                "size": " 10х10х1",
                "koef": "0.000269",
                "metrs": 1
            },
            {
                "size": " 10х10х1,1",
                "koef": "0.000291",
                "metrs": 1
            },
            {
                "size": " 10х10х1,2",
                "koef": "0.000312",
                "metrs": 1
            },
            {
                "size": " 10х10х1,3",
                "koef": "0.000332",
                "metrs": 1
            },
            {
                "size": " 10х10х1,5",
                "koef": "0.00037",
                "metrs": 1
            },
            {
                "size": " 12х12х0,5",
                "koef": "0.000177",
                "metrs": 1
            },
            {
                "size": " 12х12х0,6",
                "koef": "0.00021",
                "metrs": 1
            },
            {
                "size": " 12х12х0,7",
                "koef": "0.000242",
                "metrs": 1
            },
            {
                "size": " 12х12х0,8",
                "koef": "0.000273",
                "metrs": 1
            },
            {
                "size": " 12х12х0,9",
                "koef": "0.000303",
                "metrs": 1
            },
            {
                "size": " 12х12х1",
                "koef": "0.000332",
                "metrs": 1
            },
            {
                "size": " 12х12х1,1",
                "koef": "0.00036",
                "metrs": 1
            },
            {
                "size": " 12х12х1,2",
                "koef": "0.000388",
                "metrs": 1
            },
            {
                "size": " 12х12х1,3",
                "koef": "0.000414",
                "metrs": 1
            },
            {
                "size": " 12х12х1,5",
                "koef": "0.000464",
                "metrs": 1
            },
            {
                "size": " 15х15х0,5",
                "koef": "0.000224",
                "metrs": 1
            },
            {
                "size": " 15х15х0,6",
                "koef": "0.000266",
                "metrs": 1
            },
            {
                "size": " 15х15х0,7",
                "koef": "0.000308",
                "metrs": 1
            },
            {
                "size": " 15х15х0,8",
                "koef": "0.000348",
                "metrs": 1
            },
            {
                "size": " 15х15х0,9",
                "koef": "0.000388",
                "metrs": 1
            },
            {
                "size": " 15х15х1",
                "koef": "0.000426",
                "metrs": 1
            },
            {
                "size": " 15х15х1,1",
                "koef": "0.000464",
                "metrs": 1
            },
            {
                "size": " 15х15х1,2",
                "koef": "0.000501",
                "metrs": 1
            },
            {
                "size": " 15х15х1,3",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": " 15х15х1,35",
                "koef": "0.000554",
                "metrs": 1
            },
            {
                "size": " 15х15х1,5",
                "koef": "0.000605",
                "metrs": 1
            },
            {
                "size": " 15х15х1,8",
                "koef": "0.000702",
                "metrs": 1
            },
            {
                "size": " 15х15х2",
                "koef": "0.000762",
                "metrs": 1
            },
            {
                "size": " 18х18х0,4",
                "koef": "0.000219",
                "metrs": 1
            },
            {
                "size": " 18х18х0,5",
                "koef": "0.000271",
                "metrs": 1
            },
            {
                "size": " 18х18х0,6",
                "koef": "0.000323",
                "metrs": 1
            },
            {
                "size": " 18х18х0,7",
                "koef": "0.000374",
                "metrs": 1
            },
            {
                "size": " 18х18х0,8",
                "koef": "0.000423",
                "metrs": 1
            },
            {
                "size": " 18х18х0,9",
                "koef": "0.000472",
                "metrs": 1
            },
            {
                "size": " 18х18х1",
                "koef": "0.00052",
                "metrs": 1
            },
            {
                "size": " 18х18х1,1",
                "koef": "0.000567",
                "metrs": 1
            },
            {
                "size": " 18х18х1,2",
                "koef": "0.000614",
                "metrs": 1
            },
            {
                "size": " 18х18х1,3",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": " 18х18х1,5",
                "koef": "0.000747",
                "metrs": 1
            },
            {
                "size": " 18х18х2",
                "koef": "0.000951",
                "metrs": 1
            },
            {
                "size": " 20х20х0,4",
                "koef": "0.000244",
                "metrs": 1
            },
            {
                "size": " 20х20х0,5",
                "koef": "0.000303",
                "metrs": 1
            },
            {
                "size": " 20х20х0,6",
                "koef": "0.000361",
                "metrs": 1
            },
            {
                "size": " 20х20х0,7",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": " 20х20х0,8",
                "koef": "0.000474",
                "metrs": 1
            },
            {
                "size": " 20х20х0,9",
                "koef": "0.000529",
                "metrs": 1
            },
            {
                "size": " 20х20х1",
                "koef": "0.000583",
                "metrs": 1
            },
            {
                "size": " 20х20х1,1",
                "koef": "0.000636",
                "metrs": 1
            },
            {
                "size": " 20х20х1,2",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": " 20х20х1,3",
                "koef": "0.000741",
                "metrs": 1
            },
            {
                "size": " 20х20х1,35",
                "koef": "0.000766",
                "metrs": 1
            },
            {
                "size": " 20х20х1,4",
                "koef": "0.000791",
                "metrs": 1
            },
            {
                "size": " 20х20х1,5",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 20х20х1,7",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 20х20х1,8",
                "koef": "0.000985",
                "metrs": 1
            },
            {
                "size": " 20х20х2",
                "koef": "0.001075",
                "metrs": 1
            },
            {
                "size": " 20х20х2,5",
                "koef": "0.001289",
                "metrs": 1
            },
            {
                "size": " 25х25х0,4",
                "koef": "0.000307",
                "metrs": 1
            },
            {
                "size": " 25х25х0,5",
                "koef": "0.000381",
                "metrs": 1
            },
            {
                "size": " 25х25х0,6",
                "koef": "0.000455",
                "metrs": 1
            },
            {
                "size": " 25х25х0,7",
                "koef": "0.000527",
                "metrs": 1
            },
            {
                "size": " 25х25х0,8",
                "koef": "0.000599",
                "metrs": 1
            },
            {
                "size": " 25х25х0,9",
                "koef": "0.00067",
                "metrs": 1
            },
            {
                "size": " 25х25х1",
                "koef": "0.00074",
                "metrs": 1
            },
            {
                "size": " 25х25х1,1",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 25х25х1,2",
                "koef": "0.000878",
                "metrs": 1
            },
            {
                "size": " 25х25х1,3",
                "koef": "0.000945",
                "metrs": 1
            },
            {
                "size": " 25х25х1,35",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": " 25х25х1,4",
                "koef": "0.00101",
                "metrs": 1
            },
            {
                "size": " 25х25х1,5",
                "koef": "0.00107",
                "metrs": 1
            },
            {
                "size": " 25х25х1,8",
                "koef": "0.001268",
                "metrs": 1
            },
            {
                "size": " 25х25х2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 25х25х2,5",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 25х25х3",
                "koef": "0.00195",
                "metrs": 1
            },
            {
                "size": " 30х30х0,5",
                "koef": "0.00046",
                "metrs": 1
            },
            {
                "size": " 30х30х0,6",
                "koef": "0.000549",
                "metrs": 1
            },
            {
                "size": " 30х30х0,7",
                "koef": "0.000637",
                "metrs": 1
            },
            {
                "size": " 30х30х0,8",
                "koef": "0.000725",
                "metrs": 1
            },
            {
                "size": " 30х30х0,9",
                "koef": "0.000811",
                "metrs": 1
            },
            {
                "size": " 30х30х1",
                "koef": "0.000897",
                "metrs": 1
            },
            {
                "size": " 30х30х1,1",
                "koef": "0.000982",
                "metrs": 1
            },
            {
                "size": " 30х30х1,2",
                "koef": "0.00107",
                "metrs": 1
            },
            {
                "size": " 30х30х1,3",
                "koef": "0.00115",
                "metrs": 1
            },
            {
                "size": " 30х30х1,35",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 30х30х1,4",
                "koef": "0.00123",
                "metrs": 1
            },
            {
                "size": " 30х30х1,5",
                "koef": "0.00131",
                "metrs": 1
            },
            {
                "size": " 30х30х1,8",
                "koef": "0.00155",
                "metrs": 1
            },
            {
                "size": " 30х30х2",
                "koef": "0.0017",
                "metrs": 1
            },
            {
                "size": " 30х30х2,5",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 30х30х3",
                "koef": "0.00242",
                "metrs": 1
            },
            {
                "size": " 30х30х4",
                "koef": "0.00304",
                "metrs": 1
            },
            {
                "size": " 35х35х2",
                "koef": "0.00202",
                "metrs": 1
            },
            {
                "size": " 40х40х1",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 40х40х1,2",
                "koef": "0.001443",
                "metrs": 1
            },
            {
                "size": " 40х40х1,35",
                "koef": "0.001614",
                "metrs": 1
            },
            {
                "size": " 40х40х1,5",
                "koef": "0.00178",
                "metrs": 1
            },
            {
                "size": " 40х40х1,8",
                "koef": "0.002115",
                "metrs": 1
            },
            {
                "size": " 40х40х2",
                "koef": "0.00233",
                "metrs": 1
            },
            {
                "size": " 40х40х2,5",
                "koef": "0.00285",
                "metrs": 1
            },
            {
                "size": " 40х40х2,8",
                "koef": "0.003165",
                "metrs": 1
            },
            {
                "size": " 40х40х3",
                "koef": "0.00336",
                "metrs": 1
            },
            {
                "size": " 40х40х3,5",
                "koef": "0.00385",
                "metrs": 1
            },
            {
                "size": " 40х40х4",
                "koef": "0.0043",
                "metrs": 1
            },
            {
                "size": " 50х50х1",
                "koef": "0.001525",
                "metrs": 1
            },
            {
                "size": " 50х50х1,2",
                "koef": "0.001819",
                "metrs": 1
            },
            {
                "size": " 50х50х1,3",
                "koef": "0.001965",
                "metrs": 1
            },
            {
                "size": " 50х50х1,5",
                "koef": "0.002254",
                "metrs": 1
            },
            {
                "size": " 50х50х1,8",
                "koef": "0.002681",
                "metrs": 1
            },
            {
                "size": " 50х50х2",
                "koef": "0.00296",
                "metrs": 1
            },
            {
                "size": " 50х50х2,5",
                "koef": "0.00364",
                "metrs": 1
            },
            {
                "size": " 50х50х2,8",
                "koef": "0.004044",
                "metrs": 1
            },
            {
                "size": " 50х50х3",
                "koef": "0.00431",
                "metrs": 1
            },
            {
                "size": " 50х50х3,5",
                "koef": "0.00494",
                "metrs": 1
            },
            {
                "size": " 50х50х3,7",
                "koef": "0.005194",
                "metrs": 1
            },
            {
                "size": " 50х50х4",
                "koef": "0.00556",
                "metrs": 1
            },
            {
                "size": " 50х50х5",
                "koef": "0.00673",
                "metrs": 1
            },
            {
                "size": " 60х60х1,5",
                "koef": "0.002725",
                "metrs": 1
            },
            {
                "size": " 60х60х1,75",
                "koef": "0.003159",
                "metrs": 1
            },
            {
                "size": " 60х60х1,8",
                "koef": "0.003246",
                "metrs": 1
            },
            {
                "size": " 60х60х2",
                "koef": "0.00359",
                "metrs": 1
            },
            {
                "size": " 60х60х2,3",
                "koef": "0.004096",
                "metrs": 1
            },
            {
                "size": " 60х60х2,5",
                "koef": "0.00443",
                "metrs": 1
            },
            {
                "size": " 60х60х2,8",
                "koef": "0.004923",
                "metrs": 1
            },
            {
                "size": " 60х60х3",
                "koef": "0.00525",
                "metrs": 1
            },
            {
                "size": " 60х60х3,5",
                "koef": "0.00604",
                "metrs": 1
            },
            {
                "size": " 60х60х3,7",
                "koef": "0.006356",
                "metrs": 1
            },
            {
                "size": " 60х60х4",
                "koef": "0.00682",
                "metrs": 1
            },
            {
                "size": " 60х60х4,5",
                "koef": "0.00743",
                "metrs": 1
            },
            {
                "size": " 60х60х5",
                "koef": "0.0083",
                "metrs": 1
            },
            {
                "size": " 60х60х6",
                "koef": "0.00969",
                "metrs": 1
            },
            {
                "size": " 70х70х2",
                "koef": "0.00419",
                "metrs": 1
            },
            {
                "size": " 70х70х2,35",
                "koef": "0.004917",
                "metrs": 1
            },
            {
                "size": " 70х70х2,5",
                "koef": "0.00517",
                "metrs": 1
            },
            {
                "size": " 70х70х2,5",
                "koef": "0.00517",
                "metrs": 1
            },
            {
                "size": " 70х70х2,8",
                "koef": "0.005802",
                "metrs": 1
            },
            {
                "size": " 70х70х3",
                "koef": "0.00619",
                "metrs": 1
            },
            {
                "size": " 70х70х4",
                "koef": "0.00807",
                "metrs": 1
            },
            {
                "size": " 70х70х5",
                "koef": "0.00987",
                "metrs": 1
            },
            {
                "size": " 70х70х6",
                "koef": "0.01157",
                "metrs": 1
            },
            {
                "size": " 80х80х1,8",
                "koef": "0.004376",
                "metrs": 1
            },
            {
                "size": " 80х80х2",
                "koef": "0.004844",
                "metrs": 1
            },
            {
                "size": " 80х80х2,5",
                "koef": "0.005999",
                "metrs": 1
            },
            {
                "size": " 80х80х2,8",
                "koef": "0.006682",
                "metrs": 1
            },
            {
                "size": " 80х80х3",
                "koef": "0.00713",
                "metrs": 1
            },
            {
                "size": " 80х80х3,5",
                "koef": "0.00824",
                "metrs": 1
            },
            {
                "size": " 80х80х4",
                "koef": "0.00933",
                "metrs": 1
            },
            {
                "size": " 80х80х4,5",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": " 80х80х5",
                "koef": "0.01144",
                "metrs": 1
            },
            {
                "size": " 80х80х6",
                "koef": "0.01346",
                "metrs": 1
            },
            {
                "size": " 80х80х7",
                "koef": "0.01538",
                "metrs": 1
            },
            {
                "size": " 80х80х8",
                "koef": "0.01722",
                "metrs": 1
            },
            {
                "size": " 80х80х9",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": " 80х80х10",
                "koef": "0.02063",
                "metrs": 1
            },
            {
                "size": " 90х90х2,5",
                "koef": "0.006784",
                "metrs": 1
            },
            {
                "size": " 90х90х3",
                "koef": "0.00807",
                "metrs": 1
            },
            {
                "size": " 90х90х4",
                "koef": "0.01059",
                "metrs": 1
            },
            {
                "size": " 90х90х5",
                "koef": "0.013",
                "metrs": 1
            },
            {
                "size": " 90х90х6",
                "koef": "0.01534",
                "metrs": 1
            },
            {
                "size": " 100х100х2",
                "koef": "0.0061",
                "metrs": 1
            },
            {
                "size": " 100х100х2,2",
                "koef": "0.006691",
                "metrs": 1
            },
            {
                "size": " 100х100х2,3",
                "koef": "0.006984",
                "metrs": 1
            },
            {
                "size": " 100х100х2,5",
                "koef": "0.007569",
                "metrs": 1
            },
            {
                "size": " 100х100х2,8",
                "koef": "0.00844",
                "metrs": 1
            },
            {
                "size": " 100х100х3",
                "koef": "0.00902",
                "metrs": 1
            },
            {
                "size": " 100х100х3,5",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": " 100х100х4",
                "koef": "0.01184",
                "metrs": 1
            },
            {
                "size": " 100х100х4,5",
                "koef": "0.01308",
                "metrs": 1
            },
            {
                "size": " 100х100х5",
                "koef": "0.01458",
                "metrs": 1
            },
            {
                "size": " 100х100х5,5",
                "koef": "0.01571",
                "metrs": 1
            },
            {
                "size": " 100х100х6",
                "koef": "0.01722",
                "metrs": 1
            },
            {
                "size": " 100х100х7",
                "koef": "0.01978",
                "metrs": 1
            },
            {
                "size": " 100х100х8",
                "koef": "0.02225",
                "metrs": 1
            },
            {
                "size": " 100х100х9",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": " 100х100х10",
                "koef": "0.02691",
                "metrs": 1
            },
            {
                "size": " 110х110х5",
                "koef": "0.01615",
                "metrs": 1
            },
            {
                "size": " 110х110х6",
                "koef": "0.01911",
                "metrs": 1
            },
            {
                "size": " 120х120х3",
                "koef": "0.01084",
                "metrs": 1
            },
            {
                "size": " 120х120х3,5",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": " 120х120х4",
                "koef": "0.01425",
                "metrs": 1
            },
            {
                "size": " 120х120х4,5",
                "koef": "0.01591",
                "metrs": 1
            },
            {
                "size": " 120х120х5",
                "koef": "0.01755",
                "metrs": 1
            },
            {
                "size": " 120х120х5,5",
                "koef": "0.01916",
                "metrs": 1
            },
            {
                "size": " 120х120х6",
                "koef": "0.02099",
                "metrs": 1
            },
            {
                "size": " 120х120х6,5",
                "koef": "0.02203",
                "metrs": 1
            },
            {
                "size": " 120х120х7",
                "koef": "0.02418",
                "metrs": 1
            },
            {
                "size": " 120х120х8",
                "koef": "0.02727",
                "metrs": 1
            },
            {
                "size": " 120х120х9",
                "koef": "0.03028",
                "metrs": 1
            },
            {
                "size": " 120х120х10",
                "koef": "0.03319",
                "metrs": 1
            },
            {
                "size": " 120х120х11",
                "koef": "0.03601",
                "metrs": 1
            },
            {
                "size": " 140х140х3,5",
                "koef": "0.01484",
                "metrs": 1
            },
            {
                "size": " 140х140х4",
                "koef": "0.01676",
                "metrs": 1
            },
            {
                "size": " 140х140х4,5",
                "koef": "0.01874",
                "metrs": 1
            },
            {
                "size": " 140х140х5",
                "koef": "0.02069",
                "metrs": 1
            },
            {
                "size": " 140х140х5,5",
                "koef": "0.02262",
                "metrs": 1
            },
            {
                "size": " 140х140х6",
                "koef": "0.02476",
                "metrs": 1
            },
            {
                "size": " 140х140х6,5",
                "koef": "0.02611",
                "metrs": 1
            },
            {
                "size": " 140х140х7",
                "koef": "0.02857",
                "metrs": 1
            },
            {
                "size": " 140х140х7,5",
                "koef": "0.02969",
                "metrs": 1
            },
            {
                "size": " 140х140х8",
                "koef": "0.03229",
                "metrs": 1
            },
            {
                "size": " 140х140х9",
                "koef": "0.03593",
                "metrs": 1
            },
            {
                "size": " 140х140х10",
                "koef": "0.03947",
                "metrs": 1
            },
            {
                "size": " 140х140х11",
                "koef": "0.04292",
                "metrs": 1
            },
            {
                "size": " 140х140х12",
                "koef": "0.04629",
                "metrs": 1
            },
            {
                "size": " 150х150х4",
                "koef": "0.01801",
                "metrs": 1
            },
            {
                "size": " 150х150х4,5",
                "koef": "0.02015",
                "metrs": 1
            },
            {
                "size": " 150х150х5",
                "koef": "0.02226",
                "metrs": 1
            },
            {
                "size": " 150х150х5,5",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": " 150х150х6",
                "koef": "0.0264",
                "metrs": 1
            },
            {
                "size": " 150х150х6,5",
                "koef": "0.02815",
                "metrs": 1
            },
            {
                "size": " 150х150х7",
                "koef": "0.03077",
                "metrs": 1
            },
            {
                "size": " 150х150х7,5",
                "koef": "0.03204",
                "metrs": 1
            },
            {
                "size": " 150х150х8",
                "koef": "0.03481",
                "metrs": 1
            },
            {
                "size": " 150х150х9",
                "koef": "0.03875",
                "metrs": 1
            },
            {
                "size": " 150х150х10",
                "koef": "0.04261",
                "metrs": 1
            },
            {
                "size": " 150х150х11",
                "koef": "0.04638",
                "metrs": 1
            },
            {
                "size": " 150х150х12",
                "koef": "0.05005",
                "metrs": 1
            },
            {
                "size": " 160х160х4",
                "koef": "0.01927",
                "metrs": 1
            },
            {
                "size": " 160х160х4,5",
                "koef": "0.02156",
                "metrs": 1
            },
            {
                "size": " 160х160х5",
                "koef": "0.02383",
                "metrs": 1
            },
            {
                "size": " 160х160х5,5",
                "koef": "0.02607",
                "metrs": 1
            },
            {
                "size": " 160х160х6",
                "koef": "0.02829",
                "metrs": 1
            },
            {
                "size": " 160х160х6,5",
                "koef": "0.03019",
                "metrs": 1
            },
            {
                "size": " 160х160х7",
                "koef": "0.03231",
                "metrs": 1
            },
            {
                "size": " 160х160х7,5",
                "koef": "0.0344",
                "metrs": 1
            },
            {
                "size": " 160х160х8",
                "koef": "0.03646",
                "metrs": 1
            },
            {
                "size": " 160х160х9",
                "koef": "0.04158",
                "metrs": 1
            },
            {
                "size": " 160х160х10",
                "koef": "0.04575",
                "metrs": 1
            },
            {
                "size": " 160х160х11",
                "koef": "0.04983",
                "metrs": 1
            },
            {
                "size": " 160х160х12",
                "koef": "0.05382",
                "metrs": 1
            },
            {
                "size": " 180х180х4",
                "koef": "0.02189",
                "metrs": 1
            },
            {
                "size": " 180х180х4,5",
                "koef": "0.02452",
                "metrs": 1
            },
            {
                "size": " 180х180х5",
                "koef": "0.02697",
                "metrs": 1
            },
            {
                "size": " 180х180х5,5",
                "koef": "0.02952",
                "metrs": 1
            },
            {
                "size": " 180х180х6",
                "koef": "0.03205",
                "metrs": 1
            },
            {
                "size": " 180х180х6,5",
                "koef": "0.03427",
                "metrs": 1
            },
            {
                "size": " 180х180х7",
                "koef": "0.0367",
                "metrs": 1
            },
            {
                "size": " 180х180х7,5",
                "koef": "0.03911",
                "metrs": 1
            },
            {
                "size": " 180х180х8",
                "koef": "0.04234",
                "metrs": 1
            },
            {
                "size": " 180х180х9",
                "koef": "0.04723",
                "metrs": 1
            },
            {
                "size": " 180х180х10",
                "koef": "0.05203",
                "metrs": 1
            },
            {
                "size": " 180х180х11",
                "koef": "0.05429",
                "metrs": 1
            },
            {
                "size": " 180х180х12",
                "koef": "0.06136",
                "metrs": 1
            },
            {
                "size": " 200х200х4",
                "koef": "0.0244",
                "metrs": 1
            },
            {
                "size": " 200х200х4,5",
                "koef": "0.02735",
                "metrs": 1
            },
            {
                "size": " 200х200х5",
                "koef": "0.03011",
                "metrs": 1
            },
            {
                "size": " 200х200х5,5",
                "koef": "0.03298",
                "metrs": 1
            },
            {
                "size": " 200х200х6",
                "koef": "0.03582",
                "metrs": 1
            },
            {
                "size": " 200х200х6,5",
                "koef": "0.03835",
                "metrs": 1
            },
            {
                "size": " 200х200х7",
                "koef": "0.0411",
                "metrs": 1
            },
            {
                "size": " 200х200х7,5",
                "koef": "0.04382",
                "metrs": 1
            },
            {
                "size": " 200х200х8",
                "koef": "0.04651",
                "metrs": 1
            },
            {
                "size": " 200х200х8,5",
                "koef": "0.04916",
                "metrs": 1
            },
            {
                "size": " 200х200х9",
                "koef": "0.05179",
                "metrs": 1
            },
            {
                "size": " 200х200х9,5",
                "koef": "0.05439",
                "metrs": 1
            },
            {
                "size": " 200х200х10",
                "koef": "0.05696",
                "metrs": 1
            },
            {
                "size": " 200х200х11",
                "koef": "0.0612",
                "metrs": 1
            },
            {
                "size": " 200х200х12",
                "koef": "0.06599",
                "metrs": 1
            },
            {
                "size": " 250х250х5",
                "koef": "0.03813",
                "metrs": 1
            },
            {
                "size": " 250х250х5,5",
                "koef": "0.04182",
                "metrs": 1
            },
            {
                "size": " 250х250х6",
                "koef": "0.04524",
                "metrs": 1
            },
            {
                "size": " 250х250х6,5",
                "koef": "0.04856",
                "metrs": 1
            },
            {
                "size": " 250х250х7",
                "koef": "0.05209",
                "metrs": 1
            },
            {
                "size": " 250х250х7,5",
                "koef": "0.05559",
                "metrs": 1
            },
            {
                "size": " 250х250х8",
                "koef": "0.05907",
                "metrs": 1
            },
            {
                "size": " 250х250х8,5",
                "koef": "0.06251",
                "metrs": 1
            },
            {
                "size": " 250х250х9",
                "koef": "0.06592",
                "metrs": 1
            },
            {
                "size": " 250х250х9,5",
                "koef": "0.06931",
                "metrs": 1
            },
            {
                "size": " 250х250х10",
                "koef": "0.07266",
                "metrs": 1
            },
            {
                "size": " 250х250х10,5",
                "koef": "0.07525",
                "metrs": 1
            },
            {
                "size": " 250х250х11",
                "koef": "0.07847",
                "metrs": 1
            },
            {
                "size": " 250х250х11,5",
                "koef": "0.08167",
                "metrs": 1
            },
            {
                "size": " 250х250х12",
                "koef": "0.08483",
                "metrs": 1
            },
            {
                "size": " 250х250х14",
                "koef": "0.1011",
                "metrs": 1
            },
            {
                "size": " 300х300х5",
                "koef": "0.04598",
                "metrs": 1
            },
            {
                "size": " 300х300х6",
                "koef": "0.05466",
                "metrs": 1
            },
            {
                "size": " 300х300х6,5",
                "koef": "0.05876",
                "metrs": 1
            },
            {
                "size": " 300х300х7",
                "koef": "0.06308",
                "metrs": 1
            },
            {
                "size": " 300х300х7,5",
                "koef": "0.06737",
                "metrs": 1
            },
            {
                "size": " 300х300х8",
                "koef": "0.07163",
                "metrs": 1
            },
            {
                "size": " 300х300х8,5",
                "koef": "0.07585",
                "metrs": 1
            },
            {
                "size": " 300х300х9",
                "koef": "0.08005",
                "metrs": 1
            },
            {
                "size": " 300х300х9,5",
                "koef": "0.08422",
                "metrs": 1
            },
            {
                "size": " 300х300х10",
                "koef": "0.08836",
                "metrs": 1
            },
            {
                "size": " 300х300х10,5",
                "koef": "0.09173",
                "metrs": 1
            },
            {
                "size": " 300х300х11",
                "koef": "0.09574",
                "metrs": 1
            },
            {
                "size": " 300х300х11,5",
                "koef": "0.09972",
                "metrs": 1
            },
            {
                "size": " 300х300х12",
                "koef": "0.1037",
                "metrs": 1
            },
            {
                "size": " 300х300х14",
                "koef": "0.1191",
                "metrs": 1
            },
            {
                "size": " 400х400х6",
                "koef": "0.07374",
                "metrs": 1
            },
            {
                "size": " 400х400х8",
                "koef": "0.0967",
                "metrs": 1
            }
        ],
        "Труба профильная квадратная оцинкованная": [
            {
                "size": " 8х8х0,5",
                "koef": "0.000118",
                "metrs": 1
            },
            {
                "size": " 8х8х0,6",
                "koef": "0.000139",
                "metrs": 1
            },
            {
                "size": " 8х8х0,7",
                "koef": "0.000158",
                "metrs": 1
            },
            {
                "size": " 8х8х0,8",
                "koef": "0.000177",
                "metrs": 1
            },
            {
                "size": " 8х8х0,9",
                "koef": "0.000195",
                "metrs": 1
            },
            {
                "size": " 8х8х1",
                "koef": "0.000212",
                "metrs": 1
            },
            {
                "size": " 8х8х1,1",
                "koef": "0.000229",
                "metrs": 1
            },
            {
                "size": " 8х8х1,2",
                "koef": "0.000244",
                "metrs": 1
            },
            {
                "size": " 8х8х1,3",
                "koef": "0.000258",
                "metrs": 1
            },
            {
                "size": " 8х8х1,5",
                "koef": "0.000284",
                "metrs": 1
            },
            {
                "size": " 10х10х0,5",
                "koef": "0.00015",
                "metrs": 1
            },
            {
                "size": " 10х10х0,6",
                "koef": "0.000177",
                "metrs": 1
            },
            {
                "size": " 10х10х0,7",
                "koef": "0.000204",
                "metrs": 1
            },
            {
                "size": " 10х10х0,8",
                "koef": "0.000229",
                "metrs": 1
            },
            {
                "size": " 10х10х0,9",
                "koef": "0.000253",
                "metrs": 1
            },
            {
                "size": " 10х10х1",
                "koef": "0.000277",
                "metrs": 1
            },
            {
                "size": " 10х10х1,1",
                "koef": "0.0003",
                "metrs": 1
            },
            {
                "size": " 10х10х1,2",
                "koef": "0.000321",
                "metrs": 1
            },
            {
                "size": " 10х10х1,3",
                "koef": "0.000342",
                "metrs": 1
            },
            {
                "size": " 10х10х1,5",
                "koef": "0.000381",
                "metrs": 1
            },
            {
                "size": " 12х12х0,5",
                "koef": "0.000182",
                "metrs": 1
            },
            {
                "size": " 12х12х0,6",
                "koef": "0.000216",
                "metrs": 1
            },
            {
                "size": " 12х12х0,7",
                "koef": "0.000249",
                "metrs": 1
            },
            {
                "size": " 12х12х0,8",
                "koef": "0.000281",
                "metrs": 1
            },
            {
                "size": " 12х12х0,9",
                "koef": "0.000312",
                "metrs": 1
            },
            {
                "size": " 12х12х1",
                "koef": "0.000342",
                "metrs": 1
            },
            {
                "size": " 12х12х1,1",
                "koef": "0.000371",
                "metrs": 1
            },
            {
                "size": " 12х12х1,2",
                "koef": "0.000399",
                "metrs": 1
            },
            {
                "size": " 12х12х1,3",
                "koef": "0.000426",
                "metrs": 1
            },
            {
                "size": " 12х12х1,5",
                "koef": "0.000478",
                "metrs": 1
            },
            {
                "size": " 15х15х0,5",
                "koef": "0.000231",
                "metrs": 1
            },
            {
                "size": " 15х15х0,6",
                "koef": "0.000274",
                "metrs": 1
            },
            {
                "size": " 15х15х0,7",
                "koef": "0.000317",
                "metrs": 1
            },
            {
                "size": " 15х15х0,8",
                "koef": "0.000358",
                "metrs": 1
            },
            {
                "size": " 15х15х0,9",
                "koef": "0.0004",
                "metrs": 1
            },
            {
                "size": " 15х15х1",
                "koef": "0.000439",
                "metrs": 1
            },
            {
                "size": " 15х15х1,1",
                "koef": "0.000478",
                "metrs": 1
            },
            {
                "size": " 15х15х1,2",
                "koef": "0.000516",
                "metrs": 1
            },
            {
                "size": " 15х15х1,3",
                "koef": "0.000553",
                "metrs": 1
            },
            {
                "size": " 15х15х1,5",
                "koef": "0.000623",
                "metrs": 1
            },
            {
                "size": " 15х15х2",
                "koef": "0.000785",
                "metrs": 1
            },
            {
                "size": " 18х18х0,5",
                "koef": "0.00028",
                "metrs": 1
            },
            {
                "size": " 18х18х0,6",
                "koef": "0.000333",
                "metrs": 1
            },
            {
                "size": " 18х18х0,7",
                "koef": "0.000385",
                "metrs": 1
            },
            {
                "size": " 18х18х0,8",
                "koef": "0.000436",
                "metrs": 1
            },
            {
                "size": " 18х18х0,9",
                "koef": "0.000486",
                "metrs": 1
            },
            {
                "size": " 18х18х1",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": " 18х18х1,1",
                "koef": "0.000584",
                "metrs": 1
            },
            {
                "size": " 18х18х1,2",
                "koef": "0.000632",
                "metrs": 1
            },
            {
                "size": " 18х18х1,3",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 18х18х1,5",
                "koef": "0.000769",
                "metrs": 1
            },
            {
                "size": " 18х18х2",
                "koef": "0.000979",
                "metrs": 1
            },
            {
                "size": " 20х20х0,5",
                "koef": "0.000312",
                "metrs": 1
            },
            {
                "size": " 20х20х0,6",
                "koef": "0.000371",
                "metrs": 1
            },
            {
                "size": " 20х20х0,7",
                "koef": "0.00043",
                "metrs": 1
            },
            {
                "size": " 20х20х0,75",
                "koef": "0.000459",
                "metrs": 1
            },
            {
                "size": " 20х20х0,8",
                "koef": "0.000488",
                "metrs": 1
            },
            {
                "size": " 20х20х0,85",
                "koef": "0.000516",
                "metrs": 1
            },
            {
                "size": " 20х20х0,9",
                "koef": "0.000545",
                "metrs": 1
            },
            {
                "size": " 20х20х1",
                "koef": "0.0006",
                "metrs": 1
            },
            {
                "size": " 20х20х1,1",
                "koef": "0.000656",
                "metrs": 1
            },
            {
                "size": " 20х20х1,2",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 20х20х1,3",
                "koef": "0.000763",
                "metrs": 1
            },
            {
                "size": " 20х20х1,4",
                "koef": "0.000815",
                "metrs": 1
            },
            {
                "size": " 20х20х1,5",
                "koef": "0.000866",
                "metrs": 1
            },
            {
                "size": " 20х20х1,8",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": " 20х20х2",
                "koef": "0.001107",
                "metrs": 1
            },
            {
                "size": " 25х25х0,5",
                "koef": "0.000393",
                "metrs": 1
            },
            {
                "size": " 25х25х0,6",
                "koef": "0.000468",
                "metrs": 1
            },
            {
                "size": " 25х25х0,7",
                "koef": "0.000543",
                "metrs": 1
            },
            {
                "size": " 25х25х0,75",
                "koef": "0.00058",
                "metrs": 1
            },
            {
                "size": " 25х25х0,8",
                "koef": "0.000617",
                "metrs": 1
            },
            {
                "size": " 25х25х0,85",
                "koef": "0.000654",
                "metrs": 1
            },
            {
                "size": " 25х25х0,9",
                "koef": "0.00069",
                "metrs": 1
            },
            {
                "size": " 25х25х1",
                "koef": "0.000762",
                "metrs": 1
            },
            {
                "size": " 25х25х1,1",
                "koef": "0.000833",
                "metrs": 1
            },
            {
                "size": " 25х25х1,2",
                "koef": "0.000904",
                "metrs": 1
            },
            {
                "size": " 25х25х1,3",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": " 25х25х1,4",
                "koef": "0.00104",
                "metrs": 1
            },
            {
                "size": " 25х25х1,5",
                "koef": "0.001102",
                "metrs": 1
            },
            {
                "size": " 25х25х1,8",
                "koef": "0.001306",
                "metrs": 1
            },
            {
                "size": " 25х25х2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 30х30х0,5",
                "koef": "0.000474",
                "metrs": 1
            },
            {
                "size": " 30х30х0,6",
                "koef": "0.000566",
                "metrs": 1
            },
            {
                "size": " 30х30х0,7",
                "koef": "0.000657",
                "metrs": 1
            },
            {
                "size": " 30х30х0,8",
                "koef": "0.000747",
                "metrs": 1
            },
            {
                "size": " 30х30х0,9",
                "koef": "0.000835",
                "metrs": 1
            },
            {
                "size": " 30х30х1",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": " 30х30х1,1",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": " 30х30х1,2",
                "koef": "0.001102",
                "metrs": 1
            },
            {
                "size": " 30х30х1,3",
                "koef": "0.001184",
                "metrs": 1
            },
            {
                "size": " 30х30х1,4",
                "koef": "0.001267",
                "metrs": 1
            },
            {
                "size": " 30х30х1,5",
                "koef": "0.001349",
                "metrs": 1
            },
            {
                "size": " 30х30х1,8",
                "koef": "0.001597",
                "metrs": 1
            },
            {
                "size": " 30х30х2",
                "koef": "0.001751",
                "metrs": 1
            },
            {
                "size": " 40х40х1",
                "koef": "0.001247",
                "metrs": 1
            },
            {
                "size": " 40х40х1,2",
                "koef": "0.001486",
                "metrs": 1
            },
            {
                "size": " 40х40х1,5",
                "koef": "0.001833",
                "metrs": 1
            },
            {
                "size": " 40х40х1,8",
                "koef": "0.002179",
                "metrs": 1
            },
            {
                "size": " 40х40х2",
                "koef": "0.0024",
                "metrs": 1
            },
            {
                "size": " 50х50х1",
                "koef": "0.001571",
                "metrs": 1
            },
            {
                "size": " 50х50х1,2",
                "koef": "0.001874",
                "metrs": 1
            },
            {
                "size": " 50х50х1,5",
                "koef": "0.002322",
                "metrs": 1
            },
            {
                "size": " 50х50х1,8",
                "koef": "0.002761",
                "metrs": 1
            },
            {
                "size": " 50х50х2",
                "koef": "0.003049",
                "metrs": 1
            },
            {
                "size": " 60х60х1,2",
                "koef": "0.002262",
                "metrs": 1
            },
            {
                "size": " 60х60х1,5",
                "koef": "0.002807",
                "metrs": 1
            },
            {
                "size": " 60х60х1,8",
                "koef": "0.003343",
                "metrs": 1
            },
            {
                "size": " 60х60х2",
                "koef": "0.003698",
                "metrs": 1
            },
            {
                "size": " 60х60х2,5",
                "koef": "0.004563",
                "metrs": 1
            },
            {
                "size": " 80х80х2",
                "koef": "0.00499",
                "metrs": 1
            },
            {
                "size": " 80х80х2,5",
                "koef": "0.006179",
                "metrs": 1
            },
            {
                "size": " 80х80х3",
                "koef": "0.007344",
                "metrs": 1
            }
        ],
        "Труба овальная": [
            {
                "size": "40х20х1",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "40х20х1,2",
                "koef": "0.000885",
                "metrs": 1
            },
            {
                "size": "40х20х1,5",
                "koef": "0.00109",
                "metrs": 1
            }
        ],
        "Труба плоскоовальная": [
            {
                "size": " 30х15х1,2",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": " 30х15х1,5",
                "koef": "0.00089",
                "metrs": 1
            },
            {
                "size": " 30х15х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 40х20х1",
                "koef": "0.000783",
                "metrs": 1
            },
            {
                "size": " 40х20х1,2",
                "koef": "0.00095",
                "metrs": 1
            },
            {
                "size": " 40х20х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": " 40х20х2",
                "koef": "0.00152",
                "metrs": 1
            },
            {
                "size": " 40х20х2,5",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": " 40х25х1,2",
                "koef": "0.00111",
                "metrs": 1
            },
            {
                "size": " 40х25х1,5",
                "koef": "0.001341",
                "metrs": 1
            },
            {
                "size": " 40х25х2",
                "koef": "0.00176",
                "metrs": 1
            },
            {
                "size": " 40х25х2,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 50х25х1,5",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": " 60х20х1,5",
                "koef": "0.00163",
                "metrs": 1
            },
            {
                "size": " 60х20х2",
                "koef": "0.00214",
                "metrs": 1
            }
        ],
        "Труба плоскоовальная оцинкованная": [
            {
                "size": "30х15х1,2",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": "30х15х1,5",
                "koef": "0.000917",
                "metrs": 1
            },
            {
                "size": "30х15х2",
                "koef": "0.001215",
                "metrs": 1
            },
            {
                "size": "40х20х1",
                "koef": "0.000806",
                "metrs": 1
            },
            {
                "size": "40х20х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "40х20х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": "40х20х2",
                "koef": "0.001566",
                "metrs": 1
            },
            {
                "size": "40х25х1,2",
                "koef": "0.001143",
                "metrs": 1
            },
            {
                "size": "40х25х1,5",
                "koef": "0.001381",
                "metrs": 1
            },
            {
                "size": "40х25х2",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "60х20х1,5",
                "koef": "0.001679",
                "metrs": 1
            },
            {
                "size": "60х20х2",
                "koef": "0.002204",
                "metrs": 1
            }
        ],
        "Труба профильная прямоугольная": [
            {
                "size": " 15х10х1",
                "koef": "0.000348",
                "metrs": 1
            },
            {
                "size": " 15х10х1,2",
                "koef": "0.000406",
                "metrs": 1
            },
            {
                "size": " 15х10х1,5",
                "koef": "0.000488",
                "metrs": 1
            },
            {
                "size": " 20х10х0,5",
                "koef": "0.000224",
                "metrs": 1
            },
            {
                "size": " 20х10х0,6",
                "koef": "0.000266",
                "metrs": 1
            },
            {
                "size": " 20х10х0,7",
                "koef": "0.000308",
                "metrs": 1
            },
            {
                "size": " 20х10х0,8",
                "koef": "0.000348",
                "metrs": 1
            },
            {
                "size": " 20х10х0,9",
                "koef": "0.000388",
                "metrs": 1
            },
            {
                "size": " 20х10х1",
                "koef": "0.000426",
                "metrs": 1
            },
            {
                "size": " 20х10х1,1",
                "koef": "0.000464",
                "metrs": 1
            },
            {
                "size": " 20х10х1,2",
                "koef": "0.000501",
                "metrs": 1
            },
            {
                "size": " 20х10х1,3",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": " 20х10х1,5",
                "koef": "0.000605",
                "metrs": 1
            },
            {
                "size": " 20х15х0,5",
                "koef": "0.000264",
                "metrs": 1
            },
            {
                "size": " 20х15х0,6",
                "koef": "0.000314",
                "metrs": 1
            },
            {
                "size": " 20х15х0,7",
                "koef": "0.000363",
                "metrs": 1
            },
            {
                "size": " 20х15х0,8",
                "koef": "0.000411",
                "metrs": 1
            },
            {
                "size": " 20х15х1",
                "koef": "0.000505",
                "metrs": 1
            },
            {
                "size": " 20х15х1,2",
                "koef": "0.000595",
                "metrs": 1
            },
            {
                "size": " 20х15х1,5",
                "koef": "0.000723",
                "metrs": 1
            },
            {
                "size": " 25х10х0,5",
                "koef": "0.000264",
                "metrs": 1
            },
            {
                "size": " 25х10х0,6",
                "koef": "0.000314",
                "metrs": 1
            },
            {
                "size": " 25х10х0,7",
                "koef": "0.000363",
                "metrs": 1
            },
            {
                "size": " 25х10х0,8",
                "koef": "0.000411",
                "metrs": 1
            },
            {
                "size": " 25х10х1",
                "koef": "0.000505",
                "metrs": 1
            },
            {
                "size": " 25х10х1,1",
                "koef": "0.00055",
                "metrs": 1
            },
            {
                "size": " 25х10х1,2",
                "koef": "0.000595",
                "metrs": 1
            },
            {
                "size": " 25х10х1,3",
                "koef": "0.000638",
                "metrs": 1
            },
            {
                "size": " 25х10х1,5",
                "koef": "0.000723",
                "metrs": 1
            },
            {
                "size": " 25х15х0,6",
                "koef": "0.000361",
                "metrs": 1
            },
            {
                "size": " 25х15х0,7",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": " 25х15х0,8",
                "koef": "0.000474",
                "metrs": 1
            },
            {
                "size": " 25х15х1",
                "koef": "0.000583",
                "metrs": 1
            },
            {
                "size": " 25х15х1,1",
                "koef": "0.000636",
                "metrs": 1
            },
            {
                "size": " 25х15х1,2",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": " 25х15х1,3",
                "koef": "0.000741",
                "metrs": 1
            },
            {
                "size": " 25х15х1,5",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 25х15х1,8",
                "koef": "0.000985",
                "metrs": 1
            },
            {
                "size": " 25х15х2",
                "koef": "0.00108",
                "metrs": 1
            },
            {
                "size": " 28х25х1",
                "koef": "0.000787",
                "metrs": 1
            },
            {
                "size": " 28х25х1,1",
                "koef": "0.000861",
                "metrs": 1
            },
            {
                "size": " 28х25х1,2",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": " 28х25х1,3",
                "koef": "0.001006",
                "metrs": 1
            },
            {
                "size": " 28х25х1,5",
                "koef": "0.00115",
                "metrs": 1
            },
            {
                "size": " 28х25х1,8",
                "koef": "0.001352",
                "metrs": 1
            },
            {
                "size": " 28х25х2",
                "koef": "0.00149",
                "metrs": 1
            },
            {
                "size": " 28х27х0,6",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": " 30х10х1",
                "koef": "0.000583",
                "metrs": 1
            },
            {
                "size": " 30х10х1,2",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": " 30х10х1,5",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 30х10х2",
                "koef": "0.00108",
                "metrs": 1
            },
            {
                "size": " 30х15х0,4",
                "koef": "0.000275",
                "metrs": 1
            },
            {
                "size": " 30х15х0,5",
                "koef": "0.000342",
                "metrs": 1
            },
            {
                "size": " 30х15х0,6",
                "koef": "0.000408",
                "metrs": 1
            },
            {
                "size": " 30х15х0,7",
                "koef": "0.000473",
                "metrs": 1
            },
            {
                "size": " 30х15х0,8",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": " 30х15х0,9",
                "koef": "0.0006",
                "metrs": 1
            },
            {
                "size": " 30х15х1",
                "koef": "0.000661",
                "metrs": 1
            },
            {
                "size": " 30х15х1,1",
                "koef": "0.000723",
                "metrs": 1
            },
            {
                "size": " 30х15х1,2",
                "koef": "0.000783",
                "metrs": 1
            },
            {
                "size": " 30х15х1,3",
                "koef": "0.000843",
                "metrs": 1
            },
            {
                "size": " 30х15х1,5",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 30х15х1,8",
                "koef": "0.001126",
                "metrs": 1
            },
            {
                "size": " 30х15х2",
                "koef": "0.00123",
                "metrs": 1
            },
            {
                "size": " 30х15х2,5",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 30х20х0,4",
                "koef": "0.000307",
                "metrs": 1
            },
            {
                "size": " 30х20х0,5",
                "koef": "0.000381",
                "metrs": 1
            },
            {
                "size": " 30х20х0,6",
                "koef": "0.000455",
                "metrs": 1
            },
            {
                "size": " 30х20х0,7",
                "koef": "0.000527",
                "metrs": 1
            },
            {
                "size": " 30х20х0,8",
                "koef": "0.000599",
                "metrs": 1
            },
            {
                "size": " 30х20х0,9",
                "koef": "0.00067",
                "metrs": 1
            },
            {
                "size": " 30х20х1",
                "koef": "0.00074",
                "metrs": 1
            },
            {
                "size": " 30х20х1,1",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 30х20х1,2",
                "koef": "0.000877",
                "metrs": 1
            },
            {
                "size": " 30х20х1,3",
                "koef": "0.000945",
                "metrs": 1
            },
            {
                "size": " 30х20х1,5",
                "koef": "0.00108",
                "metrs": 1
            },
            {
                "size": " 30х20х1,8",
                "koef": "0.001268",
                "metrs": 1
            },
            {
                "size": " 30х20х2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 30х20х2,5",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 30х25х0,8",
                "koef": "0.000662",
                "metrs": 1
            },
            {
                "size": " 30х25х1",
                "koef": "0.000819",
                "metrs": 1
            },
            {
                "size": " 30х25х1,2",
                "koef": "0.000972",
                "metrs": 1
            },
            {
                "size": " 30х25х1,5",
                "koef": "0.001194",
                "metrs": 1
            },
            {
                "size": " 30х25х2",
                "koef": "0.001547",
                "metrs": 1
            },
            {
                "size": " 30х25х2,5",
                "koef": "0.001878",
                "metrs": 1
            },
            {
                "size": " 35х15х0,8",
                "koef": "0.000599",
                "metrs": 1
            },
            {
                "size": " 35х15х0,9",
                "koef": "0.00067",
                "metrs": 1
            },
            {
                "size": " 35х15х1",
                "koef": "0.00074",
                "metrs": 1
            },
            {
                "size": " 35х15х1,2",
                "koef": "0.000877",
                "metrs": 1
            },
            {
                "size": " 35х15х1,5",
                "koef": "0.00108",
                "metrs": 1
            },
            {
                "size": " 35х15х2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 35х20х1,5",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 35х25х1",
                "koef": "0.000897",
                "metrs": 1
            },
            {
                "size": " 35х25х1,2",
                "koef": "0.001066",
                "metrs": 1
            },
            {
                "size": " 35х25х1,5",
                "koef": "0.00131",
                "metrs": 1
            },
            {
                "size": " 40х10х0,8",
                "koef": "0.000599",
                "metrs": 1
            },
            {
                "size": " 40х10х0,9",
                "koef": "0.00067",
                "metrs": 1
            },
            {
                "size": " 40х10х1",
                "koef": "0.00074",
                "metrs": 1
            },
            {
                "size": " 40х10х1,1",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 40х10х1,2",
                "koef": "0.000877",
                "metrs": 1
            },
            {
                "size": " 40х10х1,3",
                "koef": "0.000945",
                "metrs": 1
            },
            {
                "size": " 40х10х1,5",
                "koef": "0.001076",
                "metrs": 1
            },
            {
                "size": " 40х10х1,8",
                "koef": "0.001268",
                "metrs": 1
            },
            {
                "size": " 40х10х2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 40х10х2,5",
                "koef": "0.001682",
                "metrs": 1
            },
            {
                "size": " 40х20х0,4",
                "koef": "0.00037",
                "metrs": 1
            },
            {
                "size": " 40х20х0,5",
                "koef": "0.00046",
                "metrs": 1
            },
            {
                "size": " 40х20х0,6",
                "koef": "0.000549",
                "metrs": 1
            },
            {
                "size": " 40х20х0,7",
                "koef": "0.000637",
                "metrs": 1
            },
            {
                "size": " 40х20х0,8",
                "koef": "0.000725",
                "metrs": 1
            },
            {
                "size": " 40х20х0,9",
                "koef": "0.000811",
                "metrs": 1
            },
            {
                "size": " 40х20х1",
                "koef": "0.000897",
                "metrs": 1
            },
            {
                "size": " 40х20х1,1",
                "koef": "0.000982",
                "metrs": 1
            },
            {
                "size": " 40х20х1,2",
                "koef": "0.00107",
                "metrs": 1
            },
            {
                "size": " 40х20х1,3",
                "koef": "0.001149",
                "metrs": 1
            },
            {
                "size": " 40х20х1,35",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 40х20х1,4",
                "koef": "0.001231",
                "metrs": 1
            },
            {
                "size": " 40х20х1,5",
                "koef": "0.00131",
                "metrs": 1
            },
            {
                "size": " 40х20х1,8",
                "koef": "0.00155",
                "metrs": 1
            },
            {
                "size": " 40х20х2",
                "koef": "0.0017",
                "metrs": 1
            },
            {
                "size": " 40х20х2,5",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 40х20х3",
                "koef": "0.00242",
                "metrs": 1
            },
            {
                "size": " 40х20х3,5",
                "koef": "0.00275",
                "metrs": 1
            },
            {
                "size": " 40х25х0,8",
                "koef": "0.000788",
                "metrs": 1
            },
            {
                "size": " 40х25х0,9",
                "koef": "0.000882",
                "metrs": 1
            },
            {
                "size": " 40х25х1",
                "koef": "0.000976",
                "metrs": 1
            },
            {
                "size": " 40х25х1,1",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": " 40х25х1,2",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": " 40х25х1,3",
                "koef": "0.001251",
                "metrs": 1
            },
            {
                "size": " 40х25х1,35",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": " 40х25х1,5",
                "koef": "0.00143",
                "metrs": 1
            },
            {
                "size": " 40х25х1,8",
                "koef": "0.001691",
                "metrs": 1
            },
            {
                "size": " 40х25х2",
                "koef": "0.00186",
                "metrs": 1
            },
            {
                "size": " 40х25х2,5",
                "koef": "0.00227",
                "metrs": 1
            },
            {
                "size": " 40х25х3",
                "koef": "0.00266",
                "metrs": 1
            },
            {
                "size": " 40х25х3,5",
                "koef": "0.00302",
                "metrs": 1
            },
            {
                "size": " 40х30х1",
                "koef": "0.001054",
                "metrs": 1
            },
            {
                "size": " 40х30х1,2",
                "koef": "0.001254",
                "metrs": 1
            },
            {
                "size": " 40х30х1,5",
                "koef": "0.00155",
                "metrs": 1
            },
            {
                "size": " 40х30х2",
                "koef": "0.00202",
                "metrs": 1
            },
            {
                "size": " 40х30х2,5",
                "koef": "0.00247",
                "metrs": 1
            },
            {
                "size": " 45х20х1,5",
                "koef": "0.00143",
                "metrs": 1
            },
            {
                "size": " 45х30х2,5",
                "koef": "0.00266",
                "metrs": 1
            },
            {
                "size": " 45х30х3",
                "koef": "0.00313",
                "metrs": 1
            },
            {
                "size": " 45х35х3",
                "koef": "0.003364",
                "metrs": 1
            },
            {
                "size": " 50х10х1",
                "koef": "0.000897",
                "metrs": 1
            },
            {
                "size": " 50х10х1,2",
                "koef": "0.001066",
                "metrs": 1
            },
            {
                "size": " 50х10х1,5",
                "koef": "0.001312",
                "metrs": 1
            },
            {
                "size": " 50х20х1,5",
                "koef": "0.001547",
                "metrs": 1
            },
            {
                "size": " 50х20х2",
                "koef": "0.002018",
                "metrs": 1
            },
            {
                "size": " 50х25х1",
                "koef": "0.001133",
                "metrs": 1
            },
            {
                "size": " 50х25х1,2",
                "koef": "0.001348",
                "metrs": 1
            },
            {
                "size": " 50х25х1,3",
                "koef": "0.001455",
                "metrs": 1
            },
            {
                "size": " 50х25х1,5",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": " 50х25х1,8",
                "koef": "0.001974",
                "metrs": 1
            },
            {
                "size": " 50х25х2",
                "koef": "0.00215",
                "metrs": 1
            },
            {
                "size": " 50х25х2,2",
                "koef": "0.002373",
                "metrs": 1
            },
            {
                "size": " 50х25х2,5",
                "koef": "0.00262",
                "metrs": 1
            },
            {
                "size": " 50х25х2,8",
                "koef": "0.002945",
                "metrs": 1
            },
            {
                "size": " 50х25х3",
                "koef": "0.00307",
                "metrs": 1
            },
            {
                "size": " 50х25х3,5",
                "koef": "0.00349",
                "metrs": 1
            },
            {
                "size": " 50х25х4",
                "koef": "0.00388",
                "metrs": 1
            },
            {
                "size": " 50х30х1,2",
                "koef": "0.001443",
                "metrs": 1
            },
            {
                "size": " 50х30х1,5",
                "koef": "0.00178",
                "metrs": 1
            },
            {
                "size": " 50х30х1,8",
                "koef": "0.002115",
                "metrs": 1
            },
            {
                "size": " 50х30х2",
                "koef": "0.00231",
                "metrs": 1
            },
            {
                "size": " 50х30х2,3",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": " 50х30х2,5",
                "koef": "0.00282",
                "metrs": 1
            },
            {
                "size": " 50х30х2,8",
                "koef": "0.003165",
                "metrs": 1
            },
            {
                "size": " 50х30х3",
                "koef": "0.0033",
                "metrs": 1
            },
            {
                "size": " 50х30х3,5",
                "koef": "0.00376",
                "metrs": 1
            },
            {
                "size": " 50х30х4",
                "koef": "0.0042",
                "metrs": 1
            },
            {
                "size": " 50х35х1,5",
                "koef": "0.0019",
                "metrs": 1
            },
            {
                "size": " 50х35х2",
                "koef": "0.00249",
                "metrs": 1
            },
            {
                "size": " 50х40х1",
                "koef": "0.001368",
                "metrs": 1
            },
            {
                "size": " 50х40х1,1",
                "koef": "0.0015",
                "metrs": 1
            },
            {
                "size": " 50х40х1,2",
                "koef": "0.001631",
                "metrs": 1
            },
            {
                "size": " 50х40х1,3",
                "koef": "0.001761",
                "metrs": 1
            },
            {
                "size": " 50х40х1,4",
                "koef": "0.00189",
                "metrs": 1
            },
            {
                "size": " 50х40х1,5",
                "koef": "0.002018",
                "metrs": 1
            },
            {
                "size": " 50х40х2",
                "koef": "0.00262",
                "metrs": 1
            },
            {
                "size": " 50х40х2,5",
                "koef": "0.00321",
                "metrs": 1
            },
            {
                "size": " 50х40х2,8",
                "koef": "0.003604",
                "metrs": 1
            },
            {
                "size": " 50х40х3",
                "koef": "0.00377",
                "metrs": 1
            },
            {
                "size": " 50х40х4",
                "koef": "0.00483",
                "metrs": 1
            },
            {
                "size": " 60х10х1",
                "koef": "0.001054",
                "metrs": 1
            },
            {
                "size": " 60х10х1,2",
                "koef": "0.001254",
                "metrs": 1
            },
            {
                "size": " 60х10х1,5",
                "koef": "0.001547",
                "metrs": 1
            },
            {
                "size": " 60х20х1,2",
                "koef": "0.001443",
                "metrs": 1
            },
            {
                "size": " 60х20х1,5",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 60х20х2",
                "koef": "0.00233",
                "metrs": 1
            },
            {
                "size": " 60х25х2,5",
                "koef": "0.00305",
                "metrs": 1
            },
            {
                "size": " 60х25х3",
                "koef": "0.0036",
                "metrs": 1
            },
            {
                "size": " 60х27х0,6",
                "koef": "0.000803",
                "metrs": 1
            },
            {
                "size": " 60х30х1",
                "koef": "0.001368",
                "metrs": 1
            },
            {
                "size": " 60х30х1,1",
                "koef": "0.0015",
                "metrs": 1
            },
            {
                "size": " 60х30х1,2",
                "koef": "0.001631",
                "metrs": 1
            },
            {
                "size": " 60х30х1,3",
                "koef": "0.001761",
                "metrs": 1
            },
            {
                "size": " 60х30х1,4",
                "koef": "0.00189",
                "metrs": 1
            },
            {
                "size": " 60х30х1,5",
                "koef": "0.00202",
                "metrs": 1
            },
            {
                "size": " 60х30х1,8",
                "koef": "0.002398",
                "metrs": 1
            },
            {
                "size": " 60х30х2",
                "koef": "0.00263",
                "metrs": 1
            },
            {
                "size": " 60х30х2,5",
                "koef": "0.00321",
                "metrs": 1
            },
            {
                "size": " 60х30х2,8",
                "koef": "0.003604",
                "metrs": 1
            },
            {
                "size": " 60х30х3",
                "koef": "0.00377",
                "metrs": 1
            },
            {
                "size": " 60х30х3,5",
                "koef": "0.00431",
                "metrs": 1
            },
            {
                "size": " 60х30х4",
                "koef": "0.00483",
                "metrs": 1
            },
            {
                "size": " 60х40х1",
                "koef": "0.001525",
                "metrs": 1
            },
            {
                "size": " 60х40х1,2",
                "koef": "0.001819",
                "metrs": 1
            },
            {
                "size": " 60х40х1,3",
                "koef": "0.001965",
                "metrs": 1
            },
            {
                "size": " 60х40х1,5",
                "koef": "0.00225",
                "metrs": 1
            },
            {
                "size": " 60х40х1,8",
                "koef": "0.002681",
                "metrs": 1
            },
            {
                "size": " 60х40х2",
                "koef": "0.00293",
                "metrs": 1
            },
            {
                "size": " 60х40х2,2",
                "koef": "0.003237",
                "metrs": 1
            },
            {
                "size": " 60х40х2,5",
                "koef": "0.0036",
                "metrs": 1
            },
            {
                "size": " 60х40х2,8",
                "koef": "0.004044",
                "metrs": 1
            },
            {
                "size": " 60х40х3",
                "koef": "0.00425",
                "metrs": 1
            },
            {
                "size": " 60х40х3,5",
                "koef": "0.00486",
                "metrs": 1
            },
            {
                "size": " 60х40х3,7",
                "koef": "0.005194",
                "metrs": 1
            },
            {
                "size": " 60х40х4",
                "koef": "0.00545",
                "metrs": 1
            },
            {
                "size": " 60х40х5",
                "koef": "0.00656",
                "metrs": 1
            },
            {
                "size": " 70х30х1,5",
                "koef": "0.002254",
                "metrs": 1
            },
            {
                "size": " 70х30х1,8",
                "koef": "0.002681",
                "metrs": 1
            },
            {
                "size": " 70х30х2",
                "koef": "0.00296",
                "metrs": 1
            },
            {
                "size": " 70х30х2,5",
                "koef": "0.003644",
                "metrs": 1
            },
            {
                "size": " 70х30х3",
                "koef": "0.0043",
                "metrs": 1
            },
            {
                "size": " 70х30х4",
                "koef": "0.00556",
                "metrs": 1
            },
            {
                "size": " 70х40х3",
                "koef": "0.00478",
                "metrs": 1
            },
            {
                "size": " 70х40х4",
                "koef": "0.00619",
                "metrs": 1
            },
            {
                "size": " 70х40х5",
                "koef": "0.00751",
                "metrs": 1
            },
            {
                "size": " 70х50х2",
                "koef": "0.00356",
                "metrs": 1
            },
            {
                "size": " 70х50х2,5",
                "koef": "0.00439",
                "metrs": 1
            },
            {
                "size": " 70х50х2,8",
                "koef": "0.004923",
                "metrs": 1
            },
            {
                "size": " 70х50х3",
                "koef": "0.00519",
                "metrs": 1
            },
            {
                "size": " 70х50х3,5",
                "koef": "0.00596",
                "metrs": 1
            },
            {
                "size": " 70х50х4",
                "koef": "0.00671",
                "metrs": 1
            },
            {
                "size": " 70х50х5",
                "koef": "0.00813",
                "metrs": 1
            },
            {
                "size": " 80х20х1,8",
                "koef": "0.002681",
                "metrs": 1
            },
            {
                "size": " 80х20х2",
                "koef": "0.00296",
                "metrs": 1
            },
            {
                "size": " 80х30х2,5",
                "koef": "0.004037",
                "metrs": 1
            },
            {
                "size": " 80х30х3",
                "koef": "0.004777",
                "metrs": 1
            },
            {
                "size": " 80х40х1,5",
                "koef": "0.002725",
                "metrs": 1
            },
            {
                "size": " 80х40х1,8",
                "koef": "0.003246",
                "metrs": 1
            },
            {
                "size": " 80х40х2",
                "koef": "0.00356",
                "metrs": 1
            },
            {
                "size": " 80х40х2,2",
                "koef": "0.003927",
                "metrs": 1
            },
            {
                "size": " 80х40х2,5",
                "koef": "0.00439",
                "metrs": 1
            },
            {
                "size": " 80х40х2,8",
                "koef": "0.004923",
                "metrs": 1
            },
            {
                "size": " 80х40х3",
                "koef": "0.00519",
                "metrs": 1
            },
            {
                "size": " 80х40х3,5",
                "koef": "0.00586",
                "metrs": 1
            },
            {
                "size": " 80х40х3,7",
                "koef": "0.006356",
                "metrs": 1
            },
            {
                "size": " 80х40х4",
                "koef": "0.00671",
                "metrs": 1
            },
            {
                "size": " 80х40х5",
                "koef": "0.00813",
                "metrs": 1
            },
            {
                "size": " 80х50х2,8",
                "koef": "0.005363",
                "metrs": 1
            },
            {
                "size": " 80х50х3",
                "koef": "0.00572",
                "metrs": 1
            },
            {
                "size": " 80х50х3,5",
                "koef": "0.00659",
                "metrs": 1
            },
            {
                "size": " 80х50х4",
                "koef": "0.00744",
                "metrs": 1
            },
            {
                "size": " 80х60х1,5",
                "koef": "0.003196",
                "metrs": 1
            },
            {
                "size": " 80х60х1,8",
                "koef": "0.003811",
                "metrs": 1
            },
            {
                "size": " 80х60х2",
                "koef": "0.00419",
                "metrs": 1
            },
            {
                "size": " 80х60х2,2",
                "koef": "0.004618",
                "metrs": 1
            },
            {
                "size": " 80х60х2,5",
                "koef": "0.00517",
                "metrs": 1
            },
            {
                "size": " 80х60х3",
                "koef": "0.00613",
                "metrs": 1
            },
            {
                "size": " 80х60х3,5",
                "koef": "0.00706",
                "metrs": 1
            },
            {
                "size": " 80х60х4",
                "koef": "0.00797",
                "metrs": 1
            },
            {
                "size": " 80х60х5",
                "koef": "0.0097",
                "metrs": 1
            },
            {
                "size": " 80х60х6",
                "koef": "0.01133",
                "metrs": 1
            },
            {
                "size": " 90х30х1,5",
                "koef": "0.002725",
                "metrs": 1
            },
            {
                "size": " 90х30х2",
                "koef": "0.003588",
                "metrs": 1
            },
            {
                "size": " 90х30х2,5",
                "koef": "0.004429",
                "metrs": 1
            },
            {
                "size": " 90х30х3",
                "koef": "0.005248",
                "metrs": 1
            },
            {
                "size": " 90х60х5",
                "koef": "0.01048",
                "metrs": 1
            },
            {
                "size": " 100х40х2",
                "koef": "0.004216",
                "metrs": 1
            },
            {
                "size": " 100х40х2,5",
                "koef": "0.005214",
                "metrs": 1
            },
            {
                "size": " 100х40х3",
                "koef": "0.00613",
                "metrs": 1
            },
            {
                "size": " 100х40х3,5",
                "koef": "0.00706",
                "metrs": 1
            },
            {
                "size": " 100х40х4",
                "koef": "0.00797",
                "metrs": 1
            },
            {
                "size": " 100х40х4,5",
                "koef": "0.00885",
                "metrs": 1
            },
            {
                "size": " 100х50х2",
                "koef": "0.00453",
                "metrs": 1
            },
            {
                "size": " 100х50х2,5",
                "koef": "0.005607",
                "metrs": 1
            },
            {
                "size": " 100х50х2,8",
                "koef": "0.006242",
                "metrs": 1
            },
            {
                "size": " 100х50х3",
                "koef": "0.0066",
                "metrs": 1
            },
            {
                "size": " 100х50х4",
                "koef": "0.00859",
                "metrs": 1
            },
            {
                "size": " 100х50х4,5",
                "koef": "0.00955",
                "metrs": 1
            },
            {
                "size": " 100х50х5",
                "koef": "0.01048",
                "metrs": 1
            },
            {
                "size": " 100х50х6",
                "koef": "0.01227",
                "metrs": 1
            },
            {
                "size": " 100х60х2",
                "koef": "0.004844",
                "metrs": 1
            },
            {
                "size": " 100х60х2,5",
                "koef": "0.005999",
                "metrs": 1
            },
            {
                "size": " 100х60х3",
                "koef": "0.00707",
                "metrs": 1
            },
            {
                "size": " 100х60х3,5",
                "koef": "0.00816",
                "metrs": 1
            },
            {
                "size": " 100х60х4",
                "koef": "0.00922",
                "metrs": 1
            },
            {
                "size": " 100х60х4,5",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": " 100х60х5",
                "koef": "0.01127",
                "metrs": 1
            },
            {
                "size": " 100х60х5,5",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": " 100х60х6",
                "koef": "0.01321",
                "metrs": 1
            },
            {
                "size": " 100х70х4",
                "koef": "0.00996",
                "metrs": 1
            },
            {
                "size": " 100х70х5",
                "koef": "0.01222",
                "metrs": 1
            },
            {
                "size": " 100х80х3",
                "koef": "0.008074",
                "metrs": 1
            },
            {
                "size": " 100х80х4",
                "koef": "0.01059",
                "metrs": 1
            },
            {
                "size": " 100х80х4,5",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": " 100х80х5",
                "koef": "0.01301",
                "metrs": 1
            },
            {
                "size": " 100х80х6",
                "koef": "0.01534",
                "metrs": 1
            },
            {
                "size": " 100х80х7",
                "koef": "0.01758",
                "metrs": 1
            },
            {
                "size": " 100х80х8",
                "koef": "0.01973",
                "metrs": 1
            },
            {
                "size": " 100х80х9",
                "koef": "0.0218",
                "metrs": 1
            },
            {
                "size": " 110х30х2,5",
                "koef": "0.005214",
                "metrs": 1
            },
            {
                "size": " 120х40х2",
                "koef": "0.004844",
                "metrs": 1
            },
            {
                "size": " 120х40х2,5",
                "koef": "0.005999",
                "metrs": 1
            },
            {
                "size": " 120х40х3",
                "koef": "0.00707",
                "metrs": 1
            },
            {
                "size": " 120х40х3,5",
                "koef": "0.00816",
                "metrs": 1
            },
            {
                "size": " 120х40х4",
                "koef": "0.00922",
                "metrs": 1
            },
            {
                "size": " 120х40х4,5",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": " 120х40х5",
                "koef": "0.01127",
                "metrs": 1
            },
            {
                "size": " 120х50х2",
                "koef": "0.005158",
                "metrs": 1
            },
            {
                "size": " 120х50х2,5",
                "koef": "0.006392",
                "metrs": 1
            },
            {
                "size": " 120х50х3",
                "koef": "0.007603",
                "metrs": 1
            },
            {
                "size": " 120х50х4",
                "koef": "0.009958",
                "metrs": 1
            },
            {
                "size": " 120х60х2",
                "koef": "0.005472",
                "metrs": 1
            },
            {
                "size": " 120х60х2,8",
                "koef": "0.007561",
                "metrs": 1
            },
            {
                "size": " 120х60х3",
                "koef": "0.00801",
                "metrs": 1
            },
            {
                "size": " 120х60х3,5",
                "koef": "0.00926",
                "metrs": 1
            },
            {
                "size": " 120х60х4",
                "koef": "0.01048",
                "metrs": 1
            },
            {
                "size": " 120х60х4,5",
                "koef": "0.01167",
                "metrs": 1
            },
            {
                "size": " 120х60х5",
                "koef": "0.01284",
                "metrs": 1
            },
            {
                "size": " 120х60х6",
                "koef": "0.0151",
                "metrs": 1
            },
            {
                "size": " 120х80х2,35",
                "koef": "0.007131",
                "metrs": 1
            },
            {
                "size": " 120х80х2,5",
                "koef": "0.007569",
                "metrs": 1
            },
            {
                "size": " 120х80х2,8",
                "koef": "0.00844",
                "metrs": 1
            },
            {
                "size": " 120х80х3",
                "koef": "0.00896",
                "metrs": 1
            },
            {
                "size": " 120х80х3,5",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": " 120х80х4",
                "koef": "0.01173",
                "metrs": 1
            },
            {
                "size": " 120х80х4,5",
                "koef": "0.01308",
                "metrs": 1
            },
            {
                "size": " 120х80х5",
                "koef": "0.01441",
                "metrs": 1
            },
            {
                "size": " 120х80х5,5",
                "koef": "0.01571",
                "metrs": 1
            },
            {
                "size": " 120х80х6",
                "koef": "0.01698",
                "metrs": 1
            },
            {
                "size": " 120х80х6,5",
                "koef": "0.01794",
                "metrs": 1
            },
            {
                "size": " 120х80х7",
                "koef": "0.01912",
                "metrs": 1
            },
            {
                "size": " 120х80х8",
                "koef": "0.02225",
                "metrs": 1
            },
            {
                "size": " 120х80х9",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": " 120х80х10",
                "koef": "0.02691",
                "metrs": 1
            },
            {
                "size": " 120х100х4",
                "koef": "0.0131",
                "metrs": 1
            },
            {
                "size": " 120х100х4,5",
                "koef": "0.01463",
                "metrs": 1
            },
            {
                "size": " 120х100х5",
                "koef": "0.01615",
                "metrs": 1
            },
            {
                "size": " 120х100х6",
                "koef": "0.01911",
                "metrs": 1
            },
            {
                "size": " 120х100х7",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 120х100х8",
                "koef": "0.02476",
                "metrs": 1
            },
            {
                "size": " 120х100х9",
                "koef": "0.02745",
                "metrs": 1
            },
            {
                "size": " 120х100х10",
                "koef": "0.03005",
                "metrs": 1
            },
            {
                "size": " 140х40х4",
                "koef": "0.01059",
                "metrs": 1
            },
            {
                "size": " 140х60х2,5",
                "koef": "0.007569",
                "metrs": 1
            },
            {
                "size": " 140х60х3",
                "koef": "0.00896",
                "metrs": 1
            },
            {
                "size": " 140х60х3,5",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": " 140х60х4",
                "koef": "0.01173",
                "metrs": 1
            },
            {
                "size": " 140х60х4,5",
                "koef": "0.01308",
                "metrs": 1
            },
            {
                "size": " 140х60х5",
                "koef": "0.01441",
                "metrs": 1
            },
            {
                "size": " 140х60х5,5",
                "koef": "0.01571",
                "metrs": 1
            },
            {
                "size": " 140х60х6",
                "koef": "0.01698",
                "metrs": 1
            },
            {
                "size": " 140х60х7",
                "koef": "0.01912",
                "metrs": 1
            },
            {
                "size": " 140х80х4",
                "koef": "0.0131",
                "metrs": 1
            },
            {
                "size": " 140х80х4,5",
                "koef": "0.01463",
                "metrs": 1
            },
            {
                "size": " 140х80х5",
                "koef": "0.01615",
                "metrs": 1
            },
            {
                "size": " 140х80х6",
                "koef": "0.01911",
                "metrs": 1
            },
            {
                "size": " 140х80х7",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 140х80х8",
                "koef": "0.02476",
                "metrs": 1
            },
            {
                "size": " 140х80х9",
                "koef": "0.02745",
                "metrs": 1
            },
            {
                "size": " 140х80х10",
                "koef": "0.03005",
                "metrs": 1
            },
            {
                "size": " 140х100х3",
                "koef": "0.0109",
                "metrs": 1
            },
            {
                "size": " 140х100х3,5",
                "koef": "0.01264",
                "metrs": 1
            },
            {
                "size": " 140х100х4",
                "koef": "0.01425",
                "metrs": 1
            },
            {
                "size": " 140х100х4,5",
                "koef": "0.01591",
                "metrs": 1
            },
            {
                "size": " 140х100х5",
                "koef": "0.01755",
                "metrs": 1
            },
            {
                "size": " 140х100х5,5",
                "koef": "0.01937",
                "metrs": 1
            },
            {
                "size": " 140х100х6",
                "koef": "0.02075",
                "metrs": 1
            },
            {
                "size": " 140х100х6,5",
                "koef": "0.02203",
                "metrs": 1
            },
            {
                "size": " 140х100х7",
                "koef": "0.02352",
                "metrs": 1
            },
            {
                "size": " 140х100х8",
                "koef": "0.02727",
                "metrs": 1
            },
            {
                "size": " 140х100х9",
                "koef": "0.03027",
                "metrs": 1
            },
            {
                "size": " 140х100х10",
                "koef": "0.03319",
                "metrs": 1
            },
            {
                "size": " 140х110х4",
                "koef": "0.01487",
                "metrs": 1
            },
            {
                "size": " 140х110х4,5",
                "koef": "0.01662",
                "metrs": 1
            },
            {
                "size": " 140х110х5",
                "koef": "0.01833",
                "metrs": 1
            },
            {
                "size": " 140х110х6",
                "koef": "0.02169",
                "metrs": 1
            },
            {
                "size": " 140х110х7",
                "koef": "0.02461",
                "metrs": 1
            },
            {
                "size": " 140х110х8",
                "koef": "0.02853",
                "metrs": 1
            },
            {
                "size": " 140х110х9",
                "koef": "0.03169",
                "metrs": 1
            },
            {
                "size": " 140х110х10",
                "koef": "0.03476",
                "metrs": 1
            },
            {
                "size": " 140х120х4",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": " 140х120х4,5",
                "koef": "0.01732",
                "metrs": 1
            },
            {
                "size": " 140х120х5",
                "koef": "0.01912",
                "metrs": 1
            },
            {
                "size": " 140х120х5,5",
                "koef": "0.02089",
                "metrs": 1
            },
            {
                "size": " 140х120х6",
                "koef": "0.02263",
                "metrs": 1
            },
            {
                "size": " 140х120х7",
                "koef": "0.02571",
                "metrs": 1
            },
            {
                "size": " 140х120х8",
                "koef": "0.02892",
                "metrs": 1
            },
            {
                "size": " 140х120х9",
                "koef": "0.0331",
                "metrs": 1
            },
            {
                "size": " 140х120х10",
                "koef": "0.03633",
                "metrs": 1
            },
            {
                "size": " 140х120х11",
                "koef": "0.03947",
                "metrs": 1
            },
            {
                "size": " 150х80х4",
                "koef": "0.01373",
                "metrs": 1
            },
            {
                "size": " 150х80х4,5",
                "koef": "0.01534",
                "metrs": 1
            },
            {
                "size": " 150х80х5",
                "koef": "0.01693",
                "metrs": 1
            },
            {
                "size": " 150х80х6",
                "koef": "0.02025",
                "metrs": 1
            },
            {
                "size": " 150х80х7",
                "koef": "0.02308",
                "metrs": 1
            },
            {
                "size": " 150х80х8",
                "koef": "0.02601",
                "metrs": 1
            },
            {
                "size": " 150х80х9",
                "koef": "0.02886",
                "metrs": 1
            },
            {
                "size": " 150х100х4",
                "koef": "0.01487",
                "metrs": 1
            },
            {
                "size": " 150х100х4,5",
                "koef": "0.01662",
                "metrs": 1
            },
            {
                "size": " 150х100х5",
                "koef": "0.01833",
                "metrs": 1
            },
            {
                "size": " 150х100х6",
                "koef": "0.02169",
                "metrs": 1
            },
            {
                "size": " 150х100х7",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": " 150х100х8",
                "koef": "0.02853",
                "metrs": 1
            },
            {
                "size": " 150х100х9",
                "koef": "0.03169",
                "metrs": 1
            },
            {
                "size": " 150х100х10",
                "koef": "0.03476",
                "metrs": 1
            },
            {
                "size": " 150х120х4",
                "koef": "0.01624",
                "metrs": 1
            },
            {
                "size": " 150х120х4,5",
                "koef": "0.01817",
                "metrs": 1
            },
            {
                "size": " 150х120х5",
                "koef": "0.02007",
                "metrs": 1
            },
            {
                "size": " 150х120х7",
                "koef": "0.02747",
                "metrs": 1
            },
            {
                "size": " 150х120х8",
                "koef": "0.03104",
                "metrs": 1
            },
            {
                "size": " 150х120х9",
                "koef": "0.03451",
                "metrs": 1
            },
            {
                "size": " 150х120х10",
                "koef": "0.0379",
                "metrs": 1
            },
            {
                "size": " 150х120х11",
                "koef": "0.0412",
                "metrs": 1
            },
            {
                "size": " 150х130х4",
                "koef": "0.00676",
                "metrs": 1
            },
            {
                "size": " 150х130х4,5",
                "koef": "0.01874",
                "metrs": 1
            },
            {
                "size": " 150х130х5",
                "koef": "0.02069",
                "metrs": 1
            },
            {
                "size": " 150х130х6",
                "koef": "0.02452",
                "metrs": 1
            },
            {
                "size": " 150х130х7",
                "koef": "0.02791",
                "metrs": 1
            },
            {
                "size": " 150х130х8",
                "koef": "0.03143",
                "metrs": 1
            },
            {
                "size": " 150х130х9",
                "koef": "0.03593",
                "metrs": 1
            },
            {
                "size": " 150х130х10",
                "koef": "0.03947",
                "metrs": 1
            },
            {
                "size": " 150х130х11",
                "koef": "0.04292",
                "metrs": 1
            },
            {
                "size": " 160х60х4",
                "koef": "0.0131",
                "metrs": 1
            },
            {
                "size": " 160х80х4",
                "koef": "0.01425",
                "metrs": 1
            },
            {
                "size": " 160х80х4,5",
                "koef": "0.01591",
                "metrs": 1
            },
            {
                "size": " 160х80х5",
                "koef": "0.01755",
                "metrs": 1
            },
            {
                "size": " 160х80х6",
                "koef": "0.02075",
                "metrs": 1
            },
            {
                "size": " 160х80х7",
                "koef": "0.02352",
                "metrs": 1
            },
            {
                "size": " 160х80х8",
                "koef": "0.02727",
                "metrs": 1
            },
            {
                "size": " 160х80х9",
                "koef": "0.03027",
                "metrs": 1
            },
            {
                "size": " 160х80х10",
                "koef": "0.03319",
                "metrs": 1
            },
            {
                "size": " 160х100х4",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": " 160х100х4,5",
                "koef": "0.01732",
                "metrs": 1
            },
            {
                "size": " 160х100х5",
                "koef": "0.01912",
                "metrs": 1
            },
            {
                "size": " 160х100х6",
                "koef": "0.02263",
                "metrs": 1
            },
            {
                "size": " 160х100х7",
                "koef": "0.02571",
                "metrs": 1
            },
            {
                "size": " 160х100х8",
                "koef": "0.02892",
                "metrs": 1
            },
            {
                "size": " 160х100х9",
                "koef": "0.0331",
                "metrs": 1
            },
            {
                "size": " 160х100х10",
                "koef": "0.03633",
                "metrs": 1
            },
            {
                "size": " 160х120х4",
                "koef": "0.01676",
                "metrs": 1
            },
            {
                "size": " 160х120х4,5",
                "koef": "0.01874",
                "metrs": 1
            },
            {
                "size": " 160х120х5",
                "koef": "0.02069",
                "metrs": 1
            },
            {
                "size": " 160х120х5,5",
                "koef": "0.02262",
                "metrs": 1
            },
            {
                "size": " 160х120х6",
                "koef": "0.02452",
                "metrs": 1
            },
            {
                "size": " 160х120х6,5",
                "koef": "0.02611",
                "metrs": 1
            },
            {
                "size": " 160х120х7",
                "koef": "0.02791",
                "metrs": 1
            },
            {
                "size": " 160х120х7,5",
                "koef": "0.02969",
                "metrs": 1
            },
            {
                "size": " 160х120х8",
                "koef": "0.03143",
                "metrs": 1
            },
            {
                "size": " 160х120х9",
                "koef": "0.03593",
                "metrs": 1
            },
            {
                "size": " 160х120х10",
                "koef": "0.03947",
                "metrs": 1
            },
            {
                "size": " 160х120х11",
                "koef": "0.04292",
                "metrs": 1
            },
            {
                "size": " 160х140х4",
                "koef": "0.01801",
                "metrs": 1
            },
            {
                "size": " 160х140х4,5",
                "koef": "0.02015",
                "metrs": 1
            },
            {
                "size": " 160х140х5",
                "koef": "0.02226",
                "metrs": 1
            },
            {
                "size": " 160х140х5,5",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": " 160х140х6",
                "koef": "0.0264",
                "metrs": 1
            },
            {
                "size": " 160х140х6,5",
                "koef": "0.02815",
                "metrs": 1
            },
            {
                "size": " 160х140х7",
                "koef": "0.03011",
                "metrs": 1
            },
            {
                "size": " 160х140х7,5",
                "koef": "0.03204",
                "metrs": 1
            },
            {
                "size": " 160х140х8",
                "koef": "0.03395",
                "metrs": 1
            },
            {
                "size": " 160х140х9",
                "koef": "0.03875",
                "metrs": 1
            },
            {
                "size": " 160х140х10",
                "koef": "0.04261",
                "metrs": 1
            },
            {
                "size": " 160х140х11",
                "koef": "0.04638",
                "metrs": 1
            },
            {
                "size": " 160х140х12",
                "koef": "0.05005",
                "metrs": 1
            },
            {
                "size": " 180х60х4",
                "koef": "0.01425",
                "metrs": 1
            },
            {
                "size": " 180х60х5",
                "koef": "0.01755",
                "metrs": 1
            },
            {
                "size": " 180х80х4",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": " 180х80х4,5",
                "koef": "0.01732",
                "metrs": 1
            },
            {
                "size": " 180х80х5",
                "koef": "0.01912",
                "metrs": 1
            },
            {
                "size": " 180х80х6",
                "koef": "0.02263",
                "metrs": 1
            },
            {
                "size": " 180х80х7",
                "koef": "0.02571",
                "metrs": 1
            },
            {
                "size": " 180х80х8",
                "koef": "0.02892",
                "metrs": 1
            },
            {
                "size": " 180х80х9",
                "koef": "0.0331",
                "metrs": 1
            },
            {
                "size": " 180х80х10",
                "koef": "0.03633",
                "metrs": 1
            },
            {
                "size": " 180х100х4",
                "koef": "0.01676",
                "metrs": 1
            },
            {
                "size": " 180х100х4,5",
                "koef": "0.01874",
                "metrs": 1
            },
            {
                "size": " 180х100х5",
                "koef": "0.02069",
                "metrs": 1
            },
            {
                "size": " 180х100х6",
                "koef": "0.02452",
                "metrs": 1
            },
            {
                "size": " 180х100х7",
                "koef": "0.02791",
                "metrs": 1
            },
            {
                "size": " 180х100х8",
                "koef": "0.03143",
                "metrs": 1
            },
            {
                "size": " 180х100х9",
                "koef": "0.03593",
                "metrs": 1
            },
            {
                "size": " 180х100х10",
                "koef": "0.03947",
                "metrs": 1
            },
            {
                "size": " 180х120х4",
                "koef": "0.01801",
                "metrs": 1
            },
            {
                "size": " 180х120х4,5",
                "koef": "0.02015",
                "metrs": 1
            },
            {
                "size": " 180х120х5",
                "koef": "0.02226",
                "metrs": 1
            },
            {
                "size": " 180х120х6",
                "koef": "0.0264",
                "metrs": 1
            },
            {
                "size": " 180х120х7",
                "koef": "0.03011",
                "metrs": 1
            },
            {
                "size": " 180х120х8",
                "koef": "0.03394",
                "metrs": 1
            },
            {
                "size": " 180х120х9",
                "koef": "0.03875",
                "metrs": 1
            },
            {
                "size": " 180х120х10",
                "koef": "0.04261",
                "metrs": 1
            },
            {
                "size": " 180х120х11",
                "koef": "0.04638",
                "metrs": 1
            },
            {
                "size": " 180х125х4",
                "koef": "0.01844",
                "metrs": 1
            },
            {
                "size": " 180х125х4,5",
                "koef": "0.02064",
                "metrs": 1
            },
            {
                "size": " 180х125х5",
                "koef": "0.02282",
                "metrs": 1
            },
            {
                "size": " 180х125х6",
                "koef": "0.02711",
                "metrs": 1
            },
            {
                "size": " 180х125х7",
                "koef": "0.03132",
                "metrs": 1
            },
            {
                "size": " 180х125х8",
                "koef": "0.03543",
                "metrs": 1
            },
            {
                "size": " 180х125х9",
                "koef": "0.03946",
                "metrs": 1
            },
            {
                "size": " 180х125х10",
                "koef": "0.04339",
                "metrs": 1
            },
            {
                "size": " 180х140х4",
                "koef": "0.01927",
                "metrs": 1
            },
            {
                "size": " 180х140х4,5",
                "koef": "0.02156",
                "metrs": 1
            },
            {
                "size": " 180х140х5",
                "koef": "0.02383",
                "metrs": 1
            },
            {
                "size": " 180х140х5,5",
                "koef": "0.02607",
                "metrs": 1
            },
            {
                "size": " 180х140х6",
                "koef": "0.02829",
                "metrs": 1
            },
            {
                "size": " 180х140х6,5",
                "koef": "0.03019",
                "metrs": 1
            },
            {
                "size": " 180х140х7",
                "koef": "0.03231",
                "metrs": 1
            },
            {
                "size": " 180х140х7,5",
                "koef": "0.0344",
                "metrs": 1
            },
            {
                "size": " 180х140х8",
                "koef": "0.03646",
                "metrs": 1
            },
            {
                "size": " 180х140х9",
                "koef": "0.04158",
                "metrs": 1
            },
            {
                "size": " 180х140х10",
                "koef": "0.04575",
                "metrs": 1
            },
            {
                "size": " 180х140х11",
                "koef": "0.04983",
                "metrs": 1
            },
            {
                "size": " 180х140х12",
                "koef": "0.05382",
                "metrs": 1
            },
            {
                "size": " 180х160х4",
                "koef": "0.02063",
                "metrs": 1
            },
            {
                "size": " 180х160х4,5",
                "koef": "0.02311",
                "metrs": 1
            },
            {
                "size": " 180х160х5",
                "koef": "0.02557",
                "metrs": 1
            },
            {
                "size": " 180х160х6",
                "koef": "0.03041",
                "metrs": 1
            },
            {
                "size": " 180х160х7",
                "koef": "0.03517",
                "metrs": 1
            },
            {
                "size": " 180х160х8",
                "koef": "0.03983",
                "metrs": 1
            },
            {
                "size": " 180х160х9",
                "koef": "0.0444",
                "metrs": 1
            },
            {
                "size": " 180х160х10",
                "koef": "0.04889",
                "metrs": 1
            },
            {
                "size": " 180х160х11",
                "koef": "0.05328",
                "metrs": 1
            },
            {
                "size": " 180х160х12",
                "koef": "0.05759",
                "metrs": 1
            },
            {
                "size": " 200х80х4",
                "koef": "0.01676",
                "metrs": 1
            },
            {
                "size": " 200х80х4,5",
                "koef": "0.01874",
                "metrs": 1
            },
            {
                "size": " 200х80х5",
                "koef": "0.02069",
                "metrs": 1
            },
            {
                "size": " 200х80х6",
                "koef": "0.02452",
                "metrs": 1
            },
            {
                "size": " 200х80х7",
                "koef": "0.02791",
                "metrs": 1
            },
            {
                "size": " 200х80х8",
                "koef": "0.03143",
                "metrs": 1
            },
            {
                "size": " 200х80х9",
                "koef": "0.03593",
                "metrs": 1
            },
            {
                "size": " 200х100х4",
                "koef": "0.01801",
                "metrs": 1
            },
            {
                "size": " 200х100х4,5",
                "koef": "0.02015",
                "metrs": 1
            },
            {
                "size": " 200х100х5",
                "koef": "0.02226",
                "metrs": 1
            },
            {
                "size": " 200х100х6",
                "koef": "0.0264",
                "metrs": 1
            },
            {
                "size": " 200х100х7",
                "koef": "0.03011",
                "metrs": 1
            },
            {
                "size": " 200х100х8",
                "koef": "0.03395",
                "metrs": 1
            },
            {
                "size": " 200х100х9",
                "koef": "0.03875",
                "metrs": 1
            },
            {
                "size": " 200х100х10",
                "koef": "0.04261",
                "metrs": 1
            },
            {
                "size": " 200х120х4",
                "koef": "0.01927",
                "metrs": 1
            },
            {
                "size": " 200х120х4,5",
                "koef": "0.02156",
                "metrs": 1
            },
            {
                "size": " 200х120х5",
                "koef": "0.02383",
                "metrs": 1
            },
            {
                "size": " 200х120х5,5",
                "koef": "0.02607",
                "metrs": 1
            },
            {
                "size": " 200х120х6",
                "koef": "0.02829",
                "metrs": 1
            },
            {
                "size": " 200х120х6,5",
                "koef": "0.03019",
                "metrs": 1
            },
            {
                "size": " 200х120х7",
                "koef": "0.03231",
                "metrs": 1
            },
            {
                "size": " 200х120х7,5",
                "koef": "0.0344",
                "metrs": 1
            },
            {
                "size": " 200х120х8",
                "koef": "0.03646",
                "metrs": 1
            },
            {
                "size": " 200х120х9",
                "koef": "0.04158",
                "metrs": 1
            },
            {
                "size": " 200х120х10",
                "koef": "0.04575",
                "metrs": 1
            },
            {
                "size": " 200х120х11",
                "koef": "0.04983",
                "metrs": 1
            },
            {
                "size": " 200х160х4",
                "koef": "0.02189",
                "metrs": 1
            },
            {
                "size": " 200х160х4,5",
                "koef": "0.02452",
                "metrs": 1
            },
            {
                "size": " 200х160х5",
                "koef": "0.02697",
                "metrs": 1
            },
            {
                "size": " 200х160х5,5",
                "koef": "2.952",
                "metrs": 1
            },
            {
                "size": " 200х160х6",
                "koef": "0.03205",
                "metrs": 1
            },
            {
                "size": " 200х160х6,5",
                "koef": "0.03427",
                "metrs": 1
            },
            {
                "size": " 200х160х7",
                "koef": "0.0367",
                "metrs": 1
            },
            {
                "size": " 200х160х7,5",
                "koef": "0.03911",
                "metrs": 1
            },
            {
                "size": " 200х160х8",
                "koef": "0.04148",
                "metrs": 1
            },
            {
                "size": " 200х160х9",
                "koef": "0.04614",
                "metrs": 1
            },
            {
                "size": " 200х160х10",
                "koef": "0.05068",
                "metrs": 1
            },
            {
                "size": " 200х160х11",
                "koef": "0.05429",
                "metrs": 1
            },
            {
                "size": " 200х160х12",
                "koef": "0.05844",
                "metrs": 1
            },
            {
                "size": " 240х120х5",
                "koef": "0.02697",
                "metrs": 1
            },
            {
                "size": " 240х120х6",
                "koef": "0.03205",
                "metrs": 1
            },
            {
                "size": " 240х120х7",
                "koef": "0.0367",
                "metrs": 1
            },
            {
                "size": " 240х120х8",
                "koef": "0.04148",
                "metrs": 1
            },
            {
                "size": " 240х160х5",
                "koef": "0.03011",
                "metrs": 1
            },
            {
                "size": " 240х160х6",
                "koef": "0.03582",
                "metrs": 1
            },
            {
                "size": " 240х160х7",
                "koef": "0.0411",
                "metrs": 1
            },
            {
                "size": " 240х160х7,5",
                "koef": "0.04382",
                "metrs": 1
            },
            {
                "size": " 240х160х8",
                "koef": "0.04651",
                "metrs": 1
            },
            {
                "size": " 240х160х9",
                "koef": "0.05179",
                "metrs": 1
            },
            {
                "size": " 240х160х10",
                "koef": "0.05696",
                "metrs": 1
            },
            {
                "size": " 240х160х12",
                "koef": "0.06599",
                "metrs": 1
            },
            {
                "size": " 250х150х5",
                "koef": "0.03011",
                "metrs": 1
            },
            {
                "size": " 250х150х6",
                "koef": "0.03582",
                "metrs": 1
            },
            {
                "size": " 250х150х7",
                "koef": "0.0411",
                "metrs": 1
            },
            {
                "size": " 250х150х8",
                "koef": "0.04651",
                "metrs": 1
            },
            {
                "size": " 250х150х10",
                "koef": "0.05696",
                "metrs": 1
            },
            {
                "size": " 250х150х12",
                "koef": "0.06598",
                "metrs": 1
            },
            {
                "size": " 300х100х6",
                "koef": "0.03582",
                "metrs": 1
            },
            {
                "size": " 300х100х8",
                "koef": "0.04651",
                "metrs": 1
            },
            {
                "size": " 300х200х6",
                "koef": "0.04524",
                "metrs": 1
            },
            {
                "size": " 300х200х6,5",
                "koef": "0.04856",
                "metrs": 1
            },
            {
                "size": " 300х200х7",
                "koef": "0.05209",
                "metrs": 1
            },
            {
                "size": " 300х200х7,5",
                "koef": "0.05559",
                "metrs": 1
            },
            {
                "size": " 300х200х8",
                "koef": "0.05907",
                "metrs": 1
            },
            {
                "size": " 300х200х8,5",
                "koef": "0.06251",
                "metrs": 1
            },
            {
                "size": " 300х200х9",
                "koef": "0.06592",
                "metrs": 1
            },
            {
                "size": " 300х200х9,5",
                "koef": "0.06931",
                "metrs": 1
            },
            {
                "size": " 300х200х10",
                "koef": "0.07266",
                "metrs": 1
            },
            {
                "size": " 300х200х10,5",
                "koef": "0.07525",
                "metrs": 1
            },
            {
                "size": " 300х200х11",
                "koef": "0.07847",
                "metrs": 1
            },
            {
                "size": " 300х200х11,5",
                "koef": "0.08167",
                "metrs": 1
            },
            {
                "size": " 300х200х12",
                "koef": "0.08483",
                "metrs": 1
            },
            {
                "size": " 350х250х8",
                "koef": "0.07249",
                "metrs": 1
            },
            {
                "size": " 350х250х10",
                "koef": "0.08971",
                "metrs": 1
            },
            {
                "size": " 350х250х12",
                "koef": "0.1066",
                "metrs": 1
            },
            {
                "size": " 400х200х6",
                "koef": "0.0549",
                "metrs": 1
            }
        ],
        "Труба профильная прямоугольная оцинкованная": [
            {
                "size": " 20х10х0,6",
                "koef": "0.000274",
                "metrs": 1
            },
            {
                "size": " 20х10х0,7",
                "koef": "0.000317",
                "metrs": 1
            },
            {
                "size": " 20х10х0,8",
                "koef": "0.000359",
                "metrs": 1
            },
            {
                "size": " 20х10х1",
                "koef": "0.000439",
                "metrs": 1
            },
            {
                "size": " 20х10х1,1",
                "koef": "0.000478",
                "metrs": 1
            },
            {
                "size": " 20х10х1,2",
                "koef": "0.000516",
                "metrs": 1
            },
            {
                "size": " 20х10х1,3",
                "koef": "0.000553",
                "metrs": 1
            },
            {
                "size": " 20х10х1,5",
                "koef": "0.000623",
                "metrs": 1
            },
            {
                "size": " 20х15х0,6",
                "koef": "0.000323",
                "metrs": 1
            },
            {
                "size": " 20х15х0,7",
                "koef": "0.000374",
                "metrs": 1
            },
            {
                "size": " 20х15х0,8",
                "koef": "0.000423",
                "metrs": 1
            },
            {
                "size": " 20х15х1",
                "koef": "0.00052",
                "metrs": 1
            },
            {
                "size": " 20х15х1,2",
                "koef": "0.000613",
                "metrs": 1
            },
            {
                "size": " 20х15х1,5",
                "koef": "0.000745",
                "metrs": 1
            },
            {
                "size": " 25х10х0,6",
                "koef": "0.000323",
                "metrs": 1
            },
            {
                "size": " 25х10х0,7",
                "koef": "0.000374",
                "metrs": 1
            },
            {
                "size": " 25х10х0,8",
                "koef": "0.000423",
                "metrs": 1
            },
            {
                "size": " 25х10х1",
                "koef": "0.00052",
                "metrs": 1
            },
            {
                "size": " 25х10х1,1",
                "koef": "0.000567",
                "metrs": 1
            },
            {
                "size": " 25х10х1,2",
                "koef": "0.000613",
                "metrs": 1
            },
            {
                "size": " 25х10х1,3",
                "koef": "0.000658",
                "metrs": 1
            },
            {
                "size": " 25х10х1,5",
                "koef": "0.000745",
                "metrs": 1
            },
            {
                "size": " 25х15х0,6",
                "koef": "0.000371",
                "metrs": 1
            },
            {
                "size": " 25х15х0,7",
                "koef": "0.00043",
                "metrs": 1
            },
            {
                "size": " 25х15х0,8",
                "koef": "0.000488",
                "metrs": 1
            },
            {
                "size": " 25х15х1",
                "koef": "0.0006",
                "metrs": 1
            },
            {
                "size": " 25х15х1,1",
                "koef": "0.000656",
                "metrs": 1
            },
            {
                "size": " 25х15х1,2",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 25х15х1,3",
                "koef": "0.000763",
                "metrs": 1
            },
            {
                "size": " 25х15х1,5",
                "koef": "0.000866",
                "metrs": 1
            },
            {
                "size": " 25х15х1,8",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": " 25х15х2",
                "koef": "0.001112",
                "metrs": 1
            },
            {
                "size": " 28х25х1",
                "koef": "0.000811",
                "metrs": 1
            },
            {
                "size": " 28х25х1,1",
                "koef": "0.000887",
                "metrs": 1
            },
            {
                "size": " 28х25х1,2",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 28х25х1,3",
                "koef": "0.001036",
                "metrs": 1
            },
            {
                "size": " 28х25х1,5",
                "koef": "0.001184",
                "metrs": 1
            },
            {
                "size": " 28х25х1,8",
                "koef": "0.001393",
                "metrs": 1
            },
            {
                "size": " 28х25х2",
                "koef": "0.001535",
                "metrs": 1
            },
            {
                "size": " 30х10х0,6",
                "koef": "0.000371",
                "metrs": 1
            },
            {
                "size": " 30х10х0,7",
                "koef": "0.00043",
                "metrs": 1
            },
            {
                "size": " 30х10х0,8",
                "koef": "0.000488",
                "metrs": 1
            },
            {
                "size": " 30х10х1",
                "koef": "0.0006",
                "metrs": 1
            },
            {
                "size": " 30х10х1,2",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 30х10х1,5",
                "koef": "0.000866",
                "metrs": 1
            },
            {
                "size": " 30х15х0,6",
                "koef": "0.00042",
                "metrs": 1
            },
            {
                "size": " 30х15х0,7",
                "koef": "0.000487",
                "metrs": 1
            },
            {
                "size": " 30х15х0,8",
                "koef": "0.000552",
                "metrs": 1
            },
            {
                "size": " 30х15х0,9",
                "koef": "0.000618",
                "metrs": 1
            },
            {
                "size": " 30х15х1",
                "koef": "0.000681",
                "metrs": 1
            },
            {
                "size": " 30х15х1,1",
                "koef": "0.000745",
                "metrs": 1
            },
            {
                "size": " 30х15х1,2",
                "koef": "0.000806",
                "metrs": 1
            },
            {
                "size": " 30х15х1,3",
                "koef": "0.000868",
                "metrs": 1
            },
            {
                "size": " 30х15х1,5",
                "koef": "0.000988",
                "metrs": 1
            },
            {
                "size": " 30х15х1,8",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": " 30х15х2",
                "koef": "0.001267",
                "metrs": 1
            },
            {
                "size": " 30х20х0,6",
                "koef": "0.000468",
                "metrs": 1
            },
            {
                "size": " 30х20х0,7",
                "koef": "0.000543",
                "metrs": 1
            },
            {
                "size": " 30х20х0,8",
                "koef": "0.000617",
                "metrs": 1
            },
            {
                "size": " 30х20х0,9",
                "koef": "0.00069",
                "metrs": 1
            },
            {
                "size": " 30х20х1",
                "koef": "0.000762",
                "metrs": 1
            },
            {
                "size": " 30х20х1,1",
                "koef": "0.000833",
                "metrs": 1
            },
            {
                "size": " 30х20х1,2",
                "koef": "0.000903",
                "metrs": 1
            },
            {
                "size": " 30х20х1,3",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": " 30х20х1,5",
                "koef": "0.001112",
                "metrs": 1
            },
            {
                "size": " 30х20х1,8",
                "koef": "0.001306",
                "metrs": 1
            },
            {
                "size": " 30х20х2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 35х15х0,8",
                "koef": "0.000617",
                "metrs": 1
            },
            {
                "size": " 35х15х0,9",
                "koef": "0.00069",
                "metrs": 1
            },
            {
                "size": " 35х15х1",
                "koef": "0.000762",
                "metrs": 1
            },
            {
                "size": " 35х15х1,2",
                "koef": "0.000903",
                "metrs": 1
            },
            {
                "size": " 35х15х1,5",
                "koef": "0.001112",
                "metrs": 1
            },
            {
                "size": " 35х15х2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 40х10х0,8",
                "koef": "0.000617",
                "metrs": 1
            },
            {
                "size": " 40х10х0,9",
                "koef": "0.00069",
                "metrs": 1
            },
            {
                "size": " 40х10х1",
                "koef": "0.000762",
                "metrs": 1
            },
            {
                "size": " 40х10х1,1",
                "koef": "0.000833",
                "metrs": 1
            },
            {
                "size": " 40х10х1,2",
                "koef": "0.000904",
                "metrs": 1
            },
            {
                "size": " 40х10х1,3",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": " 40х10х1,5",
                "koef": "0.001109",
                "metrs": 1
            },
            {
                "size": " 40х10х2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 40х20х0,6",
                "koef": "0.000566",
                "metrs": 1
            },
            {
                "size": " 40х20х0,7",
                "koef": "0.000657",
                "metrs": 1
            },
            {
                "size": " 40х20х0,8",
                "koef": "0.000747",
                "metrs": 1
            },
            {
                "size": " 40х20х0,9",
                "koef": "0.000835",
                "metrs": 1
            },
            {
                "size": " 40х20х1",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": " 40х20х1,1",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": " 40х20х1,2",
                "koef": "0.001102",
                "metrs": 1
            },
            {
                "size": " 40х20х1,3",
                "koef": "0.001183",
                "metrs": 1
            },
            {
                "size": " 40х20х1,4",
                "koef": "0.001268",
                "metrs": 1
            },
            {
                "size": " 40х20х1,5",
                "koef": "0.001349",
                "metrs": 1
            },
            {
                "size": " 40х20х1,8",
                "koef": "0.001597",
                "metrs": 1
            },
            {
                "size": " 40х20х2",
                "koef": "0.001751",
                "metrs": 1
            },
            {
                "size": " 40х25х0,8",
                "koef": "0.000811",
                "metrs": 1
            },
            {
                "size": " 40х25х0,9",
                "koef": "0.000909",
                "metrs": 1
            },
            {
                "size": " 40х25х1",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 40х25х1,1",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 40х25х1,2",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 40х25х1,3",
                "koef": "0.001288",
                "metrs": 1
            },
            {
                "size": " 40х25х1,5",
                "koef": "0.001473",
                "metrs": 1
            },
            {
                "size": " 40х25х1,8",
                "koef": "0.001742",
                "metrs": 1
            },
            {
                "size": " 40х25х2",
                "koef": "0.001916",
                "metrs": 1
            },
            {
                "size": " 40х30х1",
                "koef": "0.001086",
                "metrs": 1
            },
            {
                "size": " 40х30х1,2",
                "koef": "0.001292",
                "metrs": 1
            },
            {
                "size": " 40х30х1,5",
                "koef": "0.001596",
                "metrs": 1
            },
            {
                "size": " 40х30х2",
                "koef": "0.002081",
                "metrs": 1
            },
            {
                "size": " 50х25х1",
                "koef": "0.001167",
                "metrs": 1
            },
            {
                "size": " 50х25х1,2",
                "koef": "0.001389",
                "metrs": 1
            },
            {
                "size": " 50х25х1,5",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 50х25х1,8",
                "koef": "0.002033",
                "metrs": 1
            },
            {
                "size": " 50х25х2",
                "koef": "0.002214",
                "metrs": 1
            },
            {
                "size": " 50х30х1,2",
                "koef": "0.001486",
                "metrs": 1
            },
            {
                "size": " 50х30х1,5",
                "koef": "0.001833",
                "metrs": 1
            },
            {
                "size": " 50х30х1,8",
                "koef": "0.002179",
                "metrs": 1
            },
            {
                "size": " 50х30х2",
                "koef": "0.002379",
                "metrs": 1
            },
            {
                "size": " 50х40х1",
                "koef": "0.001409",
                "metrs": 1
            },
            {
                "size": " 50х40х1,1",
                "koef": "0.001545",
                "metrs": 1
            },
            {
                "size": " 50х40х1,2",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 50х40х1,3",
                "koef": "0.001814",
                "metrs": 1
            },
            {
                "size": " 50х40х1,4",
                "koef": "0.001947",
                "metrs": 1
            },
            {
                "size": " 50х40х1,5",
                "koef": "0.002079",
                "metrs": 1
            },
            {
                "size": " 50х40х2",
                "koef": "0.002699",
                "metrs": 1
            },
            {
                "size": " 50х40х2,5",
                "koef": "0.003306",
                "metrs": 1
            },
            {
                "size": " 60х10х1",
                "koef": "0.001086",
                "metrs": 1
            },
            {
                "size": " 60х10х1,2",
                "koef": "0.001292",
                "metrs": 1
            },
            {
                "size": " 60х10х1,5",
                "koef": "0.001594",
                "metrs": 1
            },
            {
                "size": " 60х10х2",
                "koef": "0.002079",
                "metrs": 1
            },
            {
                "size": " 60х20х1,2",
                "koef": "0.001486",
                "metrs": 1
            },
            {
                "size": " 60х20х1,5",
                "koef": "0.001836",
                "metrs": 1
            },
            {
                "size": " 60х20х2",
                "koef": "0.0024",
                "metrs": 1
            },
            {
                "size": " 60х30х1",
                "koef": "0.001409",
                "metrs": 1
            },
            {
                "size": " 60х30х1,1",
                "koef": "0.001545",
                "metrs": 1
            },
            {
                "size": " 60х30х1,2",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 60х30х1,3",
                "koef": "0.001814",
                "metrs": 1
            },
            {
                "size": " 60х30х1,4",
                "koef": "0.001947",
                "metrs": 1
            },
            {
                "size": " 60х30х1,5",
                "koef": "0.002081",
                "metrs": 1
            },
            {
                "size": " 60х30х1,8",
                "koef": "0.00247",
                "metrs": 1
            },
            {
                "size": " 60х30х2",
                "koef": "0.002709",
                "metrs": 1
            },
            {
                "size": " 60х40х1",
                "koef": "0.001571",
                "metrs": 1
            },
            {
                "size": " 60х40х1,2",
                "koef": "0.001874",
                "metrs": 1
            },
            {
                "size": " 60х40х1,5",
                "koef": "0.002318",
                "metrs": 1
            },
            {
                "size": " 60х40х1,8",
                "koef": "0.002761",
                "metrs": 1
            },
            {
                "size": " 60х40х2",
                "koef": "0.003018",
                "metrs": 1
            },
            {
                "size": " 60х40х2,5",
                "koef": "0.003708",
                "metrs": 1
            },
            {
                "size": " 80х20х1",
                "koef": "0.001571",
                "metrs": 1
            },
            {
                "size": " 80х20х1,2",
                "koef": "0.001874",
                "metrs": 1
            },
            {
                "size": " 80х40х1,5",
                "koef": "0.002807",
                "metrs": 1
            },
            {
                "size": " 80х40х1,8",
                "koef": "0.003343",
                "metrs": 1
            },
            {
                "size": " 80х40х2",
                "koef": "0.003667",
                "metrs": 1
            },
            {
                "size": " 80х40х2,5",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": " 80х60х1,5",
                "koef": "0.003292",
                "metrs": 1
            },
            {
                "size": " 80х60х2",
                "koef": "0.004316",
                "metrs": 1
            },
            {
                "size": " 80х60х2,5",
                "koef": "0.005325",
                "metrs": 1
            },
            {
                "size": " 120х80х4",
                "koef": "0.01208",
                "metrs": 1
            }
        ],
        "Труба электросварная": [
            {
                "size": "10х0,4",
                "koef": "0.000095",
                "metrs": 1
            },
            {
                "size": "10х0,5",
                "koef": "0.000117",
                "metrs": 1
            },
            {
                "size": "10х0,6",
                "koef": "0.000139",
                "metrs": 1
            },
            {
                "size": "10х0,7",
                "koef": "0.000161",
                "metrs": 1
            },
            {
                "size": "10х0,8",
                "koef": "0.000182",
                "metrs": 1
            },
            {
                "size": "10х0,9",
                "koef": "0.000202",
                "metrs": 1
            },
            {
                "size": "10х1",
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": "10х1,1",
                "koef": "0.000241",
                "metrs": 1
            },
            {
                "size": "10х1,2",
                "koef": "0.00026",
                "metrs": 1
            },
            {
                "size": "10х1,3",
                "koef": "0.000279",
                "metrs": 1
            },
            {
                "size": "10х1,5",
                "koef": "0.000314",
                "metrs": 1
            },
            {
                "size": "12х0,4",
                "koef": "0.000114",
                "metrs": 1
            },
            {
                "size": "12х0,5",
                "koef": "0.000142",
                "metrs": 1
            },
            {
                "size": "12х0,6",
                "koef": "0.000169",
                "metrs": 1
            },
            {
                "size": "12х0,7",
                "koef": "0.000195",
                "metrs": 1
            },
            {
                "size": "12х0,8",
                "koef": "0.000221",
                "metrs": 1
            },
            {
                "size": "12х0,9",
                "koef": "0.000246",
                "metrs": 1
            },
            {
                "size": "12х1",
                "koef": "0.000271",
                "metrs": 1
            },
            {
                "size": "12х1,1",
                "koef": "0.000296",
                "metrs": 1
            },
            {
                "size": "12х1,2",
                "koef": "0.00032",
                "metrs": 1
            },
            {
                "size": "12х1,3",
                "koef": "0.000343",
                "metrs": 1
            },
            {
                "size": "12х1,5",
                "koef": "0.000388",
                "metrs": 1
            },
            {
                "size": "13х0,6",
                "koef": "0.000183",
                "metrs": 1
            },
            {
                "size": "13х0,7",
                "koef": "0.000212",
                "metrs": 1
            },
            {
                "size": "13х0,8",
                "koef": "0.000241",
                "metrs": 1
            },
            {
                "size": "13х1",
                "koef": "0.000296",
                "metrs": 1
            },
            {
                "size": "13х1,2",
                "koef": "0.000349",
                "metrs": 1
            },
            {
                "size": "13х1,5",
                "koef": "0.000425",
                "metrs": 1
            },
            {
                "size": "14х0,5",
                "koef": "0.000166",
                "metrs": 1
            },
            {
                "size": "14х0,6",
                "koef": "0.000198",
                "metrs": 1
            },
            {
                "size": "14х0,7",
                "koef": "0.00023",
                "metrs": 1
            },
            {
                "size": "14х0,8",
                "koef": "0.00026",
                "metrs": 1
            },
            {
                "size": "14х0,9",
                "koef": "0.000291",
                "metrs": 1
            },
            {
                "size": "14х1",
                "koef": "0.000321",
                "metrs": 1
            },
            {
                "size": "14х1,1",
                "koef": "0.00035",
                "metrs": 1
            },
            {
                "size": "14х1,2",
                "koef": "0.000379",
                "metrs": 1
            },
            {
                "size": "14х1,3",
                "koef": "0.000407",
                "metrs": 1
            },
            {
                "size": "14х1,5",
                "koef": "0.000462",
                "metrs": 1
            },
            {
                "size": "15х2,8",
                "koef": "0.000842",
                "metrs": 1
            },
            {
                "size": "16х0,4",
                "koef": "0.000154",
                "metrs": 1
            },
            {
                "size": "16х0,5",
                "koef": "0.000191",
                "metrs": 1
            },
            {
                "size": "16х0,6",
                "koef": "0.000228",
                "metrs": 1
            },
            {
                "size": "16х0,7",
                "koef": "0.000264",
                "metrs": 1
            },
            {
                "size": "16х0,8",
                "koef": "0.0003",
                "metrs": 1
            },
            {
                "size": "16х0,9",
                "koef": "0.000335",
                "metrs": 1
            },
            {
                "size": "16х1",
                "koef": "0.00037",
                "metrs": 1
            },
            {
                "size": "16х1,1",
                "koef": "0.000404",
                "metrs": 1
            },
            {
                "size": "16х1,2",
                "koef": "0.000438",
                "metrs": 1
            },
            {
                "size": "16х1,3",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": "16х1,5",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": "16х1,8",
                "koef": "0.00063",
                "metrs": 1
            },
            {
                "size": "18х0,4",
                "koef": "0.000174",
                "metrs": 1
            },
            {
                "size": "18х0,5",
                "koef": "0.000216",
                "metrs": 1
            },
            {
                "size": "18х0,6",
                "koef": "0.000257",
                "metrs": 1
            },
            {
                "size": "18х0,7",
                "koef": "0.000299",
                "metrs": 1
            },
            {
                "size": "18х0,8",
                "koef": "0.000339",
                "metrs": 1
            },
            {
                "size": "18х0,9",
                "koef": "0.00038",
                "metrs": 1
            },
            {
                "size": "18х1",
                "koef": "0.000419",
                "metrs": 1
            },
            {
                "size": "18х1,1",
                "koef": "0.000458",
                "metrs": 1
            },
            {
                "size": "18х1,2",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": "18х1,3",
                "koef": "0.000535",
                "metrs": 1
            },
            {
                "size": "18х1,5",
                "koef": "0.00061",
                "metrs": 1
            },
            {
                "size": "18х1,8",
                "koef": "0.000719",
                "metrs": 1
            },
            {
                "size": "18х2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "19х0,4",
                "koef": "0.000183",
                "metrs": 1
            },
            {
                "size": "19х0,5",
                "koef": "0.000228",
                "metrs": 1
            },
            {
                "size": "19х0,6",
                "koef": "0.000272",
                "metrs": 1
            },
            {
                "size": "19х0,7",
                "koef": "0.000316",
                "metrs": 1
            },
            {
                "size": "19х0,8",
                "koef": "0.000359",
                "metrs": 1
            },
            {
                "size": "19х0,9",
                "koef": "0.000402",
                "metrs": 1
            },
            {
                "size": "19х1",
                "koef": "0.000444",
                "metrs": 1
            },
            {
                "size": "19х1,1",
                "koef": "0.000486",
                "metrs": 1
            },
            {
                "size": "19х1,2",
                "koef": "0.000527",
                "metrs": 1
            },
            {
                "size": "19х1,3",
                "koef": "0.000567",
                "metrs": 1
            },
            {
                "size": "19х1,5",
                "koef": "0.000647",
                "metrs": 1
            },
            {
                "size": "19х2",
                "koef": "0.000838",
                "metrs": 1
            },
            {
                "size": "20х0,4",
                "koef": "0.000193",
                "metrs": 1
            },
            {
                "size": "20х0,5",
                "koef": "0.00024",
                "metrs": 1
            },
            {
                "size": "20х0,6",
                "koef": "0.000287",
                "metrs": 1
            },
            {
                "size": "20х0,7",
                "koef": "0.000333",
                "metrs": 1
            },
            {
                "size": "20х0,8",
                "koef": "0.000379",
                "metrs": 1
            },
            {
                "size": "20х0,9",
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": "20х1",
                "koef": "0.000469",
                "metrs": 1
            },
            {
                "size": "20х1,1",
                "koef": "0.000513",
                "metrs": 1
            },
            {
                "size": "20х1,2",
                "koef": "0.000556",
                "metrs": 1
            },
            {
                "size": "20х1,3",
                "koef": "0.0006",
                "metrs": 1
            },
            {
                "size": "20х1,5",
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": "20х2",
                "koef": "0.000888",
                "metrs": 1
            },
            {
                "size": "20х2,5",
                "koef": "0.001079",
                "metrs": 1
            },
            {
                "size": "22х0,4",
                "koef": "0.000213",
                "metrs": 1
            },
            {
                "size": "22х0,5",
                "koef": "0.000265",
                "metrs": 1
            },
            {
                "size": "22х0,6",
                "koef": "0.000317",
                "metrs": 1
            },
            {
                "size": "22х0,7",
                "koef": "0.000368",
                "metrs": 1
            },
            {
                "size": "22х0,8",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": "22х0,9",
                "koef": "0.000468",
                "metrs": 1
            },
            {
                "size": "22х1",
                "koef": "0.000518",
                "metrs": 1
            },
            {
                "size": "22х1,1",
                "koef": "0.000567",
                "metrs": 1
            },
            {
                "size": "22х1,2",
                "koef": "0.000616",
                "metrs": 1
            },
            {
                "size": "22х1,3",
                "koef": "0.000664",
                "metrs": 1
            },
            {
                "size": "22х1,5",
                "koef": "0.000758",
                "metrs": 1
            },
            {
                "size": "22х2",
                "koef": "0.000986",
                "metrs": 1
            },
            {
                "size": "22х2,5",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": "22х3",
                "koef": "0.001406",
                "metrs": 1
            },
            {
                "size": "24х0,5",
                "koef": "0.00029",
                "metrs": 1
            },
            {
                "size": "24х0,6",
                "koef": "0.000346",
                "metrs": 1
            },
            {
                "size": "24х0,7",
                "koef": "0.000402",
                "metrs": 1
            },
            {
                "size": "24х0,8",
                "koef": "0.000458",
                "metrs": 1
            },
            {
                "size": "24х1",
                "koef": "0.000567",
                "metrs": 1
            },
            {
                "size": "24х1,2",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": "24х1,5",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": "25х0,4",
                "koef": "0.000243",
                "metrs": 1
            },
            {
                "size": "25х0,5",
                "koef": "0.000302",
                "metrs": 1
            },
            {
                "size": "25х0,6",
                "koef": "0.000361",
                "metrs": 1
            },
            {
                "size": "25х0,7",
                "koef": "0.000419",
                "metrs": 1
            },
            {
                "size": "25х0,8",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": "25х0,9",
                "koef": "0.000535",
                "metrs": 1
            },
            {
                "size": "25х1",
                "koef": "0.000592",
                "metrs": 1
            },
            {
                "size": "25х1,1",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "25х1,2",
                "koef": "0.000704",
                "metrs": 1
            },
            {
                "size": "25х1,3",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": "25х1,35",
                "koef": "0.000787",
                "metrs": 1
            },
            {
                "size": "25х1,5",
                "koef": "0.000869",
                "metrs": 1
            },
            {
                "size": "25х1,8",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "25х2",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": "25х2,5",
                "koef": "0.001387",
                "metrs": 1
            },
            {
                "size": "25х2,8",
                "koef": "0.001533",
                "metrs": 1
            },
            {
                "size": "26х0,6",
                "koef": "0.000376",
                "metrs": 1
            },
            {
                "size": "26х0,7",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": "26х0,8",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": "26х1",
                "koef": "0.000617",
                "metrs": 1
            },
            {
                "size": "26х1,2",
                "koef": "0.000734",
                "metrs": 1
            },
            {
                "size": "26х1,5",
                "koef": "0.000906",
                "metrs": 1
            },
            {
                "size": "26х2",
                "koef": "0.001184",
                "metrs": 1
            },
            {
                "size": "27х1,5",
                "koef": "0.000943",
                "metrs": 1
            },
            {
                "size": "28х0,6",
                "koef": "0.000405",
                "metrs": 1
            },
            {
                "size": "28х0,7",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": "28х0,8",
                "koef": "0.000537",
                "metrs": 1
            },
            {
                "size": "28х0,9",
                "koef": "0.000601",
                "metrs": 1
            },
            {
                "size": "28х1",
                "koef": "0.000666",
                "metrs": 1
            },
            {
                "size": "28х1,1",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": "28х1,2",
                "koef": "0.000793",
                "metrs": 1
            },
            {
                "size": "28х1,3",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "28х1,5",
                "koef": "0.00098",
                "metrs": 1
            },
            {
                "size": "28х1,8",
                "koef": "0.001163",
                "metrs": 1
            },
            {
                "size": "28х2",
                "koef": "0.001282",
                "metrs": 1
            },
            {
                "size": "28х2,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "30х0,7",
                "koef": "0.000506",
                "metrs": 1
            },
            {
                "size": "30х0,8",
                "koef": "0.000576",
                "metrs": 1
            },
            {
                "size": "30х0,9",
                "koef": "0.000646",
                "metrs": 1
            },
            {
                "size": "30х1",
                "koef": "0.000715",
                "metrs": 1
            },
            {
                "size": "30х1,1",
                "koef": "0.000784",
                "metrs": 1
            },
            {
                "size": "30х1,2",
                "koef": "0.000852",
                "metrs": 1
            },
            {
                "size": "30х1,3",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": "30х1,5",
                "koef": "0.001054",
                "metrs": 1
            },
            {
                "size": "30х1,8",
                "koef": "0.001252",
                "metrs": 1
            },
            {
                "size": "30х2",
                "koef": "0.001381",
                "metrs": 1
            },
            {
                "size": "31х0,7",
                "koef": "0.000523",
                "metrs": 1
            },
            {
                "size": "31х0,8",
                "koef": "0.000596",
                "metrs": 1
            },
            {
                "size": "31х1",
                "koef": "0.00074",
                "metrs": 1
            },
            {
                "size": "31х1,2",
                "koef": "0.000882",
                "metrs": 1
            },
            {
                "size": "31х1,5",
                "koef": "0.001091",
                "metrs": 1
            },
            {
                "size": "32х0,5",
                "koef": "0.000388",
                "metrs": 1
            },
            {
                "size": "32х0,6",
                "koef": "0.000465",
                "metrs": 1
            },
            {
                "size": "32х0,7",
                "koef": "0.00054",
                "metrs": 1
            },
            {
                "size": "32х0,8",
                "koef": "0.000616",
                "metrs": 1
            },
            {
                "size": "32х0,9",
                "koef": "0.00069",
                "metrs": 1
            },
            {
                "size": "32х1",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": "32х1,1",
                "koef": "0.000838",
                "metrs": 1
            },
            {
                "size": "32х1,2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "32х1,3",
                "koef": "0.000984",
                "metrs": 1
            },
            {
                "size": "32х1,35",
                "koef": "0.00102",
                "metrs": 1
            },
            {
                "size": "32х1,5",
                "koef": "0.001128",
                "metrs": 1
            },
            {
                "size": "32х1,8",
                "koef": "0.001341",
                "metrs": 1
            },
            {
                "size": "32х2",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": "32х2,3",
                "koef": "0.001685",
                "metrs": 1
            },
            {
                "size": "32х2,5",
                "koef": "0.001819",
                "metrs": 1
            },
            {
                "size": "32х2,8",
                "koef": "0.002016",
                "metrs": 1
            },
            {
                "size": "32х3,2",
                "koef": "0.002273",
                "metrs": 1
            },
            {
                "size": "33,7х2,5",
                "koef": "0.001924",
                "metrs": 1
            },
            {
                "size": "33,7х2,8",
                "koef": "0.002134",
                "metrs": 1
            },
            {
                "size": "33,7х3",
                "koef": "0.002271",
                "metrs": 1
            },
            {
                "size": "33,7х3,2",
                "koef": "0.002407",
                "metrs": 1
            },
            {
                "size": "35х0,7",
                "koef": "0.000592",
                "metrs": 1
            },
            {
                "size": "35х0,8",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": "35х0,9",
                "koef": "0.000757",
                "metrs": 1
            },
            {
                "size": "35х1",
                "koef": "0.000838",
                "metrs": 1
            },
            {
                "size": "35х1,1",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": "35х1,2",
                "koef": "0.001",
                "metrs": 1
            },
            {
                "size": "35х1,3",
                "koef": "0.00108",
                "metrs": 1
            },
            {
                "size": "35х1,5",
                "koef": "0.001239",
                "metrs": 1
            },
            {
                "size": "35х1,8",
                "koef": "0.001474",
                "metrs": 1
            },
            {
                "size": "35х2",
                "koef": "0.001628",
                "metrs": 1
            },
            {
                "size": "36х1",
                "koef": "0.000863",
                "metrs": 1
            },
            {
                "size": "36х1,1",
                "koef": "0.000947",
                "metrs": 1
            },
            {
                "size": "36х1,2",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "36х1,3",
                "koef": "0.001112",
                "metrs": 1
            },
            {
                "size": "36х1,5",
                "koef": "0.001276",
                "metrs": 1
            },
            {
                "size": "36х1,8",
                "koef": "0.001518",
                "metrs": 1
            },
            {
                "size": "36х2",
                "koef": "0.001677",
                "metrs": 1
            },
            {
                "size": "38х0,6",
                "koef": "0.000553",
                "metrs": 1
            },
            {
                "size": "38х0,7",
                "koef": "0.000644",
                "metrs": 1
            },
            {
                "size": "38х0,8",
                "koef": "0.000734",
                "metrs": 1
            },
            {
                "size": "38х0,9",
                "koef": "0.000823",
                "metrs": 1
            },
            {
                "size": "38х1",
                "koef": "0.000912",
                "metrs": 1
            },
            {
                "size": "38х1,1",
                "koef": "0.001001",
                "metrs": 1
            },
            {
                "size": "38х1,2",
                "koef": "0.001089",
                "metrs": 1
            },
            {
                "size": "38х1,3",
                "koef": "0.001177",
                "metrs": 1
            },
            {
                "size": "38х1,5",
                "koef": "0.00135",
                "metrs": 1
            },
            {
                "size": "38х1,8",
                "koef": "0.001607",
                "metrs": 1
            },
            {
                "size": "38х2",
                "koef": "0.001776",
                "metrs": 1
            },
            {
                "size": "38х2,3",
                "koef": "0.002025",
                "metrs": 1
            },
            {
                "size": "38х2,5",
                "koef": "0.002189",
                "metrs": 1
            },
            {
                "size": "38х3",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": "38х3,1",
                "koef": "0.002668",
                "metrs": 1
            },
            {
                "size": "38х3,5",
                "koef": "0.002978",
                "metrs": 1
            },
            {
                "size": "38х4",
                "koef": "0.003354",
                "metrs": 1
            },
            {
                "size": "38х6",
                "koef": "0.004735",
                "metrs": 1
            },
            {
                "size": "40х0,8",
                "koef": "0.000773",
                "metrs": 1
            },
            {
                "size": "40х0,9",
                "koef": "0.000868",
                "metrs": 1
            },
            {
                "size": "40х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": "40х1,1",
                "koef": "0.001055",
                "metrs": 1
            },
            {
                "size": "40х1,2",
                "koef": "0.001148",
                "metrs": 1
            },
            {
                "size": "40х1,3",
                "koef": "0.001241",
                "metrs": 1
            },
            {
                "size": "40х1,35",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": "40х1,5",
                "koef": "0.001424",
                "metrs": 1
            },
            {
                "size": "40х1,6",
                "koef": "0.001515",
                "metrs": 1
            },
            {
                "size": "40х1,8",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "40х2",
                "koef": "0.001874",
                "metrs": 1
            },
            {
                "size": "40х2,5",
                "koef": "0.002312",
                "metrs": 1
            },
            {
                "size": "40х3",
                "koef": "0.002737",
                "metrs": 1
            },
            {
                "size": "40х3,5",
                "koef": "0.003151",
                "metrs": 1
            },
            {
                "size": "40х4",
                "koef": "0.003551",
                "metrs": 1
            },
            {
                "size": "42х0,8",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": "42х0,9",
                "koef": "0.000912",
                "metrs": 1
            },
            {
                "size": "42х1",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": "42х1,1",
                "koef": "0.00111",
                "metrs": 1
            },
            {
                "size": "42х1,2",
                "koef": "0.001207",
                "metrs": 1
            },
            {
                "size": "42х1,3",
                "koef": "0.001305",
                "metrs": 1
            },
            {
                "size": "42х1,35",
                "koef": "0.001353",
                "metrs": 1
            },
            {
                "size": "42х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": "42х1,8",
                "koef": "0.001785",
                "metrs": 1
            },
            {
                "size": "42х2",
                "koef": "0.001973",
                "metrs": 1
            },
            {
                "size": "42х2,5",
                "koef": "0.002435",
                "metrs": 1
            },
            {
                "size": "42х3",
                "koef": "0.002885",
                "metrs": 1
            },
            {
                "size": "42х4",
                "koef": "0.003749",
                "metrs": 1
            },
            {
                "size": "43х1",
                "koef": "0.001036",
                "metrs": 1
            },
            {
                "size": "43х1,2",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": "43х1,5",
                "koef": "0.001535",
                "metrs": 1
            },
            {
                "size": "43х2",
                "koef": "0.002022",
                "metrs": 1
            },
            {
                "size": "43х2,5",
                "koef": "0.002497",
                "metrs": 1
            },
            {
                "size": "45х1",
                "koef": "0.001085",
                "metrs": 1
            },
            {
                "size": "45х1,2",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": "45х1,5",
                "koef": "0.001609",
                "metrs": 1
            },
            {
                "size": "45х2",
                "koef": "0.002121",
                "metrs": 1
            },
            {
                "size": "45х2,5",
                "koef": "0.00262",
                "metrs": 1
            },
            {
                "size": "45х3",
                "koef": "0.003107",
                "metrs": 1
            },
            {
                "size": "48х1",
                "koef": "0.001159",
                "metrs": 1
            },
            {
                "size": "48х1,2",
                "koef": "0.001385",
                "metrs": 1
            },
            {
                "size": "48х1,3",
                "koef": "0.001497",
                "metrs": 1
            },
            {
                "size": "48х1,5",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": "48х1,75",
                "koef": "0.001996",
                "metrs": 1
            },
            {
                "size": "48х1,8",
                "koef": "0.002051",
                "metrs": 1
            },
            {
                "size": "48х2",
                "koef": "0.002269",
                "metrs": 1
            },
            {
                "size": "48х2,5",
                "koef": "0.002805",
                "metrs": 1
            },
            {
                "size": "48х3",
                "koef": "0.003329",
                "metrs": 1
            },
            {
                "size": "48х3,2",
                "koef": "0.003535",
                "metrs": 1
            },
            {
                "size": "48х3,5",
                "koef": "0.003841",
                "metrs": 1
            },
            {
                "size": "48х4",
                "koef": "0.00434",
                "metrs": 1
            },
            {
                "size": "50х1,5",
                "koef": "0.001794",
                "metrs": 1
            },
            {
                "size": "50х1,8",
                "koef": "0.00214",
                "metrs": 1
            },
            {
                "size": "50х2",
                "koef": "0.002368",
                "metrs": 1
            },
            {
                "size": "50х3",
                "koef": "0.003477",
                "metrs": 1
            },
            {
                "size": "50х3,5",
                "koef": "0.004014",
                "metrs": 1
            },
            {
                "size": "51х1",
                "koef": "0.001233",
                "metrs": 1
            },
            {
                "size": "51х1,2",
                "koef": "0.001474",
                "metrs": 1
            },
            {
                "size": "51х1,5",
                "koef": "0.001831",
                "metrs": 1
            },
            {
                "size": "51х2",
                "koef": "0.002417",
                "metrs": 1
            },
            {
                "size": "51х2,2",
                "koef": "0.002648",
                "metrs": 1
            },
            {
                "size": "51х2,25",
                "koef": "0.002705",
                "metrs": 1
            },
            {
                "size": "51х2,5",
                "koef": "0.00299",
                "metrs": 1
            },
            {
                "size": "51х3",
                "koef": "0.003551",
                "metrs": 1
            },
            {
                "size": "51х3,5",
                "koef": "0.0041",
                "metrs": 1
            },
            {
                "size": "57х1",
                "koef": "0.001381",
                "metrs": 1
            },
            {
                "size": "57х1,2",
                "koef": "0.001651",
                "metrs": 1
            },
            {
                "size": "57х1,5",
                "koef": "0.002053",
                "metrs": 1
            },
            {
                "size": "57х1,8",
                "koef": "0.00245",
                "metrs": 1
            },
            {
                "size": "57х2",
                "koef": "0.002713",
                "metrs": 1
            },
            {
                "size": "57х2,2",
                "koef": "0.002973",
                "metrs": 1
            },
            {
                "size": "57х2,3",
                "koef": "0.003103",
                "metrs": 1
            },
            {
                "size": "57х2,35",
                "koef": "0.003167",
                "metrs": 1
            },
            {
                "size": "57х2,5",
                "koef": "0.00336",
                "metrs": 1
            },
            {
                "size": "57х2,8",
                "koef": "0.003743",
                "metrs": 1
            },
            {
                "size": "57х3",
                "koef": "0.003995",
                "metrs": 1
            },
            {
                "size": "57х3,2",
                "koef": "0.004246",
                "metrs": 1
            },
            {
                "size": "57х3,5",
                "koef": "0.004618",
                "metrs": 1
            },
            {
                "size": "57х3,8",
                "koef": "0.004986",
                "metrs": 1
            },
            {
                "size": "57х4",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": "57х4,5",
                "koef": "0.005826",
                "metrs": 1
            },
            {
                "size": "57х5",
                "koef": "0.006412",
                "metrs": 1
            },
            {
                "size": "60х1,2",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": "60х1,5",
                "koef": "0.002164",
                "metrs": 1
            },
            {
                "size": "60х1,75",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "60х2",
                "koef": "0.002861",
                "metrs": 1
            },
            {
                "size": "60х2,2",
                "koef": "0.003136",
                "metrs": 1
            },
            {
                "size": "60х2,35",
                "koef": "0.003341",
                "metrs": 1
            },
            {
                "size": "60х2,5",
                "koef": "0.003545",
                "metrs": 1
            },
            {
                "size": "60х2,8",
                "koef": "0.00395",
                "metrs": 1
            },
            {
                "size": "60х3",
                "koef": "0.004217",
                "metrs": 1
            },
            {
                "size": "60х3,2",
                "koef": "0.004482",
                "metrs": 1
            },
            {
                "size": "60х3,5",
                "koef": "0.004877",
                "metrs": 1
            },
            {
                "size": "60х3,8",
                "koef": "0.005267",
                "metrs": 1
            },
            {
                "size": "60х4",
                "koef": "0.005524",
                "metrs": 1
            },
            {
                "size": "62х1,5",
                "koef": "0.002238",
                "metrs": 1
            },
            {
                "size": "62х1,8",
                "koef": "0.002672",
                "metrs": 1
            },
            {
                "size": "62х2",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": "62х3",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "63,5х1,2",
                "koef": "0.001844",
                "metrs": 1
            },
            {
                "size": "63,5х1,5",
                "koef": "0.002294",
                "metrs": 1
            },
            {
                "size": "63,5х2",
                "koef": "0.003033",
                "metrs": 1
            },
            {
                "size": "63,5х3,5",
                "koef": "0.005179",
                "metrs": 1
            },
            {
                "size": "73х5,5",
                "koef": "0.009156",
                "metrs": 1
            },
            {
                "size": "74х1,8",
                "koef": "0.003205",
                "metrs": 1
            },
            {
                "size": "74х2",
                "koef": "0.003551",
                "metrs": 1
            },
            {
                "size": "76х1,5",
                "koef": "0.002756",
                "metrs": 1
            },
            {
                "size": "76х1,8",
                "koef": "0.003294",
                "metrs": 1
            },
            {
                "size": "76х2",
                "koef": "0.00365",
                "metrs": 1
            },
            {
                "size": "76х2,2",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": "76х2,35",
                "koef": "0.004268",
                "metrs": 1
            },
            {
                "size": "76х2,5",
                "koef": "0.004532",
                "metrs": 1
            },
            {
                "size": "76х2,8",
                "koef": "0.005055",
                "metrs": 1
            },
            {
                "size": "76х3",
                "koef": "0.005401",
                "metrs": 1
            },
            {
                "size": "76х3,2",
                "koef": "0.005745",
                "metrs": 1
            },
            {
                "size": "76х3,5",
                "koef": "0.006258",
                "metrs": 1
            },
            {
                "size": "76х3,8",
                "koef": "0.006766",
                "metrs": 1
            },
            {
                "size": "76х4",
                "koef": "0.007103",
                "metrs": 1
            },
            {
                "size": "76х4,5",
                "koef": "0.007935",
                "metrs": 1
            },
            {
                "size": "76х5",
                "koef": "0.008755",
                "metrs": 1
            },
            {
                "size": "76х6",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": "83х2,8",
                "koef": "0.005538",
                "metrs": 1
            },
            {
                "size": "89х1,5",
                "koef": "0.003237",
                "metrs": 1
            },
            {
                "size": "89х2",
                "koef": "0.004291",
                "metrs": 1
            },
            {
                "size": "89х2,2",
                "koef": "0.004709",
                "metrs": 1
            },
            {
                "size": "89х2,35",
                "koef": "0.005022",
                "metrs": 1
            },
            {
                "size": "89х2,5",
                "koef": "0.005333",
                "metrs": 1
            },
            {
                "size": "89х2,8",
                "koef": "0.005952",
                "metrs": 1
            },
            {
                "size": "89х3",
                "koef": "0.006363",
                "metrs": 1
            },
            {
                "size": "89х3,2",
                "koef": "0.006771",
                "metrs": 1
            },
            {
                "size": "89х3,5",
                "koef": "0.00738",
                "metrs": 1
            },
            {
                "size": "89х3,7",
                "koef": "0.007783",
                "metrs": 1
            },
            {
                "size": "89х3,8",
                "koef": "0.007984",
                "metrs": 1
            },
            {
                "size": "89х4",
                "koef": "0.008385",
                "metrs": 1
            },
            {
                "size": "89х4,5",
                "koef": "0.009378",
                "metrs": 1
            },
            {
                "size": "89х5",
                "koef": "0.01036",
                "metrs": 1
            },
            {
                "size": "89х6",
                "koef": "0.01228",
                "metrs": 1
            },
            {
                "size": "89х7",
                "koef": "0.01416",
                "metrs": 1
            },
            {
                "size": "95х2,5",
                "koef": "0.005703",
                "metrs": 1
            },
            {
                "size": "95х3",
                "koef": "0.006807",
                "metrs": 1
            },
            {
                "size": "95х3,5",
                "koef": "0.007898",
                "metrs": 1
            },
            {
                "size": "102х2",
                "koef": "0.004932",
                "metrs": 1
            },
            {
                "size": "102х2,2",
                "koef": "0.005415",
                "metrs": 1
            },
            {
                "size": "102х2,3",
                "koef": "0.005655",
                "metrs": 1
            },
            {
                "size": "102х2,35",
                "koef": "0.005775",
                "metrs": 1
            },
            {
                "size": "102х2,5",
                "koef": "0.006135",
                "metrs": 1
            },
            {
                "size": "102х2,8",
                "koef": "0.00685",
                "metrs": 1
            },
            {
                "size": "102х3",
                "koef": "0.007324",
                "metrs": 1
            },
            {
                "size": "102х3,2",
                "koef": "0.007797",
                "metrs": 1
            },
            {
                "size": "102х3,5",
                "koef": "0.008502",
                "metrs": 1
            },
            {
                "size": "102х3,8",
                "koef": "0.009203",
                "metrs": 1
            },
            {
                "size": "102х4",
                "koef": "0.009667",
                "metrs": 1
            },
            {
                "size": "102х4,5",
                "koef": "0.01082",
                "metrs": 1
            },
            {
                "size": "102х5",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "102х6",
                "koef": "0.01421",
                "metrs": 1
            },
            {
                "size": "102х16",
                "koef": "0.03393",
                "metrs": 1
            },
            {
                "size": "108х2",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": "108х2,2",
                "koef": "0.00574",
                "metrs": 1
            },
            {
                "size": "108х2,35",
                "koef": "0.006123",
                "metrs": 1
            },
            {
                "size": "108х2,5",
                "koef": "0.006504",
                "metrs": 1
            },
            {
                "size": "108х2,8",
                "koef": "0.007264",
                "metrs": 1
            },
            {
                "size": "108х3",
                "koef": "0.007768",
                "metrs": 1
            },
            {
                "size": "108х3,2",
                "koef": "0.00827",
                "metrs": 1
            },
            {
                "size": "108х3,5",
                "koef": "0.00902",
                "metrs": 1
            },
            {
                "size": "108х3,7",
                "koef": "0.009517",
                "metrs": 1
            },
            {
                "size": "108х3,8",
                "koef": "0.009765",
                "metrs": 1
            },
            {
                "size": "108х4",
                "koef": "0.01026",
                "metrs": 1
            },
            {
                "size": "108х4,5",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "108х5",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": "108х6",
                "koef": "0.01509",
                "metrs": 1
            },
            {
                "size": "114х2",
                "koef": "0.005524",
                "metrs": 1
            },
            {
                "size": "114х2,2",
                "koef": "0.006066",
                "metrs": 1
            },
            {
                "size": "114х2,5",
                "koef": "0.006874",
                "metrs": 1
            },
            {
                "size": "114х2,8",
                "koef": "0.007679",
                "metrs": 1
            },
            {
                "size": "114х3",
                "koef": "0.008212",
                "metrs": 1
            },
            {
                "size": "114х3,2",
                "koef": "0.008744",
                "metrs": 1
            },
            {
                "size": "114х3,5",
                "koef": "0.009538",
                "metrs": 1
            },
            {
                "size": "114х3,8",
                "koef": "0.01033",
                "metrs": 1
            },
            {
                "size": "114х4",
                "koef": "0.01085",
                "metrs": 1
            },
            {
                "size": "114х4,5",
                "koef": "0.01215",
                "metrs": 1
            },
            {
                "size": "114х5",
                "koef": "0.01344",
                "metrs": 1
            },
            {
                "size": "114х5,5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "114х6",
                "koef": "0.01598",
                "metrs": 1
            },
            {
                "size": "114х7",
                "koef": "0.01847",
                "metrs": 1
            },
            {
                "size": "120х2,5",
                "koef": "0.007244",
                "metrs": 1
            },
            {
                "size": "120х2,8",
                "koef": "0.008093",
                "metrs": 1
            },
            {
                "size": "120х3",
                "koef": "0.008656",
                "metrs": 1
            },
            {
                "size": "120х3,5",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "121х12",
                "koef": "0.03226",
                "metrs": 1
            },
            {
                "size": "127х2",
                "koef": "0.006165",
                "metrs": 1
            },
            {
                "size": "127х2,2",
                "koef": "0.006771",
                "metrs": 1
            },
            {
                "size": "127х2,5",
                "koef": "0.007676",
                "metrs": 1
            },
            {
                "size": "127х2,8",
                "koef": "0.008576",
                "metrs": 1
            },
            {
                "size": "127х3",
                "koef": "0.009174",
                "metrs": 1
            },
            {
                "size": "127х3,2",
                "koef": "0.00977",
                "metrs": 1
            },
            {
                "size": "127х3,5",
                "koef": "0.01066",
                "metrs": 1
            },
            {
                "size": "127х3,8",
                "koef": "0.01155",
                "metrs": 1
            },
            {
                "size": "127х4",
                "koef": "0.01213",
                "metrs": 1
            },
            {
                "size": "127х4,5",
                "koef": "0.01359",
                "metrs": 1
            },
            {
                "size": "127х5",
                "koef": "0.01504",
                "metrs": 1
            },
            {
                "size": "127х6",
                "koef": "0.0179",
                "metrs": 1
            },
            {
                "size": "133х3",
                "koef": "0.009618",
                "metrs": 1
            },
            {
                "size": "133х3,2",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "133х3,5",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "133х3,8",
                "koef": "0.01211",
                "metrs": 1
            },
            {
                "size": "133х4",
                "koef": "0.01273",
                "metrs": 1
            },
            {
                "size": "133х4,5",
                "koef": "0.01426",
                "metrs": 1
            },
            {
                "size": "133х5",
                "koef": "0.01578",
                "metrs": 1
            },
            {
                "size": "133х6",
                "koef": "0.01879",
                "metrs": 1
            },
            {
                "size": "133х7",
                "koef": "0.02175",
                "metrs": 1
            },
            {
                "size": "133х8",
                "koef": "0.02466",
                "metrs": 1
            },
            {
                "size": "133х12",
                "koef": "0.03581",
                "metrs": 1
            },
            {
                "size": "140х14",
                "koef": "0.0435",
                "metrs": 1
            },
            {
                "size": "140х16",
                "koef": "0.04893",
                "metrs": 1
            },
            {
                "size": "146х5",
                "koef": "0.01739",
                "metrs": 1
            },
            {
                "size": "146х6",
                "koef": "0.02072",
                "metrs": 1
            },
            {
                "size": "146х7",
                "koef": "0.024",
                "metrs": 1
            },
            {
                "size": "146х8",
                "koef": "0.02723",
                "metrs": 1
            },
            {
                "size": "152х16",
                "koef": "0.05366",
                "metrs": 1
            },
            {
                "size": "159х3",
                "koef": "0.01154",
                "metrs": 1
            },
            {
                "size": "159х3,2",
                "koef": "0.0123",
                "metrs": 1
            },
            {
                "size": "159х3,5",
                "koef": "0.01342",
                "metrs": 1
            },
            {
                "size": "159х3,8",
                "koef": "0.01454",
                "metrs": 1
            },
            {
                "size": "159х4",
                "koef": "0.01529",
                "metrs": 1
            },
            {
                "size": "159х4,5",
                "koef": "0.01715",
                "metrs": 1
            },
            {
                "size": "159х5",
                "koef": "0.01899",
                "metrs": 1
            },
            {
                "size": "159х5,5",
                "koef": "0.02082",
                "metrs": 1
            },
            {
                "size": "159х6",
                "koef": "0.02264",
                "metrs": 1
            },
            {
                "size": "159х6,5",
                "koef": "0.02445",
                "metrs": 1
            },
            {
                "size": "159х7",
                "koef": "0.02624",
                "metrs": 1
            },
            {
                "size": "159х7,5",
                "koef": "0.02802",
                "metrs": 1
            },
            {
                "size": "159х8",
                "koef": "0.02979",
                "metrs": 1
            },
            {
                "size": "159х9",
                "koef": "0.03329",
                "metrs": 1
            },
            {
                "size": "159х10",
                "koef": "0.03675",
                "metrs": 1
            },
            {
                "size": "168х4",
                "koef": "0.01618",
                "metrs": 1
            },
            {
                "size": "168х4,5",
                "koef": "0.01814",
                "metrs": 1
            },
            {
                "size": "168х5",
                "koef": "0.0201",
                "metrs": 1
            },
            {
                "size": "168х6",
                "koef": "0.02397",
                "metrs": 1
            },
            {
                "size": "168х7",
                "koef": "0.02779",
                "metrs": 1
            },
            {
                "size": "168х8",
                "koef": "0.03157",
                "metrs": 1
            },
            {
                "size": "168х9",
                "koef": "0.03529",
                "metrs": 1
            },
            {
                "size": "168х10",
                "koef": "0.03897",
                "metrs": 1
            },
            {
                "size": "219х4",
                "koef": "0.02121",
                "metrs": 1
            },
            {
                "size": "219х4,5",
                "koef": "0.0238",
                "metrs": 1
            },
            {
                "size": "219х5",
                "koef": "0.02639",
                "metrs": 1
            },
            {
                "size": "219х5,5",
                "koef": "0.02896",
                "metrs": 1
            },
            {
                "size": "219х6",
                "koef": "0.03152",
                "metrs": 1
            },
            {
                "size": "219х6,5",
                "koef": "0.03406",
                "metrs": 1
            },
            {
                "size": "219х7",
                "koef": "0.0366",
                "metrs": 1
            },
            {
                "size": "219х7,5",
                "koef": "0.03912",
                "metrs": 1
            },
            {
                "size": "219х8",
                "koef": "0.04163",
                "metrs": 1
            },
            {
                "size": "219х8,5",
                "koef": "0.04413",
                "metrs": 1
            },
            {
                "size": "219х9",
                "koef": "0.04661",
                "metrs": 1
            },
            {
                "size": "219х9,5",
                "koef": "0.04908",
                "metrs": 1
            },
            {
                "size": "219х10",
                "koef": "0.05154",
                "metrs": 1
            },
            {
                "size": "219х22",
                "koef": "0.1069",
                "metrs": 1
            },
            {
                "size": "244,5х5",
                "koef": "0.02953",
                "metrs": 1
            },
            {
                "size": "244,5х6",
                "koef": "0.03529",
                "metrs": 1
            },
            {
                "size": "244,5х7",
                "koef": "0.041",
                "metrs": 1
            },
            {
                "size": "244,5х8",
                "koef": "0.04666",
                "metrs": 1
            },
            {
                "size": "244,5х9",
                "koef": "0.05227",
                "metrs": 1
            },
            {
                "size": "244,5х10",
                "koef": "0.05783",
                "metrs": 1
            },
            {
                "size": "273х4,5",
                "koef": "0.0298",
                "metrs": 1
            },
            {
                "size": "273х5",
                "koef": "0.03305",
                "metrs": 1
            },
            {
                "size": "273х5,5",
                "koef": "0.03628",
                "metrs": 1
            },
            {
                "size": "273х6",
                "koef": "0.03951",
                "metrs": 1
            },
            {
                "size": "273х7",
                "koef": "0.04592",
                "metrs": 1
            },
            {
                "size": "273х8",
                "koef": "0.05228",
                "metrs": 1
            },
            {
                "size": "273х9",
                "koef": "0.0586",
                "metrs": 1
            },
            {
                "size": "273х10",
                "koef": "0.06486",
                "metrs": 1
            },
            {
                "size": "273х11",
                "koef": "0.07107",
                "metrs": 1
            },
            {
                "size": "273х12",
                "koef": "0.07724",
                "metrs": 1
            },
            {
                "size": "325х5",
                "koef": "0.03946",
                "metrs": 1
            },
            {
                "size": "325х6",
                "koef": "0.0472",
                "metrs": 1
            },
            {
                "size": "325х6,5",
                "koef": "0.05106",
                "metrs": 1
            },
            {
                "size": "325х7",
                "koef": "0.0549",
                "metrs": 1
            },
            {
                "size": "325х7,5",
                "koef": "0.05873",
                "metrs": 1
            },
            {
                "size": "325х8",
                "koef": "0.06254",
                "metrs": 1
            },
            {
                "size": "325х8,5",
                "koef": "0.06635",
                "metrs": 1
            },
            {
                "size": "325х9",
                "koef": "0.07014",
                "metrs": 1
            },
            {
                "size": "325х9,5",
                "koef": "0.07392",
                "metrs": 1
            },
            {
                "size": "325х10",
                "koef": "0.07768",
                "metrs": 1
            },
            {
                "size": "325х11",
                "koef": "0.08518",
                "metrs": 1
            },
            {
                "size": "325х12",
                "koef": "0.09263",
                "metrs": 1
            },
            {
                "size": "325х14",
                "koef": "0.1074",
                "metrs": 1
            },
            {
                "size": "377х5",
                "koef": "0.04587",
                "metrs": 1
            },
            {
                "size": "377х6",
                "koef": "0.0549",
                "metrs": 1
            },
            {
                "size": "377х6,5",
                "koef": "0.05939",
                "metrs": 1
            },
            {
                "size": "377х7",
                "koef": "0.06387",
                "metrs": 1
            },
            {
                "size": "377х7,5",
                "koef": "0.06834",
                "metrs": 1
            },
            {
                "size": "377х8",
                "koef": "0.0728",
                "metrs": 1
            },
            {
                "size": "377х8,5",
                "koef": "0.07725",
                "metrs": 1
            },
            {
                "size": "377х9",
                "koef": "0.08168",
                "metrs": 1
            },
            {
                "size": "377х9,5",
                "koef": "0.0861",
                "metrs": 1
            },
            {
                "size": "377х10",
                "koef": "0.09051",
                "metrs": 1
            },
            {
                "size": "377х11",
                "koef": "0.09929",
                "metrs": 1
            },
            {
                "size": "377х12",
                "koef": "0.108",
                "metrs": 1
            },
            {
                "size": "406х8",
                "koef": "0.07852",
                "metrs": 1
            },
            {
                "size": "406х9",
                "koef": "0.08812",
                "metrs": 1
            },
            {
                "size": "406х10",
                "koef": "0.09766",
                "metrs": 1
            },
            {
                "size": "406х11",
                "koef": "0.1072",
                "metrs": 1
            },
            {
                "size": "406х12",
                "koef": "0.1166",
                "metrs": 1
            },
            {
                "size": "406х13",
                "koef": "0.126",
                "metrs": 1
            },
            {
                "size": "406х14",
                "koef": "0.1353",
                "metrs": 1
            },
            {
                "size": "406х15",
                "koef": "0.1446",
                "metrs": 1
            },
            {
                "size": "406х16",
                "koef": "0.1539",
                "metrs": 1
            },
            {
                "size": "406х17",
                "koef": "0.1631",
                "metrs": 1
            },
            {
                "size": "406х18",
                "koef": "0.1722",
                "metrs": 1
            },
            {
                "size": "406х19",
                "koef": "0.1813",
                "metrs": 1
            },
            {
                "size": "406х20",
                "koef": "0.1904",
                "metrs": 1
            },
            {
                "size": "406х21",
                "koef": "0.1994",
                "metrs": 1
            },
            {
                "size": "406х22",
                "koef": "0.2083",
                "metrs": 1
            },
            {
                "size": "406х23",
                "koef": "0.2172",
                "metrs": 1
            },
            {
                "size": "406х24",
                "koef": "0.2261",
                "metrs": 1
            },
            {
                "size": "406х25",
                "koef": "0.2349",
                "metrs": 1
            },
            {
                "size": "406х26",
                "koef": "0.2437",
                "metrs": 1
            },
            {
                "size": "406х27",
                "koef": "0.2524",
                "metrs": 1
            },
            {
                "size": "406х28",
                "koef": "0.261",
                "metrs": 1
            },
            {
                "size": "406х29",
                "koef": "0.2696",
                "metrs": 1
            },
            {
                "size": "406х30",
                "koef": "0.2782",
                "metrs": 1
            },
            {
                "size": "406х31",
                "koef": "0.2867",
                "metrs": 1
            },
            {
                "size": "406х32",
                "koef": "0.2951",
                "metrs": 1
            },
            {
                "size": "406х33",
                "koef": "0.3036",
                "metrs": 1
            },
            {
                "size": "406х34",
                "koef": "0.3119",
                "metrs": 1
            },
            {
                "size": "406х35",
                "koef": "0.3202",
                "metrs": 1
            },
            {
                "size": "406х36",
                "koef": "0.3285",
                "metrs": 1
            },
            {
                "size": "406х37",
                "koef": "0.3367",
                "metrs": 1
            },
            {
                "size": "406х38",
                "koef": "0.3449",
                "metrs": 1
            },
            {
                "size": "406х39",
                "koef": "0.353",
                "metrs": 1
            },
            {
                "size": "406х40",
                "koef": "0.361",
                "metrs": 1
            },
            {
                "size": "426х5",
                "koef": "0.05191",
                "metrs": 1
            },
            {
                "size": "426х6",
                "koef": "0.06215",
                "metrs": 1
            },
            {
                "size": "426х6,5",
                "koef": "0.06725",
                "metrs": 1
            },
            {
                "size": "426х7",
                "koef": "0.07233",
                "metrs": 1
            },
            {
                "size": "426х7,5",
                "koef": "0.07741",
                "metrs": 1
            },
            {
                "size": "426х8",
                "koef": "0.08247",
                "metrs": 1
            },
            {
                "size": "426х8,5",
                "koef": "0.08752",
                "metrs": 1
            },
            {
                "size": "426х9",
                "koef": "0.09255",
                "metrs": 1
            },
            {
                "size": "426х9,5",
                "koef": "0.09758",
                "metrs": 1
            },
            {
                "size": "426х10",
                "koef": "0.1026",
                "metrs": 1
            },
            {
                "size": "426х11",
                "koef": "0.1126",
                "metrs": 1
            },
            {
                "size": "426х12",
                "koef": "0.1225",
                "metrs": 1
            },
            {
                "size": "426х18",
                "koef": "0.1811",
                "metrs": 1
            },
            {
                "size": "457х8",
                "koef": "0.08858",
                "metrs": 1
            },
            {
                "size": "457х9",
                "koef": "0.09944",
                "metrs": 1
            },
            {
                "size": "457х10",
                "koef": "0.1102",
                "metrs": 1
            },
            {
                "size": "457х11",
                "koef": "0.121",
                "metrs": 1
            },
            {
                "size": "457х12",
                "koef": "0.1317",
                "metrs": 1
            },
            {
                "size": "457х13",
                "koef": "0.1423",
                "metrs": 1
            },
            {
                "size": "457х14",
                "koef": "0.153",
                "metrs": 1
            },
            {
                "size": "457х15",
                "koef": "0.1635",
                "metrs": 1
            },
            {
                "size": "457х16",
                "koef": "0.174",
                "metrs": 1
            },
            {
                "size": "457х17",
                "koef": "0.1845",
                "metrs": 1
            },
            {
                "size": "457х18",
                "koef": "0.1949",
                "metrs": 1
            },
            {
                "size": "457х19",
                "koef": "0.2052",
                "metrs": 1
            },
            {
                "size": "457х20",
                "koef": "0.2155",
                "metrs": 1
            },
            {
                "size": "457х21",
                "koef": "0.2258",
                "metrs": 1
            },
            {
                "size": "457х22",
                "koef": "0.236",
                "metrs": 1
            },
            {
                "size": "457х23",
                "koef": "0.2462",
                "metrs": 1
            },
            {
                "size": "457х24",
                "koef": "0.2563",
                "metrs": 1
            },
            {
                "size": "457х25",
                "koef": "0.2663",
                "metrs": 1
            },
            {
                "size": "457х26",
                "koef": "0.2764",
                "metrs": 1
            },
            {
                "size": "457х27",
                "koef": "0.2863",
                "metrs": 1
            },
            {
                "size": "457х28",
                "koef": "0.2962",
                "metrs": 1
            },
            {
                "size": "457х29",
                "koef": "0.3061",
                "metrs": 1
            },
            {
                "size": "457х30",
                "koef": "0.3159",
                "metrs": 1
            },
            {
                "size": "457х31",
                "koef": "0.3257",
                "metrs": 1
            },
            {
                "size": "457х32",
                "koef": "0.3354",
                "metrs": 1
            },
            {
                "size": "457х33",
                "koef": "0.3451",
                "metrs": 1
            },
            {
                "size": "457х34",
                "koef": "0.3547",
                "metrs": 1
            },
            {
                "size": "457х35",
                "koef": "0.3643",
                "metrs": 1
            },
            {
                "size": "457х36",
                "koef": "0.3738",
                "metrs": 1
            },
            {
                "size": "457х37",
                "koef": "0.3832",
                "metrs": 1
            },
            {
                "size": "457х38",
                "koef": "0.3927",
                "metrs": 1
            },
            {
                "size": "457х39",
                "koef": "0.402",
                "metrs": 1
            },
            {
                "size": "457х40",
                "koef": "0.4114",
                "metrs": 1
            },
            {
                "size": "470х6",
                "koef": "0.06866",
                "metrs": 1
            },
            {
                "size": "470х8",
                "koef": "0.09115",
                "metrs": 1
            },
            {
                "size": "470х10",
                "koef": "0.1134",
                "metrs": 1
            },
            {
                "size": "470х12",
                "koef": "0.1355",
                "metrs": 1
            },
            {
                "size": "478х6",
                "koef": "0.06984",
                "metrs": 1
            },
            {
                "size": "478х7",
                "koef": "0.08131",
                "metrs": 1
            },
            {
                "size": "478х8",
                "koef": "0.09273",
                "metrs": 1
            },
            {
                "size": "478х9",
                "koef": "0.1041",
                "metrs": 1
            },
            {
                "size": "478х10",
                "koef": "0.1154",
                "metrs": 1
            },
            {
                "size": "478х11",
                "koef": "0.1267",
                "metrs": 1
            },
            {
                "size": "478х12",
                "koef": "0.1379",
                "metrs": 1
            },
            {
                "size": "480х5",
                "koef": "0.05857",
                "metrs": 1
            },
            {
                "size": "480х6",
                "koef": "0.07014",
                "metrs": 1
            },
            {
                "size": "480х8",
                "koef": "0.09312",
                "metrs": 1
            },
            {
                "size": "480х10",
                "koef": "0.1159",
                "metrs": 1
            },
            {
                "size": "480х12",
                "koef": "0.1385",
                "metrs": 1
            },
            {
                "size": "480х14",
                "koef": "0.1609",
                "metrs": 1
            },
            {
                "size": "480х16",
                "koef": "0.1831",
                "metrs": 1
            },
            {
                "size": "485х6",
                "koef": "0.07088",
                "metrs": 1
            },
            {
                "size": "485х8",
                "koef": "0.09411",
                "metrs": 1
            },
            {
                "size": "485х10",
                "koef": "0.1171",
                "metrs": 1
            },
            {
                "size": "485х12",
                "koef": "0.14",
                "metrs": 1
            },
            {
                "size": "485х14",
                "koef": "0.1626",
                "metrs": 1
            },
            {
                "size": "485х16",
                "koef": "0.1851",
                "metrs": 1
            },
            {
                "size": "485х18",
                "koef": "0.2073",
                "metrs": 1
            },
            {
                "size": "485х20",
                "koef": "0.2294",
                "metrs": 1
            },
            {
                "size": "508х6",
                "koef": "0.07428",
                "metrs": 1
            },
            {
                "size": "508х8",
                "koef": "0.09865",
                "metrs": 1
            },
            {
                "size": "508х9",
                "koef": "0.1108",
                "metrs": 1
            },
            {
                "size": "508х10",
                "koef": "0.1228",
                "metrs": 1
            },
            {
                "size": "508х11",
                "koef": "0.1348",
                "metrs": 1
            },
            {
                "size": "508х12",
                "koef": "0.1468",
                "metrs": 1
            },
            {
                "size": "508х13",
                "koef": "0.1587",
                "metrs": 1
            },
            {
                "size": "508х14",
                "koef": "0.1706",
                "metrs": 1
            },
            {
                "size": "508х15",
                "koef": "0.1824",
                "metrs": 1
            },
            {
                "size": "508х16",
                "koef": "0.1941",
                "metrs": 1
            },
            {
                "size": "508х17",
                "koef": "0.2058",
                "metrs": 1
            },
            {
                "size": "508х18",
                "koef": "0.2175",
                "metrs": 1
            },
            {
                "size": "508х19",
                "koef": "0.2291",
                "metrs": 1
            },
            {
                "size": "508х20",
                "koef": "0.2407",
                "metrs": 1
            },
            {
                "size": "508х21",
                "koef": "0.2522",
                "metrs": 1
            },
            {
                "size": "508х22",
                "koef": "0.2637",
                "metrs": 1
            },
            {
                "size": "508х23",
                "koef": "0.2751",
                "metrs": 1
            },
            {
                "size": "508х24",
                "koef": "0.2865",
                "metrs": 1
            },
            {
                "size": "508х25",
                "koef": "0.2978",
                "metrs": 1
            },
            {
                "size": "508х26",
                "koef": "0.3091",
                "metrs": 1
            },
            {
                "size": "508х27",
                "koef": "0.3203",
                "metrs": 1
            },
            {
                "size": "508х28",
                "koef": "0.3315",
                "metrs": 1
            },
            {
                "size": "508х29",
                "koef": "0.3426",
                "metrs": 1
            },
            {
                "size": "508х30",
                "koef": "0.3536",
                "metrs": 1
            },
            {
                "size": "508х31",
                "koef": "0.3647",
                "metrs": 1
            },
            {
                "size": "508х32",
                "koef": "0.3756",
                "metrs": 1
            },
            {
                "size": "508х33",
                "koef": "0.3866",
                "metrs": 1
            },
            {
                "size": "508х34",
                "koef": "0.3974",
                "metrs": 1
            },
            {
                "size": "508х35",
                "koef": "0.4083",
                "metrs": 1
            },
            {
                "size": "508х36",
                "koef": "0.419",
                "metrs": 1
            },
            {
                "size": "508х37",
                "koef": "0.4298",
                "metrs": 1
            },
            {
                "size": "508х38",
                "koef": "0.4405",
                "metrs": 1
            },
            {
                "size": "508х39",
                "koef": "0.4511",
                "metrs": 1
            },
            {
                "size": "508х40",
                "koef": "0.4617",
                "metrs": 1
            },
            {
                "size": "520х8",
                "koef": "0.101",
                "metrs": 1
            },
            {
                "size": "520х10",
                "koef": "0.1258",
                "metrs": 1
            },
            {
                "size": "520х12",
                "koef": "0.1503",
                "metrs": 1
            },
            {
                "size": "520х14",
                "koef": "0.1747",
                "metrs": 1
            },
            {
                "size": "520х16",
                "koef": "0.1989",
                "metrs": 1
            },
            {
                "size": "520х18",
                "koef": "0.2228",
                "metrs": 1
            },
            {
                "size": "520х20",
                "koef": "0.2466",
                "metrs": 1
            },
            {
                "size": "520х22",
                "koef": "0.2702",
                "metrs": 1
            },
            {
                "size": "520х24",
                "koef": "0.2936",
                "metrs": 1
            },
            {
                "size": "520х25",
                "koef": "0.3052",
                "metrs": 1
            },
            {
                "size": "520х27",
                "koef": "0.3283",
                "metrs": 1
            },
            {
                "size": "520х28",
                "koef": "0.3397",
                "metrs": 1
            },
            {
                "size": "520х30",
                "koef": "0.3625",
                "metrs": 1
            },
            {
                "size": "520х32",
                "koef": "0.3851",
                "metrs": 1
            },
            {
                "size": "520х35",
                "koef": "0.4186",
                "metrs": 1
            },
            {
                "size": "530х5",
                "koef": "0.06474",
                "metrs": 1
            },
            {
                "size": "530х6",
                "koef": "0.07754",
                "metrs": 1
            },
            {
                "size": "530х7",
                "koef": "0.09029",
                "metrs": 1
            },
            {
                "size": "530х7,5",
                "koef": "0.09664",
                "metrs": 1
            },
            {
                "size": "530х8",
                "koef": "0.103",
                "metrs": 1
            },
            {
                "size": "530х8,5",
                "koef": "0.1093",
                "metrs": 1
            },
            {
                "size": "530х9",
                "koef": "0.1156",
                "metrs": 1
            },
            {
                "size": "530х9,5",
                "koef": "0.1219",
                "metrs": 1
            },
            {
                "size": "530х10",
                "koef": "0.1282",
                "metrs": 1
            },
            {
                "size": "530х11",
                "koef": "0.1408",
                "metrs": 1
            },
            {
                "size": "530х12",
                "koef": "0.1533",
                "metrs": 1
            },
            {
                "size": "530х13",
                "koef": "0.1657",
                "metrs": 1
            },
            {
                "size": "530х14",
                "koef": "0.1782",
                "metrs": 1
            },
            {
                "size": "530х15",
                "koef": "0.1905",
                "metrs": 1
            },
            {
                "size": "530х16",
                "koef": "0.2028",
                "metrs": 1
            },
            {
                "size": "530х17",
                "koef": "0.2151",
                "metrs": 1
            },
            {
                "size": "530х18",
                "koef": "0.2273",
                "metrs": 1
            },
            {
                "size": "530х19",
                "koef": "0.2394",
                "metrs": 1
            },
            {
                "size": "530х20",
                "koef": "0.2515",
                "metrs": 1
            },
            {
                "size": "530х21",
                "koef": "0.2636",
                "metrs": 1
            },
            {
                "size": "530х22",
                "koef": "0.2756",
                "metrs": 1
            },
            {
                "size": "530х23",
                "koef": "0.2876",
                "metrs": 1
            },
            {
                "size": "530х24",
                "koef": "0.2995",
                "metrs": 1
            },
            {
                "size": "530х25",
                "koef": "0.3114",
                "metrs": 1
            },
            {
                "size": "530х26",
                "koef": "0.3232",
                "metrs": 1
            },
            {
                "size": "530х27",
                "koef": "0.3349",
                "metrs": 1
            },
            {
                "size": "530х28",
                "koef": "0.3466",
                "metrs": 1
            },
            {
                "size": "530х29",
                "koef": "0.3583",
                "metrs": 1
            },
            {
                "size": "530х30",
                "koef": "0.3699",
                "metrs": 1
            },
            {
                "size": "530х31",
                "koef": "0.3815",
                "metrs": 1
            },
            {
                "size": "530х32",
                "koef": "0.393",
                "metrs": 1
            },
            {
                "size": "530х33",
                "koef": "0.4045",
                "metrs": 1
            },
            {
                "size": "530х34",
                "koef": "0.4159",
                "metrs": 1
            },
            {
                "size": "530х35",
                "koef": "0.4273",
                "metrs": 1
            },
            {
                "size": "530х36",
                "koef": "0.4386",
                "metrs": 1
            },
            {
                "size": "530х37",
                "koef": "0.4499",
                "metrs": 1
            },
            {
                "size": "530х38",
                "koef": "0.4611",
                "metrs": 1
            },
            {
                "size": "530х39",
                "koef": "0.4722",
                "metrs": 1
            },
            {
                "size": "530х40",
                "koef": "0.4834",
                "metrs": 1
            },
            {
                "size": "530х45",
                "koef": "0.5382",
                "metrs": 1
            },
            {
                "size": "580х6",
                "koef": "0.08493",
                "metrs": 1
            },
            {
                "size": "580х8",
                "koef": "0.1129",
                "metrs": 1
            },
            {
                "size": "580х10",
                "koef": "0.1406",
                "metrs": 1
            },
            {
                "size": "580х12",
                "koef": "0.1681",
                "metrs": 1
            },
            {
                "size": "580х14",
                "koef": "0.1954",
                "metrs": 1
            },
            {
                "size": "580х16",
                "koef": "0.2225",
                "metrs": 1
            },
            {
                "size": "580х18",
                "koef": "0.2495",
                "metrs": 1
            },
            {
                "size": "580х20",
                "koef": "0.2762",
                "metrs": 1
            },
            {
                "size": "580х22",
                "koef": "0.3027",
                "metrs": 1
            },
            {
                "size": "580х24",
                "koef": "0.3291",
                "metrs": 1
            },
            {
                "size": "580х25",
                "koef": "0.3422",
                "metrs": 1
            },
            {
                "size": "580х27",
                "koef": "0.3682",
                "metrs": 1
            },
            {
                "size": "580х28",
                "koef": "0.3812",
                "metrs": 1
            },
            {
                "size": "580х30",
                "koef": "0.4069",
                "metrs": 1
            },
            {
                "size": "580х32",
                "koef": "0.4325",
                "metrs": 1
            },
            {
                "size": "580х35",
                "koef": "0.4704",
                "metrs": 1
            },
            {
                "size": "580х40",
                "koef": "0.5327",
                "metrs": 1
            },
            {
                "size": "580х45",
                "koef": "0.5937",
                "metrs": 1
            },
            {
                "size": "630х6",
                "koef": "0.09233",
                "metrs": 1
            },
            {
                "size": "630х7",
                "koef": "0.1075",
                "metrs": 1
            },
            {
                "size": "630х7,5",
                "koef": "0.1151",
                "metrs": 1
            },
            {
                "size": "630х8",
                "koef": "0.1227",
                "metrs": 1
            },
            {
                "size": "630х8,5",
                "koef": "0.1303",
                "metrs": 1
            },
            {
                "size": "630х9",
                "koef": "0.1378",
                "metrs": 1
            },
            {
                "size": "630х9,5",
                "koef": "0.1454",
                "metrs": 1
            },
            {
                "size": "630х10",
                "koef": "0.1529",
                "metrs": 1
            },
            {
                "size": "630х11",
                "koef": "0.1679",
                "metrs": 1
            },
            {
                "size": "630х12",
                "koef": "0.1829",
                "metrs": 1
            },
            {
                "size": "630х13",
                "koef": "0.1978",
                "metrs": 1
            },
            {
                "size": "630х14",
                "koef": "0.2127",
                "metrs": 1
            },
            {
                "size": "630х15",
                "koef": "0.2275",
                "metrs": 1
            },
            {
                "size": "630х16",
                "koef": "0.2423",
                "metrs": 1
            },
            {
                "size": "630х17",
                "koef": "0.257",
                "metrs": 1
            },
            {
                "size": "630х17,5",
                "koef": "0.2643",
                "metrs": 1
            },
            {
                "size": "630х18",
                "koef": "0.2717",
                "metrs": 1
            },
            {
                "size": "630х19",
                "koef": "0.2863",
                "metrs": 1
            },
            {
                "size": "630х20",
                "koef": "0.3009",
                "metrs": 1
            },
            {
                "size": "630х21",
                "koef": "0.3154",
                "metrs": 1
            },
            {
                "size": "630х22",
                "koef": "0.3299",
                "metrs": 1
            },
            {
                "size": "630х23",
                "koef": "0.3443",
                "metrs": 1
            },
            {
                "size": "630х24",
                "koef": "0.3587",
                "metrs": 1
            },
            {
                "size": "630х25",
                "koef": "0.373",
                "metrs": 1
            },
            {
                "size": "630х26",
                "koef": "0.3873",
                "metrs": 1
            },
            {
                "size": "630х27",
                "koef": "0.4015",
                "metrs": 1
            },
            {
                "size": "630х28",
                "koef": "0.4157",
                "metrs": 1
            },
            {
                "size": "630х29",
                "koef": "0.4298",
                "metrs": 1
            },
            {
                "size": "630х30",
                "koef": "0.4439",
                "metrs": 1
            },
            {
                "size": "630х31",
                "koef": "0.4579",
                "metrs": 1
            },
            {
                "size": "630х32",
                "koef": "0.4719",
                "metrs": 1
            },
            {
                "size": "630х33",
                "koef": "0.4859",
                "metrs": 1
            },
            {
                "size": "630х34",
                "koef": "0.4997",
                "metrs": 1
            },
            {
                "size": "630х35",
                "koef": "0.5136",
                "metrs": 1
            },
            {
                "size": "630х36",
                "koef": "0.5274",
                "metrs": 1
            },
            {
                "size": "630х37",
                "koef": "0.5411",
                "metrs": 1
            },
            {
                "size": "630х38",
                "koef": "0.5548",
                "metrs": 1
            },
            {
                "size": "630х39",
                "koef": "0.5684",
                "metrs": 1
            },
            {
                "size": "630х40",
                "koef": "0.582",
                "metrs": 1
            },
            {
                "size": "630х45",
                "koef": "0.6492",
                "metrs": 1
            },
            {
                "size": "650х8",
                "koef": "0.1267",
                "metrs": 1
            },
            {
                "size": "650х10",
                "koef": "0.1578",
                "metrs": 1
            },
            {
                "size": "650х12",
                "koef": "0.1888",
                "metrs": 1
            },
            {
                "size": "650х14",
                "koef": "0.2196",
                "metrs": 1
            },
            {
                "size": "650х16",
                "koef": "0.2502",
                "metrs": 1
            },
            {
                "size": "650х18",
                "koef": "0.2805",
                "metrs": 1
            },
            {
                "size": "650х20",
                "koef": "0.3107",
                "metrs": 1
            },
            {
                "size": "650х22",
                "koef": "0.3407",
                "metrs": 1
            },
            {
                "size": "650х24",
                "koef": "0.3705",
                "metrs": 1
            },
            {
                "size": "650х25",
                "koef": "0.3853",
                "metrs": 1
            },
            {
                "size": "650х27",
                "koef": "0.4148",
                "metrs": 1
            },
            {
                "size": "650х28",
                "koef": "0.4295",
                "metrs": 1
            },
            {
                "size": "650х30",
                "koef": "0.4587",
                "metrs": 1
            },
            {
                "size": "650х32",
                "koef": "0.4877",
                "metrs": 1
            },
            {
                "size": "650х35",
                "koef": "0.5308",
                "metrs": 1
            },
            {
                "size": "650х40",
                "koef": "0.6017",
                "metrs": 1
            },
            {
                "size": "650х45",
                "koef": "0.6714",
                "metrs": 1
            },
            {
                "size": "680х8",
                "koef": "0.1326",
                "metrs": 1
            },
            {
                "size": "680х10",
                "koef": "0.1652",
                "metrs": 1
            },
            {
                "size": "680х12",
                "koef": "0.1977",
                "metrs": 1
            },
            {
                "size": "680х14",
                "koef": "0.2299",
                "metrs": 1
            },
            {
                "size": "680х16",
                "koef": "0.262",
                "metrs": 1
            },
            {
                "size": "680х18",
                "koef": "0.2939",
                "metrs": 1
            },
            {
                "size": "680х20",
                "koef": "0.3255",
                "metrs": 1
            },
            {
                "size": "680х22",
                "koef": "0.357",
                "metrs": 1
            },
            {
                "size": "680х24",
                "koef": "0.3883",
                "metrs": 1
            },
            {
                "size": "680х25",
                "koef": "0.4038",
                "metrs": 1
            },
            {
                "size": "680х27",
                "koef": "0.4348",
                "metrs": 1
            },
            {
                "size": "680х28",
                "koef": "0.4502",
                "metrs": 1
            },
            {
                "size": "680х30",
                "koef": "0.4809",
                "metrs": 1
            },
            {
                "size": "680х32",
                "koef": "0.5114",
                "metrs": 1
            },
            {
                "size": "680х35",
                "koef": "0.5567",
                "metrs": 1
            },
            {
                "size": "680х40",
                "koef": "0.6313",
                "metrs": 1
            },
            {
                "size": "680х45",
                "koef": "0.7047",
                "metrs": 1
            },
            {
                "size": "720х6",
                "koef": "0.1056",
                "metrs": 1
            },
            {
                "size": "720х7",
                "koef": "0.1231",
                "metrs": 1
            },
            {
                "size": "720х7,5",
                "koef": "0.1318",
                "metrs": 1
            },
            {
                "size": "720х8",
                "koef": "0.1405",
                "metrs": 1
            },
            {
                "size": "720х8,5",
                "koef": "0.1491",
                "metrs": 1
            },
            {
                "size": "720х9",
                "koef": "0.1578",
                "metrs": 1
            },
            {
                "size": "720х9,5",
                "koef": "0.1665",
                "metrs": 1
            },
            {
                "size": "720х10",
                "koef": "0.1751",
                "metrs": 1
            },
            {
                "size": "720х11",
                "koef": "0.1923",
                "metrs": 1
            },
            {
                "size": "720х12",
                "koef": "0.2095",
                "metrs": 1
            },
            {
                "size": "720х13",
                "koef": "0.2267",
                "metrs": 1
            },
            {
                "size": "720х14",
                "koef": "0.2438",
                "metrs": 1
            },
            {
                "size": "720х15",
                "koef": "0.2608",
                "metrs": 1
            },
            {
                "size": "720х16",
                "koef": "0.2778",
                "metrs": 1
            },
            {
                "size": "720х17",
                "koef": "0.2947",
                "metrs": 1
            },
            {
                "size": "720х17,5",
                "koef": "0.3032",
                "metrs": 1
            },
            {
                "size": "720х18",
                "koef": "0.3116",
                "metrs": 1
            },
            {
                "size": "720х19",
                "koef": "0.3285",
                "metrs": 1
            },
            {
                "size": "720х20",
                "koef": "0.3453",
                "metrs": 1
            },
            {
                "size": "720х21",
                "koef": "0.362",
                "metrs": 1
            },
            {
                "size": "720х22",
                "koef": "0.3787",
                "metrs": 1
            },
            {
                "size": "720х23",
                "koef": "0.3953",
                "metrs": 1
            },
            {
                "size": "720х24",
                "koef": "0.4119",
                "metrs": 1
            },
            {
                "size": "720х25",
                "koef": "0.4285",
                "metrs": 1
            },
            {
                "size": "720х26",
                "koef": "0.445",
                "metrs": 1
            },
            {
                "size": "720х27",
                "koef": "0.4614",
                "metrs": 1
            },
            {
                "size": "720х28",
                "koef": "0.4778",
                "metrs": 1
            },
            {
                "size": "720х29",
                "koef": "0.4942",
                "metrs": 1
            },
            {
                "size": "720х30",
                "koef": "0.5105",
                "metrs": 1
            },
            {
                "size": "720х31",
                "koef": "0.5267",
                "metrs": 1
            },
            {
                "size": "720х32",
                "koef": "0.5429",
                "metrs": 1
            },
            {
                "size": "720х33",
                "koef": "0.5591",
                "metrs": 1
            },
            {
                "size": "720х34",
                "koef": "0.5752",
                "metrs": 1
            },
            {
                "size": "720х35",
                "koef": "0.5913",
                "metrs": 1
            },
            {
                "size": "720х36",
                "koef": "0.6073",
                "metrs": 1
            },
            {
                "size": "720х37",
                "koef": "0.6232",
                "metrs": 1
            },
            {
                "size": "720х38",
                "koef": "0.6391",
                "metrs": 1
            },
            {
                "size": "720х39",
                "koef": "0.655",
                "metrs": 1
            },
            {
                "size": "720х40",
                "koef": "0.6708",
                "metrs": 1
            },
            {
                "size": "720х45",
                "koef": "0.7491",
                "metrs": 1
            },
            {
                "size": "820х6",
                "koef": "0.1204",
                "metrs": 1
            },
            {
                "size": "820х7",
                "koef": "0.1403",
                "metrs": 1
            },
            {
                "size": "820х8",
                "koef": "0.1602",
                "metrs": 1
            },
            {
                "size": "820х8,5",
                "koef": "0.1701",
                "metrs": 1
            },
            {
                "size": "820х9",
                "koef": "0.18",
                "metrs": 1
            },
            {
                "size": "820х10",
                "koef": "0.1998",
                "metrs": 1
            },
            {
                "size": "820х11",
                "koef": "0.2195",
                "metrs": 1
            },
            {
                "size": "820х12",
                "koef": "0.2391",
                "metrs": 1
            },
            {
                "size": "820х13",
                "koef": "0.2587",
                "metrs": 1
            },
            {
                "size": "820х14",
                "koef": "0.2783",
                "metrs": 1
            },
            {
                "size": "820х15",
                "koef": "0.2978",
                "metrs": 1
            },
            {
                "size": "820х16",
                "koef": "0.3172",
                "metrs": 1
            },
            {
                "size": "820х17",
                "koef": "0.3367",
                "metrs": 1
            },
            {
                "size": "820х18",
                "koef": "0.356",
                "metrs": 1
            },
            {
                "size": "820х19",
                "koef": "0.3753",
                "metrs": 1
            },
            {
                "size": "820х20",
                "koef": "0.3946",
                "metrs": 1
            },
            {
                "size": "820х21",
                "koef": "0.4138",
                "metrs": 1
            },
            {
                "size": "820х22",
                "koef": "0.433",
                "metrs": 1
            },
            {
                "size": "820х23",
                "koef": "0.4521",
                "metrs": 1
            },
            {
                "size": "820х24",
                "koef": "0.4711",
                "metrs": 1
            },
            {
                "size": "820х25",
                "koef": "0.4901",
                "metrs": 1
            },
            {
                "size": "820х26",
                "koef": "0.5091",
                "metrs": 1
            },
            {
                "size": "820х27",
                "koef": "0.528",
                "metrs": 1
            },
            {
                "size": "820х28",
                "koef": "0.5469",
                "metrs": 1
            },
            {
                "size": "820х29",
                "koef": "0.5657",
                "metrs": 1
            },
            {
                "size": "820х30",
                "koef": "0.5845",
                "metrs": 1
            },
            {
                "size": "820х31",
                "koef": "0.6032",
                "metrs": 1
            },
            {
                "size": "820х32",
                "koef": "0.6219",
                "metrs": 1
            },
            {
                "size": "820х33",
                "koef": "0.6405",
                "metrs": 1
            },
            {
                "size": "820х34",
                "koef": "0.6591",
                "metrs": 1
            },
            {
                "size": "820х35",
                "koef": "0.6776",
                "metrs": 1
            },
            {
                "size": "820х36",
                "koef": "0.696",
                "metrs": 1
            },
            {
                "size": "820х37",
                "koef": "0.7145",
                "metrs": 1
            },
            {
                "size": "820х38",
                "koef": "0.7328",
                "metrs": 1
            },
            {
                "size": "820х39",
                "koef": "0.7512",
                "metrs": 1
            },
            {
                "size": "820х40",
                "koef": "0.7694",
                "metrs": 1
            },
            {
                "size": "820х45",
                "koef": "0.8601",
                "metrs": 1
            },
            {
                "size": "920х8",
                "koef": "0.1799",
                "metrs": 1
            },
            {
                "size": "920х9",
                "koef": "0.2022",
                "metrs": 1
            },
            {
                "size": "920х10",
                "koef": "0.2244",
                "metrs": 1
            },
            {
                "size": "920х11",
                "koef": "0.2466",
                "metrs": 1
            },
            {
                "size": "920х12",
                "koef": "0.2687",
                "metrs": 1
            },
            {
                "size": "920х13",
                "koef": "0.2908",
                "metrs": 1
            },
            {
                "size": "920х14",
                "koef": "0.3128",
                "metrs": 1
            },
            {
                "size": "920х15",
                "koef": "0.3348",
                "metrs": 1
            },
            {
                "size": "920х16",
                "koef": "0.3567",
                "metrs": 1
            },
            {
                "size": "920х17",
                "koef": "0.3786",
                "metrs": 1
            },
            {
                "size": "920х18",
                "koef": "0.4004",
                "metrs": 1
            },
            {
                "size": "920х19",
                "koef": "0.4222",
                "metrs": 1
            },
            {
                "size": "920х20",
                "koef": "0.4439",
                "metrs": 1
            },
            {
                "size": "920х21",
                "koef": "0.4656",
                "metrs": 1
            },
            {
                "size": "920х22",
                "koef": "0.4872",
                "metrs": 1
            },
            {
                "size": "920х23",
                "koef": "0.5088",
                "metrs": 1
            },
            {
                "size": "920х24",
                "koef": "0.5303",
                "metrs": 1
            },
            {
                "size": "920х25",
                "koef": "0.5518",
                "metrs": 1
            },
            {
                "size": "920х26",
                "koef": "0.5732",
                "metrs": 1
            },
            {
                "size": "920х27",
                "koef": "0.5946",
                "metrs": 1
            },
            {
                "size": "920х28",
                "koef": "0.6159",
                "metrs": 1
            },
            {
                "size": "920х29",
                "koef": "0.6372",
                "metrs": 1
            },
            {
                "size": "920х30",
                "koef": "0.6585",
                "metrs": 1
            },
            {
                "size": "920х31",
                "koef": "0.6796",
                "metrs": 1
            },
            {
                "size": "920х32",
                "koef": "0.7008",
                "metrs": 1
            },
            {
                "size": "920х33",
                "koef": "0.7219",
                "metrs": 1
            },
            {
                "size": "920х34",
                "koef": "0.7429",
                "metrs": 1
            },
            {
                "size": "920х35",
                "koef": "0.7639",
                "metrs": 1
            },
            {
                "size": "920х36",
                "koef": "0.7848",
                "metrs": 1
            },
            {
                "size": "920х37",
                "koef": "0.8057",
                "metrs": 1
            },
            {
                "size": "920х38",
                "koef": "0.8266",
                "metrs": 1
            },
            {
                "size": "920х39",
                "koef": "0.8473",
                "metrs": 1
            },
            {
                "size": "920х40",
                "koef": "0.8681",
                "metrs": 1
            },
            {
                "size": "920х45",
                "koef": "0.971",
                "metrs": 1
            },
            {
                "size": "1020х8",
                "koef": "0.1997",
                "metrs": 1
            },
            {
                "size": "1020х9",
                "koef": "0.2244",
                "metrs": 1
            },
            {
                "size": "1020х10",
                "koef": "0.2491",
                "metrs": 1
            },
            {
                "size": "1020х11",
                "koef": "0.2737",
                "metrs": 1
            },
            {
                "size": "1020х12",
                "koef": "0.2983",
                "metrs": 1
            },
            {
                "size": "1020х13",
                "koef": "0.3228",
                "metrs": 1
            },
            {
                "size": "1020х14",
                "koef": "0.3473",
                "metrs": 1
            },
            {
                "size": "1020х15",
                "koef": "0.3718",
                "metrs": 1
            },
            {
                "size": "1020х16",
                "koef": "0.3962",
                "metrs": 1
            },
            {
                "size": "1020х17",
                "koef": "0.4205",
                "metrs": 1
            },
            {
                "size": "1020х18",
                "koef": "0.4448",
                "metrs": 1
            },
            {
                "size": "1020х19",
                "koef": "0.469",
                "metrs": 1
            },
            {
                "size": "1020х20",
                "koef": "0.4932",
                "metrs": 1
            },
            {
                "size": "1020х21",
                "koef": "0.5174",
                "metrs": 1
            },
            {
                "size": "1020х22",
                "koef": "0.5415",
                "metrs": 1
            },
            {
                "size": "1020х23",
                "koef": "0.5655",
                "metrs": 1
            },
            {
                "size": "1020х24",
                "koef": "0.5895",
                "metrs": 1
            },
            {
                "size": "1020х25",
                "koef": "0.6135",
                "metrs": 1
            },
            {
                "size": "1020х26",
                "koef": "0.6374",
                "metrs": 1
            },
            {
                "size": "1020х27",
                "koef": "0.6612",
                "metrs": 1
            },
            {
                "size": "1020х28",
                "koef": "0.685",
                "metrs": 1
            },
            {
                "size": "1020х29",
                "koef": "0.7087",
                "metrs": 1
            },
            {
                "size": "1020х30",
                "koef": "0.7324",
                "metrs": 1
            },
            {
                "size": "1020х31",
                "koef": "0.7561",
                "metrs": 1
            },
            {
                "size": "1020х32",
                "koef": "0.7797",
                "metrs": 1
            },
            {
                "size": "1020х33",
                "koef": "0.8032",
                "metrs": 1
            },
            {
                "size": "1020х34",
                "koef": "0.8268",
                "metrs": 1
            },
            {
                "size": "1020х35",
                "koef": "0.8502",
                "metrs": 1
            },
            {
                "size": "1020х36",
                "koef": "0.8736",
                "metrs": 1
            },
            {
                "size": "1020х37",
                "koef": "0.897",
                "metrs": 1
            },
            {
                "size": "1020х38",
                "koef": "0.9203",
                "metrs": 1
            },
            {
                "size": "1020х39",
                "koef": "0.9435",
                "metrs": 1
            },
            {
                "size": "1020х40",
                "koef": "0.9667",
                "metrs": 1
            },
            {
                "size": "1020х41",
                "koef": "0.9899",
                "metrs": 1
            },
            {
                "size": "1020х42",
                "koef": "1.013",
                "metrs": 1
            },
            {
                "size": "1020х45",
                "koef": "1.082",
                "metrs": 1
            },
            {
                "size": "1120х8",
                "koef": "0.2194",
                "metrs": 1
            },
            {
                "size": "1120х9",
                "koef": "0.2466",
                "metrs": 1
            },
            {
                "size": "1120х10",
                "koef": "0.2737",
                "metrs": 1
            },
            {
                "size": "1120х11",
                "koef": "0.3008",
                "metrs": 1
            },
            {
                "size": "1120х12",
                "koef": "0.3279",
                "metrs": 1
            },
            {
                "size": "1120х13",
                "koef": "0.3549",
                "metrs": 1
            },
            {
                "size": "1120х14",
                "koef": "0.3819",
                "metrs": 1
            },
            {
                "size": "1120х15",
                "koef": "0.4088",
                "metrs": 1
            },
            {
                "size": "1120х16",
                "koef": "0.4356",
                "metrs": 1
            },
            {
                "size": "1120х17",
                "koef": "0.4624",
                "metrs": 1
            },
            {
                "size": "1120х18",
                "koef": "0.4892",
                "metrs": 1
            },
            {
                "size": "1120х19",
                "koef": "0.5159",
                "metrs": 1
            },
            {
                "size": "1120х20",
                "koef": "0.5426",
                "metrs": 1
            },
            {
                "size": "1120х21",
                "koef": "0.5692",
                "metrs": 1
            },
            {
                "size": "1120х22",
                "koef": "0.5957",
                "metrs": 1
            },
            {
                "size": "1120х23",
                "koef": "0.6222",
                "metrs": 1
            },
            {
                "size": "1120х24",
                "koef": "0.6487",
                "metrs": 1
            },
            {
                "size": "1120х25",
                "koef": "0.6751",
                "metrs": 1
            },
            {
                "size": "1120х26",
                "koef": "0.7015",
                "metrs": 1
            },
            {
                "size": "1120х27",
                "koef": "0.7278",
                "metrs": 1
            },
            {
                "size": "1120х28",
                "koef": "0.754",
                "metrs": 1
            },
            {
                "size": "1120х29",
                "koef": "0.7803",
                "metrs": 1
            },
            {
                "size": "1120х30",
                "koef": "0.8064",
                "metrs": 1
            },
            {
                "size": "1120х31",
                "koef": "0.8325",
                "metrs": 1
            },
            {
                "size": "1120х32",
                "koef": "0.8586",
                "metrs": 1
            },
            {
                "size": "1120х33",
                "koef": "0.8846",
                "metrs": 1
            },
            {
                "size": "1120х34",
                "koef": "0.9106",
                "metrs": 1
            },
            {
                "size": "1120х35",
                "koef": "0.9365",
                "metrs": 1
            },
            {
                "size": "1120х36",
                "koef": "0.9624",
                "metrs": 1
            },
            {
                "size": "1120х37",
                "koef": "0.9882",
                "metrs": 1
            },
            {
                "size": "1120х38",
                "koef": "1.014",
                "metrs": 1
            },
            {
                "size": "1120х39",
                "koef": "1.04",
                "metrs": 1
            },
            {
                "size": "1120х40",
                "koef": "1.065",
                "metrs": 1
            },
            {
                "size": "1120х41",
                "koef": "1.091",
                "metrs": 1
            },
            {
                "size": "1120х42",
                "koef": "1.117",
                "metrs": 1
            },
            {
                "size": "1120х45",
                "koef": "1.193",
                "metrs": 1
            },
            {
                "size": "1220х8",
                "koef": "0.2391",
                "metrs": 1
            },
            {
                "size": "1220х9",
                "koef": "0.2688",
                "metrs": 1
            },
            {
                "size": "1220х10",
                "koef": "0.2984",
                "metrs": 1
            },
            {
                "size": "1220х11",
                "koef": "0.328",
                "metrs": 1
            },
            {
                "size": "1220х12",
                "koef": "0.3575",
                "metrs": 1
            },
            {
                "size": "1220х13",
                "koef": "0.387",
                "metrs": 1
            },
            {
                "size": "1220х14",
                "koef": "0.4164",
                "metrs": 1
            },
            {
                "size": "1220х15",
                "koef": "0.4458",
                "metrs": 1
            },
            {
                "size": "1220х16",
                "koef": "0.4751",
                "metrs": 1
            },
            {
                "size": "1220х17",
                "koef": "0.5044",
                "metrs": 1
            },
            {
                "size": "1220х18",
                "koef": "0.5336",
                "metrs": 1
            },
            {
                "size": "1220х19",
                "koef": "0.5628",
                "metrs": 1
            },
            {
                "size": "1220х20",
                "koef": "0.5919",
                "metrs": 1
            },
            {
                "size": "1220х21",
                "koef": "0.621",
                "metrs": 1
            },
            {
                "size": "1220х22",
                "koef": "0.65",
                "metrs": 1
            },
            {
                "size": "1220х23",
                "koef": "0.679",
                "metrs": 1
            },
            {
                "size": "1220х24",
                "koef": "0.7079",
                "metrs": 1
            },
            {
                "size": "1220х25",
                "koef": "0.7368",
                "metrs": 1
            },
            {
                "size": "1220х26",
                "koef": "0.7656",
                "metrs": 1
            },
            {
                "size": "1220х27",
                "koef": "0.7944",
                "metrs": 1
            },
            {
                "size": "1220х28",
                "koef": "0.8231",
                "metrs": 1
            },
            {
                "size": "1220х29",
                "koef": "0.8518",
                "metrs": 1
            },
            {
                "size": "1220х30",
                "koef": "0.8804",
                "metrs": 1
            },
            {
                "size": "1220х31",
                "koef": "0.909",
                "metrs": 1
            },
            {
                "size": "1220х32",
                "koef": "0.9375",
                "metrs": 1
            },
            {
                "size": "1220х33",
                "koef": "0.966",
                "metrs": 1
            },
            {
                "size": "1220х34",
                "koef": "0.9944",
                "metrs": 1
            },
            {
                "size": "1220х35",
                "koef": "1.023",
                "metrs": 1
            },
            {
                "size": "1220х36",
                "koef": "1.051",
                "metrs": 1
            },
            {
                "size": "1220х37",
                "koef": "1.079",
                "metrs": 1
            },
            {
                "size": "1220х38",
                "koef": "1.108",
                "metrs": 1
            },
            {
                "size": "1220х39",
                "koef": "1.136",
                "metrs": 1
            },
            {
                "size": "1220х40",
                "koef": "1.164",
                "metrs": 1
            },
            {
                "size": "1220х41",
                "koef": "1.192",
                "metrs": 1
            },
            {
                "size": "1220х42",
                "koef": "1.22",
                "metrs": 1
            },
            {
                "size": "1220х43",
                "koef": "1.248",
                "metrs": 1
            },
            {
                "size": "1220х44",
                "koef": "1.276",
                "metrs": 1
            },
            {
                "size": "1220х45",
                "koef": "1.304",
                "metrs": 1
            },
            {
                "size": "1220х46",
                "koef": "1.332",
                "metrs": 1
            },
            {
                "size": "1220х47",
                "koef": "1.36",
                "metrs": 1
            },
            {
                "size": "1220х48",
                "koef": "1.387",
                "metrs": 1
            },
            {
                "size": "1220х49",
                "koef": "1.415",
                "metrs": 1
            },
            {
                "size": "1220х50",
                "koef": "1.443",
                "metrs": 1
            },
            {
                "size": "1220х51",
                "koef": "1.47",
                "metrs": 1
            },
            {
                "size": "1220х52",
                "koef": "1.498",
                "metrs": 1
            },
            {
                "size": "1220х53",
                "koef": "1.525",
                "metrs": 1
            },
            {
                "size": "1220х54",
                "koef": "1.553",
                "metrs": 1
            },
            {
                "size": "1220х55",
                "koef": "1.58",
                "metrs": 1
            },
            {
                "size": "1220х56",
                "koef": "1.608",
                "metrs": 1
            },
            {
                "size": "1220х57",
                "koef": "1.635",
                "metrs": 1
            },
            {
                "size": "1220х58",
                "koef": "1.662",
                "metrs": 1
            },
            {
                "size": "1220х59",
                "koef": "1.689",
                "metrs": 1
            },
            {
                "size": "1220х60",
                "koef": "1.716",
                "metrs": 1
            },
            {
                "size": "1320х8",
                "koef": "0.2588",
                "metrs": 1
            },
            {
                "size": "1320х10",
                "koef": "0.3231",
                "metrs": 1
            },
            {
                "size": "1320х11",
                "koef": "0.3551",
                "metrs": 1
            },
            {
                "size": "1320х12",
                "koef": "0.3871",
                "metrs": 1
            },
            {
                "size": "1320х13",
                "koef": "0.419",
                "metrs": 1
            },
            {
                "size": "1320х14",
                "koef": "0.4509",
                "metrs": 1
            },
            {
                "size": "1320х15",
                "koef": "0.4827",
                "metrs": 1
            },
            {
                "size": "1320х16",
                "koef": "0.5145",
                "metrs": 1
            },
            {
                "size": "1320х17",
                "koef": "0.5463",
                "metrs": 1
            },
            {
                "size": "1320х18",
                "koef": "0.578",
                "metrs": 1
            },
            {
                "size": "1320х19",
                "koef": "0.6096",
                "metrs": 1
            },
            {
                "size": "1320х20",
                "koef": "0.6412",
                "metrs": 1
            },
            {
                "size": "1320х21",
                "koef": "0.6727",
                "metrs": 1
            },
            {
                "size": "1320х22",
                "koef": "0.7042",
                "metrs": 1
            },
            {
                "size": "1320х23",
                "koef": "0.7357",
                "metrs": 1
            },
            {
                "size": "1320х24",
                "koef": "0.7671",
                "metrs": 1
            },
            {
                "size": "1320х25",
                "koef": "0.7984",
                "metrs": 1
            },
            {
                "size": "1320х26",
                "koef": "0.8297",
                "metrs": 1
            },
            {
                "size": "1320х27",
                "koef": "0.861",
                "metrs": 1
            },
            {
                "size": "1320х28",
                "koef": "0.8922",
                "metrs": 1
            },
            {
                "size": "1320х29",
                "koef": "0.9233",
                "metrs": 1
            },
            {
                "size": "1320х30",
                "koef": "0.9544",
                "metrs": 1
            },
            {
                "size": "1320х31",
                "koef": "0.9854",
                "metrs": 1
            },
            {
                "size": "1320х32",
                "koef": "1.016",
                "metrs": 1
            },
            {
                "size": "1320х33",
                "koef": "1.047",
                "metrs": 1
            },
            {
                "size": "1320х34",
                "koef": "1.078",
                "metrs": 1
            },
            {
                "size": "1320х35",
                "koef": "1.109",
                "metrs": 1
            },
            {
                "size": "1320х36",
                "koef": "1.14",
                "metrs": 1
            },
            {
                "size": "1320х37",
                "koef": "1.171",
                "metrs": 1
            },
            {
                "size": "1320х38",
                "koef": "1.201",
                "metrs": 1
            },
            {
                "size": "1320х40",
                "koef": "1.263",
                "metrs": 1
            },
            {
                "size": "1320х41",
                "koef": "1.293",
                "metrs": 1
            },
            {
                "size": "1320х42",
                "koef": "1.324",
                "metrs": 1
            },
            {
                "size": "1320х43",
                "koef": "1.354",
                "metrs": 1
            },
            {
                "size": "1320х44",
                "koef": "1.385",
                "metrs": 1
            },
            {
                "size": "1320х45",
                "koef": "1.415",
                "metrs": 1
            },
            {
                "size": "1320х46",
                "koef": "1.445",
                "metrs": 1
            },
            {
                "size": "1320х47",
                "koef": "1.476",
                "metrs": 1
            },
            {
                "size": "1320х48",
                "koef": "1.506",
                "metrs": 1
            },
            {
                "size": "1320х49",
                "koef": "1.536",
                "metrs": 1
            },
            {
                "size": "1320х50",
                "koef": "1.566",
                "metrs": 1
            },
            {
                "size": "1320х51",
                "koef": "1.596",
                "metrs": 1
            },
            {
                "size": "1320х52",
                "koef": "1.626",
                "metrs": 1
            },
            {
                "size": "1420х8",
                "koef": "0.2786",
                "metrs": 1
            },
            {
                "size": "1420х10",
                "koef": "0.3477",
                "metrs": 1
            },
            {
                "size": "1420х11",
                "koef": "0.3822",
                "metrs": 1
            },
            {
                "size": "1420х12",
                "koef": "0.4167",
                "metrs": 1
            },
            {
                "size": "1420х13",
                "koef": "0.4511",
                "metrs": 1
            },
            {
                "size": "1420х14",
                "koef": "0.4854",
                "metrs": 1
            },
            {
                "size": "1420х15",
                "koef": "0.5197",
                "metrs": 1
            },
            {
                "size": "1420х15,7",
                "koef": "0.5437",
                "metrs": 1
            },
            {
                "size": "1420х16",
                "koef": "0.554",
                "metrs": 1
            },
            {
                "size": "1420х17",
                "koef": "0.5882",
                "metrs": 1
            },
            {
                "size": "1420х18",
                "koef": "0.6224",
                "metrs": 1
            },
            {
                "size": "1420х18,5",
                "koef": "0.6394",
                "metrs": 1
            },
            {
                "size": "1420х19",
                "koef": "0.6565",
                "metrs": 1
            },
            {
                "size": "1420х20",
                "koef": "0.6905",
                "metrs": 1
            },
            {
                "size": "1420х21",
                "koef": "0.7245",
                "metrs": 1
            },
            {
                "size": "1420х21,6",
                "koef": "0.7449",
                "metrs": 1
            },
            {
                "size": "1420х22",
                "koef": "0.7585",
                "metrs": 1
            },
            {
                "size": "1420х23",
                "koef": "0.7924",
                "metrs": 1
            },
            {
                "size": "1420х24",
                "koef": "0.8263",
                "metrs": 1
            },
            {
                "size": "1420х25",
                "koef": "0.8601",
                "metrs": 1
            },
            {
                "size": "1420х26",
                "koef": "0.8938",
                "metrs": 1
            },
            {
                "size": "1420х27",
                "koef": "0.9275",
                "metrs": 1
            },
            {
                "size": "1420х28",
                "koef": "0.9612",
                "metrs": 1
            },
            {
                "size": "1420х29",
                "koef": "0.9948",
                "metrs": 1
            },
            {
                "size": "1420х30",
                "koef": "1.028",
                "metrs": 1
            },
            {
                "size": "1420х31",
                "koef": "1.062",
                "metrs": 1
            },
            {
                "size": "1420х32",
                "koef": "1.095",
                "metrs": 1
            },
            {
                "size": "1420х33",
                "koef": "1.129",
                "metrs": 1
            },
            {
                "size": "1420х34",
                "koef": "1.162",
                "metrs": 1
            },
            {
                "size": "1420х35",
                "koef": "1.195",
                "metrs": 1
            },
            {
                "size": "1420х36",
                "koef": "1.229",
                "metrs": 1
            },
            {
                "size": "1420х37",
                "koef": "1.262",
                "metrs": 1
            },
            {
                "size": "1420х38",
                "koef": "1.295",
                "metrs": 1
            },
            {
                "size": "1420х39",
                "koef": "1.328",
                "metrs": 1
            },
            {
                "size": "1420х40",
                "koef": "1.361",
                "metrs": 1
            },
            {
                "size": "1420х41",
                "koef": "1.394",
                "metrs": 1
            },
            {
                "size": "1420х42",
                "koef": "1.427",
                "metrs": 1
            },
            {
                "size": "1420х43",
                "koef": "1.46",
                "metrs": 1
            },
            {
                "size": "1420х44",
                "koef": "1.493",
                "metrs": 1
            },
            {
                "size": "1420х45",
                "koef": "1.526",
                "metrs": 1
            },
            {
                "size": "1420х46",
                "koef": "1.559",
                "metrs": 1
            },
            {
                "size": "1420х47",
                "koef": "1.591",
                "metrs": 1
            },
            {
                "size": "1420х48",
                "koef": "1.624",
                "metrs": 1
            },
            {
                "size": "1420х49",
                "koef": "1.657",
                "metrs": 1
            },
            {
                "size": "1420х50",
                "koef": "1.689",
                "metrs": 1
            },
            {
                "size": "1420х51",
                "koef": "1.722",
                "metrs": 1
            },
            {
                "size": "1420х52",
                "koef": "1.754",
                "metrs": 1
            },
            {
                "size": "1420х53",
                "koef": "1.787",
                "metrs": 1
            },
            {
                "size": "1420х54",
                "koef": "1.819",
                "metrs": 1
            },
            {
                "size": "1420х55",
                "koef": "1.851",
                "metrs": 1
            },
            {
                "size": "1420х56",
                "koef": "1.884",
                "metrs": 1
            },
            {
                "size": "1420х57",
                "koef": "1.916",
                "metrs": 1
            },
            {
                "size": "1420х58",
                "koef": "1.948",
                "metrs": 1
            },
            {
                "size": "1420х59",
                "koef": "1.98",
                "metrs": 1
            },
            {
                "size": "1420х60",
                "koef": "2.012",
                "metrs": 1
            },
            {
                "size": "1520х8",
                "koef": "0.2983",
                "metrs": 1
            },
            {
                "size": "1520х10",
                "koef": "0.3724",
                "metrs": 1
            },
            {
                "size": "1520х11",
                "koef": "0.4094",
                "metrs": 1
            },
            {
                "size": "1520х12",
                "koef": "0.4463",
                "metrs": 1
            },
            {
                "size": "1520х13",
                "koef": "0.4831",
                "metrs": 1
            },
            {
                "size": "1520х14",
                "koef": "0.52",
                "metrs": 1
            },
            {
                "size": "1520х15",
                "koef": "0.5567",
                "metrs": 1
            },
            {
                "size": "1520х16",
                "koef": "0.5935",
                "metrs": 1
            },
            {
                "size": "1520х17",
                "koef": "0.6301",
                "metrs": 1
            },
            {
                "size": "1520х18",
                "koef": "0.6667",
                "metrs": 1
            },
            {
                "size": "1520х19",
                "koef": "0.7033",
                "metrs": 1
            },
            {
                "size": "1520х20",
                "koef": "0.7398",
                "metrs": 1
            },
            {
                "size": "1520х21",
                "koef": "0.7763",
                "metrs": 1
            },
            {
                "size": "1520х22",
                "koef": "0.8127",
                "metrs": 1
            },
            {
                "size": "1520х23",
                "koef": "0.8491",
                "metrs": 1
            },
            {
                "size": "1520х24",
                "koef": "0.8854",
                "metrs": 1
            },
            {
                "size": "1520х25",
                "koef": "0.9217",
                "metrs": 1
            },
            {
                "size": "1520х26",
                "koef": "0.958",
                "metrs": 1
            },
            {
                "size": "1520х27",
                "koef": "0.9941",
                "metrs": 1
            },
            {
                "size": "1520х28",
                "koef": "1.03",
                "metrs": 1
            },
            {
                "size": "1520х29",
                "koef": "1.066",
                "metrs": 1
            },
            {
                "size": "1520х30",
                "koef": "1.102",
                "metrs": 1
            },
            {
                "size": "1520х31",
                "koef": "1.138",
                "metrs": 1
            },
            {
                "size": "1520х32",
                "koef": "1.174",
                "metrs": 1
            },
            {
                "size": "1520х33",
                "koef": "1.21",
                "metrs": 1
            },
            {
                "size": "1520х34",
                "koef": "1.246",
                "metrs": 1
            },
            {
                "size": "1520х35",
                "koef": "1.282",
                "metrs": 1
            },
            {
                "size": "1520х36",
                "koef": "1.318",
                "metrs": 1
            },
            {
                "size": "1520х37",
                "koef": "1.353",
                "metrs": 1
            },
            {
                "size": "1520х38",
                "koef": "1.389",
                "metrs": 1
            },
            {
                "size": "1520х39",
                "koef": "1.424",
                "metrs": 1
            },
            {
                "size": "1520х40",
                "koef": "1.46",
                "metrs": 1
            },
            {
                "size": "1520х41",
                "koef": "1.495",
                "metrs": 1
            },
            {
                "size": "1520х42",
                "koef": "1.531",
                "metrs": 1
            },
            {
                "size": "1520х43",
                "koef": "1.566",
                "metrs": 1
            },
            {
                "size": "1520х44",
                "koef": "1.602",
                "metrs": 1
            },
            {
                "size": "1520х45",
                "koef": "1.637",
                "metrs": 1
            },
            {
                "size": "1520х46",
                "koef": "1.672",
                "metrs": 1
            },
            {
                "size": "1520х47",
                "koef": "1.707",
                "metrs": 1
            },
            {
                "size": "1520х48",
                "koef": "1.742",
                "metrs": 1
            },
            {
                "size": "1520х49",
                "koef": "1.778",
                "metrs": 1
            },
            {
                "size": "1520х50",
                "koef": "1.813",
                "metrs": 1
            },
            {
                "size": "1520х51",
                "koef": "1.848",
                "metrs": 1
            },
            {
                "size": "1520х52",
                "koef": "1.883",
                "metrs": 1
            },
            {
                "size": "1620х8",
                "koef": "0.318",
                "metrs": 1
            },
            {
                "size": "1620х10",
                "koef": "0.397",
                "metrs": 1
            },
            {
                "size": "1620х11",
                "koef": "0.4365",
                "metrs": 1
            },
            {
                "size": "1620х12",
                "koef": "0.4759",
                "metrs": 1
            },
            {
                "size": "1620х13",
                "koef": "0.5152",
                "metrs": 1
            },
            {
                "size": "1620х14",
                "koef": "0.5545",
                "metrs": 1
            },
            {
                "size": "1620х15",
                "koef": "0.5937",
                "metrs": 1
            },
            {
                "size": "1620х16",
                "koef": "0.6329",
                "metrs": 1
            },
            {
                "size": "1620х17",
                "koef": "0.672",
                "metrs": 1
            },
            {
                "size": "1620х18",
                "koef": "0.7111",
                "metrs": 1
            },
            {
                "size": "1620х19",
                "koef": "0.7502",
                "metrs": 1
            },
            {
                "size": "1620х20",
                "koef": "0.7892",
                "metrs": 1
            },
            {
                "size": "1620х21",
                "koef": "0.8281",
                "metrs": 1
            },
            {
                "size": "1620х22",
                "koef": "0.867",
                "metrs": 1
            },
            {
                "size": "1620х23",
                "koef": "0.9058",
                "metrs": 1
            },
            {
                "size": "1620х24",
                "koef": "0.9446",
                "metrs": 1
            },
            {
                "size": "1620х25",
                "koef": "0.9834",
                "metrs": 1
            },
            {
                "size": "1620х26",
                "koef": "1.022",
                "metrs": 1
            },
            {
                "size": "1620х27",
                "koef": "1.061",
                "metrs": 1
            },
            {
                "size": "1620х28",
                "koef": "1.099",
                "metrs": 1
            },
            {
                "size": "1620х29",
                "koef": "1.138",
                "metrs": 1
            },
            {
                "size": "1620х30",
                "koef": "1.176",
                "metrs": 1
            },
            {
                "size": "1620х31",
                "koef": "1.215",
                "metrs": 1
            },
            {
                "size": "1620х32",
                "koef": "1.253",
                "metrs": 1
            },
            {
                "size": "1620х33",
                "koef": "1.292",
                "metrs": 1
            },
            {
                "size": "1620х34",
                "koef": "1.33",
                "metrs": 1
            },
            {
                "size": "1620х35",
                "koef": "1.368",
                "metrs": 1
            },
            {
                "size": "1620х36",
                "koef": "1.406",
                "metrs": 1
            },
            {
                "size": "1620х37",
                "koef": "1.444",
                "metrs": 1
            },
            {
                "size": "1620х38",
                "koef": "1.483",
                "metrs": 1
            },
            {
                "size": "1620х39",
                "koef": "1.521",
                "metrs": 1
            },
            {
                "size": "1620х40",
                "koef": "1.559",
                "metrs": 1
            },
            {
                "size": "1620х41",
                "koef": "1.597",
                "metrs": 1
            },
            {
                "size": "1620х42",
                "koef": "1.634",
                "metrs": 1
            },
            {
                "size": "1620х43",
                "koef": "1.672",
                "metrs": 1
            },
            {
                "size": "1620х44",
                "koef": "1.71",
                "metrs": 1
            },
            {
                "size": "1620х45",
                "koef": "1.748",
                "metrs": 1
            },
            {
                "size": "1620х46",
                "koef": "1.786",
                "metrs": 1
            },
            {
                "size": "1620х47",
                "koef": "1.823",
                "metrs": 1
            },
            {
                "size": "1620х48",
                "koef": "1.861",
                "metrs": 1
            },
            {
                "size": "1620х49",
                "koef": "1.898",
                "metrs": 1
            },
            {
                "size": "1620х50",
                "koef": "1.936",
                "metrs": 1
            },
            {
                "size": "1620х51",
                "koef": "1.973",
                "metrs": 1
            },
            {
                "size": "1620х52",
                "koef": "2.011",
                "metrs": 1
            },
            {
                "size": "1720х8",
                "koef": "0.3378",
                "metrs": 1
            },
            {
                "size": "1720х10",
                "koef": "0.4217",
                "metrs": 1
            },
            {
                "size": "1720х11",
                "koef": "0.4636",
                "metrs": 1
            },
            {
                "size": "1720х12",
                "koef": "0.5055",
                "metrs": 1
            },
            {
                "size": "1720х13",
                "koef": "0.5473",
                "metrs": 1
            },
            {
                "size": "1720х14",
                "koef": "0.589",
                "metrs": 1
            },
            {
                "size": "1720х15",
                "koef": "0.6307",
                "metrs": 1
            },
            {
                "size": "1720х16",
                "koef": "0.6724",
                "metrs": 1
            },
            {
                "size": "1720х17",
                "koef": "0.714",
                "metrs": 1
            },
            {
                "size": "1720х18",
                "koef": "0.7555",
                "metrs": 1
            },
            {
                "size": "1720х19",
                "koef": "0.797",
                "metrs": 1
            },
            {
                "size": "1720х20",
                "koef": "0.8385",
                "metrs": 1
            },
            {
                "size": "1720х21",
                "koef": "0.8799",
                "metrs": 1
            },
            {
                "size": "1720х22",
                "koef": "0.9213",
                "metrs": 1
            },
            {
                "size": "1720х23",
                "koef": "0.9626",
                "metrs": 1
            },
            {
                "size": "1720х24",
                "koef": "1.004",
                "metrs": 1
            },
            {
                "size": "1720х25",
                "koef": "1.045",
                "metrs": 1
            },
            {
                "size": "1720х26",
                "koef": "1.086",
                "metrs": 1
            },
            {
                "size": "1720х27",
                "koef": "1.127",
                "metrs": 1
            },
            {
                "size": "1720х28",
                "koef": "1.168",
                "metrs": 1
            },
            {
                "size": "1720х29",
                "koef": "1.209",
                "metrs": 1
            },
            {
                "size": "1720х30",
                "koef": "1.25",
                "metrs": 1
            },
            {
                "size": "1720х31",
                "koef": "1.291",
                "metrs": 1
            },
            {
                "size": "1720х32",
                "koef": "1.332",
                "metrs": 1
            },
            {
                "size": "1720х33",
                "koef": "1.373",
                "metrs": 1
            },
            {
                "size": "1720х34",
                "koef": "1.414",
                "metrs": 1
            },
            {
                "size": "1720х35",
                "koef": "1.454",
                "metrs": 1
            },
            {
                "size": "1720х36",
                "koef": "1.495",
                "metrs": 1
            },
            {
                "size": "1720х37",
                "koef": "1.536",
                "metrs": 1
            },
            {
                "size": "1720х38",
                "koef": "1.576",
                "metrs": 1
            },
            {
                "size": "1720х39",
                "koef": "1.617",
                "metrs": 1
            },
            {
                "size": "1720х40",
                "koef": "1.657",
                "metrs": 1
            },
            {
                "size": "1720х41",
                "koef": "1.698",
                "metrs": 1
            },
            {
                "size": "1720х42",
                "koef": "1.738",
                "metrs": 1
            },
            {
                "size": "1720х43",
                "koef": "1.778",
                "metrs": 1
            },
            {
                "size": "1720х44",
                "koef": "1.819",
                "metrs": 1
            },
            {
                "size": "1720х45",
                "koef": "1.859",
                "metrs": 1
            },
            {
                "size": "1720х46",
                "koef": "1.899",
                "metrs": 1
            },
            {
                "size": "1720х47",
                "koef": "1.939",
                "metrs": 1
            },
            {
                "size": "1720х48",
                "koef": "1.979",
                "metrs": 1
            },
            {
                "size": "1720х49",
                "koef": "2.019",
                "metrs": 1
            },
            {
                "size": "1720х50",
                "koef": "2.059",
                "metrs": 1
            },
            {
                "size": "1720х51",
                "koef": "2.099",
                "metrs": 1
            },
            {
                "size": "1720х52",
                "koef": "2.139",
                "metrs": 1
            },
            {
                "size": "1720х53",
                "koef": "2.179",
                "metrs": 1
            },
            {
                "size": "1720х54",
                "koef": "2.219",
                "metrs": 1
            },
            {
                "size": "1820х8",
                "koef": "0.3575",
                "metrs": 1
            },
            {
                "size": "1820х10",
                "koef": "0.4464",
                "metrs": 1
            },
            {
                "size": "1820х12",
                "koef": "0.5351",
                "metrs": 1
            },
            {
                "size": "1820х13",
                "koef": "0.5793",
                "metrs": 1
            },
            {
                "size": "1820х14",
                "koef": "0.6235",
                "metrs": 1
            },
            {
                "size": "1820х15",
                "koef": "0.6677",
                "metrs": 1
            },
            {
                "size": "1820х16",
                "koef": "0.7118",
                "metrs": 1
            },
            {
                "size": "1820х17",
                "koef": "0.7559",
                "metrs": 1
            },
            {
                "size": "1820х18",
                "koef": "0.7999",
                "metrs": 1
            },
            {
                "size": "1820х19",
                "koef": "0.8439",
                "metrs": 1
            },
            {
                "size": "1820х20",
                "koef": "0.8878",
                "metrs": 1
            },
            {
                "size": "1820х21",
                "koef": "0.9317",
                "metrs": 1
            },
            {
                "size": "1820х22",
                "koef": "0.9755",
                "metrs": 1
            },
            {
                "size": "1820х23",
                "koef": "1.019",
                "metrs": 1
            },
            {
                "size": "1820х24",
                "koef": "1.063",
                "metrs": 1
            },
            {
                "size": "1820х25",
                "koef": "1.107",
                "metrs": 1
            },
            {
                "size": "1820х26",
                "koef": "1.15",
                "metrs": 1
            },
            {
                "size": "1820х27",
                "koef": "1.194",
                "metrs": 1
            },
            {
                "size": "1820х28",
                "koef": "1.237",
                "metrs": 1
            },
            {
                "size": "1820х29",
                "koef": "1.281",
                "metrs": 1
            },
            {
                "size": "1820х30",
                "koef": "1.324",
                "metrs": 1
            },
            {
                "size": "1820х31",
                "koef": "1.368",
                "metrs": 1
            },
            {
                "size": "1820х32",
                "koef": "1.411",
                "metrs": 1
            },
            {
                "size": "1820х33",
                "koef": "1.454",
                "metrs": 1
            },
            {
                "size": "1820х34",
                "koef": "1.498",
                "metrs": 1
            },
            {
                "size": "1820х35",
                "koef": "1.541",
                "metrs": 1
            },
            {
                "size": "1820х36",
                "koef": "1.584",
                "metrs": 1
            },
            {
                "size": "1820х37",
                "koef": "1.627",
                "metrs": 1
            },
            {
                "size": "1820х38",
                "koef": "1.67",
                "metrs": 1
            },
            {
                "size": "1820х39",
                "koef": "1.713",
                "metrs": 1
            },
            {
                "size": "1820х40",
                "koef": "1.756",
                "metrs": 1
            },
            {
                "size": "1820х41",
                "koef": "1.799",
                "metrs": 1
            },
            {
                "size": "1820х42",
                "koef": "1.842",
                "metrs": 1
            },
            {
                "size": "1820х43",
                "koef": "1.884",
                "metrs": 1
            },
            {
                "size": "1820х44",
                "koef": "1.927",
                "metrs": 1
            },
            {
                "size": "1820х45",
                "koef": "1.97",
                "metrs": 1
            },
            {
                "size": "1820х46",
                "koef": "2.012",
                "metrs": 1
            },
            {
                "size": "1820х47",
                "koef": "2.055",
                "metrs": 1
            },
            {
                "size": "1820х48",
                "koef": "2.098",
                "metrs": 1
            },
            {
                "size": "1820х49",
                "koef": "2.14",
                "metrs": 1
            },
            {
                "size": "1820х50",
                "koef": "2.183",
                "metrs": 1
            },
            {
                "size": "1820х51",
                "koef": "2.225",
                "metrs": 1
            },
            {
                "size": "1820х52",
                "koef": "2.267",
                "metrs": 1
            },
            {
                "size": "1820х53",
                "koef": "2.31",
                "metrs": 1
            },
            {
                "size": "1820х54",
                "koef": "2.352",
                "metrs": 1
            },
            {
                "size": "1920х8",
                "koef": "0.3772",
                "metrs": 1
            },
            {
                "size": "1920х10",
                "koef": "0.471",
                "metrs": 1
            },
            {
                "size": "1920х12",
                "koef": "0.5646",
                "metrs": 1
            },
            {
                "size": "1920х14",
                "koef": "0.6581",
                "metrs": 1
            },
            {
                "size": "1920х15",
                "koef": "0.7047",
                "metrs": 1
            },
            {
                "size": "1920х16",
                "koef": "0.7513",
                "metrs": 1
            },
            {
                "size": "1920х17",
                "koef": "0.7978",
                "metrs": 1
            },
            {
                "size": "1920х18",
                "koef": "0.8443",
                "metrs": 1
            },
            {
                "size": "1920х19",
                "koef": "0.8907",
                "metrs": 1
            },
            {
                "size": "1920х20",
                "koef": "0.9371",
                "metrs": 1
            },
            {
                "size": "1920х21",
                "koef": "0.9835",
                "metrs": 1
            },
            {
                "size": "1920х22",
                "koef": "1.03",
                "metrs": 1
            },
            {
                "size": "1920х23",
                "koef": "1.076",
                "metrs": 1
            },
            {
                "size": "1920х24",
                "koef": "1.122",
                "metrs": 1
            },
            {
                "size": "1920х25",
                "koef": "1.168",
                "metrs": 1
            },
            {
                "size": "1920х26",
                "koef": "1.214",
                "metrs": 1
            },
            {
                "size": "1920х27",
                "koef": "1.26",
                "metrs": 1
            },
            {
                "size": "1920х28",
                "koef": "1.306",
                "metrs": 1
            },
            {
                "size": "1920х29",
                "koef": "1.352",
                "metrs": 1
            },
            {
                "size": "1920х30",
                "koef": "1.398",
                "metrs": 1
            },
            {
                "size": "1920х31",
                "koef": "1.444",
                "metrs": 1
            },
            {
                "size": "1920х32",
                "koef": "1.49",
                "metrs": 1
            },
            {
                "size": "1920х33",
                "koef": "1.536",
                "metrs": 1
            },
            {
                "size": "1920х34",
                "koef": "1.581",
                "metrs": 1
            },
            {
                "size": "1920х35",
                "koef": "1.627",
                "metrs": 1
            },
            {
                "size": "1920х36",
                "koef": "1.673",
                "metrs": 1
            },
            {
                "size": "1920х37",
                "koef": "1.718",
                "metrs": 1
            },
            {
                "size": "1920х38",
                "koef": "1.764",
                "metrs": 1
            },
            {
                "size": "1920х39",
                "koef": "1.809",
                "metrs": 1
            },
            {
                "size": "1920х40",
                "koef": "1.855",
                "metrs": 1
            },
            {
                "size": "1920х41",
                "koef": "1.9",
                "metrs": 1
            },
            {
                "size": "1920х42",
                "koef": "1.945",
                "metrs": 1
            },
            {
                "size": "1920х43",
                "koef": "1.99",
                "metrs": 1
            },
            {
                "size": "1920х44",
                "koef": "2.036",
                "metrs": 1
            },
            {
                "size": "1920х45",
                "koef": "2.081",
                "metrs": 1
            },
            {
                "size": "1920х46",
                "koef": "2.126",
                "metrs": 1
            },
            {
                "size": "1920х47",
                "koef": "2.171",
                "metrs": 1
            },
            {
                "size": "1920х48",
                "koef": "2.216",
                "metrs": 1
            },
            {
                "size": "1920х49",
                "koef": "2.261",
                "metrs": 1
            },
            {
                "size": "1920х50",
                "koef": "2.306",
                "metrs": 1
            },
            {
                "size": "1920х51",
                "koef": "2.351",
                "metrs": 1
            },
            {
                "size": "1920х52",
                "koef": "2.396",
                "metrs": 1
            },
            {
                "size": "1920х53",
                "koef": "2.44",
                "metrs": 1
            },
            {
                "size": "1920х54",
                "koef": "2.485",
                "metrs": 1
            },
            {
                "size": "2020х8",
                "koef": "0.397",
                "metrs": 1
            },
            {
                "size": "2020х10",
                "koef": "0.4957",
                "metrs": 1
            },
            {
                "size": "2020х12",
                "koef": "0.5942",
                "metrs": 1
            },
            {
                "size": "2020х14",
                "koef": "0.6926",
                "metrs": 1
            },
            {
                "size": "2020х15",
                "koef": "0.7417",
                "metrs": 1
            },
            {
                "size": "2020х16",
                "koef": "0.7907",
                "metrs": 1
            },
            {
                "size": "2020х17",
                "koef": "0.8397",
                "metrs": 1
            },
            {
                "size": "2020х18",
                "koef": "0.8887",
                "metrs": 1
            },
            {
                "size": "2020х19",
                "koef": "0.9376",
                "metrs": 1
            },
            {
                "size": "2020х20",
                "koef": "0.9865",
                "metrs": 1
            },
            {
                "size": "2020х21",
                "koef": "1.035",
                "metrs": 1
            },
            {
                "size": "2020х22",
                "koef": "1.084",
                "metrs": 1
            },
            {
                "size": "2020х23",
                "koef": "1.133",
                "metrs": 1
            },
            {
                "size": "2020х24",
                "koef": "1.181",
                "metrs": 1
            },
            {
                "size": "2020х25",
                "koef": "1.23",
                "metrs": 1
            },
            {
                "size": "2020х26",
                "koef": "1.279",
                "metrs": 1
            },
            {
                "size": "2020х27",
                "koef": "1.327",
                "metrs": 1
            },
            {
                "size": "2020х28",
                "koef": "1.376",
                "metrs": 1
            },
            {
                "size": "2020х29",
                "koef": "1.424",
                "metrs": 1
            },
            {
                "size": "2020х30",
                "koef": "1.472",
                "metrs": 1
            },
            {
                "size": "2020х31",
                "koef": "1.521",
                "metrs": 1
            },
            {
                "size": "2020х32",
                "koef": "1.569",
                "metrs": 1
            },
            {
                "size": "2020х33",
                "koef": "1.617",
                "metrs": 1
            },
            {
                "size": "2020х34",
                "koef": "1.665",
                "metrs": 1
            },
            {
                "size": "2020х35",
                "koef": "1.713",
                "metrs": 1
            },
            {
                "size": "2020х36",
                "koef": "1.761",
                "metrs": 1
            },
            {
                "size": "2020х37",
                "koef": "1.809",
                "metrs": 1
            },
            {
                "size": "2020х38",
                "koef": "1.857",
                "metrs": 1
            },
            {
                "size": "2020х39",
                "koef": "1.905",
                "metrs": 1
            },
            {
                "size": "2020х40",
                "koef": "1.953",
                "metrs": 1
            },
            {
                "size": "2020х41",
                "koef": "2.001",
                "metrs": 1
            },
            {
                "size": "2020х42",
                "koef": "2.049",
                "metrs": 1
            },
            {
                "size": "2020х43",
                "koef": "2.096",
                "metrs": 1
            },
            {
                "size": "2020х44",
                "koef": "2.144",
                "metrs": 1
            },
            {
                "size": "2020х45",
                "koef": "2.192",
                "metrs": 1
            },
            {
                "size": "2020х46",
                "koef": "2.239",
                "metrs": 1
            },
            {
                "size": "2020х47",
                "koef": "2.287",
                "metrs": 1
            },
            {
                "size": "2020х48",
                "koef": "2.334",
                "metrs": 1
            },
            {
                "size": "2020х49",
                "koef": "2.382",
                "metrs": 1
            },
            {
                "size": "2020х50",
                "koef": "2.429",
                "metrs": 1
            },
            {
                "size": "2020х51",
                "koef": "2.476",
                "metrs": 1
            },
            {
                "size": "2020х52",
                "koef": "2.524",
                "metrs": 1
            },
            {
                "size": "2020х53",
                "koef": "2.571",
                "metrs": 1
            },
            {
                "size": "2020х54",
                "koef": "2.618",
                "metrs": 1
            },
            {
                "size": "2020х55",
                "koef": "2.665",
                "metrs": 1
            },
            {
                "size": "2020х56",
                "koef": "2.712",
                "metrs": 1
            },
            {
                "size": "2020х57",
                "koef": "2.759",
                "metrs": 1
            },
            {
                "size": "2020х58",
                "koef": "2.806",
                "metrs": 1
            },
            {
                "size": "2020х59",
                "koef": "2.853",
                "metrs": 1
            },
            {
                "size": "2020х60",
                "koef": "2.9",
                "metrs": 1
            },
            {
                "size": "2120х8",
                "koef": "0.4167",
                "metrs": 1
            },
            {
                "size": "2120х10",
                "koef": "0.5204",
                "metrs": 1
            },
            {
                "size": "2120х12",
                "koef": "0.6238",
                "metrs": 1
            },
            {
                "size": "2120х14",
                "koef": "0.7271",
                "metrs": 1
            },
            {
                "size": "2120х15",
                "koef": "0.7787",
                "metrs": 1
            },
            {
                "size": "2120х16",
                "koef": "0.8302",
                "metrs": 1
            },
            {
                "size": "2120х17",
                "koef": "0.8817",
                "metrs": 1
            },
            {
                "size": "2120х18",
                "koef": "0.9331",
                "metrs": 1
            },
            {
                "size": "2120х19",
                "koef": "0.9845",
                "metrs": 1
            },
            {
                "size": "2120х20",
                "koef": "1.036",
                "metrs": 1
            },
            {
                "size": "2120х21",
                "koef": "1.087",
                "metrs": 1
            },
            {
                "size": "2120х22",
                "koef": "1.138",
                "metrs": 1
            },
            {
                "size": "2120х23",
                "koef": "1.189",
                "metrs": 1
            },
            {
                "size": "2120х24",
                "koef": "1.241",
                "metrs": 1
            },
            {
                "size": "2120х25",
                "koef": "1.292",
                "metrs": 1
            },
            {
                "size": "2120х26",
                "koef": "1.343",
                "metrs": 1
            },
            {
                "size": "2120х27",
                "koef": "1.394",
                "metrs": 1
            },
            {
                "size": "2120х28",
                "koef": "1.445",
                "metrs": 1
            },
            {
                "size": "2120х29",
                "koef": "1.495",
                "metrs": 1
            },
            {
                "size": "2120х30",
                "koef": "1.546",
                "metrs": 1
            },
            {
                "size": "2120х31",
                "koef": "1.597",
                "metrs": 1
            },
            {
                "size": "2120х32",
                "koef": "1.648",
                "metrs": 1
            },
            {
                "size": "2120х33",
                "koef": "1.698",
                "metrs": 1
            },
            {
                "size": "2120х34",
                "koef": "1.749",
                "metrs": 1
            },
            {
                "size": "2120х35",
                "koef": "1.8",
                "metrs": 1
            },
            {
                "size": "2120х36",
                "koef": "1.85",
                "metrs": 1
            },
            {
                "size": "2120х37",
                "koef": "1.901",
                "metrs": 1
            },
            {
                "size": "2120х38",
                "koef": "1.951",
                "metrs": 1
            },
            {
                "size": "2120х39",
                "koef": "2.002",
                "metrs": 1
            },
            {
                "size": "2120х40",
                "koef": "2.052",
                "metrs": 1
            },
            {
                "size": "2120х41",
                "koef": "2.102",
                "metrs": 1
            },
            {
                "size": "2120х42",
                "koef": "2.152",
                "metrs": 1
            },
            {
                "size": "2120х43",
                "koef": "2.203",
                "metrs": 1
            },
            {
                "size": "2120х44",
                "koef": "2.253",
                "metrs": 1
            },
            {
                "size": "2120х45",
                "koef": "2.303",
                "metrs": 1
            },
            {
                "size": "2120х46",
                "koef": "2.353",
                "metrs": 1
            },
            {
                "size": "2120х47",
                "koef": "2.403",
                "metrs": 1
            },
            {
                "size": "2120х48",
                "koef": "2.453",
                "metrs": 1
            },
            {
                "size": "2120х49",
                "koef": "2.503",
                "metrs": 1
            },
            {
                "size": "2120х50",
                "koef": "2.552",
                "metrs": 1
            },
            {
                "size": "2120х51",
                "koef": "2.602",
                "metrs": 1
            },
            {
                "size": "2120х52",
                "koef": "2.652",
                "metrs": 1
            },
            {
                "size": "2120х53",
                "koef": "2.702",
                "metrs": 1
            },
            {
                "size": "2120х54",
                "koef": "2.751",
                "metrs": 1
            },
            {
                "size": "2120х55",
                "koef": "2.801",
                "metrs": 1
            },
            {
                "size": "2120х56",
                "koef": "2.85",
                "metrs": 1
            },
            {
                "size": "2120х57",
                "koef": "2.9",
                "metrs": 1
            },
            {
                "size": "2120х58",
                "koef": "2.949",
                "metrs": 1
            },
            {
                "size": "2120х59",
                "koef": "2.999",
                "metrs": 1
            },
            {
                "size": "2120х60",
                "koef": "3.048",
                "metrs": 1
            },
            {
                "size": "2220х8",
                "koef": "0.4364",
                "metrs": 1
            },
            {
                "size": "2220х10",
                "koef": "0.545",
                "metrs": 1
            },
            {
                "size": "2220х12",
                "koef": "0.6534",
                "metrs": 1
            },
            {
                "size": "2220х14",
                "koef": "0.7616",
                "metrs": 1
            },
            {
                "size": "2220х15",
                "koef": "0.8157",
                "metrs": 1
            },
            {
                "size": "2220х16",
                "koef": "0.8697",
                "metrs": 1
            },
            {
                "size": "2220х17",
                "koef": "0.9236",
                "metrs": 1
            },
            {
                "size": "2220х18",
                "koef": "0.9775",
                "metrs": 1
            },
            {
                "size": "2220х19",
                "koef": "1.031",
                "metrs": 1
            },
            {
                "size": "2220х20",
                "koef": "1.085",
                "metrs": 1
            },
            {
                "size": "2220х21",
                "koef": "1.139",
                "metrs": 1
            },
            {
                "size": "2220х22",
                "koef": "1.193",
                "metrs": 1
            },
            {
                "size": "2220х23",
                "koef": "1.246",
                "metrs": 1
            },
            {
                "size": "2220х24",
                "koef": "1.3",
                "metrs": 1
            },
            {
                "size": "2220х25",
                "koef": "1.353",
                "metrs": 1
            },
            {
                "size": "2220х26",
                "koef": "1.407",
                "metrs": 1
            },
            {
                "size": "2220х27",
                "koef": "1.46",
                "metrs": 1
            },
            {
                "size": "2220х28",
                "koef": "1.514",
                "metrs": 1
            },
            {
                "size": "2220х29",
                "koef": "1.567",
                "metrs": 1
            },
            {
                "size": "2220х30",
                "koef": "1.62",
                "metrs": 1
            },
            {
                "size": "2220х31",
                "koef": "1.674",
                "metrs": 1
            },
            {
                "size": "2220х32",
                "koef": "1.727",
                "metrs": 1
            },
            {
                "size": "2220х33",
                "koef": "1.78",
                "metrs": 1
            },
            {
                "size": "2220х34",
                "koef": "1.833",
                "metrs": 1
            },
            {
                "size": "2220х35",
                "koef": "1.886",
                "metrs": 1
            },
            {
                "size": "2220х36",
                "koef": "1.939",
                "metrs": 1
            },
            {
                "size": "2220х37",
                "koef": "1.992",
                "metrs": 1
            },
            {
                "size": "2220х38",
                "koef": "2.045",
                "metrs": 1
            },
            {
                "size": "2220х39",
                "koef": "2.098",
                "metrs": 1
            },
            {
                "size": "2220х40",
                "koef": "2.15",
                "metrs": 1
            },
            {
                "size": "2220х41",
                "koef": "2.203",
                "metrs": 1
            },
            {
                "size": "2220х42",
                "koef": "2.256",
                "metrs": 1
            },
            {
                "size": "2220х43",
                "koef": "2.309",
                "metrs": 1
            },
            {
                "size": "2220х44",
                "koef": "2.361",
                "metrs": 1
            },
            {
                "size": "2220х45",
                "koef": "2.414",
                "metrs": 1
            },
            {
                "size": "2220х46",
                "koef": "2.466",
                "metrs": 1
            },
            {
                "size": "2220х47",
                "koef": "2.519",
                "metrs": 1
            },
            {
                "size": "2220х48",
                "koef": "2.571",
                "metrs": 1
            },
            {
                "size": "2220х49",
                "koef": "2.623",
                "metrs": 1
            },
            {
                "size": "2220х50",
                "koef": "2.676",
                "metrs": 1
            },
            {
                "size": "2220х51",
                "koef": "2.728",
                "metrs": 1
            },
            {
                "size": "2220х52",
                "koef": "2.78",
                "metrs": 1
            },
            {
                "size": "2220х53",
                "koef": "2.832",
                "metrs": 1
            },
            {
                "size": "2220х54",
                "koef": "2.885",
                "metrs": 1
            },
            {
                "size": "2220х55",
                "koef": "2.937",
                "metrs": 1
            },
            {
                "size": "2220х56",
                "koef": "2.989",
                "metrs": 1
            },
            {
                "size": "2220х57",
                "koef": "3.041",
                "metrs": 1
            },
            {
                "size": "2220х58",
                "koef": "3.092",
                "metrs": 1
            },
            {
                "size": "2220х59",
                "koef": "3.144",
                "metrs": 1
            },
            {
                "size": "2220х60",
                "koef": "3.196",
                "metrs": 1
            },
            {
                "size": "2320х10",
                "koef": "0.5697",
                "metrs": 1
            },
            {
                "size": "2320х12",
                "koef": "0.683",
                "metrs": 1
            },
            {
                "size": "2320х14",
                "koef": "0.7962",
                "metrs": 1
            },
            {
                "size": "2320х15",
                "koef": "0.8527",
                "metrs": 1
            },
            {
                "size": "2320х16",
                "koef": "0.9091",
                "metrs": 1
            },
            {
                "size": "2320х17",
                "koef": "0.9655",
                "metrs": 1
            },
            {
                "size": "2320х18",
                "koef": "1.022",
                "metrs": 1
            },
            {
                "size": "2320х19",
                "koef": "1.078",
                "metrs": 1
            },
            {
                "size": "2320х20",
                "koef": "1.134",
                "metrs": 1
            },
            {
                "size": "2320х21",
                "koef": "1.191",
                "metrs": 1
            },
            {
                "size": "2320х22",
                "koef": "1.247",
                "metrs": 1
            },
            {
                "size": "2320х23",
                "koef": "1.303",
                "metrs": 1
            },
            {
                "size": "2320х24",
                "koef": "1.359",
                "metrs": 1
            },
            {
                "size": "2320х25",
                "koef": "1.415",
                "metrs": 1
            },
            {
                "size": "2320х26",
                "koef": "1.471",
                "metrs": 1
            },
            {
                "size": "2320х27",
                "koef": "1.527",
                "metrs": 1
            },
            {
                "size": "2320х28",
                "koef": "1.583",
                "metrs": 1
            },
            {
                "size": "2320х29",
                "koef": "1.638",
                "metrs": 1
            },
            {
                "size": "2320х30",
                "koef": "1.694",
                "metrs": 1
            },
            {
                "size": "2320х31",
                "koef": "1.75",
                "metrs": 1
            },
            {
                "size": "2320х32",
                "koef": "1.806",
                "metrs": 1
            },
            {
                "size": "2320х33",
                "koef": "1.861",
                "metrs": 1
            },
            {
                "size": "2320х34",
                "koef": "1.917",
                "metrs": 1
            },
            {
                "size": "2320х35",
                "koef": "1.972",
                "metrs": 1
            },
            {
                "size": "2320х36",
                "koef": "2.028",
                "metrs": 1
            },
            {
                "size": "2320х37",
                "koef": "2.083",
                "metrs": 1
            },
            {
                "size": "2320х38",
                "koef": "2.139",
                "metrs": 1
            },
            {
                "size": "2320х39",
                "koef": "2.194",
                "metrs": 1
            },
            {
                "size": "2320х40",
                "koef": "2.249",
                "metrs": 1
            },
            {
                "size": "2320х41",
                "koef": "2.304",
                "metrs": 1
            },
            {
                "size": "2320х42",
                "koef": "2.36",
                "metrs": 1
            },
            {
                "size": "2320х43",
                "koef": "2.415",
                "metrs": 1
            },
            {
                "size": "2320х44",
                "koef": "2.47",
                "metrs": 1
            },
            {
                "size": "2320х45",
                "koef": "2.525",
                "metrs": 1
            },
            {
                "size": "2320х46",
                "koef": "2.58",
                "metrs": 1
            },
            {
                "size": "2320х47",
                "koef": "2.635",
                "metrs": 1
            },
            {
                "size": "2320х48",
                "koef": "2.689",
                "metrs": 1
            },
            {
                "size": "2320х49",
                "koef": "2.744",
                "metrs": 1
            },
            {
                "size": "2320х50",
                "koef": "2.799",
                "metrs": 1
            },
            {
                "size": "2320х51",
                "koef": "2.854",
                "metrs": 1
            },
            {
                "size": "2320х52",
                "koef": "2.908",
                "metrs": 1
            },
            {
                "size": "2320х53",
                "koef": "2.963",
                "metrs": 1
            },
            {
                "size": "2320х54",
                "koef": "3.018",
                "metrs": 1
            },
            {
                "size": "2320х56",
                "koef": "3.127",
                "metrs": 1
            },
            {
                "size": "2320х57",
                "koef": "3.181",
                "metrs": 1
            },
            {
                "size": "2320х58",
                "koef": "3.235",
                "metrs": 1
            },
            {
                "size": "2320х59",
                "koef": "3.29",
                "metrs": 1
            },
            {
                "size": "2320х60",
                "koef": "3.344",
                "metrs": 1
            },
            {
                "size": "2420х10",
                "koef": "0.5943",
                "metrs": 1
            },
            {
                "size": "2420х12",
                "koef": "0.7126",
                "metrs": 1
            },
            {
                "size": "2420х14",
                "koef": "0.8307",
                "metrs": 1
            },
            {
                "size": "2420х15",
                "koef": "0.8897",
                "metrs": 1
            },
            {
                "size": "2420х16",
                "koef": "0.9486",
                "metrs": 1
            },
            {
                "size": "2420х17",
                "koef": "1.007",
                "metrs": 1
            },
            {
                "size": "2420х18",
                "koef": "1.066",
                "metrs": 1
            },
            {
                "size": "2420х19",
                "koef": "1.125",
                "metrs": 1
            },
            {
                "size": "2420х20",
                "koef": "1.184",
                "metrs": 1
            },
            {
                "size": "2420х21",
                "koef": "1.242",
                "metrs": 1
            },
            {
                "size": "2420х22",
                "koef": "1.301",
                "metrs": 1
            },
            {
                "size": "2420х23",
                "koef": "1.36",
                "metrs": 1
            },
            {
                "size": "2420х24",
                "koef": "1.418",
                "metrs": 1
            },
            {
                "size": "2420х25",
                "koef": "1.477",
                "metrs": 1
            },
            {
                "size": "2420х26",
                "koef": "1.535",
                "metrs": 1
            },
            {
                "size": "2420х27",
                "koef": "1.593",
                "metrs": 1
            },
            {
                "size": "2420х28",
                "koef": "1.652",
                "metrs": 1
            },
            {
                "size": "2420х29",
                "koef": "1.71",
                "metrs": 1
            },
            {
                "size": "2420х30",
                "koef": "1.768",
                "metrs": 1
            },
            {
                "size": "2420х31",
                "koef": "1.826",
                "metrs": 1
            },
            {
                "size": "2420х32",
                "koef": "1.885",
                "metrs": 1
            },
            {
                "size": "2420х33",
                "koef": "1.943",
                "metrs": 1
            },
            {
                "size": "2420х34",
                "koef": "2.001",
                "metrs": 1
            },
            {
                "size": "2420х35",
                "koef": "2.059",
                "metrs": 1
            },
            {
                "size": "2420х36",
                "koef": "2.117",
                "metrs": 1
            },
            {
                "size": "2420х37",
                "koef": "2.174",
                "metrs": 1
            },
            {
                "size": "2420х38",
                "koef": "2.232",
                "metrs": 1
            },
            {
                "size": "2420х39",
                "koef": "2.29",
                "metrs": 1
            },
            {
                "size": "2420х40",
                "koef": "2.348",
                "metrs": 1
            },
            {
                "size": "2420х41",
                "koef": "2.405",
                "metrs": 1
            },
            {
                "size": "2420х42",
                "koef": "2.463",
                "metrs": 1
            },
            {
                "size": "2420х43",
                "koef": "2.521",
                "metrs": 1
            },
            {
                "size": "2420х44",
                "koef": "2.578",
                "metrs": 1
            },
            {
                "size": "2420х45",
                "koef": "2.636",
                "metrs": 1
            },
            {
                "size": "2420х46",
                "koef": "2.693",
                "metrs": 1
            },
            {
                "size": "2420х47",
                "koef": "2.751",
                "metrs": 1
            },
            {
                "size": "2420х48",
                "koef": "2.808",
                "metrs": 1
            },
            {
                "size": "2420х49",
                "koef": "2.865",
                "metrs": 1
            },
            {
                "size": "2420х50",
                "koef": "2.922",
                "metrs": 1
            },
            {
                "size": "2420х51",
                "koef": "2.98",
                "metrs": 1
            },
            {
                "size": "2420х52",
                "koef": "3.037",
                "metrs": 1
            },
            {
                "size": "2420х53",
                "koef": "3.094",
                "metrs": 1
            },
            {
                "size": "2420х54",
                "koef": "3.151",
                "metrs": 1
            },
            {
                "size": "2420х55",
                "koef": "3.208",
                "metrs": 1
            },
            {
                "size": "2420х56",
                "koef": "3.265",
                "metrs": 1
            },
            {
                "size": "2420х57",
                "koef": "3.322",
                "metrs": 1
            },
            {
                "size": "2420х58",
                "koef": "3.379",
                "metrs": 1
            },
            {
                "size": "2420х59",
                "koef": "3.435",
                "metrs": 1
            },
            {
                "size": "2420х60",
                "koef": "3.492",
                "metrs": 1
            },
            {
                "size": "2520х10",
                "koef": "0.619",
                "metrs": 1
            },
            {
                "size": "2520х12",
                "koef": "0.7422",
                "metrs": 1
            },
            {
                "size": "2520х14",
                "koef": "0.8652",
                "metrs": 1
            },
            {
                "size": "2520х15",
                "koef": "0.9267",
                "metrs": 1
            },
            {
                "size": "2520х16",
                "koef": "0.988",
                "metrs": 1
            },
            {
                "size": "2520х17",
                "koef": "1.049",
                "metrs": 1
            },
            {
                "size": "2520х18",
                "koef": "1.111",
                "metrs": 1
            },
            {
                "size": "2520х19",
                "koef": "1.172",
                "metrs": 1
            },
            {
                "size": "2520х20",
                "koef": "1.233",
                "metrs": 1
            },
            {
                "size": "2520х21",
                "koef": "1.294",
                "metrs": 1
            },
            {
                "size": "2520х22",
                "koef": "1.355",
                "metrs": 1
            },
            {
                "size": "2520х23",
                "koef": "1.416",
                "metrs": 1
            },
            {
                "size": "2520х24",
                "koef": "1.477",
                "metrs": 1
            },
            {
                "size": "2520х25",
                "koef": "1.538",
                "metrs": 1
            },
            {
                "size": "2520х26",
                "koef": "1.599",
                "metrs": 1
            },
            {
                "size": "2520х27",
                "koef": "1.66",
                "metrs": 1
            },
            {
                "size": "2520х28",
                "koef": "1.721",
                "metrs": 1
            },
            {
                "size": "2520х29",
                "koef": "1.782",
                "metrs": 1
            },
            {
                "size": "2520х30",
                "koef": "1.842",
                "metrs": 1
            },
            {
                "size": "2520х31",
                "koef": "1.903",
                "metrs": 1
            },
            {
                "size": "2520х32",
                "koef": "1.963",
                "metrs": 1
            },
            {
                "size": "2520х33",
                "koef": "2.024",
                "metrs": 1
            },
            {
                "size": "2520х34",
                "koef": "2.084",
                "metrs": 1
            },
            {
                "size": "2520х35",
                "koef": "2.145",
                "metrs": 1
            },
            {
                "size": "2520х36",
                "koef": "2.205",
                "metrs": 1
            },
            {
                "size": "2520х37",
                "koef": "2.266",
                "metrs": 1
            },
            {
                "size": "2520х38",
                "koef": "2.326",
                "metrs": 1
            },
            {
                "size": "2520х39",
                "koef": "2.386",
                "metrs": 1
            },
            {
                "size": "2520х40",
                "koef": "2.446",
                "metrs": 1
            },
            {
                "size": "2520х41",
                "koef": "2.507",
                "metrs": 1
            },
            {
                "size": "2520х42",
                "koef": "2.567",
                "metrs": 1
            },
            {
                "size": "2520х43",
                "koef": "2.627",
                "metrs": 1
            },
            {
                "size": "2520х44",
                "koef": "2.687",
                "metrs": 1
            },
            {
                "size": "2520х45",
                "koef": "2.747",
                "metrs": 1
            },
            {
                "size": "2520х46",
                "koef": "2.807",
                "metrs": 1
            },
            {
                "size": "2520х47",
                "koef": "2.866",
                "metrs": 1
            },
            {
                "size": "2520х48",
                "koef": "2.926",
                "metrs": 1
            },
            {
                "size": "2520х49",
                "koef": "2.986",
                "metrs": 1
            },
            {
                "size": "2520х50",
                "koef": "3.046",
                "metrs": 1
            },
            {
                "size": "2520х51",
                "koef": "3.105",
                "metrs": 1
            },
            {
                "size": "2520х52",
                "koef": "3.165",
                "metrs": 1
            },
            {
                "size": "2520х53",
                "koef": "3.225",
                "metrs": 1
            },
            {
                "size": "2520х54",
                "koef": "3.284",
                "metrs": 1
            },
            {
                "size": "2520х55",
                "koef": "3.343",
                "metrs": 1
            },
            {
                "size": "2520х56",
                "koef": "3.403",
                "metrs": 1
            },
            {
                "size": "2520х57",
                "koef": "3.462",
                "metrs": 1
            },
            {
                "size": "2520х58",
                "koef": "3.522",
                "metrs": 1
            },
            {
                "size": "2520х59",
                "koef": "3.581",
                "metrs": 1
            },
            {
                "size": "2520х60",
                "koef": "3.64",
                "metrs": 1
            },
            {
                "size": "2620х10",
                "koef": "0.6437",
                "metrs": 1
            },
            {
                "size": "2620х12",
                "koef": "0.7718",
                "metrs": 1
            },
            {
                "size": "2620х14",
                "koef": "0.8997",
                "metrs": 1
            },
            {
                "size": "2620х15",
                "koef": "0.9636",
                "metrs": 1
            },
            {
                "size": "2620х16",
                "koef": "1.027",
                "metrs": 1
            },
            {
                "size": "2620х17",
                "koef": "1.091",
                "metrs": 1
            },
            {
                "size": "2620х18",
                "koef": "1.155",
                "metrs": 1
            },
            {
                "size": "2620х19",
                "koef": "1.219",
                "metrs": 1
            },
            {
                "size": "2620х20",
                "koef": "1.282",
                "metrs": 1
            },
            {
                "size": "2620х21",
                "koef": "1.346",
                "metrs": 1
            },
            {
                "size": "2620х22",
                "koef": "1.41",
                "metrs": 1
            },
            {
                "size": "2620х23",
                "koef": "1.473",
                "metrs": 1
            },
            {
                "size": "2620х24",
                "koef": "1.537",
                "metrs": 1
            },
            {
                "size": "2620х25",
                "koef": "1.6",
                "metrs": 1
            },
            {
                "size": "2620х26",
                "koef": "1.663",
                "metrs": 1
            },
            {
                "size": "2620х27",
                "koef": "1.727",
                "metrs": 1
            },
            {
                "size": "2620х28",
                "koef": "1.79",
                "metrs": 1
            },
            {
                "size": "2620х29",
                "koef": "1.853",
                "metrs": 1
            },
            {
                "size": "2620х30",
                "koef": "1.916",
                "metrs": 1
            },
            {
                "size": "2620х31",
                "koef": "1.979",
                "metrs": 1
            },
            {
                "size": "2620х32",
                "koef": "2.042",
                "metrs": 1
            },
            {
                "size": "2620х33",
                "koef": "2.105",
                "metrs": 1
            },
            {
                "size": "2620х34",
                "koef": "2.168",
                "metrs": 1
            },
            {
                "size": "2620х35",
                "koef": "2.231",
                "metrs": 1
            },
            {
                "size": "2620х36",
                "koef": "2.294",
                "metrs": 1
            },
            {
                "size": "2620х37",
                "koef": "2.357",
                "metrs": 1
            },
            {
                "size": "2620х38",
                "koef": "2.42",
                "metrs": 1
            },
            {
                "size": "2620х39",
                "koef": "2.482",
                "metrs": 1
            },
            {
                "size": "2620х40",
                "koef": "2.545",
                "metrs": 1
            },
            {
                "size": "2620х41",
                "koef": "2.608",
                "metrs": 1
            },
            {
                "size": "2620х42",
                "koef": "2.67",
                "metrs": 1
            },
            {
                "size": "2620х43",
                "koef": "2.733",
                "metrs": 1
            },
            {
                "size": "2620х44",
                "koef": "2.795",
                "metrs": 1
            },
            {
                "size": "2620х45",
                "koef": "2.858",
                "metrs": 1
            },
            {
                "size": "2620х46",
                "koef": "2.92",
                "metrs": 1
            },
            {
                "size": "2620х47",
                "koef": "2.982",
                "metrs": 1
            },
            {
                "size": "2620х48",
                "koef": "3.045",
                "metrs": 1
            },
            {
                "size": "2620х49",
                "koef": "3.107",
                "metrs": 1
            },
            {
                "size": "2620х50",
                "koef": "3.169",
                "metrs": 1
            },
            {
                "size": "2620х51",
                "koef": "3.231",
                "metrs": 1
            },
            {
                "size": "2620х52",
                "koef": "3.293",
                "metrs": 1
            },
            {
                "size": "2620х53",
                "koef": "3.355",
                "metrs": 1
            },
            {
                "size": "2620х54",
                "koef": "3.417",
                "metrs": 1
            },
            {
                "size": "2620х55",
                "koef": "3.479",
                "metrs": 1
            },
            {
                "size": "2620х56",
                "koef": "3.541",
                "metrs": 1
            },
            {
                "size": "2620х57",
                "koef": "3.603",
                "metrs": 1
            },
            {
                "size": "2620х58",
                "koef": "3.665",
                "metrs": 1
            },
            {
                "size": "2620х59",
                "koef": "3.726",
                "metrs": 1
            },
            {
                "size": "2620х60",
                "koef": "3.788",
                "metrs": 1
            },
            {
                "size": "2720х10",
                "koef": "0.6683",
                "metrs": 1
            },
            {
                "size": "2720х12",
                "koef": "0.8014",
                "metrs": 1
            },
            {
                "size": "2720х14",
                "koef": "0.9343",
                "metrs": 1
            },
            {
                "size": "2720х15",
                "koef": "1.001",
                "metrs": 1
            },
            {
                "size": "2720х16",
                "koef": "1.067",
                "metrs": 1
            },
            {
                "size": "2720х17",
                "koef": "1.133",
                "metrs": 1
            },
            {
                "size": "2720х18",
                "koef": "1.199",
                "metrs": 1
            },
            {
                "size": "2720х19",
                "koef": "1.266",
                "metrs": 1
            },
            {
                "size": "2720х20",
                "koef": "1.332",
                "metrs": 1
            },
            {
                "size": "2720х21",
                "koef": "1.398",
                "metrs": 1
            },
            {
                "size": "2720х22",
                "koef": "1.464",
                "metrs": 1
            },
            {
                "size": "2720х23",
                "koef": "1.53",
                "metrs": 1
            },
            {
                "size": "2720х24",
                "koef": "1.596",
                "metrs": 1
            },
            {
                "size": "2720х25",
                "koef": "1.662",
                "metrs": 1
            },
            {
                "size": "2720х26",
                "koef": "1.727",
                "metrs": 1
            },
            {
                "size": "2720х27",
                "koef": "1.793",
                "metrs": 1
            },
            {
                "size": "2720х28",
                "koef": "1.859",
                "metrs": 1
            },
            {
                "size": "2720х29",
                "koef": "1.925",
                "metrs": 1
            },
            {
                "size": "2720х30",
                "koef": "1.99",
                "metrs": 1
            },
            {
                "size": "2720х31",
                "koef": "2.056",
                "metrs": 1
            },
            {
                "size": "2720х32",
                "koef": "2.121",
                "metrs": 1
            },
            {
                "size": "2720х33",
                "koef": "2.187",
                "metrs": 1
            },
            {
                "size": "2720х34",
                "koef": "2.252",
                "metrs": 1
            },
            {
                "size": "2720х35",
                "koef": "2.318",
                "metrs": 1
            },
            {
                "size": "2720х36",
                "koef": "2.383",
                "metrs": 1
            },
            {
                "size": "2720х37",
                "koef": "2.448",
                "metrs": 1
            },
            {
                "size": "2720х38",
                "koef": "2.513",
                "metrs": 1
            },
            {
                "size": "2720х39",
                "koef": "2.579",
                "metrs": 1
            },
            {
                "size": "2720х40",
                "koef": "2.644",
                "metrs": 1
            },
            {
                "size": "2720х41",
                "koef": "2.709",
                "metrs": 1
            },
            {
                "size": "2720х42",
                "koef": "2.774",
                "metrs": 1
            },
            {
                "size": "2720х43",
                "koef": "2.839",
                "metrs": 1
            },
            {
                "size": "2720х44",
                "koef": "2.904",
                "metrs": 1
            },
            {
                "size": "2720х45",
                "koef": "2.969",
                "metrs": 1
            },
            {
                "size": "2720х46",
                "koef": "3.033",
                "metrs": 1
            },
            {
                "size": "2720х47",
                "koef": "3.098",
                "metrs": 1
            },
            {
                "size": "2720х48",
                "koef": "3.163",
                "metrs": 1
            },
            {
                "size": "2720х49",
                "koef": "3.228",
                "metrs": 1
            },
            {
                "size": "2720х50",
                "koef": "3.292",
                "metrs": 1
            },
            {
                "size": "2720х51",
                "koef": "3.357",
                "metrs": 1
            },
            {
                "size": "2720х52",
                "koef": "3.421",
                "metrs": 1
            },
            {
                "size": "2720х53",
                "koef": "3.486",
                "metrs": 1
            },
            {
                "size": "2720х54",
                "koef": "3.55",
                "metrs": 1
            },
            {
                "size": "2720х55",
                "koef": "3.615",
                "metrs": 1
            },
            {
                "size": "2720х56",
                "koef": "3.679",
                "metrs": 1
            },
            {
                "size": "2720х57",
                "koef": "3.743",
                "metrs": 1
            },
            {
                "size": "2720х58",
                "koef": "3.808",
                "metrs": 1
            },
            {
                "size": "2720х59",
                "koef": "3.872",
                "metrs": 1
            },
            {
                "size": "2720х60",
                "koef": "3.936",
                "metrs": 1
            },
            {
                "size": "2820х10",
                "koef": "0.693",
                "metrs": 1
            },
            {
                "size": "2820х12",
                "koef": "0.831",
                "metrs": 1
            },
            {
                "size": "2820х14",
                "koef": "0.9688",
                "metrs": 1
            },
            {
                "size": "2820х15",
                "koef": "1.038",
                "metrs": 1
            },
            {
                "size": "2820х16",
                "koef": "1.106",
                "metrs": 1
            },
            {
                "size": "2820х17",
                "koef": "1.175",
                "metrs": 1
            },
            {
                "size": "2820х18",
                "koef": "1.244",
                "metrs": 1
            },
            {
                "size": "2820х19",
                "koef": "1.312",
                "metrs": 1
            },
            {
                "size": "2820х20",
                "koef": "1.381",
                "metrs": 1
            },
            {
                "size": "2820х21",
                "koef": "1.45",
                "metrs": 1
            },
            {
                "size": "2820х22",
                "koef": "1.518",
                "metrs": 1
            },
            {
                "size": "2820х23",
                "koef": "1.586",
                "metrs": 1
            },
            {
                "size": "2820х24",
                "koef": "1.655",
                "metrs": 1
            },
            {
                "size": "2820х25",
                "koef": "1.723",
                "metrs": 1
            },
            {
                "size": "2820х26",
                "koef": "1.792",
                "metrs": 1
            },
            {
                "size": "2820х27",
                "koef": "1.86",
                "metrs": 1
            },
            {
                "size": "2820х28",
                "koef": "1.928",
                "metrs": 1
            },
            {
                "size": "2820х29",
                "koef": "1.996",
                "metrs": 1
            },
            {
                "size": "2820х30",
                "koef": "2.064",
                "metrs": 1
            },
            {
                "size": "2820х31",
                "koef": "2.132",
                "metrs": 1
            },
            {
                "size": "2820х32",
                "koef": "2.2",
                "metrs": 1
            },
            {
                "size": "2820х33",
                "koef": "2.268",
                "metrs": 1
            },
            {
                "size": "2820х34",
                "koef": "2.336",
                "metrs": 1
            },
            {
                "size": "2820х35",
                "koef": "2.404",
                "metrs": 1
            },
            {
                "size": "2820х36",
                "koef": "2.472",
                "metrs": 1
            },
            {
                "size": "2820х37",
                "koef": "2.539",
                "metrs": 1
            },
            {
                "size": "2820х38",
                "koef": "2.607",
                "metrs": 1
            },
            {
                "size": "2820х39",
                "koef": "2.675",
                "metrs": 1
            },
            {
                "size": "2820х40",
                "koef": "2.742",
                "metrs": 1
            },
            {
                "size": "2820х41",
                "koef": "2.81",
                "metrs": 1
            },
            {
                "size": "2820х42",
                "koef": "2.877",
                "metrs": 1
            },
            {
                "size": "2820х43",
                "koef": "2.945",
                "metrs": 1
            },
            {
                "size": "2820х44",
                "koef": "3.012",
                "metrs": 1
            },
            {
                "size": "2820х45",
                "koef": "3.08",
                "metrs": 1
            },
            {
                "size": "2820х46",
                "koef": "3.147",
                "metrs": 1
            },
            {
                "size": "2820х47",
                "koef": "3.214",
                "metrs": 1
            },
            {
                "size": "2820х48",
                "koef": "3.281",
                "metrs": 1
            },
            {
                "size": "2820х49",
                "koef": "3.349",
                "metrs": 1
            },
            {
                "size": "2820х50",
                "koef": "3.416",
                "metrs": 1
            },
            {
                "size": "2820х51",
                "koef": "3.483",
                "metrs": 1
            },
            {
                "size": "2820х52",
                "koef": "3.55",
                "metrs": 1
            },
            {
                "size": "2820х53",
                "koef": "3.617",
                "metrs": 1
            },
            {
                "size": "2820х54",
                "koef": "3.684",
                "metrs": 1
            },
            {
                "size": "2820х55",
                "koef": "3.75",
                "metrs": 1
            },
            {
                "size": "2820х56",
                "koef": "3.817",
                "metrs": 1
            },
            {
                "size": "2820х57",
                "koef": "3.884",
                "metrs": 1
            },
            {
                "size": "2820х58",
                "koef": "3.951",
                "metrs": 1
            },
            {
                "size": "2820х59",
                "koef": "4.017",
                "metrs": 1
            },
            {
                "size": "2820х60",
                "koef": "4.084",
                "metrs": 1
            },
            {
                "size": "2920х10",
                "koef": "0.7176",
                "metrs": 1
            },
            {
                "size": "2920х12",
                "koef": "0.8606",
                "metrs": 1
            },
            {
                "size": "2920х14",
                "koef": "1.003",
                "metrs": 1
            },
            {
                "size": "2920х15",
                "koef": "1.075",
                "metrs": 1
            },
            {
                "size": "2920х16",
                "koef": "1.146",
                "metrs": 1
            },
            {
                "size": "2920х17",
                "koef": "1.217",
                "metrs": 1
            },
            {
                "size": "2920х18",
                "koef": "1.288",
                "metrs": 1
            },
            {
                "size": "2920х19",
                "koef": "1.359",
                "metrs": 1
            },
            {
                "size": "2920х20",
                "koef": "1.43",
                "metrs": 1
            },
            {
                "size": "2920х21",
                "koef": "1.501",
                "metrs": 1
            },
            {
                "size": "2920х22",
                "koef": "1.572",
                "metrs": 1
            },
            {
                "size": "2920х23",
                "koef": "1.643",
                "metrs": 1
            },
            {
                "size": "2920х24",
                "koef": "1.714",
                "metrs": 1
            },
            {
                "size": "2920х25",
                "koef": "1.785",
                "metrs": 1
            },
            {
                "size": "2920х26",
                "koef": "1.856",
                "metrs": 1
            },
            {
                "size": "2920х27",
                "koef": "1.926",
                "metrs": 1
            },
            {
                "size": "2920х28",
                "koef": "1.997",
                "metrs": 1
            },
            {
                "size": "2920х29",
                "koef": "2.068",
                "metrs": 1
            },
            {
                "size": "2920х30",
                "koef": "2.138",
                "metrs": 1
            },
            {
                "size": "2920х31",
                "koef": "2.209",
                "metrs": 1
            },
            {
                "size": "2920х32",
                "koef": "2.279",
                "metrs": 1
            },
            {
                "size": "2920х33",
                "koef": "2.35",
                "metrs": 1
            },
            {
                "size": "2920х34",
                "koef": "2.42",
                "metrs": 1
            },
            {
                "size": "2920х35",
                "koef": "2.49",
                "metrs": 1
            },
            {
                "size": "2920х36",
                "koef": "2.56",
                "metrs": 1
            },
            {
                "size": "2920х37",
                "koef": "2.631",
                "metrs": 1
            },
            {
                "size": "2920х38",
                "koef": "2.701",
                "metrs": 1
            },
            {
                "size": "2920х39",
                "koef": "2.771",
                "metrs": 1
            },
            {
                "size": "2920х40",
                "koef": "2.841",
                "metrs": 1
            },
            {
                "size": "2920х41",
                "koef": "2.911",
                "metrs": 1
            },
            {
                "size": "2920х42",
                "koef": "2.981",
                "metrs": 1
            },
            {
                "size": "2920х43",
                "koef": "3.051",
                "metrs": 1
            },
            {
                "size": "2920х44",
                "koef": "3.121",
                "metrs": 1
            },
            {
                "size": "2920х45",
                "koef": "3.191",
                "metrs": 1
            },
            {
                "size": "2920х46",
                "koef": "3.26",
                "metrs": 1
            },
            {
                "size": "2920х47",
                "koef": "3.33",
                "metrs": 1
            },
            {
                "size": "2920х48",
                "koef": "3.4",
                "metrs": 1
            },
            {
                "size": "2920х49",
                "koef": "3.469",
                "metrs": 1
            },
            {
                "size": "2920х50",
                "koef": "3.539",
                "metrs": 1
            },
            {
                "size": "2920х51",
                "koef": "3.608",
                "metrs": 1
            },
            {
                "size": "2920х52",
                "koef": "3.678",
                "metrs": 1
            },
            {
                "size": "2920х53",
                "koef": "3.747",
                "metrs": 1
            },
            {
                "size": "2920х54",
                "koef": "3.817",
                "metrs": 1
            },
            {
                "size": "2920х55",
                "koef": "3.886",
                "metrs": 1
            },
            {
                "size": "2920х56",
                "koef": "3.955",
                "metrs": 1
            },
            {
                "size": "2920х57",
                "koef": "4.025",
                "metrs": 1
            },
            {
                "size": "2920х58",
                "koef": "4.094",
                "metrs": 1
            },
            {
                "size": "2920х59",
                "koef": "4.163",
                "metrs": 1
            },
            {
                "size": "2920х60",
                "koef": "4.232",
                "metrs": 1
            },
            {
                "size": "3000х20",
                "koef": "1.47",
                "metrs": 1
            },
            {
                "size": "3000х21",
                "koef": "1.543",
                "metrs": 1
            },
            {
                "size": "3000х22",
                "koef": "1.616",
                "metrs": 1
            },
            {
                "size": "3000х23",
                "koef": "1.689",
                "metrs": 1
            },
            {
                "size": "3000х24",
                "koef": "1.761",
                "metrs": 1
            },
            {
                "size": "3000х25",
                "koef": "1.834",
                "metrs": 1
            },
            {
                "size": "3000х26",
                "koef": "1.907",
                "metrs": 1
            },
            {
                "size": "3000х27",
                "koef": "1.98",
                "metrs": 1
            },
            {
                "size": "3000х28",
                "koef": "2.052",
                "metrs": 1
            },
            {
                "size": "3000х29",
                "koef": "2.125",
                "metrs": 1
            },
            {
                "size": "3000х30",
                "koef": "2.197",
                "metrs": 1
            },
            {
                "size": "3000х31",
                "koef": "2.27",
                "metrs": 1
            },
            {
                "size": "3000х32",
                "koef": "2.342",
                "metrs": 1
            },
            {
                "size": "3000х33",
                "koef": "2.415",
                "metrs": 1
            },
            {
                "size": "3000х34",
                "koef": "2.487",
                "metrs": 1
            },
            {
                "size": "3000х35",
                "koef": "2.559",
                "metrs": 1
            },
            {
                "size": "3000х36",
                "koef": "2.631",
                "metrs": 1
            },
            {
                "size": "3000х37",
                "koef": "2.704",
                "metrs": 1
            },
            {
                "size": "3000х38",
                "koef": "2.776",
                "metrs": 1
            },
            {
                "size": "3000х39",
                "koef": "2.848",
                "metrs": 1
            },
            {
                "size": "3000х40",
                "koef": "2.92",
                "metrs": 1
            },
            {
                "size": "3000х41",
                "koef": "2.992",
                "metrs": 1
            },
            {
                "size": "3000х42",
                "koef": "3.064",
                "metrs": 1
            },
            {
                "size": "3000х43",
                "koef": "3.136",
                "metrs": 1
            },
            {
                "size": "3000х44",
                "koef": "3.208",
                "metrs": 1
            },
            {
                "size": "3000х45",
                "koef": "3.279",
                "metrs": 1
            },
            {
                "size": "3000х46",
                "koef": "3.351",
                "metrs": 1
            },
            {
                "size": "3000х47",
                "koef": "3.423",
                "metrs": 1
            },
            {
                "size": "3000х48",
                "koef": "3.494",
                "metrs": 1
            },
            {
                "size": "3000х49",
                "koef": "3.566",
                "metrs": 1
            },
            {
                "size": "3000х50",
                "koef": "3.638",
                "metrs": 1
            },
            {
                "size": "3000х51",
                "koef": "3.709",
                "metrs": 1
            },
            {
                "size": "3000х52",
                "koef": "3.781",
                "metrs": 1
            },
            {
                "size": "3000х53",
                "koef": "3.852",
                "metrs": 1
            },
            {
                "size": "3000х54",
                "koef": "3.923",
                "metrs": 1
            },
            {
                "size": "3000х55",
                "koef": "3.995",
                "metrs": 1
            },
            {
                "size": "3000х56",
                "koef": "4.066",
                "metrs": 1
            },
            {
                "size": "3000х57",
                "koef": "4.137",
                "metrs": 1
            },
            {
                "size": "3000х58",
                "koef": "4.208",
                "metrs": 1
            },
            {
                "size": "3000х59",
                "koef": "4.279",
                "metrs": 1
            },
            {
                "size": "3000х60",
                "koef": "4.35",
                "metrs": 1
            },
            {
                "size": "3020х10",
                "koef": "0.7423",
                "metrs": 1
            },
            {
                "size": "3020х12",
                "koef": "0.8902",
                "metrs": 1
            },
            {
                "size": "3020х14",
                "koef": "1.038",
                "metrs": 1
            },
            {
                "size": "3020х15",
                "koef": "1.112",
                "metrs": 1
            },
            {
                "size": "3020х16",
                "koef": "1.185",
                "metrs": 1
            },
            {
                "size": "3020х17",
                "koef": "1.259",
                "metrs": 1
            },
            {
                "size": "3020х18",
                "koef": "1.333",
                "metrs": 1
            },
            {
                "size": "3020х19",
                "koef": "1.406",
                "metrs": 1
            },
            {
                "size": "3020х20",
                "koef": "1.48",
                "metrs": 1
            },
            {
                "size": "3020х21",
                "koef": "1.553",
                "metrs": 1
            },
            {
                "size": "3020х22",
                "koef": "1.627",
                "metrs": 1
            },
            {
                "size": "3020х23",
                "koef": "1.7",
                "metrs": 1
            },
            {
                "size": "3020х24",
                "koef": "1.773",
                "metrs": 1
            },
            {
                "size": "3020х25",
                "koef": "1.847",
                "metrs": 1
            },
            {
                "size": "3020х26",
                "koef": "1.92",
                "metrs": 1
            },
            {
                "size": "3020х27",
                "koef": "1.993",
                "metrs": 1
            },
            {
                "size": "3020х28",
                "koef": "2.066",
                "metrs": 1
            },
            {
                "size": "3020х29",
                "koef": "2.139",
                "metrs": 1
            },
            {
                "size": "3020х30",
                "koef": "2.212",
                "metrs": 1
            },
            {
                "size": "3020х31",
                "koef": "2.285",
                "metrs": 1
            },
            {
                "size": "3020х32",
                "koef": "2.358",
                "metrs": 1
            },
            {
                "size": "3020х33",
                "koef": "2.431",
                "metrs": 1
            },
            {
                "size": "3020х34",
                "koef": "2.504",
                "metrs": 1
            },
            {
                "size": "3020х35",
                "koef": "2.577",
                "metrs": 1
            },
            {
                "size": "3020х36",
                "koef": "2.649",
                "metrs": 1
            },
            {
                "size": "3020х37",
                "koef": "2.722",
                "metrs": 1
            },
            {
                "size": "3020х38",
                "koef": "2.795",
                "metrs": 1
            },
            {
                "size": "3020х39",
                "koef": "2.867",
                "metrs": 1
            },
            {
                "size": "3020х40",
                "koef": "2.94",
                "metrs": 1
            },
            {
                "size": "3020х41",
                "koef": "3.012",
                "metrs": 1
            },
            {
                "size": "3020х42",
                "koef": "3.085",
                "metrs": 1
            },
            {
                "size": "3020х43",
                "koef": "3.157",
                "metrs": 1
            },
            {
                "size": "3020х44",
                "koef": "3.229",
                "metrs": 1
            },
            {
                "size": "3020х45",
                "koef": "3.302",
                "metrs": 1
            },
            {
                "size": "3020х46",
                "koef": "3.374",
                "metrs": 1
            },
            {
                "size": "3020х47",
                "koef": "3.446",
                "metrs": 1
            },
            {
                "size": "3020х48",
                "koef": "3.518",
                "metrs": 1
            },
            {
                "size": "3020х49",
                "koef": "3.59",
                "metrs": 1
            },
            {
                "size": "3020х50",
                "koef": "3.662",
                "metrs": 1
            },
            {
                "size": "3020х51",
                "koef": "3.734",
                "metrs": 1
            },
            {
                "size": "3020х52",
                "koef": "3.806",
                "metrs": 1
            },
            {
                "size": "3020х53",
                "koef": "3.878",
                "metrs": 1
            },
            {
                "size": "3020х54",
                "koef": "3.95",
                "metrs": 1
            },
            {
                "size": "3020х55",
                "koef": "4.022",
                "metrs": 1
            },
            {
                "size": "3020х56",
                "koef": "4.093",
                "metrs": 1
            },
            {
                "size": "3020х57",
                "koef": "4.165",
                "metrs": 1
            },
            {
                "size": "3020х58",
                "koef": "4.237",
                "metrs": 1
            },
            {
                "size": "3020х59",
                "koef": "4.308",
                "metrs": 1
            },
            {
                "size": "3020х60",
                "koef": "4.38",
                "metrs": 1
            },
            {
                "size": "3120х10",
                "koef": "0.767",
                "metrs": 1
            },
            {
                "size": "3120х12",
                "koef": "0.9198",
                "metrs": 1
            },
            {
                "size": "3120х14",
                "koef": "1.072",
                "metrs": 1
            },
            {
                "size": "3120х15",
                "koef": "1.149",
                "metrs": 1
            },
            {
                "size": "3120х16",
                "koef": "1.225",
                "metrs": 1
            },
            {
                "size": "3120х17",
                "koef": "1.301",
                "metrs": 1
            },
            {
                "size": "3120х18",
                "koef": "1.377",
                "metrs": 1
            },
            {
                "size": "3120х19",
                "koef": "1.453",
                "metrs": 1
            },
            {
                "size": "3120х20",
                "koef": "1.529",
                "metrs": 1
            },
            {
                "size": "3120х21",
                "koef": "1.605",
                "metrs": 1
            },
            {
                "size": "3120х22",
                "koef": "1.681",
                "metrs": 1
            },
            {
                "size": "3120х23",
                "koef": "1.757",
                "metrs": 1
            },
            {
                "size": "3120х24",
                "koef": "1.832",
                "metrs": 1
            },
            {
                "size": "3120х25",
                "koef": "1.908",
                "metrs": 1
            },
            {
                "size": "3120х26",
                "koef": "1.984",
                "metrs": 1
            },
            {
                "size": "3120х27",
                "koef": "2.06",
                "metrs": 1
            },
            {
                "size": "3120х28",
                "koef": "2.135",
                "metrs": 1
            },
            {
                "size": "3120х29",
                "koef": "2.211",
                "metrs": 1
            },
            {
                "size": "3120х30",
                "koef": "2.286",
                "metrs": 1
            },
            {
                "size": "3120х31",
                "koef": "2.362",
                "metrs": 1
            },
            {
                "size": "3120х32",
                "koef": "2.437",
                "metrs": 1
            },
            {
                "size": "3120х33",
                "koef": "2.512",
                "metrs": 1
            },
            {
                "size": "3120х34",
                "koef": "2.588",
                "metrs": 1
            },
            {
                "size": "3120х35",
                "koef": "2.663",
                "metrs": 1
            },
            {
                "size": "3120х36",
                "koef": "2.738",
                "metrs": 1
            },
            {
                "size": "3120х37",
                "koef": "2.813",
                "metrs": 1
            },
            {
                "size": "3120х38",
                "koef": "2.888",
                "metrs": 1
            },
            {
                "size": "3120х39",
                "koef": "2.963",
                "metrs": 1
            },
            {
                "size": "3120х40",
                "koef": "3.038",
                "metrs": 1
            },
            {
                "size": "3120х41",
                "koef": "3.113",
                "metrs": 1
            },
            {
                "size": "3120х42",
                "koef": "3.188",
                "metrs": 1
            },
            {
                "size": "3120х43",
                "koef": "3.263",
                "metrs": 1
            },
            {
                "size": "3120х44",
                "koef": "3.338",
                "metrs": 1
            },
            {
                "size": "3120х45",
                "koef": "3.413",
                "metrs": 1
            },
            {
                "size": "3120х46",
                "koef": "3.487",
                "metrs": 1
            },
            {
                "size": "3120х47",
                "koef": "3.562",
                "metrs": 1
            },
            {
                "size": "3120х48",
                "koef": "3.636",
                "metrs": 1
            },
            {
                "size": "3120х49",
                "koef": "3.711",
                "metrs": 1
            },
            {
                "size": "3120х50",
                "koef": "3.786",
                "metrs": 1
            },
            {
                "size": "3220х10",
                "koef": "0.7916",
                "metrs": 1
            },
            {
                "size": "3220х12",
                "koef": "0.9494",
                "metrs": 1
            },
            {
                "size": "3220х14",
                "koef": "1.107",
                "metrs": 1
            },
            {
                "size": "3220х15",
                "koef": "1.186",
                "metrs": 1
            },
            {
                "size": "3220х16",
                "koef": "1.264",
                "metrs": 1
            },
            {
                "size": "3220х17",
                "koef": "1.343",
                "metrs": 1
            },
            {
                "size": "3220х18",
                "koef": "1.421",
                "metrs": 1
            },
            {
                "size": "3220х19",
                "koef": "1.5",
                "metrs": 1
            },
            {
                "size": "3220х20",
                "koef": "1.578",
                "metrs": 1
            },
            {
                "size": "3220х21",
                "koef": "1.657",
                "metrs": 1
            },
            {
                "size": "3220х22",
                "koef": "1.735",
                "metrs": 1
            },
            {
                "size": "3220х23",
                "koef": "1.813",
                "metrs": 1
            },
            {
                "size": "3220х24",
                "koef": "1.892",
                "metrs": 1
            },
            {
                "size": "3220х25",
                "koef": "1.97",
                "metrs": 1
            },
            {
                "size": "3220х26",
                "koef": "2.048",
                "metrs": 1
            },
            {
                "size": "3220х27",
                "koef": "2.126",
                "metrs": 1
            },
            {
                "size": "3220х28",
                "koef": "2.204",
                "metrs": 1
            },
            {
                "size": "3220х29",
                "koef": "2.282",
                "metrs": 1
            },
            {
                "size": "3220х30",
                "koef": "2.36",
                "metrs": 1
            },
            {
                "size": "3220х31",
                "koef": "2.438",
                "metrs": 1
            },
            {
                "size": "3220х32",
                "koef": "2.516",
                "metrs": 1
            },
            {
                "size": "3220х33",
                "koef": "2.594",
                "metrs": 1
            },
            {
                "size": "3220х34",
                "koef": "2.671",
                "metrs": 1
            },
            {
                "size": "3220х35",
                "koef": "2.749",
                "metrs": 1
            },
            {
                "size": "3220х36",
                "koef": "2.827",
                "metrs": 1
            },
            {
                "size": "3220х37",
                "koef": "2.904",
                "metrs": 1
            },
            {
                "size": "3220х38",
                "koef": "2.982",
                "metrs": 1
            },
            {
                "size": "3220х39",
                "koef": "3.059",
                "metrs": 1
            },
            {
                "size": "3220х40",
                "koef": "3.137",
                "metrs": 1
            },
            {
                "size": "3220х41",
                "koef": "3.214",
                "metrs": 1
            },
            {
                "size": "3220х42",
                "koef": "3.292",
                "metrs": 1
            },
            {
                "size": "3220х43",
                "koef": "3.369",
                "metrs": 1
            },
            {
                "size": "3220х44",
                "koef": "3.446",
                "metrs": 1
            },
            {
                "size": "3220х45",
                "koef": "3.524",
                "metrs": 1
            },
            {
                "size": "3220х46",
                "koef": "3.601",
                "metrs": 1
            },
            {
                "size": "3220х47",
                "koef": "3.678",
                "metrs": 1
            },
            {
                "size": "3220х48",
                "koef": "3.755",
                "metrs": 1
            },
            {
                "size": "3220х49",
                "koef": "3.832",
                "metrs": 1
            },
            {
                "size": "3220х50",
                "koef": "3.909",
                "metrs": 1
            },
            {
                "size": "3320х10",
                "koef": "0.8163",
                "metrs": 1
            },
            {
                "size": "3320х12",
                "koef": "0.979",
                "metrs": 1
            },
            {
                "size": "3320х14",
                "koef": "1.141",
                "metrs": 1
            },
            {
                "size": "3320х15",
                "koef": "1.223",
                "metrs": 1
            },
            {
                "size": "3320х16",
                "koef": "1.304",
                "metrs": 1
            },
            {
                "size": "3320х17",
                "koef": "1.385",
                "metrs": 1
            },
            {
                "size": "3320х18",
                "koef": "1.466",
                "metrs": 1
            },
            {
                "size": "3320х19",
                "koef": "1.547",
                "metrs": 1
            },
            {
                "size": "3320х20",
                "koef": "1.628",
                "metrs": 1
            },
            {
                "size": "3320х21",
                "koef": "1.709",
                "metrs": 1
            },
            {
                "size": "3320х22",
                "koef": "1.789",
                "metrs": 1
            },
            {
                "size": "3320х23",
                "koef": "1.87",
                "metrs": 1
            },
            {
                "size": "3320х24",
                "koef": "1.951",
                "metrs": 1
            },
            {
                "size": "3320х25",
                "koef": "2.031",
                "metrs": 1
            },
            {
                "size": "3320х26",
                "koef": "2.112",
                "metrs": 1
            },
            {
                "size": "3320х27",
                "koef": "2.193",
                "metrs": 1
            },
            {
                "size": "3320х28",
                "koef": "2.273",
                "metrs": 1
            },
            {
                "size": "3320х29",
                "koef": "2.354",
                "metrs": 1
            },
            {
                "size": "3320х30",
                "koef": "2.434",
                "metrs": 1
            },
            {
                "size": "3320х31",
                "koef": "2.514",
                "metrs": 1
            },
            {
                "size": "3320х32",
                "koef": "2.595",
                "metrs": 1
            },
            {
                "size": "3320х33",
                "koef": "2.675",
                "metrs": 1
            },
            {
                "size": "3320х34",
                "koef": "2.755",
                "metrs": 1
            },
            {
                "size": "3320х35",
                "koef": "2.835",
                "metrs": 1
            },
            {
                "size": "3320х36",
                "koef": "2.916",
                "metrs": 1
            },
            {
                "size": "3320х37",
                "koef": "2.996",
                "metrs": 1
            },
            {
                "size": "3320х38",
                "koef": "3.076",
                "metrs": 1
            },
            {
                "size": "3320х39",
                "koef": "3.156",
                "metrs": 1
            },
            {
                "size": "3320х40",
                "koef": "3.236",
                "metrs": 1
            },
            {
                "size": "3320х41",
                "koef": "3.315",
                "metrs": 1
            },
            {
                "size": "3320х42",
                "koef": "3.395",
                "metrs": 1
            },
            {
                "size": "3320х43",
                "koef": "3.475",
                "metrs": 1
            },
            {
                "size": "3320х44",
                "koef": "3.555",
                "metrs": 1
            },
            {
                "size": "3320х45",
                "koef": "3.634",
                "metrs": 1
            },
            {
                "size": "3320х46",
                "koef": "3.714",
                "metrs": 1
            },
            {
                "size": "3320х47",
                "koef": "3.794",
                "metrs": 1
            },
            {
                "size": "3320х48",
                "koef": "3.873",
                "metrs": 1
            },
            {
                "size": "3320х49",
                "koef": "3.953",
                "metrs": 1
            },
            {
                "size": "3320х50",
                "koef": "4.032",
                "metrs": 1
            },
            {
                "size": "3420х10",
                "koef": "0.841",
                "metrs": 1
            },
            {
                "size": "3420х12",
                "koef": "1.009",
                "metrs": 1
            },
            {
                "size": "3420х14",
                "koef": "1.176",
                "metrs": 1
            },
            {
                "size": "3420х15",
                "koef": "1.26",
                "metrs": 1
            },
            {
                "size": "3420х16",
                "koef": "1.343",
                "metrs": 1
            },
            {
                "size": "3420х17",
                "koef": "1.427",
                "metrs": 1
            },
            {
                "size": "3420х18",
                "koef": "1.51",
                "metrs": 1
            },
            {
                "size": "3420х19",
                "koef": "1.594",
                "metrs": 1
            },
            {
                "size": "3420х20",
                "koef": "1.677",
                "metrs": 1
            },
            {
                "size": "3420х21",
                "koef": "1.76",
                "metrs": 1
            },
            {
                "size": "3420х22",
                "koef": "1.844",
                "metrs": 1
            },
            {
                "size": "3420х23",
                "koef": "1.927",
                "metrs": 1
            },
            {
                "size": "3420х24",
                "koef": "2.01",
                "metrs": 1
            },
            {
                "size": "3420х25",
                "koef": "2.093",
                "metrs": 1
            },
            {
                "size": "3420х26",
                "koef": "2.176",
                "metrs": 1
            },
            {
                "size": "3420х27",
                "koef": "2.259",
                "metrs": 1
            },
            {
                "size": "3420х28",
                "koef": "2.342",
                "metrs": 1
            },
            {
                "size": "3420х29",
                "koef": "2.425",
                "metrs": 1
            },
            {
                "size": "3420х30",
                "koef": "2.508",
                "metrs": 1
            },
            {
                "size": "3420х31",
                "koef": "2.591",
                "metrs": 1
            },
            {
                "size": "3420х32",
                "koef": "2.674",
                "metrs": 1
            },
            {
                "size": "3420х33",
                "koef": "2.756",
                "metrs": 1
            },
            {
                "size": "3420х34",
                "koef": "2.839",
                "metrs": 1
            },
            {
                "size": "3420х35",
                "koef": "2.922",
                "metrs": 1
            },
            {
                "size": "3420х36",
                "koef": "3.004",
                "metrs": 1
            },
            {
                "size": "3420х37",
                "koef": "3.087",
                "metrs": 1
            },
            {
                "size": "3420х38",
                "koef": "3.169",
                "metrs": 1
            },
            {
                "size": "3420х39",
                "koef": "3.252",
                "metrs": 1
            },
            {
                "size": "3420х40",
                "koef": "3.334",
                "metrs": 1
            },
            {
                "size": "3420х41",
                "koef": "3.417",
                "metrs": 1
            },
            {
                "size": "3420х42",
                "koef": "3.499",
                "metrs": 1
            },
            {
                "size": "3420х43",
                "koef": "3.581",
                "metrs": 1
            },
            {
                "size": "3420х44",
                "koef": "3.663",
                "metrs": 1
            },
            {
                "size": "3420х45",
                "koef": "3.745",
                "metrs": 1
            },
            {
                "size": "3420х46",
                "koef": "3.828",
                "metrs": 1
            },
            {
                "size": "3420х47",
                "koef": "3.91",
                "metrs": 1
            },
            {
                "size": "3420х48",
                "koef": "3.992",
                "metrs": 1
            },
            {
                "size": "3420х49",
                "koef": "4.074",
                "metrs": 1
            },
            {
                "size": "3420х50",
                "koef": "4.155",
                "metrs": 1
            },
            {
                "size": "3520х10",
                "koef": "0.8656",
                "metrs": 1
            },
            {
                "size": "3520х12",
                "koef": "1.038",
                "metrs": 1
            },
            {
                "size": "3520х14",
                "koef": "1.21",
                "metrs": 1
            },
            {
                "size": "3520х15",
                "koef": "1.297",
                "metrs": 1
            },
            {
                "size": "3520х16",
                "koef": "1.383",
                "metrs": 1
            },
            {
                "size": "3520х17",
                "koef": "1.469",
                "metrs": 1
            },
            {
                "size": "3520х18",
                "koef": "1.555",
                "metrs": 1
            },
            {
                "size": "3520х19",
                "koef": "1.64",
                "metrs": 1
            },
            {
                "size": "3520х20",
                "koef": "1.726",
                "metrs": 1
            },
            {
                "size": "3520х21",
                "koef": "1.812",
                "metrs": 1
            },
            {
                "size": "3520х22",
                "koef": "1.898",
                "metrs": 1
            },
            {
                "size": "3520х23",
                "koef": "1.984",
                "metrs": 1
            },
            {
                "size": "3520х24",
                "koef": "2.069",
                "metrs": 1
            },
            {
                "size": "3520х25",
                "koef": "2.155",
                "metrs": 1
            },
            {
                "size": "3520х26",
                "koef": "2.24",
                "metrs": 1
            },
            {
                "size": "3520х27",
                "koef": "2.326",
                "metrs": 1
            },
            {
                "size": "3520х28",
                "koef": "2.411",
                "metrs": 1
            },
            {
                "size": "3520х29",
                "koef": "2.497",
                "metrs": 1
            },
            {
                "size": "3520х30",
                "koef": "2.582",
                "metrs": 1
            },
            {
                "size": "3520х31",
                "koef": "2.667",
                "metrs": 1
            },
            {
                "size": "3520х32",
                "koef": "2.753",
                "metrs": 1
            },
            {
                "size": "3520х33",
                "koef": "2.838",
                "metrs": 1
            },
            {
                "size": "3520х34",
                "koef": "2.923",
                "metrs": 1
            },
            {
                "size": "3520х35",
                "koef": "3.008",
                "metrs": 1
            },
            {
                "size": "3520х36",
                "koef": "3.093",
                "metrs": 1
            },
            {
                "size": "3520х37",
                "koef": "3.178",
                "metrs": 1
            },
            {
                "size": "3520х38",
                "koef": "3.263",
                "metrs": 1
            },
            {
                "size": "3520х39",
                "koef": "3.348",
                "metrs": 1
            },
            {
                "size": "3520х40",
                "koef": "3.433",
                "metrs": 1
            },
            {
                "size": "3520х41",
                "koef": "3.518",
                "metrs": 1
            },
            {
                "size": "3520х42",
                "koef": "3.602",
                "metrs": 1
            },
            {
                "size": "3520х43",
                "koef": "3.687",
                "metrs": 1
            },
            {
                "size": "3520х44",
                "koef": "3.772",
                "metrs": 1
            },
            {
                "size": "3520х45",
                "koef": "3.856",
                "metrs": 1
            },
            {
                "size": "3520х46",
                "koef": "3.941",
                "metrs": 1
            },
            {
                "size": "3520х47",
                "koef": "4.026",
                "metrs": 1
            },
            {
                "size": "3520х48",
                "koef": "4.11",
                "metrs": 1
            },
            {
                "size": "3520х49",
                "koef": "4.194",
                "metrs": 1
            },
            {
                "size": "3520х50",
                "koef": "4.279",
                "metrs": 1
            },
            {
                "size": "3620х10",
                "koef": "0.8903",
                "metrs": 1
            },
            {
                "size": "3620х12",
                "koef": "1.068",
                "metrs": 1
            },
            {
                "size": "3620х14",
                "koef": "1.245",
                "metrs": 1
            },
            {
                "size": "3620х15",
                "koef": "1.334",
                "metrs": 1
            },
            {
                "size": "3620х16",
                "koef": "1.422",
                "metrs": 1
            },
            {
                "size": "3620х17",
                "koef": "1.511",
                "metrs": 1
            },
            {
                "size": "3620х18",
                "koef": "1.599",
                "metrs": 1
            },
            {
                "size": "3620х19",
                "koef": "1.687",
                "metrs": 1
            },
            {
                "size": "3620х20",
                "koef": "1.776",
                "metrs": 1
            },
            {
                "size": "3620х21",
                "koef": "1.864",
                "metrs": 1
            },
            {
                "size": "3620х22",
                "koef": "1.952",
                "metrs": 1
            },
            {
                "size": "3620х23",
                "koef": "2.04",
                "metrs": 1
            },
            {
                "size": "3620х24",
                "koef": "2.128",
                "metrs": 1
            },
            {
                "size": "3620х25",
                "koef": "2.216",
                "metrs": 1
            },
            {
                "size": "3620х26",
                "koef": "2.304",
                "metrs": 1
            },
            {
                "size": "3620х27",
                "koef": "2.392",
                "metrs": 1
            },
            {
                "size": "3620х28",
                "koef": "2.48",
                "metrs": 1
            },
            {
                "size": "3620х29",
                "koef": "2.568",
                "metrs": 1
            },
            {
                "size": "3620х30",
                "koef": "2.656",
                "metrs": 1
            },
            {
                "size": "3620х31",
                "koef": "2.744",
                "metrs": 1
            },
            {
                "size": "3620х32",
                "koef": "2.832",
                "metrs": 1
            },
            {
                "size": "3620х33",
                "koef": "2.919",
                "metrs": 1
            },
            {
                "size": "3620х34",
                "koef": "3.007",
                "metrs": 1
            },
            {
                "size": "3620х35",
                "koef": "3.094",
                "metrs": 1
            },
            {
                "size": "3620х36",
                "koef": "3.182",
                "metrs": 1
            },
            {
                "size": "3620х37",
                "koef": "3.269",
                "metrs": 1
            },
            {
                "size": "3620х38",
                "koef": "3.357",
                "metrs": 1
            },
            {
                "size": "3620х39",
                "koef": "3.444",
                "metrs": 1
            },
            {
                "size": "3620х40",
                "koef": "3.532",
                "metrs": 1
            },
            {
                "size": "3620х41",
                "koef": "3.619",
                "metrs": 1
            },
            {
                "size": "3620х42",
                "koef": "3.706",
                "metrs": 1
            },
            {
                "size": "3620х43",
                "koef": "3.793",
                "metrs": 1
            },
            {
                "size": "3620х44",
                "koef": "3.88",
                "metrs": 1
            },
            {
                "size": "3620х45",
                "koef": "3.967",
                "metrs": 1
            },
            {
                "size": "3620х46",
                "koef": "4.054",
                "metrs": 1
            },
            {
                "size": "3620х47",
                "koef": "4.141",
                "metrs": 1
            },
            {
                "size": "3620х48",
                "koef": "4.228",
                "metrs": 1
            },
            {
                "size": "3620х49",
                "koef": "4.315",
                "metrs": 1
            },
            {
                "size": "3620х50",
                "koef": "4.402",
                "metrs": 1
            },
            {
                "size": "3720х14",
                "koef": "1.28",
                "metrs": 1
            },
            {
                "size": "3720х15",
                "koef": "1.371",
                "metrs": 1
            },
            {
                "size": "3720х16",
                "koef": "1.462",
                "metrs": 1
            },
            {
                "size": "3720х17",
                "koef": "1.552",
                "metrs": 1
            },
            {
                "size": "3720х18",
                "koef": "1.643",
                "metrs": 1
            },
            {
                "size": "3720х19",
                "koef": "1.734",
                "metrs": 1
            },
            {
                "size": "3720х20",
                "koef": "1.825",
                "metrs": 1
            },
            {
                "size": "3720х21",
                "koef": "1.916",
                "metrs": 1
            },
            {
                "size": "3720х22",
                "koef": "2.006",
                "metrs": 1
            },
            {
                "size": "3720х23",
                "koef": "2.097",
                "metrs": 1
            },
            {
                "size": "3720х24",
                "koef": "2.188",
                "metrs": 1
            },
            {
                "size": "3720х25",
                "koef": "2.278",
                "metrs": 1
            },
            {
                "size": "3720х26",
                "koef": "2.369",
                "metrs": 1
            },
            {
                "size": "3720х27",
                "koef": "2.459",
                "metrs": 1
            },
            {
                "size": "3720х28",
                "koef": "2.549",
                "metrs": 1
            },
            {
                "size": "3720х29",
                "koef": "2.64",
                "metrs": 1
            },
            {
                "size": "3720х30",
                "koef": "2.73",
                "metrs": 1
            },
            {
                "size": "3720х31",
                "koef": "2.82",
                "metrs": 1
            },
            {
                "size": "3720х32",
                "koef": "2.91",
                "metrs": 1
            },
            {
                "size": "3720х33",
                "koef": "3.001",
                "metrs": 1
            },
            {
                "size": "3720х34",
                "koef": "3.091",
                "metrs": 1
            },
            {
                "size": "3720х35",
                "koef": "3.181",
                "metrs": 1
            },
            {
                "size": "3720х36",
                "koef": "3.271",
                "metrs": 1
            },
            {
                "size": "3720х37",
                "koef": "3.361",
                "metrs": 1
            },
            {
                "size": "3720х38",
                "koef": "3.451",
                "metrs": 1
            },
            {
                "size": "3720х39",
                "koef": "3.54",
                "metrs": 1
            },
            {
                "size": "3720х40",
                "koef": "3.63",
                "metrs": 1
            },
            {
                "size": "3720х41",
                "koef": "3.72",
                "metrs": 1
            },
            {
                "size": "3720х42",
                "koef": "3.81",
                "metrs": 1
            },
            {
                "size": "3720х43",
                "koef": "3.899",
                "metrs": 1
            },
            {
                "size": "3720х44",
                "koef": "3.989",
                "metrs": 1
            },
            {
                "size": "3720х45",
                "koef": "4.078",
                "metrs": 1
            },
            {
                "size": "3720х46",
                "koef": "4.168",
                "metrs": 1
            },
            {
                "size": "3720х47",
                "koef": "4.257",
                "metrs": 1
            },
            {
                "size": "3720х48",
                "koef": "4.347",
                "metrs": 1
            },
            {
                "size": "3720х49",
                "koef": "4.436",
                "metrs": 1
            },
            {
                "size": "3720х50",
                "koef": "4.525",
                "metrs": 1
            },
            {
                "size": "3820х14",
                "koef": "1.314",
                "metrs": 1
            },
            {
                "size": "3820х15",
                "koef": "1.408",
                "metrs": 1
            },
            {
                "size": "3820х16",
                "koef": "1.501",
                "metrs": 1
            },
            {
                "size": "3820х17",
                "koef": "1.594",
                "metrs": 1
            },
            {
                "size": "3820х18",
                "koef": "1.688",
                "metrs": 1
            },
            {
                "size": "3820х19",
                "koef": "1.781",
                "metrs": 1
            },
            {
                "size": "3820х20",
                "koef": "1.874",
                "metrs": 1
            },
            {
                "size": "3820х21",
                "koef": "1.967",
                "metrs": 1
            },
            {
                "size": "3820х22",
                "koef": "2.061",
                "metrs": 1
            },
            {
                "size": "3820х23",
                "koef": "2.154",
                "metrs": 1
            },
            {
                "size": "3820х24",
                "koef": "2.247",
                "metrs": 1
            },
            {
                "size": "3820х25",
                "koef": "2.34",
                "metrs": 1
            },
            {
                "size": "3820х26",
                "koef": "2.433",
                "metrs": 1
            },
            {
                "size": "3820х27",
                "koef": "2.526",
                "metrs": 1
            },
            {
                "size": "3820х28",
                "koef": "2.618",
                "metrs": 1
            },
            {
                "size": "3820х29",
                "koef": "2.711",
                "metrs": 1
            },
            {
                "size": "3820х30",
                "koef": "2.804",
                "metrs": 1
            },
            {
                "size": "3820х31",
                "koef": "2.897",
                "metrs": 1
            },
            {
                "size": "3820х32",
                "koef": "2.989",
                "metrs": 1
            },
            {
                "size": "3820х33",
                "koef": "3.082",
                "metrs": 1
            },
            {
                "size": "3820х34",
                "koef": "3.175",
                "metrs": 1
            },
            {
                "size": "3820х35",
                "koef": "3.267",
                "metrs": 1
            },
            {
                "size": "3820х36",
                "koef": "3.359",
                "metrs": 1
            },
            {
                "size": "3820х37",
                "koef": "3.452",
                "metrs": 1
            },
            {
                "size": "3820х38",
                "koef": "3.544",
                "metrs": 1
            },
            {
                "size": "3820х39",
                "koef": "3.637",
                "metrs": 1
            },
            {
                "size": "3820х40",
                "koef": "3.729",
                "metrs": 1
            },
            {
                "size": "3820х41",
                "koef": "3.821",
                "metrs": 1
            },
            {
                "size": "3820х42",
                "koef": "3.913",
                "metrs": 1
            },
            {
                "size": "3820х43",
                "koef": "4.005",
                "metrs": 1
            },
            {
                "size": "3820х44",
                "koef": "4.097",
                "metrs": 1
            },
            {
                "size": "3820х45",
                "koef": "4.189",
                "metrs": 1
            },
            {
                "size": "3820х46",
                "koef": "4.281",
                "metrs": 1
            },
            {
                "size": "3820х47",
                "koef": "4.373",
                "metrs": 1
            },
            {
                "size": "3820х48",
                "koef": "4.465",
                "metrs": 1
            },
            {
                "size": "3820х49",
                "koef": "4.557",
                "metrs": 1
            },
            {
                "size": "3820х50",
                "koef": "4.649",
                "metrs": 1
            },
            {
                "size": "3920х14",
                "koef": "1.349",
                "metrs": 1
            },
            {
                "size": "3920х15",
                "koef": "1.445",
                "metrs": 1
            },
            {
                "size": "3920х16",
                "koef": "1.54",
                "metrs": 1
            },
            {
                "size": "3920х17",
                "koef": "1.636",
                "metrs": 1
            },
            {
                "size": "3920х18",
                "koef": "1.732",
                "metrs": 1
            },
            {
                "size": "3920х19",
                "koef": "1.828",
                "metrs": 1
            },
            {
                "size": "3920х20",
                "koef": "1.924",
                "metrs": 1
            },
            {
                "size": "3920х21",
                "koef": "2.019",
                "metrs": 1
            },
            {
                "size": "3920х22",
                "koef": "2.115",
                "metrs": 1
            },
            {
                "size": "3920х23",
                "koef": "2.21",
                "metrs": 1
            },
            {
                "size": "3920х24",
                "koef": "2.306",
                "metrs": 1
            },
            {
                "size": "3920х25",
                "koef": "2.401",
                "metrs": 1
            },
            {
                "size": "3920х26",
                "koef": "2.497",
                "metrs": 1
            },
            {
                "size": "3920х27",
                "koef": "2.592",
                "metrs": 1
            },
            {
                "size": "3920х28",
                "koef": "2.688",
                "metrs": 1
            },
            {
                "size": "3920х29",
                "koef": "2.783",
                "metrs": 1
            },
            {
                "size": "3920х30",
                "koef": "2.878",
                "metrs": 1
            },
            {
                "size": "3920х31",
                "koef": "2.973",
                "metrs": 1
            },
            {
                "size": "3920х32",
                "koef": "3.068",
                "metrs": 1
            },
            {
                "size": "3920х33",
                "koef": "3.163",
                "metrs": 1
            },
            {
                "size": "3920х34",
                "koef": "3.258",
                "metrs": 1
            },
            {
                "size": "3920х35",
                "koef": "3.353",
                "metrs": 1
            },
            {
                "size": "3920х36",
                "koef": "3.448",
                "metrs": 1
            },
            {
                "size": "3920х37",
                "koef": "3.543",
                "metrs": 1
            },
            {
                "size": "3920х38",
                "koef": "3.638",
                "metrs": 1
            },
            {
                "size": "3920х39",
                "koef": "3.733",
                "metrs": 1
            },
            {
                "size": "3920х40",
                "koef": "3.827",
                "metrs": 1
            },
            {
                "size": "3920х41",
                "koef": "3.922",
                "metrs": 1
            },
            {
                "size": "3920х42",
                "koef": "4.017",
                "metrs": 1
            },
            {
                "size": "3920х43",
                "koef": "4.111",
                "metrs": 1
            },
            {
                "size": "3920х44",
                "koef": "4.206",
                "metrs": 1
            },
            {
                "size": "3920х45",
                "koef": "4.3",
                "metrs": 1
            },
            {
                "size": "3920х46",
                "koef": "4.395",
                "metrs": 1
            },
            {
                "size": "3920х47",
                "koef": "4.489",
                "metrs": 1
            },
            {
                "size": "3920х48",
                "koef": "4.583",
                "metrs": 1
            },
            {
                "size": "3920х49",
                "koef": "4.678",
                "metrs": 1
            },
            {
                "size": "3920х50",
                "koef": "4.772",
                "metrs": 1
            },
            {
                "size": "4020х14",
                "koef": "1.383",
                "metrs": 1
            },
            {
                "size": "4020х15",
                "koef": "1.482",
                "metrs": 1
            },
            {
                "size": "4020х16",
                "koef": "1.58",
                "metrs": 1
            },
            {
                "size": "4020х17",
                "koef": "1.678",
                "metrs": 1
            },
            {
                "size": "4020х18",
                "koef": "1.777",
                "metrs": 1
            },
            {
                "size": "4020х19",
                "koef": "1.875",
                "metrs": 1
            },
            {
                "size": "4020х20",
                "koef": "1.973",
                "metrs": 1
            },
            {
                "size": "4020х21",
                "koef": "2.071",
                "metrs": 1
            },
            {
                "size": "4020х22",
                "koef": "2.169",
                "metrs": 1
            },
            {
                "size": "4020х23",
                "koef": "2.267",
                "metrs": 1
            },
            {
                "size": "4020х24",
                "koef": "2.365",
                "metrs": 1
            },
            {
                "size": "4020х25",
                "koef": "2.463",
                "metrs": 1
            },
            {
                "size": "4020х26",
                "koef": "2.561",
                "metrs": 1
            },
            {
                "size": "4020х27",
                "koef": "2.659",
                "metrs": 1
            },
            {
                "size": "4020х28",
                "koef": "2.757",
                "metrs": 1
            },
            {
                "size": "4020х29",
                "koef": "2.854",
                "metrs": 1
            },
            {
                "size": "4020х30",
                "koef": "2.952",
                "metrs": 1
            },
            {
                "size": "4020х31",
                "koef": "3.05",
                "metrs": 1
            },
            {
                "size": "4020х32",
                "koef": "3.147",
                "metrs": 1
            },
            {
                "size": "4020х33",
                "koef": "3.245",
                "metrs": 1
            },
            {
                "size": "4020х34",
                "koef": "3.342",
                "metrs": 1
            },
            {
                "size": "4020х35",
                "koef": "3.44",
                "metrs": 1
            },
            {
                "size": "4020х36",
                "koef": "3.537",
                "metrs": 1
            },
            {
                "size": "4020х37",
                "koef": "3.634",
                "metrs": 1
            },
            {
                "size": "4020х38",
                "koef": "3.732",
                "metrs": 1
            },
            {
                "size": "4020х39",
                "koef": "3.829",
                "metrs": 1
            },
            {
                "size": "4020х40",
                "koef": "3.926",
                "metrs": 1
            },
            {
                "size": "4020х41",
                "koef": "4.023",
                "metrs": 1
            },
            {
                "size": "4020х42",
                "koef": "4.12",
                "metrs": 1
            },
            {
                "size": "4020х43",
                "koef": "4.217",
                "metrs": 1
            },
            {
                "size": "4020х44",
                "koef": "4.314",
                "metrs": 1
            },
            {
                "size": "4020х45",
                "koef": "4.411",
                "metrs": 1
            },
            {
                "size": "4020х46",
                "koef": "4.508",
                "metrs": 1
            },
            {
                "size": "4020х47",
                "koef": "4.605",
                "metrs": 1
            },
            {
                "size": "4020х48",
                "koef": "4.702",
                "metrs": 1
            },
            {
                "size": "4020х49",
                "koef": "4.799",
                "metrs": 1
            },
            {
                "size": "4020х50",
                "koef": "4.895",
                "metrs": 1
            },
            {
                "size": "4120х14",
                "koef": "1.418",
                "metrs": 1
            },
            {
                "size": "4120х15",
                "koef": "1.519",
                "metrs": 1
            },
            {
                "size": "4120х16",
                "koef": "1.619",
                "metrs": 1
            },
            {
                "size": "4120х17",
                "koef": "1.72",
                "metrs": 1
            },
            {
                "size": "4120х18",
                "koef": "1.821",
                "metrs": 1
            },
            {
                "size": "4120х19",
                "koef": "1.922",
                "metrs": 1
            },
            {
                "size": "4120х20",
                "koef": "2.022",
                "metrs": 1
            },
            {
                "size": "4120х21",
                "koef": "2.123",
                "metrs": 1
            },
            {
                "size": "4120х22",
                "koef": "2.223",
                "metrs": 1
            },
            {
                "size": "4120х23",
                "koef": "2.324",
                "metrs": 1
            },
            {
                "size": "4120х24",
                "koef": "2.424",
                "metrs": 1
            },
            {
                "size": "4120х25",
                "koef": "2.525",
                "metrs": 1
            },
            {
                "size": "4120х26",
                "koef": "2.625",
                "metrs": 1
            },
            {
                "size": "4120х27",
                "koef": "2.725",
                "metrs": 1
            },
            {
                "size": "4120х28",
                "koef": "2.826",
                "metrs": 1
            },
            {
                "size": "4120х29",
                "koef": "2.926",
                "metrs": 1
            },
            {
                "size": "4120х30",
                "koef": "3.026",
                "metrs": 1
            },
            {
                "size": "4120х31",
                "koef": "3.126",
                "metrs": 1
            },
            {
                "size": "4120х32",
                "koef": "3.226",
                "metrs": 1
            },
            {
                "size": "4120х33",
                "koef": "3.326",
                "metrs": 1
            },
            {
                "size": "4120х34",
                "koef": "3.426",
                "metrs": 1
            },
            {
                "size": "4120х35",
                "koef": "3.526",
                "metrs": 1
            },
            {
                "size": "4120х36",
                "koef": "3.626",
                "metrs": 1
            },
            {
                "size": "4120х37",
                "koef": "3.726",
                "metrs": 1
            },
            {
                "size": "4120х38",
                "koef": "3.825",
                "metrs": 1
            },
            {
                "size": "4120х39",
                "koef": "3.925",
                "metrs": 1
            },
            {
                "size": "4120х40",
                "koef": "4.025",
                "metrs": 1
            },
            {
                "size": "4120х41",
                "koef": "4.124",
                "metrs": 1
            },
            {
                "size": "4120х42",
                "koef": "4.224",
                "metrs": 1
            },
            {
                "size": "4120х43",
                "koef": "4.323",
                "metrs": 1
            },
            {
                "size": "4120х44",
                "koef": "4.423",
                "metrs": 1
            },
            {
                "size": "4120х45",
                "koef": "4.522",
                "metrs": 1
            },
            {
                "size": "4120х46",
                "koef": "4.622",
                "metrs": 1
            },
            {
                "size": "4120х47",
                "koef": "4.721",
                "metrs": 1
            },
            {
                "size": "4120х48",
                "koef": "4.82",
                "metrs": 1
            },
            {
                "size": "4120х49",
                "koef": "4.919",
                "metrs": 1
            },
            {
                "size": "4120х50",
                "koef": "5.019",
                "metrs": 1
            },
            {
                "size": "4220х14",
                "koef": "1.452",
                "metrs": 1
            },
            {
                "size": "4220х15",
                "koef": "1.556",
                "metrs": 1
            },
            {
                "size": "4220х16",
                "koef": "1.659",
                "metrs": 1
            },
            {
                "size": "4220х17",
                "koef": "1.762",
                "metrs": 1
            },
            {
                "size": "4220х18",
                "koef": "1.865",
                "metrs": 1
            },
            {
                "size": "4220х19",
                "koef": "1.968",
                "metrs": 1
            },
            {
                "size": "4220х20",
                "koef": "2.072",
                "metrs": 1
            },
            {
                "size": "4220х21",
                "koef": "2.175",
                "metrs": 1
            },
            {
                "size": "4220х22",
                "koef": "2.278",
                "metrs": 1
            },
            {
                "size": "4220х23",
                "koef": "2.381",
                "metrs": 1
            },
            {
                "size": "4220х24",
                "koef": "2.484",
                "metrs": 1
            },
            {
                "size": "4220х25",
                "koef": "2.586",
                "metrs": 1
            },
            {
                "size": "4220х26",
                "koef": "2.689",
                "metrs": 1
            },
            {
                "size": "4220х27",
                "koef": "2.792",
                "metrs": 1
            },
            {
                "size": "4220х28",
                "koef": "2.895",
                "metrs": 1
            },
            {
                "size": "4220х29",
                "koef": "2.997",
                "metrs": 1
            },
            {
                "size": "4220х30",
                "koef": "3.1",
                "metrs": 1
            },
            {
                "size": "4220х31",
                "koef": "3.203",
                "metrs": 1
            },
            {
                "size": "4220х32",
                "koef": "3.305",
                "metrs": 1
            },
            {
                "size": "4220х33",
                "koef": "3.408",
                "metrs": 1
            },
            {
                "size": "4220х34",
                "koef": "3.51",
                "metrs": 1
            },
            {
                "size": "4220х35",
                "koef": "3.612",
                "metrs": 1
            },
            {
                "size": "4220х36",
                "koef": "3.715",
                "metrs": 1
            },
            {
                "size": "4220х37",
                "koef": "3.817",
                "metrs": 1
            },
            {
                "size": "4220х38",
                "koef": "3.919",
                "metrs": 1
            },
            {
                "size": "4220х39",
                "koef": "4.021",
                "metrs": 1
            },
            {
                "size": "4220х40",
                "koef": "4.123",
                "metrs": 1
            },
            {
                "size": "4220х41",
                "koef": "4.225",
                "metrs": 1
            },
            {
                "size": "4220х42",
                "koef": "4.327",
                "metrs": 1
            },
            {
                "size": "4220х43",
                "koef": "4.429",
                "metrs": 1
            },
            {
                "size": "4220х44",
                "koef": "4.531",
                "metrs": 1
            },
            {
                "size": "4220х45",
                "koef": "4.633",
                "metrs": 1
            },
            {
                "size": "4220х46",
                "koef": "4.735",
                "metrs": 1
            },
            {
                "size": "4220х47",
                "koef": "4.837",
                "metrs": 1
            },
            {
                "size": "4220х48",
                "koef": "4.939",
                "metrs": 1
            },
            {
                "size": "4220х49",
                "koef": "5.04",
                "metrs": 1
            },
            {
                "size": "4220х50",
                "koef": "5.142",
                "metrs": 1
            },
            {
                "size": "4320х14",
                "koef": "1.487",
                "metrs": 1
            },
            {
                "size": "4320х15",
                "koef": "1.593",
                "metrs": 1
            },
            {
                "size": "4320х16",
                "koef": "1.698",
                "metrs": 1
            },
            {
                "size": "4320х17",
                "koef": "1.804",
                "metrs": 1
            },
            {
                "size": "4320х18",
                "koef": "1.91",
                "metrs": 1
            },
            {
                "size": "4320х19",
                "koef": "2.015",
                "metrs": 1
            },
            {
                "size": "4320х20",
                "koef": "2.121",
                "metrs": 1
            },
            {
                "size": "4320х21",
                "koef": "2.226",
                "metrs": 1
            },
            {
                "size": "4320х22",
                "koef": "2.332",
                "metrs": 1
            },
            {
                "size": "4320х23",
                "koef": "2.437",
                "metrs": 1
            },
            {
                "size": "4320х24",
                "koef": "2.543",
                "metrs": 1
            },
            {
                "size": "4320х25",
                "koef": "2.648",
                "metrs": 1
            },
            {
                "size": "4320х26",
                "koef": "2.753",
                "metrs": 1
            },
            {
                "size": "4320х27",
                "koef": "2.859",
                "metrs": 1
            },
            {
                "size": "4320х28",
                "koef": "2.964",
                "metrs": 1
            },
            {
                "size": "4320х29",
                "koef": "3.069",
                "metrs": 1
            },
            {
                "size": "4320х30",
                "koef": "3.174",
                "metrs": 1
            },
            {
                "size": "4320х31",
                "koef": "3.279",
                "metrs": 1
            },
            {
                "size": "4320х32",
                "koef": "3.384",
                "metrs": 1
            },
            {
                "size": "4320х33",
                "koef": "3.489",
                "metrs": 1
            },
            {
                "size": "4320х34",
                "koef": "3.594",
                "metrs": 1
            },
            {
                "size": "4320х35",
                "koef": "3.699",
                "metrs": 1
            },
            {
                "size": "4320х36",
                "koef": "3.803",
                "metrs": 1
            },
            {
                "size": "4320х37",
                "koef": "3.908",
                "metrs": 1
            },
            {
                "size": "4320х38",
                "koef": "4.013",
                "metrs": 1
            },
            {
                "size": "4320х39",
                "koef": "4.117",
                "metrs": 1
            },
            {
                "size": "4320х40",
                "koef": "4.222",
                "metrs": 1
            },
            {
                "size": "4320х41",
                "koef": "4.327",
                "metrs": 1
            },
            {
                "size": "4320х42",
                "koef": "4.431",
                "metrs": 1
            },
            {
                "size": "4320х43",
                "koef": "4.536",
                "metrs": 1
            },
            {
                "size": "4320х44",
                "koef": "4.64",
                "metrs": 1
            },
            {
                "size": "4320х45",
                "koef": "4.744",
                "metrs": 1
            },
            {
                "size": "4320х46",
                "koef": "4.849",
                "metrs": 1
            },
            {
                "size": "4320х47",
                "koef": "4.953",
                "metrs": 1
            },
            {
                "size": "4320х48",
                "koef": "5.057",
                "metrs": 1
            },
            {
                "size": "4320х49",
                "koef": "5.161",
                "metrs": 1
            },
            {
                "size": "4320х50",
                "koef": "5.265",
                "metrs": 1
            },
            {
                "size": "4420х14",
                "koef": "1.521",
                "metrs": 1
            },
            {
                "size": "4420х15",
                "koef": "1.63",
                "metrs": 1
            },
            {
                "size": "4420х16",
                "koef": "1.738",
                "metrs": 1
            },
            {
                "size": "4420х17",
                "koef": "1.846",
                "metrs": 1
            },
            {
                "size": "4420х18",
                "koef": "1.954",
                "metrs": 1
            },
            {
                "size": "4420х19",
                "koef": "2.062",
                "metrs": 1
            },
            {
                "size": "4420х20",
                "koef": "2.17",
                "metrs": 1
            },
            {
                "size": "4420х21",
                "koef": "2.278",
                "metrs": 1
            },
            {
                "size": "4420х22",
                "koef": "2.386",
                "metrs": 1
            },
            {
                "size": "4420х23",
                "koef": "2.494",
                "metrs": 1
            },
            {
                "size": "4420х24",
                "koef": "2.602",
                "metrs": 1
            },
            {
                "size": "4420х25",
                "koef": "2.71",
                "metrs": 1
            },
            {
                "size": "4420х26",
                "koef": "2.817",
                "metrs": 1
            },
            {
                "size": "4420х27",
                "koef": "2.925",
                "metrs": 1
            },
            {
                "size": "4420х28",
                "koef": "3.033",
                "metrs": 1
            },
            {
                "size": "4420х29",
                "koef": "3.14",
                "metrs": 1
            },
            {
                "size": "4420х30",
                "koef": "3.248",
                "metrs": 1
            },
            {
                "size": "4420х31",
                "koef": "3.355",
                "metrs": 1
            },
            {
                "size": "4420х32",
                "koef": "3.463",
                "metrs": 1
            },
            {
                "size": "4420х33",
                "koef": "3.57",
                "metrs": 1
            },
            {
                "size": "4420х34",
                "koef": "3.678",
                "metrs": 1
            },
            {
                "size": "4420х35",
                "koef": "3.785",
                "metrs": 1
            },
            {
                "size": "4420х36",
                "koef": "3.892",
                "metrs": 1
            },
            {
                "size": "4420х37",
                "koef": "3.999",
                "metrs": 1
            },
            {
                "size": "4420х38",
                "koef": "4.107",
                "metrs": 1
            },
            {
                "size": "4420х39",
                "koef": "4.214",
                "metrs": 1
            },
            {
                "size": "4420х40",
                "koef": "4.321",
                "metrs": 1
            },
            {
                "size": "4420х41",
                "koef": "4.428",
                "metrs": 1
            },
            {
                "size": "4420х42",
                "koef": "4.535",
                "metrs": 1
            },
            {
                "size": "4420х43",
                "koef": "4.642",
                "metrs": 1
            },
            {
                "size": "4420х44",
                "koef": "4.748",
                "metrs": 1
            },
            {
                "size": "4420х45",
                "koef": "4.855",
                "metrs": 1
            },
            {
                "size": "4420х46",
                "koef": "4.962",
                "metrs": 1
            },
            {
                "size": "4420х47",
                "koef": "5.069",
                "metrs": 1
            },
            {
                "size": "4420х48",
                "koef": "5.175",
                "metrs": 1
            },
            {
                "size": "4420х49",
                "koef": "5.282",
                "metrs": 1
            },
            {
                "size": "4420х50",
                "koef": "5.389",
                "metrs": 1
            },
            {
                "size": "4520х14",
                "koef": "1.556",
                "metrs": 1
            },
            {
                "size": "4520х15",
                "koef": "1.666",
                "metrs": 1
            },
            {
                "size": "4520х16",
                "koef": "1.777",
                "metrs": 1
            },
            {
                "size": "4520х17",
                "koef": "1.888",
                "metrs": 1
            },
            {
                "size": "4520х18",
                "koef": "1.998",
                "metrs": 1
            },
            {
                "size": "4520х19",
                "koef": "2.109",
                "metrs": 1
            },
            {
                "size": "4520х20",
                "koef": "2.22",
                "metrs": 1
            },
            {
                "size": "4520х21",
                "koef": "2.33",
                "metrs": 1
            },
            {
                "size": "4520х22",
                "koef": "2.44",
                "metrs": 1
            },
            {
                "size": "4520х23",
                "koef": "2.551",
                "metrs": 1
            },
            {
                "size": "4520х24",
                "koef": "2.661",
                "metrs": 1
            },
            {
                "size": "4520х25",
                "koef": "2.771",
                "metrs": 1
            },
            {
                "size": "4520х26",
                "koef": "2.882",
                "metrs": 1
            },
            {
                "size": "4520х27",
                "koef": "2.992",
                "metrs": 1
            },
            {
                "size": "4520х28",
                "koef": "3.102",
                "metrs": 1
            },
            {
                "size": "4520х29",
                "koef": "3.212",
                "metrs": 1
            },
            {
                "size": "4520х30",
                "koef": "3.322",
                "metrs": 1
            },
            {
                "size": "4520х31",
                "koef": "3.432",
                "metrs": 1
            },
            {
                "size": "4520х32",
                "koef": "3.542",
                "metrs": 1
            },
            {
                "size": "4520х33",
                "koef": "3.652",
                "metrs": 1
            },
            {
                "size": "4520х34",
                "koef": "3.761",
                "metrs": 1
            },
            {
                "size": "4520х35",
                "koef": "3.871",
                "metrs": 1
            },
            {
                "size": "4520х36",
                "koef": "3.981",
                "metrs": 1
            },
            {
                "size": "4520х37",
                "koef": "4.091",
                "metrs": 1
            },
            {
                "size": "4520х38",
                "koef": "4.2",
                "metrs": 1
            },
            {
                "size": "4520х39",
                "koef": "4.31",
                "metrs": 1
            },
            {
                "size": "4520х40",
                "koef": "4.419",
                "metrs": 1
            },
            {
                "size": "4520х41",
                "koef": "4.529",
                "metrs": 1
            },
            {
                "size": "4520х42",
                "koef": "4.638",
                "metrs": 1
            },
            {
                "size": "4520х43",
                "koef": "4.748",
                "metrs": 1
            },
            {
                "size": "4520х44",
                "koef": "4.857",
                "metrs": 1
            },
            {
                "size": "4520х45",
                "koef": "4.966",
                "metrs": 1
            },
            {
                "size": "4520х46",
                "koef": "5.075",
                "metrs": 1
            },
            {
                "size": "4520х47",
                "koef": "5.185",
                "metrs": 1
            },
            {
                "size": "4520х48",
                "koef": "5.294",
                "metrs": 1
            },
            {
                "size": "4520х49",
                "koef": "5.403",
                "metrs": 1
            },
            {
                "size": "4520х50",
                "koef": "5.512",
                "metrs": 1
            }
        ],
        "Труба электросварная оцинкованная": [
            {
                "size": "10х0,5",
                "koef": "0.000121",
                "metrs": 1
            },
            {
                "size": "10х0,6",
                "koef": "0.000143",
                "metrs": 1
            },
            {
                "size": "10х0,7",
                "koef": "0.000165",
                "metrs": 1
            },
            {
                "size": "10х0,8",
                "koef": "0.000187",
                "metrs": 1
            },
            {
                "size": "10х0,9",
                "koef": "0.000208",
                "metrs": 1
            },
            {
                "size": "10х1",
                "koef": "0.000229",
                "metrs": 1
            },
            {
                "size": "10х1,1",
                "koef": "0.000249",
                "metrs": 1
            },
            {
                "size": "10х1,2",
                "koef": "0.000268",
                "metrs": 1
            },
            {
                "size": "10х1,3",
                "koef": "0.000287",
                "metrs": 1
            },
            {
                "size": "10х1,5",
                "koef": "0.000324",
                "metrs": 1
            },
            {
                "size": "12х0,5",
                "koef": "0.000146",
                "metrs": 1
            },
            {
                "size": "12х0,6",
                "koef": "0.000174",
                "metrs": 1
            },
            {
                "size": "12х0,7",
                "koef": "0.000201",
                "metrs": 1
            },
            {
                "size": "12х0,8",
                "koef": "0.000228",
                "metrs": 1
            },
            {
                "size": "12х0,9",
                "koef": "0.000254",
                "metrs": 1
            },
            {
                "size": "12х1",
                "koef": "0.000279",
                "metrs": 1
            },
            {
                "size": "12х1,1",
                "koef": "0.000305",
                "metrs": 1
            },
            {
                "size": "12х1,2",
                "koef": "0.000329",
                "metrs": 1
            },
            {
                "size": "12х1,3",
                "koef": "0.000353",
                "metrs": 1
            },
            {
                "size": "12х1,5",
                "koef": "0.0004",
                "metrs": 1
            },
            {
                "size": "14х0,5",
                "koef": "0.000171",
                "metrs": 1
            },
            {
                "size": "14х0,6",
                "koef": "0.000204",
                "metrs": 1
            },
            {
                "size": "14х0,7",
                "koef": "0.000236",
                "metrs": 1
            },
            {
                "size": "14х0,8",
                "koef": "0.000268",
                "metrs": 1
            },
            {
                "size": "14х0,9",
                "koef": "0.000299",
                "metrs": 1
            },
            {
                "size": "14х1",
                "koef": "0.00033",
                "metrs": 1
            },
            {
                "size": "14х1,1",
                "koef": "0.00036",
                "metrs": 1
            },
            {
                "size": "14х1,2",
                "koef": "0.00039",
                "metrs": 1
            },
            {
                "size": "14х1,3",
                "koef": "0.000419",
                "metrs": 1
            },
            {
                "size": "14х1,5",
                "koef": "0.000476",
                "metrs": 1
            },
            {
                "size": "15х2,8",
                "koef": "0.000868",
                "metrs": 1
            },
            {
                "size": "16х0,5",
                "koef": "0.000197",
                "metrs": 1
            },
            {
                "size": "16х0,6",
                "koef": "0.000235",
                "metrs": 1
            },
            {
                "size": "16х0,7",
                "koef": "0.000272",
                "metrs": 1
            },
            {
                "size": "16х0,8",
                "koef": "0.000309",
                "metrs": 1
            },
            {
                "size": "16х0,9",
                "koef": "0.000345",
                "metrs": 1
            },
            {
                "size": "16х1",
                "koef": "0.000381",
                "metrs": 1
            },
            {
                "size": "16х1,1",
                "koef": "0.000416",
                "metrs": 1
            },
            {
                "size": "16х1,2",
                "koef": "0.000451",
                "metrs": 1
            },
            {
                "size": "16х1,3",
                "koef": "0.000485",
                "metrs": 1
            },
            {
                "size": "16х1,5",
                "koef": "0.000552",
                "metrs": 1
            },
            {
                "size": "18х0,5",
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": "18х0,6",
                "koef": "0.000265",
                "metrs": 1
            },
            {
                "size": "18х0,7",
                "koef": "0.000308",
                "metrs": 1
            },
            {
                "size": "18х0,8",
                "koef": "0.00035",
                "metrs": 1
            },
            {
                "size": "18х0,9",
                "koef": "0.000391",
                "metrs": 1
            },
            {
                "size": "18х1",
                "koef": "0.000432",
                "metrs": 1
            },
            {
                "size": "18х1,1",
                "koef": "0.000472",
                "metrs": 1
            },
            {
                "size": "18х1,2",
                "koef": "0.000512",
                "metrs": 1
            },
            {
                "size": "18х1,3",
                "koef": "0.000551",
                "metrs": 1
            },
            {
                "size": "18х1,5",
                "koef": "0.000629",
                "metrs": 1
            },
            {
                "size": "19х0,5",
                "koef": "0.000235",
                "metrs": 1
            },
            {
                "size": "19х0,6",
                "koef": "0.00028",
                "metrs": 1
            },
            {
                "size": "19х0,7",
                "koef": "0.000325",
                "metrs": 1
            },
            {
                "size": "19х0,8",
                "koef": "0.00037",
                "metrs": 1
            },
            {
                "size": "19х0,9",
                "koef": "0.000414",
                "metrs": 1
            },
            {
                "size": "19х1",
                "koef": "0.000457",
                "metrs": 1
            },
            {
                "size": "19х1,1",
                "koef": "0.0005",
                "metrs": 1
            },
            {
                "size": "19х1,2",
                "koef": "0.000543",
                "metrs": 1
            },
            {
                "size": "19х1,3",
                "koef": "0.000584",
                "metrs": 1
            },
            {
                "size": "19х1,5",
                "koef": "0.000667",
                "metrs": 1
            },
            {
                "size": "20х0,5",
                "koef": "0.000248",
                "metrs": 1
            },
            {
                "size": "20х0,6",
                "koef": "0.000296",
                "metrs": 1
            },
            {
                "size": "20х0,7",
                "koef": "0.000343",
                "metrs": 1
            },
            {
                "size": "20х0,8",
                "koef": "0.00039",
                "metrs": 1
            },
            {
                "size": "20х0,9",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": "20х1",
                "koef": "0.000483",
                "metrs": 1
            },
            {
                "size": "20х1,1",
                "koef": "0.000528",
                "metrs": 1
            },
            {
                "size": "20х1,2",
                "koef": "0.000573",
                "metrs": 1
            },
            {
                "size": "20х1,3",
                "koef": "0.000618",
                "metrs": 1
            },
            {
                "size": "20х1,5",
                "koef": "0.000705",
                "metrs": 1
            },
            {
                "size": "20х2",
                "koef": "0.000914",
                "metrs": 1
            },
            {
                "size": "20х2,5",
                "koef": "0.001111",
                "metrs": 1
            },
            {
                "size": "20х2,8",
                "koef": "0.001223",
                "metrs": 1
            },
            {
                "size": "22х0,5",
                "koef": "0.000273",
                "metrs": 1
            },
            {
                "size": "22х0,6",
                "koef": "0.000326",
                "metrs": 1
            },
            {
                "size": "22х0,7",
                "koef": "0.000379",
                "metrs": 1
            },
            {
                "size": "22х0,8",
                "koef": "0.000431",
                "metrs": 1
            },
            {
                "size": "22х0,9",
                "koef": "0.000482",
                "metrs": 1
            },
            {
                "size": "22х1",
                "koef": "0.000533",
                "metrs": 1
            },
            {
                "size": "22х1,1",
                "koef": "0.000584",
                "metrs": 1
            },
            {
                "size": "22х1,2",
                "koef": "0.000634",
                "metrs": 1
            },
            {
                "size": "22х1,3",
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": "22х1,5",
                "koef": "0.000781",
                "metrs": 1
            },
            {
                "size": "22х2",
                "koef": "0.001016",
                "metrs": 1
            },
            {
                "size": "24х0,6",
                "koef": "0.000357",
                "metrs": 1
            },
            {
                "size": "24х0,7",
                "koef": "0.000414",
                "metrs": 1
            },
            {
                "size": "24х0,8",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": "24х1",
                "koef": "0.000584",
                "metrs": 1
            },
            {
                "size": "24х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "24х1,5",
                "koef": "0.000857",
                "metrs": 1
            },
            {
                "size": "24х2",
                "koef": "0.001118",
                "metrs": 1
            },
            {
                "size": "25х0,5",
                "koef": "0.000311",
                "metrs": 1
            },
            {
                "size": "25х0,6",
                "koef": "0.000372",
                "metrs": 1
            },
            {
                "size": "25х0,7",
                "koef": "0.000432",
                "metrs": 1
            },
            {
                "size": "25х0,8",
                "koef": "0.000492",
                "metrs": 1
            },
            {
                "size": "25х0,9",
                "koef": "0.000551",
                "metrs": 1
            },
            {
                "size": "25х1",
                "koef": "0.00061",
                "metrs": 1
            },
            {
                "size": "25х1,1",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": "25х1,2",
                "koef": "0.000725",
                "metrs": 1
            },
            {
                "size": "25х1,3",
                "koef": "0.000783",
                "metrs": 1
            },
            {
                "size": "25х1,5",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "25х1,8",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": "25х2",
                "koef": "0.001168",
                "metrs": 1
            },
            {
                "size": "25х2,8",
                "koef": "0.001579",
                "metrs": 1
            },
            {
                "size": "25х3,2",
                "koef": "0.001772",
                "metrs": 1
            },
            {
                "size": "26х0,6",
                "koef": "0.000387",
                "metrs": 1
            },
            {
                "size": "26х0,7",
                "koef": "0.00045",
                "metrs": 1
            },
            {
                "size": "26х0,8",
                "koef": "0.000512",
                "metrs": 1
            },
            {
                "size": "26х1",
                "koef": "0.000635",
                "metrs": 1
            },
            {
                "size": "26х1,2",
                "koef": "0.000756",
                "metrs": 1
            },
            {
                "size": "26х1,5",
                "koef": "0.000933",
                "metrs": 1
            },
            {
                "size": "26х2",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "28х0,6",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": "28х0,7",
                "koef": "0.000485",
                "metrs": 1
            },
            {
                "size": "28х0,8",
                "koef": "0.000553",
                "metrs": 1
            },
            {
                "size": "28х0,9",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "28х1",
                "koef": "0.000686",
                "metrs": 1
            },
            {
                "size": "28х1,1",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": "28х1,2",
                "koef": "0.000817",
                "metrs": 1
            },
            {
                "size": "28х1,3",
                "koef": "0.000882",
                "metrs": 1
            },
            {
                "size": "28х1,5",
                "koef": "0.00101",
                "metrs": 1
            },
            {
                "size": "28х1,8",
                "koef": "0.001198",
                "metrs": 1
            },
            {
                "size": "28х2",
                "koef": "0.001321",
                "metrs": 1
            },
            {
                "size": "30х0,7",
                "koef": "0.000521",
                "metrs": 1
            },
            {
                "size": "30х0,8",
                "koef": "0.000593",
                "metrs": 1
            },
            {
                "size": "30х0,9",
                "koef": "0.000665",
                "metrs": 1
            },
            {
                "size": "30х1",
                "koef": "0.000737",
                "metrs": 1
            },
            {
                "size": "30х1,1",
                "koef": "0.000808",
                "metrs": 1
            },
            {
                "size": "30х1,2",
                "koef": "0.000878",
                "metrs": 1
            },
            {
                "size": "30х1,3",
                "koef": "0.000948",
                "metrs": 1
            },
            {
                "size": "30х1,5",
                "koef": "0.001086",
                "metrs": 1
            },
            {
                "size": "30х1,8",
                "koef": "0.001289",
                "metrs": 1
            },
            {
                "size": "30х2",
                "koef": "0.001422",
                "metrs": 1
            },
            {
                "size": "31х0,7",
                "koef": "0.000539",
                "metrs": 1
            },
            {
                "size": "31х0,8",
                "koef": "0.000614",
                "metrs": 1
            },
            {
                "size": "31х1",
                "koef": "0.000762",
                "metrs": 1
            },
            {
                "size": "31х1,2",
                "koef": "0.000908",
                "metrs": 1
            },
            {
                "size": "31х1,5",
                "koef": "0.001124",
                "metrs": 1
            },
            {
                "size": "32х0,5",
                "koef": "0.0004",
                "metrs": 1
            },
            {
                "size": "32х0,6",
                "koef": "0.000479",
                "metrs": 1
            },
            {
                "size": "32х0,7",
                "koef": "0.000557",
                "metrs": 1
            },
            {
                "size": "32х0,8",
                "koef": "0.000634",
                "metrs": 1
            },
            {
                "size": "32х0,9",
                "koef": "0.000711",
                "metrs": 1
            },
            {
                "size": "32х1",
                "koef": "0.000787",
                "metrs": 1
            },
            {
                "size": "32х1,1",
                "koef": "0.000863",
                "metrs": 1
            },
            {
                "size": "32х1,2",
                "koef": "0.000939",
                "metrs": 1
            },
            {
                "size": "32х1,3",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": "32х1,5",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": "32х1,8",
                "koef": "0.001381",
                "metrs": 1
            },
            {
                "size": "32х2",
                "koef": "0.001524",
                "metrs": 1
            },
            {
                "size": "32х2,8",
                "koef": "0.002077",
                "metrs": 1
            },
            {
                "size": "32х3,2",
                "koef": "0.002341",
                "metrs": 1
            },
            {
                "size": "35х0,7",
                "koef": "0.00061",
                "metrs": 1
            },
            {
                "size": "35х0,8",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "35х0,9",
                "koef": "0.00078",
                "metrs": 1
            },
            {
                "size": "35х1",
                "koef": "0.000864",
                "metrs": 1
            },
            {
                "size": "35х1,1",
                "koef": "0.000947",
                "metrs": 1
            },
            {
                "size": "35х1,2",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "35х1,3",
                "koef": "0.001113",
                "metrs": 1
            },
            {
                "size": "35х1,5",
                "koef": "0.001276",
                "metrs": 1
            },
            {
                "size": "35х1,8",
                "koef": "0.001518",
                "metrs": 1
            },
            {
                "size": "35х2",
                "koef": "0.001676",
                "metrs": 1
            },
            {
                "size": "36х1",
                "koef": "0.000889",
                "metrs": 1
            },
            {
                "size": "36х1,1",
                "koef": "0.000975",
                "metrs": 1
            },
            {
                "size": "36х1,2",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": "36х1,3",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "36х1,5",
                "koef": "0.001315",
                "metrs": 1
            },
            {
                "size": "36х1,8",
                "koef": "0.001564",
                "metrs": 1
            },
            {
                "size": "36х2",
                "koef": "0.001727",
                "metrs": 1
            },
            {
                "size": "38х0,6",
                "koef": "0.00057",
                "metrs": 1
            },
            {
                "size": "38х0,7",
                "koef": "0.000663",
                "metrs": 1
            },
            {
                "size": "38х0,8",
                "koef": "0.000756",
                "metrs": 1
            },
            {
                "size": "38х0,9",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": "38х1",
                "koef": "0.00094",
                "metrs": 1
            },
            {
                "size": "38х1,1",
                "koef": "0.001031",
                "metrs": 1
            },
            {
                "size": "38х1,2",
                "koef": "0.001122",
                "metrs": 1
            },
            {
                "size": "38х1,3",
                "koef": "0.001212",
                "metrs": 1
            },
            {
                "size": "38х1,4",
                "koef": "0.001302",
                "metrs": 1
            },
            {
                "size": "38х1,5",
                "koef": "0.001391",
                "metrs": 1
            },
            {
                "size": "38х1,8",
                "koef": "0.001655",
                "metrs": 1
            },
            {
                "size": "38х2",
                "koef": "0.001829",
                "metrs": 1
            },
            {
                "size": "40х0,8",
                "koef": "0.000797",
                "metrs": 1
            },
            {
                "size": "40х0,9",
                "koef": "0.000894",
                "metrs": 1
            },
            {
                "size": "40х1",
                "koef": "0.000991",
                "metrs": 1
            },
            {
                "size": "40х1,1",
                "koef": "0.001087",
                "metrs": 1
            },
            {
                "size": "40х1,2",
                "koef": "0.001183",
                "metrs": 1
            },
            {
                "size": "40х1,3",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "40х1,5",
                "koef": "0.001467",
                "metrs": 1
            },
            {
                "size": "40х1,8",
                "koef": "0.001747",
                "metrs": 1
            },
            {
                "size": "40х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "40х3",
                "koef": "0.00282",
                "metrs": 1
            },
            {
                "size": "40х3,5",
                "koef": "0.003245",
                "metrs": 1
            },
            {
                "size": "42х0,7",
                "koef": "0.000734",
                "metrs": 1
            },
            {
                "size": "42х0,8",
                "koef": "0.000837",
                "metrs": 1
            },
            {
                "size": "42х0,9",
                "koef": "0.00094",
                "metrs": 1
            },
            {
                "size": "42х1",
                "koef": "0.001041",
                "metrs": 1
            },
            {
                "size": "42х1,1",
                "koef": "0.001143",
                "metrs": 1
            },
            {
                "size": "42х1,2",
                "koef": "0.001244",
                "metrs": 1
            },
            {
                "size": "42х1,3",
                "koef": "0.001344",
                "metrs": 1
            },
            {
                "size": "42х1,5",
                "koef": "0.001543",
                "metrs": 1
            },
            {
                "size": "42х1,8",
                "koef": "0.001838",
                "metrs": 1
            },
            {
                "size": "42х2",
                "koef": "0.002032",
                "metrs": 1
            },
            {
                "size": "42х2,5",
                "koef": "0.002508",
                "metrs": 1
            },
            {
                "size": "45х1,5",
                "koef": "0.001657",
                "metrs": 1
            },
            {
                "size": "48х1",
                "koef": "0.001194",
                "metrs": 1
            },
            {
                "size": "48х1,2",
                "koef": "0.001427",
                "metrs": 1
            },
            {
                "size": "48х1,5",
                "koef": "0.001772",
                "metrs": 1
            },
            {
                "size": "48х1,8",
                "koef": "0.002112",
                "metrs": 1
            },
            {
                "size": "48х2",
                "koef": "0.002337",
                "metrs": 1
            },
            {
                "size": "48х3",
                "koef": "0.003429",
                "metrs": 1
            },
            {
                "size": "48х3,2",
                "koef": "0.003642",
                "metrs": 1
            },
            {
                "size": "48х3,5",
                "koef": "0.003956",
                "metrs": 1
            },
            {
                "size": "48х4",
                "koef": "0.004471",
                "metrs": 1
            },
            {
                "size": "50х1,5",
                "koef": "0.001848",
                "metrs": 1
            },
            {
                "size": "50х1,8",
                "koef": "0.002204",
                "metrs": 1
            },
            {
                "size": "50х2",
                "koef": "0.002439",
                "metrs": 1
            },
            {
                "size": "50х3",
                "koef": "0.003582",
                "metrs": 1
            },
            {
                "size": "50х3,5",
                "koef": "0.004134",
                "metrs": 1
            },
            {
                "size": "51х1",
                "koef": "0.00127",
                "metrs": 1
            },
            {
                "size": "51х1,2",
                "koef": "0.001518",
                "metrs": 1
            },
            {
                "size": "51х1,5",
                "koef": "0.001886",
                "metrs": 1
            },
            {
                "size": "51х2",
                "koef": "0.002489",
                "metrs": 1
            },
            {
                "size": "57х1",
                "koef": "0.001422",
                "metrs": 1
            },
            {
                "size": "57х1,2",
                "koef": "0.001701",
                "metrs": 1
            },
            {
                "size": "57х1,5",
                "koef": "0.002115",
                "metrs": 1
            },
            {
                "size": "57х1,8",
                "koef": "0.002524",
                "metrs": 1
            },
            {
                "size": "57х2",
                "koef": "0.002794",
                "metrs": 1
            },
            {
                "size": "57х2,5",
                "koef": "0.003461",
                "metrs": 1
            },
            {
                "size": "57х3",
                "koef": "0.004115",
                "metrs": 1
            },
            {
                "size": "57х3,5",
                "koef": "0.004756",
                "metrs": 1
            },
            {
                "size": "57х4",
                "koef": "0.005385",
                "metrs": 1
            },
            {
                "size": "60х1,5",
                "koef": "0.002229",
                "metrs": 1
            },
            {
                "size": "60х2",
                "koef": "0.002947",
                "metrs": 1
            },
            {
                "size": "60х3",
                "koef": "0.004344",
                "metrs": 1
            },
            {
                "size": "62х1,5",
                "koef": "0.002305",
                "metrs": 1
            },
            {
                "size": "62х1,8",
                "koef": "0.002752",
                "metrs": 1
            },
            {
                "size": "62х2",
                "koef": "0.003048",
                "metrs": 1
            },
            {
                "size": "62х3",
                "koef": "0.004496",
                "metrs": 1
            },
            {
                "size": "63,5х1,2",
                "koef": "0.001899",
                "metrs": 1
            },
            {
                "size": "63,5х1,5",
                "koef": "0.002362",
                "metrs": 1
            },
            {
                "size": "63,5х2",
                "koef": "0.003124",
                "metrs": 1
            },
            {
                "size": "65х3,5",
                "koef": "0.005468",
                "metrs": 1
            },
            {
                "size": "65х4",
                "koef": "0.006198",
                "metrs": 1
            },
            {
                "size": "74х1,8",
                "koef": "0.003301",
                "metrs": 1
            },
            {
                "size": "74х2",
                "koef": "0.003658",
                "metrs": 1
            },
            {
                "size": "76х3",
                "koef": "0.005563",
                "metrs": 1
            },
            {
                "size": "76х3,5",
                "koef": "0.006446",
                "metrs": 1
            },
            {
                "size": "76х4",
                "koef": "0.007316",
                "metrs": 1
            },
            {
                "size": "80х4",
                "koef": "0.007722",
                "metrs": 1
            },
            {
                "size": "89х3",
                "koef": "0.006554",
                "metrs": 1
            },
            {
                "size": "89х3,5",
                "koef": "0.007601",
                "metrs": 1
            },
            {
                "size": "89х4",
                "koef": "0.008636",
                "metrs": 1
            },
            {
                "size": "100х4",
                "koef": "0.009754",
                "metrs": 1
            },
            {
                "size": "100х4,5",
                "koef": "0.01092",
                "metrs": 1
            },
            {
                "size": "102х3",
                "koef": "0.007544",
                "metrs": 1
            },
            {
                "size": "102х3,5",
                "koef": "0.008757",
                "metrs": 1
            },
            {
                "size": "102х4",
                "koef": "0.009957",
                "metrs": 1
            },
            {
                "size": "108х3",
                "koef": "0.008001",
                "metrs": 1
            },
            {
                "size": "108х3,5",
                "koef": "0.009291",
                "metrs": 1
            },
            {
                "size": "108х4",
                "koef": "0.01057",
                "metrs": 1
            },
            {
                "size": "108х4,5",
                "koef": "0.01183",
                "metrs": 1
            },
            {
                "size": "108х5",
                "koef": "0.01308",
                "metrs": 1
            },
            {
                "size": "114х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "114х4,5",
                "koef": "0.01252",
                "metrs": 1
            },
            {
                "size": "127х4",
                "koef": "0.0125",
                "metrs": 1
            },
            {
                "size": "127х4,5",
                "koef": "0.014",
                "metrs": 1
            },
            {
                "size": "133х4",
                "koef": "0.01311",
                "metrs": 1
            },
            {
                "size": "133х4,5",
                "koef": "0.01469",
                "metrs": 1
            },
            {
                "size": "159х4",
                "koef": "0.01575",
                "metrs": 1
            },
            {
                "size": "159х4,5",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": "159х5",
                "koef": "0.01956",
                "metrs": 1
            },
            {
                "size": "159х6",
                "koef": "0.02332",
                "metrs": 1
            },
            {
                "size": "159х8",
                "koef": "0.03068",
                "metrs": 1
            },
            {
                "size": "219х4",
                "koef": "0.02185",
                "metrs": 1
            },
            {
                "size": "219х4,5",
                "koef": "0.02452",
                "metrs": 1
            },
            {
                "size": "219х5",
                "koef": "0.02718",
                "metrs": 1
            },
            {
                "size": "219х6",
                "koef": "0.03246",
                "metrs": 1
            },
            {
                "size": "219х8",
                "koef": "0.04288",
                "metrs": 1
            },
            {
                "size": "273х5",
                "koef": "0.03404",
                "metrs": 1
            },
            {
                "size": "273х6",
                "koef": "0.04069",
                "metrs": 1
            },
            {
                "size": "273х7",
                "koef": "0.0473",
                "metrs": 1
            },
            {
                "size": "273х8",
                "koef": "0.05385",
                "metrs": 1
            },
            {
                "size": "325х5",
                "koef": "0.04064",
                "metrs": 1
            },
            {
                "size": "325х6",
                "koef": "0.04862",
                "metrs": 1
            },
            {
                "size": "325х7",
                "koef": "0.05654",
                "metrs": 1
            },
            {
                "size": "325х8",
                "koef": "0.06442",
                "metrs": 1
            },
            {
                "size": "377х6",
                "koef": "0.05654",
                "metrs": 1
            },
            {
                "size": "377х7",
                "koef": "0.06579",
                "metrs": 1
            },
            {
                "size": "377х8",
                "koef": "0.07498",
                "metrs": 1
            },
            {
                "size": "377х10",
                "koef": "0.09322",
                "metrs": 1
            },
            {
                "size": "426х6",
                "koef": "0.06401",
                "metrs": 1
            },
            {
                "size": "426х7",
                "koef": "0.0745",
                "metrs": 1
            },
            {
                "size": "426х8",
                "koef": "0.08494",
                "metrs": 1
            },
            {
                "size": "426х10",
                "koef": "0.1057",
                "metrs": 1
            }
        ],
        "Уголок": [
            {
                "size": "25х25х3",
                "koef": "0.00112",
                "metrs": 1
            },
            {
                "size": "25х25х4",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "30х30х4",
                "koef": "0.00178",
                "metrs": 1
            },
            {
                "size": "32х32х3",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "32х32х4",
                "koef": "0.00191",
                "metrs": 1
            },
            {
                "size": "35х35х3",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "35х35х4",
                "koef": "0.0021",
                "metrs": 1
            },
            {
                "size": "40х40х3",
                "koef": "0.00185",
                "metrs": 1
            },
            {
                "size": "40х40х4",
                "koef": "0.00242",
                "metrs": 1
            },
            {
                "size": "40х40х5",
                "koef": "0.00298",
                "metrs": 1
            },
            {
                "size": "45х45х4",
                "koef": "0.00273",
                "metrs": 1
            },
            {
                "size": "45х45х5",
                "koef": "0.00337",
                "metrs": 1
            },
            {
                "size": "50х50х4",
                "koef": "0.00305",
                "metrs": 1
            },
            {
                "size": "50х50х5",
                "koef": "0.00377",
                "metrs": 1
            },
            {
                "size": "50х50х6",
                "koef": "0.00447",
                "metrs": 1
            },
            {
                "size": "63х40х5",
                "koef": "0.00391",
                "metrs": 1
            },
            {
                "size": "63х40х6",
                "koef": "0.00463",
                "metrs": 1
            },
            {
                "size": "63х63х4",
                "koef": "0.0039",
                "metrs": 1
            },
            {
                "size": "63х63х5",
                "koef": "0.00481",
                "metrs": 1
            },
            {
                "size": "63х63х6",
                "koef": "0.00572",
                "metrs": 1
            },
            {
                "size": "70х50х5",
                "koef": "0.004514",
                "metrs": 1
            },
            {
                "size": "70х70х5",
                "koef": "0.00538",
                "metrs": 1
            },
            {
                "size": "70х70х6",
                "koef": "0.00639",
                "metrs": 1
            },
            {
                "size": "70х70х7",
                "koef": "0.00739",
                "metrs": 1
            },
            {
                "size": "75х50х5",
                "koef": "0.00479",
                "metrs": 1
            },
            {
                "size": "75х50х6",
                "koef": "0.00569",
                "metrs": 1
            },
            {
                "size": "75х75х5",
                "koef": "0.0058",
                "metrs": 1
            },
            {
                "size": "75х75х6",
                "koef": "0.00689",
                "metrs": 1
            },
            {
                "size": "75х75х7",
                "koef": "0.00796",
                "metrs": 1
            },
            {
                "size": "75х75х8",
                "koef": "0.00902",
                "metrs": 1
            },
            {
                "size": "75х75х9",
                "koef": "0.01007",
                "metrs": 1
            },
            {
                "size": "80х80х6",
                "koef": "0.00736",
                "metrs": 1
            },
            {
                "size": "80х80х7",
                "koef": "0.00851",
                "metrs": 1
            },
            {
                "size": "80х80х8",
                "koef": "0.00965",
                "metrs": 1
            },
            {
                "size": "90х90х6",
                "koef": "0.00833",
                "metrs": 1
            },
            {
                "size": "90х90х7",
                "koef": "0.00964",
                "metrs": 1
            },
            {
                "size": "90х90х8",
                "koef": "0.01093",
                "metrs": 1
            },
            {
                "size": "90х90х9",
                "koef": "0.0122",
                "metrs": 1
            },
            {
                "size": "100х63х6",
                "koef": "0.00753",
                "metrs": 1
            },
            {
                "size": "100х63х8",
                "koef": "0.00987",
                "metrs": 1
            },
            {
                "size": "100х100х6,5",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "100х100х7",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "100х100х8",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": "100х100х9",
                "koef": "0.01349",
                "metrs": 1
            },
            {
                "size": "100х100х10",
                "koef": "0.0151",
                "metrs": 1
            },
            {
                "size": "100х100х12",
                "koef": "0.0179",
                "metrs": 1
            },
            {
                "size": "110х110х7",
                "koef": "0.01189",
                "metrs": 1
            },
            {
                "size": "110х110х8",
                "koef": "0.0135",
                "metrs": 1
            },
            {
                "size": "125х80х8",
                "koef": "0.01258",
                "metrs": 1
            },
            {
                "size": "125х80х10",
                "koef": "0.01547",
                "metrs": 1
            },
            {
                "size": "125х125х8",
                "koef": "0.01546",
                "metrs": 1
            },
            {
                "size": "125х125х9",
                "koef": "0.0173",
                "metrs": 1
            },
            {
                "size": "125х125х10",
                "koef": "0.0191",
                "metrs": 1
            },
            {
                "size": "125х125х12",
                "koef": "0.02268",
                "metrs": 1
            },
            {
                "size": "140х90х8",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "140х90х10",
                "koef": "0.01746",
                "metrs": 1
            },
            {
                "size": "140х140х9",
                "koef": "0.01941",
                "metrs": 1
            },
            {
                "size": "140х140х10",
                "koef": "0.02145",
                "metrs": 1
            },
            {
                "size": "140х140х12",
                "koef": "0.0255",
                "metrs": 1
            },
            {
                "size": "160х100х10",
                "koef": "0.01985",
                "metrs": 1
            },
            {
                "size": "160х100х12",
                "koef": "0.02358",
                "metrs": 1
            },
            {
                "size": "160х100х14",
                "koef": "0.02726",
                "metrs": 1
            },
            {
                "size": "160х160х10",
                "koef": "0.02467",
                "metrs": 1
            },
            {
                "size": "160х160х11",
                "koef": "0.02702",
                "metrs": 1
            },
            {
                "size": "160х160х12",
                "koef": "0.02935",
                "metrs": 1
            },
            {
                "size": "160х160х14",
                "koef": "0.0342",
                "metrs": 1
            },
            {
                "size": "160х160х16",
                "koef": "0.03852",
                "metrs": 1
            },
            {
                "size": "160х160х18",
                "koef": "0.04301",
                "metrs": 1
            },
            {
                "size": "180х180х11",
                "koef": "0.03047",
                "metrs": 1
            },
            {
                "size": "180х180х12",
                "koef": "0.03312",
                "metrs": 1
            },
            {
                "size": "200х125х11",
                "koef": "0.02737",
                "metrs": 1
            },
            {
                "size": "200х125х12",
                "koef": "0.02974",
                "metrs": 1
            },
            {
                "size": "200х125х14",
                "koef": "0.03443",
                "metrs": 1
            },
            {
                "size": "200х125х16",
                "koef": "0.03907",
                "metrs": 1
            },
            {
                "size": "200х200х12",
                "koef": "0.03697",
                "metrs": 1
            },
            {
                "size": "200х200х14",
                "koef": "0.0428",
                "metrs": 1
            },
            {
                "size": "200х200х16",
                "koef": "0.04865",
                "metrs": 1
            },
            {
                "size": "200х200х20",
                "koef": "0.06008",
                "metrs": 1
            },
            {
                "size": "200х200х25",
                "koef": "0.07402",
                "metrs": 1
            },
            {
                "size": "200х200х30",
                "koef": "0.08756",
                "metrs": 1
            }
        ],
        "Уголок б/у": [
            {
                "size": " 25х25х4",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": " 63х63х6",
                "koef": "0.00572",
                "metrs": 1
            },
            {
                "size": " 63х63х10",
                "koef": "0.009106",
                "metrs": 1
            },
            {
                "size": " 75х75х8",
                "koef": "0.00902",
                "metrs": 1
            },
            {
                "size": " 90х90х9",
                "koef": "0.0122",
                "metrs": 1
            },
            {
                "size": " 100х100х8",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": " 100х100х10",
                "koef": "0.0151",
                "metrs": 1
            },
            {
                "size": " 110х110х8",
                "koef": "0.0135",
                "metrs": 1
            },
            {
                "size": " 125х80х8",
                "koef": "0.01258",
                "metrs": 1
            },
            {
                "size": " 125х125х8",
                "koef": "0.01546",
                "metrs": 1
            },
            {
                "size": " 140х90х8",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 140х140х9",
                "koef": "0.01941",
                "metrs": 1
            },
            {
                "size": " 180х180х12",
                "koef": "0.03312",
                "metrs": 1
            }
        ],
        "Уголок гнутый": [
            {
                "size": "15х15х0,5",
                "koef": "0.000116",
                "metrs": 1
            },
            {
                "size": "15х15х0,55",
                "koef": "0.000127",
                "metrs": 1
            },
            {
                "size": "15х15х0,6",
                "koef": "0.000138",
                "metrs": 1
            },
            {
                "size": "15х15х0,7",
                "koef": "0.000161",
                "metrs": 1
            },
            {
                "size": "15х15х0,8",
                "koef": "0.000183",
                "metrs": 1
            },
            {
                "size": "15х15х0,9",
                "koef": "0.000206",
                "metrs": 1
            },
            {
                "size": "15х15х1",
                "koef": "0.000228",
                "metrs": 1
            },
            {
                "size": "15х15х1,2",
                "koef": "0.000271",
                "metrs": 1
            },
            {
                "size": "15х15х1,5",
                "koef": "0.000336",
                "metrs": 1
            },
            {
                "size": "15х15х1,8",
                "koef": "0.000398",
                "metrs": 1
            },
            {
                "size": "15х15х2",
                "koef": "0.00044",
                "metrs": 1
            },
            {
                "size": "15х15х2,5",
                "koef": "0.00054",
                "metrs": 1
            },
            {
                "size": "15х15х3",
                "koef": "0.000636",
                "metrs": 1
            },
            {
                "size": "15х15х3,5",
                "koef": "0.000728",
                "metrs": 1
            },
            {
                "size": "15х15х4",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "15х30х1",
                "koef": "0.000345",
                "metrs": 1
            },
            {
                "size": "15х30х1,2",
                "koef": "0.000413",
                "metrs": 1
            },
            {
                "size": "15х30х1,5",
                "koef": "0.000512",
                "metrs": 1
            },
            {
                "size": "15х30х1,8",
                "koef": "0.00061",
                "metrs": 1
            },
            {
                "size": "15х30х2",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": "15х30х2,5",
                "koef": "0.000834",
                "metrs": 1
            },
            {
                "size": "15х30х3",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "15х30х3,5",
                "koef": "0.00114",
                "metrs": 1
            },
            {
                "size": "15х35х1",
                "koef": "0.000385",
                "metrs": 1
            },
            {
                "size": "15х35х1,2",
                "koef": "0.00046",
                "metrs": 1
            },
            {
                "size": "15х35х1,5",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": "15х35х1,8",
                "koef": "0.000681",
                "metrs": 1
            },
            {
                "size": "15х35х2",
                "koef": "0.000754",
                "metrs": 1
            },
            {
                "size": "15х35х2,5",
                "koef": "0.000932",
                "metrs": 1
            },
            {
                "size": "15х35х3",
                "koef": "0.001107",
                "metrs": 1
            },
            {
                "size": "15х35х3,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "15х40х1",
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": "15х40х1,2",
                "koef": "0.000507",
                "metrs": 1
            },
            {
                "size": "15х40х1,5",
                "koef": "0.00063",
                "metrs": 1
            },
            {
                "size": "15х40х1,8",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": "15х40х2",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": "15х40х2,5",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "15х40х3",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "15х40х3,5",
                "koef": "0.001415",
                "metrs": 1
            },
            {
                "size": "15х45х1",
                "koef": "0.000463",
                "metrs": 1
            },
            {
                "size": "15х45х1,2",
                "koef": "0.000554",
                "metrs": 1
            },
            {
                "size": "15х45х1,5",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": "15х45х1,8",
                "koef": "0.000822",
                "metrs": 1
            },
            {
                "size": "15х45х2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "15х45х2,5",
                "koef": "0.001128",
                "metrs": 1
            },
            {
                "size": "15х45х3",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": "15х45х3,5",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": "15х50х1",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": "15х50х1,2",
                "koef": "0.000601",
                "metrs": 1
            },
            {
                "size": "15х50х1,5",
                "koef": "0.000748",
                "metrs": 1
            },
            {
                "size": "15х50х1,8",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "15х50х2",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "15х50х2,5",
                "koef": "0.001227",
                "metrs": 1
            },
            {
                "size": "15х50х3",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "15х50х3,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "15х55х1",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "15х55х1,2",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "15х55х1,5",
                "koef": "0.000807",
                "metrs": 1
            },
            {
                "size": "15х55х1,8",
                "koef": "0.000964",
                "metrs": 1
            },
            {
                "size": "15х55х2",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": "15х55х2,5",
                "koef": "0.001325",
                "metrs": 1
            },
            {
                "size": "15х55х3",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "15х55х3,5",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": "15х60х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "15х60х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "15х60х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "15х60х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "15х60х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "15х60х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "15х60х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "15х60х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "15х65х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "15х65х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "15х65х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "15х65х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "15х65х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "15х65х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "15х65х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "15х65х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "20х20х0,5",
                "koef": "0.000155",
                "metrs": 1
            },
            {
                "size": "20х20х0,55",
                "koef": "0.00017",
                "metrs": 1
            },
            {
                "size": "20х20х0,6",
                "koef": "0.000186",
                "metrs": 1
            },
            {
                "size": "20х20х0,7",
                "koef": "0.000216",
                "metrs": 1
            },
            {
                "size": "20х20х0,8",
                "koef": "0.000246",
                "metrs": 1
            },
            {
                "size": "20х20х0,9",
                "koef": "0.000276",
                "metrs": 1
            },
            {
                "size": "20х20х1",
                "koef": "0.000306",
                "metrs": 1
            },
            {
                "size": "20х20х1,2",
                "koef": "0.000365",
                "metrs": 1
            },
            {
                "size": "20х20х1,5",
                "koef": "0.000453",
                "metrs": 1
            },
            {
                "size": "20х20х1,8",
                "koef": "0.00054",
                "metrs": 1
            },
            {
                "size": "20х20х2",
                "koef": "0.000597",
                "metrs": 1
            },
            {
                "size": "20х20х2,5",
                "koef": "0.000736",
                "metrs": 1
            },
            {
                "size": "20х20х3",
                "koef": "0.000871",
                "metrs": 1
            },
            {
                "size": "20х20х3,5",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": "20х20х4",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": "20х30х1",
                "koef": "0.000385",
                "metrs": 1
            },
            {
                "size": "20х30х1,2",
                "koef": "0.00046",
                "metrs": 1
            },
            {
                "size": "20х30х1,5",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": "20х30х1,8",
                "koef": "0.000681",
                "metrs": 1
            },
            {
                "size": "20х30х2",
                "koef": "0.000754",
                "metrs": 1
            },
            {
                "size": "20х30х2,5",
                "koef": "0.000932",
                "metrs": 1
            },
            {
                "size": "20х30х3",
                "koef": "0.001107",
                "metrs": 1
            },
            {
                "size": "20х30х3,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "20х35х1",
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": "20х35х1,2",
                "koef": "0.000507",
                "metrs": 1
            },
            {
                "size": "20х35х1,5",
                "koef": "0.00063",
                "metrs": 1
            },
            {
                "size": "20х35х1,8",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": "20х35х2",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": "20х35х2,5",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "20х35х3",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "20х35х3,5",
                "koef": "0.001415",
                "metrs": 1
            },
            {
                "size": "20х40х1",
                "koef": "0.000463",
                "metrs": 1
            },
            {
                "size": "20х40х1,2",
                "koef": "0.000554",
                "metrs": 1
            },
            {
                "size": "20х40х1,5",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": "20х40х1,8",
                "koef": "0.000822",
                "metrs": 1
            },
            {
                "size": "20х40х2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "20х40х2,5",
                "koef": "0.001128",
                "metrs": 1
            },
            {
                "size": "20х40х3",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": "20х40х3,5",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": "20х45х1",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": "20х45х1,2",
                "koef": "0.000601",
                "metrs": 1
            },
            {
                "size": "20х45х1,5",
                "koef": "0.000748",
                "metrs": 1
            },
            {
                "size": "20х45х1,8",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "20х45х2",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "20х45х2,5",
                "koef": "0.001227",
                "metrs": 1
            },
            {
                "size": "20х45х3",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "20х45х3,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "20х50х1",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "20х50х1,2",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "20х50х1,5",
                "koef": "0.000807",
                "metrs": 1
            },
            {
                "size": "20х50х1,8",
                "koef": "0.000964",
                "metrs": 1
            },
            {
                "size": "20х50х2",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": "20х50х2,5",
                "koef": "0.001325",
                "metrs": 1
            },
            {
                "size": "20х50х3",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "20х50х3,5",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": "20х55х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "20х55х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "20х55х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "20х55х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "20х55х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "20х55х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "20х55х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "20х55х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "20х60х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "20х60х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "20х60х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "20х60х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "20х60х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "20х60х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "20х60х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "20х60х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "20х65х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "20х65х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "20х65х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "20х65х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "20х65х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "20х65х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "20х65х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "20х65х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "25х16х3",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "25х20х2",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": "25х25х0,5",
                "koef": "0.000194",
                "metrs": 1
            },
            {
                "size": "25х25х0,55",
                "koef": "0.000214",
                "metrs": 1
            },
            {
                "size": "25х25х0,6",
                "koef": "0.000233",
                "metrs": 1
            },
            {
                "size": "25х25х0,7",
                "koef": "0.000271",
                "metrs": 1
            },
            {
                "size": "25х25х0,8",
                "koef": "0.000309",
                "metrs": 1
            },
            {
                "size": "25х25х0,9",
                "koef": "0.000347",
                "metrs": 1
            },
            {
                "size": "25х25х1",
                "koef": "0.000385",
                "metrs": 1
            },
            {
                "size": "25х25х1,2",
                "koef": "0.00046",
                "metrs": 1
            },
            {
                "size": "25х25х1,5",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": "25х25х1,8",
                "koef": "0.000681",
                "metrs": 1
            },
            {
                "size": "25х25х2",
                "koef": "0.000754",
                "metrs": 1
            },
            {
                "size": "25х25х2,5",
                "koef": "0.000932",
                "metrs": 1
            },
            {
                "size": "25х25х3",
                "koef": "0.001107",
                "metrs": 1
            },
            {
                "size": "25х25х3,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "25х25х4",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": "25х30х1",
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": "25х30х1,2",
                "koef": "0.000507",
                "metrs": 1
            },
            {
                "size": "25х30х1,5",
                "koef": "0.00063",
                "metrs": 1
            },
            {
                "size": "25х30х1,8",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": "25х30х2",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": "25х30х2,5",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "25х30х3",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "25х30х3,5",
                "koef": "0.001415",
                "metrs": 1
            },
            {
                "size": "25х35х1",
                "koef": "0.000463",
                "metrs": 1
            },
            {
                "size": "25х35х1,2",
                "koef": "0.000554",
                "metrs": 1
            },
            {
                "size": "25х35х1,5",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": "25х35х1,8",
                "koef": "0.000822",
                "metrs": 1
            },
            {
                "size": "25х35х2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "25х35х2,5",
                "koef": "0.001128",
                "metrs": 1
            },
            {
                "size": "25х35х3",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": "25х35х3,5",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": "25х40х1",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": "25х40х1,2",
                "koef": "0.000601",
                "metrs": 1
            },
            {
                "size": "25х40х1,5",
                "koef": "0.000748",
                "metrs": 1
            },
            {
                "size": "25х40х1,8",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "25х40х2",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "25х40х2,5",
                "koef": "0.001227",
                "metrs": 1
            },
            {
                "size": "25х40х3",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "25х40х3,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "25х45х1",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "25х45х1,2",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "25х45х1,5",
                "koef": "0.000807",
                "metrs": 1
            },
            {
                "size": "25х45х1,8",
                "koef": "0.000964",
                "metrs": 1
            },
            {
                "size": "25х45х2",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": "25х45х2,5",
                "koef": "0.001325",
                "metrs": 1
            },
            {
                "size": "25х45х3",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "25х45х3,5",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": "25х50х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "25х50х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "25х50х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "25х50х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "25х50х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "25х50х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "25х50х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "25х50х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "25х55х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "25х55х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "25х55х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "25х55х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "25х55х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "25х55х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "25х55х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "25х55х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "25х60х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "25х60х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "25х60х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "25х60х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "25х60х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "25х60х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "25х60х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "25х60х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "25х65х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "25х65х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "25х65х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "25х65х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "25х65х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "25х65х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "25х65х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "25х65х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "28х28х1,5",
                "koef": "0.000642",
                "metrs": 1
            },
            {
                "size": "28х28х2",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": "28х28х2,5",
                "koef": "0.00105",
                "metrs": 1
            },
            {
                "size": "28х28х3",
                "koef": "0.001248",
                "metrs": 1
            },
            {
                "size": "30х15х0,5",
                "koef": "0.000175",
                "metrs": 1
            },
            {
                "size": "30х15х0,55",
                "koef": "0.000192",
                "metrs": 1
            },
            {
                "size": "30х15х0,6",
                "koef": "0.000209",
                "metrs": 1
            },
            {
                "size": "30х15х0,7",
                "koef": "0.000243",
                "metrs": 1
            },
            {
                "size": "30х15х0,8",
                "koef": "0.000278",
                "metrs": 1
            },
            {
                "size": "30х15х1",
                "koef": "0.000345",
                "metrs": 1
            },
            {
                "size": "30х15х1,2",
                "koef": "0.000413",
                "metrs": 1
            },
            {
                "size": "30х15х1,5",
                "koef": "0.000512",
                "metrs": 1
            },
            {
                "size": "30х15х1,8",
                "koef": "0.00061",
                "metrs": 1
            },
            {
                "size": "30х15х2",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": "30х15х2,5",
                "koef": "0.000834",
                "metrs": 1
            },
            {
                "size": "30х15х3",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "30х15х3,5",
                "koef": "0.00114",
                "metrs": 1
            },
            {
                "size": "30х15х4",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": "30х20х0,5",
                "koef": "0.000194",
                "metrs": 1
            },
            {
                "size": "30х20х0,55",
                "koef": "0.000214",
                "metrs": 1
            },
            {
                "size": "30х20х0,6",
                "koef": "0.000233",
                "metrs": 1
            },
            {
                "size": "30х20х0,7",
                "koef": "0.000271",
                "metrs": 1
            },
            {
                "size": "30х20х0,8",
                "koef": "0.000309",
                "metrs": 1
            },
            {
                "size": "30х20х1",
                "koef": "0.000385",
                "metrs": 1
            },
            {
                "size": "30х20х1,2",
                "koef": "0.00046",
                "metrs": 1
            },
            {
                "size": "30х20х1,5",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": "30х20х1,8",
                "koef": "0.000681",
                "metrs": 1
            },
            {
                "size": "30х20х2",
                "koef": "0.000754",
                "metrs": 1
            },
            {
                "size": "30х20х2,5",
                "koef": "0.000932",
                "metrs": 1
            },
            {
                "size": "30х20х3",
                "koef": "0.001107",
                "metrs": 1
            },
            {
                "size": "30х20х3,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "30х20х4",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": "30х25х0,5",
                "koef": "0.000214",
                "metrs": 1
            },
            {
                "size": "30х25х0,55",
                "koef": "0.000235",
                "metrs": 1
            },
            {
                "size": "30х25х0,6",
                "koef": "0.000256",
                "metrs": 1
            },
            {
                "size": "30х25х0,7",
                "koef": "0.000298",
                "metrs": 1
            },
            {
                "size": "30х25х0,8",
                "koef": "0.00034",
                "metrs": 1
            },
            {
                "size": "30х25х1",
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": "30х25х1,2",
                "koef": "0.000507",
                "metrs": 1
            },
            {
                "size": "30х25х1,5",
                "koef": "0.00063",
                "metrs": 1
            },
            {
                "size": "30х25х1,8",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": "30х25х2",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": "30х25х2,5",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "30х25х3",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "30х25х3,5",
                "koef": "0.001415",
                "metrs": 1
            },
            {
                "size": "30х25х4",
                "koef": "0.001601",
                "metrs": 1
            },
            {
                "size": "30х30х0,5",
                "koef": "0.000234",
                "metrs": 1
            },
            {
                "size": "30х30х0,55",
                "koef": "0.000257",
                "metrs": 1
            },
            {
                "size": "30х30х0,6",
                "koef": "0.00028",
                "metrs": 1
            },
            {
                "size": "30х30х0,7",
                "koef": "0.000326",
                "metrs": 1
            },
            {
                "size": "30х30х0,8",
                "koef": "0.000372",
                "metrs": 1
            },
            {
                "size": "30х30х0,9",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": "30х30х1",
                "koef": "0.000463",
                "metrs": 1
            },
            {
                "size": "30х30х1,2",
                "koef": "0.000554",
                "metrs": 1
            },
            {
                "size": "30х30х1,5",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": "30х30х1,8",
                "koef": "0.000822",
                "metrs": 1
            },
            {
                "size": "30х30х2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "30х30х2,5",
                "koef": "0.001128",
                "metrs": 1
            },
            {
                "size": "30х30х3",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": "30х30х3,5",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": "30х30х4",
                "koef": "0.001758",
                "metrs": 1
            },
            {
                "size": "30х35х1",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": "30х35х1,2",
                "koef": "0.000601",
                "metrs": 1
            },
            {
                "size": "30х35х1,5",
                "koef": "0.000748",
                "metrs": 1
            },
            {
                "size": "30х35х1,8",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "30х35х2",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "30х35х2,5",
                "koef": "0.001227",
                "metrs": 1
            },
            {
                "size": "30х35х3",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "30х35х3,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "30х40х1",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "30х40х1,2",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "30х40х1,5",
                "koef": "0.000807",
                "metrs": 1
            },
            {
                "size": "30х40х1,8",
                "koef": "0.000964",
                "metrs": 1
            },
            {
                "size": "30х40х2",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": "30х40х2,5",
                "koef": "0.001325",
                "metrs": 1
            },
            {
                "size": "30х40х3",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "30х40х3,5",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": "30х45х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "30х45х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "30х45х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "30х45х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "30х45х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "30х45х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "30х45х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "30х45х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "30х50х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "30х50х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "30х50х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "30х50х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "30х50х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "30х50х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "30х50х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "30х50х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "30х55х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "30х55х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "30х55х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "30х55х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "30х55х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "30х55х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "30х55х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "30х55х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "30х60х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "30х60х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "30х60х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "30х60х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "30х60х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "30х60х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "30х60х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "30х60х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "30х65х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "30х65х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "30х65х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "30х65х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "30х65х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "30х65х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "30х65х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "30х65х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "30х70х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "30х70х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "30х70х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "30х70х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "30х70х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "30х70х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "30х70х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "30х70х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "32х15х0,5",
                "koef": "0.000183",
                "metrs": 1
            },
            {
                "size": "32х15х0,55",
                "koef": "0.000201",
                "metrs": 1
            },
            {
                "size": "32х15х0,6",
                "koef": "0.000219",
                "metrs": 1
            },
            {
                "size": "32х15х0,8",
                "koef": "0.00029",
                "metrs": 1
            },
            {
                "size": "32х15х0,9",
                "koef": "0.000326",
                "metrs": 1
            },
            {
                "size": "32х15х1",
                "koef": "0.000361",
                "metrs": 1
            },
            {
                "size": "32х15х1,2",
                "koef": "0.000431",
                "metrs": 1
            },
            {
                "size": "32х15х1,5",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": "32х15х2",
                "koef": "0.000706",
                "metrs": 1
            },
            {
                "size": "32х15х2,5",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": "32х15х3",
                "koef": "0.001036",
                "metrs": 1
            },
            {
                "size": "32х20х0,5",
                "koef": "0.000202",
                "metrs": 1
            },
            {
                "size": "32х20х0,55",
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": "32х20х0,6",
                "koef": "0.000242",
                "metrs": 1
            },
            {
                "size": "32х20х0,8",
                "koef": "0.000322",
                "metrs": 1
            },
            {
                "size": "32х20х0,9",
                "koef": "0.000361",
                "metrs": 1
            },
            {
                "size": "32х20х1",
                "koef": "0.0004",
                "metrs": 1
            },
            {
                "size": "32х20х1,2",
                "koef": "0.000479",
                "metrs": 1
            },
            {
                "size": "32х20х1,5",
                "koef": "0.000595",
                "metrs": 1
            },
            {
                "size": "32х20х2",
                "koef": "0.000785",
                "metrs": 1
            },
            {
                "size": "32х20х2,5",
                "koef": "0.000971",
                "metrs": 1
            },
            {
                "size": "32х20х3",
                "koef": "0.001154",
                "metrs": 1
            },
            {
                "size": "32х20х4",
                "koef": "0.001507",
                "metrs": 1
            },
            {
                "size": "32х25х0,5",
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": "32х25х0,55",
                "koef": "0.000244",
                "metrs": 1
            },
            {
                "size": "32х25х0,6",
                "koef": "0.000266",
                "metrs": 1
            },
            {
                "size": "32х25х0,8",
                "koef": "0.000353",
                "metrs": 1
            },
            {
                "size": "32х25х0,9",
                "koef": "0.000396",
                "metrs": 1
            },
            {
                "size": "32х25х1",
                "koef": "0.00044",
                "metrs": 1
            },
            {
                "size": "32х25х1,2",
                "koef": "0.000526",
                "metrs": 1
            },
            {
                "size": "32х25х1,5",
                "koef": "0.000654",
                "metrs": 1
            },
            {
                "size": "32х25х2",
                "koef": "0.00084",
                "metrs": 1
            },
            {
                "size": "32х25х2,5",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "32х25х3",
                "koef": "0.001272",
                "metrs": 1
            },
            {
                "size": "32х25х4",
                "koef": "0.001664",
                "metrs": 1
            },
            {
                "size": "32х30х2",
                "koef": "0.000942",
                "metrs": 1
            },
            {
                "size": "32х30х3",
                "koef": "0.001389",
                "metrs": 1
            },
            {
                "size": "32х30х4",
                "koef": "0.001821",
                "metrs": 1
            },
            {
                "size": "32х32х0,5",
                "koef": "0.000249",
                "metrs": 1
            },
            {
                "size": "32х32х0,55",
                "koef": "0.000274",
                "metrs": 1
            },
            {
                "size": "32х32х0,6",
                "koef": "0.000299",
                "metrs": 1
            },
            {
                "size": "32х32х0,7",
                "koef": "0.000348",
                "metrs": 1
            },
            {
                "size": "32х32х0,8",
                "koef": "0.000397",
                "metrs": 1
            },
            {
                "size": "32х32х0,9",
                "koef": "0.000446",
                "metrs": 1
            },
            {
                "size": "32х32х1",
                "koef": "0.000495",
                "metrs": 1
            },
            {
                "size": "32х32х1,2",
                "koef": "0.000592",
                "metrs": 1
            },
            {
                "size": "32х32х1,5",
                "koef": "0.000736",
                "metrs": 1
            },
            {
                "size": "32х32х2",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "32х32х2,5",
                "koef": "0.001207",
                "metrs": 1
            },
            {
                "size": "32х32х3",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": "32х32х4",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": "35х15х0,7",
                "koef": "0.000271",
                "metrs": 1
            },
            {
                "size": "35х15х1",
                "koef": "0.000385",
                "metrs": 1
            },
            {
                "size": "35х15х1,2",
                "koef": "0.00046",
                "metrs": 1
            },
            {
                "size": "35х15х1,5",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": "35х15х1,8",
                "koef": "0.000681",
                "metrs": 1
            },
            {
                "size": "35х15х2",
                "koef": "0.000754",
                "metrs": 1
            },
            {
                "size": "35х15х2,5",
                "koef": "0.000932",
                "metrs": 1
            },
            {
                "size": "35х15х3",
                "koef": "0.001107",
                "metrs": 1
            },
            {
                "size": "35х15х3,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "35х15х4",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": "35х20х0,7",
                "koef": "0.000298",
                "metrs": 1
            },
            {
                "size": "35х20х1",
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": "35х20х1,2",
                "koef": "0.000507",
                "metrs": 1
            },
            {
                "size": "35х20х1,5",
                "koef": "0.00063",
                "metrs": 1
            },
            {
                "size": "35х20х1,8",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": "35х20х2",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": "35х20х2,5",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "35х20х3",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "35х20х3,5",
                "koef": "0.001415",
                "metrs": 1
            },
            {
                "size": "35х20х4",
                "koef": "0.001601",
                "metrs": 1
            },
            {
                "size": "35х25х0,7",
                "koef": "0.000326",
                "metrs": 1
            },
            {
                "size": "35х25х1",
                "koef": "0.000463",
                "metrs": 1
            },
            {
                "size": "35х25х1,2",
                "koef": "0.000554",
                "metrs": 1
            },
            {
                "size": "35х25х1,5",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": "35х25х1,8",
                "koef": "0.000822",
                "metrs": 1
            },
            {
                "size": "35х25х2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "35х25х2,5",
                "koef": "0.001128",
                "metrs": 1
            },
            {
                "size": "35х25х3",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": "35х25х3,5",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": "35х25х4",
                "koef": "0.001758",
                "metrs": 1
            },
            {
                "size": "35х28х0,9",
                "koef": "0.000439",
                "metrs": 1
            },
            {
                "size": "35х30х1",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": "35х30х1,2",
                "koef": "0.000601",
                "metrs": 1
            },
            {
                "size": "35х30х1,5",
                "koef": "0.000748",
                "metrs": 1
            },
            {
                "size": "35х30х1,8",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "35х30х2",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "35х30х2,5",
                "koef": "0.001227",
                "metrs": 1
            },
            {
                "size": "35х30х3",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "35х30х3,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "35х30х4",
                "koef": "0.001915",
                "metrs": 1
            },
            {
                "size": "35х35х0,5",
                "koef": "0.000273",
                "metrs": 1
            },
            {
                "size": "35х35х0,55",
                "koef": "0.0003",
                "metrs": 1
            },
            {
                "size": "35х35х0,6",
                "koef": "0.000327",
                "metrs": 1
            },
            {
                "size": "35х35х0,7",
                "koef": "0.000381",
                "metrs": 1
            },
            {
                "size": "35х35х0,8",
                "koef": "0.000435",
                "metrs": 1
            },
            {
                "size": "35х35х0,9",
                "koef": "0.000488",
                "metrs": 1
            },
            {
                "size": "35х35х1",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "35х35х1,2",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "35х35х1,5",
                "koef": "0.000807",
                "metrs": 1
            },
            {
                "size": "35х35х1,8",
                "koef": "0.000964",
                "metrs": 1
            },
            {
                "size": "35х35х2",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": "35х35х2,5",
                "koef": "0.001325",
                "metrs": 1
            },
            {
                "size": "35х35х3",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "35х35х3,5",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": "35х35х4",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": "35х35х5",
                "koef": "0.002551",
                "metrs": 1
            },
            {
                "size": "35х40х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "35х40х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "35х40х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "35х40х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "35х40х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "35х40х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "35х40х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "35х40х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "35х45х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "35х45х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "35х45х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "35х45х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "35х45х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "35х45х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "35х45х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "35х45х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "35х50х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "35х50х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "35х50х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "35х50х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "35х50х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "35х50х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "35х50х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "35х50х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "35х55х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "35х55х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "35х55х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "35х55х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "35х55х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "35х55х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "35х55х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "35х55х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "35х60х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "35х60х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "35х60х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "35х60х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "35х60х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "35х60х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "35х60х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "35х60х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "35х65х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "35х65х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "35х65х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "35х65х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "35х65х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "35х65х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "35х65х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "35х65х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "35х70х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "35х70х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "35х70х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "35х70х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "35х70х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "35х70х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "35х70х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "35х70х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "36х20х2",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": "36х20х3",
                "koef": "0.001248",
                "metrs": 1
            },
            {
                "size": "36х25х2",
                "koef": "0.000926",
                "metrs": 1
            },
            {
                "size": "36х25х3",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": "36х30х2",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": "36х30х3",
                "koef": "0.001484",
                "metrs": 1
            },
            {
                "size": "36х30х4",
                "koef": "0.001947",
                "metrs": 1
            },
            {
                "size": "36х36х3",
                "koef": "0.00157",
                "metrs": 1
            },
            {
                "size": "36х36х4",
                "koef": "0.002135",
                "metrs": 1
            },
            {
                "size": "36х36х5",
                "koef": "0.00263",
                "metrs": 1
            },
            {
                "size": "40х15х0,5",
                "koef": "0.000214",
                "metrs": 1
            },
            {
                "size": "40х15х0,55",
                "koef": "0.000235",
                "metrs": 1
            },
            {
                "size": "40х15х0,6",
                "koef": "0.000256",
                "metrs": 1
            },
            {
                "size": "40х15х0,7",
                "koef": "0.000298",
                "metrs": 1
            },
            {
                "size": "40х15х0,8",
                "koef": "0.00034",
                "metrs": 1
            },
            {
                "size": "40х15х0,9",
                "koef": "0.000382",
                "metrs": 1
            },
            {
                "size": "40х15х1",
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": "40х15х1,2",
                "koef": "0.000507",
                "metrs": 1
            },
            {
                "size": "40х15х1,5",
                "koef": "0.00063",
                "metrs": 1
            },
            {
                "size": "40х15х1,8",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": "40х15х2",
                "koef": "0.000832",
                "metrs": 1
            },
            {
                "size": "40х15х2,5",
                "koef": "0.00103",
                "metrs": 1
            },
            {
                "size": "40х15х3",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "40х15х3,5",
                "koef": "0.001415",
                "metrs": 1
            },
            {
                "size": "40х20х0,5",
                "koef": "0.000234",
                "metrs": 1
            },
            {
                "size": "40х20х0,55",
                "koef": "0.000257",
                "metrs": 1
            },
            {
                "size": "40х20х0,6",
                "koef": "0.00028",
                "metrs": 1
            },
            {
                "size": "40х20х0,7",
                "koef": "0.000326",
                "metrs": 1
            },
            {
                "size": "40х20х0,8",
                "koef": "0.000372",
                "metrs": 1
            },
            {
                "size": "40х20х0,9",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": "40х20х1",
                "koef": "0.000463",
                "metrs": 1
            },
            {
                "size": "40х20х1,2",
                "koef": "0.000554",
                "metrs": 1
            },
            {
                "size": "40х20х1,5",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": "40х20х1,8",
                "koef": "0.000822",
                "metrs": 1
            },
            {
                "size": "40х20х2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "40х20х2,5",
                "koef": "0.001128",
                "metrs": 1
            },
            {
                "size": "40х20х3",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": "40х20х3,5",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": "40х25х0,5",
                "koef": "0.000253",
                "metrs": 1
            },
            {
                "size": "40х25х0,55",
                "koef": "0.000278",
                "metrs": 1
            },
            {
                "size": "40х25х0,6",
                "koef": "0.000303",
                "metrs": 1
            },
            {
                "size": "40х25х0,7",
                "koef": "0.000353",
                "metrs": 1
            },
            {
                "size": "40х25х0,8",
                "koef": "0.000403",
                "metrs": 1
            },
            {
                "size": "40х25х0,9",
                "koef": "0.000453",
                "metrs": 1
            },
            {
                "size": "40х25х1",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": "40х25х1,2",
                "koef": "0.000601",
                "metrs": 1
            },
            {
                "size": "40х25х1,5",
                "koef": "0.000748",
                "metrs": 1
            },
            {
                "size": "40х25х1,8",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "40х25х2",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "40х25х2,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "40х25х3",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "40х25х3,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "40х25х4",
                "koef": "0.001915",
                "metrs": 1
            },
            {
                "size": "40х25х5",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": "40х30х0,5",
                "koef": "0.000273",
                "metrs": 1
            },
            {
                "size": "40х30х0,55",
                "koef": "0.0003",
                "metrs": 1
            },
            {
                "size": "40х30х0,6",
                "koef": "0.000327",
                "metrs": 1
            },
            {
                "size": "40х30х0,7",
                "koef": "0.000381",
                "metrs": 1
            },
            {
                "size": "40х30х0,8",
                "koef": "0.000435",
                "metrs": 1
            },
            {
                "size": "40х30х0,9",
                "koef": "0.000488",
                "metrs": 1
            },
            {
                "size": "40х30х1",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "40х30х1,2",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "40х30х1,5",
                "koef": "0.000807",
                "metrs": 1
            },
            {
                "size": "40х30х1,8",
                "koef": "0.000964",
                "metrs": 1
            },
            {
                "size": "40х30х2",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": "40х30х2,5",
                "koef": "0.001325",
                "metrs": 1
            },
            {
                "size": "40х30х3",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "40х30х3,5",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": "40х30х4",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": "40х30х5",
                "koef": "0.002551",
                "metrs": 1
            },
            {
                "size": "40х35х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "40х35х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "40х35х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "40х35х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "40х35х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "40х35х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "40х35х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "40х35х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "40х35х4",
                "koef": "0.002229",
                "metrs": 1
            },
            {
                "size": "40х35х5",
                "koef": "0.002747",
                "metrs": 1
            },
            {
                "size": "40х40х0,5",
                "koef": "0.000312",
                "metrs": 1
            },
            {
                "size": "40х40х0,55",
                "koef": "0.000343",
                "metrs": 1
            },
            {
                "size": "40х40х0,6",
                "koef": "0.000374",
                "metrs": 1
            },
            {
                "size": "40х40х0,7",
                "koef": "0.000436",
                "metrs": 1
            },
            {
                "size": "40х40х0,8",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": "40х40х0,9",
                "koef": "0.000559",
                "metrs": 1
            },
            {
                "size": "40х40х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "40х40х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "40х40х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "40х40х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "40х40х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "40х40х2,5",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": "40х40х3",
                "koef": "0.00176",
                "metrs": 1
            },
            {
                "size": "40х40х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "40х40х4",
                "koef": "0.002386",
                "metrs": 1
            },
            {
                "size": "40х40х5",
                "koef": "0.002944",
                "metrs": 1
            },
            {
                "size": "40х40х6",
                "koef": "0.003485",
                "metrs": 1
            },
            {
                "size": "40х45х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "40х45х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "40х45х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "40х45х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "40х45х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "40х45х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "40х45х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "40х45х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "40х50х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "40х50х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "40х50х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "40х50х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "40х50х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "40х50х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "40х50х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "40х50х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "40х55х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "40х55х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "40х55х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "40х55х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "40х55х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "40х55х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "40х55х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "40х55х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "40х60х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "40х60х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "40х60х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "40х60х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "40х60х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "40х60х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "40х60х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "40х60х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "40х65х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "40х65х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "40х65х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "40х65х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "40х65х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "40х65х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "40х65х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "40х65х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "40х70х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "40х70х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "40х70х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "40х70х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "40х70х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "40х70х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "40х70х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "40х70х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "45х15х1",
                "koef": "0.000463",
                "metrs": 1
            },
            {
                "size": "45х15х1,2",
                "koef": "0.000554",
                "metrs": 1
            },
            {
                "size": "45х15х1,5",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": "45х15х1,8",
                "koef": "0.000822",
                "metrs": 1
            },
            {
                "size": "45х15х2",
                "koef": "0.000911",
                "metrs": 1
            },
            {
                "size": "45х15х2,5",
                "koef": "0.001128",
                "metrs": 1
            },
            {
                "size": "45х15х3",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": "45х15х3,5",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": "45х20х1",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": "45х20х1,2",
                "koef": "0.000601",
                "metrs": 1
            },
            {
                "size": "45х20х1,5",
                "koef": "0.000748",
                "metrs": 1
            },
            {
                "size": "45х20х1,8",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "45х20х2",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "45х20х2,5",
                "koef": "0.001227",
                "metrs": 1
            },
            {
                "size": "45х20х3",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "45х20х3,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "45х25х1",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "45х25х1,2",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "45х25х1,5",
                "koef": "0.000807",
                "metrs": 1
            },
            {
                "size": "45х25х1,8",
                "koef": "0.000964",
                "metrs": 1
            },
            {
                "size": "45х25х2",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": "45х25х2,5",
                "koef": "0.001325",
                "metrs": 1
            },
            {
                "size": "45х25х3",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "45х25х3,5",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": "45х28х3",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": "45х28х4",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "45х30х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "45х30х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "45х30х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "45х30х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "45х30х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "45х30х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "45х30х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "45х30х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "45х30х4",
                "koef": "0.002229",
                "metrs": 1
            },
            {
                "size": "45х35х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "45х35х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "45х35х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "45х35х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "45х35х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "45х35х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "45х35х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "45х35х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "45х35х4",
                "koef": "0.002386",
                "metrs": 1
            },
            {
                "size": "45х35х5",
                "koef": "0.002944",
                "metrs": 1
            },
            {
                "size": "45х40х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "45х40х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "45х40х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "45х40х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "45х40х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "45х40х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "45х40х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "45х40х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "45х40х4",
                "koef": "0.002543",
                "metrs": 1
            },
            {
                "size": "45х40х5",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": "45х40х6",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": "45х45х0,5",
                "koef": "0.000351",
                "metrs": 1
            },
            {
                "size": "45х45х0,55",
                "koef": "0.000386",
                "metrs": 1
            },
            {
                "size": "45х45х0,6",
                "koef": "0.000421",
                "metrs": 1
            },
            {
                "size": "45х45х0,7",
                "koef": "0.000491",
                "metrs": 1
            },
            {
                "size": "45х45х0,8",
                "koef": "0.00056",
                "metrs": 1
            },
            {
                "size": "45х45х0,9",
                "koef": "0.000629",
                "metrs": 1
            },
            {
                "size": "45х45х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "45х45х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "45х45х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "45х45х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "45х45х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "45х45х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "45х45х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "45х45х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "45х45х4",
                "koef": "0.0027",
                "metrs": 1
            },
            {
                "size": "45х45х5",
                "koef": "0.003336",
                "metrs": 1
            },
            {
                "size": "45х45х6",
                "koef": "0.003956",
                "metrs": 1
            },
            {
                "size": "45х50х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "45х50х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "45х50х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "45х50х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "45х50х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "45х50х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "45х50х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "45х50х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "45х55х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "45х55х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "45х55х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "45х55х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "45х55х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "45х55х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "45х55х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "45х55х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "45х60х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "45х60х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "45х60х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "45х60х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "45х60х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "45х60х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "45х60х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "45х60х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "45х65х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "45х65х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "45х65х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "45х65х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "45х65х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "45х65х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "45х65х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "45х65х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "45х70х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "45х70х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "45х70х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "45х70х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "45х70х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "45х70х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "45х70х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "45х70х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "50х15х1",
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": "50х15х1,2",
                "koef": "0.000601",
                "metrs": 1
            },
            {
                "size": "50х15х1,5",
                "koef": "0.000748",
                "metrs": 1
            },
            {
                "size": "50х15х1,8",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "50х15х2",
                "koef": "0.000989",
                "metrs": 1
            },
            {
                "size": "50х15х2,5",
                "koef": "0.001227",
                "metrs": 1
            },
            {
                "size": "50х15х3",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "50х15х3,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "50х20х1",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "50х20х1,2",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "50х20х1,5",
                "koef": "0.000807",
                "metrs": 1
            },
            {
                "size": "50х20х1,8",
                "koef": "0.000964",
                "metrs": 1
            },
            {
                "size": "50х20х2",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": "50х20х2,5",
                "koef": "0.001325",
                "metrs": 1
            },
            {
                "size": "50х20х3",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "50х20х3,5",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": "50х25х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "50х25х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "50х25х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "50х25х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "50х25х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "50х25х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "50х25х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "50х25х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "50х30х0,5",
                "koef": "0.000312",
                "metrs": 1
            },
            {
                "size": "50х30х0,55",
                "koef": "0.000343",
                "metrs": 1
            },
            {
                "size": "50х30х0,6",
                "koef": "0.000374",
                "metrs": 1
            },
            {
                "size": "50х30х0,7",
                "koef": "0.000436",
                "metrs": 1
            },
            {
                "size": "50х30х0,8",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": "50х30х0,9",
                "koef": "0.000559",
                "metrs": 1
            },
            {
                "size": "50х30х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "50х30х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "50х30х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "50х30х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "50х30х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "50х30х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "50х30х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "50х30х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "50х30х4",
                "koef": "0.002386",
                "metrs": 1
            },
            {
                "size": "50х30х5",
                "koef": "0.002944",
                "metrs": 1
            },
            {
                "size": "50х32х3",
                "koef": "0.00186",
                "metrs": 1
            },
            {
                "size": "50х32х4",
                "koef": "0.002449",
                "metrs": 1
            },
            {
                "size": "50х33х5",
                "koef": "0.003062",
                "metrs": 1
            },
            {
                "size": "50х35х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "50х35х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "50х35х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "50х35х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "50х35х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "50х35х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "50х35х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "50х35х3,2",
                "koef": "0.00198",
                "metrs": 1
            },
            {
                "size": "50х35х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "50х35х4",
                "koef": "0.002543",
                "metrs": 1
            },
            {
                "size": "50х35х5",
                "koef": "0.00314",
                "metrs": 1
            },
            {
                "size": "50х40х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "50х40х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "50х40х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "50х40х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "50х40х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "50х40х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "50х40х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "50х40х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "50х40х4",
                "koef": "0.0027",
                "metrs": 1
            },
            {
                "size": "50х40х5",
                "koef": "0.003336",
                "metrs": 1
            },
            {
                "size": "50х40х6",
                "koef": "0.003956",
                "metrs": 1
            },
            {
                "size": "50х45х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "50х45х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "50х45х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "50х45х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "50х45х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "50х45х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "50х45х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "50х45х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "50х45х4",
                "koef": "0.002857",
                "metrs": 1
            },
            {
                "size": "50х45х5",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": "50х45х6",
                "koef": "0.004192",
                "metrs": 1
            },
            {
                "size": "50х50х0,5",
                "koef": "0.000391",
                "metrs": 1
            },
            {
                "size": "50х50х0,55",
                "koef": "0.000429",
                "metrs": 1
            },
            {
                "size": "50х50х0,6",
                "koef": "0.000468",
                "metrs": 1
            },
            {
                "size": "50х50х0,7",
                "koef": "0.000546",
                "metrs": 1
            },
            {
                "size": "50х50х0,8",
                "koef": "0.000623",
                "metrs": 1
            },
            {
                "size": "50х50х0,9",
                "koef": "0.0007",
                "metrs": 1
            },
            {
                "size": "50х50х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "50х50х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "50х50х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "50х50х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "50х50х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "50х50х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "50х50х3",
                "koef": "0.00223",
                "metrs": 1
            },
            {
                "size": "50х50х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "50х50х4",
                "koef": "0.0029",
                "metrs": 1
            },
            {
                "size": "50х50х5",
                "koef": "0.003729",
                "metrs": 1
            },
            {
                "size": "50х50х6",
                "koef": "0.004427",
                "metrs": 1
            },
            {
                "size": "50х55х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "50х55х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "50х55х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "50х55х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "50х55х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "50х55х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "50х55х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "50х55х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "50х60х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "50х60х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "50х60х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "50х60х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "50х60х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "50х60х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "50х60х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "50х60х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "50х65х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "50х65х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "50х65х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "50х65х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "50х65х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "50х65х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "50х65х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "50х65х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "50х70х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "50х70х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "50х70х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "50х70х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "50х70х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "50х70х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "50х70х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "50х70х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "55х15х1",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "55х15х1,2",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": "55х15х1,5",
                "koef": "0.000807",
                "metrs": 1
            },
            {
                "size": "55х15х1,8",
                "koef": "0.000964",
                "metrs": 1
            },
            {
                "size": "55х15х2",
                "koef": "0.001068",
                "metrs": 1
            },
            {
                "size": "55х15х2,5",
                "koef": "0.001325",
                "metrs": 1
            },
            {
                "size": "55х15х3",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": "55х15х3,5",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": "55х20х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "55х20х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "55х20х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "55х20х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "55х20х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "55х20х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "55х20х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "55х20х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "55х25х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "55х25х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "55х25х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "55х25х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "55х25х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "55х25х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "55х25х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "55х25х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "55х30х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "55х30х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "55х30х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "55х30х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "55х30х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "55х30х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "55х30х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "55х30х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "55х30х4",
                "koef": "0.002543",
                "metrs": 1
            },
            {
                "size": "55х35х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "55х35х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "55х35х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "55х35х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "55х35х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "55х35х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "55х35х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "55х35х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "55х35х4",
                "koef": "0.0027",
                "metrs": 1
            },
            {
                "size": "55х35х5",
                "koef": "0.003336",
                "metrs": 1
            },
            {
                "size": "55х40х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "55х40х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "55х40х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "55х40х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "55х40х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "55х40х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "55х40х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "55х40х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "55х40х4",
                "koef": "0.002857",
                "metrs": 1
            },
            {
                "size": "55х40х5",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": "55х40х6",
                "koef": "0.004192",
                "metrs": 1
            },
            {
                "size": "55х45х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "55х45х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "55х45х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "55х45х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "55х45х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "55х45х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "55х45х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "55х45х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "55х45х4",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": "55х45х5",
                "koef": "0.003729",
                "metrs": 1
            },
            {
                "size": "55х45х6",
                "koef": "0.004427",
                "metrs": 1
            },
            {
                "size": "55х50х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "55х50х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "55х50х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "55х50х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "55х50х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "55х50х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "55х50х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "55х50х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "55х50х4",
                "koef": "0.003171",
                "metrs": 1
            },
            {
                "size": "55х50х5",
                "koef": "0.003925",
                "metrs": 1
            },
            {
                "size": "55х50х6",
                "koef": "0.004663",
                "metrs": 1
            },
            {
                "size": "55х55х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "55х55х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "55х55х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "55х55х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "55х55х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "55х55х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "55х55х3",
                "koef": "0.00243",
                "metrs": 1
            },
            {
                "size": "55х55х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "55х55х4",
                "koef": "0.003328",
                "metrs": 1
            },
            {
                "size": "55х55х5",
                "koef": "0.004121",
                "metrs": 1
            },
            {
                "size": "55х55х6",
                "koef": "0.004898",
                "metrs": 1
            },
            {
                "size": "55х60х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "55х60х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "55х60х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "55х60х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "55х60х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "55х60х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "55х60х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "55х60х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "55х65х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "55х65х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "55х65х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "55х65х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "55х65х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "55х65х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "55х65х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "55х65х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "55х70х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "55х70х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "55х70х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "55х70х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "55х70х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "55х70х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "55х70х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "55х70х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "56х36х4",
                "koef": "0.002763",
                "metrs": 1
            },
            {
                "size": "56х36х5",
                "koef": "0.003415",
                "metrs": 1
            },
            {
                "size": "56х56х4",
                "koef": "0.003391",
                "metrs": 1
            },
            {
                "size": "56х56х5",
                "koef": "0.0042",
                "metrs": 1
            },
            {
                "size": "60х15х1",
                "koef": "0.000581",
                "metrs": 1
            },
            {
                "size": "60х15х1,2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "60х15х1,5",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": "60х15х1,8",
                "koef": "0.001034",
                "metrs": 1
            },
            {
                "size": "60х15х2",
                "koef": "0.001146",
                "metrs": 1
            },
            {
                "size": "60х15х2,5",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": "60х15х3",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "60х15х3,5",
                "koef": "0.001964",
                "metrs": 1
            },
            {
                "size": "60х20х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "60х20х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "60х20х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "60х20х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "60х20х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "60х20х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "60х20х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "60х20х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "60х25х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "60х25х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "60х25х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "60х25х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "60х25х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "60х25х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "60х25х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "60х25х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "60х30х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "60х30х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "60х30х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "60х30х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "60х30х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "60х30х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "60х30х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "60х30х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "60х30х4",
                "koef": "0.0027",
                "metrs": 1
            },
            {
                "size": "60х35х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "60х35х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "60х35х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "60х35х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "60х35х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "60х35х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "60х35х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "60х35х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "60х35х4",
                "koef": "0.002857",
                "metrs": 1
            },
            {
                "size": "60х35х5",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": "60х40х0,5",
                "koef": "0.000391",
                "metrs": 1
            },
            {
                "size": "60х40х0,55",
                "koef": "0.000429",
                "metrs": 1
            },
            {
                "size": "60х40х0,6",
                "koef": "0.000468",
                "metrs": 1
            },
            {
                "size": "60х40х0,7",
                "koef": "0.000546",
                "metrs": 1
            },
            {
                "size": "60х40х0,8",
                "koef": "0.000623",
                "metrs": 1
            },
            {
                "size": "60х40х0,9",
                "koef": "0.0007",
                "metrs": 1
            },
            {
                "size": "60х40х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "60х40х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "60х40х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "60х40х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "60х40х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "60х40х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "60х40х3",
                "koef": "0.00223",
                "metrs": 1
            },
            {
                "size": "60х40х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "60х40х4",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": "60х40х5",
                "koef": "0.003729",
                "metrs": 1
            },
            {
                "size": "60х40х6",
                "koef": "0.004427",
                "metrs": 1
            },
            {
                "size": "60х45х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "60х45х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "60х45х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "60х45х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "60х45х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "60х45х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "60х45х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "60х45х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "60х45х4",
                "koef": "0.003171",
                "metrs": 1
            },
            {
                "size": "60х45х5",
                "koef": "0.003925",
                "metrs": 1
            },
            {
                "size": "60х45х6",
                "koef": "0.004663",
                "metrs": 1
            },
            {
                "size": "60х50х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "60х50х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "60х50х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "60х50х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "60х50х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "60х50х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "60х50х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "60х50х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "60х50х4",
                "koef": "0.003328",
                "metrs": 1
            },
            {
                "size": "60х50х5",
                "koef": "0.004121",
                "metrs": 1
            },
            {
                "size": "60х50х6",
                "koef": "0.004898",
                "metrs": 1
            },
            {
                "size": "60х55х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "60х55х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "60х55х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "60х55х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "60х55х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "60х55х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "60х55х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "60х55х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "60х55х4",
                "koef": "0.003485",
                "metrs": 1
            },
            {
                "size": "60х55х5",
                "koef": "0.004318",
                "metrs": 1
            },
            {
                "size": "60х55х6",
                "koef": "0.005134",
                "metrs": 1
            },
            {
                "size": "60х60х0,5",
                "koef": "0.000469",
                "metrs": 1
            },
            {
                "size": "60х60х0,55",
                "koef": "0.000516",
                "metrs": 1
            },
            {
                "size": "60х60х0,6",
                "koef": "0.000562",
                "metrs": 1
            },
            {
                "size": "60х60х0,7",
                "koef": "0.000656",
                "metrs": 1
            },
            {
                "size": "60х60х0,8",
                "koef": "0.000749",
                "metrs": 1
            },
            {
                "size": "60х60х0,9",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": "60х60х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "60х60х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "60х60х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "60х60х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "60х60х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "60х60х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "60х60х3",
                "koef": "0.0027",
                "metrs": 1
            },
            {
                "size": "60х60х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "60х60х4",
                "koef": "0.00353",
                "metrs": 1
            },
            {
                "size": "60х60х5",
                "koef": "0.004514",
                "metrs": 1
            },
            {
                "size": "60х60х6",
                "koef": "0.005369",
                "metrs": 1
            },
            {
                "size": "60х65х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "60х65х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "60х65х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "60х65х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "60х65х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "60х65х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "60х65х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "60х65х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "60х70х1",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": "60х70х1,2",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "60х70х1,5",
                "koef": "0.001513",
                "metrs": 1
            },
            {
                "size": "60х70х1,8",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": "60х70х2",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": "60х70х2,5",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "60х70х3",
                "koef": "0.002991",
                "metrs": 1
            },
            {
                "size": "60х70х3,5",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": "63х35х2",
                "koef": "0.001507",
                "metrs": 1
            },
            {
                "size": "63х35х3",
                "koef": "0.002237",
                "metrs": 1
            },
            {
                "size": "63х35х4",
                "koef": "0.002952",
                "metrs": 1
            },
            {
                "size": "63х35х5",
                "koef": "0.00365",
                "metrs": 1
            },
            {
                "size": "63х40х0,5",
                "koef": "0.000402",
                "metrs": 1
            },
            {
                "size": "63х40х0,55",
                "koef": "0.000442",
                "metrs": 1
            },
            {
                "size": "63х40х0,6",
                "koef": "0.000482",
                "metrs": 1
            },
            {
                "size": "63х40х0,7",
                "koef": "0.000562",
                "metrs": 1
            },
            {
                "size": "63х40х0,8",
                "koef": "0.000642",
                "metrs": 1
            },
            {
                "size": "63х40х0,9",
                "koef": "0.000721",
                "metrs": 1
            },
            {
                "size": "63х40х1",
                "koef": "0.000801",
                "metrs": 1
            },
            {
                "size": "63х40х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": "63х40х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": "63х40х2",
                "koef": "0.001586",
                "metrs": 1
            },
            {
                "size": "63х40х2,5",
                "koef": "0.001972",
                "metrs": 1
            },
            {
                "size": "63х40х3",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": "63х40х4",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "63х40х5",
                "koef": "0.003846",
                "metrs": 1
            },
            {
                "size": "63х40х6",
                "koef": "0.004569",
                "metrs": 1
            },
            {
                "size": "63х40х8",
                "koef": "0.005966",
                "metrs": 1
            },
            {
                "size": "63х45х2",
                "koef": "0.001664",
                "metrs": 1
            },
            {
                "size": "63х45х3",
                "koef": "0.002473",
                "metrs": 1
            },
            {
                "size": "63х45х4",
                "koef": "0.003266",
                "metrs": 1
            },
            {
                "size": "63х45х5",
                "koef": "0.004043",
                "metrs": 1
            },
            {
                "size": "63х45х6",
                "koef": "0.004804",
                "metrs": 1
            },
            {
                "size": "63х50х2",
                "koef": "0.001743",
                "metrs": 1
            },
            {
                "size": "63х50х3",
                "koef": "0.002591",
                "metrs": 1
            },
            {
                "size": "63х50х4",
                "koef": "0.003423",
                "metrs": 1
            },
            {
                "size": "63х50х5",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": "63х50х6",
                "koef": "0.00504",
                "metrs": 1
            },
            {
                "size": "63х55х2",
                "koef": "0.001821",
                "metrs": 1
            },
            {
                "size": "63х55х3",
                "koef": "0.002708",
                "metrs": 1
            },
            {
                "size": "63х55х4",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "63х55х5",
                "koef": "0.004435",
                "metrs": 1
            },
            {
                "size": "63х55х6",
                "koef": "0.005275",
                "metrs": 1
            },
            {
                "size": "63х60х2",
                "koef": "0.0019",
                "metrs": 1
            },
            {
                "size": "63х60х3",
                "koef": "0.002826",
                "metrs": 1
            },
            {
                "size": "63х60х4",
                "koef": "0.003737",
                "metrs": 1
            },
            {
                "size": "63х60х5",
                "koef": "0.004632",
                "metrs": 1
            },
            {
                "size": "63х60х6",
                "koef": "0.005511",
                "metrs": 1
            },
            {
                "size": "63х63х0,5",
                "koef": "0.000493",
                "metrs": 1
            },
            {
                "size": "63х63х0,55",
                "koef": "0.000542",
                "metrs": 1
            },
            {
                "size": "63х63х0,6",
                "koef": "0.000591",
                "metrs": 1
            },
            {
                "size": "63х63х0,7",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": "63х63х0,8",
                "koef": "0.000786",
                "metrs": 1
            },
            {
                "size": "63х63х0,9",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "63х63х1",
                "koef": "0.000981",
                "metrs": 1
            },
            {
                "size": "63х63х1,2",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "63х63х1,5",
                "koef": "0.001466",
                "metrs": 1
            },
            {
                "size": "63х63х2",
                "koef": "0.001947",
                "metrs": 1
            },
            {
                "size": "63х63х2,5",
                "koef": "0.002424",
                "metrs": 1
            },
            {
                "size": "63х63х3",
                "koef": "0.002897",
                "metrs": 1
            },
            {
                "size": "63х63х4",
                "koef": "0.003831",
                "metrs": 1
            },
            {
                "size": "63х63х5",
                "koef": "0.004749",
                "metrs": 1
            },
            {
                "size": "63х63х6",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": "65х15х1",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "65х15х1,2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": "65х15х1,5",
                "koef": "0.000924",
                "metrs": 1
            },
            {
                "size": "65х15х1,8",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": "65х15х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": "65х15х2,5",
                "koef": "0.001521",
                "metrs": 1
            },
            {
                "size": "65х15х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": "65х15х3,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "65х20х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "65х20х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "65х20х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "65х20х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "65х20х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "65х20х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "65х20х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "65х20х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "65х25х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "65х25х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "65х25х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "65х25х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "65х25х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "65х25х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "65х25х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "65х25х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "65х30х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "65х30х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "65х30х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "65х30х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "65х30х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "65х30х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "65х30х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "65х30х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "65х35х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "65х35х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "65х35х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "65х35х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "65х35х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "65х35х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "65х35х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "65х35х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "65х40х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "65х40х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "65х40х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "65х40х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "65х40х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "65х40х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "65х40х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "65х40х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "65х45х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "65х45х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "65х45х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "65х45х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "65х45х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "65х45х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "65х45х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "65х45х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "65х45х4",
                "koef": "0.003328",
                "metrs": 1
            },
            {
                "size": "65х45х5",
                "koef": "0.004121",
                "metrs": 1
            },
            {
                "size": "65х45х6",
                "koef": "0.004898",
                "metrs": 1
            },
            {
                "size": "65х50х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "65х50х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "65х50х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "65х50х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "65х50х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "65х50х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "65х50х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "65х50х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "65х50х4",
                "koef": "0.003485",
                "metrs": 1
            },
            {
                "size": "65х50х5",
                "koef": "0.004318",
                "metrs": 1
            },
            {
                "size": "65х50х6",
                "koef": "0.005134",
                "metrs": 1
            },
            {
                "size": "65х50х7",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "65х50х8",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "65х55х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "65х55х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "65х55х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "65х55х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "65х55х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "65х55х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "65х55х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "65х55х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "65х55х4",
                "koef": "0.003642",
                "metrs": 1
            },
            {
                "size": "65х55х5",
                "koef": "0.004514",
                "metrs": 1
            },
            {
                "size": "65х55х6",
                "koef": "0.005369",
                "metrs": 1
            },
            {
                "size": "65х60х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "65х60х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "65х60х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "65х60х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "65х60х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "65х60х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "65х60х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "65х60х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "65х60х4",
                "koef": "0.003799",
                "metrs": 1
            },
            {
                "size": "65х60х5",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "65х60х6",
                "koef": "0.005605",
                "metrs": 1
            },
            {
                "size": "65х63х2",
                "koef": "0.001978",
                "metrs": 1
            },
            {
                "size": "65х63х3",
                "koef": "0.002944",
                "metrs": 1
            },
            {
                "size": "65х63х4",
                "koef": "0.003894",
                "metrs": 1
            },
            {
                "size": "65х63х5",
                "koef": "0.004828",
                "metrs": 1
            },
            {
                "size": "65х63х6",
                "koef": "0.005746",
                "metrs": 1
            },
            {
                "size": "65х65х1",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": "65х65х1,2",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "65х65х1,5",
                "koef": "0.001513",
                "metrs": 1
            },
            {
                "size": "65х65х1,8",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": "65х65х2",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": "65х65х2,5",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "65х65х3",
                "koef": "0.002991",
                "metrs": 1
            },
            {
                "size": "65х65х3,5",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": "65х65х4",
                "koef": "0.003956",
                "metrs": 1
            },
            {
                "size": "65х65х5",
                "koef": "0.004906",
                "metrs": 1
            },
            {
                "size": "65х65х6",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": "65х70х1",
                "koef": "0.001052",
                "metrs": 1
            },
            {
                "size": "65х70х1,2",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": "65х70х1,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "65х70х1,8",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": "65х70х2",
                "koef": "0.002088",
                "metrs": 1
            },
            {
                "size": "65х70х2,5",
                "koef": "0.0026",
                "metrs": 1
            },
            {
                "size": "65х70х3",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "65х70х3,5",
                "koef": "0.003613",
                "metrs": 1
            },
            {
                "size": "68х68х8",
                "koef": "0.008038",
                "metrs": 1
            },
            {
                "size": "70х15х1",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": "70х15х1,2",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": "70х15х1,5",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "70х15х1,8",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": "70х15х2",
                "koef": "0.001303",
                "metrs": 1
            },
            {
                "size": "70х15х2,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": "70х15х3",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "70х15х3,5",
                "koef": "0.002239",
                "metrs": 1
            },
            {
                "size": "70х20х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "70х20х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "70х20х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "70х20х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "70х20х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "70х20х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "70х20х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "70х20х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "70х25х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "70х25х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "70х25х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "70х25х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "70х25х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "70х25х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "70х25х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "70х25х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "70х30х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "70х30х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "70х30х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "70х30х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "70х30х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "70х30х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "70х30х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "70х30х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "70х35х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "70х35х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "70х35х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "70х35х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "70х35х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "70х35х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "70х35х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "70х35х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "70х40х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "70х40х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "70х40х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "70х40х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "70х40х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "70х40х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "70х40х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "70х40х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "70х45х0,5",
                "koef": "0.000449",
                "metrs": 1
            },
            {
                "size": "70х45х0,55",
                "koef": "0.000494",
                "metrs": 1
            },
            {
                "size": "70х45х0,6",
                "koef": "0.000539",
                "metrs": 1
            },
            {
                "size": "70х45х0,7",
                "koef": "0.000628",
                "metrs": 1
            },
            {
                "size": "70х45х0,8",
                "koef": "0.000717",
                "metrs": 1
            },
            {
                "size": "70х45х0,9",
                "koef": "0.000806",
                "metrs": 1
            },
            {
                "size": "70х45х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "70х45х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "70х45х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "70х45х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "70х45х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "70х45х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "70х45х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "70х45х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "70х45х4",
                "koef": "0.003485",
                "metrs": 1
            },
            {
                "size": "70х45х5",
                "koef": "0.004318",
                "metrs": 1
            },
            {
                "size": "70х45х6",
                "koef": "0.005134",
                "metrs": 1
            },
            {
                "size": "70х50х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "70х50х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "70х50х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "70х50х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "70х50х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "70х50х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "70х50х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "70х50х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "70х50х4",
                "koef": "0.00353",
                "metrs": 1
            },
            {
                "size": "70х50х5",
                "koef": "0.004514",
                "metrs": 1
            },
            {
                "size": "70х50х6",
                "koef": "0.005369",
                "metrs": 1
            },
            {
                "size": "70х55х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "70х55х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "70х55х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "70х55х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "70х55х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "70х55х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "70х55х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "70х55х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "70х55х4",
                "koef": "0.003799",
                "metrs": 1
            },
            {
                "size": "70х55х5",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "70х55х6",
                "koef": "0.005605",
                "metrs": 1
            },
            {
                "size": "70х60х1",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": "70х60х1,2",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "70х60х1,5",
                "koef": "0.001513",
                "metrs": 1
            },
            {
                "size": "70х60х1,8",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": "70х60х2",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": "70х60х2,5",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "70х60х3",
                "koef": "0.002991",
                "metrs": 1
            },
            {
                "size": "70х60х3,5",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": "70х60х4",
                "koef": "0.003956",
                "metrs": 1
            },
            {
                "size": "70х60х5",
                "koef": "0.004906",
                "metrs": 1
            },
            {
                "size": "70х60х6",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": "70х63х3",
                "koef": "0.003062",
                "metrs": 1
            },
            {
                "size": "70х63х4",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "70х63х5",
                "koef": "0.005024",
                "metrs": 1
            },
            {
                "size": "70х63х6",
                "koef": "0.005982",
                "metrs": 1
            },
            {
                "size": "70х65х1",
                "koef": "0.001052",
                "metrs": 1
            },
            {
                "size": "70х65х1,2",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": "70х65х1,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "70х65х1,8",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": "70х65х2",
                "koef": "0.002088",
                "metrs": 1
            },
            {
                "size": "70х65х2,5",
                "koef": "0.0026",
                "metrs": 1
            },
            {
                "size": "70х65х3",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "70х65х3,5",
                "koef": "0.003613",
                "metrs": 1
            },
            {
                "size": "70х65х4",
                "koef": "0.004113",
                "metrs": 1
            },
            {
                "size": "70х65х5",
                "koef": "0.005103",
                "metrs": 1
            },
            {
                "size": "70х65х6",
                "koef": "0.006076",
                "metrs": 1
            },
            {
                "size": "70х65х8",
                "koef": "0.007976",
                "metrs": 1
            },
            {
                "size": "70х70х0,5",
                "koef": "0.000548",
                "metrs": 1
            },
            {
                "size": "70х70х0,55",
                "koef": "0.000602",
                "metrs": 1
            },
            {
                "size": "70х70х0,6",
                "koef": "0.000657",
                "metrs": 1
            },
            {
                "size": "70х70х0,7",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": "70х70х0,8",
                "koef": "0.000874",
                "metrs": 1
            },
            {
                "size": "70х70х0,9",
                "koef": "0.000983",
                "metrs": 1
            },
            {
                "size": "70х70х1",
                "koef": "0.001091",
                "metrs": 1
            },
            {
                "size": "70х70х1,2",
                "koef": "0.001307",
                "metrs": 1
            },
            {
                "size": "70х70х1,5",
                "koef": "0.001631",
                "metrs": 1
            },
            {
                "size": "70х70х1,8",
                "koef": "0.001953",
                "metrs": 1
            },
            {
                "size": "70х70х2",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "70х70х2,5",
                "koef": "0.002698",
                "metrs": 1
            },
            {
                "size": "70х70х3",
                "koef": "0.003226",
                "metrs": 1
            },
            {
                "size": "70х70х3,5",
                "koef": "0.00375",
                "metrs": 1
            },
            {
                "size": "70х70х4",
                "koef": "0.00416",
                "metrs": 1
            },
            {
                "size": "70х70х4,5",
                "koef": "0.004787",
                "metrs": 1
            },
            {
                "size": "70х70х5",
                "koef": "0.005299",
                "metrs": 1
            },
            {
                "size": "70х70х6",
                "koef": "0.006311",
                "metrs": 1
            },
            {
                "size": "70х70х7",
                "koef": "0.007308",
                "metrs": 1
            },
            {
                "size": "70х70х8",
                "koef": "0.00829",
                "metrs": 1
            },
            {
                "size": "70х70х10",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": "73х35х1,5",
                "koef": "0.001254",
                "metrs": 1
            },
            {
                "size": "75х15х1",
                "koef": "0.000699",
                "metrs": 1
            },
            {
                "size": "75х15х1,2",
                "koef": "0.000836",
                "metrs": 1
            },
            {
                "size": "75х15х1,5",
                "koef": "0.001042",
                "metrs": 1
            },
            {
                "size": "75х15х1,8",
                "koef": "0.001246",
                "metrs": 1
            },
            {
                "size": "75х15х2",
                "koef": "0.001382",
                "metrs": 1
            },
            {
                "size": "75х15х2,5",
                "koef": "0.001717",
                "metrs": 1
            },
            {
                "size": "75х15х3",
                "koef": "0.002049",
                "metrs": 1
            },
            {
                "size": "75х15х3,5",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "75х20х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "75х20х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "75х20х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "75х20х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "75х20х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "75х20х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "75х20х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "75х20х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "75х25х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "75х25х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "75х25х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "75х25х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "75х25х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "75х25х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "75х25х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "75х25х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "75х30х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "75х30х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "75х30х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "75х30х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "75х30х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "75х30х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "75х30х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "75х30х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "75х35х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "75х35х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "75х35х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "75х35х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "75х35х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "75х35х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "75х35х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "75х35х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "75х40х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "75х40х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "75х40х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "75х40х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "75х40х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "75х40х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "75х40х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "75х40х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "75х45х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "75х45х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "75х45х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "75х45х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "75х45х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "75х45х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "75х45х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "75х45х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "75х50х0,5",
                "koef": "0.000489",
                "metrs": 1
            },
            {
                "size": "75х50х0,55",
                "koef": "0.000537",
                "metrs": 1
            },
            {
                "size": "75х50х0,6",
                "koef": "0.000586",
                "metrs": 1
            },
            {
                "size": "75х50х0,7",
                "koef": "0.000683",
                "metrs": 1
            },
            {
                "size": "75х50х0,8",
                "koef": "0.00078",
                "metrs": 1
            },
            {
                "size": "75х50х0,9",
                "koef": "0.000877",
                "metrs": 1
            },
            {
                "size": "75х50х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "75х50х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "75х50х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "75х50х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "75х50х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "75х50х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "75х50х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "75х50х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "75х50х4",
                "koef": "0.003799",
                "metrs": 1
            },
            {
                "size": "75х50х5",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "75х50х6",
                "koef": "0.005605",
                "metrs": 1
            },
            {
                "size": "75х50х7",
                "koef": "0.006484",
                "metrs": 1
            },
            {
                "size": "75х50х8",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "75х55х1",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": "75х55х1,2",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "75х55х1,5",
                "koef": "0.001513",
                "metrs": 1
            },
            {
                "size": "75х55х1,8",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": "75х55х2",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": "75х55х2,5",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "75х55х3",
                "koef": "0.002991",
                "metrs": 1
            },
            {
                "size": "75х55х3,5",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": "75х55х4",
                "koef": "0.003956",
                "metrs": 1
            },
            {
                "size": "75х55х5",
                "koef": "0.004906",
                "metrs": 1
            },
            {
                "size": "75х55х6",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": "75х60х1",
                "koef": "0.001052",
                "metrs": 1
            },
            {
                "size": "75х60х1,2",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": "75х60х1,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "75х60х1,8",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": "75х60х2",
                "koef": "0.002088",
                "metrs": 1
            },
            {
                "size": "75х60х2,5",
                "koef": "0.0026",
                "metrs": 1
            },
            {
                "size": "75х60х3",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "75х60х3,5",
                "koef": "0.003613",
                "metrs": 1
            },
            {
                "size": "75х60х4",
                "koef": "0.004113",
                "metrs": 1
            },
            {
                "size": "75х60х5",
                "koef": "0.005103",
                "metrs": 1
            },
            {
                "size": "75х60х6",
                "koef": "0.006076",
                "metrs": 1
            },
            {
                "size": "75х63х3",
                "koef": "0.003179",
                "metrs": 1
            },
            {
                "size": "75х63х4",
                "koef": "0.004208",
                "metrs": 1
            },
            {
                "size": "75х63х5",
                "koef": "0.00522",
                "metrs": 1
            },
            {
                "size": "75х63х6",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": "75х65х1",
                "koef": "0.001091",
                "metrs": 1
            },
            {
                "size": "75х65х1,2",
                "koef": "0.001307",
                "metrs": 1
            },
            {
                "size": "75х65х1,5",
                "koef": "0.001631",
                "metrs": 1
            },
            {
                "size": "75х65х1,8",
                "koef": "0.001953",
                "metrs": 1
            },
            {
                "size": "75х65х2",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "75х65х2,5",
                "koef": "0.002698",
                "metrs": 1
            },
            {
                "size": "75х65х3",
                "koef": "0.003226",
                "metrs": 1
            },
            {
                "size": "75х65х3,5",
                "koef": "0.00375",
                "metrs": 1
            },
            {
                "size": "75х65х4",
                "koef": "0.00427",
                "metrs": 1
            },
            {
                "size": "75х65х5",
                "koef": "0.005299",
                "metrs": 1
            },
            {
                "size": "75х65х6",
                "koef": "0.006311",
                "metrs": 1
            },
            {
                "size": "75х65х10",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": "75х68х8",
                "koef": "0.008478",
                "metrs": 1
            },
            {
                "size": "75х70х1",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": "75х70х1,2",
                "koef": "0.001355",
                "metrs": 1
            },
            {
                "size": "75х70х1,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "75х70х1,8",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": "75х70х2",
                "koef": "0.002245",
                "metrs": 1
            },
            {
                "size": "75х70х2,5",
                "koef": "0.002797",
                "metrs": 1
            },
            {
                "size": "75х70х3",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": "75х70х3,5",
                "koef": "0.003888",
                "metrs": 1
            },
            {
                "size": "75х70х4",
                "koef": "0.004427",
                "metrs": 1
            },
            {
                "size": "75х70х5",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": "75х70х6",
                "koef": "0.006547",
                "metrs": 1
            },
            {
                "size": "75х70х8",
                "koef": "0.008604",
                "metrs": 1
            },
            {
                "size": "75х70х10",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": "75х75х0,5",
                "koef": "0.000587",
                "metrs": 1
            },
            {
                "size": "75х75х0,55",
                "koef": "0.000645",
                "metrs": 1
            },
            {
                "size": "75х75х0,6",
                "koef": "0.000704",
                "metrs": 1
            },
            {
                "size": "75х75х0,7",
                "koef": "0.00082",
                "metrs": 1
            },
            {
                "size": "75х75х0,8",
                "koef": "0.000937",
                "metrs": 1
            },
            {
                "size": "75х75х0,9",
                "koef": "0.001053",
                "metrs": 1
            },
            {
                "size": "75х75х1",
                "koef": "0.00117",
                "metrs": 1
            },
            {
                "size": "75х75х1,2",
                "koef": "0.001402",
                "metrs": 1
            },
            {
                "size": "75х75х1,5",
                "koef": "0.001749",
                "metrs": 1
            },
            {
                "size": "75х75х1,8",
                "koef": "0.002094",
                "metrs": 1
            },
            {
                "size": "75х75х2",
                "koef": "0.002324",
                "metrs": 1
            },
            {
                "size": "75х75х2,5",
                "koef": "0.002895",
                "metrs": 1
            },
            {
                "size": "75х75х3",
                "koef": "0.003462",
                "metrs": 1
            },
            {
                "size": "75х75х3,5",
                "koef": "0.004025",
                "metrs": 1
            },
            {
                "size": "75х75х4",
                "koef": "0.004584",
                "metrs": 1
            },
            {
                "size": "75х75х5",
                "koef": "0.005691",
                "metrs": 1
            },
            {
                "size": "75х75х6",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "75х75х7",
                "koef": "0.007858",
                "metrs": 1
            },
            {
                "size": "75х75х8",
                "koef": "0.008918",
                "metrs": 1
            },
            {
                "size": "75х75х9",
                "koef": "0.009962",
                "metrs": 1
            },
            {
                "size": "75х75х10",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": "75х80х1",
                "koef": "0.001209",
                "metrs": 1
            },
            {
                "size": "75х80х1,2",
                "koef": "0.001449",
                "metrs": 1
            },
            {
                "size": "75х80х1,5",
                "koef": "0.001807",
                "metrs": 1
            },
            {
                "size": "75х80х1,8",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": "75х80х2",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "75х80х2,5",
                "koef": "0.002993",
                "metrs": 1
            },
            {
                "size": "75х80х3",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "75х80х3,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": "75х85х1",
                "koef": "0.001248",
                "metrs": 1
            },
            {
                "size": "75х85х1,2",
                "koef": "0.001496",
                "metrs": 1
            },
            {
                "size": "75х85х1,5",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": "75х85х1,8",
                "koef": "0.002235",
                "metrs": 1
            },
            {
                "size": "75х85х2",
                "koef": "0.002481",
                "metrs": 1
            },
            {
                "size": "75х85х2,5",
                "koef": "0.003091",
                "metrs": 1
            },
            {
                "size": "75х85х3",
                "koef": "0.003697",
                "metrs": 1
            },
            {
                "size": "75х85х3,5",
                "koef": "0.0043",
                "metrs": 1
            },
            {
                "size": "75х90х1",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": "75х90х1,2",
                "koef": "0.001543",
                "metrs": 1
            },
            {
                "size": "75х90х1,5",
                "koef": "0.001925",
                "metrs": 1
            },
            {
                "size": "75х90х1,8",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "75х90х2",
                "koef": "0.002559",
                "metrs": 1
            },
            {
                "size": "75х90х2,5",
                "koef": "0.003189",
                "metrs": 1
            },
            {
                "size": "75х90х3",
                "koef": "0.003815",
                "metrs": 1
            },
            {
                "size": "75х90х3,5",
                "koef": "0.004437",
                "metrs": 1
            },
            {
                "size": "75х95х1",
                "koef": "0.001327",
                "metrs": 1
            },
            {
                "size": "75х95х1,2",
                "koef": "0.00159",
                "metrs": 1
            },
            {
                "size": "75х95х1,5",
                "koef": "0.001984",
                "metrs": 1
            },
            {
                "size": "75х95х1,8",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "75х95х2",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "75х95х2,5",
                "koef": "0.003287",
                "metrs": 1
            },
            {
                "size": "75х95х3",
                "koef": "0.003933",
                "metrs": 1
            },
            {
                "size": "75х95х3,5",
                "koef": "0.004575",
                "metrs": 1
            },
            {
                "size": "75х100х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": "75х100х1,2",
                "koef": "0.001637",
                "metrs": 1
            },
            {
                "size": "75х100х1,5",
                "koef": "0.002043",
                "metrs": 1
            },
            {
                "size": "75х100х1,8",
                "koef": "0.002447",
                "metrs": 1
            },
            {
                "size": "75х100х2",
                "koef": "0.002716",
                "metrs": 1
            },
            {
                "size": "75х100х2,5",
                "koef": "0.003385",
                "metrs": 1
            },
            {
                "size": "75х100х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "75х100х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": "76х60х2,5",
                "koef": "0.00262",
                "metrs": 1
            },
            {
                "size": "79х90х1,5",
                "koef": "0.001972",
                "metrs": 1
            },
            {
                "size": "80х15х1",
                "koef": "0.000738",
                "metrs": 1
            },
            {
                "size": "80х15х1,2",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": "80х15х1,5",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": "80х15х1,8",
                "koef": "0.001317",
                "metrs": 1
            },
            {
                "size": "80х15х2",
                "koef": "0.00146",
                "metrs": 1
            },
            {
                "size": "80х15х2,5",
                "koef": "0.001815",
                "metrs": 1
            },
            {
                "size": "80х15х3",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "80х15х3,5",
                "koef": "0.002514",
                "metrs": 1
            },
            {
                "size": "80х20х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "80х20х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "80х20х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "80х20х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "80х20х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "80х20х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "80х20х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "80х20х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "80х25х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "80х25х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "80х25х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "80х25х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "80х25х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "80х25х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "80х25х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "80х25х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "80х30х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "80х30х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "80х30х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "80х30х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "80х30х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "80х30х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "80х30х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "80х30х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "80х35х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "80х35х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "80х35х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "80х35х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "80х35х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "80х35х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "80х35х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "80х35х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "80х40х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "80х40х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "80х40х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "80х40х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "80х40х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "80х40х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "80х40х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "80х40х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "80х45х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "80х45х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "80х45х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "80х45х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "80х45х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "80х45х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "80х45х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "80х45х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "80х50х1",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": "80х50х1,2",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "80х50х1,5",
                "koef": "0.001513",
                "metrs": 1
            },
            {
                "size": "80х50х1,8",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": "80х50х2",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": "80х50х2,5",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "80х50х3",
                "koef": "0.002991",
                "metrs": 1
            },
            {
                "size": "80х50х3,5",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": "80х50х4",
                "koef": "0.003956",
                "metrs": 1
            },
            {
                "size": "80х50х5",
                "koef": "0.004906",
                "metrs": 1
            },
            {
                "size": "80х50х6",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": "80х55х1",
                "koef": "0.001052",
                "metrs": 1
            },
            {
                "size": "80х55х1,2",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": "80х55х1,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "80х55х1,8",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": "80х55х2",
                "koef": "0.002088",
                "metrs": 1
            },
            {
                "size": "80х55х2,5",
                "koef": "0.0026",
                "metrs": 1
            },
            {
                "size": "80х55х3",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "80х55х3,5",
                "koef": "0.003613",
                "metrs": 1
            },
            {
                "size": "80х55х4",
                "koef": "0.004113",
                "metrs": 1
            },
            {
                "size": "80х55х5",
                "koef": "0.005103",
                "metrs": 1
            },
            {
                "size": "80х55х6",
                "koef": "0.006076",
                "metrs": 1
            },
            {
                "size": "80х60х1",
                "koef": "0.001091",
                "metrs": 1
            },
            {
                "size": "80х60х1,2",
                "koef": "0.001307",
                "metrs": 1
            },
            {
                "size": "80х60х1,5",
                "koef": "0.001631",
                "metrs": 1
            },
            {
                "size": "80х60х1,8",
                "koef": "0.001953",
                "metrs": 1
            },
            {
                "size": "80х60х2",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "80х60х2,5",
                "koef": "0.002698",
                "metrs": 1
            },
            {
                "size": "80х60х3",
                "koef": "0.003226",
                "metrs": 1
            },
            {
                "size": "80х60х3,5",
                "koef": "0.00375",
                "metrs": 1
            },
            {
                "size": "80х60х4",
                "koef": "0.00427",
                "metrs": 1
            },
            {
                "size": "80х60х5",
                "koef": "0.005299",
                "metrs": 1
            },
            {
                "size": "80х60х6",
                "koef": "0.006311",
                "metrs": 1
            },
            {
                "size": "80х60х7",
                "koef": "0.007308",
                "metrs": 1
            },
            {
                "size": "80х60х8",
                "koef": "0.00829",
                "metrs": 1
            },
            {
                "size": "80х63х3",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": "80х63х4",
                "koef": "0.00426",
                "metrs": 1
            },
            {
                "size": "80х63х5",
                "koef": "0.005416",
                "metrs": 1
            },
            {
                "size": "80х63х6",
                "koef": "0.006453",
                "metrs": 1
            },
            {
                "size": "80х65х1",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": "80х65х1,2",
                "koef": "0.001355",
                "metrs": 1
            },
            {
                "size": "80х65х1,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "80х65х1,8",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": "80х65х2",
                "koef": "0.002245",
                "metrs": 1
            },
            {
                "size": "80х65х2,5",
                "koef": "0.002797",
                "metrs": 1
            },
            {
                "size": "80х65х3",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": "80х65х3,5",
                "koef": "0.003888",
                "metrs": 1
            },
            {
                "size": "80х65х4",
                "koef": "0.004427",
                "metrs": 1
            },
            {
                "size": "80х65х5",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": "80х65х6",
                "koef": "0.006547",
                "metrs": 1
            },
            {
                "size": "80х65х10",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": "80х68х8",
                "koef": "0.008792",
                "metrs": 1
            },
            {
                "size": "80х70х1",
                "koef": "0.00117",
                "metrs": 1
            },
            {
                "size": "80х70х1,2",
                "koef": "0.001402",
                "metrs": 1
            },
            {
                "size": "80х70х1,5",
                "koef": "0.001749",
                "metrs": 1
            },
            {
                "size": "80х70х1,8",
                "koef": "0.002094",
                "metrs": 1
            },
            {
                "size": "80х70х2",
                "koef": "0.002324",
                "metrs": 1
            },
            {
                "size": "80х70х2,5",
                "koef": "0.002895",
                "metrs": 1
            },
            {
                "size": "80х70х3",
                "koef": "0.003462",
                "metrs": 1
            },
            {
                "size": "80х70х3,5",
                "koef": "0.004025",
                "metrs": 1
            },
            {
                "size": "80х70х4",
                "koef": "0.004584",
                "metrs": 1
            },
            {
                "size": "80х70х5",
                "koef": "0.005691",
                "metrs": 1
            },
            {
                "size": "80х70х6",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "80х70х8",
                "koef": "0.008918",
                "metrs": 1
            },
            {
                "size": "80х70х10",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": "80х75х1",
                "koef": "0.001209",
                "metrs": 1
            },
            {
                "size": "80х75х1,2",
                "koef": "0.001449",
                "metrs": 1
            },
            {
                "size": "80х75х1,5",
                "koef": "0.001807",
                "metrs": 1
            },
            {
                "size": "80х75х1,8",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": "80х75х2",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "80х75х2,5",
                "koef": "0.002993",
                "metrs": 1
            },
            {
                "size": "80х75х3",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "80х75х3,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": "80х75х4",
                "koef": "0.004741",
                "metrs": 1
            },
            {
                "size": "80х75х5",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": "80х75х6",
                "koef": "0.007018",
                "metrs": 1
            },
            {
                "size": "80х75х8",
                "koef": "0.009232",
                "metrs": 1
            },
            {
                "size": "80х75х10",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": "80х80х1",
                "koef": "0.001248",
                "metrs": 1
            },
            {
                "size": "80х80х1,2",
                "koef": "0.001496",
                "metrs": 1
            },
            {
                "size": "80х80х1,5",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": "80х80х1,8",
                "koef": "0.002235",
                "metrs": 1
            },
            {
                "size": "80х80х2",
                "koef": "0.002481",
                "metrs": 1
            },
            {
                "size": "80х80х2,5",
                "koef": "0.003091",
                "metrs": 1
            },
            {
                "size": "80х80х3",
                "koef": "0.00364",
                "metrs": 1
            },
            {
                "size": "80х80х3,5",
                "koef": "0.0043",
                "metrs": 1
            },
            {
                "size": "80х80х4",
                "koef": "0.00479",
                "metrs": 1
            },
            {
                "size": "80х80х5",
                "koef": "0.00592",
                "metrs": 1
            },
            {
                "size": "80х80х5,5",
                "koef": "0.006671",
                "metrs": 1
            },
            {
                "size": "80х80х6",
                "koef": "0.00701",
                "metrs": 1
            },
            {
                "size": "80х80х7",
                "koef": "0.00811",
                "metrs": 1
            },
            {
                "size": "80х80х8",
                "koef": "0.009546",
                "metrs": 1
            },
            {
                "size": "80х80х10",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "80х85х1",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": "80х85х1,2",
                "koef": "0.001543",
                "metrs": 1
            },
            {
                "size": "80х85х1,5",
                "koef": "0.001925",
                "metrs": 1
            },
            {
                "size": "80х85х1,8",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "80х85х2",
                "koef": "0.002559",
                "metrs": 1
            },
            {
                "size": "80х85х2,5",
                "koef": "0.003189",
                "metrs": 1
            },
            {
                "size": "80х85х3",
                "koef": "0.003815",
                "metrs": 1
            },
            {
                "size": "80х85х3,5",
                "koef": "0.004437",
                "metrs": 1
            },
            {
                "size": "80х90х1",
                "koef": "0.001327",
                "metrs": 1
            },
            {
                "size": "80х90х1,2",
                "koef": "0.00159",
                "metrs": 1
            },
            {
                "size": "80х90х1,5",
                "koef": "0.001984",
                "metrs": 1
            },
            {
                "size": "80х90х1,8",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "80х90х2",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "80х90х2,5",
                "koef": "0.003287",
                "metrs": 1
            },
            {
                "size": "80х90х3",
                "koef": "0.003933",
                "metrs": 1
            },
            {
                "size": "80х90х3,5",
                "koef": "0.004575",
                "metrs": 1
            },
            {
                "size": "80х95х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": "80х95х1,2",
                "koef": "0.001637",
                "metrs": 1
            },
            {
                "size": "80х95х1,5",
                "koef": "0.002043",
                "metrs": 1
            },
            {
                "size": "80х95х1,8",
                "koef": "0.002447",
                "metrs": 1
            },
            {
                "size": "80х95х2",
                "koef": "0.002716",
                "metrs": 1
            },
            {
                "size": "80х95х2,5",
                "koef": "0.003385",
                "metrs": 1
            },
            {
                "size": "80х95х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "80х95х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": "80х100х1",
                "koef": "0.001405",
                "metrs": 1
            },
            {
                "size": "80х100х1,2",
                "koef": "0.001684",
                "metrs": 1
            },
            {
                "size": "80х100х1,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "80х100х1,8",
                "koef": "0.002518",
                "metrs": 1
            },
            {
                "size": "80х100х2",
                "koef": "0.002795",
                "metrs": 1
            },
            {
                "size": "80х100х2,5",
                "koef": "0.003483",
                "metrs": 1
            },
            {
                "size": "80х100х3",
                "koef": "0.004168",
                "metrs": 1
            },
            {
                "size": "80х100х3,5",
                "koef": "0.004849",
                "metrs": 1
            },
            {
                "size": "85х15х1",
                "koef": "0.000777",
                "metrs": 1
            },
            {
                "size": "85х15х1,2",
                "koef": "0.000931",
                "metrs": 1
            },
            {
                "size": "85х15х1,5",
                "koef": "0.00116",
                "metrs": 1
            },
            {
                "size": "85х15х1,8",
                "koef": "0.001388",
                "metrs": 1
            },
            {
                "size": "85х15х2",
                "koef": "0.001539",
                "metrs": 1
            },
            {
                "size": "85х15х2,5",
                "koef": "0.001913",
                "metrs": 1
            },
            {
                "size": "85х15х3",
                "koef": "0.002284",
                "metrs": 1
            },
            {
                "size": "85х15х3,5",
                "koef": "0.002651",
                "metrs": 1
            },
            {
                "size": "85х20х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "85х20х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "85х20х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "85х20х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "85х20х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "85х20х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "85х20х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "85х20х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "85х25х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "85х25х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "85х25х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "85х25х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "85х25х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "85х25х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "85х25х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "85х25х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "85х30х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "85х30х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "85х30х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "85х30х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "85х30х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "85х30х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "85х30х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "85х30х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "85х35х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "85х35х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "85х35х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "85х35х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "85х35х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "85х35х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "85х35х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "85х35х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "85х35х4",
                "koef": "0.00353",
                "metrs": 1
            },
            {
                "size": "85х40х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "85х40х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "85х40х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "85х40х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "85х40х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "85х40х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "85х40х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "85х40х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "85х45х1",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": "85х45х1,2",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "85х45х1,5",
                "koef": "0.001513",
                "metrs": 1
            },
            {
                "size": "85х45х1,8",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": "85х45х2",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": "85х45х2,5",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "85х45х3",
                "koef": "0.002991",
                "metrs": 1
            },
            {
                "size": "85х45х3,5",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": "85х50х1",
                "koef": "0.001052",
                "metrs": 1
            },
            {
                "size": "85х50х1,2",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": "85х50х1,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "85х50х1,8",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": "85х50х2",
                "koef": "0.002088",
                "metrs": 1
            },
            {
                "size": "85х50х2,5",
                "koef": "0.0026",
                "metrs": 1
            },
            {
                "size": "85х50х3",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "85х50х3,5",
                "koef": "0.003613",
                "metrs": 1
            },
            {
                "size": "85х55х1",
                "koef": "0.001091",
                "metrs": 1
            },
            {
                "size": "85х55х1,2",
                "koef": "0.001307",
                "metrs": 1
            },
            {
                "size": "85х55х1,5",
                "koef": "0.001631",
                "metrs": 1
            },
            {
                "size": "85х55х1,8",
                "koef": "0.001953",
                "metrs": 1
            },
            {
                "size": "85х55х2",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "85х55х2,5",
                "koef": "0.002698",
                "metrs": 1
            },
            {
                "size": "85х55х3",
                "koef": "0.003226",
                "metrs": 1
            },
            {
                "size": "85х55х3,5",
                "koef": "0.00375",
                "metrs": 1
            },
            {
                "size": "85х60х1",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": "85х60х1,2",
                "koef": "0.001355",
                "metrs": 1
            },
            {
                "size": "85х60х1,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "85х60х1,8",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": "85х60х2",
                "koef": "0.002245",
                "metrs": 1
            },
            {
                "size": "85х60х2,5",
                "koef": "0.002797",
                "metrs": 1
            },
            {
                "size": "85х60х3",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": "85х60х3,5",
                "koef": "0.003888",
                "metrs": 1
            },
            {
                "size": "85х65х1",
                "koef": "0.00117",
                "metrs": 1
            },
            {
                "size": "85х65х1,2",
                "koef": "0.001402",
                "metrs": 1
            },
            {
                "size": "85х65х1,5",
                "koef": "0.001749",
                "metrs": 1
            },
            {
                "size": "85х65х1,8",
                "koef": "0.002094",
                "metrs": 1
            },
            {
                "size": "85х65х2",
                "koef": "0.002324",
                "metrs": 1
            },
            {
                "size": "85х65х2,5",
                "koef": "0.002895",
                "metrs": 1
            },
            {
                "size": "85х65х3",
                "koef": "0.003462",
                "metrs": 1
            },
            {
                "size": "85х65х3,5",
                "koef": "0.004025",
                "metrs": 1
            },
            {
                "size": "85х67х4",
                "koef": "0.00449",
                "metrs": 1
            },
            {
                "size": "85х70х1",
                "koef": "0.001209",
                "metrs": 1
            },
            {
                "size": "85х70х1,2",
                "koef": "0.001449",
                "metrs": 1
            },
            {
                "size": "85х70х1,5",
                "koef": "0.001807",
                "metrs": 1
            },
            {
                "size": "85х70х1,8",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": "85х70х2",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "85х70х2,5",
                "koef": "0.002993",
                "metrs": 1
            },
            {
                "size": "85х70х3",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "85х70х3,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": "85х75х1",
                "koef": "0.001248",
                "metrs": 1
            },
            {
                "size": "85х75х1,2",
                "koef": "0.001496",
                "metrs": 1
            },
            {
                "size": "85х75х1,5",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": "85х75х1,8",
                "koef": "0.002235",
                "metrs": 1
            },
            {
                "size": "85х75х2",
                "koef": "0.002481",
                "metrs": 1
            },
            {
                "size": "85х75х2,5",
                "koef": "0.003091",
                "metrs": 1
            },
            {
                "size": "85х75х3",
                "koef": "0.003697",
                "metrs": 1
            },
            {
                "size": "85х75х3,5",
                "koef": "0.0043",
                "metrs": 1
            },
            {
                "size": "85х80х1",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": "85х80х1,2",
                "koef": "0.001543",
                "metrs": 1
            },
            {
                "size": "85х80х1,5",
                "koef": "0.001925",
                "metrs": 1
            },
            {
                "size": "85х80х1,8",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "85х80х2",
                "koef": "0.002559",
                "metrs": 1
            },
            {
                "size": "85х80х2,5",
                "koef": "0.003189",
                "metrs": 1
            },
            {
                "size": "85х80х3",
                "koef": "0.003815",
                "metrs": 1
            },
            {
                "size": "85х80х3,5",
                "koef": "0.004437",
                "metrs": 1
            },
            {
                "size": "85х85х1",
                "koef": "0.001327",
                "metrs": 1
            },
            {
                "size": "85х85х1,2",
                "koef": "0.00159",
                "metrs": 1
            },
            {
                "size": "85х85х1,5",
                "koef": "0.001984",
                "metrs": 1
            },
            {
                "size": "85х85х1,8",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "85х85х2",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "85х85х2,5",
                "koef": "0.003287",
                "metrs": 1
            },
            {
                "size": "85х85х3",
                "koef": "0.003933",
                "metrs": 1
            },
            {
                "size": "85х85х3,5",
                "koef": "0.004575",
                "metrs": 1
            },
            {
                "size": "85х90х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": "85х90х1,2",
                "koef": "0.001637",
                "metrs": 1
            },
            {
                "size": "85х90х1,5",
                "koef": "0.002043",
                "metrs": 1
            },
            {
                "size": "85х90х1,8",
                "koef": "0.002447",
                "metrs": 1
            },
            {
                "size": "85х90х2",
                "koef": "0.002716",
                "metrs": 1
            },
            {
                "size": "85х90х2,5",
                "koef": "0.003385",
                "metrs": 1
            },
            {
                "size": "85х90х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "85х90х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": "85х95х1",
                "koef": "0.001405",
                "metrs": 1
            },
            {
                "size": "85х95х1,2",
                "koef": "0.001684",
                "metrs": 1
            },
            {
                "size": "85х95х1,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "85х95х1,8",
                "koef": "0.002518",
                "metrs": 1
            },
            {
                "size": "85х95х2",
                "koef": "0.002795",
                "metrs": 1
            },
            {
                "size": "85х95х2,5",
                "koef": "0.003483",
                "metrs": 1
            },
            {
                "size": "85х95х3",
                "koef": "0.004168",
                "metrs": 1
            },
            {
                "size": "85х95х3,5",
                "koef": "0.004849",
                "metrs": 1
            },
            {
                "size": "85х100х1",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": "85х100х1,2",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": "85х100х1,5",
                "koef": "0.002161",
                "metrs": 1
            },
            {
                "size": "85х100х1,8",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": "85х100х2",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "85х100х2,5",
                "koef": "0.003582",
                "metrs": 1
            },
            {
                "size": "85х100х3",
                "koef": "0.004286",
                "metrs": 1
            },
            {
                "size": "85х100х3,5",
                "koef": "0.004987",
                "metrs": 1
            },
            {
                "size": "90х15х1",
                "koef": "0.000816",
                "metrs": 1
            },
            {
                "size": "90х15х1,2",
                "koef": "0.000978",
                "metrs": 1
            },
            {
                "size": "90х15х1,5",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": "90х15х1,8",
                "koef": "0.001458",
                "metrs": 1
            },
            {
                "size": "90х15х2",
                "koef": "0.001617",
                "metrs": 1
            },
            {
                "size": "90х15х2,5",
                "koef": "0.002012",
                "metrs": 1
            },
            {
                "size": "90х15х3",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "90х15х3,5",
                "koef": "0.002789",
                "metrs": 1
            },
            {
                "size": "90х20х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "90х20х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "90х20х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "90х20х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "90х20х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "90х20х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "90х20х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "90х20х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "90х25х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "90х25х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "90х25х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "90х25х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "90х25х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "90х25х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "90х25х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "90х25х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "90х30х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "90х30х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "90х30х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "90х30х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "90х30х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "90х30х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "90х30х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "90х30х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "90х35х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "90х35х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "90х35х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "90х35х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "90х35х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "90х35х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "90х35х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "90х35х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "90х40х1",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": "90х40х1,2",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "90х40х1,5",
                "koef": "0.001513",
                "metrs": 1
            },
            {
                "size": "90х40х1,8",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": "90х40х2",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": "90х40х2,5",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "90х40х3",
                "koef": "0.002991",
                "metrs": 1
            },
            {
                "size": "90х40х3,5",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": "90х45х1",
                "koef": "0.001052",
                "metrs": 1
            },
            {
                "size": "90х45х1,2",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": "90х45х1,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "90х45х1,8",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": "90х45х2",
                "koef": "0.002088",
                "metrs": 1
            },
            {
                "size": "90х45х2,5",
                "koef": "0.0026",
                "metrs": 1
            },
            {
                "size": "90х45х3",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "90х45х3,5",
                "koef": "0.003613",
                "metrs": 1
            },
            {
                "size": "90х50х1",
                "koef": "0.001091",
                "metrs": 1
            },
            {
                "size": "90х50х1,2",
                "koef": "0.001307",
                "metrs": 1
            },
            {
                "size": "90х50х1,5",
                "koef": "0.001631",
                "metrs": 1
            },
            {
                "size": "90х50х1,8",
                "koef": "0.001953",
                "metrs": 1
            },
            {
                "size": "90х50х2",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "90х50х2,5",
                "koef": "0.002698",
                "metrs": 1
            },
            {
                "size": "90х50х3",
                "koef": "0.003226",
                "metrs": 1
            },
            {
                "size": "90х50х3,5",
                "koef": "0.00375",
                "metrs": 1
            },
            {
                "size": "90х50х4",
                "koef": "0.00427",
                "metrs": 1
            },
            {
                "size": "90х50х5",
                "koef": "0.005299",
                "metrs": 1
            },
            {
                "size": "90х50х6",
                "koef": "0.006311",
                "metrs": 1
            },
            {
                "size": "90х55х1",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": "90х55х1,2",
                "koef": "0.001355",
                "metrs": 1
            },
            {
                "size": "90х55х1,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "90х55х1,8",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": "90х55х2",
                "koef": "0.002245",
                "metrs": 1
            },
            {
                "size": "90х55х2,5",
                "koef": "0.002797",
                "metrs": 1
            },
            {
                "size": "90х55х3",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": "90х55х3,5",
                "koef": "0.003888",
                "metrs": 1
            },
            {
                "size": "90х55х4",
                "koef": "0.004427",
                "metrs": 1
            },
            {
                "size": "90х55х5",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": "90х55х6",
                "koef": "0.006547",
                "metrs": 1
            },
            {
                "size": "90х56х3",
                "koef": "0.003368",
                "metrs": 1
            },
            {
                "size": "90х56х4",
                "koef": "0.004459",
                "metrs": 1
            },
            {
                "size": "90х56х5",
                "koef": "0.005534",
                "metrs": 1
            },
            {
                "size": "90х56х5,5",
                "koef": "0.006066",
                "metrs": 1
            },
            {
                "size": "90х56х6",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": "90х56х8",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "90х60х1",
                "koef": "0.00117",
                "metrs": 1
            },
            {
                "size": "90х60х1,2",
                "koef": "0.001402",
                "metrs": 1
            },
            {
                "size": "90х60х1,5",
                "koef": "0.001749",
                "metrs": 1
            },
            {
                "size": "90х60х1,8",
                "koef": "0.002094",
                "metrs": 1
            },
            {
                "size": "90х60х2",
                "koef": "0.002324",
                "metrs": 1
            },
            {
                "size": "90х60х2,5",
                "koef": "0.002895",
                "metrs": 1
            },
            {
                "size": "90х60х3",
                "koef": "0.003462",
                "metrs": 1
            },
            {
                "size": "90х60х3,5",
                "koef": "0.004025",
                "metrs": 1
            },
            {
                "size": "90х60х4",
                "koef": "0.004584",
                "metrs": 1
            },
            {
                "size": "90х60х5",
                "koef": "0.005691",
                "metrs": 1
            },
            {
                "size": "90х60х6",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "90х63х3",
                "koef": "0.003532",
                "metrs": 1
            },
            {
                "size": "90х63х4",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "90х63х5",
                "koef": "0.005809",
                "metrs": 1
            },
            {
                "size": "90х63х6",
                "koef": "0.006924",
                "metrs": 1
            },
            {
                "size": "90х65х1",
                "koef": "0.001209",
                "metrs": 1
            },
            {
                "size": "90х65х1,2",
                "koef": "0.001449",
                "metrs": 1
            },
            {
                "size": "90х65х1,5",
                "koef": "0.001807",
                "metrs": 1
            },
            {
                "size": "90х65х1,8",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": "90х65х2",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "90х65х2,5",
                "koef": "0.002993",
                "metrs": 1
            },
            {
                "size": "90х65х3",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "90х65х3,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": "90х65х4",
                "koef": "0.004741",
                "metrs": 1
            },
            {
                "size": "90х65х5",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": "90х65х6",
                "koef": "0.007018",
                "metrs": 1
            },
            {
                "size": "90х68х8",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "90х70х1",
                "koef": "0.001248",
                "metrs": 1
            },
            {
                "size": "90х70х1,2",
                "koef": "0.001496",
                "metrs": 1
            },
            {
                "size": "90х70х1,5",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": "90х70х1,8",
                "koef": "0.002235",
                "metrs": 1
            },
            {
                "size": "90х70х2",
                "koef": "0.002481",
                "metrs": 1
            },
            {
                "size": "90х70х2,5",
                "koef": "0.003091",
                "metrs": 1
            },
            {
                "size": "90х70х3",
                "koef": "0.003697",
                "metrs": 1
            },
            {
                "size": "90х70х3,5",
                "koef": "0.0043",
                "metrs": 1
            },
            {
                "size": "90х70х4",
                "koef": "0.00479",
                "metrs": 1
            },
            {
                "size": "90х70х5",
                "koef": "0.006084",
                "metrs": 1
            },
            {
                "size": "90х70х6",
                "koef": "0.007253",
                "metrs": 1
            },
            {
                "size": "90х70х8",
                "koef": "0.009546",
                "metrs": 1
            },
            {
                "size": "90х70х10",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "90х75х1",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": "90х75х1,2",
                "koef": "0.001543",
                "metrs": 1
            },
            {
                "size": "90х75х1,5",
                "koef": "0.001925",
                "metrs": 1
            },
            {
                "size": "90х75х1,8",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "90х75х2",
                "koef": "0.002559",
                "metrs": 1
            },
            {
                "size": "90х75х2,5",
                "koef": "0.003189",
                "metrs": 1
            },
            {
                "size": "90х75х3",
                "koef": "0.003815",
                "metrs": 1
            },
            {
                "size": "90х75х3,5",
                "koef": "0.004437",
                "metrs": 1
            },
            {
                "size": "90х75х4",
                "koef": "0.005055",
                "metrs": 1
            },
            {
                "size": "90х75х5",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": "90х75х6",
                "koef": "0.007489",
                "metrs": 1
            },
            {
                "size": "90х75х8",
                "koef": "0.00986",
                "metrs": 1
            },
            {
                "size": "90х75х10",
                "koef": "0.01217",
                "metrs": 1
            },
            {
                "size": "90х80х1",
                "koef": "0.001327",
                "metrs": 1
            },
            {
                "size": "90х80х1,2",
                "koef": "0.00159",
                "metrs": 1
            },
            {
                "size": "90х80х1,5",
                "koef": "0.001984",
                "metrs": 1
            },
            {
                "size": "90х80х1,8",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "90х80х2",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "90х80х2,5",
                "koef": "0.003287",
                "metrs": 1
            },
            {
                "size": "90х80х3",
                "koef": "0.003933",
                "metrs": 1
            },
            {
                "size": "90х80х3,5",
                "koef": "0.004575",
                "metrs": 1
            },
            {
                "size": "90х80х4",
                "koef": "0.005212",
                "metrs": 1
            },
            {
                "size": "90х80х5",
                "koef": "0.006476",
                "metrs": 1
            },
            {
                "size": "90х80х6",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "90х80х8",
                "koef": "0.01017",
                "metrs": 1
            },
            {
                "size": "90х80х10",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": "90х85х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": "90х85х1,2",
                "koef": "0.001637",
                "metrs": 1
            },
            {
                "size": "90х85х1,5",
                "koef": "0.002043",
                "metrs": 1
            },
            {
                "size": "90х85х1,8",
                "koef": "0.002447",
                "metrs": 1
            },
            {
                "size": "90х85х2",
                "koef": "0.002716",
                "metrs": 1
            },
            {
                "size": "90х85х2,5",
                "koef": "0.003385",
                "metrs": 1
            },
            {
                "size": "90х85х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "90х85х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": "90х90х1",
                "koef": "0.001405",
                "metrs": 1
            },
            {
                "size": "90х90х1,2",
                "koef": "0.001684",
                "metrs": 1
            },
            {
                "size": "90х90х1,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "90х90х1,8",
                "koef": "0.002518",
                "metrs": 1
            },
            {
                "size": "90х90х2",
                "koef": "0.002795",
                "metrs": 1
            },
            {
                "size": "90х90х2,5",
                "koef": "0.003483",
                "metrs": 1
            },
            {
                "size": "90х90х3",
                "koef": "0.004168",
                "metrs": 1
            },
            {
                "size": "90х90х3,5",
                "koef": "0.004849",
                "metrs": 1
            },
            {
                "size": "90х90х4",
                "koef": "0.005526",
                "metrs": 1
            },
            {
                "size": "90х90х5",
                "koef": "0.006869",
                "metrs": 1
            },
            {
                "size": "90х90х6",
                "koef": "0.008195",
                "metrs": 1
            },
            {
                "size": "90х90х7",
                "koef": "0.009506",
                "metrs": 1
            },
            {
                "size": "90х90х8",
                "koef": "0.0108",
                "metrs": 1
            },
            {
                "size": "90х90х9",
                "koef": "0.01208",
                "metrs": 1
            },
            {
                "size": "90х90х10",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": "90х95х1",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": "90х95х1,2",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": "90х95х1,5",
                "koef": "0.002161",
                "metrs": 1
            },
            {
                "size": "90х95х1,8",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": "90х95х2",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "90х95х2,5",
                "koef": "0.003582",
                "metrs": 1
            },
            {
                "size": "90х95х3",
                "koef": "0.004286",
                "metrs": 1
            },
            {
                "size": "90х95х3,5",
                "koef": "0.004987",
                "metrs": 1
            },
            {
                "size": "90х100х1",
                "koef": "0.001484",
                "metrs": 1
            },
            {
                "size": "90х100х1,2",
                "koef": "0.001778",
                "metrs": 1
            },
            {
                "size": "90х100х1,5",
                "koef": "0.00222",
                "metrs": 1
            },
            {
                "size": "90х100х1,8",
                "koef": "0.002659",
                "metrs": 1
            },
            {
                "size": "90х100х2",
                "koef": "0.002952",
                "metrs": 1
            },
            {
                "size": "90х100х2,5",
                "koef": "0.00368",
                "metrs": 1
            },
            {
                "size": "90х100х3",
                "koef": "0.004404",
                "metrs": 1
            },
            {
                "size": "90х100х3,5",
                "koef": "0.005124",
                "metrs": 1
            },
            {
                "size": "95х15х1",
                "koef": "0.000856",
                "metrs": 1
            },
            {
                "size": "95х15х1,2",
                "koef": "0.001025",
                "metrs": 1
            },
            {
                "size": "95х15х1,5",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": "95х15х1,8",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": "95х15х2",
                "koef": "0.001696",
                "metrs": 1
            },
            {
                "size": "95х15х2,5",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": "95х15х3",
                "koef": "0.00252",
                "metrs": 1
            },
            {
                "size": "95х15х3,5",
                "koef": "0.002926",
                "metrs": 1
            },
            {
                "size": "95х20х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "95х20х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "95х20х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "95х20х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "95х20х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "95х20х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "95х20х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "95х20х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "95х25х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "95х25х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "95х25х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "95х25х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "95х25х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "95х25х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "95х25х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "95х25х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "95х30х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "95х30х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "95х30х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "95х30х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "95х30х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "95х30х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "95х30х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "95х30х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "95х35х1",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": "95х35х1,2",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "95х35х1,5",
                "koef": "0.001513",
                "metrs": 1
            },
            {
                "size": "95х35х1,8",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": "95х35х2",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": "95х35х2,5",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "95х35х3",
                "koef": "0.002991",
                "metrs": 1
            },
            {
                "size": "95х35х3,5",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": "95х40х1",
                "koef": "0.001052",
                "metrs": 1
            },
            {
                "size": "95х40х1,2",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": "95х40х1,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "95х40х1,8",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": "95х40х2",
                "koef": "0.002088",
                "metrs": 1
            },
            {
                "size": "95х40х2,5",
                "koef": "0.0026",
                "metrs": 1
            },
            {
                "size": "95х40х3",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "95х40х3,5",
                "koef": "0.003613",
                "metrs": 1
            },
            {
                "size": "95х45х1",
                "koef": "0.001091",
                "metrs": 1
            },
            {
                "size": "95х45х1,2",
                "koef": "0.001307",
                "metrs": 1
            },
            {
                "size": "95х45х1,5",
                "koef": "0.001631",
                "metrs": 1
            },
            {
                "size": "95х45х1,8",
                "koef": "0.001953",
                "metrs": 1
            },
            {
                "size": "95х45х2",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "95х45х2,5",
                "koef": "0.002698",
                "metrs": 1
            },
            {
                "size": "95х45х3",
                "koef": "0.003226",
                "metrs": 1
            },
            {
                "size": "95х45х3,5",
                "koef": "0.00375",
                "metrs": 1
            },
            {
                "size": "95х50х1",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": "95х50х1,2",
                "koef": "0.001355",
                "metrs": 1
            },
            {
                "size": "95х50х1,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "95х50х1,8",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": "95х50х2",
                "koef": "0.002245",
                "metrs": 1
            },
            {
                "size": "95х50х2,5",
                "koef": "0.002797",
                "metrs": 1
            },
            {
                "size": "95х50х3",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": "95х50х3,5",
                "koef": "0.003888",
                "metrs": 1
            },
            {
                "size": "95х55х1",
                "koef": "0.00117",
                "metrs": 1
            },
            {
                "size": "95х55х1,2",
                "koef": "0.001402",
                "metrs": 1
            },
            {
                "size": "95х55х1,5",
                "koef": "0.001749",
                "metrs": 1
            },
            {
                "size": "95х55х1,8",
                "koef": "0.002094",
                "metrs": 1
            },
            {
                "size": "95х55х2",
                "koef": "0.002324",
                "metrs": 1
            },
            {
                "size": "95х55х2,5",
                "koef": "0.002895",
                "metrs": 1
            },
            {
                "size": "95х55х3",
                "koef": "0.003462",
                "metrs": 1
            },
            {
                "size": "95х55х3,5",
                "koef": "0.004025",
                "metrs": 1
            },
            {
                "size": "95х60х1",
                "koef": "0.001209",
                "metrs": 1
            },
            {
                "size": "95х60х1,2",
                "koef": "0.001449",
                "metrs": 1
            },
            {
                "size": "95х60х1,5",
                "koef": "0.001807",
                "metrs": 1
            },
            {
                "size": "95х60х1,8",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": "95х60х2",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "95х60х2,5",
                "koef": "0.002993",
                "metrs": 1
            },
            {
                "size": "95х60х3",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "95х60х3,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": "95х65х1",
                "koef": "0.001248",
                "metrs": 1
            },
            {
                "size": "95х65х1,2",
                "koef": "0.001496",
                "metrs": 1
            },
            {
                "size": "95х65х1,5",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": "95х65х1,8",
                "koef": "0.002235",
                "metrs": 1
            },
            {
                "size": "95х65х2",
                "koef": "0.002481",
                "metrs": 1
            },
            {
                "size": "95х65х2,5",
                "koef": "0.003091",
                "metrs": 1
            },
            {
                "size": "95х65х3",
                "koef": "0.003697",
                "metrs": 1
            },
            {
                "size": "95х65х3,5",
                "koef": "0.0043",
                "metrs": 1
            },
            {
                "size": "95х70х1",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": "95х70х1,2",
                "koef": "0.001543",
                "metrs": 1
            },
            {
                "size": "95х70х1,5",
                "koef": "0.001925",
                "metrs": 1
            },
            {
                "size": "95х70х1,8",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "95х70х2",
                "koef": "0.002559",
                "metrs": 1
            },
            {
                "size": "95х70х2,5",
                "koef": "0.003189",
                "metrs": 1
            },
            {
                "size": "95х70х3",
                "koef": "0.003815",
                "metrs": 1
            },
            {
                "size": "95х70х3,5",
                "koef": "0.004437",
                "metrs": 1
            },
            {
                "size": "95х75х1",
                "koef": "0.001327",
                "metrs": 1
            },
            {
                "size": "95х75х1,2",
                "koef": "0.00159",
                "metrs": 1
            },
            {
                "size": "95х75х1,5",
                "koef": "0.001984",
                "metrs": 1
            },
            {
                "size": "95х75х1,8",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "95х75х2",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "95х75х2,5",
                "koef": "0.003287",
                "metrs": 1
            },
            {
                "size": "95х75х3",
                "koef": "0.003933",
                "metrs": 1
            },
            {
                "size": "95х75х3,5",
                "koef": "0.004575",
                "metrs": 1
            },
            {
                "size": "95х80х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": "95х80х1,2",
                "koef": "0.001637",
                "metrs": 1
            },
            {
                "size": "95х80х1,5",
                "koef": "0.002043",
                "metrs": 1
            },
            {
                "size": "95х80х1,8",
                "koef": "0.002447",
                "metrs": 1
            },
            {
                "size": "95х80х2",
                "koef": "0.002716",
                "metrs": 1
            },
            {
                "size": "95х80х2,5",
                "koef": "0.003385",
                "metrs": 1
            },
            {
                "size": "95х80х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "95х80х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": "95х85х1",
                "koef": "0.001405",
                "metrs": 1
            },
            {
                "size": "95х85х1,2",
                "koef": "0.001684",
                "metrs": 1
            },
            {
                "size": "95х85х1,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "95х85х1,8",
                "koef": "0.002518",
                "metrs": 1
            },
            {
                "size": "95х85х2",
                "koef": "0.002795",
                "metrs": 1
            },
            {
                "size": "95х85х2,5",
                "koef": "0.003483",
                "metrs": 1
            },
            {
                "size": "95х85х3",
                "koef": "0.004168",
                "metrs": 1
            },
            {
                "size": "95х85х3,5",
                "koef": "0.004849",
                "metrs": 1
            },
            {
                "size": "95х90х1",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": "95х90х1,2",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": "95х90х1,5",
                "koef": "0.002161",
                "metrs": 1
            },
            {
                "size": "95х90х1,8",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": "95х90х2",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "95х90х2,5",
                "koef": "0.003582",
                "metrs": 1
            },
            {
                "size": "95х90х3",
                "koef": "0.004286",
                "metrs": 1
            },
            {
                "size": "95х90х3,5",
                "koef": "0.004987",
                "metrs": 1
            },
            {
                "size": "95х95х1",
                "koef": "0.001484",
                "metrs": 1
            },
            {
                "size": "95х95х1,2",
                "koef": "0.001778",
                "metrs": 1
            },
            {
                "size": "95х95х1,5",
                "koef": "0.00222",
                "metrs": 1
            },
            {
                "size": "95х95х1,8",
                "koef": "0.002659",
                "metrs": 1
            },
            {
                "size": "95х95х2",
                "koef": "0.002952",
                "metrs": 1
            },
            {
                "size": "95х95х2,5",
                "koef": "0.00368",
                "metrs": 1
            },
            {
                "size": "95х95х3",
                "koef": "0.004404",
                "metrs": 1
            },
            {
                "size": "95х95х3,5",
                "koef": "0.005124",
                "metrs": 1
            },
            {
                "size": "95х100х1",
                "koef": "0.001523",
                "metrs": 1
            },
            {
                "size": "95х100х1,2",
                "koef": "0.001826",
                "metrs": 1
            },
            {
                "size": "95х100х1,5",
                "koef": "0.002278",
                "metrs": 1
            },
            {
                "size": "95х100х1,8",
                "koef": "0.00273",
                "metrs": 1
            },
            {
                "size": "95х100х2",
                "koef": "0.00303",
                "metrs": 1
            },
            {
                "size": "95х100х2,5",
                "koef": "0.003778",
                "metrs": 1
            },
            {
                "size": "95х100х3",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "95х100х3,5",
                "koef": "0.005261",
                "metrs": 1
            },
            {
                "size": "100х15х1",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": "100х15х1,2",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": "100х15х1,5",
                "koef": "0.001336",
                "metrs": 1
            },
            {
                "size": "100х15х1,8",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "100х15х2",
                "koef": "0.001774",
                "metrs": 1
            },
            {
                "size": "100х15х2,5",
                "koef": "0.002208",
                "metrs": 1
            },
            {
                "size": "100х15х3",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "100х15х3,5",
                "koef": "0.003063",
                "metrs": 1
            },
            {
                "size": "100х20х1",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": "100х20х1,2",
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": "100х20х1,5",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": "100х20х1,8",
                "koef": "0.00167",
                "metrs": 1
            },
            {
                "size": "100х20х2",
                "koef": "0.001853",
                "metrs": 1
            },
            {
                "size": "100х20х2,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "100х20х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "100х20х3,5",
                "koef": "0.003201",
                "metrs": 1
            },
            {
                "size": "100х25х1",
                "koef": "0.000973",
                "metrs": 1
            },
            {
                "size": "100х25х1,2",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "100х25х1,5",
                "koef": "0.001454",
                "metrs": 1
            },
            {
                "size": "100х25х1,8",
                "koef": "0.001741",
                "metrs": 1
            },
            {
                "size": "100х25х2",
                "koef": "0.001931",
                "metrs": 1
            },
            {
                "size": "100х25х2,5",
                "koef": "0.002404",
                "metrs": 1
            },
            {
                "size": "100х25х3",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "100х25х3,5",
                "koef": "0.003338",
                "metrs": 1
            },
            {
                "size": "100х30х1",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": "100х30х1,2",
                "koef": "0.001213",
                "metrs": 1
            },
            {
                "size": "100х30х1,5",
                "koef": "0.001513",
                "metrs": 1
            },
            {
                "size": "100х30х1,8",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": "100х30х2",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": "100х30х2,5",
                "koef": "0.002502",
                "metrs": 1
            },
            {
                "size": "100х30х3",
                "koef": "0.002991",
                "metrs": 1
            },
            {
                "size": "100х30х3,5",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": "100х35х1",
                "koef": "0.001052",
                "metrs": 1
            },
            {
                "size": "100х35х1,2",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": "100х35х1,5",
                "koef": "0.001572",
                "metrs": 1
            },
            {
                "size": "100х35х1,8",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": "100х35х2",
                "koef": "0.002088",
                "metrs": 1
            },
            {
                "size": "100х35х2,5",
                "koef": "0.0026",
                "metrs": 1
            },
            {
                "size": "100х35х3",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "100х35х3,5",
                "koef": "0.003613",
                "metrs": 1
            },
            {
                "size": "100х40х1",
                "koef": "0.001091",
                "metrs": 1
            },
            {
                "size": "100х40х1,2",
                "koef": "0.001307",
                "metrs": 1
            },
            {
                "size": "100х40х1,5",
                "koef": "0.001631",
                "metrs": 1
            },
            {
                "size": "100х40х1,8",
                "koef": "0.001953",
                "metrs": 1
            },
            {
                "size": "100х40х2",
                "koef": "0.002167",
                "metrs": 1
            },
            {
                "size": "100х40х2,5",
                "koef": "0.002698",
                "metrs": 1
            },
            {
                "size": "100х40х3",
                "koef": "0.003226",
                "metrs": 1
            },
            {
                "size": "100х40х3,5",
                "koef": "0.00375",
                "metrs": 1
            },
            {
                "size": "100х45х1",
                "koef": "0.00113",
                "metrs": 1
            },
            {
                "size": "100х45х1,2",
                "koef": "0.001355",
                "metrs": 1
            },
            {
                "size": "100х45х1,5",
                "koef": "0.00169",
                "metrs": 1
            },
            {
                "size": "100х45х1,8",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": "100х45х2",
                "koef": "0.002245",
                "metrs": 1
            },
            {
                "size": "100х45х2,5",
                "koef": "0.002797",
                "metrs": 1
            },
            {
                "size": "100х45х3",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": "100х45х3,5",
                "koef": "0.003888",
                "metrs": 1
            },
            {
                "size": "100х50х1",
                "koef": "0.00117",
                "metrs": 1
            },
            {
                "size": "100х50х1,2",
                "koef": "0.001402",
                "metrs": 1
            },
            {
                "size": "100х50х1,5",
                "koef": "0.001749",
                "metrs": 1
            },
            {
                "size": "100х50х1,8",
                "koef": "0.002094",
                "metrs": 1
            },
            {
                "size": "100х50х2",
                "koef": "0.002324",
                "metrs": 1
            },
            {
                "size": "100х50х2,5",
                "koef": "0.002895",
                "metrs": 1
            },
            {
                "size": "100х50х3",
                "koef": "0.003462",
                "metrs": 1
            },
            {
                "size": "100х50х3,5",
                "koef": "0.004025",
                "metrs": 1
            },
            {
                "size": "100х50х4",
                "koef": "0.004584",
                "metrs": 1
            },
            {
                "size": "100х50х5",
                "koef": "0.005691",
                "metrs": 1
            },
            {
                "size": "100х50х6",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "100х55х1",
                "koef": "0.001209",
                "metrs": 1
            },
            {
                "size": "100х55х1,2",
                "koef": "0.001449",
                "metrs": 1
            },
            {
                "size": "100х55х1,5",
                "koef": "0.001807",
                "metrs": 1
            },
            {
                "size": "100х55х1,8",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": "100х55х2",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "100х55х2,5",
                "koef": "0.002993",
                "metrs": 1
            },
            {
                "size": "100х55х3",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "100х55х3,5",
                "koef": "0.004162",
                "metrs": 1
            },
            {
                "size": "100х55х4",
                "koef": "0.004741",
                "metrs": 1
            },
            {
                "size": "100х55х5",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": "100х55х6",
                "koef": "0.007018",
                "metrs": 1
            },
            {
                "size": "100х60х1",
                "koef": "0.001248",
                "metrs": 1
            },
            {
                "size": "100х60х1,2",
                "koef": "0.001496",
                "metrs": 1
            },
            {
                "size": "100х60х1,5",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": "100х60х1,8",
                "koef": "0.002235",
                "metrs": 1
            },
            {
                "size": "100х60х2",
                "koef": "0.002481",
                "metrs": 1
            },
            {
                "size": "100х60х2,5",
                "koef": "0.003091",
                "metrs": 1
            },
            {
                "size": "100х60х3",
                "koef": "0.003697",
                "metrs": 1
            },
            {
                "size": "100х60х3,5",
                "koef": "0.0043",
                "metrs": 1
            },
            {
                "size": "100х60х4",
                "koef": "0.004898",
                "metrs": 1
            },
            {
                "size": "100х60х5",
                "koef": "0.006084",
                "metrs": 1
            },
            {
                "size": "100х60х6",
                "koef": "0.007253",
                "metrs": 1
            },
            {
                "size": "100х63х2",
                "koef": "0.002528",
                "metrs": 1
            },
            {
                "size": "100х63х3",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": "100х63х4",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "100х63х5",
                "koef": "0.006202",
                "metrs": 1
            },
            {
                "size": "100х63х6",
                "koef": "0.007395",
                "metrs": 1
            },
            {
                "size": "100х63х7",
                "koef": "0.008572",
                "metrs": 1
            },
            {
                "size": "100х63х8",
                "koef": "0.009734",
                "metrs": 1
            },
            {
                "size": "100х63х10",
                "koef": "0.01201",
                "metrs": 1
            },
            {
                "size": "100х65х1",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": "100х65х1,2",
                "koef": "0.001543",
                "metrs": 1
            },
            {
                "size": "100х65х1,5",
                "koef": "0.001925",
                "metrs": 1
            },
            {
                "size": "100х65х1,8",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": "100х65х2",
                "koef": "0.002559",
                "metrs": 1
            },
            {
                "size": "100х65х2,5",
                "koef": "0.003189",
                "metrs": 1
            },
            {
                "size": "100х65х3",
                "koef": "0.003815",
                "metrs": 1
            },
            {
                "size": "100х65х3,5",
                "koef": "0.004437",
                "metrs": 1
            },
            {
                "size": "100х65х4",
                "koef": "0.00495",
                "metrs": 1
            },
            {
                "size": "100х65х5",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": "100х65х6",
                "koef": "0.007489",
                "metrs": 1
            },
            {
                "size": "100х65х8",
                "koef": "0.00986",
                "metrs": 1
            },
            {
                "size": "100х65х10",
                "koef": "0.01217",
                "metrs": 1
            },
            {
                "size": "100х68х8",
                "koef": "0.01005",
                "metrs": 1
            },
            {
                "size": "100х70х1",
                "koef": "0.001327",
                "metrs": 1
            },
            {
                "size": "100х70х1,2",
                "koef": "0.00159",
                "metrs": 1
            },
            {
                "size": "100х70х1,5",
                "koef": "0.001984",
                "metrs": 1
            },
            {
                "size": "100х70х1,8",
                "koef": "0.002377",
                "metrs": 1
            },
            {
                "size": "100х70х2",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "100х70х2,5",
                "koef": "0.003287",
                "metrs": 1
            },
            {
                "size": "100х70х3",
                "koef": "0.003933",
                "metrs": 1
            },
            {
                "size": "100х70х3,5",
                "koef": "0.004575",
                "metrs": 1
            },
            {
                "size": "100х70х4",
                "koef": "0.005212",
                "metrs": 1
            },
            {
                "size": "100х70х5",
                "koef": "0.006476",
                "metrs": 1
            },
            {
                "size": "100х70х6",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "100х70х8",
                "koef": "0.01017",
                "metrs": 1
            },
            {
                "size": "100х70х10",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": "100х75х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": "100х75х1,2",
                "koef": "0.001637",
                "metrs": 1
            },
            {
                "size": "100х75х1,5",
                "koef": "0.002043",
                "metrs": 1
            },
            {
                "size": "100х75х1,8",
                "koef": "0.002447",
                "metrs": 1
            },
            {
                "size": "100х75х2",
                "koef": "0.002716",
                "metrs": 1
            },
            {
                "size": "100х75х2,5",
                "koef": "0.003385",
                "metrs": 1
            },
            {
                "size": "100х75х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "100х75х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": "100х75х4",
                "koef": "0.005369",
                "metrs": 1
            },
            {
                "size": "100х75х5",
                "koef": "0.006673",
                "metrs": 1
            },
            {
                "size": "100х75х6",
                "koef": "0.00796",
                "metrs": 1
            },
            {
                "size": "100х75х8",
                "koef": "0.01049",
                "metrs": 1
            },
            {
                "size": "100х75х10",
                "koef": "0.01295",
                "metrs": 1
            },
            {
                "size": "100х80х1",
                "koef": "0.001405",
                "metrs": 1
            },
            {
                "size": "100х80х1,2",
                "koef": "0.001684",
                "metrs": 1
            },
            {
                "size": "100х80х1,5",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": "100х80х1,8",
                "koef": "0.002518",
                "metrs": 1
            },
            {
                "size": "100х80х2",
                "koef": "0.002795",
                "metrs": 1
            },
            {
                "size": "100х80х2,5",
                "koef": "0.003483",
                "metrs": 1
            },
            {
                "size": "100х80х3",
                "koef": "0.004168",
                "metrs": 1
            },
            {
                "size": "100х80х3,5",
                "koef": "0.004849",
                "metrs": 1
            },
            {
                "size": "100х80х4",
                "koef": "0.005526",
                "metrs": 1
            },
            {
                "size": "100х80х5",
                "koef": "0.006869",
                "metrs": 1
            },
            {
                "size": "100х80х6",
                "koef": "0.008195",
                "metrs": 1
            },
            {
                "size": "100х80х8",
                "koef": "0.0108",
                "metrs": 1
            },
            {
                "size": "100х80х10",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": "100х85х1",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": "100х85х1,2",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": "100х85х1,5",
                "koef": "0.002161",
                "metrs": 1
            },
            {
                "size": "100х85х1,8",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": "100х85х2",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "100х85х2,5",
                "koef": "0.003582",
                "metrs": 1
            },
            {
                "size": "100х85х3",
                "koef": "0.004286",
                "metrs": 1
            },
            {
                "size": "100х85х3,5",
                "koef": "0.004987",
                "metrs": 1
            },
            {
                "size": "100х90х1",
                "koef": "0.001484",
                "metrs": 1
            },
            {
                "size": "100х90х1,2",
                "koef": "0.001778",
                "metrs": 1
            },
            {
                "size": "100х90х1,5",
                "koef": "0.00222",
                "metrs": 1
            },
            {
                "size": "100х90х1,8",
                "koef": "0.002659",
                "metrs": 1
            },
            {
                "size": "100х90х2",
                "koef": "0.002952",
                "metrs": 1
            },
            {
                "size": "100х90х2,5",
                "koef": "0.00368",
                "metrs": 1
            },
            {
                "size": "100х90х3",
                "koef": "0.004404",
                "metrs": 1
            },
            {
                "size": "100х90х3,5",
                "koef": "0.005124",
                "metrs": 1
            },
            {
                "size": "100х90х4",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": "100х90х5",
                "koef": "0.007261",
                "metrs": 1
            },
            {
                "size": "100х90х6",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "100х90х8",
                "koef": "0.01143",
                "metrs": 1
            },
            {
                "size": "100х90х10",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "100х95х1",
                "koef": "0.001523",
                "metrs": 1
            },
            {
                "size": "100х95х1,2",
                "koef": "0.001826",
                "metrs": 1
            },
            {
                "size": "100х95х1,5",
                "koef": "0.002278",
                "metrs": 1
            },
            {
                "size": "100х95х1,8",
                "koef": "0.00273",
                "metrs": 1
            },
            {
                "size": "100х95х2",
                "koef": "0.00303",
                "metrs": 1
            },
            {
                "size": "100х95х2,5",
                "koef": "0.003778",
                "metrs": 1
            },
            {
                "size": "100х95х3",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "100х95х3,5",
                "koef": "0.005261",
                "metrs": 1
            },
            {
                "size": "100х100х1",
                "koef": "0.001562",
                "metrs": 1
            },
            {
                "size": "100х100х1,2",
                "koef": "0.001873",
                "metrs": 1
            },
            {
                "size": "100х100х1,5",
                "koef": "0.002337",
                "metrs": 1
            },
            {
                "size": "100х100х1,8",
                "koef": "0.002801",
                "metrs": 1
            },
            {
                "size": "100х100х2",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "100х100х2,5",
                "koef": "0.003876",
                "metrs": 1
            },
            {
                "size": "100х100х3",
                "koef": "0.004639",
                "metrs": 1
            },
            {
                "size": "100х100х3,5",
                "koef": "0.005399",
                "metrs": 1
            },
            {
                "size": "100х100х4",
                "koef": "0.00605",
                "metrs": 1
            },
            {
                "size": "100х100х5",
                "koef": "0.00749",
                "metrs": 1
            },
            {
                "size": "100х100х6",
                "koef": "0.00889",
                "metrs": 1
            },
            {
                "size": "100х100х6,5",
                "koef": "0.009873",
                "metrs": 1
            },
            {
                "size": "100х100х7",
                "koef": "0.01031",
                "metrs": 1
            },
            {
                "size": "100х100х8",
                "koef": "0.01206",
                "metrs": 1
            },
            {
                "size": "100х100х10",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": "100х100х12",
                "koef": "0.01771",
                "metrs": 1
            },
            {
                "size": "100х100х14",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "100х100х16",
                "koef": "0.02311",
                "metrs": 1
            },
            {
                "size": "105х100х3",
                "koef": "0.00469",
                "metrs": 1
            },
            {
                "size": "110х60х2",
                "koef": "0.002638",
                "metrs": 1
            },
            {
                "size": "110х60х3",
                "koef": "0.003933",
                "metrs": 1
            },
            {
                "size": "110х60х4",
                "koef": "0.005212",
                "metrs": 1
            },
            {
                "size": "110х60х5",
                "koef": "0.006476",
                "metrs": 1
            },
            {
                "size": "110х60х6",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "110х63х2",
                "koef": "0.002685",
                "metrs": 1
            },
            {
                "size": "110х63х3",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": "110х63х4",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "110х63х5",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": "110х63х6",
                "koef": "0.007866",
                "metrs": 1
            },
            {
                "size": "110х65х2",
                "koef": "0.002716",
                "metrs": 1
            },
            {
                "size": "110х65х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "110х65х4",
                "koef": "0.005369",
                "metrs": 1
            },
            {
                "size": "110х65х5",
                "koef": "0.006673",
                "metrs": 1
            },
            {
                "size": "110х65х6",
                "koef": "0.00796",
                "metrs": 1
            },
            {
                "size": "110х68х8",
                "koef": "0.01068",
                "metrs": 1
            },
            {
                "size": "110х70х2",
                "koef": "0.002795",
                "metrs": 1
            },
            {
                "size": "110х70х3",
                "koef": "0.004168",
                "metrs": 1
            },
            {
                "size": "110х70х4",
                "koef": "0.005526",
                "metrs": 1
            },
            {
                "size": "110х70х5",
                "koef": "0.006869",
                "metrs": 1
            },
            {
                "size": "110х70х6",
                "koef": "0.008195",
                "metrs": 1
            },
            {
                "size": "110х70х6,5",
                "koef": "0.008853",
                "metrs": 1
            },
            {
                "size": "110х70х8",
                "koef": "0.0108",
                "metrs": 1
            },
            {
                "size": "110х70х10",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": "110х75х2",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "110х75х3",
                "koef": "0.004286",
                "metrs": 1
            },
            {
                "size": "110х75х4",
                "koef": "0.005683",
                "metrs": 1
            },
            {
                "size": "110х75х5",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": "110х75х6",
                "koef": "0.008431",
                "metrs": 1
            },
            {
                "size": "110х75х8",
                "koef": "0.01112",
                "metrs": 1
            },
            {
                "size": "110х75х10",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": "110х80х2",
                "koef": "0.002952",
                "metrs": 1
            },
            {
                "size": "110х80х3",
                "koef": "0.004404",
                "metrs": 1
            },
            {
                "size": "110х80х4",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": "110х80х5",
                "koef": "0.007261",
                "metrs": 1
            },
            {
                "size": "110х80х6",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "110х80х8",
                "koef": "0.01143",
                "metrs": 1
            },
            {
                "size": "110х80х10",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "110х90х2",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "110х90х3",
                "koef": "0.004639",
                "metrs": 1
            },
            {
                "size": "110х90х4",
                "koef": "0.006154",
                "metrs": 1
            },
            {
                "size": "110х90х5",
                "koef": "0.00744",
                "metrs": 1
            },
            {
                "size": "110х90х6",
                "koef": "0.009137",
                "metrs": 1
            },
            {
                "size": "110х90х8",
                "koef": "0.01206",
                "metrs": 1
            },
            {
                "size": "110х90х10",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": "110х100х2",
                "koef": "0.003266",
                "metrs": 1
            },
            {
                "size": "110х100х3",
                "koef": "0.004875",
                "metrs": 1
            },
            {
                "size": "110х100х4",
                "koef": "0.006468",
                "metrs": 1
            },
            {
                "size": "110х100х5",
                "koef": "0.008046",
                "metrs": 1
            },
            {
                "size": "110х100х6",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "110х100х8",
                "koef": "0.01269",
                "metrs": 1
            },
            {
                "size": "110х100х10",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": "110х110х2",
                "koef": "0.003423",
                "metrs": 1
            },
            {
                "size": "110х110х3",
                "koef": "0.00511",
                "metrs": 1
            },
            {
                "size": "110х110х4",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "110х110х5",
                "koef": "0.008439",
                "metrs": 1
            },
            {
                "size": "110х110х6",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": "110х110х7",
                "koef": "0.0117",
                "metrs": 1
            },
            {
                "size": "110х110х8",
                "koef": "0.01331",
                "metrs": 1
            },
            {
                "size": "115х65х5",
                "koef": "0.00671",
                "metrs": 1
            },
            {
                "size": "115х80х3",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "115х80х4",
                "koef": "0.005997",
                "metrs": 1
            },
            {
                "size": "115х115х3",
                "koef": "0.005346",
                "metrs": 1
            },
            {
                "size": "115х115х4",
                "koef": "0.007096",
                "metrs": 1
            },
            {
                "size": "120х60х2",
                "koef": "0.002795",
                "metrs": 1
            },
            {
                "size": "120х60х3",
                "koef": "0.004168",
                "metrs": 1
            },
            {
                "size": "120х60х4",
                "koef": "0.005526",
                "metrs": 1
            },
            {
                "size": "120х60х5",
                "koef": "0.006869",
                "metrs": 1
            },
            {
                "size": "120х60х6",
                "koef": "0.008195",
                "metrs": 1
            },
            {
                "size": "120х63х2",
                "koef": "0.002842",
                "metrs": 1
            },
            {
                "size": "120х63х3",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": "120х63х4",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "120х63х5",
                "koef": "0.006987",
                "metrs": 1
            },
            {
                "size": "120х63х6",
                "koef": "0.008337",
                "metrs": 1
            },
            {
                "size": "120х65х2",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "120х65х3",
                "koef": "0.004286",
                "metrs": 1
            },
            {
                "size": "120х65х4",
                "koef": "0.005683",
                "metrs": 1
            },
            {
                "size": "120х65х5",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": "120х65х6",
                "koef": "0.008431",
                "metrs": 1
            },
            {
                "size": "120х65х8",
                "koef": "0.01112",
                "metrs": 1
            },
            {
                "size": "120х65х10",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": "120х70х2",
                "koef": "0.002952",
                "metrs": 1
            },
            {
                "size": "120х70х3",
                "koef": "0.004404",
                "metrs": 1
            },
            {
                "size": "120х70х4",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": "120х70х5",
                "koef": "0.007261",
                "metrs": 1
            },
            {
                "size": "120х70х6",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "120х70х8",
                "koef": "0.01143",
                "metrs": 1
            },
            {
                "size": "120х70х10",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "120х75х2",
                "koef": "0.00303",
                "metrs": 1
            },
            {
                "size": "120х75х3",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "120х75х4",
                "koef": "0.005997",
                "metrs": 1
            },
            {
                "size": "120х75х5",
                "koef": "0.007458",
                "metrs": 1
            },
            {
                "size": "120х75х6",
                "koef": "0.008902",
                "metrs": 1
            },
            {
                "size": "120х75х8",
                "koef": "0.01174",
                "metrs": 1
            },
            {
                "size": "120х75х10",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": "120х80х2",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "120х80х3",
                "koef": "0.004639",
                "metrs": 1
            },
            {
                "size": "120х80х4",
                "koef": "0.006154",
                "metrs": 1
            },
            {
                "size": "120х80х5",
                "koef": "0.007654",
                "metrs": 1
            },
            {
                "size": "120х80х6",
                "koef": "0.009137",
                "metrs": 1
            },
            {
                "size": "120х80х8",
                "koef": "0.01206",
                "metrs": 1
            },
            {
                "size": "120х80х10",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": "120х90х3",
                "koef": "0.004875",
                "metrs": 1
            },
            {
                "size": "120х90х4",
                "koef": "0.006468",
                "metrs": 1
            },
            {
                "size": "120х90х5",
                "koef": "0.008046",
                "metrs": 1
            },
            {
                "size": "120х90х6",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "120х90х8",
                "koef": "0.01269",
                "metrs": 1
            },
            {
                "size": "120х90х10",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": "120х100х2",
                "koef": "0.003423",
                "metrs": 1
            },
            {
                "size": "120х100х3",
                "koef": "0.00511",
                "metrs": 1
            },
            {
                "size": "120х100х4",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "120х100х5",
                "koef": "0.008439",
                "metrs": 1
            },
            {
                "size": "120х100х6",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": "120х100х8",
                "koef": "0.01288",
                "metrs": 1
            },
            {
                "size": "120х100х10",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": "120х110х2",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "120х110х3",
                "koef": "0.005346",
                "metrs": 1
            },
            {
                "size": "120х110х4",
                "koef": "0.007096",
                "metrs": 1
            },
            {
                "size": "120х110х5",
                "koef": "0.008831",
                "metrs": 1
            },
            {
                "size": "120х110х6",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "120х110х8",
                "koef": "0.01394",
                "metrs": 1
            },
            {
                "size": "120х110х10",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": "120х120х2",
                "koef": "0.003737",
                "metrs": 1
            },
            {
                "size": "120х120х3",
                "koef": "0.005581",
                "metrs": 1
            },
            {
                "size": "120х120х4",
                "koef": "0.00741",
                "metrs": 1
            },
            {
                "size": "120х120х5",
                "koef": "0.00906",
                "metrs": 1
            },
            {
                "size": "120х120х6",
                "koef": "0.01078",
                "metrs": 1
            },
            {
                "size": "120х120х8",
                "koef": "0.01457",
                "metrs": 1
            },
            {
                "size": "120х120х10",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": "125х60х2",
                "koef": "0.002873",
                "metrs": 1
            },
            {
                "size": "125х60х3",
                "koef": "0.004286",
                "metrs": 1
            },
            {
                "size": "125х60х4",
                "koef": "0.005683",
                "metrs": 1
            },
            {
                "size": "125х63х2",
                "koef": "0.00292",
                "metrs": 1
            },
            {
                "size": "125х63х3",
                "koef": "0.004357",
                "metrs": 1
            },
            {
                "size": "125х63х4",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "125х63х5",
                "koef": "0.007183",
                "metrs": 1
            },
            {
                "size": "125х63х6",
                "koef": "0.008572",
                "metrs": 1
            },
            {
                "size": "125х65х2",
                "koef": "0.002952",
                "metrs": 1
            },
            {
                "size": "125х65х3",
                "koef": "0.004404",
                "metrs": 1
            },
            {
                "size": "125х65х4",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": "125х65х5",
                "koef": "0.007261",
                "metrs": 1
            },
            {
                "size": "125х65х6",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "125х65х8",
                "koef": "0.01143",
                "metrs": 1
            },
            {
                "size": "125х65х10",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "125х70х2",
                "koef": "0.00303",
                "metrs": 1
            },
            {
                "size": "125х70х3",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "125х70х4",
                "koef": "0.005997",
                "metrs": 1
            },
            {
                "size": "125х70х5",
                "koef": "0.007458",
                "metrs": 1
            },
            {
                "size": "125х70х6",
                "koef": "0.008902",
                "metrs": 1
            },
            {
                "size": "125х70х8",
                "koef": "0.01174",
                "metrs": 1
            },
            {
                "size": "125х70х10",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": "125х75х2",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "125х75х3",
                "koef": "0.004639",
                "metrs": 1
            },
            {
                "size": "125х75х4",
                "koef": "0.006154",
                "metrs": 1
            },
            {
                "size": "125х75х5",
                "koef": "0.007654",
                "metrs": 1
            },
            {
                "size": "125х75х6",
                "koef": "0.009137",
                "metrs": 1
            },
            {
                "size": "125х75х8",
                "koef": "0.01206",
                "metrs": 1
            },
            {
                "size": "125х75х10",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": "125х80х2",
                "koef": "0.003187",
                "metrs": 1
            },
            {
                "size": "125х80х3",
                "koef": "0.004757",
                "metrs": 1
            },
            {
                "size": "125х80х4",
                "koef": "0.006311",
                "metrs": 1
            },
            {
                "size": "125х80х5",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": "125х80х6",
                "koef": "0.009373",
                "metrs": 1
            },
            {
                "size": "125х80х7",
                "koef": "0.01088",
                "metrs": 1
            },
            {
                "size": "125х80х8",
                "koef": "0.01237",
                "metrs": 1
            },
            {
                "size": "125х80х10",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": "125х80х12",
                "koef": "0.01818",
                "metrs": 1
            },
            {
                "size": "125х90х2",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": "125х90х3",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "125х90х4",
                "koef": "0.006625",
                "metrs": 1
            },
            {
                "size": "125х90х5",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": "125х90х6",
                "koef": "0.009844",
                "metrs": 1
            },
            {
                "size": "125х90х8",
                "koef": "0.013",
                "metrs": 1
            },
            {
                "size": "125х90х10",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": "125х100х2",
                "koef": "0.003501",
                "metrs": 1
            },
            {
                "size": "125х100х3",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": "125х100х4",
                "koef": "0.006939",
                "metrs": 1
            },
            {
                "size": "125х100х5",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": "125х100х6",
                "koef": "0.01031",
                "metrs": 1
            },
            {
                "size": "125х100х8",
                "koef": "0.01363",
                "metrs": 1
            },
            {
                "size": "125х100х10",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": "125х110х2",
                "koef": "0.003658",
                "metrs": 1
            },
            {
                "size": "125х110х3",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "125х110х4",
                "koef": "0.007253",
                "metrs": 1
            },
            {
                "size": "125х110х5",
                "koef": "0.009027",
                "metrs": 1
            },
            {
                "size": "125х110х6",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "125х110х8",
                "koef": "0.01426",
                "metrs": 1
            },
            {
                "size": "125х110х10",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": "125х120х2",
                "koef": "0.003815",
                "metrs": 1
            },
            {
                "size": "125х120х3",
                "koef": "0.005699",
                "metrs": 1
            },
            {
                "size": "125х120х4",
                "koef": "0.007567",
                "metrs": 1
            },
            {
                "size": "125х120х5",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "125х120х6",
                "koef": "0.01126",
                "metrs": 1
            },
            {
                "size": "125х120х8",
                "koef": "0.01488",
                "metrs": 1
            },
            {
                "size": "125х120х10",
                "koef": "0.01845",
                "metrs": 1
            },
            {
                "size": "125х125х2",
                "koef": "0.003894",
                "metrs": 1
            },
            {
                "size": "125х125х3",
                "koef": "0.005817",
                "metrs": 1
            },
            {
                "size": "125х125х4",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "125х125х5",
                "koef": "0.009616",
                "metrs": 1
            },
            {
                "size": "125х125х6",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "125х125х8",
                "koef": "0.0152",
                "metrs": 1
            },
            {
                "size": "125х125х9",
                "koef": "0.01703",
                "metrs": 1
            },
            {
                "size": "125х125х10",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "125х125х12",
                "koef": "0.02242",
                "metrs": 1
            },
            {
                "size": "125х125х14",
                "koef": "0.02594",
                "metrs": 1
            },
            {
                "size": "125х125х16",
                "koef": "0.02939",
                "metrs": 1
            },
            {
                "size": "130х60х2",
                "koef": "0.002952",
                "metrs": 1
            },
            {
                "size": "130х60х3",
                "koef": "0.004404",
                "metrs": 1
            },
            {
                "size": "130х60х4",
                "koef": "0.00584",
                "metrs": 1
            },
            {
                "size": "130х60х5",
                "koef": "0.007261",
                "metrs": 1
            },
            {
                "size": "130х60х6",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "130х63х2",
                "koef": "0.002999",
                "metrs": 1
            },
            {
                "size": "130х63х3",
                "koef": "0.004474",
                "metrs": 1
            },
            {
                "size": "130х63х4",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "130х63х5",
                "koef": "0.007379",
                "metrs": 1
            },
            {
                "size": "130х63х6",
                "koef": "0.008808",
                "metrs": 1
            },
            {
                "size": "130х65х2",
                "koef": "0.00303",
                "metrs": 1
            },
            {
                "size": "130х65х3",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "130х65х4",
                "koef": "0.005997",
                "metrs": 1
            },
            {
                "size": "130х65х5",
                "koef": "0.007458",
                "metrs": 1
            },
            {
                "size": "130х65х6",
                "koef": "0.008902",
                "metrs": 1
            },
            {
                "size": "130х65х10",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": "130х68х8",
                "koef": "0.01193",
                "metrs": 1
            },
            {
                "size": "130х70х2",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "130х70х3",
                "koef": "0.004639",
                "metrs": 1
            },
            {
                "size": "130х70х4",
                "koef": "0.006154",
                "metrs": 1
            },
            {
                "size": "130х70х5",
                "koef": "0.007654",
                "metrs": 1
            },
            {
                "size": "130х70х6",
                "koef": "0.009137",
                "metrs": 1
            },
            {
                "size": "130х70х8",
                "koef": "0.01206",
                "metrs": 1
            },
            {
                "size": "130х70х10",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": "130х75х2",
                "koef": "0.003187",
                "metrs": 1
            },
            {
                "size": "130х75х3",
                "koef": "0.004757",
                "metrs": 1
            },
            {
                "size": "130х75х4",
                "koef": "0.006311",
                "metrs": 1
            },
            {
                "size": "130х75х5",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": "130х75х6",
                "koef": "0.009373",
                "metrs": 1
            },
            {
                "size": "130х75х8",
                "koef": "0.01237",
                "metrs": 1
            },
            {
                "size": "130х75х10",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": "130х80х2",
                "koef": "0.003266",
                "metrs": 1
            },
            {
                "size": "130х80х3",
                "koef": "0.004875",
                "metrs": 1
            },
            {
                "size": "130х80х4",
                "koef": "0.006468",
                "metrs": 1
            },
            {
                "size": "130х80х5",
                "koef": "0.008046",
                "metrs": 1
            },
            {
                "size": "130х80х6",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "130х80х8",
                "koef": "0.01269",
                "metrs": 1
            },
            {
                "size": "130х80х10",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": "130х90х2",
                "koef": "0.003423",
                "metrs": 1
            },
            {
                "size": "130х90х3",
                "koef": "0.00511",
                "metrs": 1
            },
            {
                "size": "130х90х4",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "130х90х5",
                "koef": "0.008439",
                "metrs": 1
            },
            {
                "size": "130х90х6",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": "130х90х8",
                "koef": "0.01331",
                "metrs": 1
            },
            {
                "size": "130х90х10",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": "130х100х2",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "130х100х3",
                "koef": "0.005346",
                "metrs": 1
            },
            {
                "size": "130х100х4",
                "koef": "0.007096",
                "metrs": 1
            },
            {
                "size": "130х100х5",
                "koef": "0.008831",
                "metrs": 1
            },
            {
                "size": "130х100х6",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "130х100х8",
                "koef": "0.01394",
                "metrs": 1
            },
            {
                "size": "130х100х10",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": "130х110х2",
                "koef": "0.003737",
                "metrs": 1
            },
            {
                "size": "130х110х3",
                "koef": "0.005581",
                "metrs": 1
            },
            {
                "size": "130х110х4",
                "koef": "0.00741",
                "metrs": 1
            },
            {
                "size": "130х110х5",
                "koef": "0.009224",
                "metrs": 1
            },
            {
                "size": "130х110х6",
                "koef": "0.01102",
                "metrs": 1
            },
            {
                "size": "130х110х8",
                "koef": "0.01457",
                "metrs": 1
            },
            {
                "size": "130х110х10",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": "130х120х2",
                "koef": "0.003894",
                "metrs": 1
            },
            {
                "size": "130х120х3",
                "koef": "0.005817",
                "metrs": 1
            },
            {
                "size": "130х120х4",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "130х120х5",
                "koef": "0.009616",
                "metrs": 1
            },
            {
                "size": "130х120х6",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "130х120х8",
                "koef": "0.0152",
                "metrs": 1
            },
            {
                "size": "130х120х10",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "130х130х2",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "130х130х3",
                "koef": "0.006052",
                "metrs": 1
            },
            {
                "size": "130х130х4",
                "koef": "0.008038",
                "metrs": 1
            },
            {
                "size": "130х130х5",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "130х130х6",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "130х130х8",
                "koef": "0.01583",
                "metrs": 1
            },
            {
                "size": "130х130х10",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": "140х60х2",
                "koef": "0.003109",
                "metrs": 1
            },
            {
                "size": "140х60х3",
                "koef": "0.004639",
                "metrs": 1
            },
            {
                "size": "140х60х4",
                "koef": "0.006154",
                "metrs": 1
            },
            {
                "size": "140х60х5",
                "koef": "0.007654",
                "metrs": 1
            },
            {
                "size": "140х60х6",
                "koef": "0.009137",
                "metrs": 1
            },
            {
                "size": "140х63х2",
                "koef": "0.003156",
                "metrs": 1
            },
            {
                "size": "140х63х3",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": "140х63х4",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "140х63х5",
                "koef": "0.007772",
                "metrs": 1
            },
            {
                "size": "140х63х6",
                "koef": "0.009279",
                "metrs": 1
            },
            {
                "size": "140х65х2",
                "koef": "0.003187",
                "metrs": 1
            },
            {
                "size": "140х65х3",
                "koef": "0.004757",
                "metrs": 1
            },
            {
                "size": "140х65х4",
                "koef": "0.006311",
                "metrs": 1
            },
            {
                "size": "140х65х5",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": "140х65х6",
                "koef": "0.009373",
                "metrs": 1
            },
            {
                "size": "140х65х8",
                "koef": "0.01237",
                "metrs": 1
            },
            {
                "size": "140х65х10",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": "140х70х2",
                "koef": "0.003266",
                "metrs": 1
            },
            {
                "size": "140х70х3",
                "koef": "0.004875",
                "metrs": 1
            },
            {
                "size": "140х70х4",
                "koef": "0.006468",
                "metrs": 1
            },
            {
                "size": "140х70х5",
                "koef": "0.008046",
                "metrs": 1
            },
            {
                "size": "140х70х6",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "140х70х8",
                "koef": "0.01269",
                "metrs": 1
            },
            {
                "size": "140х70х10",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": "140х75х2",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": "140х75х3",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "140х75х4",
                "koef": "0.006625",
                "metrs": 1
            },
            {
                "size": "140х75х5",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": "140х75х6",
                "koef": "0.009844",
                "metrs": 1
            },
            {
                "size": "140х75х8",
                "koef": "0.013",
                "metrs": 1
            },
            {
                "size": "140х75х10",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": "140х80х2",
                "koef": "0.003423",
                "metrs": 1
            },
            {
                "size": "140х80х3",
                "koef": "0.00511",
                "metrs": 1
            },
            {
                "size": "140х80х4",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "140х80х5",
                "koef": "0.008439",
                "metrs": 1
            },
            {
                "size": "140х80х6",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": "140х80х8",
                "koef": "0.01331",
                "metrs": 1
            },
            {
                "size": "140х80х10",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": "140х90х2",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "140х90х3",
                "koef": "0.005346",
                "metrs": 1
            },
            {
                "size": "140х90х4",
                "koef": "0.007096",
                "metrs": 1
            },
            {
                "size": "140х90х5",
                "koef": "0.008831",
                "metrs": 1
            },
            {
                "size": "140х90х6",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "140х90х8",
                "koef": "0.01394",
                "metrs": 1
            },
            {
                "size": "140х90х10",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": "140х100х2",
                "koef": "0.003737",
                "metrs": 1
            },
            {
                "size": "140х100х3",
                "koef": "0.005581",
                "metrs": 1
            },
            {
                "size": "140х100х4",
                "koef": "0.00741",
                "metrs": 1
            },
            {
                "size": "140х100х5",
                "koef": "0.009224",
                "metrs": 1
            },
            {
                "size": "140х100х6",
                "koef": "0.01102",
                "metrs": 1
            },
            {
                "size": "140х100х8",
                "koef": "0.01457",
                "metrs": 1
            },
            {
                "size": "140х100х10",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": "140х110х2",
                "koef": "0.003894",
                "metrs": 1
            },
            {
                "size": "140х110х3",
                "koef": "0.005817",
                "metrs": 1
            },
            {
                "size": "140х110х4",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "140х110х5",
                "koef": "0.009616",
                "metrs": 1
            },
            {
                "size": "140х110х6",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "140х110х8",
                "koef": "0.0152",
                "metrs": 1
            },
            {
                "size": "140х110х10",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "140х120х2",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "140х120х3",
                "koef": "0.006052",
                "metrs": 1
            },
            {
                "size": "140х120х4",
                "koef": "0.008038",
                "metrs": 1
            },
            {
                "size": "140х120х5",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "140х120х6",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "140х120х8",
                "koef": "0.01583",
                "metrs": 1
            },
            {
                "size": "140х120х10",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": "140х130х2",
                "koef": "0.004208",
                "metrs": 1
            },
            {
                "size": "140х130х3",
                "koef": "0.006288",
                "metrs": 1
            },
            {
                "size": "140х130х4",
                "koef": "0.008352",
                "metrs": 1
            },
            {
                "size": "140х130х5",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "140х130х6",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "140х130х8",
                "koef": "0.01645",
                "metrs": 1
            },
            {
                "size": "140х130х10",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": "140х140х2",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "140х140х3",
                "koef": "0.006523",
                "metrs": 1
            },
            {
                "size": "140х140х4",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "140х140х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "140х140х6",
                "koef": "0.01291",
                "metrs": 1
            },
            {
                "size": "140х140х8",
                "koef": "0.01708",
                "metrs": 1
            },
            {
                "size": "140х140х9",
                "koef": "0.01915",
                "metrs": 1
            },
            {
                "size": "140х140х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "140х140х12",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "147х125х8",
                "koef": "0.01593",
                "metrs": 1
            },
            {
                "size": "150х50х3",
                "koef": "0.004639",
                "metrs": 1
            },
            {
                "size": "150х50х4",
                "koef": "0.006154",
                "metrs": 1
            },
            {
                "size": "150х50х5",
                "koef": "0.007654",
                "metrs": 1
            },
            {
                "size": "150х50х6",
                "koef": "0.009137",
                "metrs": 1
            },
            {
                "size": "150х60х2",
                "koef": "0.003266",
                "metrs": 1
            },
            {
                "size": "150х60х3",
                "koef": "0.004875",
                "metrs": 1
            },
            {
                "size": "150х60х4",
                "koef": "0.006468",
                "metrs": 1
            },
            {
                "size": "150х60х5",
                "koef": "0.008046",
                "metrs": 1
            },
            {
                "size": "150х60х6",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "150х63х2",
                "koef": "0.003313",
                "metrs": 1
            },
            {
                "size": "150х63х3",
                "koef": "0.004946",
                "metrs": 1
            },
            {
                "size": "150х63х4",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "150х63х5",
                "koef": "0.008164",
                "metrs": 1
            },
            {
                "size": "150х63х6",
                "koef": "0.00975",
                "metrs": 1
            },
            {
                "size": "150х65х2",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": "150х65х3",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "150х65х4",
                "koef": "0.006625",
                "metrs": 1
            },
            {
                "size": "150х65х5",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": "150х65х6",
                "koef": "0.009844",
                "metrs": 1
            },
            {
                "size": "150х65х8",
                "koef": "0.013",
                "metrs": 1
            },
            {
                "size": "150х65х10",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": "150х70х2",
                "koef": "0.003423",
                "metrs": 1
            },
            {
                "size": "150х70х3",
                "koef": "0.00511",
                "metrs": 1
            },
            {
                "size": "150х70х4",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "150х70х5",
                "koef": "0.008439",
                "metrs": 1
            },
            {
                "size": "150х70х6",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": "150х70х8",
                "koef": "0.01331",
                "metrs": 1
            },
            {
                "size": "150х70х10",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": "150х75х2",
                "koef": "0.003501",
                "metrs": 1
            },
            {
                "size": "150х75х3",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": "150х75х4",
                "koef": "0.006939",
                "metrs": 1
            },
            {
                "size": "150х75х5",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": "150х75х6",
                "koef": "0.01031",
                "metrs": 1
            },
            {
                "size": "150х75х8",
                "koef": "0.01363",
                "metrs": 1
            },
            {
                "size": "150х75х10",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": "150х80х2",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "150х80х3",
                "koef": "0.005346",
                "metrs": 1
            },
            {
                "size": "150х80х4",
                "koef": "0.007096",
                "metrs": 1
            },
            {
                "size": "150х80х5",
                "koef": "0.008831",
                "metrs": 1
            },
            {
                "size": "150х80х6",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "150х80х8",
                "koef": "0.01394",
                "metrs": 1
            },
            {
                "size": "150х80х10",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": "150х90х2",
                "koef": "0.003737",
                "metrs": 1
            },
            {
                "size": "150х90х3",
                "koef": "0.005581",
                "metrs": 1
            },
            {
                "size": "150х90х4",
                "koef": "0.00741",
                "metrs": 1
            },
            {
                "size": "150х90х5",
                "koef": "0.009224",
                "metrs": 1
            },
            {
                "size": "150х90х6",
                "koef": "0.01102",
                "metrs": 1
            },
            {
                "size": "150х90х8",
                "koef": "0.01457",
                "metrs": 1
            },
            {
                "size": "150х90х10",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": "150х100х2",
                "koef": "0.003894",
                "metrs": 1
            },
            {
                "size": "150х100х3",
                "koef": "0.005817",
                "metrs": 1
            },
            {
                "size": "150х100х4",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "150х100х5",
                "koef": "0.009616",
                "metrs": 1
            },
            {
                "size": "150х100х6",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "150х100х8",
                "koef": "0.0152",
                "metrs": 1
            },
            {
                "size": "150х100х10",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "150х110х2",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "150х110х3",
                "koef": "0.006052",
                "metrs": 1
            },
            {
                "size": "150х110х4",
                "koef": "0.008038",
                "metrs": 1
            },
            {
                "size": "150х110х5",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "150х110х6",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "150х110х8",
                "koef": "0.01583",
                "metrs": 1
            },
            {
                "size": "150х110х10",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": "150х120х2",
                "koef": "0.004208",
                "metrs": 1
            },
            {
                "size": "150х120х3",
                "koef": "0.006288",
                "metrs": 1
            },
            {
                "size": "150х120х4",
                "koef": "0.008352",
                "metrs": 1
            },
            {
                "size": "150х120х5",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "150х120х6",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "150х120х8",
                "koef": "0.01645",
                "metrs": 1
            },
            {
                "size": "150х120х10",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": "150х130х2",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "150х130х3",
                "koef": "0.006523",
                "metrs": 1
            },
            {
                "size": "150х130х4",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "150х130х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "150х130х6",
                "koef": "0.01291",
                "metrs": 1
            },
            {
                "size": "150х130х8",
                "koef": "0.01708",
                "metrs": 1
            },
            {
                "size": "150х130х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "150х140х2",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "150х140х3",
                "koef": "0.006759",
                "metrs": 1
            },
            {
                "size": "150х140х4",
                "koef": "0.00898",
                "metrs": 1
            },
            {
                "size": "150х140х5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "150х140х6",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "150х140х8",
                "koef": "0.01771",
                "metrs": 1
            },
            {
                "size": "150х140х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "150х150х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "150х150х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "150х150х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "150х150х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "150х150х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "150х150х8",
                "koef": "0.01834",
                "metrs": 1
            },
            {
                "size": "150х150х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "152х100х5,5",
                "koef": "0.01037",
                "metrs": 1
            },
            {
                "size": "155х100х6",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": "160х60х2",
                "koef": "0.003423",
                "metrs": 1
            },
            {
                "size": "160х60х3",
                "koef": "0.00511",
                "metrs": 1
            },
            {
                "size": "160х60х4",
                "koef": "0.006782",
                "metrs": 1
            },
            {
                "size": "160х60х5",
                "koef": "0.008439",
                "metrs": 1
            },
            {
                "size": "160х60х6",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": "160х63х2",
                "koef": "0.00347",
                "metrs": 1
            },
            {
                "size": "160х63х3",
                "koef": "0.005181",
                "metrs": 1
            },
            {
                "size": "160х63х4",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "160х63х5",
                "koef": "0.008557",
                "metrs": 1
            },
            {
                "size": "160х63х6",
                "koef": "0.01022",
                "metrs": 1
            },
            {
                "size": "160х65х2",
                "koef": "0.003501",
                "metrs": 1
            },
            {
                "size": "160х65х3",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": "160х65х4",
                "koef": "0.006939",
                "metrs": 1
            },
            {
                "size": "160х65х5",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": "160х65х6",
                "koef": "0.01031",
                "metrs": 1
            },
            {
                "size": "160х65х8",
                "koef": "0.01363",
                "metrs": 1
            },
            {
                "size": "160х65х10",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": "160х70х2",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "160х70х3",
                "koef": "0.005346",
                "metrs": 1
            },
            {
                "size": "160х70х4",
                "koef": "0.007096",
                "metrs": 1
            },
            {
                "size": "160х70х5",
                "koef": "0.008831",
                "metrs": 1
            },
            {
                "size": "160х70х6",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "160х70х8",
                "koef": "0.01394",
                "metrs": 1
            },
            {
                "size": "160х70х10",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": "160х75х2",
                "koef": "0.003658",
                "metrs": 1
            },
            {
                "size": "160х75х3",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "160х75х4",
                "koef": "0.007253",
                "metrs": 1
            },
            {
                "size": "160х75х5",
                "koef": "0.009027",
                "metrs": 1
            },
            {
                "size": "160х75х6",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "160х75х8",
                "koef": "0.01426",
                "metrs": 1
            },
            {
                "size": "160х75х10",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": "160х80х2",
                "koef": "0.003737",
                "metrs": 1
            },
            {
                "size": "160х80х3",
                "koef": "0.005581",
                "metrs": 1
            },
            {
                "size": "160х80х4",
                "koef": "0.00741",
                "metrs": 1
            },
            {
                "size": "160х80х5",
                "koef": "0.009224",
                "metrs": 1
            },
            {
                "size": "160х80х6",
                "koef": "0.01102",
                "metrs": 1
            },
            {
                "size": "160х80х8",
                "koef": "0.01457",
                "metrs": 1
            },
            {
                "size": "160х80х10",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": "160х90х2",
                "koef": "0.003894",
                "metrs": 1
            },
            {
                "size": "160х90х3",
                "koef": "0.005817",
                "metrs": 1
            },
            {
                "size": "160х90х4",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "160х90х5",
                "koef": "0.009616",
                "metrs": 1
            },
            {
                "size": "160х90х6",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "160х90х8",
                "koef": "0.0152",
                "metrs": 1
            },
            {
                "size": "160х90х10",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "160х100х2",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "160х100х3",
                "koef": "0.006052",
                "metrs": 1
            },
            {
                "size": "160х100х4",
                "koef": "0.008038",
                "metrs": 1
            },
            {
                "size": "160х100х5",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "160х100х6",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "160х100х8",
                "koef": "0.01583",
                "metrs": 1
            },
            {
                "size": "160х100х9",
                "koef": "0.01773",
                "metrs": 1
            },
            {
                "size": "160х100х10",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": "160х100х12",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "160х100х14",
                "koef": "0.02704",
                "metrs": 1
            },
            {
                "size": "160х110х2",
                "koef": "0.004208",
                "metrs": 1
            },
            {
                "size": "160х110х3",
                "koef": "0.006288",
                "metrs": 1
            },
            {
                "size": "160х110х4",
                "koef": "0.008352",
                "metrs": 1
            },
            {
                "size": "160х110х5",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "160х110х6",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "160х110х8",
                "koef": "0.01645",
                "metrs": 1
            },
            {
                "size": "160х110х10",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": "160х120х2",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "160х120х3",
                "koef": "0.006523",
                "metrs": 1
            },
            {
                "size": "160х120х4",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "160х120х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "160х120х6",
                "koef": "0.01291",
                "metrs": 1
            },
            {
                "size": "160х120х8",
                "koef": "0.01708",
                "metrs": 1
            },
            {
                "size": "160х120х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "160х130х2",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "160х130х3",
                "koef": "0.006759",
                "metrs": 1
            },
            {
                "size": "160х130х4",
                "koef": "0.00898",
                "metrs": 1
            },
            {
                "size": "160х130х5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "160х130х6",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "160х130х8",
                "koef": "0.01771",
                "metrs": 1
            },
            {
                "size": "160х130х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "160х140х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "160х140х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "160х140х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "160х140х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "160х140х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "160х140х8",
                "koef": "0.01834",
                "metrs": 1
            },
            {
                "size": "160х140х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "160х150х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "160х150х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "160х150х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "160х150х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "160х150х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "160х150х8",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": "160х150х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "160х160х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "160х160х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "160х160х4",
                "koef": "0.00976",
                "metrs": 1
            },
            {
                "size": "160х160х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "160х160х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "160х160х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "160х160х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "160х160х11",
                "koef": "0.02668",
                "metrs": 1
            },
            {
                "size": "160х160х12",
                "koef": "0.02901",
                "metrs": 1
            },
            {
                "size": "160х160х14",
                "koef": "0.03363",
                "metrs": 1
            },
            {
                "size": "160х160х16",
                "koef": "0.03818",
                "metrs": 1
            },
            {
                "size": "170х60х2",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": "170х60х3",
                "koef": "0.005346",
                "metrs": 1
            },
            {
                "size": "170х60х4",
                "koef": "0.007096",
                "metrs": 1
            },
            {
                "size": "170х60х5",
                "koef": "0.008831",
                "metrs": 1
            },
            {
                "size": "170х60х6",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "170х63х2",
                "koef": "0.003627",
                "metrs": 1
            },
            {
                "size": "170х63х3",
                "koef": "0.005416",
                "metrs": 1
            },
            {
                "size": "170х63х4",
                "koef": "0.007191",
                "metrs": 1
            },
            {
                "size": "170х63х5",
                "koef": "0.008949",
                "metrs": 1
            },
            {
                "size": "170х63х6",
                "koef": "0.01069",
                "metrs": 1
            },
            {
                "size": "170х65х2",
                "koef": "0.003658",
                "metrs": 1
            },
            {
                "size": "170х65х3",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "170х65х4",
                "koef": "0.007253",
                "metrs": 1
            },
            {
                "size": "170х65х5",
                "koef": "0.009027",
                "metrs": 1
            },
            {
                "size": "170х65х6",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "170х65х8",
                "koef": "0.01426",
                "metrs": 1
            },
            {
                "size": "170х65х10",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": "170х70х2",
                "koef": "0.003737",
                "metrs": 1
            },
            {
                "size": "170х70х3",
                "koef": "0.005581",
                "metrs": 1
            },
            {
                "size": "170х70х4",
                "koef": "0.00741",
                "metrs": 1
            },
            {
                "size": "170х70х5",
                "koef": "0.009224",
                "metrs": 1
            },
            {
                "size": "170х70х6",
                "koef": "0.01102",
                "metrs": 1
            },
            {
                "size": "170х70х8",
                "koef": "0.01457",
                "metrs": 1
            },
            {
                "size": "170х70х10",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": "170х75х2",
                "koef": "0.003815",
                "metrs": 1
            },
            {
                "size": "170х75х3",
                "koef": "0.005699",
                "metrs": 1
            },
            {
                "size": "170х75х4",
                "koef": "0.007567",
                "metrs": 1
            },
            {
                "size": "170х75х5",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "170х75х6",
                "koef": "0.01126",
                "metrs": 1
            },
            {
                "size": "170х75х8",
                "koef": "0.01488",
                "metrs": 1
            },
            {
                "size": "170х75х10",
                "koef": "0.01845",
                "metrs": 1
            },
            {
                "size": "170х80х2",
                "koef": "0.003894",
                "metrs": 1
            },
            {
                "size": "170х80х3",
                "koef": "0.005817",
                "metrs": 1
            },
            {
                "size": "170х80х4",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "170х80х5",
                "koef": "0.009616",
                "metrs": 1
            },
            {
                "size": "170х80х6",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "170х80х8",
                "koef": "0.0152",
                "metrs": 1
            },
            {
                "size": "170х80х10",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "170х90х2",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "170х90х3",
                "koef": "0.006052",
                "metrs": 1
            },
            {
                "size": "170х90х4",
                "koef": "0.008038",
                "metrs": 1
            },
            {
                "size": "170х90х5",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "170х90х6",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "170х90х8",
                "koef": "0.01583",
                "metrs": 1
            },
            {
                "size": "170х90х10",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": "170х100х2",
                "koef": "0.004208",
                "metrs": 1
            },
            {
                "size": "170х100х3",
                "koef": "0.006288",
                "metrs": 1
            },
            {
                "size": "170х100х4",
                "koef": "0.008352",
                "metrs": 1
            },
            {
                "size": "170х100х5",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "170х100х6",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "170х100х8",
                "koef": "0.01645",
                "metrs": 1
            },
            {
                "size": "170х100х10",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": "170х110х2",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "170х110х3",
                "koef": "0.006523",
                "metrs": 1
            },
            {
                "size": "170х110х4",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "170х110х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "170х110х6",
                "koef": "0.01291",
                "metrs": 1
            },
            {
                "size": "170х110х8",
                "koef": "0.01708",
                "metrs": 1
            },
            {
                "size": "170х110х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "170х120х2",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "170х120х3",
                "koef": "0.006759",
                "metrs": 1
            },
            {
                "size": "170х120х4",
                "koef": "0.00898",
                "metrs": 1
            },
            {
                "size": "170х120х5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "170х120х6",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "170х120х8",
                "koef": "0.01771",
                "metrs": 1
            },
            {
                "size": "170х120х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "170х130х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "170х130х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "170х130х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "170х130х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "170х130х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "170х130х8",
                "koef": "0.01834",
                "metrs": 1
            },
            {
                "size": "170х130х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "170х140х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "170х140х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "170х140х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "170х140х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "170х140х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "170х140х8",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": "170х140х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "170х150х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "170х150х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "170х150х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "170х150х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "170х150х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "170х150х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "170х150х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "170х160х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "170х160х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "170х160х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "170х160х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "170х160х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "170х160х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "170х160х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "170х170х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "170х170х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "170х170х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "170х170х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "170х170х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "170х170х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "170х170х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "180х60х2",
                "koef": "0.003737",
                "metrs": 1
            },
            {
                "size": "180х60х3",
                "koef": "0.005581",
                "metrs": 1
            },
            {
                "size": "180х60х4",
                "koef": "0.00741",
                "metrs": 1
            },
            {
                "size": "180х60х5",
                "koef": "0.009224",
                "metrs": 1
            },
            {
                "size": "180х60х6",
                "koef": "0.01102",
                "metrs": 1
            },
            {
                "size": "180х63х2",
                "koef": "0.003784",
                "metrs": 1
            },
            {
                "size": "180х63х3",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": "180х63х4",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "180х63х5",
                "koef": "0.009342",
                "metrs": 1
            },
            {
                "size": "180х63х6",
                "koef": "0.01116",
                "metrs": 1
            },
            {
                "size": "180х65х2",
                "koef": "0.003815",
                "metrs": 1
            },
            {
                "size": "180х65х3",
                "koef": "0.005699",
                "metrs": 1
            },
            {
                "size": "180х65х4",
                "koef": "0.007567",
                "metrs": 1
            },
            {
                "size": "180х65х5",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": "180х65х6",
                "koef": "0.01126",
                "metrs": 1
            },
            {
                "size": "180х65х8",
                "koef": "0.01488",
                "metrs": 1
            },
            {
                "size": "180х65х10",
                "koef": "0.01845",
                "metrs": 1
            },
            {
                "size": "180х70х2",
                "koef": "0.003894",
                "metrs": 1
            },
            {
                "size": "180х70х3",
                "koef": "0.005817",
                "metrs": 1
            },
            {
                "size": "180х70х4",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "180х70х5",
                "koef": "0.009616",
                "metrs": 1
            },
            {
                "size": "180х70х6",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "180х70х8",
                "koef": "0.0152",
                "metrs": 1
            },
            {
                "size": "180х70х10",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": "180х75х2",
                "koef": "0.003972",
                "metrs": 1
            },
            {
                "size": "180х75х3",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "180х75х4",
                "koef": "0.007881",
                "metrs": 1
            },
            {
                "size": "180х75х5",
                "koef": "0.009813",
                "metrs": 1
            },
            {
                "size": "180х75х6",
                "koef": "0.01173",
                "metrs": 1
            },
            {
                "size": "180х75х8",
                "koef": "0.01551",
                "metrs": 1
            },
            {
                "size": "180х75х10",
                "koef": "0.01923",
                "metrs": 1
            },
            {
                "size": "180х80х2",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "180х80х3",
                "koef": "0.006052",
                "metrs": 1
            },
            {
                "size": "180х80х4",
                "koef": "0.008038",
                "metrs": 1
            },
            {
                "size": "180х80х5",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "180х80х6",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "180х80х8",
                "koef": "0.01583",
                "metrs": 1
            },
            {
                "size": "180х80х10",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": "180х90х2",
                "koef": "0.004208",
                "metrs": 1
            },
            {
                "size": "180х90х3",
                "koef": "0.006288",
                "metrs": 1
            },
            {
                "size": "180х90х4",
                "koef": "0.008352",
                "metrs": 1
            },
            {
                "size": "180х90х5",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "180х90х6",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "180х90х8",
                "koef": "0.01645",
                "metrs": 1
            },
            {
                "size": "180х90х10",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": "180х100х2",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "180х100х3",
                "koef": "0.006523",
                "metrs": 1
            },
            {
                "size": "180х100х4",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "180х100х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "180х100х6",
                "koef": "0.01291",
                "metrs": 1
            },
            {
                "size": "180х100х8",
                "koef": "0.01708",
                "metrs": 1
            },
            {
                "size": "180х100х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "180х110х2",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "180х110х3",
                "koef": "0.006759",
                "metrs": 1
            },
            {
                "size": "180х110х4",
                "koef": "0.00898",
                "metrs": 1
            },
            {
                "size": "180х110х5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "180х110х6",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "180х110х8",
                "koef": "0.01771",
                "metrs": 1
            },
            {
                "size": "180х110х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "180х110х11",
                "koef": "0.02409",
                "metrs": 1
            },
            {
                "size": "180х110х12",
                "koef": "0.02619",
                "metrs": 1
            },
            {
                "size": "180х120х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "180х120х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "180х120х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "180х120х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "180х120х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "180х120х8",
                "koef": "0.01834",
                "metrs": 1
            },
            {
                "size": "180х120х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "180х130х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "180х130х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "180х130х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "180х130х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "180х130х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "180х130х8",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": "180х130х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "180х140х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "180х140х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "180х140х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "180х140х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "180х140х6",
                "koef": "0.01455",
                "metrs": 1
            },
            {
                "size": "180х140х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "180х140х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "180х150х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "180х150х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "180х150х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "180х150х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "180х150х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "180х150х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "180х150х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "180х160х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "180х160х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "180х160х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "180х160х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "180х160х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "180х160х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "180х160х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "180х170х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "180х170х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "180х170х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "180х170х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "180х170х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "180х170х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "180х170х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "180х180х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "180х180х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "180х180х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "180х180х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "180х180х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "180х180х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "180х180х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "180х180х11",
                "koef": "0.03014",
                "metrs": 1
            },
            {
                "size": "180х180х12",
                "koef": "0.03278",
                "metrs": 1
            },
            {
                "size": "190х60х2",
                "koef": "0.003894",
                "metrs": 1
            },
            {
                "size": "190х60х3",
                "koef": "0.005817",
                "metrs": 1
            },
            {
                "size": "190х60х4",
                "koef": "0.007724",
                "metrs": 1
            },
            {
                "size": "190х60х5",
                "koef": "0.009616",
                "metrs": 1
            },
            {
                "size": "190х60х6",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "190х63х2",
                "koef": "0.003941",
                "metrs": 1
            },
            {
                "size": "190х63х3",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": "190х63х4",
                "koef": "0.007819",
                "metrs": 1
            },
            {
                "size": "190х63х5",
                "koef": "0.009734",
                "metrs": 1
            },
            {
                "size": "190х63х6",
                "koef": "0.01163",
                "metrs": 1
            },
            {
                "size": "190х65х2",
                "koef": "0.003972",
                "metrs": 1
            },
            {
                "size": "190х65х3",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "190х65х4",
                "koef": "0.007881",
                "metrs": 1
            },
            {
                "size": "190х65х5",
                "koef": "0.009813",
                "metrs": 1
            },
            {
                "size": "190х65х6",
                "koef": "0.01173",
                "metrs": 1
            },
            {
                "size": "190х65х8",
                "koef": "0.01551",
                "metrs": 1
            },
            {
                "size": "190х65х10",
                "koef": "0.01923",
                "metrs": 1
            },
            {
                "size": "190х70х2",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "190х70х3",
                "koef": "0.006052",
                "metrs": 1
            },
            {
                "size": "190х70х4",
                "koef": "0.008038",
                "metrs": 1
            },
            {
                "size": "190х70х5",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "190х70х6",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "190х70х8",
                "koef": "0.01583",
                "metrs": 1
            },
            {
                "size": "190х70х10",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": "190х75х2",
                "koef": "0.004129",
                "metrs": 1
            },
            {
                "size": "190х75х3",
                "koef": "0.00617",
                "metrs": 1
            },
            {
                "size": "190х75х4",
                "koef": "0.008195",
                "metrs": 1
            },
            {
                "size": "190х75х5",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": "190х75х6",
                "koef": "0.0122",
                "metrs": 1
            },
            {
                "size": "190х75х8",
                "koef": "0.01614",
                "metrs": 1
            },
            {
                "size": "190х75х10",
                "koef": "0.02002",
                "metrs": 1
            },
            {
                "size": "190х80х2",
                "koef": "0.004208",
                "metrs": 1
            },
            {
                "size": "190х80х3",
                "koef": "0.006288",
                "metrs": 1
            },
            {
                "size": "190х80х4",
                "koef": "0.008352",
                "metrs": 1
            },
            {
                "size": "190х80х5",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "190х80х6",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "190х80х8",
                "koef": "0.01645",
                "metrs": 1
            },
            {
                "size": "190х80х10",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": "190х90х2",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "190х90х3",
                "koef": "0.006523",
                "metrs": 1
            },
            {
                "size": "190х90х4",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "190х90х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "190х90х6",
                "koef": "0.01291",
                "metrs": 1
            },
            {
                "size": "190х90х8",
                "koef": "0.01708",
                "metrs": 1
            },
            {
                "size": "190х90х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "190х100х2",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "190х100х3",
                "koef": "0.006759",
                "metrs": 1
            },
            {
                "size": "190х100х4",
                "koef": "0.00898",
                "metrs": 1
            },
            {
                "size": "190х100х5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "190х100х6",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "190х100х8",
                "koef": "0.01771",
                "metrs": 1
            },
            {
                "size": "190х100х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "190х110х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "190х110х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "190х110х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "190х110х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "190х110х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "190х110х8",
                "koef": "0.01834",
                "metrs": 1
            },
            {
                "size": "190х110х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "190х120х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "190х120х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "190х120х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "190х120х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "190х120х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "190х120х8",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": "190х120х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "190х130х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "190х130х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "190х130х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "190х130х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "190х130х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "190х130х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "190х130х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "190х140х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "190х140х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "190х140х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "190х140х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "190х140х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "190х140х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "190х140х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "190х150х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "190х150х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "190х150х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "190х150х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "190х150х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "190х150х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "190х150х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "190х160х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "190х160х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "190х160х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "190х160х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "190х160х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "190х160х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "190х160х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "190х170х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "190х170х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "190х170х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "190х170х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "190х170х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "190х170х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "190х170х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "190х180х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "190х180х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "190х180х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "190х180х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "190х180х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "190х180х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "190х180х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "190х190х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "190х190х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "190х190х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "190х190х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "190х190х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "190х190х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "190х190х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "200х60х2",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": "200х60х3",
                "koef": "0.006052",
                "metrs": 1
            },
            {
                "size": "200х60х4",
                "koef": "0.008038",
                "metrs": 1
            },
            {
                "size": "200х60х5",
                "koef": "0.01001",
                "metrs": 1
            },
            {
                "size": "200х60х6",
                "koef": "0.01196",
                "metrs": 1
            },
            {
                "size": "200х63х2",
                "koef": "0.004098",
                "metrs": 1
            },
            {
                "size": "200х63х3",
                "koef": "0.006123",
                "metrs": 1
            },
            {
                "size": "200х63х4",
                "koef": "0.008133",
                "metrs": 1
            },
            {
                "size": "200х63х5",
                "koef": "0.01013",
                "metrs": 1
            },
            {
                "size": "200х63х6",
                "koef": "0.0121",
                "metrs": 1
            },
            {
                "size": "200х65х2",
                "koef": "0.004129",
                "metrs": 1
            },
            {
                "size": "200х65х3",
                "koef": "0.00617",
                "metrs": 1
            },
            {
                "size": "200х65х4",
                "koef": "0.008195",
                "metrs": 1
            },
            {
                "size": "200х65х5",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": "200х65х6",
                "koef": "0.0122",
                "metrs": 1
            },
            {
                "size": "200х65х8",
                "koef": "0.01614",
                "metrs": 1
            },
            {
                "size": "200х65х10",
                "koef": "0.02002",
                "metrs": 1
            },
            {
                "size": "200х70х2",
                "koef": "0.004208",
                "metrs": 1
            },
            {
                "size": "200х70х3",
                "koef": "0.006288",
                "metrs": 1
            },
            {
                "size": "200х70х4",
                "koef": "0.008352",
                "metrs": 1
            },
            {
                "size": "200х70х5",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "200х70х6",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "200х70х8",
                "koef": "0.01645",
                "metrs": 1
            },
            {
                "size": "200х70х10",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": "200х75х2",
                "koef": "0.004286",
                "metrs": 1
            },
            {
                "size": "200х75х3",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "200х75х4",
                "koef": "0.008509",
                "metrs": 1
            },
            {
                "size": "200х75х5",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": "200х75х6",
                "koef": "0.01267",
                "metrs": 1
            },
            {
                "size": "200х75х8",
                "koef": "0.01677",
                "metrs": 1
            },
            {
                "size": "200х75х10",
                "koef": "0.0208",
                "metrs": 1
            },
            {
                "size": "200х80х2",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "200х80х3",
                "koef": "0.006523",
                "metrs": 1
            },
            {
                "size": "200х80х4",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "200х80х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "200х80х6",
                "koef": "0.01291",
                "metrs": 1
            },
            {
                "size": "200х80х8",
                "koef": "0.01708",
                "metrs": 1
            },
            {
                "size": "200х80х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "200х90х2",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "200х90х3",
                "koef": "0.006759",
                "metrs": 1
            },
            {
                "size": "200х90х4",
                "koef": "0.00898",
                "metrs": 1
            },
            {
                "size": "200х90х5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "200х90х6",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "200х90х8",
                "koef": "0.01771",
                "metrs": 1
            },
            {
                "size": "200х90х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "200х100х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "200х100х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "200х100х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "200х100х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "200х100х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "200х100х8",
                "koef": "0.01834",
                "metrs": 1
            },
            {
                "size": "200х100х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "200х110х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "200х110х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "200х110х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "200х110х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "200х110х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "200х110х8",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": "200х110х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "200х120х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "200х120х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "200х120х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "200х120х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "200х120х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "200х120х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "200х120х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "200х125х12",
                "koef": "0.02948",
                "metrs": 1
            },
            {
                "size": "200х125х14",
                "koef": "0.03418",
                "metrs": 1
            },
            {
                "size": "200х125х16",
                "koef": "0.03881",
                "metrs": 1
            },
            {
                "size": "200х130х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "200х130х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "200х130х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "200х130х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "200х130х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "200х130х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "200х130х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "200х140х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "200х140х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "200х140х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "200х140х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "200х140х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "200х140х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "200х140х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "200х150х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "200х150х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "200х150х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "200х150х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "200х150х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "200х150х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "200х150х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "200х160х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "200х160х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "200х160х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "200х160х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "200х160х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "200х160х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "200х160х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "200х170х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "200х170х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "200х170х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "200х170х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "200х170х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "200х170х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "200х170х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "200х180х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "200х180х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "200х180х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "200х180х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "200х180х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "200х180х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "200х180х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "200х190х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "200х190х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "200х190х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "200х190х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "200х190х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "200х190х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "200х190х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "200х200х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "200х200х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "200х200х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "200х200х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "200х200х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "200х200х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "200х200х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "200х200х12",
                "koef": "0.03655",
                "metrs": 1
            },
            {
                "size": "200х200х13",
                "koef": "0.03949",
                "metrs": 1
            },
            {
                "size": "200х200х14",
                "koef": "0.04242",
                "metrs": 1
            },
            {
                "size": "200х200х16",
                "koef": "0.04823",
                "metrs": 1
            },
            {
                "size": "210х60х2",
                "koef": "0.004208",
                "metrs": 1
            },
            {
                "size": "210х60х3",
                "koef": "0.006288",
                "metrs": 1
            },
            {
                "size": "210х60х4",
                "koef": "0.008352",
                "metrs": 1
            },
            {
                "size": "210х60х5",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "210х60х6",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "210х63х2",
                "koef": "0.004255",
                "metrs": 1
            },
            {
                "size": "210х63х3",
                "koef": "0.006359",
                "metrs": 1
            },
            {
                "size": "210х63х4",
                "koef": "0.008447",
                "metrs": 1
            },
            {
                "size": "210х63х5",
                "koef": "0.01052",
                "metrs": 1
            },
            {
                "size": "210х63х6",
                "koef": "0.01258",
                "metrs": 1
            },
            {
                "size": "210х65х2",
                "koef": "0.004286",
                "metrs": 1
            },
            {
                "size": "210х65х3",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "210х65х4",
                "koef": "0.008509",
                "metrs": 1
            },
            {
                "size": "210х65х5",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": "210х65х6",
                "koef": "0.01267",
                "metrs": 1
            },
            {
                "size": "210х65х8",
                "koef": "0.01677",
                "metrs": 1
            },
            {
                "size": "210х65х10",
                "koef": "0.0208",
                "metrs": 1
            },
            {
                "size": "210х70х2",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "210х70х3",
                "koef": "0.006523",
                "metrs": 1
            },
            {
                "size": "210х70х4",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "210х70х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "210х70х6",
                "koef": "0.01291",
                "metrs": 1
            },
            {
                "size": "210х70х8",
                "koef": "0.01708",
                "metrs": 1
            },
            {
                "size": "210х70х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": "210х75х2",
                "koef": "0.004443",
                "metrs": 1
            },
            {
                "size": "210х75х3",
                "koef": "0.006641",
                "metrs": 1
            },
            {
                "size": "210х75х4",
                "koef": "0.008823",
                "metrs": 1
            },
            {
                "size": "210х75х5",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": "210х75х6",
                "koef": "0.01314",
                "metrs": 1
            },
            {
                "size": "210х75х8",
                "koef": "0.0174",
                "metrs": 1
            },
            {
                "size": "210х75х10",
                "koef": "0.02159",
                "metrs": 1
            },
            {
                "size": "210х80х2",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "210х80х3",
                "koef": "0.006759",
                "metrs": 1
            },
            {
                "size": "210х80х4",
                "koef": "0.00898",
                "metrs": 1
            },
            {
                "size": "210х80х5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "210х80х6",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "210х80х8",
                "koef": "0.01771",
                "metrs": 1
            },
            {
                "size": "210х80х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "210х90х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "210х90х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "210х90х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "210х90х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "210х90х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "210х90х8",
                "koef": "0.01834",
                "metrs": 1
            },
            {
                "size": "210х90х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "210х100х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "210х100х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "210х100х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "210х100х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "210х100х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "210х100х8",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": "210х100х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "210х110х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "210х110х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "210х110х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "210х110х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "210х110х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "210х110х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "210х110х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "210х120х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "210х120х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "210х120х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "210х120х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "210х120х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "210х120х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "210х120х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "210х130х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "210х130х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "210х130х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "210х130х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "210х130х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "210х130х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "210х130х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "210х140х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "210х140х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "210х140х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "210х140х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "210х140х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "210х140х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "210х140х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "210х150х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "210х150х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "210х150х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "210х150х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "210х150х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "210х150х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "210х150х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "210х160х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "210х160х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "210х160х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "210х160х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "210х160х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "210х160х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "210х160х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "210х170х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "210х170х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "210х170х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "210х170х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "210х170х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "210х170х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "210х170х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "210х180х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "210х180х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "210х180х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "210х180х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "210х180х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "210х180х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "210х180х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "210х190х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "210х190х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "210х190х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "210х190х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "210х190х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "210х190х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "210х190х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "210х200х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "210х200х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "210х200х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "210х200х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "210х200х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "210х200х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "210х200х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "210х210х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "210х210х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "210х210х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "210х210х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "210х210х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "210х210х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "210х210х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "220х60х2",
                "koef": "0.004365",
                "metrs": 1
            },
            {
                "size": "220х60х3",
                "koef": "0.006523",
                "metrs": 1
            },
            {
                "size": "220х60х4",
                "koef": "0.008666",
                "metrs": 1
            },
            {
                "size": "220х60х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": "220х60х6",
                "koef": "0.01291",
                "metrs": 1
            },
            {
                "size": "220х63х2",
                "koef": "0.004412",
                "metrs": 1
            },
            {
                "size": "220х63х3",
                "koef": "0.006594",
                "metrs": 1
            },
            {
                "size": "220х63х4",
                "koef": "0.008761",
                "metrs": 1
            },
            {
                "size": "220х63х5",
                "koef": "0.01091",
                "metrs": 1
            },
            {
                "size": "220х63х6",
                "koef": "0.01305",
                "metrs": 1
            },
            {
                "size": "220х65х2",
                "koef": "0.004443",
                "metrs": 1
            },
            {
                "size": "220х65х3",
                "koef": "0.006641",
                "metrs": 1
            },
            {
                "size": "220х65х4",
                "koef": "0.008823",
                "metrs": 1
            },
            {
                "size": "220х65х5",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": "220х65х6",
                "koef": "0.01314",
                "metrs": 1
            },
            {
                "size": "220х65х8",
                "koef": "0.0174",
                "metrs": 1
            },
            {
                "size": "220х70х2",
                "koef": "0.004522",
                "metrs": 1
            },
            {
                "size": "220х70х3",
                "koef": "0.006759",
                "metrs": 1
            },
            {
                "size": "220х70х4",
                "koef": "0.00898",
                "metrs": 1
            },
            {
                "size": "220х70х5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "220х70х6",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "220х70х8",
                "koef": "0.01771",
                "metrs": 1
            },
            {
                "size": "220х70х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": "220х75х2",
                "koef": "0.0046",
                "metrs": 1
            },
            {
                "size": "220х75х3",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "220х75х4",
                "koef": "0.009137",
                "metrs": 1
            },
            {
                "size": "220х75х5",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": "220х75х6",
                "koef": "0.01361",
                "metrs": 1
            },
            {
                "size": "220х75х8",
                "koef": "0.01802",
                "metrs": 1
            },
            {
                "size": "220х75х10",
                "koef": "0.02237",
                "metrs": 1
            },
            {
                "size": "220х80х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "220х80х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "220х80х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "220х80х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "220х80х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "220х80х8",
                "koef": "0.01834",
                "metrs": 1
            },
            {
                "size": "220х80х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "220х90х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "220х90х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "220х90х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "220х90х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "220х90х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "220х90х8",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": "220х90х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "220х100х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "220х100х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "220х100х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "220х100х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "220х100х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "220х100х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "220х100х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "220х110х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "220х110х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "220х110х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "220х110х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "220х110х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "220х110х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "220х110х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "220х120х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "220х120х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "220х120х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "220х120х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "220х120х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "220х120х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "220х120х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "220х130х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "220х130х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "220х130х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "220х130х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "220х130х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "220х130х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "220х130х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "220х140х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "220х140х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "220х140х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "220х140х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "220х140х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "220х140х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "220х140х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "220х150х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "220х150х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "220х150х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "220х150х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "220х150х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "220х150х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "220х150х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "220х160х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "220х160х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "220х160х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "220х160х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "220х160х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "220х160х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "220х160х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "220х170х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "220х170х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "220х170х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "220х170х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "220х170х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "220х170х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "220х170х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "220х180х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "220х180х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "220х180х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "220х180х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "220х180х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "220х180х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "220х180х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "220х190х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "220х190х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "220х190х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "220х190х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "220х190х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "220х190х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "220х190х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "220х200х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "220х200х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "220х200х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "220х200х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "220х200х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "220х200х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "220х200х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "220х210х2",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "220х210х3",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "220х210х4",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "220х210х5",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "220х210х6",
                "koef": "0.01997",
                "metrs": 1
            },
            {
                "size": "220х210х8",
                "koef": "0.0265",
                "metrs": 1
            },
            {
                "size": "220х210х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "220х220х2",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "220х220х3",
                "koef": "0.01029",
                "metrs": 1
            },
            {
                "size": "220х220х4",
                "koef": "0.01369",
                "metrs": 1
            },
            {
                "size": "220х220х5",
                "koef": "0.01707",
                "metrs": 1
            },
            {
                "size": "220х220х6",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "220х220х8",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "220х220х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": "220х220х14",
                "koef": "0.04682",
                "metrs": 1
            },
            {
                "size": "220х220х16",
                "koef": "0.05325",
                "metrs": 1
            },
            {
                "size": "230х60х3",
                "koef": "0.006759",
                "metrs": 1
            },
            {
                "size": "230х60х4",
                "koef": "0.00898",
                "metrs": 1
            },
            {
                "size": "230х60х5",
                "koef": "0.01119",
                "metrs": 1
            },
            {
                "size": "230х60х6",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "230х63х2",
                "koef": "0.004569",
                "metrs": 1
            },
            {
                "size": "230х63х3",
                "koef": "0.00683",
                "metrs": 1
            },
            {
                "size": "230х63х4",
                "koef": "0.009075",
                "metrs": 1
            },
            {
                "size": "230х63х5",
                "koef": "0.0113",
                "metrs": 1
            },
            {
                "size": "230х63х6",
                "koef": "0.01352",
                "metrs": 1
            },
            {
                "size": "230х65х2",
                "koef": "0.0046",
                "metrs": 1
            },
            {
                "size": "230х65х3",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "230х65х4",
                "koef": "0.009137",
                "metrs": 1
            },
            {
                "size": "230х65х5",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": "230х65х6",
                "koef": "0.01361",
                "metrs": 1
            },
            {
                "size": "230х65х8",
                "koef": "0.01802",
                "metrs": 1
            },
            {
                "size": "230х65х10",
                "koef": "0.02237",
                "metrs": 1
            },
            {
                "size": "230х70х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "230х70х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "230х70х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "230х70х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "230х70х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "230х70х8",
                "koef": "0.01834",
                "metrs": 1
            },
            {
                "size": "230х70х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": "230х75х2",
                "koef": "0.004757",
                "metrs": 1
            },
            {
                "size": "230х75х3",
                "koef": "0.007112",
                "metrs": 1
            },
            {
                "size": "230х75х4",
                "koef": "0.009451",
                "metrs": 1
            },
            {
                "size": "230х75х5",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "230х75х6",
                "koef": "0.01408",
                "metrs": 1
            },
            {
                "size": "230х75х8",
                "koef": "0.01865",
                "metrs": 1
            },
            {
                "size": "230х75х10",
                "koef": "0.02316",
                "metrs": 1
            },
            {
                "size": "230х80х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "230х80х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "230х80х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "230х80х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "230х80х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "230х80х8",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": "230х80х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "230х90х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "230х90х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "230х90х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "230х90х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "230х90х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "230х90х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "230х90х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "230х100х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "230х100х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "230х100х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "230х100х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "230х100х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "230х100х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "230х100х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "230х110х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "230х110х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "230х110х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "230х110х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "230х110х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "230х110х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "230х110х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "230х120х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "230х120х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "230х120х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "230х120х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "230х120х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "230х120х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "230х120х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "230х130х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "230х130х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "230х130х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "230х130х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "230х130х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "230х130х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "230х130х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "230х140х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "230х140х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "230х140х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "230х140х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "230х140х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "230х140х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "230х140х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "230х150х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "230х150х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "230х150х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "230х150х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "230х150х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "230х150х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "230х150х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "230х160х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "230х160х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "230х160х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "230х160х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "230х160х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "230х160х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "230х160х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "230х170х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "230х170х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "230х170х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "230х170х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "230х170х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "230х170х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "230х170х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "230х180х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "230х180х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "230х180х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "230х180х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "230х180х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "230х180х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "230х180х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "230х190х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "230х190х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "230х190х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "230х190х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "230х190х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "230х190х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "230х190х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "230х200х2",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "230х200х3",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "230х200х4",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "230х200х5",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "230х200х6",
                "koef": "0.01997",
                "metrs": 1
            },
            {
                "size": "230х200х8",
                "koef": "0.0265",
                "metrs": 1
            },
            {
                "size": "230х200х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "230х210х2",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "230х210х3",
                "koef": "0.01029",
                "metrs": 1
            },
            {
                "size": "230х210х4",
                "koef": "0.01369",
                "metrs": 1
            },
            {
                "size": "230х210х5",
                "koef": "0.01707",
                "metrs": 1
            },
            {
                "size": "230х210х6",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "230х210х8",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "230х210х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": "230х220х2",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": "230х220х3",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": "230х220х4",
                "koef": "0.014",
                "metrs": 1
            },
            {
                "size": "230х220х5",
                "koef": "0.01747",
                "metrs": 1
            },
            {
                "size": "230х220х6",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": "230х220х8",
                "koef": "0.02776",
                "metrs": 1
            },
            {
                "size": "230х220х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": "230х230х2",
                "koef": "0.007191",
                "metrs": 1
            },
            {
                "size": "230х230х3",
                "koef": "0.01076",
                "metrs": 1
            },
            {
                "size": "230х230х4",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "230х230х5",
                "koef": "0.01786",
                "metrs": 1
            },
            {
                "size": "230х230х6",
                "koef": "0.02138",
                "metrs": 1
            },
            {
                "size": "230х230х8",
                "koef": "0.02839",
                "metrs": 1
            },
            {
                "size": "230х230х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "240х60х2",
                "koef": "0.004679",
                "metrs": 1
            },
            {
                "size": "240х60х3",
                "koef": "0.006994",
                "metrs": 1
            },
            {
                "size": "240х60х4",
                "koef": "0.009294",
                "metrs": 1
            },
            {
                "size": "240х60х5",
                "koef": "0.01158",
                "metrs": 1
            },
            {
                "size": "240х60х6",
                "koef": "0.01385",
                "metrs": 1
            },
            {
                "size": "240х63х2",
                "koef": "0.004726",
                "metrs": 1
            },
            {
                "size": "240х63х3",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": "240х63х4",
                "koef": "0.009389",
                "metrs": 1
            },
            {
                "size": "240х63х5",
                "koef": "0.0117",
                "metrs": 1
            },
            {
                "size": "240х63х6",
                "koef": "0.01399",
                "metrs": 1
            },
            {
                "size": "240х65х2",
                "koef": "0.004757",
                "metrs": 1
            },
            {
                "size": "240х65х3",
                "koef": "0.007112",
                "metrs": 1
            },
            {
                "size": "240х65х4",
                "koef": "0.009451",
                "metrs": 1
            },
            {
                "size": "240х65х5",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "240х65х6",
                "koef": "0.01408",
                "metrs": 1
            },
            {
                "size": "240х65х8",
                "koef": "0.01865",
                "metrs": 1
            },
            {
                "size": "240х65х10",
                "koef": "0.02316",
                "metrs": 1
            },
            {
                "size": "240х70х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "240х70х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "240х70х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "240х70х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "240х70х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "240х70х8",
                "koef": "0.01897",
                "metrs": 1
            },
            {
                "size": "240х70х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": "240х75х2",
                "koef": "0.004914",
                "metrs": 1
            },
            {
                "size": "240х75х3",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "240х75х4",
                "koef": "0.009765",
                "metrs": 1
            },
            {
                "size": "240х75х5",
                "koef": "0.01217",
                "metrs": 1
            },
            {
                "size": "240х75х6",
                "koef": "0.01455",
                "metrs": 1
            },
            {
                "size": "240х75х8",
                "koef": "0.01928",
                "metrs": 1
            },
            {
                "size": "240х75х10",
                "koef": "0.02394",
                "metrs": 1
            },
            {
                "size": "240х80х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "240х80х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "240х80х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "240х80х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "240х80х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "240х80х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "240х80х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "240х90х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "240х90х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "240х90х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "240х90х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "240х90х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "240х90х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "240х90х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "240х100х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "240х100х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "240х100х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "240х100х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "240х100х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "240х100х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "240х100х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "240х110х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "240х110х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "240х110х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "240х110х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "240х110х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "240х110х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "240х110х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "240х120х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "240х120х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "240х120х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "240х120х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "240х120х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "240х120х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "240х120х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "240х130х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "240х130х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "240х130х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "240х130х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "240х130х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "240х130х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "240х130х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "240х140х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "240х140х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "240х140х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "240х140х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "240х140х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "240х140х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "240х140х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "240х150х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "240х150х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "240х150х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "240х150х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "240х150х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "240х150х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "240х150х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "240х160х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "240х160х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "240х160х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "240х160х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "240х160х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "240х160х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "240х160х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "240х170х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "240х170х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "240х170х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "240х170х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "240х170х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "240х170х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "240х170х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "240х180х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "240х180х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "240х180х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "240х180х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "240х180х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "240х180х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "240х180х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "240х190х2",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "240х190х3",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "240х190х4",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "240х190х5",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "240х190х6",
                "koef": "0.01997",
                "metrs": 1
            },
            {
                "size": "240х190х8",
                "koef": "0.0265",
                "metrs": 1
            },
            {
                "size": "240х190х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "240х200х2",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "240х200х3",
                "koef": "0.01029",
                "metrs": 1
            },
            {
                "size": "240х200х4",
                "koef": "0.01369",
                "metrs": 1
            },
            {
                "size": "240х200х5",
                "koef": "0.01707",
                "metrs": 1
            },
            {
                "size": "240х200х6",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "240х200х8",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "240х200х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": "240х210х2",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": "240х210х3",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": "240х210х4",
                "koef": "0.014",
                "metrs": 1
            },
            {
                "size": "240х210х5",
                "koef": "0.01747",
                "metrs": 1
            },
            {
                "size": "240х210х6",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": "240х210х8",
                "koef": "0.02776",
                "metrs": 1
            },
            {
                "size": "240х210х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": "240х220х2",
                "koef": "0.007191",
                "metrs": 1
            },
            {
                "size": "240х220х3",
                "koef": "0.01076",
                "metrs": 1
            },
            {
                "size": "240х220х4",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "240х220х5",
                "koef": "0.01786",
                "metrs": 1
            },
            {
                "size": "240х220х6",
                "koef": "0.02138",
                "metrs": 1
            },
            {
                "size": "240х220х8",
                "koef": "0.02839",
                "metrs": 1
            },
            {
                "size": "240х220х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "240х230х2",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "240х230х3",
                "koef": "0.011",
                "metrs": 1
            },
            {
                "size": "240х230х4",
                "koef": "0.01463",
                "metrs": 1
            },
            {
                "size": "240х230х5",
                "koef": "0.01825",
                "metrs": 1
            },
            {
                "size": "240х230х6",
                "koef": "0.02185",
                "metrs": 1
            },
            {
                "size": "240х230х8",
                "koef": "0.02901",
                "metrs": 1
            },
            {
                "size": "240х230х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": "240х240х2",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "240х240х3",
                "koef": "0.01123",
                "metrs": 1
            },
            {
                "size": "240х240х4",
                "koef": "0.01495",
                "metrs": 1
            },
            {
                "size": "240х240х5",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": "240х240х6",
                "koef": "0.02233",
                "metrs": 1
            },
            {
                "size": "240х240х8",
                "koef": "0.02964",
                "metrs": 1
            },
            {
                "size": "240х240х10",
                "koef": "0.0369",
                "metrs": 1
            },
            {
                "size": "250х60х2",
                "koef": "0.004836",
                "metrs": 1
            },
            {
                "size": "250х60х3",
                "koef": "0.00723",
                "metrs": 1
            },
            {
                "size": "250х60х4",
                "koef": "0.009608",
                "metrs": 1
            },
            {
                "size": "250х60х5",
                "koef": "0.01197",
                "metrs": 1
            },
            {
                "size": "250х60х6",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "250х63х2",
                "koef": "0.004883",
                "metrs": 1
            },
            {
                "size": "250х63х3",
                "koef": "0.007301",
                "metrs": 1
            },
            {
                "size": "250х63х4",
                "koef": "0.009703",
                "metrs": 1
            },
            {
                "size": "250х63х5",
                "koef": "0.01209",
                "metrs": 1
            },
            {
                "size": "250х63х6",
                "koef": "0.01446",
                "metrs": 1
            },
            {
                "size": "250х65х2",
                "koef": "0.004914",
                "metrs": 1
            },
            {
                "size": "250х65х3",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "250х65х4",
                "koef": "0.009765",
                "metrs": 1
            },
            {
                "size": "250х65х5",
                "koef": "0.01217",
                "metrs": 1
            },
            {
                "size": "250х65х6",
                "koef": "0.01455",
                "metrs": 1
            },
            {
                "size": "250х65х8",
                "koef": "0.01928",
                "metrs": 1
            },
            {
                "size": "250х70х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "250х70х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "250х70х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "250х70х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "250х70х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "250х70х8",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "250х70х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": "250х75х2",
                "koef": "0.005071",
                "metrs": 1
            },
            {
                "size": "250х75х3",
                "koef": "0.007583",
                "metrs": 1
            },
            {
                "size": "250х75х4",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": "250х75х5",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": "250х75х6",
                "koef": "0.01502",
                "metrs": 1
            },
            {
                "size": "250х75х8",
                "koef": "0.01991",
                "metrs": 1
            },
            {
                "size": "250х75х10",
                "koef": "0.02473",
                "metrs": 1
            },
            {
                "size": "250х80х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "250х80х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "250х80х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "250х80х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "250х80х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "250х80х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "250х80х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "250х90х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "250х90х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "250х90х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "250х90х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "250х90х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "250х90х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "250х90х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "250х100х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "250х100х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "250х100х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "250х100х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "250х100х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "250х100х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "250х100х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "250х110х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "250х110х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "250х110х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "250х110х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "250х110х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "250х110х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "250х110х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "250х120х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "250х120х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "250х120х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "250х120х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "250х120х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "250х120х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "250х120х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "250х130х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "250х130х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "250х130х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "250х130х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "250х130х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "250х130х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "250х130х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "250х140х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "250х140х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "250х140х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "250х140х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "250х140х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "250х140х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "250х140х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "250х150х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "250х150х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "250х150х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "250х150х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "250х150х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "250х150х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "250х150х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "250х160х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "250х160х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "250х160х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "250х160х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "250х160х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "250х160х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "250х160х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "250х170х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "250х170х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "250х170х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "250х170х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "250х170х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "250х170х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "250х170х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "250х180х2",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "250х180х3",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "250х180х4",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "250х180х5",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "250х180х6",
                "koef": "0.01997",
                "metrs": 1
            },
            {
                "size": "250х180х8",
                "koef": "0.0265",
                "metrs": 1
            },
            {
                "size": "250х180х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "250х190х2",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "250х190х3",
                "koef": "0.01029",
                "metrs": 1
            },
            {
                "size": "250х190х4",
                "koef": "0.01369",
                "metrs": 1
            },
            {
                "size": "250х190х5",
                "koef": "0.01707",
                "metrs": 1
            },
            {
                "size": "250х190х6",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "250х190х8",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "250х190х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": "250х200х2",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": "250х200х3",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": "250х200х4",
                "koef": "0.014",
                "metrs": 1
            },
            {
                "size": "250х200х5",
                "koef": "0.01747",
                "metrs": 1
            },
            {
                "size": "250х200х6",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": "250х200х8",
                "koef": "0.02776",
                "metrs": 1
            },
            {
                "size": "250х200х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": "250х210х2",
                "koef": "0.007191",
                "metrs": 1
            },
            {
                "size": "250х210х3",
                "koef": "0.01076",
                "metrs": 1
            },
            {
                "size": "250х210х4",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "250х210х5",
                "koef": "0.01786",
                "metrs": 1
            },
            {
                "size": "250х210х6",
                "koef": "0.02138",
                "metrs": 1
            },
            {
                "size": "250х210х8",
                "koef": "0.02839",
                "metrs": 1
            },
            {
                "size": "250х210х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "250х220х2",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "250х220х3",
                "koef": "0.011",
                "metrs": 1
            },
            {
                "size": "250х220х4",
                "koef": "0.01463",
                "metrs": 1
            },
            {
                "size": "250х220х5",
                "koef": "0.01825",
                "metrs": 1
            },
            {
                "size": "250х220х6",
                "koef": "0.02185",
                "metrs": 1
            },
            {
                "size": "250х220х8",
                "koef": "0.02901",
                "metrs": 1
            },
            {
                "size": "250х220х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": "250х230х2",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "250х230х3",
                "koef": "0.01123",
                "metrs": 1
            },
            {
                "size": "250х230х4",
                "koef": "0.01495",
                "metrs": 1
            },
            {
                "size": "250х230х5",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": "250х230х6",
                "koef": "0.02233",
                "metrs": 1
            },
            {
                "size": "250х230х8",
                "koef": "0.02964",
                "metrs": 1
            },
            {
                "size": "250х230х10",
                "koef": "0.0369",
                "metrs": 1
            },
            {
                "size": "250х240х2",
                "koef": "0.007662",
                "metrs": 1
            },
            {
                "size": "250х240х3",
                "koef": "0.01147",
                "metrs": 1
            },
            {
                "size": "250х240х4",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "250х240х5",
                "koef": "0.01904",
                "metrs": 1
            },
            {
                "size": "250х240х6",
                "koef": "0.0228",
                "metrs": 1
            },
            {
                "size": "250х240х8",
                "koef": "0.03027",
                "metrs": 1
            },
            {
                "size": "250х240х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": "250х250х2",
                "koef": "0.007819",
                "metrs": 1
            },
            {
                "size": "250х250х3",
                "koef": "0.0117",
                "metrs": 1
            },
            {
                "size": "250х250х4",
                "koef": "0.01557",
                "metrs": 1
            },
            {
                "size": "250х250х5",
                "koef": "0.01943",
                "metrs": 1
            },
            {
                "size": "250х250х6",
                "koef": "0.02327",
                "metrs": 1
            },
            {
                "size": "250х250х8",
                "koef": "0.0309",
                "metrs": 1
            },
            {
                "size": "250х250х10",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": "250х250х16",
                "koef": "0.06079",
                "metrs": 1
            },
            {
                "size": "260х60х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "260х60х3",
                "koef": "0.007465",
                "metrs": 1
            },
            {
                "size": "260х60х4",
                "koef": "0.009922",
                "metrs": 1
            },
            {
                "size": "260х60х5",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "260х60х6",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "260х63х2",
                "koef": "0.00504",
                "metrs": 1
            },
            {
                "size": "260х63х3",
                "koef": "0.007536",
                "metrs": 1
            },
            {
                "size": "260х63х4",
                "koef": "0.01002",
                "metrs": 1
            },
            {
                "size": "260х63х5",
                "koef": "0.01248",
                "metrs": 1
            },
            {
                "size": "260х63х6",
                "koef": "0.01493",
                "metrs": 1
            },
            {
                "size": "260х65х2",
                "koef": "0.005071",
                "metrs": 1
            },
            {
                "size": "260х65х3",
                "koef": "0.007583",
                "metrs": 1
            },
            {
                "size": "260х65х4",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": "260х65х5",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": "260х65х6",
                "koef": "0.01502",
                "metrs": 1
            },
            {
                "size": "260х65х8",
                "koef": "0.01991",
                "metrs": 1
            },
            {
                "size": "260х65х10",
                "koef": "0.02473",
                "metrs": 1
            },
            {
                "size": "260х70х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "260х70х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "260х70х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "260х70х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "260х70х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "260х70х8",
                "koef": "0.02022",
                "metrs": 1
            },
            {
                "size": "260х70х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": "260х75х2",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": "260х75х3",
                "koef": "0.007819",
                "metrs": 1
            },
            {
                "size": "260х75х4",
                "koef": "0.01039",
                "metrs": 1
            },
            {
                "size": "260х75х5",
                "koef": "0.01295",
                "metrs": 1
            },
            {
                "size": "260х75х6",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "260х75х8",
                "koef": "0.02054",
                "metrs": 1
            },
            {
                "size": "260х75х10",
                "koef": "0.02551",
                "metrs": 1
            },
            {
                "size": "260х80х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "260х80х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "260х80х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "260х80х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "260х80х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "260х80х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "260х80х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "260х90х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "260х90х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "260х90х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "260х90х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "260х90х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "260х90х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "260х90х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "260х100х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "260х100х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "260х100х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "260х100х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "260х100х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "260х100х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "260х100х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "260х110х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "260х110х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "260х110х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "260х110х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "260х110х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "260х110х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "260х110х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "260х120х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "260х120х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "260х120х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "260х120х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "260х120х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "260х120х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "260х120х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "260х130х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "260х130х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "260х130х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "260х130х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "260х130х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "260х130х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "260х130х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "260х140х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "260х140х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "260х140х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "260х140х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "260х140х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "260х140х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "260х140х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "260х150х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "260х150х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "260х150х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "260х150х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "260х150х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "260х150х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "260х150х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "260х160х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "260х160х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "260х160х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "260х160х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "260х160х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "260х160х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "260х160х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "260х170х2",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "260х170х3",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "260х170х4",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "260х170х5",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "260х170х6",
                "koef": "0.01997",
                "metrs": 1
            },
            {
                "size": "260х170х8",
                "koef": "0.0265",
                "metrs": 1
            },
            {
                "size": "260х170х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "260х180х2",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "260х180х3",
                "koef": "0.01029",
                "metrs": 1
            },
            {
                "size": "260х180х4",
                "koef": "0.01369",
                "metrs": 1
            },
            {
                "size": "260х180х5",
                "koef": "0.01707",
                "metrs": 1
            },
            {
                "size": "260х180х6",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "260х180х8",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "260х180х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": "260х190х2",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": "260х190х3",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": "260х190х4",
                "koef": "0.014",
                "metrs": 1
            },
            {
                "size": "260х190х5",
                "koef": "0.01747",
                "metrs": 1
            },
            {
                "size": "260х190х6",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": "260х190х8",
                "koef": "0.02776",
                "metrs": 1
            },
            {
                "size": "260х190х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": "260х200х2",
                "koef": "0.007191",
                "metrs": 1
            },
            {
                "size": "260х200х3",
                "koef": "0.01076",
                "metrs": 1
            },
            {
                "size": "260х200х4",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "260х200х5",
                "koef": "0.01786",
                "metrs": 1
            },
            {
                "size": "260х200х6",
                "koef": "0.02138",
                "metrs": 1
            },
            {
                "size": "260х200х8",
                "koef": "0.02839",
                "metrs": 1
            },
            {
                "size": "260х200х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "260х210х2",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "260х210х3",
                "koef": "0.011",
                "metrs": 1
            },
            {
                "size": "260х210х4",
                "koef": "0.01463",
                "metrs": 1
            },
            {
                "size": "260х210х5",
                "koef": "0.01825",
                "metrs": 1
            },
            {
                "size": "260х210х6",
                "koef": "0.02185",
                "metrs": 1
            },
            {
                "size": "260х210х8",
                "koef": "0.02901",
                "metrs": 1
            },
            {
                "size": "260х210х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": "260х220х2",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "260х220х3",
                "koef": "0.01123",
                "metrs": 1
            },
            {
                "size": "260х220х4",
                "koef": "0.01495",
                "metrs": 1
            },
            {
                "size": "260х220х5",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": "260х220х6",
                "koef": "0.02233",
                "metrs": 1
            },
            {
                "size": "260х220х8",
                "koef": "0.02964",
                "metrs": 1
            },
            {
                "size": "260х220х10",
                "koef": "0.0369",
                "metrs": 1
            },
            {
                "size": "260х230х2",
                "koef": "0.007662",
                "metrs": 1
            },
            {
                "size": "260х230х3",
                "koef": "0.01147",
                "metrs": 1
            },
            {
                "size": "260х230х4",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "260х230х5",
                "koef": "0.01904",
                "metrs": 1
            },
            {
                "size": "260х230х6",
                "koef": "0.0228",
                "metrs": 1
            },
            {
                "size": "260х230х8",
                "koef": "0.03027",
                "metrs": 1
            },
            {
                "size": "260х230х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": "260х240х2",
                "koef": "0.007819",
                "metrs": 1
            },
            {
                "size": "260х240х3",
                "koef": "0.0117",
                "metrs": 1
            },
            {
                "size": "260х240х4",
                "koef": "0.01557",
                "metrs": 1
            },
            {
                "size": "260х240х5",
                "koef": "0.01943",
                "metrs": 1
            },
            {
                "size": "260х240х6",
                "koef": "0.02327",
                "metrs": 1
            },
            {
                "size": "260х240х8",
                "koef": "0.0309",
                "metrs": 1
            },
            {
                "size": "260х240х10",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": "260х250х2",
                "koef": "0.007976",
                "metrs": 1
            },
            {
                "size": "260х250х3",
                "koef": "0.01194",
                "metrs": 1
            },
            {
                "size": "260х250х4",
                "koef": "0.01589",
                "metrs": 1
            },
            {
                "size": "260х250х5",
                "koef": "0.01982",
                "metrs": 1
            },
            {
                "size": "260х250х6",
                "koef": "0.02374",
                "metrs": 1
            },
            {
                "size": "260х250х8",
                "koef": "0.03153",
                "metrs": 1
            },
            {
                "size": "260х250х10",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": "260х260х2",
                "koef": "0.008133",
                "metrs": 1
            },
            {
                "size": "260х260х3",
                "koef": "0.01218",
                "metrs": 1
            },
            {
                "size": "260х260х4",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "260х260х5",
                "koef": "0.02021",
                "metrs": 1
            },
            {
                "size": "260х260х6",
                "koef": "0.02421",
                "metrs": 1
            },
            {
                "size": "260х260х8",
                "koef": "0.03215",
                "metrs": 1
            },
            {
                "size": "260х260х10",
                "koef": "0.04003",
                "metrs": 1
            },
            {
                "size": "270х60х2",
                "koef": "0.00515",
                "metrs": 1
            },
            {
                "size": "270х60х3",
                "koef": "0.007701",
                "metrs": 1
            },
            {
                "size": "270х60х4",
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": "270х60х5",
                "koef": "0.01276",
                "metrs": 1
            },
            {
                "size": "270х60х6",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "270х63х2",
                "koef": "0.005197",
                "metrs": 1
            },
            {
                "size": "270х63х3",
                "koef": "0.007772",
                "metrs": 1
            },
            {
                "size": "270х63х4",
                "koef": "0.01033",
                "metrs": 1
            },
            {
                "size": "270х63х5",
                "koef": "0.01287",
                "metrs": 1
            },
            {
                "size": "270х63х6",
                "koef": "0.0154",
                "metrs": 1
            },
            {
                "size": "270х65х2",
                "koef": "0.005228",
                "metrs": 1
            },
            {
                "size": "270х65х3",
                "koef": "0.007819",
                "metrs": 1
            },
            {
                "size": "270х65х4",
                "koef": "0.01039",
                "metrs": 1
            },
            {
                "size": "270х65х5",
                "koef": "0.01295",
                "metrs": 1
            },
            {
                "size": "270х65х6",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "270х65х8",
                "koef": "0.02054",
                "metrs": 1
            },
            {
                "size": "270х70х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "270х70х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "270х70х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "270х70х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "270х70х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "270х70х8",
                "koef": "0.02085",
                "metrs": 1
            },
            {
                "size": "270х70х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": "270х75х2",
                "koef": "0.005385",
                "metrs": 1
            },
            {
                "size": "270х75х3",
                "koef": "0.008054",
                "metrs": 1
            },
            {
                "size": "270х75х4",
                "koef": "0.01071",
                "metrs": 1
            },
            {
                "size": "270х75х5",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": "270х75х6",
                "koef": "0.01597",
                "metrs": 1
            },
            {
                "size": "270х75х8",
                "koef": "0.02116",
                "metrs": 1
            },
            {
                "size": "270х75х10",
                "koef": "0.0263",
                "metrs": 1
            },
            {
                "size": "270х80х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "270х80х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "270х80х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "270х80х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "270х80х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "270х80х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "270х80х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "270х90х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "270х90х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "270х90х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "270х90х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "270х90х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "270х90х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "270х90х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "270х100х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "270х100х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "270х100х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "270х100х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "270х100х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "270х100х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "270х100х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "270х110х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "270х110х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "270х110х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "270х110х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "270х110х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "270х110х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "270х110х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "270х120х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "270х120х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "270х120х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "270х120х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "270х120х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "270х120х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "270х120х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "270х130х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "270х130х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "270х130х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "270х130х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "270х130х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "270х130х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "270х130х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "270х140х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "270х140х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "270х140х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "270х140х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "270х140х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "270х140х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "270х140х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "270х150х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "270х150х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "270х150х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "270х150х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "270х150х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "270х150х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "270х150х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "270х160х2",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "270х160х3",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "270х160х4",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "270х160х5",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "270х160х6",
                "koef": "0.01997",
                "metrs": 1
            },
            {
                "size": "270х160х8",
                "koef": "0.0265",
                "metrs": 1
            },
            {
                "size": "270х160х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "270х170х2",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "270х170х3",
                "koef": "0.01029",
                "metrs": 1
            },
            {
                "size": "270х170х4",
                "koef": "0.01369",
                "metrs": 1
            },
            {
                "size": "270х170х5",
                "koef": "0.01707",
                "metrs": 1
            },
            {
                "size": "270х170х6",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "270х170х8",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "270х170х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": "270х180х2",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": "270х180х3",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": "270х180х4",
                "koef": "0.014",
                "metrs": 1
            },
            {
                "size": "270х180х5",
                "koef": "0.01747",
                "metrs": 1
            },
            {
                "size": "270х180х6",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": "270х180х8",
                "koef": "0.02776",
                "metrs": 1
            },
            {
                "size": "270х180х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": "270х190х2",
                "koef": "0.007191",
                "metrs": 1
            },
            {
                "size": "270х190х3",
                "koef": "0.01076",
                "metrs": 1
            },
            {
                "size": "270х190х4",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "270х190х5",
                "koef": "0.01786",
                "metrs": 1
            },
            {
                "size": "270х190х6",
                "koef": "0.02138",
                "metrs": 1
            },
            {
                "size": "270х190х8",
                "koef": "0.02839",
                "metrs": 1
            },
            {
                "size": "270х190х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "270х200х2",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "270х200х3",
                "koef": "0.011",
                "metrs": 1
            },
            {
                "size": "270х200х4",
                "koef": "0.01463",
                "metrs": 1
            },
            {
                "size": "270х200х5",
                "koef": "0.01825",
                "metrs": 1
            },
            {
                "size": "270х200х6",
                "koef": "0.02185",
                "metrs": 1
            },
            {
                "size": "270х200х8",
                "koef": "0.02901",
                "metrs": 1
            },
            {
                "size": "270х200х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": "270х210х2",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "270х210х3",
                "koef": "0.01123",
                "metrs": 1
            },
            {
                "size": "270х210х4",
                "koef": "0.01495",
                "metrs": 1
            },
            {
                "size": "270х210х5",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": "270х210х6",
                "koef": "0.02233",
                "metrs": 1
            },
            {
                "size": "270х210х8",
                "koef": "0.02964",
                "metrs": 1
            },
            {
                "size": "270х210х10",
                "koef": "0.0369",
                "metrs": 1
            },
            {
                "size": "270х220х2",
                "koef": "0.007662",
                "metrs": 1
            },
            {
                "size": "270х220х3",
                "koef": "0.01147",
                "metrs": 1
            },
            {
                "size": "270х220х4",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "270х220х5",
                "koef": "0.01904",
                "metrs": 1
            },
            {
                "size": "270х220х6",
                "koef": "0.0228",
                "metrs": 1
            },
            {
                "size": "270х220х8",
                "koef": "0.03027",
                "metrs": 1
            },
            {
                "size": "270х220х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": "270х230х2",
                "koef": "0.007819",
                "metrs": 1
            },
            {
                "size": "270х230х3",
                "koef": "0.0117",
                "metrs": 1
            },
            {
                "size": "270х230х4",
                "koef": "0.01557",
                "metrs": 1
            },
            {
                "size": "270х230х5",
                "koef": "0.01943",
                "metrs": 1
            },
            {
                "size": "270х230х6",
                "koef": "0.02327",
                "metrs": 1
            },
            {
                "size": "270х230х8",
                "koef": "0.0309",
                "metrs": 1
            },
            {
                "size": "270х230х10",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": "270х240х2",
                "koef": "0.007976",
                "metrs": 1
            },
            {
                "size": "270х240х3",
                "koef": "0.01194",
                "metrs": 1
            },
            {
                "size": "270х240х4",
                "koef": "0.01589",
                "metrs": 1
            },
            {
                "size": "270х240х5",
                "koef": "0.01982",
                "metrs": 1
            },
            {
                "size": "270х240х6",
                "koef": "0.02374",
                "metrs": 1
            },
            {
                "size": "270х240х8",
                "koef": "0.03153",
                "metrs": 1
            },
            {
                "size": "270х240х10",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": "270х250х2",
                "koef": "0.008133",
                "metrs": 1
            },
            {
                "size": "270х250х3",
                "koef": "0.01218",
                "metrs": 1
            },
            {
                "size": "270х250х4",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "270х250х5",
                "koef": "0.02021",
                "metrs": 1
            },
            {
                "size": "270х250х6",
                "koef": "0.02421",
                "metrs": 1
            },
            {
                "size": "270х250х8",
                "koef": "0.03215",
                "metrs": 1
            },
            {
                "size": "270х250х10",
                "koef": "0.04003",
                "metrs": 1
            },
            {
                "size": "270х260х2",
                "koef": "0.00829",
                "metrs": 1
            },
            {
                "size": "270х260х3",
                "koef": "0.01241",
                "metrs": 1
            },
            {
                "size": "270х260х4",
                "koef": "0.01652",
                "metrs": 1
            },
            {
                "size": "270х260х5",
                "koef": "0.02061",
                "metrs": 1
            },
            {
                "size": "270х260х6",
                "koef": "0.02468",
                "metrs": 1
            },
            {
                "size": "270х260х8",
                "koef": "0.03278",
                "metrs": 1
            },
            {
                "size": "270х260х10",
                "koef": "0.04082",
                "metrs": 1
            },
            {
                "size": "270х270х2",
                "koef": "0.008447",
                "metrs": 1
            },
            {
                "size": "270х270х3",
                "koef": "0.01265",
                "metrs": 1
            },
            {
                "size": "270х270х4",
                "koef": "0.01683",
                "metrs": 1
            },
            {
                "size": "270х270х5",
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": "270х270х6",
                "koef": "0.02515",
                "metrs": 1
            },
            {
                "size": "270х270х8",
                "koef": "0.03341",
                "metrs": 1
            },
            {
                "size": "270х270х10",
                "koef": "0.04161",
                "metrs": 1
            },
            {
                "size": "280х60х2",
                "koef": "0.005307",
                "metrs": 1
            },
            {
                "size": "280х60х3",
                "koef": "0.007936",
                "metrs": 1
            },
            {
                "size": "280х60х4",
                "koef": "0.01055",
                "metrs": 1
            },
            {
                "size": "280х60х5",
                "koef": "0.01315",
                "metrs": 1
            },
            {
                "size": "280х60х6",
                "koef": "0.01573",
                "metrs": 1
            },
            {
                "size": "280х63х2",
                "koef": "0.005354",
                "metrs": 1
            },
            {
                "size": "280х63х3",
                "koef": "0.008007",
                "metrs": 1
            },
            {
                "size": "280х63х4",
                "koef": "0.01064",
                "metrs": 1
            },
            {
                "size": "280х63х5",
                "koef": "0.01327",
                "metrs": 1
            },
            {
                "size": "280х63х6",
                "koef": "0.01587",
                "metrs": 1
            },
            {
                "size": "280х65х2",
                "koef": "0.005385",
                "metrs": 1
            },
            {
                "size": "280х65х3",
                "koef": "0.008054",
                "metrs": 1
            },
            {
                "size": "280х65х4",
                "koef": "0.01071",
                "metrs": 1
            },
            {
                "size": "280х65х5",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": "280х65х6",
                "koef": "0.01597",
                "metrs": 1
            },
            {
                "size": "280х65х8",
                "koef": "0.02116",
                "metrs": 1
            },
            {
                "size": "280х65х10",
                "koef": "0.0263",
                "metrs": 1
            },
            {
                "size": "280х70х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "280х70х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "280х70х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "280х70х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "280х70х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "280х70х8",
                "koef": "0.02148",
                "metrs": 1
            },
            {
                "size": "280х70х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": "280х75х2",
                "koef": "0.005542",
                "metrs": 1
            },
            {
                "size": "280х75х3",
                "koef": "0.00829",
                "metrs": 1
            },
            {
                "size": "280х75х4",
                "koef": "0.01102",
                "metrs": 1
            },
            {
                "size": "280х75х5",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": "280х75х6",
                "koef": "0.01644",
                "metrs": 1
            },
            {
                "size": "280х75х8",
                "koef": "0.02179",
                "metrs": 1
            },
            {
                "size": "280х75х10",
                "koef": "0.02708",
                "metrs": 1
            },
            {
                "size": "280х80х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "280х80х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "280х80х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "280х80х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "280х80х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "280х80х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "280х80х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "280х90х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "280х90х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "280х90х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "280х90х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "280х90х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "280х90х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "280х90х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "280х100х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "280х100х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "280х100х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "280х100х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "280х100х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "280х100х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "280х100х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "280х110х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "280х110х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "280х110х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "280х110х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "280х110х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "280х110х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "280х110х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "280х120х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "280х120х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "280х120х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "280х120х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "280х120х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "280х120х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "280х120х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "280х130х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "280х130х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "280х130х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "280х130х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "280х130х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "280х130х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "280х130х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "280х140х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "280х140х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "280х140х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "280х140х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "280х140х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "280х140х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "280х140х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "280х150х2",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "280х150х3",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "280х150х4",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "280х150х5",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "280х150х6",
                "koef": "0.01997",
                "metrs": 1
            },
            {
                "size": "280х150х8",
                "koef": "0.0265",
                "metrs": 1
            },
            {
                "size": "280х150х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "280х160х2",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "280х160х3",
                "koef": "0.01029",
                "metrs": 1
            },
            {
                "size": "280х160х4",
                "koef": "0.01369",
                "metrs": 1
            },
            {
                "size": "280х160х5",
                "koef": "0.01707",
                "metrs": 1
            },
            {
                "size": "280х160х6",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "280х160х8",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "280х160х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": "280х170х2",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": "280х170х3",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": "280х170х4",
                "koef": "0.014",
                "metrs": 1
            },
            {
                "size": "280х170х5",
                "koef": "0.01747",
                "metrs": 1
            },
            {
                "size": "280х170х6",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": "280х170х8",
                "koef": "0.02776",
                "metrs": 1
            },
            {
                "size": "280х170х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": "280х180х2",
                "koef": "0.007191",
                "metrs": 1
            },
            {
                "size": "280х180х3",
                "koef": "0.01076",
                "metrs": 1
            },
            {
                "size": "280х180х4",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "280х180х5",
                "koef": "0.01786",
                "metrs": 1
            },
            {
                "size": "280х180х6",
                "koef": "0.02138",
                "metrs": 1
            },
            {
                "size": "280х180х8",
                "koef": "0.02839",
                "metrs": 1
            },
            {
                "size": "280х180х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "280х190х2",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "280х190х3",
                "koef": "0.011",
                "metrs": 1
            },
            {
                "size": "280х190х4",
                "koef": "0.01463",
                "metrs": 1
            },
            {
                "size": "280х190х5",
                "koef": "0.01825",
                "metrs": 1
            },
            {
                "size": "280х190х6",
                "koef": "0.02185",
                "metrs": 1
            },
            {
                "size": "280х190х8",
                "koef": "0.02901",
                "metrs": 1
            },
            {
                "size": "280х190х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": "280х200х2",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "280х200х3",
                "koef": "0.01123",
                "metrs": 1
            },
            {
                "size": "280х200х4",
                "koef": "0.01495",
                "metrs": 1
            },
            {
                "size": "280х200х5",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": "280х200х6",
                "koef": "0.02233",
                "metrs": 1
            },
            {
                "size": "280х200х8",
                "koef": "0.02964",
                "metrs": 1
            },
            {
                "size": "280х200х10",
                "koef": "0.0369",
                "metrs": 1
            },
            {
                "size": "280х210х2",
                "koef": "0.007662",
                "metrs": 1
            },
            {
                "size": "280х210х3",
                "koef": "0.01147",
                "metrs": 1
            },
            {
                "size": "280х210х4",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "280х210х5",
                "koef": "0.01904",
                "metrs": 1
            },
            {
                "size": "280х210х6",
                "koef": "0.0228",
                "metrs": 1
            },
            {
                "size": "280х210х8",
                "koef": "0.03027",
                "metrs": 1
            },
            {
                "size": "280х210х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": "280х220х2",
                "koef": "0.007819",
                "metrs": 1
            },
            {
                "size": "280х220х3",
                "koef": "0.0117",
                "metrs": 1
            },
            {
                "size": "280х220х4",
                "koef": "0.01557",
                "metrs": 1
            },
            {
                "size": "280х220х5",
                "koef": "0.01943",
                "metrs": 1
            },
            {
                "size": "280х220х6",
                "koef": "0.02327",
                "metrs": 1
            },
            {
                "size": "280х220х8",
                "koef": "0.0309",
                "metrs": 1
            },
            {
                "size": "280х220х10",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": "280х230х2",
                "koef": "0.007976",
                "metrs": 1
            },
            {
                "size": "280х230х3",
                "koef": "0.01194",
                "metrs": 1
            },
            {
                "size": "280х230х4",
                "koef": "0.01589",
                "metrs": 1
            },
            {
                "size": "280х230х5",
                "koef": "0.01982",
                "metrs": 1
            },
            {
                "size": "280х230х6",
                "koef": "0.02374",
                "metrs": 1
            },
            {
                "size": "280х230х8",
                "koef": "0.03153",
                "metrs": 1
            },
            {
                "size": "280х230х10",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": "280х240х2",
                "koef": "0.008133",
                "metrs": 1
            },
            {
                "size": "280х240х3",
                "koef": "0.01218",
                "metrs": 1
            },
            {
                "size": "280х240х4",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "280х240х5",
                "koef": "0.02021",
                "metrs": 1
            },
            {
                "size": "280х240х6",
                "koef": "0.02421",
                "metrs": 1
            },
            {
                "size": "280х240х8",
                "koef": "0.03215",
                "metrs": 1
            },
            {
                "size": "280х240х10",
                "koef": "0.04003",
                "metrs": 1
            },
            {
                "size": "280х250х2",
                "koef": "0.00829",
                "metrs": 1
            },
            {
                "size": "280х250х3",
                "koef": "0.01241",
                "metrs": 1
            },
            {
                "size": "280х250х4",
                "koef": "0.01652",
                "metrs": 1
            },
            {
                "size": "280х250х5",
                "koef": "0.02061",
                "metrs": 1
            },
            {
                "size": "280х250х6",
                "koef": "0.02468",
                "metrs": 1
            },
            {
                "size": "280х250х8",
                "koef": "0.03278",
                "metrs": 1
            },
            {
                "size": "280х250х10",
                "koef": "0.04082",
                "metrs": 1
            },
            {
                "size": "280х260х2",
                "koef": "0.008447",
                "metrs": 1
            },
            {
                "size": "280х260х3",
                "koef": "0.01265",
                "metrs": 1
            },
            {
                "size": "280х260х4",
                "koef": "0.01683",
                "metrs": 1
            },
            {
                "size": "280х260х5",
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": "280х260х6",
                "koef": "0.02515",
                "metrs": 1
            },
            {
                "size": "280х260х8",
                "koef": "0.03341",
                "metrs": 1
            },
            {
                "size": "280х260х10",
                "koef": "0.04161",
                "metrs": 1
            },
            {
                "size": "280х270х2",
                "koef": "0.008604",
                "metrs": 1
            },
            {
                "size": "280х270х3",
                "koef": "0.01288",
                "metrs": 1
            },
            {
                "size": "280х270х4",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "280х270х5",
                "koef": "0.02139",
                "metrs": 1
            },
            {
                "size": "280х270х6",
                "koef": "0.02562",
                "metrs": 1
            },
            {
                "size": "280х270х8",
                "koef": "0.03404",
                "metrs": 1
            },
            {
                "size": "280х270х10",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": "280х280х2",
                "koef": "0.008761",
                "metrs": 1
            },
            {
                "size": "280х280х3",
                "koef": "0.01312",
                "metrs": 1
            },
            {
                "size": "280х280х4",
                "koef": "0.01746",
                "metrs": 1
            },
            {
                "size": "280х280х5",
                "koef": "0.02178",
                "metrs": 1
            },
            {
                "size": "280х280х6",
                "koef": "0.02609",
                "metrs": 1
            },
            {
                "size": "280х280х8",
                "koef": "0.03467",
                "metrs": 1
            },
            {
                "size": "280х280х10",
                "koef": "0.04318",
                "metrs": 1
            },
            {
                "size": "290х60х2",
                "koef": "0.005464",
                "metrs": 1
            },
            {
                "size": "290х60х3",
                "koef": "0.008172",
                "metrs": 1
            },
            {
                "size": "290х60х4",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": "290х60х5",
                "koef": "0.01354",
                "metrs": 1
            },
            {
                "size": "290х60х6",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "290х63х2",
                "koef": "0.005511",
                "metrs": 1
            },
            {
                "size": "290х63х3",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": "290х63х4",
                "koef": "0.01096",
                "metrs": 1
            },
            {
                "size": "290х63х5",
                "koef": "0.01366",
                "metrs": 1
            },
            {
                "size": "290х63х6",
                "koef": "0.01634",
                "metrs": 1
            },
            {
                "size": "290х65х2",
                "koef": "0.005542",
                "metrs": 1
            },
            {
                "size": "290х65х3",
                "koef": "0.00829",
                "metrs": 1
            },
            {
                "size": "290х65х4",
                "koef": "0.01102",
                "metrs": 1
            },
            {
                "size": "290х65х5",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": "290х65х6",
                "koef": "0.01644",
                "metrs": 1
            },
            {
                "size": "290х65х8",
                "koef": "0.02179",
                "metrs": 1
            },
            {
                "size": "290х70х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "290х70х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "290х70х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "290х70х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "290х70х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "290х70х8",
                "koef": "0.02211",
                "metrs": 1
            },
            {
                "size": "290х70х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": "290х75х2",
                "koef": "0.005699",
                "metrs": 1
            },
            {
                "size": "290х75х3",
                "koef": "0.008525",
                "metrs": 1
            },
            {
                "size": "290х75х4",
                "koef": "0.01134",
                "metrs": 1
            },
            {
                "size": "290х75х5",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "290х75х6",
                "koef": "0.01691",
                "metrs": 1
            },
            {
                "size": "290х75х8",
                "koef": "0.02242",
                "metrs": 1
            },
            {
                "size": "290х75х10",
                "koef": "0.02787",
                "metrs": 1
            },
            {
                "size": "290х80х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "290х80х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "290х80х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "290х80х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "290х80х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "290х80х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "290х80х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "290х90х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "290х90х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "290х90х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "290х90х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "290х90х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "290х90х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "290х90х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "290х100х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "290х100х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "290х100х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "290х100х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "290х100х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "290х100х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "290х100х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "290х110х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "290х110х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "290х110х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "290х110х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "290х110х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "290х110х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "290х110х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "290х120х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "290х120х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "290х120х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "290х120х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "290х120х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "290х120х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "290х120х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "290х130х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "290х130х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "290х130х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "290х130х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "290х130х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "290х130х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "290х130х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "290х140х2",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "290х140х3",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "290х140х4",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "290х140х5",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "290х140х6",
                "koef": "0.01997",
                "metrs": 1
            },
            {
                "size": "290х140х8",
                "koef": "0.0265",
                "metrs": 1
            },
            {
                "size": "290х140х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "290х150х2",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "290х150х3",
                "koef": "0.01029",
                "metrs": 1
            },
            {
                "size": "290х150х4",
                "koef": "0.01369",
                "metrs": 1
            },
            {
                "size": "290х150х5",
                "koef": "0.01707",
                "metrs": 1
            },
            {
                "size": "290х150х6",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "290х150х8",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "290х150х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": "290х160х2",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": "290х160х3",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": "290х160х4",
                "koef": "0.014",
                "metrs": 1
            },
            {
                "size": "290х160х5",
                "koef": "0.01747",
                "metrs": 1
            },
            {
                "size": "290х160х6",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": "290х160х8",
                "koef": "0.02776",
                "metrs": 1
            },
            {
                "size": "290х160х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": "290х170х2",
                "koef": "0.007191",
                "metrs": 1
            },
            {
                "size": "290х170х3",
                "koef": "0.01076",
                "metrs": 1
            },
            {
                "size": "290х170х4",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "290х170х5",
                "koef": "0.01786",
                "metrs": 1
            },
            {
                "size": "290х170х6",
                "koef": "0.02138",
                "metrs": 1
            },
            {
                "size": "290х170х8",
                "koef": "0.02839",
                "metrs": 1
            },
            {
                "size": "290х170х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "290х180х2",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "290х180х3",
                "koef": "0.011",
                "metrs": 1
            },
            {
                "size": "290х180х4",
                "koef": "0.01463",
                "metrs": 1
            },
            {
                "size": "290х180х5",
                "koef": "0.01825",
                "metrs": 1
            },
            {
                "size": "290х180х6",
                "koef": "0.02185",
                "metrs": 1
            },
            {
                "size": "290х180х8",
                "koef": "0.02901",
                "metrs": 1
            },
            {
                "size": "290х180х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": "290х190х2",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "290х190х3",
                "koef": "0.01123",
                "metrs": 1
            },
            {
                "size": "290х190х4",
                "koef": "0.01495",
                "metrs": 1
            },
            {
                "size": "290х190х5",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": "290х190х6",
                "koef": "0.02233",
                "metrs": 1
            },
            {
                "size": "290х190х8",
                "koef": "0.02964",
                "metrs": 1
            },
            {
                "size": "290х190х10",
                "koef": "0.0369",
                "metrs": 1
            },
            {
                "size": "290х200х2",
                "koef": "0.007662",
                "metrs": 1
            },
            {
                "size": "290х200х3",
                "koef": "0.01147",
                "metrs": 1
            },
            {
                "size": "290х200х4",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "290х200х5",
                "koef": "0.01904",
                "metrs": 1
            },
            {
                "size": "290х200х6",
                "koef": "0.0228",
                "metrs": 1
            },
            {
                "size": "290х200х8",
                "koef": "0.03027",
                "metrs": 1
            },
            {
                "size": "290х200х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": "290х210х2",
                "koef": "0.007819",
                "metrs": 1
            },
            {
                "size": "290х210х3",
                "koef": "0.0117",
                "metrs": 1
            },
            {
                "size": "290х210х4",
                "koef": "0.01557",
                "metrs": 1
            },
            {
                "size": "290х210х5",
                "koef": "0.01943",
                "metrs": 1
            },
            {
                "size": "290х210х6",
                "koef": "0.02327",
                "metrs": 1
            },
            {
                "size": "290х210х8",
                "koef": "0.0309",
                "metrs": 1
            },
            {
                "size": "290х210х10",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": "290х220х2",
                "koef": "0.007976",
                "metrs": 1
            },
            {
                "size": "290х220х3",
                "koef": "0.01194",
                "metrs": 1
            },
            {
                "size": "290х220х4",
                "koef": "0.01589",
                "metrs": 1
            },
            {
                "size": "290х220х5",
                "koef": "0.01982",
                "metrs": 1
            },
            {
                "size": "290х220х6",
                "koef": "0.02374",
                "metrs": 1
            },
            {
                "size": "290х220х8",
                "koef": "0.03153",
                "metrs": 1
            },
            {
                "size": "290х220х10",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": "290х230х2",
                "koef": "0.008133",
                "metrs": 1
            },
            {
                "size": "290х230х3",
                "koef": "0.01218",
                "metrs": 1
            },
            {
                "size": "290х230х4",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "290х230х5",
                "koef": "0.02021",
                "metrs": 1
            },
            {
                "size": "290х230х6",
                "koef": "0.02421",
                "metrs": 1
            },
            {
                "size": "290х230х8",
                "koef": "0.03215",
                "metrs": 1
            },
            {
                "size": "290х230х10",
                "koef": "0.04003",
                "metrs": 1
            },
            {
                "size": "290х240х2",
                "koef": "0.00829",
                "metrs": 1
            },
            {
                "size": "290х240х3",
                "koef": "0.01241",
                "metrs": 1
            },
            {
                "size": "290х240х4",
                "koef": "0.01652",
                "metrs": 1
            },
            {
                "size": "290х240х5",
                "koef": "0.02061",
                "metrs": 1
            },
            {
                "size": "290х240х6",
                "koef": "0.02468",
                "metrs": 1
            },
            {
                "size": "290х240х8",
                "koef": "0.03278",
                "metrs": 1
            },
            {
                "size": "290х240х10",
                "koef": "0.04082",
                "metrs": 1
            },
            {
                "size": "290х250х2",
                "koef": "0.008447",
                "metrs": 1
            },
            {
                "size": "290х250х3",
                "koef": "0.01265",
                "metrs": 1
            },
            {
                "size": "290х250х4",
                "koef": "0.01683",
                "metrs": 1
            },
            {
                "size": "290х250х5",
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": "290х250х6",
                "koef": "0.02515",
                "metrs": 1
            },
            {
                "size": "290х250х8",
                "koef": "0.03341",
                "metrs": 1
            },
            {
                "size": "290х250х10",
                "koef": "0.04161",
                "metrs": 1
            },
            {
                "size": "290х260х2",
                "koef": "0.008604",
                "metrs": 1
            },
            {
                "size": "290х260х3",
                "koef": "0.01288",
                "metrs": 1
            },
            {
                "size": "290х260х4",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "290х260х5",
                "koef": "0.02139",
                "metrs": 1
            },
            {
                "size": "290х260х6",
                "koef": "0.02562",
                "metrs": 1
            },
            {
                "size": "290х260х8",
                "koef": "0.03404",
                "metrs": 1
            },
            {
                "size": "290х260х10",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": "290х270х2",
                "koef": "0.008761",
                "metrs": 1
            },
            {
                "size": "290х270х3",
                "koef": "0.01312",
                "metrs": 1
            },
            {
                "size": "290х270х4",
                "koef": "0.01746",
                "metrs": 1
            },
            {
                "size": "290х270х5",
                "koef": "0.02178",
                "metrs": 1
            },
            {
                "size": "290х270х6",
                "koef": "0.02609",
                "metrs": 1
            },
            {
                "size": "290х270х8",
                "koef": "0.03467",
                "metrs": 1
            },
            {
                "size": "290х270х10",
                "koef": "0.04318",
                "metrs": 1
            },
            {
                "size": "290х280х2",
                "koef": "0.008918",
                "metrs": 1
            },
            {
                "size": "290х280х3",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": "290х280х4",
                "koef": "0.01777",
                "metrs": 1
            },
            {
                "size": "290х280х5",
                "koef": "0.02218",
                "metrs": 1
            },
            {
                "size": "290х280х6",
                "koef": "0.02656",
                "metrs": 1
            },
            {
                "size": "290х280х8",
                "koef": "0.03529",
                "metrs": 1
            },
            {
                "size": "290х280х10",
                "koef": "0.04396",
                "metrs": 1
            },
            {
                "size": "290х290х2",
                "koef": "0.009075",
                "metrs": 1
            },
            {
                "size": "290х290х3",
                "koef": "0.01359",
                "metrs": 1
            },
            {
                "size": "290х290х4",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "290х290х5",
                "koef": "0.02257",
                "metrs": 1
            },
            {
                "size": "290х290х6",
                "koef": "0.02704",
                "metrs": 1
            },
            {
                "size": "290х290х8",
                "koef": "0.03592",
                "metrs": 1
            },
            {
                "size": "290х290х10",
                "koef": "0.04475",
                "metrs": 1
            },
            {
                "size": "300х60х2",
                "koef": "0.005621",
                "metrs": 1
            },
            {
                "size": "300х60х3",
                "koef": "0.008407",
                "metrs": 1
            },
            {
                "size": "300х60х4",
                "koef": "0.01118",
                "metrs": 1
            },
            {
                "size": "300х60х5",
                "koef": "0.01393",
                "metrs": 1
            },
            {
                "size": "300х60х6",
                "koef": "0.01667",
                "metrs": 1
            },
            {
                "size": "300х63х2",
                "koef": "0.005668",
                "metrs": 1
            },
            {
                "size": "300х63х3",
                "koef": "0.008478",
                "metrs": 1
            },
            {
                "size": "300х63х4",
                "koef": "0.01127",
                "metrs": 1
            },
            {
                "size": "300х63х5",
                "koef": "0.01405",
                "metrs": 1
            },
            {
                "size": "300х63х6",
                "koef": "0.01681",
                "metrs": 1
            },
            {
                "size": "300х65х2",
                "koef": "0.005699",
                "metrs": 1
            },
            {
                "size": "300х65х3",
                "koef": "0.008525",
                "metrs": 1
            },
            {
                "size": "300х65х4",
                "koef": "0.01134",
                "metrs": 1
            },
            {
                "size": "300х65х5",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "300х65х6",
                "koef": "0.01691",
                "metrs": 1
            },
            {
                "size": "300х65х8",
                "koef": "0.02242",
                "metrs": 1
            },
            {
                "size": "300х70х2",
                "koef": "0.005778",
                "metrs": 1
            },
            {
                "size": "300х70х3",
                "koef": "0.008643",
                "metrs": 1
            },
            {
                "size": "300х70х4",
                "koef": "0.01149",
                "metrs": 1
            },
            {
                "size": "300х70х5",
                "koef": "0.01433",
                "metrs": 1
            },
            {
                "size": "300х70х6",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "300х70х8",
                "koef": "0.02273",
                "metrs": 1
            },
            {
                "size": "300х70х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": "300х75х2",
                "koef": "0.005856",
                "metrs": 1
            },
            {
                "size": "300х75х3",
                "koef": "0.008761",
                "metrs": 1
            },
            {
                "size": "300х75х4",
                "koef": "0.01165",
                "metrs": 1
            },
            {
                "size": "300х75х5",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": "300х75х6",
                "koef": "0.01738",
                "metrs": 1
            },
            {
                "size": "300х75х8",
                "koef": "0.02305",
                "metrs": 1
            },
            {
                "size": "300х75х10",
                "koef": "0.02865",
                "metrs": 1
            },
            {
                "size": "300х80х2",
                "koef": "0.005935",
                "metrs": 1
            },
            {
                "size": "300х80х3",
                "koef": "0.008878",
                "metrs": 1
            },
            {
                "size": "300х80х4",
                "koef": "0.01181",
                "metrs": 1
            },
            {
                "size": "300х80х5",
                "koef": "0.01472",
                "metrs": 1
            },
            {
                "size": "300х80х6",
                "koef": "0.01762",
                "metrs": 1
            },
            {
                "size": "300х80х8",
                "koef": "0.02336",
                "metrs": 1
            },
            {
                "size": "300х80х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": "300х90х2",
                "koef": "0.006092",
                "metrs": 1
            },
            {
                "size": "300х90х3",
                "koef": "0.009114",
                "metrs": 1
            },
            {
                "size": "300х90х4",
                "koef": "0.01212",
                "metrs": 1
            },
            {
                "size": "300х90х5",
                "koef": "0.01511",
                "metrs": 1
            },
            {
                "size": "300х90х6",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "300х90х8",
                "koef": "0.02399",
                "metrs": 1
            },
            {
                "size": "300х90х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": "300х100х2",
                "koef": "0.006249",
                "metrs": 1
            },
            {
                "size": "300х100х3",
                "koef": "0.009349",
                "metrs": 1
            },
            {
                "size": "300х100х4",
                "koef": "0.01243",
                "metrs": 1
            },
            {
                "size": "300х100х5",
                "koef": "0.0155",
                "metrs": 1
            },
            {
                "size": "300х100х6",
                "koef": "0.01856",
                "metrs": 1
            },
            {
                "size": "300х100х8",
                "koef": "0.02462",
                "metrs": 1
            },
            {
                "size": "300х100х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": "300х110х2",
                "koef": "0.006406",
                "metrs": 1
            },
            {
                "size": "300х110х3",
                "koef": "0.009585",
                "metrs": 1
            },
            {
                "size": "300х110х4",
                "koef": "0.01275",
                "metrs": 1
            },
            {
                "size": "300х110х5",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "300х110х6",
                "koef": "0.01903",
                "metrs": 1
            },
            {
                "size": "300х110х8",
                "koef": "0.02525",
                "metrs": 1
            },
            {
                "size": "300х110х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": "300х120х2",
                "koef": "0.006563",
                "metrs": 1
            },
            {
                "size": "300х120х3",
                "koef": "0.00982",
                "metrs": 1
            },
            {
                "size": "300х120х4",
                "koef": "0.01306",
                "metrs": 1
            },
            {
                "size": "300х120х5",
                "koef": "0.01629",
                "metrs": 1
            },
            {
                "size": "300х120х6",
                "koef": "0.0195",
                "metrs": 1
            },
            {
                "size": "300х120х8",
                "koef": "0.02587",
                "metrs": 1
            },
            {
                "size": "300х120х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": "300х130х2",
                "koef": "0.00672",
                "metrs": 1
            },
            {
                "size": "300х130х3",
                "koef": "0.01006",
                "metrs": 1
            },
            {
                "size": "300х130х4",
                "koef": "0.01338",
                "metrs": 1
            },
            {
                "size": "300х130х5",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "300х130х6",
                "koef": "0.01997",
                "metrs": 1
            },
            {
                "size": "300х130х8",
                "koef": "0.0265",
                "metrs": 1
            },
            {
                "size": "300х130х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": "300х140х2",
                "koef": "0.006877",
                "metrs": 1
            },
            {
                "size": "300х140х3",
                "koef": "0.01029",
                "metrs": 1
            },
            {
                "size": "300х140х4",
                "koef": "0.01369",
                "metrs": 1
            },
            {
                "size": "300х140х5",
                "koef": "0.01707",
                "metrs": 1
            },
            {
                "size": "300х140х6",
                "koef": "0.02044",
                "metrs": 1
            },
            {
                "size": "300х140х8",
                "koef": "0.02713",
                "metrs": 1
            },
            {
                "size": "300х140х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": "300х150х2",
                "koef": "0.007034",
                "metrs": 1
            },
            {
                "size": "300х150х3",
                "koef": "0.01053",
                "metrs": 1
            },
            {
                "size": "300х150х4",
                "koef": "0.014",
                "metrs": 1
            },
            {
                "size": "300х150х5",
                "koef": "0.01747",
                "metrs": 1
            },
            {
                "size": "300х150х6",
                "koef": "0.02091",
                "metrs": 1
            },
            {
                "size": "300х150х8",
                "koef": "0.02776",
                "metrs": 1
            },
            {
                "size": "300х150х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": "300х160х2",
                "koef": "0.007191",
                "metrs": 1
            },
            {
                "size": "300х160х3",
                "koef": "0.01076",
                "metrs": 1
            },
            {
                "size": "300х160х4",
                "koef": "0.01432",
                "metrs": 1
            },
            {
                "size": "300х160х5",
                "koef": "0.01786",
                "metrs": 1
            },
            {
                "size": "300х160х6",
                "koef": "0.02138",
                "metrs": 1
            },
            {
                "size": "300х160х8",
                "koef": "0.02839",
                "metrs": 1
            },
            {
                "size": "300х160х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": "300х170х2",
                "koef": "0.007348",
                "metrs": 1
            },
            {
                "size": "300х170х3",
                "koef": "0.011",
                "metrs": 1
            },
            {
                "size": "300х170х4",
                "koef": "0.01463",
                "metrs": 1
            },
            {
                "size": "300х170х5",
                "koef": "0.01825",
                "metrs": 1
            },
            {
                "size": "300х170х6",
                "koef": "0.02185",
                "metrs": 1
            },
            {
                "size": "300х170х8",
                "koef": "0.02901",
                "metrs": 1
            },
            {
                "size": "300х170х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": "300х180х2",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "300х180х3",
                "koef": "0.01123",
                "metrs": 1
            },
            {
                "size": "300х180х4",
                "koef": "0.01495",
                "metrs": 1
            },
            {
                "size": "300х180х5",
                "koef": "0.01864",
                "metrs": 1
            },
            {
                "size": "300х180х6",
                "koef": "0.02233",
                "metrs": 1
            },
            {
                "size": "300х180х8",
                "koef": "0.02964",
                "metrs": 1
            },
            {
                "size": "300х180х10",
                "koef": "0.0369",
                "metrs": 1
            },
            {
                "size": "300х190х2",
                "koef": "0.007662",
                "metrs": 1
            },
            {
                "size": "300х190х3",
                "koef": "0.01147",
                "metrs": 1
            },
            {
                "size": "300х190х4",
                "koef": "0.01526",
                "metrs": 1
            },
            {
                "size": "300х190х5",
                "koef": "0.01904",
                "metrs": 1
            },
            {
                "size": "300х190х6",
                "koef": "0.0228",
                "metrs": 1
            },
            {
                "size": "300х190х8",
                "koef": "0.03027",
                "metrs": 1
            },
            {
                "size": "300х190х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": "300х200х2",
                "koef": "0.007819",
                "metrs": 1
            },
            {
                "size": "300х200х3",
                "koef": "0.0117",
                "metrs": 1
            },
            {
                "size": "300х200х4",
                "koef": "0.01557",
                "metrs": 1
            },
            {
                "size": "300х200х5",
                "koef": "0.01943",
                "metrs": 1
            },
            {
                "size": "300х200х6",
                "koef": "0.02327",
                "metrs": 1
            },
            {
                "size": "300х200х8",
                "koef": "0.0309",
                "metrs": 1
            },
            {
                "size": "300х200х10",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": "300х210х2",
                "koef": "0.007976",
                "metrs": 1
            },
            {
                "size": "300х210х3",
                "koef": "0.01194",
                "metrs": 1
            },
            {
                "size": "300х210х4",
                "koef": "0.01589",
                "metrs": 1
            },
            {
                "size": "300х210х5",
                "koef": "0.01982",
                "metrs": 1
            },
            {
                "size": "300х210х6",
                "koef": "0.02374",
                "metrs": 1
            },
            {
                "size": "300х210х8",
                "koef": "0.03153",
                "metrs": 1
            },
            {
                "size": "300х210х10",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": "300х220х2",
                "koef": "0.008133",
                "metrs": 1
            },
            {
                "size": "300х220х3",
                "koef": "0.01218",
                "metrs": 1
            },
            {
                "size": "300х220х4",
                "koef": "0.0162",
                "metrs": 1
            },
            {
                "size": "300х220х5",
                "koef": "0.02021",
                "metrs": 1
            },
            {
                "size": "300х220х6",
                "koef": "0.02421",
                "metrs": 1
            },
            {
                "size": "300х220х8",
                "koef": "0.03215",
                "metrs": 1
            },
            {
                "size": "300х220х10",
                "koef": "0.04003",
                "metrs": 1
            },
            {
                "size": "300х230х2",
                "koef": "0.00829",
                "metrs": 1
            },
            {
                "size": "300х230х3",
                "koef": "0.01241",
                "metrs": 1
            },
            {
                "size": "300х230х4",
                "koef": "0.01652",
                "metrs": 1
            },
            {
                "size": "300х230х5",
                "koef": "0.02061",
                "metrs": 1
            },
            {
                "size": "300х230х6",
                "koef": "0.02468",
                "metrs": 1
            },
            {
                "size": "300х230х8",
                "koef": "0.03278",
                "metrs": 1
            },
            {
                "size": "300х230х10",
                "koef": "0.04082",
                "metrs": 1
            },
            {
                "size": "300х240х2",
                "koef": "0.008447",
                "metrs": 1
            },
            {
                "size": "300х240х3",
                "koef": "0.01265",
                "metrs": 1
            },
            {
                "size": "300х240х4",
                "koef": "0.01683",
                "metrs": 1
            },
            {
                "size": "300х240х5",
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": "300х240х6",
                "koef": "0.02515",
                "metrs": 1
            },
            {
                "size": "300х240х8",
                "koef": "0.03341",
                "metrs": 1
            },
            {
                "size": "300х240х10",
                "koef": "0.04161",
                "metrs": 1
            },
            {
                "size": "300х250х2",
                "koef": "0.008604",
                "metrs": 1
            },
            {
                "size": "300х250х3",
                "koef": "0.01288",
                "metrs": 1
            },
            {
                "size": "300х250х4",
                "koef": "0.01714",
                "metrs": 1
            },
            {
                "size": "300х250х5",
                "koef": "0.02139",
                "metrs": 1
            },
            {
                "size": "300х250х6",
                "koef": "0.02562",
                "metrs": 1
            },
            {
                "size": "300х250х8",
                "koef": "0.03404",
                "metrs": 1
            },
            {
                "size": "300х250х10",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": "300х260х2",
                "koef": "0.008761",
                "metrs": 1
            },
            {
                "size": "300х260х3",
                "koef": "0.01312",
                "metrs": 1
            },
            {
                "size": "300х260х4",
                "koef": "0.01746",
                "metrs": 1
            },
            {
                "size": "300х260х5",
                "koef": "0.02178",
                "metrs": 1
            },
            {
                "size": "300х260х6",
                "koef": "0.02609",
                "metrs": 1
            },
            {
                "size": "300х260х8",
                "koef": "0.03467",
                "metrs": 1
            },
            {
                "size": "300х260х10",
                "koef": "0.04318",
                "metrs": 1
            },
            {
                "size": "300х270х2",
                "koef": "0.008918",
                "metrs": 1
            },
            {
                "size": "300х270х3",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": "300х270х4",
                "koef": "0.01777",
                "metrs": 1
            },
            {
                "size": "300х270х5",
                "koef": "0.02218",
                "metrs": 1
            },
            {
                "size": "300х270х6",
                "koef": "0.02656",
                "metrs": 1
            },
            {
                "size": "300х270х8",
                "koef": "0.03529",
                "metrs": 1
            },
            {
                "size": "300х270х10",
                "koef": "0.04396",
                "metrs": 1
            },
            {
                "size": "300х280х2",
                "koef": "0.009075",
                "metrs": 1
            },
            {
                "size": "300х280х3",
                "koef": "0.01359",
                "metrs": 1
            },
            {
                "size": "300х280х4",
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": "300х280х5",
                "koef": "0.02257",
                "metrs": 1
            },
            {
                "size": "300х280х6",
                "koef": "0.02704",
                "metrs": 1
            },
            {
                "size": "300х280х8",
                "koef": "0.03592",
                "metrs": 1
            },
            {
                "size": "300х280х10",
                "koef": "0.04475",
                "metrs": 1
            },
            {
                "size": "300х290х2",
                "koef": "0.009232",
                "metrs": 1
            },
            {
                "size": "300х290х3",
                "koef": "0.01382",
                "metrs": 1
            },
            {
                "size": "300х290х4",
                "koef": "0.0184",
                "metrs": 1
            },
            {
                "size": "300х290х5",
                "koef": "0.02296",
                "metrs": 1
            },
            {
                "size": "300х290х6",
                "koef": "0.02751",
                "metrs": 1
            },
            {
                "size": "300х290х8",
                "koef": "0.03655",
                "metrs": 1
            },
            {
                "size": "300х290х10",
                "koef": "0.04553",
                "metrs": 1
            },
            {
                "size": "300х300х2",
                "koef": "0.009389",
                "metrs": 1
            },
            {
                "size": "300х300х3",
                "koef": "0.01406",
                "metrs": 1
            },
            {
                "size": "300х300х4",
                "koef": "0.01871",
                "metrs": 1
            },
            {
                "size": "300х300х5",
                "koef": "0.02335",
                "metrs": 1
            },
            {
                "size": "300х300х6",
                "koef": "0.02798",
                "metrs": 1
            },
            {
                "size": "300х300х8",
                "koef": "0.03718",
                "metrs": 1
            },
            {
                "size": "300х300х10",
                "koef": "0.04631",
                "metrs": 1
            }
        ],
        "Уголок гнутый оцинкованный": [
            {
                "size": " 15х15х0,5",
                "koef": "0.000119",
                "metrs": 1
            },
            {
                "size": " 15х15х0,55",
                "koef": "0.000131",
                "metrs": 1
            },
            {
                "size": " 15х15х0,6",
                "koef": "0.000143",
                "metrs": 1
            },
            {
                "size": " 15х15х0,7",
                "koef": "0.000166",
                "metrs": 1
            },
            {
                "size": " 15х15х0,8",
                "koef": "0.000189",
                "metrs": 1
            },
            {
                "size": " 15х15х0,9",
                "koef": "0.000212",
                "metrs": 1
            },
            {
                "size": " 15х15х1",
                "koef": "0.000234",
                "metrs": 1
            },
            {
                "size": " 15х15х1,2",
                "koef": "0.000279",
                "metrs": 1
            },
            {
                "size": " 15х15х1,5",
                "koef": "0.000346",
                "metrs": 1
            },
            {
                "size": " 15х15х1,8",
                "koef": "0.00041",
                "metrs": 1
            },
            {
                "size": " 15х15х2",
                "koef": "0.000453",
                "metrs": 1
            },
            {
                "size": " 15х15х2,5",
                "koef": "0.000556",
                "metrs": 1
            },
            {
                "size": " 15х15х3",
                "koef": "0.000655",
                "metrs": 1
            },
            {
                "size": " 15х15х3,5",
                "koef": "0.00075",
                "metrs": 1
            },
            {
                "size": " 15х15х4",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 15х30х1",
                "koef": "0.000356",
                "metrs": 1
            },
            {
                "size": " 15х30х1,2",
                "koef": "0.000425",
                "metrs": 1
            },
            {
                "size": " 15х30х1,5",
                "koef": "0.000528",
                "metrs": 1
            },
            {
                "size": " 15х30х1,8",
                "koef": "0.000629",
                "metrs": 1
            },
            {
                "size": " 15х30х2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": " 15х30х2,5",
                "koef": "0.000859",
                "metrs": 1
            },
            {
                "size": " 15х30х3",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 15х30х3,5",
                "koef": "0.001174",
                "metrs": 1
            },
            {
                "size": " 15х35х1",
                "koef": "0.000396",
                "metrs": 1
            },
            {
                "size": " 15х35х1,2",
                "koef": "0.000473",
                "metrs": 1
            },
            {
                "size": " 15х35х1,5",
                "koef": "0.000588",
                "metrs": 1
            },
            {
                "size": " 15х35х1,8",
                "koef": "0.000701",
                "metrs": 1
            },
            {
                "size": " 15х35х2",
                "koef": "0.000776",
                "metrs": 1
            },
            {
                "size": " 15х35х2,5",
                "koef": "0.00096",
                "metrs": 1
            },
            {
                "size": " 15х35х3",
                "koef": "0.00114",
                "metrs": 1
            },
            {
                "size": " 15х35х3,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 15х40х1",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": " 15х40х1,2",
                "koef": "0.000522",
                "metrs": 1
            },
            {
                "size": " 15х40х1,5",
                "koef": "0.000649",
                "metrs": 1
            },
            {
                "size": " 15х40х1,8",
                "koef": "0.000774",
                "metrs": 1
            },
            {
                "size": " 15х40х2",
                "koef": "0.000857",
                "metrs": 1
            },
            {
                "size": " 15х40х2,5",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 15х40х3",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 15х40х3,5",
                "koef": "0.001457",
                "metrs": 1
            },
            {
                "size": " 15х45х1",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": " 15х45х1,2",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": " 15х45х1,5",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 15х45х1,8",
                "koef": "0.000847",
                "metrs": 1
            },
            {
                "size": " 15х45х2",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 15х45х2,5",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 15х45х3",
                "koef": "0.001383",
                "metrs": 1
            },
            {
                "size": " 15х45х3,5",
                "koef": "0.001599",
                "metrs": 1
            },
            {
                "size": " 15х50х1",
                "koef": "0.000517",
                "metrs": 1
            },
            {
                "size": " 15х50х1,2",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 15х50х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 15х50х1,8",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": " 15х50х2",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 15х50х2,5",
                "koef": "0.001263",
                "metrs": 1
            },
            {
                "size": " 15х50х3",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 15х50х3,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 15х55х1",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 15х55х1,2",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 15х55х1,5",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 15х55х1,8",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 15х55х2",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 15х55х2,5",
                "koef": "0.001364",
                "metrs": 1
            },
            {
                "size": " 15х55х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 15х55х3,5",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": " 15х60х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 15х60х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 15х60х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 15х60х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 15х60х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 15х60х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 15х60х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 15х60х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 15х65х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 15х65х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 15х65х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 15х65х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 15х65х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 15х65х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 15х65х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 15х65х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 20х20х0,5",
                "koef": "0.00016",
                "metrs": 1
            },
            {
                "size": " 20х20х0,55",
                "koef": "0.000175",
                "metrs": 1
            },
            {
                "size": " 20х20х0,6",
                "koef": "0.000191",
                "metrs": 1
            },
            {
                "size": " 20х20х0,7",
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": " 20х20х0,8",
                "koef": "0.000254",
                "metrs": 1
            },
            {
                "size": " 20х20х0,9",
                "koef": "0.000285",
                "metrs": 1
            },
            {
                "size": " 20х20х1",
                "koef": "0.000315",
                "metrs": 1
            },
            {
                "size": " 20х20х1,2",
                "koef": "0.000376",
                "metrs": 1
            },
            {
                "size": " 20х20х1,5",
                "koef": "0.000467",
                "metrs": 1
            },
            {
                "size": " 20х20х1,8",
                "koef": "0.000556",
                "metrs": 1
            },
            {
                "size": " 20х20х2",
                "koef": "0.000614",
                "metrs": 1
            },
            {
                "size": " 20х20х2,5",
                "koef": "0.000758",
                "metrs": 1
            },
            {
                "size": " 20х20х3",
                "koef": "0.000897",
                "metrs": 1
            },
            {
                "size": " 20х20х3,5",
                "koef": "0.001033",
                "metrs": 1
            },
            {
                "size": " 20х20х4",
                "koef": "0.001164",
                "metrs": 1
            },
            {
                "size": " 20х30х1",
                "koef": "0.000396",
                "metrs": 1
            },
            {
                "size": " 20х30х1,2",
                "koef": "0.000473",
                "metrs": 1
            },
            {
                "size": " 20х30х1,5",
                "koef": "0.000588",
                "metrs": 1
            },
            {
                "size": " 20х30х1,8",
                "koef": "0.000701",
                "metrs": 1
            },
            {
                "size": " 20х30х2",
                "koef": "0.000776",
                "metrs": 1
            },
            {
                "size": " 20х30х2,5",
                "koef": "0.00096",
                "metrs": 1
            },
            {
                "size": " 20х30х3",
                "koef": "0.00114",
                "metrs": 1
            },
            {
                "size": " 20х30х3,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 20х35х1",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": " 20х35х1,2",
                "koef": "0.000522",
                "metrs": 1
            },
            {
                "size": " 20х35х1,5",
                "koef": "0.000649",
                "metrs": 1
            },
            {
                "size": " 20х35х1,8",
                "koef": "0.000774",
                "metrs": 1
            },
            {
                "size": " 20х35х2",
                "koef": "0.000857",
                "metrs": 1
            },
            {
                "size": " 20х35х2,5",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 20х35х3",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 20х35х3,5",
                "koef": "0.001457",
                "metrs": 1
            },
            {
                "size": " 20х40х1",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": " 20х40х1,2",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": " 20х40х1,5",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 20х40х1,8",
                "koef": "0.000847",
                "metrs": 1
            },
            {
                "size": " 20х40х2",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 20х40х2,5",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 20х40х3",
                "koef": "0.001383",
                "metrs": 1
            },
            {
                "size": " 20х40х3,5",
                "koef": "0.001599",
                "metrs": 1
            },
            {
                "size": " 20х45х1",
                "koef": "0.000517",
                "metrs": 1
            },
            {
                "size": " 20х45х1,2",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 20х45х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 20х45х1,8",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": " 20х45х2",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 20х45х2,5",
                "koef": "0.001263",
                "metrs": 1
            },
            {
                "size": " 20х45х3",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 20х45х3,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 20х50х1",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 20х50х1,2",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 20х50х1,5",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 20х50х1,8",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 20х50х2",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 20х50х2,5",
                "koef": "0.001364",
                "metrs": 1
            },
            {
                "size": " 20х50х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 20х50х3,5",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": " 20х55х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 20х55х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 20х55х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 20х55х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 20х55х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 20х55х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 20х55х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 20х55х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 20х60х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 20х60х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 20х60х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 20х60х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 20х60х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 20х60х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 20х60х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 20х60х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 20х65х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 20х65х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 20х65х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 20х65х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 20х65х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 20х65х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 20х65х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 20х65х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 25х25х0,5",
                "koef": "0.0002",
                "metrs": 1
            },
            {
                "size": " 25х25х0,55",
                "koef": "0.00022",
                "metrs": 1
            },
            {
                "size": " 25х25х0,6",
                "koef": "0.00024",
                "metrs": 1
            },
            {
                "size": " 25х25х0,7",
                "koef": "0.000279",
                "metrs": 1
            },
            {
                "size": " 25х25х0,8",
                "koef": "0.000318",
                "metrs": 1
            },
            {
                "size": " 25х25х0,9",
                "koef": "0.000357",
                "metrs": 1
            },
            {
                "size": " 25х25х1",
                "koef": "0.000396",
                "metrs": 1
            },
            {
                "size": " 25х25х1,2",
                "koef": "0.000473",
                "metrs": 1
            },
            {
                "size": " 25х25х1,5",
                "koef": "0.000588",
                "metrs": 1
            },
            {
                "size": " 25х25х1,8",
                "koef": "0.000701",
                "metrs": 1
            },
            {
                "size": " 25х25х2",
                "koef": "0.000776",
                "metrs": 1
            },
            {
                "size": " 25х25х2,5",
                "koef": "0.00096",
                "metrs": 1
            },
            {
                "size": " 25х25х3",
                "koef": "0.00114",
                "metrs": 1
            },
            {
                "size": " 25х25х3,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 25х25х4",
                "koef": "0.001488",
                "metrs": 1
            },
            {
                "size": " 25х30х1",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": " 25х30х1,2",
                "koef": "0.000522",
                "metrs": 1
            },
            {
                "size": " 25х30х1,5",
                "koef": "0.000649",
                "metrs": 1
            },
            {
                "size": " 25х30х1,8",
                "koef": "0.000774",
                "metrs": 1
            },
            {
                "size": " 25х30х2",
                "koef": "0.000857",
                "metrs": 1
            },
            {
                "size": " 25х30х2,5",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 25х30х3",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 25х30х3,5",
                "koef": "0.001457",
                "metrs": 1
            },
            {
                "size": " 25х35х1",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": " 25х35х1,2",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": " 25х35х1,5",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 25х35х1,8",
                "koef": "0.000847",
                "metrs": 1
            },
            {
                "size": " 25х35х2",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 25х35х2,5",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 25х35х3",
                "koef": "0.001383",
                "metrs": 1
            },
            {
                "size": " 25х35х3,5",
                "koef": "0.001599",
                "metrs": 1
            },
            {
                "size": " 25х40х1",
                "koef": "0.000517",
                "metrs": 1
            },
            {
                "size": " 25х40х1,2",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 25х40х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 25х40х1,8",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": " 25х40х2",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 25х40х2,5",
                "koef": "0.001263",
                "metrs": 1
            },
            {
                "size": " 25х40х3",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 25х40х3,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 25х45х1",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 25х45х1,2",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 25х45х1,5",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 25х45х1,8",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 25х45х2",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 25х45х2,5",
                "koef": "0.001364",
                "metrs": 1
            },
            {
                "size": " 25х45х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 25х45х3,5",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": " 25х50х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 25х50х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 25х50х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 25х50х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 25х50х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 25х50х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 25х50х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 25х50х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 25х55х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 25х55х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 25х55х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 25х55х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 25х55х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 25х55х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 25х55х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 25х55х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 25х60х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 25х60х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 25х60х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 25х60х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 25х60х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 25х60х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 25х60х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 25х60х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 25х65х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 25х65х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 25х65х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 25х65х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 25х65х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 25х65х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 25х65х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 25х65х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 28х28х1,5",
                "koef": "0.000661",
                "metrs": 1
            },
            {
                "size": " 28х28х2",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 28х28х2,5",
                "koef": "0.001081",
                "metrs": 1
            },
            {
                "size": " 28х28х3",
                "koef": "0.001286",
                "metrs": 1
            },
            {
                "size": " 30х15х0,5",
                "koef": "0.00018",
                "metrs": 1
            },
            {
                "size": " 30х15х0,55",
                "koef": "0.000198",
                "metrs": 1
            },
            {
                "size": " 30х15х0,6",
                "koef": "0.000215",
                "metrs": 1
            },
            {
                "size": " 30х15х0,7",
                "koef": "0.000251",
                "metrs": 1
            },
            {
                "size": " 30х15х0,8",
                "koef": "0.000286",
                "metrs": 1
            },
            {
                "size": " 30х15х1",
                "koef": "0.000356",
                "metrs": 1
            },
            {
                "size": " 30х15х1,2",
                "koef": "0.000425",
                "metrs": 1
            },
            {
                "size": " 30х15х1,5",
                "koef": "0.000528",
                "metrs": 1
            },
            {
                "size": " 30х15х1,8",
                "koef": "0.000629",
                "metrs": 1
            },
            {
                "size": " 30х15х2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": " 30х15х2,5",
                "koef": "0.000859",
                "metrs": 1
            },
            {
                "size": " 30х15х3",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 30х15х3,5",
                "koef": "0.001174",
                "metrs": 1
            },
            {
                "size": " 30х15х4",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": " 30х20х0,5",
                "koef": "0.0002",
                "metrs": 1
            },
            {
                "size": " 30х20х0,55",
                "koef": "0.00022",
                "metrs": 1
            },
            {
                "size": " 30х20х0,6",
                "koef": "0.00024",
                "metrs": 1
            },
            {
                "size": " 30х20х0,7",
                "koef": "0.000279",
                "metrs": 1
            },
            {
                "size": " 30х20х0,8",
                "koef": "0.000318",
                "metrs": 1
            },
            {
                "size": " 30х20х1",
                "koef": "0.000396",
                "metrs": 1
            },
            {
                "size": " 30х20х1,2",
                "koef": "0.000473",
                "metrs": 1
            },
            {
                "size": " 30х20х1,5",
                "koef": "0.000588",
                "metrs": 1
            },
            {
                "size": " 30х20х1,8",
                "koef": "0.000701",
                "metrs": 1
            },
            {
                "size": " 30х20х2",
                "koef": "0.000776",
                "metrs": 1
            },
            {
                "size": " 30х20х2,5",
                "koef": "0.00096",
                "metrs": 1
            },
            {
                "size": " 30х20х3",
                "koef": "0.00114",
                "metrs": 1
            },
            {
                "size": " 30х20х3,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 30х20х4",
                "koef": "0.001488",
                "metrs": 1
            },
            {
                "size": " 30х25х0,5",
                "koef": "0.00022",
                "metrs": 1
            },
            {
                "size": " 30х25х0,55",
                "koef": "0.000242",
                "metrs": 1
            },
            {
                "size": " 30х25х0,6",
                "koef": "0.000264",
                "metrs": 1
            },
            {
                "size": " 30х25х0,7",
                "koef": "0.000307",
                "metrs": 1
            },
            {
                "size": " 30х25х0,8",
                "koef": "0.000351",
                "metrs": 1
            },
            {
                "size": " 30х25х0,9",
                "koef": "0.000394",
                "metrs": 1
            },
            {
                "size": " 30х25х1",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": " 30х25х1,2",
                "koef": "0.000522",
                "metrs": 1
            },
            {
                "size": " 30х25х1,5",
                "koef": "0.000649",
                "metrs": 1
            },
            {
                "size": " 30х25х1,8",
                "koef": "0.000774",
                "metrs": 1
            },
            {
                "size": " 30х25х2",
                "koef": "0.000857",
                "metrs": 1
            },
            {
                "size": " 30х25х2,5",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 30х25х3",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 30х25х3,5",
                "koef": "0.001457",
                "metrs": 1
            },
            {
                "size": " 30х25х4",
                "koef": "0.001649",
                "metrs": 1
            },
            {
                "size": " 30х30х0,5",
                "koef": "0.000241",
                "metrs": 1
            },
            {
                "size": " 30х30х0,55",
                "koef": "0.000264",
                "metrs": 1
            },
            {
                "size": " 30х30х0,6",
                "koef": "0.000288",
                "metrs": 1
            },
            {
                "size": " 30х30х0,7",
                "koef": "0.000336",
                "metrs": 1
            },
            {
                "size": " 30х30х0,8",
                "koef": "0.000383",
                "metrs": 1
            },
            {
                "size": " 30х30х0,9",
                "koef": "0.00043",
                "metrs": 1
            },
            {
                "size": " 30х30х1",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": " 30х30х1,2",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": " 30х30х1,5",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 30х30х1,8",
                "koef": "0.000847",
                "metrs": 1
            },
            {
                "size": " 30х30х2",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 30х30х2,5",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 30х30х3",
                "koef": "0.001383",
                "metrs": 1
            },
            {
                "size": " 30х30х3,5",
                "koef": "0.001599",
                "metrs": 1
            },
            {
                "size": " 30х30х4",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": " 30х35х1",
                "koef": "0.000517",
                "metrs": 1
            },
            {
                "size": " 30х35х1,2",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 30х35х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 30х35х1,8",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": " 30х35х2",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 30х35х2,5",
                "koef": "0.001263",
                "metrs": 1
            },
            {
                "size": " 30х35х3",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 30х35х3,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 30х40х1",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 30х40х1,2",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 30х40х1,5",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 30х40х1,8",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 30х40х2",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 30х40х2,5",
                "koef": "0.001364",
                "metrs": 1
            },
            {
                "size": " 30х40х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 30х40х3,5",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": " 30х45х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 30х45х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 30х45х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 30х45х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 30х45х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 30х45х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 30х45х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 30х45х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 30х50х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 30х50х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 30х50х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 30х50х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 30х50х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 30х50х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 30х50х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 30х50х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 30х55х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 30х55х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 30х55х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 30х55х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 30х55х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 30х55х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 30х55х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 30х55х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 30х60х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 30х60х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 30х60х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 30х60х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 30х60х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 30х60х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 30х60х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 30х60х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 30х65х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 30х65х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 30х65х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 30х65х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 30х65х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 30х65х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 30х65х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 30х65х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 30х70х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 30х70х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 30х70х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 30х70х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 30х70х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 30х70х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 30х70х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 30х70х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 32х15х0,5",
                "koef": "0.000188",
                "metrs": 1
            },
            {
                "size": " 32х15х0,55",
                "koef": "0.000207",
                "metrs": 1
            },
            {
                "size": " 32х15х0,6",
                "koef": "0.000225",
                "metrs": 1
            },
            {
                "size": " 32х15х0,8",
                "koef": "0.000299",
                "metrs": 1
            },
            {
                "size": " 32х15х0,9",
                "koef": "0.000335",
                "metrs": 1
            },
            {
                "size": " 32х15х1",
                "koef": "0.000372",
                "metrs": 1
            },
            {
                "size": " 32х15х1,2",
                "koef": "0.000444",
                "metrs": 1
            },
            {
                "size": " 32х15х1,5",
                "koef": "0.000552",
                "metrs": 1
            },
            {
                "size": " 32х15х2",
                "koef": "0.000728",
                "metrs": 1
            },
            {
                "size": " 32х15х2,5",
                "koef": "0.0009",
                "metrs": 1
            },
            {
                "size": " 32х15х3",
                "koef": "0.001067",
                "metrs": 1
            },
            {
                "size": " 32х20х0,5",
                "koef": "0.000208",
                "metrs": 1
            },
            {
                "size": " 32х20х0,55",
                "koef": "0.000229",
                "metrs": 1
            },
            {
                "size": " 32х20х0,6",
                "koef": "0.000249",
                "metrs": 1
            },
            {
                "size": " 32х20х0,8",
                "koef": "0.000331",
                "metrs": 1
            },
            {
                "size": " 32х20х0,9",
                "koef": "0.000372",
                "metrs": 1
            },
            {
                "size": " 32х20х1",
                "koef": "0.000412",
                "metrs": 1
            },
            {
                "size": " 32х20х1,2",
                "koef": "0.000493",
                "metrs": 1
            },
            {
                "size": " 32х20х1,5",
                "koef": "0.000612",
                "metrs": 1
            },
            {
                "size": " 32х20х2",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 32х20х2,5",
                "koef": "0.001001",
                "metrs": 1
            },
            {
                "size": " 32х20х3",
                "koef": "0.001189",
                "metrs": 1
            },
            {
                "size": " 32х25х0,5",
                "koef": "0.000228",
                "metrs": 1
            },
            {
                "size": " 32х25х0,55",
                "koef": "0.000251",
                "metrs": 1
            },
            {
                "size": " 32х25х0,6",
                "koef": "0.000274",
                "metrs": 1
            },
            {
                "size": " 32х25х0,7",
                "koef": "0.000319",
                "metrs": 1
            },
            {
                "size": " 32х25х0,8",
                "koef": "0.000364",
                "metrs": 1
            },
            {
                "size": " 32х25х0,9",
                "koef": "0.000408",
                "metrs": 1
            },
            {
                "size": " 32х25х1",
                "koef": "0.000453",
                "metrs": 1
            },
            {
                "size": " 32х25х1,2",
                "koef": "0.000541",
                "metrs": 1
            },
            {
                "size": " 32х25х1,5",
                "koef": "0.000673",
                "metrs": 1
            },
            {
                "size": " 32х25х2",
                "koef": "0.000865",
                "metrs": 1
            },
            {
                "size": " 32х25х2,5",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 32х25х3",
                "koef": "0.00131",
                "metrs": 1
            },
            {
                "size": " 32х32х0,5",
                "koef": "0.000257",
                "metrs": 1
            },
            {
                "size": " 32х32х0,55",
                "koef": "0.000282",
                "metrs": 1
            },
            {
                "size": " 32х32х0,6",
                "koef": "0.000308",
                "metrs": 1
            },
            {
                "size": " 32х32х0,7",
                "koef": "0.000358",
                "metrs": 1
            },
            {
                "size": " 32х32х0,8",
                "koef": "0.000409",
                "metrs": 1
            },
            {
                "size": " 32х32х0,9",
                "koef": "0.000459",
                "metrs": 1
            },
            {
                "size": " 32х32х1",
                "koef": "0.000509",
                "metrs": 1
            },
            {
                "size": " 32х32х1,2",
                "koef": "0.000609",
                "metrs": 1
            },
            {
                "size": " 32х32х1,5",
                "koef": "0.000758",
                "metrs": 1
            },
            {
                "size": " 32х32х2",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 32х32х2,5",
                "koef": "0.001243",
                "metrs": 1
            },
            {
                "size": " 32х32х3",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 32х32х4",
                "koef": "0.001941",
                "metrs": 1
            },
            {
                "size": " 33х33х3",
                "koef": "0.001528",
                "metrs": 1
            },
            {
                "size": " 35х15х0,7",
                "koef": "0.000279",
                "metrs": 1
            },
            {
                "size": " 35х15х1",
                "koef": "0.000396",
                "metrs": 1
            },
            {
                "size": " 35х15х1,2",
                "koef": "0.000473",
                "metrs": 1
            },
            {
                "size": " 35х15х1,5",
                "koef": "0.000588",
                "metrs": 1
            },
            {
                "size": " 35х15х1,8",
                "koef": "0.000701",
                "metrs": 1
            },
            {
                "size": " 35х15х2",
                "koef": "0.000776",
                "metrs": 1
            },
            {
                "size": " 35х15х2,5",
                "koef": "0.00096",
                "metrs": 1
            },
            {
                "size": " 35х15х3",
                "koef": "0.00114",
                "metrs": 1
            },
            {
                "size": " 35х15х3,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 35х15х4",
                "koef": "0.001488",
                "metrs": 1
            },
            {
                "size": " 35х20х0,7",
                "koef": "0.000307",
                "metrs": 1
            },
            {
                "size": " 35х20х1",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": " 35х20х1,2",
                "koef": "0.000522",
                "metrs": 1
            },
            {
                "size": " 35х20х1,5",
                "koef": "0.000649",
                "metrs": 1
            },
            {
                "size": " 35х20х1,8",
                "koef": "0.000774",
                "metrs": 1
            },
            {
                "size": " 35х20х2",
                "koef": "0.000857",
                "metrs": 1
            },
            {
                "size": " 35х20х2,5",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 35х20х3",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 35х20х3,5",
                "koef": "0.001457",
                "metrs": 1
            },
            {
                "size": " 35х20х4",
                "koef": "0.001649",
                "metrs": 1
            },
            {
                "size": " 35х25х0,7",
                "koef": "0.000336",
                "metrs": 1
            },
            {
                "size": " 35х25х1",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": " 35х25х1,2",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": " 35х25х1,5",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 35х25х1,8",
                "koef": "0.000847",
                "metrs": 1
            },
            {
                "size": " 35х25х2",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 35х25х2,5",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 35х25х3",
                "koef": "0.001383",
                "metrs": 1
            },
            {
                "size": " 35х25х3,5",
                "koef": "0.001599",
                "metrs": 1
            },
            {
                "size": " 35х25х4",
                "koef": "0.001811",
                "metrs": 1
            },
            {
                "size": " 35х28х0,9",
                "koef": "0.000452",
                "metrs": 1
            },
            {
                "size": " 35х30х1",
                "koef": "0.000517",
                "metrs": 1
            },
            {
                "size": " 35х30х1,2",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 35х30х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 35х30х1,8",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": " 35х30х2",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 35х30х2,5",
                "koef": "0.001263",
                "metrs": 1
            },
            {
                "size": " 35х30х3",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 35х30х3,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 35х35х0,5",
                "koef": "0.000281",
                "metrs": 1
            },
            {
                "size": " 35х35х0,55",
                "koef": "0.000309",
                "metrs": 1
            },
            {
                "size": " 35х35х0,6",
                "koef": "0.000337",
                "metrs": 1
            },
            {
                "size": " 35х35х0,7",
                "koef": "0.000392",
                "metrs": 1
            },
            {
                "size": " 35х35х0,8",
                "koef": "0.000448",
                "metrs": 1
            },
            {
                "size": " 35х35х0,9",
                "koef": "0.000503",
                "metrs": 1
            },
            {
                "size": " 35х35х1",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 35х35х1,2",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 35х35х1,5",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 35х35х1,8",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 35х35х2",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 35х35х2,5",
                "koef": "0.001364",
                "metrs": 1
            },
            {
                "size": " 35х35х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 35х35х3,5",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": " 35х35х4",
                "koef": "0.002135",
                "metrs": 1
            },
            {
                "size": " 35х40х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 35х40х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 35х40х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 35х40х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 35х40х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 35х40х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 35х40х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 35х40х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 35х45х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 35х45х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 35х45х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 35х45х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 35х45х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 35х45х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 35х45х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 35х45х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 35х50х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 35х50х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 35х50х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 35х50х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 35х50х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 35х50х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 35х50х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 35х50х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 35х55х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 35х55х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 35х55х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 35х55х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 35х55х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 35х55х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 35х55х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 35х55х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 35х60х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 35х60х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 35х60х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 35х60х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 35х60х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 35х60х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 35х60х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 35х60х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 35х65х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 35х65х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 35х65х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 35х65х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 35х65х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 35х65х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 35х65х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 35х65х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 35х70х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 35х70х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 35х70х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 35х70х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 35х70х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 35х70х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 35х70х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 35х70х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 36х36х2,5",
                "koef": "0.001405",
                "metrs": 1
            },
            {
                "size": " 36х36х3,5",
                "koef": "0.001938",
                "metrs": 1
            },
            {
                "size": " 38х38х1",
                "koef": "0.000606",
                "metrs": 1
            },
            {
                "size": " 39х39х3",
                "koef": "0.001819",
                "metrs": 1
            },
            {
                "size": " 40х15х0,5",
                "koef": "0.00022",
                "metrs": 1
            },
            {
                "size": " 40х15х0,55",
                "koef": "0.000242",
                "metrs": 1
            },
            {
                "size": " 40х15х0,6",
                "koef": "0.000264",
                "metrs": 1
            },
            {
                "size": " 40х15х0,7",
                "koef": "0.000307",
                "metrs": 1
            },
            {
                "size": " 40х15х0,8",
                "koef": "0.000351",
                "metrs": 1
            },
            {
                "size": " 40х15х0,9",
                "koef": "0.000394",
                "metrs": 1
            },
            {
                "size": " 40х15х1",
                "koef": "0.000437",
                "metrs": 1
            },
            {
                "size": " 40х15х1,2",
                "koef": "0.000522",
                "metrs": 1
            },
            {
                "size": " 40х15х1,5",
                "koef": "0.000649",
                "metrs": 1
            },
            {
                "size": " 40х15х1,8",
                "koef": "0.000774",
                "metrs": 1
            },
            {
                "size": " 40х15х2",
                "koef": "0.000857",
                "metrs": 1
            },
            {
                "size": " 40х15х2,5",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 40х15х3",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 40х15х3,5",
                "koef": "0.001457",
                "metrs": 1
            },
            {
                "size": " 40х20х0,5",
                "koef": "0.000241",
                "metrs": 1
            },
            {
                "size": " 40х20х0,55",
                "koef": "0.000264",
                "metrs": 1
            },
            {
                "size": " 40х20х0,6",
                "koef": "0.000288",
                "metrs": 1
            },
            {
                "size": " 40х20х0,7",
                "koef": "0.000336",
                "metrs": 1
            },
            {
                "size": " 40х20х0,8",
                "koef": "0.000383",
                "metrs": 1
            },
            {
                "size": " 40х20х0,9",
                "koef": "0.00043",
                "metrs": 1
            },
            {
                "size": " 40х20х1",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": " 40х20х1,2",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": " 40х20х1,5",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 40х20х1,8",
                "koef": "0.000847",
                "metrs": 1
            },
            {
                "size": " 40х20х2",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 40х20х2,5",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 40х20х3",
                "koef": "0.001383",
                "metrs": 1
            },
            {
                "size": " 40х20х3,5",
                "koef": "0.001599",
                "metrs": 1
            },
            {
                "size": " 40х25х0,5",
                "koef": "0.000261",
                "metrs": 1
            },
            {
                "size": " 40х25х0,55",
                "koef": "0.000287",
                "metrs": 1
            },
            {
                "size": " 40х25х0,6",
                "koef": "0.000312",
                "metrs": 1
            },
            {
                "size": " 40х25х0,7",
                "koef": "0.000364",
                "metrs": 1
            },
            {
                "size": " 40х25х0,8",
                "koef": "0.000415",
                "metrs": 1
            },
            {
                "size": " 40х25х0,9",
                "koef": "0.000466",
                "metrs": 1
            },
            {
                "size": " 40х25х1",
                "koef": "0.000517",
                "metrs": 1
            },
            {
                "size": " 40х25х1,2",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 40х25х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 40х25х1,8",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": " 40х25х2",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 40х25х2,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 40х25х3",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 40х25х3,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 40х30х0,5",
                "koef": "0.000281",
                "metrs": 1
            },
            {
                "size": " 40х30х0,55",
                "koef": "0.000309",
                "metrs": 1
            },
            {
                "size": " 40х30х0,6",
                "koef": "0.000337",
                "metrs": 1
            },
            {
                "size": " 40х30х0,7",
                "koef": "0.000392",
                "metrs": 1
            },
            {
                "size": " 40х30х0,8",
                "koef": "0.000448",
                "metrs": 1
            },
            {
                "size": " 40х30х0,9",
                "koef": "0.000503",
                "metrs": 1
            },
            {
                "size": " 40х30х1",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 40х30х1,2",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 40х30х1,5",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 40х30х1,8",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 40х30х2",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 40х30х2,5",
                "koef": "0.001364",
                "metrs": 1
            },
            {
                "size": " 40х30х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 40х30х3,5",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": " 40х30х4",
                "koef": "0.002135",
                "metrs": 1
            },
            {
                "size": " 40х35х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 40х35х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 40х35х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 40х35х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 40х35х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 40х35х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 40х35х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 40х35х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 40х40х0,5",
                "koef": "0.000321",
                "metrs": 1
            },
            {
                "size": " 40х40х0,55",
                "koef": "0.000353",
                "metrs": 1
            },
            {
                "size": " 40х40х0,6",
                "koef": "0.000385",
                "metrs": 1
            },
            {
                "size": " 40х40х0,7",
                "koef": "0.000449",
                "metrs": 1
            },
            {
                "size": " 40х40х0,8",
                "koef": "0.000512",
                "metrs": 1
            },
            {
                "size": " 40х40х0,9",
                "koef": "0.000576",
                "metrs": 1
            },
            {
                "size": " 40х40х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 40х40х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 40х40х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 40х40х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 40х40х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 40х40х2,5",
                "koef": "0.001524",
                "metrs": 1
            },
            {
                "size": " 40х40х3",
                "koef": "0.001813",
                "metrs": 1
            },
            {
                "size": " 40х40х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 40х40х4",
                "koef": "0.002458",
                "metrs": 1
            },
            {
                "size": " 40х40х5",
                "koef": "0.003032",
                "metrs": 1
            },
            {
                "size": " 40х45х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 40х45х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 40х45х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 40х45х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 40х45х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 40х45х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 40х45х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 40х45х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 40х50х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 40х50х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 40х50х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 40х50х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 40х50х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 40х50х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 40х50х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 40х50х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 40х55х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 40х55х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 40х55х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 40х55х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 40х55х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 40х55х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 40х55х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 40х55х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 40х60х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 40х60х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 40х60х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 40х60х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 40х60х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 40х60х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 40х60х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 40х60х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 40х65х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 40х65х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 40х65х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 40х65х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 40х65х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 40х65х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 40х65х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 40х65х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 40х70х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 40х70х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 40х70х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 40х70х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 40х70х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 40х70х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 40х70х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 40х70х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 41х41х1",
                "koef": "0.000655",
                "metrs": 1
            },
            {
                "size": " 43х43х2,5",
                "koef": "0.001688",
                "metrs": 1
            },
            {
                "size": " 44х44х2,5",
                "koef": "0.001728",
                "metrs": 1
            },
            {
                "size": " 45х15х1",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": " 45х15х1,2",
                "koef": "0.000571",
                "metrs": 1
            },
            {
                "size": " 45х15х1,5",
                "koef": "0.00071",
                "metrs": 1
            },
            {
                "size": " 45х15х1,8",
                "koef": "0.000847",
                "metrs": 1
            },
            {
                "size": " 45х15х2",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 45х15х2,5",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 45х15х3",
                "koef": "0.001383",
                "metrs": 1
            },
            {
                "size": " 45х15х3,5",
                "koef": "0.001599",
                "metrs": 1
            },
            {
                "size": " 45х20х1",
                "koef": "0.000517",
                "metrs": 1
            },
            {
                "size": " 45х20х1,2",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 45х20х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 45х20х1,8",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": " 45х20х2",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 45х20х2,5",
                "koef": "0.001263",
                "metrs": 1
            },
            {
                "size": " 45х20х3",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 45х20х3,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 45х25х1",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 45х25х1,2",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 45х25х1,5",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 45х25х1,8",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 45х25х2",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 45х25х2,5",
                "koef": "0.001364",
                "metrs": 1
            },
            {
                "size": " 45х25х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 45х25х3,5",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": " 45х30х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 45х30х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 45х30х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 45х30х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 45х30х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 45х30х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 45х30х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 45х30х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 45х35х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 45х35х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 45х35х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 45х35х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 45х35х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 45х35х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 45х35х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 45х35х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 45х40х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 45х40х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 45х40х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 45х40х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 45х40х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 45х40х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 45х40х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 45х40х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 45х45х0,5",
                "koef": "0.000362",
                "metrs": 1
            },
            {
                "size": " 45х45х0,55",
                "koef": "0.000398",
                "metrs": 1
            },
            {
                "size": " 45х45х0,6",
                "koef": "0.000434",
                "metrs": 1
            },
            {
                "size": " 45х45х0,7",
                "koef": "0.000505",
                "metrs": 1
            },
            {
                "size": " 45х45х0,8",
                "koef": "0.000577",
                "metrs": 1
            },
            {
                "size": " 45х45х0,9",
                "koef": "0.000648",
                "metrs": 1
            },
            {
                "size": " 45х45х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 45х45х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 45х45х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 45х45х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 45х45х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 45х45х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 45х45х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 45х45х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 45х45х4",
                "koef": "0.002781",
                "metrs": 1
            },
            {
                "size": " 45х45х5",
                "koef": "0.003436",
                "metrs": 1
            },
            {
                "size": " 45х50х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 45х50х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 45х50х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 45х50х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 45х50х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 45х50х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 45х50х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 45х50х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 45х55х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 45х55х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 45х55х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 45х55х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 45х55х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 45х55х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 45х55х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 45х55х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 45х60х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 45х60х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 45х60х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 45х60х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 45х60х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 45х60х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 45х60х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 45х60х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 45х65х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 45х65х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 45х65х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 45х65х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 45х65х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 45х65х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 45х65х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 45х65х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 45х70х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 45х70х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 45х70х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 45х70х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 45х70х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 45х70х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 45х70х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 45х70х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 47х47х2,7",
                "koef": "0.001993",
                "metrs": 1
            },
            {
                "size": " 50х15х1",
                "koef": "0.000517",
                "metrs": 1
            },
            {
                "size": " 50х15х1,2",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 50х15х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 50х15х1,8",
                "koef": "0.00092",
                "metrs": 1
            },
            {
                "size": " 50х15х2",
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": " 50х15х2,5",
                "koef": "0.001263",
                "metrs": 1
            },
            {
                "size": " 50х15х3",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 50х15х3,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 50х20х1",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 50х20х1,2",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 50х20х1,5",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 50х20х1,8",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 50х20х2",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 50х20х2,5",
                "koef": "0.001364",
                "metrs": 1
            },
            {
                "size": " 50х20х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 50х20х3,5",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": " 50х25х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 50х25х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 50х25х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 50х25х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 50х25х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 50х25х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 50х25х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 50х25х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 50х30х0,5",
                "koef": "0.000321",
                "metrs": 1
            },
            {
                "size": " 50х30х0,55",
                "koef": "0.000353",
                "metrs": 1
            },
            {
                "size": " 50х30х0,6",
                "koef": "0.000385",
                "metrs": 1
            },
            {
                "size": " 50х30х0,7",
                "koef": "0.000449",
                "metrs": 1
            },
            {
                "size": " 50х30х0,8",
                "koef": "0.000512",
                "metrs": 1
            },
            {
                "size": " 50х30х0,9",
                "koef": "0.000576",
                "metrs": 1
            },
            {
                "size": " 50х30х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 50х30х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 50х30х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 50х30х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 50х30х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 50х30х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 50х30х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 50х30х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 50х30х4",
                "koef": "0.002458",
                "metrs": 1
            },
            {
                "size": " 50х30х5",
                "koef": "0.003032",
                "metrs": 1
            },
            {
                "size": " 50х35х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 50х35х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 50х35х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 50х35х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 50х35х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 50х35х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 50х35х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 50х35х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 50х40х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 50х40х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 50х40х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 50х40х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 50х40х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 50х40х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 50х40х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 50х40х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 50х45х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 50х45х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 50х45х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 50х45х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 50х45х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 50х45х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 50х45х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 50х45х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 50х49х2,7",
                "koef": "0.002102",
                "metrs": 1
            },
            {
                "size": " 50х50х0,5",
                "koef": "0.000402",
                "metrs": 1
            },
            {
                "size": " 50х50х0,55",
                "koef": "0.000442",
                "metrs": 1
            },
            {
                "size": " 50х50х0,6",
                "koef": "0.000482",
                "metrs": 1
            },
            {
                "size": " 50х50х0,7",
                "koef": "0.000562",
                "metrs": 1
            },
            {
                "size": " 50х50х0,8",
                "koef": "0.000642",
                "metrs": 1
            },
            {
                "size": " 50х50х0,9",
                "koef": "0.000721",
                "metrs": 1
            },
            {
                "size": " 50х50х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 50х50х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 50х50х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 50х50х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 50х50х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 50х50х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 50х50х3",
                "koef": "0.002297",
                "metrs": 1
            },
            {
                "size": " 50х50х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 50х50х4",
                "koef": "0.002987",
                "metrs": 1
            },
            {
                "size": " 50х50х5",
                "koef": "0.003841",
                "metrs": 1
            },
            {
                "size": " 50х55х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 50х55х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 50х55х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 50х55х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 50х55х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 50х55х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 50х55х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 50х55х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 50х60х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 50х60х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 50х60х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 50х60х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 50х60х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 50х60х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 50х60х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 50х60х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 50х63х1,5",
                "koef": "0.001352",
                "metrs": 1
            },
            {
                "size": " 50х65х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 50х65х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 50х65х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 50х65х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 50х65х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 50х65х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 50х65х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 50х65х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 50х70х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 50х70х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 50х70х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 50х70х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 50х70х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 50х70х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 50х70х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 50х70х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 55х15х1",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 55х15х1,2",
                "koef": "0.000668",
                "metrs": 1
            },
            {
                "size": " 55х15х1,5",
                "koef": "0.000831",
                "metrs": 1
            },
            {
                "size": " 55х15х1,8",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 55х15х2",
                "koef": "0.0011",
                "metrs": 1
            },
            {
                "size": " 55х15х2,5",
                "koef": "0.001364",
                "metrs": 1
            },
            {
                "size": " 55х15х3",
                "koef": "0.001625",
                "metrs": 1
            },
            {
                "size": " 55х15х3,5",
                "koef": "0.001882",
                "metrs": 1
            },
            {
                "size": " 55х20х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 55х20х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 55х20х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 55х20х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 55х20х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 55х20х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 55х20х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 55х20х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 55х25х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 55х25х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 55х25х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 55х25х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 55х25х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 55х25х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 55х25х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 55х25х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 55х30х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 55х30х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 55х30х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 55х30х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 55х30х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 55х30х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 55х30х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 55х30х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 55х35х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 55х35х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 55х35х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 55х35х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 55х35х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 55х35х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 55х35х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 55х35х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 55х40х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 55х40х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 55х40х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 55х40х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 55х40х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 55х40х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 55х40х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 55х40х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 55х45х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 55х45х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 55х45х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 55х45х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 55х45х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 55х45х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 55х45х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 55х45х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 55х50х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 55х50х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 55х50х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 55х50х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 55х50х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 55х50х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 55х50х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 55х50х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 55х51х3",
                "koef": "0.002498",
                "metrs": 1
            },
            {
                "size": " 55х55х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 55х55х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 55х55х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 55х55х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 55х55х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 55х55х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 55х55х3",
                "koef": "0.002503",
                "metrs": 1
            },
            {
                "size": " 55х55х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 55х60х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 55х60х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 55х60х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 55х60х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 55х60х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 55х60х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 55х60х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 55х60х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 55х65х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 55х65х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 55х65х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 55х65х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 55х65х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 55х65х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 55х65х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 55х65х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 55х70х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 55х70х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 55х70х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 55х70х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 55х70х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 55х70х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 55х70х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 55х70х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 60х15х1",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 60х15х1,2",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 60х15х1,5",
                "koef": "0.000891",
                "metrs": 1
            },
            {
                "size": " 60х15х1,8",
                "koef": "0.001065",
                "metrs": 1
            },
            {
                "size": " 60х15х2",
                "koef": "0.00118",
                "metrs": 1
            },
            {
                "size": " 60х15х2,5",
                "koef": "0.001465",
                "metrs": 1
            },
            {
                "size": " 60х15х3",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 60х15х3,5",
                "koef": "0.002023",
                "metrs": 1
            },
            {
                "size": " 60х20х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 60х20х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 60х20х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 60х20х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 60х20х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 60х20х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 60х20х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 60х20х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 60х25х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 60х25х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 60х25х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 60х25х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 60х25х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 60х25х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 60х25х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 60х25х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 60х30х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 60х30х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 60х30х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 60х30х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 60х30х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 60х30х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 60х30х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 60х30х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 60х35х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 60х35х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 60х35х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 60х35х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 60х35х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 60х35х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 60х35х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 60х35х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 60х40х0,5",
                "koef": "0.000402",
                "metrs": 1
            },
            {
                "size": " 60х40х0,55",
                "koef": "0.000442",
                "metrs": 1
            },
            {
                "size": " 60х40х0,6",
                "koef": "0.000482",
                "metrs": 1
            },
            {
                "size": " 60х40х0,7",
                "koef": "0.000562",
                "metrs": 1
            },
            {
                "size": " 60х40х0,8",
                "koef": "0.000642",
                "metrs": 1
            },
            {
                "size": " 60х40х0,9",
                "koef": "0.000721",
                "metrs": 1
            },
            {
                "size": " 60х40х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 60х40х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 60х40х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 60х40х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 60х40х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 60х40х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 60х40х3",
                "koef": "0.002297",
                "metrs": 1
            },
            {
                "size": " 60х40х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 60х40х4",
                "koef": "0.003105",
                "metrs": 1
            },
            {
                "size": " 60х40х5",
                "koef": "0.003841",
                "metrs": 1
            },
            {
                "size": " 60х45х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 60х45х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 60х45х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 60х45х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 60х45х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 60х45х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 60х45х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 60х45х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 60х50х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 60х50х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 60х50х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 60х50х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 60х50х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 60х50х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 60х50х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 60х50х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 60х55х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 60х55х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 60х55х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 60х55х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 60х55х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 60х55х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 60х55х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 60х55х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 60х60х0,5",
                "koef": "0.000483",
                "metrs": 1
            },
            {
                "size": " 60х60х0,55",
                "koef": "0.000531",
                "metrs": 1
            },
            {
                "size": " 60х60х0,6",
                "koef": "0.000579",
                "metrs": 1
            },
            {
                "size": " 60х60х0,7",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": " 60х60х0,8",
                "koef": "0.000771",
                "metrs": 1
            },
            {
                "size": " 60х60х0,9",
                "koef": "0.000867",
                "metrs": 1
            },
            {
                "size": " 60х60х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 60х60х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 60х60х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 60х60х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 60х60х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 60х60х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 60х60х3",
                "koef": "0.002781",
                "metrs": 1
            },
            {
                "size": " 60х60х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 60х60х4",
                "koef": "0.003636",
                "metrs": 1
            },
            {
                "size": " 60х60х5",
                "koef": "0.004649",
                "metrs": 1
            },
            {
                "size": " 60х63х3,2",
                "koef": "0.0031",
                "metrs": 1
            },
            {
                "size": " 60х65х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 60х65х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 60х65х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 60х65х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 60х65х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 60х65х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 60х65х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 60х65х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 60х70х1",
                "koef": "0.001043",
                "metrs": 1
            },
            {
                "size": " 60х70х1,2",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 60х70х1,5",
                "koef": "0.001558",
                "metrs": 1
            },
            {
                "size": " 60х70х1,8",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": " 60х70х2",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 60х70х2,5",
                "koef": "0.002577",
                "metrs": 1
            },
            {
                "size": " 60х70х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 60х70х3,5",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": " 63х40х0,5",
                "koef": "0.000414",
                "metrs": 1
            },
            {
                "size": " 63х40х0,55",
                "koef": "0.000456",
                "metrs": 1
            },
            {
                "size": " 63х40х0,6",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": " 63х40х0,7",
                "koef": "0.000579",
                "metrs": 1
            },
            {
                "size": " 63х40х0,8",
                "koef": "0.000661",
                "metrs": 1
            },
            {
                "size": " 63х40х0,9",
                "koef": "0.000743",
                "metrs": 1
            },
            {
                "size": " 63х40х1",
                "koef": "0.000825",
                "metrs": 1
            },
            {
                "size": " 63х40х1,2",
                "koef": "0.000988",
                "metrs": 1
            },
            {
                "size": " 63х40х1,5",
                "koef": "0.001231",
                "metrs": 1
            },
            {
                "size": " 63х40х2",
                "koef": "0.001633",
                "metrs": 1
            },
            {
                "size": " 63х40х2,5",
                "koef": "0.002031",
                "metrs": 1
            },
            {
                "size": " 63х40х3",
                "koef": "0.002426",
                "metrs": 1
            },
            {
                "size": " 63х40х4",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 63х40х5",
                "koef": "0.003962",
                "metrs": 1
            },
            {
                "size": " 63х40х6",
                "koef": "0.004706",
                "metrs": 1
            },
            {
                "size": " 63х63х0,5",
                "koef": "0.000507",
                "metrs": 1
            },
            {
                "size": " 63х63х0,55",
                "koef": "0.000558",
                "metrs": 1
            },
            {
                "size": " 63х63х0,6",
                "koef": "0.000608",
                "metrs": 1
            },
            {
                "size": " 63х63х0,7",
                "koef": "0.000709",
                "metrs": 1
            },
            {
                "size": " 63х63х0,8",
                "koef": "0.00081",
                "metrs": 1
            },
            {
                "size": " 63х63х0,9",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 63х63х1",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": " 63х63х1,2",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 63х63х1,5",
                "koef": "0.00151",
                "metrs": 1
            },
            {
                "size": " 63х63х2",
                "koef": "0.002005",
                "metrs": 1
            },
            {
                "size": " 63х63х2,5",
                "koef": "0.002496",
                "metrs": 1
            },
            {
                "size": " 63х63х3",
                "koef": "0.002984",
                "metrs": 1
            },
            {
                "size": " 63х63х4",
                "koef": "0.003946",
                "metrs": 1
            },
            {
                "size": " 63х63х5",
                "koef": "0.004892",
                "metrs": 1
            },
            {
                "size": " 63х63х6",
                "koef": "0.005822",
                "metrs": 1
            },
            {
                "size": " 65х15х1",
                "koef": "0.000639",
                "metrs": 1
            },
            {
                "size": " 65х15х1,2",
                "koef": "0.000765",
                "metrs": 1
            },
            {
                "size": " 65х15х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 65х15х1,8",
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": " 65х15х2",
                "koef": "0.001261",
                "metrs": 1
            },
            {
                "size": " 65х15х2,5",
                "koef": "0.001567",
                "metrs": 1
            },
            {
                "size": " 65х15х3",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 65х15х3,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 65х20х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 65х20х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 65х20х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 65х20х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 65х20х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 65х20х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 65х20х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 65х20х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 65х25х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 65х25х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 65х25х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 65х25х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 65х25х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 65х25х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 65х25х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 65х25х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 65х30х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 65х30х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 65х30х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 65х30х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 65х30х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 65х30х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 65х30х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 65х30х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 65х35х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 65х35х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 65х35х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 65х35х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 65х35х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 65х35х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 65х35х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 65х35х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 65х40х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 65х40х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 65х40х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 65х40х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 65х40х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 65х40х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 65х40х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 65х40х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 65х45х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 65х45х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 65х45х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 65х45х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 65х45х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 65х45х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 65х45х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 65х45х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 65х50х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 65х50х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 65х50х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 65х50х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 65х50х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 65х50х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 65х50х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 65х50х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 65х55х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 65х55х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 65х55х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 65х55х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 65х55х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 65х55х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 65х55х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 65х55х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 65х60х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 65х60х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 65х60х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 65х60х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 65х60х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 65х60х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 65х60х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 65х60х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 65х65х1",
                "koef": "0.001043",
                "metrs": 1
            },
            {
                "size": " 65х65х1,2",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 65х65х1,5",
                "koef": "0.001558",
                "metrs": 1
            },
            {
                "size": " 65х65х1,8",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": " 65х65х2",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 65х65х2,5",
                "koef": "0.002577",
                "metrs": 1
            },
            {
                "size": " 65х65х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 65х65х3,5",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": " 65х66х3,5",
                "koef": "0.003608",
                "metrs": 1
            },
            {
                "size": " 65х70х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 65х70х1,2",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 65х70х1,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": " 65х70х1,8",
                "koef": "0.001939",
                "metrs": 1
            },
            {
                "size": " 65х70х2",
                "koef": "0.002151",
                "metrs": 1
            },
            {
                "size": " 65х70х2,5",
                "koef": "0.002678",
                "metrs": 1
            },
            {
                "size": " 65х70х3",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 65х70х3,5",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": " 67х67х2,8",
                "koef": "0.00297",
                "metrs": 1
            },
            {
                "size": " 70х15х1",
                "koef": "0.000679",
                "metrs": 1
            },
            {
                "size": " 70х15х1,2",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 70х15х1,5",
                "koef": "0.001013",
                "metrs": 1
            },
            {
                "size": " 70х15х1,8",
                "koef": "0.001211",
                "metrs": 1
            },
            {
                "size": " 70х15х2",
                "koef": "0.001342",
                "metrs": 1
            },
            {
                "size": " 70х15х2,5",
                "koef": "0.001668",
                "metrs": 1
            },
            {
                "size": " 70х15х3",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 70х15х3,5",
                "koef": "0.002306",
                "metrs": 1
            },
            {
                "size": " 70х20х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 70х20х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 70х20х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 70х20х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 70х20х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 70х20х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 70х20х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 70х20х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 70х25х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 70х25х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 70х25х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 70х25х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 70х25х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 70х25х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 70х25х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 70х25х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 70х30х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 70х30х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 70х30х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 70х30х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 70х30х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 70х30х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 70х30х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 70х30х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 70х35х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 70х35х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 70х35х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 70х35х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 70х35х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 70х35х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 70х35х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 70х35х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 70х40х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 70х40х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 70х40х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 70х40х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 70х40х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 70х40х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 70х40х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 70х40х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 70х45х0,5",
                "koef": "0.000463",
                "metrs": 1
            },
            {
                "size": " 70х45х0,55",
                "koef": "0.000509",
                "metrs": 1
            },
            {
                "size": " 70х45х0,6",
                "koef": "0.000555",
                "metrs": 1
            },
            {
                "size": " 70х45х0,7",
                "koef": "0.000647",
                "metrs": 1
            },
            {
                "size": " 70х45х0,8",
                "koef": "0.000739",
                "metrs": 1
            },
            {
                "size": " 70х45х0,9",
                "koef": "0.00083",
                "metrs": 1
            },
            {
                "size": " 70х45х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 70х45х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 70х45х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 70х45х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 70х45х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 70х45х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 70х45х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 70х45х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 70х45х4",
                "koef": "0.00359",
                "metrs": 1
            },
            {
                "size": " 70х45х5",
                "koef": "0.004447",
                "metrs": 1
            },
            {
                "size": " 70х45х6",
                "koef": "0.005288",
                "metrs": 1
            },
            {
                "size": " 70х50х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 70х50х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 70х50х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 70х50х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 70х50х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 70х50х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 70х50х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 70х50х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 70х55х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 70х55х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 70х55х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 70х55х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 70х55х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 70х55х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 70х55х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 70х55х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 70х60х1",
                "koef": "0.001043",
                "metrs": 1
            },
            {
                "size": " 70х60х1,2",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 70х60х1,5",
                "koef": "0.001558",
                "metrs": 1
            },
            {
                "size": " 70х60х1,8",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": " 70х60х2",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 70х60х2,5",
                "koef": "0.002577",
                "metrs": 1
            },
            {
                "size": " 70х60х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 70х60х3,5",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": " 70х65х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 70х65х1,2",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 70х65х1,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": " 70х65х1,8",
                "koef": "0.001939",
                "metrs": 1
            },
            {
                "size": " 70х65х2",
                "koef": "0.002151",
                "metrs": 1
            },
            {
                "size": " 70х65х2,5",
                "koef": "0.002678",
                "metrs": 1
            },
            {
                "size": " 70х65х3",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 70х65х3,5",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": " 70х70х0,5",
                "koef": "0.000564",
                "metrs": 1
            },
            {
                "size": " 70х70х0,55",
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": " 70х70х0,6",
                "koef": "0.000676",
                "metrs": 1
            },
            {
                "size": " 70х70х0,7",
                "koef": "0.000788",
                "metrs": 1
            },
            {
                "size": " 70х70х0,8",
                "koef": "0.0009",
                "metrs": 1
            },
            {
                "size": " 70х70х0,9",
                "koef": "0.001012",
                "metrs": 1
            },
            {
                "size": " 70х70х1",
                "koef": "0.001124",
                "metrs": 1
            },
            {
                "size": " 70х70х1,2",
                "koef": "0.001347",
                "metrs": 1
            },
            {
                "size": " 70х70х1,5",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 70х70х1,8",
                "koef": "0.002011",
                "metrs": 1
            },
            {
                "size": " 70х70х2",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 70х70х2,5",
                "koef": "0.002779",
                "metrs": 1
            },
            {
                "size": " 70х70х3",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": " 70х70х3,5",
                "koef": "0.003863",
                "metrs": 1
            },
            {
                "size": " 70х70х4",
                "koef": "0.004285",
                "metrs": 1
            },
            {
                "size": " 70х70х5",
                "koef": "0.005458",
                "metrs": 1
            },
            {
                "size": " 70х70х6",
                "koef": "0.006501",
                "metrs": 1
            },
            {
                "size": " 72х72х1,5",
                "koef": "0.001728",
                "metrs": 1
            },
            {
                "size": " 73х35х1,5",
                "koef": "0.001292",
                "metrs": 1
            },
            {
                "size": " 75х15х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 75х15х1,2",
                "koef": "0.000862",
                "metrs": 1
            },
            {
                "size": " 75х15х1,5",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 75х15х1,8",
                "koef": "0.001284",
                "metrs": 1
            },
            {
                "size": " 75х15х2",
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": " 75х15х2,5",
                "koef": "0.001769",
                "metrs": 1
            },
            {
                "size": " 75х15х3",
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": " 75х15х3,5",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 75х20х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 75х20х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 75х20х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 75х20х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 75х20х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 75х20х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 75х20х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 75х20х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 75х25х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 75х25х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 75х25х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 75х25х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 75х25х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 75х25х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 75х25х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 75х25х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 75х30х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 75х30х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 75х30х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 75х30х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 75х30х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 75х30х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 75х30х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 75х30х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 75х35х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 75х35х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 75х35х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 75х35х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 75х35х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 75х35х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 75х35х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 75х35х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 75х40х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 75х40х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 75х40х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 75х40х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 75х40х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 75х40х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 75х40х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 75х40х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 75х45х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 75х45х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 75х45х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 75х45х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 75х45х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 75х45х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 75х45х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 75х45х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 75х50х0,5",
                "koef": "0.000503",
                "metrs": 1
            },
            {
                "size": " 75х50х0,55",
                "koef": "0.000553",
                "metrs": 1
            },
            {
                "size": " 75х50х0,6",
                "koef": "0.000604",
                "metrs": 1
            },
            {
                "size": " 75х50х0,7",
                "koef": "0.000704",
                "metrs": 1
            },
            {
                "size": " 75х50х0,8",
                "koef": "0.000803",
                "metrs": 1
            },
            {
                "size": " 75х50х0,9",
                "koef": "0.000903",
                "metrs": 1
            },
            {
                "size": " 75х50х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 75х50х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 75х50х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 75х50х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 75х50х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 75х50х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 75х50х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 75х50х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 75х50х4",
                "koef": "0.003913",
                "metrs": 1
            },
            {
                "size": " 75х50х5",
                "koef": "0.004851",
                "metrs": 1
            },
            {
                "size": " 75х50х6",
                "koef": "0.005773",
                "metrs": 1
            },
            {
                "size": " 75х55х1",
                "koef": "0.001043",
                "metrs": 1
            },
            {
                "size": " 75х55х1,2",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 75х55х1,5",
                "koef": "0.001558",
                "metrs": 1
            },
            {
                "size": " 75х55х1,8",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": " 75х55х2",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 75х55х2,5",
                "koef": "0.002577",
                "metrs": 1
            },
            {
                "size": " 75х55х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 75х55х3,5",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": " 75х60х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 75х60х1,2",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 75х60х1,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": " 75х60х1,8",
                "koef": "0.001939",
                "metrs": 1
            },
            {
                "size": " 75х60х2",
                "koef": "0.002151",
                "metrs": 1
            },
            {
                "size": " 75х60х2,5",
                "koef": "0.002678",
                "metrs": 1
            },
            {
                "size": " 75х60х3",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 75х60х3,5",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": " 75х65х1",
                "koef": "0.001124",
                "metrs": 1
            },
            {
                "size": " 75х65х1,2",
                "koef": "0.001347",
                "metrs": 1
            },
            {
                "size": " 75х65х1,5",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 75х65х1,8",
                "koef": "0.002011",
                "metrs": 1
            },
            {
                "size": " 75х65х2",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 75х65х2,5",
                "koef": "0.002779",
                "metrs": 1
            },
            {
                "size": " 75х65х3",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": " 75х65х3,5",
                "koef": "0.003863",
                "metrs": 1
            },
            {
                "size": " 75х70х1",
                "koef": "0.001164",
                "metrs": 1
            },
            {
                "size": " 75х70х1,2",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": " 75х70х1,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 75х70х1,8",
                "koef": "0.002084",
                "metrs": 1
            },
            {
                "size": " 75х70х2",
                "koef": "0.002312",
                "metrs": 1
            },
            {
                "size": " 75х70х2,5",
                "koef": "0.00288",
                "metrs": 1
            },
            {
                "size": " 75х70х3",
                "koef": "0.003444",
                "metrs": 1
            },
            {
                "size": " 75х70х3,5",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": " 75х75х0,5",
                "koef": "0.000604",
                "metrs": 1
            },
            {
                "size": " 75х75х0,55",
                "koef": "0.000665",
                "metrs": 1
            },
            {
                "size": " 75х75х0,6",
                "koef": "0.000725",
                "metrs": 1
            },
            {
                "size": " 75х75х0,7",
                "koef": "0.000845",
                "metrs": 1
            },
            {
                "size": " 75х75х0,8",
                "koef": "0.000965",
                "metrs": 1
            },
            {
                "size": " 75х75х0,9",
                "koef": "0.001085",
                "metrs": 1
            },
            {
                "size": " 75х75х1",
                "koef": "0.001205",
                "metrs": 1
            },
            {
                "size": " 75х75х1,2",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": " 75х75х1,5",
                "koef": "0.001801",
                "metrs": 1
            },
            {
                "size": " 75х75х1,8",
                "koef": "0.002157",
                "metrs": 1
            },
            {
                "size": " 75х75х2",
                "koef": "0.002393",
                "metrs": 1
            },
            {
                "size": " 75х75х2,5",
                "koef": "0.002982",
                "metrs": 1
            },
            {
                "size": " 75х75х3",
                "koef": "0.003566",
                "metrs": 1
            },
            {
                "size": " 75х75х3,5",
                "koef": "0.004146",
                "metrs": 1
            },
            {
                "size": " 75х75х4",
                "koef": "0.004722",
                "metrs": 1
            },
            {
                "size": " 75х75х5",
                "koef": "0.005862",
                "metrs": 1
            },
            {
                "size": " 75х75х6",
                "koef": "0.006986",
                "metrs": 1
            },
            {
                "size": " 75х80х1",
                "koef": "0.001245",
                "metrs": 1
            },
            {
                "size": " 75х80х1,2",
                "koef": "0.001492",
                "metrs": 1
            },
            {
                "size": " 75х80х1,5",
                "koef": "0.001862",
                "metrs": 1
            },
            {
                "size": " 75х80х1,8",
                "koef": "0.00223",
                "metrs": 1
            },
            {
                "size": " 75х80х2",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 75х80х2,5",
                "koef": "0.003083",
                "metrs": 1
            },
            {
                "size": " 75х80х3",
                "koef": "0.003687",
                "metrs": 1
            },
            {
                "size": " 75х80х3,5",
                "koef": "0.004287",
                "metrs": 1
            },
            {
                "size": " 75х85х1",
                "koef": "0.001286",
                "metrs": 1
            },
            {
                "size": " 75х85х1,2",
                "koef": "0.001541",
                "metrs": 1
            },
            {
                "size": " 75х85х1,5",
                "koef": "0.001922",
                "metrs": 1
            },
            {
                "size": " 75х85х1,8",
                "koef": "0.002302",
                "metrs": 1
            },
            {
                "size": " 75х85х2",
                "koef": "0.002555",
                "metrs": 1
            },
            {
                "size": " 75х85х2,5",
                "koef": "0.003184",
                "metrs": 1
            },
            {
                "size": " 75х85х3",
                "koef": "0.003808",
                "metrs": 1
            },
            {
                "size": " 75х85х3,5",
                "koef": "0.004429",
                "metrs": 1
            },
            {
                "size": " 75х90х1",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": " 75х90х1,2",
                "koef": "0.001589",
                "metrs": 1
            },
            {
                "size": " 75х90х1,5",
                "koef": "0.001983",
                "metrs": 1
            },
            {
                "size": " 75х90х1,8",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 75х90х2",
                "koef": "0.002636",
                "metrs": 1
            },
            {
                "size": " 75х90х2,5",
                "koef": "0.003285",
                "metrs": 1
            },
            {
                "size": " 75х90х3",
                "koef": "0.00393",
                "metrs": 1
            },
            {
                "size": " 75х90х3,5",
                "koef": "0.00457",
                "metrs": 1
            },
            {
                "size": " 75х95х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": " 75х95х1,2",
                "koef": "0.001638",
                "metrs": 1
            },
            {
                "size": " 75х95х1,5",
                "koef": "0.002044",
                "metrs": 1
            },
            {
                "size": " 75х95х1,8",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 75х95х2",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 75х95х2,5",
                "koef": "0.003386",
                "metrs": 1
            },
            {
                "size": " 75х95х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": " 75х95х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": " 75х100х1",
                "koef": "0.001407",
                "metrs": 1
            },
            {
                "size": " 75х100х1,2",
                "koef": "0.001686",
                "metrs": 1
            },
            {
                "size": " 75х100х1,5",
                "koef": "0.002104",
                "metrs": 1
            },
            {
                "size": " 75х100х1,8",
                "koef": "0.002521",
                "metrs": 1
            },
            {
                "size": " 75х100х2",
                "koef": "0.002798",
                "metrs": 1
            },
            {
                "size": " 75х100х2,5",
                "koef": "0.003487",
                "metrs": 1
            },
            {
                "size": " 75х100х3",
                "koef": "0.004172",
                "metrs": 1
            },
            {
                "size": " 75х100х3,5",
                "koef": "0.004853",
                "metrs": 1
            },
            {
                "size": " 76х60х2,5",
                "koef": "0.002699",
                "metrs": 1
            },
            {
                "size": " 76х76х2,5",
                "koef": "0.003022",
                "metrs": 1
            },
            {
                "size": " 78х78х2,5",
                "koef": "0.003103",
                "metrs": 1
            },
            {
                "size": " 79х90х1,5",
                "koef": "0.002031",
                "metrs": 1
            },
            {
                "size": " 80х15х1",
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": " 80х15х1,2",
                "koef": "0.00091",
                "metrs": 1
            },
            {
                "size": " 80х15х1,5",
                "koef": "0.001134",
                "metrs": 1
            },
            {
                "size": " 80х15х1,8",
                "koef": "0.001356",
                "metrs": 1
            },
            {
                "size": " 80х15х2",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 80х15х2,5",
                "koef": "0.00187",
                "metrs": 1
            },
            {
                "size": " 80х15х3",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 80х15х3,5",
                "koef": "0.002589",
                "metrs": 1
            },
            {
                "size": " 80х20х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 80х20х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 80х20х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 80х20х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 80х20х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 80х20х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 80х20х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 80х20х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 80х25х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 80х25х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 80х25х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 80х25х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 80х25х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 80х25х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 80х25х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 80х25х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 80х30х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 80х30х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 80х30х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 80х30х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 80х30х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 80х30х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 80х30х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 80х30х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 80х35х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 80х35х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 80х35х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 80х35х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 80х35х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 80х35х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 80х35х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 80х35х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 80х40х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 80х40х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 80х40х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 80х40х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 80х40х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 80х40х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 80х40х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 80х40х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 80х45х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 80х45х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 80х45х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 80х45х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 80х45х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 80х45х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 80х45х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 80х45х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 80х50х1",
                "koef": "0.001043",
                "metrs": 1
            },
            {
                "size": " 80х50х1,2",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 80х50х1,5",
                "koef": "0.001558",
                "metrs": 1
            },
            {
                "size": " 80х50х1,8",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": " 80х50х2",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 80х50х2,5",
                "koef": "0.002577",
                "metrs": 1
            },
            {
                "size": " 80х50х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 80х50х3,5",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": " 80х55х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 80х55х1,2",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 80х55х1,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": " 80х55х1,8",
                "koef": "0.001939",
                "metrs": 1
            },
            {
                "size": " 80х55х2",
                "koef": "0.002151",
                "metrs": 1
            },
            {
                "size": " 80х55х2,5",
                "koef": "0.002678",
                "metrs": 1
            },
            {
                "size": " 80х55х3",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 80х55х3,5",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": " 80х60х1",
                "koef": "0.001124",
                "metrs": 1
            },
            {
                "size": " 80х60х1,2",
                "koef": "0.001347",
                "metrs": 1
            },
            {
                "size": " 80х60х1,5",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 80х60х1,8",
                "koef": "0.002011",
                "metrs": 1
            },
            {
                "size": " 80х60х2",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 80х60х2,5",
                "koef": "0.002779",
                "metrs": 1
            },
            {
                "size": " 80х60х3",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": " 80х60х3,5",
                "koef": "0.003863",
                "metrs": 1
            },
            {
                "size": " 80х65х1",
                "koef": "0.001164",
                "metrs": 1
            },
            {
                "size": " 80х65х1,2",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": " 80х65х1,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 80х65х1,8",
                "koef": "0.002084",
                "metrs": 1
            },
            {
                "size": " 80х65х2",
                "koef": "0.002312",
                "metrs": 1
            },
            {
                "size": " 80х65х2,5",
                "koef": "0.00288",
                "metrs": 1
            },
            {
                "size": " 80х65х3",
                "koef": "0.003444",
                "metrs": 1
            },
            {
                "size": " 80х65х3,5",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": " 80х70х1",
                "koef": "0.001205",
                "metrs": 1
            },
            {
                "size": " 80х70х1,2",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": " 80х70х1,5",
                "koef": "0.001801",
                "metrs": 1
            },
            {
                "size": " 80х70х1,8",
                "koef": "0.002157",
                "metrs": 1
            },
            {
                "size": " 80х70х2",
                "koef": "0.002393",
                "metrs": 1
            },
            {
                "size": " 80х70х2,5",
                "koef": "0.002982",
                "metrs": 1
            },
            {
                "size": " 80х70х3",
                "koef": "0.003566",
                "metrs": 1
            },
            {
                "size": " 80х70х3,5",
                "koef": "0.004146",
                "metrs": 1
            },
            {
                "size": " 80х75х1",
                "koef": "0.001245",
                "metrs": 1
            },
            {
                "size": " 80х75х1,2",
                "koef": "0.001492",
                "metrs": 1
            },
            {
                "size": " 80х75х1,5",
                "koef": "0.001862",
                "metrs": 1
            },
            {
                "size": " 80х75х1,8",
                "koef": "0.00223",
                "metrs": 1
            },
            {
                "size": " 80х75х2",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 80х75х2,5",
                "koef": "0.003083",
                "metrs": 1
            },
            {
                "size": " 80х75х3",
                "koef": "0.003687",
                "metrs": 1
            },
            {
                "size": " 80х75х3,5",
                "koef": "0.004287",
                "metrs": 1
            },
            {
                "size": " 80х80х1",
                "koef": "0.001286",
                "metrs": 1
            },
            {
                "size": " 80х80х1,2",
                "koef": "0.001541",
                "metrs": 1
            },
            {
                "size": " 80х80х1,5",
                "koef": "0.001922",
                "metrs": 1
            },
            {
                "size": " 80х80х1,8",
                "koef": "0.002302",
                "metrs": 1
            },
            {
                "size": " 80х80х2",
                "koef": "0.002555",
                "metrs": 1
            },
            {
                "size": " 80х80х2,5",
                "koef": "0.003184",
                "metrs": 1
            },
            {
                "size": " 80х80х3",
                "koef": "0.003749",
                "metrs": 1
            },
            {
                "size": " 80х80х3,5",
                "koef": "0.004429",
                "metrs": 1
            },
            {
                "size": " 80х80х4",
                "koef": "0.004934",
                "metrs": 1
            },
            {
                "size": " 80х80х5",
                "koef": "0.006098",
                "metrs": 1
            },
            {
                "size": " 80х80х6",
                "koef": "0.00722",
                "metrs": 1
            },
            {
                "size": " 80х80х7",
                "koef": "0.008353",
                "metrs": 1
            },
            {
                "size": " 80х85х1",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": " 80х85х1,2",
                "koef": "0.001589",
                "metrs": 1
            },
            {
                "size": " 80х85х1,5",
                "koef": "0.001983",
                "metrs": 1
            },
            {
                "size": " 80х85х1,8",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 80х85х2",
                "koef": "0.002636",
                "metrs": 1
            },
            {
                "size": " 80х85х2,5",
                "koef": "0.003285",
                "metrs": 1
            },
            {
                "size": " 80х85х3",
                "koef": "0.00393",
                "metrs": 1
            },
            {
                "size": " 80х85х3,5",
                "koef": "0.00457",
                "metrs": 1
            },
            {
                "size": " 80х90х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": " 80х90х1,2",
                "koef": "0.001638",
                "metrs": 1
            },
            {
                "size": " 80х90х1,5",
                "koef": "0.002044",
                "metrs": 1
            },
            {
                "size": " 80х90х1,8",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 80х90х2",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 80х90х2,5",
                "koef": "0.003386",
                "metrs": 1
            },
            {
                "size": " 80х90х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": " 80х90х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": " 80х95х1",
                "koef": "0.001407",
                "metrs": 1
            },
            {
                "size": " 80х95х1,2",
                "koef": "0.001686",
                "metrs": 1
            },
            {
                "size": " 80х95х1,5",
                "koef": "0.002104",
                "metrs": 1
            },
            {
                "size": " 80х95х1,8",
                "koef": "0.002521",
                "metrs": 1
            },
            {
                "size": " 80х95х2",
                "koef": "0.002798",
                "metrs": 1
            },
            {
                "size": " 80х95х2,5",
                "koef": "0.003487",
                "metrs": 1
            },
            {
                "size": " 80х95х3",
                "koef": "0.004172",
                "metrs": 1
            },
            {
                "size": " 80х95х3,5",
                "koef": "0.004853",
                "metrs": 1
            },
            {
                "size": " 80х100х1",
                "koef": "0.001447",
                "metrs": 1
            },
            {
                "size": " 80х100х1,2",
                "koef": "0.001735",
                "metrs": 1
            },
            {
                "size": " 80х100х1,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 80х100х1,8",
                "koef": "0.002594",
                "metrs": 1
            },
            {
                "size": " 80х100х2",
                "koef": "0.002878",
                "metrs": 1
            },
            {
                "size": " 80х100х2,5",
                "koef": "0.003588",
                "metrs": 1
            },
            {
                "size": " 80х100х3",
                "koef": "0.004293",
                "metrs": 1
            },
            {
                "size": " 80х100х3,5",
                "koef": "0.004995",
                "metrs": 1
            },
            {
                "size": " 83х83х2,5",
                "koef": "0.003305",
                "metrs": 1
            },
            {
                "size": " 85х15х1",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 85х15х1,2",
                "koef": "0.000959",
                "metrs": 1
            },
            {
                "size": " 85х15х1,5",
                "koef": "0.001195",
                "metrs": 1
            },
            {
                "size": " 85х15х1,8",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 85х15х2",
                "koef": "0.001585",
                "metrs": 1
            },
            {
                "size": " 85х15х2,5",
                "koef": "0.001971",
                "metrs": 1
            },
            {
                "size": " 85х15х3",
                "koef": "0.002353",
                "metrs": 1
            },
            {
                "size": " 85х15х3,5",
                "koef": "0.002731",
                "metrs": 1
            },
            {
                "size": " 85х20х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 85х20х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 85х20х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 85х20х1,8",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 85х20х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 85х20х2,5",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 85х20х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 85х20х3,5",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 85х25х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 85х25х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 85х25х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 85х25х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 85х25х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 85х25х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 85х25х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 85х25х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 85х30х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 85х30х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 85х30х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 85х30х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 85х30х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 85х30х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 85х30х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 85х30х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 85х35х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 85х35х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 85х35х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 85х35х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 85х35х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 85х35х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 85х35х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 85х35х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 85х40х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 85х40х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 85х40х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 85х40х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 85х40х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 85х40х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 85х40х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 85х40х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 85х45х1",
                "koef": "0.001043",
                "metrs": 1
            },
            {
                "size": " 85х45х1,2",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 85х45х1,5",
                "koef": "0.001558",
                "metrs": 1
            },
            {
                "size": " 85х45х1,8",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": " 85х45х2",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 85х45х2,5",
                "koef": "0.002577",
                "metrs": 1
            },
            {
                "size": " 85х45х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 85х45х3,5",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": " 85х50х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 85х50х1,2",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 85х50х1,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": " 85х50х1,8",
                "koef": "0.001939",
                "metrs": 1
            },
            {
                "size": " 85х50х2",
                "koef": "0.002151",
                "metrs": 1
            },
            {
                "size": " 85х50х2,5",
                "koef": "0.002678",
                "metrs": 1
            },
            {
                "size": " 85х50х3",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 85х50х3,5",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": " 85х55х1",
                "koef": "0.001124",
                "metrs": 1
            },
            {
                "size": " 85х55х1,2",
                "koef": "0.001347",
                "metrs": 1
            },
            {
                "size": " 85х55х1,5",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 85х55х1,8",
                "koef": "0.002011",
                "metrs": 1
            },
            {
                "size": " 85х55х2",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 85х55х2,5",
                "koef": "0.002779",
                "metrs": 1
            },
            {
                "size": " 85х55х3",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": " 85х55х3,5",
                "koef": "0.003863",
                "metrs": 1
            },
            {
                "size": " 85х60х1",
                "koef": "0.001164",
                "metrs": 1
            },
            {
                "size": " 85х60х1,2",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": " 85х60х1,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 85х60х1,8",
                "koef": "0.002084",
                "metrs": 1
            },
            {
                "size": " 85х60х2",
                "koef": "0.002312",
                "metrs": 1
            },
            {
                "size": " 85х60х2,5",
                "koef": "0.00288",
                "metrs": 1
            },
            {
                "size": " 85х60х3",
                "koef": "0.003444",
                "metrs": 1
            },
            {
                "size": " 85х60х3,5",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": " 85х65х1",
                "koef": "0.001205",
                "metrs": 1
            },
            {
                "size": " 85х65х1,2",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": " 85х65х1,5",
                "koef": "0.001801",
                "metrs": 1
            },
            {
                "size": " 85х65х1,8",
                "koef": "0.002157",
                "metrs": 1
            },
            {
                "size": " 85х65х2",
                "koef": "0.002393",
                "metrs": 1
            },
            {
                "size": " 85х65х2,5",
                "koef": "0.002982",
                "metrs": 1
            },
            {
                "size": " 85х65х3",
                "koef": "0.003566",
                "metrs": 1
            },
            {
                "size": " 85х65х3,5",
                "koef": "0.004146",
                "metrs": 1
            },
            {
                "size": " 85х70х1",
                "koef": "0.001245",
                "metrs": 1
            },
            {
                "size": " 85х70х1,2",
                "koef": "0.001492",
                "metrs": 1
            },
            {
                "size": " 85х70х1,5",
                "koef": "0.001862",
                "metrs": 1
            },
            {
                "size": " 85х70х1,8",
                "koef": "0.00223",
                "metrs": 1
            },
            {
                "size": " 85х70х2",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 85х70х2,5",
                "koef": "0.003083",
                "metrs": 1
            },
            {
                "size": " 85х70х3",
                "koef": "0.003687",
                "metrs": 1
            },
            {
                "size": " 85х70х3,5",
                "koef": "0.004287",
                "metrs": 1
            },
            {
                "size": " 85х75х1",
                "koef": "0.001286",
                "metrs": 1
            },
            {
                "size": " 85х75х1,2",
                "koef": "0.001541",
                "metrs": 1
            },
            {
                "size": " 85х75х1,5",
                "koef": "0.001922",
                "metrs": 1
            },
            {
                "size": " 85х75х1,8",
                "koef": "0.002302",
                "metrs": 1
            },
            {
                "size": " 85х75х2",
                "koef": "0.002555",
                "metrs": 1
            },
            {
                "size": " 85х75х2,5",
                "koef": "0.003184",
                "metrs": 1
            },
            {
                "size": " 85х75х3",
                "koef": "0.003808",
                "metrs": 1
            },
            {
                "size": " 85х75х3,5",
                "koef": "0.004429",
                "metrs": 1
            },
            {
                "size": " 85х80х1",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": " 85х80х1,2",
                "koef": "0.001589",
                "metrs": 1
            },
            {
                "size": " 85х80х1,5",
                "koef": "0.001983",
                "metrs": 1
            },
            {
                "size": " 85х80х1,8",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 85х80х2",
                "koef": "0.002636",
                "metrs": 1
            },
            {
                "size": " 85х80х2,5",
                "koef": "0.003285",
                "metrs": 1
            },
            {
                "size": " 85х80х3",
                "koef": "0.00393",
                "metrs": 1
            },
            {
                "size": " 85х80х3,5",
                "koef": "0.00457",
                "metrs": 1
            },
            {
                "size": " 85х85х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": " 85х85х1,2",
                "koef": "0.001638",
                "metrs": 1
            },
            {
                "size": " 85х85х1,5",
                "koef": "0.002044",
                "metrs": 1
            },
            {
                "size": " 85х85х1,8",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 85х85х2",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 85х85х2,5",
                "koef": "0.003386",
                "metrs": 1
            },
            {
                "size": " 85х85х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": " 85х85х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": " 85х90х1",
                "koef": "0.001407",
                "metrs": 1
            },
            {
                "size": " 85х90х1,2",
                "koef": "0.001686",
                "metrs": 1
            },
            {
                "size": " 85х90х1,5",
                "koef": "0.002104",
                "metrs": 1
            },
            {
                "size": " 85х90х1,8",
                "koef": "0.002521",
                "metrs": 1
            },
            {
                "size": " 85х90х2",
                "koef": "0.002798",
                "metrs": 1
            },
            {
                "size": " 85х90х2,5",
                "koef": "0.003487",
                "metrs": 1
            },
            {
                "size": " 85х90х3",
                "koef": "0.004172",
                "metrs": 1
            },
            {
                "size": " 85х90х3,5",
                "koef": "0.004853",
                "metrs": 1
            },
            {
                "size": " 85х95х1",
                "koef": "0.001447",
                "metrs": 1
            },
            {
                "size": " 85х95х1,2",
                "koef": "0.001735",
                "metrs": 1
            },
            {
                "size": " 85х95х1,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 85х95х1,8",
                "koef": "0.002594",
                "metrs": 1
            },
            {
                "size": " 85х95х2",
                "koef": "0.002878",
                "metrs": 1
            },
            {
                "size": " 85х95х2,5",
                "koef": "0.003588",
                "metrs": 1
            },
            {
                "size": " 85х95х3",
                "koef": "0.004293",
                "metrs": 1
            },
            {
                "size": " 85х95х3,5",
                "koef": "0.004995",
                "metrs": 1
            },
            {
                "size": " 85х100х1",
                "koef": "0.001488",
                "metrs": 1
            },
            {
                "size": " 85х100х1,2",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 85х100х1,5",
                "koef": "0.002226",
                "metrs": 1
            },
            {
                "size": " 85х100х1,8",
                "koef": "0.002666",
                "metrs": 1
            },
            {
                "size": " 85х100х2",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 85х100х2,5",
                "koef": "0.003689",
                "metrs": 1
            },
            {
                "size": " 85х100х3",
                "koef": "0.004415",
                "metrs": 1
            },
            {
                "size": " 85х100х3,5",
                "koef": "0.005136",
                "metrs": 1
            },
            {
                "size": " 87х87х2,5",
                "koef": "0.003467",
                "metrs": 1
            },
            {
                "size": " 90х15х1",
                "koef": "0.000841",
                "metrs": 1
            },
            {
                "size": " 90х15х1,2",
                "koef": "0.001007",
                "metrs": 1
            },
            {
                "size": " 90х15х1,5",
                "koef": "0.001255",
                "metrs": 1
            },
            {
                "size": " 90х15х1,8 ",
                "koef": "0.001502",
                "metrs": 1
            },
            {
                "size": " 90х15х2",
                "koef": "0.001666",
                "metrs": 1
            },
            {
                "size": " 90х15х2,5 ",
                "koef": "0.002072",
                "metrs": 1
            },
            {
                "size": " 90х15х3",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 90х15х3,5 ",
                "koef": "0.002872",
                "metrs": 1
            },
            {
                "size": " 90х20х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 90х20х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 90х20х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 90х20х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 90х20х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 90х20х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 90х20х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 90х20х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 90х25х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 90х25х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 90х25х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 90х25х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 90х25х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 90х25х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 90х25х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 90х25х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 90х30х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 90х30х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 90х30х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 90х30х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 90х30х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 90х30х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 90х30х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 90х30х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 90х35х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 90х35х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 90х35х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 90х35х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 90х35х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 90х35х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 90х35х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 90х35х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 90х40х1",
                "koef": "0.001043",
                "metrs": 1
            },
            {
                "size": " 90х40х1,2",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 90х40х1,5",
                "koef": "0.001558",
                "metrs": 1
            },
            {
                "size": " 90х40х1,8",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": " 90х40х2",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 90х40х2,5",
                "koef": "0.002577",
                "metrs": 1
            },
            {
                "size": " 90х40х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 90х40х3,5",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": " 90х45х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 90х45х1,2",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 90х45х1,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": " 90х45х1,8",
                "koef": "0.001939",
                "metrs": 1
            },
            {
                "size": " 90х45х2",
                "koef": "0.002151",
                "metrs": 1
            },
            {
                "size": " 90х45х2,5",
                "koef": "0.002678",
                "metrs": 1
            },
            {
                "size": " 90х45х3",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 90х45х3,5",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": " 90х50х1",
                "koef": "0.001124",
                "metrs": 1
            },
            {
                "size": " 90х50х1,2",
                "koef": "0.001347",
                "metrs": 1
            },
            {
                "size": " 90х50х1,5",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 90х50х1,8",
                "koef": "0.002011",
                "metrs": 1
            },
            {
                "size": " 90х50х2",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 90х50х2,5",
                "koef": "0.002779",
                "metrs": 1
            },
            {
                "size": " 90х50х3",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": " 90х50х3,5",
                "koef": "0.003863",
                "metrs": 1
            },
            {
                "size": " 90х55х1",
                "koef": "0.001164",
                "metrs": 1
            },
            {
                "size": " 90х55х1,2",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": " 90х55х1,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 90х55х1,8",
                "koef": "0.002084",
                "metrs": 1
            },
            {
                "size": " 90х55х2",
                "koef": "0.002312",
                "metrs": 1
            },
            {
                "size": " 90х55х2,5",
                "koef": "0.00288",
                "metrs": 1
            },
            {
                "size": " 90х55х3",
                "koef": "0.003444",
                "metrs": 1
            },
            {
                "size": " 90х55х3,5",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": " 90х56х3",
                "koef": "0.003469",
                "metrs": 1
            },
            {
                "size": " 90х56х4",
                "koef": "0.004593",
                "metrs": 1
            },
            {
                "size": " 90х56х5",
                "koef": "0.0057",
                "metrs": 1
            },
            {
                "size": " 90х56х6",
                "koef": "0.006792",
                "metrs": 1
            },
            {
                "size": " 90х60х1",
                "koef": "0.001205",
                "metrs": 1
            },
            {
                "size": " 90х60х1,2",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": " 90х60х1,5",
                "koef": "0.001801",
                "metrs": 1
            },
            {
                "size": " 90х60х1,8",
                "koef": "0.002157",
                "metrs": 1
            },
            {
                "size": " 90х60х2",
                "koef": "0.002393",
                "metrs": 1
            },
            {
                "size": " 90х60х2,5",
                "koef": "0.002982",
                "metrs": 1
            },
            {
                "size": " 90х60х3",
                "koef": "0.003566",
                "metrs": 1
            },
            {
                "size": " 90х60х3,5",
                "koef": "0.004146",
                "metrs": 1
            },
            {
                "size": " 90х65х1",
                "koef": "0.001245",
                "metrs": 1
            },
            {
                "size": " 90х65х1,2",
                "koef": "0.001492",
                "metrs": 1
            },
            {
                "size": " 90х65х1,5",
                "koef": "0.001862",
                "metrs": 1
            },
            {
                "size": " 90х65х1,8",
                "koef": "0.00223",
                "metrs": 1
            },
            {
                "size": " 90х65х2",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 90х65х2,5",
                "koef": "0.003083",
                "metrs": 1
            },
            {
                "size": " 90х65х3",
                "koef": "0.003687",
                "metrs": 1
            },
            {
                "size": " 90х65х3,5",
                "koef": "0.004287",
                "metrs": 1
            },
            {
                "size": " 90х70х1",
                "koef": "0.001286",
                "metrs": 1
            },
            {
                "size": " 90х70х1,2",
                "koef": "0.001541",
                "metrs": 1
            },
            {
                "size": " 90х70х1,5",
                "koef": "0.001922",
                "metrs": 1
            },
            {
                "size": " 90х70х1,8",
                "koef": "0.002302",
                "metrs": 1
            },
            {
                "size": " 90х70х2",
                "koef": "0.002555",
                "metrs": 1
            },
            {
                "size": " 90х70х2,5",
                "koef": "0.003184",
                "metrs": 1
            },
            {
                "size": " 90х70х3",
                "koef": "0.003808",
                "metrs": 1
            },
            {
                "size": " 90х70х3,5",
                "koef": "0.004429",
                "metrs": 1
            },
            {
                "size": " 90х75х1",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": " 90х75х1,2",
                "koef": "0.001589",
                "metrs": 1
            },
            {
                "size": " 90х75х1,5",
                "koef": "0.001983",
                "metrs": 1
            },
            {
                "size": " 90х75х1,8",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 90х75х2",
                "koef": "0.002636",
                "metrs": 1
            },
            {
                "size": " 90х75х2,5",
                "koef": "0.003285",
                "metrs": 1
            },
            {
                "size": " 90х75х3",
                "koef": "0.00393",
                "metrs": 1
            },
            {
                "size": " 90х75х3,5",
                "koef": "0.00457",
                "metrs": 1
            },
            {
                "size": " 90х80х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": " 90х80х1,2",
                "koef": "0.001638",
                "metrs": 1
            },
            {
                "size": " 90х80х1,5",
                "koef": "0.002044",
                "metrs": 1
            },
            {
                "size": " 90х80х1,8",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 90х80х2",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 90х80х2,5",
                "koef": "0.003386",
                "metrs": 1
            },
            {
                "size": " 90х80х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": " 90х80х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": " 90х85х1",
                "koef": "0.001407",
                "metrs": 1
            },
            {
                "size": " 90х85х1,2",
                "koef": "0.001686",
                "metrs": 1
            },
            {
                "size": " 90х85х1,5",
                "koef": "0.002104",
                "metrs": 1
            },
            {
                "size": " 90х85х1,8",
                "koef": "0.002521",
                "metrs": 1
            },
            {
                "size": " 90х85х2",
                "koef": "0.002798",
                "metrs": 1
            },
            {
                "size": " 90х85х2,5",
                "koef": "0.003487",
                "metrs": 1
            },
            {
                "size": " 90х85х3",
                "koef": "0.004172",
                "metrs": 1
            },
            {
                "size": " 90х85х3,5",
                "koef": "0.004853",
                "metrs": 1
            },
            {
                "size": " 90х90х1",
                "koef": "0.001447",
                "metrs": 1
            },
            {
                "size": " 90х90х1,2",
                "koef": "0.001735",
                "metrs": 1
            },
            {
                "size": " 90х90х1,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 90х90х1,8",
                "koef": "0.002594",
                "metrs": 1
            },
            {
                "size": " 90х90х2",
                "koef": "0.002878",
                "metrs": 1
            },
            {
                "size": " 90х90х2,5",
                "koef": "0.003588",
                "metrs": 1
            },
            {
                "size": " 90х90х3",
                "koef": "0.004293",
                "metrs": 1
            },
            {
                "size": " 90х90х3,5",
                "koef": "0.004995",
                "metrs": 1
            },
            {
                "size": " 90х90х4",
                "koef": "0.005692",
                "metrs": 1
            },
            {
                "size": " 90х90х5",
                "koef": "0.007075",
                "metrs": 1
            },
            {
                "size": " 90х90х6",
                "koef": "0.008441",
                "metrs": 1
            },
            {
                "size": " 90х90х7",
                "koef": "0.009792",
                "metrs": 1
            },
            {
                "size": " 90х90х8",
                "koef": "0.01113",
                "metrs": 1
            },
            {
                "size": " 90х95х1",
                "koef": "0.001488",
                "metrs": 1
            },
            {
                "size": " 90х95х1,2",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 90х95х1,5",
                "koef": "0.002226",
                "metrs": 1
            },
            {
                "size": " 90х95х1,8",
                "koef": "0.002666",
                "metrs": 1
            },
            {
                "size": " 90х95х2",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 90х95х2,5",
                "koef": "0.003689",
                "metrs": 1
            },
            {
                "size": " 90х95х3",
                "koef": "0.004415",
                "metrs": 1
            },
            {
                "size": " 90х95х3,5",
                "koef": "0.005136",
                "metrs": 1
            },
            {
                "size": " 90х100х1",
                "koef": "0.001528",
                "metrs": 1
            },
            {
                "size": " 90х100х1,2",
                "koef": "0.001832",
                "metrs": 1
            },
            {
                "size": " 90х100х1,5",
                "koef": "0.002286",
                "metrs": 1
            },
            {
                "size": " 90х100х1,8",
                "koef": "0.002739",
                "metrs": 1
            },
            {
                "size": " 90х100х2",
                "koef": "0.00304",
                "metrs": 1
            },
            {
                "size": " 90х100х2,5",
                "koef": "0.00379",
                "metrs": 1
            },
            {
                "size": " 90х100х3",
                "koef": "0.004536",
                "metrs": 1
            },
            {
                "size": " 90х100х3,5",
                "koef": "0.005278",
                "metrs": 1
            },
            {
                "size": " 95х15х1",
                "koef": "0.000881",
                "metrs": 1
            },
            {
                "size": " 95х15х1,2",
                "koef": "0.001056",
                "metrs": 1
            },
            {
                "size": " 95х15х1,5",
                "koef": "0.001316",
                "metrs": 1
            },
            {
                "size": " 95х15х1,8",
                "koef": "0.001575",
                "metrs": 1
            },
            {
                "size": " 95х15х2",
                "koef": "0.001746",
                "metrs": 1
            },
            {
                "size": " 95х15х2,5",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 95х15х3",
                "koef": "0.002595",
                "metrs": 1
            },
            {
                "size": " 95х15х3,5",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 95х20х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 95х20х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 95х20х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 95х20х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 95х20х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 95х20х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 95х20х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 95х20х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 95х25х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 95х25х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 95х25х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 95х25х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 95х25х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 95х25х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 95х25х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 95х25х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 95х30х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 95х30х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 95х30х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 95х30х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 95х30х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 95х30х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 95х30х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 95х30х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 95х35х1",
                "koef": "0.001043",
                "metrs": 1
            },
            {
                "size": " 95х35х1,2",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 95х35х1,5",
                "koef": "0.001558",
                "metrs": 1
            },
            {
                "size": " 95х35х1,8",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": " 95х35х2",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 95х35х2,5",
                "koef": "0.002577",
                "metrs": 1
            },
            {
                "size": " 95х35х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 95х35х3,5",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": " 95х40х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 95х40х1,2",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 95х40х1,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": " 95х40х1,8",
                "koef": "0.001939",
                "metrs": 1
            },
            {
                "size": " 95х40х2",
                "koef": "0.002151",
                "metrs": 1
            },
            {
                "size": " 95х40х2,5",
                "koef": "0.002678",
                "metrs": 1
            },
            {
                "size": " 95х40х3",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 95х40х3,5",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": " 95х45х1",
                "koef": "0.001124",
                "metrs": 1
            },
            {
                "size": " 95х45х1,2",
                "koef": "0.001347",
                "metrs": 1
            },
            {
                "size": " 95х45х1,5",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 95х45х1,8",
                "koef": "0.002011",
                "metrs": 1
            },
            {
                "size": " 95х45х2",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 95х45х2,5",
                "koef": "0.002779",
                "metrs": 1
            },
            {
                "size": " 95х45х3",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": " 95х45х3,5",
                "koef": "0.003863",
                "metrs": 1
            },
            {
                "size": " 95х50х1",
                "koef": "0.001164",
                "metrs": 1
            },
            {
                "size": " 95х50х1,2",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": " 95х50х1,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 95х50х1,8",
                "koef": "0.002084",
                "metrs": 1
            },
            {
                "size": " 95х50х2",
                "koef": "0.002312",
                "metrs": 1
            },
            {
                "size": " 95х50х2,5",
                "koef": "0.00288",
                "metrs": 1
            },
            {
                "size": " 95х50х3",
                "koef": "0.003444",
                "metrs": 1
            },
            {
                "size": " 95х50х3,5",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": " 95х55х1",
                "koef": "0.001205",
                "metrs": 1
            },
            {
                "size": " 95х55х1,2",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": " 95х55х1,5",
                "koef": "0.001801",
                "metrs": 1
            },
            {
                "size": " 95х55х1,8",
                "koef": "0.002157",
                "metrs": 1
            },
            {
                "size": " 95х55х2",
                "koef": "0.002393",
                "metrs": 1
            },
            {
                "size": " 95х55х2,5",
                "koef": "0.002982",
                "metrs": 1
            },
            {
                "size": " 95х55х3",
                "koef": "0.003566",
                "metrs": 1
            },
            {
                "size": " 95х55х3,5",
                "koef": "0.004146",
                "metrs": 1
            },
            {
                "size": " 95х60х1",
                "koef": "0.001245",
                "metrs": 1
            },
            {
                "size": " 95х60х1,2",
                "koef": "0.001492",
                "metrs": 1
            },
            {
                "size": " 95х60х1,5",
                "koef": "0.001862",
                "metrs": 1
            },
            {
                "size": " 95х60х1,8",
                "koef": "0.00223",
                "metrs": 1
            },
            {
                "size": " 95х60х2",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 95х60х2,5",
                "koef": "0.003083",
                "metrs": 1
            },
            {
                "size": " 95х60х3",
                "koef": "0.003687",
                "metrs": 1
            },
            {
                "size": " 95х60х3,5",
                "koef": "0.004287",
                "metrs": 1
            },
            {
                "size": " 95х65х1",
                "koef": "0.001286",
                "metrs": 1
            },
            {
                "size": " 95х65х1,2",
                "koef": "0.001541",
                "metrs": 1
            },
            {
                "size": " 95х65х1,5",
                "koef": "0.001922",
                "metrs": 1
            },
            {
                "size": " 95х65х1,8",
                "koef": "0.002302",
                "metrs": 1
            },
            {
                "size": " 95х65х2",
                "koef": "0.002555",
                "metrs": 1
            },
            {
                "size": " 95х65х2,5",
                "koef": "0.003184",
                "metrs": 1
            },
            {
                "size": " 95х65х3",
                "koef": "0.003808",
                "metrs": 1
            },
            {
                "size": " 95х65х3,5",
                "koef": "0.004429",
                "metrs": 1
            },
            {
                "size": " 95х70х1",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": " 95х70х1,2",
                "koef": "0.001589",
                "metrs": 1
            },
            {
                "size": " 95х70х1,5",
                "koef": "0.001983",
                "metrs": 1
            },
            {
                "size": " 95х70х1,8",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 95х70х2",
                "koef": "0.002636",
                "metrs": 1
            },
            {
                "size": " 95х70х2,5",
                "koef": "0.003285",
                "metrs": 1
            },
            {
                "size": " 95х70х3",
                "koef": "0.00393",
                "metrs": 1
            },
            {
                "size": " 95х70х3,5",
                "koef": "0.00457",
                "metrs": 1
            },
            {
                "size": " 95х75х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": " 95х75х1,2",
                "koef": "0.001638",
                "metrs": 1
            },
            {
                "size": " 95х75х1,5",
                "koef": "0.002044",
                "metrs": 1
            },
            {
                "size": " 95х75х1,8",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 95х75х2",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 95х75х2,5",
                "koef": "0.003386",
                "metrs": 1
            },
            {
                "size": " 95х75х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": " 95х75х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": " 95х80х1",
                "koef": "0.001407",
                "metrs": 1
            },
            {
                "size": " 95х80х1,2",
                "koef": "0.001686",
                "metrs": 1
            },
            {
                "size": " 95х80х1,5",
                "koef": "0.002104",
                "metrs": 1
            },
            {
                "size": " 95х80х1,8",
                "koef": "0.002521",
                "metrs": 1
            },
            {
                "size": " 95х80х2",
                "koef": "0.002798",
                "metrs": 1
            },
            {
                "size": " 95х80х2,5",
                "koef": "0.003487",
                "metrs": 1
            },
            {
                "size": " 95х80х3",
                "koef": "0.004172",
                "metrs": 1
            },
            {
                "size": " 95х80х3,5",
                "koef": "0.004853",
                "metrs": 1
            },
            {
                "size": " 95х85х1",
                "koef": "0.001447",
                "metrs": 1
            },
            {
                "size": " 95х85х1,2",
                "koef": "0.001735",
                "metrs": 1
            },
            {
                "size": " 95х85х1,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 95х85х1,8",
                "koef": "0.002594",
                "metrs": 1
            },
            {
                "size": " 95х85х2",
                "koef": "0.002878",
                "metrs": 1
            },
            {
                "size": " 95х85х2,5",
                "koef": "0.003588",
                "metrs": 1
            },
            {
                "size": " 95х85х3",
                "koef": "0.004293",
                "metrs": 1
            },
            {
                "size": " 95х85х3,5",
                "koef": "0.004995",
                "metrs": 1
            },
            {
                "size": " 95х90х1",
                "koef": "0.001488",
                "metrs": 1
            },
            {
                "size": " 95х90х1,2",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 95х90х1,5",
                "koef": "0.002226",
                "metrs": 1
            },
            {
                "size": " 95х90х1,8",
                "koef": "0.002666",
                "metrs": 1
            },
            {
                "size": " 95х90х2",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 95х90х2,5",
                "koef": "0.003689",
                "metrs": 1
            },
            {
                "size": " 95х90х3",
                "koef": "0.004415",
                "metrs": 1
            },
            {
                "size": " 95х90х3,5",
                "koef": "0.005136",
                "metrs": 1
            },
            {
                "size": " 95х95х1",
                "koef": "0.001528",
                "metrs": 1
            },
            {
                "size": " 95х95х1,2",
                "koef": "0.001832",
                "metrs": 1
            },
            {
                "size": " 95х95х1,5",
                "koef": "0.002286",
                "metrs": 1
            },
            {
                "size": " 95х95х1,8",
                "koef": "0.002739",
                "metrs": 1
            },
            {
                "size": " 95х95х2",
                "koef": "0.00304",
                "metrs": 1
            },
            {
                "size": " 95х95х2,5",
                "koef": "0.00379",
                "metrs": 1
            },
            {
                "size": " 95х95х3",
                "koef": "0.004536",
                "metrs": 1
            },
            {
                "size": " 95х95х3,5",
                "koef": "0.005278",
                "metrs": 1
            },
            {
                "size": " 95х100х1",
                "koef": "0.001569",
                "metrs": 1
            },
            {
                "size": " 95х100х1,2",
                "koef": "0.00188",
                "metrs": 1
            },
            {
                "size": " 95х100х1,5",
                "koef": "0.002347",
                "metrs": 1
            },
            {
                "size": " 95х100х1,8",
                "koef": "0.002812",
                "metrs": 1
            },
            {
                "size": " 95х100х2",
                "koef": "0.003121",
                "metrs": 1
            },
            {
                "size": " 95х100х2,5",
                "koef": "0.003891",
                "metrs": 1
            },
            {
                "size": " 95х100х3",
                "koef": "0.004657",
                "metrs": 1
            },
            {
                "size": " 95х100х3,5",
                "koef": "0.005419",
                "metrs": 1
            },
            {
                "size": " 97х97х2,45",
                "koef": "0.003795",
                "metrs": 1
            },
            {
                "size": " 100х15х1",
                "koef": "0.000922",
                "metrs": 1
            },
            {
                "size": " 100х15х1,2",
                "koef": "0.001104",
                "metrs": 1
            },
            {
                "size": " 100х15х1,5",
                "koef": "0.001377",
                "metrs": 1
            },
            {
                "size": " 100х15х1,8",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 100х15х2",
                "koef": "0.001827",
                "metrs": 1
            },
            {
                "size": " 100х15х2,5",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 100х15х3",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 100х15х3,5",
                "koef": "0.003155",
                "metrs": 1
            },
            {
                "size": " 100х20х1",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 100х20х1,2",
                "koef": "0.001153",
                "metrs": 1
            },
            {
                "size": " 100х20х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 100х20х1,8",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 100х20х2",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 100х20х2,5",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 100х20х3",
                "koef": "0.002838",
                "metrs": 1
            },
            {
                "size": " 100х20х3,5",
                "koef": "0.003297",
                "metrs": 1
            },
            {
                "size": " 100х25х1",
                "koef": "0.001003",
                "metrs": 1
            },
            {
                "size": " 100х25х1,2",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 100х25х1,5",
                "koef": "0.001498",
                "metrs": 1
            },
            {
                "size": " 100х25х1,8",
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": " 100х25х2",
                "koef": "0.001989",
                "metrs": 1
            },
            {
                "size": " 100х25х2,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 100х25х3",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 100х25х3,5",
                "koef": "0.003438",
                "metrs": 1
            },
            {
                "size": " 100х30х1",
                "koef": "0.001043",
                "metrs": 1
            },
            {
                "size": " 100х30х1,2",
                "koef": "0.00125",
                "metrs": 1
            },
            {
                "size": " 100х30х1,5",
                "koef": "0.001558",
                "metrs": 1
            },
            {
                "size": " 100х30х1,8",
                "koef": "0.001866",
                "metrs": 1
            },
            {
                "size": " 100х30х2",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 100х30х2,5",
                "koef": "0.002577",
                "metrs": 1
            },
            {
                "size": " 100х30х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 100х30х3,5",
                "koef": "0.00358",
                "metrs": 1
            },
            {
                "size": " 100х35х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 100х35х1,2",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 100х35х1,5",
                "koef": "0.001619",
                "metrs": 1
            },
            {
                "size": " 100х35х1,8",
                "koef": "0.001939",
                "metrs": 1
            },
            {
                "size": " 100х35х2",
                "koef": "0.002151",
                "metrs": 1
            },
            {
                "size": " 100х35х2,5",
                "koef": "0.002678",
                "metrs": 1
            },
            {
                "size": " 100х35х3",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 100х35х3,5",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": " 100х40х1",
                "koef": "0.001124",
                "metrs": 1
            },
            {
                "size": " 100х40х1,2",
                "koef": "0.001347",
                "metrs": 1
            },
            {
                "size": " 100х40х1,5",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 100х40х1,8",
                "koef": "0.002011",
                "metrs": 1
            },
            {
                "size": " 100х40х2",
                "koef": "0.002232",
                "metrs": 1
            },
            {
                "size": " 100х40х2,5",
                "koef": "0.002779",
                "metrs": 1
            },
            {
                "size": " 100х40х3",
                "koef": "0.003323",
                "metrs": 1
            },
            {
                "size": " 100х40х3,5",
                "koef": "0.003863",
                "metrs": 1
            },
            {
                "size": " 100х45х1",
                "koef": "0.001164",
                "metrs": 1
            },
            {
                "size": " 100х45х1,2",
                "koef": "0.001395",
                "metrs": 1
            },
            {
                "size": " 100х45х1,5",
                "koef": "0.00174",
                "metrs": 1
            },
            {
                "size": " 100х45х1,8",
                "koef": "0.002084",
                "metrs": 1
            },
            {
                "size": " 100х45х2",
                "koef": "0.002312",
                "metrs": 1
            },
            {
                "size": " 100х45х2,5",
                "koef": "0.00288",
                "metrs": 1
            },
            {
                "size": " 100х45х3",
                "koef": "0.003444",
                "metrs": 1
            },
            {
                "size": " 100х45х3,5",
                "koef": "0.004004",
                "metrs": 1
            },
            {
                "size": " 100х50х1",
                "koef": "0.001205",
                "metrs": 1
            },
            {
                "size": " 100х50х1,2",
                "koef": "0.001444",
                "metrs": 1
            },
            {
                "size": " 100х50х1,5",
                "koef": "0.001801",
                "metrs": 1
            },
            {
                "size": " 100х50х1,8",
                "koef": "0.002157",
                "metrs": 1
            },
            {
                "size": " 100х50х2",
                "koef": "0.002393",
                "metrs": 1
            },
            {
                "size": " 100х50х2,5",
                "koef": "0.002982",
                "metrs": 1
            },
            {
                "size": " 100х50х3",
                "koef": "0.003566",
                "metrs": 1
            },
            {
                "size": " 100х50х3,5",
                "koef": "0.004146",
                "metrs": 1
            },
            {
                "size": " 100х55х1",
                "koef": "0.001245",
                "metrs": 1
            },
            {
                "size": " 100х55х1,2",
                "koef": "0.001492",
                "metrs": 1
            },
            {
                "size": " 100х55х1,5",
                "koef": "0.001862",
                "metrs": 1
            },
            {
                "size": " 100х55х1,8",
                "koef": "0.00223",
                "metrs": 1
            },
            {
                "size": " 100х55х2",
                "koef": "0.002474",
                "metrs": 1
            },
            {
                "size": " 100х55х2,5",
                "koef": "0.003083",
                "metrs": 1
            },
            {
                "size": " 100х55х3",
                "koef": "0.003687",
                "metrs": 1
            },
            {
                "size": " 100х55х3,5",
                "koef": "0.004287",
                "metrs": 1
            },
            {
                "size": " 100х60х1",
                "koef": "0.001286",
                "metrs": 1
            },
            {
                "size": " 100х60х1,2",
                "koef": "0.001541",
                "metrs": 1
            },
            {
                "size": " 100х60х1,5",
                "koef": "0.001922",
                "metrs": 1
            },
            {
                "size": " 100х60х1,8",
                "koef": "0.002302",
                "metrs": 1
            },
            {
                "size": " 100х60х2",
                "koef": "0.002555",
                "metrs": 1
            },
            {
                "size": " 100х60х2,5",
                "koef": "0.003184",
                "metrs": 1
            },
            {
                "size": " 100х60х3",
                "koef": "0.003808",
                "metrs": 1
            },
            {
                "size": " 100х60х3,5",
                "koef": "0.004429",
                "metrs": 1
            },
            {
                "size": " 100х63х3",
                "koef": "0.003881",
                "metrs": 1
            },
            {
                "size": " 100х63х4",
                "koef": "0.005142",
                "metrs": 1
            },
            {
                "size": " 100х63х5",
                "koef": "0.006388",
                "metrs": 1
            },
            {
                "size": " 100х63х6",
                "koef": "0.007617",
                "metrs": 1
            },
            {
                "size": " 100х63х7",
                "koef": "0.008829",
                "metrs": 1
            },
            {
                "size": " 100х63х8",
                "koef": "0.01003",
                "metrs": 1
            },
            {
                "size": " 100х65х1",
                "koef": "0.001326",
                "metrs": 1
            },
            {
                "size": " 100х65х1,2",
                "koef": "0.001589",
                "metrs": 1
            },
            {
                "size": " 100х65х1,5",
                "koef": "0.001983",
                "metrs": 1
            },
            {
                "size": " 100х65х1,8",
                "koef": "0.002375",
                "metrs": 1
            },
            {
                "size": " 100х65х2",
                "koef": "0.002636",
                "metrs": 1
            },
            {
                "size": " 100х65х2,5",
                "koef": "0.003285",
                "metrs": 1
            },
            {
                "size": " 100х65х3",
                "koef": "0.00393",
                "metrs": 1
            },
            {
                "size": " 100х65х3,5",
                "koef": "0.00457",
                "metrs": 1
            },
            {
                "size": " 100х70х1",
                "koef": "0.001366",
                "metrs": 1
            },
            {
                "size": " 100х70х1,2",
                "koef": "0.001638",
                "metrs": 1
            },
            {
                "size": " 100х70х1,5",
                "koef": "0.002044",
                "metrs": 1
            },
            {
                "size": " 100х70х1,8",
                "koef": "0.002448",
                "metrs": 1
            },
            {
                "size": " 100х70х2",
                "koef": "0.002717",
                "metrs": 1
            },
            {
                "size": " 100х70х2,5",
                "koef": "0.003386",
                "metrs": 1
            },
            {
                "size": " 100х70х3",
                "koef": "0.004051",
                "metrs": 1
            },
            {
                "size": " 100х70х3,5",
                "koef": "0.004712",
                "metrs": 1
            },
            {
                "size": " 100х75х1",
                "koef": "0.001407",
                "metrs": 1
            },
            {
                "size": " 100х75х1,2",
                "koef": "0.001686",
                "metrs": 1
            },
            {
                "size": " 100х75х1,5",
                "koef": "0.002104",
                "metrs": 1
            },
            {
                "size": " 100х75х1,8",
                "koef": "0.002521",
                "metrs": 1
            },
            {
                "size": " 100х75х2",
                "koef": "0.002798",
                "metrs": 1
            },
            {
                "size": " 100х75х2,5",
                "koef": "0.003487",
                "metrs": 1
            },
            {
                "size": " 100х75х3",
                "koef": "0.004172",
                "metrs": 1
            },
            {
                "size": " 100х75х3,5",
                "koef": "0.004853",
                "metrs": 1
            },
            {
                "size": " 100х80х1",
                "koef": "0.001447",
                "metrs": 1
            },
            {
                "size": " 100х80х1,2",
                "koef": "0.001735",
                "metrs": 1
            },
            {
                "size": " 100х80х1,5",
                "koef": "0.002165",
                "metrs": 1
            },
            {
                "size": " 100х80х1,8",
                "koef": "0.002594",
                "metrs": 1
            },
            {
                "size": " 100х80х2",
                "koef": "0.002878",
                "metrs": 1
            },
            {
                "size": " 100х80х2,5",
                "koef": "0.003588",
                "metrs": 1
            },
            {
                "size": " 100х80х3",
                "koef": "0.004293",
                "metrs": 1
            },
            {
                "size": " 100х80х3,5",
                "koef": "0.004995",
                "metrs": 1
            },
            {
                "size": " 100х85х1",
                "koef": "0.001488",
                "metrs": 1
            },
            {
                "size": " 100х85х1,2",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 100х85х1,5",
                "koef": "0.002226",
                "metrs": 1
            },
            {
                "size": " 100х85х1,8",
                "koef": "0.002666",
                "metrs": 1
            },
            {
                "size": " 100х85х2",
                "koef": "0.002959",
                "metrs": 1
            },
            {
                "size": " 100х85х2,5",
                "koef": "0.003689",
                "metrs": 1
            },
            {
                "size": " 100х85х3",
                "koef": "0.004415",
                "metrs": 1
            },
            {
                "size": " 100х85х3,5",
                "koef": "0.005136",
                "metrs": 1
            },
            {
                "size": " 100х90х1",
                "koef": "0.001528",
                "metrs": 1
            },
            {
                "size": " 100х90х1,2",
                "koef": "0.001832",
                "metrs": 1
            },
            {
                "size": " 100х90х1,5",
                "koef": "0.002286",
                "metrs": 1
            },
            {
                "size": " 100х90х1,8",
                "koef": "0.002739",
                "metrs": 1
            },
            {
                "size": " 100х90х2",
                "koef": "0.00304",
                "metrs": 1
            },
            {
                "size": " 100х90х2,5",
                "koef": "0.00379",
                "metrs": 1
            },
            {
                "size": " 100х90х3",
                "koef": "0.004536",
                "metrs": 1
            },
            {
                "size": " 100х90х3,5",
                "koef": "0.005278",
                "metrs": 1
            },
            {
                "size": " 100х95х1",
                "koef": "0.001569",
                "metrs": 1
            },
            {
                "size": " 100х95х1,2",
                "koef": "0.00188",
                "metrs": 1
            },
            {
                "size": " 100х95х1,5",
                "koef": "0.002347",
                "metrs": 1
            },
            {
                "size": " 100х95х1,8",
                "koef": "0.002812",
                "metrs": 1
            },
            {
                "size": " 100х95х2",
                "koef": "0.003121",
                "metrs": 1
            },
            {
                "size": " 100х95х2,5",
                "koef": "0.003891",
                "metrs": 1
            },
            {
                "size": " 100х95х3",
                "koef": "0.004657",
                "metrs": 1
            },
            {
                "size": " 100х95х3,5",
                "koef": "0.005419",
                "metrs": 1
            },
            {
                "size": " 100х100х1",
                "koef": "0.001609",
                "metrs": 1
            },
            {
                "size": " 100х100х1,2",
                "koef": "0.001929",
                "metrs": 1
            },
            {
                "size": " 100х100х1,5",
                "koef": "0.002407",
                "metrs": 1
            },
            {
                "size": " 100х100х1,8",
                "koef": "0.002885",
                "metrs": 1
            },
            {
                "size": " 100х100х2",
                "koef": "0.003202",
                "metrs": 1
            },
            {
                "size": " 100х100х2,5",
                "koef": "0.003992",
                "metrs": 1
            },
            {
                "size": " 100х100х3",
                "koef": "0.004779",
                "metrs": 1
            },
            {
                "size": " 100х100х3,5",
                "koef": "0.005561",
                "metrs": 1
            },
            {
                "size": " 100х100х4",
                "koef": "0.006232",
                "metrs": 1
            },
            {
                "size": " 100х100х5",
                "koef": "0.007715",
                "metrs": 1
            },
            {
                "size": " 100х100х6",
                "koef": "0.009157",
                "metrs": 1
            },
            {
                "size": " 115х80х3",
                "koef": "0.004657",
                "metrs": 1
            },
            {
                "size": " 115х80х4",
                "koef": "0.006177",
                "metrs": 1
            },
            {
                "size": " 115х115х3",
                "koef": "0.005506",
                "metrs": 1
            },
            {
                "size": " 115х115х4",
                "koef": "0.007309",
                "metrs": 1
            },
            {
                "size": " 120х80х3",
                "koef": "0.004779",
                "metrs": 1
            },
            {
                "size": " 120х80х4",
                "koef": "0.006339",
                "metrs": 1
            },
            {
                "size": " 120х120х3",
                "koef": "0.005749",
                "metrs": 1
            },
            {
                "size": " 120х120х4",
                "koef": "0.007633",
                "metrs": 1
            },
            {
                "size": " 125х80х3",
                "koef": "0.0049",
                "metrs": 1
            },
            {
                "size": " 125х80х4",
                "koef": "0.006501",
                "metrs": 1
            },
            {
                "size": " 125х80х5",
                "koef": "0.008085",
                "metrs": 1
            },
            {
                "size": " 125х80х6",
                "koef": "0.009654",
                "metrs": 1
            },
            {
                "size": " 125х125х3",
                "koef": "0.005991",
                "metrs": 1
            },
            {
                "size": " 125х125х4",
                "koef": "0.007956",
                "metrs": 1
            },
            {
                "size": " 125х125х5",
                "koef": "0.009905",
                "metrs": 1
            },
            {
                "size": " 140х90х3",
                "koef": "0.005506",
                "metrs": 1
            },
            {
                "size": " 140х90х4",
                "koef": "0.007309",
                "metrs": 1
            },
            {
                "size": " 140х90х5",
                "koef": "0.009096",
                "metrs": 1
            },
            {
                "size": " 140х90х6",
                "koef": "0.01087",
                "metrs": 1
            },
            {
                "size": " 140х140х4",
                "koef": "0.008926",
                "metrs": 1
            },
            {
                "size": " 140х140х5",
                "koef": "0.01112",
                "metrs": 1
            },
            {
                "size": " 150х50х3",
                "koef": "0.004779",
                "metrs": 1
            },
            {
                "size": " 150х50х4",
                "koef": "0.006339",
                "metrs": 1
            },
            {
                "size": " 150х50х5",
                "koef": "0.007883",
                "metrs": 1
            },
            {
                "size": " 150х50х6",
                "koef": "0.009412",
                "metrs": 1
            },
            {
                "size": " 150х100х3",
                "koef": "0.005991",
                "metrs": 1
            },
            {
                "size": " 150х100х4",
                "koef": "0.007956",
                "metrs": 1
            },
            {
                "size": " 150х100х5",
                "koef": "0.009905",
                "metrs": 1
            },
            {
                "size": " 150х100х6",
                "koef": "0.01184",
                "metrs": 1
            },
            {
                "size": " 150х150х3",
                "koef": "0.007204",
                "metrs": 1
            },
            {
                "size": " 150х150х4",
                "koef": "0.009573",
                "metrs": 1
            },
            {
                "size": " 150х150х5",
                "koef": "0.01193",
                "metrs": 1
            },
            {
                "size": " 150х150х6",
                "koef": "0.01426",
                "metrs": 1
            },
            {
                "size": " 160х100х3",
                "koef": "0.006234",
                "metrs": 1
            },
            {
                "size": " 160х100х4",
                "koef": "0.00828",
                "metrs": 1
            },
            {
                "size": " 160х100х5",
                "koef": "0.01031",
                "metrs": 1
            },
            {
                "size": " 160х100х6",
                "koef": "0.01232",
                "metrs": 1
            },
            {
                "size": " 160х160х3",
                "koef": "0.007689",
                "metrs": 1
            },
            {
                "size": " 160х160х4",
                "koef": "0.01005",
                "metrs": 1
            },
            {
                "size": " 160х160х5",
                "koef": "0.01273",
                "metrs": 1
            },
            {
                "size": " 160х160х6",
                "koef": "0.01523",
                "metrs": 1
            },
            {
                "size": " 180х100х3",
                "koef": "0.006719",
                "metrs": 1
            },
            {
                "size": " 180х100х4",
                "koef": "0.008926",
                "metrs": 1
            },
            {
                "size": " 180х100х5",
                "koef": "0.01112",
                "metrs": 1
            },
            {
                "size": " 180х100х6",
                "koef": "0.01329",
                "metrs": 1
            },
            {
                "size": " 180х180х3",
                "koef": "0.00866",
                "metrs": 1
            },
            {
                "size": " 180х180х4",
                "koef": "0.01151",
                "metrs": 1
            }
        ],
        "Уголок оцинкованный": [
            {
                "size": " 25х25х3",
                "koef": "0.001154",
                "metrs": 1
            },
            {
                "size": " 25х25х4",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 32х32х3",
                "koef": "0.001504",
                "metrs": 1
            },
            {
                "size": " 32х32х4",
                "koef": "0.001967",
                "metrs": 1
            },
            {
                "size": " 35х35х3",
                "koef": "0.001648",
                "metrs": 1
            },
            {
                "size": " 35х35х4",
                "koef": "0.002163",
                "metrs": 1
            },
            {
                "size": " 40х40х1,5",
                "koef": "0.000952",
                "metrs": 1
            },
            {
                "size": " 40х40х3",
                "koef": "0.001906",
                "metrs": 1
            },
            {
                "size": " 40х40х4",
                "koef": "0.002493",
                "metrs": 1
            },
            {
                "size": " 40х40х5",
                "koef": "0.003069",
                "metrs": 1
            },
            {
                "size": " 45х45х4",
                "koef": "0.002812",
                "metrs": 1
            },
            {
                "size": " 45х45х5",
                "koef": "0.003471",
                "metrs": 1
            },
            {
                "size": " 50х50х4",
                "koef": "0.003141",
                "metrs": 1
            },
            {
                "size": " 50х50х5",
                "koef": "0.003883",
                "metrs": 1
            },
            {
                "size": " 63х63х4",
                "koef": "0.004017",
                "metrs": 1
            },
            {
                "size": " 63х63х5",
                "koef": "0.004954",
                "metrs": 1
            },
            {
                "size": " 63х63х6",
                "koef": "0.005892",
                "metrs": 1
            },
            {
                "size": " 65х65х3",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 70х70х5",
                "koef": "0.005541",
                "metrs": 1
            },
            {
                "size": " 70х70х6",
                "koef": "0.006582",
                "metrs": 1
            },
            {
                "size": " 75х50х5",
                "koef": "0.004934",
                "metrs": 1
            },
            {
                "size": " 75х50х6",
                "koef": "0.005861",
                "metrs": 1
            },
            {
                "size": " 75х75х5",
                "koef": "0.005974",
                "metrs": 1
            },
            {
                "size": " 75х75х6",
                "koef": "0.007097",
                "metrs": 1
            },
            {
                "size": " 80х80х5",
                "koef": "0.006266",
                "metrs": 1
            },
            {
                "size": " 80х80х6",
                "koef": "0.007581",
                "metrs": 1
            },
            {
                "size": " 80х80х7",
                "koef": "0.008765",
                "metrs": 1
            },
            {
                "size": " 90х90х6",
                "koef": "0.00858",
                "metrs": 1
            },
            {
                "size": " 90х90х7",
                "koef": "0.009929",
                "metrs": 1
            },
            {
                "size": " 90х90х8",
                "koef": "0.01126",
                "metrs": 1
            },
            {
                "size": " 100х63х6",
                "koef": "0.007756",
                "metrs": 1
            },
            {
                "size": " 100х63х8",
                "koef": "0.01017",
                "metrs": 1
            },
            {
                "size": " 100х100х7",
                "koef": "0.01111",
                "metrs": 1
            },
            {
                "size": " 100х100х8",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 110х110х7",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": " 110х110х8",
                "koef": "0.0139",
                "metrs": 1
            },
            {
                "size": " 125х80х8",
                "koef": "0.01296",
                "metrs": 1
            },
            {
                "size": " 125х80х10",
                "koef": "0.01593",
                "metrs": 1
            },
            {
                "size": " 125х125х8",
                "koef": "0.01592",
                "metrs": 1
            },
            {
                "size": " 140х140х9",
                "koef": "0.01999",
                "metrs": 1
            },
            {
                "size": " 160х160х10",
                "koef": "0.02541",
                "metrs": 1
            },
            {
                "size": " 185х185х11",
                "koef": "0.03193",
                "metrs": 1
            }
        ],
        "Швеллер": [
            {
                "size": " 5П",
                "koef": "0.00484",
                "metrs": 1
            },
            {
                "size": " 5У",
                "koef": "0.00484",
                "metrs": 1
            },
            {
                "size": " 6,5П",
                "koef": "0.0059",
                "metrs": 1
            },
            {
                "size": " 6,5У",
                "koef": "0.0059",
                "metrs": 1
            },
            {
                "size": " 8П",
                "koef": "0.00705",
                "metrs": 1
            },
            {
                "size": " 8У",
                "koef": "0.00705",
                "metrs": 1
            },
            {
                "size": " 10П",
                "koef": "0.00859",
                "metrs": 1
            },
            {
                "size": " 10У",
                "koef": "0.00859",
                "metrs": 1
            },
            {
                "size": " 12П",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": " 12У",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": " 14П",
                "koef": "0.0123",
                "metrs": 1
            },
            {
                "size": " 14У",
                "koef": "0.0123",
                "metrs": 1
            },
            {
                "size": " 16П",
                "koef": "0.0142",
                "metrs": 1
            },
            {
                "size": " 16У",
                "koef": "0.0142",
                "metrs": 1
            },
            {
                "size": " 18П",
                "koef": "0.0163",
                "metrs": 1
            },
            {
                "size": " 18У",
                "koef": "0.0163",
                "metrs": 1
            },
            {
                "size": " 20П",
                "koef": "0.0184",
                "metrs": 1
            },
            {
                "size": " 20У",
                "koef": "0.0184",
                "metrs": 1
            },
            {
                "size": " 22П",
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": " 22У",
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": " 24П",
                "koef": "0.024",
                "metrs": 1
            },
            {
                "size": " 24У",
                "koef": "0.024",
                "metrs": 1
            },
            {
                "size": " 27П",
                "koef": "0.0277",
                "metrs": 1
            },
            {
                "size": " 27У",
                "koef": "0.0277",
                "metrs": 1
            },
            {
                "size": " 30П",
                "koef": "0.0318",
                "metrs": 1
            },
            {
                "size": " 30У",
                "koef": "0.0318",
                "metrs": 1
            },
            {
                "size": " 40П",
                "koef": "0.0483",
                "metrs": 1
            },
            {
                "size": " 40У",
                "koef": "0.0483",
                "metrs": 1
            }
        ],
        "Швеллер б/у": [
            {
                "size": "10П",
                "koef": "0.00859",
                "metrs": 1
            },
            {
                "size": "10У",
                "koef": "0.00859",
                "metrs": 1
            },
            {
                "size": "12П",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "12У",
                "koef": "0.0104",
                "metrs": 1
            },
            {
                "size": "14П",
                "koef": "0.0123",
                "metrs": 1
            },
            {
                "size": "14У",
                "koef": "0.0123",
                "metrs": 1
            },
            {
                "size": "16П",
                "koef": "0.0142",
                "metrs": 1
            },
            {
                "size": "16У",
                "koef": "0.0142",
                "metrs": 1
            },
            {
                "size": "18П",
                "koef": "0.0163",
                "metrs": 1
            },
            {
                "size": "18У",
                "koef": "0.0163",
                "metrs": 1
            },
            {
                "size": "20П",
                "koef": "0.0184",
                "metrs": 1
            },
            {
                "size": "20У",
                "koef": "0.0184",
                "metrs": 1
            },
            {
                "size": "22П",
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": "22У",
                "koef": "0.021",
                "metrs": 1
            },
            {
                "size": "24П",
                "koef": "0.024",
                "metrs": 1
            },
            {
                "size": "24У",
                "koef": "0.024",
                "metrs": 1
            },
            {
                "size": "27П",
                "koef": "0.0277",
                "metrs": 1
            },
            {
                "size": "27У",
                "koef": "0.0277",
                "metrs": 1
            },
            {
                "size": "30П",
                "koef": "0.0318",
                "metrs": 1
            },
            {
                "size": "30У",
                "koef": "0.0318",
                "metrs": 1
            },
            {
                "size": "36П",
                "koef": "0.0419",
                "metrs": 1
            },
            {
                "size": "36У",
                "koef": "0.0419",
                "metrs": 1
            },
            {
                "size": "40П",
                "koef": "0.0483",
                "metrs": 1
            },
            {
                "size": "40У",
                "koef": "0.0483",
                "metrs": 1
            }
        ],
        "Швеллер гнутый": [
            {
                "size": " 8х22х16х2",
                "koef": "0.000659",
                "metrs": 1
            },
            {
                "size": " 20х15х1",
                "koef": "0.000377",
                "metrs": 1
            },
            {
                "size": " 20х15х1,2",
                "koef": "0.000448",
                "metrs": 1
            },
            {
                "size": " 20х15х1,5",
                "koef": "0.000553",
                "metrs": 1
            },
            {
                "size": " 20х15х1,8",
                "koef": "0.000656",
                "metrs": 1
            },
            {
                "size": " 20х15х2",
                "koef": "0.000722",
                "metrs": 1
            },
            {
                "size": " 20х20х1",
                "koef": "0.000455",
                "metrs": 1
            },
            {
                "size": " 20х20х1,2",
                "koef": "0.000543",
                "metrs": 1
            },
            {
                "size": " 20х20х1,5",
                "koef": "0.000671",
                "metrs": 1
            },
            {
                "size": " 20х20х1,8",
                "koef": "0.000797",
                "metrs": 1
            },
            {
                "size": " 20х20х2",
                "koef": "0.000879",
                "metrs": 1
            },
            {
                "size": " 20х25х1",
                "koef": "0.000534",
                "metrs": 1
            },
            {
                "size": " 20х25х1,2",
                "koef": "0.000637",
                "metrs": 1
            },
            {
                "size": " 20х25х1,5",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": " 20х25х1,8",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 20х25х2",
                "koef": "0.001036",
                "metrs": 1
            },
            {
                "size": " 20х30х1",
                "koef": "0.000612",
                "metrs": 1
            },
            {
                "size": " 20х30х1,2",
                "koef": "0.000731",
                "metrs": 1
            },
            {
                "size": " 20х30х1,5",
                "koef": "0.000907",
                "metrs": 1
            },
            {
                "size": " 20х30х1,8",
                "koef": "0.00108",
                "metrs": 1
            },
            {
                "size": " 20х30х2",
                "koef": "0.001193",
                "metrs": 1
            },
            {
                "size": " 20х35х1",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 20х35х1,2",
                "koef": "0.000825",
                "metrs": 1
            },
            {
                "size": " 20х35х1,5",
                "koef": "0.001024",
                "metrs": 1
            },
            {
                "size": " 20х35х1,8",
                "koef": "0.001221",
                "metrs": 1
            },
            {
                "size": " 20х35х2",
                "koef": "0.00135",
                "metrs": 1
            },
            {
                "size": " 20х40х1",
                "koef": "0.000769",
                "metrs": 1
            },
            {
                "size": " 20х40х1,2",
                "koef": "0.000919",
                "metrs": 1
            },
            {
                "size": " 20х40х1,5",
                "koef": "0.001142",
                "metrs": 1
            },
            {
                "size": " 20х40х1,8",
                "koef": "0.001362",
                "metrs": 1
            },
            {
                "size": " 20х40х2",
                "koef": "0.001507",
                "metrs": 1
            },
            {
                "size": " 20х45х1",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 20х45х1,2",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": " 20х45х1,5",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": " 20х45х1,8",
                "koef": "0.001503",
                "metrs": 1
            },
            {
                "size": " 20х45х2",
                "koef": "0.001664",
                "metrs": 1
            },
            {
                "size": " 20х50х1",
                "koef": "0.000926",
                "metrs": 1
            },
            {
                "size": " 20х50х1,2",
                "koef": "0.001108",
                "metrs": 1
            },
            {
                "size": " 20х50х1,5",
                "koef": "0.001378",
                "metrs": 1
            },
            {
                "size": " 20х50х1,8",
                "koef": "0.001645",
                "metrs": 1
            },
            {
                "size": " 20х50х2",
                "koef": "0.001821",
                "metrs": 1
            },
            {
                "size": " 20х55х1",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 20х55х1,2",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": " 20х55х1,5",
                "koef": "0.001495",
                "metrs": 1
            },
            {
                "size": " 20х55х1,8",
                "koef": "0.001786",
                "metrs": 1
            },
            {
                "size": " 20х55х2",
                "koef": "0.001978",
                "metrs": 1
            },
            {
                "size": " 20х60х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 20х60х1,2",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": " 20х60х1,5",
                "koef": "0.001613",
                "metrs": 1
            },
            {
                "size": " 20х60х1,8",
                "koef": "0.001927",
                "metrs": 1
            },
            {
                "size": " 20х60х2",
                "koef": "0.002135",
                "metrs": 1
            },
            {
                "size": " 25х15х1",
                "koef": "0.000416",
                "metrs": 1
            },
            {
                "size": " 25х15х1,2",
                "koef": "0.000495",
                "metrs": 1
            },
            {
                "size": " 25х15х1,5",
                "koef": "0.000612",
                "metrs": 1
            },
            {
                "size": " 25х15х1,8",
                "koef": "0.000726",
                "metrs": 1
            },
            {
                "size": " 25х15х2",
                "koef": "0.000801",
                "metrs": 1
            },
            {
                "size": " 25х20х1",
                "koef": "0.000495",
                "metrs": 1
            },
            {
                "size": " 25х20х1,2",
                "koef": "0.00059",
                "metrs": 1
            },
            {
                "size": " 25х20х1,5",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": " 25х20х1,8",
                "koef": "0.000868",
                "metrs": 1
            },
            {
                "size": " 25х20х2",
                "koef": "0.000958",
                "metrs": 1
            },
            {
                "size": " 25х25х1",
                "koef": "0.000573",
                "metrs": 1
            },
            {
                "size": " 25х25х1,2",
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": " 25х25х1,5",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 25х25х1,8",
                "koef": "0.001009",
                "metrs": 1
            },
            {
                "size": " 25х25х2",
                "koef": "0.001115",
                "metrs": 1
            },
            {
                "size": " 25х30х1",
                "koef": "0.000652",
                "metrs": 1
            },
            {
                "size": " 25х30х1,2",
                "koef": "0.000778",
                "metrs": 1
            },
            {
                "size": " 25х30х1,5",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 25х30х1,8",
                "koef": "0.00115",
                "metrs": 1
            },
            {
                "size": " 25х30х2",
                "koef": "0.001272",
                "metrs": 1
            },
            {
                "size": " 25х35х1",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": " 25х35х1,2",
                "koef": "0.000872",
                "metrs": 1
            },
            {
                "size": " 25х35х1,5",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 25х35х1,8",
                "koef": "0.001291",
                "metrs": 1
            },
            {
                "size": " 25х35х2",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 25х40х1",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 25х40х1,2",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 25х40х1,5",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 25х40х1,8",
                "koef": "0.001433",
                "metrs": 1
            },
            {
                "size": " 25х40х2",
                "koef": "0.001586",
                "metrs": 1
            },
            {
                "size": " 25х45х1",
                "koef": "0.000887",
                "metrs": 1
            },
            {
                "size": " 25х45х1,2",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 25х45х1,5",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 25х45х1,8",
                "koef": "0.001574",
                "metrs": 1
            },
            {
                "size": " 25х45х2",
                "koef": "0.001743",
                "metrs": 1
            },
            {
                "size": " 25х50х1",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 25х50х1,2",
                "koef": "0.001155",
                "metrs": 1
            },
            {
                "size": " 25х50х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 25х50х1,8",
                "koef": "0.001715",
                "metrs": 1
            },
            {
                "size": " 25х50х2",
                "koef": "0.0019",
                "metrs": 1
            },
            {
                "size": " 25х55х1",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 25х55х1,2",
                "koef": "0.001249",
                "metrs": 1
            },
            {
                "size": " 25х55х1,5",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": " 25х55х1,8",
                "koef": "0.001857",
                "metrs": 1
            },
            {
                "size": " 25х55х2",
                "koef": "0.002057",
                "metrs": 1
            },
            {
                "size": " 25х60х1",
                "koef": "0.001123",
                "metrs": 1
            },
            {
                "size": " 25х60х1,2",
                "koef": "0.001343",
                "metrs": 1
            },
            {
                "size": " 25х60х1,5",
                "koef": "0.001672",
                "metrs": 1
            },
            {
                "size": " 25х60х1,8",
                "koef": "0.001998",
                "metrs": 1
            },
            {
                "size": " 25х60х2",
                "koef": "0.002214",
                "metrs": 1
            },
            {
                "size": " 26х25х2",
                "koef": "0.00109",
                "metrs": 1
            },
            {
                "size": " 28х27х2,5",
                "koef": "0.00142",
                "metrs": 1
            },
            {
                "size": " 30х15х1",
                "koef": "0.000455",
                "metrs": 1
            },
            {
                "size": " 30х15х1,2",
                "koef": "0.000543",
                "metrs": 1
            },
            {
                "size": " 30х15х1,5",
                "koef": "0.000671",
                "metrs": 1
            },
            {
                "size": " 30х15х1,8",
                "koef": "0.000797",
                "metrs": 1
            },
            {
                "size": " 30х15х2",
                "koef": "0.000879",
                "metrs": 1
            },
            {
                "size": " 30х20х1",
                "koef": "0.000534",
                "metrs": 1
            },
            {
                "size": " 30х20х1,2",
                "koef": "0.000637",
                "metrs": 1
            },
            {
                "size": " 30х20х1,5",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": " 30х20х1,8",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 30х20х2",
                "koef": "0.001036",
                "metrs": 1
            },
            {
                "size": " 30х25х1",
                "koef": "0.000612",
                "metrs": 1
            },
            {
                "size": " 30х25х1,2",
                "koef": "0.000731",
                "metrs": 1
            },
            {
                "size": " 30х25х1,5",
                "koef": "0.000907",
                "metrs": 1
            },
            {
                "size": " 30х25х1,8",
                "koef": "0.00108",
                "metrs": 1
            },
            {
                "size": " 30х25х2",
                "koef": "0.00122",
                "metrs": 1
            },
            {
                "size": " 30х25х3",
                "koef": "0.00161",
                "metrs": 1
            },
            {
                "size": " 30х30х1",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 30х30х1,2",
                "koef": "0.000825",
                "metrs": 1
            },
            {
                "size": " 30х30х1,5",
                "koef": "0.001024",
                "metrs": 1
            },
            {
                "size": " 30х30х1,8",
                "koef": "0.001221",
                "metrs": 1
            },
            {
                "size": " 30х30х2",
                "koef": "0.0013",
                "metrs": 1
            },
            {
                "size": " 30х35х1",
                "koef": "0.000769",
                "metrs": 1
            },
            {
                "size": " 30х35х1,2",
                "koef": "0.000919",
                "metrs": 1
            },
            {
                "size": " 30х35х1,5",
                "koef": "0.001142",
                "metrs": 1
            },
            {
                "size": " 30х35х1,8",
                "koef": "0.001362",
                "metrs": 1
            },
            {
                "size": " 30х35х2",
                "koef": "0.001507",
                "metrs": 1
            },
            {
                "size": " 30х40х1",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 30х40х1,2",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": " 30х40х1,5",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": " 30х40х1,8",
                "koef": "0.001503",
                "metrs": 1
            },
            {
                "size": " 30х40х2",
                "koef": "0.001664",
                "metrs": 1
            },
            {
                "size": " 30х45х1",
                "koef": "0.000926",
                "metrs": 1
            },
            {
                "size": " 30х45х1,2",
                "koef": "0.001108",
                "metrs": 1
            },
            {
                "size": " 30х45х1,5",
                "koef": "0.001378",
                "metrs": 1
            },
            {
                "size": " 30х45х1,8",
                "koef": "0.001645",
                "metrs": 1
            },
            {
                "size": " 30х45х2",
                "koef": "0.001821",
                "metrs": 1
            },
            {
                "size": " 30х50х1",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 30х50х1,2",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": " 30х50х1,5",
                "koef": "0.001495",
                "metrs": 1
            },
            {
                "size": " 30х50х1,8",
                "koef": "0.001786",
                "metrs": 1
            },
            {
                "size": " 30х50х2",
                "koef": "0.001978",
                "metrs": 1
            },
            {
                "size": " 30х55х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 30х55х1,2",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": " 30х55х1,5",
                "koef": "0.001613",
                "metrs": 1
            },
            {
                "size": " 30х55х1,8",
                "koef": "0.001927",
                "metrs": 1
            },
            {
                "size": " 30х55х2",
                "koef": "0.002135",
                "metrs": 1
            },
            {
                "size": " 30х60х1",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 30х60х1,2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 30х60х1,5",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": " 30х60х1,8",
                "koef": "0.002069",
                "metrs": 1
            },
            {
                "size": " 30х60х2",
                "koef": "0.002292",
                "metrs": 1
            },
            {
                "size": " 30х70х3х2",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": " 32х22х12х3",
                "koef": "0.00128",
                "metrs": 1
            },
            {
                "size": " 32х25х3",
                "koef": "0.00166",
                "metrs": 1
            },
            {
                "size": " 32х32х2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 32х32х20х2",
                "koef": "0.0012",
                "metrs": 1
            },
            {
                "size": " 32х40х15х3",
                "koef": "0.00178",
                "metrs": 1
            },
            {
                "size": " 32х50х20х4",
                "koef": "0.00274",
                "metrs": 1
            },
            {
                "size": " 35х15х1",
                "koef": "0.000495",
                "metrs": 1
            },
            {
                "size": " 35х15х1,2",
                "koef": "0.00059",
                "metrs": 1
            },
            {
                "size": " 35х15х1,5",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": " 35х15х1,8",
                "koef": "0.000868",
                "metrs": 1
            },
            {
                "size": " 35х15х2",
                "koef": "0.000958",
                "metrs": 1
            },
            {
                "size": " 35х20х1",
                "koef": "0.000573",
                "metrs": 1
            },
            {
                "size": " 35х20х1,2",
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": " 35х20х1,5",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 35х20х1,8",
                "koef": "0.001009",
                "metrs": 1
            },
            {
                "size": " 35х20х2",
                "koef": "0.001115",
                "metrs": 1
            },
            {
                "size": " 35х25х1",
                "koef": "0.000652",
                "metrs": 1
            },
            {
                "size": " 35х25х1,2",
                "koef": "0.000778",
                "metrs": 1
            },
            {
                "size": " 35х25х1,5",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 35х25х1,8",
                "koef": "0.00115",
                "metrs": 1
            },
            {
                "size": " 35х25х2",
                "koef": "0.001272",
                "metrs": 1
            },
            {
                "size": " 35х30х1",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": " 35х30х1,2",
                "koef": "0.000872",
                "metrs": 1
            },
            {
                "size": " 35х30х1,5",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 35х30х1,8",
                "koef": "0.001291",
                "metrs": 1
            },
            {
                "size": " 35х30х2",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 35х35х1",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 35х35х1,2",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 35х35х1,5",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 35х35х1,8",
                "koef": "0.001433",
                "metrs": 1
            },
            {
                "size": " 35х35х2",
                "koef": "0.001586",
                "metrs": 1
            },
            {
                "size": " 35х35х26х2,5",
                "koef": "0.0017",
                "metrs": 1
            },
            {
                "size": " 35х40х1",
                "koef": "0.000887",
                "metrs": 1
            },
            {
                "size": " 35х40х1,2",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 35х40х1,5",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 35х40х1,8",
                "koef": "0.001574",
                "metrs": 1
            },
            {
                "size": " 35х40х2",
                "koef": "0.001743",
                "metrs": 1
            },
            {
                "size": " 35х45х1",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 35х45х1,2",
                "koef": "0.001155",
                "metrs": 1
            },
            {
                "size": " 35х45х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 35х45х1,8",
                "koef": "0.001715",
                "metrs": 1
            },
            {
                "size": " 35х45х2",
                "koef": "0.0019",
                "metrs": 1
            },
            {
                "size": " 35х50х1",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 35х50х1,2",
                "koef": "0.001249",
                "metrs": 1
            },
            {
                "size": " 35х50х1,5",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": " 35х50х1,8",
                "koef": "0.001857",
                "metrs": 1
            },
            {
                "size": " 35х50х2",
                "koef": "0.002057",
                "metrs": 1
            },
            {
                "size": " 35х55х1",
                "koef": "0.001123",
                "metrs": 1
            },
            {
                "size": " 35х55х1,2",
                "koef": "0.001343",
                "metrs": 1
            },
            {
                "size": " 35х55х1,5",
                "koef": "0.001672",
                "metrs": 1
            },
            {
                "size": " 35х55х1,8",
                "koef": "0.001998",
                "metrs": 1
            },
            {
                "size": " 35х55х2",
                "koef": "0.002214",
                "metrs": 1
            },
            {
                "size": " 35х60х1",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 35х60х1,2",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 35х60х1,5",
                "koef": "0.00179",
                "metrs": 1
            },
            {
                "size": " 35х60х1,8",
                "koef": "0.002139",
                "metrs": 1
            },
            {
                "size": " 35х60х2",
                "koef": "0.002371",
                "metrs": 1
            },
            {
                "size": " 37х60х32х3",
                "koef": "0.00277",
                "metrs": 1
            },
            {
                "size": " 40х15х1",
                "koef": "0.000534",
                "metrs": 1
            },
            {
                "size": " 40х15х1,2",
                "koef": "0.000637",
                "metrs": 1
            },
            {
                "size": " 40х15х1,5",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": " 40х15х1,8",
                "koef": "0.000938",
                "metrs": 1
            },
            {
                "size": " 40х15х2",
                "koef": "0.001036",
                "metrs": 1
            },
            {
                "size": " 40х20х1",
                "koef": "0.000612",
                "metrs": 1
            },
            {
                "size": " 40х20х1,2",
                "koef": "0.000731",
                "metrs": 1
            },
            {
                "size": " 40х20х1,5",
                "koef": "0.000907",
                "metrs": 1
            },
            {
                "size": " 40х20х1,8",
                "koef": "0.00108",
                "metrs": 1
            },
            {
                "size": " 40х20х2",
                "koef": "0.00114",
                "metrs": 1
            },
            {
                "size": " 40х20х3",
                "koef": "0.00161",
                "metrs": 1
            },
            {
                "size": " 40х25х1",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 40х25х1,2",
                "koef": "0.000825",
                "metrs": 1
            },
            {
                "size": " 40х25х1,5",
                "koef": "0.001024",
                "metrs": 1
            },
            {
                "size": " 40х25х1,8",
                "koef": "0.001221",
                "metrs": 1
            },
            {
                "size": " 40х25х2",
                "koef": "0.00135",
                "metrs": 1
            },
            {
                "size": " 40х30х1",
                "koef": "0.000769",
                "metrs": 1
            },
            {
                "size": " 40х30х1,2",
                "koef": "0.000919",
                "metrs": 1
            },
            {
                "size": " 40х30х1,5",
                "koef": "0.001142",
                "metrs": 1
            },
            {
                "size": " 40х30х1,8",
                "koef": "0.001362",
                "metrs": 1
            },
            {
                "size": " 40х30х2",
                "koef": "0.00145",
                "metrs": 1
            },
            {
                "size": " 40х30х2,5",
                "koef": "0.00179",
                "metrs": 1
            },
            {
                "size": " 40х32х20х2",
                "koef": "0.00133",
                "metrs": 1
            },
            {
                "size": " 40х35х1",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 40х35х1,2",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": " 40х35х1,5",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": " 40х35х1,8",
                "koef": "0.001503",
                "metrs": 1
            },
            {
                "size": " 40х35х2",
                "koef": "0.001664",
                "metrs": 1
            },
            {
                "size": " 40х40х1",
                "koef": "0.000926",
                "metrs": 1
            },
            {
                "size": " 40х40х1,2",
                "koef": "0.001108",
                "metrs": 1
            },
            {
                "size": " 40х40х1,5",
                "koef": "0.001378",
                "metrs": 1
            },
            {
                "size": " 40х40х1,8",
                "koef": "0.001645",
                "metrs": 1
            },
            {
                "size": " 40х40х2",
                "koef": "0.00177",
                "metrs": 1
            },
            {
                "size": " 40х40х2,5",
                "koef": "0.00218",
                "metrs": 1
            },
            {
                "size": " 40х40х3",
                "koef": "0.00255",
                "metrs": 1
            },
            {
                "size": " 40х40х20х2",
                "koef": "0.00145",
                "metrs": 1
            },
            {
                "size": " 40х45х1",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 40х45х1,2",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": " 40х45х1,5",
                "koef": "0.001495",
                "metrs": 1
            },
            {
                "size": " 40х45х1,8",
                "koef": "0.001786",
                "metrs": 1
            },
            {
                "size": " 40х45х2",
                "koef": "0.001978",
                "metrs": 1
            },
            {
                "size": " 40х50х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 40х50х1,2",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": " 40х50х1,5",
                "koef": "0.001613",
                "metrs": 1
            },
            {
                "size": " 40х50х1,8",
                "koef": "0.001927",
                "metrs": 1
            },
            {
                "size": " 40х50х2",
                "koef": "0.002135",
                "metrs": 1
            },
            {
                "size": " 40х50х32х3",
                "koef": "0.0026",
                "metrs": 1
            },
            {
                "size": " 40х55х1",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 40х55х1,2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 40х55х1,5",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": " 40х55х1,8",
                "koef": "0.002069",
                "metrs": 1
            },
            {
                "size": " 40х55х2",
                "koef": "0.002292",
                "metrs": 1
            },
            {
                "size": " 40х60х1",
                "koef": "0.00124",
                "metrs": 1
            },
            {
                "size": " 40х60х1,2",
                "koef": "0.001485",
                "metrs": 1
            },
            {
                "size": " 40х60х1,5",
                "koef": "0.001849",
                "metrs": 1
            },
            {
                "size": " 40х60х1,8",
                "koef": "0.00221",
                "metrs": 1
            },
            {
                "size": " 40х60х2",
                "koef": "0.002449",
                "metrs": 1
            },
            {
                "size": " 42х42х4",
                "koef": "0.00349",
                "metrs": 1
            },
            {
                "size": " 43х106х32х3",
                "koef": "0.00399",
                "metrs": 1
            },
            {
                "size": " 45х15х1",
                "koef": "0.000573",
                "metrs": 1
            },
            {
                "size": " 45х15х1,2",
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": " 45х15х1,5",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 45х15х1,8",
                "koef": "0.001009",
                "metrs": 1
            },
            {
                "size": " 45х15х2",
                "koef": "0.001115",
                "metrs": 1
            },
            {
                "size": " 45х20х1",
                "koef": "0.000652",
                "metrs": 1
            },
            {
                "size": " 45х20х1,2",
                "koef": "0.000778",
                "metrs": 1
            },
            {
                "size": " 45х20х1,5",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 45х20х1,8",
                "koef": "0.00115",
                "metrs": 1
            },
            {
                "size": " 45х20х2",
                "koef": "0.001272",
                "metrs": 1
            },
            {
                "size": " 45х25х1",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": " 45х25х1,2",
                "koef": "0.000872",
                "metrs": 1
            },
            {
                "size": " 45х25х1,5",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 45х25х1,8",
                "koef": "0.001291",
                "metrs": 1
            },
            {
                "size": " 45х25х2",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 45х25х3",
                "koef": "0.00196",
                "metrs": 1
            },
            {
                "size": " 45х25х15х3",
                "koef": "0.00173",
                "metrs": 1
            },
            {
                "size": " 45х30х1",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 45х30х1,2",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 45х30х1,5",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 45х30х1,8",
                "koef": "0.001433",
                "metrs": 1
            },
            {
                "size": " 45х30х2",
                "koef": "0.001586",
                "metrs": 1
            },
            {
                "size": " 45х31х2",
                "koef": "0.00156",
                "metrs": 1
            },
            {
                "size": " 45х35х1",
                "koef": "0.000887",
                "metrs": 1
            },
            {
                "size": " 45х35х1,2",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 45х35х1,5",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 45х35х1,8",
                "koef": "0.001574",
                "metrs": 1
            },
            {
                "size": " 45х35х2",
                "koef": "0.001743",
                "metrs": 1
            },
            {
                "size": " 45х40х1",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 45х40х1,2",
                "koef": "0.001155",
                "metrs": 1
            },
            {
                "size": " 45х40х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 45х40х1,8",
                "koef": "0.001715",
                "metrs": 1
            },
            {
                "size": " 45х40х2",
                "koef": "0.0019",
                "metrs": 1
            },
            {
                "size": " 45х43х2",
                "koef": "0.00197",
                "metrs": 1
            },
            {
                "size": " 45х45х1",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 45х45х1,2",
                "koef": "0.001249",
                "metrs": 1
            },
            {
                "size": " 45х45х1,5",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": " 45х45х1,8",
                "koef": "0.001857",
                "metrs": 1
            },
            {
                "size": " 45х45х2",
                "koef": "0.002057",
                "metrs": 1
            },
            {
                "size": " 45х50х1",
                "koef": "0.001123",
                "metrs": 1
            },
            {
                "size": " 45х50х1,2",
                "koef": "0.001343",
                "metrs": 1
            },
            {
                "size": " 45х50х1,5",
                "koef": "0.001672",
                "metrs": 1
            },
            {
                "size": " 45х50х1,8",
                "koef": "0.001998",
                "metrs": 1
            },
            {
                "size": " 45х50х2",
                "koef": "0.002214",
                "metrs": 1
            },
            {
                "size": " 45х55х1",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 45х55х1,2",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 45х55х1,5",
                "koef": "0.00179",
                "metrs": 1
            },
            {
                "size": " 45х55х1,8",
                "koef": "0.002139",
                "metrs": 1
            },
            {
                "size": " 45х55х2",
                "koef": "0.002371",
                "metrs": 1
            },
            {
                "size": " 45х60х1",
                "koef": "0.00128",
                "metrs": 1
            },
            {
                "size": " 45х60х1,2",
                "koef": "0.001532",
                "metrs": 1
            },
            {
                "size": " 45х60х1,5",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 45х60х1,8",
                "koef": "0.002281",
                "metrs": 1
            },
            {
                "size": " 45х60х2",
                "koef": "0.002528",
                "metrs": 1
            },
            {
                "size": " 50х15х1",
                "koef": "0.000612",
                "metrs": 1
            },
            {
                "size": " 50х15х1,2",
                "koef": "0.000731",
                "metrs": 1
            },
            {
                "size": " 50х15х1,5",
                "koef": "0.000907",
                "metrs": 1
            },
            {
                "size": " 50х15х1,8",
                "koef": "0.00108",
                "metrs": 1
            },
            {
                "size": " 50х15х2",
                "koef": "0.001193",
                "metrs": 1
            },
            {
                "size": " 50х18х3",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": " 50х20х1",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 50х20х1,2",
                "koef": "0.000825",
                "metrs": 1
            },
            {
                "size": " 50х20х1,5",
                "koef": "0.001024",
                "metrs": 1
            },
            {
                "size": " 50х20х1,8",
                "koef": "0.001221",
                "metrs": 1
            },
            {
                "size": " 50х20х2",
                "koef": "0.00135",
                "metrs": 1
            },
            {
                "size": " 50х25х1",
                "koef": "0.000769",
                "metrs": 1
            },
            {
                "size": " 50х25х1,2",
                "koef": "0.000919",
                "metrs": 1
            },
            {
                "size": " 50х25х1,5",
                "koef": "0.001142",
                "metrs": 1
            },
            {
                "size": " 50х25х1,8",
                "koef": "0.001362",
                "metrs": 1
            },
            {
                "size": " 50х25х2",
                "koef": "0.001507",
                "metrs": 1
            },
            {
                "size": " 50х30х1",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 50х30х1,2",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": " 50х30х1,5",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": " 50х30х1,8",
                "koef": "0.001503",
                "metrs": 1
            },
            {
                "size": " 50х30х2",
                "koef": "0.00161",
                "metrs": 1
            },
            {
                "size": " 50х30х2,5",
                "koef": "0.00199",
                "metrs": 1
            },
            {
                "size": " 50х32х2,5",
                "koef": "0.00207",
                "metrs": 1
            },
            {
                "size": " 50х35х1",
                "koef": "0.000926",
                "metrs": 1
            },
            {
                "size": " 50х35х1,2",
                "koef": "0.001108",
                "metrs": 1
            },
            {
                "size": " 50х35х1,5",
                "koef": "0.001378",
                "metrs": 1
            },
            {
                "size": " 50х35х1,8",
                "koef": "0.001645",
                "metrs": 1
            },
            {
                "size": " 50х35х2",
                "koef": "0.001821",
                "metrs": 1
            },
            {
                "size": " 50х40х1",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 50х40х1,2",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": " 50х40х1,5",
                "koef": "0.001495",
                "metrs": 1
            },
            {
                "size": " 50х40х1,8",
                "koef": "0.001786",
                "metrs": 1
            },
            {
                "size": " 50х40х2",
                "koef": "0.00192",
                "metrs": 1
            },
            {
                "size": " 50х40х2,5",
                "koef": "0.00238",
                "metrs": 1
            },
            {
                "size": " 50х40х3",
                "koef": "0.00281",
                "metrs": 1
            },
            {
                "size": " 50х40х4",
                "koef": "0.00362",
                "metrs": 1
            },
            {
                "size": " 50х40х5",
                "koef": "0.00471",
                "metrs": 1
            },
            {
                "size": " 50х40х6",
                "koef": "0.005558",
                "metrs": 1
            },
            {
                "size": " 50х40х12х2,5",
                "koef": "0.00182",
                "metrs": 1
            },
            {
                "size": " 50х40х20х2",
                "koef": "0.00161",
                "metrs": 1
            },
            {
                "size": " 50х45х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 50х45х1,2",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": " 50х45х1,5",
                "koef": "0.001613",
                "metrs": 1
            },
            {
                "size": " 50х45х1,8",
                "koef": "0.001927",
                "metrs": 1
            },
            {
                "size": " 50х45х2",
                "koef": "0.002135",
                "metrs": 1
            },
            {
                "size": " 50х47х6",
                "koef": "0.00573",
                "metrs": 1
            },
            {
                "size": " 50х48х15х3",
                "koef": "0.00239",
                "metrs": 1
            },
            {
                "size": " 50х50х1",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 50х50х1,2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 50х50х1,5",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": " 50х50х1,8",
                "koef": "0.002069",
                "metrs": 1
            },
            {
                "size": " 50х50х2",
                "koef": "0.002292",
                "metrs": 1
            },
            {
                "size": " 50х50х2,5",
                "koef": "0.00277",
                "metrs": 1
            },
            {
                "size": " 50х50х3",
                "koef": "0.00328",
                "metrs": 1
            },
            {
                "size": " 50х50х4",
                "koef": "0.00424",
                "metrs": 1
            },
            {
                "size": " 50х50х5",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": " 50х50х6",
                "koef": "0.0065",
                "metrs": 1
            },
            {
                "size": " 50х50х15х3",
                "koef": "0.00244",
                "metrs": 1
            },
            {
                "size": " 50х50х25х2",
                "koef": "0.00185",
                "metrs": 1
            },
            {
                "size": " 50х50х25х3",
                "koef": "0.00267",
                "metrs": 1
            },
            {
                "size": " 50х50х25х4",
                "koef": "0.00346",
                "metrs": 1
            },
            {
                "size": " 50х55х1",
                "koef": "0.00124",
                "metrs": 1
            },
            {
                "size": " 50х55х1,2",
                "koef": "0.001485",
                "metrs": 1
            },
            {
                "size": " 50х55х1,5",
                "koef": "0.001849",
                "metrs": 1
            },
            {
                "size": " 50х55х1,8",
                "koef": "0.00221",
                "metrs": 1
            },
            {
                "size": " 50х55х2",
                "koef": "0.002449",
                "metrs": 1
            },
            {
                "size": " 50х55х30х2",
                "koef": "0.002",
                "metrs": 1
            },
            {
                "size": " 50х60х1",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 50х60х1,2",
                "koef": "0.001579",
                "metrs": 1
            },
            {
                "size": " 50х60х1,5",
                "koef": "0.001966",
                "metrs": 1
            },
            {
                "size": " 50х60х1,8",
                "koef": "0.002351",
                "metrs": 1
            },
            {
                "size": " 50х60х2",
                "koef": "0.002606",
                "metrs": 1
            },
            {
                "size": " 50х60х32х3",
                "koef": "0.00307",
                "metrs": 1
            },
            {
                "size": " 50х60х32х4",
                "koef": "0.00399",
                "metrs": 1
            },
            {
                "size": " 50х92х60х3",
                "koef": "0.00448",
                "metrs": 1
            },
            {
                "size": " 55х15х1",
                "koef": "0.000652",
                "metrs": 1
            },
            {
                "size": " 55х15х1,2",
                "koef": "0.000778",
                "metrs": 1
            },
            {
                "size": " 55х15х1,5",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 55х15х1,8",
                "koef": "0.00115",
                "metrs": 1
            },
            {
                "size": " 55х15х2",
                "koef": "0.001272",
                "metrs": 1
            },
            {
                "size": " 55х20х1",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": " 55х20х1,2",
                "koef": "0.000872",
                "metrs": 1
            },
            {
                "size": " 55х20х1,5",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 55х20х1,8",
                "koef": "0.001291",
                "metrs": 1
            },
            {
                "size": " 55х20х2",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 55х25х1",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 55х25х1,2",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 55х25х1,5",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 55х25х1,8",
                "koef": "0.001433",
                "metrs": 1
            },
            {
                "size": " 55х25х2",
                "koef": "0.001586",
                "metrs": 1
            },
            {
                "size": " 55х30х1",
                "koef": "0.000887",
                "metrs": 1
            },
            {
                "size": " 55х30х1,2",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 55х30х1,5",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 55х30х1,8",
                "koef": "0.001574",
                "metrs": 1
            },
            {
                "size": " 55х30х2",
                "koef": "0.001743",
                "metrs": 1
            },
            {
                "size": " 55х35х1",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 55х35х1,2",
                "koef": "0.001155",
                "metrs": 1
            },
            {
                "size": " 55х35х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 55х35х1,8",
                "koef": "0.001715",
                "metrs": 1
            },
            {
                "size": " 55х35х2",
                "koef": "0.0019",
                "metrs": 1
            },
            {
                "size": " 55х40х1",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 55х40х1,2",
                "koef": "0.001249",
                "metrs": 1
            },
            {
                "size": " 55х40х1,5",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": " 55х40х1,8",
                "koef": "0.001857",
                "metrs": 1
            },
            {
                "size": " 55х40х2",
                "koef": "0.002057",
                "metrs": 1
            },
            {
                "size": " 55х45х1",
                "koef": "0.001123",
                "metrs": 1
            },
            {
                "size": " 55х45х1,2",
                "koef": "0.001343",
                "metrs": 1
            },
            {
                "size": " 55х45х1,5",
                "koef": "0.001672",
                "metrs": 1
            },
            {
                "size": " 55х45х1,8",
                "koef": "0.001998",
                "metrs": 1
            },
            {
                "size": " 55х45х2",
                "koef": "0.002214",
                "metrs": 1
            },
            {
                "size": " 55х50х1",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 55х50х1,2",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 55х50х1,5",
                "koef": "0.00179",
                "metrs": 1
            },
            {
                "size": " 55х50х1,8",
                "koef": "0.002139",
                "metrs": 1
            },
            {
                "size": " 55х50х2",
                "koef": "0.002371",
                "metrs": 1
            },
            {
                "size": " 55х55х1",
                "koef": "0.00128",
                "metrs": 1
            },
            {
                "size": " 55х55х1,2",
                "koef": "0.001532",
                "metrs": 1
            },
            {
                "size": " 55х55х1,5",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 55х55х1,8",
                "koef": "0.002281",
                "metrs": 1
            },
            {
                "size": " 55х55х2",
                "koef": "0.002528",
                "metrs": 1
            },
            {
                "size": " 55х60х1",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 55х60х1,2",
                "koef": "0.001626",
                "metrs": 1
            },
            {
                "size": " 55х60х1,5",
                "koef": "0.002025",
                "metrs": 1
            },
            {
                "size": " 55х60х1,8",
                "koef": "0.002422",
                "metrs": 1
            },
            {
                "size": " 55х60х2",
                "koef": "0.002685",
                "metrs": 1
            },
            {
                "size": " 60х15х1",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 60х15х1,2",
                "koef": "0.000825",
                "metrs": 1
            },
            {
                "size": " 60х15х1,5",
                "koef": "0.001024",
                "metrs": 1
            },
            {
                "size": " 60х15х1,8",
                "koef": "0.001221",
                "metrs": 1
            },
            {
                "size": " 60х15х2",
                "koef": "0.00135",
                "metrs": 1
            },
            {
                "size": " 60х20х1",
                "koef": "0.000769",
                "metrs": 1
            },
            {
                "size": " 60х20х1,2",
                "koef": "0.000919",
                "metrs": 1
            },
            {
                "size": " 60х20х1,5",
                "koef": "0.001142",
                "metrs": 1
            },
            {
                "size": " 60х20х1,8",
                "koef": "0.001362",
                "metrs": 1
            },
            {
                "size": " 60х20х2",
                "koef": "0.001507",
                "metrs": 1
            },
            {
                "size": " 60х25х1",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 60х25х1,2",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": " 60х25х1,5",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": " 60х25х1,8",
                "koef": "0.001503",
                "metrs": 1
            },
            {
                "size": " 60х25х2",
                "koef": "0.001664",
                "metrs": 1
            },
            {
                "size": " 60х26х2,5",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": " 60х28х2,5",
                "koef": "0.002178",
                "metrs": 1
            },
            {
                "size": " 60х30х1",
                "koef": "0.000926",
                "metrs": 1
            },
            {
                "size": " 60х30х1,2",
                "koef": "0.001108",
                "metrs": 1
            },
            {
                "size": " 60х30х1,5",
                "koef": "0.001378",
                "metrs": 1
            },
            {
                "size": " 60х30х1,8",
                "koef": "0.001645",
                "metrs": 1
            },
            {
                "size": " 60х30х2",
                "koef": "0.001821",
                "metrs": 1
            },
            {
                "size": " 60х30х2,5",
                "koef": "0.00219",
                "metrs": 1
            },
            {
                "size": " 60х30х3",
                "koef": "0.00255",
                "metrs": 1
            },
            {
                "size": " 60х32х2",
                "koef": "0.001884",
                "metrs": 1
            },
            {
                "size": " 60х32х2,5",
                "koef": "0.00226",
                "metrs": 1
            },
            {
                "size": " 60х32х3",
                "koef": "0.00267",
                "metrs": 1
            },
            {
                "size": " 60х32х4",
                "koef": "0.00343",
                "metrs": 1
            },
            {
                "size": " 60х33х5",
                "koef": "0.004553",
                "metrs": 1
            },
            {
                "size": " 60х35х1",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 60х35х1,2",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": " 60х35х1,5",
                "koef": "0.001495",
                "metrs": 1
            },
            {
                "size": " 60х35х1,8",
                "koef": "0.001786",
                "metrs": 1
            },
            {
                "size": " 60х35х2",
                "koef": "0.001978",
                "metrs": 1
            },
            {
                "size": " 60х40х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 60х40х1,2",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": " 60х40х1,5",
                "koef": "0.001613",
                "metrs": 1
            },
            {
                "size": " 60х40х1,8",
                "koef": "0.001927",
                "metrs": 1
            },
            {
                "size": " 60х40х2",
                "koef": "0.00208",
                "metrs": 1
            },
            {
                "size": " 60х40х3",
                "koef": "0.00304",
                "metrs": 1
            },
            {
                "size": " 60х40х4",
                "koef": "0.004145",
                "metrs": 1
            },
            {
                "size": " 60х40х5",
                "koef": "0.005103",
                "metrs": 1
            },
            {
                "size": " 60х40х6",
                "koef": "0.006029",
                "metrs": 1
            },
            {
                "size": " 60х40х20х2",
                "koef": "0.00177",
                "metrs": 1
            },
            {
                "size": " 60х45х1",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 60х45х1,2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 60х45х1,5",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": " 60х45х1,8",
                "koef": "0.002069",
                "metrs": 1
            },
            {
                "size": " 60х45х2",
                "koef": "0.002292",
                "metrs": 1
            },
            {
                "size": " 60х50х1",
                "koef": "0.00124",
                "metrs": 1
            },
            {
                "size": " 60х50х1,2",
                "koef": "0.001485",
                "metrs": 1
            },
            {
                "size": " 60х50х1,5",
                "koef": "0.001849",
                "metrs": 1
            },
            {
                "size": " 60х50х1,8",
                "koef": "0.00221",
                "metrs": 1
            },
            {
                "size": " 60х50х2",
                "koef": "0.002449",
                "metrs": 1
            },
            {
                "size": " 60х50х3",
                "koef": "0.0035",
                "metrs": 1
            },
            {
                "size": " 60х50х4",
                "koef": "0.004773",
                "metrs": 1
            },
            {
                "size": " 60х50х25х3",
                "koef": "0.00291",
                "metrs": 1
            },
            {
                "size": " 60х55х1",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 60х55х1,2",
                "koef": "0.001579",
                "metrs": 1
            },
            {
                "size": " 60х55х1,5",
                "koef": "0.001966",
                "metrs": 1
            },
            {
                "size": " 60х55х1,8",
                "koef": "0.002351",
                "metrs": 1
            },
            {
                "size": " 60х55х2",
                "koef": "0.002606",
                "metrs": 1
            },
            {
                "size": " 60х60х1",
                "koef": "0.001397",
                "metrs": 1
            },
            {
                "size": " 60х60х1,2",
                "koef": "0.001673",
                "metrs": 1
            },
            {
                "size": " 60х60х1,5",
                "koef": "0.002084",
                "metrs": 1
            },
            {
                "size": " 60х60х1,8",
                "koef": "0.002493",
                "metrs": 1
            },
            {
                "size": " 60х60х2",
                "koef": "0.002763",
                "metrs": 1
            },
            {
                "size": " 60х60х3",
                "koef": "0.00399",
                "metrs": 1
            },
            {
                "size": " 60х60х4",
                "koef": "0.00518",
                "metrs": 1
            },
            {
                "size": " 60х60х5",
                "koef": "0.006673",
                "metrs": 1
            },
            {
                "size": " 60х60х6",
                "koef": "0.007913",
                "metrs": 1
            },
            {
                "size": " 60х60х32х3",
                "koef": "0.00331",
                "metrs": 1
            },
            {
                "size": " 63х21х2,2",
                "koef": "0.00168",
                "metrs": 1
            },
            {
                "size": " 65х15х1",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": " 65х15х1,2",
                "koef": "0.000872",
                "metrs": 1
            },
            {
                "size": " 65х15х1,5",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 65х15х1,8",
                "koef": "0.001291",
                "metrs": 1
            },
            {
                "size": " 65х15х2",
                "koef": "0.001429",
                "metrs": 1
            },
            {
                "size": " 65х20х1",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 65х20х1,2",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 65х20х1,5",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 65х20х1,8",
                "koef": "0.001433",
                "metrs": 1
            },
            {
                "size": " 65х20х2",
                "koef": "0.001586",
                "metrs": 1
            },
            {
                "size": " 65х25х1",
                "koef": "0.000887",
                "metrs": 1
            },
            {
                "size": " 65х25х1,2",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 65х25х1,5",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 65х25х1,8",
                "koef": "0.001574",
                "metrs": 1
            },
            {
                "size": " 65х25х2",
                "koef": "0.001743",
                "metrs": 1
            },
            {
                "size": " 65х30х1",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 65х30х1,2",
                "koef": "0.001155",
                "metrs": 1
            },
            {
                "size": " 65х30х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 65х30х1,8",
                "koef": "0.001715",
                "metrs": 1
            },
            {
                "size": " 65х30х2",
                "koef": "0.0019",
                "metrs": 1
            },
            {
                "size": " 65х32х2",
                "koef": "0.001962",
                "metrs": 1
            },
            {
                "size": " 65х32х3",
                "koef": "0.002897",
                "metrs": 1
            },
            {
                "size": " 65х32х4",
                "koef": "0.003799",
                "metrs": 1
            },
            {
                "size": " 65х33х5",
                "koef": "0.004749",
                "metrs": 1
            },
            {
                "size": " 65х35х1",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 65х35х1,2",
                "koef": "0.001249",
                "metrs": 1
            },
            {
                "size": " 65х35х1,5",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": " 65х35х1,8",
                "koef": "0.001857",
                "metrs": 1
            },
            {
                "size": " 65х35х2",
                "koef": "0.002057",
                "metrs": 1
            },
            {
                "size": " 65х35х3",
                "koef": "0.003038",
                "metrs": 1
            },
            {
                "size": " 65х40х1",
                "koef": "0.001123",
                "metrs": 1
            },
            {
                "size": " 65х40х1,2",
                "koef": "0.001343",
                "metrs": 1
            },
            {
                "size": " 65х40х1,5",
                "koef": "0.001672",
                "metrs": 1
            },
            {
                "size": " 65х40х1,8",
                "koef": "0.001998",
                "metrs": 1
            },
            {
                "size": " 65х40х2",
                "koef": "0.002214",
                "metrs": 1
            },
            {
                "size": " 65х40х2,5",
                "koef": "0.002747",
                "metrs": 1
            },
            {
                "size": " 65х40х3",
                "koef": "0.003273",
                "metrs": 1
            },
            {
                "size": " 65х40х4",
                "koef": "0.00398",
                "metrs": 1
            },
            {
                "size": " 65х40х5",
                "koef": "0.005299",
                "metrs": 1
            },
            {
                "size": " 65х40х6",
                "koef": "0.006264",
                "metrs": 1
            },
            {
                "size": " 65х45х1",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 65х45х1,2",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 65х45х1,5",
                "koef": "0.00179",
                "metrs": 1
            },
            {
                "size": " 65х45х1,8",
                "koef": "0.002139",
                "metrs": 1
            },
            {
                "size": " 65х45х2",
                "koef": "0.002371",
                "metrs": 1
            },
            {
                "size": " 65х50х1",
                "koef": "0.00128",
                "metrs": 1
            },
            {
                "size": " 65х50х1,2",
                "koef": "0.001532",
                "metrs": 1
            },
            {
                "size": " 65х50х1,5",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 65х50х1,8",
                "koef": "0.002281",
                "metrs": 1
            },
            {
                "size": " 65х50х2",
                "koef": "0.002528",
                "metrs": 1
            },
            {
                "size": " 65х55х1",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 65х55х1,2",
                "koef": "0.001626",
                "metrs": 1
            },
            {
                "size": " 65х55х1,5",
                "koef": "0.002025",
                "metrs": 1
            },
            {
                "size": " 65х55х1,8",
                "koef": "0.002422",
                "metrs": 1
            },
            {
                "size": " 65х55х2",
                "koef": "0.002685",
                "metrs": 1
            },
            {
                "size": " 65х55х20х2,5",
                "koef": "0.00256",
                "metrs": 1
            },
            {
                "size": " 65х60х1",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 65х60х1,2",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 65х60х1,5",
                "koef": "0.002143",
                "metrs": 1
            },
            {
                "size": " 65х60х1,8",
                "koef": "0.002563",
                "metrs": 1
            },
            {
                "size": " 65х60х2",
                "koef": "0.002842",
                "metrs": 1
            },
            {
                "size": " 65х65х2",
                "koef": "0.002999",
                "metrs": 1
            },
            {
                "size": " 65х65х3",
                "koef": "0.004451",
                "metrs": 1
            },
            {
                "size": " 65х65х4",
                "koef": "0.005872",
                "metrs": 1
            },
            {
                "size": " 65х65х5",
                "koef": "0.007261",
                "metrs": 1
            },
            {
                "size": " 65х65х6",
                "koef": "0.008619",
                "metrs": 1
            },
            {
                "size": " 65х65х40х4",
                "koef": "0.00487",
                "metrs": 1
            },
            {
                "size": " 67х65х35х3",
                "koef": "0.00366",
                "metrs": 1
            },
            {
                "size": " 68х27х1",
                "koef": "0.00093",
                "metrs": 1
            },
            {
                "size": " 70х15х1",
                "koef": "0.000769",
                "metrs": 1
            },
            {
                "size": " 70х15х1,2",
                "koef": "0.000919",
                "metrs": 1
            },
            {
                "size": " 70х15х1,5",
                "koef": "0.001142",
                "metrs": 1
            },
            {
                "size": " 70х15х1,8",
                "koef": "0.001362",
                "metrs": 1
            },
            {
                "size": " 70х15х2",
                "koef": "0.001507",
                "metrs": 1
            },
            {
                "size": " 70х20х1",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 70х20х1,2",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": " 70х20х1,5",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": " 70х20х1,8",
                "koef": "0.001503",
                "metrs": 1
            },
            {
                "size": " 70х20х2",
                "koef": "0.001664",
                "metrs": 1
            },
            {
                "size": " 70х25х1",
                "koef": "0.000926",
                "metrs": 1
            },
            {
                "size": " 70х25х1,2",
                "koef": "0.001108",
                "metrs": 1
            },
            {
                "size": " 70х25х1,5",
                "koef": "0.001378",
                "metrs": 1
            },
            {
                "size": " 70х25х1,8",
                "koef": "0.001645",
                "metrs": 1
            },
            {
                "size": " 70х25х2",
                "koef": "0.001821",
                "metrs": 1
            },
            {
                "size": " 70х30х1",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 70х30х1,2",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": " 70х30х1,5",
                "koef": "0.001495",
                "metrs": 1
            },
            {
                "size": " 70х30х1,8",
                "koef": "0.001786",
                "metrs": 1
            },
            {
                "size": " 70х30х2",
                "koef": "0.00192",
                "metrs": 1
            },
            {
                "size": " 70х32х2",
                "koef": "0.002041",
                "metrs": 1
            },
            {
                "size": " 70х32х2,5",
                "koef": "0.002532",
                "metrs": 1
            },
            {
                "size": " 70х32х3",
                "koef": "0.003014",
                "metrs": 1
            },
            {
                "size": " 70х32х4",
                "koef": "0.003956",
                "metrs": 1
            },
            {
                "size": " 70х33х5",
                "koef": "0.004946",
                "metrs": 1
            },
            {
                "size": " 70х35х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 70х35х1,2",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": " 70х35х1,5",
                "koef": "0.001613",
                "metrs": 1
            },
            {
                "size": " 70х35х1,8",
                "koef": "0.001927",
                "metrs": 1
            },
            {
                "size": " 70х35х2",
                "koef": "0.002135",
                "metrs": 1
            },
            {
                "size": " 70х40х1",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 70х40х1,2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 70х40х1,5",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": " 70х40х1,8",
                "koef": "0.002069",
                "metrs": 1
            },
            {
                "size": " 70х40х2",
                "koef": "0.002292",
                "metrs": 1
            },
            {
                "size": " 70х40х2,5",
                "koef": "0.002846",
                "metrs": 1
            },
            {
                "size": " 70х40х3",
                "koef": "0.00326",
                "metrs": 1
            },
            {
                "size": " 70х40х4",
                "koef": "0.004459",
                "metrs": 1
            },
            {
                "size": " 70х40х5",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": " 70х40х6",
                "koef": "0.0065",
                "metrs": 1
            },
            {
                "size": " 70х45х1",
                "koef": "0.00124",
                "metrs": 1
            },
            {
                "size": " 70х45х1,2",
                "koef": "0.001485",
                "metrs": 1
            },
            {
                "size": " 70х45х1,5",
                "koef": "0.001849",
                "metrs": 1
            },
            {
                "size": " 70х45х1,8",
                "koef": "0.00221",
                "metrs": 1
            },
            {
                "size": " 70х45х2",
                "koef": "0.002449",
                "metrs": 1
            },
            {
                "size": " 70х48х5",
                "koef": "0.00667",
                "metrs": 1
            },
            {
                "size": " 70х50х1",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 70х50х1,2",
                "koef": "0.001579",
                "metrs": 1
            },
            {
                "size": " 70х50х1,5",
                "koef": "0.001966",
                "metrs": 1
            },
            {
                "size": " 70х50х1,8",
                "koef": "0.002351",
                "metrs": 1
            },
            {
                "size": " 70х50х2",
                "koef": "0.002606",
                "metrs": 1
            },
            {
                "size": " 70х50х3",
                "koef": "0.00373",
                "metrs": 1
            },
            {
                "size": " 70х50х4",
                "koef": "0.00487",
                "metrs": 1
            },
            {
                "size": " 70х50х5",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 70х50х6",
                "koef": "0.007442",
                "metrs": 1
            },
            {
                "size": " 70х55х1",
                "koef": "0.001397",
                "metrs": 1
            },
            {
                "size": " 70х55х1,2",
                "koef": "0.001673",
                "metrs": 1
            },
            {
                "size": " 70х55х1,5",
                "koef": "0.002084",
                "metrs": 1
            },
            {
                "size": " 70х55х1,8",
                "koef": "0.002493",
                "metrs": 1
            },
            {
                "size": " 70х55х2",
                "koef": "0.002763",
                "metrs": 1
            },
            {
                "size": " 70х60х1",
                "koef": "0.001476",
                "metrs": 1
            },
            {
                "size": " 70х60х1,2",
                "koef": "0.001767",
                "metrs": 1
            },
            {
                "size": " 70х60х1,5",
                "koef": "0.002202",
                "metrs": 1
            },
            {
                "size": " 70х60х1,8",
                "koef": "0.002634",
                "metrs": 1
            },
            {
                "size": " 70х60х2",
                "koef": "0.00292",
                "metrs": 1
            },
            {
                "size": " 70х60х4",
                "koef": "0.0055",
                "metrs": 1
            },
            {
                "size": " 70х65х4",
                "koef": "0.006029",
                "metrs": 1
            },
            {
                "size": " 70х70х2",
                "koef": "0.003234",
                "metrs": 1
            },
            {
                "size": " 70х70х3",
                "koef": "0.004804",
                "metrs": 1
            },
            {
                "size": " 70х70х4",
                "koef": "0.006343",
                "metrs": 1
            },
            {
                "size": " 70х70х5",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 70х70х6",
                "koef": "0.009326",
                "metrs": 1
            },
            {
                "size": " 70х80х50х4",
                "koef": "0.00581",
                "metrs": 1
            },
            {
                "size": " 75х15х1",
                "koef": "0.000809",
                "metrs": 1
            },
            {
                "size": " 75х15х1,2",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 75х15х1,5",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 75х15х1,8",
                "koef": "0.001433",
                "metrs": 1
            },
            {
                "size": " 75х15х2",
                "koef": "0.001586",
                "metrs": 1
            },
            {
                "size": " 75х20х1",
                "koef": "0.000887",
                "metrs": 1
            },
            {
                "size": " 75х20х1,2",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 75х20х1,5",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 75х20х1,8",
                "koef": "0.001574",
                "metrs": 1
            },
            {
                "size": " 75х20х2",
                "koef": "0.001743",
                "metrs": 1
            },
            {
                "size": " 75х25х1",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 75х25х1,2",
                "koef": "0.001155",
                "metrs": 1
            },
            {
                "size": " 75х25х1,5",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 75х25х1,8",
                "koef": "0.001715",
                "metrs": 1
            },
            {
                "size": " 75х25х2",
                "koef": "0.0019",
                "metrs": 1
            },
            {
                "size": " 75х30х1",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 75х30х1,2",
                "koef": "0.001249",
                "metrs": 1
            },
            {
                "size": " 75х30х1,5",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": " 75х30х1,8",
                "koef": "0.001857",
                "metrs": 1
            },
            {
                "size": " 75х30х2",
                "koef": "0.002057",
                "metrs": 1
            },
            {
                "size": " 75х32х2",
                "koef": "0.00212",
                "metrs": 1
            },
            {
                "size": " 75х32х3",
                "koef": "0.003132",
                "metrs": 1
            },
            {
                "size": " 75х32х4",
                "koef": "0.004113",
                "metrs": 1
            },
            {
                "size": " 75х33х5",
                "koef": "0.005142",
                "metrs": 1
            },
            {
                "size": " 75х35х1",
                "koef": "0.001123",
                "metrs": 1
            },
            {
                "size": " 75х35х1,2",
                "koef": "0.001343",
                "metrs": 1
            },
            {
                "size": " 75х35х1,5",
                "koef": "0.001672",
                "metrs": 1
            },
            {
                "size": " 75х35х1,8",
                "koef": "0.001998",
                "metrs": 1
            },
            {
                "size": " 75х35х2",
                "koef": "0.002214",
                "metrs": 1
            },
            {
                "size": " 75х40х1",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": " 75х40х1,2",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 75х40х1,5",
                "koef": "0.00179",
                "metrs": 1
            },
            {
                "size": " 75х40х1,8",
                "koef": "0.002139",
                "metrs": 1
            },
            {
                "size": " 75х40х2",
                "koef": "0.002371",
                "metrs": 1
            },
            {
                "size": " 75х40х2,5",
                "koef": "0.002944",
                "metrs": 1
            },
            {
                "size": " 75х40х3",
                "koef": "0.003509",
                "metrs": 1
            },
            {
                "size": " 75х40х4",
                "koef": "0.004616",
                "metrs": 1
            },
            {
                "size": " 75х40х5",
                "koef": "0.005691",
                "metrs": 1
            },
            {
                "size": " 75х40х6",
                "koef": "0.006735",
                "metrs": 1
            },
            {
                "size": " 75х45х1",
                "koef": "0.00128",
                "metrs": 1
            },
            {
                "size": " 75х45х1,2",
                "koef": "0.001532",
                "metrs": 1
            },
            {
                "size": " 75х45х1,5",
                "koef": "0.001908",
                "metrs": 1
            },
            {
                "size": " 75х45х1,8",
                "koef": "0.002281",
                "metrs": 1
            },
            {
                "size": " 75х45х2",
                "koef": "0.002528",
                "metrs": 1
            },
            {
                "size": " 75х50х1",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 75х50х1,2",
                "koef": "0.001626",
                "metrs": 1
            },
            {
                "size": " 75х50х1,5",
                "koef": "0.002025",
                "metrs": 1
            },
            {
                "size": " 75х50х1,8",
                "koef": "0.002422",
                "metrs": 1
            },
            {
                "size": " 75х50х2",
                "koef": "0.002685",
                "metrs": 1
            },
            {
                "size": " 75х50х2,5",
                "koef": "0.003336",
                "metrs": 1
            },
            {
                "size": " 75х50х3",
                "koef": "0.00398",
                "metrs": 1
            },
            {
                "size": " 75х50х4",
                "koef": "0.005244",
                "metrs": 1
            },
            {
                "size": " 75х50х5",
                "koef": "0.006476",
                "metrs": 1
            },
            {
                "size": " 75х50х6",
                "koef": "0.007677",
                "metrs": 1
            },
            {
                "size": " 75х55х1",
                "koef": "0.001437",
                "metrs": 1
            },
            {
                "size": " 75х55х1,2",
                "koef": "0.00172",
                "metrs": 1
            },
            {
                "size": " 75х55х1,5",
                "koef": "0.002143",
                "metrs": 1
            },
            {
                "size": " 75х55х1,8",
                "koef": "0.002563",
                "metrs": 1
            },
            {
                "size": " 75х55х2",
                "koef": "0.002842",
                "metrs": 1
            },
            {
                "size": " 75х60х1",
                "koef": "0.001515",
                "metrs": 1
            },
            {
                "size": " 75х60х1,2",
                "koef": "0.001814",
                "metrs": 1
            },
            {
                "size": " 75х60х1,5",
                "koef": "0.002261",
                "metrs": 1
            },
            {
                "size": " 75х60х1,8",
                "koef": "0.002704",
                "metrs": 1
            },
            {
                "size": " 75х60х2",
                "koef": "0.002999",
                "metrs": 1
            },
            {
                "size": " 75х65х4",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 75х75х2",
                "koef": "0.00347",
                "metrs": 1
            },
            {
                "size": " 75х75х3",
                "koef": "0.005157",
                "metrs": 1
            },
            {
                "size": " 75х75х4",
                "koef": "0.006814",
                "metrs": 1
            },
            {
                "size": " 75х75х5",
                "koef": "0.008439",
                "metrs": 1
            },
            {
                "size": " 75х75х6",
                "koef": "0.01003",
                "metrs": 1
            },
            {
                "size": " 78х46х6",
                "koef": "0.0069",
                "metrs": 1
            },
            {
                "size": " 80х15х1",
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": " 80х15х1,2",
                "koef": "0.001014",
                "metrs": 1
            },
            {
                "size": " 80х15х1,5",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": " 80х15х1,8",
                "koef": "0.001503",
                "metrs": 1
            },
            {
                "size": " 80х15х2",
                "koef": "0.001664",
                "metrs": 1
            },
            {
                "size": " 80х20х1",
                "koef": "0.000926",
                "metrs": 1
            },
            {
                "size": " 80х20х1,2",
                "koef": "0.001108",
                "metrs": 1
            },
            {
                "size": " 80х20х1,5",
                "koef": "0.001378",
                "metrs": 1
            },
            {
                "size": " 80х20х1,8",
                "koef": "0.001645",
                "metrs": 1
            },
            {
                "size": " 80х20х2",
                "koef": "0.001821",
                "metrs": 1
            },
            {
                "size": " 80х25х1",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 80х25х1,2",
                "koef": "0.001202",
                "metrs": 1
            },
            {
                "size": " 80х25х1,5",
                "koef": "0.001495",
                "metrs": 1
            },
            {
                "size": " 80х25х1,8",
                "koef": "0.001786",
                "metrs": 1
            },
            {
                "size": " 80х25х2",
                "koef": "0.001978",
                "metrs": 1
            },
            {
                "size": " 80х25х4",
                "koef": "0.00361",
                "metrs": 1
            },
            {
                "size": " 80х30х1",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 80х30х1,2",
                "koef": "0.001296",
                "metrs": 1
            },
            {
                "size": " 80х30х1,5",
                "koef": "0.001613",
                "metrs": 1
            },
            {
                "size": " 80х30х1,8",
                "koef": "0.001927",
                "metrs": 1
            },
            {
                "size": " 80х30х2",
                "koef": "0.002135",
                "metrs": 1
            },
            {
                "size": " 80х32х2",
                "koef": "0.002198",
                "metrs": 1
            },
            {
                "size": " 80х32х2,5",
                "koef": "0.002728",
                "metrs": 1
            },
            {
                "size": " 80х32х3",
                "koef": "0.00325",
                "metrs": 1
            },
            {
                "size": " 80х32х4",
                "koef": "0.00405",
                "metrs": 1
            },
            {
                "size": " 80х32х5",
                "koef": "0.00526",
                "metrs": 1
            },
            {
                "size": " 80х35х1",
                "koef": "0.001162",
                "metrs": 1
            },
            {
                "size": " 80х35х1,2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 80х35х1,5",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": " 80х35х1,8",
                "koef": "0.002069",
                "metrs": 1
            },
            {
                "size": " 80х35х2",
                "koef": "0.002292",
                "metrs": 1
            },
            {
                "size": " 80х35х3",
                "koef": "0.003391",
                "metrs": 1
            },
            {
                "size": " 80х35х4",
                "koef": "0.00424",
                "metrs": 1
            },
            {
                "size": " 80х35х5",
                "koef": "0.005495",
                "metrs": 1
            },
            {
                "size": " 80х35х6",
                "koef": "0.0065",
                "metrs": 1
            },
            {
                "size": " 80х40х1",
                "koef": "0.00124",
                "metrs": 1
            },
            {
                "size": " 80х40х1,2",
                "koef": "0.001485",
                "metrs": 1
            },
            {
                "size": " 80х40х1,5",
                "koef": "0.001849",
                "metrs": 1
            },
            {
                "size": " 80х40х1,8",
                "koef": "0.00221",
                "metrs": 1
            },
            {
                "size": " 80х40х2",
                "koef": "0.002449",
                "metrs": 1
            },
            {
                "size": " 80х40х2,5",
                "koef": "0.00297",
                "metrs": 1
            },
            {
                "size": " 80х40х3",
                "koef": "0.00351",
                "metrs": 1
            },
            {
                "size": " 80х40х4",
                "koef": "0.004773",
                "metrs": 1
            },
            {
                "size": " 80х40х5",
                "koef": "0.005887",
                "metrs": 1
            },
            {
                "size": " 80х40х6",
                "koef": "0.006971",
                "metrs": 1
            },
            {
                "size": " 80х45х1",
                "koef": "0.001319",
                "metrs": 1
            },
            {
                "size": " 80х45х1,2",
                "koef": "0.001579",
                "metrs": 1
            },
            {
                "size": " 80х45х1,5",
                "koef": "0.001966",
                "metrs": 1
            },
            {
                "size": " 80х45х1,8",
                "koef": "0.002351",
                "metrs": 1
            },
            {
                "size": " 80х45х2",
                "koef": "0.002606",
                "metrs": 1
            },
            {
                "size": " 80х50х1",
                "koef": "0.001397",
                "metrs": 1
            },
            {
                "size": " 80х50х1,2",
                "koef": "0.001673",
                "metrs": 1
            },
            {
                "size": " 80х50х1,5",
                "koef": "0.002084",
                "metrs": 1
            },
            {
                "size": " 80х50х1,8",
                "koef": "0.002493",
                "metrs": 1
            },
            {
                "size": " 80х50х2",
                "koef": "0.002763",
                "metrs": 1
            },
            {
                "size": " 80х50х2,5",
                "koef": "0.003434",
                "metrs": 1
            },
            {
                "size": " 80х50х3",
                "koef": "0.004098",
                "metrs": 1
            },
            {
                "size": " 80х50х4",
                "koef": "0.00518",
                "metrs": 1
            },
            {
                "size": " 80х50х5",
                "koef": "0.006673",
                "metrs": 1
            },
            {
                "size": " 80х50х6",
                "koef": "0.007913",
                "metrs": 1
            },
            {
                "size": " 80х50х25х3",
                "koef": "0.00338",
                "metrs": 1
            },
            {
                "size": " 80х55х1",
                "koef": "0.001476",
                "metrs": 1
            },
            {
                "size": " 80х55х1,2",
                "koef": "0.001767",
                "metrs": 1
            },
            {
                "size": " 80х55х1,5",
                "koef": "0.002202",
                "metrs": 1
            },
            {
                "size": " 80х55х1,8",
                "koef": "0.002634",
                "metrs": 1
            },
            {
                "size": " 80х55х2",
                "koef": "0.00292",
                "metrs": 1
            },
            {
                "size": " 80х60х1",
                "koef": "0.001554",
                "metrs": 1
            },
            {
                "size": " 80х60х1,2",
                "koef": "0.001861",
                "metrs": 1
            },
            {
                "size": " 80х60х1,5",
                "koef": "0.00232",
                "metrs": 1
            },
            {
                "size": " 80х60х1,8",
                "koef": "0.002775",
                "metrs": 1
            },
            {
                "size": " 80х60х2",
                "koef": "0.003077",
                "metrs": 1
            },
            {
                "size": " 80х60х2,5",
                "koef": "0.003827",
                "metrs": 1
            },
            {
                "size": " 80х60х3",
                "koef": "0.00446",
                "metrs": 1
            },
            {
                "size": " 80х60х4",
                "koef": "0.00581",
                "metrs": 1
            },
            {
                "size": " 80х60х5",
                "koef": "0.007458",
                "metrs": 1
            },
            {
                "size": " 80х60х6",
                "koef": "0.00837",
                "metrs": 1
            },
            {
                "size": " 80х60х32х3",
                "koef": "0.00378",
                "metrs": 1
            },
            {
                "size": " 80х60х40х3",
                "koef": "0.00397",
                "metrs": 1
            },
            {
                "size": " 80х80х2",
                "koef": "0.003705",
                "metrs": 1
            },
            {
                "size": " 80х80х3",
                "koef": "0.0054",
                "metrs": 1
            },
            {
                "size": " 80х80х4",
                "koef": "0.00707",
                "metrs": 1
            },
            {
                "size": " 80х80х5",
                "koef": "0.009027",
                "metrs": 1
            },
            {
                "size": " 80х80х6",
                "koef": "0.01074",
                "metrs": 1
            },
            {
                "size": " 80х80х40х3",
                "koef": "0.00444",
                "metrs": 1
            },
            {
                "size": " 80х80х40х5",
                "koef": "0.00714",
                "metrs": 1
            },
            {
                "size": " 80х80х50х4",
                "koef": "0.00613",
                "metrs": 1
            },
            {
                "size": " 85х32х2",
                "koef": "0.002276",
                "metrs": 1
            },
            {
                "size": " 85х32х3",
                "koef": "0.003368",
                "metrs": 1
            },
            {
                "size": " 85х32х4",
                "koef": "0.004427",
                "metrs": 1
            },
            {
                "size": " 85х32х5",
                "koef": "0.005456",
                "metrs": 1
            },
            {
                "size": " 85х40х2",
                "koef": "0.002528",
                "metrs": 1
            },
            {
                "size": " 85х40х3",
                "koef": "0.003744",
                "metrs": 1
            },
            {
                "size": " 85х40х4",
                "koef": "0.00493",
                "metrs": 1
            },
            {
                "size": " 85х40х5",
                "koef": "0.006084",
                "metrs": 1
            },
            {
                "size": " 85х40х6",
                "koef": "0.007206",
                "metrs": 1
            },
            {
                "size": " 85х50х2",
                "koef": "0.002842",
                "metrs": 1
            },
            {
                "size": " 85х50х3",
                "koef": "0.004215",
                "metrs": 1
            },
            {
                "size": " 85х50х4",
                "koef": "0.005558",
                "metrs": 1
            },
            {
                "size": " 85х50х5",
                "koef": "0.006869",
                "metrs": 1
            },
            {
                "size": " 85х50х6",
                "koef": "0.008148",
                "metrs": 1
            },
            {
                "size": " 85х60х2",
                "koef": "0.003156",
                "metrs": 1
            },
            {
                "size": " 85х60х3",
                "koef": "0.004686",
                "metrs": 1
            },
            {
                "size": " 85х60х4",
                "koef": "0.006186",
                "metrs": 1
            },
            {
                "size": " 85х60х5",
                "koef": "0.007654",
                "metrs": 1
            },
            {
                "size": " 85х60х6",
                "koef": "0.00909",
                "metrs": 1
            },
            {
                "size": " 85х80х4",
                "koef": "0.00738",
                "metrs": 1
            },
            {
                "size": " 85х85х2",
                "koef": "0.003941",
                "metrs": 1
            },
            {
                "size": " 85х85х3",
                "koef": "0.005864",
                "metrs": 1
            },
            {
                "size": " 85х85х4",
                "koef": "0.007756",
                "metrs": 1
            },
            {
                "size": " 85х85х5",
                "koef": "0.009616",
                "metrs": 1
            },
            {
                "size": " 85х85х6",
                "koef": "0.01145",
                "metrs": 1
            },
            {
                "size": " 86х50х3",
                "koef": "0.004239",
                "metrs": 1
            },
            {
                "size": " 90х32х2",
                "koef": "0.002355",
                "metrs": 1
            },
            {
                "size": " 90х32х3",
                "koef": "0.003485",
                "metrs": 1
            },
            {
                "size": " 90х32х4",
                "koef": "0.004584",
                "metrs": 1
            },
            {
                "size": " 90х32х5",
                "koef": "0.005652",
                "metrs": 1
            },
            {
                "size": " 90х40х2",
                "koef": "0.002606",
                "metrs": 1
            },
            {
                "size": " 90х40х3",
                "koef": "0.003862",
                "metrs": 1
            },
            {
                "size": " 90х40х4",
                "koef": "0.005087",
                "metrs": 1
            },
            {
                "size": " 90х40х5",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 90х40х6",
                "koef": "0.007442",
                "metrs": 1
            },
            {
                "size": " 90х45х3",
                "koef": "0.004098",
                "metrs": 1
            },
            {
                "size": " 90х50х2",
                "koef": "0.00292",
                "metrs": 1
            },
            {
                "size": " 90х50х3",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 90х50х3,5",
                "koef": "0.00487",
                "metrs": 1
            },
            {
                "size": " 90х50х4",
                "koef": "0.005715",
                "metrs": 1
            },
            {
                "size": " 90х50х5",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": " 90х50х6",
                "koef": "0.008384",
                "metrs": 1
            },
            {
                "size": " 90х54х5",
                "koef": "0.00706",
                "metrs": 1
            },
            {
                "size": " 90х60х2",
                "koef": "0.003234",
                "metrs": 1
            },
            {
                "size": " 90х60х3",
                "koef": "0.004804",
                "metrs": 1
            },
            {
                "size": " 90х60х4",
                "koef": "0.006343",
                "metrs": 1
            },
            {
                "size": " 90х60х5",
                "koef": "0.00871",
                "metrs": 1
            },
            {
                "size": " 90х60х6",
                "koef": "0.009326",
                "metrs": 1
            },
            {
                "size": " 90х80х50х4",
                "koef": "0.00644",
                "metrs": 1
            },
            {
                "size": " 90х90х2",
                "koef": "0.004176",
                "metrs": 1
            },
            {
                "size": " 90х90х3",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 90х90х4",
                "koef": "0.008227",
                "metrs": 1
            },
            {
                "size": " 90х90х5",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": " 90х90х6",
                "koef": "0.01215",
                "metrs": 1
            },
            {
                "size": " 90х90х7",
                "koef": "0.01407",
                "metrs": 1
            },
            {
                "size": " 95х32х2",
                "koef": "0.002434",
                "metrs": 1
            },
            {
                "size": " 95х32х3",
                "koef": "0.003603",
                "metrs": 1
            },
            {
                "size": " 95х32х4",
                "koef": "0.004741",
                "metrs": 1
            },
            {
                "size": " 95х32х5",
                "koef": "0.005848",
                "metrs": 1
            },
            {
                "size": " 95х38х2,5",
                "koef": "0.0043",
                "metrs": 1
            },
            {
                "size": " 95х40х2",
                "koef": "0.002685",
                "metrs": 1
            },
            {
                "size": " 95х40х3",
                "koef": "0.00398",
                "metrs": 1
            },
            {
                "size": " 95х40х4",
                "koef": "0.005244",
                "metrs": 1
            },
            {
                "size": " 95х40х5",
                "koef": "0.006476",
                "metrs": 1
            },
            {
                "size": " 95х40х6",
                "koef": "0.007677",
                "metrs": 1
            },
            {
                "size": " 95х50х2",
                "koef": "0.002999",
                "metrs": 1
            },
            {
                "size": " 95х50х3",
                "koef": "0.004451",
                "metrs": 1
            },
            {
                "size": " 95х50х4",
                "koef": "0.005872",
                "metrs": 1
            },
            {
                "size": " 95х50х5",
                "koef": "0.007261",
                "metrs": 1
            },
            {
                "size": " 95х50х6",
                "koef": "0.008619",
                "metrs": 1
            },
            {
                "size": " 95х60х2",
                "koef": "0.003313",
                "metrs": 1
            },
            {
                "size": " 95х60х3",
                "koef": "0.004922",
                "metrs": 1
            },
            {
                "size": " 95х60х4",
                "koef": "0.0065",
                "metrs": 1
            },
            {
                "size": " 95х60х5",
                "koef": "0.008046",
                "metrs": 1
            },
            {
                "size": " 95х60х6",
                "koef": "0.009561",
                "metrs": 1
            },
            {
                "size": " 95х65х2",
                "koef": "0.00347",
                "metrs": 1
            },
            {
                "size": " 95х65х3",
                "koef": "0.005157",
                "metrs": 1
            },
            {
                "size": " 95х65х4",
                "koef": "0.006814",
                "metrs": 1
            },
            {
                "size": " 95х65х5",
                "koef": "0.008439",
                "metrs": 1
            },
            {
                "size": " 95х65х6",
                "koef": "0.01003",
                "metrs": 1
            },
            {
                "size": " 95х70х2",
                "koef": "0.003627",
                "metrs": 1
            },
            {
                "size": " 95х70х3",
                "koef": "0.005393",
                "metrs": 1
            },
            {
                "size": " 95х70х4",
                "koef": "0.007128",
                "metrs": 1
            },
            {
                "size": " 95х70х5",
                "koef": "0.008831",
                "metrs": 1
            },
            {
                "size": " 95х70х6",
                "koef": "0.0105",
                "metrs": 1
            },
            {
                "size": " 95х70х8",
                "koef": "0.01375",
                "metrs": 1
            },
            {
                "size": " 95х70х10",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": " 95х95х2",
                "koef": "0.004412",
                "metrs": 1
            },
            {
                "size": " 95х95х3",
                "koef": "0.00657",
                "metrs": 1
            },
            {
                "size": " 95х95х4",
                "koef": "0.008698",
                "metrs": 1
            },
            {
                "size": " 95х95х5",
                "koef": "0.01079",
                "metrs": 1
            },
            {
                "size": " 95х95х6",
                "koef": "0.01286",
                "metrs": 1
            },
            {
                "size": " 100х35х3",
                "koef": "0.003862",
                "metrs": 1
            },
            {
                "size": " 100х35х4",
                "koef": "0.005087",
                "metrs": 1
            },
            {
                "size": " 100х35х5",
                "koef": "0.00628",
                "metrs": 1
            },
            {
                "size": " 100х35х6",
                "koef": "0.007442",
                "metrs": 1
            },
            {
                "size": " 100х40х2",
                "koef": "0.002763",
                "metrs": 1
            },
            {
                "size": " 100х40х2,5",
                "koef": "0.00336",
                "metrs": 1
            },
            {
                "size": " 100х40х3",
                "koef": "0.00397",
                "metrs": 1
            },
            {
                "size": " 100х40х4",
                "koef": "0.005401",
                "metrs": 1
            },
            {
                "size": " 100х40х5",
                "koef": "0.006673",
                "metrs": 1
            },
            {
                "size": " 100х40х6",
                "koef": "0.007913",
                "metrs": 1
            },
            {
                "size": " 100х50х2",
                "koef": "0.003077",
                "metrs": 1
            },
            {
                "size": " 100х50х2,5",
                "koef": "0.003827",
                "metrs": 1
            },
            {
                "size": " 100х50х3",
                "koef": "0.00447",
                "metrs": 1
            },
            {
                "size": " 100х50х4",
                "koef": "0.00581",
                "metrs": 1
            },
            {
                "size": " 100х50х5",
                "koef": "0.00714",
                "metrs": 1
            },
            {
                "size": " 100х50х6",
                "koef": "0.00837",
                "metrs": 1
            },
            {
                "size": " 100х50х7",
                "koef": "0.01022",
                "metrs": 1
            },
            {
                "size": " 100х50х25х3",
                "koef": "0.00385",
                "metrs": 1
            },
            {
                "size": " 100х60х2",
                "koef": "0.003391",
                "metrs": 1
            },
            {
                "size": " 100х60х3",
                "koef": "0.00493",
                "metrs": 1
            },
            {
                "size": " 100х60х4",
                "koef": "0.00644",
                "metrs": 1
            },
            {
                "size": " 100х60х5",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": " 100х60х6",
                "koef": "0.009797",
                "metrs": 1
            },
            {
                "size": " 100х60х32х3",
                "koef": "0.00425",
                "metrs": 1
            },
            {
                "size": " 100х65х2",
                "koef": "0.003548",
                "metrs": 1
            },
            {
                "size": " 100х65х3",
                "koef": "0.005275",
                "metrs": 1
            },
            {
                "size": " 100х65х4",
                "koef": "0.006971",
                "metrs": 1
            },
            {
                "size": " 100х65х5",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": " 100х65х6",
                "koef": "0.01027",
                "metrs": 1
            },
            {
                "size": " 100х68х8",
                "koef": "0.01382",
                "metrs": 1
            },
            {
                "size": " 100х70х2",
                "koef": "0.003705",
                "metrs": 1
            },
            {
                "size": " 100х70х3",
                "koef": "0.005511",
                "metrs": 1
            },
            {
                "size": " 100х70х4",
                "koef": "0.007285",
                "metrs": 1
            },
            {
                "size": " 100х70х5",
                "koef": "0.009027",
                "metrs": 1
            },
            {
                "size": " 100х70х6",
                "koef": "0.01074",
                "metrs": 1
            },
            {
                "size": " 100х70х8",
                "koef": "0.01407",
                "metrs": 1
            },
            {
                "size": " 100х70х10",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": " 100х75х4",
                "koef": "0.007599",
                "metrs": 1
            },
            {
                "size": " 100х80х2",
                "koef": "0.004019",
                "metrs": 1
            },
            {
                "size": " 100х80х3",
                "koef": "0.00587",
                "metrs": 1
            },
            {
                "size": " 100х80х4",
                "koef": "0.0077",
                "metrs": 1
            },
            {
                "size": " 100х80х5",
                "koef": "0.00949",
                "metrs": 1
            },
            {
                "size": " 100х80х6",
                "koef": "0.01214",
                "metrs": 1
            },
            {
                "size": " 100х80х8",
                "koef": "0.01532",
                "metrs": 1
            },
            {
                "size": " 100х80х10",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 100х80х40х3",
                "koef": "0.00491",
                "metrs": 1
            },
            {
                "size": " 100х80х50х4",
                "koef": "0.00676",
                "metrs": 1
            },
            {
                "size": " 100х80х50х5",
                "koef": "0.00831",
                "metrs": 1
            },
            {
                "size": " 100х90х2,5",
                "koef": "0.0055",
                "metrs": 1
            },
            {
                "size": " 100х100х2",
                "koef": "0.004647",
                "metrs": 1
            },
            {
                "size": " 100х100х3",
                "koef": "0.00679",
                "metrs": 1
            },
            {
                "size": " 100х100х4",
                "koef": "0.009169",
                "metrs": 1
            },
            {
                "size": " 100х100х5",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": " 100х100х6",
                "koef": "0.01308",
                "metrs": 1
            },
            {
                "size": " 100х100х60х4",
                "koef": "0.0077",
                "metrs": 1
            },
            {
                "size": " 100х100х60х6",
                "koef": "0.0112",
                "metrs": 1
            },
            {
                "size": " 100х180х35х8",
                "koef": "0.01791",
                "metrs": 1
            },
            {
                "size": " 104х20х2",
                "koef": "0.00114",
                "metrs": 1
            },
            {
                "size": " 106х45х3",
                "koef": "0.004474",
                "metrs": 1
            },
            {
                "size": " 106х50х4",
                "koef": "0.006",
                "metrs": 1
            },
            {
                "size": " 108х70х6",
                "koef": "0.01063",
                "metrs": 1
            },
            {
                "size": " 110х26х2,5",
                "koef": "0.00301",
                "metrs": 1
            },
            {
                "size": " 110х50х4",
                "koef": "0.00613",
                "metrs": 1
            },
            {
                "size": " 110х50х5",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 110х60х4",
                "koef": "0.006971",
                "metrs": 1
            },
            {
                "size": " 110х100х4",
                "koef": "0.00927",
                "metrs": 1
            },
            {
                "size": " 115х90х5",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 120х25х4",
                "koef": "0.00487",
                "metrs": 1
            },
            {
                "size": " 120х35х4",
                "koef": "0.005715",
                "metrs": 1
            },
            {
                "size": " 120х35х5",
                "koef": "0.007065",
                "metrs": 1
            },
            {
                "size": " 120х35х6",
                "koef": "0.008384",
                "metrs": 1
            },
            {
                "size": " 120х40х2",
                "koef": "0.003077",
                "metrs": 1
            },
            {
                "size": " 120х40х3",
                "koef": "0.004569",
                "metrs": 1
            },
            {
                "size": " 120х40х4",
                "koef": "0.006029",
                "metrs": 1
            },
            {
                "size": " 120х40х5",
                "koef": "0.007458",
                "metrs": 1
            },
            {
                "size": " 120х40х6",
                "koef": "0.008855",
                "metrs": 1
            },
            {
                "size": " 120х45х35х5",
                "koef": "0.00714",
                "metrs": 1
            },
            {
                "size": " 120х50х2",
                "koef": "0.003391",
                "metrs": 1
            },
            {
                "size": " 120х50х2,5",
                "koef": "0.004219",
                "metrs": 1
            },
            {
                "size": " 120х50х3",
                "koef": "0.00491",
                "metrs": 1
            },
            {
                "size": " 120х50х4",
                "koef": "0.00644",
                "metrs": 1
            },
            {
                "size": " 120х50х5",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": " 120х50х6",
                "koef": "0.00931",
                "metrs": 1
            },
            {
                "size": " 120х60х2",
                "koef": "0.003705",
                "metrs": 1
            },
            {
                "size": " 120х60х3",
                "koef": "0.005511",
                "metrs": 1
            },
            {
                "size": " 120х60х4",
                "koef": "0.00707",
                "metrs": 1
            },
            {
                "size": " 120х60х5",
                "koef": "0.00871",
                "metrs": 1
            },
            {
                "size": " 120х60х6",
                "koef": "0.01025",
                "metrs": 1
            },
            {
                "size": " 120х60х50х5",
                "koef": "0.00831",
                "metrs": 1
            },
            {
                "size": " 120х65х2",
                "koef": "0.003862",
                "metrs": 1
            },
            {
                "size": " 120х65х3",
                "koef": "0.005746",
                "metrs": 1
            },
            {
                "size": " 120х65х4",
                "koef": "0.007599",
                "metrs": 1
            },
            {
                "size": " 120х65х5",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 120х65х6",
                "koef": "0.01121",
                "metrs": 1
            },
            {
                "size": " 120х68х8",
                "koef": "0.01507",
                "metrs": 1
            },
            {
                "size": " 120х70х2",
                "koef": "0.004019",
                "metrs": 1
            },
            {
                "size": " 120х70х3",
                "koef": "0.005982",
                "metrs": 1
            },
            {
                "size": " 120х70х4",
                "koef": "0.007913",
                "metrs": 1
            },
            {
                "size": " 120х70х5",
                "koef": "0.00949",
                "metrs": 1
            },
            {
                "size": " 120х70х6",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 120х70х8",
                "koef": "0.01532",
                "metrs": 1
            },
            {
                "size": " 120х70х10",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 120х80х2",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 120х80х3",
                "koef": "0.006453",
                "metrs": 1
            },
            {
                "size": " 120х80х4",
                "koef": "0.00832",
                "metrs": 1
            },
            {
                "size": " 120х80х5",
                "koef": "0.01028",
                "metrs": 1
            },
            {
                "size": " 120х80х6",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 120х80х8",
                "koef": "0.01658",
                "metrs": 1
            },
            {
                "size": " 120х80х10",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": " 120х90х7",
                "koef": "0.01475",
                "metrs": 1
            },
            {
                "size": " 120х100х8",
                "koef": "0.01906",
                "metrs": 1
            },
            {
                "size": " 120х105х8",
                "koef": "0.01843",
                "metrs": 1
            },
            {
                "size": " 130х40х2",
                "koef": "0.003234",
                "metrs": 1
            },
            {
                "size": " 130х40х3",
                "koef": "0.004804",
                "metrs": 1
            },
            {
                "size": " 130х40х4",
                "koef": "0.006343",
                "metrs": 1
            },
            {
                "size": " 130х40х5",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 130х40х6",
                "koef": "0.009326",
                "metrs": 1
            },
            {
                "size": " 130х50х2",
                "koef": "0.003548",
                "metrs": 1
            },
            {
                "size": " 130х50х3",
                "koef": "0.005275",
                "metrs": 1
            },
            {
                "size": " 130х50х4",
                "koef": "0.006971",
                "metrs": 1
            },
            {
                "size": " 130х50х5",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": " 130х50х6",
                "koef": "0.01027",
                "metrs": 1
            },
            {
                "size": " 130х60х2",
                "koef": "0.003862",
                "metrs": 1
            },
            {
                "size": " 130х60х3",
                "koef": "0.005746",
                "metrs": 1
            },
            {
                "size": " 130х60х4",
                "koef": "0.007599",
                "metrs": 1
            },
            {
                "size": " 130х60х5",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 130х60х6",
                "koef": "0.01121",
                "metrs": 1
            },
            {
                "size": " 130х65х2",
                "koef": "0.004019",
                "metrs": 1
            },
            {
                "size": " 130х65х3",
                "koef": "0.005982",
                "metrs": 1
            },
            {
                "size": " 130х65х4",
                "koef": "0.007913",
                "metrs": 1
            },
            {
                "size": " 130х65х5",
                "koef": "0.009813",
                "metrs": 1
            },
            {
                "size": " 130х65х6",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 130х68х8",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 130х70х2",
                "koef": "0.004176",
                "metrs": 1
            },
            {
                "size": " 130х70х3",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 130х70х4",
                "koef": "0.008227",
                "metrs": 1
            },
            {
                "size": " 130х70х5",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": " 130х70х6",
                "koef": "0.01215",
                "metrs": 1
            },
            {
                "size": " 130х70х8",
                "koef": "0.01595",
                "metrs": 1
            },
            {
                "size": " 130х70х10",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": " 130х80х2",
                "koef": "0.00449",
                "metrs": 1
            },
            {
                "size": " 130х80х3",
                "koef": "0.006688",
                "metrs": 1
            },
            {
                "size": " 130х80х4",
                "koef": "0.008855",
                "metrs": 1
            },
            {
                "size": " 130х80х5",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": " 130х80х6",
                "koef": "0.01309",
                "metrs": 1
            },
            {
                "size": " 130х80х8",
                "koef": "0.01721",
                "metrs": 1
            },
            {
                "size": " 130х80х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": " 130х108х50х4",
                "koef": "0.00858",
                "metrs": 1
            },
            {
                "size": " 135х50х36х4",
                "koef": "0.00647",
                "metrs": 1
            },
            {
                "size": " 135х130х8",
                "koef": "0.02282",
                "metrs": 1
            },
            {
                "size": " 140х35х4",
                "koef": "0.006343",
                "metrs": 1
            },
            {
                "size": " 140х35х5",
                "koef": "0.00785",
                "metrs": 1
            },
            {
                "size": " 140х35х6",
                "koef": "0.009326",
                "metrs": 1
            },
            {
                "size": " 140х40х2",
                "koef": "0.003391",
                "metrs": 1
            },
            {
                "size": " 140х40х2,5",
                "koef": "0.00415",
                "metrs": 1
            },
            {
                "size": " 140х40х3",
                "koef": "0.00491",
                "metrs": 1
            },
            {
                "size": " 140х40х4",
                "koef": "0.006657",
                "metrs": 1
            },
            {
                "size": " 140х40х5",
                "koef": "0.008243",
                "metrs": 1
            },
            {
                "size": " 140х40х6",
                "koef": "0.009797",
                "metrs": 1
            },
            {
                "size": " 140х50х2",
                "koef": "0.003705",
                "metrs": 1
            },
            {
                "size": " 140х50х3",
                "koef": "0.005511",
                "metrs": 1
            },
            {
                "size": " 140х50х4",
                "koef": "0.007285",
                "metrs": 1
            },
            {
                "size": " 140х50х5",
                "koef": "0.009027",
                "metrs": 1
            },
            {
                "size": " 140х50х6",
                "koef": "0.01074",
                "metrs": 1
            },
            {
                "size": " 140х60х2",
                "koef": "0.004019",
                "metrs": 1
            },
            {
                "size": " 140х60х3",
                "koef": "0.00585",
                "metrs": 1
            },
            {
                "size": " 140х60х4",
                "koef": "0.00759",
                "metrs": 1
            },
            {
                "size": " 140х60х5",
                "koef": "0.00949",
                "metrs": 1
            },
            {
                "size": " 140х60х6",
                "koef": "0.0112",
                "metrs": 1
            },
            {
                "size": " 140х65х2",
                "koef": "0.004176",
                "metrs": 1
            },
            {
                "size": " 140х65х3",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 140х65х4",
                "koef": "0.008227",
                "metrs": 1
            },
            {
                "size": " 140х65х5",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": " 140х65х6",
                "koef": "0.01215",
                "metrs": 1
            },
            {
                "size": " 140х68х8",
                "koef": "0.01633",
                "metrs": 1
            },
            {
                "size": " 140х70х2",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 140х70х3",
                "koef": "0.006453",
                "metrs": 1
            },
            {
                "size": " 140х70х4",
                "koef": "0.008541",
                "metrs": 1
            },
            {
                "size": " 140х70х5",
                "koef": "0.01028",
                "metrs": 1
            },
            {
                "size": " 140х70х6",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 140х70х8",
                "koef": "0.01658",
                "metrs": 1
            },
            {
                "size": " 140х70х10",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": " 140х70х30х4",
                "koef": "0.00707",
                "metrs": 1
            },
            {
                "size": " 140х80х2",
                "koef": "0.004647",
                "metrs": 1
            },
            {
                "size": " 140х80х3",
                "koef": "0.006924",
                "metrs": 1
            },
            {
                "size": " 140х80х4",
                "koef": "0.00895",
                "metrs": 1
            },
            {
                "size": " 140х80х5",
                "koef": "0.01106",
                "metrs": 1
            },
            {
                "size": " 140х80х6",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 140х80х8",
                "koef": "0.01784",
                "metrs": 1
            },
            {
                "size": " 140х80х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 140х90х2",
                "koef": "0.004961",
                "metrs": 1
            },
            {
                "size": " 140х90х3",
                "koef": "0.007395",
                "metrs": 1
            },
            {
                "size": " 140х90х4",
                "koef": "0.009797",
                "metrs": 1
            },
            {
                "size": " 140х90х5",
                "koef": "0.01217",
                "metrs": 1
            },
            {
                "size": " 140х90х6",
                "koef": "0.01451",
                "metrs": 1
            },
            {
                "size": " 140х90х8",
                "koef": "0.01909",
                "metrs": 1
            },
            {
                "size": " 140х90х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 140х100х2",
                "koef": "0.005275",
                "metrs": 1
            },
            {
                "size": " 140х100х3",
                "koef": "0.007866",
                "metrs": 1
            },
            {
                "size": " 140х100х4",
                "koef": "0.01042",
                "metrs": 1
            },
            {
                "size": " 140х100х5",
                "koef": "0.01295",
                "metrs": 1
            },
            {
                "size": " 140х100х6",
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": " 140х100х8",
                "koef": "0.02035",
                "metrs": 1
            },
            {
                "size": " 140х100х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 144х160х90х6",
                "koef": "0.01751",
                "metrs": 1
            },
            {
                "size": " 145х65х3",
                "koef": "0.0062",
                "metrs": 1
            },
            {
                "size": " 145х75х5",
                "koef": "0.0107",
                "metrs": 1
            },
            {
                "size": " 148х25х4",
                "koef": "0.00575",
                "metrs": 1
            },
            {
                "size": " 150х40х2",
                "koef": "0.003548",
                "metrs": 1
            },
            {
                "size": " 150х40х3",
                "koef": "0.005275",
                "metrs": 1
            },
            {
                "size": " 150х40х4",
                "koef": "0.006971",
                "metrs": 1
            },
            {
                "size": " 150х40х5",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": " 150х40х6",
                "koef": "0.01027",
                "metrs": 1
            },
            {
                "size": " 150х50х2",
                "koef": "0.003862",
                "metrs": 1
            },
            {
                "size": " 150х50х3",
                "koef": "0.005746",
                "metrs": 1
            },
            {
                "size": " 150х50х4",
                "koef": "0.007599",
                "metrs": 1
            },
            {
                "size": " 150х50х5",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 150х50х6",
                "koef": "0.01121",
                "metrs": 1
            },
            {
                "size": " 150х60х2",
                "koef": "0.004176",
                "metrs": 1
            },
            {
                "size": " 150х60х3",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 150х60х4",
                "koef": "0.008227",
                "metrs": 1
            },
            {
                "size": " 150х60х5",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": " 150х60х6",
                "koef": "0.01215",
                "metrs": 1
            },
            {
                "size": " 150х65х2",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 150х65х3",
                "koef": "0.006453",
                "metrs": 1
            },
            {
                "size": " 150х65х4",
                "koef": "0.008541",
                "metrs": 1
            },
            {
                "size": " 150х65х5",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": " 150х65х6",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 150х68х8",
                "koef": "0.01696",
                "metrs": 1
            },
            {
                "size": " 150х70х2",
                "koef": "0.00449",
                "metrs": 1
            },
            {
                "size": " 150х70х3",
                "koef": "0.006688",
                "metrs": 1
            },
            {
                "size": " 150х70х4",
                "koef": "0.008855",
                "metrs": 1
            },
            {
                "size": " 150х70х5",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": " 150х70х6",
                "koef": "0.01309",
                "metrs": 1
            },
            {
                "size": " 150х70х8",
                "koef": "0.01721",
                "metrs": 1
            },
            {
                "size": " 150х70х10",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": " 150х80х2",
                "koef": "0.004804",
                "metrs": 1
            },
            {
                "size": " 150х80х3",
                "koef": "0.007159",
                "metrs": 1
            },
            {
                "size": " 150х80х4",
                "koef": "0.009483",
                "metrs": 1
            },
            {
                "size": " 150х80х5",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": " 150х80х6",
                "koef": "0.01404",
                "metrs": 1
            },
            {
                "size": " 150х80х8",
                "koef": "0.01846",
                "metrs": 1
            },
            {
                "size": " 150х80х10",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": " 150х90х2",
                "koef": "0.005118",
                "metrs": 1
            },
            {
                "size": " 150х90х3",
                "koef": "0.00763",
                "metrs": 1
            },
            {
                "size": " 150х90х4",
                "koef": "0.01011",
                "metrs": 1
            },
            {
                "size": " 150х90х5",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": " 150х90х6",
                "koef": "0.01498",
                "metrs": 1
            },
            {
                "size": " 150х90х8",
                "koef": "0.01972",
                "metrs": 1
            },
            {
                "size": " 150х90х10",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": " 150х100х2",
                "koef": "0.005432",
                "metrs": 1
            },
            {
                "size": " 150х100х3",
                "koef": "0.008101",
                "metrs": 1
            },
            {
                "size": " 150х100х4",
                "koef": "0.01074",
                "metrs": 1
            },
            {
                "size": " 150х100х5",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": " 150х100х6",
                "koef": "0.01592",
                "metrs": 1
            },
            {
                "size": " 150х100х8",
                "koef": "0.02098",
                "metrs": 1
            },
            {
                "size": " 150х100х10",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": " 150х110х2",
                "koef": "0.005746",
                "metrs": 1
            },
            {
                "size": " 150х110х3",
                "koef": "0.008572",
                "metrs": 1
            },
            {
                "size": " 150х110х4",
                "koef": "0.01137",
                "metrs": 1
            },
            {
                "size": " 150х110х5",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 150х110х6",
                "koef": "0.01686",
                "metrs": 1
            },
            {
                "size": " 150х110х8",
                "koef": "0.02223",
                "metrs": 1
            },
            {
                "size": " 150х110х10",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": " 150х120х2",
                "koef": "0.00606",
                "metrs": 1
            },
            {
                "size": " 150х120х3",
                "koef": "0.009043",
                "metrs": 1
            },
            {
                "size": " 150х120х4",
                "koef": "0.01199",
                "metrs": 1
            },
            {
                "size": " 150х120х5",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": " 150х120х6",
                "koef": "0.0178",
                "metrs": 1
            },
            {
                "size": " 150х120х8",
                "koef": "0.02349",
                "metrs": 1
            },
            {
                "size": " 150х120х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": " 150х130х2",
                "koef": "0.006374",
                "metrs": 1
            },
            {
                "size": " 150х130х3",
                "koef": "0.009514",
                "metrs": 1
            },
            {
                "size": " 150х130х4",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 150х130х5",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 150х130х6",
                "koef": "0.01875",
                "metrs": 1
            },
            {
                "size": " 150х130х8",
                "koef": "0.02474",
                "metrs": 1
            },
            {
                "size": " 150х130х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": " 160х20х6",
                "koef": "0.008855",
                "metrs": 1
            },
            {
                "size": " 160х35х4",
                "koef": "0.006971",
                "metrs": 1
            },
            {
                "size": " 160х35х5",
                "koef": "0.008635",
                "metrs": 1
            },
            {
                "size": " 160х35х6",
                "koef": "0.01027",
                "metrs": 1
            },
            {
                "size": " 160х40х2",
                "koef": "0.00365",
                "metrs": 1
            },
            {
                "size": " 160х40х3",
                "koef": "0.00538",
                "metrs": 1
            },
            {
                "size": " 160х40х4",
                "koef": "0.007285",
                "metrs": 1
            },
            {
                "size": " 160х40х5",
                "koef": "0.00871",
                "metrs": 1
            },
            {
                "size": " 160х40х6",
                "koef": "0.01074",
                "metrs": 1
            },
            {
                "size": " 160х50х2",
                "koef": "0.004019",
                "metrs": 1
            },
            {
                "size": " 160х50х2,5",
                "koef": "0.00492",
                "metrs": 1
            },
            {
                "size": " 160х50х3",
                "koef": "0.005982",
                "metrs": 1
            },
            {
                "size": " 160х50х4",
                "koef": "0.0077",
                "metrs": 1
            },
            {
                "size": " 160х50х5",
                "koef": "0.00949",
                "metrs": 1
            },
            {
                "size": " 160х50х6",
                "koef": "0.0112",
                "metrs": 1
            },
            {
                "size": " 160х50х30х3",
                "koef": "0.00538",
                "metrs": 1
            },
            {
                "size": " 160х50х30х4",
                "koef": "0.00707",
                "metrs": 1
            },
            {
                "size": " 160х60х2",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 160х60х2,5",
                "koef": "0.00531",
                "metrs": 1
            },
            {
                "size": " 160х60х3",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": " 160х60х4",
                "koef": "0.00832",
                "metrs": 1
            },
            {
                "size": " 160х60х5",
                "koef": "0.01028",
                "metrs": 1
            },
            {
                "size": " 160х60х6",
                "koef": "0.01214",
                "metrs": 1
            },
            {
                "size": " 160х65х2",
                "koef": "0.00449",
                "metrs": 1
            },
            {
                "size": " 160х65х3",
                "koef": "0.006688",
                "metrs": 1
            },
            {
                "size": " 160х65х4",
                "koef": "0.008855",
                "metrs": 1
            },
            {
                "size": " 160х65х5",
                "koef": "0.01099",
                "metrs": 1
            },
            {
                "size": " 160х65х6",
                "koef": "0.01309",
                "metrs": 1
            },
            {
                "size": " 160х68х8",
                "koef": "0.01758",
                "metrs": 1
            },
            {
                "size": " 160х70х2",
                "koef": "0.004647",
                "metrs": 1
            },
            {
                "size": " 160х70х3",
                "koef": "0.006924",
                "metrs": 1
            },
            {
                "size": " 160х70х4",
                "koef": "0.00895",
                "metrs": 1
            },
            {
                "size": " 160х70х5",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": " 160х70х6",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 160х70х8",
                "koef": "0.01784",
                "metrs": 1
            },
            {
                "size": " 160х70х10",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 160х75х8",
                "koef": "0.01717",
                "metrs": 1
            },
            {
                "size": " 160х80х2",
                "koef": "0.004961",
                "metrs": 1
            },
            {
                "size": " 160х80х2,5",
                "koef": "0.00611",
                "metrs": 1
            },
            {
                "size": " 160х80х3",
                "koef": "0.00726",
                "metrs": 1
            },
            {
                "size": " 160х80х4",
                "koef": "0.00958",
                "metrs": 1
            },
            {
                "size": " 160х80х5",
                "koef": "0.01185",
                "metrs": 1
            },
            {
                "size": " 160х80х6",
                "koef": "0.01402",
                "metrs": 1
            },
            {
                "size": " 160х80х8",
                "koef": "0.01909",
                "metrs": 1
            },
            {
                "size": " 160х80х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 160х80х50х5",
                "koef": "0.01067",
                "metrs": 1
            },
            {
                "size": " 160х90х2",
                "koef": "0.005275",
                "metrs": 1
            },
            {
                "size": " 160х90х3",
                "koef": "0.007866",
                "metrs": 1
            },
            {
                "size": " 160х90х4",
                "koef": "0.01042",
                "metrs": 1
            },
            {
                "size": " 160х90х5",
                "koef": "0.01295",
                "metrs": 1
            },
            {
                "size": " 160х90х6",
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": " 160х90х8",
                "koef": "0.02035",
                "metrs": 1
            },
            {
                "size": " 160х90х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 160х100х2",
                "koef": "0.005589",
                "metrs": 1
            },
            {
                "size": " 160х100х3",
                "koef": "0.00828",
                "metrs": 1
            },
            {
                "size": " 160х100х4",
                "koef": "0.01272",
                "metrs": 1
            },
            {
                "size": " 160х100х5",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": " 160х100х6",
                "koef": "0.01591",
                "metrs": 1
            },
            {
                "size": " 160х100х8",
                "koef": "0.0216",
                "metrs": 1
            },
            {
                "size": " 160х100х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": " 160х110х2",
                "koef": "0.005903",
                "metrs": 1
            },
            {
                "size": " 160х110х3",
                "koef": "0.008808",
                "metrs": 1
            },
            {
                "size": " 160х110х4",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 160х110х5",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": " 160х110х6",
                "koef": "0.01733",
                "metrs": 1
            },
            {
                "size": " 160х110х8",
                "koef": "0.02286",
                "metrs": 1
            },
            {
                "size": " 160х110х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 160х120х2",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 160х120х3",
                "koef": "0.009279",
                "metrs": 1
            },
            {
                "size": " 160х120х4",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": " 160х120х5",
                "koef": "0.01499",
                "metrs": 1
            },
            {
                "size": " 160х120х6",
                "koef": "0.01779",
                "metrs": 1
            },
            {
                "size": " 160х120х8",
                "koef": "0.02412",
                "metrs": 1
            },
            {
                "size": " 160х120х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": " 160х130х2",
                "koef": "0.006531",
                "metrs": 1
            },
            {
                "size": " 160х130х3",
                "koef": "0.00975",
                "metrs": 1
            },
            {
                "size": " 160х130х4",
                "koef": "0.01294",
                "metrs": 1
            },
            {
                "size": " 160х130х5",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": " 160х130х6",
                "koef": "0.01922",
                "metrs": 1
            },
            {
                "size": " 160х130х8",
                "koef": "0.02537",
                "metrs": 1
            },
            {
                "size": " 160х130х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 160х140х2",
                "koef": "0.006845",
                "metrs": 1
            },
            {
                "size": " 160х140х3",
                "koef": "0.01022",
                "metrs": 1
            },
            {
                "size": " 160х140х4",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 160х140х5",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": " 160х140х6",
                "koef": "0.02016",
                "metrs": 1
            },
            {
                "size": " 160х140х8",
                "koef": "0.02663",
                "metrs": 1
            },
            {
                "size": " 160х140х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": " 160х160х3",
                "koef": "0.01116",
                "metrs": 1
            },
            {
                "size": " 160х160х4",
                "koef": "0.01482",
                "metrs": 1
            },
            {
                "size": " 160х160х5",
                "koef": "0.01845",
                "metrs": 1
            },
            {
                "size": " 160х160х6",
                "koef": "0.02156",
                "metrs": 1
            },
            {
                "size": " 170х60х4",
                "koef": "0.00864",
                "metrs": 1
            },
            {
                "size": " 170х70х2,5",
                "koef": "0.005986",
                "metrs": 1
            },
            {
                "size": " 170х70х3",
                "koef": "0.007159",
                "metrs": 1
            },
            {
                "size": " 170х70х4",
                "koef": "0.009483",
                "metrs": 1
            },
            {
                "size": " 170х70х5",
                "koef": "0.01145",
                "metrs": 1
            },
            {
                "size": " 170х70х6",
                "koef": "0.01355",
                "metrs": 1
            },
            {
                "size": " 170х100х2,5",
                "koef": "0.007163",
                "metrs": 1
            },
            {
                "size": " 170х100х3",
                "koef": "0.008572",
                "metrs": 1
            },
            {
                "size": " 170х100х4",
                "koef": "0.01137",
                "metrs": 1
            },
            {
                "size": " 180х35х4",
                "koef": "0.007599",
                "metrs": 1
            },
            {
                "size": " 180х35х5",
                "koef": "0.00942",
                "metrs": 1
            },
            {
                "size": " 180х35х6",
                "koef": "0.01121",
                "metrs": 1
            },
            {
                "size": " 180х40х2",
                "koef": "0.004019",
                "metrs": 1
            },
            {
                "size": " 180х40х3",
                "koef": "0.00585",
                "metrs": 1
            },
            {
                "size": " 180х40х4",
                "koef": "0.0077",
                "metrs": 1
            },
            {
                "size": " 180х40х5",
                "koef": "0.009813",
                "metrs": 1
            },
            {
                "size": " 180х40х6",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 180х50х2",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 180х50х2,5",
                "koef": "0.005397",
                "metrs": 1
            },
            {
                "size": " 180х50х3",
                "koef": "0.006453",
                "metrs": 1
            },
            {
                "size": " 180х50х4",
                "koef": "0.00832",
                "metrs": 1
            },
            {
                "size": " 180х50х5",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": " 180х50х6",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 180х60х2",
                "koef": "0.004647",
                "metrs": 1
            },
            {
                "size": " 180х60х3",
                "koef": "0.006924",
                "metrs": 1
            },
            {
                "size": " 180х60х4",
                "koef": "0.009169",
                "metrs": 1
            },
            {
                "size": " 180х60х5",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": " 180х60х6",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 180х65х2",
                "koef": "0.004804",
                "metrs": 1
            },
            {
                "size": " 180х65х3",
                "koef": "0.007159",
                "metrs": 1
            },
            {
                "size": " 180х65х4",
                "koef": "0.009483",
                "metrs": 1
            },
            {
                "size": " 180х65х5",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": " 180х65х6",
                "koef": "0.01404",
                "metrs": 1
            },
            {
                "size": " 180х68х8",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 180х70х2",
                "koef": "0.004961",
                "metrs": 1
            },
            {
                "size": " 180х70х3",
                "koef": "0.007395",
                "metrs": 1
            },
            {
                "size": " 180х70х4",
                "koef": "0.009797",
                "metrs": 1
            },
            {
                "size": " 180х70х5",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 180х70х6",
                "koef": "0.01402",
                "metrs": 1
            },
            {
                "size": " 180х70х7",
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": " 180х70х8",
                "koef": "0.01909",
                "metrs": 1
            },
            {
                "size": " 180х70х10",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 180х80х2",
                "koef": "0.005275",
                "metrs": 1
            },
            {
                "size": " 180х80х3",
                "koef": "0.007866",
                "metrs": 1
            },
            {
                "size": " 180х80х4",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": " 180х80х5",
                "koef": "0.01268",
                "metrs": 1
            },
            {
                "size": " 180х80х6",
                "koef": "0.01496",
                "metrs": 1
            },
            {
                "size": " 180х80х8",
                "koef": "0.01905",
                "metrs": 1
            },
            {
                "size": " 180х80х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 180х90х2",
                "koef": "0.005589",
                "metrs": 1
            },
            {
                "size": " 180х90х3",
                "koef": "0.008337",
                "metrs": 1
            },
            {
                "size": " 180х90х4",
                "koef": "0.01105",
                "metrs": 1
            },
            {
                "size": " 180х90х5",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": " 180х90х6",
                "koef": "0.01639",
                "metrs": 1
            },
            {
                "size": " 180х90х8",
                "koef": "0.0216",
                "metrs": 1
            },
            {
                "size": " 180х90х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": " 180х100х2",
                "koef": "0.005903",
                "metrs": 1
            },
            {
                "size": " 180х100х3",
                "koef": "0.008808",
                "metrs": 1
            },
            {
                "size": " 180х100х4",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 180х100х5",
                "koef": "0.0142",
                "metrs": 1
            },
            {
                "size": " 180х100х6",
                "koef": "0.01684",
                "metrs": 1
            },
            {
                "size": " 180х100х8",
                "koef": "0.02286",
                "metrs": 1
            },
            {
                "size": " 180х100х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 180х110х2",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 180х110х3",
                "koef": "0.009279",
                "metrs": 1
            },
            {
                "size": " 180х110х4",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": " 180х110х5",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": " 180х110х6",
                "koef": "0.01827",
                "metrs": 1
            },
            {
                "size": " 180х110х8",
                "koef": "0.02412",
                "metrs": 1
            },
            {
                "size": " 180х110х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": " 180х120х2",
                "koef": "0.006531",
                "metrs": 1
            },
            {
                "size": " 180х120х3",
                "koef": "0.00975",
                "metrs": 1
            },
            {
                "size": " 180х120х4",
                "koef": "0.01294",
                "metrs": 1
            },
            {
                "size": " 180х120х5",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": " 180х120х6",
                "koef": "0.01922",
                "metrs": 1
            },
            {
                "size": " 180х120х8",
                "koef": "0.02537",
                "metrs": 1
            },
            {
                "size": " 180х120х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 180х130х2",
                "koef": "0.006845",
                "metrs": 1
            },
            {
                "size": " 180х130х3",
                "koef": "0.01022",
                "metrs": 1
            },
            {
                "size": " 180х130х4",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 180х130х5",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": " 180х130х6",
                "koef": "0.02016",
                "metrs": 1
            },
            {
                "size": " 180х130х8",
                "koef": "0.02576",
                "metrs": 1
            },
            {
                "size": " 180х130х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": " 180х140х2",
                "koef": "0.007159",
                "metrs": 1
            },
            {
                "size": " 180х140х3",
                "koef": "0.01069",
                "metrs": 1
            },
            {
                "size": " 180х140х4",
                "koef": "0.01419",
                "metrs": 1
            },
            {
                "size": " 180х140х5",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": " 180х140х6",
                "koef": "0.0211",
                "metrs": 1
            },
            {
                "size": " 180х140х8",
                "koef": "0.02788",
                "metrs": 1
            },
            {
                "size": " 180х140х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": " 180х150х2",
                "koef": "0.007473",
                "metrs": 1
            },
            {
                "size": " 180х150х3",
                "koef": "0.01116",
                "metrs": 1
            },
            {
                "size": " 180х150х4",
                "koef": "0.01482",
                "metrs": 1
            },
            {
                "size": " 180х150х5",
                "koef": "0.01845",
                "metrs": 1
            },
            {
                "size": " 180х150х6",
                "koef": "0.02204",
                "metrs": 1
            },
            {
                "size": " 180х150х8",
                "koef": "0.02914",
                "metrs": 1
            },
            {
                "size": " 180х150х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": " 185х100х3",
                "koef": "0.00879",
                "metrs": 1
            },
            {
                "size": " 200х35х4",
                "koef": "0.008227",
                "metrs": 1
            },
            {
                "size": " 200х35х5",
                "koef": "0.01021",
                "metrs": 1
            },
            {
                "size": " 200х35х6",
                "koef": "0.01215",
                "metrs": 1
            },
            {
                "size": " 200х40х2",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 200х40х3",
                "koef": "0.006453",
                "metrs": 1
            },
            {
                "size": " 200х40х4",
                "koef": "0.008541",
                "metrs": 1
            },
            {
                "size": " 200х40х5",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": " 200х40х6",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 200х50х2",
                "koef": "0.004647",
                "metrs": 1
            },
            {
                "size": " 200х50х2,5",
                "koef": "0.005789",
                "metrs": 1
            },
            {
                "size": " 200х50х3",
                "koef": "0.00679",
                "metrs": 1
            },
            {
                "size": " 200х50х4",
                "koef": "0.00895",
                "metrs": 1
            },
            {
                "size": " 200х50х5",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": " 200х50х6",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 200х50х30х4",
                "koef": "0.00833",
                "metrs": 1
            },
            {
                "size": " 200х60х2",
                "koef": "0.004961",
                "metrs": 1
            },
            {
                "size": " 200х60х2,5",
                "koef": "0.006182",
                "metrs": 1
            },
            {
                "size": " 200х60х3",
                "koef": "0.007395",
                "metrs": 1
            },
            {
                "size": " 200х60х4",
                "koef": "0.00947",
                "metrs": 1
            },
            {
                "size": " 200х60х5",
                "koef": "0.01217",
                "metrs": 1
            },
            {
                "size": " 200х60х6",
                "koef": "0.01451",
                "metrs": 1
            },
            {
                "size": " 200х65х2",
                "koef": "0.005118",
                "metrs": 1
            },
            {
                "size": " 200х65х3",
                "koef": "0.00763",
                "metrs": 1
            },
            {
                "size": " 200х65х4",
                "koef": "0.01011",
                "metrs": 1
            },
            {
                "size": " 200х65х5",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": " 200х65х6",
                "koef": "0.01498",
                "metrs": 1
            },
            {
                "size": " 200х65х8",
                "koef": "0.01972",
                "metrs": 1
            },
            {
                "size": " 200х70х2",
                "koef": "0.005275",
                "metrs": 1
            },
            {
                "size": " 200х70х3",
                "koef": "0.007866",
                "metrs": 1
            },
            {
                "size": " 200х70х4",
                "koef": "0.01042",
                "metrs": 1
            },
            {
                "size": " 200х70х5",
                "koef": "0.01295",
                "metrs": 1
            },
            {
                "size": " 200х70х6",
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": " 200х70х8",
                "koef": "0.02035",
                "metrs": 1
            },
            {
                "size": " 200х70х10",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 200х75х6",
                "koef": "0.01592",
                "metrs": 1
            },
            {
                "size": " 200х80х2",
                "koef": "0.005589",
                "metrs": 1
            },
            {
                "size": " 200х80х2,5",
                "koef": "0.006967",
                "metrs": 1
            },
            {
                "size": " 200х80х3",
                "koef": "0.008337",
                "metrs": 1
            },
            {
                "size": " 200х80х4",
                "koef": "0.01083",
                "metrs": 1
            },
            {
                "size": " 200х80х5",
                "koef": "0.01342",
                "metrs": 1
            },
            {
                "size": " 200х80х6",
                "koef": "0.01591",
                "metrs": 1
            },
            {
                "size": " 200х80х8",
                "koef": "0.0216",
                "metrs": 1
            },
            {
                "size": " 200х80х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": " 200х90х2",
                "koef": "0.005903",
                "metrs": 1
            },
            {
                "size": " 200х90х3",
                "koef": "0.008808",
                "metrs": 1
            },
            {
                "size": " 200х90х4",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 200х90х5",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": " 200х90х6",
                "koef": "0.01733",
                "metrs": 1
            },
            {
                "size": " 200х90х8",
                "koef": "0.02286",
                "metrs": 1
            },
            {
                "size": " 200х90х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 200х100х2",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 200х100х3",
                "koef": "0.00915",
                "metrs": 1
            },
            {
                "size": " 200х100х4",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": " 200х100х5",
                "koef": "0.01482",
                "metrs": 1
            },
            {
                "size": " 200х100х6",
                "koef": "0.01779",
                "metrs": 1
            },
            {
                "size": " 200х100х8",
                "koef": "0.02412",
                "metrs": 1
            },
            {
                "size": " 200х100х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": " 200х110х2",
                "koef": "0.006531",
                "metrs": 1
            },
            {
                "size": " 200х110х3",
                "koef": "0.00975",
                "metrs": 1
            },
            {
                "size": " 200х110х4",
                "koef": "0.01294",
                "metrs": 1
            },
            {
                "size": " 200х110х5",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": " 200х110х6",
                "koef": "0.01922",
                "metrs": 1
            },
            {
                "size": " 200х110х8",
                "koef": "0.02537",
                "metrs": 1
            },
            {
                "size": " 200х110х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 200х120х2",
                "koef": "0.006845",
                "metrs": 1
            },
            {
                "size": " 200х120х3",
                "koef": "0.01022",
                "metrs": 1
            },
            {
                "size": " 200х120х4",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 200х120х5",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": " 200х120х6",
                "koef": "0.02016",
                "metrs": 1
            },
            {
                "size": " 200х120х8",
                "koef": "0.02663",
                "metrs": 1
            },
            {
                "size": " 200х120х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": " 200х125х3",
                "koef": "0.01046",
                "metrs": 1
            },
            {
                "size": " 200х125х4",
                "koef": "0.01388",
                "metrs": 1
            },
            {
                "size": " 200х125х5",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": " 200х125х6",
                "koef": "0.02063",
                "metrs": 1
            },
            {
                "size": " 200х130х2",
                "koef": "0.007159",
                "metrs": 1
            },
            {
                "size": " 200х130х3",
                "koef": "0.01069",
                "metrs": 1
            },
            {
                "size": " 200х130х4",
                "koef": "0.01419",
                "metrs": 1
            },
            {
                "size": " 200х130х5",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": " 200х130х6",
                "koef": "0.0211",
                "metrs": 1
            },
            {
                "size": " 200х130х8",
                "koef": "0.02788",
                "metrs": 1
            },
            {
                "size": " 200х130х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": " 200х140х2",
                "koef": "0.007473",
                "metrs": 1
            },
            {
                "size": " 200х140х3",
                "koef": "0.01116",
                "metrs": 1
            },
            {
                "size": " 200х140х4",
                "koef": "0.01482",
                "metrs": 1
            },
            {
                "size": " 200х140х5",
                "koef": "0.01845",
                "metrs": 1
            },
            {
                "size": " 200х140х6",
                "koef": "0.02204",
                "metrs": 1
            },
            {
                "size": " 200х140х8",
                "koef": "0.02914",
                "metrs": 1
            },
            {
                "size": " 200х140х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": " 200х150х2",
                "koef": "0.007787",
                "metrs": 1
            },
            {
                "size": " 200х150х3",
                "koef": "0.01163",
                "metrs": 1
            },
            {
                "size": " 200х150х4",
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": " 200х150х5",
                "koef": "0.01923",
                "metrs": 1
            },
            {
                "size": " 200х150х6",
                "koef": "0.02298",
                "metrs": 1
            },
            {
                "size": " 200х150х8",
                "koef": "0.0304",
                "metrs": 1
            },
            {
                "size": " 200х150х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": " 200х160х2",
                "koef": "0.008101",
                "metrs": 1
            },
            {
                "size": " 200х160х3",
                "koef": "0.0121",
                "metrs": 1
            },
            {
                "size": " 200х160х4",
                "koef": "0.01608",
                "metrs": 1
            },
            {
                "size": " 200х160х5",
                "koef": "0.02002",
                "metrs": 1
            },
            {
                "size": " 200х160х6",
                "koef": "0.02393",
                "metrs": 1
            },
            {
                "size": " 200х160х8",
                "koef": "0.03036",
                "metrs": 1
            },
            {
                "size": " 200х160х10",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": " 200х170х2",
                "koef": "0.008415",
                "metrs": 1
            },
            {
                "size": " 200х170х3",
                "koef": "0.01258",
                "metrs": 1
            },
            {
                "size": " 200х170х4",
                "koef": "0.0167",
                "metrs": 1
            },
            {
                "size": " 200х170х5",
                "koef": "0.0208",
                "metrs": 1
            },
            {
                "size": " 200х170х6",
                "koef": "0.02487",
                "metrs": 1
            },
            {
                "size": " 200х170х8",
                "koef": "0.03291",
                "metrs": 1
            },
            {
                "size": " 200х170х10",
                "koef": "0.04082",
                "metrs": 1
            },
            {
                "size": " 200х180х6",
                "koef": "0.02533",
                "metrs": 1
            },
            {
                "size": " 205х38х2,5",
                "koef": "0.00534",
                "metrs": 1
            },
            {
                "size": " 206х75х6",
                "koef": "0.01572",
                "metrs": 1
            },
            {
                "size": " 210х57х4",
                "koef": "0.00971",
                "metrs": 1
            },
            {
                "size": " 220х40х2",
                "koef": "0.004647",
                "metrs": 1
            },
            {
                "size": " 220х40х3",
                "koef": "0.006924",
                "metrs": 1
            },
            {
                "size": " 220х40х4",
                "koef": "0.009169",
                "metrs": 1
            },
            {
                "size": " 220х40х5",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": " 220х40х6",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 220х50х2",
                "koef": "0.004961",
                "metrs": 1
            },
            {
                "size": " 220х50х3",
                "koef": "0.007395",
                "metrs": 1
            },
            {
                "size": " 220х50х4",
                "koef": "0.009797",
                "metrs": 1
            },
            {
                "size": " 220х50х5",
                "koef": "0.01217",
                "metrs": 1
            },
            {
                "size": " 220х50х6",
                "koef": "0.01451",
                "metrs": 1
            },
            {
                "size": " 220х60х2",
                "koef": "0.005275",
                "metrs": 1
            },
            {
                "size": " 220х60х3",
                "koef": "0.007866",
                "metrs": 1
            },
            {
                "size": " 220х60х4",
                "koef": "0.01042",
                "metrs": 1
            },
            {
                "size": " 220х60х5",
                "koef": "0.01295",
                "metrs": 1
            },
            {
                "size": " 220х60х6",
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": " 220х65х2",
                "koef": "0.005432",
                "metrs": 1
            },
            {
                "size": " 220х65х3",
                "koef": "0.008101",
                "metrs": 1
            },
            {
                "size": " 220х65х4",
                "koef": "0.01074",
                "metrs": 1
            },
            {
                "size": " 220х65х5",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": " 220х65х6",
                "koef": "0.01592",
                "metrs": 1
            },
            {
                "size": " 220х65х8",
                "koef": "0.02098",
                "metrs": 1
            },
            {
                "size": " 220х70х2",
                "koef": "0.005589",
                "metrs": 1
            },
            {
                "size": " 220х70х3",
                "koef": "0.008337",
                "metrs": 1
            },
            {
                "size": " 220х70х4",
                "koef": "0.01105",
                "metrs": 1
            },
            {
                "size": " 220х70х5",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": " 220х70х6",
                "koef": "0.01639",
                "metrs": 1
            },
            {
                "size": " 220х70х8",
                "koef": "0.0216",
                "metrs": 1
            },
            {
                "size": " 220х70х10",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": " 220х80х2",
                "koef": "0.005903",
                "metrs": 1
            },
            {
                "size": " 220х80х3",
                "koef": "0.008808",
                "metrs": 1
            },
            {
                "size": " 220х80х4",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 220х80х5",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": " 220х80х6",
                "koef": "0.01733",
                "metrs": 1
            },
            {
                "size": " 220х80х8",
                "koef": "0.02286",
                "metrs": 1
            },
            {
                "size": " 220х80х10",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 220х90х2",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 220х90х3",
                "koef": "0.009279",
                "metrs": 1
            },
            {
                "size": " 220х90х4",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": " 220х90х5",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": " 220х90х6",
                "koef": "0.01827",
                "metrs": 1
            },
            {
                "size": " 220х90х8",
                "koef": "0.02412",
                "metrs": 1
            },
            {
                "size": " 220х90х10",
                "koef": "0.02983",
                "metrs": 1
            },
            {
                "size": " 220х100х2",
                "koef": "0.006531",
                "metrs": 1
            },
            {
                "size": " 220х100х3",
                "koef": "0.00975",
                "metrs": 1
            },
            {
                "size": " 220х100х4",
                "koef": "0.01294",
                "metrs": 1
            },
            {
                "size": " 220х100х5",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": " 220х100х6",
                "koef": "0.01922",
                "metrs": 1
            },
            {
                "size": " 220х100х8",
                "koef": "0.02537",
                "metrs": 1
            },
            {
                "size": " 220х100х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 220х110х2",
                "koef": "0.006845",
                "metrs": 1
            },
            {
                "size": " 220х110х3",
                "koef": "0.01022",
                "metrs": 1
            },
            {
                "size": " 220х110х4",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 220х110х5",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": " 220х110х6",
                "koef": "0.02016",
                "metrs": 1
            },
            {
                "size": " 220х110х8",
                "koef": "0.02663",
                "metrs": 1
            },
            {
                "size": " 220х110х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": " 220х120х2",
                "koef": "0.007159",
                "metrs": 1
            },
            {
                "size": " 220х120х3",
                "koef": "0.01069",
                "metrs": 1
            },
            {
                "size": " 220х120х4",
                "koef": "0.01419",
                "metrs": 1
            },
            {
                "size": " 220х120х5",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": " 220х120х6",
                "koef": "0.0211",
                "metrs": 1
            },
            {
                "size": " 220х120х8",
                "koef": "0.02788",
                "metrs": 1
            },
            {
                "size": " 220х120х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": " 220х130х2",
                "koef": "0.007473",
                "metrs": 1
            },
            {
                "size": " 220х130х3",
                "koef": "0.01116",
                "metrs": 1
            },
            {
                "size": " 220х130х4",
                "koef": "0.01482",
                "metrs": 1
            },
            {
                "size": " 220х130х5",
                "koef": "0.01845",
                "metrs": 1
            },
            {
                "size": " 220х130х6",
                "koef": "0.02204",
                "metrs": 1
            },
            {
                "size": " 220х130х8",
                "koef": "0.02914",
                "metrs": 1
            },
            {
                "size": " 220х130х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": " 220х140х2",
                "koef": "0.007787",
                "metrs": 1
            },
            {
                "size": " 220х140х3",
                "koef": "0.01163",
                "metrs": 1
            },
            {
                "size": " 220х140х4",
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": " 220х140х5",
                "koef": "0.01923",
                "metrs": 1
            },
            {
                "size": " 220х140х6",
                "koef": "0.02298",
                "metrs": 1
            },
            {
                "size": " 220х140х8",
                "koef": "0.0304",
                "metrs": 1
            },
            {
                "size": " 220х140х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": " 220х150х2",
                "koef": "0.008101",
                "metrs": 1
            },
            {
                "size": " 220х150х3",
                "koef": "0.0121",
                "metrs": 1
            },
            {
                "size": " 220х150х4",
                "koef": "0.01608",
                "metrs": 1
            },
            {
                "size": " 220х150х5",
                "koef": "0.02002",
                "metrs": 1
            },
            {
                "size": " 220х150х6",
                "koef": "0.02393",
                "metrs": 1
            },
            {
                "size": " 220х150х8",
                "koef": "0.03165",
                "metrs": 1
            },
            {
                "size": " 220х150х10",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": " 220х160х2",
                "koef": "0.008415",
                "metrs": 1
            },
            {
                "size": " 220х160х3",
                "koef": "0.01258",
                "metrs": 1
            },
            {
                "size": " 220х160х4",
                "koef": "0.0167",
                "metrs": 1
            },
            {
                "size": " 220х160х5",
                "koef": "0.0208",
                "metrs": 1
            },
            {
                "size": " 220х160х6",
                "koef": "0.02487",
                "metrs": 1
            },
            {
                "size": " 220х160х8",
                "koef": "0.03291",
                "metrs": 1
            },
            {
                "size": " 220х160х10",
                "koef": "0.04082",
                "metrs": 1
            },
            {
                "size": " 220х170х2",
                "koef": "0.008729",
                "metrs": 1
            },
            {
                "size": " 220х170х3",
                "koef": "0.01305",
                "metrs": 1
            },
            {
                "size": " 220х170х4",
                "koef": "0.01733",
                "metrs": 1
            },
            {
                "size": " 220х170х5",
                "koef": "0.02159",
                "metrs": 1
            },
            {
                "size": " 220х170х6",
                "koef": "0.02581",
                "metrs": 1
            },
            {
                "size": " 220х170х8",
                "koef": "0.03416",
                "metrs": 1
            },
            {
                "size": " 220х170х10",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": " 220х180х2",
                "koef": "0.009043",
                "metrs": 1
            },
            {
                "size": " 220х180х3",
                "koef": "0.01352",
                "metrs": 1
            },
            {
                "size": " 220х180х4",
                "koef": "0.01796",
                "metrs": 1
            },
            {
                "size": " 220х180х5",
                "koef": "0.02237",
                "metrs": 1
            },
            {
                "size": " 220х180х6",
                "koef": "0.02675",
                "metrs": 1
            },
            {
                "size": " 220х180х8",
                "koef": "0.03542",
                "metrs": 1
            },
            {
                "size": " 220х180х10",
                "koef": "0.04396",
                "metrs": 1
            },
            {
                "size": " 250х25х3",
                "koef": "0.00575",
                "metrs": 1
            },
            {
                "size": " 250х35х3",
                "koef": "0.00726",
                "metrs": 1
            },
            {
                "size": " 250х35х4",
                "koef": "0.009797",
                "metrs": 1
            },
            {
                "size": " 250х35х5",
                "koef": "0.01217",
                "metrs": 1
            },
            {
                "size": " 250х35х6",
                "koef": "0.01451",
                "metrs": 1
            },
            {
                "size": " 250х40х2",
                "koef": "0.005118",
                "metrs": 1
            },
            {
                "size": " 250х40х3",
                "koef": "0.00763",
                "metrs": 1
            },
            {
                "size": " 250х40х4",
                "koef": "0.01011",
                "metrs": 1
            },
            {
                "size": " 250х40х5",
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": " 250х40х6",
                "koef": "0.01498",
                "metrs": 1
            },
            {
                "size": " 250х50х2",
                "koef": "0.005432",
                "metrs": 1
            },
            {
                "size": " 250х50х3",
                "koef": "0.008101",
                "metrs": 1
            },
            {
                "size": " 250х50х4",
                "koef": "0.01074",
                "metrs": 1
            },
            {
                "size": " 250х50х5",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": " 250х50х6",
                "koef": "0.01592",
                "metrs": 1
            },
            {
                "size": " 250х60х2",
                "koef": "0.005746",
                "metrs": 1
            },
            {
                "size": " 250х60х3",
                "koef": "0.00844",
                "metrs": 1
            },
            {
                "size": " 250х60х4",
                "koef": "0.01115",
                "metrs": 1
            },
            {
                "size": " 250х60х5",
                "koef": "0.01381",
                "metrs": 1
            },
            {
                "size": " 250х60х6",
                "koef": "0.01638",
                "metrs": 1
            },
            {
                "size": " 250х65х2",
                "koef": "0.005903",
                "metrs": 1
            },
            {
                "size": " 250х65х3",
                "koef": "0.008808",
                "metrs": 1
            },
            {
                "size": " 250х65х4",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 250х65х5",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": " 250х65х6",
                "koef": "0.01733",
                "metrs": 1
            },
            {
                "size": " 250х68х8",
                "koef": "0.02324",
                "metrs": 1
            },
            {
                "size": " 250х70х2",
                "koef": "0.00606",
                "metrs": 1
            },
            {
                "size": " 250х70х3",
                "koef": "0.009043",
                "metrs": 1
            },
            {
                "size": " 250х70х4",
                "koef": "0.01199",
                "metrs": 1
            },
            {
                "size": " 250х70х5",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": " 250х70х6",
                "koef": "0.0178",
                "metrs": 1
            },
            {
                "size": " 250х70х8",
                "koef": "0.02349",
                "metrs": 1
            },
            {
                "size": " 250х70х10",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": " 250х75х4",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": " 250х80х2",
                "koef": "0.006374",
                "metrs": 1
            },
            {
                "size": " 250х80х3",
                "koef": "0.009514",
                "metrs": 1
            },
            {
                "size": " 250х80х4",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 250х80х5",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 250х80х6",
                "koef": "0.01875",
                "metrs": 1
            },
            {
                "size": " 250х80х8",
                "koef": "0.02474",
                "metrs": 1
            },
            {
                "size": " 250х80х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": " 250х90х2",
                "koef": "0.006688",
                "metrs": 1
            },
            {
                "size": " 250х90х3",
                "koef": "0.009985",
                "metrs": 1
            },
            {
                "size": " 250х90х4",
                "koef": "0.01325",
                "metrs": 1
            },
            {
                "size": " 250х90х5",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": " 250х90х6",
                "koef": "0.01969",
                "metrs": 1
            },
            {
                "size": " 250х90х8",
                "koef": "0.02471",
                "metrs": 1
            },
            {
                "size": " 250х90х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": " 250х100х2",
                "koef": "0.007002",
                "metrs": 1
            },
            {
                "size": " 250х100х3",
                "koef": "0.01046",
                "metrs": 1
            },
            {
                "size": " 250х100х4",
                "koef": "0.01388",
                "metrs": 1
            },
            {
                "size": " 250х100х5",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": " 250х100х6",
                "koef": "0.02063",
                "metrs": 1
            },
            {
                "size": " 250х100х8",
                "koef": "0.02726",
                "metrs": 1
            },
            {
                "size": " 250х100х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": " 250х110х2",
                "koef": "0.007316",
                "metrs": 1
            },
            {
                "size": " 250х110х3",
                "koef": "0.01093",
                "metrs": 1
            },
            {
                "size": " 250х110х4",
                "koef": "0.01451",
                "metrs": 1
            },
            {
                "size": " 250х110х5",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": " 250х110х6",
                "koef": "0.02157",
                "metrs": 1
            },
            {
                "size": " 250х110х8",
                "koef": "0.02851",
                "metrs": 1
            },
            {
                "size": " 250х110х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": " 250х120х2",
                "koef": "0.00763",
                "metrs": 1
            },
            {
                "size": " 250х120х3",
                "koef": "0.0114",
                "metrs": 1
            },
            {
                "size": " 250х120х4",
                "koef": "0.01513",
                "metrs": 1
            },
            {
                "size": " 250х120х5",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 250х120х6",
                "koef": "0.02251",
                "metrs": 1
            },
            {
                "size": " 250х120х8",
                "koef": "0.02977",
                "metrs": 1
            },
            {
                "size": " 250х120х10",
                "koef": "0.0369",
                "metrs": 1
            },
            {
                "size": " 250х125х4",
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": " 250х125х5",
                "koef": "0.01923",
                "metrs": 1
            },
            {
                "size": " 250х125х6",
                "koef": "0.0225",
                "metrs": 1
            },
            {
                "size": " 250х125х8",
                "koef": "0.0304",
                "metrs": 1
            },
            {
                "size": " 250х130х2",
                "koef": "0.007944",
                "metrs": 1
            },
            {
                "size": " 250х130х3",
                "koef": "0.01187",
                "metrs": 1
            },
            {
                "size": " 250х130х4",
                "koef": "0.01576",
                "metrs": 1
            },
            {
                "size": " 250х130х5",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": " 250х130х6",
                "koef": "0.02346",
                "metrs": 1
            },
            {
                "size": " 250х130х8",
                "koef": "0.03102",
                "metrs": 1
            },
            {
                "size": " 250х130х10",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": " 250х140х2",
                "koef": "0.008258",
                "metrs": 1
            },
            {
                "size": " 250х140х3",
                "koef": "0.01234",
                "metrs": 1
            },
            {
                "size": " 250х140х4",
                "koef": "0.01639",
                "metrs": 1
            },
            {
                "size": " 250х140х5",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": " 250х140х6",
                "koef": "0.0244",
                "metrs": 1
            },
            {
                "size": " 250х140х8",
                "koef": "0.03228",
                "metrs": 1
            },
            {
                "size": " 250х140х10",
                "koef": "0.04003",
                "metrs": 1
            },
            {
                "size": " 250х150х2",
                "koef": "0.008572",
                "metrs": 1
            },
            {
                "size": " 250х150х3",
                "koef": "0.01281",
                "metrs": 1
            },
            {
                "size": " 250х150х4",
                "koef": "0.01702",
                "metrs": 1
            },
            {
                "size": " 250х150х5",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": " 250х150х6",
                "koef": "0.02534",
                "metrs": 1
            },
            {
                "size": " 250х150х8",
                "koef": "0.03354",
                "metrs": 1
            },
            {
                "size": " 250х150х10",
                "koef": "0.04161",
                "metrs": 1
            },
            {
                "size": " 250х160х2",
                "koef": "0.008886",
                "metrs": 1
            },
            {
                "size": " 250х160х3",
                "koef": "0.01328",
                "metrs": 1
            },
            {
                "size": " 250х160х4",
                "koef": "0.01765",
                "metrs": 1
            },
            {
                "size": " 250х160х5",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 250х160х6",
                "koef": "0.02628",
                "metrs": 1
            },
            {
                "size": " 250х160х8",
                "koef": "0.03479",
                "metrs": 1
            },
            {
                "size": " 250х160х10",
                "koef": "0.04318",
                "metrs": 1
            },
            {
                "size": " 250х170х2",
                "koef": "0.0092",
                "metrs": 1
            },
            {
                "size": " 250х170х3",
                "koef": "0.01375",
                "metrs": 1
            },
            {
                "size": " 250х170х4",
                "koef": "0.01827",
                "metrs": 1
            },
            {
                "size": " 250х170х5",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": " 250х170х6",
                "koef": "0.02722",
                "metrs": 1
            },
            {
                "size": " 250х170х8",
                "koef": "0.03605",
                "metrs": 1
            },
            {
                "size": " 250х170х10",
                "koef": "0.04475",
                "metrs": 1
            },
            {
                "size": " 250х180х2",
                "koef": "0.009514",
                "metrs": 1
            },
            {
                "size": " 250х180х3",
                "koef": "0.01422",
                "metrs": 1
            },
            {
                "size": " 250х180х4",
                "koef": "0.0189",
                "metrs": 1
            },
            {
                "size": " 250х180х5",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 250х180х6",
                "koef": "0.02817",
                "metrs": 1
            },
            {
                "size": " 250х180х8",
                "koef": "0.0373",
                "metrs": 1
            },
            {
                "size": " 250х180х10",
                "koef": "0.04631",
                "metrs": 1
            },
            {
                "size": " 250х200х2",
                "koef": "0.01014",
                "metrs": 1
            },
            {
                "size": " 250х200х3",
                "koef": "0.01517",
                "metrs": 1
            },
            {
                "size": " 250х200х4",
                "koef": "0.02016",
                "metrs": 1
            },
            {
                "size": " 250х200х5",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 250х200х6",
                "koef": "0.03005",
                "metrs": 1
            },
            {
                "size": " 250х200х8",
                "koef": "0.03982",
                "metrs": 1
            },
            {
                "size": " 250х200х10",
                "koef": "0.04946",
                "metrs": 1
            },
            {
                "size": " 250х220х2",
                "koef": "0.01077",
                "metrs": 1
            },
            {
                "size": " 250х220х3",
                "koef": "0.01611",
                "metrs": 1
            },
            {
                "size": " 250х220х4",
                "koef": "0.02141",
                "metrs": 1
            },
            {
                "size": " 250х220х5",
                "koef": "0.02669",
                "metrs": 1
            },
            {
                "size": " 250х220х6",
                "koef": "0.03193",
                "metrs": 1
            },
            {
                "size": " 250х220х8",
                "koef": "0.04233",
                "metrs": 1
            },
            {
                "size": " 250х220х10",
                "koef": "0.0526",
                "metrs": 1
            },
            {
                "size": " 270х40х2",
                "koef": "0.005432",
                "metrs": 1
            },
            {
                "size": " 270х40х3",
                "koef": "0.008101",
                "metrs": 1
            },
            {
                "size": " 270х40х4",
                "koef": "0.01074",
                "metrs": 1
            },
            {
                "size": " 270х40х5",
                "koef": "0.01335",
                "metrs": 1
            },
            {
                "size": " 270х40х6",
                "koef": "0.01592",
                "metrs": 1
            },
            {
                "size": " 270х50х2",
                "koef": "0.005746",
                "metrs": 1
            },
            {
                "size": " 270х50х3",
                "koef": "0.008572",
                "metrs": 1
            },
            {
                "size": " 270х50х4",
                "koef": "0.01137",
                "metrs": 1
            },
            {
                "size": " 270х50х5",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": " 270х50х6",
                "koef": "0.01686",
                "metrs": 1
            },
            {
                "size": " 270х60х2",
                "koef": "0.00606",
                "metrs": 1
            },
            {
                "size": " 270х60х3",
                "koef": "0.009043",
                "metrs": 1
            },
            {
                "size": " 270х60х4",
                "koef": "0.01199",
                "metrs": 1
            },
            {
                "size": " 270х60х5",
                "koef": "0.01492",
                "metrs": 1
            },
            {
                "size": " 270х60х6",
                "koef": "0.0178",
                "metrs": 1
            },
            {
                "size": " 270х65х2",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 270х65х3",
                "koef": "0.009279",
                "metrs": 1
            },
            {
                "size": " 270х65х4",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": " 270х65х5",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": " 270х65х6",
                "koef": "0.01827",
                "metrs": 1
            },
            {
                "size": " 270х68х8",
                "koef": "0.02449",
                "metrs": 1
            },
            {
                "size": " 270х70х2",
                "koef": "0.006374",
                "metrs": 1
            },
            {
                "size": " 270х70х3",
                "koef": "0.009514",
                "metrs": 1
            },
            {
                "size": " 270х70х4",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 270х70х5",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 270х70х6",
                "koef": "0.01875",
                "metrs": 1
            },
            {
                "size": " 270х70х8",
                "koef": "0.02474",
                "metrs": 1
            },
            {
                "size": " 270х70х10",
                "koef": "0.03062",
                "metrs": 1
            },
            {
                "size": " 270х80х2",
                "koef": "0.006688",
                "metrs": 1
            },
            {
                "size": " 270х80х3",
                "koef": "0.009985",
                "metrs": 1
            },
            {
                "size": " 270х80х4",
                "koef": "0.01325",
                "metrs": 1
            },
            {
                "size": " 270х80х5",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": " 270х80х6",
                "koef": "0.01969",
                "metrs": 1
            },
            {
                "size": " 270х80х8",
                "koef": "0.026",
                "metrs": 1
            },
            {
                "size": " 270х80х10",
                "koef": "0.03219",
                "metrs": 1
            },
            {
                "size": " 270х90х2",
                "koef": "0.007002",
                "metrs": 1
            },
            {
                "size": " 270х90х3",
                "koef": "0.01046",
                "metrs": 1
            },
            {
                "size": " 270х90х4",
                "koef": "0.01388",
                "metrs": 1
            },
            {
                "size": " 270х90х5",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": " 270х90х6",
                "koef": "0.02063",
                "metrs": 1
            },
            {
                "size": " 270х90х8",
                "koef": "0.02726",
                "metrs": 1
            },
            {
                "size": " 270х90х10",
                "koef": "0.03375",
                "metrs": 1
            },
            {
                "size": " 270х90х72х8",
                "koef": "0.02526",
                "metrs": 1
            },
            {
                "size": " 270х90х80х6",
                "koef": "0.01967",
                "metrs": 1
            },
            {
                "size": " 270х100х2",
                "koef": "0.007316",
                "metrs": 1
            },
            {
                "size": " 270х100х3",
                "koef": "0.01093",
                "metrs": 1
            },
            {
                "size": " 270х100х4",
                "koef": "0.01451",
                "metrs": 1
            },
            {
                "size": " 270х100х5",
                "koef": "0.01805",
                "metrs": 1
            },
            {
                "size": " 270х100х6",
                "koef": "0.02157",
                "metrs": 1
            },
            {
                "size": " 270х100х7",
                "koef": "0.02442",
                "metrs": 1
            },
            {
                "size": " 270х100х8",
                "koef": "0.02851",
                "metrs": 1
            },
            {
                "size": " 270х100х10",
                "koef": "0.03532",
                "metrs": 1
            },
            {
                "size": " 270х110х2",
                "koef": "0.00763",
                "metrs": 1
            },
            {
                "size": " 270х110х3",
                "koef": "0.0114",
                "metrs": 1
            },
            {
                "size": " 270х110х4",
                "koef": "0.01513",
                "metrs": 1
            },
            {
                "size": " 270х110х5",
                "koef": "0.01884",
                "metrs": 1
            },
            {
                "size": " 270х110х6",
                "koef": "0.02251",
                "metrs": 1
            },
            {
                "size": " 270х110х8",
                "koef": "0.02977",
                "metrs": 1
            },
            {
                "size": " 270х110х10",
                "koef": "0.0369",
                "metrs": 1
            },
            {
                "size": " 270х120х2",
                "koef": "0.007944",
                "metrs": 1
            },
            {
                "size": " 270х120х3",
                "koef": "0.01187",
                "metrs": 1
            },
            {
                "size": " 270х120х4",
                "koef": "0.01576",
                "metrs": 1
            },
            {
                "size": " 270х120х5",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": " 270х120х6",
                "koef": "0.02346",
                "metrs": 1
            },
            {
                "size": " 270х120х8",
                "koef": "0.03102",
                "metrs": 1
            },
            {
                "size": " 270х120х10",
                "koef": "0.03847",
                "metrs": 1
            },
            {
                "size": " 270х130х2",
                "koef": "0.008258",
                "metrs": 1
            },
            {
                "size": " 270х130х3",
                "koef": "0.01234",
                "metrs": 1
            },
            {
                "size": " 270х130х4",
                "koef": "0.01639",
                "metrs": 1
            },
            {
                "size": " 270х130х5",
                "koef": "0.02041",
                "metrs": 1
            },
            {
                "size": " 270х130х6",
                "koef": "0.0244",
                "metrs": 1
            },
            {
                "size": " 270х130х8",
                "koef": "0.03228",
                "metrs": 1
            },
            {
                "size": " 270х130х10",
                "koef": "0.04003",
                "metrs": 1
            },
            {
                "size": " 270х140х2",
                "koef": "0.008572",
                "metrs": 1
            },
            {
                "size": " 270х140х3",
                "koef": "0.01281",
                "metrs": 1
            },
            {
                "size": " 270х140х4",
                "koef": "0.01702",
                "metrs": 1
            },
            {
                "size": " 270х140х5",
                "koef": "0.0212",
                "metrs": 1
            },
            {
                "size": " 270х140х6",
                "koef": "0.02534",
                "metrs": 1
            },
            {
                "size": " 270х140х8",
                "koef": "0.03354",
                "metrs": 1
            },
            {
                "size": " 270х140х10",
                "koef": "0.04161",
                "metrs": 1
            },
            {
                "size": " 270х150х2",
                "koef": "0.008886",
                "metrs": 1
            },
            {
                "size": " 270х150х3",
                "koef": "0.01328",
                "metrs": 1
            },
            {
                "size": " 270х150х4",
                "koef": "0.01765",
                "metrs": 1
            },
            {
                "size": " 270х150х5",
                "koef": "0.02198",
                "metrs": 1
            },
            {
                "size": " 270х150х6",
                "koef": "0.02628",
                "metrs": 1
            },
            {
                "size": " 270х150х8",
                "koef": "0.03479",
                "metrs": 1
            },
            {
                "size": " 270х150х10",
                "koef": "0.04318",
                "metrs": 1
            },
            {
                "size": " 270х160х2",
                "koef": "0.0092",
                "metrs": 1
            },
            {
                "size": " 270х160х3",
                "koef": "0.01375",
                "metrs": 1
            },
            {
                "size": " 270х160х4",
                "koef": "0.01827",
                "metrs": 1
            },
            {
                "size": " 270х160х5",
                "koef": "0.02276",
                "metrs": 1
            },
            {
                "size": " 270х160х6",
                "koef": "0.02722",
                "metrs": 1
            },
            {
                "size": " 270х160х8",
                "koef": "0.03605",
                "metrs": 1
            },
            {
                "size": " 270х160х10",
                "koef": "0.04475",
                "metrs": 1
            },
            {
                "size": " 270х170х2",
                "koef": "0.009514",
                "metrs": 1
            },
            {
                "size": " 270х170х3",
                "koef": "0.01422",
                "metrs": 1
            },
            {
                "size": " 270х170х4",
                "koef": "0.0189",
                "metrs": 1
            },
            {
                "size": " 270х170х5",
                "koef": "0.02355",
                "metrs": 1
            },
            {
                "size": " 270х170х6",
                "koef": "0.02817",
                "metrs": 1
            },
            {
                "size": " 270х170х8",
                "koef": "0.0373",
                "metrs": 1
            },
            {
                "size": " 270х170х10",
                "koef": "0.04631",
                "metrs": 1
            },
            {
                "size": " 270х180х2",
                "koef": "0.009828",
                "metrs": 1
            },
            {
                "size": " 270х180х3",
                "koef": "0.0147",
                "metrs": 1
            },
            {
                "size": " 270х180х4",
                "koef": "0.01953",
                "metrs": 1
            },
            {
                "size": " 270х180х5",
                "koef": "0.02434",
                "metrs": 1
            },
            {
                "size": " 270х180х6",
                "koef": "0.02911",
                "metrs": 1
            },
            {
                "size": " 270х180х8",
                "koef": "0.03856",
                "metrs": 1
            },
            {
                "size": " 270х180х10",
                "koef": "0.04789",
                "metrs": 1
            },
            {
                "size": " 270х200х2",
                "koef": "0.01046",
                "metrs": 1
            },
            {
                "size": " 270х200х3",
                "koef": "0.01564",
                "metrs": 1
            },
            {
                "size": " 270х200х4",
                "koef": "0.02079",
                "metrs": 1
            },
            {
                "size": " 270х200х5",
                "koef": "0.0259",
                "metrs": 1
            },
            {
                "size": " 270х200х6",
                "koef": "0.03099",
                "metrs": 1
            },
            {
                "size": " 270х200х8",
                "koef": "0.04107",
                "metrs": 1
            },
            {
                "size": " 270х200х10",
                "koef": "0.05103",
                "metrs": 1
            },
            {
                "size": " 270х220х2",
                "koef": "0.01108",
                "metrs": 1
            },
            {
                "size": " 270х220х3",
                "koef": "0.01658",
                "metrs": 1
            },
            {
                "size": " 270х220х4",
                "koef": "0.02204",
                "metrs": 1
            },
            {
                "size": " 270х220х5",
                "koef": "0.02748",
                "metrs": 1
            },
            {
                "size": " 270х220х6",
                "koef": "0.03288",
                "metrs": 1
            },
            {
                "size": " 270х220х8",
                "koef": "0.04358",
                "metrs": 1
            },
            {
                "size": " 270х220х10",
                "koef": "0.05417",
                "metrs": 1
            },
            {
                "size": " 270х230х2",
                "koef": "0.0114",
                "metrs": 1
            },
            {
                "size": " 270х230х3",
                "koef": "0.01705",
                "metrs": 1
            },
            {
                "size": " 270х230х4",
                "koef": "0.02267",
                "metrs": 1
            },
            {
                "size": " 270х230х5",
                "koef": "0.02826",
                "metrs": 1
            },
            {
                "size": " 270х230х6",
                "koef": "0.03382",
                "metrs": 1
            },
            {
                "size": " 270х230х8",
                "koef": "0.04484",
                "metrs": 1
            },
            {
                "size": " 270х230х10",
                "koef": "0.05573",
                "metrs": 1
            },
            {
                "size": " 270х240х2",
                "koef": "0.01171",
                "metrs": 1
            },
            {
                "size": " 270х240х3",
                "koef": "0.01752",
                "metrs": 1
            },
            {
                "size": " 270х240х4",
                "koef": "0.0233",
                "metrs": 1
            },
            {
                "size": " 270х240х5",
                "koef": "0.02905",
                "metrs": 1
            },
            {
                "size": " 270х240х6",
                "koef": "0.03476",
                "metrs": 1
            },
            {
                "size": " 270х240х8",
                "koef": "0.0461",
                "metrs": 1
            },
            {
                "size": " 270х240х10",
                "koef": "0.0573",
                "metrs": 1
            },
            {
                "size": " 280х40х2",
                "koef": "0.005589",
                "metrs": 1
            },
            {
                "size": " 280х40х3",
                "koef": "0.008337",
                "metrs": 1
            },
            {
                "size": " 280х40х4",
                "koef": "0.01105",
                "metrs": 1
            },
            {
                "size": " 280х40х5",
                "koef": "0.01374",
                "metrs": 1
            },
            {
                "size": " 280х40х6",
                "koef": "0.01639",
                "metrs": 1
            },
            {
                "size": " 280х50х2",
                "koef": "0.005903",
                "metrs": 1
            },
            {
                "size": " 280х50х3",
                "koef": "0.008808",
                "metrs": 1
            },
            {
                "size": " 280х50х4",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 280х50х5",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": " 280х50х6",
                "koef": "0.01733",
                "metrs": 1
            },
            {
                "size": " 280х60х2",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 280х60х3",
                "koef": "0.009279",
                "metrs": 1
            },
            {
                "size": " 280х60х3,9",
                "koef": "0.0118",
                "metrs": 1
            },
            {
                "size": " 280х60х4",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": " 280х60х5",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": " 280х60х6",
                "koef": "0.01758",
                "metrs": 1
            },
            {
                "size": " 280х65х2",
                "koef": "0.006374",
                "metrs": 1
            },
            {
                "size": " 280х65х3",
                "koef": "0.009514",
                "metrs": 1
            },
            {
                "size": " 280х65х4",
                "koef": "0.01262",
                "metrs": 1
            },
            {
                "size": " 280х65х5",
                "koef": "0.0157",
                "metrs": 1
            },
            {
                "size": " 280х65х6",
                "koef": "0.01875",
                "metrs": 1
            },
            {
                "size": " 280х68х8",
                "koef": "0.02512",
                "metrs": 1
            },
            {
                "size": " 280х70х2",
                "koef": "0.006531",
                "metrs": 1
            },
            {
                "size": " 280х70х3",
                "koef": "0.00975",
                "metrs": 1
            },
            {
                "size": " 280х70х4",
                "koef": "0.01294",
                "metrs": 1
            },
            {
                "size": " 280х70х5",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": " 280х70х6",
                "koef": "0.01922",
                "metrs": 1
            },
            {
                "size": " 280х70х8",
                "koef": "0.02537",
                "metrs": 1
            },
            {
                "size": " 280х70х10",
                "koef": "0.0314",
                "metrs": 1
            },
            {
                "size": " 280х80х2",
                "koef": "0.006845",
                "metrs": 1
            },
            {
                "size": " 280х80х3",
                "koef": "0.01022",
                "metrs": 1
            },
            {
                "size": " 280х80х4",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 280х80х5",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": " 280х80х6",
                "koef": "0.02016",
                "metrs": 1
            },
            {
                "size": " 280х80х8",
                "koef": "0.02663",
                "metrs": 1
            },
            {
                "size": " 280х80х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": " 280х90х2",
                "koef": "0.007159",
                "metrs": 1
            },
            {
                "size": " 280х90х3",
                "koef": "0.01069",
                "metrs": 1
            },
            {
                "size": " 280х90х4",
                "koef": "0.01419",
                "metrs": 1
            },
            {
                "size": " 280х90х5",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": " 280х90х6",
                "koef": "0.0211",
                "metrs": 1
            },
            {
                "size": " 280х90х8",
                "koef": "0.02788",
                "metrs": 1
            },
            {
                "size": " 280х90х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": " 280х100х2",
                "koef": "0.007473",
                "metrs": 1
            },
            {
                "size": " 280х100х3",
                "koef": "0.01116",
                "metrs": 1
            },
            {
                "size": " 280х100х4",
                "koef": "0.01482",
                "metrs": 1
            },
            {
                "size": " 280х100х5",
                "koef": "0.01845",
                "metrs": 1
            },
            {
                "size": " 280х100х6",
                "koef": "0.02204",
                "metrs": 1
            },
            {
                "size": " 280х100х8",
                "koef": "0.02914",
                "metrs": 1
            },
            {
                "size": " 280х100х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": " 280х110х2",
                "koef": "0.007787",
                "metrs": 1
            },
            {
                "size": " 280х110х3",
                "koef": "0.01163",
                "metrs": 1
            },
            {
                "size": " 280х110х4",
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": " 280х110х5",
                "koef": "0.01923",
                "metrs": 1
            },
            {
                "size": " 280х110х6",
                "koef": "0.02298",
                "metrs": 1
            },
            {
                "size": " 280х110х8",
                "koef": "0.0304",
                "metrs": 1
            },
            {
                "size": " 280х110х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": " 280х120х2",
                "koef": "0.008101",
                "metrs": 1
            },
            {
                "size": " 280х120х3",
                "koef": "0.0121",
                "metrs": 1
            },
            {
                "size": " 280х120х4",
                "koef": "0.01608",
                "metrs": 1
            },
            {
                "size": " 280х120х5",
                "koef": "0.02002",
                "metrs": 1
            },
            {
                "size": " 280х120х6",
                "koef": "0.02393",
                "metrs": 1
            },
            {
                "size": " 280х120х8",
                "koef": "0.03165",
                "metrs": 1
            },
            {
                "size": " 280х120х10",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": " 280х130х2",
                "koef": "0.008415",
                "metrs": 1
            },
            {
                "size": " 280х130х3",
                "koef": "0.01258",
                "metrs": 1
            },
            {
                "size": " 280х130х4",
                "koef": "0.0167",
                "metrs": 1
            },
            {
                "size": " 280х130х5",
                "koef": "0.0208",
                "metrs": 1
            },
            {
                "size": " 280х130х6",
                "koef": "0.02487",
                "metrs": 1
            },
            {
                "size": " 280х130х8",
                "koef": "0.03291",
                "metrs": 1
            },
            {
                "size": " 280х130х10",
                "koef": "0.04082",
                "metrs": 1
            },
            {
                "size": " 280х140х2",
                "koef": "0.008729",
                "metrs": 1
            },
            {
                "size": " 280х140х3",
                "koef": "0.01305",
                "metrs": 1
            },
            {
                "size": " 280х140х4",
                "koef": "0.01733",
                "metrs": 1
            },
            {
                "size": " 280х140х5",
                "koef": "0.02127",
                "metrs": 1
            },
            {
                "size": " 280х140х6",
                "koef": "0.02581",
                "metrs": 1
            },
            {
                "size": " 280х140х8",
                "koef": "0.03416",
                "metrs": 1
            },
            {
                "size": " 280х140х10",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": " 280х150х2",
                "koef": "0.009043",
                "metrs": 1
            },
            {
                "size": " 280х150х3",
                "koef": "0.01352",
                "metrs": 1
            },
            {
                "size": " 280х150х4",
                "koef": "0.01796",
                "metrs": 1
            },
            {
                "size": " 280х150х5",
                "koef": "0.02237",
                "metrs": 1
            },
            {
                "size": " 280х150х6",
                "koef": "0.02675",
                "metrs": 1
            },
            {
                "size": " 280х150х8",
                "koef": "0.03542",
                "metrs": 1
            },
            {
                "size": " 280х150х10",
                "koef": "0.04396",
                "metrs": 1
            },
            {
                "size": " 280х160х2",
                "koef": "0.009357",
                "metrs": 1
            },
            {
                "size": " 280х160х3",
                "koef": "0.01399",
                "metrs": 1
            },
            {
                "size": " 280х160х4",
                "koef": "0.01859",
                "metrs": 1
            },
            {
                "size": " 280х160х5",
                "koef": "0.02316",
                "metrs": 1
            },
            {
                "size": " 280х160х6",
                "koef": "0.02769",
                "metrs": 1
            },
            {
                "size": " 280х160х8",
                "koef": "0.03668",
                "metrs": 1
            },
            {
                "size": " 280х160х10",
                "koef": "0.04553",
                "metrs": 1
            },
            {
                "size": " 280х170х2",
                "koef": "0.009671",
                "metrs": 1
            },
            {
                "size": " 280х170х3",
                "koef": "0.01446",
                "metrs": 1
            },
            {
                "size": " 280х170х4",
                "koef": "0.01922",
                "metrs": 1
            },
            {
                "size": " 280х170х5",
                "koef": "0.02394",
                "metrs": 1
            },
            {
                "size": " 280х170х6",
                "koef": "0.02864",
                "metrs": 1
            },
            {
                "size": " 280х170х8",
                "koef": "0.03793",
                "metrs": 1
            },
            {
                "size": " 280х170х10",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": " 280х180х2",
                "koef": "0.009985",
                "metrs": 1
            },
            {
                "size": " 280х180х3",
                "koef": "0.01493",
                "metrs": 1
            },
            {
                "size": " 280х180х4",
                "koef": "0.01984",
                "metrs": 1
            },
            {
                "size": " 280х180х5",
                "koef": "0.02473",
                "metrs": 1
            },
            {
                "size": " 280х180х6",
                "koef": "0.02958",
                "metrs": 1
            },
            {
                "size": " 280х180х8",
                "koef": "0.03919",
                "metrs": 1
            },
            {
                "size": " 280х180х10",
                "koef": "0.04867",
                "metrs": 1
            },
            {
                "size": " 280х200х2",
                "koef": "0.01061",
                "metrs": 1
            },
            {
                "size": " 280х200х3",
                "koef": "0.01587",
                "metrs": 1
            },
            {
                "size": " 280х200х4",
                "koef": "0.0211",
                "metrs": 1
            },
            {
                "size": " 280х200х5",
                "koef": "0.0263",
                "metrs": 1
            },
            {
                "size": " 280х200х6",
                "koef": "0.03146",
                "metrs": 1
            },
            {
                "size": " 280х200х8",
                "koef": "0.0417",
                "metrs": 1
            },
            {
                "size": " 280х200х10",
                "koef": "0.05181",
                "metrs": 1
            },
            {
                "size": " 280х220х2",
                "koef": "0.01124",
                "metrs": 1
            },
            {
                "size": " 280х220х3",
                "koef": "0.01681",
                "metrs": 1
            },
            {
                "size": " 280х220х4",
                "koef": "0.02236",
                "metrs": 1
            },
            {
                "size": " 280х220х5",
                "koef": "0.02787",
                "metrs": 1
            },
            {
                "size": " 280х220х6",
                "koef": "0.03335",
                "metrs": 1
            },
            {
                "size": " 280х220х8",
                "koef": "0.04421",
                "metrs": 1
            },
            {
                "size": " 280х220х10",
                "koef": "0.05495",
                "metrs": 1
            },
            {
                "size": " 280х230х2",
                "koef": "0.01156",
                "metrs": 1
            },
            {
                "size": " 280х230х3",
                "koef": "0.01729",
                "metrs": 1
            },
            {
                "size": " 280х230х4",
                "koef": "0.02298",
                "metrs": 1
            },
            {
                "size": " 280х230х5",
                "koef": "0.02865",
                "metrs": 1
            },
            {
                "size": " 280х230х6",
                "koef": "0.03429",
                "metrs": 1
            },
            {
                "size": " 280х230х8",
                "koef": "0.04547",
                "metrs": 1
            },
            {
                "size": " 280х230х10",
                "koef": "0.05652",
                "metrs": 1
            },
            {
                "size": " 280х240х2",
                "koef": "0.01187",
                "metrs": 1
            },
            {
                "size": " 280х240х3",
                "koef": "0.01776",
                "metrs": 1
            },
            {
                "size": " 280х240х4",
                "koef": "0.02361",
                "metrs": 1
            },
            {
                "size": " 280х240х5",
                "koef": "0.02944",
                "metrs": 1
            },
            {
                "size": " 280х240х6",
                "koef": "0.03523",
                "metrs": 1
            },
            {
                "size": " 280х240х8",
                "koef": "0.04672",
                "metrs": 1
            },
            {
                "size": " 280х240х10",
                "koef": "0.05809",
                "metrs": 1
            },
            {
                "size": " 300х40х2",
                "koef": "0.005903",
                "metrs": 1
            },
            {
                "size": " 300х40х3",
                "koef": "0.008808",
                "metrs": 1
            },
            {
                "size": " 300х40х4",
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": " 300х40х5",
                "koef": "0.01452",
                "metrs": 1
            },
            {
                "size": " 300х40х6",
                "koef": "0.01733",
                "metrs": 1
            },
            {
                "size": " 300х50х2",
                "koef": "0.006217",
                "metrs": 1
            },
            {
                "size": " 300х50х3",
                "koef": "0.009279",
                "metrs": 1
            },
            {
                "size": " 300х50х4",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": " 300х50х5",
                "koef": "0.01531",
                "metrs": 1
            },
            {
                "size": " 300х50х6",
                "koef": "0.01827",
                "metrs": 1
            },
            {
                "size": " 300х60х2",
                "koef": "0.006531",
                "metrs": 1
            },
            {
                "size": " 300х60х3",
                "koef": "0.00975",
                "metrs": 1
            },
            {
                "size": " 300х60х4",
                "koef": "0.01294",
                "metrs": 1
            },
            {
                "size": " 300х60х5",
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": " 300х60х6",
                "koef": "0.01922",
                "metrs": 1
            },
            {
                "size": " 300х65х2",
                "koef": "0.006688",
                "metrs": 1
            },
            {
                "size": " 300х65х3",
                "koef": "0.009985",
                "metrs": 1
            },
            {
                "size": " 300х65х4",
                "koef": "0.01325",
                "metrs": 1
            },
            {
                "size": " 300х65х5",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": " 300х65х6",
                "koef": "0.01969",
                "metrs": 1
            },
            {
                "size": " 300х68х8",
                "koef": "0.02638",
                "metrs": 1
            },
            {
                "size": " 300х70х2",
                "koef": "0.006845",
                "metrs": 1
            },
            {
                "size": " 300х70х3",
                "koef": "0.01022",
                "metrs": 1
            },
            {
                "size": " 300х70х4",
                "koef": "0.01356",
                "metrs": 1
            },
            {
                "size": " 300х70х5",
                "koef": "0.01688",
                "metrs": 1
            },
            {
                "size": " 300х70х6",
                "koef": "0.02016",
                "metrs": 1
            },
            {
                "size": " 300х70х8",
                "koef": "0.02663",
                "metrs": 1
            },
            {
                "size": " 300х70х10",
                "koef": "0.03297",
                "metrs": 1
            },
            {
                "size": " 300х80х2",
                "koef": "0.007159",
                "metrs": 1
            },
            {
                "size": " 300х80х3",
                "koef": "0.01069",
                "metrs": 1
            },
            {
                "size": " 300х80х4",
                "koef": "0.01419",
                "metrs": 1
            },
            {
                "size": " 300х80х5",
                "koef": "0.01766",
                "metrs": 1
            },
            {
                "size": " 300х80х6",
                "koef": "0.02062",
                "metrs": 1
            },
            {
                "size": " 300х80х8",
                "koef": "0.02788",
                "metrs": 1
            },
            {
                "size": " 300х80х10",
                "koef": "0.03454",
                "metrs": 1
            },
            {
                "size": " 300х80х40х4",
                "koef": "0.01272",
                "metrs": 1
            },
            {
                "size": " 300х80х40х5",
                "koef": "0.01577",
                "metrs": 1
            },
            {
                "size": " 300х90х2",
                "koef": "0.007473",
                "metrs": 1
            },
            {
                "size": " 300х90х3",
                "koef": "0.01116",
                "metrs": 1
            },
            {
                "size": " 300х90х4",
                "koef": "0.01482",
                "metrs": 1
            },
            {
                "size": " 300х90х5",
                "koef": "0.01845",
                "metrs": 1
            },
            {
                "size": " 300х90х6",
                "koef": "0.02204",
                "metrs": 1
            },
            {
                "size": " 300х90х8",
                "koef": "0.02914",
                "metrs": 1
            },
            {
                "size": " 300х90х10",
                "koef": "0.03611",
                "metrs": 1
            },
            {
                "size": " 300х100х2",
                "koef": "0.007787",
                "metrs": 1
            },
            {
                "size": " 300х100х3",
                "koef": "0.01163",
                "metrs": 1
            },
            {
                "size": " 300х100х4",
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": " 300х100х5",
                "koef": "0.01923",
                "metrs": 1
            },
            {
                "size": " 300х100х6",
                "koef": "0.02277",
                "metrs": 1
            },
            {
                "size": " 300х100х8",
                "koef": "0.02953",
                "metrs": 1
            },
            {
                "size": " 300х100х10",
                "koef": "0.03768",
                "metrs": 1
            },
            {
                "size": " 300х110х2",
                "koef": "0.008101",
                "metrs": 1
            },
            {
                "size": " 300х110х3",
                "koef": "0.0121",
                "metrs": 1
            },
            {
                "size": " 300х110х4",
                "koef": "0.01608",
                "metrs": 1
            },
            {
                "size": " 300х110х5",
                "koef": "0.02002",
                "metrs": 1
            },
            {
                "size": " 300х110х6",
                "koef": "0.02393",
                "metrs": 1
            },
            {
                "size": " 300х110х8",
                "koef": "0.03165",
                "metrs": 1
            },
            {
                "size": " 300х110х10",
                "koef": "0.03925",
                "metrs": 1
            },
            {
                "size": " 300х120х2",
                "koef": "0.008415",
                "metrs": 1
            },
            {
                "size": " 300х120х3",
                "koef": "0.01258",
                "metrs": 1
            },
            {
                "size": " 300х120х4",
                "koef": "0.0167",
                "metrs": 1
            },
            {
                "size": " 300х120х5",
                "koef": "0.0208",
                "metrs": 1
            },
            {
                "size": " 300х120х6",
                "koef": "0.02487",
                "metrs": 1
            },
            {
                "size": " 300х120х8",
                "koef": "0.03291",
                "metrs": 1
            },
            {
                "size": " 300х120х10",
                "koef": "0.04082",
                "metrs": 1
            },
            {
                "size": " 300х125х6",
                "koef": "0.02534",
                "metrs": 1
            },
            {
                "size": " 300х125х8",
                "koef": "0.03354",
                "metrs": 1
            },
            {
                "size": " 300х130х2",
                "koef": "0.008729",
                "metrs": 1
            },
            {
                "size": " 300х130х3",
                "koef": "0.01305",
                "metrs": 1
            },
            {
                "size": " 300х130х4",
                "koef": "0.01733",
                "metrs": 1
            },
            {
                "size": " 300х130х5",
                "koef": "0.02159",
                "metrs": 1
            },
            {
                "size": " 300х130х6",
                "koef": "0.02581",
                "metrs": 1
            },
            {
                "size": " 300х130х8",
                "koef": "0.03416",
                "metrs": 1
            },
            {
                "size": " 300х130х10",
                "koef": "0.04239",
                "metrs": 1
            },
            {
                "size": " 300х140х2",
                "koef": "0.009043",
                "metrs": 1
            },
            {
                "size": " 300х140х3",
                "koef": "0.01352",
                "metrs": 1
            },
            {
                "size": " 300х140х4",
                "koef": "0.01796",
                "metrs": 1
            },
            {
                "size": " 300х140х5",
                "koef": "0.02237",
                "metrs": 1
            },
            {
                "size": " 300х140х6",
                "koef": "0.02675",
                "metrs": 1
            },
            {
                "size": " 300х140х8",
                "koef": "0.03542",
                "metrs": 1
            },
            {
                "size": " 300х140х10",
                "koef": "0.04396",
                "metrs": 1
            },
            {
                "size": " 300х150х2",
                "koef": "0.009357",
                "metrs": 1
            },
            {
                "size": " 300х150х3",
                "koef": "0.01399",
                "metrs": 1
            },
            {
                "size": " 300х150х4",
                "koef": "0.01859",
                "metrs": 1
            },
            {
                "size": " 300х150х5",
                "koef": "0.02316",
                "metrs": 1
            },
            {
                "size": " 300х150х6",
                "koef": "0.02769",
                "metrs": 1
            },
            {
                "size": " 300х150х8",
                "koef": "0.03668",
                "metrs": 1
            },
            {
                "size": " 300х150х10",
                "koef": "0.04553",
                "metrs": 1
            },
            {
                "size": " 300х160х2",
                "koef": "0.009671",
                "metrs": 1
            },
            {
                "size": " 300х160х3",
                "koef": "0.01446",
                "metrs": 1
            },
            {
                "size": " 300х160х4",
                "koef": "0.01922",
                "metrs": 1
            },
            {
                "size": " 300х160х5",
                "koef": "0.02394",
                "metrs": 1
            },
            {
                "size": " 300х160х6",
                "koef": "0.02864",
                "metrs": 1
            },
            {
                "size": " 300х160х8",
                "koef": "0.03793",
                "metrs": 1
            },
            {
                "size": " 300х160х10",
                "koef": "0.0471",
                "metrs": 1
            },
            {
                "size": " 300х170х2",
                "koef": "0.009985",
                "metrs": 1
            },
            {
                "size": " 300х170х3",
                "koef": "0.01493",
                "metrs": 1
            },
            {
                "size": " 300х170х4",
                "koef": "0.01984",
                "metrs": 1
            },
            {
                "size": " 300х170х5",
                "koef": "0.02473",
                "metrs": 1
            },
            {
                "size": " 300х170х6",
                "koef": "0.02958",
                "metrs": 1
            },
            {
                "size": " 300х170х8",
                "koef": "0.03919",
                "metrs": 1
            },
            {
                "size": " 300х170х10",
                "koef": "0.04867",
                "metrs": 1
            },
            {
                "size": " 300х180х2",
                "koef": "0.0103",
                "metrs": 1
            },
            {
                "size": " 300х180х3",
                "koef": "0.0154",
                "metrs": 1
            },
            {
                "size": " 300х180х4",
                "koef": "0.02047",
                "metrs": 1
            },
            {
                "size": " 300х180х5",
                "koef": "0.02551",
                "metrs": 1
            },
            {
                "size": " 300х180х6",
                "koef": "0.03052",
                "metrs": 1
            },
            {
                "size": " 300х180х8",
                "koef": "0.04044",
                "metrs": 1
            },
            {
                "size": " 300х180х10",
                "koef": "0.05024",
                "metrs": 1
            },
            {
                "size": " 300х200х2",
                "koef": "0.01093",
                "metrs": 1
            },
            {
                "size": " 300х200х3",
                "koef": "0.01634",
                "metrs": 1
            },
            {
                "size": " 300х200х4",
                "koef": "0.02173",
                "metrs": 1
            },
            {
                "size": " 300х200х5",
                "koef": "0.02708",
                "metrs": 1
            },
            {
                "size": " 300х200х6",
                "koef": "0.0324",
                "metrs": 1
            },
            {
                "size": " 300х200х8",
                "koef": "0.04296",
                "metrs": 1
            },
            {
                "size": " 300х200х10",
                "koef": "0.05338",
                "metrs": 1
            },
            {
                "size": " 300х220х2",
                "koef": "0.01156",
                "metrs": 1
            },
            {
                "size": " 300х220х3",
                "koef": "0.01729",
                "metrs": 1
            },
            {
                "size": " 300х220х4",
                "koef": "0.02298",
                "metrs": 1
            },
            {
                "size": " 300х220х5",
                "koef": "0.02865",
                "metrs": 1
            },
            {
                "size": " 300х220х6",
                "koef": "0.03429",
                "metrs": 1
            },
            {
                "size": " 300х220х8",
                "koef": "0.04547",
                "metrs": 1
            },
            {
                "size": " 300х220х10",
                "koef": "0.05652",
                "metrs": 1
            },
            {
                "size": " 300х230х2",
                "koef": "0.01187",
                "metrs": 1
            },
            {
                "size": " 300х230х3",
                "koef": "0.01776",
                "metrs": 1
            },
            {
                "size": " 300х230х4",
                "koef": "0.02361",
                "metrs": 1
            },
            {
                "size": " 300х230х5",
                "koef": "0.02944",
                "metrs": 1
            },
            {
                "size": " 300х230х6",
                "koef": "0.03523",
                "metrs": 1
            },
            {
                "size": " 300х230х8",
                "koef": "0.04672",
                "metrs": 1
            },
            {
                "size": " 300х230х10",
                "koef": "0.05809",
                "metrs": 1
            },
            {
                "size": " 300х240х2",
                "koef": "0.01218",
                "metrs": 1
            },
            {
                "size": " 300х240х3",
                "koef": "0.01823",
                "metrs": 1
            },
            {
                "size": " 300х240х4",
                "koef": "0.02424",
                "metrs": 1
            },
            {
                "size": " 300х240х5",
                "koef": "0.03022",
                "metrs": 1
            },
            {
                "size": " 300х240х6",
                "koef": "0.03617",
                "metrs": 1
            },
            {
                "size": " 300х240х8",
                "koef": "0.04798",
                "metrs": 1
            },
            {
                "size": " 300х240х10",
                "koef": "0.05966",
                "metrs": 1
            },
            {
                "size": " 310х100х3",
                "koef": "0.01187",
                "metrs": 1
            },
            {
                "size": " 310х100х4",
                "koef": "0.01576",
                "metrs": 1
            },
            {
                "size": " 310х100х5",
                "koef": "0.01963",
                "metrs": 1
            },
            {
                "size": " 310х100х6",
                "koef": "0.02297",
                "metrs": 1
            },
            {
                "size": " 330х105х8",
                "koef": "0.03291",
                "metrs": 1
            },
            {
                "size": " 360х110х8",
                "koef": "0.03542",
                "metrs": 1
            },
            {
                "size": " 360х110х10",
                "koef": "0.04396",
                "metrs": 1
            },
            {
                "size": " 380х65х6",
                "koef": "0.02297",
                "metrs": 1
            },
            {
                "size": " 400х95х8",
                "koef": "0.03518",
                "metrs": 1
            },
            {
                "size": " 400х115х8",
                "koef": "0.03856",
                "metrs": 1
            },
            {
                "size": " 400х115х12",
                "koef": "0.05709",
                "metrs": 1
            },
            {
                "size": " 410х65х6",
                "koef": "0.02438",
                "metrs": 1
            }
        ],
        "Швеллер гнутый оцинкованный": [
            {
                "size": " 20х15х1",
                "koef": "0.000388",
                "metrs": 1
            },
            {
                "size": " 20х15х1,2",
                "koef": "0.000462",
                "metrs": 1
            },
            {
                "size": " 20х15х1,5",
                "koef": "0.00057",
                "metrs": 1
            },
            {
                "size": " 20х15х1,8",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": " 20х15х2",
                "koef": "0.000744",
                "metrs": 1
            },
            {
                "size": "20х20х1",
                "koef": "0.000469",
                "metrs": 1
            },
            {
                "size": "20х20х1,2",
                "koef": "0.000559",
                "metrs": 1
            },
            {
                "size": "20х20х1,5",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 20х20х1,8",
                "koef": "0.000821",
                "metrs": 1
            },
            {
                "size": " 20х20х2",
                "koef": "0.000906",
                "metrs": 1
            },
            {
                "size": " 20х25х1",
                "koef": "0.00055",
                "metrs": 1
            },
            {
                "size": " 20х25х1,2",
                "koef": "0.000656",
                "metrs": 1
            },
            {
                "size": "20х25х1,5",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 20х25х1,8",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 20х25х2",
                "koef": "0.001067",
                "metrs": 1
            },
            {
                "size": "20х30х1",
                "koef": "0.000631",
                "metrs": 1
            },
            {
                "size": " 20х30х1,2",
                "koef": "0.000753",
                "metrs": 1
            },
            {
                "size": " 20х30х1,5",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": " 20х30х1,8",
                "koef": "0.001112",
                "metrs": 1
            },
            {
                "size": " 20х30х2",
                "koef": "0.001229",
                "metrs": 1
            },
            {
                "size": "Швеллер гнутый  оцинкованный 20х35х1",
                "koef": "0.000712",
                "metrs": 1
            },
            {
                "size": " 20х35х1,2",
                "koef": "0.00085",
                "metrs": 1
            },
            {
                "size": " 20х35х1,5",
                "koef": "0.001055",
                "metrs": 1
            },
            {
                "size": " 20х35х1,8",
                "koef": "0.001257",
                "metrs": 1
            },
            {
                "size": " 20х35х2",
                "koef": "0.001391",
                "metrs": 1
            },
            {
                "size": " 20х40х1",
                "koef": "0.000792",
                "metrs": 1
            },
            {
                "size": " 20х40х1,2",
                "koef": "0.000947",
                "metrs": 1
            },
            {
                "size": " 20х40х1,5",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": " 20х40х1,8",
                "koef": "0.001403",
                "metrs": 1
            },
            {
                "size": " 20х40х2",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": " 20х45х1",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 20х45х1,2",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 20х45х1,5",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 20х45х1,8",
                "koef": "0.001549",
                "metrs": 1
            },
            {
                "size": " 20х45х2",
                "koef": "0.001714",
                "metrs": 1
            },
            {
                "size": " 20х50х1",
                "koef": "0.000954",
                "metrs": 1
            },
            {
                "size": " 20х50х1,2",
                "koef": "0.001141",
                "metrs": 1
            },
            {
                "size": " 20х50х1,5",
                "koef": "0.001419",
                "metrs": 1
            },
            {
                "size": " 20х50х1,8",
                "koef": "0.001694",
                "metrs": 1
            },
            {
                "size": " 20х50х2",
                "koef": "0.001876",
                "metrs": 1
            },
            {
                "size": " 20х55х1",
                "koef": "0.001035",
                "metrs": 1
            },
            {
                "size": " 20х55х1,2",
                "koef": "0.001238",
                "metrs": 1
            },
            {
                "size": " 20х55х1,5",
                "koef": "0.00154",
                "metrs": 1
            },
            {
                "size": " 20х55х1,8",
                "koef": "0.00184",
                "metrs": 1
            },
            {
                "size": " 20х55х2",
                "koef": "0.002038",
                "metrs": 1
            },
            {
                "size": " 20х60х1",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 20х60х1,2",
                "koef": "0.001335",
                "metrs": 1
            },
            {
                "size": " 20х60х1,5",
                "koef": "0.001662",
                "metrs": 1
            },
            {
                "size": " 20х60х1,8",
                "koef": "0.001985",
                "metrs": 1
            },
            {
                "size": " 20х60х2",
                "koef": "0.002199",
                "metrs": 1
            },
            {
                "size": " 25х15х1",
                "koef": "0.000429",
                "metrs": 1
            },
            {
                "size": " 25х15х1,2",
                "koef": "0.00051",
                "metrs": 1
            },
            {
                "size": " 25х15х1,5",
                "koef": "0.000631",
                "metrs": 1
            },
            {
                "size": " 25х15х1,8",
                "koef": "0.000748",
                "metrs": 1
            },
            {
                "size": " 25х15х2",
                "koef": "0.000825",
                "metrs": 1
            },
            {
                "size": " 25х20х1",
                "koef": "0.000509",
                "metrs": 1
            },
            {
                "size": " 25х20х1,2",
                "koef": "0.000607",
                "metrs": 1
            },
            {
                "size": " 25х20х1,5",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": " 25х20х1,8",
                "koef": "0.000894",
                "metrs": 1
            },
            {
                "size": " 25х20х2",
                "koef": "0.000986",
                "metrs": 1
            },
            {
                "size": " 25х25х1",
                "koef": "0.00059",
                "metrs": 1
            },
            {
                "size": " 25х25х1,2",
                "koef": "0.000704",
                "metrs": 1
            },
            {
                "size": " 25х25х1,5",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 25х25х1,8",
                "koef": "0.001039",
                "metrs": 1
            },
            {
                "size": " 25х25х2",
                "koef": "0.001148",
                "metrs": 1
            },
            {
                "size": " 25х30х1",
                "koef": "0.000671",
                "metrs": 1
            },
            {
                "size": " 25х30х1,2",
                "koef": "0.000801",
                "metrs": 1
            },
            {
                "size": " 25х30х1,5",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 25х30х1,8",
                "koef": "0.001185",
                "metrs": 1
            },
            {
                "size": " 25х30х2",
                "koef": "0.00131",
                "metrs": 1
            },
            {
                "size": " 25х35х1",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": " 25х35х1,2",
                "koef": "0.000898",
                "metrs": 1
            },
            {
                "size": " 25х35х1,5",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 25х35х1,8",
                "koef": "0.00133",
                "metrs": 1
            },
            {
                "size": " 25х35х2",
                "koef": "0.001472",
                "metrs": 1
            },
            {
                "size": " 25х40х1",
                "koef": "0.000833",
                "metrs": 1
            },
            {
                "size": " 25х40х1,2",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 25х40х1,5",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 25х40х1,8",
                "koef": "0.001476",
                "metrs": 1
            },
            {
                "size": " 25х40х2",
                "koef": "0.001633",
                "metrs": 1
            },
            {
                "size": " 25х45х1",
                "koef": "0.000914",
                "metrs": 1
            },
            {
                "size": " 25х45х1,2",
                "koef": "0.001093",
                "metrs": 1
            },
            {
                "size": " 25х45х1,5",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 25х45х1,8",
                "koef": "0.001621",
                "metrs": 1
            },
            {
                "size": " 25х45х2",
                "koef": "0.001795",
                "metrs": 1
            },
            {
                "size": " 25х50х1",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 25х50х1,2",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 25х50х1,5",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 25х50х1,8",
                "koef": "0.001767",
                "metrs": 1
            },
            {
                "size": " 25х50х2",
                "koef": "0.001957",
                "metrs": 1
            },
            {
                "size": " 25х55х1",
                "koef": "0.001075",
                "metrs": 1
            },
            {
                "size": " 25х55х1,2",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": " 25х55х1,5",
                "koef": "0.001601",
                "metrs": 1
            },
            {
                "size": " 25х55х1,8",
                "koef": "0.001912",
                "metrs": 1
            },
            {
                "size": " 25х55х2",
                "koef": "0.002118",
                "metrs": 1
            },
            {
                "size": " 25х60х1",
                "koef": "0.001156",
                "metrs": 1
            },
            {
                "size": " 25х60х1,2",
                "koef": "0.001384",
                "metrs": 1
            },
            {
                "size": " 25х60х1,5",
                "koef": "0.001722",
                "metrs": 1
            },
            {
                "size": " 25х60х1,8",
                "koef": "0.002058",
                "metrs": 1
            },
            {
                "size": " 25х60х2",
                "koef": "0.00228",
                "metrs": 1
            },
            {
                "size": " 30х15х1",
                "koef": "0.000469",
                "metrs": 1
            },
            {
                "size": " 30х15х1,2",
                "koef": "0.000559",
                "metrs": 1
            },
            {
                "size": " 30х15х1,5",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 30х15х1,8",
                "koef": "0.000821",
                "metrs": 1
            },
            {
                "size": " 30х15х2",
                "koef": "0.000906",
                "metrs": 1
            },
            {
                "size": " 30х20х1",
                "koef": "0.00055",
                "metrs": 1
            },
            {
                "size": " 30х20х1,2",
                "koef": "0.000656",
                "metrs": 1
            },
            {
                "size": " 30х20х1,5",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 30х20х1,8",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 30х20х2",
                "koef": "0.001067",
                "metrs": 1
            },
            {
                "size": " 30х25х1",
                "koef": "0.000631",
                "metrs": 1
            },
            {
                "size": " 30х25х1,2",
                "koef": "0.000753",
                "metrs": 1
            },
            {
                "size": " 30х25х1,5",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": " 30х25х1,8",
                "koef": "0.001112",
                "metrs": 1
            },
            {
                "size": " 30х25х2",
                "koef": "0.001257",
                "metrs": 1
            },
            {
                "size": " 30х30х1",
                "koef": "0.000712",
                "metrs": 1
            },
            {
                "size": " 30х30х1,2",
                "koef": "0.00085",
                "metrs": 1
            },
            {
                "size": " 30х30х1,5",
                "koef": "0.001055",
                "metrs": 1
            },
            {
                "size": " 30х30х1,8",
                "koef": "0.001257",
                "metrs": 1
            },
            {
                "size": " 30х30х2",
                "koef": "0.001339",
                "metrs": 1
            },
            {
                "size": " 30х35х1",
                "koef": "0.000792",
                "metrs": 1
            },
            {
                "size": " 30х35х1,2",
                "koef": "0.000947",
                "metrs": 1
            },
            {
                "size": " 30х35х1,5",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": " 30х35х1,8",
                "koef": "0.001403",
                "metrs": 1
            },
            {
                "size": " 30х35х2",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": " 30х40х1",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 30х40х1,2",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 30х40х1,5",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 30х40х1,8",
                "koef": "0.001549",
                "metrs": 1
            },
            {
                "size": " 30х40х2",
                "koef": "0.001714",
                "metrs": 1
            },
            {
                "size": " 30х45х1",
                "koef": "0.000954",
                "metrs": 1
            },
            {
                "size": " 30х45х1,2",
                "koef": "0.001141",
                "metrs": 1
            },
            {
                "size": " 30х45х1,5",
                "koef": "0.001419",
                "metrs": 1
            },
            {
                "size": " 30х45х1,8",
                "koef": "0.001694",
                "metrs": 1
            },
            {
                "size": " 30х45х2",
                "koef": "0.001876",
                "metrs": 1
            },
            {
                "size": " 30х50х1",
                "koef": "0.001035",
                "metrs": 1
            },
            {
                "size": " 30х50х1,2",
                "koef": "0.001238",
                "metrs": 1
            },
            {
                "size": " 30х50х1,5",
                "koef": "0.00154",
                "metrs": 1
            },
            {
                "size": " 30х50х1,8",
                "koef": "0.00184",
                "metrs": 1
            },
            {
                "size": " 30х50х2",
                "koef": "0.002038",
                "metrs": 1
            },
            {
                "size": " 30х55х1",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 30х55х1,2",
                "koef": "0.001335",
                "metrs": 1
            },
            {
                "size": " 30х55х1,5",
                "koef": "0.001662",
                "metrs": 1
            },
            {
                "size": " 30х55х1,8",
                "koef": "0.001985",
                "metrs": 1
            },
            {
                "size": " 30х55х2",
                "koef": "0.002199",
                "metrs": 1
            },
            {
                "size": " 30х60х1",
                "koef": "0.001197",
                "metrs": 1
            },
            {
                "size": " 30х60х1,2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 30х60х1,5",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 30х60х1,8",
                "koef": "0.002131",
                "metrs": 1
            },
            {
                "size": " 30х60х2",
                "koef": "0.002361",
                "metrs": 1
            },
            {
                "size": " 35х15х1",
                "koef": "0.000509",
                "metrs": 1
            },
            {
                "size": " 35х15х1,2",
                "koef": "0.000607",
                "metrs": 1
            },
            {
                "size": " 35х15х1,5",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": " 35х15х1,8",
                "koef": "0.000894",
                "metrs": 1
            },
            {
                "size": " 35х15х2",
                "koef": "0.000986",
                "metrs": 1
            },
            {
                "size": " 35х20х1",
                "koef": "0.00059",
                "metrs": 1
            },
            {
                "size": " 35х20х1,2",
                "koef": "0.000704",
                "metrs": 1
            },
            {
                "size": " 35х20х1,5",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 35х20х1,8",
                "koef": "0.001039",
                "metrs": 1
            },
            {
                "size": " 35х20х2",
                "koef": "0.001148",
                "metrs": 1
            },
            {
                "size": " 35х25х1",
                "koef": "0.000671",
                "metrs": 1
            },
            {
                "size": " 35х25х1,2",
                "koef": "0.000801",
                "metrs": 1
            },
            {
                "size": " 35х25х1,5",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 35х25х1,8",
                "koef": "0.001185",
                "metrs": 1
            },
            {
                "size": " 35х25х2",
                "koef": "0.00131",
                "metrs": 1
            },
            {
                "size": " 35х30х1",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": " 35х30х1,2",
                "koef": "0.000898",
                "metrs": 1
            },
            {
                "size": " 35х30х1,5",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 35х30х1,8",
                "koef": "0.00133",
                "metrs": 1
            },
            {
                "size": " 35х30х2",
                "koef": "0.001472",
                "metrs": 1
            },
            {
                "size": " 35х35х1",
                "koef": "0.000833",
                "metrs": 1
            },
            {
                "size": " 35х35х1,2",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 35х35х1,5",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 35х35х1,8",
                "koef": "0.001476",
                "metrs": 1
            },
            {
                "size": " 35х35х2",
                "koef": "0.001633",
                "metrs": 1
            },
            {
                "size": " 35х40х1",
                "koef": "0.000914",
                "metrs": 1
            },
            {
                "size": " 35х40х1,2",
                "koef": "0.001093",
                "metrs": 1
            },
            {
                "size": " 35х40х1,5",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 35х40х1,8",
                "koef": "0.001621",
                "metrs": 1
            },
            {
                "size": " 35х40х2",
                "koef": "0.001795",
                "metrs": 1
            },
            {
                "size": " 35х45х1",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 35х45х1,2",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 35х45х1,5",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 35х45х1,8",
                "koef": "0.001767",
                "metrs": 1
            },
            {
                "size": " 35х45х2",
                "koef": "0.001957",
                "metrs": 1
            },
            {
                "size": " 35х50х1",
                "koef": "0.001075",
                "metrs": 1
            },
            {
                "size": " 35х50х1,2",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": " 35х50х1,5",
                "koef": "0.001601",
                "metrs": 1
            },
            {
                "size": " 35х50х1,8",
                "koef": "0.001912",
                "metrs": 1
            },
            {
                "size": " 35х50х2",
                "koef": "0.002118",
                "metrs": 1
            },
            {
                "size": " 35х55х1",
                "koef": "0.001156",
                "metrs": 1
            },
            {
                "size": " 35х55х1,2",
                "koef": "0.001384",
                "metrs": 1
            },
            {
                "size": " 35х55х1,5",
                "koef": "0.001722",
                "metrs": 1
            },
            {
                "size": " 35х55х1,8",
                "koef": "0.002058",
                "metrs": 1
            },
            {
                "size": " 35х55х2",
                "koef": "0.00228",
                "metrs": 1
            },
            {
                "size": " 35х60х1",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 35х60х1,2",
                "koef": "0.001481",
                "metrs": 1
            },
            {
                "size": " 35х60х1,5",
                "koef": "0.001843",
                "metrs": 1
            },
            {
                "size": " 35х60х1,8",
                "koef": "0.002203",
                "metrs": 1
            },
            {
                "size": " 35х60х2",
                "koef": "0.002442",
                "metrs": 1
            },
            {
                "size": " 40х15х1",
                "koef": "0.00055",
                "metrs": 1
            },
            {
                "size": " 40х15х1,2",
                "koef": "0.000656",
                "metrs": 1
            },
            {
                "size": " 40х15х1,5",
                "koef": "0.000813",
                "metrs": 1
            },
            {
                "size": " 40х15х1,8",
                "koef": "0.000966",
                "metrs": 1
            },
            {
                "size": " 40х15х2",
                "koef": "0.001067",
                "metrs": 1
            },
            {
                "size": " 40х20х1",
                "koef": "0.000631",
                "metrs": 1
            },
            {
                "size": " 40х20х1,2",
                "koef": "0.000753",
                "metrs": 1
            },
            {
                "size": " 40х20х1,5",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": " 40х20х1,8",
                "koef": "0.001112",
                "metrs": 1
            },
            {
                "size": " 40х20х2",
                "koef": "0.001174",
                "metrs": 1
            },
            {
                "size": " 40х25х1",
                "koef": "0.000712",
                "metrs": 1
            },
            {
                "size": " 40х25х1,2",
                "koef": "0.00085",
                "metrs": 1
            },
            {
                "size": " 40х25х1,5",
                "koef": "0.001055",
                "metrs": 1
            },
            {
                "size": " 40х25х1,8",
                "koef": "0.001257",
                "metrs": 1
            },
            {
                "size": " 40х25х2",
                "koef": "0.001391",
                "metrs": 1
            },
            {
                "size": " 40х30х1",
                "koef": "0.000792",
                "metrs": 1
            },
            {
                "size": " 40х30х1,2",
                "koef": "0.000947",
                "metrs": 1
            },
            {
                "size": " 40х30х1,5",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": " 40х30х1,8",
                "koef": "0.001403",
                "metrs": 1
            },
            {
                "size": " 40х30х2",
                "koef": "0.001494",
                "metrs": 1
            },
            {
                "size": " 40х35х1",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 40х35х1,2",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 40х35х1,5",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 40х35х1,8",
                "koef": "0.001549",
                "metrs": 1
            },
            {
                "size": " 40х35х2",
                "koef": "0.001714",
                "metrs": 1
            },
            {
                "size": " 40х40х1",
                "koef": "0.000954",
                "metrs": 1
            },
            {
                "size": " 40х40х1,2",
                "koef": "0.001141",
                "metrs": 1
            },
            {
                "size": " 40х40х1,5",
                "koef": "0.001419",
                "metrs": 1
            },
            {
                "size": " 40х40х1,8",
                "koef": "0.001694",
                "metrs": 1
            },
            {
                "size": " 40х40х2",
                "koef": "0.001823",
                "metrs": 1
            },
            {
                "size": " 40х45х1",
                "koef": "0.001035",
                "metrs": 1
            },
            {
                "size": " 40х45х1,2",
                "koef": "0.001238",
                "metrs": 1
            },
            {
                "size": " 40х45х1,5",
                "koef": "0.00154",
                "metrs": 1
            },
            {
                "size": " 40х45х1,8",
                "koef": "0.00184",
                "metrs": 1
            },
            {
                "size": " 40х45х2",
                "koef": "0.002038",
                "metrs": 1
            },
            {
                "size": " 40х50х1",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 40х50х1,2",
                "koef": "0.001335",
                "metrs": 1
            },
            {
                "size": " 40х50х1,5",
                "koef": "0.001662",
                "metrs": 1
            },
            {
                "size": " 40х50х1,8",
                "koef": "0.001985",
                "metrs": 1
            },
            {
                "size": " 40х50х2",
                "koef": "0.002199",
                "metrs": 1
            },
            {
                "size": " 40х55х1",
                "koef": "0.001197",
                "metrs": 1
            },
            {
                "size": " 40х55х1,2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 40х55х1,5",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 40х55х1,8",
                "koef": "0.002131",
                "metrs": 1
            },
            {
                "size": " 40х55х2",
                "koef": "0.002361",
                "metrs": 1
            },
            {
                "size": " 40х60х1",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": " 40х60х1,2",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": " 40х60х1,5",
                "koef": "0.001904",
                "metrs": 1
            },
            {
                "size": " 40х60х1,8",
                "koef": "0.002276",
                "metrs": 1
            },
            {
                "size": " 40х60х2",
                "koef": "0.002523",
                "metrs": 1
            },
            {
                "size": " 45х15х1",
                "koef": "0.00059",
                "metrs": 1
            },
            {
                "size": " 45х15х1,2",
                "koef": "0.000704",
                "metrs": 1
            },
            {
                "size": " 45х15х1,5",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 45х15х1,8",
                "koef": "0.001039",
                "metrs": 1
            },
            {
                "size": " 45х15х2",
                "koef": "0.001148",
                "metrs": 1
            },
            {
                "size": " 45х20х1",
                "koef": "0.000671",
                "metrs": 1
            },
            {
                "size": " 45х20х1,2",
                "koef": "0.000801",
                "metrs": 1
            },
            {
                "size": " 45х20х1,5",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 45х20х1,8",
                "koef": "0.001185",
                "metrs": 1
            },
            {
                "size": " 45х20х2",
                "koef": "0.00131",
                "metrs": 1
            },
            {
                "size": " 45х25х1",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": " 45х25х1,2",
                "koef": "0.000898",
                "metrs": 1
            },
            {
                "size": " 45х25х1,5",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 45х25х1,8",
                "koef": "0.00133",
                "metrs": 1
            },
            {
                "size": " 45х25х2",
                "koef": "0.001472",
                "metrs": 1
            },
            {
                "size": " 45х30х1",
                "koef": "0.000833",
                "metrs": 1
            },
            {
                "size": " 45х30х1,2",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 45х30х1,5",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 45х30х1,8",
                "koef": "0.001476",
                "metrs": 1
            },
            {
                "size": " 45х30х2",
                "koef": "0.001633",
                "metrs": 1
            },
            {
                "size": " 45х35х1",
                "koef": "0.000914",
                "metrs": 1
            },
            {
                "size": " 45х35х1,2",
                "koef": "0.001093",
                "metrs": 1
            },
            {
                "size": " 45х35х1,5",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 45х35х1,8",
                "koef": "0.001621",
                "metrs": 1
            },
            {
                "size": " 45х35х2",
                "koef": "0.001795",
                "metrs": 1
            },
            {
                "size": " 45х40х1",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 45х40х1,2",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 45х40х1,5",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 45х40х1,8",
                "koef": "0.001767",
                "metrs": 1
            },
            {
                "size": " 45х40х2",
                "koef": "0.001957",
                "metrs": 1
            },
            {
                "size": " 45х45х1",
                "koef": "0.001075",
                "metrs": 1
            },
            {
                "size": " 45х45х1,2",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": " 45х45х1,5",
                "koef": "0.001601",
                "metrs": 1
            },
            {
                "size": " 45х45х1,8",
                "koef": "0.001912",
                "metrs": 1
            },
            {
                "size": " 45х45х2",
                "koef": "0.002118",
                "metrs": 1
            },
            {
                "size": " 45х50х1",
                "koef": "0.001156",
                "metrs": 1
            },
            {
                "size": " 45х50х1,2",
                "koef": "0.001384",
                "metrs": 1
            },
            {
                "size": " 45х50х1,5",
                "koef": "0.001722",
                "metrs": 1
            },
            {
                "size": " 45х50х1,8",
                "koef": "0.002058",
                "metrs": 1
            },
            {
                "size": " 45х50х2",
                "koef": "0.00228",
                "metrs": 1
            },
            {
                "size": " 45х55х1",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 45х55х1,2",
                "koef": "0.001481",
                "metrs": 1
            },
            {
                "size": " 45х55х1,5",
                "koef": "0.001843",
                "metrs": 1
            },
            {
                "size": " 45х55х1,8",
                "koef": "0.002203",
                "metrs": 1
            },
            {
                "size": " 45х55х2",
                "koef": "0.002442",
                "metrs": 1
            },
            {
                "size": " 45х60х1",
                "koef": "0.001318",
                "metrs": 1
            },
            {
                "size": " 45х60х1,2",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": " 45х60х1,5",
                "koef": "0.001965",
                "metrs": 1
            },
            {
                "size": " 45х60х1,8",
                "koef": "0.002349",
                "metrs": 1
            },
            {
                "size": " 45х60х2",
                "koef": "0.002604",
                "metrs": 1
            },
            {
                "size": " 50х15х1",
                "koef": "0.000631",
                "metrs": 1
            },
            {
                "size": " 50х15х1,2",
                "koef": "0.000753",
                "metrs": 1
            },
            {
                "size": " 50х15х1,5",
                "koef": "0.000934",
                "metrs": 1
            },
            {
                "size": " 50х15х1,8",
                "koef": "0.001112",
                "metrs": 1
            },
            {
                "size": " 50х15х2",
                "koef": "0.001229",
                "metrs": 1
            },
            {
                "size": " 50х20х1",
                "koef": "0.000712",
                "metrs": 1
            },
            {
                "size": " 50х20х1,2",
                "koef": "0.00085",
                "metrs": 1
            },
            {
                "size": " 50х20х1,5",
                "koef": "0.001055",
                "metrs": 1
            },
            {
                "size": " 50х20х1,8",
                "koef": "0.001257",
                "metrs": 1
            },
            {
                "size": " 50х20х2",
                "koef": "0.001391",
                "metrs": 1
            },
            {
                "size": " 50х25х1",
                "koef": "0.000792",
                "metrs": 1
            },
            {
                "size": " 50х25х1,2",
                "koef": "0.000947",
                "metrs": 1
            },
            {
                "size": " 50х25х1,5",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": " 50х25х1,8",
                "koef": "0.001403",
                "metrs": 1
            },
            {
                "size": " 50х25х2",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": " 50х30х1",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 50х30х1,2",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 50х30х1,5",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 50х30х1,8",
                "koef": "0.001549",
                "metrs": 1
            },
            {
                "size": " 50х30х2",
                "koef": "0.001658",
                "metrs": 1
            },
            {
                "size": " 50х35х1",
                "koef": "0.000954",
                "metrs": 1
            },
            {
                "size": " 50х35х1,2",
                "koef": "0.001141",
                "metrs": 1
            },
            {
                "size": " 50х35х1,5",
                "koef": "0.001419",
                "metrs": 1
            },
            {
                "size": " 50х35х1,8",
                "koef": "0.001694",
                "metrs": 1
            },
            {
                "size": " 50х35х2",
                "koef": "0.001876",
                "metrs": 1
            },
            {
                "size": " 50х40х1",
                "koef": "0.001035",
                "metrs": 1
            },
            {
                "size": " 50х40х1,2",
                "koef": "0.001238",
                "metrs": 1
            },
            {
                "size": " 50х40х1,5",
                "koef": "0.00154",
                "metrs": 1
            },
            {
                "size": " 50х40х1,8",
                "koef": "0.00184",
                "metrs": 1
            },
            {
                "size": " 50х40х2",
                "koef": "0.001978",
                "metrs": 1
            },
            {
                "size": " 50х45х1",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 50х45х1,2",
                "koef": "0.001335",
                "metrs": 1
            },
            {
                "size": " 50х45х1,5",
                "koef": "0.001662",
                "metrs": 1
            },
            {
                "size": " 50х45х1,8",
                "koef": "0.001985",
                "metrs": 1
            },
            {
                "size": " 50х45х2",
                "koef": "0.002199",
                "metrs": 1
            },
            {
                "size": " 50х50х1",
                "koef": "0.001197",
                "metrs": 1
            },
            {
                "size": " 50х50х1,2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 50х50х1,5",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 50х50х1,8",
                "koef": "0.002131",
                "metrs": 1
            },
            {
                "size": " 50х50х2",
                "koef": "0.002361",
                "metrs": 1
            },
            {
                "size": " 50х55х1",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": " 50х55х1,2",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": " 50х55х1,5",
                "koef": "0.001904",
                "metrs": 1
            },
            {
                "size": " 50х55х1,8",
                "koef": "0.002276",
                "metrs": 1
            },
            {
                "size": " 50х55х2",
                "koef": "0.002523",
                "metrs": 1
            },
            {
                "size": " 50х60х1",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 50х60х1,2",
                "koef": "0.001626",
                "metrs": 1
            },
            {
                "size": " 50х60х1,5",
                "koef": "0.002025",
                "metrs": 1
            },
            {
                "size": " 50х60х1,8",
                "koef": "0.002422",
                "metrs": 1
            },
            {
                "size": " 50х60х2",
                "koef": "0.002684",
                "metrs": 1
            },
            {
                "size": " 55х15х1",
                "koef": "0.000671",
                "metrs": 1
            },
            {
                "size": " 55х15х1,2",
                "koef": "0.000801",
                "metrs": 1
            },
            {
                "size": " 55х15х1,5",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 55х15х1,8",
                "koef": "0.001185",
                "metrs": 1
            },
            {
                "size": " 55х15х2",
                "koef": "0.00131",
                "metrs": 1
            },
            {
                "size": " 55х20х1",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": " 55х20х1,2",
                "koef": "0.000898",
                "metrs": 1
            },
            {
                "size": " 55х20х1,5",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 55х20х1,8",
                "koef": "0.00133",
                "metrs": 1
            },
            {
                "size": " 55х20х2",
                "koef": "0.001472",
                "metrs": 1
            },
            {
                "size": " 55х25х1",
                "koef": "0.000833",
                "metrs": 1
            },
            {
                "size": " 55х25х1,2",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 55х25х1,5",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 55х25х1,8",
                "koef": "0.001476",
                "metrs": 1
            },
            {
                "size": " 55х25х2",
                "koef": "0.001633",
                "metrs": 1
            },
            {
                "size": " 55х30х1",
                "koef": "0.000914",
                "metrs": 1
            },
            {
                "size": " 55х30х1,2",
                "koef": "0.001093",
                "metrs": 1
            },
            {
                "size": " 55х30х1,5",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 55х30х1,8",
                "koef": "0.001621",
                "metrs": 1
            },
            {
                "size": " 55х30х2",
                "koef": "0.001795",
                "metrs": 1
            },
            {
                "size": " 55х35х1",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 55х35х1,2",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 55х35х1,5",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 55х35х1,8",
                "koef": "0.001767",
                "metrs": 1
            },
            {
                "size": " 55х35х2",
                "koef": "0.001957",
                "metrs": 1
            },
            {
                "size": " 55х40х1",
                "koef": "0.001075",
                "metrs": 1
            },
            {
                "size": " 55х40х1,2",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": " 55х40х1,5",
                "koef": "0.001601",
                "metrs": 1
            },
            {
                "size": " 55х40х1,8",
                "koef": "0.001912",
                "metrs": 1
            },
            {
                "size": " 55х40х2",
                "koef": "0.002118",
                "metrs": 1
            },
            {
                "size": " 55х45х1",
                "koef": "0.001156",
                "metrs": 1
            },
            {
                "size": " 55х45х1,2",
                "koef": "0.001384",
                "metrs": 1
            },
            {
                "size": " 55х45х1,5",
                "koef": "0.001722",
                "metrs": 1
            },
            {
                "size": " 55х45х1,8",
                "koef": "0.002058",
                "metrs": 1
            },
            {
                "size": " 55х45х2",
                "koef": "0.00228",
                "metrs": 1
            },
            {
                "size": " 55х50х1",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 55х50х1,2",
                "koef": "0.001481",
                "metrs": 1
            },
            {
                "size": " 55х50х1,5",
                "koef": "0.001843",
                "metrs": 1
            },
            {
                "size": " 55х50х1,8",
                "koef": "0.002203",
                "metrs": 1
            },
            {
                "size": " 55х50х2",
                "koef": "0.002442",
                "metrs": 1
            },
            {
                "size": " 55х55х1",
                "koef": "0.001318",
                "metrs": 1
            },
            {
                "size": " 55х55х1,2",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": " 55х55х1,5",
                "koef": "0.001965",
                "metrs": 1
            },
            {
                "size": " 55х55х1,8",
                "koef": "0.002349",
                "metrs": 1
            },
            {
                "size": " 55х55х2",
                "koef": "0.002604",
                "metrs": 1
            },
            {
                "size": " 55х60х1",
                "koef": "0.001399",
                "metrs": 1
            },
            {
                "size": " 55х60х1,2",
                "koef": "0.001675",
                "metrs": 1
            },
            {
                "size": " 55х60х1,5",
                "koef": "0.002086",
                "metrs": 1
            },
            {
                "size": " 55х60х1,8",
                "koef": "0.002495",
                "metrs": 1
            },
            {
                "size": " 55х60х2",
                "koef": "0.002765",
                "metrs": 1
            },
            {
                "size": " 60х15х1",
                "koef": "0.000712",
                "metrs": 1
            },
            {
                "size": " 60х15х1,2",
                "koef": "0.00085",
                "metrs": 1
            },
            {
                "size": " 60х15х1,5",
                "koef": "0.001055",
                "metrs": 1
            },
            {
                "size": " 60х15х1,8",
                "koef": "0.001257",
                "metrs": 1
            },
            {
                "size": " 60х15х2",
                "koef": "0.001391",
                "metrs": 1
            },
            {
                "size": " 60х20х1",
                "koef": "0.000792",
                "metrs": 1
            },
            {
                "size": " 60х20х1,2",
                "koef": "0.000947",
                "metrs": 1
            },
            {
                "size": " 60х20х1,5",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": " 60х20х1,8",
                "koef": "0.001403",
                "metrs": 1
            },
            {
                "size": " 60х20х2",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": " 60х25х1",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 60х25х1,2",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 60х25х1,5",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 60х25х1,8",
                "koef": "0.001549",
                "metrs": 1
            },
            {
                "size": " 60х25х2",
                "koef": "0.001714",
                "metrs": 1
            },
            {
                "size": " 60х30х1",
                "koef": "0.000954",
                "metrs": 1
            },
            {
                "size": " 60х30х1,2",
                "koef": "0.001141",
                "metrs": 1
            },
            {
                "size": " 60х30х1,5",
                "koef": "0.001419",
                "metrs": 1
            },
            {
                "size": " 60х30х1,8",
                "koef": "0.001694",
                "metrs": 1
            },
            {
                "size": " 60х30х2",
                "koef": "0.001876",
                "metrs": 1
            },
            {
                "size": " 60х35х1",
                "koef": "0.001035",
                "metrs": 1
            },
            {
                "size": " 60х35х1,2",
                "koef": "0.001238",
                "metrs": 1
            },
            {
                "size": " 60х35х1,5",
                "koef": "0.00154",
                "metrs": 1
            },
            {
                "size": " 60х35х1,8",
                "koef": "0.00184",
                "metrs": 1
            },
            {
                "size": " 60х35х2",
                "koef": "0.002038",
                "metrs": 1
            },
            {
                "size": " 60х40х1",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 60х40х1,2",
                "koef": "0.001335",
                "metrs": 1
            },
            {
                "size": " 60х40х1,5",
                "koef": "0.001662",
                "metrs": 1
            },
            {
                "size": " 60х40х1,8",
                "koef": "0.001985",
                "metrs": 1
            },
            {
                "size": " 60х40х2",
                "koef": "0.002142",
                "metrs": 1
            },
            {
                "size": " 60х45х1",
                "koef": "0.001197",
                "metrs": 1
            },
            {
                "size": " 60х45х1,2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 60х45х1,5",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 60х45х1,8",
                "koef": "0.002131",
                "metrs": 1
            },
            {
                "size": " 60х45х2",
                "koef": "0.002361",
                "metrs": 1
            },
            {
                "size": " 60х50х1",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": " 60х50х1,2",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": " 60х50х1,5",
                "koef": "0.001904",
                "metrs": 1
            },
            {
                "size": " 60х50х1,8",
                "koef": "0.002276",
                "metrs": 1
            },
            {
                "size": " 60х50х2",
                "koef": "0.002523",
                "metrs": 1
            },
            {
                "size": " 60х55х1",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 60х55х1,2",
                "koef": "0.001626",
                "metrs": 1
            },
            {
                "size": " 60х55х1,5",
                "koef": "0.002025",
                "metrs": 1
            },
            {
                "size": " 60х55х1,8",
                "koef": "0.002422",
                "metrs": 1
            },
            {
                "size": " 60х55х2",
                "koef": "0.002684",
                "metrs": 1
            },
            {
                "size": " 60х60х1",
                "koef": "0.001439",
                "metrs": 1
            },
            {
                "size": " 60х60х1,2",
                "koef": "0.001723",
                "metrs": 1
            },
            {
                "size": " 60х60х1,5",
                "koef": "0.002147",
                "metrs": 1
            },
            {
                "size": " 60х60х1,8",
                "koef": "0.002567",
                "metrs": 1
            },
            {
                "size": " 60х60х2",
                "koef": "0.002846",
                "metrs": 1
            },
            {
                "size": " 65х15х1",
                "koef": "0.000752",
                "metrs": 1
            },
            {
                "size": " 65х15х1,2",
                "koef": "0.000898",
                "metrs": 1
            },
            {
                "size": " 65х15х1,5",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 65х15х1,8",
                "koef": "0.00133",
                "metrs": 1
            },
            {
                "size": " 65х15х2",
                "koef": "0.001472",
                "metrs": 1
            },
            {
                "size": " 65х20х1",
                "koef": "0.000833",
                "metrs": 1
            },
            {
                "size": " 65х20х1,2",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 65х20х1,5",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 65х20х1,8",
                "koef": "0.001476",
                "metrs": 1
            },
            {
                "size": " 65х20х2",
                "koef": "0.001633",
                "metrs": 1
            },
            {
                "size": " 65х25х1",
                "koef": "0.000914",
                "metrs": 1
            },
            {
                "size": " 65х25х1,2",
                "koef": "0.001093",
                "metrs": 1
            },
            {
                "size": " 65х25х1,5",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 65х25х1,8",
                "koef": "0.001621",
                "metrs": 1
            },
            {
                "size": " 65х25х2",
                "koef": "0.001795",
                "metrs": 1
            },
            {
                "size": " 65х30х1",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 65х30х1,2",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 65х30х1,5",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 65х30х1,8",
                "koef": "0.001767",
                "metrs": 1
            },
            {
                "size": " 65х30х2",
                "koef": "0.001957",
                "metrs": 1
            },
            {
                "size": " 65х35х1",
                "koef": "0.001075",
                "metrs": 1
            },
            {
                "size": " 65х35х1,2",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": " 65х35х1,5",
                "koef": "0.001601",
                "metrs": 1
            },
            {
                "size": " 65х35х1,8",
                "koef": "0.001912",
                "metrs": 1
            },
            {
                "size": " 65х35х2",
                "koef": "0.002118",
                "metrs": 1
            },
            {
                "size": " 65х40х1",
                "koef": "0.001156",
                "metrs": 1
            },
            {
                "size": " 65х40х1,2",
                "koef": "0.001384",
                "metrs": 1
            },
            {
                "size": " 65х40х1,5",
                "koef": "0.001722",
                "metrs": 1
            },
            {
                "size": " 65х40х1,8",
                "koef": "0.002058",
                "metrs": 1
            },
            {
                "size": " 65х40х2",
                "koef": "0.00228",
                "metrs": 1
            },
            {
                "size": " 65х45х1",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 65х45х1,2",
                "koef": "0.001481",
                "metrs": 1
            },
            {
                "size": " 65х45х1,5",
                "koef": "0.001843",
                "metrs": 1
            },
            {
                "size": " 65х45х1,8",
                "koef": "0.002203",
                "metrs": 1
            },
            {
                "size": " 65х45х2",
                "koef": "0.002442",
                "metrs": 1
            },
            {
                "size": " 65х50х1",
                "koef": "0.001318",
                "metrs": 1
            },
            {
                "size": " 65х50х1,2",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": " 65х50х1,5",
                "koef": "0.001965",
                "metrs": 1
            },
            {
                "size": " 65х50х1,8",
                "koef": "0.002349",
                "metrs": 1
            },
            {
                "size": " 65х50х2",
                "koef": "0.002604",
                "metrs": 1
            },
            {
                "size": " 65х55х1",
                "koef": "0.001399",
                "metrs": 1
            },
            {
                "size": " 65х55х1,2",
                "koef": "0.001675",
                "metrs": 1
            },
            {
                "size": " 65х55х1,5",
                "koef": "0.002086",
                "metrs": 1
            },
            {
                "size": " 65х55х1,8",
                "koef": "0.002495",
                "metrs": 1
            },
            {
                "size": " 65х55х2",
                "koef": "0.002765",
                "metrs": 1
            },
            {
                "size": " 65х60х1",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 65х60х1,2",
                "koef": "0.001772",
                "metrs": 1
            },
            {
                "size": " 65х60х1,5",
                "koef": "0.002207",
                "metrs": 1
            },
            {
                "size": " 65х60х1,8",
                "koef": "0.00264",
                "metrs": 1
            },
            {
                "size": " 65х60х2",
                "koef": "0.002927",
                "metrs": 1
            },
            {
                "size": " 70х15х1",
                "koef": "0.000792",
                "metrs": 1
            },
            {
                "size": " 70х15х1,2",
                "koef": "0.000947",
                "metrs": 1
            },
            {
                "size": " 70х15х1,5",
                "koef": "0.001176",
                "metrs": 1
            },
            {
                "size": " 70х15х1,8",
                "koef": "0.001403",
                "metrs": 1
            },
            {
                "size": " 70х15х2",
                "koef": "0.001552",
                "metrs": 1
            },
            {
                "size": " 70х20х1",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 70х20х1,2",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 70х20х1,5",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 70х20х1,8",
                "koef": "0.001549",
                "metrs": 1
            },
            {
                "size": " 70х20х2",
                "koef": "0.001714",
                "metrs": 1
            },
            {
                "size": " 70х25х1",
                "koef": "0.000954",
                "metrs": 1
            },
            {
                "size": " 70х25х1,2",
                "koef": "0.001141",
                "metrs": 1
            },
            {
                "size": " 70х25х1,5",
                "koef": "0.001419",
                "metrs": 1
            },
            {
                "size": " 70х25х1,8",
                "koef": "0.001694",
                "metrs": 1
            },
            {
                "size": " 70х25х2",
                "koef": "0.001876",
                "metrs": 1
            },
            {
                "size": " 70х30х1",
                "koef": "0.001035",
                "metrs": 1
            },
            {
                "size": " 70х30х1,2",
                "koef": "0.001238",
                "metrs": 1
            },
            {
                "size": " 70х30х1,5",
                "koef": "0.00154",
                "metrs": 1
            },
            {
                "size": " 70х30х1,8",
                "koef": "0.00184",
                "metrs": 1
            },
            {
                "size": " 70х30х2",
                "koef": "0.001978",
                "metrs": 1
            },
            {
                "size": " 70х35х1",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 70х35х1,2",
                "koef": "0.001335",
                "metrs": 1
            },
            {
                "size": " 70х35х1,5",
                "koef": "0.001662",
                "metrs": 1
            },
            {
                "size": " 70х35х1,8",
                "koef": "0.001985",
                "metrs": 1
            },
            {
                "size": " 70х35х2",
                "koef": "0.002199",
                "metrs": 1
            },
            {
                "size": " 70х40х1",
                "koef": "0.001197",
                "metrs": 1
            },
            {
                "size": " 70х40х1,2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 70х40х1,5",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 70х40х1,8",
                "koef": "0.002131",
                "metrs": 1
            },
            {
                "size": " 70х40х2",
                "koef": "0.002361",
                "metrs": 1
            },
            {
                "size": " 70х45х1",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": " 70х45х1,2",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": " 70х45х1,5",
                "koef": "0.001904",
                "metrs": 1
            },
            {
                "size": " 70х45х1,8",
                "koef": "0.002276",
                "metrs": 1
            },
            {
                "size": " 70х45х2",
                "koef": "0.002523",
                "metrs": 1
            },
            {
                "size": " 70х50х1",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 70х50х1,2",
                "koef": "0.001626",
                "metrs": 1
            },
            {
                "size": " 70х50х1,5",
                "koef": "0.002025",
                "metrs": 1
            },
            {
                "size": " 70х50х1,8",
                "koef": "0.002422",
                "metrs": 1
            },
            {
                "size": " 70х50х2",
                "koef": "0.002684",
                "metrs": 1
            },
            {
                "size": " 70х55х1",
                "koef": "0.001439",
                "metrs": 1
            },
            {
                "size": " 70х55х1,2",
                "koef": "0.001723",
                "metrs": 1
            },
            {
                "size": " 70х55х1,5",
                "koef": "0.002147",
                "metrs": 1
            },
            {
                "size": " 70х55х1,8",
                "koef": "0.002567",
                "metrs": 1
            },
            {
                "size": " 70х55х2",
                "koef": "0.002846",
                "metrs": 1
            },
            {
                "size": " 70х60х1",
                "koef": "0.00152",
                "metrs": 1
            },
            {
                "size": " 70х60х1,2",
                "koef": "0.00182",
                "metrs": 1
            },
            {
                "size": " 70х60х1,5",
                "koef": "0.002268",
                "metrs": 1
            },
            {
                "size": " 70х60х1,8",
                "koef": "0.002713",
                "metrs": 1
            },
            {
                "size": " 70х60х2",
                "koef": "0.003008",
                "metrs": 1
            },
            {
                "size": " 75х15х1",
                "koef": "0.000833",
                "metrs": 1
            },
            {
                "size": " 75х15х1,2",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 75х15х1,5",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 75х15х1,8",
                "koef": "0.001476",
                "metrs": 1
            },
            {
                "size": " 75х15х2",
                "koef": "0.001633",
                "metrs": 1
            },
            {
                "size": " 75х20х1",
                "koef": "0.000914",
                "metrs": 1
            },
            {
                "size": " 75х20х1,2",
                "koef": "0.001093",
                "metrs": 1
            },
            {
                "size": " 75х20х1,5",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 75х20х1,8",
                "koef": "0.001621",
                "metrs": 1
            },
            {
                "size": " 75х20х2",
                "koef": "0.001795",
                "metrs": 1
            },
            {
                "size": " 75х25х1",
                "koef": "0.000995",
                "metrs": 1
            },
            {
                "size": " 75х25х1,2",
                "koef": "0.00119",
                "metrs": 1
            },
            {
                "size": " 75х25х1,5",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 75х25х1,8",
                "koef": "0.001767",
                "metrs": 1
            },
            {
                "size": " 75х25х2",
                "koef": "0.001957",
                "metrs": 1
            },
            {
                "size": " 75х30х1",
                "koef": "0.001075",
                "metrs": 1
            },
            {
                "size": " 75х30х1,2",
                "koef": "0.001287",
                "metrs": 1
            },
            {
                "size": " 75х30х1,5",
                "koef": "0.001601",
                "metrs": 1
            },
            {
                "size": " 75х30х1,8",
                "koef": "0.001912",
                "metrs": 1
            },
            {
                "size": " 75х30х2",
                "koef": "0.002118",
                "metrs": 1
            },
            {
                "size": " 75х35х1",
                "koef": "0.001156",
                "metrs": 1
            },
            {
                "size": " 75х35х1,2",
                "koef": "0.001384",
                "metrs": 1
            },
            {
                "size": " 75х35х1,5",
                "koef": "0.001722",
                "metrs": 1
            },
            {
                "size": " 75х35х1,8",
                "koef": "0.002058",
                "metrs": 1
            },
            {
                "size": " 75х35х2",
                "koef": "0.00228",
                "metrs": 1
            },
            {
                "size": " 75х40х1",
                "koef": "0.001237",
                "metrs": 1
            },
            {
                "size": " 75х40х1,2",
                "koef": "0.001481",
                "metrs": 1
            },
            {
                "size": " 75х40х1,5",
                "koef": "0.001843",
                "metrs": 1
            },
            {
                "size": " 75х40х1,8",
                "koef": "0.002203",
                "metrs": 1
            },
            {
                "size": " 75х40х2",
                "koef": "0.002442",
                "metrs": 1
            },
            {
                "size": " 75х45х1",
                "koef": "0.001318",
                "metrs": 1
            },
            {
                "size": " 75х45х1,2",
                "koef": "0.001578",
                "metrs": 1
            },
            {
                "size": " 75х45х1,5",
                "koef": "0.001965",
                "metrs": 1
            },
            {
                "size": " 75х45х1,8",
                "koef": "0.002349",
                "metrs": 1
            },
            {
                "size": " 75х45х2",
                "koef": "0.002604",
                "metrs": 1
            },
            {
                "size": " 75х50х1",
                "koef": "0.001399",
                "metrs": 1
            },
            {
                "size": " 75х50х1,2",
                "koef": "0.001675",
                "metrs": 1
            },
            {
                "size": " 75х50х1,5",
                "koef": "0.002086",
                "metrs": 1
            },
            {
                "size": " 75х50х1,8",
                "koef": "0.002495",
                "metrs": 1
            },
            {
                "size": " 75х50х2",
                "koef": "0.002765",
                "metrs": 1
            },
            {
                "size": " 75х55х1",
                "koef": "0.00148",
                "metrs": 1
            },
            {
                "size": " 75х55х1,2",
                "koef": "0.001772",
                "metrs": 1
            },
            {
                "size": " 75х55х1,5",
                "koef": "0.002207",
                "metrs": 1
            },
            {
                "size": " 75х55х1,8",
                "koef": "0.00264",
                "metrs": 1
            },
            {
                "size": " 75х55х2",
                "koef": "0.002927",
                "metrs": 1
            },
            {
                "size": " 75х60х1",
                "koef": "0.001561",
                "metrs": 1
            },
            {
                "size": " 75х60х1,2",
                "koef": "0.001869",
                "metrs": 1
            },
            {
                "size": " 75х60х1,5",
                "koef": "0.002329",
                "metrs": 1
            },
            {
                "size": " 75х60х1,8",
                "koef": "0.002786",
                "metrs": 1
            },
            {
                "size": " 75х60х2",
                "koef": "0.003089",
                "metrs": 1
            },
            {
                "size": " 80х15х1",
                "koef": "0.000873",
                "metrs": 1
            },
            {
                "size": " 80х15х1,2",
                "koef": "0.001044",
                "metrs": 1
            },
            {
                "size": " 80х15х1,5",
                "koef": "0.001298",
                "metrs": 1
            },
            {
                "size": " 80х15х1,8",
                "koef": "0.001549",
                "metrs": 1
            },
            {
                "size": " 80х15х2",
                "koef": "0.001714",
                "metrs": 1
            },
            {
                "size": " 80х20х1",
                "koef": "0.000954",
                "metrs": 1
            },
            {
                "size": " 80х20х1,2",
                "koef": "0.001141",
                "metrs": 1
            },
            {
                "size": " 80х20х1,5",
                "koef": "0.001419",
                "metrs": 1
            },
            {
                "size": " 80х20х1,8",
                "koef": "0.001694",
                "metrs": 1
            },
            {
                "size": " 80х20х2",
                "koef": "0.001876",
                "metrs": 1
            },
            {
                "size": " 80х25х1",
                "koef": "0.001035",
                "metrs": 1
            },
            {
                "size": " 80х25х1,2",
                "koef": "0.001238",
                "metrs": 1
            },
            {
                "size": " 80х25х1,5",
                "koef": "0.00154",
                "metrs": 1
            },
            {
                "size": " 80х25х1,8",
                "koef": "0.00184",
                "metrs": 1
            },
            {
                "size": " 80х25х2",
                "koef": "0.002038",
                "metrs": 1
            },
            {
                "size": " 80х30х1",
                "koef": "0.001116",
                "metrs": 1
            },
            {
                "size": " 80х30х1,2",
                "koef": "0.001335",
                "metrs": 1
            },
            {
                "size": " 80х30х1,5",
                "koef": "0.001662",
                "metrs": 1
            },
            {
                "size": " 80х30х1,8",
                "koef": "0.001985",
                "metrs": 1
            },
            {
                "size": " 80х30х2",
                "koef": "0.002199",
                "metrs": 1
            },
            {
                "size": " 80х35х1",
                "koef": "0.001197",
                "metrs": 1
            },
            {
                "size": " 80х35х1,2",
                "koef": "0.001432",
                "metrs": 1
            },
            {
                "size": " 80х35х1,5",
                "koef": "0.001783",
                "metrs": 1
            },
            {
                "size": " 80х35х1,8",
                "koef": "0.002131",
                "metrs": 1
            },
            {
                "size": " 80х35х2",
                "koef": "0.002361",
                "metrs": 1
            },
            {
                "size": " 80х40х1",
                "koef": "0.001278",
                "metrs": 1
            },
            {
                "size": " 80х40х1,2",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": " 80х40х1,5",
                "koef": "0.001904",
                "metrs": 1
            },
            {
                "size": " 80х40х1,8",
                "koef": "0.002276",
                "metrs": 1
            },
            {
                "size": " 80х40х2",
                "koef": "0.002523",
                "metrs": 1
            },
            {
                "size": " 80х45х1",
                "koef": "0.001358",
                "metrs": 1
            },
            {
                "size": " 80х45х1,2",
                "koef": "0.001626",
                "metrs": 1
            },
            {
                "size": " 80х45х1,5",
                "koef": "0.002025",
                "metrs": 1
            },
            {
                "size": " 80х45х1,8",
                "koef": "0.002422",
                "metrs": 1
            },
            {
                "size": " 80х45х2",
                "koef": "0.002684",
                "metrs": 1
            },
            {
                "size": " 80х50х1",
                "koef": "0.001439",
                "metrs": 1
            },
            {
                "size": " 80х50х1,2",
                "koef": "0.001723",
                "metrs": 1
            },
            {
                "size": " 80х50х1,5",
                "koef": "0.002147",
                "metrs": 1
            },
            {
                "size": " 80х50х1,8",
                "koef": "0.002567",
                "metrs": 1
            },
            {
                "size": " 80х50х2",
                "koef": "0.002846",
                "metrs": 1
            },
            {
                "size": " 80х55х1",
                "koef": "0.00152",
                "metrs": 1
            },
            {
                "size": " 80х55х1,2",
                "koef": "0.00182",
                "metrs": 1
            },
            {
                "size": " 80х55х1,5",
                "koef": "0.002268",
                "metrs": 1
            },
            {
                "size": " 80х55х1,8",
                "koef": "0.002713",
                "metrs": 1
            },
            {
                "size": " 80х55х2",
                "koef": "0.003008",
                "metrs": 1
            },
            {
                "size": " 80х60х1",
                "koef": "0.001601",
                "metrs": 1
            },
            {
                "size": " 80х60х1,2",
                "koef": "0.001917",
                "metrs": 1
            },
            {
                "size": " 80х60х1,5",
                "koef": "0.002389",
                "metrs": 1
            },
            {
                "size": " 80х60х1,8",
                "koef": "0.002858",
                "metrs": 1
            },
            {
                "size": " 80х60х2",
                "koef": "0.00317",
                "metrs": 1
            },
            {
                "size": " 100х45х1",
                "koef": "0.00152",
                "metrs": 1
            },
            {
                "size": " 100х45х1,2",
                "koef": "0.00182",
                "metrs": 1
            },
            {
                "size": " 100х45х1,5",
                "koef": "0.002268",
                "metrs": 1
            },
            {
                "size": " 125х45х1",
                "koef": "0.001722",
                "metrs": 1
            },
            {
                "size": " 125х45х1,2",
                "koef": "0.002063",
                "metrs": 1
            },
            {
                "size": " 125х45х1,5",
                "koef": "0.002571",
                "metrs": 1
            },
            {
                "size": " 125х45х1,8",
                "koef": "0.003077",
                "metrs": 1
            },
            {
                "size": " 150х45х1",
                "koef": "0.001924",
                "metrs": 1
            },
            {
                "size": " 150х45х1,2",
                "koef": "0.002305",
                "metrs": 1
            },
            {
                "size": " 150х45х1,5",
                "koef": "0.002874",
                "metrs": 1
            },
            {
                "size": " 150х45х1,8",
                "koef": "0.003441",
                "metrs": 1
            },
            {
                "size": " 175х45х1",
                "koef": "0.002126",
                "metrs": 1
            },
            {
                "size": " 175х45х1,2",
                "koef": "0.002548",
                "metrs": 1
            },
            {
                "size": " 175х45х1,5",
                "koef": "0.003178",
                "metrs": 1
            },
            {
                "size": " 175х45х1,8",
                "koef": "0.003804",
                "metrs": 1
            },
            {
                "size": " 175х45х2",
                "koef": "0.004221",
                "metrs": 1
            },
            {
                "size": " 200х45х1",
                "koef": "0.002329",
                "metrs": 1
            },
            {
                "size": " 200х45х1,2",
                "koef": "0.00279",
                "metrs": 1
            },
            {
                "size": " 200х45х1,5",
                "koef": "0.003481",
                "metrs": 1
            },
            {
                "size": " 200х45х1,8",
                "koef": "0.004168",
                "metrs": 1
            },
            {
                "size": " 200х45х2",
                "koef": "0.004625",
                "metrs": 1
            },
            {
                "size": " 200х50х2",
                "koef": "0.004787",
                "metrs": 1
            },
            {
                "size": " 300х100х3",
                "koef": "0.01198",
                "metrs": 1
            },
            {
                "size": " 300х100х3,5",
                "koef": "0.01395",
                "metrs": 1
            },
            {
                "size": " 300х100х4",
                "koef": "0.01591",
                "metrs": 1
            },
            {
                "size": " 350х100х3",
                "koef": "0.0132",
                "metrs": 1
            },
            {
                "size": " 350х100х3,5",
                "koef": "0.01537",
                "metrs": 1
            },
            {
                "size": " 350х100х4",
                "koef": "0.01753",
                "metrs": 1
            },
            {
                "size": " 400х100х3",
                "koef": "0.01441",
                "metrs": 1
            },
            {
                "size": " 400х100х3,5",
                "koef": "0.01678",
                "metrs": 1
            }
        ],
        "Швеллер оцинкованный б/у": [
            {
                "size": "12У",
                "koef": "0.01071",
                "metrs": 1
            },
            {
                "size": "14У",
                "koef": "0.01267",
                "metrs": 1
            }
        ],
        "Шестигранник": [
            {
                "size": 5,
                "koef": "0.00017",
                "metrs": 1
            },
            {
                "size": "5.5",
                "koef": "0.000206",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.000245",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.000333",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.000435",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0.000551",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.00068",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0.000823",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000979",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0.001149",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001332",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.00153",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.001965",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.002454",
                "metrs": 1
            },
            {
                "size": 21,
                "koef": "0.002998",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.00329",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.003916",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0.004956",
                "metrs": 1
            },
            {
                "size": 29,
                "koef": "0.005717",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.006118",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.006961",
                "metrs": 1
            },
            {
                "size": 34,
                "koef": "0.007859",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.008811",
                "metrs": 1
            },
            {
                "size": 38,
                "koef": "0.009817",
                "metrs": 1
            },
            {
                "size": 41,
                "koef": "0.01143",
                "metrs": 1
            },
            {
                "size": 43,
                "koef": "0.01257",
                "metrs": 1
            },
            {
                "size": 46,
                "koef": "0.01439",
                "metrs": 1
            },
            {
                "size": 48,
                "koef": "0.01566",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.017",
                "metrs": 1
            },
            {
                "size": 52,
                "koef": "0.01838",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.02056",
                "metrs": 1
            },
            {
                "size": 57,
                "koef": "0.02209",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.02447",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0.02872",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0.03824",
                "metrs": 1
            }
        ]
    },
    "Нержавеющий металлопрокат": {
        "Канат": [
            {
                "size": "1.8",
                "koef": "0.000016",
                "metrs": 1000
            },
            {
                "size": "2.5",
                "koef": "0.000031",
                "metrs": 1000
            },
            {
                "size": "3.2",
                "koef": "0.000054",
                "metrs": 1000
            },
            {
                "size": "3.6",
                "koef": "0.000064",
                "metrs": 1000
            },
            {
                "size": 4,
                "koef": "0.000081",
                "metrs": 1000
            },
            {
                "size": "4.5",
                "koef": "0.000098",
                "metrs": 1000
            },
            {
                "size": 5,
                "koef": "0.000129",
                "metrs": 1000
            },
            {
                "size": 6,
                "koef": "0.000175",
                "metrs": 1000
            },
            {
                "size": "6.4",
                "koef": "0.000198",
                "metrs": 1000
            },
            {
                "size": 8,
                "koef": "0.000309",
                "metrs": 1000
            },
            {
                "size": "9.5",
                "koef": "0.000436",
                "metrs": 1000
            }
        ],
        "Квадрат": [
            {
                "size": 6,
                "koef": "0.000284",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.000506",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.00079",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.001138",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001548",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.001778",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.002022",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.002852",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.00316",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.00455",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.004938",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.00711",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.01024",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.01264",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.01975",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.02844",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.03871",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.05056",
                "metrs": 1
            },
            {
                "size": 90,
                "koef": "0.06399",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0.079",
                "metrs": 1
            },
            {
                "size": 130,
                "koef": "0.1335",
                "metrs": 1
            },
            {
                "size": 140,
                "koef": "0.1548",
                "metrs": 1
            },
            {
                "size": 200,
                "koef": "0.316",
                "metrs": 1
            }
        ],
        "Круг": [
            {
                "size": 3,
                "koef": "0.000055",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.0001",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.000155",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.000223",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.000304",
                "metrs": 1
            },
            {
                "size": "7.5",
                "koef": "0.000349",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.000398",
                "metrs": 1
            },
            {
                "size": "8.5",
                "koef": "0.000448",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0.000502",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.00062",
                "metrs": 1
            },
            {
                "size": "10.5",
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": "10.8",
                "koef": "0.000724",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0.000751",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000894",
                "metrs": 1
            },
            {
                "size": "12.3",
                "koef": "0.000939",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001216",
                "metrs": 1
            },
            {
                "size": "14.6",
                "koef": "0.001322",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.001396",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.001588",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.001793",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.002011",
                "metrs": 1
            },
            {
                "size": "18.3",
                "koef": "0.002078",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.00224",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.002482",
                "metrs": 1
            },
            {
                "size": 21,
                "koef": "0.002736",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.003003",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.003574",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.003878",
                "metrs": 1
            },
            {
                "size": 26,
                "koef": "0.004195",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.004865",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.005584",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.006353",
                "metrs": 1
            },
            {
                "size": 34,
                "koef": "0.007172",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.007601",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.008041",
                "metrs": 1
            },
            {
                "size": 38,
                "koef": "0.00896",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.009928",
                "metrs": 1
            },
            {
                "size": 42,
                "koef": "0.01095",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.01256",
                "metrs": 1
            },
            {
                "size": 48,
                "koef": "0.0143",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.01551",
                "metrs": 1
            },
            {
                "size": 52,
                "koef": "0.01678",
                "metrs": 1
            },
            {
                "size": 53,
                "koef": "0.01743",
                "metrs": 1
            },
            {
                "size": 54,
                "koef": "0.01809",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.01877",
                "metrs": 1
            },
            {
                "size": 56,
                "koef": "0.01946",
                "metrs": 1
            },
            {
                "size": 58,
                "koef": "0.02087",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.02234",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0.02621",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.0304",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0.0349",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.03971",
                "metrs": 1
            },
            {
                "size": 85,
                "koef": "0.04483",
                "metrs": 1
            },
            {
                "size": 90,
                "koef": "0.05026",
                "metrs": 1
            },
            {
                "size": 95,
                "koef": "0.056",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0.06205",
                "metrs": 1
            },
            {
                "size": 105,
                "koef": "0.06841",
                "metrs": 1
            },
            {
                "size": 110,
                "koef": "0.07508",
                "metrs": 1
            },
            {
                "size": 115,
                "koef": "0.08206",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "0.08935",
                "metrs": 1
            },
            {
                "size": 125,
                "koef": "0.09695",
                "metrs": 1
            },
            {
                "size": 130,
                "koef": "0.1049",
                "metrs": 1
            },
            {
                "size": 135,
                "koef": "0.1131",
                "metrs": 1
            },
            {
                "size": 140,
                "koef": "0.1216",
                "metrs": 1
            },
            {
                "size": 145,
                "koef": "0.1305",
                "metrs": 1
            },
            {
                "size": 150,
                "koef": "0.1396",
                "metrs": 1
            },
            {
                "size": 155,
                "koef": "0.1491",
                "metrs": 1
            },
            {
                "size": 160,
                "koef": "0.1588",
                "metrs": 1
            },
            {
                "size": 170,
                "koef": "0.1793",
                "metrs": 1
            },
            {
                "size": 175,
                "koef": "0.19",
                "metrs": 1
            },
            {
                "size": 180,
                "koef": "0.201",
                "metrs": 1
            },
            {
                "size": 190,
                "koef": "0.224",
                "metrs": 1
            },
            {
                "size": 200,
                "koef": "0.2482",
                "metrs": 1
            },
            {
                "size": 210,
                "koef": "0.2736",
                "metrs": 1
            },
            {
                "size": 220,
                "koef": "0.3003",
                "metrs": 1
            },
            {
                "size": 230,
                "koef": "0.3282",
                "metrs": 1
            },
            {
                "size": 240,
                "koef": "0.3574",
                "metrs": 1
            },
            {
                "size": 250,
                "koef": "0.3878",
                "metrs": 1
            },
            {
                "size": 260,
                "koef": "0.4194",
                "metrs": 1
            },
            {
                "size": 270,
                "koef": "0.4523",
                "metrs": 1
            },
            {
                "size": 280,
                "koef": "0.4864",
                "metrs": 1
            },
            {
                "size": 300,
                "koef": "0.5584",
                "metrs": 1
            },
            {
                "size": 320,
                "koef": "0.6354",
                "metrs": 1
            },
            {
                "size": 340,
                "koef": "0.7173",
                "metrs": 1
            },
            {
                "size": 350,
                "koef": "0.7601",
                "metrs": 1
            },
            {
                "size": 360,
                "koef": "0.8041",
                "metrs": 1
            },
            {
                "size": 370,
                "koef": "0.8494",
                "metrs": 1
            },
            {
                "size": 380,
                "koef": "0.896",
                "metrs": 1
            },
            {
                "size": 400,
                "koef": "0.9927",
                "metrs": 1
            },
            {
                "size": 410,
                "koef": "1.043",
                "metrs": 1
            },
            {
                "size": 420,
                "koef": "1.095",
                "metrs": 1
            },
            {
                "size": 430,
                "koef": "1.147",
                "metrs": 1
            },
            {
                "size": 450,
                "koef": "1.256",
                "metrs": 1
            },
            {
                "size": 500,
                "koef": "1.551",
                "metrs": 1
            }
        ],
        "Лента/штрипс": [
            {
                "size": "0.05",
                "koef": "0.000395",
                "metrs": 1
            },
            {
                "size": "0.1",
                "koef": "0.00079",
                "metrs": 1
            },
            {
                "size": "0.12",
                "koef": "0.000948",
                "metrs": 1
            },
            {
                "size": "0.15",
                "koef": "0.001185",
                "metrs": 1
            },
            {
                "size": "0.2",
                "koef": "0.00158",
                "metrs": 1
            },
            {
                "size": "0.25",
                "koef": "0.001975",
                "metrs": 1
            },
            {
                "size": "0.3",
                "koef": "0.00237",
                "metrs": 1
            },
            {
                "size": "0.39",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": "0.4",
                "koef": "0.00316",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0.00395",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0.00553",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.0079",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.01185",
                "metrs": 1
            }
        ],
        "Лист/рулон г/к": [
            {
                "size": "0.4",
                "koef": "0.00316",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0.00395",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.0079",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.01185",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": "2.2",
                "koef": "0.01738",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0.01975",
                "metrs": 1
            },
            {
                "size": "2.7",
                "koef": "0.02133",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.0237",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0.02765",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.0316",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.0395",
                "metrs": 1
            },
            {
                "size": "5.5",
                "koef": "0.04345",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.0474",
                "metrs": 1
            },
            {
                "size": "6.5",
                "koef": "0.05135",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.0553",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.0632",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.079",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0.0869",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.0948",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0.1027",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.1106",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.1264",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.1422",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.158",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.1738",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.1896",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.1975",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.2212",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.237",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.2528",
                "metrs": 1
            },
            {
                "size": 34,
                "koef": "0.2686",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.2765",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.2844",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.316",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.3555",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.395",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.4345",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.474",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.553",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0.5925",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.632",
                "metrs": 1
            }
        ],
        "Лист/рулон х/к": [
            {
                "size": "0.4",
                "koef": "0.00316",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0.00395",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0.00474",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0.00553",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": "0.93",
                "koef": "0.007347",
                "metrs": 1
            },
            {
                "size": "0.95",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.0079",
                "metrs": 1
            },
            {
                "size": "1.18",
                "koef": "0.009322",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": "1.3",
                "koef": "0.01027",
                "metrs": 1
            },
            {
                "size": "1.38",
                "koef": "0.0109",
                "metrs": 1
            },
            {
                "size": "1.4",
                "koef": "0.01106",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.01185",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0.01422",
                "metrs": 1
            },
            {
                "size": "1.85",
                "koef": "0.01462",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": "2.2",
                "koef": "0.01738",
                "metrs": 1
            },
            {
                "size": "2.38",
                "koef": "0.0188",
                "metrs": 1
            },
            {
                "size": "2.4",
                "koef": "0.01896",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0.01975",
                "metrs": 1
            },
            {
                "size": "2.8",
                "koef": "0.02212",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.0237",
                "metrs": 1
            },
            {
                "size": "3.8",
                "koef": "0.03002",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.0316",
                "metrs": 1
            },
            {
                "size": "4.8",
                "koef": "0.03792",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.0395",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.0474",
                "metrs": 1
            }
        ],
        "Плита": [
            {
                "size": 60,
                "koef": "0.474",
                "metrs": 1
            }
        ],
        "Полоса": [
            {
                "size": " 3х20",
                "koef": "0.000474",
                "metrs": 1
            },
            {
                "size": " 3х25",
                "koef": "0.000593",
                "metrs": 1
            },
            {
                "size": " 3х30",
                "koef": "0.000711",
                "metrs": 1
            },
            {
                "size": " 3х40",
                "koef": "0.000948",
                "metrs": 1
            },
            {
                "size": " 4х20",
                "koef": "0.000632",
                "metrs": 1
            },
            {
                "size": " 4х25",
                "koef": "0.00079",
                "metrs": 1
            },
            {
                "size": " 4х30",
                "koef": "0.000948",
                "metrs": 1
            },
            {
                "size": " 4х35",
                "koef": "0.001106",
                "metrs": 1
            },
            {
                "size": " 4х40",
                "koef": "0.001264",
                "metrs": 1
            },
            {
                "size": " 4х50",
                "koef": "0.00158",
                "metrs": 1
            },
            {
                "size": " 4х55",
                "koef": "0.001738",
                "metrs": 1
            },
            {
                "size": " 4х60",
                "koef": "0.001896",
                "metrs": 1
            },
            {
                "size": " 4х65",
                "koef": "0.002054",
                "metrs": 1
            },
            {
                "size": " 4х70",
                "koef": "0.002212",
                "metrs": 1
            },
            {
                "size": " 4х75",
                "koef": "0.00237",
                "metrs": 1
            },
            {
                "size": " 4х80",
                "koef": "0.002528",
                "metrs": 1
            },
            {
                "size": " 4х85",
                "koef": "0.002686",
                "metrs": 1
            },
            {
                "size": " 4х90",
                "koef": "0.002844",
                "metrs": 1
            },
            {
                "size": " 4х95",
                "koef": "0.003002",
                "metrs": 1
            },
            {
                "size": " 4х100",
                "koef": "0.00316",
                "metrs": 1
            },
            {
                "size": " 4х110",
                "koef": "0.003476",
                "metrs": 1
            },
            {
                "size": " 4х120",
                "koef": "0.003792",
                "metrs": 1
            },
            {
                "size": " 4х130",
                "koef": "0.004108",
                "metrs": 1
            },
            {
                "size": " 4х140",
                "koef": "0.004424",
                "metrs": 1
            },
            {
                "size": " 4х150",
                "koef": "0.00474",
                "metrs": 1
            },
            {
                "size": " 4х160",
                "koef": "0.005056",
                "metrs": 1
            },
            {
                "size": " 4х170",
                "koef": "0.005372",
                "metrs": 1
            },
            {
                "size": " 4х180",
                "koef": "0.005688",
                "metrs": 1
            },
            {
                "size": " 4х190",
                "koef": "0.006004",
                "metrs": 1
            },
            {
                "size": " 4х200",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": " 4х210",
                "koef": "0.006636",
                "metrs": 1
            },
            {
                "size": " 4х220",
                "koef": "0.006952",
                "metrs": 1
            },
            {
                "size": " 4х230",
                "koef": "0.007268",
                "metrs": 1
            },
            {
                "size": " 4х240",
                "koef": "0.007584",
                "metrs": 1
            },
            {
                "size": " 4х250",
                "koef": "0.0079",
                "metrs": 1
            },
            {
                "size": " 4х300",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": " 4х500",
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": " 5х20",
                "koef": "0.00079",
                "metrs": 1
            },
            {
                "size": " 5х25",
                "koef": "0.000988",
                "metrs": 1
            },
            {
                "size": " 5х40",
                "koef": "0.00158",
                "metrs": 1
            },
            {
                "size": " 5х50",
                "koef": "0.001975",
                "metrs": 1
            },
            {
                "size": " 5х55",
                "koef": "0.002173",
                "metrs": 1
            },
            {
                "size": " 5х60",
                "koef": "0.00237",
                "metrs": 1
            },
            {
                "size": " 5х65",
                "koef": "0.002568",
                "metrs": 1
            },
            {
                "size": " 5х70",
                "koef": "0.002765",
                "metrs": 1
            },
            {
                "size": " 5х75",
                "koef": "0.002963",
                "metrs": 1
            },
            {
                "size": " 5х80",
                "koef": "0.00316",
                "metrs": 1
            },
            {
                "size": " 5х85",
                "koef": "0.003358",
                "metrs": 1
            },
            {
                "size": " 5х90",
                "koef": "0.003555",
                "metrs": 1
            },
            {
                "size": " 5х95",
                "koef": "0.003752",
                "metrs": 1
            },
            {
                "size": " 5х100",
                "koef": "0.00395",
                "metrs": 1
            },
            {
                "size": " 5х110",
                "koef": "0.004345",
                "metrs": 1
            },
            {
                "size": " 5х115",
                "koef": "0.004543",
                "metrs": 1
            },
            {
                "size": " 5х120",
                "koef": "0.00474",
                "metrs": 1
            },
            {
                "size": " 5х130",
                "koef": "0.005135",
                "metrs": 1
            },
            {
                "size": " 5х140",
                "koef": "0.00553",
                "metrs": 1
            },
            {
                "size": " 5х150",
                "koef": "0.005925",
                "metrs": 1
            },
            {
                "size": " 5х160",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": " 5х170",
                "koef": "0.006715",
                "metrs": 1
            },
            {
                "size": " 5х180",
                "koef": "0.00711",
                "metrs": 1
            },
            {
                "size": " 5х190",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": " 5х200",
                "koef": "0.0079",
                "metrs": 1
            },
            {
                "size": " 5х210",
                "koef": "0.008295",
                "metrs": 1
            },
            {
                "size": " 5х220",
                "koef": "0.00869",
                "metrs": 1
            },
            {
                "size": " 5х230",
                "koef": "0.009085",
                "metrs": 1
            },
            {
                "size": " 5х240",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": " 5х250",
                "koef": "0.009875",
                "metrs": 1
            },
            {
                "size": " 5х300",
                "koef": "0.01185",
                "metrs": 1
            },
            {
                "size": " 5х500",
                "koef": "0.01975",
                "metrs": 1
            },
            {
                "size": " 6х20",
                "koef": "0.000948",
                "metrs": 1
            },
            {
                "size": " 6х25",
                "koef": "0.001185",
                "metrs": 1
            },
            {
                "size": " 6х30",
                "koef": "0.001422",
                "metrs": 1
            },
            {
                "size": " 6х40",
                "koef": "0.001896",
                "metrs": 1
            },
            {
                "size": " 6х50",
                "koef": "0.00237",
                "metrs": 1
            },
            {
                "size": " 6х60",
                "koef": "0.002844",
                "metrs": 1
            },
            {
                "size": " 6х65",
                "koef": "0.003081",
                "metrs": 1
            },
            {
                "size": " 6х70",
                "koef": "0.003318",
                "metrs": 1
            },
            {
                "size": " 6х75",
                "koef": "0.003555",
                "metrs": 1
            },
            {
                "size": " 6х80",
                "koef": "0.003792",
                "metrs": 1
            },
            {
                "size": " 6х85",
                "koef": "0.004029",
                "metrs": 1
            },
            {
                "size": " 6х90",
                "koef": "0.004266",
                "metrs": 1
            },
            {
                "size": " 6х95",
                "koef": "0.004503",
                "metrs": 1
            },
            {
                "size": " 6х100",
                "koef": "0.00474",
                "metrs": 1
            },
            {
                "size": " 6х110",
                "koef": "0.005214",
                "metrs": 1
            },
            {
                "size": " 6х120",
                "koef": "0.005688",
                "metrs": 1
            },
            {
                "size": " 6х130",
                "koef": "0.006162",
                "metrs": 1
            },
            {
                "size": " 6х140",
                "koef": "0.006636",
                "metrs": 1
            },
            {
                "size": " 6х150",
                "koef": "0.00711",
                "metrs": 1
            },
            {
                "size": " 6х160",
                "koef": "0.007584",
                "metrs": 1
            },
            {
                "size": " 6х170",
                "koef": "0.008058",
                "metrs": 1
            },
            {
                "size": " 6х180",
                "koef": "0.008532",
                "metrs": 1
            },
            {
                "size": " 6х190",
                "koef": "0.009006",
                "metrs": 1
            },
            {
                "size": " 6х200",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": " 6х210",
                "koef": "0.009954",
                "metrs": 1
            },
            {
                "size": " 6х220",
                "koef": "0.01043",
                "metrs": 1
            },
            {
                "size": " 6х230",
                "koef": "0.0109",
                "metrs": 1
            },
            {
                "size": " 6х240",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": " 6х250",
                "koef": "0.01185",
                "metrs": 1
            },
            {
                "size": " 6х300",
                "koef": "0.01422",
                "metrs": 1
            },
            {
                "size": " 6х500",
                "koef": "0.0237",
                "metrs": 1
            },
            {
                "size": " 8х25",
                "koef": "0.00158",
                "metrs": 1
            },
            {
                "size": " 8х30",
                "koef": "0.001896",
                "metrs": 1
            },
            {
                "size": " 8х40",
                "koef": "0.002528",
                "metrs": 1
            },
            {
                "size": " 8х50",
                "koef": "0.00316",
                "metrs": 1
            },
            {
                "size": " 8х60",
                "koef": "0.003792",
                "metrs": 1
            },
            {
                "size": " 8х80",
                "koef": "0.005056",
                "metrs": 1
            },
            {
                "size": " 8х85",
                "koef": "0.005372",
                "metrs": 1
            },
            {
                "size": " 8х90",
                "koef": "0.005688",
                "metrs": 1
            },
            {
                "size": " 8х95",
                "koef": "0.006004",
                "metrs": 1
            },
            {
                "size": " 8х100",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": " 8х110",
                "koef": "0.006952",
                "metrs": 1
            },
            {
                "size": " 8х120",
                "koef": "0.007584",
                "metrs": 1
            },
            {
                "size": " 8х130",
                "koef": "0.008216",
                "metrs": 1
            },
            {
                "size": " 8х140",
                "koef": "0.008848",
                "metrs": 1
            },
            {
                "size": " 8х150",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": " 8х160",
                "koef": "0.01011",
                "metrs": 1
            },
            {
                "size": " 8х170",
                "koef": "0.01074",
                "metrs": 1
            },
            {
                "size": " 8х180",
                "koef": "0.01138",
                "metrs": 1
            },
            {
                "size": " 8х190",
                "koef": "0.01201",
                "metrs": 1
            },
            {
                "size": " 8х200",
                "koef": "0.01264",
                "metrs": 1
            },
            {
                "size": " 10х20",
                "koef": "0.00158",
                "metrs": 1
            },
            {
                "size": " 10х25",
                "koef": "0.001975",
                "metrs": 1
            },
            {
                "size": " 10х30",
                "koef": "0.00237",
                "metrs": 1
            },
            {
                "size": " 10х40",
                "koef": "0.00316",
                "metrs": 1
            },
            {
                "size": " 10х50",
                "koef": "0.00395",
                "metrs": 1
            },
            {
                "size": " 10х60",
                "koef": "0.00474",
                "metrs": 1
            },
            {
                "size": " 10х100",
                "koef": "0.0079",
                "metrs": 1
            },
            {
                "size": " 12х20",
                "koef": "0.001896",
                "metrs": 1
            },
            {
                "size": " 14х30",
                "koef": "0.003318",
                "metrs": 1
            },
            {
                "size": " 20х40",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": " 30х60",
                "koef": "0.01422",
                "metrs": 1
            }
        ],
        "Проволока": [
            {
                "size": "0.11",
                "koef": "0.007508",
                "metrs": 100000
            },
            {
                "size": "0.12",
                "koef": "0.008935",
                "metrs": 100000
            },
            {
                "size": "0.13",
                "koef": "0.01049",
                "metrs": 100000
            },
            {
                "size": "0.14",
                "koef": "0.01216",
                "metrs": 100000
            },
            {
                "size": "0.16",
                "koef": "0.01588",
                "metrs": 100000
            },
            {
                "size": "0.2",
                "koef": "0.02482",
                "metrs": 100000
            },
            {
                "size": "0.22",
                "koef": "0.03003",
                "metrs": 100000
            },
            {
                "size": "0.24",
                "koef": "0.03574",
                "metrs": 100000
            },
            {
                "size": "0.25",
                "koef": "0.03878",
                "metrs": 100000
            },
            {
                "size": "0.28",
                "koef": "0.04864",
                "metrs": 100000
            },
            {
                "size": "0.3",
                "koef": "0.05584",
                "metrs": 100000
            },
            {
                "size": "0.32",
                "koef": "0.06354",
                "metrs": 100000
            },
            {
                "size": "0.35",
                "koef": "0.07601",
                "metrs": 100000
            },
            {
                "size": "0.36",
                "koef": "0.08041",
                "metrs": 100000
            },
            {
                "size": "0.4",
                "koef": "0.09927",
                "metrs": 100000
            },
            {
                "size": "0.45",
                "koef": "0.1256",
                "metrs": 100000
            },
            {
                "size": "0.5",
                "koef": "0.1551",
                "metrs": 100000
            },
            {
                "size": "0.6",
                "koef": "0.2234",
                "metrs": 100000
            },
            {
                "size": "0.65",
                "koef": "0.2621",
                "metrs": 100000
            },
            {
                "size": "0.7",
                "koef": "0.304",
                "metrs": 100000
            },
            {
                "size": "0.8",
                "koef": "0.3971",
                "metrs": 100000
            },
            {
                "size": "0.9",
                "koef": "0.5026",
                "metrs": 100000
            },
            {
                "size": 1,
                "koef": "0.6205",
                "metrs": 100000
            },
            {
                "size": "1.1",
                "koef": "0.7508",
                "metrs": 100000
            },
            {
                "size": "1.2",
                "koef": "0.8935",
                "metrs": 100000
            },
            {
                "size": "1.4",
                "koef": "0.001216",
                "metrs": 100
            },
            {
                "size": "1.5",
                "koef": "0.001396",
                "metrs": 100
            },
            {
                "size": "1.6",
                "koef": "0.001588",
                "metrs": 100
            },
            {
                "size": "1.8",
                "koef": "0.00201",
                "metrs": 100
            },
            {
                "size": 2,
                "koef": "0.002482",
                "metrs": 100
            },
            {
                "size": "2.2",
                "koef": "0.003003",
                "metrs": 100
            },
            {
                "size": "2.5",
                "koef": "0.003878",
                "metrs": 100
            },
            {
                "size": "2.6",
                "koef": "0.004194",
                "metrs": 100
            },
            {
                "size": "2.8",
                "koef": "0.004864",
                "metrs": 100
            },
            {
                "size": 3,
                "koef": "0.005584",
                "metrs": 100
            },
            {
                "size": "3.2",
                "koef": "0.006354",
                "metrs": 100
            },
            {
                "size": "3.35",
                "koef": "0.006963",
                "metrs": 100
            },
            {
                "size": "3.5",
                "koef": "0.007601",
                "metrs": 100
            },
            {
                "size": 4,
                "koef": "0.009927",
                "metrs": 100
            },
            {
                "size": "4.5",
                "koef": "0.01256",
                "metrs": 100
            },
            {
                "size": 5,
                "koef": "0.01551",
                "metrs": 100
            },
            {
                "size": "5.5",
                "koef": "0.01877",
                "metrs": 100
            },
            {
                "size": 6,
                "koef": "0.02234",
                "metrs": 100
            },
            {
                "size": 7,
                "koef": "0.0304",
                "metrs": 100
            },
            {
                "size": 8,
                "koef": "0.03971",
                "metrs": 100
            },
            {
                "size": 10,
                "koef": "0.06205",
                "metrs": 100
            },
            {
                "size": 12,
                "koef": "0.08935",
                "metrs": 100
            }
        ],
        "Труба бесшовная": [
            {
                "size": "325х13",
                "koef": "0.1079",
                "metrs": 1
            },
            {
                "size": "457х14",
                "koef": "0.1651",
                "metrs": 1
            },
            {
                "size": " 2х0,2",
                "koef": "0.000009",
                "metrs": 1
            },
            {
                "size": " 2х0,5",
                "koef": "0.000019",
                "metrs": 1
            },
            {
                "size": " 4х0,5",
                "koef": "0.000043",
                "metrs": 1
            },
            {
                "size": " 5х0,5",
                "koef": "0.000056",
                "metrs": 1
            },
            {
                "size": " 6х0,8",
                "koef": "0.000103",
                "metrs": 1
            },
            {
                "size": " 6х1",
                "koef": "0.000124",
                "metrs": 1
            },
            {
                "size": " 6х1,5",
                "koef": "0.000168",
                "metrs": 1
            },
            {
                "size": " 7х1",
                "koef": "0.000149",
                "metrs": 1
            },
            {
                "size": " 7х1,5",
                "koef": "0.000205",
                "metrs": 1
            },
            {
                "size": " 8х0,8",
                "koef": "0.000143",
                "metrs": 1
            },
            {
                "size": " 8х1",
                "koef": "0.000174",
                "metrs": 1
            },
            {
                "size": " 8х1,2",
                "koef": "0.000203",
                "metrs": 1
            },
            {
                "size": " 8х1,5",
                "koef": "0.000242",
                "metrs": 1
            },
            {
                "size": "8х2",
                "koef": "0.000298",
                "metrs": 1
            },
            {
                "size": "9х2",
                "koef": "0.000347",
                "metrs": 1
            },
            {
                "size": " 10х0,8",
                "koef": "0.000183",
                "metrs": 1
            },
            {
                "size": " 10х1",
                "koef": "0.000223",
                "metrs": 1
            },
            {
                "size": " 10х1,5",
                "koef": "0.000316",
                "metrs": 1
            },
            {
                "size": " 10х2",
                "koef": "0.000397",
                "metrs": 1
            },
            {
                "size": " 10х2,5",
                "koef": "0.000465",
                "metrs": 1
            },
            {
                "size": " 10х3",
                "koef": "0.000521",
                "metrs": 1
            },
            {
                "size": " 12х0,8",
                "koef": "0.000222",
                "metrs": 1
            },
            {
                "size": " 12х1",
                "koef": "0.000273",
                "metrs": 1
            },
            {
                "size": "12х1.5",
                "koef": "0.000391",
                "metrs": 1
            },
            {
                "size": " 12х2",
                "koef": "0.000496",
                "metrs": 1
            },
            {
                "size": " 12х2.5",
                "koef": "0.000589",
                "metrs": 1
            },
            {
                "size": " 12х3",
                "koef": "0.00067",
                "metrs": 1
            },
            {
                "size": " 13х1,5",
                "koef": "0.000428",
                "metrs": 1
            },
            {
                "size": " 13х2",
                "koef": "0.000546",
                "metrs": 1
            },
            {
                "size": " 14х1",
                "koef": "0.000323",
                "metrs": 1
            },
            {
                "size": " 14х1,4",
                "koef": "0.000438",
                "metrs": 1
            },
            {
                "size": " 14х1,5",
                "koef": "0.000465",
                "metrs": 1
            },
            {
                "size": " 14х2",
                "koef": "0.000596",
                "metrs": 1
            },
            {
                "size": " 14х2,5",
                "koef": "0.000714",
                "metrs": 1
            },
            {
                "size": " 14х3",
                "koef": "0.000819",
                "metrs": 1
            },
            {
                "size": " 15х1",
                "koef": "0.000347",
                "metrs": 1
            },
            {
                "size": " 15х1,5",
                "koef": "0.000503",
                "metrs": 1
            },
            {
                "size": " 15х3",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": " 16х1",
                "koef": "0.000372",
                "metrs": 1
            },
            {
                "size": " 16х1,5",
                "koef": "0.00054",
                "metrs": 1
            },
            {
                "size": " 16х2",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": " 16х2,5",
                "koef": "0.000838",
                "metrs": 1
            },
            {
                "size": " 16х3",
                "koef": "0.000968",
                "metrs": 1
            },
            {
                "size": " 16х6",
                "koef": "0.001489",
                "metrs": 1
            },
            {
                "size": " 17х2",
                "koef": "0.000745",
                "metrs": 1
            },
            {
                "size": " 18х1",
                "koef": "0.000422",
                "metrs": 1
            },
            {
                "size": " 18х1,2",
                "koef": "0.0005",
                "metrs": 1
            },
            {
                "size": " 18х1,5",
                "koef": "0.000614",
                "metrs": 1
            },
            {
                "size": " 18х2",
                "koef": "0.000794",
                "metrs": 1
            },
            {
                "size": " 18х2,5",
                "koef": "0.000962",
                "metrs": 1
            },
            {
                "size": " 18х3",
                "koef": "0.001117",
                "metrs": 1
            },
            {
                "size": " 18х3,5",
                "koef": "0.00126",
                "metrs": 1
            },
            {
                "size": " 18х4",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 20х1",
                "koef": "0.000472",
                "metrs": 1
            },
            {
                "size": " 20х1,5",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": " 20х2",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": " 20х2,5",
                "koef": "0.001086",
                "metrs": 1
            },
            {
                "size": " 20х3",
                "koef": "0.001266",
                "metrs": 1
            },
            {
                "size": " 20х3,5",
                "koef": "0.001433",
                "metrs": 1
            },
            {
                "size": " 20х4",
                "koef": "0.001588",
                "metrs": 1
            },
            {
                "size": " 21х2",
                "koef": "0.000943",
                "metrs": 1
            },
            {
                "size": " 21х2,5",
                "koef": "0.001148",
                "metrs": 1
            },
            {
                "size": " 21х3",
                "koef": "0.00134",
                "metrs": 1
            },
            {
                "size": " 21х4",
                "koef": "0.001688",
                "metrs": 1
            },
            {
                "size": " 22х1",
                "koef": "0.000521",
                "metrs": 1
            },
            {
                "size": " 22х1,4",
                "koef": "0.000716",
                "metrs": 1
            },
            {
                "size": " 22х1,5",
                "koef": "0.000763",
                "metrs": 1
            },
            {
                "size": " 22х2",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": " 22х2,5",
                "koef": "0.00121",
                "metrs": 1
            },
            {
                "size": " 22х3",
                "koef": "0.001415",
                "metrs": 1
            },
            {
                "size": " 22х3,5",
                "koef": "0.001607",
                "metrs": 1
            },
            {
                "size": " 22х4",
                "koef": "0.001787",
                "metrs": 1
            },
            {
                "size": " 24х2",
                "koef": "0.001092",
                "metrs": 1
            },
            {
                "size": " 24х3",
                "koef": "0.001564",
                "metrs": 1
            },
            {
                "size": " 24х3,5",
                "koef": "0.001781",
                "metrs": 1
            },
            {
                "size": " 25х1",
                "koef": "0.000596",
                "metrs": 1
            },
            {
                "size": " 25х1,1",
                "koef": "0.000652",
                "metrs": 1
            },
            {
                "size": " 25х1,5",
                "koef": "0.000875",
                "metrs": 1
            },
            {
                "size": " 25х2",
                "koef": "0.001142",
                "metrs": 1
            },
            {
                "size": " 25х2,5",
                "koef": "0.001396",
                "metrs": 1
            },
            {
                "size": " 25х3",
                "koef": "0.001638",
                "metrs": 1
            },
            {
                "size": " 25х3,5",
                "koef": "0.001868",
                "metrs": 1
            },
            {
                "size": " 25х4",
                "koef": "0.002085",
                "metrs": 1
            },
            {
                "size": " 25х4,5",
                "koef": "0.00229",
                "metrs": 1
            },
            {
                "size": " 25х5",
                "koef": "0.002482",
                "metrs": 1
            },
            {
                "size": " 25х7",
                "koef": "0.003127",
                "metrs": 1
            },
            {
                "size": " 27х1",
                "koef": "0.000645",
                "metrs": 1
            },
            {
                "size": " 27х2",
                "koef": "0.001241",
                "metrs": 1
            },
            {
                "size": " 27х3",
                "koef": "0.001787",
                "metrs": 1
            },
            {
                "size": " 27х3,5",
                "koef": "0.002041",
                "metrs": 1
            },
            {
                "size": " 27х4",
                "koef": "0.002283",
                "metrs": 1
            },
            {
                "size": " 28х2",
                "koef": "0.001291",
                "metrs": 1
            },
            {
                "size": " 28х2,5",
                "koef": "0.001582",
                "metrs": 1
            },
            {
                "size": " 28х3",
                "koef": "0.001861",
                "metrs": 1
            },
            {
                "size": " 28х3,5",
                "koef": "0.002128",
                "metrs": 1
            },
            {
                "size": " 28х4",
                "koef": "0.002383",
                "metrs": 1
            },
            {
                "size": " 30х1",
                "koef": "0.00072",
                "metrs": 1
            },
            {
                "size": " 30х2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": " 30х2,5",
                "koef": "0.001706",
                "metrs": 1
            },
            {
                "size": " 30х3",
                "koef": "0.00201",
                "metrs": 1
            },
            {
                "size": " 30х4",
                "koef": "0.002581",
                "metrs": 1
            },
            {
                "size": " 30х5",
                "koef": "0.003102",
                "metrs": 1
            },
            {
                "size": " 32х1",
                "koef": "0.000769",
                "metrs": 1
            },
            {
                "size": " 32х2",
                "koef": "0.001489",
                "metrs": 1
            },
            {
                "size": " 32х2,4",
                "koef": "0.001763",
                "metrs": 1
            },
            {
                "size": " 32х2,5",
                "koef": "0.00183",
                "metrs": 1
            },
            {
                "size": " 32х3",
                "koef": "0.002159",
                "metrs": 1
            },
            {
                "size": " 32х3,5",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 32х4",
                "koef": "0.00278",
                "metrs": 1
            },
            {
                "size": " 32х4,5",
                "koef": "0.003071",
                "metrs": 1
            },
            {
                "size": " 32х5",
                "koef": "0.003351",
                "metrs": 1
            },
            {
                "size": " 32х6",
                "koef": "0.003872",
                "metrs": 1
            },
            {
                "size": " 34х1,4",
                "koef": "0.001133",
                "metrs": 1
            },
            {
                "size": " 34х1,5",
                "koef": "0.00121",
                "metrs": 1
            },
            {
                "size": " 34х2,5",
                "koef": "0.001954",
                "metrs": 1
            },
            {
                "size": " 34х3",
                "koef": "0.002308",
                "metrs": 1
            },
            {
                "size": " 34х3,5",
                "koef": "0.002649",
                "metrs": 1
            },
            {
                "size": " 34х4",
                "koef": "0.002978",
                "metrs": 1
            },
            {
                "size": " 34х4,5",
                "koef": "0.003295",
                "metrs": 1
            },
            {
                "size": " 34х5",
                "koef": "0.003599",
                "metrs": 1
            },
            {
                "size": " 35х2",
                "koef": "0.001638",
                "metrs": 1
            },
            {
                "size": " 35х3",
                "koef": "0.002383",
                "metrs": 1
            },
            {
                "size": " 36х2",
                "koef": "0.001688",
                "metrs": 1
            },
            {
                "size": " 36х3",
                "koef": "0.002457",
                "metrs": 1
            },
            {
                "size": " 37х2,5",
                "koef": "0.002141",
                "metrs": 1
            },
            {
                "size": " 38х1,5",
                "koef": "0.001359",
                "metrs": 1
            },
            {
                "size": " 38х2",
                "koef": "0.001787",
                "metrs": 1
            },
            {
                "size": " 38х2,5",
                "koef": "0.002203",
                "metrs": 1
            },
            {
                "size": " 38х3",
                "koef": "0.002606",
                "metrs": 1
            },
            {
                "size": " 38х3,5",
                "koef": "0.002997",
                "metrs": 1
            },
            {
                "size": " 38х4",
                "koef": "0.003375",
                "metrs": 1
            },
            {
                "size": " 38х5",
                "koef": "0.003375",
                "metrs": 1
            },
            {
                "size": " 38х6",
                "koef": "0.004765",
                "metrs": 1
            },
            {
                "size": " 40х2",
                "koef": "0.001886",
                "metrs": 1
            },
            {
                "size": " 40х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "40х4",
                "koef": "0.003574",
                "metrs": 1
            },
            {
                "size": " 40х6",
                "koef": "0.005063",
                "metrs": 1
            },
            {
                "size": " 42х2",
                "koef": "0.001985",
                "metrs": 1
            },
            {
                "size": " 42х3",
                "koef": "0.002904",
                "metrs": 1
            },
            {
                "size": " 42х3,5",
                "koef": "0.003344",
                "metrs": 1
            },
            {
                "size": " 42х4",
                "koef": "0.003772",
                "metrs": 1
            },
            {
                "size": " 42х5",
                "koef": "0.004591",
                "metrs": 1
            },
            {
                "size": " 42х6",
                "koef": "0.005361",
                "metrs": 1
            },
            {
                "size": " 42х8,8",
                "koef": "0.007251",
                "metrs": 1
            },
            {
                "size": " 45х2",
                "koef": "0.002134",
                "metrs": 1
            },
            {
                "size": " 45х2,5",
                "koef": "0.002637",
                "metrs": 1
            },
            {
                "size": " 45х3",
                "koef": "0.003127",
                "metrs": 1
            },
            {
                "size": " 45х3,5",
                "koef": "0.003605",
                "metrs": 1
            },
            {
                "size": " 45х4",
                "koef": "0.00407",
                "metrs": 1
            },
            {
                "size": " 45х4,5",
                "koef": "0.004523",
                "metrs": 1
            },
            {
                "size": " 45х5",
                "koef": "0.004964",
                "metrs": 1
            },
            {
                "size": " 45х6",
                "koef": "0.005808",
                "metrs": 1
            },
            {
                "size": " 45х8",
                "koef": "0.007346",
                "metrs": 1
            },
            {
                "size": " 48х2",
                "koef": "0.002283",
                "metrs": 1
            },
            {
                "size": " 48х2,5",
                "koef": "0.002823",
                "metrs": 1
            },
            {
                "size": " 48х3",
                "koef": "0.003351",
                "metrs": 1
            },
            {
                "size": " 48х3,5",
                "koef": "0.003865",
                "metrs": 1
            },
            {
                "size": " 48х4",
                "koef": "0.004368",
                "metrs": 1
            },
            {
                "size": " 48х5",
                "koef": "0.005336",
                "metrs": 1
            },
            {
                "size": " 48х6",
                "koef": "0.006254",
                "metrs": 1
            },
            {
                "size": " 48х8",
                "koef": "0.007942",
                "metrs": 1
            },
            {
                "size": " 50х3",
                "koef": "0.003499",
                "metrs": 1
            },
            {
                "size": " 50х4",
                "koef": "0.004567",
                "metrs": 1
            },
            {
                "size": " 50х5",
                "koef": "0.005584",
                "metrs": 1
            },
            {
                "size": " 51х2",
                "koef": "0.002432",
                "metrs": 1
            },
            {
                "size": " 51х2,5",
                "koef": "0.003009",
                "metrs": 1
            },
            {
                "size": " 51х3",
                "koef": "0.003574",
                "metrs": 1
            },
            {
                "size": " 51х4",
                "koef": "0.004666",
                "metrs": 1
            },
            {
                "size": " 52х3",
                "koef": "0.003648",
                "metrs": 1
            },
            {
                "size": " 53х1,8",
                "koef": "0.002287",
                "metrs": 1
            },
            {
                "size": " 53х4",
                "koef": "0.004864",
                "metrs": 1
            },
            {
                "size": " 53х6,5",
                "koef": "0.007501",
                "metrs": 1
            },
            {
                "size": " 54х2",
                "koef": "0.002581",
                "metrs": 1
            },
            {
                "size": "54х2,5",
                "koef": "0.003195",
                "metrs": 1
            },
            {
                "size": " 56х3",
                "koef": "0.003946",
                "metrs": 1
            },
            {
                "size": " 56х4",
                "koef": "0.005162",
                "metrs": 1
            },
            {
                "size": " 56х5",
                "koef": "0.006329",
                "metrs": 1
            },
            {
                "size": " 57х2",
                "koef": "0.00273",
                "metrs": 1
            },
            {
                "size": " 57х2,5",
                "koef": "0.003382",
                "metrs": 1
            },
            {
                "size": " 57х3",
                "koef": "0.004021",
                "metrs": 1
            },
            {
                "size": " 57х3,5",
                "koef": "0.004647",
                "metrs": 1
            },
            {
                "size": " 57х4",
                "koef": "0.005262",
                "metrs": 1
            },
            {
                "size": " 57х4,5",
                "koef": "0.005863",
                "metrs": 1
            },
            {
                "size": " 57х5",
                "koef": "0.006453",
                "metrs": 1
            },
            {
                "size": " 57х6",
                "koef": "0.007594",
                "metrs": 1
            },
            {
                "size": " 57х7",
                "koef": "0.008686",
                "metrs": 1
            },
            {
                "size": " 57х8",
                "koef": "0.009729",
                "metrs": 1
            },
            {
                "size": " 60х2",
                "koef": "0.002879",
                "metrs": 1
            },
            {
                "size": " 60х3",
                "koef": "0.004244",
                "metrs": 1
            },
            {
                "size": " 60х4",
                "koef": "0.005559",
                "metrs": 1
            },
            {
                "size": " 60х4,5",
                "koef": "0.006198",
                "metrs": 1
            },
            {
                "size": " 60х5",
                "koef": "0.006825",
                "metrs": 1
            },
            {
                "size": " 60х8",
                "koef": "0.01032",
                "metrs": 1
            },
            {
                "size": " 60х8,5",
                "koef": "0.01086",
                "metrs": 1
            },
            {
                "size": " 60х10",
                "koef": "0.01241",
                "metrs": 1
            },
            {
                "size": " 63х3",
                "koef": "0.004467",
                "metrs": 1
            },
            {
                "size": " 63х8,5",
                "koef": "0.0115",
                "metrs": 1
            },
            {
                "size": " 65х3",
                "koef": "0.004616",
                "metrs": 1
            },
            {
                "size": " 65х4",
                "koef": "0.006056",
                "metrs": 1
            },
            {
                "size": " 65х5",
                "koef": "0.007446",
                "metrs": 1
            },
            {
                "size": " 65х7",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": " 65х8",
                "koef": "0.01132",
                "metrs": 1
            },
            {
                "size": " 68х4",
                "koef": "0.006354",
                "metrs": 1
            },
            {
                "size": " 68х8",
                "koef": "0.01191",
                "metrs": 1
            },
            {
                "size": " 70х2,5",
                "koef": "0.004188",
                "metrs": 1
            },
            {
                "size": " 70х3",
                "koef": "0.004989",
                "metrs": 1
            },
            {
                "size": "70х3,5",
                "koef": "0.005777",
                "metrs": 1
            },
            {
                "size": " 70х8",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": " 73х4",
                "koef": "0.00685",
                "metrs": 1
            },
            {
                "size": " 74х8",
                "koef": "0.0131",
                "metrs": 1
            },
            {
                "size": " 75х3",
                "koef": "0.005361",
                "metrs": 1
            },
            {
                "size": " 76х3",
                "koef": "0.005435",
                "metrs": 1
            },
            {
                "size": " 76х3,5",
                "koef": "0.006298",
                "metrs": 1
            },
            {
                "size": " 76х4",
                "koef": "0.007148",
                "metrs": 1
            },
            {
                "size": " 76х4,5",
                "koef": "0.007985",
                "metrs": 1
            },
            {
                "size": " 76х5",
                "koef": "0.008811",
                "metrs": 1
            },
            {
                "size": " 76х6",
                "koef": "0.01042",
                "metrs": 1
            },
            {
                "size": " 76х7",
                "koef": "0.01199",
                "metrs": 1
            },
            {
                "size": " 76х8",
                "koef": "0.0135",
                "metrs": 1
            },
            {
                "size": " 76х8,5",
                "koef": "0.01424",
                "metrs": 1
            },
            {
                "size": " 76х10",
                "koef": "0.01638",
                "metrs": 1
            },
            {
                "size": " 80х3",
                "koef": "0.005733",
                "metrs": 1
            },
            {
                "size": " 80х3,5",
                "koef": "0.006645",
                "metrs": 1
            },
            {
                "size": " 80х4",
                "koef": "0.007545",
                "metrs": 1
            },
            {
                "size": " 80х7",
                "koef": "0.01268",
                "metrs": 1
            },
            {
                "size": " 83х3",
                "koef": "0.005956",
                "metrs": 1
            },
            {
                "size": " 83х4",
                "koef": "0.007843",
                "metrs": 1
            },
            {
                "size": " 83х4,5",
                "koef": "0.008767",
                "metrs": 1
            },
            {
                "size": " 83х5",
                "koef": "0.009679",
                "metrs": 1
            },
            {
                "size": " 83х6",
                "koef": "0.01147",
                "metrs": 1
            },
            {
                "size": " 83х9",
                "koef": "0.01653",
                "metrs": 1
            },
            {
                "size": " 83х10",
                "koef": "0.01812",
                "metrs": 1
            },
            {
                "size": " 85х3",
                "koef": "0.006105",
                "metrs": 1
            },
            {
                "size": " 85х4,5",
                "koef": "0.008991",
                "metrs": 1
            },
            {
                "size": " 85х7,5",
                "koef": "0.01443",
                "metrs": 1
            },
            {
                "size": " 88,9х3",
                "koef": "0.006396",
                "metrs": 1
            },
            {
                "size": " 89х3",
                "koef": "0.006403",
                "metrs": 1
            },
            {
                "size": " 89х3,5",
                "koef": "0.007427",
                "metrs": 1
            },
            {
                "size": " 89х4",
                "koef": "0.008438",
                "metrs": 1
            },
            {
                "size": " 89х4,5",
                "koef": "0.009437",
                "metrs": 1
            },
            {
                "size": " 89х5",
                "koef": "0.01042",
                "metrs": 1
            },
            {
                "size": " 89х6",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": " 89х8",
                "koef": "0.01608",
                "metrs": 1
            },
            {
                "size": " 89х10",
                "koef": "0.01961",
                "metrs": 1
            },
            {
                "size": " 89х12",
                "koef": "0.02293",
                "metrs": 1
            },
            {
                "size": " 95х3",
                "koef": "0.00685",
                "metrs": 1
            },
            {
                "size": " 95х5",
                "koef": "0.01117",
                "metrs": 1
            },
            {
                "size": " 95х6",
                "koef": "0.01325",
                "metrs": 1
            },
            {
                "size": " 95х8",
                "koef": "0.01727",
                "metrs": 1
            },
            {
                "size": " 95х9",
                "koef": "0.01921",
                "metrs": 1
            },
            {
                "size": " 102х4",
                "koef": "0.009729",
                "metrs": 1
            },
            {
                "size": " 102х5",
                "koef": "0.01204",
                "metrs": 1
            },
            {
                "size": " 102х6",
                "koef": "0.0143",
                "metrs": 1
            },
            {
                "size": " 102х7",
                "koef": "0.0165",
                "metrs": 1
            },
            {
                "size": " 102х8",
                "koef": "0.01866",
                "metrs": 1
            },
            {
                "size": " 102х10",
                "koef": "0.02283",
                "metrs": 1
            },
            {
                "size": " 108х3",
                "koef": "0.007818",
                "metrs": 1
            },
            {
                "size": "1083.5",
                "koef": "0.009077",
                "metrs": 1
            },
            {
                "size": " 108х4",
                "koef": "0.01032",
                "metrs": 1
            },
            {
                "size": " 108х4,5",
                "koef": "0.01156",
                "metrs": 1
            },
            {
                "size": " 108х5",
                "koef": "0.01278",
                "metrs": 1
            },
            {
                "size": " 108х6",
                "koef": "0.01519",
                "metrs": 1
            },
            {
                "size": " 108х7",
                "koef": "0.01755",
                "metrs": 1
            },
            {
                "size": " 108х8",
                "koef": "0.01985",
                "metrs": 1
            },
            {
                "size": " 108х10",
                "koef": "0.02432",
                "metrs": 1
            },
            {
                "size": " 108х12",
                "koef": "0.02859",
                "metrs": 1
            },
            {
                "size": " 110х3,5",
                "koef": "0.009251",
                "metrs": 1
            },
            {
                "size": " 110х20",
                "koef": "0.04467",
                "metrs": 1
            },
            {
                "size": " 114х3",
                "koef": "0.008265",
                "metrs": 1
            },
            {
                "size": " 114х4",
                "koef": "0.01092",
                "metrs": 1
            },
            {
                "size": " 114х4,5",
                "koef": "0.01223",
                "metrs": 1
            },
            {
                "size": " 114х5",
                "koef": "0.01353",
                "metrs": 1
            },
            {
                "size": " 114х6",
                "koef": "0.01608",
                "metrs": 1
            },
            {
                "size": " 114х7",
                "koef": "0.01859",
                "metrs": 1
            },
            {
                "size": " 114х8",
                "koef": "0.02105",
                "metrs": 1
            },
            {
                "size": " 114х10",
                "koef": "0.02581",
                "metrs": 1
            },
            {
                "size": " 114х12",
                "koef": "0.03038",
                "metrs": 1
            },
            {
                "size": " 114х14",
                "koef": "0.03475",
                "metrs": 1
            },
            {
                "size": " 120х11",
                "koef": "0.02976",
                "metrs": 1
            },
            {
                "size": " 121х5",
                "koef": "0.01439",
                "metrs": 1
            },
            {
                "size": " 121х8",
                "koef": "0.02244",
                "metrs": 1
            },
            {
                "size": " 121х10",
                "koef": "0.02755",
                "metrs": 1
            },
            {
                "size": " 121х12",
                "koef": "0.03246",
                "metrs": 1
            },
            {
                "size": " 121х22",
                "koef": "0.05405",
                "metrs": 1
            },
            {
                "size": " 127х6",
                "koef": "0.01802",
                "metrs": 1
            },
            {
                "size": " 127х8",
                "koef": "0.02363",
                "metrs": 1
            },
            {
                "size": " 127х10",
                "koef": "0.02904",
                "metrs": 1
            },
            {
                "size": " 127х14",
                "koef": "0.03926",
                "metrs": 1
            },
            {
                "size": " 127х15",
                "koef": "0.0417",
                "metrs": 1
            },
            {
                "size": " 130х5",
                "koef": "0.01551",
                "metrs": 1
            },
            {
                "size": " 133х3",
                "koef": "0.009679",
                "metrs": 1
            },
            {
                "size": " 133х4",
                "koef": "0.01281",
                "metrs": 1
            },
            {
                "size": " 133х4,5",
                "koef": "0.01435",
                "metrs": 1
            },
            {
                "size": " 133х5",
                "koef": "0.01588",
                "metrs": 1
            },
            {
                "size": " 133х5,5",
                "koef": "0.0174",
                "metrs": 1
            },
            {
                "size": " 133х6",
                "koef": "0.01891",
                "metrs": 1
            },
            {
                "size": " 133х7",
                "koef": "0.02189",
                "metrs": 1
            },
            {
                "size": " 133х8",
                "koef": "0.02482",
                "metrs": 1
            },
            {
                "size": " 133х10",
                "koef": "0.03053",
                "metrs": 1
            },
            {
                "size": " 136х7,5",
                "koef": "0.02392",
                "metrs": 1
            },
            {
                "size": " 140х5",
                "koef": "0.01675",
                "metrs": 1
            },
            {
                "size": " 140х6",
                "koef": "0.01995",
                "metrs": 1
            },
            {
                "size": " 140х7",
                "koef": "0.02311",
                "metrs": 1
            },
            {
                "size": " 140х9",
                "koef": "0.02926",
                "metrs": 1
            },
            {
                "size": " 140х10",
                "koef": "0.03226",
                "metrs": 1
            },
            {
                "size": " 146х14",
                "koef": "0.04586",
                "metrs": 1
            },
            {
                "size": " 152х6",
                "koef": "0.02174",
                "metrs": 1
            },
            {
                "size": " 152х7",
                "koef": "0.02519",
                "metrs": 1
            },
            {
                "size": " 152х8",
                "koef": "0.02859",
                "metrs": 1
            },
            {
                "size": " 152х10",
                "koef": "0.03524",
                "metrs": 1
            },
            {
                "size": " 152х15",
                "koef": "0.051",
                "metrs": 1
            },
            {
                "size": " 159х3",
                "koef": "0.01162",
                "metrs": 1
            },
            {
                "size": " 159х3,5",
                "koef": "0.01351",
                "metrs": 1
            },
            {
                "size": " 159х4",
                "koef": "0.01539",
                "metrs": 1
            },
            {
                "size": " 159х4,5",
                "koef": "0.01726",
                "metrs": 1
            },
            {
                "size": " 159х5",
                "koef": "0.01911",
                "metrs": 1
            },
            {
                "size": " 159х6",
                "koef": "0.02278",
                "metrs": 1
            },
            {
                "size": " 159х7",
                "koef": "0.02641",
                "metrs": 1
            },
            {
                "size": " 159х8",
                "koef": "0.02998",
                "metrs": 1
            },
            {
                "size": " 159х10",
                "koef": "0.03698",
                "metrs": 1
            },
            {
                "size": " 159х12",
                "koef": "0.04378",
                "metrs": 1
            },
            {
                "size": " 159х16",
                "koef": "0.05678",
                "metrs": 1
            },
            {
                "size": " 160х8",
                "koef": "0.03018",
                "metrs": 1
            },
            {
                "size": " 160х12",
                "koef": "0.04408",
                "metrs": 1
            },
            {
                "size": " 168х6",
                "koef": "0.02412",
                "metrs": 1
            },
            {
                "size": " 200х6",
                "koef": "0.02889",
                "metrs": 1
            },
            {
                "size": " 219х4",
                "koef": "0.02134",
                "metrs": 1
            },
            {
                "size": " 219х4,5",
                "koef": "0.02396",
                "metrs": 1
            },
            {
                "size": " 219х5",
                "koef": "0.02656",
                "metrs": 1
            },
            {
                "size": " 219х6",
                "koef": "0.03172",
                "metrs": 1
            },
            {
                "size": " 219х7",
                "koef": "0.03683",
                "metrs": 1
            },
            {
                "size": "219х8",
                "koef": "0.04189",
                "metrs": 1
            },
            {
                "size": " 219х9",
                "koef": "0.04691",
                "metrs": 1
            },
            {
                "size": " 219х10",
                "koef": "0.05187",
                "metrs": 1
            },
            {
                "size": " 219х11",
                "koef": "0.05678",
                "metrs": 1
            },
            {
                "size": " 219х12",
                "koef": "0.06165",
                "metrs": 1
            },
            {
                "size": " 219х20",
                "koef": "0.09878",
                "metrs": 1
            },
            {
                "size": " 220х5",
                "koef": "0.02668",
                "metrs": 1
            },
            {
                "size": " 220х6,5",
                "koef": "0.03444",
                "metrs": 1
            },
            {
                "size": " 273х4",
                "koef": "0.0267",
                "metrs": 1
            },
            {
                "size": " 273х6",
                "koef": "0.03976",
                "metrs": 1
            },
            {
                "size": " 273х7",
                "koef": "0.04621",
                "metrs": 1
            },
            {
                "size": " 273х8",
                "koef": "0.05262",
                "metrs": 1
            },
            {
                "size": " 273х10",
                "koef": "0.06527",
                "metrs": 1
            },
            {
                "size": " 273х11",
                "koef": "0.07153",
                "metrs": 1
            },
            {
                "size": " 273х12",
                "koef": "0.07773",
                "metrs": 1
            },
            {
                "size": " 325х6",
                "koef": "0.0475",
                "metrs": 1
            },
            {
                "size": " 325х8",
                "koef": "0.06294",
                "metrs": 1
            },
            {
                "size": " 325х10",
                "koef": "0.07818",
                "metrs": 1
            },
            {
                "size": " 325х12",
                "koef": "0.09322",
                "metrs": 1
            },
            {
                "size": " 325х14",
                "koef": "0.1081",
                "metrs": 1
            },
            {
                "size": " 377х6",
                "koef": "0.05525",
                "metrs": 1
            },
            {
                "size": " 377х8",
                "koef": "0.07326",
                "metrs": 1
            },
            {
                "size": " 377х9",
                "koef": "0.0822",
                "metrs": 1
            },
            {
                "size": " 377х12",
                "koef": "0.1087",
                "metrs": 1
            },
            {
                "size": " 377х14",
                "koef": "0.1261",
                "metrs": 1
            },
            {
                "size": " 426х6",
                "koef": "0.06254",
                "metrs": 1
            },
            {
                "size": " 426х8",
                "koef": "0.08299",
                "metrs": 1
            },
            {
                "size": " 426х10",
                "koef": "0.1032",
                "metrs": 1
            },
            {
                "size": " 426х12",
                "koef": "0.1233",
                "metrs": 1
            },
            {
                "size": " 428,4х36,4",
                "koef": "0.3541",
                "metrs": 1
            },
            {
                "size": " 530х25",
                "koef": "0.3133",
                "metrs": 1
            },
            {
                "size": " 540х30",
                "koef": "0.3797",
                "metrs": 1
            }
        ],
        "Труба квадратная": [
            {
                "size": " 10х10х1",
                "koef": "0.000271",
                "metrs": 1
            },
            {
                "size": " 10х10х1,5",
                "koef": "0.000372",
                "metrs": 1
            },
            {
                "size": " 15х15х1",
                "koef": "0.000429",
                "metrs": 1
            },
            {
                "size": " 15х15х1,2",
                "koef": "0.000504",
                "metrs": 1
            },
            {
                "size": " 15х15х1,5",
                "koef": "0.000609",
                "metrs": 1
            },
            {
                "size": " 20х20х1 ",
                "koef": "0.000587",
                "metrs": 1
            },
            {
                "size": " 20х20х1,2",
                "koef": "0.000693",
                "metrs": 1
            },
            {
                "size": " 20х20х1,5",
                "koef": "0.000846",
                "metrs": 1
            },
            {
                "size": " 20х20х2 ",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 25х25х1 ",
                "koef": "0.000745",
                "metrs": 1
            },
            {
                "size": " 25х25х1,2",
                "koef": "0.000883",
                "metrs": 1
            },
            {
                "size": " 25х25х1,5",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 25х25х2",
                "koef": "0.001399",
                "metrs": 1
            },
            {
                "size": " 25х25х3",
                "koef": "0.001963",
                "metrs": 1
            },
            {
                "size": " 30х30х1",
                "koef": "0.000903",
                "metrs": 1
            },
            {
                "size": " 30х30х1,2",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 30х30х1,5",
                "koef": "0.00132",
                "metrs": 1
            },
            {
                "size": " 30х30х2",
                "koef": "0.001715",
                "metrs": 1
            },
            {
                "size": " 30х30х3",
                "koef": "0.002437",
                "metrs": 1
            },
            {
                "size": " 35х35х1,5",
                "koef": "0.001557",
                "metrs": 1
            },
            {
                "size": " 40х40х1",
                "koef": "0.001219",
                "metrs": 1
            },
            {
                "size": " 40х40х1,2 ",
                "koef": "0.001452",
                "metrs": 1
            },
            {
                "size": " 40х40х1,5",
                "koef": "0.001794",
                "metrs": 1
            },
            {
                "size": " 40х40х2",
                "koef": "0.002347",
                "metrs": 1
            },
            {
                "size": " 40х40х2,5",
                "koef": "0.002878",
                "metrs": 1
            },
            {
                "size": " 40х40х3",
                "koef": "0.003385",
                "metrs": 1
            },
            {
                "size": " 40х40х4",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 45х45х2",
                "koef": "0.002663",
                "metrs": 1
            },
            {
                "size": " 50х50х1,5 ",
                "koef": "0.002268",
                "metrs": 1
            },
            {
                "size": " 50х50х2",
                "koef": "0.002979",
                "metrs": 1
            },
            {
                "size": " 50х50х2,5",
                "koef": "0.003668",
                "metrs": 1
            },
            {
                "size": " 50х50х3",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 50х50х4",
                "koef": "0.005597",
                "metrs": 1
            },
            {
                "size": " 60х60х1,5",
                "koef": "0.002742",
                "metrs": 1
            },
            {
                "size": " 60х60х2",
                "koef": "0.003611",
                "metrs": 1
            },
            {
                "size": " 60х60х3 ",
                "koef": "0.005281",
                "metrs": 1
            },
            {
                "size": " 60х60х4",
                "koef": "0.006861",
                "metrs": 1
            },
            {
                "size": " 80х80х1,5",
                "koef": "0.00369",
                "metrs": 1
            },
            {
                "size": " 80х80х2",
                "koef": "0.004875",
                "metrs": 1
            },
            {
                "size": " 80х80х3",
                "koef": "0.007177",
                "metrs": 1
            },
            {
                "size": " 80х80х4",
                "koef": "0.009389",
                "metrs": 1
            },
            {
                "size": " 80х80х5 ",
                "koef": "0.01151",
                "metrs": 1
            },
            {
                "size": " 90х90х2",
                "koef": "0.005507",
                "metrs": 1
            },
            {
                "size": " 100х100х2",
                "koef": "0.006139",
                "metrs": 1
            },
            {
                "size": " 100х100х3",
                "koef": "0.009073",
                "metrs": 1
            },
            {
                "size": " 100х100х4",
                "koef": "0.01192",
                "metrs": 1
            },
            {
                "size": " 100х100х5",
                "koef": "0.01467",
                "metrs": 1
            },
            {
                "size": " 120х120х2",
                "koef": "0.007403",
                "metrs": 1
            },
            {
                "size": " 120х120х3",
                "koef": "0.01097",
                "metrs": 1
            },
            {
                "size": " 120х120х4",
                "koef": "0.01444",
                "metrs": 1
            },
            {
                "size": " 120х120х5",
                "koef": "0.01783",
                "metrs": 1
            },
            {
                "size": " 150х150х2",
                "koef": "0.009299",
                "metrs": 1
            },
            {
                "size": " 150х150х3",
                "koef": "0.01381",
                "metrs": 1
            },
            {
                "size": " 150х150х4",
                "koef": "0.01824",
                "metrs": 1
            },
            {
                "size": " 150х150х5",
                "koef": "0.02257",
                "metrs": 1
            },
            {
                "size": " 200х200х4",
                "koef": "0.02456",
                "metrs": 1
            },
            {
                "size": " 200х200х8",
                "koef": "0.04767",
                "metrs": 1
            }
        ],
        "Труба прямоугольная": [
            {
                "size": " 20х10х1,5",
                "koef": "0.000609",
                "metrs": 1
            },
            {
                "size": " 30х10х1,5",
                "koef": "0.000846",
                "metrs": 1
            },
            {
                "size": " 30х15х1,5",
                "koef": "0.000965",
                "metrs": 1
            },
            {
                "size": " 30х20х1,5",
                "koef": "0.001083",
                "metrs": 1
            },
            {
                "size": " 40х10х1",
                "koef": "0.000745",
                "metrs": 1
            },
            {
                "size": " 40х20х1",
                "koef": "0.000903",
                "metrs": 1
            },
            {
                "size": " 40х20х1,2",
                "koef": "0.001073",
                "metrs": 1
            },
            {
                "size": " 40х20х1,5",
                "koef": "0.00132",
                "metrs": 1
            },
            {
                "size": " 40х20х2",
                "koef": "0.001715",
                "metrs": 1
            },
            {
                "size": " 40х30х1,5",
                "koef": "0.001557",
                "metrs": 1
            },
            {
                "size": " 50х20х2",
                "koef": "0.002031",
                "metrs": 1
            },
            {
                "size": " 50х25х1,5",
                "koef": "0.001676",
                "metrs": 1
            },
            {
                "size": " 50х25х2",
                "koef": "0.002189",
                "metrs": 1
            },
            {
                "size": " 50х25х3",
                "koef": "0.003148",
                "metrs": 1
            },
            {
                "size": " 50х30х1,5",
                "koef": "0.001794",
                "metrs": 1
            },
            {
                "size": " 60х20х1,5",
                "koef": "0.001794",
                "metrs": 1
            },
            {
                "size": " 60х30х1,5",
                "koef": "0.002031",
                "metrs": 1
            },
            {
                "size": " 60х30х2",
                "koef": "0.002663",
                "metrs": 1
            },
            {
                "size": " 60х30х3",
                "koef": "0.003859",
                "metrs": 1
            },
            {
                "size": " 60х40х1,5",
                "koef": "0.002268",
                "metrs": 1
            },
            {
                "size": " 60х40х2 ",
                "koef": "0.002979",
                "metrs": 1
            },
            {
                "size": " 60х40х3",
                "koef": "0.004333",
                "metrs": 1
            },
            {
                "size": " 80х20х1,5",
                "koef": "0.002268",
                "metrs": 1
            },
            {
                "size": " 80х40х1,5",
                "koef": "0.002742",
                "metrs": 1
            },
            {
                "size": " 80х40х2",
                "koef": "0.003611",
                "metrs": 1
            },
            {
                "size": " 80х40х3",
                "koef": "0.005281",
                "metrs": 1
            },
            {
                "size": " 80х40х5",
                "koef": "0.00835",
                "metrs": 1
            },
            {
                "size": " 100х50х2",
                "koef": "0.004559",
                "metrs": 1
            },
            {
                "size": " 100х50х3",
                "koef": "0.006703",
                "metrs": 1
            },
            {
                "size": " 100х50х4",
                "koef": "0.008757",
                "metrs": 1
            },
            {
                "size": " 100х50х5",
                "koef": "0.01072",
                "metrs": 1
            },
            {
                "size": " 100х60х3",
                "koef": "0.007177",
                "metrs": 1
            },
            {
                "size": " 120х60х2",
                "koef": "0.005507",
                "metrs": 1
            },
            {
                "size": " 150х50х2",
                "koef": "0.006139",
                "metrs": 1
            },
            {
                "size": " 150х100х3",
                "koef": "0.01144",
                "metrs": 1
            },
            {
                "size": " 150х100х4",
                "koef": "0.01508",
                "metrs": 1
            },
            {
                "size": " 200х50х2",
                "koef": "0.007719",
                "metrs": 1
            },
            {
                "size": " 200х100х2",
                "koef": "0.009299",
                "metrs": 1
            },
            {
                "size": " 200х100х3",
                "koef": "0.01381",
                "metrs": 1
            }
        ],
        "Труба электросварная": [
            {
                "size": "1,8х0,25",
                "koef": "0.00001",
                "metrs": 1
            },
            {
                "size": "8х1 ",
                "koef": "0.000174",
                "metrs": 1
            },
            {
                "size": "10х0,5",
                "koef": "0.000118",
                "metrs": 1
            },
            {
                "size": "10х1",
                "koef": "0.000223",
                "metrs": 1
            },
            {
                "size": "10х1,5 ",
                "koef": "0.000316",
                "metrs": 1
            },
            {
                "size": "12х1",
                "koef": "0.000273",
                "metrs": 1
            },
            {
                "size": "12х1,5",
                "koef": "0.000391",
                "metrs": 1
            },
            {
                "size": "12х2",
                "koef": "0.000496",
                "metrs": 1
            },
            {
                "size": " 14х1",
                "koef": "0.000323",
                "metrs": 1
            },
            {
                "size": "14х1,5",
                "koef": "0.000465",
                "metrs": 1
            },
            {
                "size": "14х2",
                "koef": "0.000596",
                "metrs": 1
            },
            {
                "size": "15х3",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "16х0,6",
                "koef": "0.000229",
                "metrs": 1
            },
            {
                "size": "16х1 ",
                "koef": "0.000372",
                "metrs": 1
            },
            {
                "size": "16х1,2",
                "koef": "0.000441",
                "metrs": 1
            },
            {
                "size": "16х1,5",
                "koef": "0.00054",
                "metrs": 1
            },
            {
                "size": "16х2 ",
                "koef": "0.000695",
                "metrs": 1
            },
            {
                "size": "16х2,2",
                "koef": "0.000753",
                "metrs": 1
            },
            {
                "size": "17,1х1,65",
                "koef": "0.000633",
                "metrs": 1
            },
            {
                "size": "18х0,3",
                "koef": "0.000132",
                "metrs": 1
            },
            {
                "size": "18х1",
                "koef": "0.000422",
                "metrs": 1
            },
            {
                "size": "18х1,5",
                "koef": "0.000614",
                "metrs": 1
            },
            {
                "size": "18х2",
                "koef": "0.000794",
                "metrs": 1
            },
            {
                "size": "18х3",
                "koef": "0.001117",
                "metrs": 1
            },
            {
                "size": "19х1,5",
                "koef": "0.000651",
                "metrs": 1
            },
            {
                "size": "20х1",
                "koef": "0.000472",
                "metrs": 1
            },
            {
                "size": "20х1,2",
                "koef": "0.00056",
                "metrs": 1
            },
            {
                "size": "20х1,5",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": "20х2",
                "koef": "0.000893",
                "metrs": 1
            },
            {
                "size": "20х2,5",
                "koef": "0.001086",
                "metrs": 1
            },
            {
                "size": "20х3",
                "koef": "0.001266",
                "metrs": 1
            },
            {
                "size": "20х3,5",
                "koef": "0.001433",
                "metrs": 1
            },
            {
                "size": "21х3",
                "koef": "0.00134",
                "metrs": 1
            },
            {
                "size": "21,3х1,5",
                "koef": "0.000737",
                "metrs": 1
            },
            {
                "size": "21,3х2",
                "koef": "0.000958",
                "metrs": 1
            },
            {
                "size": "21,3х2,5",
                "koef": "0.001166",
                "metrs": 1
            },
            {
                "size": "21,3х3",
                "koef": "0.001363",
                "metrs": 1
            },
            {
                "size": "21,34х4,78",
                "koef": "0.001965",
                "metrs": 1
            },
            {
                "size": "22х1",
                "koef": "0.000521",
                "metrs": 1
            },
            {
                "size": "22х1,5",
                "koef": "0.000763",
                "metrs": 1
            },
            {
                "size": "22х2",
                "koef": "0.000993",
                "metrs": 1
            },
            {
                "size": "22х3",
                "koef": "0.001415",
                "metrs": 1
            },
            {
                "size": "23х1,5",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": "25х1",
                "koef": "0.000596",
                "metrs": 1
            },
            {
                "size": "25х1,5",
                "koef": "0.000875",
                "metrs": 1
            },
            {
                "size": "25х2",
                "koef": "0.001142",
                "metrs": 1
            },
            {
                "size": "25х2,5",
                "koef": "0.001396",
                "metrs": 1
            },
            {
                "size": "25х3",
                "koef": "0.001638",
                "metrs": 1
            },
            {
                "size": "26,9х2",
                "koef": "0.001236",
                "metrs": 1
            },
            {
                "size": "26,9х2,5",
                "koef": "0.001514",
                "metrs": 1
            },
            {
                "size": "26,9х3",
                "koef": "0.001779",
                "metrs": 1
            },
            {
                "size": "27х3,5",
                "koef": "0.002041",
                "metrs": 1
            },
            {
                "size": "28х1,5",
                "koef": "0.000987",
                "metrs": 1
            },
            {
                "size": "28х2",
                "koef": "0.001291",
                "metrs": 1
            },
            {
                "size": "28х3",
                "koef": "0.001861",
                "metrs": 1
            },
            {
                "size": "29х1,5",
                "koef": "0.001024",
                "metrs": 1
            },
            {
                "size": "30х1,5",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": "30х2",
                "koef": "0.00139",
                "metrs": 1
            },
            {
                "size": "32х1",
                "koef": "0.000769",
                "metrs": 1
            },
            {
                "size": "32х1,5",
                "koef": "0.001135",
                "metrs": 1
            },
            {
                "size": "32х2",
                "koef": "0.001489",
                "metrs": 1
            },
            {
                "size": "32х2,5",
                "koef": "0.00183",
                "metrs": 1
            },
            {
                "size": "32х3",
                "koef": "0.002159",
                "metrs": 1
            },
            {
                "size": "32х4",
                "koef": "0.00278",
                "metrs": 1
            },
            {
                "size": "33х1,5",
                "koef": "0.001173",
                "metrs": 1
            },
            {
                "size": "33,7х1,5",
                "koef": "0.001199",
                "metrs": 1
            },
            {
                "size": "33,7х2",
                "koef": "0.001573",
                "metrs": 1
            },
            {
                "size": "33,7х2,5",
                "koef": "0.001936",
                "metrs": 1
            },
            {
                "size": "33,7х3",
                "koef": "0.002286",
                "metrs": 1
            },
            {
                "size": "34х1,5",
                "koef": "0.00121",
                "metrs": 1
            },
            {
                "size": "34х2,5",
                "koef": "0.001954",
                "metrs": 1
            },
            {
                "size": "35х1,5",
                "koef": "0.001247",
                "metrs": 1
            },
            {
                "size": "38х1",
                "koef": "0.000918",
                "metrs": 1
            },
            {
                "size": "38х1,2",
                "koef": "0.001096",
                "metrs": 1
            },
            {
                "size": "38х1,5",
                "koef": "0.001359",
                "metrs": 1
            },
            {
                "size": "38х2",
                "koef": "0.001787",
                "metrs": 1
            },
            {
                "size": "38х2,5",
                "koef": "0.002203",
                "metrs": 1
            },
            {
                "size": "38х3",
                "koef": "0.002606",
                "metrs": 1
            },
            {
                "size": "38х5",
                "koef": "0.004095",
                "metrs": 1
            },
            {
                "size": "40х1,5",
                "koef": "0.001433",
                "metrs": 1
            },
            {
                "size": "40х2",
                "koef": "0.001886",
                "metrs": 1
            },
            {
                "size": "40х3",
                "koef": "0.002755",
                "metrs": 1
            },
            {
                "size": "41х1,5",
                "koef": "0.00147",
                "metrs": 1
            },
            {
                "size": "42х3",
                "koef": "0.002904",
                "metrs": 1
            },
            {
                "size": "42,4х1,2",
                "koef": "0.001227",
                "metrs": 1
            },
            {
                "size": "42,4х1,5",
                "koef": "0.001523",
                "metrs": 1
            },
            {
                "size": "42,4х2",
                "koef": "0.002005",
                "metrs": 1
            },
            {
                "size": "42,4х3",
                "koef": "0.002934",
                "metrs": 1
            },
            {
                "size": "43х1,5",
                "koef": "0.001545",
                "metrs": 1
            },
            {
                "size": "45х3",
                "koef": "0.003127",
                "metrs": 1
            },
            {
                "size": "48х1,5",
                "koef": "0.001731",
                "metrs": 1
            },
            {
                "size": "48х3",
                "koef": "0.003351",
                "metrs": 1
            },
            {
                "size": "48х5",
                "koef": "0.005336",
                "metrs": 1
            },
            {
                "size": "48,3х1,5",
                "koef": "0.001742",
                "metrs": 1
            },
            {
                "size": "48,3х2",
                "koef": "0.002298",
                "metrs": 1
            },
            {
                "size": "48,3х2,5",
                "koef": "0.002842",
                "metrs": 1
            },
            {
                "size": "48,3х2,77",
                "koef": "0.00313",
                "metrs": 1
            },
            {
                "size": "48,3х3",
                "koef": "0.003373",
                "metrs": 1
            },
            {
                "size": "50х1,5",
                "koef": "0.001806",
                "metrs": 1
            },
            {
                "size": "50х2",
                "koef": "0.002383",
                "metrs": 1
            },
            {
                "size": "50,8х1,2",
                "koef": "0.001477",
                "metrs": 1
            },
            {
                "size": "50,8х1,5",
                "koef": "0.001835",
                "metrs": 1
            },
            {
                "size": "50,8х2",
                "koef": "0.002422",
                "metrs": 1
            },
            {
                "size": "50,8х3",
                "koef": "0.003559",
                "metrs": 1
            },
            {
                "size": "51х2,5",
                "koef": "0.003009",
                "metrs": 1
            },
            {
                "size": "52х1",
                "koef": "0.001266",
                "metrs": 1
            },
            {
                "size": "52х1,5",
                "koef": "0.00188",
                "metrs": 1
            },
            {
                "size": "53х1,5",
                "koef": "0.001917",
                "metrs": 1
            },
            {
                "size": "54х2",
                "koef": "0.002581",
                "metrs": 1
            },
            {
                "size": "54х2,5",
                "koef": "0.003195",
                "metrs": 1
            },
            {
                "size": "56х3",
                "koef": "0.003946",
                "metrs": 1
            },
            {
                "size": "56х4",
                "koef": "0.005162",
                "metrs": 1
            },
            {
                "size": "57х1,5",
                "koef": "0.002066",
                "metrs": 1
            },
            {
                "size": "57х2",
                "koef": "0.00273",
                "metrs": 1
            },
            {
                "size": "57х2,5",
                "koef": "0.003382",
                "metrs": 1
            },
            {
                "size": "57х3",
                "koef": "0.004021",
                "metrs": 1
            },
            {
                "size": "57х3,5",
                "koef": "0.004647",
                "metrs": 1
            },
            {
                "size": "57х4",
                "koef": "0.005262",
                "metrs": 1
            },
            {
                "size": "60х1,5",
                "koef": "0.002178",
                "metrs": 1
            },
            {
                "size": "60х2",
                "koef": "0.002879",
                "metrs": 1
            },
            {
                "size": "60х2,5",
                "koef": "0.003568",
                "metrs": 1
            },
            {
                "size": "60х4",
                "koef": "0.005559",
                "metrs": 1
            },
            {
                "size": "60х5,5",
                "koef": "0.007439",
                "metrs": 1
            },
            {
                "size": "60,3х1,5",
                "koef": "0.002189",
                "metrs": 1
            },
            {
                "size": "60,3х2",
                "koef": "0.002894",
                "metrs": 1
            },
            {
                "size": "60,3х2,5",
                "koef": "0.003586",
                "metrs": 1
            },
            {
                "size": "60,3х3",
                "koef": "0.004266",
                "metrs": 1
            },
            {
                "size": "63,5х1,5",
                "koef": "0.002308",
                "metrs": 1
            },
            {
                "size": "63,5х2",
                "koef": "0.003053",
                "metrs": 1
            },
            {
                "size": "63,5х3",
                "koef": "0.004505",
                "metrs": 1
            },
            {
                "size": "65х3",
                "koef": "0.004616",
                "metrs": 1
            },
            {
                "size": "70х2",
                "koef": "0.003375",
                "metrs": 1
            },
            {
                "size": "70х3,5",
                "koef": "0.005777",
                "metrs": 1
            },
            {
                "size": "73х3",
                "koef": "0.005212",
                "metrs": 1
            },
            {
                "size": "76х1,5",
                "koef": "0.002773",
                "metrs": 1
            },
            {
                "size": "76х2",
                "koef": "0.003673",
                "metrs": 1
            },
            {
                "size": "76х4",
                "koef": "0.007148",
                "metrs": 1
            },
            {
                "size": "76х6",
                "koef": "0.01042",
                "metrs": 1
            },
            {
                "size": "76,1х1,5",
                "koef": "0.002777",
                "metrs": 1
            },
            {
                "size": "76,1х2",
                "koef": "0.003678",
                "metrs": 1
            },
            {
                "size": "76,1х2,5",
                "koef": "0.004567",
                "metrs": 1
            },
            {
                "size": "76,1х3",
                "koef": "0.005443",
                "metrs": 1
            },
            {
                "size": "76,1х4",
                "koef": "0.007158",
                "metrs": 1
            },
            {
                "size": "84х2",
                "koef": "0.00407",
                "metrs": 1
            },
            {
                "size": "85х2",
                "koef": "0.00412",
                "metrs": 1
            },
            {
                "size": "88,9х1,5",
                "koef": "0.003254",
                "metrs": 1
            },
            {
                "size": "88,9х2",
                "koef": "0.004313",
                "metrs": 1
            },
            {
                "size": "88,9х3",
                "koef": "0.006396",
                "metrs": 1
            },
            {
                "size": "88,9х3,05",
                "koef": "0.006499",
                "metrs": 1
            },
            {
                "size": "88,9х4",
                "koef": "0.008428",
                "metrs": 1
            },
            {
                "size": "89х4",
                "koef": "0.008438",
                "metrs": 1
            },
            {
                "size": "89х6",
                "koef": "0.01236",
                "metrs": 1
            },
            {
                "size": "89х10",
                "koef": "0.01961",
                "metrs": 1
            },
            {
                "size": "101,6х1,5",
                "koef": "0.003727",
                "metrs": 1
            },
            {
                "size": "101,6х2",
                "koef": "0.004944",
                "metrs": 1
            },
            {
                "size": "101,6х3",
                "koef": "0.007341",
                "metrs": 1
            },
            {
                "size": "101,6х4",
                "koef": "0.009689",
                "metrs": 1
            },
            {
                "size": "104х2",
                "koef": "0.005063",
                "metrs": 1
            },
            {
                "size": "108х2",
                "koef": "0.005262",
                "metrs": 1
            },
            {
                "size": "108х3",
                "koef": "0.007818",
                "metrs": 1
            },
            {
                "size": "108х4",
                "koef": "0.01032",
                "metrs": 1
            },
            {
                "size": "108х5",
                "koef": "0.01278",
                "metrs": 1
            },
            {
                "size": "108х12",
                "koef": "0.02859",
                "metrs": 1
            },
            {
                "size": "114х5",
                "koef": "0.01353",
                "metrs": 1
            },
            {
                "size": "114,3х1,5",
                "koef": "0.004199",
                "metrs": 1
            },
            {
                "size": "114,3х2",
                "koef": "0.005574",
                "metrs": 1
            },
            {
                "size": "114,3х3",
                "koef": "0.008287",
                "metrs": 1
            },
            {
                "size": "114,3х4",
                "koef": "0.01095",
                "metrs": 1
            },
            {
                "size": "121х5",
                "koef": "0.01439",
                "metrs": 1
            },
            {
                "size": "127х10",
                "koef": "0.02904",
                "metrs": 1
            },
            {
                "size": "129х2",
                "koef": "0.006304",
                "metrs": 1
            },
            {
                "size": "133х5",
                "koef": "0.01588",
                "metrs": 1
            },
            {
                "size": "133х6",
                "koef": "0.01891",
                "metrs": 1
            },
            {
                "size": "139,7х2",
                "koef": "0.006835",
                "metrs": 1
            },
            {
                "size": "139,7х3",
                "koef": "0.01018",
                "metrs": 1
            },
            {
                "size": "139,7х4",
                "koef": "0.01347",
                "metrs": 1
            },
            {
                "size": "140х16",
                "koef": "0.04924",
                "metrs": 1
            },
            {
                "size": "159х2",
                "koef": "0.007793",
                "metrs": 1
            },
            {
                "size": "159х3",
                "koef": "0.01162",
                "metrs": 1
            },
            {
                "size": "159х4",
                "koef": "0.01539",
                "metrs": 1
            },
            {
                "size": "159х5",
                "koef": "0.01911",
                "metrs": 1
            },
            {
                "size": "159х14",
                "koef": "0.05038",
                "metrs": 1
            },
            {
                "size": "168х12",
                "koef": "0.04646",
                "metrs": 1
            },
            {
                "size": "168,3х3",
                "koef": "0.01231",
                "metrs": 1
            },
            {
                "size": "168,3х5",
                "koef": "0.02026",
                "metrs": 1
            },
            {
                "size": "219х4,5",
                "koef": "0.02396",
                "metrs": 1
            },
            {
                "size": "219х8",
                "koef": "0.04189",
                "metrs": 1
            },
            {
                "size": "219х10",
                "koef": "0.05187",
                "metrs": 1
            },
            {
                "size": "219,1х2",
                "koef": "0.01078",
                "metrs": 1
            },
            {
                "size": "245х20",
                "koef": "0.1117",
                "metrs": 1
            },
            {
                "size": "273х4",
                "koef": "0.0267",
                "metrs": 1
            },
            {
                "size": "273х5",
                "koef": "0.03326",
                "metrs": 1
            },
            {
                "size": "273х6",
                "koef": "0.03976",
                "metrs": 1
            },
            {
                "size": "273х11",
                "koef": "0.07153",
                "metrs": 1
            },
            {
                "size": "323,9х2",
                "koef": "0.01598",
                "metrs": 1
            },
            {
                "size": "323,9х3",
                "koef": "0.02389",
                "metrs": 1
            },
            {
                "size": "325х4",
                "koef": "0.03187",
                "metrs": 1
            },
            {
                "size": "325х5",
                "koef": "0.03971",
                "metrs": 1
            },
            {
                "size": "325х6",
                "koef": "0.0475",
                "metrs": 1
            },
            {
                "size": "325х7",
                "koef": "0.05525",
                "metrs": 1
            },
            {
                "size": "325х8",
                "koef": "0.06294",
                "metrs": 1
            },
            {
                "size": "325х9",
                "koef": "0.07058",
                "metrs": 1
            },
            {
                "size": "325х10",
                "koef": "0.07818",
                "metrs": 1
            },
            {
                "size": "325х11",
                "koef": "0.08572",
                "metrs": 1
            },
            {
                "size": "325х12",
                "koef": "0.09322",
                "metrs": 1
            },
            {
                "size": "355х4",
                "koef": "0.03485",
                "metrs": 1
            },
            {
                "size": "377х4",
                "koef": "0.03703",
                "metrs": 1
            },
            {
                "size": "377х4,5",
                "koef": "0.0416",
                "metrs": 1
            },
            {
                "size": "377х5",
                "koef": "0.04616",
                "metrs": 1
            },
            {
                "size": "377х6",
                "koef": "0.05525",
                "metrs": 1
            },
            {
                "size": "377х7",
                "koef": "0.06428",
                "metrs": 1
            },
            {
                "size": "377х8",
                "koef": "0.07326",
                "metrs": 1
            },
            {
                "size": "377х9",
                "koef": "0.0822",
                "metrs": 1
            },
            {
                "size": "377х10",
                "koef": "0.09108",
                "metrs": 1
            },
            {
                "size": "377х11",
                "koef": "0.09992",
                "metrs": 1
            },
            {
                "size": "377х12",
                "koef": "0.1087",
                "metrs": 1
            },
            {
                "size": "377х13",
                "koef": "0.1174",
                "metrs": 1
            },
            {
                "size": "377х14",
                "koef": "0.1261",
                "metrs": 1
            },
            {
                "size": "377х15",
                "koef": "0.1348",
                "metrs": 1
            },
            {
                "size": "377х16",
                "koef": "0.1434",
                "metrs": 1
            },
            {
                "size": "1000х10",
                "koef": "0.2457",
                "metrs": 1
            },
            {
                "size": "1000х11",
                "koef": "0.27",
                "metrs": 1
            },
            {
                "size": "1000х12",
                "koef": "0.2942",
                "metrs": 1
            },
            {
                "size": "1000х13",
                "koef": "0.3184",
                "metrs": 1
            },
            {
                "size": "1000х14",
                "koef": "0.3426",
                "metrs": 1
            },
            {
                "size": "1000х15",
                "koef": "0.3667",
                "metrs": 1
            },
            {
                "size": "1000х16",
                "koef": "0.3907",
                "metrs": 1
            },
            {
                "size": "1000х17",
                "koef": "0.4147",
                "metrs": 1
            },
            {
                "size": "1000х18",
                "koef": "0.4387",
                "metrs": 1
            },
            {
                "size": "1000х19",
                "koef": "0.4626",
                "metrs": 1
            },
            {
                "size": "1000х20",
                "koef": "0.4864",
                "metrs": 1
            },
            {
                "size": "1000х21",
                "koef": "0.5102",
                "metrs": 1
            },
            {
                "size": "1000х22",
                "koef": "0.534",
                "metrs": 1
            },
            {
                "size": "1000х23",
                "koef": "0.5577",
                "metrs": 1
            },
            {
                "size": "1000х24",
                "koef": "0.5813",
                "metrs": 1
            },
            {
                "size": "1000х25",
                "koef": "0.605",
                "metrs": 1
            },
            {
                "size": "1000х26",
                "koef": "0.6285",
                "metrs": 1
            },
            {
                "size": "1000х27",
                "koef": "0.652",
                "metrs": 1
            },
            {
                "size": "1000х28",
                "koef": "0.6755",
                "metrs": 1
            },
            {
                "size": "1000х29",
                "koef": "0.6989",
                "metrs": 1
            },
            {
                "size": "1000х30",
                "koef": "0.7222",
                "metrs": 1
            },
            {
                "size": "1000х31",
                "koef": "0.7455",
                "metrs": 1
            },
            {
                "size": "1000х32",
                "koef": "0.7688",
                "metrs": 1
            },
            {
                "size": "1000х33",
                "koef": "0.792",
                "metrs": 1
            },
            {
                "size": "1000х34",
                "koef": "0.8151",
                "metrs": 1
            },
            {
                "size": "1000х35",
                "koef": "0.8382",
                "metrs": 1
            },
            {
                "size": "1000х36",
                "koef": "0.8613",
                "metrs": 1
            },
            {
                "size": "1000х37",
                "koef": "0.8843",
                "metrs": 1
            },
            {
                "size": "1000х38",
                "koef": "0.9073",
                "metrs": 1
            },
            {
                "size": "1000х39",
                "koef": "0.9302",
                "metrs": 1
            },
            {
                "size": "1000х4",
                "koef": "0.09888",
                "metrs": 1
            },
            {
                "size": "1000х40",
                "koef": "0.953",
                "metrs": 1
            },
            {
                "size": "1000х45",
                "koef": "1.067",
                "metrs": 1
            },
            {
                "size": "1000х5",
                "koef": "0.1235",
                "metrs": 1
            },
            {
                "size": "1000х50",
                "koef": "1.179",
                "metrs": 1
            },
            {
                "size": "1000х6",
                "koef": "0.148",
                "metrs": 1
            },
            {
                "size": "1000х7",
                "koef": "0.1725",
                "metrs": 1
            },
            {
                "size": "1000х8",
                "koef": "0.197",
                "metrs": 1
            },
            {
                "size": "1000х9",
                "koef": "0.2214",
                "metrs": 1
            },
            {
                "size": "1010х10",
                "koef": "0.2482",
                "metrs": 1
            },
            {
                "size": "1010х11",
                "koef": "0.2727",
                "metrs": 1
            },
            {
                "size": "1010х12",
                "koef": "0.2972",
                "metrs": 1
            },
            {
                "size": "1010х13",
                "koef": "0.3217",
                "metrs": 1
            },
            {
                "size": "1010х14",
                "koef": "0.3461",
                "metrs": 1
            },
            {
                "size": "1010х15",
                "koef": "0.3704",
                "metrs": 1
            },
            {
                "size": "1010х16",
                "koef": "0.3947",
                "metrs": 1
            },
            {
                "size": "1010х18",
                "koef": "0.4432",
                "metrs": 1
            },
            {
                "size": "1010х20",
                "koef": "0.4914",
                "metrs": 1
            },
            {
                "size": "1010х25",
                "koef": "0.6112",
                "metrs": 1
            },
            {
                "size": "1010х4",
                "koef": "0.09987",
                "metrs": 1
            },
            {
                "size": "1010х5",
                "koef": "0.1247",
                "metrs": 1
            },
            {
                "size": "1010х6",
                "koef": "0.1495",
                "metrs": 1
            },
            {
                "size": "1010х7",
                "koef": "0.1743",
                "metrs": 1
            },
            {
                "size": "1010х8",
                "koef": "0.1989",
                "metrs": 1
            },
            {
                "size": "1010х9",
                "koef": "0.2236",
                "metrs": 1
            },
            {
                "size": "1016х10",
                "koef": "0.2497",
                "metrs": 1
            },
            {
                "size": "1016х11",
                "koef": "0.2744",
                "metrs": 1
            },
            {
                "size": "1016х12",
                "koef": "0.299",
                "metrs": 1
            },
            {
                "size": "1016х13",
                "koef": "0.3236",
                "metrs": 1
            },
            {
                "size": "1016х14",
                "koef": "0.3482",
                "metrs": 1
            },
            {
                "size": "1016х15",
                "koef": "0.3727",
                "metrs": 1
            },
            {
                "size": "1016х16",
                "koef": "0.3971",
                "metrs": 1
            },
            {
                "size": "1016х17",
                "koef": "0.4215",
                "metrs": 1
            },
            {
                "size": "1016х18",
                "koef": "0.4458",
                "metrs": 1
            },
            {
                "size": "1016х19",
                "koef": "0.4701",
                "metrs": 1
            },
            {
                "size": "1016х20",
                "koef": "0.4944",
                "metrs": 1
            },
            {
                "size": "1016х21",
                "koef": "0.5186",
                "metrs": 1
            },
            {
                "size": "1016х22",
                "koef": "0.5427",
                "metrs": 1
            },
            {
                "size": "1016х23",
                "koef": "0.5668",
                "metrs": 1
            },
            {
                "size": "1016х24",
                "koef": "0.5909",
                "metrs": 1
            },
            {
                "size": "1016х25",
                "koef": "0.6149",
                "metrs": 1
            },
            {
                "size": "1016х26",
                "koef": "0.6388",
                "metrs": 1
            },
            {
                "size": "1016х27",
                "koef": "0.6627",
                "metrs": 1
            },
            {
                "size": "1016х28",
                "koef": "0.6866",
                "metrs": 1
            },
            {
                "size": "1016х29",
                "koef": "0.7104",
                "metrs": 1
            },
            {
                "size": "1016х30",
                "koef": "0.7341",
                "metrs": 1
            },
            {
                "size": "1016х31",
                "koef": "0.7578",
                "metrs": 1
            },
            {
                "size": "1016х32",
                "koef": "0.7815",
                "metrs": 1
            },
            {
                "size": "1016х33",
                "koef": "0.8051",
                "metrs": 1
            },
            {
                "size": "1016х34",
                "koef": "0.8286",
                "metrs": 1
            },
            {
                "size": "1016х35",
                "koef": "0.8521",
                "metrs": 1
            },
            {
                "size": "1016х36",
                "koef": "0.8756",
                "metrs": 1
            },
            {
                "size": "1016х37",
                "koef": "0.899",
                "metrs": 1
            },
            {
                "size": "1016х38",
                "koef": "0.9224",
                "metrs": 1
            },
            {
                "size": "1016х39",
                "koef": "0.9457",
                "metrs": 1
            },
            {
                "size": "1016х4",
                "koef": "0.1005",
                "metrs": 1
            },
            {
                "size": "1016х40",
                "koef": "0.9689",
                "metrs": 1
            },
            {
                "size": "1016х5",
                "koef": "0.1255",
                "metrs": 1
            },
            {
                "size": "1016х6",
                "koef": "0.1504",
                "metrs": 1
            },
            {
                "size": "1016х7",
                "koef": "0.1753",
                "metrs": 1
            },
            {
                "size": "1016х8",
                "koef": "0.2001",
                "metrs": 1
            },
            {
                "size": "1016х9",
                "koef": "0.2249",
                "metrs": 1
            },
            {
                "size": "1020х10",
                "koef": "0.2507",
                "metrs": 1
            },
            {
                "size": "1020х11",
                "koef": "0.2755",
                "metrs": 1
            },
            {
                "size": "1020х12",
                "koef": "0.3002",
                "metrs": 1
            },
            {
                "size": "1020х13",
                "koef": "0.3249",
                "metrs": 1
            },
            {
                "size": "1020х14",
                "koef": "0.3495",
                "metrs": 1
            },
            {
                "size": "1020х15",
                "koef": "0.3741",
                "metrs": 1
            },
            {
                "size": "1020х16",
                "koef": "0.3987",
                "metrs": 1
            },
            {
                "size": "1020х17",
                "koef": "0.4232",
                "metrs": 1
            },
            {
                "size": "1020х18",
                "koef": "0.4476",
                "metrs": 1
            },
            {
                "size": "1020х19",
                "koef": "0.472",
                "metrs": 1
            },
            {
                "size": "1020х20",
                "koef": "0.4964",
                "metrs": 1
            },
            {
                "size": "1020х21",
                "koef": "0.5207",
                "metrs": 1
            },
            {
                "size": "1020х22",
                "koef": "0.5449",
                "metrs": 1
            },
            {
                "size": "1020х23",
                "koef": "0.5691",
                "metrs": 1
            },
            {
                "size": "1020х24",
                "koef": "0.5933",
                "metrs": 1
            },
            {
                "size": "1020х25",
                "koef": "0.6174",
                "metrs": 1
            },
            {
                "size": "1020х26",
                "koef": "0.6414",
                "metrs": 1
            },
            {
                "size": "1020х27",
                "koef": "0.6654",
                "metrs": 1
            },
            {
                "size": "1020х28",
                "koef": "0.6894",
                "metrs": 1
            },
            {
                "size": "1020х29",
                "koef": "0.7133",
                "metrs": 1
            },
            {
                "size": "1020х30",
                "koef": "0.7371",
                "metrs": 1
            },
            {
                "size": "1020х31",
                "koef": "0.7609",
                "metrs": 1
            },
            {
                "size": "1020х32",
                "koef": "0.7847",
                "metrs": 1
            },
            {
                "size": "1020х33",
                "koef": "0.8084",
                "metrs": 1
            },
            {
                "size": "1020х34",
                "koef": "0.832",
                "metrs": 1
            },
            {
                "size": "1020х35",
                "koef": "0.8556",
                "metrs": 1
            },
            {
                "size": "1020х36",
                "koef": "0.8792",
                "metrs": 1
            },
            {
                "size": "1020х37",
                "koef": "0.9027",
                "metrs": 1
            },
            {
                "size": "1020х38",
                "koef": "0.9261",
                "metrs": 1
            },
            {
                "size": "1020х39",
                "koef": "0.9495",
                "metrs": 1
            },
            {
                "size": "1020х4",
                "koef": "0.1009",
                "metrs": 1
            },
            {
                "size": "1020х40",
                "koef": "0.9729",
                "metrs": 1
            },
            {
                "size": "1020х41",
                "koef": "0.9962",
                "metrs": 1
            },
            {
                "size": "1020х42",
                "koef": "1.019",
                "metrs": 1
            },
            {
                "size": "1020х45",
                "koef": "1.089",
                "metrs": 1
            },
            {
                "size": "1020х5",
                "koef": "0.126",
                "metrs": 1
            },
            {
                "size": "1020х50",
                "koef": "1.204",
                "metrs": 1
            },
            {
                "size": "1020х6",
                "koef": "0.151",
                "metrs": 1
            },
            {
                "size": "1020х7",
                "koef": "0.176",
                "metrs": 1
            },
            {
                "size": "1020х8",
                "koef": "0.2009",
                "metrs": 1
            },
            {
                "size": "1020х9",
                "koef": "0.2258",
                "metrs": 1
            },
            {
                "size": "1120х10",
                "koef": "0.2755",
                "metrs": 1
            },
            {
                "size": "1120х11",
                "koef": "0.3028",
                "metrs": 1
            },
            {
                "size": "1120х12",
                "koef": "0.33",
                "metrs": 1
            },
            {
                "size": "1120х13",
                "koef": "0.3572",
                "metrs": 1
            },
            {
                "size": "1120х14",
                "koef": "0.3843",
                "metrs": 1
            },
            {
                "size": "1120х15",
                "koef": "0.4114",
                "metrs": 1
            },
            {
                "size": "1120х16",
                "koef": "0.4384",
                "metrs": 1
            },
            {
                "size": "1120х17",
                "koef": "0.4654",
                "metrs": 1
            },
            {
                "size": "1120х18",
                "koef": "0.4923",
                "metrs": 1
            },
            {
                "size": "1120х19",
                "koef": "0.5192",
                "metrs": 1
            },
            {
                "size": "1120х20",
                "koef": "0.546",
                "metrs": 1
            },
            {
                "size": "1120х21",
                "koef": "0.5728",
                "metrs": 1
            },
            {
                "size": "1120х22",
                "koef": "0.5995",
                "metrs": 1
            },
            {
                "size": "1120х23",
                "koef": "0.6262",
                "metrs": 1
            },
            {
                "size": "1120х24",
                "koef": "0.6528",
                "metrs": 1
            },
            {
                "size": "1120х25",
                "koef": "0.6794",
                "metrs": 1
            },
            {
                "size": "1120х26",
                "koef": "0.7059",
                "metrs": 1
            },
            {
                "size": "1120х27",
                "koef": "0.7324",
                "metrs": 1
            },
            {
                "size": "1120х28",
                "koef": "0.7589",
                "metrs": 1
            },
            {
                "size": "1120х29",
                "koef": "0.7852",
                "metrs": 1
            },
            {
                "size": "1120х30",
                "koef": "0.8116",
                "metrs": 1
            },
            {
                "size": "1120х31",
                "koef": "0.8378",
                "metrs": 1
            },
            {
                "size": "1120х32",
                "koef": "0.8641",
                "metrs": 1
            },
            {
                "size": "1120х33",
                "koef": "0.8903",
                "metrs": 1
            },
            {
                "size": "1120х34",
                "koef": "0.9164",
                "metrs": 1
            },
            {
                "size": "1120х35",
                "koef": "0.9425",
                "metrs": 1
            },
            {
                "size": "1120х36",
                "koef": "0.9685",
                "metrs": 1
            },
            {
                "size": "1120х37",
                "koef": "0.9945",
                "metrs": 1
            },
            {
                "size": "1120х38",
                "koef": "1.02",
                "metrs": 1
            },
            {
                "size": "1120х39",
                "koef": "1.046",
                "metrs": 1
            },
            {
                "size": "1120х4",
                "koef": "0.1108",
                "metrs": 1
            },
            {
                "size": "1120х40",
                "koef": "1.072",
                "metrs": 1
            },
            {
                "size": "1120х41",
                "koef": "1.098",
                "metrs": 1
            },
            {
                "size": "1120х42",
                "koef": "1.124",
                "metrs": 1
            },
            {
                "size": "1120х45",
                "koef": "1.201",
                "metrs": 1
            },
            {
                "size": "1120х5",
                "koef": "0.1384",
                "metrs": 1
            },
            {
                "size": "1120х50",
                "koef": "1.328",
                "metrs": 1
            },
            {
                "size": "1120х6",
                "koef": "0.1659",
                "metrs": 1
            },
            {
                "size": "1120х7",
                "koef": "0.1934",
                "metrs": 1
            },
            {
                "size": "1120х8",
                "koef": "0.2208",
                "metrs": 1
            },
            {
                "size": "1120х9",
                "koef": "0.2482",
                "metrs": 1
            },
            {
                "size": "1220х10",
                "koef": "0.3003",
                "metrs": 1
            },
            {
                "size": "1220х11",
                "koef": "0.3301",
                "metrs": 1
            },
            {
                "size": "1220х12",
                "koef": "0.3598",
                "metrs": 1
            },
            {
                "size": "1220х13",
                "koef": "0.3894",
                "metrs": 1
            },
            {
                "size": "1220х14",
                "koef": "0.419",
                "metrs": 1
            },
            {
                "size": "1220х15",
                "koef": "0.4486",
                "metrs": 1
            },
            {
                "size": "1220х16",
                "koef": "0.4781",
                "metrs": 1
            },
            {
                "size": "1220х17",
                "koef": "0.5076",
                "metrs": 1
            },
            {
                "size": "1220х18",
                "koef": "0.537",
                "metrs": 1
            },
            {
                "size": "1220х19",
                "koef": "0.5663",
                "metrs": 1
            },
            {
                "size": "1220х20",
                "koef": "0.5956",
                "metrs": 1
            },
            {
                "size": "1220х21",
                "koef": "0.6249",
                "metrs": 1
            },
            {
                "size": "1220х22",
                "koef": "0.6541",
                "metrs": 1
            },
            {
                "size": "1220х23",
                "koef": "0.6833",
                "metrs": 1
            },
            {
                "size": "1220х24",
                "koef": "0.7124",
                "metrs": 1
            },
            {
                "size": "1220х25",
                "koef": "0.7415",
                "metrs": 1
            },
            {
                "size": "1220х26",
                "koef": "0.7705",
                "metrs": 1
            },
            {
                "size": "1220х27",
                "koef": "0.7994",
                "metrs": 1
            },
            {
                "size": "1220х28",
                "koef": "0.8283",
                "metrs": 1
            },
            {
                "size": "1220х29",
                "koef": "0.8572",
                "metrs": 1
            },
            {
                "size": "1220х30",
                "koef": "0.886",
                "metrs": 1
            },
            {
                "size": "1220х31",
                "koef": "0.9148",
                "metrs": 1
            },
            {
                "size": "1220х32",
                "koef": "0.9435",
                "metrs": 1
            },
            {
                "size": "1220х33",
                "koef": "0.9722",
                "metrs": 1
            },
            {
                "size": "1220х34",
                "koef": "1.001",
                "metrs": 1
            },
            {
                "size": "1220х35",
                "koef": "1.029",
                "metrs": 1
            },
            {
                "size": "1220х36",
                "koef": "1.058",
                "metrs": 1
            },
            {
                "size": "1220х37",
                "koef": "1.086",
                "metrs": 1
            },
            {
                "size": "1220х38",
                "koef": "1.115",
                "metrs": 1
            },
            {
                "size": "1220х39",
                "koef": "1.143",
                "metrs": 1
            },
            {
                "size": "1220х4",
                "koef": "0.1207",
                "metrs": 1
            },
            {
                "size": "1220х40",
                "koef": "1.171",
                "metrs": 1
            },
            {
                "size": "1220х41",
                "koef": "1.2",
                "metrs": 1
            },
            {
                "size": "1220х42",
                "koef": "1.228",
                "metrs": 1
            },
            {
                "size": "1220х43",
                "koef": "1.256",
                "metrs": 1
            },
            {
                "size": "1220х44",
                "koef": "1.284",
                "metrs": 1
            },
            {
                "size": "1220х45",
                "koef": "1.312",
                "metrs": 1
            },
            {
                "size": "1220х46",
                "koef": "1.34",
                "metrs": 1
            },
            {
                "size": "1220х47",
                "koef": "1.368",
                "metrs": 1
            },
            {
                "size": "1220х48",
                "koef": "1.396",
                "metrs": 1
            },
            {
                "size": "1220х49",
                "koef": "1.424",
                "metrs": 1
            },
            {
                "size": "1220х5",
                "koef": "0.1508",
                "metrs": 1
            },
            {
                "size": "1220х50",
                "koef": "1.452",
                "metrs": 1
            },
            {
                "size": "1220х6",
                "koef": "0.1808",
                "metrs": 1
            },
            {
                "size": "1220х7",
                "koef": "0.2107",
                "metrs": 1
            },
            {
                "size": "1220х8",
                "koef": "0.2406",
                "metrs": 1
            },
            {
                "size": "1220х9",
                "koef": "0.2705",
                "metrs": 1
            },
            {
                "size": "1320х10",
                "koef": "0.3251",
                "metrs": 1
            },
            {
                "size": "1320х11",
                "koef": "0.3574",
                "metrs": 1
            },
            {
                "size": "1320х12",
                "koef": "0.3896",
                "metrs": 1
            },
            {
                "size": " 1320х13",
                "koef": "0.4217",
                "metrs": 1
            },
            {
                "size": "1320х14",
                "koef": "0.4538",
                "metrs": 1
            },
            {
                "size": "1320х15",
                "koef": "0.4858",
                "metrs": 1
            },
            {
                "size": "1320х16",
                "koef": "0.5178",
                "metrs": 1
            },
            {
                "size": "1320х17",
                "koef": "0.5498",
                "metrs": 1
            },
            {
                "size": " 1320х18",
                "koef": "0.5816",
                "metrs": 1
            },
            {
                "size": "1320х19",
                "koef": "0.6135",
                "metrs": 1
            },
            {
                "size": "1320х20",
                "koef": "0.6453",
                "metrs": 1
            },
            {
                "size": "1320х21",
                "koef": "0.677",
                "metrs": 1
            },
            {
                "size": "1320х22",
                "koef": "0.7087",
                "metrs": 1
            },
            {
                "size": "1320х23",
                "koef": "0.7404",
                "metrs": 1
            },
            {
                "size": "1320х24",
                "koef": "0.772",
                "metrs": 1
            },
            {
                "size": "1320х25",
                "koef": "0.8035",
                "metrs": 1
            },
            {
                "size": "1320х26",
                "koef": "0.835",
                "metrs": 1
            },
            {
                "size": "1320х27",
                "koef": "0.8664",
                "metrs": 1
            },
            {
                "size": "1320х28",
                "koef": "0.8978",
                "metrs": 1
            },
            {
                "size": "1320х29",
                "koef": "0.9292",
                "metrs": 1
            },
            {
                "size": "1320х30",
                "koef": "0.9605",
                "metrs": 1
            },
            {
                "size": "1320х31",
                "koef": "0.9917",
                "metrs": 1
            },
            {
                "size": "1320х32",
                "koef": "1.023",
                "metrs": 1
            },
            {
                "size": "1320х33",
                "koef": "1.054",
                "metrs": 1
            },
            {
                "size": "1320х34",
                "koef": "1.085",
                "metrs": 1
            },
            {
                "size": "1320х35",
                "koef": "1.116",
                "metrs": 1
            },
            {
                "size": "1320х36",
                "koef": "1.147",
                "metrs": 1
            },
            {
                "size": "1320х37",
                "koef": "1.178",
                "metrs": 1
            },
            {
                "size": "1320х38",
                "koef": "1.209",
                "metrs": 1
            },
            {
                "size": "1320х39",
                "koef": "1.24",
                "metrs": 1
            },
            {
                "size": "1320х4",
                "koef": "0.1306",
                "metrs": 1
            },
            {
                "size": "1320х40",
                "koef": "1.271",
                "metrs": 1
            },
            {
                "size": "1320х41",
                "koef": "1.301",
                "metrs": 1
            },
            {
                "size": "1320х42",
                "koef": "1.332",
                "metrs": 1
            },
            {
                "size": "1320х43",
                "koef": "1.363",
                "metrs": 1
            },
            {
                "size": "1320х44",
                "koef": "1.393",
                "metrs": 1
            },
            {
                "size": "1320х45",
                "koef": "1.424",
                "metrs": 1
            },
            {
                "size": "1320х46",
                "koef": "1.454",
                "metrs": 1
            },
            {
                "size": "1320х47",
                "koef": "1.485",
                "metrs": 1
            },
            {
                "size": "1320х48",
                "koef": "1.515",
                "metrs": 1
            },
            {
                "size": "1320х49",
                "koef": "1.546",
                "metrs": 1
            },
            {
                "size": "1320х5",
                "koef": "0.1632",
                "metrs": 1
            },
            {
                "size": "1320х50",
                "koef": "1.576",
                "metrs": 1
            },
            {
                "size": "1320х51",
                "koef": "1.606",
                "metrs": 1
            },
            {
                "size": "1320х52",
                "koef": "1.636",
                "metrs": 1
            },
            {
                "size": "1320х6",
                "koef": "0.1957",
                "metrs": 1
            },
            {
                "size": "1320х7",
                "koef": "0.2281",
                "metrs": 1
            },
            {
                "size": "1320х8",
                "koef": "0.2605",
                "metrs": 1
            },
            {
                "size": "1320х9",
                "koef": "0.2928",
                "metrs": 1
            },
            {
                "size": "1420х10",
                "koef": "0.3499",
                "metrs": 1
            },
            {
                "size": "1420х11",
                "koef": "0.3847",
                "metrs": 1
            },
            {
                "size": "1420х12",
                "koef": "0.4193",
                "metrs": 1
            },
            {
                "size": "1420х13",
                "koef": "0.454",
                "metrs": 1
            },
            {
                "size": "1420х14",
                "koef": "0.4885",
                "metrs": 1
            },
            {
                "size": "1420х15",
                "koef": "0.5231",
                "metrs": 1
            },
            {
                "size": "1420х16",
                "koef": "0.5575",
                "metrs": 1
            },
            {
                "size": "1420х17",
                "koef": "0.5919",
                "metrs": 1
            },
            {
                "size": "1420х18",
                "koef": "0.6263",
                "metrs": 1
            },
            {
                "size": "1420х19",
                "koef": "0.6606",
                "metrs": 1
            },
            {
                "size": "1420х20",
                "koef": "0.6949",
                "metrs": 1
            },
            {
                "size": "1420х21",
                "koef": "0.7291",
                "metrs": 1
            },
            {
                "size": "1420х22",
                "koef": "0.7633",
                "metrs": 1
            },
            {
                "size": "1420х23",
                "koef": "0.7974",
                "metrs": 1
            },
            {
                "size": "1420х24",
                "koef": "0.8315",
                "metrs": 1
            },
            {
                "size": "1420х25",
                "koef": "0.8655",
                "metrs": 1
            },
            {
                "size": "1420х26",
                "koef": "0.8995",
                "metrs": 1
            },
            {
                "size": "1420х27",
                "koef": "0.9335",
                "metrs": 1
            },
            {
                "size": "1420х28",
                "koef": "0.9673",
                "metrs": 1
            },
            {
                "size": "1420х29",
                "koef": "1.001",
                "metrs": 1
            },
            {
                "size": "1420х30",
                "koef": "1.035",
                "metrs": 1
            },
            {
                "size": "1420х31",
                "koef": "1.069",
                "metrs": 1
            },
            {
                "size": "1420х32",
                "koef": "1.102",
                "metrs": 1
            },
            {
                "size": "1420х33",
                "koef": "1.136",
                "metrs": 1
            },
            {
                "size": "1420х34",
                "koef": "1.17",
                "metrs": 1
            },
            {
                "size": "1420х35",
                "koef": "1.203",
                "metrs": 1
            },
            {
                "size": "1420х36",
                "koef": "1.237",
                "metrs": 1
            },
            {
                "size": "1420х37",
                "koef": "1.27",
                "metrs": 1
            },
            {
                "size": "1420х38",
                "koef": "1.303",
                "metrs": 1
            },
            {
                "size": "1420х39",
                "koef": "1.337",
                "metrs": 1
            },
            {
                "size": "1420х4",
                "koef": "0.1406",
                "metrs": 1
            },
            {
                "size": "1420х40",
                "koef": "1.37",
                "metrs": 1
            },
            {
                "size": "1420х41",
                "koef": "1.403",
                "metrs": 1
            },
            {
                "size": "1420х42",
                "koef": "1.436",
                "metrs": 1
            },
            {
                "size": "1420х43",
                "koef": "1.47",
                "metrs": 1
            },
            {
                "size": "1420х44",
                "koef": "1.503",
                "metrs": 1
            },
            {
                "size": "1420х45",
                "koef": "1.536",
                "metrs": 1
            },
            {
                "size": "1420х46",
                "koef": "1.569",
                "metrs": 1
            },
            {
                "size": "1420х47",
                "koef": "1.602",
                "metrs": 1
            },
            {
                "size": "1420х48",
                "koef": "1.634",
                "metrs": 1
            },
            {
                "size": "1420х49",
                "koef": "1.667",
                "metrs": 1
            },
            {
                "size": "1420х5",
                "koef": "0.1756",
                "metrs": 1
            },
            {
                "size": "1420х50",
                "koef": "1.7",
                "metrs": 1
            },
            {
                "size": "1420х51",
                "koef": "1.733",
                "metrs": 1
            },
            {
                "size": "1420х52",
                "koef": "1.765",
                "metrs": 1
            },
            {
                "size": "1420х6",
                "koef": "0.2106",
                "metrs": 1
            },
            {
                "size": "1420х7",
                "koef": "0.2455",
                "metrs": 1
            },
            {
                "size": "1420х8",
                "koef": "0.2804",
                "metrs": 1
            },
            {
                "size": "1420х9",
                "koef": "0.3152",
                "metrs": 1
            },
            {
                "size": "1500х10",
                "koef": "0.3698",
                "metrs": 1
            },
            {
                "size": "1500х11",
                "koef": "0.4065",
                "metrs": 1
            },
            {
                "size": "1500х12",
                "koef": "0.4432",
                "metrs": 1
            },
            {
                "size": "1500х13",
                "koef": "0.4798",
                "metrs": 1
            },
            {
                "size": "1500х14",
                "koef": "0.5163",
                "metrs": 1
            },
            {
                "size": "1500х15",
                "koef": "0.5528",
                "metrs": 1
            },
            {
                "size": "1500х16",
                "koef": "0.5893",
                "metrs": 1
            },
            {
                "size": "1500х18",
                "koef": "0.6621",
                "metrs": 1
            },
            {
                "size": "1500х20",
                "koef": "0.7346",
                "metrs": 1
            },
            {
                "size": "1500х25",
                "koef": "0.9152",
                "metrs": 1
            },
            {
                "size": "1500х28",
                "koef": "1.023",
                "metrs": 1
            },
            {
                "size": "1500х30",
                "koef": "1.094",
                "metrs": 1
            },
            {
                "size": "1500х32",
                "koef": "1.166",
                "metrs": 1
            },
            {
                "size": "1500х35",
                "koef": "1.273",
                "metrs": 1
            },
            {
                "size": "1500х4",
                "koef": "0.1485",
                "metrs": 1
            },
            {
                "size": "1500х40",
                "koef": "1.449",
                "metrs": 1
            },
            {
                "size": "1500х45",
                "koef": "1.625",
                "metrs": 1
            },
            {
                "size": "1500х5",
                "koef": "0.1855",
                "metrs": 1
            },
            {
                "size": "1500х50",
                "koef": "1.799",
                "metrs": 1
            },
            {
                "size": "1500х6",
                "koef": "0.2225",
                "metrs": 1
            },
            {
                "size": "1500х7",
                "koef": "0.2594",
                "metrs": 1
            },
            {
                "size": "1500х8",
                "koef": "0.2962",
                "metrs": 1
            },
            {
                "size": "1500х9",
                "koef": "0.333",
                "metrs": 1
            },
            {
                "size": "1520х10",
                "koef": "0.3748",
                "metrs": 1
            },
            {
                "size": "1520х11",
                "koef": "0.412",
                "metrs": 1
            },
            {
                "size": "1520х12",
                "koef": "0.4491",
                "metrs": 1
            },
            {
                "size": "1520х13",
                "koef": "0.4862",
                "metrs": 1
            },
            {
                "size": "1520х14",
                "koef": "0.5233",
                "metrs": 1
            },
            {
                "size": "1520х15",
                "koef": "0.5603",
                "metrs": 1
            },
            {
                "size": "1520х16",
                "koef": "0.5972",
                "metrs": 1
            },
            {
                "size": "1520х17",
                "koef": "0.6341",
                "metrs": 1
            },
            {
                "size": "1520х18",
                "koef": "0.671",
                "metrs": 1
            },
            {
                "size": "1520х19",
                "koef": "0.7078",
                "metrs": 1
            },
            {
                "size": "1520х20",
                "koef": "0.7446",
                "metrs": 1
            },
            {
                "size": "1520х21",
                "koef": "0.7813",
                "metrs": 1
            },
            {
                "size": "1520х22",
                "koef": "0.8179",
                "metrs": 1
            },
            {
                "size": "1520х23",
                "koef": "0.8545",
                "metrs": 1
            },
            {
                "size": "1520х24",
                "koef": "0.8911",
                "metrs": 1
            },
            {
                "size": "1520х25",
                "koef": "0.9276",
                "metrs": 1
            },
            {
                "size": "1520х26",
                "koef": "0.9641",
                "metrs": 1
            },
            {
                "size": "1520х27",
                "koef": "1",
                "metrs": 1
            },
            {
                "size": "1520х28",
                "koef": "1.037",
                "metrs": 1
            },
            {
                "size": "1520х29",
                "koef": "1.073",
                "metrs": 1
            },
            {
                "size": "1520х30",
                "koef": "1.109",
                "metrs": 1
            },
            {
                "size": "1520х31",
                "koef": "1.146",
                "metrs": 1
            },
            {
                "size": "1520х32",
                "koef": "1.182",
                "metrs": 1
            },
            {
                "size": "1520х33",
                "koef": "1.218",
                "metrs": 1
            },
            {
                "size": "1520х34",
                "koef": "1.254",
                "metrs": 1
            },
            {
                "size": "1520х35",
                "koef": "1.29",
                "metrs": 1
            },
            {
                "size": "1520х36",
                "koef": "1.326",
                "metrs": 1
            },
            {
                "size": "1520х37",
                "koef": "1.362",
                "metrs": 1
            },
            {
                "size": "1520х38",
                "koef": "1.398",
                "metrs": 1
            },
            {
                "size": "1520х39",
                "koef": "1.433",
                "metrs": 1
            },
            {
                "size": "1520х40",
                "koef": "1.469",
                "metrs": 1
            },
            {
                "size": "1520х41",
                "koef": "1.505",
                "metrs": 1
            },
            {
                "size": "1520х42",
                "koef": "1.541",
                "metrs": 1
            },
            {
                "size": "1520х43",
                "koef": "1.576",
                "metrs": 1
            },
            {
                "size": "1520х44",
                "koef": "1.612",
                "metrs": 1
            },
            {
                "size": "1520х45",
                "koef": "1.647",
                "metrs": 1
            },
            {
                "size": "1520х46",
                "koef": "1.683",
                "metrs": 1
            },
            {
                "size": "1520х47",
                "koef": "1.718",
                "metrs": 1
            },
            {
                "size": "1520х48",
                "koef": "1.754",
                "metrs": 1
            },
            {
                "size": "1520х49",
                "koef": "1.789",
                "metrs": 1
            },
            {
                "size": "1520х5",
                "koef": "0.188",
                "metrs": 1
            },
            {
                "size": "1520х50",
                "koef": "1.824",
                "metrs": 1
            },
            {
                "size": "1520х51",
                "koef": "1.859",
                "metrs": 1
            },
            {
                "size": "1520х52",
                "koef": "1.895",
                "metrs": 1
            },
            {
                "size": "1520х6",
                "koef": "0.2255",
                "metrs": 1
            },
            {
                "size": "1520х7",
                "koef": "0.2629",
                "metrs": 1
            },
            {
                "size": "1520х8",
                "koef": "0.3002",
                "metrs": 1
            },
            {
                "size": "1520х9",
                "koef": "0.3375",
                "metrs": 1
            },
            {
                "size": "1620х10",
                "koef": "0.3996",
                "metrs": 1
            },
            {
                "size": "1620х11",
                "koef": "0.4393",
                "metrs": 1
            },
            {
                "size": "1620х12",
                "koef": "0.4789",
                "metrs": 1
            },
            {
                "size": "1620х13",
                "koef": "0.5185",
                "metrs": 1
            },
            {
                "size": "1620х14",
                "koef": "0.558",
                "metrs": 1
            },
            {
                "size": "1620х15",
                "koef": "0.5975",
                "metrs": 1
            },
            {
                "size": "1620х16",
                "koef": "0.6369",
                "metrs": 1
            },
            {
                "size": "1620х17",
                "koef": "0.6763",
                "metrs": 1
            },
            {
                "size": "1620х18",
                "koef": "0.7157",
                "metrs": 1
            },
            {
                "size": "1620х19",
                "koef": "0.755",
                "metrs": 1
            },
            {
                "size": "1620х20",
                "koef": "0.7942",
                "metrs": 1
            },
            {
                "size": "1620х21",
                "koef": "0.8334",
                "metrs": 1
            },
            {
                "size": "1620х22",
                "koef": "0.8725",
                "metrs": 1
            },
            {
                "size": "1620х23",
                "koef": "0.9116",
                "metrs": 1
            },
            {
                "size": "1620х24",
                "koef": "0.9507",
                "metrs": 1
            },
            {
                "size": "1620х25",
                "koef": "0.9896",
                "metrs": 1
            },
            {
                "size": "1620х26",
                "koef": "1.029",
                "metrs": 1
            },
            {
                "size": "1620х27",
                "koef": "1.067",
                "metrs": 1
            },
            {
                "size": "1620х28",
                "koef": "1.106",
                "metrs": 1
            },
            {
                "size": "1620х29",
                "koef": "1.145",
                "metrs": 1
            },
            {
                "size": "1620х30",
                "koef": "1.184",
                "metrs": 1
            },
            {
                "size": "1620х31",
                "koef": "1.223",
                "metrs": 1
            },
            {
                "size": "1620х32",
                "koef": "1.261",
                "metrs": 1
            },
            {
                "size": "1620х33",
                "koef": "1.3",
                "metrs": 1
            },
            {
                "size": "1620х34",
                "koef": "1.338",
                "metrs": 1
            },
            {
                "size": "1620х35",
                "koef": "1.377",
                "metrs": 1
            },
            {
                "size": "1620х36",
                "koef": "1.415",
                "metrs": 1
            },
            {
                "size": "1620х37",
                "koef": "1.454",
                "metrs": 1
            },
            {
                "size": "1620х38",
                "koef": "1.492",
                "metrs": 1
            },
            {
                "size": "1620х39",
                "koef": "1.53",
                "metrs": 1
            },
            {
                "size": "1620х40",
                "koef": "1.569",
                "metrs": 1
            },
            {
                "size": "1620х41",
                "koef": "1.607",
                "metrs": 1
            },
            {
                "size": "1620х42",
                "koef": "1.645",
                "metrs": 1
            },
            {
                "size": "1620х43",
                "koef": "1.683",
                "metrs": 1
            },
            {
                "size": "1620х44",
                "koef": "1.721",
                "metrs": 1
            },
            {
                "size": "1620х45",
                "koef": "1.759",
                "metrs": 1
            },
            {
                "size": "1620х46",
                "koef": "1.797",
                "metrs": 1
            },
            {
                "size": "1620х47",
                "koef": "1.835",
                "metrs": 1
            },
            {
                "size": "1620х48",
                "koef": "1.873",
                "metrs": 1
            },
            {
                "size": "1620х49",
                "koef": "1.911",
                "metrs": 1
            },
            {
                "size": "1620х5",
                "koef": "0.2004",
                "metrs": 1
            },
            {
                "size": "1620х50",
                "koef": "1.948",
                "metrs": 1
            },
            {
                "size": "1620х51",
                "koef": "1.986",
                "metrs": 1
            },
            {
                "size": "1620х52",
                "koef": "2.024",
                "metrs": 1
            },
            {
                "size": "1620х6",
                "koef": "0.2403",
                "metrs": 1
            },
            {
                "size": "1620х7",
                "koef": "0.2802",
                "metrs": 1
            },
            {
                "size": "1620х8",
                "koef": "0.3201",
                "metrs": 1
            },
            {
                "size": "1620х9",
                "koef": "0.3598",
                "metrs": 1
            },
            {
                "size": "1720х10",
                "koef": "0.4244",
                "metrs": 1
            },
            {
                "size": "1720х11",
                "koef": "0.4666",
                "metrs": 1
            },
            {
                "size": "1720х12",
                "koef": "0.5087",
                "metrs": 1
            },
            {
                "size": "1720х13",
                "koef": "0.5507",
                "metrs": 1
            },
            {
                "size": "1720х14",
                "koef": "0.5928",
                "metrs": 1
            },
            {
                "size": "1720х15",
                "koef": "0.6347",
                "metrs": 1
            },
            {
                "size": "1720х16",
                "koef": "0.6767",
                "metrs": 1
            },
            {
                "size": "1720х17",
                "koef": "0.7185",
                "metrs": 1
            },
            {
                "size": "1720х18",
                "koef": "0.7603",
                "metrs": 1
            },
            {
                "size": "1720х19",
                "koef": "0.8021",
                "metrs": 1
            },
            {
                "size": "1720х20",
                "koef": "0.8438",
                "metrs": 1
            },
            {
                "size": "1720х21",
                "koef": "0.8855",
                "metrs": 1
            },
            {
                "size": "1720х22",
                "koef": "0.9271",
                "metrs": 1
            },
            {
                "size": "1720х23",
                "koef": "0.9687",
                "metrs": 1
            },
            {
                "size": "1720х24",
                "koef": "1.01",
                "metrs": 1
            },
            {
                "size": "1720х25",
                "koef": "1.052",
                "metrs": 1
            },
            {
                "size": "1720х26",
                "koef": "1.093",
                "metrs": 1
            },
            {
                "size": "1720х27",
                "koef": "1.134",
                "metrs": 1
            },
            {
                "size": "1720х28",
                "koef": "1.176",
                "metrs": 1
            },
            {
                "size": "1720х29",
                "koef": "1.217",
                "metrs": 1
            },
            {
                "size": "1720х30",
                "koef": "1.258",
                "metrs": 1
            },
            {
                "size": "1720х31",
                "koef": "1.299",
                "metrs": 1
            },
            {
                "size": "1720х32",
                "koef": "1.341",
                "metrs": 1
            },
            {
                "size": "1720х33",
                "koef": "1.382",
                "metrs": 1
            },
            {
                "size": "1720х34",
                "koef": "1.423",
                "metrs": 1
            },
            {
                "size": "1720х35",
                "koef": "1.464",
                "metrs": 1
            },
            {
                "size": "1720х36",
                "koef": "1.505",
                "metrs": 1
            },
            {
                "size": "1720х37",
                "koef": "1.545",
                "metrs": 1
            },
            {
                "size": "1720х38",
                "koef": "1.586",
                "metrs": 1
            },
            {
                "size": "1720х39",
                "koef": "1.627",
                "metrs": 1
            },
            {
                "size": "1720х40",
                "koef": "1.668",
                "metrs": 1
            },
            {
                "size": "1720х41",
                "koef": "1.708",
                "metrs": 1
            },
            {
                "size": "1720х42",
                "koef": "1.749",
                "metrs": 1
            },
            {
                "size": "1720х43",
                "koef": "1.79",
                "metrs": 1
            },
            {
                "size": "1720х44",
                "koef": "1.83",
                "metrs": 1
            },
            {
                "size": "1720х45",
                "koef": "1.871",
                "metrs": 1
            },
            {
                "size": "1720х46",
                "koef": "1.911",
                "metrs": 1
            },
            {
                "size": "1720х47",
                "koef": "1.952",
                "metrs": 1
            },
            {
                "size": "1720х48",
                "koef": "1.992",
                "metrs": 1
            },
            {
                "size": "1720х49",
                "koef": "2.032",
                "metrs": 1
            },
            {
                "size": "1720х5",
                "koef": "0.2128",
                "metrs": 1
            },
            {
                "size": "1720х50",
                "koef": "2.072",
                "metrs": 1
            },
            {
                "size": "1720х51",
                "koef": "2.113",
                "metrs": 1
            },
            {
                "size": "1720х52",
                "koef": "2.153",
                "metrs": 1
            },
            {
                "size": "1720х53",
                "koef": "2.193",
                "metrs": 1
            },
            {
                "size": "1720х54",
                "koef": "2.233",
                "metrs": 1
            },
            {
                "size": "1720х6",
                "koef": "0.2552",
                "metrs": 1
            },
            {
                "size": "1720х7",
                "koef": "0.2976",
                "metrs": 1
            },
            {
                "size": "1720х8",
                "koef": "0.3399",
                "metrs": 1
            },
            {
                "size": "1720х9",
                "koef": "0.3822",
                "metrs": 1
            },
            {
                "size": "1820х10",
                "koef": "0.4492",
                "metrs": 1
            },
            {
                "size": "1820х11",
                "koef": "0.4939",
                "metrs": 1
            },
            {
                "size": "1820х12",
                "koef": "0.5385",
                "metrs": 1
            },
            {
                "size": "1820х13",
                "koef": "0.583",
                "metrs": 1
            },
            {
                "size": "1820х14",
                "koef": "0.6275",
                "metrs": 1
            },
            {
                "size": "1820х15",
                "koef": "0.672",
                "metrs": 1
            },
            {
                "size": "1820х16",
                "koef": "0.7164",
                "metrs": 1
            },
            {
                "size": "1820х17",
                "koef": "0.7607",
                "metrs": 1
            },
            {
                "size": "1820х18",
                "koef": "0.805",
                "metrs": 1
            },
            {
                "size": "1820х19",
                "koef": "0.8493",
                "metrs": 1
            },
            {
                "size": "1820х20",
                "koef": "0.8935",
                "metrs": 1
            },
            {
                "size": "1820х21",
                "koef": "0.9376",
                "metrs": 1
            },
            {
                "size": "1820х22",
                "koef": "0.9817",
                "metrs": 1
            },
            {
                "size": "1820х23",
                "koef": "1.026",
                "metrs": 1
            },
            {
                "size": "1820х24",
                "koef": "1.07",
                "metrs": 1
            },
            {
                "size": "1820х25",
                "koef": "1.114",
                "metrs": 1
            },
            {
                "size": "1820х26",
                "koef": "1.158",
                "metrs": 1
            },
            {
                "size": "1820х27",
                "koef": "1.201",
                "metrs": 1
            },
            {
                "size": "1820х28",
                "koef": "1.245",
                "metrs": 1
            },
            {
                "size": "1820х29",
                "koef": "1.289",
                "metrs": 1
            },
            {
                "size": "1820х30",
                "koef": "1.333",
                "metrs": 1
            },
            {
                "size": "1820х31",
                "koef": "1.376",
                "metrs": 1
            },
            {
                "size": "1820х32",
                "koef": "1.42",
                "metrs": 1
            },
            {
                "size": "1820х33",
                "koef": "1.464",
                "metrs": 1
            },
            {
                "size": "1820х34",
                "koef": "1.507",
                "metrs": 1
            },
            {
                "size": "1820х35",
                "koef": "1.551",
                "metrs": 1
            },
            {
                "size": "1820х36",
                "koef": "1.594",
                "metrs": 1
            },
            {
                "size": "1820х37",
                "koef": "1.637",
                "metrs": 1
            },
            {
                "size": "1820х38",
                "koef": "1.681",
                "metrs": 1
            },
            {
                "size": "1820х39",
                "koef": "1.724",
                "metrs": 1
            },
            {
                "size": "1820х40",
                "koef": "1.767",
                "metrs": 1
            },
            {
                "size": "1820х41",
                "koef": "1.81",
                "metrs": 1
            },
            {
                "size": "1820х42",
                "koef": "1.853",
                "metrs": 1
            },
            {
                "size": "1820х43",
                "koef": "1.896",
                "metrs": 1
            },
            {
                "size": "1820х44",
                "koef": "1.939",
                "metrs": 1
            },
            {
                "size": "1820х45",
                "koef": "1.982",
                "metrs": 1
            },
            {
                "size": "1820х46",
                "koef": "2.025",
                "metrs": 1
            },
            {
                "size": "1820х47",
                "koef": "2.068",
                "metrs": 1
            },
            {
                "size": "1820х48",
                "koef": "2.111",
                "metrs": 1
            },
            {
                "size": "1820х49",
                "koef": "2.154",
                "metrs": 1
            },
            {
                "size": "1820х5",
                "koef": "0.2252",
                "metrs": 1
            },
            {
                "size": "1820х50",
                "koef": "2.196",
                "metrs": 1
            },
            {
                "size": "1820х51",
                "koef": "2.239",
                "metrs": 1
            },
            {
                "size": "1820х52",
                "koef": "2.282",
                "metrs": 1
            },
            {
                "size": "1820х53",
                "koef": "2.324",
                "metrs": 1
            },
            {
                "size": "1820х54",
                "koef": "2.367",
                "metrs": 1
            },
            {
                "size": "1820х6",
                "koef": "0.2701",
                "metrs": 1
            },
            {
                "size": "1820х7",
                "koef": "0.315",
                "metrs": 1
            },
            {
                "size": "1820х8",
                "koef": "0.3598",
                "metrs": 1
            },
            {
                "size": "1820х9",
                "koef": "0.4045",
                "metrs": 1
            },
            {
                "size": "1920х10",
                "koef": "0.474",
                "metrs": 1
            },
            {
                "size": "1920х12",
                "koef": "0.5682",
                "metrs": 1
            },
            {
                "size": "1920х14",
                "koef": "0.6623",
                "metrs": 1
            },
            {
                "size": "1920х15",
                "koef": "0.7092",
                "metrs": 1
            },
            {
                "size": "1920х16",
                "koef": "0.7561",
                "metrs": 1
            },
            {
                "size": "1920х17",
                "koef": "0.8029",
                "metrs": 1
            },
            {
                "size": "1920х18",
                "koef": "0.8497",
                "metrs": 1
            },
            {
                "size": "1920х19",
                "koef": "0.8964",
                "metrs": 1
            },
            {
                "size": "1920х20",
                "koef": "0.9431",
                "metrs": 1
            },
            {
                "size": "1920х21",
                "koef": "0.9897",
                "metrs": 1
            },
            {
                "size": "1920х22",
                "koef": "1.036",
                "metrs": 1
            },
            {
                "size": "1920х23",
                "koef": "1.083",
                "metrs": 1
            },
            {
                "size": "1920х24",
                "koef": "1.129",
                "metrs": 1
            },
            {
                "size": "1920х25",
                "koef": "1.176",
                "metrs": 1
            },
            {
                "size": "1920х26",
                "koef": "1.222",
                "metrs": 1
            },
            {
                "size": "1920х27",
                "koef": "1.269",
                "metrs": 1
            },
            {
                "size": "1920х28",
                "koef": "1.315",
                "metrs": 1
            },
            {
                "size": "1920х29",
                "koef": "1.361",
                "metrs": 1
            },
            {
                "size": "1920х30",
                "koef": "1.407",
                "metrs": 1
            },
            {
                "size": "1920х31",
                "koef": "1.453",
                "metrs": 1
            },
            {
                "size": "1920х32",
                "koef": "1.499",
                "metrs": 1
            },
            {
                "size": "1920х33",
                "koef": "1.545",
                "metrs": 1
            },
            {
                "size": "1920х34",
                "koef": "1.591",
                "metrs": 1
            },
            {
                "size": "1920х35",
                "koef": "1.637",
                "metrs": 1
            },
            {
                "size": "1920х36",
                "koef": "1.683",
                "metrs": 1
            },
            {
                "size": "1920х37",
                "koef": "1.729",
                "metrs": 1
            },
            {
                "size": "1920х38",
                "koef": "1.775",
                "metrs": 1
            },
            {
                "size": "1920х39",
                "koef": "1.821",
                "metrs": 1
            },
            {
                "size": "1920х40",
                "koef": "1.866",
                "metrs": 1
            },
            {
                "size": "1920х41",
                "koef": "1.912",
                "metrs": 1
            },
            {
                "size": "1920х42",
                "koef": "1.958",
                "metrs": 1
            },
            {
                "size": "1920х43",
                "koef": "2.003",
                "metrs": 1
            },
            {
                "size": "1920х44",
                "koef": "2.049",
                "metrs": 1
            },
            {
                "size": "1920х45",
                "koef": "2.094",
                "metrs": 1
            },
            {
                "size": "1920х46",
                "koef": "2.139",
                "metrs": 1
            },
            {
                "size": " 1920х47",
                "koef": "2.185",
                "metrs": 1
            },
            {
                "size": "1920х48",
                "koef": "2.23",
                "metrs": 1
            },
            {
                "size": "1920х49",
                "koef": "2.275",
                "metrs": 1
            },
            {
                "size": "1920х50",
                "koef": "2.321",
                "metrs": 1
            },
            {
                "size": "1920х51",
                "koef": "2.366",
                "metrs": 1
            },
            {
                "size": "1920х52",
                "koef": "2.411",
                "metrs": 1
            },
            {
                "size": "1920х53",
                "koef": "2.456",
                "metrs": 1
            },
            {
                "size": "1920х54",
                "koef": "2.501",
                "metrs": 1
            },
            {
                "size": "1920х8",
                "koef": "0.3796",
                "metrs": 1
            },
            {
                "size": "2000х10",
                "koef": "0.4939",
                "metrs": 1
            },
            {
                "size": "2000х12",
                "koef": "0.5921",
                "metrs": 1
            },
            {
                "size": "2000х14",
                "koef": "0.6901",
                "metrs": 1
            },
            {
                "size": "2000х16",
                "koef": "0.7878",
                "metrs": 1
            },
            {
                "size": "2000х18",
                "koef": "0.8854",
                "metrs": 1
            },
            {
                "size": "2000х20",
                "koef": "0.9828",
                "metrs": 1
            },
            {
                "size": "2000х25",
                "koef": "1.225",
                "metrs": 1
            },
            {
                "size": "2000х28",
                "koef": "1.37",
                "metrs": 1
            },
            {
                "size": "2000х30",
                "koef": "1.467",
                "metrs": 1
            },
            {
                "size": "2000х32",
                "koef": "1.563",
                "metrs": 1
            },
            {
                "size": "2000х35",
                "koef": "1.707",
                "metrs": 1
            },
            {
                "size": "2000х40",
                "koef": "1.946",
                "metrs": 1
            },
            {
                "size": "2000х45",
                "koef": "2.183",
                "metrs": 1
            },
            {
                "size": "2000х50",
                "koef": "2.42",
                "metrs": 1
            },
            {
                "size": "2000х8",
                "koef": "0.3955",
                "metrs": 1
            },
            {
                "size": "2020х10",
                "koef": "0.4989",
                "metrs": 1
            },
            {
                "size": "2020х11",
                "koef": "0.5485",
                "metrs": 1
            },
            {
                "size": "2020х12",
                "koef": "0.598",
                "metrs": 1
            },
            {
                "size": "2020х13",
                "koef": "0.6475",
                "metrs": 1
            },
            {
                "size": "2020х14",
                "koef": "0.697",
                "metrs": 1
            },
            {
                "size": "2020х15",
                "koef": "0.7464",
                "metrs": 1
            },
            {
                "size": "2020х16",
                "koef": "0.7958",
                "metrs": 1
            },
            {
                "size": "2020х17",
                "koef": "0.8451",
                "metrs": 1
            },
            {
                "size": "2020х18",
                "koef": "0.8944",
                "metrs": 1
            },
            {
                "size": "2020х19",
                "koef": "0.9436",
                "metrs": 1
            },
            {
                "size": "2020х20",
                "koef": "0.9927",
                "metrs": 1
            },
            {
                "size": "2020х21",
                "koef": "1.042",
                "metrs": 1
            },
            {
                "size": "2020х22",
                "koef": "1.091",
                "metrs": 1
            },
            {
                "size": "2020х23",
                "koef": "1.14",
                "metrs": 1
            },
            {
                "size": "2020х24",
                "koef": "1.189",
                "metrs": 1
            },
            {
                "size": "2020х25",
                "koef": "1.238",
                "metrs": 1
            },
            {
                "size": "2020х26",
                "koef": "1.287",
                "metrs": 1
            },
            {
                "size": "2020х27",
                "koef": "1.336",
                "metrs": 1
            },
            {
                "size": "2020х28",
                "koef": "1.384",
                "metrs": 1
            },
            {
                "size": "2020х29",
                "koef": "1.433",
                "metrs": 1
            },
            {
                "size": "2020х30",
                "koef": "1.482",
                "metrs": 1
            },
            {
                "size": "2020х31",
                "koef": "1.53",
                "metrs": 1
            },
            {
                "size": "2020х32",
                "koef": "1.579",
                "metrs": 1
            },
            {
                "size": "2020х33",
                "koef": "1.627",
                "metrs": 1
            },
            {
                "size": "2020х34",
                "koef": "1.676",
                "metrs": 1
            },
            {
                "size": "2020х35",
                "koef": "1.724",
                "metrs": 1
            },
            {
                "size": "2020х36",
                "koef": "1.773",
                "metrs": 1
            },
            {
                "size": "2020х37",
                "koef": "1.821",
                "metrs": 1
            },
            {
                "size": "2020х38",
                "koef": "1.869",
                "metrs": 1
            },
            {
                "size": "2020х39",
                "koef": "1.917",
                "metrs": 1
            },
            {
                "size": "2020х40",
                "koef": "1.966",
                "metrs": 1
            },
            {
                "size": "2020х41",
                "koef": "2.014",
                "metrs": 1
            },
            {
                "size": "2020х42",
                "koef": "2.062",
                "metrs": 1
            },
            {
                "size": "2020х43",
                "koef": "2.11",
                "metrs": 1
            },
            {
                "size": "2020х44",
                "koef": "2.158",
                "metrs": 1
            },
            {
                "size": "2020х45",
                "koef": "2.206",
                "metrs": 1
            },
            {
                "size": "2020х46",
                "koef": "2.254",
                "metrs": 1
            },
            {
                "size": "2020х47",
                "koef": "2.301",
                "metrs": 1
            },
            {
                "size": "2020х48",
                "koef": "2.349",
                "metrs": 1
            },
            {
                "size": "2020х49",
                "koef": "2.397",
                "metrs": 1
            },
            {
                "size": "2020х5",
                "koef": "0.25",
                "metrs": 1
            },
            {
                "size": "2020х50",
                "koef": "2.445",
                "metrs": 1
            },
            {
                "size": "2020х51",
                "koef": "2.492",
                "metrs": 1
            },
            {
                "size": "2020х52",
                "koef": "2.54",
                "metrs": 1
            },
            {
                "size": "2020х53",
                "koef": "2.587",
                "metrs": 1
            },
            {
                "size": "2020х54",
                "koef": "2.635",
                "metrs": 1
            },
            {
                "size": "2020х55",
                "koef": "2.682",
                "metrs": 1
            },
            {
                "size": "2020х56",
                "koef": "2.73",
                "metrs": 1
            },
            {
                "size": "2020х57",
                "koef": "2.777",
                "metrs": 1
            },
            {
                "size": "2020х58",
                "koef": "2.824",
                "metrs": 1
            },
            {
                "size": "2020х59",
                "koef": "2.871",
                "metrs": 1
            },
            {
                "size": "2020х6",
                "koef": "0.2999",
                "metrs": 1
            },
            {
                "size": "2020х60",
                "koef": "2.919",
                "metrs": 1
            },
            {
                "size": "2020х7",
                "koef": "0.3497",
                "metrs": 1
            },
            {
                "size": "2020х8",
                "koef": "0.3995",
                "metrs": 1
            },
            {
                "size": "2020х9",
                "koef": "0.4492",
                "metrs": 1
            },
            {
                "size": "2120х10",
                "koef": "0.5237",
                "metrs": 1
            },
            {
                "size": "2120х12",
                "koef": "0.6278",
                "metrs": 1
            },
            {
                "size": "2120х14",
                "koef": "0.7318",
                "metrs": 1
            },
            {
                "size": "2120х15",
                "koef": "0.7836",
                "metrs": 1
            },
            {
                "size": "2120х16",
                "koef": "0.8355",
                "metrs": 1
            },
            {
                "size": "2120х17",
                "koef": "0.8873",
                "metrs": 1
            },
            {
                "size": "2120х18",
                "koef": "0.939",
                "metrs": 1
            },
            {
                "size": "2120х19",
                "koef": "0.9907",
                "metrs": 1
            },
            {
                "size": "2120х20",
                "koef": "1.042",
                "metrs": 1
            },
            {
                "size": "2120х21",
                "koef": "1.094",
                "metrs": 1
            },
            {
                "size": "2120х22",
                "koef": "1.146",
                "metrs": 1
            },
            {
                "size": "2120х23",
                "koef": "1.197",
                "metrs": 1
            },
            {
                "size": "2120х24",
                "koef": "1.248",
                "metrs": 1
            },
            {
                "size": "2120х25",
                "koef": "1.3",
                "metrs": 1
            },
            {
                "size": "2120х26",
                "koef": "1.351",
                "metrs": 1
            },
            {
                "size": "2120х27",
                "koef": "1.403",
                "metrs": 1
            },
            {
                "size": "2120х28",
                "koef": "1.454",
                "metrs": 1
            },
            {
                "size": "2120х29",
                "koef": "1.505",
                "metrs": 1
            },
            {
                "size": "2120х30",
                "koef": "1.556",
                "metrs": 1
            },
            {
                "size": "2120х31",
                "koef": "1.607",
                "metrs": 1
            },
            {
                "size": "2120х32",
                "koef": "1.658",
                "metrs": 1
            },
            {
                "size": "2120х33",
                "koef": "1.709",
                "metrs": 1
            },
            {
                "size": "2120х34",
                "koef": "1.76",
                "metrs": 1
            },
            {
                "size": "2120х35",
                "koef": "1.811",
                "metrs": 1
            },
            {
                "size": "2120х36",
                "koef": "1.862",
                "metrs": 1
            },
            {
                "size": "2120х37",
                "koef": "1.913",
                "metrs": 1
            },
            {
                "size": "2120х38",
                "koef": "1.964",
                "metrs": 1
            },
            {
                "size": "2120х39",
                "koef": "2.014",
                "metrs": 1
            },
            {
                "size": "2120х40",
                "koef": "2.065",
                "metrs": 1
            },
            {
                "size": "2120х41",
                "koef": "2.116",
                "metrs": 1
            },
            {
                "size": "2120х42",
                "koef": "2.166",
                "metrs": 1
            },
            {
                "size": "2120х43",
                "koef": "2.217",
                "metrs": 1
            },
            {
                "size": "2120х44",
                "koef": "2.267",
                "metrs": 1
            },
            {
                "size": "2120х45",
                "koef": "2.317",
                "metrs": 1
            },
            {
                "size": "2120х46",
                "koef": "2.368",
                "metrs": 1
            },
            {
                "size": "2120х47",
                "koef": "2.418",
                "metrs": 1
            },
            {
                "size": "2120х48",
                "koef": "2.468",
                "metrs": 1
            },
            {
                "size": "2120х49",
                "koef": "2.519",
                "metrs": 1
            },
            {
                "size": "2120х50",
                "koef": "2.569",
                "metrs": 1
            },
            {
                "size": "2120х51",
                "koef": "2.619",
                "metrs": 1
            },
            {
                "size": "2120х52",
                "koef": "2.669",
                "metrs": 1
            },
            {
                "size": "2120х53",
                "koef": "2.719",
                "metrs": 1
            },
            {
                "size": "2120х54",
                "koef": "2.769",
                "metrs": 1
            },
            {
                "size": "2120х55",
                "koef": "2.819",
                "metrs": 1
            },
            {
                "size": "2120х56",
                "koef": "2.869",
                "metrs": 1
            },
            {
                "size": "2120х57",
                "koef": "2.918",
                "metrs": 1
            },
            {
                "size": "2120х58",
                "koef": "2.968",
                "metrs": 1
            },
            {
                "size": "2120х59",
                "koef": "3.018",
                "metrs": 1
            },
            {
                "size": "2120х60",
                "koef": "3.068",
                "metrs": 1
            },
            {
                "size": "2120х8",
                "koef": "0.4193",
                "metrs": 1
            },
            {
                "size": "2220х10",
                "koef": "0.5485",
                "metrs": 1
            },
            {
                "size": "2220х12",
                "koef": "0.6576",
                "metrs": 1
            },
            {
                "size": "2220х14",
                "koef": "0.7665",
                "metrs": 1
            },
            {
                "size": "2220х15",
                "koef": "0.8209",
                "metrs": 1
            },
            {
                "size": "2220х16",
                "koef": "0.8752",
                "metrs": 1
            },
            {
                "size": "2220х17",
                "koef": "0.9295",
                "metrs": 1
            },
            {
                "size": "2220х18",
                "koef": "0.9837",
                "metrs": 1
            },
            {
                "size": "2220х19",
                "koef": "1.038",
                "metrs": 1
            },
            {
                "size": "2220х20",
                "koef": "1.092",
                "metrs": 1
            },
            {
                "size": "2220х21",
                "koef": "1.146",
                "metrs": 1
            },
            {
                "size": "2220х22",
                "koef": "1.2",
                "metrs": 1
            },
            {
                "size": "2220х23",
                "koef": "1.254",
                "metrs": 1
            },
            {
                "size": "2220х24",
                "koef": "1.308",
                "metrs": 1
            },
            {
                "size": "2220х25",
                "koef": "1.362",
                "metrs": 1
            },
            {
                "size": "2220х26",
                "koef": "1.416",
                "metrs": 1
            },
            {
                "size": "2220х27",
                "koef": "1.47",
                "metrs": 1
            },
            {
                "size": "2220х28",
                "koef": "1.523",
                "metrs": 1
            },
            {
                "size": "2220х29",
                "koef": "1.577",
                "metrs": 1
            },
            {
                "size": "2220х30",
                "koef": "1.631",
                "metrs": 1
            },
            {
                "size": "2220х31",
                "koef": "1.684",
                "metrs": 1
            },
            {
                "size": "2220х32",
                "koef": "1.738",
                "metrs": 1
            },
            {
                "size": "2220х33",
                "koef": "1.791",
                "metrs": 1
            },
            {
                "size": "2220х34",
                "koef": "1.845",
                "metrs": 1
            },
            {
                "size": "2220х35",
                "koef": "1.898",
                "metrs": 1
            },
            {
                "size": "2220х36",
                "koef": "1.951",
                "metrs": 1
            },
            {
                "size": "2220х37",
                "koef": "2.005",
                "metrs": 1
            },
            {
                "size": "2220х38",
                "koef": "2.058",
                "metrs": 1
            },
            {
                "size": "2220х39",
                "koef": "2.111",
                "metrs": 1
            },
            {
                "size": "2220х40",
                "koef": "2.164",
                "metrs": 1
            },
            {
                "size": "2220х41",
                "koef": "2.217",
                "metrs": 1
            },
            {
                "size": "2220х42",
                "koef": "2.27",
                "metrs": 1
            },
            {
                "size": "2220х43",
                "koef": "2.323",
                "metrs": 1
            },
            {
                "size": "2220х44",
                "koef": "2.376",
                "metrs": 1
            },
            {
                "size": "2220х45",
                "koef": "2.429",
                "metrs": 1
            },
            {
                "size": "2220х46",
                "koef": "2.482",
                "metrs": 1
            },
            {
                "size": "2220х47",
                "koef": "2.535",
                "metrs": 1
            },
            {
                "size": "2220х48",
                "koef": "2.587",
                "metrs": 1
            },
            {
                "size": "2220х49",
                "koef": "2.64",
                "metrs": 1
            },
            {
                "size": "2220х50",
                "koef": "2.693",
                "metrs": 1
            },
            {
                "size": "2220х51",
                "koef": "2.745",
                "metrs": 1
            },
            {
                "size": "2220х52",
                "koef": "2.798",
                "metrs": 1
            },
            {
                "size": "2220х53",
                "koef": "2.85",
                "metrs": 1
            },
            {
                "size": "2220х54",
                "koef": "2.903",
                "metrs": 1
            },
            {
                "size": "2220х55",
                "koef": "2.955",
                "metrs": 1
            },
            {
                "size": "2220х56",
                "koef": "3.008",
                "metrs": 1
            },
            {
                "size": "2220х57",
                "koef": "3.06",
                "metrs": 1
            },
            {
                "size": "2220х58",
                "koef": "3.112",
                "metrs": 1
            },
            {
                "size": "2220х59",
                "koef": "3.164",
                "metrs": 1
            },
            {
                "size": "2220х60",
                "koef": "3.216",
                "metrs": 1
            },
            {
                "size": "2220х8",
                "koef": "0.4392",
                "metrs": 1
            },
            {
                "size": "2320х10",
                "koef": "0.5733",
                "metrs": 1
            },
            {
                "size": "2320х12",
                "koef": "0.6874",
                "metrs": 1
            },
            {
                "size": "2320х14",
                "koef": "0.8012",
                "metrs": 1
            },
            {
                "size": "2320х15",
                "koef": "0.8581",
                "metrs": 1
            },
            {
                "size": "2320х16",
                "koef": "0.9149",
                "metrs": 1
            },
            {
                "size": "2320х17",
                "koef": "0.9717",
                "metrs": 1
            },
            {
                "size": "2320х18",
                "koef": "1.028",
                "metrs": 1
            },
            {
                "size": "2320х19",
                "koef": "1.085",
                "metrs": 1
            },
            {
                "size": "2320х20",
                "koef": "1.142",
                "metrs": 1
            },
            {
                "size": "2320х21",
                "koef": "1.198",
                "metrs": 1
            },
            {
                "size": "2320х22",
                "koef": "1.255",
                "metrs": 1
            },
            {
                "size": "2320х23",
                "koef": "1.311",
                "metrs": 1
            },
            {
                "size": "2320х24",
                "koef": "1.368",
                "metrs": 1
            },
            {
                "size": "2320х25",
                "koef": "1.424",
                "metrs": 1
            },
            {
                "size": "2320х26",
                "koef": "1.48",
                "metrs": 1
            },
            {
                "size": "2320х27",
                "koef": "1.537",
                "metrs": 1
            },
            {
                "size": "2320х28",
                "koef": "1.593",
                "metrs": 1
            },
            {
                "size": "2320х29",
                "koef": "1.649",
                "metrs": 1
            },
            {
                "size": "2320х30",
                "koef": "1.705",
                "metrs": 1
            },
            {
                "size": "2320х31",
                "koef": "1.761",
                "metrs": 1
            },
            {
                "size": "2320х32",
                "koef": "1.817",
                "metrs": 1
            },
            {
                "size": "2320х33",
                "koef": "1.873",
                "metrs": 1
            },
            {
                "size": "2320х34",
                "koef": "1.929",
                "metrs": 1
            },
            {
                "size": "2320х35",
                "koef": "1.985",
                "metrs": 1
            },
            {
                "size": "2320х36",
                "koef": "2.041",
                "metrs": 1
            },
            {
                "size": "2320х37",
                "koef": "2.096",
                "metrs": 1
            },
            {
                "size": "2320х38",
                "koef": "2.152",
                "metrs": 1
            },
            {
                "size": "2320х39",
                "koef": "2.208",
                "metrs": 1
            },
            {
                "size": "2320х40",
                "koef": "2.263",
                "metrs": 1
            },
            {
                "size": "2320х41",
                "koef": "2.319",
                "metrs": 1
            },
            {
                "size": "2320х42",
                "koef": "2.375",
                "metrs": 1
            },
            {
                "size": "2320х43",
                "koef": "2.43",
                "metrs": 1
            },
            {
                "size": "2320х44",
                "koef": "2.485",
                "metrs": 1
            },
            {
                "size": "2320х45",
                "koef": "2.541",
                "metrs": 1
            },
            {
                "size": "2320х46",
                "koef": "2.596",
                "metrs": 1
            },
            {
                "size": "2320х47",
                "koef": "2.651",
                "metrs": 1
            },
            {
                "size": "2320х48",
                "koef": "2.707",
                "metrs": 1
            },
            {
                "size": "2320х49",
                "koef": "2.762",
                "metrs": 1
            },
            {
                "size": "2320х50",
                "koef": "2.817",
                "metrs": 1
            },
            {
                "size": "2320х51",
                "koef": "2.872",
                "metrs": 1
            },
            {
                "size": "2320х52",
                "koef": "2.927",
                "metrs": 1
            },
            {
                "size": "2320х53",
                "koef": "2.982",
                "metrs": 1
            },
            {
                "size": "2320х54",
                "koef": "3.037",
                "metrs": 1
            },
            {
                "size": "2320х56",
                "koef": "3.147",
                "metrs": 1
            },
            {
                "size": "2320х57",
                "koef": "3.201",
                "metrs": 1
            },
            {
                "size": "2320х58",
                "koef": "3.256",
                "metrs": 1
            },
            {
                "size": "2320х59",
                "koef": "3.311",
                "metrs": 1
            },
            {
                "size": "2320х60",
                "koef": "3.365",
                "metrs": 1
            },
            {
                "size": "2320х8",
                "koef": "0.459",
                "metrs": 1
            },
            {
                "size": "2420х14",
                "koef": "0.836",
                "metrs": 1
            },
            {
                "size": "2420х15",
                "koef": "0.8953",
                "metrs": 1
            },
            {
                "size": "2420х16",
                "koef": "0.9546",
                "metrs": 1
            },
            {
                "size": "2420х17",
                "koef": "1.014",
                "metrs": 1
            },
            {
                "size": "2420х18",
                "koef": "1.073",
                "metrs": 1
            },
            {
                "size": "2420х19",
                "koef": "1.132",
                "metrs": 1
            },
            {
                "size": "2420х20",
                "koef": "1.191",
                "metrs": 1
            },
            {
                "size": "2420х21",
                "koef": "1.25",
                "metrs": 1
            },
            {
                "size": "2420х22",
                "koef": "1.309",
                "metrs": 1
            },
            {
                "size": "2420х23",
                "koef": "1.368",
                "metrs": 1
            },
            {
                "size": "2420х24",
                "koef": "1.427",
                "metrs": 1
            },
            {
                "size": "2420х25",
                "koef": "1.486",
                "metrs": 1
            },
            {
                "size": "2420х26",
                "koef": "1.545",
                "metrs": 1
            },
            {
                "size": " 2420х27",
                "koef": "1.604",
                "metrs": 1
            },
            {
                "size": "2420х28",
                "koef": "1.662",
                "metrs": 1
            },
            {
                "size": "2420х29",
                "koef": "1.721",
                "metrs": 1
            },
            {
                "size": "2420х30",
                "koef": "1.779",
                "metrs": 1
            },
            {
                "size": "2420х31",
                "koef": "1.838",
                "metrs": 1
            },
            {
                "size": "2420х32",
                "koef": "1.897",
                "metrs": 1
            },
            {
                "size": "2420х33",
                "koef": "1.955",
                "metrs": 1
            },
            {
                "size": "2420х34",
                "koef": "2.013",
                "metrs": 1
            },
            {
                "size": "2420х35",
                "koef": "2.072",
                "metrs": 1
            },
            {
                "size": "2420х36",
                "koef": "2.13",
                "metrs": 1
            },
            {
                "size": "2420х37",
                "koef": "2.188",
                "metrs": 1
            },
            {
                "size": "2420х38",
                "koef": "2.246",
                "metrs": 1
            },
            {
                "size": "2420х39",
                "koef": "2.305",
                "metrs": 1
            },
            {
                "size": "2420х40",
                "koef": "2.363",
                "metrs": 1
            },
            {
                "size": "2420х41",
                "koef": "2.421",
                "metrs": 1
            },
            {
                "size": "2420х42",
                "koef": "2.479",
                "metrs": 1
            },
            {
                "size": "2420х43",
                "koef": "2.537",
                "metrs": 1
            },
            {
                "size": "2420х44",
                "koef": "2.595",
                "metrs": 1
            },
            {
                "size": "2420х45",
                "koef": "2.652",
                "metrs": 1
            },
            {
                "size": "2420х46",
                "koef": "2.71",
                "metrs": 1
            },
            {
                "size": "2420х47",
                "koef": "2.768",
                "metrs": 1
            },
            {
                "size": "2420х48",
                "koef": "2.826",
                "metrs": 1
            },
            {
                "size": "2420х49",
                "koef": "2.883",
                "metrs": 1
            },
            {
                "size": "2420х50",
                "koef": "2.941",
                "metrs": 1
            },
            {
                "size": "2420х51",
                "koef": "2.999",
                "metrs": 1
            },
            {
                "size": "2420х52",
                "koef": "3.056",
                "metrs": 1
            },
            {
                "size": "2420х53",
                "koef": "3.114",
                "metrs": 1
            },
            {
                "size": "2420х54",
                "koef": "3.171",
                "metrs": 1
            },
            {
                "size": "2420х55",
                "koef": "3.228",
                "metrs": 1
            },
            {
                "size": "2420х56",
                "koef": "3.286",
                "metrs": 1
            },
            {
                "size": "2420х57",
                "koef": "3.343",
                "metrs": 1
            },
            {
                "size": "2420х58",
                "koef": "3.4",
                "metrs": 1
            },
            {
                "size": "2420х59",
                "koef": "3.457",
                "metrs": 1
            },
            {
                "size": "2420х60",
                "koef": "3.514",
                "metrs": 1
            },
            {
                "size": "2520х14",
                "koef": "0.8707",
                "metrs": 1
            },
            {
                "size": "2520х15",
                "koef": "0.9326",
                "metrs": 1
            },
            {
                "size": "2520х16",
                "koef": "0.9943",
                "metrs": 1
            },
            {
                "size": "2520х17",
                "koef": "1.056",
                "metrs": 1
            },
            {
                "size": "2520х18",
                "koef": "1.118",
                "metrs": 1
            },
            {
                "size": "2520х19",
                "koef": "1.179",
                "metrs": 1
            },
            {
                "size": "2520х20",
                "koef": "1.241",
                "metrs": 1
            },
            {
                "size": "2520х21",
                "koef": "1.302",
                "metrs": 1
            },
            {
                "size": "2520х22",
                "koef": "1.364",
                "metrs": 1
            },
            {
                "size": "2520х23",
                "koef": "1.425",
                "metrs": 1
            },
            {
                "size": "2520х24",
                "koef": "1.487",
                "metrs": 1
            },
            {
                "size": "2520х25",
                "koef": "1.548",
                "metrs": 1
            },
            {
                "size": "2520х26",
                "koef": "1.609",
                "metrs": 1
            },
            {
                "size": "2520х27",
                "koef": "1.671",
                "metrs": 1
            },
            {
                "size": "2520х28",
                "koef": "1.732",
                "metrs": 1
            },
            {
                "size": "2520х29",
                "koef": "1.793",
                "metrs": 1
            },
            {
                "size": "2520х30",
                "koef": "1.854",
                "metrs": 1
            },
            {
                "size": "2520х31",
                "koef": "1.915",
                "metrs": 1
            },
            {
                "size": "2520х32",
                "koef": "1.976",
                "metrs": 1
            },
            {
                "size": "2520х33",
                "koef": "2.037",
                "metrs": 1
            },
            {
                "size": "2520х34",
                "koef": "2.098",
                "metrs": 1
            },
            {
                "size": "2520х35",
                "koef": "2.159",
                "metrs": 1
            },
            {
                "size": "2520х36",
                "koef": "2.219",
                "metrs": 1
            },
            {
                "size": " 2520х37",
                "koef": "2.28",
                "metrs": 1
            },
            {
                "size": "2520х38",
                "koef": "2.341",
                "metrs": 1
            },
            {
                "size": "2520х39",
                "koef": "2.401",
                "metrs": 1
            },
            {
                "size": "2520х40",
                "koef": "2.462",
                "metrs": 1
            },
            {
                "size": "2520х41",
                "koef": "2.523",
                "metrs": 1
            },
            {
                "size": "2520х42",
                "koef": "2.583",
                "metrs": 1
            },
            {
                "size": "2520х43",
                "koef": "2.643",
                "metrs": 1
            },
            {
                "size": "2520х44",
                "koef": "2.704",
                "metrs": 1
            },
            {
                "size": "2520х45",
                "koef": "2.764",
                "metrs": 1
            },
            {
                "size": "2520х46",
                "koef": "2.824",
                "metrs": 1
            },
            {
                "size": "2520х47",
                "koef": "2.885",
                "metrs": 1
            },
            {
                "size": "2520х48",
                "koef": "2.945",
                "metrs": 1
            },
            {
                "size": "2520х49",
                "koef": "3.005",
                "metrs": 1
            },
            {
                "size": "2520х50",
                "koef": "3.065",
                "metrs": 1
            },
            {
                "size": "2520х51",
                "koef": "3.125",
                "metrs": 1
            },
            {
                "size": "2520х52",
                "koef": "3.185",
                "metrs": 1
            },
            {
                "size": "2520х53",
                "koef": "3.245",
                "metrs": 1
            },
            {
                "size": "2520х54",
                "koef": "3.305",
                "metrs": 1
            },
            {
                "size": "2520х55",
                "koef": "3.365",
                "metrs": 1
            },
            {
                "size": "2520х56",
                "koef": "3.425",
                "metrs": 1
            },
            {
                "size": "2520х57",
                "koef": "3.484",
                "metrs": 1
            },
            {
                "size": "2520х58",
                "koef": "3.544",
                "metrs": 1
            },
            {
                "size": "2520х59",
                "koef": "3.604",
                "metrs": 1
            },
            {
                "size": "2520х60",
                "koef": "3.663",
                "metrs": 1
            },
            {
                "size": "2620х14",
                "koef": "0.9055",
                "metrs": 1
            },
            {
                "size": "2620х15",
                "koef": "0.9698",
                "metrs": 1
            },
            {
                "size": "2620х16",
                "koef": "1.034",
                "metrs": 1
            },
            {
                "size": "2620х17",
                "koef": "1.098",
                "metrs": 1
            },
            {
                "size": "2620х18",
                "koef": "1.162",
                "metrs": 1
            },
            {
                "size": "2620х19",
                "koef": "1.227",
                "metrs": 1
            },
            {
                "size": "2620х20",
                "koef": "1.291",
                "metrs": 1
            },
            {
                "size": "2620х21",
                "koef": "1.355",
                "metrs": 1
            },
            {
                "size": "2620х22",
                "koef": "1.419",
                "metrs": 1
            },
            {
                "size": "2620х23",
                "koef": "1.482",
                "metrs": 1
            },
            {
                "size": "2620х24",
                "koef": "1.546",
                "metrs": 1
            },
            {
                "size": "2620х25",
                "koef": "1.61",
                "metrs": 1
            },
            {
                "size": "2620х26",
                "koef": "1.674",
                "metrs": 1
            },
            {
                "size": "2620х27",
                "koef": "1.738",
                "metrs": 1
            },
            {
                "size": "2620х28",
                "koef": "1.801",
                "metrs": 1
            },
            {
                "size": "2620х29",
                "koef": "1.865",
                "metrs": 1
            },
            {
                "size": "2620х30",
                "koef": "1.928",
                "metrs": 1
            },
            {
                "size": "2620х31",
                "koef": "1.992",
                "metrs": 1
            },
            {
                "size": "2620х32",
                "koef": "2.055",
                "metrs": 1
            },
            {
                "size": "2620х33",
                "koef": "2.119",
                "metrs": 1
            },
            {
                "size": "2620х34",
                "koef": "2.182",
                "metrs": 1
            },
            {
                "size": "2620х35",
                "koef": "2.245",
                "metrs": 1
            },
            {
                "size": "2620х36",
                "koef": "2.309",
                "metrs": 1
            },
            {
                "size": "2620х37",
                "koef": "2.372",
                "metrs": 1
            },
            {
                "size": "2620х38",
                "koef": "2.435",
                "metrs": 1
            },
            {
                "size": "2620х39",
                "koef": "2.498",
                "metrs": 1
            },
            {
                "size": "2620х40",
                "koef": "2.561",
                "metrs": 1
            },
            {
                "size": "2620х41",
                "koef": "2.624",
                "metrs": 1
            },
            {
                "size": "2620х42",
                "koef": "2.687",
                "metrs": 1
            },
            {
                "size": "2620х43",
                "koef": "2.75",
                "metrs": 1
            },
            {
                "size": "2620х44",
                "koef": "2.813",
                "metrs": 1
            },
            {
                "size": "2620х45",
                "koef": "2.876",
                "metrs": 1
            },
            {
                "size": "2620х46",
                "koef": "2.939",
                "metrs": 1
            },
            {
                "size": "2620х47",
                "koef": "3.001",
                "metrs": 1
            },
            {
                "size": "2620х48",
                "koef": "3.064",
                "metrs": 1
            },
            {
                "size": "2620х49",
                "koef": "3.127",
                "metrs": 1
            },
            {
                "size": "2620х50",
                "koef": "3.189",
                "metrs": 1
            },
            {
                "size": "2620х51",
                "koef": "3.252",
                "metrs": 1
            },
            {
                "size": "2620х52",
                "koef": "3.314",
                "metrs": 1
            },
            {
                "size": "2620х53",
                "koef": "3.377",
                "metrs": 1
            },
            {
                "size": "2620х54",
                "koef": "3.439",
                "metrs": 1
            },
            {
                "size": "2620х55",
                "koef": "3.501",
                "metrs": 1
            },
            {
                "size": "2620х56",
                "koef": "3.564",
                "metrs": 1
            },
            {
                "size": "2620х57",
                "koef": "3.626",
                "metrs": 1
            },
            {
                "size": "2620х58",
                "koef": "3.688",
                "metrs": 1
            },
            {
                "size": "2620х59",
                "koef": "3.75",
                "metrs": 1
            },
            {
                "size": "2620х60",
                "koef": "3.812",
                "metrs": 1
            },
            {
                "size": "2720х14",
                "koef": "0.9402",
                "metrs": 1
            },
            {
                "size": "2720х15",
                "koef": "1.007",
                "metrs": 1
            },
            {
                "size": "2720х16",
                "koef": "1.074",
                "metrs": 1
            },
            {
                "size": "2720х17",
                "koef": "1.14",
                "metrs": 1
            },
            {
                "size": "2720х18",
                "koef": "1.207",
                "metrs": 1
            },
            {
                "size": "2720х19",
                "koef": "1.274",
                "metrs": 1
            },
            {
                "size": "2720х20",
                "koef": "1.34",
                "metrs": 1
            },
            {
                "size": "2720х21",
                "koef": "1.407",
                "metrs": 1
            },
            {
                "size": "2720х22",
                "koef": "1.473",
                "metrs": 1
            },
            {
                "size": "2720х23",
                "koef": "1.54",
                "metrs": 1
            },
            {
                "size": "2720х24",
                "koef": "1.606",
                "metrs": 1
            },
            {
                "size": "2720х25",
                "koef": "1.672",
                "metrs": 1
            },
            {
                "size": "2720х26",
                "koef": "1.738",
                "metrs": 1
            },
            {
                "size": "2720х27",
                "koef": "1.805",
                "metrs": 1
            },
            {
                "size": "2720х28",
                "koef": "1.871",
                "metrs": 1
            },
            {
                "size": "2720х29",
                "koef": "1.937",
                "metrs": 1
            },
            {
                "size": "2720х30",
                "koef": "2.003",
                "metrs": 1
            },
            {
                "size": "2720х31",
                "koef": "2.069",
                "metrs": 1
            },
            {
                "size": "2720х32",
                "koef": "2.135",
                "metrs": 1
            },
            {
                "size": "2720х33",
                "koef": "2.201",
                "metrs": 1
            },
            {
                "size": "2720х34",
                "koef": "2.267",
                "metrs": 1
            },
            {
                "size": "2720х35",
                "koef": "2.332",
                "metrs": 1
            },
            {
                "size": "2720х36",
                "koef": "2.398",
                "metrs": 1
            },
            {
                "size": "2720х37",
                "koef": "2.464",
                "metrs": 1
            },
            {
                "size": "2720х38",
                "koef": "2.529",
                "metrs": 1
            },
            {
                "size": "2720х39",
                "koef": "2.595",
                "metrs": 1
            },
            {
                "size": "2720х40",
                "koef": "2.661",
                "metrs": 1
            },
            {
                "size": "2720х41",
                "koef": "2.726",
                "metrs": 1
            },
            {
                "size": "2720х42",
                "koef": "2.791",
                "metrs": 1
            },
            {
                "size": "2720х43",
                "koef": "2.857",
                "metrs": 1
            },
            {
                "size": "2720х44",
                "koef": "2.922",
                "metrs": 1
            },
            {
                "size": "2720х45",
                "koef": "2.988",
                "metrs": 1
            },
            {
                "size": "2720х46",
                "koef": "3.053",
                "metrs": 1
            },
            {
                "size": "2720х47",
                "koef": "3.118",
                "metrs": 1
            },
            {
                "size": "2720х48",
                "koef": "3.183",
                "metrs": 1
            },
            {
                "size": "2720х49",
                "koef": "3.248",
                "metrs": 1
            },
            {
                "size": "2720х50",
                "koef": "3.313",
                "metrs": 1
            },
            {
                "size": "2720х51",
                "koef": "3.378",
                "metrs": 1
            },
            {
                "size": "2720х52",
                "koef": "3.443",
                "metrs": 1
            },
            {
                "size": "2720х53",
                "koef": "3.508",
                "metrs": 1
            },
            {
                "size": "2720х54",
                "koef": "3.573",
                "metrs": 1
            },
            {
                "size": "2720х55",
                "koef": "3.638",
                "metrs": 1
            },
            {
                "size": "2720х56",
                "koef": "3.703",
                "metrs": 1
            },
            {
                "size": "2720х57",
                "koef": "3.767",
                "metrs": 1
            },
            {
                "size": "2720х58",
                "koef": "3.832",
                "metrs": 1
            },
            {
                "size": "2720х59",
                "koef": "3.896",
                "metrs": 1
            },
            {
                "size": "2720х60",
                "koef": "3.961",
                "metrs": 1
            },
            {
                "size": "2820х14",
                "koef": "0.975",
                "metrs": 1
            },
            {
                "size": "2820х15",
                "koef": "1.044",
                "metrs": 1
            },
            {
                "size": "2820х16",
                "koef": "1.113",
                "metrs": 1
            },
            {
                "size": "2820х17",
                "koef": "1.183",
                "metrs": 1
            },
            {
                "size": "2820х18",
                "koef": "1.252",
                "metrs": 1
            },
            {
                "size": "2820х19",
                "koef": "1.321",
                "metrs": 1
            },
            {
                "size": "2820х20",
                "koef": "1.39",
                "metrs": 1
            },
            {
                "size": "2820х21",
                "koef": "1.459",
                "metrs": 1
            },
            {
                "size": "2820х22",
                "koef": "1.528",
                "metrs": 1
            },
            {
                "size": "2820х23",
                "koef": "1.597",
                "metrs": 1
            },
            {
                "size": "2820х24",
                "koef": "1.665",
                "metrs": 1
            },
            {
                "size": "2820х25",
                "koef": "1.734",
                "metrs": 1
            },
            {
                "size": "2820х26",
                "koef": "1.803",
                "metrs": 1
            },
            {
                "size": "2820х27",
                "koef": "1.872",
                "metrs": 1
            },
            {
                "size": "2820х28",
                "koef": "1.94",
                "metrs": 1
            },
            {
                "size": "2820х29",
                "koef": "2.009",
                "metrs": 1
            },
            {
                "size": "2820х30",
                "koef": "2.077",
                "metrs": 1
            },
            {
                "size": "2820х31",
                "koef": "2.146",
                "metrs": 1
            },
            {
                "size": "2820х32",
                "koef": "2.214",
                "metrs": 1
            },
            {
                "size": "2820х33",
                "koef": "2.283",
                "metrs": 1
            },
            {
                "size": "2820х34",
                "koef": "2.351",
                "metrs": 1
            },
            {
                "size": "2820х35",
                "koef": "2.419",
                "metrs": 1
            },
            {
                "size": "2820х36",
                "koef": "2.487",
                "metrs": 1
            },
            {
                "size": "2820х37",
                "koef": "2.556",
                "metrs": 1
            },
            {
                "size": "2820х38",
                "koef": "2.624",
                "metrs": 1
            },
            {
                "size": "2820х39",
                "koef": "2.692",
                "metrs": 1
            },
            {
                "size": "2820х40",
                "koef": "2.76",
                "metrs": 1
            },
            {
                "size": "2820х41",
                "koef": "2.828",
                "metrs": 1
            },
            {
                "size": "2820х42",
                "koef": "2.896",
                "metrs": 1
            },
            {
                "size": "2820х43",
                "koef": "2.964",
                "metrs": 1
            },
            {
                "size": "2820х44",
                "koef": "3.031",
                "metrs": 1
            },
            {
                "size": "2820х45",
                "koef": "3.099",
                "metrs": 1
            },
            {
                "size": "2820х46",
                "koef": "3.167",
                "metrs": 1
            },
            {
                "size": "2820х47",
                "koef": "3.235",
                "metrs": 1
            },
            {
                "size": "2820х48",
                "koef": "3.302",
                "metrs": 1
            },
            {
                "size": "2820х49",
                "koef": "3.37",
                "metrs": 1
            },
            {
                "size": "2820х50",
                "koef": "3.437",
                "metrs": 1
            },
            {
                "size": "2820х51",
                "koef": "3.505",
                "metrs": 1
            },
            {
                "size": "2820х52",
                "koef": "3.572",
                "metrs": 1
            },
            {
                "size": "2820х53",
                "koef": "3.64",
                "metrs": 1
            },
            {
                "size": "2820х54",
                "koef": "3.707",
                "metrs": 1
            },
            {
                "size": "2820х55",
                "koef": "3.774",
                "metrs": 1
            },
            {
                "size": "2820х56",
                "koef": "3.842",
                "metrs": 1
            },
            {
                "size": "2820х57",
                "koef": "3.909",
                "metrs": 1
            },
            {
                "size": "2820х58",
                "koef": "3.976",
                "metrs": 1
            },
            {
                "size": "2820х59",
                "koef": "4.043",
                "metrs": 1
            },
            {
                "size": "2820х60",
                "koef": "4.11",
                "metrs": 1
            },
            {
                "size": "2920х14",
                "koef": "1.01",
                "metrs": 1
            },
            {
                "size": "2920х15",
                "koef": "1.081",
                "metrs": 1
            },
            {
                "size": "2920х16",
                "koef": "1.153",
                "metrs": 1
            },
            {
                "size": "2920х17",
                "koef": "1.225",
                "metrs": 1
            },
            {
                "size": "2920х18",
                "koef": "1.296",
                "metrs": 1
            },
            {
                "size": "2920х19",
                "koef": "1.368",
                "metrs": 1
            },
            {
                "size": "2920х20",
                "koef": "1.439",
                "metrs": 1
            },
            {
                "size": "2920х21",
                "koef": "1.511",
                "metrs": 1
            },
            {
                "size": " 2920х22",
                "koef": "1.582",
                "metrs": 1
            },
            {
                "size": "2920х23",
                "koef": "1.654",
                "metrs": 1
            },
            {
                "size": "2920х24",
                "koef": "1.725",
                "metrs": 1
            },
            {
                "size": "2920х25",
                "koef": "1.796",
                "metrs": 1
            },
            {
                "size": "2920х26",
                "koef": "1.867",
                "metrs": 1
            },
            {
                "size": "2920х27",
                "koef": "1.939",
                "metrs": 1
            },
            {
                "size": "2920х28",
                "koef": "2.01",
                "metrs": 1
            },
            {
                "size": "2920х29",
                "koef": "2.081",
                "metrs": 1
            },
            {
                "size": "2920х30",
                "koef": "2.152",
                "metrs": 1
            },
            {
                "size": "2920х31",
                "koef": "2.223",
                "metrs": 1
            },
            {
                "size": "2920х32",
                "koef": "2.294",
                "metrs": 1
            },
            {
                "size": "2920х33",
                "koef": "2.364",
                "metrs": 1
            },
            {
                "size": "2920х34",
                "koef": "2.435",
                "metrs": 1
            },
            {
                "size": "2920х35",
                "koef": "2.506",
                "metrs": 1
            },
            {
                "size": "2920х36",
                "koef": "2.577",
                "metrs": 1
            },
            {
                "size": "2920х37",
                "koef": "2.647",
                "metrs": 1
            },
            {
                "size": "2920х38",
                "koef": "2.718",
                "metrs": 1
            },
            {
                "size": "2920х39",
                "koef": "2.789",
                "metrs": 1
            },
            {
                "size": "2920х40",
                "koef": "2.859",
                "metrs": 1
            },
            {
                "size": "2920х41",
                "koef": "2.93",
                "metrs": 1
            },
            {
                "size": "2920х42",
                "koef": "3",
                "metrs": 1
            },
            {
                "size": "2920х43",
                "koef": "3.07",
                "metrs": 1
            },
            {
                "size": "2920х44",
                "koef": "3.141",
                "metrs": 1
            },
            {
                "size": "2920х45",
                "koef": "3.211",
                "metrs": 1
            },
            {
                "size": "2920х46",
                "koef": "3.281",
                "metrs": 1
            },
            {
                "size": "2920х47",
                "koef": "3.351",
                "metrs": 1
            },
            {
                "size": "2920х48",
                "koef": "3.421",
                "metrs": 1
            },
            {
                "size": " 2920х49",
                "koef": "3.491",
                "metrs": 1
            },
            {
                "size": "2920х50",
                "koef": "3.561",
                "metrs": 1
            },
            {
                "size": "2920х51",
                "koef": "3.631",
                "metrs": 1
            },
            {
                "size": "2920х52",
                "koef": "3.701",
                "metrs": 1
            },
            {
                "size": "2920х53",
                "koef": "3.771",
                "metrs": 1
            },
            {
                "size": "2920х54",
                "koef": "3.841",
                "metrs": 1
            },
            {
                "size": "2920х55",
                "koef": "3.911",
                "metrs": 1
            },
            {
                "size": "2920х56",
                "koef": "3.98",
                "metrs": 1
            },
            {
                "size": "2920х57",
                "koef": "4.05",
                "metrs": 1
            },
            {
                "size": "2920х58",
                "koef": "4.12",
                "metrs": 1
            },
            {
                "size": "2920х59",
                "koef": "4.189",
                "metrs": 1
            },
            {
                "size": " 2920х60",
                "koef": "4.259",
                "metrs": 1
            },
            {
                "size": "3000х20",
                "koef": "1.479",
                "metrs": 1
            },
            {
                "size": "3000х21",
                "koef": "1.553",
                "metrs": 1
            },
            {
                "size": "3000х22",
                "koef": "1.626",
                "metrs": 1
            },
            {
                "size": "3000х23",
                "koef": "1.699",
                "metrs": 1
            },
            {
                "size": "3000х24",
                "koef": "1.773",
                "metrs": 1
            },
            {
                "size": "3000х25",
                "koef": "1.846",
                "metrs": 1
            },
            {
                "size": "3000х26",
                "koef": "1.919",
                "metrs": 1
            },
            {
                "size": "3000х27",
                "koef": "1.992",
                "metrs": 1
            },
            {
                "size": "3000х28",
                "koef": "2.065",
                "metrs": 1
            },
            {
                "size": "3000х29",
                "koef": "2.138",
                "metrs": 1
            },
            {
                "size": "3000х30",
                "koef": "2.211",
                "metrs": 1
            },
            {
                "size": "3000х31",
                "koef": "2.284",
                "metrs": 1
            },
            {
                "size": "3000х32",
                "koef": "2.357",
                "metrs": 1
            },
            {
                "size": "3000х33",
                "koef": "2.43",
                "metrs": 1
            },
            {
                "size": "3000х34",
                "koef": "2.503",
                "metrs": 1
            },
            {
                "size": "3000х35",
                "koef": "2.576",
                "metrs": 1
            },
            {
                "size": "3000х36",
                "koef": "2.648",
                "metrs": 1
            },
            {
                "size": "3000х37",
                "koef": "2.721",
                "metrs": 1
            },
            {
                "size": "3000х38",
                "koef": "2.793",
                "metrs": 1
            },
            {
                "size": "3000х39",
                "koef": "2.866",
                "metrs": 1
            },
            {
                "size": "3000х40",
                "koef": "2.939",
                "metrs": 1
            },
            {
                "size": "3000х41",
                "koef": "3.011",
                "metrs": 1
            },
            {
                "size": "3000х42",
                "koef": "3.083",
                "metrs": 1
            },
            {
                "size": "3000х43",
                "koef": "3.156",
                "metrs": 1
            },
            {
                "size": "3000х44",
                "koef": "3.228",
                "metrs": 1
            },
            {
                "size": "3000х45",
                "koef": "3.3",
                "metrs": 1
            },
            {
                "size": "3000х46",
                "koef": "3.372",
                "metrs": 1
            },
            {
                "size": "3000х47",
                "koef": "3.445",
                "metrs": 1
            },
            {
                "size": "3000х48",
                "koef": "3.517",
                "metrs": 1
            },
            {
                "size": "3000х49",
                "koef": "3.589",
                "metrs": 1
            },
            {
                "size": "3000х50",
                "koef": "3.661",
                "metrs": 1
            },
            {
                "size": "3000х51",
                "koef": "3.733",
                "metrs": 1
            },
            {
                "size": "3000х52",
                "koef": "3.805",
                "metrs": 1
            },
            {
                "size": "3000х53",
                "koef": "3.876",
                "metrs": 1
            },
            {
                "size": "3000х54",
                "koef": "3.948",
                "metrs": 1
            },
            {
                "size": "3000х55",
                "koef": "4.02",
                "metrs": 1
            },
            {
                "size": " 3000х56",
                "koef": "4.092",
                "metrs": 1
            },
            {
                "size": "3000х57",
                "koef": "4.163",
                "metrs": 1
            },
            {
                "size": "3000х58",
                "koef": "4.235",
                "metrs": 1
            },
            {
                "size": "3000х59",
                "koef": "4.306",
                "metrs": 1
            },
            {
                "size": "3000х60",
                "koef": "4.378",
                "metrs": 1
            },
            {
                "size": "3020х14",
                "koef": "1.044",
                "metrs": 1
            },
            {
                "size": "3020х15",
                "koef": "1.119",
                "metrs": 1
            },
            {
                "size": "3020х16",
                "koef": "1.193",
                "metrs": 1
            },
            {
                "size": "3020х17",
                "koef": "1.267",
                "metrs": 1
            },
            {
                "size": "3020х18",
                "koef": "1.341",
                "metrs": 1
            },
            {
                "size": "3020х19",
                "koef": "1.415",
                "metrs": 1
            },
            {
                "size": "3020х20",
                "koef": "1.489",
                "metrs": 1
            },
            {
                "size": "3020х21",
                "koef": "1.563",
                "metrs": 1
            },
            {
                "size": "3020х22",
                "koef": "1.637",
                "metrs": 1
            },
            {
                "size": "3020х23",
                "koef": "1.711",
                "metrs": 1
            },
            {
                "size": "3020х24",
                "koef": "1.785",
                "metrs": 1
            },
            {
                "size": "3020х25",
                "koef": "1.858",
                "metrs": 1
            },
            {
                "size": "3020х26",
                "koef": "1.932",
                "metrs": 1
            },
            {
                "size": "3020х27",
                "koef": "2.006",
                "metrs": 1
            },
            {
                "size": "3020х28",
                "koef": "2.079",
                "metrs": 1
            },
            {
                "size": "3020х29",
                "koef": "2.153",
                "metrs": 1
            },
            {
                "size": "3020х30",
                "koef": "2.226",
                "metrs": 1
            },
            {
                "size": "3020х31",
                "koef": "2.3",
                "metrs": 1
            },
            {
                "size": "3020х32",
                "koef": "2.373",
                "metrs": 1
            },
            {
                "size": "3020х33",
                "koef": "2.446",
                "metrs": 1
            },
            {
                "size": "3020х34",
                "koef": "2.52",
                "metrs": 1
            },
            {
                "size": "3020х35",
                "koef": "2.593",
                "metrs": 1
            },
            {
                "size": "3020х36",
                "koef": "2.666",
                "metrs": 1
            },
            {
                "size": "3020х37",
                "koef": "2.739",
                "metrs": 1
            },
            {
                "size": "3020х38",
                "koef": "2.812",
                "metrs": 1
            },
            {
                "size": "3020х39",
                "koef": "2.885",
                "metrs": 1
            },
            {
                "size": "3020х40",
                "koef": "2.958",
                "metrs": 1
            },
            {
                "size": "3020х41",
                "koef": "3.031",
                "metrs": 1
            },
            {
                "size": "3020х42",
                "koef": "3.104",
                "metrs": 1
            },
            {
                "size": "3020х43",
                "koef": "3.177",
                "metrs": 1
            },
            {
                "size": "3020х44",
                "koef": "3.25",
                "metrs": 1
            },
            {
                "size": "3020х45",
                "koef": "3.323",
                "metrs": 1
            },
            {
                "size": "3020х46",
                "koef": "3.395",
                "metrs": 1
            },
            {
                "size": " 3020х47",
                "koef": "3.468",
                "metrs": 1
            },
            {
                "size": "3020х48",
                "koef": "3.541",
                "metrs": 1
            },
            {
                "size": "3020х49",
                "koef": "3.613",
                "metrs": 1
            },
            {
                "size": "3020х50",
                "koef": "3.686",
                "metrs": 1
            },
            {
                "size": "3020х51",
                "koef": "3.758",
                "metrs": 1
            },
            {
                "size": "3020х52",
                "koef": "3.83",
                "metrs": 1
            },
            {
                "size": "3020х53",
                "koef": "3.903",
                "metrs": 1
            },
            {
                "size": "3020х54",
                "koef": "3.975",
                "metrs": 1
            },
            {
                "size": "3020х55",
                "koef": "4.047",
                "metrs": 1
            },
            {
                "size": "3020х56",
                "koef": "4.119",
                "metrs": 1
            },
            {
                "size": "3020х57",
                "koef": "4.192",
                "metrs": 1
            },
            {
                "size": "3020х58",
                "koef": "4.264",
                "metrs": 1
            },
            {
                "size": "3020х59",
                "koef": "4.336",
                "metrs": 1
            },
            {
                "size": "3020х60",
                "koef": "4.408",
                "metrs": 1
            },
            {
                "size": "3120х14",
                "koef": "1.079",
                "metrs": 1
            },
            {
                "size": "3120х15",
                "koef": "1.156",
                "metrs": 1
            },
            {
                "size": "3120х16",
                "koef": "1.233",
                "metrs": 1
            },
            {
                "size": "3120х17",
                "koef": "1.309",
                "metrs": 1
            },
            {
                "size": "3120х18",
                "koef": "1.386",
                "metrs": 1
            },
            {
                "size": "3120х19",
                "koef": "1.462",
                "metrs": 1
            },
            {
                "size": "3120х20",
                "koef": "1.539",
                "metrs": 1
            },
            {
                "size": "3120х21",
                "koef": "1.615",
                "metrs": 1
            },
            {
                "size": "3120х22",
                "koef": "1.692",
                "metrs": 1
            },
            {
                "size": "3120х23",
                "koef": "1.768",
                "metrs": 1
            },
            {
                "size": "3120х24",
                "koef": "1.844",
                "metrs": 1
            },
            {
                "size": "3120х25",
                "koef": "1.92",
                "metrs": 1
            },
            {
                "size": "3120х26",
                "koef": "1.997",
                "metrs": 1
            },
            {
                "size": "3120х27",
                "koef": "2.073",
                "metrs": 1
            },
            {
                "size": "3120х28",
                "koef": "2.149",
                "metrs": 1
            },
            {
                "size": "3120х29",
                "koef": "2.225",
                "metrs": 1
            },
            {
                "size": "3120х30",
                "koef": "2.301",
                "metrs": 1
            },
            {
                "size": "3120х31",
                "koef": "2.377",
                "metrs": 1
            },
            {
                "size": "3120х32",
                "koef": "2.452",
                "metrs": 1
            },
            {
                "size": "3120х33",
                "koef": "2.528",
                "metrs": 1
            },
            {
                "size": "3120х34",
                "koef": "2.604",
                "metrs": 1
            },
            {
                "size": "3120х35",
                "koef": "2.68",
                "metrs": 1
            },
            {
                "size": "3120х36",
                "koef": "2.755",
                "metrs": 1
            },
            {
                "size": "3120х37",
                "koef": "2.831",
                "metrs": 1
            },
            {
                "size": "3120х38",
                "koef": "2.907",
                "metrs": 1
            },
            {
                "size": " 3120х39",
                "koef": "2.982",
                "metrs": 1
            },
            {
                "size": "3120х40",
                "koef": "3.058",
                "metrs": 1
            },
            {
                "size": "3120х41",
                "koef": "3.133",
                "metrs": 1
            },
            {
                "size": "3120х42",
                "koef": "3.208",
                "metrs": 1
            },
            {
                "size": "3120х43",
                "koef": "3.284",
                "metrs": 1
            },
            {
                "size": "3120х44",
                "koef": "3.359",
                "metrs": 1
            },
            {
                "size": "3120х45",
                "koef": "3.434",
                "metrs": 1
            },
            {
                "size": "3120х46",
                "koef": "3.509",
                "metrs": 1
            },
            {
                "size": "3120х47",
                "koef": "3.585",
                "metrs": 1
            },
            {
                "size": "3120х48",
                "koef": "3.66",
                "metrs": 1
            },
            {
                "size": "3120х49",
                "koef": "3.735",
                "metrs": 1
            },
            {
                "size": "3120х50",
                "koef": "3.81",
                "metrs": 1
            },
            {
                "size": "3220х14",
                "koef": "1.114",
                "metrs": 1
            },
            {
                "size": "3220х15",
                "koef": "1.193",
                "metrs": 1
            },
            {
                "size": "3220х16",
                "koef": "1.272",
                "metrs": 1
            },
            {
                "size": "3220х17",
                "koef": "1.351",
                "metrs": 1
            },
            {
                "size": "3220х18",
                "koef": "1.43",
                "metrs": 1
            },
            {
                "size": " 3220х19",
                "koef": "1.509",
                "metrs": 1
            },
            {
                "size": "3220х20",
                "koef": "1.588",
                "metrs": 1
            },
            {
                "size": "3220х21",
                "koef": "1.667",
                "metrs": 1
            },
            {
                "size": "3220х22",
                "koef": "1.746",
                "metrs": 1
            },
            {
                "size": "3220х23",
                "koef": "1.825",
                "metrs": 1
            },
            {
                "size": "3220х24",
                "koef": "1.904",
                "metrs": 1
            },
            {
                "size": "3220х25",
                "koef": "1.982",
                "metrs": 1
            },
            {
                "size": "3220х26",
                "koef": "2.061",
                "metrs": 1
            },
            {
                "size": "3220х27",
                "koef": "2.14",
                "metrs": 1
            },
            {
                "size": "3220х28",
                "koef": "2.218",
                "metrs": 1
            },
            {
                "size": "3220х29",
                "koef": "2.297",
                "metrs": 1
            },
            {
                "size": "3220х30",
                "koef": "2.375",
                "metrs": 1
            },
            {
                "size": "3220х31",
                "koef": "2.454",
                "metrs": 1
            },
            {
                "size": "3220х32",
                "koef": "2.532",
                "metrs": 1
            },
            {
                "size": "3220х33",
                "koef": "2.61",
                "metrs": 1
            },
            {
                "size": "3220х34",
                "koef": "2.688",
                "metrs": 1
            },
            {
                "size": "3220х35",
                "koef": "2.767",
                "metrs": 1
            },
            {
                "size": "3220х36",
                "koef": "2.845",
                "metrs": 1
            },
            {
                "size": "3220х37",
                "koef": "2.923",
                "metrs": 1
            },
            {
                "size": "3220х38",
                "koef": "3.001",
                "metrs": 1
            },
            {
                "size": "3220х39",
                "koef": "3.079",
                "metrs": 1
            },
            {
                "size": "3220х40",
                "koef": "3.157",
                "metrs": 1
            },
            {
                "size": "3220х41",
                "koef": "3.235",
                "metrs": 1
            },
            {
                "size": "3220х42",
                "koef": "3.313",
                "metrs": 1
            },
            {
                "size": "3220х43",
                "koef": "3.39",
                "metrs": 1
            },
            {
                "size": "3220х44",
                "koef": "3.468",
                "metrs": 1
            },
            {
                "size": "3220х45",
                "koef": "3.546",
                "metrs": 1
            },
            {
                "size": "3220х46",
                "koef": "3.624",
                "metrs": 1
            },
            {
                "size": "3220х47",
                "koef": "3.701",
                "metrs": 1
            },
            {
                "size": "3220х48",
                "koef": "3.779",
                "metrs": 1
            },
            {
                "size": "3220х49",
                "koef": "3.856",
                "metrs": 1
            },
            {
                "size": "3220х50",
                "koef": "3.934",
                "metrs": 1
            },
            {
                "size": "3320х14",
                "koef": "1.149",
                "metrs": 1
            },
            {
                "size": "3320х15",
                "koef": "1.23",
                "metrs": 1
            },
            {
                "size": "3320х16",
                "koef": "1.312",
                "metrs": 1
            },
            {
                "size": "3320х17",
                "koef": "1.394",
                "metrs": 1
            },
            {
                "size": "3320х18",
                "koef": "1.475",
                "metrs": 1
            },
            {
                "size": "3320х19",
                "koef": "1.557",
                "metrs": 1
            },
            {
                "size": "3320х20",
                "koef": "1.638",
                "metrs": 1
            },
            {
                "size": "3320х21",
                "koef": "1.719",
                "metrs": 1
            },
            {
                "size": "3320х22",
                "koef": "1.801",
                "metrs": 1
            },
            {
                "size": "3320х23",
                "koef": "1.882",
                "metrs": 1
            },
            {
                "size": "3320х24",
                "koef": "1.963",
                "metrs": 1
            },
            {
                "size": "3320х25",
                "koef": "2.044",
                "metrs": 1
            },
            {
                "size": "3320х26",
                "koef": "2.126",
                "metrs": 1
            },
            {
                "size": "3320х27",
                "koef": "2.207",
                "metrs": 1
            },
            {
                "size": "3320х28",
                "koef": "2.288",
                "metrs": 1
            },
            {
                "size": "3320х29",
                "koef": "2.369",
                "metrs": 1
            },
            {
                "size": "3320х30",
                "koef": "2.45",
                "metrs": 1
            },
            {
                "size": "3320х31",
                "koef": "2.53",
                "metrs": 1
            },
            {
                "size": "3320х32",
                "koef": "2.611",
                "metrs": 1
            },
            {
                "size": "3320х33",
                "koef": "2.692",
                "metrs": 1
            },
            {
                "size": "3320х34",
                "koef": "2.773",
                "metrs": 1
            },
            {
                "size": "3320х35",
                "koef": "2.854",
                "metrs": 1
            },
            {
                "size": "3320х36",
                "koef": "2.934",
                "metrs": 1
            },
            {
                "size": "3320х37",
                "koef": "3.015",
                "metrs": 1
            },
            {
                "size": "3320х38",
                "koef": "3.095",
                "metrs": 1
            },
            {
                "size": "3320х39",
                "koef": "3.176",
                "metrs": 1
            },
            {
                "size": "3320х40",
                "koef": "3.256",
                "metrs": 1
            },
            {
                "size": "3320х41",
                "koef": "3.337",
                "metrs": 1
            },
            {
                "size": "3320х42",
                "koef": "3.417",
                "metrs": 1
            },
            {
                "size": "3320х43",
                "koef": "3.497",
                "metrs": 1
            },
            {
                "size": "3320х44",
                "koef": "3.577",
                "metrs": 1
            },
            {
                "size": "3320х45",
                "koef": "3.658",
                "metrs": 1
            },
            {
                "size": "3320х46",
                "koef": "3.738",
                "metrs": 1
            },
            {
                "size": "3320х47",
                "koef": "3.818",
                "metrs": 1
            },
            {
                "size": "3320х48",
                "koef": "3.898",
                "metrs": 1
            },
            {
                "size": "3320х49",
                "koef": "3.978",
                "metrs": 1
            },
            {
                "size": "3320х50",
                "koef": "4.058",
                "metrs": 1
            },
            {
                "size": "3420х14",
                "koef": "1.183",
                "metrs": 1
            },
            {
                "size": "3420х15",
                "koef": "1.268",
                "metrs": 1
            },
            {
                "size": "3420х16",
                "koef": "1.352",
                "metrs": 1
            },
            {
                "size": "3420х17",
                "koef": "1.436",
                "metrs": 1
            },
            {
                "size": "3420х18",
                "koef": "1.52",
                "metrs": 1
            },
            {
                "size": "3420х19",
                "koef": "1.604",
                "metrs": 1
            },
            {
                "size": "3420х20",
                "koef": "1.688",
                "metrs": 1
            },
            {
                "size": "3420х21",
                "koef": "1.772",
                "metrs": 1
            },
            {
                "size": "3420х22",
                "koef": "1.855",
                "metrs": 1
            },
            {
                "size": "3420х23",
                "koef": "1.939",
                "metrs": 1
            },
            {
                "size": "3420х24",
                "koef": "2.023",
                "metrs": 1
            },
            {
                "size": "3420х25",
                "koef": "2.106",
                "metrs": 1
            },
            {
                "size": "3420х26",
                "koef": "2.19",
                "metrs": 1
            },
            {
                "size": "3420х27",
                "koef": "2.274",
                "metrs": 1
            },
            {
                "size": "3420х28",
                "koef": "2.357",
                "metrs": 1
            },
            {
                "size": "3420х29",
                "koef": "2.441",
                "metrs": 1
            },
            {
                "size": "3420х30",
                "koef": "2.524",
                "metrs": 1
            },
            {
                "size": "3420х31",
                "koef": "2.607",
                "metrs": 1
            },
            {
                "size": "3420х32",
                "koef": "2.691",
                "metrs": 1
            },
            {
                "size": "3420х33",
                "koef": "2.774",
                "metrs": 1
            },
            {
                "size": "3420х34",
                "koef": "2.857",
                "metrs": 1
            },
            {
                "size": "3420х35",
                "koef": "2.94",
                "metrs": 1
            },
            {
                "size": "3420х36",
                "koef": "3.023",
                "metrs": 1
            },
            {
                "size": "3420х37",
                "koef": "3.107",
                "metrs": 1
            },
            {
                "size": "3420х38",
                "koef": "3.19",
                "metrs": 1
            },
            {
                "size": "3420х39",
                "koef": "3.273",
                "metrs": 1
            },
            {
                "size": "3420х40",
                "koef": "3.355",
                "metrs": 1
            },
            {
                "size": "3420х41",
                "koef": "3.438",
                "metrs": 1
            },
            {
                "size": "3420х42",
                "koef": "3.521",
                "metrs": 1
            },
            {
                "size": " 3420х43",
                "koef": "3.604",
                "metrs": 1
            },
            {
                "size": "3420х44",
                "koef": "3.687",
                "metrs": 1
            },
            {
                "size": "3420х45",
                "koef": "3.769",
                "metrs": 1
            },
            {
                "size": "3420х46",
                "koef": "3.852",
                "metrs": 1
            },
            {
                "size": "3420х47",
                "koef": "3.935",
                "metrs": 1
            },
            {
                "size": "3420х48",
                "koef": "4.017",
                "metrs": 1
            },
            {
                "size": "3420х49",
                "koef": "4.1",
                "metrs": 1
            },
            {
                "size": "3420х50",
                "koef": "4.182",
                "metrs": 1
            },
            {
                "size": "3520х14",
                "koef": "1.218",
                "metrs": 1
            },
            {
                "size": "3520х15",
                "koef": "1.305",
                "metrs": 1
            },
            {
                "size": "3520х16",
                "koef": "1.391",
                "metrs": 1
            },
            {
                "size": "3520х17",
                "koef": "1.478",
                "metrs": 1
            },
            {
                "size": "3520х18",
                "koef": "1.564",
                "metrs": 1
            },
            {
                "size": "3520х19",
                "koef": "1.651",
                "metrs": 1
            },
            {
                "size": "3520х20",
                "koef": "1.737",
                "metrs": 1
            },
            {
                "size": "3520х21",
                "koef": "1.824",
                "metrs": 1
            },
            {
                "size": "3520х22",
                "koef": "1.91",
                "metrs": 1
            },
            {
                "size": "3520х23",
                "koef": "1.996",
                "metrs": 1
            },
            {
                "size": "3520х24",
                "koef": "2.082",
                "metrs": 1
            },
            {
                "size": "3520х25",
                "koef": "2.169",
                "metrs": 1
            },
            {
                "size": "3520х26",
                "koef": "2.255",
                "metrs": 1
            },
            {
                "size": "3520х27",
                "koef": "2.341",
                "metrs": 1
            },
            {
                "size": "3520х28",
                "koef": "2.427",
                "metrs": 1
            },
            {
                "size": " 3520х29",
                "koef": "2.513",
                "metrs": 1
            },
            {
                "size": "3520х30",
                "koef": "2.599",
                "metrs": 1
            },
            {
                "size": "3520х31",
                "koef": "2.684",
                "metrs": 1
            },
            {
                "size": "3520х32",
                "koef": "2.77",
                "metrs": 1
            },
            {
                "size": "3520х33",
                "koef": "2.856",
                "metrs": 1
            },
            {
                "size": "3520х34",
                "koef": "2.942",
                "metrs": 1
            },
            {
                "size": "3520х35",
                "koef": "3.027",
                "metrs": 1
            },
            {
                "size": "3520х36",
                "koef": "3.113",
                "metrs": 1
            },
            {
                "size": "3520х37",
                "koef": "3.198",
                "metrs": 1
            },
            {
                "size": " 3520х38",
                "koef": "3.284",
                "metrs": 1
            },
            {
                "size": "3520х39",
                "koef": "3.369",
                "metrs": 1
            },
            {
                "size": "3520х40",
                "koef": "3.455",
                "metrs": 1
            },
            {
                "size": "3520х41",
                "koef": "3.54",
                "metrs": 1
            },
            {
                "size": "3520х42",
                "koef": "3.625",
                "metrs": 1
            },
            {
                "size": "3520х43",
                "koef": "3.711",
                "metrs": 1
            },
            {
                "size": " 3520х44",
                "koef": "3.796",
                "metrs": 1
            },
            {
                "size": "3520х45",
                "koef": "3.881",
                "metrs": 1
            },
            {
                "size": "3520х46",
                "koef": "3.966",
                "metrs": 1
            },
            {
                "size": "3520х47",
                "koef": "4.051",
                "metrs": 1
            },
            {
                "size": "3520х48",
                "koef": "4.136",
                "metrs": 1
            },
            {
                "size": "3520х49",
                "koef": "4.221",
                "metrs": 1
            },
            {
                "size": "3520х50",
                "koef": "4.306",
                "metrs": 1
            },
            {
                "size": "3620х14",
                "koef": "1.253",
                "metrs": 1
            },
            {
                "size": "3620х15",
                "koef": "1.342",
                "metrs": 1
            },
            {
                "size": "3620х16",
                "koef": "1.431",
                "metrs": 1
            },
            {
                "size": "3620х17",
                "koef": "1.52",
                "metrs": 1
            },
            {
                "size": "3620х18",
                "koef": "1.609",
                "metrs": 1
            },
            {
                "size": "3620х19",
                "koef": "1.698",
                "metrs": 1
            },
            {
                "size": "3620х20",
                "koef": "1.787",
                "metrs": 1
            },
            {
                "size": "3620х21",
                "koef": "1.876",
                "metrs": 1
            },
            {
                "size": "3620х22",
                "koef": "1.965",
                "metrs": 1
            },
            {
                "size": "3620х23",
                "koef": "2.053",
                "metrs": 1
            },
            {
                "size": "3620х24",
                "koef": "2.142",
                "metrs": 1
            },
            {
                "size": "3620х25",
                "koef": "2.231",
                "metrs": 1
            },
            {
                "size": "3620х26",
                "koef": "2.319",
                "metrs": 1
            },
            {
                "size": "3620х27",
                "koef": "2.408",
                "metrs": 1
            },
            {
                "size": "3620х28",
                "koef": "2.496",
                "metrs": 1
            },
            {
                "size": "3620х29",
                "koef": "2.585",
                "metrs": 1
            },
            {
                "size": "3620х30",
                "koef": "2.673",
                "metrs": 1
            },
            {
                "size": "3620х31",
                "koef": "2.761",
                "metrs": 1
            },
            {
                "size": "3620х32",
                "koef": "2.85",
                "metrs": 1
            },
            {
                "size": "3620х33",
                "koef": "2.938",
                "metrs": 1
            },
            {
                "size": "3620х34",
                "koef": "3.026",
                "metrs": 1
            },
            {
                "size": "3620х35",
                "koef": "3.114",
                "metrs": 1
            },
            {
                "size": "3620х36",
                "koef": "3.202",
                "metrs": 1
            },
            {
                "size": "3620х37",
                "koef": "3.29",
                "metrs": 1
            },
            {
                "size": "3620х38",
                "koef": "3.378",
                "metrs": 1
            },
            {
                "size": "3620х39",
                "koef": "3.466",
                "metrs": 1
            },
            {
                "size": "3620х40",
                "koef": "3.554",
                "metrs": 1
            },
            {
                "size": "3620х41",
                "koef": "3.642",
                "metrs": 1
            },
            {
                "size": "3620х42",
                "koef": "3.73",
                "metrs": 1
            },
            {
                "size": "3620х43",
                "koef": "3.817",
                "metrs": 1
            },
            {
                "size": "3620х44",
                "koef": "3.905",
                "metrs": 1
            },
            {
                "size": "3620х45",
                "koef": "3.993",
                "metrs": 1
            },
            {
                "size": "3620х46",
                "koef": "4.08",
                "metrs": 1
            },
            {
                "size": "3620х47",
                "koef": "4.168",
                "metrs": 1
            },
            {
                "size": "3620х48",
                "koef": "4.255",
                "metrs": 1
            },
            {
                "size": "3620х49",
                "koef": "4.343",
                "metrs": 1
            },
            {
                "size": "3620х50",
                "koef": "4.43",
                "metrs": 1
            },
            {
                "size": "3720х14",
                "koef": "1.288",
                "metrs": 1
            },
            {
                "size": "3720х15",
                "koef": "1.379",
                "metrs": 1
            },
            {
                "size": "3720х16",
                "koef": "1.471",
                "metrs": 1
            },
            {
                "size": "3720х17",
                "koef": "1.562",
                "metrs": 1
            },
            {
                "size": "3720х18",
                "koef": "1.654",
                "metrs": 1
            },
            {
                "size": "3720х19",
                "koef": "1.745",
                "metrs": 1
            },
            {
                "size": "3720х20",
                "koef": "1.837",
                "metrs": 1
            },
            {
                "size": "3720х21",
                "koef": "1.928",
                "metrs": 1
            },
            {
                "size": "3720х22",
                "koef": "2.019",
                "metrs": 1
            },
            {
                "size": "3720х23",
                "koef": "2.11",
                "metrs": 1
            },
            {
                "size": "3720х24",
                "koef": "2.202",
                "metrs": 1
            },
            {
                "size": "3720х25",
                "koef": "2.293",
                "metrs": 1
            },
            {
                "size": "3720х26",
                "koef": "2.384",
                "metrs": 1
            },
            {
                "size": "3720х27",
                "koef": "2.475",
                "metrs": 1
            },
            {
                "size": "3720х28",
                "koef": "2.566",
                "metrs": 1
            },
            {
                "size": "3720х29",
                "koef": "2.657",
                "metrs": 1
            },
            {
                "size": "3720х30",
                "koef": "2.747",
                "metrs": 1
            },
            {
                "size": "3720х31",
                "koef": "2.838",
                "metrs": 1
            },
            {
                "size": "3720х32",
                "koef": "2.929",
                "metrs": 1
            },
            {
                "size": "3720х33",
                "koef": "3.02",
                "metrs": 1
            },
            {
                "size": "3720х34",
                "koef": "3.11",
                "metrs": 1
            },
            {
                "size": "3720х35",
                "koef": "3.201",
                "metrs": 1
            },
            {
                "size": "3720х36",
                "koef": "3.292",
                "metrs": 1
            },
            {
                "size": "3720х37",
                "koef": "3.382",
                "metrs": 1
            },
            {
                "size": "3720х38",
                "koef": "3.473",
                "metrs": 1
            },
            {
                "size": "3720х39",
                "koef": "3.563",
                "metrs": 1
            },
            {
                "size": "3720х40",
                "koef": "3.653",
                "metrs": 1
            },
            {
                "size": "3720х41",
                "koef": "3.744",
                "metrs": 1
            },
            {
                "size": "3720х42",
                "koef": "3.834",
                "metrs": 1
            },
            {
                "size": "3720х43",
                "koef": "3.924",
                "metrs": 1
            },
            {
                "size": "3720х44",
                "koef": "4.014",
                "metrs": 1
            },
            {
                "size": "3720х45",
                "koef": "4.104",
                "metrs": 1
            },
            {
                "size": "3720х46",
                "koef": "4.194",
                "metrs": 1
            },
            {
                "size": "3720х47",
                "koef": "4.284",
                "metrs": 1
            },
            {
                "size": "3720х48",
                "koef": "4.374",
                "metrs": 1
            },
            {
                "size": "3720х49",
                "koef": "4.464",
                "metrs": 1
            },
            {
                "size": "3720х50",
                "koef": "4.554",
                "metrs": 1
            },
            {
                "size": "3820х14",
                "koef": "1.322",
                "metrs": 1
            },
            {
                "size": "3820х15",
                "koef": "1.417",
                "metrs": 1
            },
            {
                "size": "3820х16",
                "koef": "1.511",
                "metrs": 1
            },
            {
                "size": "3820х17",
                "koef": "1.605",
                "metrs": 1
            },
            {
                "size": "3820х18",
                "koef": "1.698",
                "metrs": 1
            },
            {
                "size": "3820х19",
                "koef": "1.792",
                "metrs": 1
            },
            {
                "size": "3820х20",
                "koef": "1.886",
                "metrs": 1
            },
            {
                "size": "3820х21",
                "koef": "1.98",
                "metrs": 1
            },
            {
                "size": "3820х22",
                "koef": "2.074",
                "metrs": 1
            },
            {
                "size": "3820х23",
                "koef": "2.167",
                "metrs": 1
            },
            {
                "size": "3820х24",
                "koef": "2.261",
                "metrs": 1
            },
            {
                "size": "3820х25",
                "koef": "2.355",
                "metrs": 1
            },
            {
                "size": "3820х26",
                "koef": "2.448",
                "metrs": 1
            },
            {
                "size": " 3820х27",
                "koef": "2.542",
                "metrs": 1
            },
            {
                "size": "3820х28",
                "koef": "2.635",
                "metrs": 1
            },
            {
                "size": "3820х29",
                "koef": "2.729",
                "metrs": 1
            },
            {
                "size": "3820х30",
                "koef": "2.822",
                "metrs": 1
            },
            {
                "size": "3820х31",
                "koef": "2.915",
                "metrs": 1
            },
            {
                "size": "3820х32",
                "koef": "3.008",
                "metrs": 1
            },
            {
                "size": "3820х33",
                "koef": "3.102",
                "metrs": 1
            },
            {
                "size": "3820х34",
                "koef": "3.195",
                "metrs": 1
            },
            {
                "size": "3820х35",
                "koef": "3.288",
                "metrs": 1
            },
            {
                "size": "3820х36",
                "koef": "3.381",
                "metrs": 1
            },
            {
                "size": "3820х37",
                "koef": "3.474",
                "metrs": 1
            },
            {
                "size": "3820х38",
                "koef": "3.567",
                "metrs": 1
            },
            {
                "size": "3820х39",
                "koef": "3.66",
                "metrs": 1
            },
            {
                "size": "3820х40",
                "koef": "3.753",
                "metrs": 1
            },
            {
                "size": "3820х41",
                "koef": "3.845",
                "metrs": 1
            },
            {
                "size": "3820х42",
                "koef": "3.938",
                "metrs": 1
            },
            {
                "size": "3820х43",
                "koef": "4.031",
                "metrs": 1
            },
            {
                "size": "3820х44",
                "koef": "4.123",
                "metrs": 1
            },
            {
                "size": "3820х45",
                "koef": "4.216",
                "metrs": 1
            },
            {
                "size": "3820х46",
                "koef": "4.309",
                "metrs": 1
            },
            {
                "size": "3820х47",
                "koef": "4.401",
                "metrs": 1
            },
            {
                "size": "3820х48",
                "koef": "4.494",
                "metrs": 1
            },
            {
                "size": " 3820х49",
                "koef": "4.586",
                "metrs": 1
            },
            {
                "size": "3820х50",
                "koef": "4.678",
                "metrs": 1
            },
            {
                "size": "3920х14",
                "koef": "1.357",
                "metrs": 1
            },
            {
                "size": "3920х15",
                "koef": "1.454",
                "metrs": 1
            },
            {
                "size": "3920х16",
                "koef": "1.55",
                "metrs": 1
            },
            {
                "size": "3920х17",
                "koef": "1.647",
                "metrs": 1
            },
            {
                "size": "3920х18",
                "koef": "1.743",
                "metrs": 1
            },
            {
                "size": "3920х19",
                "koef": "1.84",
                "metrs": 1
            },
            {
                "size": "3920х20",
                "koef": "1.936",
                "metrs": 1
            },
            {
                "size": "3920х21",
                "koef": "2.032",
                "metrs": 1
            },
            {
                "size": "3920х22",
                "koef": "2.128",
                "metrs": 1
            },
            {
                "size": "3920х23",
                "koef": "2.225",
                "metrs": 1
            },
            {
                "size": "3920х24",
                "koef": "2.321",
                "metrs": 1
            },
            {
                "size": "3920х25",
                "koef": "2.417",
                "metrs": 1
            },
            {
                "size": "3920х26",
                "koef": "2.513",
                "metrs": 1
            },
            {
                "size": "3920х27",
                "koef": "2.609",
                "metrs": 1
            },
            {
                "size": "3920х28",
                "koef": "2.705",
                "metrs": 1
            },
            {
                "size": "3920х29",
                "koef": "2.801",
                "metrs": 1
            },
            {
                "size": "3920х30",
                "koef": "2.896",
                "metrs": 1
            },
            {
                "size": "3920х31",
                "koef": "2.992",
                "metrs": 1
            },
            {
                "size": "3920х32",
                "koef": "3.088",
                "metrs": 1
            },
            {
                "size": "3920х33",
                "koef": "3.184",
                "metrs": 1
            },
            {
                "size": "3920х34",
                "koef": "3.279",
                "metrs": 1
            },
            {
                "size": "3920х35",
                "koef": "3.375",
                "metrs": 1
            },
            {
                "size": "3920х36",
                "koef": "3.47",
                "metrs": 1
            },
            {
                "size": "3920х37",
                "koef": "3.566",
                "metrs": 1
            },
            {
                "size": "3920х38",
                "koef": "3.661",
                "metrs": 1
            },
            {
                "size": "3920х39",
                "koef": "3.757",
                "metrs": 1
            },
            {
                "size": "3920х40",
                "koef": "3.852",
                "metrs": 1
            },
            {
                "size": "3920х41",
                "koef": "3.947",
                "metrs": 1
            },
            {
                "size": "3920х42",
                "koef": "4.042",
                "metrs": 1
            },
            {
                "size": "3920х43",
                "koef": "4.138",
                "metrs": 1
            },
            {
                "size": "3920х44",
                "koef": "4.233",
                "metrs": 1
            },
            {
                "size": "3920х45",
                "koef": "4.328",
                "metrs": 1
            },
            {
                "size": "3920х46",
                "koef": "4.423",
                "metrs": 1
            },
            {
                "size": "3920х47",
                "koef": "4.518",
                "metrs": 1
            },
            {
                "size": "3920х48",
                "koef": "4.613",
                "metrs": 1
            },
            {
                "size": "3920х49",
                "koef": "4.708",
                "metrs": 1
            },
            {
                "size": "3920х50",
                "koef": "4.802",
                "metrs": 1
            },
            {
                "size": "4020х14",
                "koef": "1.392",
                "metrs": 1
            },
            {
                "size": " 4020х15",
                "koef": "1.491",
                "metrs": 1
            },
            {
                "size": "4020х16",
                "koef": "1.59",
                "metrs": 1
            },
            {
                "size": "4020х17",
                "koef": "1.689",
                "metrs": 1
            },
            {
                "size": "4020х18",
                "koef": "1.788",
                "metrs": 1
            },
            {
                "size": "4020х19",
                "koef": "1.887",
                "metrs": 1
            },
            {
                "size": "4020х20",
                "koef": "1.985",
                "metrs": 1
            },
            {
                "size": "4020х21",
                "koef": "2.084",
                "metrs": 1
            },
            {
                "size": "4020х22",
                "koef": "2.183",
                "metrs": 1
            },
            {
                "size": "4020х23",
                "koef": "2.282",
                "metrs": 1
            },
            {
                "size": "4020х24",
                "koef": "2.38",
                "metrs": 1
            },
            {
                "size": "4020х25",
                "koef": "2.479",
                "metrs": 1
            },
            {
                "size": "4020х26",
                "koef": "2.577",
                "metrs": 1
            },
            {
                "size": "4020х27",
                "koef": "2.676",
                "metrs": 1
            },
            {
                "size": "4020х28",
                "koef": "2.774",
                "metrs": 1
            },
            {
                "size": "4020х29",
                "koef": "2.872",
                "metrs": 1
            },
            {
                "size": "4020х30",
                "koef": "2.971",
                "metrs": 1
            },
            {
                "size": "4020х31",
                "koef": "3.069",
                "metrs": 1
            },
            {
                "size": "4020х32",
                "koef": "3.167",
                "metrs": 1
            },
            {
                "size": "4020х33",
                "koef": "3.265",
                "metrs": 1
            },
            {
                "size": "4020х34",
                "koef": "3.364",
                "metrs": 1
            },
            {
                "size": "4020х35",
                "koef": "3.462",
                "metrs": 1
            },
            {
                "size": "4020х36",
                "koef": "3.56",
                "metrs": 1
            },
            {
                "size": "4020х37",
                "koef": "3.658",
                "metrs": 1
            },
            {
                "size": "4020х38",
                "koef": "3.755",
                "metrs": 1
            },
            {
                "size": "4020х39",
                "koef": "3.853",
                "metrs": 1
            },
            {
                "size": "4020х40",
                "koef": "3.951",
                "metrs": 1
            },
            {
                "size": "4020х41",
                "koef": "4.049",
                "metrs": 1
            },
            {
                "size": "4020х42",
                "koef": "4.147",
                "metrs": 1
            },
            {
                "size": "4020х43",
                "koef": "4.244",
                "metrs": 1
            },
            {
                "size": "4020х44",
                "koef": "4.342",
                "metrs": 1
            },
            {
                "size": "4020х45",
                "koef": "4.439",
                "metrs": 1
            },
            {
                "size": "4020х46",
                "koef": "4.537",
                "metrs": 1
            },
            {
                "size": " 4020х47",
                "koef": "4.634",
                "metrs": 1
            },
            {
                "size": "4020х48",
                "koef": "4.732",
                "metrs": 1
            },
            {
                "size": "4020х49",
                "koef": "4.829",
                "metrs": 1
            },
            {
                "size": "4020х50",
                "koef": "4.926",
                "metrs": 1
            },
            {
                "size": "4120х14",
                "koef": "1.427",
                "metrs": 1
            },
            {
                "size": "4120х15",
                "koef": "1.528",
                "metrs": 1
            },
            {
                "size": "4120х16",
                "koef": "1.63",
                "metrs": 1
            },
            {
                "size": "4120х17",
                "koef": "1.731",
                "metrs": 1
            },
            {
                "size": "4120х18",
                "koef": "1.833",
                "metrs": 1
            },
            {
                "size": "4120х19",
                "koef": "1.934",
                "metrs": 1
            },
            {
                "size": "4120х20",
                "koef": "2.035",
                "metrs": 1
            },
            {
                "size": "4120х21",
                "koef": "2.136",
                "metrs": 1
            },
            {
                "size": "4120х22",
                "koef": "2.238",
                "metrs": 1
            },
            {
                "size": "4120х23",
                "koef": "2.339",
                "metrs": 1
            },
            {
                "size": "4120х24",
                "koef": "2.44",
                "metrs": 1
            },
            {
                "size": "4120х25",
                "koef": "2.541",
                "metrs": 1
            },
            {
                "size": "4120х26",
                "koef": "2.642",
                "metrs": 1
            },
            {
                "size": "4120х27",
                "koef": "2.743",
                "metrs": 1
            },
            {
                "size": "4120х28",
                "koef": "2.844",
                "metrs": 1
            },
            {
                "size": "4120х29",
                "koef": "2.944",
                "metrs": 1
            },
            {
                "size": "4120х30",
                "koef": "3.045",
                "metrs": 1
            },
            {
                "size": "4120х31",
                "koef": "3.146",
                "metrs": 1
            },
            {
                "size": "4120х32",
                "koef": "3.247",
                "metrs": 1
            },
            {
                "size": "4120х33",
                "koef": "3.347",
                "metrs": 1
            },
            {
                "size": "4120х34",
                "koef": "3.448",
                "metrs": 1
            },
            {
                "size": "4120х35",
                "koef": "3.548",
                "metrs": 1
            },
            {
                "size": "4120х36",
                "koef": "3.649",
                "metrs": 1
            },
            {
                "size": "4120х37",
                "koef": "3.749",
                "metrs": 1
            },
            {
                "size": "4120х38",
                "koef": "3.85",
                "metrs": 1
            },
            {
                "size": "4120х39",
                "koef": "3.95",
                "metrs": 1
            },
            {
                "size": "4120х40",
                "koef": "4.05",
                "metrs": 1
            },
            {
                "size": "4120х41",
                "koef": "4.151",
                "metrs": 1
            },
            {
                "size": "4120х42",
                "koef": "4.251",
                "metrs": 1
            },
            {
                "size": "4120х43",
                "koef": "4.351",
                "metrs": 1
            },
            {
                "size": "4120х44",
                "koef": "4.451",
                "metrs": 1
            },
            {
                "size": "4120х45",
                "koef": "4.551",
                "metrs": 1
            },
            {
                "size": "4120х46",
                "koef": "4.651",
                "metrs": 1
            },
            {
                "size": "4120х47",
                "koef": "4.751",
                "metrs": 1
            },
            {
                "size": "4120х48",
                "koef": "4.851",
                "metrs": 1
            },
            {
                "size": "4120х49",
                "koef": "4.951",
                "metrs": 1
            },
            {
                "size": "4120х50",
                "koef": "5.051",
                "metrs": 1
            },
            {
                "size": "4220х14",
                "koef": "1.461",
                "metrs": 1
            },
            {
                "size": "4220х15",
                "koef": "1.565",
                "metrs": 1
            },
            {
                "size": "4220х16",
                "koef": "1.669",
                "metrs": 1
            },
            {
                "size": "4220х17",
                "koef": "1.773",
                "metrs": 1
            },
            {
                "size": "4220х18",
                "koef": "1.877",
                "metrs": 1
            },
            {
                "size": "4220х19",
                "koef": "1.981",
                "metrs": 1
            },
            {
                "size": "4220х20",
                "koef": "2.085",
                "metrs": 1
            },
            {
                "size": "4220х21",
                "koef": "2.188",
                "metrs": 1
            },
            {
                "size": "4220х22",
                "koef": "2.292",
                "metrs": 1
            },
            {
                "size": "4220х23",
                "koef": "2.396",
                "metrs": 1
            },
            {
                "size": "4220х24",
                "koef": "2.499",
                "metrs": 1
            },
            {
                "size": "4220х25",
                "koef": "2.603",
                "metrs": 1
            },
            {
                "size": "4220х26",
                "koef": "2.706",
                "metrs": 1
            },
            {
                "size": "4220х27",
                "koef": "2.81",
                "metrs": 1
            },
            {
                "size": "4220х28",
                "koef": "2.913",
                "metrs": 1
            },
            {
                "size": "4220х29",
                "koef": "3.016",
                "metrs": 1
            },
            {
                "size": "4220х30",
                "koef": "3.12",
                "metrs": 1
            },
            {
                "size": "4220х31",
                "koef": "3.223",
                "metrs": 1
            },
            {
                "size": "4220х32",
                "koef": "3.326",
                "metrs": 1
            },
            {
                "size": "4220х33",
                "koef": "3.429",
                "metrs": 1
            },
            {
                "size": "4220х34",
                "koef": "3.532",
                "metrs": 1
            },
            {
                "size": "4220х35",
                "koef": "3.635",
                "metrs": 1
            },
            {
                "size": "4220х36",
                "koef": "3.738",
                "metrs": 1
            },
            {
                "size": "4220х37",
                "koef": "3.841",
                "metrs": 1
            },
            {
                "size": "4220х38",
                "koef": "3.944",
                "metrs": 1
            },
            {
                "size": "4220х39",
                "koef": "4.047",
                "metrs": 1
            },
            {
                "size": "4220х40",
                "koef": "4.15",
                "metrs": 1
            },
            {
                "size": "4220х41",
                "koef": "4.252",
                "metrs": 1
            },
            {
                "size": "4220х42",
                "koef": "4.355",
                "metrs": 1
            },
            {
                "size": "4220х43",
                "koef": "4.458",
                "metrs": 1
            },
            {
                "size": "4220х44",
                "koef": "4.56",
                "metrs": 1
            },
            {
                "size": "4220х45",
                "koef": "4.663",
                "metrs": 1
            },
            {
                "size": "4220х46",
                "koef": "4.765",
                "metrs": 1
            },
            {
                "size": "4220х47",
                "koef": "4.868",
                "metrs": 1
            },
            {
                "size": "4220х48",
                "koef": "4.97",
                "metrs": 1
            },
            {
                "size": "4220х49",
                "koef": "5.072",
                "metrs": 1
            },
            {
                "size": "4220х50",
                "koef": "5.175",
                "metrs": 1
            },
            {
                "size": "426х10",
                "koef": "0.1032",
                "metrs": 1
            },
            {
                "size": "426х11",
                "koef": "0.1133",
                "metrs": 1
            },
            {
                "size": "426х12",
                "koef": "0.1233",
                "metrs": 1
            },
            {
                "size": "426х13",
                "koef": "0.1333",
                "metrs": 1
            },
            {
                "size": "426х14",
                "koef": "0.1432",
                "metrs": 1
            },
            {
                "size": " 426х15",
                "koef": "0.153",
                "metrs": 1
            },
            {
                "size": "426х16",
                "koef": "0.1628",
                "metrs": 1
            },
            {
                "size": "426х4",
                "koef": "0.04189",
                "metrs": 1
            },
            {
                "size": "426х4,5",
                "koef": "0.04707",
                "metrs": 1
            },
            {
                "size": "426х5",
                "koef": "0.05224",
                "metrs": 1
            },
            {
                "size": "426х6",
                "koef": "0.06254",
                "metrs": 1
            },
            {
                "size": "426х7",
                "koef": "0.07279",
                "metrs": 1
            },
            {
                "size": "426х8",
                "koef": "0.08299",
                "metrs": 1
            },
            {
                "size": "426х9",
                "koef": "0.09314",
                "metrs": 1
            },
            {
                "size": "4320х14",
                "koef": "1.496",
                "metrs": 1
            },
            {
                "size": "4320х15",
                "koef": "1.603",
                "metrs": 1
            },
            {
                "size": "4320х16",
                "koef": "1.709",
                "metrs": 1
            },
            {
                "size": "4320х17",
                "koef": "1.816",
                "metrs": 1
            },
            {
                "size": "4320х18",
                "koef": "1.922",
                "metrs": 1
            },
            {
                "size": " 4320х19",
                "koef": "2.028",
                "metrs": 1
            },
            {
                "size": "4320х20",
                "koef": "2.134",
                "metrs": 1
            },
            {
                "size": "4320х21",
                "koef": "2.241",
                "metrs": 1
            },
            {
                "size": "4320х22",
                "koef": "2.347",
                "metrs": 1
            },
            {
                "size": "4320х23",
                "koef": "2.453",
                "metrs": 1
            },
            {
                "size": "4320х24",
                "koef": "2.559",
                "metrs": 1
            },
            {
                "size": "4320х25",
                "koef": "2.665",
                "metrs": 1
            },
            {
                "size": "4320х26",
                "koef": "2.771",
                "metrs": 1
            },
            {
                "size": "4320х27",
                "koef": "2.877",
                "metrs": 1
            },
            {
                "size": "4320х28",
                "koef": "2.983",
                "metrs": 1
            },
            {
                "size": "4320х29",
                "koef": "3.088",
                "metrs": 1
            },
            {
                "size": "4320х30",
                "koef": "3.194",
                "metrs": 1
            },
            {
                "size": "4320х31",
                "koef": "3.3",
                "metrs": 1
            },
            {
                "size": "4320х32",
                "koef": "3.406",
                "metrs": 1
            },
            {
                "size": "4320х33",
                "koef": "3.511",
                "metrs": 1
            },
            {
                "size": "4320х34",
                "koef": "3.617",
                "metrs": 1
            },
            {
                "size": "4320х35",
                "koef": "3.722",
                "metrs": 1
            },
            {
                "size": "4320х36",
                "koef": "3.828",
                "metrs": 1
            },
            {
                "size": "4320х37",
                "koef": "3.933",
                "metrs": 1
            },
            {
                "size": "4320х38",
                "koef": "4.038",
                "metrs": 1
            },
            {
                "size": "4320х39",
                "koef": "4.144",
                "metrs": 1
            },
            {
                "size": "4320х40",
                "koef": "4.249",
                "metrs": 1
            },
            {
                "size": "4320х41",
                "koef": "4.354",
                "metrs": 1
            },
            {
                "size": "4320х42",
                "koef": "4.459",
                "metrs": 1
            },
            {
                "size": "4320х43",
                "koef": "4.564",
                "metrs": 1
            },
            {
                "size": " 4320х44",
                "koef": "4.669",
                "metrs": 1
            },
            {
                "size": "4320х45",
                "koef": "4.774",
                "metrs": 1
            },
            {
                "size": "4320х46",
                "koef": "4.879",
                "metrs": 1
            },
            {
                "size": "4320х47",
                "koef": "4.984",
                "metrs": 1
            },
            {
                "size": "4320х48",
                "koef": "5.089",
                "metrs": 1
            },
            {
                "size": "4320х49",
                "koef": "5.194",
                "metrs": 1
            },
            {
                "size": "4320х50",
                "koef": "5.299",
                "metrs": 1
            },
            {
                "size": "4420х14",
                "koef": "1.531",
                "metrs": 1
            },
            {
                "size": "4420х15",
                "koef": "1.64",
                "metrs": 1
            },
            {
                "size": "4420х16",
                "koef": "1.749",
                "metrs": 1
            },
            {
                "size": "4420х17",
                "koef": "1.858",
                "metrs": 1
            },
            {
                "size": "4420х18",
                "koef": "1.967",
                "metrs": 1
            },
            {
                "size": "4420х19",
                "koef": "2.075",
                "metrs": 1
            },
            {
                "size": "4420х20",
                "koef": "2.184",
                "metrs": 1
            },
            {
                "size": "4420х21",
                "koef": "2.293",
                "metrs": 1
            },
            {
                "size": "4420х22",
                "koef": "2.401",
                "metrs": 1
            },
            {
                "size": "4420х23",
                "koef": "2.51",
                "metrs": 1
            },
            {
                "size": "4420х24",
                "koef": "2.618",
                "metrs": 1
            },
            {
                "size": "4420х25",
                "koef": "2.727",
                "metrs": 1
            },
            {
                "size": "4420х26",
                "koef": "2.835",
                "metrs": 1
            },
            {
                "size": "4420х27",
                "koef": "2.944",
                "metrs": 1
            },
            {
                "size": "4420х28",
                "koef": "3.052",
                "metrs": 1
            },
            {
                "size": "4420х29",
                "koef": "3.16",
                "metrs": 1
            },
            {
                "size": "4420х30",
                "koef": "3.269",
                "metrs": 1
            },
            {
                "size": "4420х31",
                "koef": "3.377",
                "metrs": 1
            },
            {
                "size": "4420х32",
                "koef": "3.485",
                "metrs": 1
            },
            {
                "size": "4420х33",
                "koef": "3.593",
                "metrs": 1
            },
            {
                "size": "4420х34",
                "koef": "3.701",
                "metrs": 1
            },
            {
                "size": "4420х35",
                "koef": "3.809",
                "metrs": 1
            },
            {
                "size": " 4420х36",
                "koef": "3.917",
                "metrs": 1
            },
            {
                "size": "4420х37",
                "koef": "4.025",
                "metrs": 1
            },
            {
                "size": " 4420х38",
                "koef": "4.133",
                "metrs": 1
            },
            {
                "size": "4420х39",
                "koef": "4.24",
                "metrs": 1
            },
            {
                "size": "4420х40",
                "koef": "4.348",
                "metrs": 1
            },
            {
                "size": "4420х41",
                "koef": "4.456",
                "metrs": 1
            },
            {
                "size": "4420х42",
                "koef": "4.564",
                "metrs": 1
            },
            {
                "size": "4420х43",
                "koef": "4.671",
                "metrs": 1
            },
            {
                "size": "4420х44",
                "koef": "4.779",
                "metrs": 1
            },
            {
                "size": "4420х45",
                "koef": "4.886",
                "metrs": 1
            },
            {
                "size": "4420х46",
                "koef": "4.994",
                "metrs": 1
            },
            {
                "size": "4420х47",
                "koef": "5.101",
                "metrs": 1
            },
            {
                "size": " 4420х48",
                "koef": "5.208",
                "metrs": 1
            },
            {
                "size": "4420х49",
                "koef": "5.316",
                "metrs": 1
            },
            {
                "size": "4420х50",
                "koef": "5.423",
                "metrs": 1
            },
            {
                "size": "4520х14",
                "koef": "1.566",
                "metrs": 1
            },
            {
                "size": "4520х15",
                "koef": "1.677",
                "metrs": 1
            },
            {
                "size": "4520х16",
                "koef": "1.789",
                "metrs": 1
            },
            {
                "size": "4520х17",
                "koef": "1.9",
                "metrs": 1
            },
            {
                "size": "4520х18",
                "koef": "2.011",
                "metrs": 1
            },
            {
                "size": "4520х19",
                "koef": "2.122",
                "metrs": 1
            },
            {
                "size": "4520х20",
                "koef": "2.234",
                "metrs": 1
            },
            {
                "size": "4520х21",
                "koef": "2.345",
                "metrs": 1
            },
            {
                "size": "4520х22",
                "koef": "2.456",
                "metrs": 1
            },
            {
                "size": "4520х23",
                "koef": "2.567",
                "metrs": 1
            },
            {
                "size": "4520х24",
                "koef": "2.678",
                "metrs": 1
            },
            {
                "size": "4520х25",
                "koef": "2.789",
                "metrs": 1
            },
            {
                "size": "4520х26",
                "koef": "2.9",
                "metrs": 1
            },
            {
                "size": "4520х27",
                "koef": "3.011",
                "metrs": 1
            },
            {
                "size": "4520х28",
                "koef": "3.122",
                "metrs": 1
            },
            {
                "size": "4520х29",
                "koef": "3.232",
                "metrs": 1
            },
            {
                "size": "4520х30",
                "koef": "3.343",
                "metrs": 1
            },
            {
                "size": "4520х31",
                "koef": "3.454",
                "metrs": 1
            },
            {
                "size": "4520х32",
                "koef": "3.564",
                "metrs": 1
            },
            {
                "size": "4520х33",
                "koef": "3.675",
                "metrs": 1
            },
            {
                "size": "4520х34",
                "koef": "3.785",
                "metrs": 1
            },
            {
                "size": "4520х35",
                "koef": "3.896",
                "metrs": 1
            },
            {
                "size": "4520х36",
                "koef": "4.006",
                "metrs": 1
            },
            {
                "size": "4520х37",
                "koef": "4.117",
                "metrs": 1
            },
            {
                "size": "4520х38",
                "koef": "4.227",
                "metrs": 1
            },
            {
                "size": "4520х39",
                "koef": "4.337",
                "metrs": 1
            },
            {
                "size": "4520х40",
                "koef": "4.447",
                "metrs": 1
            },
            {
                "size": "4520х41",
                "koef": "4.558",
                "metrs": 1
            },
            {
                "size": "4520х42",
                "koef": "4.668",
                "metrs": 1
            },
            {
                "size": "4520х43",
                "koef": "4.778",
                "metrs": 1
            },
            {
                "size": "4520х44",
                "koef": "4.888",
                "metrs": 1
            },
            {
                "size": "4520х45",
                "koef": "4.998",
                "metrs": 1
            },
            {
                "size": "4520х46",
                "koef": "5.108",
                "metrs": 1
            },
            {
                "size": "4520х47",
                "koef": "5.218",
                "metrs": 1
            },
            {
                "size": " 4520х48",
                "koef": "5.327",
                "metrs": 1
            },
            {
                "size": "4520х49",
                "koef": "5.437",
                "metrs": 1
            },
            {
                "size": "4520х50",
                "koef": "5.547",
                "metrs": 1
            },
            {
                "size": "457,2х10",
                "koef": "0.111",
                "metrs": 1
            },
            {
                "size": "457,2х4",
                "koef": "0.04499",
                "metrs": 1
            },
            {
                "size": "457,2х5",
                "koef": "0.05611",
                "metrs": 1
            },
            {
                "size": "457,2х6",
                "koef": "0.06719",
                "metrs": 1
            },
            {
                "size": "457,2х7",
                "koef": "0.07821",
                "metrs": 1
            },
            {
                "size": "457,2х8",
                "koef": "0.08919",
                "metrs": 1
            },
            {
                "size": "457,2х9",
                "koef": "0.1001",
                "metrs": 1
            },
            {
                "size": "457х10",
                "koef": "0.1109",
                "metrs": 1
            },
            {
                "size": "457х11",
                "koef": "0.1218",
                "metrs": 1
            },
            {
                "size": "457х12",
                "koef": "0.1325",
                "metrs": 1
            },
            {
                "size": "457х13",
                "koef": "0.1433",
                "metrs": 1
            },
            {
                "size": "457х14",
                "koef": "0.1539",
                "metrs": 1
            },
            {
                "size": "457х15",
                "koef": "0.1645",
                "metrs": 1
            },
            {
                "size": "457х16",
                "koef": "0.1751",
                "metrs": 1
            },
            {
                "size": "457х17",
                "koef": "0.1856",
                "metrs": 1
            },
            {
                "size": "457х18",
                "koef": "0.1961",
                "metrs": 1
            },
            {
                "size": "457х19",
                "koef": "0.2065",
                "metrs": 1
            },
            {
                "size": "457х20",
                "koef": "0.2169",
                "metrs": 1
            },
            {
                "size": "457х21",
                "koef": "0.2272",
                "metrs": 1
            },
            {
                "size": "457х22",
                "koef": "0.2375",
                "metrs": 1
            },
            {
                "size": "457х23",
                "koef": "0.2477",
                "metrs": 1
            },
            {
                "size": "457х24",
                "koef": "0.2579",
                "metrs": 1
            },
            {
                "size": "457х25",
                "koef": "0.268",
                "metrs": 1
            },
            {
                "size": "457х4",
                "koef": "0.04497",
                "metrs": 1
            },
            {
                "size": "457х5",
                "koef": "0.05609",
                "metrs": 1
            },
            {
                "size": "457х6",
                "koef": "0.06716",
                "metrs": 1
            },
            {
                "size": "457х7",
                "koef": "0.07818",
                "metrs": 1
            },
            {
                "size": "457х8",
                "koef": "0.08915",
                "metrs": 1
            },
            {
                "size": "457х9",
                "koef": "0.1001",
                "metrs": 1
            },
            {
                "size": "478х10",
                "koef": "0.1162",
                "metrs": 1
            },
            {
                "size": " 478х11",
                "koef": "0.1275",
                "metrs": 1
            },
            {
                "size": "478х12",
                "koef": "0.1388",
                "metrs": 1
            },
            {
                "size": "478х4",
                "koef": "0.04706",
                "metrs": 1
            },
            {
                "size": "478х5",
                "koef": "0.0587",
                "metrs": 1
            },
            {
                "size": " 478х6",
                "koef": "0.07029",
                "metrs": 1
            },
            {
                "size": "478х7",
                "koef": "0.08183",
                "metrs": 1
            },
            {
                "size": "478х8",
                "koef": "0.09332",
                "metrs": 1
            },
            {
                "size": "478х9",
                "koef": "0.1048",
                "metrs": 1
            },
            {
                "size": "480х6",
                "koef": "0.07058",
                "metrs": 1
            },
            {
                "size": "508х10",
                "koef": "0.1236",
                "metrs": 1
            },
            {
                "size": "508х11",
                "koef": "0.1357",
                "metrs": 1
            },
            {
                "size": "508х12",
                "koef": "0.1477",
                "metrs": 1
            },
            {
                "size": "508х13",
                "koef": "0.1597",
                "metrs": 1
            },
            {
                "size": "508х14",
                "koef": "0.1716",
                "metrs": 1
            },
            {
                "size": "508х15",
                "koef": "0.1835",
                "metrs": 1
            },
            {
                "size": "508х16",
                "koef": "0.1954",
                "metrs": 1
            },
            {
                "size": "508х17",
                "koef": "0.2072",
                "metrs": 1
            },
            {
                "size": "508х18",
                "koef": "0.2189",
                "metrs": 1
            },
            {
                "size": "508х19",
                "koef": "0.2306",
                "metrs": 1
            },
            {
                "size": "508х20",
                "koef": "0.2422",
                "metrs": 1
            },
            {
                "size": "508х21",
                "koef": "0.2538",
                "metrs": 1
            },
            {
                "size": "508х22",
                "koef": "0.2654",
                "metrs": 1
            },
            {
                "size": "508х23",
                "koef": "0.2769",
                "metrs": 1
            },
            {
                "size": "508х24",
                "koef": "0.2883",
                "metrs": 1
            },
            {
                "size": "508х25",
                "koef": "0.2997",
                "metrs": 1
            },
            {
                "size": "508х4",
                "koef": "0.05003",
                "metrs": 1
            },
            {
                "size": "508х5",
                "koef": "0.06242",
                "metrs": 1
            },
            {
                "size": "508х6",
                "koef": "0.07475",
                "metrs": 1
            },
            {
                "size": "508х7",
                "koef": "0.08704",
                "metrs": 1
            },
            {
                "size": "508х8",
                "koef": "0.09927",
                "metrs": 1
            },
            {
                "size": "508х9",
                "koef": "0.1115",
                "metrs": 1
            },
            {
                "size": "530х10",
                "koef": "0.1291",
                "metrs": 1
            },
            {
                "size": "530х11",
                "koef": "0.1417",
                "metrs": 1
            },
            {
                "size": "530х12",
                "koef": "0.1543",
                "metrs": 1
            },
            {
                "size": "530х13",
                "koef": "0.1668",
                "metrs": 1
            },
            {
                "size": "530х14",
                "koef": "0.1793",
                "metrs": 1
            },
            {
                "size": "530х15",
                "koef": "0.1917",
                "metrs": 1
            },
            {
                "size": "530х16",
                "koef": "0.2041",
                "metrs": 1
            },
            {
                "size": "530х17",
                "koef": "0.2164",
                "metrs": 1
            },
            {
                "size": "530х18",
                "koef": "0.2287",
                "metrs": 1
            },
            {
                "size": "530х19",
                "koef": "0.241",
                "metrs": 1
            },
            {
                "size": "530х20",
                "koef": "0.2531",
                "metrs": 1
            },
            {
                "size": "530х21",
                "koef": "0.2653",
                "metrs": 1
            },
            {
                "size": "530х22",
                "koef": "0.2774",
                "metrs": 1
            },
            {
                "size": "530х23",
                "koef": "0.2894",
                "metrs": 1
            },
            {
                "size": "530х24",
                "koef": "0.3014",
                "metrs": 1
            },
            {
                "size": "530х25",
                "koef": "0.3133",
                "metrs": 1
            },
            {
                "size": "530х28",
                "koef": "0.3488",
                "metrs": 1
            },
            {
                "size": "530х30",
                "koef": "0.3723",
                "metrs": 1
            },
            {
                "size": "530х4",
                "koef": "0.05222",
                "metrs": 1
            },
            {
                "size": "530х40",
                "koef": "0.4864",
                "metrs": 1
            },
            {
                "size": "530х5",
                "koef": "0.06515",
                "metrs": 1
            },
            {
                "size": "530х6",
                "koef": "0.07803",
                "metrs": 1
            },
            {
                "size": "530х7",
                "koef": "0.09086",
                "metrs": 1
            },
            {
                "size": "530х8",
                "koef": "0.1036",
                "metrs": 1
            },
            {
                "size": "530х9",
                "koef": "0.1164",
                "metrs": 1
            },
            {
                "size": "550х10",
                "koef": "0.134",
                "metrs": 1
            },
            {
                "size": "550х12",
                "koef": "0.1602",
                "metrs": 1
            },
            {
                "size": "550х14",
                "koef": "0.1862",
                "metrs": 1
            },
            {
                "size": "550х16",
                "koef": "0.212",
                "metrs": 1
            },
            {
                "size": "550х18",
                "koef": "0.2377",
                "metrs": 1
            },
            {
                "size": "550х20",
                "koef": "0.2631",
                "metrs": 1
            },
            {
                "size": " 550х25",
                "koef": "0.3257",
                "metrs": 1
            },
            {
                "size": "550х28",
                "koef": "0.3627",
                "metrs": 1
            },
            {
                "size": "550х30",
                "koef": "0.3872",
                "metrs": 1
            },
            {
                "size": "550х8",
                "koef": "0.1076",
                "metrs": 1
            },
            {
                "size": "609,6х10",
                "koef": "0.1488",
                "metrs": 1
            },
            {
                "size": "609,6х11",
                "koef": "0.1634",
                "metrs": 1
            },
            {
                "size": "609,6х12",
                "koef": "0.178",
                "metrs": 1
            },
            {
                "size": "609,6х13",
                "koef": "0.1925",
                "metrs": 1
            },
            {
                "size": "609,6х14",
                "koef": "0.2069",
                "metrs": 1
            },
            {
                "size": "609,6х15",
                "koef": "0.2214",
                "metrs": 1
            },
            {
                "size": "609,6х16",
                "koef": "0.2357",
                "metrs": 1
            },
            {
                "size": "609,6х17",
                "koef": "0.25",
                "metrs": 1
            },
            {
                "size": "609,6х18",
                "koef": "0.2643",
                "metrs": 1
            },
            {
                "size": "609,6х19",
                "koef": "0.2785",
                "metrs": 1
            },
            {
                "size": "609,6х20",
                "koef": "0.2927",
                "metrs": 1
            },
            {
                "size": "609,6х4",
                "koef": "0.06012",
                "metrs": 1
            },
            {
                "size": "609,6х5",
                "koef": "0.07503",
                "metrs": 1
            },
            {
                "size": "609,6х6",
                "koef": "0.08988",
                "metrs": 1
            },
            {
                "size": "609,6х7",
                "koef": "0.1047",
                "metrs": 1
            },
            {
                "size": "609,6х8",
                "koef": "0.1194",
                "metrs": 1
            },
            {
                "size": "609,6х9",
                "koef": "0.1342",
                "metrs": 1
            },
            {
                "size": "610х10",
                "koef": "0.1489",
                "metrs": 1
            },
            {
                "size": "610х11",
                "koef": "0.1635",
                "metrs": 1
            },
            {
                "size": "610х12",
                "koef": "0.1781",
                "metrs": 1
            },
            {
                "size": "610х13",
                "koef": "0.1926",
                "metrs": 1
            },
            {
                "size": "610х14",
                "koef": "0.2071",
                "metrs": 1
            },
            {
                "size": "610х15",
                "koef": "0.2215",
                "metrs": 1
            },
            {
                "size": "610х16",
                "koef": "0.2359",
                "metrs": 1
            },
            {
                "size": " 610х17",
                "koef": "0.2502",
                "metrs": 1
            },
            {
                "size": "610х18",
                "koef": "0.2645",
                "metrs": 1
            },
            {
                "size": "610х19",
                "koef": "0.2787",
                "metrs": 1
            },
            {
                "size": "610х20",
                "koef": "0.2929",
                "metrs": 1
            },
            {
                "size": " 610х24",
                "koef": "0.349",
                "metrs": 1
            },
            {
                "size": "610х25",
                "koef": "0.363",
                "metrs": 1
            },
            {
                "size": "610х28",
                "koef": "0.4044",
                "metrs": 1
            },
            {
                "size": "610х30",
                "koef": "0.4318",
                "metrs": 1
            },
            {
                "size": "610х4",
                "koef": "0.06016",
                "metrs": 1
            },
            {
                "size": "610х40",
                "koef": "0.5659",
                "metrs": 1
            },
            {
                "size": "610х45",
                "koef": "0.631",
                "metrs": 1
            },
            {
                "size": "610х5",
                "koef": "0.07508",
                "metrs": 1
            },
            {
                "size": " 610х50",
                "koef": "0.6949",
                "metrs": 1
            },
            {
                "size": "610х6",
                "koef": "0.08994",
                "metrs": 1
            },
            {
                "size": "610х7",
                "koef": "0.1048",
                "metrs": 1
            },
            {
                "size": "610х8",
                "koef": "0.1195",
                "metrs": 1
            },
            {
                "size": "610х9",
                "koef": "0.1342",
                "metrs": 1
            },
            {
                "size": "630х10",
                "koef": "0.1539",
                "metrs": 1
            },
            {
                "size": "630х11",
                "koef": "0.169",
                "metrs": 1
            },
            {
                "size": "630х12",
                "koef": "0.1841",
                "metrs": 1
            },
            {
                "size": "630х13",
                "koef": "0.1991",
                "metrs": 1
            },
            {
                "size": "630х14",
                "koef": "0.214",
                "metrs": 1
            },
            {
                "size": "630х15",
                "koef": "0.229",
                "metrs": 1
            },
            {
                "size": "630х16",
                "koef": "0.2438",
                "metrs": 1
            },
            {
                "size": "630х17",
                "koef": "0.2586",
                "metrs": 1
            },
            {
                "size": "630х18",
                "koef": "0.2734",
                "metrs": 1
            },
            {
                "size": "630х19",
                "koef": "0.2881",
                "metrs": 1
            },
            {
                "size": " 630х20",
                "koef": "0.3028",
                "metrs": 1
            },
            {
                "size": "630х21",
                "koef": "0.3174",
                "metrs": 1
            },
            {
                "size": "630х22",
                "koef": "0.332",
                "metrs": 1
            },
            {
                "size": "630х23",
                "koef": "0.3465",
                "metrs": 1
            },
            {
                "size": "630х24",
                "koef": "0.361",
                "metrs": 1
            },
            {
                "size": "630х25",
                "koef": "0.3754",
                "metrs": 1
            },
            {
                "size": "630х28",
                "koef": "0.4183",
                "metrs": 1
            },
            {
                "size": "630х30",
                "koef": "0.4467",
                "metrs": 1
            },
            {
                "size": "630х4",
                "koef": "0.06215",
                "metrs": 1
            },
            {
                "size": "630х40",
                "koef": "0.5857",
                "metrs": 1
            },
            {
                "size": "630х45",
                "koef": "0.6533",
                "metrs": 1
            },
            {
                "size": "630х5",
                "koef": "0.07756",
                "metrs": 1
            },
            {
                "size": "630х50",
                "koef": "0.7197",
                "metrs": 1
            },
            {
                "size": "630х6",
                "koef": "0.09292",
                "metrs": 1
            },
            {
                "size": "630х7",
                "koef": "0.1082",
                "metrs": 1
            },
            {
                "size": "630х8",
                "koef": "0.1235",
                "metrs": 1
            },
            {
                "size": "630х9",
                "koef": "0.1387",
                "metrs": 1
            },
            {
                "size": "711х10",
                "koef": "0.174",
                "metrs": 1
            },
            {
                "size": "711х11",
                "koef": "0.1911",
                "metrs": 1
            },
            {
                "size": "711х12",
                "koef": "0.2082",
                "metrs": 1
            },
            {
                "size": "711х13",
                "koef": "0.2252",
                "metrs": 1
            },
            {
                "size": "711х14",
                "koef": "0.2422",
                "metrs": 1
            },
            {
                "size": "711х15",
                "koef": "0.2591",
                "metrs": 1
            },
            {
                "size": "711х16",
                "koef": "0.276",
                "metrs": 1
            },
            {
                "size": "711х17",
                "koef": "0.2928",
                "metrs": 1
            },
            {
                "size": "711х18",
                "koef": "0.3096",
                "metrs": 1
            },
            {
                "size": "711х19",
                "koef": "0.3263",
                "metrs": 1
            },
            {
                "size": "711х20",
                "koef": "0.343",
                "metrs": 1
            },
            {
                "size": "711х21",
                "koef": "0.3596",
                "metrs": 1
            },
            {
                "size": "711х22",
                "koef": "0.3762",
                "metrs": 1
            },
            {
                "size": "711х23",
                "koef": "0.3927",
                "metrs": 1
            },
            {
                "size": "711х24",
                "koef": "0.4092",
                "metrs": 1
            },
            {
                "size": "711х25",
                "koef": "0.4256",
                "metrs": 1
            },
            {
                "size": "711х26",
                "koef": "0.442",
                "metrs": 1
            },
            {
                "size": "711х27",
                "koef": "0.4583",
                "metrs": 1
            },
            {
                "size": "711х28",
                "koef": "0.4746",
                "metrs": 1
            },
            {
                "size": "711х29",
                "koef": "0.4909",
                "metrs": 1
            },
            {
                "size": "711х30",
                "koef": "0.507",
                "metrs": 1
            },
            {
                "size": "711х31",
                "koef": "0.5232",
                "metrs": 1
            },
            {
                "size": "711х32",
                "koef": "0.5393",
                "metrs": 1
            },
            {
                "size": "711х4",
                "koef": "0.07019",
                "metrs": 1
            },
            {
                "size": "711х5",
                "koef": "0.08761",
                "metrs": 1
            },
            {
                "size": "711х6",
                "koef": "0.105",
                "metrs": 1
            },
            {
                "size": "711х7",
                "koef": "0.1223",
                "metrs": 1
            },
            {
                "size": "711х8",
                "koef": "0.1396",
                "metrs": 1
            },
            {
                "size": "711х9",
                "koef": "0.1568",
                "metrs": 1
            },
            {
                "size": "720х10",
                "koef": "0.1762",
                "metrs": 1
            },
            {
                "size": "720х11",
                "koef": "0.1936",
                "metrs": 1
            },
            {
                "size": "720х12",
                "koef": "0.2109",
                "metrs": 1
            },
            {
                "size": " 720х13",
                "koef": "0.2281",
                "metrs": 1
            },
            {
                "size": "720х14",
                "koef": "0.2453",
                "metrs": 1
            },
            {
                "size": "720х15",
                "koef": "0.2625",
                "metrs": 1
            },
            {
                "size": "720х16",
                "koef": "0.2796",
                "metrs": 1
            },
            {
                "size": "720х17",
                "koef": "0.2966",
                "metrs": 1
            },
            {
                "size": "720х18",
                "koef": "0.3136",
                "metrs": 1
            },
            {
                "size": "720х19",
                "koef": "0.3306",
                "metrs": 1
            },
            {
                "size": "720х20",
                "koef": "0.3475",
                "metrs": 1
            },
            {
                "size": "720х21",
                "koef": "0.3643",
                "metrs": 1
            },
            {
                "size": "720х22",
                "koef": "0.3811",
                "metrs": 1
            },
            {
                "size": "720х23",
                "koef": "0.3979",
                "metrs": 1
            },
            {
                "size": "720х24",
                "koef": "0.4146",
                "metrs": 1
            },
            {
                "size": "720х25",
                "koef": "0.4312",
                "metrs": 1
            },
            {
                "size": "720х26",
                "koef": "0.4478",
                "metrs": 1
            },
            {
                "size": "720х27",
                "koef": "0.4644",
                "metrs": 1
            },
            {
                "size": "720х28",
                "koef": "0.4809",
                "metrs": 1
            },
            {
                "size": "720х29",
                "koef": "0.4973",
                "metrs": 1
            },
            {
                "size": "720х30",
                "koef": "0.5137",
                "metrs": 1
            },
            {
                "size": " 720х31",
                "koef": "0.5301",
                "metrs": 1
            },
            {
                "size": "720х32",
                "koef": "0.5464",
                "metrs": 1
            },
            {
                "size": "720х35",
                "koef": "0.595",
                "metrs": 1
            },
            {
                "size": "720х4",
                "koef": "0.07108",
                "metrs": 1
            },
            {
                "size": "720х40",
                "koef": "0.6751",
                "metrs": 1
            },
            {
                "size": "720х45",
                "koef": "0.7539",
                "metrs": 1
            },
            {
                "size": "720х5",
                "koef": "0.08873",
                "metrs": 1
            },
            {
                "size": "720х50",
                "koef": "0.8314",
                "metrs": 1
            },
            {
                "size": "720х6",
                "koef": "0.1063",
                "metrs": 1
            },
            {
                "size": "720х7",
                "koef": "0.1239",
                "metrs": 1
            },
            {
                "size": "720х8",
                "koef": "0.1414",
                "metrs": 1
            },
            {
                "size": "720х9",
                "koef": "0.1588",
                "metrs": 1
            },
            {
                "size": "762х10",
                "koef": "0.1866",
                "metrs": 1
            },
            {
                "size": "762х11",
                "koef": "0.205",
                "metrs": 1
            },
            {
                "size": "762х12",
                "koef": "0.2234",
                "metrs": 1
            },
            {
                "size": "762х13",
                "koef": "0.2417",
                "metrs": 1
            },
            {
                "size": "762х14",
                "koef": "0.2599",
                "metrs": 1
            },
            {
                "size": "762х15",
                "koef": "0.2781",
                "metrs": 1
            },
            {
                "size": "762х16",
                "koef": "0.2962",
                "metrs": 1
            },
            {
                "size": "762х17",
                "koef": "0.3143",
                "metrs": 1
            },
            {
                "size": "762х18",
                "koef": "0.3324",
                "metrs": 1
            },
            {
                "size": "762х19",
                "koef": "0.3504",
                "metrs": 1
            },
            {
                "size": "762х20",
                "koef": "0.3683",
                "metrs": 1
            },
            {
                "size": "762х21",
                "koef": "0.3862",
                "metrs": 1
            },
            {
                "size": "762х22",
                "koef": "0.404",
                "metrs": 1
            },
            {
                "size": "762х23",
                "koef": "0.4218",
                "metrs": 1
            },
            {
                "size": "762х24",
                "koef": "0.4396",
                "metrs": 1
            },
            {
                "size": "762х25",
                "koef": "0.4573",
                "metrs": 1
            },
            {
                "size": "762х26",
                "koef": "0.4749",
                "metrs": 1
            },
            {
                "size": "762х27",
                "koef": "0.4925",
                "metrs": 1
            },
            {
                "size": "762х28",
                "koef": "0.5101",
                "metrs": 1
            },
            {
                "size": "762х29",
                "koef": "0.5276",
                "metrs": 1
            },
            {
                "size": "762х30",
                "koef": "0.545",
                "metrs": 1
            },
            {
                "size": "762х31",
                "koef": "0.5624",
                "metrs": 1
            },
            {
                "size": "762х32",
                "koef": "0.5798",
                "metrs": 1
            },
            {
                "size": "762х4",
                "koef": "0.07525",
                "metrs": 1
            },
            {
                "size": "762х5",
                "koef": "0.09394",
                "metrs": 1
            },
            {
                "size": "762х6",
                "koef": "0.1126",
                "metrs": 1
            },
            {
                "size": "762х7",
                "koef": "0.1312",
                "metrs": 1
            },
            {
                "size": "762х8",
                "koef": "0.1497",
                "metrs": 1
            },
            {
                "size": "762х9",
                "koef": "0.1682",
                "metrs": 1
            },
            {
                "size": "810х10",
                "koef": "0.1985",
                "metrs": 1
            },
            {
                "size": "813х10",
                "koef": "0.1993",
                "metrs": 1
            },
            {
                "size": "813х11",
                "koef": "0.2189",
                "metrs": 1
            },
            {
                "size": "813х12",
                "koef": "0.2386",
                "metrs": 1
            },
            {
                "size": "813х13",
                "koef": "0.2581",
                "metrs": 1
            },
            {
                "size": "813х14",
                "koef": "0.2776",
                "metrs": 1
            },
            {
                "size": "813х15",
                "koef": "0.2971",
                "metrs": 1
            },
            {
                "size": "813х16",
                "koef": "0.3165",
                "metrs": 1
            },
            {
                "size": "813х17",
                "koef": "0.3358",
                "metrs": 1
            },
            {
                "size": "813х18",
                "koef": "0.3552",
                "metrs": 1
            },
            {
                "size": "813х19",
                "koef": "0.3744",
                "metrs": 1
            },
            {
                "size": "813х20",
                "koef": "0.3936",
                "metrs": 1
            },
            {
                "size": "813х21",
                "koef": "0.4128",
                "metrs": 1
            },
            {
                "size": "813х22",
                "koef": "0.4319",
                "metrs": 1
            },
            {
                "size": "813х23",
                "koef": "0.451",
                "metrs": 1
            },
            {
                "size": "813х24",
                "koef": "0.47",
                "metrs": 1
            },
            {
                "size": "813х25",
                "koef": "0.4889",
                "metrs": 1
            },
            {
                "size": "813х26",
                "koef": "0.5078",
                "metrs": 1
            },
            {
                "size": "813х27",
                "koef": "0.5267",
                "metrs": 1
            },
            {
                "size": "813х28",
                "koef": "0.5455",
                "metrs": 1
            },
            {
                "size": "813х29",
                "koef": "0.5643",
                "metrs": 1
            },
            {
                "size": "813х30",
                "koef": "0.583",
                "metrs": 1
            },
            {
                "size": "813х31",
                "koef": "0.6017",
                "metrs": 1
            },
            {
                "size": "813х32",
                "koef": "0.6203",
                "metrs": 1
            },
            {
                "size": "813х33",
                "koef": "0.6388",
                "metrs": 1
            },
            {
                "size": "813х34",
                "koef": "0.6573",
                "metrs": 1
            },
            {
                "size": "813х35",
                "koef": "0.6758",
                "metrs": 1
            },
            {
                "size": "813х36",
                "koef": "0.6942",
                "metrs": 1
            },
            {
                "size": "813х37",
                "koef": "0.7126",
                "metrs": 1
            },
            {
                "size": "813х38",
                "koef": "0.7309",
                "metrs": 1
            },
            {
                "size": "813х4",
                "koef": "0.08031",
                "metrs": 1
            },
            {
                "size": "813х5",
                "koef": "0.1003",
                "metrs": 1
            },
            {
                "size": "813х6",
                "koef": "0.1202",
                "metrs": 1
            },
            {
                "size": "813х7",
                "koef": "0.14",
                "metrs": 1
            },
            {
                "size": "813х8",
                "koef": "0.1598",
                "metrs": 1
            },
            {
                "size": "813х9",
                "koef": "0.1796",
                "metrs": 1
            },
            {
                "size": "820х10",
                "koef": "0.201",
                "metrs": 1
            },
            {
                "size": "820х11",
                "koef": "0.2209",
                "metrs": 1
            },
            {
                "size": "820х12",
                "koef": "0.2406",
                "metrs": 1
            },
            {
                "size": "820х13",
                "koef": "0.2604",
                "metrs": 1
            },
            {
                "size": "820х14",
                "koef": "0.2801",
                "metrs": 1
            },
            {
                "size": "820х15",
                "koef": "0.2997",
                "metrs": 1
            },
            {
                "size": "820х16",
                "koef": "0.3193",
                "metrs": 1
            },
            {
                "size": "820х17",
                "koef": "0.3388",
                "metrs": 1
            },
            {
                "size": "820х18",
                "koef": "0.3583",
                "metrs": 1
            },
            {
                "size": "820х19",
                "koef": "0.3777",
                "metrs": 1
            },
            {
                "size": "820х20",
                "koef": "0.3971",
                "metrs": 1
            },
            {
                "size": "820х21",
                "koef": "0.4164",
                "metrs": 1
            },
            {
                "size": "820х22",
                "koef": "0.4357",
                "metrs": 1
            },
            {
                "size": "820х23",
                "koef": "0.4549",
                "metrs": 1
            },
            {
                "size": "820х24",
                "koef": "0.4741",
                "metrs": 1
            },
            {
                "size": "820х25",
                "koef": "0.4933",
                "metrs": 1
            },
            {
                "size": "820х26",
                "koef": "0.5124",
                "metrs": 1
            },
            {
                "size": "820х27",
                "koef": "0.5314",
                "metrs": 1
            },
            {
                "size": "820х28",
                "koef": "0.5504",
                "metrs": 1
            },
            {
                "size": "820х29",
                "koef": "0.5693",
                "metrs": 1
            },
            {
                "size": "820х30",
                "koef": "0.5882",
                "metrs": 1
            },
            {
                "size": "820х31",
                "koef": "0.607",
                "metrs": 1
            },
            {
                "size": "820х32",
                "koef": "0.6258",
                "metrs": 1
            },
            {
                "size": "820х33",
                "koef": "0.6446",
                "metrs": 1
            },
            {
                "size": "820х34",
                "koef": "0.6633",
                "metrs": 1
            },
            {
                "size": "820х35",
                "koef": "0.6819",
                "metrs": 1
            },
            {
                "size": "820х36",
                "koef": "0.7005",
                "metrs": 1
            },
            {
                "size": "820х37",
                "koef": "0.719",
                "metrs": 1
            },
            {
                "size": "820х38",
                "koef": "0.7375",
                "metrs": 1
            },
            {
                "size": "820х4",
                "koef": "0.08101",
                "metrs": 1
            },
            {
                "size": "820х40",
                "koef": "0.7743",
                "metrs": 1
            },
            {
                "size": "820х45",
                "koef": "0.8655",
                "metrs": 1
            },
            {
                "size": "820х5",
                "koef": "0.1011",
                "metrs": 1
            },
            {
                "size": "820х50",
                "koef": "0.9555",
                "metrs": 1
            },
            {
                "size": "820х6",
                "koef": "0.1212",
                "metrs": 1
            },
            {
                "size": "820х7",
                "koef": "0.1412",
                "metrs": 1
            },
            {
                "size": "820х8",
                "koef": "0.1612",
                "metrs": 1
            },
            {
                "size": "820х9",
                "koef": "0.1812",
                "metrs": 1
            },
            {
                "size": "830х10",
                "koef": "0.2035",
                "metrs": 1
            },
            {
                "size": "916х10",
                "koef": "0.2249",
                "metrs": 1
            },
            {
                "size": "916х11",
                "koef": "0.2471",
                "metrs": 1
            },
            {
                "size": "916х12",
                "koef": "0.2692",
                "metrs": 1
            },
            {
                "size": "916х13",
                "koef": "0.2913",
                "metrs": 1
            },
            {
                "size": "916х14",
                "koef": "0.3134",
                "metrs": 1
            },
            {
                "size": "916х15",
                "koef": "0.3354",
                "metrs": 1
            },
            {
                "size": "916х16",
                "koef": "0.3574",
                "metrs": 1
            },
            {
                "size": "916х18",
                "koef": "0.4012",
                "metrs": 1
            },
            {
                "size": "916х20",
                "koef": "0.4447",
                "metrs": 1
            },
            {
                "size": "916х25",
                "koef": "0.5528",
                "metrs": 1
            },
            {
                "size": "916х5",
                "koef": "0.113",
                "metrs": 1
            },
            {
                "size": "916х6",
                "koef": "0.1355",
                "metrs": 1
            },
            {
                "size": "916х7",
                "koef": "0.1579",
                "metrs": 1
            },
            {
                "size": "916х8",
                "koef": "0.1803",
                "metrs": 1
            },
            {
                "size": "916х9",
                "koef": "0.2026",
                "metrs": 1
            },
            {
                "size": "920х10",
                "koef": "0.2258",
                "metrs": 1
            },
            {
                "size": "920х11",
                "koef": "0.2482",
                "metrs": 1
            },
            {
                "size": "920х12",
                "koef": "0.2704",
                "metrs": 1
            },
            {
                "size": "920х13",
                "koef": "0.2926",
                "metrs": 1
            },
            {
                "size": "920х14",
                "koef": "0.3148",
                "metrs": 1
            },
            {
                "size": "920х15",
                "koef": "0.3369",
                "metrs": 1
            },
            {
                "size": "920х16",
                "koef": "0.359",
                "metrs": 1
            },
            {
                "size": "920х17",
                "koef": "0.381",
                "metrs": 1
            },
            {
                "size": "920х18",
                "koef": "0.403",
                "metrs": 1
            },
            {
                "size": "920х19",
                "koef": "0.4249",
                "metrs": 1
            },
            {
                "size": "920х20",
                "koef": "0.4467",
                "metrs": 1
            },
            {
                "size": "920х21",
                "koef": "0.4685",
                "metrs": 1
            },
            {
                "size": "920х22",
                "koef": "0.4903",
                "metrs": 1
            },
            {
                "size": "920х23",
                "koef": "0.512",
                "metrs": 1
            },
            {
                "size": "920х24",
                "koef": "0.5337",
                "metrs": 1
            },
            {
                "size": "920х25",
                "koef": "0.5553",
                "metrs": 1
            },
            {
                "size": "920х26",
                "koef": "0.5769",
                "metrs": 1
            },
            {
                "size": "920х27",
                "koef": "0.5984",
                "metrs": 1
            },
            {
                "size": "920х28",
                "koef": "0.6199",
                "metrs": 1
            },
            {
                "size": "920х29",
                "koef": "0.6413",
                "metrs": 1
            },
            {
                "size": "920х30",
                "koef": "0.6627",
                "metrs": 1
            },
            {
                "size": "920х31",
                "koef": "0.684",
                "metrs": 1
            },
            {
                "size": "920х32",
                "koef": "0.7052",
                "metrs": 1
            },
            {
                "size": "920х33",
                "koef": "0.7265",
                "metrs": 1
            },
            {
                "size": "920х34",
                "koef": "0.7476",
                "metrs": 1
            },
            {
                "size": "920х35",
                "koef": "0.7688",
                "metrs": 1
            },
            {
                "size": "920х36",
                "koef": "0.7898",
                "metrs": 1
            },
            {
                "size": "920х37",
                "koef": "0.8108",
                "metrs": 1
            },
            {
                "size": "920х38",
                "koef": "0.8318",
                "metrs": 1
            },
            {
                "size": "920х39",
                "koef": "0.8527",
                "metrs": 1
            },
            {
                "size": "920х4",
                "koef": "0.09094",
                "metrs": 1
            },
            {
                "size": "920х40",
                "koef": "0.8736",
                "metrs": 1
            },
            {
                "size": "920х45",
                "koef": "0.9772",
                "metrs": 1
            },
            {
                "size": "920х5",
                "koef": "0.1135",
                "metrs": 1
            },
            {
                "size": "920х50",
                "koef": "1.08",
                "metrs": 1
            },
            {
                "size": "920х6",
                "koef": "0.1361",
                "metrs": 1
            },
            {
                "size": "920х7",
                "koef": "0.1586",
                "metrs": 1
            },
            {
                "size": "920х8",
                "koef": "0.1811",
                "metrs": 1
            },
            {
                "size": "920х9",
                "koef": "0.2035",
                "metrs": 1
            }
        ],
        "Уголок": [
            {
                "size": " 20х20х3",
                "koef": "0.000877",
                "metrs": 1
            },
            {
                "size": " 25х25х3",
                "koef": "0.001114",
                "metrs": 1
            },
            {
                "size": " 30х30х3",
                "koef": "0.001351",
                "metrs": 1
            },
            {
                "size": " 35х35х3",
                "koef": "0.001588",
                "metrs": 1
            },
            {
                "size": " 40х40х4",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": " 50х50х4",
                "koef": "0.003034",
                "metrs": 1
            },
            {
                "size": " 50х50х5",
                "koef": "0.003752",
                "metrs": 1
            },
            {
                "size": " 60х60х6",
                "koef": "0.005404",
                "metrs": 1
            },
            {
                "size": " 70х70х5",
                "koef": "0.005333",
                "metrs": 1
            },
            {
                "size": " 70х70х7",
                "koef": "0.007355",
                "metrs": 1
            },
            {
                "size": " 75х75х5",
                "koef": "0.005728",
                "metrs": 1
            },
            {
                "size": " 80х80х8",
                "koef": "0.009606",
                "metrs": 1
            },
            {
                "size": " 90х90х9",
                "koef": "0.01216",
                "metrs": 1
            },
            {
                "size": " 100х100х6",
                "koef": "0.009196",
                "metrs": 1
            },
            {
                "size": " 100х100х10",
                "koef": "0.01501",
                "metrs": 1
            }
        ],
        "Уголок гнутый": [
            {
                "size": "20х20х2",
                "koef": "0.0006",
                "metrs": 1
            },
            {
                "size": "30х30х2",
                "koef": "0.000916",
                "metrs": 1
            },
            {
                "size": "40х40х2",
                "koef": "0.001232",
                "metrs": 1
            },
            {
                "size": "40х40х3",
                "koef": "0.001825",
                "metrs": 1
            },
            {
                "size": "40х40х4",
                "koef": "0.002402",
                "metrs": 1
            },
            {
                "size": "40х40х5",
                "koef": "0.002963",
                "metrs": 1
            },
            {
                "size": " 50х50х2",
                "koef": "0.001548",
                "metrs": 1
            },
            {
                "size": "50х50х3",
                "koef": "0.002299",
                "metrs": 1
            },
            {
                "size": "50х50х4",
                "koef": "0.003034",
                "metrs": 1
            },
            {
                "size": "50х50х5",
                "koef": "0.003752",
                "metrs": 1
            },
            {
                "size": "50х50х6",
                "koef": "0.004456",
                "metrs": 1
            },
            {
                "size": "60х60х2",
                "koef": "0.001864",
                "metrs": 1
            },
            {
                "size": "60х60х3",
                "koef": "0.002773",
                "metrs": 1
            },
            {
                "size": "60х60х4",
                "koef": "0.003666",
                "metrs": 1
            },
            {
                "size": "60х60х5",
                "koef": "0.004543",
                "metrs": 1
            },
            {
                "size": "60х60х6",
                "koef": "0.005404",
                "metrs": 1
            },
            {
                "size": "70х70х2",
                "koef": "0.00218",
                "metrs": 1
            },
            {
                "size": "70х70х3",
                "koef": "0.003247",
                "metrs": 1
            },
            {
                "size": "70х70х4",
                "koef": "0.004298",
                "metrs": 1
            },
            {
                "size": "70х70х5",
                "koef": "0.005333",
                "metrs": 1
            },
            {
                "size": "70х70х6",
                "koef": "0.006352",
                "metrs": 1
            },
            {
                "size": "80х80х2",
                "koef": "0.002496",
                "metrs": 1
            },
            {
                "size": "80х80х3",
                "koef": "0.003721",
                "metrs": 1
            },
            {
                "size": "80х80х4",
                "koef": "0.00493",
                "metrs": 1
            },
            {
                "size": "80х80х5",
                "koef": "0.006123",
                "metrs": 1
            },
            {
                "size": "80х80х6",
                "koef": "0.0073",
                "metrs": 1
            },
            {
                "size": "80х80х8",
                "koef": "0.009606",
                "metrs": 1
            },
            {
                "size": "90х90х2",
                "koef": "0.002812",
                "metrs": 1
            },
            {
                "size": "90х90х3",
                "koef": "0.004195",
                "metrs": 1
            },
            {
                "size": "90х90х4",
                "koef": "0.005562",
                "metrs": 1
            },
            {
                "size": "90х90х5",
                "koef": "0.006913",
                "metrs": 1
            },
            {
                "size": "90х90х6",
                "koef": "0.008248",
                "metrs": 1
            },
            {
                "size": "90х90х8",
                "koef": "0.01087",
                "metrs": 1
            },
            {
                "size": "100х100х2",
                "koef": "0.003128",
                "metrs": 1
            },
            {
                "size": "100х100х3",
                "koef": "0.004669",
                "metrs": 1
            },
            {
                "size": "100х100х4",
                "koef": "0.006194",
                "metrs": 1
            },
            {
                "size": "100х100х5",
                "koef": "0.007703",
                "metrs": 1
            },
            {
                "size": "100х100х6",
                "koef": "0.009196",
                "metrs": 1
            },
            {
                "size": "100х100х8",
                "koef": "0.01213",
                "metrs": 1
            },
            {
                "size": "350х350х2",
                "koef": "0.01103",
                "metrs": 1
            },
            {
                "size": "350х350х3",
                "koef": "0.01652",
                "metrs": 1
            },
            {
                "size": "350х350х4",
                "koef": "0.02199",
                "metrs": 1
            },
            {
                "size": "350х350х5",
                "koef": "0.02745",
                "metrs": 1
            },
            {
                "size": "350х350х6",
                "koef": "0.0329",
                "metrs": 1
            },
            {
                "size": "350х350х8",
                "koef": "0.04373",
                "metrs": 1
            }
        ],
        "Швеллер гнутый": [
            {
                "size": "50х40х2",
                "koef": "0.001991",
                "metrs": 1
            },
            {
                "size": "50х40х3",
                "koef": "0.002939",
                "metrs": 1
            },
            {
                "size": "50х40х4",
                "koef": "0.003855",
                "metrs": 1
            },
            {
                "size": "50х40х5",
                "koef": "0.00474",
                "metrs": 1
            },
            {
                "size": "50х40х6",
                "koef": "0.005593",
                "metrs": 1
            },
            {
                "size": "60х32х2",
                "koef": "0.001896",
                "metrs": 1
            },
            {
                "size": "60х32х3",
                "koef": "0.002797",
                "metrs": 1
            },
            {
                "size": "60х32х4",
                "koef": "0.003666",
                "metrs": 1
            },
            {
                "size": "60х32х5",
                "koef": "0.004503",
                "metrs": 1
            },
            {
                "size": "60х40х2",
                "koef": "0.002149",
                "metrs": 1
            },
            {
                "size": "60х40х3",
                "koef": "0.003176",
                "metrs": 1
            },
            {
                "size": "60х40х4",
                "koef": "0.004171",
                "metrs": 1
            },
            {
                "size": "60х40х5",
                "koef": "0.005135",
                "metrs": 1
            },
            {
                "size": "60х40х6",
                "koef": "0.006067",
                "metrs": 1
            },
            {
                "size": "65х32х2",
                "koef": "0.001975",
                "metrs": 1
            },
            {
                "size": "65х32х3",
                "koef": "0.002915",
                "metrs": 1
            },
            {
                "size": "65х32х4",
                "koef": "0.003824",
                "metrs": 1
            },
            {
                "size": "65х32х5",
                "koef": "0.004701",
                "metrs": 1
            },
            {
                "size": "65х40х2",
                "koef": "0.002228",
                "metrs": 1
            },
            {
                "size": "65х40х3",
                "koef": "0.003294",
                "metrs": 1
            },
            {
                "size": "65х40х4",
                "koef": "0.004329",
                "metrs": 1
            },
            {
                "size": "65х40х5",
                "koef": "0.005333",
                "metrs": 1
            },
            {
                "size": "65х40х6",
                "koef": "0.006304",
                "metrs": 1
            },
            {
                "size": "70х32х2",
                "koef": "0.002054",
                "metrs": 1
            },
            {
                "size": "70х32х3",
                "koef": "0.003034",
                "metrs": 1
            },
            {
                "size": "70х32х4",
                "koef": "0.003982",
                "metrs": 1
            },
            {
                "size": "70х32х5",
                "koef": "0.004898",
                "metrs": 1
            },
            {
                "size": "70х40х2",
                "koef": "0.002307",
                "metrs": 1
            },
            {
                "size": "70х40х3",
                "koef": "0.003413",
                "metrs": 1
            },
            {
                "size": "70х40х4",
                "koef": "0.004487",
                "metrs": 1
            },
            {
                "size": "70х40х5",
                "koef": "0.00553",
                "metrs": 1
            },
            {
                "size": "70х40х6",
                "koef": "0.006541",
                "metrs": 1
            },
            {
                "size": "70х50х2",
                "koef": "0.002623",
                "metrs": 1
            },
            {
                "size": "70х50х3",
                "koef": "0.003887",
                "metrs": 1
            },
            {
                "size": "70х50х4",
                "koef": "0.005119",
                "metrs": 1
            },
            {
                "size": "70х50х5",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": "70х50х6",
                "koef": "0.007489",
                "metrs": 1
            },
            {
                "size": "75х32х2",
                "koef": "0.002133",
                "metrs": 1
            },
            {
                "size": "75х32х3",
                "koef": "0.003152",
                "metrs": 1
            },
            {
                "size": "75х32х4",
                "koef": "0.00414",
                "metrs": 1
            },
            {
                "size": "75х32х5",
                "koef": "0.005096",
                "metrs": 1
            },
            {
                "size": "75х40х2",
                "koef": "0.002386",
                "metrs": 1
            },
            {
                "size": "75х40х3",
                "koef": "0.003531",
                "metrs": 1
            },
            {
                "size": "75х40х4",
                "koef": "0.004645",
                "metrs": 1
            },
            {
                "size": "75х40х5",
                "koef": "0.005728",
                "metrs": 1
            },
            {
                "size": "75х40х6",
                "koef": "0.006778",
                "metrs": 1
            },
            {
                "size": "75х50х2",
                "koef": "0.002702",
                "metrs": 1
            },
            {
                "size": "75х50х3",
                "koef": "0.004005",
                "metrs": 1
            },
            {
                "size": "75х50х4",
                "koef": "0.005277",
                "metrs": 1
            },
            {
                "size": "75х50х5",
                "koef": "0.006518",
                "metrs": 1
            },
            {
                "size": "75х50х6",
                "koef": "0.007726",
                "metrs": 1
            },
            {
                "size": "80х32х2",
                "koef": "0.002212",
                "metrs": 1
            },
            {
                "size": "80х32х3",
                "koef": "0.003271",
                "metrs": 1
            },
            {
                "size": "80х32х4",
                "koef": "0.004298",
                "metrs": 1
            },
            {
                "size": "80х32х5",
                "koef": "0.005293",
                "metrs": 1
            },
            {
                "size": "80х40х2",
                "koef": "0.002465",
                "metrs": 1
            },
            {
                "size": "80х40х3",
                "koef": "0.00365",
                "metrs": 1
            },
            {
                "size": "80х40х4",
                "koef": "0.004803",
                "metrs": 1
            },
            {
                "size": "80х40х5",
                "koef": "0.005925",
                "metrs": 1
            },
            {
                "size": "80х40х6",
                "koef": "0.007015",
                "metrs": 1
            },
            {
                "size": "80х50х2",
                "koef": "0.002781",
                "metrs": 1
            },
            {
                "size": "80х50х3",
                "koef": "0.004124",
                "metrs": 1
            },
            {
                "size": "80х50х4",
                "koef": "0.005435",
                "metrs": 1
            },
            {
                "size": "80х50х5",
                "koef": "0.006715",
                "metrs": 1
            },
            {
                "size": "80х50х6",
                "koef": "0.007963",
                "metrs": 1
            },
            {
                "size": "80х60х3",
                "koef": "0.004598",
                "metrs": 1
            },
            {
                "size": "80х60х4",
                "koef": "0.006067",
                "metrs": 1
            },
            {
                "size": "80х60х5",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "80х60х6",
                "koef": "0.008911",
                "metrs": 1
            },
            {
                "size": "85х32х2",
                "koef": "0.002291",
                "metrs": 1
            },
            {
                "size": "85х32х3",
                "koef": "0.003389",
                "metrs": 1
            },
            {
                "size": "85х32х4",
                "koef": "0.004456",
                "metrs": 1
            },
            {
                "size": "85х32х5",
                "koef": "0.005491",
                "metrs": 1
            },
            {
                "size": "85х40х2",
                "koef": "0.002544",
                "metrs": 1
            },
            {
                "size": "85х40х3",
                "koef": "0.003768",
                "metrs": 1
            },
            {
                "size": "85х40х4",
                "koef": "0.004961",
                "metrs": 1
            },
            {
                "size": "85х40х5",
                "koef": "0.006123",
                "metrs": 1
            },
            {
                "size": "85х40х6",
                "koef": "0.007252",
                "metrs": 1
            },
            {
                "size": "85х50х2",
                "koef": "0.00286",
                "metrs": 1
            },
            {
                "size": "85х50х3",
                "koef": "0.004242",
                "metrs": 1
            },
            {
                "size": "85х50х4",
                "koef": "0.005593",
                "metrs": 1
            },
            {
                "size": "85х50х5",
                "koef": "0.006913",
                "metrs": 1
            },
            {
                "size": "85х50х6",
                "koef": "0.0082",
                "metrs": 1
            },
            {
                "size": "85х60х2",
                "koef": "0.003176",
                "metrs": 1
            },
            {
                "size": "85х60х3",
                "koef": "0.004716",
                "metrs": 1
            },
            {
                "size": "85х60х4",
                "koef": "0.006225",
                "metrs": 1
            },
            {
                "size": "85х60х5",
                "koef": "0.007703",
                "metrs": 1
            },
            {
                "size": "85х60х6",
                "koef": "0.009148",
                "metrs": 1
            },
            {
                "size": "90х32х2",
                "koef": "0.00237",
                "metrs": 1
            },
            {
                "size": "90х32х3",
                "koef": "0.003508",
                "metrs": 1
            },
            {
                "size": "90х32х4",
                "koef": "0.004614",
                "metrs": 1
            },
            {
                "size": "90х32х5",
                "koef": "0.005688",
                "metrs": 1
            },
            {
                "size": "90х40х2",
                "koef": "0.002623",
                "metrs": 1
            },
            {
                "size": "90х40х3",
                "koef": "0.003887",
                "metrs": 1
            },
            {
                "size": "90х40х4",
                "koef": "0.005119",
                "metrs": 1
            },
            {
                "size": "90х40х5",
                "koef": "0.00632",
                "metrs": 1
            },
            {
                "size": "90х40х6",
                "koef": "0.007489",
                "metrs": 1
            },
            {
                "size": "90х50х2",
                "koef": "0.002939",
                "metrs": 1
            },
            {
                "size": "90х50х3",
                "koef": "0.004361",
                "metrs": 1
            },
            {
                "size": "90х50х4",
                "koef": "0.005751",
                "metrs": 1
            },
            {
                "size": "90х50х5",
                "koef": "0.00711",
                "metrs": 1
            },
            {
                "size": "90х50х6",
                "koef": "0.008437",
                "metrs": 1
            },
            {
                "size": "90х60х3",
                "koef": "0.004835",
                "metrs": 1
            },
            {
                "size": "90х60х4",
                "koef": "0.006383",
                "metrs": 1
            },
            {
                "size": "90х60х5",
                "koef": "0.0079",
                "metrs": 1
            },
            {
                "size": "90х60х6",
                "koef": "0.009385",
                "metrs": 1
            },
            {
                "size": "95х32х2",
                "koef": "0.002449",
                "metrs": 1
            },
            {
                "size": "95х32х3",
                "koef": "0.003626",
                "metrs": 1
            },
            {
                "size": "95х32х4",
                "koef": "0.004772",
                "metrs": 1
            },
            {
                "size": "95х32х5",
                "koef": "0.005886",
                "metrs": 1
            },
            {
                "size": "95х40х2",
                "koef": "0.002702",
                "metrs": 1
            },
            {
                "size": "95х40х3",
                "koef": "0.004005",
                "metrs": 1
            },
            {
                "size": "95х40х4",
                "koef": "0.005277",
                "metrs": 1
            },
            {
                "size": "95х40х5",
                "koef": "0.006518",
                "metrs": 1
            },
            {
                "size": "95х40х6",
                "koef": "0.007726",
                "metrs": 1
            },
            {
                "size": "95х50х2",
                "koef": "0.003018",
                "metrs": 1
            },
            {
                "size": "95х50х3",
                "koef": "0.004479",
                "metrs": 1
            },
            {
                "size": "95х50х4",
                "koef": "0.005909",
                "metrs": 1
            },
            {
                "size": "95х50х5",
                "koef": "0.007308",
                "metrs": 1
            },
            {
                "size": "95х50х6",
                "koef": "0.008674",
                "metrs": 1
            },
            {
                "size": "95х60х2",
                "koef": "0.003334",
                "metrs": 1
            },
            {
                "size": "95х60х3",
                "koef": "0.004953",
                "metrs": 1
            },
            {
                "size": "95х60х4",
                "koef": "0.006541",
                "metrs": 1
            },
            {
                "size": "95х60х5",
                "koef": "0.008098",
                "metrs": 1
            },
            {
                "size": "95х60х6",
                "koef": "0.009622",
                "metrs": 1
            },
            {
                "size": "95х65х2",
                "koef": "0.003492",
                "metrs": 1
            },
            {
                "size": "95х65х3",
                "koef": "0.00519",
                "metrs": 1
            },
            {
                "size": "95х65х4",
                "koef": "0.006857",
                "metrs": 1
            },
            {
                "size": "95х65х5",
                "koef": "0.008493",
                "metrs": 1
            },
            {
                "size": "95х65х6",
                "koef": "0.0101",
                "metrs": 1
            },
            {
                "size": "95х65х8",
                "koef": "0.01321",
                "metrs": 1
            },
            {
                "size": "95х70х2",
                "koef": "0.00365",
                "metrs": 1
            },
            {
                "size": "95х70х3",
                "koef": "0.005427",
                "metrs": 1
            },
            {
                "size": "95х70х4",
                "koef": "0.007173",
                "metrs": 1
            },
            {
                "size": "95х70х5",
                "koef": "0.008888",
                "metrs": 1
            },
            {
                "size": "95х70х6",
                "koef": "0.01057",
                "metrs": 1
            },
            {
                "size": "95х70х8",
                "koef": "0.01384",
                "metrs": 1
            },
            {
                "size": "100х40х2",
                "koef": "0.002781",
                "metrs": 1
            },
            {
                "size": "100х40х3",
                "koef": "0.004124",
                "metrs": 1
            },
            {
                "size": "100х40х4",
                "koef": "0.005435",
                "metrs": 1
            },
            {
                "size": "100х40х5",
                "koef": "0.006715",
                "metrs": 1
            },
            {
                "size": "100х40х6",
                "koef": "0.007963",
                "metrs": 1
            },
            {
                "size": "100х50х2",
                "koef": "0.003097",
                "metrs": 1
            },
            {
                "size": "100х50х3",
                "koef": "0.004598",
                "metrs": 1
            },
            {
                "size": "100х50х4",
                "koef": "0.006067",
                "metrs": 1
            },
            {
                "size": "100х50х5",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "100х50х6",
                "koef": "0.008911",
                "metrs": 1
            },
            {
                "size": "100х60х2",
                "koef": "0.003413",
                "metrs": 1
            },
            {
                "size": "100х60х3",
                "koef": "0.005072",
                "metrs": 1
            },
            {
                "size": "100х60х4",
                "koef": "0.006699",
                "metrs": 1
            },
            {
                "size": "100х60х5",
                "koef": "0.008295",
                "metrs": 1
            },
            {
                "size": "100х60х6",
                "koef": "0.009859",
                "metrs": 1
            },
            {
                "size": "100х65х2",
                "koef": "0.003571",
                "metrs": 1
            },
            {
                "size": "100х65х3",
                "koef": "0.005309",
                "metrs": 1
            },
            {
                "size": "100х65х4",
                "koef": "0.007015",
                "metrs": 1
            },
            {
                "size": "100х65х5",
                "koef": "0.00869",
                "metrs": 1
            },
            {
                "size": "100х65х6",
                "koef": "0.01033",
                "metrs": 1
            },
            {
                "size": "100х65х8",
                "koef": "0.01352",
                "metrs": 1
            },
            {
                "size": "100х70х2",
                "koef": "0.003729",
                "metrs": 1
            },
            {
                "size": "100х70х3",
                "koef": "0.005546",
                "metrs": 1
            },
            {
                "size": "100х70х4",
                "koef": "0.007331",
                "metrs": 1
            },
            {
                "size": "100х70х5",
                "koef": "0.009085",
                "metrs": 1
            },
            {
                "size": "100х70х6",
                "koef": "0.01081",
                "metrs": 1
            },
            {
                "size": "100х70х8",
                "koef": "0.01416",
                "metrs": 1
            },
            {
                "size": "100х80х2",
                "koef": "0.004045",
                "metrs": 1
            },
            {
                "size": "100х80х3",
                "koef": "0.00602",
                "metrs": 1
            },
            {
                "size": "100х80х4",
                "koef": "0.007963",
                "metrs": 1
            },
            {
                "size": "100х80х5",
                "koef": "0.009875",
                "metrs": 1
            },
            {
                "size": "100х80х6",
                "koef": "0.01176",
                "metrs": 1
            },
            {
                "size": "100х80х8",
                "koef": "0.01542",
                "metrs": 1
            },
            {
                "size": "120х40х2",
                "koef": "0.003097",
                "metrs": 1
            },
            {
                "size": "120х40х3",
                "koef": "0.004598",
                "metrs": 1
            },
            {
                "size": "120х40х4",
                "koef": "0.006067",
                "metrs": 1
            },
            {
                "size": "120х40х5",
                "koef": "0.007505",
                "metrs": 1
            },
            {
                "size": "120х40х6",
                "koef": "0.008911",
                "metrs": 1
            },
            {
                "size": "120х50х2",
                "koef": "0.003413",
                "metrs": 1
            },
            {
                "size": "120х50х3",
                "koef": "0.005072",
                "metrs": 1
            },
            {
                "size": "120х50х4",
                "koef": "0.006699",
                "metrs": 1
            },
            {
                "size": "120х50х5",
                "koef": "0.008295",
                "metrs": 1
            },
            {
                "size": "120х50х6",
                "koef": "0.009859",
                "metrs": 1
            },
            {
                "size": "120х60х2",
                "koef": "0.003729",
                "metrs": 1
            },
            {
                "size": "120х60х3",
                "koef": "0.005546",
                "metrs": 1
            },
            {
                "size": "120х60х4",
                "koef": "0.007331",
                "metrs": 1
            },
            {
                "size": "120х60х5",
                "koef": "0.009085",
                "metrs": 1
            },
            {
                "size": "120х60х6",
                "koef": "0.01081",
                "metrs": 1
            },
            {
                "size": "120х65х2",
                "koef": "0.003887",
                "metrs": 1
            },
            {
                "size": "120х65х3",
                "koef": "0.005783",
                "metrs": 1
            },
            {
                "size": "120х65х4",
                "koef": "0.007647",
                "metrs": 1
            },
            {
                "size": "120х65х5",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": "120х65х6",
                "koef": "0.01128",
                "metrs": 1
            },
            {
                "size": "120х65х8",
                "koef": "0.01479",
                "metrs": 1
            },
            {
                "size": "120х70х2",
                "koef": "0.004045",
                "metrs": 1
            },
            {
                "size": "120х70х3",
                "koef": "0.00602",
                "metrs": 1
            },
            {
                "size": "120х70х4",
                "koef": "0.007963",
                "metrs": 1
            },
            {
                "size": "120х70х5",
                "koef": "0.009875",
                "metrs": 1
            },
            {
                "size": "120х70х6",
                "koef": "0.01176",
                "metrs": 1
            },
            {
                "size": "120х70х8",
                "koef": "0.01542",
                "metrs": 1
            },
            {
                "size": "120х80х2",
                "koef": "0.004361",
                "metrs": 1
            },
            {
                "size": "120х80х3",
                "koef": "0.006494",
                "metrs": 1
            },
            {
                "size": "120х80х4",
                "koef": "0.008595",
                "metrs": 1
            },
            {
                "size": "120х80х5",
                "koef": "0.01067",
                "metrs": 1
            },
            {
                "size": "120х80х6",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": "120х80х8",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "130х40х2",
                "koef": "0.003255",
                "metrs": 1
            },
            {
                "size": "130х40х3",
                "koef": "0.004835",
                "metrs": 1
            },
            {
                "size": "130х40х4",
                "koef": "0.006383",
                "metrs": 1
            },
            {
                "size": "130х40х5",
                "koef": "0.0079",
                "metrs": 1
            },
            {
                "size": "130х40х6",
                "koef": "0.009385",
                "metrs": 1
            },
            {
                "size": "130х50х2",
                "koef": "0.003571",
                "metrs": 1
            },
            {
                "size": "130х50х3",
                "koef": "0.005309",
                "metrs": 1
            },
            {
                "size": "130х50х4",
                "koef": "0.007015",
                "metrs": 1
            },
            {
                "size": "130х50х5",
                "koef": "0.00869",
                "metrs": 1
            },
            {
                "size": "130х50х6",
                "koef": "0.01033",
                "metrs": 1
            },
            {
                "size": "130х60х2",
                "koef": "0.003887",
                "metrs": 1
            },
            {
                "size": "130х60х3",
                "koef": "0.005783",
                "metrs": 1
            },
            {
                "size": "130х60х4",
                "koef": "0.007647",
                "metrs": 1
            },
            {
                "size": "130х60х5",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": "130х60х6",
                "koef": "0.01128",
                "metrs": 1
            },
            {
                "size": "130х65х2",
                "koef": "0.004045",
                "metrs": 1
            },
            {
                "size": "130х65х3",
                "koef": "0.00602",
                "metrs": 1
            },
            {
                "size": "130х65х4",
                "koef": "0.007963",
                "metrs": 1
            },
            {
                "size": "130х65х5",
                "koef": "0.009875",
                "metrs": 1
            },
            {
                "size": "130х65х6",
                "koef": "0.01176",
                "metrs": 1
            },
            {
                "size": "130х65х8",
                "koef": "0.01542",
                "metrs": 1
            },
            {
                "size": "130х70х2",
                "koef": "0.004203",
                "metrs": 1
            },
            {
                "size": "130х70х3",
                "koef": "0.006257",
                "metrs": 1
            },
            {
                "size": "130х70х4",
                "koef": "0.008279",
                "metrs": 1
            },
            {
                "size": "130х70х5",
                "koef": "0.01027",
                "metrs": 1
            },
            {
                "size": "130х70х6",
                "koef": "0.01223",
                "metrs": 1
            },
            {
                "size": "130х70х8",
                "koef": "0.01605",
                "metrs": 1
            },
            {
                "size": "130х80х2",
                "koef": "0.004519",
                "metrs": 1
            },
            {
                "size": "130х80х3",
                "koef": "0.006731",
                "metrs": 1
            },
            {
                "size": "130х80х4",
                "koef": "0.008911",
                "metrs": 1
            },
            {
                "size": "130х80х5",
                "koef": "0.01106",
                "metrs": 1
            },
            {
                "size": "130х80х6",
                "koef": "0.01318",
                "metrs": 1
            },
            {
                "size": "130х80х8",
                "koef": "0.01732",
                "metrs": 1
            },
            {
                "size": "140х40х2",
                "koef": "0.003413",
                "metrs": 1
            },
            {
                "size": "140х40х3",
                "koef": "0.005072",
                "metrs": 1
            },
            {
                "size": "140х40х4",
                "koef": "0.006699",
                "metrs": 1
            },
            {
                "size": "140х40х5",
                "koef": "0.008295",
                "metrs": 1
            },
            {
                "size": "140х40х6",
                "koef": "0.009859",
                "metrs": 1
            },
            {
                "size": "140х50х2",
                "koef": "0.003729",
                "metrs": 1
            },
            {
                "size": "140х50х3",
                "koef": "0.005546",
                "metrs": 1
            },
            {
                "size": "140х50х4",
                "koef": "0.007331",
                "metrs": 1
            },
            {
                "size": "140х50х5",
                "koef": "0.009085",
                "metrs": 1
            },
            {
                "size": "140х50х6",
                "koef": "0.01081",
                "metrs": 1
            },
            {
                "size": "140х60х2",
                "koef": "0.004045",
                "metrs": 1
            },
            {
                "size": "140х60х3",
                "koef": "0.00602",
                "metrs": 1
            },
            {
                "size": "140х60х4",
                "koef": "0.007963",
                "metrs": 1
            },
            {
                "size": "140х60х5",
                "koef": "0.009875",
                "metrs": 1
            },
            {
                "size": "140х60х6",
                "koef": "0.01176",
                "metrs": 1
            },
            {
                "size": "140х65х2",
                "koef": "0.004203",
                "metrs": 1
            },
            {
                "size": "140х65х3",
                "koef": "0.006257",
                "metrs": 1
            },
            {
                "size": "140х65х4",
                "koef": "0.008279",
                "metrs": 1
            },
            {
                "size": "140х65х5",
                "koef": "0.01027",
                "metrs": 1
            },
            {
                "size": "140х65х6",
                "koef": "0.01223",
                "metrs": 1
            },
            {
                "size": "140х65х8",
                "koef": "0.01605",
                "metrs": 1
            },
            {
                "size": "140х70х2",
                "koef": "0.004361",
                "metrs": 1
            },
            {
                "size": "140х70х3",
                "koef": "0.006494",
                "metrs": 1
            },
            {
                "size": "140х70х4",
                "koef": "0.008595",
                "metrs": 1
            },
            {
                "size": "140х70х5",
                "koef": "0.01067",
                "metrs": 1
            },
            {
                "size": "140х70х6",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": "140х70х8",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "140х80х2",
                "koef": "0.004677",
                "metrs": 1
            },
            {
                "size": "140х80х3",
                "koef": "0.006968",
                "metrs": 1
            },
            {
                "size": "140х80х4",
                "koef": "0.009227",
                "metrs": 1
            },
            {
                "size": "140х80х5",
                "koef": "0.01146",
                "metrs": 1
            },
            {
                "size": "140х80х6",
                "koef": "0.01365",
                "metrs": 1
            },
            {
                "size": "140х80х8",
                "koef": "0.01795",
                "metrs": 1
            },
            {
                "size": "140х90х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "140х90х3",
                "koef": "0.007442",
                "metrs": 1
            },
            {
                "size": "140х90х4",
                "koef": "0.009859",
                "metrs": 1
            },
            {
                "size": "140х90х5",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": "140х90х6",
                "koef": "0.0146",
                "metrs": 1
            },
            {
                "size": "140х90х8",
                "koef": "0.01921",
                "metrs": 1
            },
            {
                "size": "140х100х2",
                "koef": "0.005309",
                "metrs": 1
            },
            {
                "size": "140х100х3",
                "koef": "0.007916",
                "metrs": 1
            },
            {
                "size": "140х100х4",
                "koef": "0.01049",
                "metrs": 1
            },
            {
                "size": "140х100х5",
                "koef": "0.01304",
                "metrs": 1
            },
            {
                "size": "140х100х6",
                "koef": "0.01555",
                "metrs": 1
            },
            {
                "size": "140х100х8",
                "koef": "0.02048",
                "metrs": 1
            },
            {
                "size": "150х40х2",
                "koef": "0.003571",
                "metrs": 1
            },
            {
                "size": "150х40х3",
                "koef": "0.005309",
                "metrs": 1
            },
            {
                "size": "150х40х4",
                "koef": "0.007015",
                "metrs": 1
            },
            {
                "size": "150х40х5",
                "koef": "0.00869",
                "metrs": 1
            },
            {
                "size": "150х40х6",
                "koef": "0.01033",
                "metrs": 1
            },
            {
                "size": "150х50х2",
                "koef": "0.003887",
                "metrs": 1
            },
            {
                "size": "150х50х3",
                "koef": "0.005783",
                "metrs": 1
            },
            {
                "size": "150х50х4",
                "koef": "0.007647",
                "metrs": 1
            },
            {
                "size": "150х50х5",
                "koef": "0.00948",
                "metrs": 1
            },
            {
                "size": "150х50х6",
                "koef": "0.01128",
                "metrs": 1
            },
            {
                "size": "150х60х2",
                "koef": "0.004203",
                "metrs": 1
            },
            {
                "size": "150х60х3",
                "koef": "0.006257",
                "metrs": 1
            },
            {
                "size": "150х60х4",
                "koef": "0.008279",
                "metrs": 1
            },
            {
                "size": "150х60х5",
                "koef": "0.01027",
                "metrs": 1
            },
            {
                "size": "150х60х6",
                "koef": "0.01223",
                "metrs": 1
            },
            {
                "size": "150х65х2",
                "koef": "0.004361",
                "metrs": 1
            },
            {
                "size": "150х65х3",
                "koef": "0.006494",
                "metrs": 1
            },
            {
                "size": "150х65х4",
                "koef": "0.008595",
                "metrs": 1
            },
            {
                "size": "150х65х5",
                "koef": "0.01067",
                "metrs": 1
            },
            {
                "size": "150х65х6",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": "150х65х8",
                "koef": "0.01668",
                "metrs": 1
            },
            {
                "size": "150х70х2",
                "koef": "0.004519",
                "metrs": 1
            },
            {
                "size": "150х70х3",
                "koef": "0.006731",
                "metrs": 1
            },
            {
                "size": "150х70х4",
                "koef": "0.008911",
                "metrs": 1
            },
            {
                "size": "150х70х5",
                "koef": "0.01106",
                "metrs": 1
            },
            {
                "size": "150х70х6",
                "koef": "0.01318",
                "metrs": 1
            },
            {
                "size": "150х70х8",
                "koef": "0.01732",
                "metrs": 1
            },
            {
                "size": "150х80х2",
                "koef": "0.004835",
                "metrs": 1
            },
            {
                "size": "150х80х3",
                "koef": "0.007205",
                "metrs": 1
            },
            {
                "size": "150х80х4",
                "koef": "0.009543",
                "metrs": 1
            },
            {
                "size": "150х80х5",
                "koef": "0.01185",
                "metrs": 1
            },
            {
                "size": "150х80х6",
                "koef": "0.01413",
                "metrs": 1
            },
            {
                "size": "150х80х8",
                "koef": "0.01858",
                "metrs": 1
            },
            {
                "size": "150х90х2",
                "koef": "0.005151",
                "metrs": 1
            },
            {
                "size": "150х90х3",
                "koef": "0.007679",
                "metrs": 1
            },
            {
                "size": "150х90х4",
                "koef": "0.01018",
                "metrs": 1
            },
            {
                "size": "150х90х5",
                "koef": "0.01264",
                "metrs": 1
            },
            {
                "size": "150х90х6",
                "koef": "0.01507",
                "metrs": 1
            },
            {
                "size": "150х90х8",
                "koef": "0.01984",
                "metrs": 1
            },
            {
                "size": "150х100х2",
                "koef": "0.005467",
                "metrs": 1
            },
            {
                "size": "150х100х3",
                "koef": "0.008153",
                "metrs": 1
            },
            {
                "size": "150х100х4",
                "koef": "0.01081",
                "metrs": 1
            },
            {
                "size": "150х100х5",
                "koef": "0.01343",
                "metrs": 1
            },
            {
                "size": "150х100х6",
                "koef": "0.01602",
                "metrs": 1
            },
            {
                "size": "150х100х8",
                "koef": "0.02111",
                "metrs": 1
            },
            {
                "size": "150х110х2",
                "koef": "0.005783",
                "metrs": 1
            },
            {
                "size": "150х110х3",
                "koef": "0.008627",
                "metrs": 1
            },
            {
                "size": "150х110х4",
                "koef": "0.01144",
                "metrs": 1
            },
            {
                "size": "150х110х5",
                "koef": "0.01422",
                "metrs": 1
            },
            {
                "size": "150х110х6",
                "koef": "0.01697",
                "metrs": 1
            },
            {
                "size": "150х110х8",
                "koef": "0.02237",
                "metrs": 1
            },
            {
                "size": "150х120х2",
                "koef": "0.006099",
                "metrs": 1
            },
            {
                "size": "150х120х3",
                "koef": "0.009101",
                "metrs": 1
            },
            {
                "size": "150х120х4",
                "koef": "0.01207",
                "metrs": 1
            },
            {
                "size": "150х120х5",
                "koef": "0.01501",
                "metrs": 1
            },
            {
                "size": "150х120х6",
                "koef": "0.01792",
                "metrs": 1
            },
            {
                "size": "150х120х8",
                "koef": "0.02364",
                "metrs": 1
            },
            {
                "size": "150х130х2",
                "koef": "0.006415",
                "metrs": 1
            },
            {
                "size": "150х130х3",
                "koef": "0.009575",
                "metrs": 1
            },
            {
                "size": "150х130х4",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": "150х130х5",
                "koef": "0.0158",
                "metrs": 1
            },
            {
                "size": "150х130х6",
                "koef": "0.01887",
                "metrs": 1
            },
            {
                "size": "150х130х8",
                "koef": "0.0249",
                "metrs": 1
            },
            {
                "size": "160х40х2",
                "koef": "0.003729",
                "metrs": 1
            },
            {
                "size": "160х40х3",
                "koef": "0.005546",
                "metrs": 1
            },
            {
                "size": "160х40х4",
                "koef": "0.007331",
                "metrs": 1
            },
            {
                "size": "160х40х5",
                "koef": "0.009085",
                "metrs": 1
            },
            {
                "size": "160х40х6",
                "koef": "0.01081",
                "metrs": 1
            },
            {
                "size": "160х50х2",
                "koef": "0.004045",
                "metrs": 1
            },
            {
                "size": "160х50х3",
                "koef": "0.00602",
                "metrs": 1
            },
            {
                "size": "160х50х4",
                "koef": "0.007963",
                "metrs": 1
            },
            {
                "size": "160х50х5",
                "koef": "0.009875",
                "metrs": 1
            },
            {
                "size": "160х50х6",
                "koef": "0.01176",
                "metrs": 1
            },
            {
                "size": "160х60х2",
                "koef": "0.004361",
                "metrs": 1
            },
            {
                "size": "160х60х3",
                "koef": "0.006494",
                "metrs": 1
            },
            {
                "size": "160х60х4",
                "koef": "0.008595",
                "metrs": 1
            },
            {
                "size": "160х60х5",
                "koef": "0.01067",
                "metrs": 1
            },
            {
                "size": "160х60х6",
                "koef": "0.0127",
                "metrs": 1
            },
            {
                "size": "160х65х2",
                "koef": "0.004519",
                "metrs": 1
            },
            {
                "size": "160х65х3",
                "koef": "0.006731",
                "metrs": 1
            },
            {
                "size": "160х65х4",
                "koef": "0.008911",
                "metrs": 1
            },
            {
                "size": "160х65х5",
                "koef": "0.01106",
                "metrs": 1
            },
            {
                "size": "160х65х6",
                "koef": "0.01318",
                "metrs": 1
            },
            {
                "size": "160х65х8",
                "koef": "0.01732",
                "metrs": 1
            },
            {
                "size": "160х70х2",
                "koef": "0.004677",
                "metrs": 1
            },
            {
                "size": "160х70х3",
                "koef": "0.006968",
                "metrs": 1
            },
            {
                "size": "160х70х4",
                "koef": "0.009227",
                "metrs": 1
            },
            {
                "size": "160х70х5",
                "koef": "0.01146",
                "metrs": 1
            },
            {
                "size": "160х70х6",
                "koef": "0.01365",
                "metrs": 1
            },
            {
                "size": "160х70х8",
                "koef": "0.01795",
                "metrs": 1
            },
            {
                "size": "160х80х2",
                "koef": "0.004993",
                "metrs": 1
            },
            {
                "size": "160х80х3",
                "koef": "0.007442",
                "metrs": 1
            },
            {
                "size": "160х80х4",
                "koef": "0.009859",
                "metrs": 1
            },
            {
                "size": "160х80х5",
                "koef": "0.01225",
                "metrs": 1
            },
            {
                "size": "160х80х6",
                "koef": "0.0146",
                "metrs": 1
            },
            {
                "size": "160х80х8",
                "koef": "0.01921",
                "metrs": 1
            },
            {
                "size": "160х90х2",
                "koef": "0.005309",
                "metrs": 1
            },
            {
                "size": "160х90х3",
                "koef": "0.007916",
                "metrs": 1
            },
            {
                "size": "160х90х4",
                "koef": "0.01049",
                "metrs": 1
            },
            {
                "size": "160х90х5",
                "koef": "0.01304",
                "metrs": 1
            },
            {
                "size": "160х90х6",
                "koef": "0.01555",
                "metrs": 1
            },
            {
                "size": "160х90х8",
                "koef": "0.02048",
                "metrs": 1
            },
            {
                "size": "160х100х2",
                "koef": "0.005625",
                "metrs": 1
            }
        ],
        "Шестигранник": [
            {
                "size": 8,
                "koef": "0.000438",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0.000554",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0.000828",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000985",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0.001156",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001341",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.001977",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.00247",
                "metrs": 1
            },
            {
                "size": 21,
                "koef": "0.003017",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.003311",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.003941",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0.004988",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.006157",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.007006",
                "metrs": 1
            },
            {
                "size": 34,
                "koef": "0.007909",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.008867",
                "metrs": 1
            },
            {
                "size": 41,
                "koef": "0.0115",
                "metrs": 1
            },
            {
                "size": 46,
                "koef": "0.01448",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.0171",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.02463",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0.02891",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.03352",
                "metrs": 1
            }
        ]
    },
    "Алюминий-дюраль": {
        "Круг": [
            {
                "size": 6,
                "koef": "0.000076",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.000135",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.00021",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000303",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.000412",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.000474",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.000539",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.000608",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.000682",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.00076",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.000842",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.001019",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.001212",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.001315",
                "metrs": 1
            },
            {
                "size": 26,
                "koef": "0.001423",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.00165",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.001894",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.002155",
                "metrs": 1
            },
            {
                "size": 34,
                "koef": "0.002433",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.002579",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.002728",
                "metrs": 1
            },
            {
                "size": 38,
                "koef": "0.003039",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.003368",
                "metrs": 1
            },
            {
                "size": 42,
                "koef": "0.003713",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.004262",
                "metrs": 1
            },
            {
                "size": 48,
                "koef": "0.00485",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.005262",
                "metrs": 1
            },
            {
                "size": 52,
                "koef": "0.005692",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.006367",
                "metrs": 1
            },
            {
                "size": 58,
                "koef": "0.007081",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.007577",
                "metrs": 1
            },
            {
                "size": 62,
                "koef": "0.008091",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0.008893",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.01031",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0.01184",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.01347",
                "metrs": 1
            },
            {
                "size": 85,
                "koef": "0.01521",
                "metrs": 1
            },
            {
                "size": 90,
                "koef": "0.01705",
                "metrs": 1
            },
            {
                "size": 95,
                "koef": "0.019",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0.02105",
                "metrs": 1
            },
            {
                "size": 105,
                "koef": "0.02321",
                "metrs": 1
            },
            {
                "size": 110,
                "koef": "0.02547",
                "metrs": 1
            },
            {
                "size": 115,
                "koef": "0.02784",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "0.03031",
                "metrs": 1
            },
            {
                "size": 125,
                "koef": "0.03289",
                "metrs": 1
            },
            {
                "size": 130,
                "koef": "0.03557",
                "metrs": 1
            },
            {
                "size": 135,
                "koef": "0.03836",
                "metrs": 1
            },
            {
                "size": 140,
                "koef": "0.04126",
                "metrs": 1
            },
            {
                "size": 145,
                "koef": "0.04425",
                "metrs": 1
            },
            {
                "size": 150,
                "koef": "0.04736",
                "metrs": 1
            },
            {
                "size": 155,
                "koef": "0.05057",
                "metrs": 1
            },
            {
                "size": 160,
                "koef": "0.05388",
                "metrs": 1
            },
            {
                "size": 170,
                "koef": "0.06083",
                "metrs": 1
            },
            {
                "size": 180,
                "koef": "0.0682",
                "metrs": 1
            },
            {
                "size": 190,
                "koef": "0.07599",
                "metrs": 1
            },
            {
                "size": 200,
                "koef": "0.0842",
                "metrs": 1
            },
            {
                "size": 210,
                "koef": "0.09282",
                "metrs": 1
            },
            {
                "size": 220,
                "koef": "0.1019",
                "metrs": 1
            },
            {
                "size": 230,
                "koef": "0.1113",
                "metrs": 1
            },
            {
                "size": 240,
                "koef": "0.1212",
                "metrs": 1
            },
            {
                "size": 250,
                "koef": "0.1316",
                "metrs": 1
            },
            {
                "size": 260,
                "koef": "0.1423",
                "metrs": 1
            },
            {
                "size": 270,
                "koef": "0.1534",
                "metrs": 1
            },
            {
                "size": 280,
                "koef": "0.165",
                "metrs": 1
            },
            {
                "size": 290,
                "koef": "0.177",
                "metrs": 1
            },
            {
                "size": 300,
                "koef": "0.1894",
                "metrs": 1
            },
            {
                "size": 310,
                "koef": "0.2023",
                "metrs": 1
            },
            {
                "size": 320,
                "koef": "0.2155",
                "metrs": 1
            },
            {
                "size": 330,
                "koef": "0.2292",
                "metrs": 1
            },
            {
                "size": 340,
                "koef": "0.2433",
                "metrs": 1
            },
            {
                "size": 350,
                "koef": "0.2578",
                "metrs": 1
            },
            {
                "size": 360,
                "koef": "0.2728",
                "metrs": 1
            },
            {
                "size": 400,
                "koef": "0.3368",
                "metrs": 1
            },
            {
                "size": 450,
                "koef": "0.4262",
                "metrs": 1
            },
            {
                "size": 460,
                "koef": "0.4454",
                "metrs": 1
            }
        ],
        "Лента/штрипс": [
            {
                "size": "0.5",
                "koef": "0.00134",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.002144",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.00268",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.00402",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.00536",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.00804",
                "metrs": 1
            }
        ],
        "Лист": [
            {
                "size": "0.5",
                "koef": "0.00134",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0.001608",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.002144",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.00268",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.003216",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.00402",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0.004824",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.00536",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0.0067",
                "metrs": 1
            },
            {
                "size": "2.9",
                "koef": "0.007772",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.00804",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0.00938",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.01072",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.0134",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.01608",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.01876",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.02144",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.0268",
                "metrs": 1
            }
        ],
        "Плита": [
            {
                "size": 12,
                "koef": "0.03216",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.03752",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.0402",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.04288",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.04824",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.0536",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.05896",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.067",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.07504",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.0804",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.08576",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.0938",
                "metrs": 1
            },
            {
                "size": 38,
                "koef": "0.1018",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.1072",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.1206",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.134",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.1474",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.1608",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0.1742",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.1876",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0.201",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.2144",
                "metrs": 1
            },
            {
                "size": 85,
                "koef": "0.2278",
                "metrs": 1
            },
            {
                "size": 90,
                "koef": "0.2412",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0.268",
                "metrs": 1
            },
            {
                "size": 110,
                "koef": "0.2948",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "0.3216",
                "metrs": 1
            },
            {
                "size": 140,
                "koef": "0.3752",
                "metrs": 1
            },
            {
                "size": 150,
                "koef": "0.402",
                "metrs": 1
            },
            {
                "size": 160,
                "koef": "0.4288",
                "metrs": 1
            },
            {
                "size": 180,
                "koef": "0.4824",
                "metrs": 1
            },
            {
                "size": 200,
                "koef": "0.536",
                "metrs": 1
            }
        ],
        "Проволока": [
            {
                "size": "1.6",
                "koef": "0.005388",
                "metrs": 1000
            },
            {
                "size": "1.8",
                "koef": "0.00682",
                "metrs": 1000
            },
            {
                "size": 2,
                "koef": "0.008419",
                "metrs": 1000
            },
            {
                "size": "2.5",
                "koef": "0.01316",
                "metrs": 1000
            },
            {
                "size": 3,
                "koef": "0.01894",
                "metrs": 1000
            },
            {
                "size": "3.15",
                "koef": "0.02089",
                "metrs": 1000
            },
            {
                "size": 4,
                "koef": "0.03368",
                "metrs": 1000
            },
            {
                "size": 5,
                "koef": "0.05262",
                "metrs": 1000
            },
            {
                "size": 6,
                "koef": "0.07578",
                "metrs": 1000
            },
            {
                "size": 8,
                "koef": "0.1347",
                "metrs": 1000
            }
        ],
        "Труба": [
            {
                "size": " 6х1",
                "koef": "0.000042",
                "metrs": 1
            },
            {
                "size": " 7х1",
                "koef": "0.000051",
                "metrs": 1
            },
            {
                "size": " 8х1",
                "koef": "0.000059",
                "metrs": 1
            },
            {
                "size": " 8х1,5",
                "koef": "0.000082",
                "metrs": 1
            },
            {
                "size": " 8х2",
                "koef": "0.000101",
                "metrs": 1
            },
            {
                "size": " 9х1",
                "koef": "0.000067",
                "metrs": 1
            },
            {
                "size": " 10х1",
                "koef": "0.000076",
                "metrs": 1
            },
            {
                "size": " 10х1,5",
                "koef": "0.000107",
                "metrs": 1
            },
            {
                "size": " 10х2",
                "koef": "0.000135",
                "metrs": 1
            },
            {
                "size": " 12х1",
                "koef": "0.000093",
                "metrs": 1
            },
            {
                "size": " 12х1,5",
                "koef": "0.000133",
                "metrs": 1
            },
            {
                "size": " 12х2",
                "koef": "0.000168",
                "metrs": 1
            },
            {
                "size": " 12х2,45",
                "koef": "0.000197",
                "metrs": 1
            },
            {
                "size": " 14х1",
                "koef": "0.000109",
                "metrs": 1
            },
            {
                "size": " 14х1,2",
                "koef": "0.000129",
                "metrs": 1
            },
            {
                "size": " 14х1,5",
                "koef": "0.000158",
                "metrs": 1
            },
            {
                "size": " 14х2",
                "koef": "0.000202",
                "metrs": 1
            },
            {
                "size": " 14х4",
                "koef": "0.000337",
                "metrs": 1
            },
            {
                "size": " 16х1",
                "koef": "0.000126",
                "metrs": 1
            },
            {
                "size": " 16х1,2",
                "koef": "0.00015",
                "metrs": 1
            },
            {
                "size": " 16х1,5",
                "koef": "0.000183",
                "metrs": 1
            },
            {
                "size": " 16х2",
                "koef": "0.000236",
                "metrs": 1
            },
            {
                "size": " 18х1",
                "koef": "0.000143",
                "metrs": 1
            },
            {
                "size": " 18х1,5",
                "koef": "0.000208",
                "metrs": 1
            },
            {
                "size": " 18х2",
                "koef": "0.000269",
                "metrs": 1
            },
            {
                "size": " 18х3",
                "koef": "0.000379",
                "metrs": 1
            },
            {
                "size": " 20х1",
                "koef": "0.00016",
                "metrs": 1
            },
            {
                "size": " 20х1,5",
                "koef": "0.000234",
                "metrs": 1
            },
            {
                "size": " 20х2",
                "koef": "0.000303",
                "metrs": 1
            },
            {
                "size": " 20х2,5",
                "koef": "0.000368",
                "metrs": 1
            },
            {
                "size": " 20х4",
                "koef": "0.000539",
                "metrs": 1
            },
            {
                "size": " 22х1",
                "koef": "0.000177",
                "metrs": 1
            },
            {
                "size": " 22х1,2",
                "koef": "0.00021",
                "metrs": 1
            },
            {
                "size": " 22х1,5",
                "koef": "0.000259",
                "metrs": 1
            },
            {
                "size": " 22х2",
                "koef": "0.000337",
                "metrs": 1
            },
            {
                "size": " 22х2,5",
                "koef": "0.00041",
                "metrs": 1
            },
            {
                "size": " 22х3",
                "koef": "0.00048",
                "metrs": 1
            },
            {
                "size": " 24х2",
                "koef": "0.00037",
                "metrs": 1
            },
            {
                "size": " 25х1",
                "koef": "0.000202",
                "metrs": 1
            },
            {
                "size": " 25х1,5",
                "koef": "0.000297",
                "metrs": 1
            },
            {
                "size": " 25х2",
                "koef": "0.000387",
                "metrs": 1
            },
            {
                "size": " 25х2,5",
                "koef": "0.000474",
                "metrs": 1
            },
            {
                "size": " 25х3",
                "koef": "0.000556",
                "metrs": 1
            },
            {
                "size": " 26х2",
                "koef": "0.000404",
                "metrs": 1
            },
            {
                "size": " 26х6",
                "koef": "0.00101",
                "metrs": 1
            },
            {
                "size": " 27х1",
                "koef": "0.000219",
                "metrs": 1
            },
            {
                "size": " 28х1",
                "koef": "0.000227",
                "metrs": 1
            },
            {
                "size": " 28х1,5",
                "koef": "0.000335",
                "metrs": 1
            },
            {
                "size": " 28х2",
                "koef": "0.000438",
                "metrs": 1
            },
            {
                "size": " 28х3",
                "koef": "0.000631",
                "metrs": 1
            },
            {
                "size": " 28х6",
                "koef": "0.001111",
                "metrs": 1
            },
            {
                "size": " 30х1",
                "koef": "0.000244",
                "metrs": 1
            },
            {
                "size": " 30х1,5",
                "koef": "0.00036",
                "metrs": 1
            },
            {
                "size": " 30х2",
                "koef": "0.000471",
                "metrs": 1
            },
            {
                "size": " 30х2,5",
                "koef": "0.000579",
                "metrs": 1
            },
            {
                "size": " 30х3",
                "koef": "0.000682",
                "metrs": 1
            },
            {
                "size": " 30х6",
                "koef": "0.001212",
                "metrs": 1
            },
            {
                "size": " 32х1",
                "koef": "0.000261",
                "metrs": 1
            },
            {
                "size": " 32х2",
                "koef": "0.000505",
                "metrs": 1
            },
            {
                "size": " 32х3",
                "koef": "0.000732",
                "metrs": 1
            },
            {
                "size": " 34х2",
                "koef": "0.000539",
                "metrs": 1
            },
            {
                "size": " 34х5",
                "koef": "0.001221",
                "metrs": 1
            },
            {
                "size": " 34х10",
                "koef": "0.002021",
                "metrs": 1
            },
            {
                "size": " 35х1",
                "koef": "0.000286",
                "metrs": 1
            },
            {
                "size": " 35х1,5",
                "koef": "0.000423",
                "metrs": 1
            },
            {
                "size": " 35х2",
                "koef": "0.000556",
                "metrs": 1
            },
            {
                "size": " 35х2,5",
                "koef": "0.000684",
                "metrs": 1
            },
            {
                "size": " 35х5",
                "koef": "0.001263",
                "metrs": 1
            },
            {
                "size": " 36х2",
                "koef": "0.000573",
                "metrs": 1
            },
            {
                "size": " 36х3",
                "koef": "0.000834",
                "metrs": 1
            },
            {
                "size": " 38х1,5",
                "koef": "0.000461",
                "metrs": 1
            },
            {
                "size": " 38х2",
                "koef": "0.000606",
                "metrs": 1
            },
            {
                "size": " 38х3",
                "koef": "0.000884",
                "metrs": 1
            },
            {
                "size": " 38х4",
                "koef": "0.001145",
                "metrs": 1
            },
            {
                "size": " 38х5",
                "koef": "0.001389",
                "metrs": 1
            },
            {
                "size": " 38х10",
                "koef": "0.002357",
                "metrs": 1
            },
            {
                "size": " 40х1,5",
                "koef": "0.000486",
                "metrs": 1
            },
            {
                "size": " 40х2",
                "koef": "0.00064",
                "metrs": 1
            },
            {
                "size": " 40х2,5",
                "koef": "0.000789",
                "metrs": 1
            },
            {
                "size": " 40х3",
                "koef": "0.000935",
                "metrs": 1
            },
            {
                "size": " 40х3,5",
                "koef": "0.001076",
                "metrs": 1
            },
            {
                "size": " 40х5",
                "koef": "0.001473",
                "metrs": 1
            },
            {
                "size": " 40х7",
                "koef": "0.001945",
                "metrs": 1
            },
            {
                "size": " 40х8,5",
                "koef": "0.002254",
                "metrs": 1
            },
            {
                "size": " 42х1,5",
                "koef": "0.000511",
                "metrs": 1
            },
            {
                "size": " 42х3",
                "koef": "0.000985",
                "metrs": 1
            },
            {
                "size": " 42х4",
                "koef": "0.00128",
                "metrs": 1
            },
            {
                "size": " 45х2",
                "koef": "0.000724",
                "metrs": 1
            },
            {
                "size": " 45х2,5",
                "koef": "0.000895",
                "metrs": 1
            },
            {
                "size": " 45х3",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 45х4",
                "koef": "0.001381",
                "metrs": 1
            },
            {
                "size": " 45х5",
                "koef": "0.001684",
                "metrs": 1
            },
            {
                "size": " 48х3",
                "koef": "0.001137",
                "metrs": 1
            },
            {
                "size": " 48х4",
                "koef": "0.001482",
                "metrs": 1
            },
            {
                "size": " 50х1,5",
                "koef": "0.000613",
                "metrs": 1
            },
            {
                "size": " 50х2",
                "koef": "0.000808",
                "metrs": 1
            },
            {
                "size": " 50х2,5",
                "koef": "0.001",
                "metrs": 1
            },
            {
                "size": " 50х3",
                "koef": "0.001187",
                "metrs": 1
            },
            {
                "size": " 50х4",
                "koef": "0.001549",
                "metrs": 1
            },
            {
                "size": " 50х5",
                "koef": "0.001894",
                "metrs": 1
            },
            {
                "size": " 50х10",
                "koef": "0.003368",
                "metrs": 1
            },
            {
                "size": " 50х12,5",
                "koef": "0.003947",
                "metrs": 1
            },
            {
                "size": " 52х1,5",
                "koef": "0.000638",
                "metrs": 1
            },
            {
                "size": " 52х6",
                "koef": "0.002324",
                "metrs": 1
            },
            {
                "size": " 55х2",
                "koef": "0.000892",
                "metrs": 1
            },
            {
                "size": " 55х2,5",
                "koef": "0.001105",
                "metrs": 1
            },
            {
                "size": " 55х3",
                "koef": "0.001313",
                "metrs": 1
            },
            {
                "size": " 55х5",
                "koef": "0.002105",
                "metrs": 1
            },
            {
                "size": " 55х17,5",
                "koef": "0.005525",
                "metrs": 1
            },
            {
                "size": " 58х15",
                "koef": "0.005431",
                "metrs": 1
            },
            {
                "size": " 60х2",
                "koef": "0.000977",
                "metrs": 1
            },
            {
                "size": " 60х2,5",
                "koef": "0.00121",
                "metrs": 1
            },
            {
                "size": " 60х3",
                "koef": "0.00144",
                "metrs": 1
            },
            {
                "size": " 60х4",
                "koef": "0.001886",
                "metrs": 1
            },
            {
                "size": " 60х5",
                "koef": "0.002315",
                "metrs": 1
            },
            {
                "size": " 60х10",
                "koef": "0.00421",
                "metrs": 1
            },
            {
                "size": " 60х15",
                "koef": "0.005683",
                "metrs": 1
            },
            {
                "size": " 62х10",
                "koef": "0.004378",
                "metrs": 1
            },
            {
                "size": " 65х2",
                "koef": "0.001061",
                "metrs": 1
            },
            {
                "size": " 65х5",
                "koef": "0.002526",
                "metrs": 1
            },
            {
                "size": " 65х10",
                "koef": "0.004631",
                "metrs": 1
            },
            {
                "size": " 70х2",
                "koef": "0.001145",
                "metrs": 1
            },
            {
                "size": " 70х3",
                "koef": "0.001692",
                "metrs": 1
            },
            {
                "size": " 70х5",
                "koef": "0.002736",
                "metrs": 1
            },
            {
                "size": " 70х15",
                "koef": "0.006946",
                "metrs": 1
            },
            {
                "size": " 75х2",
                "koef": "0.001229",
                "metrs": 1
            },
            {
                "size": " 75х4",
                "koef": "0.002391",
                "metrs": 1
            },
            {
                "size": " 75х5",
                "koef": "0.002947",
                "metrs": 1
            },
            {
                "size": " 75х6",
                "koef": "0.003486",
                "metrs": 1
            },
            {
                "size": " 75х10",
                "koef": "0.005473",
                "metrs": 1
            },
            {
                "size": " 75х12,5",
                "koef": "0.006578",
                "metrs": 1
            },
            {
                "size": " 75х15",
                "koef": "0.007578",
                "metrs": 1
            },
            {
                "size": " 75х20",
                "koef": "0.009261",
                "metrs": 1
            },
            {
                "size": " 80х2",
                "koef": "0.001313",
                "metrs": 1
            },
            {
                "size": " 80х3",
                "koef": "0.001945",
                "metrs": 1
            },
            {
                "size": " 80х5",
                "koef": "0.003157",
                "metrs": 1
            },
            {
                "size": " 80х8",
                "koef": "0.00485",
                "metrs": 1
            },
            {
                "size": " 80х12,5",
                "koef": "0.007104",
                "metrs": 1
            },
            {
                "size": " 80х15",
                "koef": "0.008209",
                "metrs": 1
            },
            {
                "size": " 80х20",
                "koef": "0.0101",
                "metrs": 1
            },
            {
                "size": " 85х5",
                "koef": "0.003368",
                "metrs": 1
            },
            {
                "size": " 85х12,5",
                "koef": "0.00763",
                "metrs": 1
            },
            {
                "size": " 85х15",
                "koef": "0.00884",
                "metrs": 1
            },
            {
                "size": " 90х2",
                "koef": "0.001482",
                "metrs": 1
            },
            {
                "size": " 90х3",
                "koef": "0.002197",
                "metrs": 1
            },
            {
                "size": " 90х5",
                "koef": "0.003578",
                "metrs": 1
            },
            {
                "size": " 90х17,5",
                "koef": "0.01068",
                "metrs": 1
            },
            {
                "size": " 90х20",
                "koef": "0.01179",
                "metrs": 1
            },
            {
                "size": " 95х10",
                "koef": "0.007157",
                "metrs": 1
            },
            {
                "size": " 95х12,5",
                "koef": "0.008683",
                "metrs": 1
            },
            {
                "size": " 95х22,5",
                "koef": "0.01373",
                "metrs": 1
            },
            {
                "size": " 95х27,5",
                "koef": "0.01563",
                "metrs": 1
            },
            {
                "size": " 100х2,5",
                "koef": "0.002052",
                "metrs": 1
            },
            {
                "size": " 100х3",
                "koef": "0.00245",
                "metrs": 1
            },
            {
                "size": " 100х5",
                "koef": "0.003999",
                "metrs": 1
            },
            {
                "size": " 100х15",
                "koef": "0.01073",
                "metrs": 1
            },
            {
                "size": " 100х20",
                "koef": "0.01347",
                "metrs": 1
            },
            {
                "size": " 100х25",
                "koef": "0.01579",
                "metrs": 1
            },
            {
                "size": " 105х10",
                "koef": "0.007998",
                "metrs": 1
            },
            {
                "size": " 115х5",
                "koef": "0.004631",
                "metrs": 1
            },
            {
                "size": " 120х3,5",
                "koef": "0.003433",
                "metrs": 1
            },
            {
                "size": " 120х5",
                "koef": "0.004841",
                "metrs": 1
            },
            {
                "size": " 125х7,5",
                "koef": "0.00742",
                "metrs": 1
            },
            {
                "size": " 130х10",
                "koef": "0.0101",
                "metrs": 1
            },
            {
                "size": " 130х30",
                "koef": "0.02526",
                "metrs": 1
            },
            {
                "size": " 135х5",
                "koef": "0.005473",
                "metrs": 1
            },
            {
                "size": " 140х40",
                "koef": "0.03368",
                "metrs": 1
            },
            {
                "size": " 160х10",
                "koef": "0.01263",
                "metrs": 1
            },
            {
                "size": " 240х40",
                "koef": "0.06736",
                "metrs": 1
            },
            {
                "size": " 280х40",
                "koef": "0.08083",
                "metrs": 1
            },
            {
                "size": " 280х45",
                "koef": "0.08904",
                "metrs": 1
            },
            {
                "size": " 300х40",
                "koef": "0.08756",
                "metrs": 1
            }
        ],
        "Труба квадратная": [
            {
                "size": " 10х10х1",
                "koef": "0.000092",
                "metrs": 1
            },
            {
                "size": " 12х12х1",
                "koef": "0.000113",
                "metrs": 1
            },
            {
                "size": " 12х12х1,2",
                "koef": "0.000132",
                "metrs": 1
            },
            {
                "size": " 15х15х1,5",
                "koef": "0.000207",
                "metrs": 1
            },
            {
                "size": " 15х15х2",
                "koef": "0.00026",
                "metrs": 1
            },
            {
                "size": " 20х20х1",
                "koef": "0.000199",
                "metrs": 1
            },
            {
                "size": " 20х20х1,5",
                "koef": "0.000287",
                "metrs": 1
            },
            {
                "size": " 20х20х2",
                "koef": "0.000367",
                "metrs": 1
            },
            {
                "size": " 25х25х1,5",
                "koef": "0.000368",
                "metrs": 1
            },
            {
                "size": " 25х25х2",
                "koef": "0.000475",
                "metrs": 1
            },
            {
                "size": " 25х25х3",
                "koef": "0.000666",
                "metrs": 1
            },
            {
                "size": " 30х30х1,5",
                "koef": "0.000448",
                "metrs": 1
            },
            {
                "size": " 30х30х2",
                "koef": "0.000582",
                "metrs": 1
            },
            {
                "size": " 30х30х3",
                "koef": "0.000827",
                "metrs": 1
            },
            {
                "size": " 35х35х2",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": " 40х40х1,2",
                "koef": "0.000492",
                "metrs": 1
            },
            {
                "size": " 40х40х1,5",
                "koef": "0.000609",
                "metrs": 1
            },
            {
                "size": " 40х40х2",
                "koef": "0.000796",
                "metrs": 1
            },
            {
                "size": " 40х40х3",
                "koef": "0.001148",
                "metrs": 1
            },
            {
                "size": " 40х40х4",
                "koef": "0.00147",
                "metrs": 1
            },
            {
                "size": " 50х50х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 50х50х2",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": " 50х50х3",
                "koef": "0.00147",
                "metrs": 1
            },
            {
                "size": " 50х50х4",
                "koef": "0.001899",
                "metrs": 1
            },
            {
                "size": " 60х60х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": " 60х60х3",
                "koef": "0.001792",
                "metrs": 1
            },
            {
                "size": " 60х60х4",
                "koef": "0.002328",
                "metrs": 1
            },
            {
                "size": " 80х80х2",
                "koef": "0.001654",
                "metrs": 1
            },
            {
                "size": " 80х80х3",
                "koef": "0.002435",
                "metrs": 1
            },
            {
                "size": " 80х80х4",
                "koef": "0.003185",
                "metrs": 1
            },
            {
                "size": " 100х100х2",
                "koef": "0.002083",
                "metrs": 1
            },
            {
                "size": " 100х100х4",
                "koef": "0.004043",
                "metrs": 1
            },
            {
                "size": " 100х100х5",
                "koef": "0.004977",
                "metrs": 1
            }
        ],
        "Труба прямоугольная": [
            {
                "size": " 20х10х1,5",
                "koef": "0.000207",
                "metrs": 1
            },
            {
                "size": " 30х15х1,5",
                "koef": "0.000327",
                "metrs": 1
            },
            {
                "size": " 30х15х2",
                "koef": "0.000421",
                "metrs": 1
            },
            {
                "size": " 30х20х2",
                "koef": "0.000475",
                "metrs": 1
            },
            {
                "size": " 40х20х1,2",
                "koef": "0.000364",
                "metrs": 1
            },
            {
                "size": " 40х20х1,5",
                "koef": "0.000448",
                "metrs": 1
            },
            {
                "size": " 40х20х2",
                "koef": "0.000582",
                "metrs": 1
            },
            {
                "size": " 40х20х3",
                "koef": "0.000827",
                "metrs": 1
            },
            {
                "size": " 40х25х2",
                "koef": "0.000635",
                "metrs": 1
            },
            {
                "size": " 40х30х2",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": " 40х30х3",
                "koef": "0.000988",
                "metrs": 1
            },
            {
                "size": " 50х20х2",
                "koef": "0.000689",
                "metrs": 1
            },
            {
                "size": " 50х30х2",
                "koef": "0.000796",
                "metrs": 1
            },
            {
                "size": " 50х30х3",
                "koef": "0.001148",
                "metrs": 1
            },
            {
                "size": " 60х20х2",
                "koef": "0.000796",
                "metrs": 1
            },
            {
                "size": " 60х25х2",
                "koef": "0.00085",
                "metrs": 1
            },
            {
                "size": " 60х25х3",
                "koef": "0.001229",
                "metrs": 1
            },
            {
                "size": " 60х30х2",
                "koef": "0.000903",
                "metrs": 1
            },
            {
                "size": " 60х30х3",
                "koef": "0.001309",
                "metrs": 1
            },
            {
                "size": " 60х40х1,5",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 60х40х2",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": " 60х40х3",
                "koef": "0.00147",
                "metrs": 1
            },
            {
                "size": " 60х40х4",
                "koef": "0.001899",
                "metrs": 1
            },
            {
                "size": " 75х25х2,5",
                "koef": "0.001244",
                "metrs": 1
            },
            {
                "size": " 80х20х2",
                "koef": "0.001011",
                "metrs": 1
            },
            {
                "size": " 80х40х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": " 80х40х3",
                "koef": "0.001792",
                "metrs": 1
            },
            {
                "size": " 80х40х4",
                "koef": "0.002328",
                "metrs": 1
            },
            {
                "size": " 80х50х4",
                "koef": "0.002542",
                "metrs": 1
            },
            {
                "size": " 80х60х4",
                "koef": "0.002756",
                "metrs": 1
            },
            {
                "size": " 100х20х2",
                "koef": "0.001225",
                "metrs": 1
            },
            {
                "size": " 100х25х3",
                "koef": "0.001872",
                "metrs": 1
            },
            {
                "size": " 100х30х2",
                "koef": "0.001332",
                "metrs": 1
            },
            {
                "size": " 100х40х4",
                "koef": "0.002756",
                "metrs": 1
            },
            {
                "size": " 100х50х2",
                "koef": "0.001547",
                "metrs": 1
            },
            {
                "size": " 100х50х3",
                "koef": "0.002274",
                "metrs": 1
            },
            {
                "size": " 100х50х4",
                "koef": "0.002971",
                "metrs": 1
            },
            {
                "size": " 100х50х5",
                "koef": "0.003637",
                "metrs": 1
            },
            {
                "size": " 100х60х3",
                "koef": "0.002435",
                "metrs": 1
            },
            {
                "size": " 100х80х4",
                "koef": "0.003614",
                "metrs": 1
            },
            {
                "size": " 120х60х4",
                "koef": "0.003614",
                "metrs": 1
            }
        ],
        "Уголок": [
            {
                "size": " 10х10х1,2",
                "koef": "0.00006",
                "metrs": 1
            },
            {
                "size": " 12х12х1",
                "koef": "0.000062",
                "metrs": 1
            },
            {
                "size": " 12х12х1,5",
                "koef": "0.00009",
                "metrs": 1
            },
            {
                "size": " 15х15х1",
                "koef": "0.000078",
                "metrs": 1
            },
            {
                "size": " 15х15х1,2",
                "koef": "0.000093",
                "metrs": 1
            },
            {
                "size": " 15х15х1,5",
                "koef": "0.000115",
                "metrs": 1
            },
            {
                "size": " 15х15х2",
                "koef": "0.00015",
                "metrs": 1
            },
            {
                "size": " 20х10х1,2",
                "koef": "0.000093",
                "metrs": 1
            },
            {
                "size": " 20х20х1",
                "koef": "0.000105",
                "metrs": 1
            },
            {
                "size": " 20х20х1,2",
                "koef": "0.000125",
                "metrs": 1
            },
            {
                "size": " 20х20х1,5",
                "koef": "0.000155",
                "metrs": 1
            },
            {
                "size": " 20х20х2",
                "koef": "0.000204",
                "metrs": 1
            },
            {
                "size": " 25х10х3",
                "koef": "0.000257",
                "metrs": 1
            },
            {
                "size": " 25х25х1,2",
                "koef": "0.000157",
                "metrs": 1
            },
            {
                "size": " 25х25х1,5",
                "koef": "0.000195",
                "metrs": 1
            },
            {
                "size": " 25х25х2",
                "koef": "0.000257",
                "metrs": 1
            },
            {
                "size": " 25х25х3",
                "koef": "0.000378",
                "metrs": 1
            },
            {
                "size": " 25х25х4",
                "koef": "0.000493",
                "metrs": 1
            },
            {
                "size": " 30х15х1,5",
                "koef": "0.000175",
                "metrs": 1
            },
            {
                "size": " 30х15х2",
                "koef": "0.00023",
                "metrs": 1
            },
            {
                "size": " 30х20х2",
                "koef": "0.000257",
                "metrs": 1
            },
            {
                "size": " 30х20х3",
                "koef": "0.000378",
                "metrs": 1
            },
            {
                "size": " 30х30х1,2",
                "koef": "0.000189",
                "metrs": 1
            },
            {
                "size": " 30х30х1,5",
                "koef": "0.000235",
                "metrs": 1
            },
            {
                "size": " 30х30х2",
                "koef": "0.000311",
                "metrs": 1
            },
            {
                "size": " 30х30х3",
                "koef": "0.000458",
                "metrs": 1
            },
            {
                "size": " 32х19х2",
                "koef": "0.000263",
                "metrs": 1
            },
            {
                "size": " 35х35х2",
                "koef": "0.000364",
                "metrs": 1
            },
            {
                "size": " 35х35х3",
                "koef": "0.000539",
                "metrs": 1
            },
            {
                "size": " 35х35х4",
                "koef": "0.000708",
                "metrs": 1
            },
            {
                "size": " 38х22х2",
                "koef": "0.000311",
                "metrs": 1
            },
            {
                "size": " 40х20х2",
                "koef": "0.000311",
                "metrs": 1
            },
            {
                "size": " 40х25х2",
                "koef": "0.000338",
                "metrs": 1
            },
            {
                "size": " 40х25х3",
                "koef": "0.000498",
                "metrs": 1
            },
            {
                "size": " 40х25х4х3",
                "koef": "0.000598",
                "metrs": 1
            },
            {
                "size": " 40х30х3",
                "koef": "0.000539",
                "metrs": 1
            },
            {
                "size": " 40х40х1,8",
                "koef": "0.000377",
                "metrs": 1
            },
            {
                "size": " 40х40х2",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": " 40х40х3",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 40х40х4",
                "koef": "0.000815",
                "metrs": 1
            },
            {
                "size": " 45х45х1,8",
                "koef": "0.000425",
                "metrs": 1
            },
            {
                "size": " 45х45х5",
                "koef": "0.001139",
                "metrs": 1
            },
            {
                "size": " 50х20х2",
                "koef": "0.000364",
                "metrs": 1
            },
            {
                "size": " 50х25х3",
                "koef": "0.000579",
                "metrs": 1
            },
            {
                "size": " 50х30х2",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": " 50х30х3",
                "koef": "0.000619",
                "metrs": 1
            },
            {
                "size": " 50х30х3х4",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 50х30х4х3",
                "koef": "0.000745",
                "metrs": 1
            },
            {
                "size": " 50х50х1,5",
                "koef": "0.000396",
                "metrs": 1
            },
            {
                "size": " 50х50х2",
                "koef": "0.000525",
                "metrs": 1
            },
            {
                "size": " 50х50х3",
                "koef": "0.00078",
                "metrs": 1
            },
            {
                "size": " 50х50х4",
                "koef": "0.001029",
                "metrs": 1
            },
            {
                "size": " 50х50х5",
                "koef": "0.001273",
                "metrs": 1
            },
            {
                "size": " 60х20х2",
                "koef": "0.000418",
                "metrs": 1
            },
            {
                "size": " 60х30х2",
                "koef": "0.000472",
                "metrs": 1
            },
            {
                "size": " 60х40х2",
                "koef": "0.000525",
                "metrs": 1
            },
            {
                "size": " 60х40х3",
                "koef": "0.00078",
                "metrs": 1
            },
            {
                "size": " 60х40х4",
                "koef": "0.001029",
                "metrs": 1
            },
            {
                "size": " 60х60х2",
                "koef": "0.000632",
                "metrs": 1
            },
            {
                "size": " 60х60х3",
                "koef": "0.000941",
                "metrs": 1
            },
            {
                "size": " 60х60х5",
                "koef": "0.001541",
                "metrs": 1
            },
            {
                "size": " 70х70х2,5",
                "koef": "0.000921",
                "metrs": 1
            },
            {
                "size": " 70х70х5",
                "koef": "0.001809",
                "metrs": 1
            },
            {
                "size": " 75х50х5",
                "koef": "0.001608",
                "metrs": 1
            },
            {
                "size": " 80х20х2",
                "koef": "0.000525",
                "metrs": 1
            },
            {
                "size": " 80х40х3",
                "koef": "0.000941",
                "metrs": 1
            },
            {
                "size": " 80х80х3",
                "koef": "0.001262",
                "metrs": 1
            },
            {
                "size": " 80х80х6",
                "koef": "0.002476",
                "metrs": 1
            },
            {
                "size": " 80х80х8",
                "koef": "0.003259",
                "metrs": 1
            },
            {
                "size": " 100х40х3",
                "koef": "0.001101",
                "metrs": 1
            },
            {
                "size": " 100х50х5",
                "koef": "0.001943",
                "metrs": 1
            },
            {
                "size": " 100х100х6",
                "koef": "0.00312",
                "metrs": 1
            },
            {
                "size": " 100х100х10",
                "koef": "0.005092",
                "metrs": 1
            },
            {
                "size": " 120х40х3",
                "koef": "0.001262",
                "metrs": 1
            },
            {
                "size": " 150х40х3",
                "koef": "0.001503",
                "metrs": 1
            }
        ],
        "Швеллер гнутый": [
            {
                "size": "10х10х1,5",
                "koef": "0.000109",
                "metrs": 1
            },
            {
                "size": "10х10х10х1,2",
                "koef": "0.000089",
                "metrs": 1
            },
            {
                "size": "10х10х10х1,5",
                "koef": "0.000109",
                "metrs": 1
            },
            {
                "size": "10х15х1",
                "koef": "0.000102",
                "metrs": 1
            },
            {
                "size": "10х15х10х1,2",
                "koef": "0.000105",
                "metrs": 1
            },
            {
                "size": "15х12х2",
                "koef": "0.000188",
                "metrs": 1
            },
            {
                "size": "15х12х15х2",
                "koef": "0.000204",
                "metrs": 1
            },
            {
                "size": "15х15х1,5",
                "koef": "0.000169",
                "metrs": 1
            },
            {
                "size": "15х15х15х1,5",
                "koef": "0.000169",
                "metrs": 1
            },
            {
                "size": "15х20х15х2",
                "koef": "0.000247",
                "metrs": 1
            },
            {
                "size": "20х20х1,5",
                "koef": "0.000229",
                "metrs": 1
            },
            {
                "size": "20х20х2",
                "koef": "0.0003",
                "metrs": 1
            },
            {
                "size": "20х20х20х1,5",
                "koef": "0.000229",
                "metrs": 1
            },
            {
                "size": "20х20х20х2",
                "koef": "0.0003",
                "metrs": 1
            },
            {
                "size": "20х25х2",
                "koef": "0.000354",
                "metrs": 1
            },
            {
                "size": "20х25х20х2",
                "koef": "0.000327",
                "metrs": 1
            },
            {
                "size": "25х25х2",
                "koef": "0.000381",
                "metrs": 1
            },
            {
                "size": "25х40х25х1,5",
                "koef": "0.00035",
                "metrs": 1
            },
            {
                "size": "30х30х2",
                "koef": "0.000461",
                "metrs": 1
            },
            {
                "size": "30х30х30х1,5",
                "koef": "0.00035",
                "metrs": 1
            },
            {
                "size": "30х50х2",
                "koef": "0.000675",
                "metrs": 1
            },
            {
                "size": "30х50х4",
                "koef": "0.001308",
                "metrs": 1
            },
            {
                "size": "30х50х30х2",
                "koef": "0.000568",
                "metrs": 1
            },
            {
                "size": "30х50х30х4",
                "koef": "0.001093",
                "metrs": 1
            },
            {
                "size": "40х40х4",
                "koef": "0.001201",
                "metrs": 1
            },
            {
                "size": "40х60х40х5",
                "koef": "0.001742",
                "metrs": 1
            },
            {
                "size": "40х80х4",
                "koef": "0.002058",
                "metrs": 1
            },
            {
                "size": "40х80х40х4",
                "koef": "0.001629",
                "metrs": 1
            },
            {
                "size": "50х80х50х5",
                "koef": "0.002278",
                "metrs": 1
            },
            {
                "size": "50х100х50х5",
                "koef": "0.002546",
                "metrs": 1
            }
        ],
        "Шестигранник": [
            {
                "size": 8,
                "koef": "0.000149",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000334",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.000455",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.000671",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.000838",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.001123",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.002089",
                "metrs": 1
            }
        ],
        "Шина": [
            {
                "size": " 2х15",
                "koef": "0.00008",
                "metrs": 1
            },
            {
                "size": " 2х20",
                "koef": "0.000107",
                "metrs": 1
            },
            {
                "size": " 2х30",
                "koef": "0.000161",
                "metrs": 1
            },
            {
                "size": " 2х40",
                "koef": "0.000214",
                "metrs": 1
            },
            {
                "size": " 2х50",
                "koef": "0.000268",
                "metrs": 1
            },
            {
                "size": " 3х15",
                "koef": "0.000121",
                "metrs": 1
            },
            {
                "size": " 3х20",
                "koef": "0.000161",
                "metrs": 1
            },
            {
                "size": " 3х25",
                "koef": "0.000201",
                "metrs": 1
            },
            {
                "size": " 3х30",
                "koef": "0.000241",
                "metrs": 1
            },
            {
                "size": " 3х40",
                "koef": "0.000322",
                "metrs": 1
            },
            {
                "size": " 4х20",
                "koef": "0.000214",
                "metrs": 1
            },
            {
                "size": " 4х30",
                "koef": "0.000322",
                "metrs": 1
            },
            {
                "size": " 4х40",
                "koef": "0.000429",
                "metrs": 1
            },
            {
                "size": " 4х50",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": " 4х60",
                "koef": "0.000643",
                "metrs": 1
            },
            {
                "size": " 5х20",
                "koef": "0.000268",
                "metrs": 1
            },
            {
                "size": " 5х30",
                "koef": "0.000402",
                "metrs": 1
            },
            {
                "size": " 5х40",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": " 5х50",
                "koef": "0.00067",
                "metrs": 1
            },
            {
                "size": " 5х60",
                "koef": "0.000804",
                "metrs": 1
            },
            {
                "size": " 5х80",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": " 5х100",
                "koef": "0.00134",
                "metrs": 1
            },
            {
                "size": " 6х20",
                "koef": "0.000322",
                "metrs": 1
            },
            {
                "size": " 6х30",
                "koef": "0.000482",
                "metrs": 1
            },
            {
                "size": " 6х40",
                "koef": "0.000643",
                "metrs": 1
            },
            {
                "size": " 6х50",
                "koef": "0.000804",
                "metrs": 1
            },
            {
                "size": " 6х60",
                "koef": "0.000965",
                "metrs": 1
            },
            {
                "size": " 6х80",
                "koef": "0.001286",
                "metrs": 1
            },
            {
                "size": " 6х100",
                "koef": "0.001608",
                "metrs": 1
            },
            {
                "size": " 8х30",
                "koef": "0.000643",
                "metrs": 1
            },
            {
                "size": " 8х40",
                "koef": "0.000858",
                "metrs": 1
            },
            {
                "size": " 8х50",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": " 8х60",
                "koef": "0.001286",
                "metrs": 1
            },
            {
                "size": " 8х80",
                "koef": "0.001715",
                "metrs": 1
            },
            {
                "size": " 8х100",
                "koef": "0.002144",
                "metrs": 1
            },
            {
                "size": " 10х20",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": " 10х30",
                "koef": "0.000804",
                "metrs": 1
            },
            {
                "size": " 10х40",
                "koef": "0.001072",
                "metrs": 1
            },
            {
                "size": " 10х50",
                "koef": "0.00134",
                "metrs": 1
            },
            {
                "size": " 10х60",
                "koef": "0.001608",
                "metrs": 1
            },
            {
                "size": " 10х70",
                "koef": "0.001876",
                "metrs": 1
            },
            {
                "size": " 10х80",
                "koef": "0.002144",
                "metrs": 1
            },
            {
                "size": " 10х100",
                "koef": "0.00268",
                "metrs": 1
            },
            {
                "size": " 10х120",
                "koef": "0.003216",
                "metrs": 1
            },
            {
                "size": " 10х150",
                "koef": "0.00402",
                "metrs": 1
            },
            {
                "size": " 12х100",
                "koef": "0.003216",
                "metrs": 1
            },
            {
                "size": " 12х120",
                "koef": "0.003859",
                "metrs": 1
            }
        ]
    },
    "Бронза": {
        "Круг": [
            {
                "size": 8,
                "koef": "0.000377",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.000589",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000848",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001154",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.001508",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.001703",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.001909",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.002127",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.002356",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.002851",
                "metrs": 1
            },
            {
                "size": 23,
                "koef": "0.003116",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.003393",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.003681",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.004618",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.005302",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.006032",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.007216",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.007634",
                "metrs": 1
            },
            {
                "size": 38,
                "koef": "0.008506",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.009425",
                "metrs": 1
            },
            {
                "size": 42,
                "koef": "0.01039",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.01193",
                "metrs": 1
            },
            {
                "size": 48,
                "koef": "0.01357",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.01473",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.01782",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.02121",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0.02489",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.02886",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0.03313",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.0377",
                "metrs": 1
            },
            {
                "size": 85,
                "koef": "0.04256",
                "metrs": 1
            },
            {
                "size": 90,
                "koef": "0.04771",
                "metrs": 1
            },
            {
                "size": 95,
                "koef": "0.05316",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0.05891",
                "metrs": 1
            },
            {
                "size": 110,
                "koef": "0.07127",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "0.08482",
                "metrs": 1
            },
            {
                "size": 130,
                "koef": "0.09955",
                "metrs": 1
            },
            {
                "size": 140,
                "koef": "0.1155",
                "metrs": 1
            },
            {
                "size": 150,
                "koef": "0.1325",
                "metrs": 1
            },
            {
                "size": 160,
                "koef": "0.1508",
                "metrs": 1
            },
            {
                "size": 163,
                "koef": "0.1565",
                "metrs": 1
            },
            {
                "size": 170,
                "koef": "0.1702",
                "metrs": 1
            },
            {
                "size": 180,
                "koef": "0.1909",
                "metrs": 1
            },
            {
                "size": 190,
                "koef": "0.2126",
                "metrs": 1
            },
            {
                "size": 200,
                "koef": "0.2356",
                "metrs": 1
            },
            {
                "size": 210,
                "koef": "0.2598",
                "metrs": 1
            },
            {
                "size": 220,
                "koef": "0.2851",
                "metrs": 1
            },
            {
                "size": 240,
                "koef": "0.3393",
                "metrs": 1
            },
            {
                "size": 250,
                "koef": "0.3682",
                "metrs": 1
            },
            {
                "size": 260,
                "koef": "0.3982",
                "metrs": 1
            },
            {
                "size": 270,
                "koef": "0.4294",
                "metrs": 1
            },
            {
                "size": 280,
                "koef": "0.4618",
                "metrs": 1
            },
            {
                "size": 290,
                "koef": "0.4954",
                "metrs": 1
            },
            {
                "size": 300,
                "koef": "0.5301",
                "metrs": 1
            }
        ],
        "Лента/штрипс": [
            {
                "size": "0.5",
                "koef": "0.00375",
                "metrs": 1
            }
        ],
        "Проволока": [
            {
                "size": "0.2",
                "koef": "0.002356",
                "metrs": 10000
            },
            {
                "size": "0.3",
                "koef": "0.005301",
                "metrs": 10000
            },
            {
                "size": "0.5",
                "koef": "0.01473",
                "metrs": 10000
            },
            {
                "size": "0.6",
                "koef": "0.02121",
                "metrs": 10000
            },
            {
                "size": "0.8",
                "koef": "0.0377",
                "metrs": 10000
            },
            {
                "size": 1,
                "koef": "0.0589",
                "metrs": 10000
            },
            {
                "size": "1.2",
                "koef": "0.08482",
                "metrs": 10000
            },
            {
                "size": "1.5",
                "koef": "0.1325",
                "metrs": 10000
            },
            {
                "size": "1.6",
                "koef": "0.1508",
                "metrs": 10000
            },
            {
                "size": "1.8",
                "koef": "0.1909",
                "metrs": 10000
            },
            {
                "size": 2,
                "koef": "0.2356",
                "metrs": 10000
            },
            {
                "size": "2.5",
                "koef": "0.3682",
                "metrs": 10000
            },
            {
                "size": 3,
                "koef": "0.5301",
                "metrs": 10000
            },
            {
                "size": 4,
                "koef": "0.9425",
                "metrs": 10000
            },
            {
                "size": 5,
                "koef": "1.473",
                "metrs": 10000
            }
        ],
        "Труба": [
            {
                "size": "42х8,5",
                "koef": "0.006709",
                "metrs": 1
            },
            {
                "size": "45х7,5",
                "koef": "0.006627",
                "metrs": 1
            },
            {
                "size": "50х5",
                "koef": "0.005301",
                "metrs": 1
            },
            {
                "size": "50х7,5",
                "koef": "0.00751",
                "metrs": 1
            },
            {
                "size": "50х10",
                "koef": "0.009425",
                "metrs": 1
            },
            {
                "size": "50х11",
                "koef": "0.01011",
                "metrs": 1
            },
            {
                "size": "55х5",
                "koef": "0.00589",
                "metrs": 1
            },
            {
                "size": "55х7,5",
                "koef": "0.008394",
                "metrs": 1
            },
            {
                "size": "55х10",
                "koef": "0.0106",
                "metrs": 1
            },
            {
                "size": "55х12,5",
                "koef": "0.01252",
                "metrs": 1
            },
            {
                "size": "57х8,5",
                "koef": "0.009713",
                "metrs": 1
            },
            {
                "size": "60х5",
                "koef": "0.00648",
                "metrs": 1
            },
            {
                "size": "60х7,5",
                "koef": "0.009278",
                "metrs": 1
            },
            {
                "size": "60х10",
                "koef": "0.01178",
                "metrs": 1
            },
            {
                "size": "60х12,5",
                "koef": "0.01399",
                "metrs": 1
            },
            {
                "size": "60х15",
                "koef": "0.0159",
                "metrs": 1
            },
            {
                "size": "65х7,5",
                "koef": "0.01016",
                "metrs": 1
            },
            {
                "size": "65х10",
                "koef": "0.01296",
                "metrs": 1
            },
            {
                "size": "65х12,5",
                "koef": "0.01546",
                "metrs": 1
            },
            {
                "size": "65х17,5",
                "koef": "0.01959",
                "metrs": 1
            },
            {
                "size": "70х5",
                "koef": "0.007658",
                "metrs": 1
            },
            {
                "size": "70х7,5",
                "koef": "0.01104",
                "metrs": 1
            },
            {
                "size": "70х10",
                "koef": "0.01414",
                "metrs": 1
            },
            {
                "size": "70х12,5",
                "koef": "0.01694",
                "metrs": 1
            },
            {
                "size": "70х15",
                "koef": "0.01944",
                "metrs": 1
            },
            {
                "size": "70х17,5",
                "koef": "0.02165",
                "metrs": 1
            },
            {
                "size": "75х7,5",
                "koef": "0.01193",
                "metrs": 1
            },
            {
                "size": "75х10",
                "koef": "0.01532",
                "metrs": 1
            },
            {
                "size": "75х12,5",
                "koef": "0.01841",
                "metrs": 1
            },
            {
                "size": "75х15",
                "koef": "0.02121",
                "metrs": 1
            },
            {
                "size": "75х17,5",
                "koef": "0.02371",
                "metrs": 1
            },
            {
                "size": "80х7,5",
                "koef": "0.01281",
                "metrs": 1
            },
            {
                "size": "80х10",
                "koef": "0.01649",
                "metrs": 1
            },
            {
                "size": "80х12,5",
                "koef": "0.01988",
                "metrs": 1
            },
            {
                "size": "80х15",
                "koef": "0.02297",
                "metrs": 1
            },
            {
                "size": "80х17,5",
                "koef": "0.02577",
                "metrs": 1
            },
            {
                "size": "80х20",
                "koef": "0.02827",
                "metrs": 1
            },
            {
                "size": "80х25",
                "koef": "0.0324",
                "metrs": 1
            },
            {
                "size": "85х7,5",
                "koef": "0.0137",
                "metrs": 1
            },
            {
                "size": "85х10",
                "koef": "0.01767",
                "metrs": 1
            },
            {
                "size": "85х12,5",
                "koef": "0.02135",
                "metrs": 1
            },
            {
                "size": "85х15",
                "koef": "0.02474",
                "metrs": 1
            },
            {
                "size": "85х17,5",
                "koef": "0.02783",
                "metrs": 1
            },
            {
                "size": "85х20",
                "koef": "0.03063",
                "metrs": 1
            },
            {
                "size": "90х7,5",
                "koef": "0.01458",
                "metrs": 1
            },
            {
                "size": "90х10",
                "koef": "0.01885",
                "metrs": 1
            },
            {
                "size": "90х12,5",
                "koef": "0.02283",
                "metrs": 1
            },
            {
                "size": "90х15",
                "koef": "0.02651",
                "metrs": 1
            },
            {
                "size": "90х17,5",
                "koef": "0.02989",
                "metrs": 1
            },
            {
                "size": "90х20",
                "koef": "0.03299",
                "metrs": 1
            },
            {
                "size": "90х22,5",
                "koef": "0.03578",
                "metrs": 1
            },
            {
                "size": "90х25",
                "koef": "0.03829",
                "metrs": 1
            },
            {
                "size": "95х7,5",
                "koef": "0.01546",
                "metrs": 1
            },
            {
                "size": "95х10",
                "koef": "0.02003",
                "metrs": 1
            },
            {
                "size": "95х12,5",
                "koef": "0.0243",
                "metrs": 1
            },
            {
                "size": "95х15",
                "koef": "0.02827",
                "metrs": 1
            },
            {
                "size": "95х17,5",
                "koef": "0.03196",
                "metrs": 1
            },
            {
                "size": "95х20",
                "koef": "0.03534",
                "metrs": 1
            },
            {
                "size": "95х22,5",
                "koef": "0.03844",
                "metrs": 1
            },
            {
                "size": "95х25",
                "koef": "0.04123",
                "metrs": 1
            },
            {
                "size": "100х7,5",
                "koef": "0.01635",
                "metrs": 1
            },
            {
                "size": "100х10",
                "koef": "0.02121",
                "metrs": 1
            },
            {
                "size": "100х12,5",
                "koef": "0.02577",
                "metrs": 1
            },
            {
                "size": "100х15",
                "koef": "0.03004",
                "metrs": 1
            },
            {
                "size": "100х17,5",
                "koef": "0.03402",
                "metrs": 1
            },
            {
                "size": "100х20",
                "koef": "0.0377",
                "metrs": 1
            },
            {
                "size": " 100х22,5",
                "koef": "0.04109",
                "metrs": 1
            },
            {
                "size": "100х25",
                "koef": "0.04418",
                "metrs": 1
            },
            {
                "size": "100х30",
                "koef": "0.04948",
                "metrs": 1
            },
            {
                "size": "105х10",
                "koef": "0.02238",
                "metrs": 1
            },
            {
                "size": "105х12,5",
                "koef": "0.02724",
                "metrs": 1
            },
            {
                "size": "105х15",
                "koef": "0.03181",
                "metrs": 1
            },
            {
                "size": "105х17,5",
                "koef": "0.03608",
                "metrs": 1
            },
            {
                "size": "105х20",
                "koef": "0.04006",
                "metrs": 1
            },
            {
                "size": "105х22,5",
                "koef": "0.04374",
                "metrs": 1
            },
            {
                "size": "105х25",
                "koef": "0.04712",
                "metrs": 1
            },
            {
                "size": "105х30",
                "koef": "0.05301",
                "metrs": 1
            },
            {
                "size": "110х10",
                "koef": "0.02356",
                "metrs": 1
            },
            {
                "size": "110х12,5",
                "koef": "0.02872",
                "metrs": 1
            },
            {
                "size": "110х15",
                "koef": "0.03358",
                "metrs": 1
            },
            {
                "size": "110х17,5",
                "koef": "0.03814",
                "metrs": 1
            },
            {
                "size": "110х20",
                "koef": "0.04241",
                "metrs": 1
            },
            {
                "size": "110х22,5",
                "koef": "0.04639",
                "metrs": 1
            },
            {
                "size": "110х25",
                "koef": "0.05007",
                "metrs": 1
            },
            {
                "size": "110х30",
                "koef": "0.05655",
                "metrs": 1
            },
            {
                "size": "115х10",
                "koef": "0.02474",
                "metrs": 1
            },
            {
                "size": "115х12,5",
                "koef": "0.03019",
                "metrs": 1
            },
            {
                "size": "115х15",
                "koef": "0.03534",
                "metrs": 1
            },
            {
                "size": "115х17,5",
                "koef": "0.0402",
                "metrs": 1
            },
            {
                "size": "115х20",
                "koef": "0.04477",
                "metrs": 1
            },
            {
                "size": "115х22,5",
                "koef": "0.04904",
                "metrs": 1
            },
            {
                "size": "115х25",
                "koef": "0.05301",
                "metrs": 1
            },
            {
                "size": "115х30",
                "koef": "0.06008",
                "metrs": 1
            },
            {
                "size": "115х32,5",
                "koef": "0.06318",
                "metrs": 1
            },
            {
                "size": "120х12,5",
                "koef": "0.03166",
                "metrs": 1
            },
            {
                "size": "120х15",
                "koef": "0.03711",
                "metrs": 1
            },
            {
                "size": "120х17,5",
                "koef": "0.04226",
                "metrs": 1
            },
            {
                "size": "120х20",
                "koef": "0.04712",
                "metrs": 1
            },
            {
                "size": "120х22,5",
                "koef": "0.05169",
                "metrs": 1
            },
            {
                "size": "120х25",
                "koef": "0.05596",
                "metrs": 1
            },
            {
                "size": "120х27,5",
                "koef": "0.05994",
                "metrs": 1
            },
            {
                "size": "120х30",
                "koef": "0.06362",
                "metrs": 1
            },
            {
                "size": "120х32,5",
                "koef": "0.067",
                "metrs": 1
            },
            {
                "size": "120х37,5",
                "koef": "0.07289",
                "metrs": 1
            },
            {
                "size": "125х12,5",
                "koef": "0.03313",
                "metrs": 1
            },
            {
                "size": "125х15",
                "koef": "0.03888",
                "metrs": 1
            },
            {
                "size": "125х17,5",
                "koef": "0.04433",
                "metrs": 1
            },
            {
                "size": "125х20",
                "koef": "0.04948",
                "metrs": 1
            },
            {
                "size": "125х22,5",
                "koef": "0.05434",
                "metrs": 1
            },
            {
                "size": "125х25",
                "koef": "0.0589",
                "metrs": 1
            },
            {
                "size": "125х30",
                "koef": "0.06715",
                "metrs": 1
            },
            {
                "size": "125х32,5",
                "koef": "0.07083",
                "metrs": 1
            },
            {
                "size": "125х35",
                "koef": "0.07422",
                "metrs": 1
            },
            {
                "size": "130х15",
                "koef": "0.04064",
                "metrs": 1
            },
            {
                "size": "130х17,5",
                "koef": "0.04639",
                "metrs": 1
            },
            {
                "size": "130х20",
                "koef": "0.05184",
                "metrs": 1
            },
            {
                "size": "130х22,5",
                "koef": "0.05699",
                "metrs": 1
            },
            {
                "size": "130х25",
                "koef": "0.06185",
                "metrs": 1
            },
            {
                "size": "130х30",
                "koef": "0.07069",
                "metrs": 1
            },
            {
                "size": "130х32,5",
                "koef": "0.07466",
                "metrs": 1
            },
            {
                "size": "135х12,5",
                "koef": "0.03608",
                "metrs": 1
            },
            {
                "size": "135х15",
                "koef": "0.04241",
                "metrs": 1
            },
            {
                "size": "135х17,5",
                "koef": "0.04845",
                "metrs": 1
            },
            {
                "size": "135х20",
                "koef": "0.05419",
                "metrs": 1
            },
            {
                "size": "135х22,5",
                "koef": "0.05964",
                "metrs": 1
            },
            {
                "size": "135х25",
                "koef": "0.0648",
                "metrs": 1
            },
            {
                "size": "135х30",
                "koef": "0.07422",
                "metrs": 1
            },
            {
                "size": "135х32,5",
                "koef": "0.07849",
                "metrs": 1
            },
            {
                "size": "135х35",
                "koef": "0.08247",
                "metrs": 1
            },
            {
                "size": "135х37,5",
                "koef": "0.08615",
                "metrs": 1
            },
            {
                "size": "140х15",
                "koef": "0.04418",
                "metrs": 1
            },
            {
                "size": "140х17,5",
                "koef": "0.05051",
                "metrs": 1
            },
            {
                "size": "140х20",
                "koef": "0.05655",
                "metrs": 1
            },
            {
                "size": "140х22,5",
                "koef": "0.06229",
                "metrs": 1
            },
            {
                "size": "140х27,5",
                "koef": "0.07289",
                "metrs": 1
            },
            {
                "size": "140х30",
                "koef": "0.07775",
                "metrs": 1
            },
            {
                "size": "155х12,5",
                "koef": "0.04197",
                "metrs": 1
            },
            {
                "size": "155х15",
                "koef": "0.04948",
                "metrs": 1
            },
            {
                "size": "155х17,5",
                "koef": "0.0567",
                "metrs": 1
            },
            {
                "size": "155х20",
                "koef": "0.06362",
                "metrs": 1
            },
            {
                "size": "155х22,5",
                "koef": "0.07024",
                "metrs": 1
            },
            {
                "size": "155х32,5",
                "koef": "0.09381",
                "metrs": 1
            },
            {
                "size": "155х40",
                "koef": "0.1084",
                "metrs": 1
            },
            {
                "size": "160х15",
                "koef": "0.05125",
                "metrs": 1
            },
            {
                "size": "160х17,5",
                "koef": "0.05876",
                "metrs": 1
            },
            {
                "size": "160х20",
                "koef": "0.06597",
                "metrs": 1
            },
            {
                "size": "160х25",
                "koef": "0.07952",
                "metrs": 1
            },
            {
                "size": "160х30",
                "koef": "0.09189",
                "metrs": 1
            },
            {
                "size": "160х42,5",
                "koef": "0.1177",
                "metrs": 1
            },
            {
                "size": "165х27,5",
                "koef": "0.08909",
                "metrs": 1
            },
            {
                "size": "170х22,5",
                "koef": "0.0782",
                "metrs": 1
            },
            {
                "size": "175х12,5",
                "koef": "0.04786",
                "metrs": 1
            },
            {
                "size": "175х15",
                "koef": "0.05655",
                "metrs": 1
            },
            {
                "size": "175х17,5",
                "koef": "0.06494",
                "metrs": 1
            },
            {
                "size": "175х22,5",
                "koef": "0.08085",
                "metrs": 1
            },
            {
                "size": "175х32,5",
                "koef": "0.1091",
                "metrs": 1
            },
            {
                "size": "175х37,5",
                "koef": "0.1215",
                "metrs": 1
            },
            {
                "size": "175х50",
                "koef": "0.1473",
                "metrs": 1
            },
            {
                "size": "180х60",
                "koef": "0.1696",
                "metrs": 1
            },
            {
                "size": "190х20",
                "koef": "0.08011",
                "metrs": 1
            },
            {
                "size": "190х25",
                "koef": "0.09719",
                "metrs": 1
            },
            {
                "size": "190х30",
                "koef": "0.1131",
                "metrs": 1
            },
            {
                "size": "190х40",
                "koef": "0.1414",
                "metrs": 1
            },
            {
                "size": "195х22,5",
                "koef": "0.09145",
                "metrs": 1
            },
            {
                "size": "200х20",
                "koef": "0.08482",
                "metrs": 1
            },
            {
                "size": "205х15",
                "koef": "0.06715",
                "metrs": 1
            },
            {
                "size": "205х22,5",
                "koef": "0.09675",
                "metrs": 1
            },
            {
                "size": "205х32,5",
                "koef": "0.1321",
                "metrs": 1
            },
            {
                "size": "205х37,5",
                "koef": "0.148",
                "metrs": 1
            },
            {
                "size": "205х42,5",
                "koef": "0.1627",
                "metrs": 1
            },
            {
                "size": "205х50",
                "koef": "0.1826",
                "metrs": 1
            },
            {
                "size": "205х60",
                "koef": "0.205",
                "metrs": 1
            },
            {
                "size": "210х25",
                "koef": "0.109",
                "metrs": 1
            },
            {
                "size": "220х30",
                "koef": "0.1343",
                "metrs": 1
            },
            {
                "size": "220х35",
                "koef": "0.1526",
                "metrs": 1
            },
            {
                "size": "220х50",
                "koef": "0.2003",
                "metrs": 1
            },
            {
                "size": "225х22,5",
                "koef": "0.1074",
                "metrs": 1
            },
            {
                "size": "235х27,5",
                "koef": "0.1345",
                "metrs": 1
            },
            {
                "size": "240х15",
                "koef": "0.07952",
                "metrs": 1
            },
            {
                "size": "240х30",
                "koef": "0.1484",
                "metrs": 1
            },
            {
                "size": "240х35",
                "koef": "0.1691",
                "metrs": 1
            },
            {
                "size": "250х30",
                "koef": "0.1555",
                "metrs": 1
            },
            {
                "size": "250х35",
                "koef": "0.1773",
                "metrs": 1
            },
            {
                "size": "250х50",
                "koef": "0.2356",
                "metrs": 1
            },
            {
                "size": "260х30",
                "koef": "0.1626",
                "metrs": 1
            },
            {
                "size": "265х22,5",
                "koef": "0.1286",
                "metrs": 1
            },
            {
                "size": "280х35",
                "koef": "0.202",
                "metrs": 1
            }
        ]
    },
    "Латунь": {
        "Квадрат": [
            {
                "size": 5,
                "koef": "0.000211",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.000303",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.00054",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.000843",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.001214",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0.001425",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001652",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.001897",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.002436",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.003043",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.003372",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.00408",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.004856",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.005269",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0.006145",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.007587",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.01033",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.01349",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.02108",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.03035",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.04131",
                "metrs": 1
            }
        ],
        "Круг": [
            {
                "size": 3,
                "koef": "0.000059",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.000106",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.000165",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.000238",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.000324",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.000662",
                "metrs": 1
            },
            {
                "size": "10.5",
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0.000801",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.000954",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0.001119",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001297",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.001489",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.001695",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.001914",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.002146",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.00239",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.002648",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.003204",
                "metrs": 1
            },
            {
                "size": 23,
                "koef": "0.003502",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.003813",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.004138",
                "metrs": 1
            },
            {
                "size": 26,
                "koef": "0.004476",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0.004827",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.005191",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.005959",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.006779",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.008111",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.00858",
                "metrs": 1
            },
            {
                "size": 38,
                "koef": "0.009561",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.01059",
                "metrs": 1
            },
            {
                "size": 42,
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.01341",
                "metrs": 1
            },
            {
                "size": 48,
                "koef": "0.01525",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.01655",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.02003",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.02383",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0.02797",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.03244",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0.03724",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.04237",
                "metrs": 1
            },
            {
                "size": 85,
                "koef": "0.04784",
                "metrs": 1
            },
            {
                "size": 90,
                "koef": "0.05363",
                "metrs": 1
            },
            {
                "size": 95,
                "koef": "0.05975",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0.06621",
                "metrs": 1
            },
            {
                "size": 110,
                "koef": "0.08011",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "0.09534",
                "metrs": 1
            },
            {
                "size": 130,
                "koef": "0.1119",
                "metrs": 1
            },
            {
                "size": 140,
                "koef": "0.1298",
                "metrs": 1
            },
            {
                "size": 150,
                "koef": "0.149",
                "metrs": 1
            },
            {
                "size": 160,
                "koef": "0.1695",
                "metrs": 1
            },
            {
                "size": 163,
                "koef": "0.1759",
                "metrs": 1
            },
            {
                "size": 200,
                "koef": "0.2648",
                "metrs": 1
            }
        ],
        "Лента/штрипс": [
            {
                "size": "0.1",
                "koef": "0.000843",
                "metrs": 1
            },
            {
                "size": "0.2",
                "koef": "0.001686",
                "metrs": 1
            },
            {
                "size": "0.3",
                "koef": "0.002529",
                "metrs": 1
            },
            {
                "size": "0.4",
                "koef": "0.003372",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0.004215",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0.005058",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0.005901",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.006744",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.02529",
                "metrs": 1
            }
        ],
        "Лист": [
            {
                "size": "0.4",
                "koef": "0.000002",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0.004215",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0.005058",
                "metrs": 1
            },
            {
                "size": "0.7",
                "koef": "0.005901",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.006744",
                "metrs": 1
            },
            {
                "size": "0.9",
                "koef": "0.007587",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.00843",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.01012",
                "metrs": 1
            },
            {
                "size": "1.4",
                "koef": "0.0118",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.01265",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0.01517",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.01686",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0.02108",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.02529",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0.02951",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.03372",
                "metrs": 1
            },
            {
                "size": "4.5",
                "koef": "0.03794",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.04215",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.05058",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.05901",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.06744",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.0843",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.1012",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.118",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.1264",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.1349",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.1517",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.1686",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.1855",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.2108",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.2529",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.2951",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.3372",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.3793",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.4215",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.5058",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0.843",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "1.012",
                "metrs": 1
            }
        ],
        "Плита": [
            {
                "size": 30,
                "koef": "0.2529",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.2951",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.4215",
                "metrs": 1
            }
        ],
        "Проволока": [
            {
                "size": "0.2",
                "koef": "0.002648",
                "metrs": 10000
            },
            {
                "size": "0.25",
                "koef": "0.004138",
                "metrs": 10000
            },
            {
                "size": "0.3",
                "koef": "0.005959",
                "metrs": 10000
            },
            {
                "size": "0.5",
                "koef": "0.01655",
                "metrs": 10000
            },
            {
                "size": "0.6",
                "koef": "0.02384",
                "metrs": 10000
            },
            {
                "size": "0.8",
                "koef": "0.04237",
                "metrs": 10000
            },
            {
                "size": 1,
                "koef": "0.06621",
                "metrs": 10000
            },
            {
                "size": "1.2",
                "koef": "0.09534",
                "metrs": 10000
            },
            {
                "size": "1.5",
                "koef": "0.149",
                "metrs": 10000
            },
            {
                "size": "1.6",
                "koef": "0.1695",
                "metrs": 10000
            },
            {
                "size": "1.8",
                "koef": "0.2145",
                "metrs": 10000
            },
            {
                "size": 2,
                "koef": "0.2648",
                "metrs": 10000
            },
            {
                "size": "2.5",
                "koef": "0.4138",
                "metrs": 10000
            },
            {
                "size": 3,
                "koef": "0.5959",
                "metrs": 10000
            },
            {
                "size": "3.6",
                "koef": "0.8111",
                "metrs": 10000
            },
            {
                "size": 4,
                "koef": "1.059",
                "metrs": 10000
            },
            {
                "size": 5,
                "koef": "1.655",
                "metrs": 10000
            },
            {
                "size": 6,
                "koef": "2.384",
                "metrs": 10000
            },
            {
                "size": 8,
                "koef": "4.237",
                "metrs": 10000
            }
        ],
        "Труба": [
            {
                "size": " 4х1",
                "koef": "0.000079",
                "metrs": 1
            },
            {
                "size": " 5х1",
                "koef": "0.000106",
                "metrs": 1
            },
            {
                "size": " 6х1",
                "koef": "0.000132",
                "metrs": 1
            },
            {
                "size": " 8х1",
                "koef": "0.000185",
                "metrs": 1
            },
            {
                "size": " 8х1,5",
                "koef": "0.000258",
                "metrs": 1
            },
            {
                "size": " 8х2",
                "koef": "0.000318",
                "metrs": 1
            },
            {
                "size": " 10х1",
                "koef": "0.000238",
                "metrs": 1
            },
            {
                "size": " 10х1,5",
                "koef": "0.000338",
                "metrs": 1
            },
            {
                "size": " 10х2",
                "koef": "0.000424",
                "metrs": 1
            },
            {
                "size": " 12х1",
                "koef": "0.000291",
                "metrs": 1
            },
            {
                "size": " 12х1,5",
                "koef": "0.000417",
                "metrs": 1
            },
            {
                "size": " 12х2",
                "koef": "0.00053",
                "metrs": 1
            },
            {
                "size": " 14х1",
                "koef": "0.000344",
                "metrs": 1
            },
            {
                "size": " 14х1,5",
                "koef": "0.000497",
                "metrs": 1
            },
            {
                "size": " 14х2",
                "koef": "0.000636",
                "metrs": 1
            },
            {
                "size": " 15х3",
                "koef": "0.000953",
                "metrs": 1
            },
            {
                "size": " 16х1",
                "koef": "0.000397",
                "metrs": 1
            },
            {
                "size": " 16х1,5",
                "koef": "0.000576",
                "metrs": 1
            },
            {
                "size": " 16х2",
                "koef": "0.000742",
                "metrs": 1
            },
            {
                "size": " 18х1",
                "koef": "0.00045",
                "metrs": 1
            },
            {
                "size": " 18х1,5",
                "koef": "0.000655",
                "metrs": 1
            },
            {
                "size": " 18х2",
                "koef": "0.000847",
                "metrs": 1
            },
            {
                "size": " 18х3",
                "koef": "0.001192",
                "metrs": 1
            },
            {
                "size": " 19х1",
                "koef": "0.000477",
                "metrs": 1
            },
            {
                "size": " 20х1",
                "koef": "0.000503",
                "metrs": 1
            },
            {
                "size": " 20х2",
                "koef": "0.000953",
                "metrs": 1
            },
            {
                "size": " 22х2",
                "koef": "0.001059",
                "metrs": 1
            },
            {
                "size": " 24х1",
                "koef": "0.000609",
                "metrs": 1
            },
            {
                "size": " 25х1",
                "koef": "0.000636",
                "metrs": 1
            },
            {
                "size": " 25х2",
                "koef": "0.001218",
                "metrs": 1
            },
            {
                "size": " 27х2",
                "koef": "0.001324",
                "metrs": 1
            },
            {
                "size": " 30х2",
                "koef": "0.001483",
                "metrs": 1
            },
            {
                "size": " 35х2",
                "koef": "0.001748",
                "metrs": 1
            },
            {
                "size": " 38х1,5",
                "koef": "0.00145",
                "metrs": 1
            },
            {
                "size": " 40х1,5",
                "koef": "0.001529",
                "metrs": 1
            },
            {
                "size": " 60х2",
                "koef": "0.003072",
                "metrs": 1
            }
        ],
        "Шестигранник": [
            {
                "size": 5,
                "koef": "0.000183",
                "metrs": 1
            },
            {
                "size": "5.5",
                "koef": "0.000221",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.000263",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.000358",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.000467",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0.000591",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.00073",
                "metrs": 1
            },
            {
                "size": 11,
                "koef": "0.000883",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.001051",
                "metrs": 1
            },
            {
                "size": 13,
                "koef": "0.001234",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001431",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.001643",
                "metrs": 1
            },
            {
                "size": 17,
                "koef": "0.00211",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.002365",
                "metrs": 1
            },
            {
                "size": 19,
                "koef": "0.002636",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.00292",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.003533",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.004205",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0.005322",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.005724",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.006571",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.007476",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.008943",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.009462",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.01168",
                "metrs": 1
            },
            {
                "size": 41,
                "koef": "0.01227",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.01478",
                "metrs": 1
            },
            {
                "size": 46,
                "koef": "0.01545",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.01825",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.02208",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.02628",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0.03085",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.03577",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0.04107",
                "metrs": 1
            }
        ]
    },
    "Медь": {
        "Круг": [
            {
                "size": 5,
                "koef": "0.000174",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.000251",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.000342",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.000447",
                "metrs": 1
            },
            {
                "size": 9,
                "koef": "0.000565",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.000698",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.001006",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.001368",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.001571",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.001787",
                "metrs": 1
            },
            {
                "size": 18,
                "koef": "0.002263",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.002793",
                "metrs": 1
            },
            {
                "size": 22,
                "koef": "0.003379",
                "metrs": 1
            },
            {
                "size": 24,
                "koef": "0.004021",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.004363",
                "metrs": 1
            },
            {
                "size": 26,
                "koef": "0.00472",
                "metrs": 1
            },
            {
                "size": 27,
                "koef": "0.005091",
                "metrs": 1
            },
            {
                "size": 28,
                "koef": "0.005474",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.006284",
                "metrs": 1
            },
            {
                "size": 32,
                "koef": "0.007149",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.008554",
                "metrs": 1
            },
            {
                "size": 36,
                "koef": "0.009049",
                "metrs": 1
            },
            {
                "size": 38,
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.01117",
                "metrs": 1
            },
            {
                "size": 42,
                "koef": "0.01232",
                "metrs": 1
            },
            {
                "size": 45,
                "koef": "0.01414",
                "metrs": 1
            },
            {
                "size": 48,
                "koef": "0.01609",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.01745",
                "metrs": 1
            },
            {
                "size": 55,
                "koef": "0.02112",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.02514",
                "metrs": 1
            },
            {
                "size": 65,
                "koef": "0.0295",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.03421",
                "metrs": 1
            },
            {
                "size": 75,
                "koef": "0.03927",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.04469",
                "metrs": 1
            },
            {
                "size": 85,
                "koef": "0.05045",
                "metrs": 1
            },
            {
                "size": 90,
                "koef": "0.05656",
                "metrs": 1
            },
            {
                "size": 100,
                "koef": "0.06982",
                "metrs": 1
            },
            {
                "size": 110,
                "koef": "0.08448",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "0.1005",
                "metrs": 1
            },
            {
                "size": 130,
                "koef": "0.118",
                "metrs": 1
            },
            {
                "size": 140,
                "koef": "0.1369",
                "metrs": 1
            },
            {
                "size": 150,
                "koef": "0.1571",
                "metrs": 1
            },
            {
                "size": 160,
                "koef": "0.1787",
                "metrs": 1
            }
        ],
        "Лента/штрипс": [
            {
                "size": "0.1",
                "koef": "0.000889",
                "metrs": 1
            },
            {
                "size": "0.18",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": "0.2",
                "koef": "0.001778",
                "metrs": 1
            },
            {
                "size": "0.25",
                "koef": "0.002223",
                "metrs": 1
            },
            {
                "size": "0.3",
                "koef": "0.002667",
                "metrs": 1
            },
            {
                "size": "0.4",
                "koef": "0.003556",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0.004445",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0.005334",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.007112",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.00889",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.02667",
                "metrs": 1
            }
        ],
        "Лист": [
            {
                "size": "0.4",
                "koef": "0.003556",
                "metrs": 1
            },
            {
                "size": "0.5",
                "koef": "0.004445",
                "metrs": 1
            },
            {
                "size": "0.6",
                "koef": "0.005334",
                "metrs": 1
            },
            {
                "size": "0.8",
                "koef": "0.007112",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.00889",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.01067",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.01334",
                "metrs": 1
            },
            {
                "size": "1.8",
                "koef": "0.016",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.01778",
                "metrs": 1
            },
            {
                "size": "2.5",
                "koef": "0.02223",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.02667",
                "metrs": 1
            },
            {
                "size": "3.5",
                "koef": "0.03112",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.03556",
                "metrs": 1
            },
            {
                "size": "4.5",
                "koef": "0.04001",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.04445",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.05334",
                "metrs": 1
            },
            {
                "size": 7,
                "koef": "0.06223",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.07112",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.0889",
                "metrs": 1
            },
            {
                "size": 12,
                "koef": "0.1067",
                "metrs": 1
            },
            {
                "size": 14,
                "koef": "0.1245",
                "metrs": 1
            },
            {
                "size": 15,
                "koef": "0.1334",
                "metrs": 1
            },
            {
                "size": 16,
                "koef": "0.1422",
                "metrs": 1
            },
            {
                "size": 20,
                "koef": "0.1778",
                "metrs": 1
            },
            {
                "size": 25,
                "koef": "0.2223",
                "metrs": 1
            },
            {
                "size": 30,
                "koef": "0.2667",
                "metrs": 1
            },
            {
                "size": 35,
                "koef": "0.3112",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.3556",
                "metrs": 1
            },
            {
                "size": 50,
                "koef": "0.4445",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.5334",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.6223",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.7112",
                "metrs": 1
            },
            {
                "size": 120,
                "koef": "1.067",
                "metrs": 1
            }
        ],
        "Плита": [
            {
                "size": 30,
                "koef": "0.2667",
                "metrs": 1
            },
            {
                "size": 40,
                "koef": "0.3556",
                "metrs": 1
            },
            {
                "size": 60,
                "koef": "0.5334",
                "metrs": 1
            },
            {
                "size": 70,
                "koef": "0.6223",
                "metrs": 1
            },
            {
                "size": 80,
                "koef": "0.7112",
                "metrs": 1
            }
        ],
        "Проволока": [
            {
                "size": "0.2",
                "koef": "0.002793",
                "metrs": 10000
            },
            {
                "size": "0.3",
                "koef": "0.006284",
                "metrs": 10000
            },
            {
                "size": "0.4",
                "koef": "0.01117",
                "metrs": 10000
            },
            {
                "size": "0.5",
                "koef": "0.01746",
                "metrs": 10000
            },
            {
                "size": "0.6",
                "koef": "0.02514",
                "metrs": 10000
            },
            {
                "size": "0.8",
                "koef": "0.04469",
                "metrs": 10000
            },
            {
                "size": 1,
                "koef": "0.06982",
                "metrs": 10000
            },
            {
                "size": "1.2",
                "koef": "0.1005",
                "metrs": 10000
            },
            {
                "size": "1.38",
                "koef": "0.133",
                "metrs": 10000
            },
            {
                "size": "1.5",
                "koef": "0.1571",
                "metrs": 10000
            },
            {
                "size": "1.6",
                "koef": "0,1787",
                "metrs": 10000
            },
            {
                "size": "1.8",
                "koef": "0,2262",
                "metrs": 10000
            },
            {
                "size": 2,
                "koef": "0,2793",
                "metrs": 10000
            },
            {
                "size": "2.5",
                "koef": "0,4364",
                "metrs": 10000
            },
            {
                "size": 3,
                "koef": "0,6284",
                "metrs": 10000
            },
            {
                "size": "3.15",
                "koef": "0,6928",
                "metrs": 10000
            },
            {
                "size": 4,
                "koef": "1.117",
                "metrs": 10000
            },
            {
                "size": 5,
                "koef": "1.746",
                "metrs": 10000
            },
            {
                "size": 6,
                "koef": "2.514",
                "metrs": 10000
            }
        ],
        "Труба": [
            {
                "size": " 4х0,5",
                "koef": "0.000049",
                "metrs": 1
            },
            {
                "size": " 4х1",
                "koef": "0.000084",
                "metrs": 1
            },
            {
                "size": " 4,76х0,8",
                "koef": "0.000088",
                "metrs": 1
            },
            {
                "size": " 5х0,5",
                "koef": "0.000063",
                "metrs": 1
            },
            {
                "size": " 5х0,8",
                "koef": "0.000094",
                "metrs": 1
            },
            {
                "size": " 5х1",
                "koef": "0.000112",
                "metrs": 1
            },
            {
                "size": " 6х0,8",
                "koef": "0.000116",
                "metrs": 1
            },
            {
                "size": " 6х1",
                "koef": "0.00014",
                "metrs": 1
            },
            {
                "size": " 6х2",
                "koef": "0.000223",
                "metrs": 1
            },
            {
                "size": " 6,35х0,5",
                "koef": "0.000082",
                "metrs": 1
            },
            {
                "size": " 6,35х0,6",
                "koef": "0.000096",
                "metrs": 1
            },
            {
                "size": " 6,35х0,76",
                "koef": "0.000119",
                "metrs": 1
            },
            {
                "size": " 8х0,8",
                "koef": "0.000161",
                "metrs": 1
            },
            {
                "size": " 8х1",
                "koef": "0.000196",
                "metrs": 1
            },
            {
                "size": " 8х1,5",
                "koef": "0.000272",
                "metrs": 1
            },
            {
                "size": " 8х2",
                "koef": "0.000335",
                "metrs": 1
            },
            {
                "size": " 9,52х0,6",
                "koef": "0.000149",
                "metrs": 1
            },
            {
                "size": " 9,52х0,65",
                "koef": "0.000161",
                "metrs": 1
            },
            {
                "size": " 9,52х0,81",
                "koef": "0.000197",
                "metrs": 1
            },
            {
                "size": " 10х0,8",
                "koef": "0.000206",
                "metrs": 1
            },
            {
                "size": " 10х1",
                "koef": "0.000251",
                "metrs": 1
            },
            {
                "size": " 10х1,5",
                "koef": "0.000356",
                "metrs": 1
            },
            {
                "size": " 10х2",
                "koef": "0.000447",
                "metrs": 1
            },
            {
                "size": " 12х1",
                "koef": "0.000307",
                "metrs": 1
            },
            {
                "size": " 12х1,5",
                "koef": "0.00044",
                "metrs": 1
            },
            {
                "size": " 12х2",
                "koef": "0.000559",
                "metrs": 1
            },
            {
                "size": " 12,7х0,6",
                "koef": "0.000203",
                "metrs": 1
            },
            {
                "size": " 12,7х0,65",
                "koef": "0.000219",
                "metrs": 1
            },
            {
                "size": " 12,7х0,7",
                "koef": "0.000235",
                "metrs": 1
            },
            {
                "size": " 12,7х0,81",
                "koef": "0.000269",
                "metrs": 1
            },
            {
                "size": " 14х0,8",
                "koef": "0.000295",
                "metrs": 1
            },
            {
                "size": " 14х1",
                "koef": "0.000363",
                "metrs": 1
            },
            {
                "size": " 14х1,5",
                "koef": "0.000524",
                "metrs": 1
            },
            {
                "size": " 14х2",
                "koef": "0.00067",
                "metrs": 1
            },
            {
                "size": " 15х1",
                "koef": "0.000391",
                "metrs": 1
            },
            {
                "size": " 15,87х0,75",
                "koef": "0.000317",
                "metrs": 1
            },
            {
                "size": " 15,87х0,89",
                "koef": "0.000372",
                "metrs": 1
            },
            {
                "size": " 15,88х0,7",
                "koef": "0.000297",
                "metrs": 1
            },
            {
                "size": " 16х0,8",
                "koef": "0.00034",
                "metrs": 1
            },
            {
                "size": " 16х1",
                "koef": "0.000419",
                "metrs": 1
            },
            {
                "size": " 16х1,5",
                "koef": "0.000607",
                "metrs": 1
            },
            {
                "size": " 16х2",
                "koef": "0.000782",
                "metrs": 1
            },
            {
                "size": " 16х3",
                "koef": "0.001089",
                "metrs": 1
            },
            {
                "size": " 18х1",
                "koef": "0.000475",
                "metrs": 1
            },
            {
                "size": " 18х1,5",
                "koef": "0.000691",
                "metrs": 1
            },
            {
                "size": " 18х2",
                "koef": "0.000894",
                "metrs": 1
            },
            {
                "size": " 18х4",
                "koef": "0.001564",
                "metrs": 1
            },
            {
                "size": " 19х1,5",
                "koef": "0.000733",
                "metrs": 1
            },
            {
                "size": " 19х2",
                "koef": "0.00095",
                "metrs": 1
            },
            {
                "size": " 19,05х0,8",
                "koef": "0.000408",
                "metrs": 1
            },
            {
                "size": " 19,05х0,89",
                "koef": "0.000451",
                "metrs": 1
            },
            {
                "size": " 20х1",
                "koef": "0.000531",
                "metrs": 1
            },
            {
                "size": " 20х1,5",
                "koef": "0.000775",
                "metrs": 1
            },
            {
                "size": " 20х2",
                "koef": "0.001005",
                "metrs": 1
            },
            {
                "size": " 20х3",
                "koef": "0.001424",
                "metrs": 1
            },
            {
                "size": " 22х1",
                "koef": "0.000587",
                "metrs": 1
            },
            {
                "size": " 22х1,2",
                "koef": "0.000697",
                "metrs": 1
            },
            {
                "size": " 22х1,5",
                "koef": "0.000859",
                "metrs": 1
            },
            {
                "size": " 22х2",
                "koef": "0.001117",
                "metrs": 1
            },
            {
                "size": " 22х2,5",
                "koef": "0.001362",
                "metrs": 1
            },
            {
                "size": " 22,22х0,9",
                "koef": "0.000536",
                "metrs": 1
            },
            {
                "size": " 25х2",
                "koef": "0.001285",
                "metrs": 1
            },
            {
                "size": " 28х1,2",
                "koef": "0.000898",
                "metrs": 1
            },
            {
                "size": " 28х2",
                "koef": "0.001452",
                "metrs": 1
            },
            {
                "size": " 28х5",
                "koef": "0.003212",
                "metrs": 1
            },
            {
                "size": " 28,57х1",
                "koef": "0.00077",
                "metrs": 1
            },
            {
                "size": " 28,57х1,27",
                "koef": "0.000968",
                "metrs": 1
            },
            {
                "size": " 30х2",
                "koef": "0.001564",
                "metrs": 1
            },
            {
                "size": " 30х3",
                "koef": "0.002262",
                "metrs": 1
            },
            {
                "size": " 32х2",
                "koef": "0.001676",
                "metrs": 1
            },
            {
                "size": " 35х1,5",
                "koef": "0.001403",
                "metrs": 1
            },
            {
                "size": " 38х2,5",
                "koef": "0.002479",
                "metrs": 1
            },
            {
                "size": " 38х3",
                "koef": "0.002933",
                "metrs": 1
            },
            {
                "size": " 42х1,5",
                "koef": "0.001697",
                "metrs": 1
            },
            {
                "size": " 45х2,5",
                "koef": "0.002967",
                "metrs": 1
            },
            {
                "size": " 55х2,5",
                "koef": "0.003666",
                "metrs": 1
            },
            {
                "size": " 75х2,5",
                "koef": "0.005062",
                "metrs": 1
            },
            {
                "size": " 85х2,5",
                "koef": "0.00576",
                "metrs": 1
            },
            {
                "size": " 105х2,5",
                "koef": "0.007157",
                "metrs": 1
            },
            {
                "size": " 110х5",
                "koef": "0.01466",
                "metrs": 1
            },
            {
                "size": " 130х2,5",
                "koef": "0.008902",
                "metrs": 1
            },
            {
                "size": " 130х3",
                "koef": "0.01064",
                "metrs": 1
            },
            {
                "size": " 135х5",
                "koef": "0.01815",
                "metrs": 1
            },
            {
                "size": " 155х2,5",
                "koef": "0.01065",
                "metrs": 1
            },
            {
                "size": " 156х3",
                "koef": "0.01282",
                "metrs": 1
            },
            {
                "size": " 160х5",
                "koef": "0.02164",
                "metrs": 1
            },
            {
                "size": " 206х3,5",
                "koef": "0.01979",
                "metrs": 1
            },
            {
                "size": " 258х4",
                "koef": "0.02838",
                "metrs": 1
            }
        ],
        "Шина": [
            {
                "size": " 2х20",
                "koef": "0.000356",
                "metrs": 1
            },
            {
                "size": " 3х15",
                "koef": "0.0004",
                "metrs": 1
            },
            {
                "size": " 3х20",
                "koef": "0.000533",
                "metrs": 1
            },
            {
                "size": " 3х25",
                "koef": "0.000667",
                "metrs": 1
            },
            {
                "size": " 3х30",
                "koef": "0.0008",
                "metrs": 1
            },
            {
                "size": " 3х40",
                "koef": "0.001067",
                "metrs": 1
            },
            {
                "size": " 3х50",
                "koef": "0.001334",
                "metrs": 1
            },
            {
                "size": " 4х20",
                "koef": "0.000711",
                "metrs": 1
            },
            {
                "size": " 4х25",
                "koef": "0.000889",
                "metrs": 1
            },
            {
                "size": " 4х30",
                "koef": "0.001067",
                "metrs": 1
            },
            {
                "size": " 4х40",
                "koef": "0.001422",
                "metrs": 1
            },
            {
                "size": " 4х50",
                "koef": "0.001778",
                "metrs": 1
            },
            {
                "size": " 4х60",
                "koef": "0.002134",
                "metrs": 1
            },
            {
                "size": " 5х20",
                "koef": "0.000889",
                "metrs": 1
            },
            {
                "size": " 5х25",
                "koef": "0.001111",
                "metrs": 1
            },
            {
                "size": " 5х30",
                "koef": "0.001334",
                "metrs": 1
            },
            {
                "size": " 5х40",
                "koef": "0.001778",
                "metrs": 1
            },
            {
                "size": " 5х50",
                "koef": "0.002223",
                "metrs": 1
            },
            {
                "size": " 5х60",
                "koef": "0.002667",
                "metrs": 1
            },
            {
                "size": " 5х80",
                "koef": "0.003556",
                "metrs": 1
            },
            {
                "size": " 5х100",
                "koef": "0.004445",
                "metrs": 1
            },
            {
                "size": " 6х20",
                "koef": "0.001067",
                "metrs": 1
            },
            {
                "size": " 6х30",
                "koef": "0.0016",
                "metrs": 1
            },
            {
                "size": " 6х35",
                "koef": "0.001867",
                "metrs": 1
            },
            {
                "size": " 6х40",
                "koef": "0.002134",
                "metrs": 1
            },
            {
                "size": " 6х50",
                "koef": "0.002667",
                "metrs": 1
            },
            {
                "size": " 6х60",
                "koef": "0.0032",
                "metrs": 1
            },
            {
                "size": " 6х80",
                "koef": "0.004267",
                "metrs": 1
            },
            {
                "size": " 6х100",
                "koef": "0.005334",
                "metrs": 1
            },
            {
                "size": " 8х25",
                "koef": "0.001778",
                "metrs": 1
            },
            {
                "size": " 8х30",
                "koef": "0.002134",
                "metrs": 1
            },
            {
                "size": " 8х40",
                "koef": "0.002845",
                "metrs": 1
            },
            {
                "size": " 8х50",
                "koef": "0.003556",
                "metrs": 1
            },
            {
                "size": " 8х60",
                "koef": "0.004267",
                "metrs": 1
            },
            {
                "size": " 8х80",
                "koef": "0.00569",
                "metrs": 1
            },
            {
                "size": " 8х100",
                "koef": "0.007112",
                "metrs": 1
            },
            {
                "size": " 10х20",
                "koef": "0.001778",
                "metrs": 1
            },
            {
                "size": " 10х30",
                "koef": "0.002667",
                "metrs": 1
            },
            {
                "size": " 10х40",
                "koef": "0.003556",
                "metrs": 1
            },
            {
                "size": " 10х50",
                "koef": "0.004445",
                "metrs": 1
            },
            {
                "size": " 10х60",
                "koef": "0.005334",
                "metrs": 1
            },
            {
                "size": " 10х70",
                "koef": "0.006223",
                "metrs": 1
            },
            {
                "size": " 10х80",
                "koef": "0.007112",
                "metrs": 1
            },
            {
                "size": " 10х100",
                "koef": "0.00889",
                "metrs": 1
            },
            {
                "size": " 10х120",
                "koef": "0.01067",
                "metrs": 1
            },
            {
                "size": " 12х120",
                "koef": "0.0128",
                "metrs": 1
            },
            {
                "size": " 12,5х80",
                "koef": "0.00889",
                "metrs": 1
            },
            {
                "size": " 12,5х100",
                "koef": "0.01111",
                "metrs": 1
            },
            {
                "size": " 12,5х120",
                "koef": "0.01334",
                "metrs": 1
            }
        ]
    },
    "Нихром": {
        "Лента/штрипс": [
            {
                "size": "0.5",
                "koef": "0.0042",
                "metrs": 1
            },
            {
                "size": 1,
                "koef": "0.0084",
                "metrs": 1
            },
            {
                "size": "1.2",
                "koef": "0.01008",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.0126",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.0168",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.0252",
                "metrs": 1
            }
        ],
        "Проволока": [
            {
                "size": "0.1",
                "koef": "0.000066",
                "metrs": 1000
            },
            {
                "size": "0.16",
                "koef": "0.000169",
                "metrs": 1000
            },
            {
                "size": "0.2",
                "koef": "0.000264",
                "metrs": 1000
            },
            {
                "size": "0.3",
                "koef": "0.000594",
                "metrs": 1000
            },
            {
                "size": "0.4",
                "koef": "0.001056",
                "metrs": 1000
            },
            {
                "size": "0.5",
                "koef": "0.001649",
                "metrs": 1000
            },
            {
                "size": "0.6",
                "koef": "0.002375",
                "metrs": 1000
            },
            {
                "size": "0.8",
                "koef": "0.004222",
                "metrs": 1000
            },
            {
                "size": 1,
                "koef": "0.006597",
                "metrs": 1000
            },
            {
                "size": "1.2",
                "koef": "0.0095",
                "metrs": 1000
            },
            {
                "size": "1.4",
                "koef": "0.01293",
                "metrs": 1000
            },
            {
                "size": "1.5",
                "koef": "0.01484",
                "metrs": 1000
            },
            {
                "size": "1.6",
                "koef": "0.01689",
                "metrs": 1000
            },
            {
                "size": "1.8",
                "koef": "0.02138",
                "metrs": 1000
            },
            {
                "size": 2,
                "koef": "0,02639",
                "metrs": 1000
            },
            {
                "size": "2.5",
                "koef": "0,04123",
                "metrs": 1000
            },
            {
                "size": "2.6",
                "koef": "0,0446",
                "metrs": 1000
            },
            {
                "size": 3,
                "koef": "0,05938",
                "metrs": 1000
            },
            {
                "size": "3.5",
                "koef": "0,08082",
                "metrs": 1000
            },
            {
                "size": 4,
                "koef": "0,1056",
                "metrs": 1000
            },
            {
                "size": 5,
                "koef": "0,1649",
                "metrs": 1000
            },
            {
                "size": 6,
                "koef": "0.02375",
                "metrs": 1000
            }
        ]
    },
    "Свинец": {
        "Лист": [
            {
                "size": 1,
                "koef": "0.01134",
                "metrs": 1
            },
            {
                "size": "1.5",
                "koef": "0.01701",
                "metrs": 1
            },
            {
                "size": 2,
                "koef": "0.02268",
                "metrs": 1
            },
            {
                "size": 3,
                "koef": "0.03402",
                "metrs": 1
            },
            {
                "size": 4,
                "koef": "0.04536",
                "metrs": 1
            },
            {
                "size": 5,
                "koef": "0.0567",
                "metrs": 1
            },
            {
                "size": 6,
                "koef": "0.06804",
                "metrs": 1
            },
            {
                "size": 8,
                "koef": "0.09072",
                "metrs": 1
            },
            {
                "size": 10,
                "koef": "0.1134",
                "metrs": 1
            }
        ]
    },
    "Сетка": {
        "Сетка сварная неоцинкованная ВР- 1 (карты)": [
            {
                "size": "50х50х2,5",
                "koef": 0.00162,
                "metrs": 1
            },
            {
                "size": "50х50х3",
                "koef": 0.002,
                "metrs": 1
            },
            {
                "size": "50х50х3,5",
                "koef": 0.00298,
                "metrs": 1
            },
            {
                "size": "50х50х4",
                "koef": 0.0036,
                "metrs": 1
            },
            {
                "size": "50х50х5",
                "koef": 0.00565,
                "metrs": 1
            },
            {
                "size": "100х100х3",
                "koef": 0.001,
                "metrs": 1
            },
            {
                "size": "100х100х4",
                "koef": 0.00175,
                "metrs": 1
            },
            {
                "size": "100х100х5",
                "koef": 0.00275,
                "metrs": 1
            },
            {
                "size": "100х100х6",
                "koef": 0.00347,
                "metrs": 1
            },
            {
                "size": "150х150х3",
                "koef": 0.00059,
                "metrs": 1
            },
            {
                "size": "150х150х4",
                "koef": 0.0012,
                "metrs": 1
            },
            {
                "size": "150х150х5",
                "koef": 0.00185,
                "metrs": 1
            },
            {
                "size": "150х150х6",
                "koef": 0.00248,
                "metrs": 1
            },
            {
                "size": "200х200х4",
                "koef": 0.0008,
                "metrs": 1
            },
            {
                "size": "200х200х5",
                "koef": 0.0014,
                "metrs": 1
            },
            {
                "size": "200х200х6",
                "koef": 0.00191,
                "metrs": 1
            }
        ],
        "Сетка сварная оцинкованная ВР- 1 (карты)": [
            {
                "size": "50х50х3",
                "koef": 0.0023,
                "metrs": 1
            },
            {
                "size": "50х50х4",
                "koef": 0.0038,
                "metrs": 1
            },
            {
                "size": "100х100х3",
                "koef": 0.001,
                "metrs": 1
            },
            {
                "size": "100х100х4",
                "koef": 0.0019,
                "metrs": 1
            }
        ],
        "Сетка сварная неоцинкованная  (рулоны)": [
            {
                "size": "10х10х1,4",
                "koef": 0.00243,
                "metrs": 1
            },
            {
                "size": "25х12,5х2",
                "koef": 0.00298,
                "metrs": 1
            },
            {
                "size": "16х24х2",
                "koef": 0.00232,
                "metrs": 1
            },
            {
                "size": "25х25х1,6",
                "koef": 0.00128,
                "metrs": 1
            },
            {
                "size": "25х25х1,8",
                "koef": 0.0015,
                "metrs": 1
            },
            {
                "size": "25х25х2",
                "koef": 0.002,
                "metrs": 1
            },
            {
                "size": "25х50х1,8",
                "koef": 0.00096,
                "metrs": 1
            },
            {
                "size": "25х50х2",
                "koef": 0.0015,
                "metrs": 1
            },
            {
                "size": "50х50х1,6",
                "koef": 0.00061,
                "metrs": 1
            },
            {
                "size": "50х50х1,8",
                "koef": 0.00077,
                "metrs": 1
            },
            {
                "size": "50х50х2",
                "koef": 0.00101,
                "metrs": 1
            },
            {
                "size": "50х50х2,5",
                "koef": 0.00157,
                "metrs": 1
            },
            {
                "size": "50х50х3",
                "koef": 0.00227,
                "metrs": 1
            },
            {
                "size": "50х50х4",
                "koef": 0.00406,
                "metrs": 1
            },
            {
                "size": "50х75х3",
                "koef": 0.00178,
                "metrs": 1
            },
            {
                "size": "100х100х3",
                "koef": 0.00112,
                "metrs": 1
            }
        ],
        "Сетка сварная из оцинкованной проволоки  (рулоны)": [
            {
                "size": "6х6х0,5",
                "koef": 0.00055,
                "metrs": 1
            },
            {
                "size": "10х10х0,6",
                "koef": 0.0007,
                "metrs": 1
            },
            {
                "size": "10х10х1,2",
                "koef": 0.0019,
                "metrs": 1
            },
            {
                "size": "12,5х12,5х0,6",
                "koef": 0.0008,
                "metrs": 1
            },
            {
                "size": "20х20х0,8",
                "koef": 0.0005,
                "metrs": 1
            },
            {
                "size": "20х20х1",
                "koef": 0.00085,
                "metrs": 1
            },
            {
                "size": "25х25х1",
                "koef": 0.0007,
                "metrs": 1
            },
            {
                "size": "25х25х1,4",
                "koef": 0.00108,
                "metrs": 1
            },
            {
                "size": "25х25х1,6",
                "koef": 0.00128,
                "metrs": 1
            },
            {
                "size": "25х25х1,8",
                "koef": 0.0015,
                "metrs": 1
            },
            {
                "size": "25х12,5х1,6",
                "koef": 0.00148,
                "metrs": 1
            },
            {
                "size": "25х50х1,6",
                "koef": 0.00082,
                "metrs": 1
            },
            {
                "size": "25х50х1,8",
                "koef": 0.0012,
                "metrs": 1
            },
            {
                "size": "50х50х1,6",
                "koef": 0.00061,
                "metrs": 1
            },
            {
                "size": "50х50х1,8",
                "koef": 0.00077,
                "metrs": 1
            },
            {
                "size": "50х50х2",
                "koef": 0.00104,
                "metrs": 1
            },
            {
                "size": "50х50х2,5",
                "koef": 0.00163,
                "metrs": 1
            },
            {
                "size": "50х50х3,8",
                "koef": 0.0035,
                "metrs": 1
            }
        ],
        "Сетка сварная оцинкованная (рулоны)": [
            {
                "size": "10х10х1,4",
                "koef": 0.00272,
                "metrs": 1
            },
            {
                "size": "25х12,5х2",
                "koef": 0.00346,
                "metrs": 1
            },
            {
                "size": "20х20х1",
                "koef": 0.00062,
                "metrs": 1
            },
            {
                "size": "25х25х2",
                "koef": 0.00211,
                "metrs": 1
            },
            {
                "size": "25х50х2",
                "koef": 0.00154,
                "metrs": 1
            },
            {
                "size": "50х50х2",
                "koef": 0.00104,
                "metrs": 1
            },
            {
                "size": "50х50х2,5",
                "koef": 0.00163,
                "metrs": 1
            },
            {
                "size": "250х50х2,8",
                "koef": 0.00135,
                "metrs": 1
            }
        ],
        "Сетка ВР-1 кладочная": [
            {
                "size": "25х25х1,6",
                "koef": 0.00128,
                "metrs": 1
            },
            {
                "size": "25х25х1,8",
                "koef": 0.0015,
                "metrs": 1
            },
            {
                "size": "25х25х2",
                "koef": 0.002,
                "metrs": 1
            },
            {
                "size": "25х50х1,8",
                "koef": 0.00096,
                "metrs": 1
            },
            {
                "size": "25х50х2",
                "koef": 0.0015,
                "metrs": 1
            },
            {
                "size": "50х50х1,6",
                "koef": 0.00061,
                "metrs": 1
            },
            {
                "size": "50х50х1,8",
                "koef": 0.00077,
                "metrs": 1
            },
            {
                "size": "50х50х2",
                "koef": 0.00101,
                "metrs": 1
            },
            {
                "size": "50х50х2,5",
                "koef": 0.00157,
                "metrs": 1
            },
            {
                "size": "50х50х3",
                "koef": 0.002,
                "metrs": 1
            },
            {
                "size": "50х50х3,5",
                "koef": 0.00298,
                "metrs": 1
            },
            {
                "size": "50х50х4",
                "koef": 0.0036,
                "metrs": 1
            },
            {
                "size": "50х50х5",
                "koef": 0.00565,
                "metrs": 1
            },
            {
                "size": "100х100х3",
                "koef": 0.001,
                "metrs": 1
            },
            {
                "size": "100х100х4",
                "koef": 0.00175,
                "metrs": 1
            },
            {
                "size": "100х100х5",
                "koef": 0.00275,
                "metrs": 1
            },
            {
                "size": "100х100х6",
                "koef": 0.00347,
                "metrs": 1
            },
            {
                "size": "150х150х3",
                "koef": 0.00059,
                "metrs": 1
            },
            {
                "size": "150х150х4",
                "koef": 0.0012,
                "metrs": 1
            },
            {
                "size": "150х150х5",
                "koef": 0.00185,
                "metrs": 1
            },
            {
                "size": "150х150х6",
                "koef": 0.00248,
                "metrs": 1
            },
            {
                "size": "200х200х4",
                "koef": 0.0008,
                "metrs": 1
            },
            {
                "size": "200х200х5",
                "koef": 0.0014,
                "metrs": 1
            },
            {
                "size": "200х200х6",
                "koef": 0.00191,
                "metrs": 1
            }
        ],
        "Сетка А3, А500С, В500С арматурная": [
            {
                "size": "100х100х6",
                "koef": 0.00444,
                "metrs": 1
            },
            {
                "size": "100х100х8",
                "koef": 0.0079,
                "metrs": 1
            },
            {
                "size": "100х100х10",
                "koef": 0.01234,
                "metrs": 1
            },
            {
                "size": "100х100х12",
                "koef": 0.01776,
                "metrs": 1
            },
            {
                "size": "150х150х6",
                "koef": 0.00303,
                "metrs": 1
            },
            {
                "size": "150х150х8",
                "koef": 0.0054,
                "metrs": 1
            },
            {
                "size": "150х150х10",
                "koef": 0.00843,
                "metrs": 1
            },
            {
                "size": "150х150х12",
                "koef": 0.01214,
                "metrs": 1
            },
            {
                "size": "200х200х6",
                "koef": 0.00222,
                "metrs": 1
            },
            {
                "size": "200х200х8",
                "koef": 0.00395,
                "metrs": 1
            },
            {
                "size": "200х200х10",
                "koef": 0.00617,
                "metrs": 1
            },
            {
                "size": "200х200х12",
                "koef": 0.00888,
                "metrs": 1
            }
        ]
    },
    "Профнастил": {
        "C8 (1150)": [
            {
                "size": 0.4,
                "koef": 0.00328,
                "metrs": 1
            },
            {
                "size": 0.45,
                "koef": 0.00369,
                "metrs": 1
            },
            {
                "size": 0.5,
                "koef": 0.0041,
                "metrs": 1
            },
            {
                "size": 0.55,
                "koef": 0.00451,
                "metrs": 1
            },
            {
                "size": 0.6,
                "koef": 0.00491,
                "metrs": 1
            },
            {
                "size": 0.65,
                "koef": 0.00532,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.00573,
                "metrs": 1
            },
            {
                "size": 0.75,
                "koef": 0.00614,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.00655,
                "metrs": 1
            },
            {
                "size": 0.85,
                "koef": 0.00696,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.00737,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.00818,
                "metrs": 1
            }
        ],
        "С20 (1100)": [
            {
                "size": 0.4,
                "koef": 0.00342,
                "metrs": 1
            },
            {
                "size": 0.45,
                "koef": 0.00385,
                "metrs": 1
            },
            {
                "size": 0.5,
                "koef": 0.00427,
                "metrs": 1
            },
            {
                "size": 0.55,
                "koef": 0.0047,
                "metrs": 1
            },
            {
                "size": 0.6,
                "koef": 0.00513,
                "metrs": 1
            },
            {
                "size": 0.65,
                "koef": 0.00555,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.00598,
                "metrs": 1
            },
            {
                "size": 0.75,
                "koef": 0.00641,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.00683,
                "metrs": 1
            },
            {
                "size": 0.85,
                "koef": 0.00726,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.00769,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.00854,
                "metrs": 1
            }
        ],
        "С21 (1100)": [
            {
                "size": 0.35,
                "koef": 0.00327,
                "metrs": 1
            },
            {
                "size": 0.4,
                "koef": 0.00419,
                "metrs": 1
            },
            {
                "size": 0.45,
                "koef": 0.00466,
                "metrs": 1
            },
            {
                "size": 0.5,
                "koef": 0.00514,
                "metrs": 1
            },
            {
                "size": 0.55,
                "koef": 0.00561,
                "metrs": 1
            },
            {
                "size": 0.6,
                "koef": 0.00609,
                "metrs": 1
            },
            {
                "size": 0.65,
                "koef": 0.00657,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.00704,
                "metrs": 1
            },
            {
                "size": 0.75,
                "koef": 0.00752,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.00799,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.00885,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.0098,
                "metrs": 1
            }
        ],
        "НС35 (1000)": [
            {
                "size": 0.35,
                "koef": 0.00325,
                "metrs": 1
            },
            {
                "size": 0.4,
                "koef": 0.00371,
                "metrs": 1
            },
            {
                "size": 0.45,
                "koef": 0.00417,
                "metrs": 1
            },
            {
                "size": 0.5,
                "koef": 0.00464,
                "metrs": 1
            },
            {
                "size": 0.55,
                "koef": 0.0051,
                "metrs": 1
            },
            {
                "size": 0.6,
                "koef": 0.00556,
                "metrs": 1
            },
            {
                "size": 0.65,
                "koef": 0.00603,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.00649,
                "metrs": 1
            },
            {
                "size": 0.75,
                "koef": 0.00695,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.00742,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.00834,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.00926,
                "metrs": 1
            }
        ],
        "НС44 (1000)": [
            {
                "size": 0.4,
                "koef": 0.00419,
                "metrs": 1
            },
            {
                "size": 0.45,
                "koef": 0.00471,
                "metrs": 1
            },
            {
                "size": 0.5,
                "koef": 0.00524,
                "metrs": 1
            },
            {
                "size": 0.55,
                "koef": 0.00576,
                "metrs": 1
            },
            {
                "size": 0.6,
                "koef": 0.00628,
                "metrs": 1
            },
            {
                "size": 0.65,
                "koef": 0.0068,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.00732,
                "metrs": 1
            },
            {
                "size": 0.75,
                "koef": 0.00784,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.00837,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.00941,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.01046,
                "metrs": 1
            }
        ],
        "Н57 (750)": [
            {
                "size": 0.65,
                "koef": 0.00762,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.00811,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.00924,
                "metrs": 1
            }
        ],
        "Н60 (750)": [
            {
                "size": 0.6,
                "koef": 0.00654,
                "metrs": 1
            },
            {
                "size": 0.65,
                "koef": 0.00708,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.00763,
                "metrs": 1
            },
            {
                "size": 0.75,
                "koef": 0.00817,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.00871,
                "metrs": 1
            },
            {
                "size": 0.85,
                "koef": 0.00926,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.0098,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.01089,
                "metrs": 1
            }
        ],
        "Н75 (750)": [
            {
                "size": 0.6,
                "koef": 0.00737,
                "metrs": 1
            },
            {
                "size": 0.65,
                "koef": 0.00798,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.0086,
                "metrs": 1
            },
            {
                "size": 0.75,
                "koef": 0.00921,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.00982,
                "metrs": 1
            },
            {
                "size": 0.85,
                "koef": 0.01044,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.01105,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.01228,
                "metrs": 1
            }
        ],
        "Н114 (600)": [
            {
                "size": 0.4,
                "koef": 0.00609,
                "metrs": 1
            },
            {
                "size": 0.5,
                "koef": 0.00762,
                "metrs": 1
            },
            {
                "size": 0.65,
                "koef": 0.01068,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.01146,
                "metrs": 1
            },
            {
                "size": 0.75,
                "koef": 0.01223,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.013,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.0144,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.01594,
                "metrs": 1
            }
        ],
        "Н114 (750)": [
            {
                "size": 0.6,
                "koef": 0.00818,
                "metrs": 1
            },
            {
                "size": 0.65,
                "koef": 0.00886,
                "metrs": 1
            },
            {
                "size": 0.7,
                "koef": 0.00954,
                "metrs": 1
            },
            {
                "size": 0.75,
                "koef": 0.01022,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.0109,
                "metrs": 1
            },
            {
                "size": 0.85,
                "koef": 0.01159,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.01227,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.01363,
                "metrs": 1
            }
        ],
        "Н153 (840)": [
            {
                "size": 0.75,
                "koef": 0.00988,
                "metrs": 1
            },
            {
                "size": 0.8,
                "koef": 0.01054,
                "metrs": 1
            },
            {
                "size": 0.9,
                "koef": 0.01185,
                "metrs": 1
            },
            {
                "size": "1",
                "koef": 0.01316,
                "metrs": 1
            },
            {
                "size": 1.1,
                "koef": 0.01448,
                "metrs": 1
            },
            {
                "size": 1.2,
                "koef": 0.0158,
                "metrs": 1
            },
            {
                "size": 1.5,
                "koef": 0.01975,
                "metrs": 1
            }
        ]
    }

}