import React, {useState} from 'react';
import styled from 'styled-components'
import Ral from "./InnerBox/Ral";
import Gost from "./InnerBox/Gost";
import Vgp from "./InnerBox/Vgp";
import Nastil from "./InnerBox/Nastil";
import Marochnik from "./InnerBox/Marochnik/Marochnik";

const CalcInfo = () => {
    const [selectedCat, setCat] = useState('ГОСТ и ТУ');

    return (
        <Wrapper>
            <Menu>
                <MenuItem onClick={()=> setCat('ГОСТ и ТУ')} className={selectedCat === 'ГОСТ и ТУ' && 'selected'}>
                    ГОСТ и ТУ
                </MenuItem>
                <MenuItem onClick={()=> setCat('Марочник металлов')} className={selectedCat === 'Марочник металлов' && 'selected'}>
                    Марочник металлов
                </MenuItem>
                <MenuItem onClick={()=> setCat('Цвета RAL')} className={selectedCat === 'Цвета RAL' && 'selected'}>
                    Цвета RAL
                </MenuItem>
                <MenuItem onClick={()=> setCat('Труба ВГП')} className={selectedCat === 'Труба ВГП' && 'selected'}>
                    Труба ВГП
                </MenuItem>
                <MenuItem onClick={()=> setCat('Профнастил')} className={selectedCat === 'Профнастил' && 'selected'}>
                    Профнастил
                </MenuItem>
            </Menu>
            <Inner>
                {selectedCat === 'Цвета RAL' && <Ral/>}
                {selectedCat === 'ГОСТ и ТУ' && <Gost/>}
                {selectedCat === 'Марочник металлов' && <Marochnik/>}
                {selectedCat === 'Труба ВГП' && <Vgp/>}
                {selectedCat === 'Профнастил' && <Nastil/>}
            </Inner>
        </Wrapper>
    );
};

export default CalcInfo;
const Inner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background: white;
  z-index: 900;
`

const MenuItem = styled.div`
  cursor: pointer;
  position: relative;
  height: 100%;
  padding: 16px 0;
  font-weight: 500;
  width: 20%;
  text-align: center;
  transition: all 0.4s ease;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.5px;
  background: rgba(7, 7, 7, 1);
  background: #fff;
  border-left: 1px solid #F0F0F2;
  &:hover {
    color: #fff;
    background: #070707;
  }
  &.selected {
    color: #fff;
    background: #070707;
  }
  &:nth-last-child(1) {
    border-top-right-radius: 22px;

  }
  &:first-child {
    border: none;
    border-top-left-radius: 22px;
  }
 @media screen and (max-width: 1370px){
   font-size: 13px;
  }
`
const Menu = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #F0F0F2;
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background: white;
  -webkit-box-shadow: 0px 7px 18px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 7px 18px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 7px 18px 0px rgba(34, 60, 80, 0.2);
`