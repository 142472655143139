export function capitalizeFirstLetter(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
}
export function projectShorter(str) {
    if (!str) return str;
    return str.replace(/Предприятие(\d+)/g, 'П$1');
}
export function strNorm(str) {
    if (!str) return str;
    if (!/^П\d+$/.test(str)) {
        return str.replace(/(\d)$/, ' $1');
    }
    return str;
}
export function refactorPath(str) {
    if (!str) return str;
    return str.split('>');
}