export const gosts = {
    "Качественный прокат": [
        {
            name: 'ГОСТ 1050-88',
            text: 'Прокат сортовой, калиброванный со специальной отделкой поверхности из углеродистой качественной конструкционной стали (технические условия)'
        },
        {
            name: 'ГОСТ 1435-99',
            text: 'Прутки, полосы и мотки из инструментальной нелегированной стали (общие технические условия)'
        },
        {
            name: 'ГОСТ 14959-79',
            text: 'Прокат из рессорно-пружинной углеродистой и легированной стали (технические условия)'
        },
        {
            name: 'ГОСТ 19265-73',
            text: 'Прутки и полосы из быстрорежущей стали (технические условия)'
        },
        {
            name: 'ГОСТ 20072-74',
            text: 'Сталь теплоустойчивая (технические условия)'
        },
        {
            name: 'ГОСТ 3836-83',
            text: 'Сталь электротехническая нелегированная тонколистовая и ленты (технические условия)'
        },
        {
            name: 'ГОСТ 4405-75',
            text: 'Сталь полосовая горячекатаная и кованная инструментальная (сортамент)'
        },
        {
            name: 'ГОСТ 4543-71',
            text: 'Прокат из легированной конструкционной стали (технические условия)'
        },
        {
            name: 'ГОСТ 5632-72',
            text: 'Стали высоколегированные и сплавы коррозионно-стойкие, жаростойкие и жаропрочные (марки)'
        },
        {
            name: 'ГОСТ 5950-2000',
            text: 'Прутки, полосы и мотки из инструментальной легированной стали (общие технические условия)'
        },
        {
            name: 'ГОСТ 801-78',
            text: 'Сталь подшипниковая (технические условия)'
        }
    ],
    "Листовой прокат": [
        {
            name: 'ГОСТ 14637-89',
            text: 'Прокат толстолистовой из углеродистой стали обыкновенного качества (технические условия)'
        },
        {
            name: 'ГОСТ 14918-80',
            text: 'Сталь тонколистовая оцинкованная с непрерывных линий (технические условия)'
        },
        {
            name: 'ГОСТ 1577-93',
            text: 'Прокат толстолистовой и широкополосный из конструкционной качественной стали (технические условия)'
        },
        {
            name: 'ГОСТ 16523-97',
            text: 'Прокат тонколистовой из углеродистой стали качественной и обыкновенного качества общего назначения (технические условия)'
        },
        {
            name: 'ГОСТ 19281-89',
            text: 'Прокат из стали повышенной прочности (общие технические условия)'
        },
        {
            name: 'ГОСТ 19903-74',
            text: 'Прокат листовой горячекатаный (сортамент)'
        },
        {
            name: 'ГОСТ 19904-90',
            text: 'Прокат листовой холоднокатаный (сортамент)'
        },
        {
            name: 'ГОСТ 24045-94',
            text: 'Профили стальные листовый гнутые с трапециевидными гофрами для строительства (технические условия)'
        },
        {
            name: 'ГОСТ 30246-94',
            text: 'Прокат тонколистовой рулонный с защитно-декоративным покрытием для строительных конструкций (технические условия)'
        },
        {
            name: 'ГОСТ 4041-71',
            text: 'Прокат листовой для холодной штамповки из конструкционной качественной стали (технические условия)'
        },
        {
            name: 'ГОСТ 5582-75',
            text: 'Прокат тонколистовой коррозионно-стойкий, жаростойкий и жаропрочный (технические условия)'
        },
        {
            name: 'ГОСТ 7350-77',
            text: 'Сталь толстолистовая коррозионно-стойкая, жаростойкая и жаропрочная (технические условия)'
        },
        {
            name: 'ГОСТ 8568-77',
            text: 'Листы стальные с ромбическим и чечевичным рифлением (технические условия)'
        },
        {
            name: 'ГОСТ 9045-93',
            text: 'Прокат тонколистовой холоднокатаный из низкоуглеродистой качественной стали для холодной штамповки (технические условия)'
        },
        {
            name: 'Протокол 802',
            text: 'Согласование условий поставки толстолистового горячекатанного проката из стали углеродистой обыкновенного качества'
        },
        {
            name: 'Протокол 804',
            text: 'Согласование условий поставки толстолистового проката из стали повышенной прочности'
        }
    ],
    "Сортовой прокат": [
        {
            name: 'ГОСТ 103-76',
            text: 'Полоса стальная горячекатаная (сортамент)'
        },
        {
            name: 'ГОСТ 10884-94',
            text: 'Сталь арматурная термомеханически упрочненная для железобетонных конструкций (технические условия)'
        },
        {
            name: 'ГОСТ 1133-71',
            text: 'Сталь кованная круглая и квадратная (сортамент)'
        },
        {
            name: 'ГОСТ 11474-76',
            text: 'Профили стальные гнутые (технические условия)'
        },
        {
            name: 'ГОСТ 19425-74',
            text: 'Балки двутавровые и швеллеры стальные специальные (сортамент)'
        },
        {
            name: 'ГОСТ 2590-88',
            text: 'Прокат стальной горячекатаный круглый (сортамент)'
        },
        {
            name: 'ГОСТ 2591-88',
            text: 'Прокат стальной горячекатаный квадратный (сортамент)'
        },
        {
            name: 'ГОСТ 30136-95',
            text: 'Катанка из углеродистой стали обыкновенного качества (технические условия)'
        },
        {
            name: 'ГОСТ 380-94',
            text: 'Сталь углеродистая обыкновенного качества (марки)'
        },
        {
            name: 'ГОСТ 5267.1-90',
            text: 'Швеллеры (сортамент)'
        },
        {
            name: 'ГОСТ 535-2005',
            text: 'Прокат сортовой и фасонный из стали углеродистой обыкновенного качества (общие технические условия)'
        },
        {
            name: 'ГОСТ 5781-82',
            text: 'Сталь горячекатаная для армирования железобетонных конструкций (технические условия)'
        },
        {
            name: 'ГОСТ 7511-73',
            text: 'Профили стальные для оконных и фонарных переплетов и оконных панелей промышленных зданий'
        },
        {
            name: 'ГОСТ 8239-89',
            text: 'Двутавры стальные горячекатаные (сортамент)'
        },
        {
            name: 'ГОСТ 8240-97',
            text: 'Швеллеры стальные горячекатаные (сортамент)'
        },
        {
            name: 'ГОСТ 8278-83',
            text: 'Швеллеры стальные гнутые равнополочные (сортамент)'
        },
        {
            name: 'ГОСТ 8281-80',
            text: 'Швеллеры стальные гнутые неравнополочные (сортамент)'
        },
        {
            name: 'ГОСТ 8509-93',
            text: 'Уголки стальные горячекатаные равнополочные (сортамент)'
        },
        {
            name: 'ГОСТ 8510-86',
            text: 'Уголки стальные горячекатаные неравнополочные (сортамент)'
        },
        {
            name: 'ГОСТ P 52544-2006',
            text: 'Прокат арматурный свариваемый'
        }
    ],
    "Трубопроводная арматура": [
        {
            name: 'ГОСТ 1215-79',
            text: 'Отливки из ковкого чугуна (общие технические условия)'
        },
        {
            name: 'ГОСТ 12815-80',
            text: 'Фланцы арматуры, соединительных частей и трубопроводов на Ру от 0,1 до 20 МПа от 1 до 200 кгссм2'
        },
        {
            name: 'ГОСТ 12816-80',
            text: 'Фланцы арматуры, соединительных частей и трубопроводов на Ру от 0,1 до 20 МПа от 1 до 200 кгссм2 (общие технические условия)'
        },
        {
            name: 'ГОСТ 12820-80',
            text: 'Фланцы стальные плоские приварные на Ру от 0,1 до 2,5 МПа от 1 до 25 кгссм2 (конструкция и размеры)'
        },
        {
            name: 'ГОСТ 12821-80',
            text: 'Фланцы стальные плоские приварные на Ру от 0,1 до 20,0 МПа (от 1 до 200 кгссм2) (конструкция и размеры)'
        },
        {
            name: 'ГОСТ 17375-2001',
            text: 'Отводы крутоизогнутые типа 3D (R = 1.5DN) (конструкция)'
        },
        {
            name: 'ГОСТ 17380-83',
            text: 'Детали трубопроводов стальные бесшовные приварные на Ру 10Мпа(100 кгссм2) (технические условия)'
        },
        {
            name: 'ГОСТ 8944-75',
            text: 'Соединительные части из ковкого чугуна с цилиндрической резьбой для трубопроводов (технические требования)'
        },
        {
            name: 'ГОСТ 8946-75',
            text: 'Соединительные части из ковкого чугуна с цилиндрической резьбой для трубопроводов Угольники проходные (основные размеры)'
        },
        {
            name: 'ГОСТ 8947-75',
            text: 'Соединительные части из ковкого чугуна с цилиндрической резьбой для трубопроводов. Угольники переходные (основные размеры)'
        },
        {
            name: 'ГОСТ 8948-75',
            text: 'Соединительные части из ковкого чугуна с цилиндрической резьбой для трубопроводов. Тройники прямые (основные размеры)'
        },
        {
            name: 'ГОСТ 8949-75',
            text: 'Соединительные части из ковкого чугуна с цилиндрической резьбой для трубопроводов. Тройники переходные (основные размеры)'
        },
        {
            name: 'ГОСТ 8952-75',
            text: 'Соединительные части из ковкого чугуна с цилиндрической резьбой для трубопроводов. Кресты переходные (основные размеры)'
        },
        {
            name: 'ГОСТ 8956-75',
            text: 'Соединительные части из ковкого чугуна с цилиндрической резьбой для трубопроводов. Муфты компенсирующие (основные размеры)'
        },
        {
            name: 'ГОСТ 8960-75',
            text: 'Соединительные части из ковкого чугуна с цилиндрической резьбой для трубопроводов. Футорки (основные размеры)'
        },
        {
            name: 'ГОСТ 8965-75',
            text: 'Части соединительные стальные с цилиндрической резьбой для трубопроводов Р=1,6Мпа'
        },
        {
            name: 'ГОСТ 8969-75',
            text: 'Части соединительные стальные с цилиндрической резьбой для трубопроводов Р=1,6Мпа. Сгоны (основные размеры)'
        }
    ],
    "Трубный прокат": [
        {
            name: 'ГОСТ 10704-91',
            text: 'Трубы стальные электросварные прямошовные (сортамент)'
        },
        {
            name: 'ГОСТ 10705-80',
            text: 'Трубы стальные электросварные (технические условия)'
        },
        {
            name: 'ГОСТ 10706-76',
            text: 'Трубы стальные электросварные прямошовные (технические требования)'
        },
        {
            name: 'ГОСТ 11068-81',
            text: 'Трубы электросварные из коррозионно-стойкой стали (технические условия)'
        },
        {
            name: 'ГОСТ 13663-86',
            text: 'Трубы стальные профильные (технические требования)'
        },
        {
            name: 'ГОСТ 30245-03',
            text: 'Профили стальные гнутые замкнутые сварные квадратные и прямоугольные для строительных конструкций (технические условия)'
        },
        {
            name: 'ГОСТ 3262-75',
            text: 'Трубы стальные водогазопроводные (технические условия)'
        },
        {
            name: 'ГОСТ 550-75',
            text: 'Трубы стальные бесшовные для нефтеперерабатывающей промышленности (технические условия)'
        },
        {
            name: 'ГОСТ 8639-82',
            text: 'Трубы стальные квадратные (сортамент)'
        },
        {
            name: 'ГОСТ 8642-68',
            text: 'Трубы стальные овальные (сортамент)'
        },
        {
            name: 'ГОСТ 8645-68',
            text: 'Трубы стальные прямоугольные (сортамент)'
        },
        {
            name: 'ГОСТ 8731-87',
            text: 'Трубы стальные бесшовные горячедеформированные (технические условия)'
        },
        {
            name: 'ГОСТ 8732-78',
            text: 'Трубы стальные бесшовные горячедеформированные (сортамент)'
        },
        {
            name: 'ГОСТ 8733-74',
            text: 'Трубы стальные бесшовные холоднодеформированные и теплодеформированные (технические требования)'
        },
        {
            name: 'ГОСТ 8734-75',
            text: 'Трубы стальные бесшовные холоднодеформированные (сортамент)'
        },
        {
            name: 'ГОСТ 9940-81',
            text: 'Трубы бесшовные горячедеформированные из коррозионно-стойкой стали (технические условия)'
        },
        {
            name: 'ГОСТ 9941-81',
            text: 'Трубы бесшовные холодно-и теплодеформированные из коррозионностойкой стали (технические условия)'
        }
    ],
    "Цветные металлы": [
        {
            name: 'ГОСТ 1066-90',
            text: 'Проволока латунная (технические условия)'
        },
        {
            name: 'ГОСТ 11069-01',
            text: 'Алюминий первичный (марки)'
        },
        {
            name: 'ГОСТ 1173-93',
            text: 'Ленты медные (технические условия)'
        },
        {
            name: 'ГОСТ 1180-91',
            text: 'Аноды цинковые (технические условия)'
        },
        {
            name: 'ГОСТ 1208-90',
            text: 'Трубы бронзовые прессованные (технические условия)'
        },
        {
            name: 'ГОСТ 1209-90',
            text: 'Баббиты кальциевые в чушках (технические условия)'
        },
        {
            name: 'ГОСТ 1320-74',
            text: 'Баббиты оловянные и свинцовые (технические условия)'
        },
        {
            name: 'ГОСТ 1535-91',
            text: 'Прутки медные (технические условия)'
        },
        {
            name: 'ГОСТ 15527-04',
            text: 'Сплавы медно-цинковые (латуни), обрабатываемые давлением (марки)'
        },
        {
            name: 'ГОСТ 1628-78',
            text: 'Прутки бронзовые (технические условия)'
        },
        {
            name: 'ГОСТ 17232-99',
            text: 'Плиты из алюминия и алюминиевых сплавов (технические условия)'
        },
        {
            name: 'ГОСТ 18175-78',
            text: 'Бронзы безоловянные, обрабатываемые давлением (марки)'
        },
        {
            name: 'ГОСТ 18482-79',
            text: 'Трубы прессованные из алюминия и алюминиевых сплавов (технические условия)'
        },
        {
            name: 'ГОСТ 2060-90',
            text: 'Прутки латунные (технические условия)'
        },
        {
            name: 'ГОСТ 21488-97',
            text: 'Прутки прессованные из алюминия и алюминиевых сплавов (технические условия)'
        },
        {
            name: 'ГОСТ 21631-76',
            text: 'Листы из алюминия и алюминиевых сплавов (технические условия)'
        },
        {
            name: 'ГОСТ 21646-03',
            text: 'Трубы медные и латунные для теплообменных аппаратов (технические условия)'
        },
        {
            name: 'ГОСТ 21930-76',
            text: 'Припои оловянно-свинцовые в чушках (технические условия)'
        },
        {
            name: 'ГОСТ 21931-76',
            text: 'Припои оловянно-свинцовые в изделиях (технические условия)'
        },
        {
            name: 'ГОСТ 2208-91',
            text: 'Ленты латунные общего назначения (технические условия)'
        },
        {
            name: 'ГОСТ 22861-93',
            text: 'Свинец высокой чистоты (технические условия)'
        },
        {
            name: 'ГОСТ 3640-94',
            text: 'Цинк (технические условия)'
        },
        {
            name: 'ГОСТ 3778-98',
            text: 'Свинец (технические условия)'
        },
        {
            name: 'ГОСТ 434-78',
            text: 'Проволока прямоугольного сечения и шины медные для электрических целей (технические условия)'
        },
        {
            name: 'ГОСТ 4784-97',
            text: 'Алюминий и сплавы алюминиевые деформируемые (марки)'
        },
        {
            name: 'ГОСТ 494-90',
            text: 'Трубы латунные (технические условия)'
        },
        {
            name: 'ГОСТ 495-92',
            text: 'Листы и полосы медные (технические условия)'
        },
        {
            name: 'ГОСТ 598-90',
            text: 'Листы цинковые общего назначения (технические условия)'
        },
        {
            name: 'ГОСТ 617-90',
            text: 'Трубы медные (технические условия)'
        },
        {
            name: 'ГОСТ 859-01',
            text: 'Медь (марки)'
        },
        {
            name: 'ГОСТ 860-75',
            text: 'Олово (технические условия)'
        },
        {
            name: 'ГОСТ 931-90',
            text: 'Листы и полосы латунные (технические условия)'
        },
        {
            name: 'ГОСТ 9559-89',
            text: 'Листы свинцовые (технические условия)'
        }
    ]
 }
