import React, {useState} from 'react';
import styled from 'styled-components';
import {useRecoilState} from "recoil";
import {selectedCategoryId} from "../../../../state/atoms";
import {cat} from '../../../../data/catMaroch'
import { markiData } from '../../../../data/markiData';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


const Marochnik = () => {
    function DangerousHtmlComponent({ htmlContent }) {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }
    const [html, setHtml] = useState('');

    return (
        <Wrapper>
            <Menu>
                {cat.map((item, index) => (
                    <MenuItemComponent key={index} {...item} setHtml={setHtml}/>
                ))}
            </Menu>
            <MarkiWrapper>
                {html &&
                    <DangerousHtmlComponent htmlContent={html} />
                }
            </MarkiWrapper>
        </Wrapper>
    );
};

export default Marochnik;

const MenuItemComponent = ({  title, submenu, key, setHtml }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [catId,setSelectedId] = useRecoilState(selectedCategoryId);

    const handleClick =  (key) => {
        setIsOpen(!isOpen);
        if (Object.keys(markiData).includes(title)) {
            setHtml(markiData[title]);
        }
        if (key) setSelectedId(key);
    }

    return (
        <MenuWrapper>
            <MenuItem onClick={() => handleClick(key)} className={catId === key ? 'selected' : ''}>
                <p>{title}</p>
                {submenu && (
                   <Arrow>
                   {!isOpen ?
                       <KeyboardArrowUpIcon  style={{fontSize: '18px',fill: '#D2D2D5', transform: 'rotate(90deg)'}}/> :
                       <KeyboardArrowDownIcon  style={{fontSize: '18px', fill: '#D2D2D5'}}/>}
                   </Arrow>
                )}
             
            </MenuItem>
            {isOpen && submenu && (
                <SubMenu>
                    {submenu.map((item, index) => (
                  
                        <MenuItemComponent setHtml={setHtml} key={index}  {...item} />
                    ))}
                </SubMenu>
            )}
        </MenuWrapper>
    );
};


const MenuItem = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  user-select: none;
  font-size: 13px;
  border-bottom: 1px solid rgba(240, 240, 242, 1);
  p {
    color: #070707;
  }
  &.selected {
    p {
      font-weight: 500;
      color: #545456;
    }
    background-color: #F0F0F2;
    border-radius: 6px;
  }
  &:hover {
    background-color: #F0F0F2;
    border-radius: 6px;
  }
`;

const SubMenu = styled.div`
  padding-left: 12px;
  font-size: 12px;
  font-weight: 400;
 
`;
const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
`;

const Arrow = styled.span`
  margin-right: 8px;
  img {
    transition: all 0.3s ease;
    width: 11px;
    transform: rotate(-30deg);
    &.open {
      width: 11px;
      transform: rotate(60deg);
    }
  }
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: white;
`
const Menu = styled.div`

  width: 290px;
  flex: 0 0 auto;
  padding: 8px 20px;
  border-right: 1px solid #0000ff21;
 @media screen and (max-width: 1370px){
   width: 212px;
   padding: 8px 16px;
   }
   @media screen and (max-width: 768px){
    padding: 8px 12px;
    }
   @media screen and (max-width: 768px){
    width: 168px;
    }
`
const MarkiWrapper = styled.div`
  width: 100%; 
  font-family: 'Roboto', sans-serif;
  padding: 12px 16px;
  overflow-x: scroll;
  font-size: 14px !important;
  center {
    text-align: left;
  }
  table {
    background: #fff;
    border: none;
    outline: none;
    border-collapse: separate;
    border-spacing: 0;
  }
  /* Установка радиуса границ для крайних ячеек */
        tr:first-of-type td:first-of-type {
            border-top-left-radius: 16px;
        }

        tr:first-of-type td:last-of-type {
            border-top-right-radius: 16px;
        }

        tr:last-of-type td:first-of-type {
            border-bottom-left-radius: 16px;
        }

        tr:last-of-type td:last-of-type {
            border-bottom-right-radius: 16px;
        }

        /* Решение проблемы с артефактами */
        tr:first-of-type th {
            border-top-width: 1px;
        }

        tr:last-of-type td {
            border-bottom-width: 1px;
        }

        tr th:first-of-type,
        tr td:first-of-type {
            border-left-width: 1px;
        }

        tr th:last-of-type,
        tr td:last-of-type {
            border-right-width: 1px;
        }

  td {
    padding: 15px 20px;
    background: white;
    border: 1px solid rgba(240, 240, 242, 1);
  }

  .text5 {
    display: block;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .text5 b{
    font-weight: 500;
  }
  .help2 {
    display: block;
    margin-top: 12px;
  }
 @media screen and (max-width: 1370px){
      font-size: 13px !important;
    }
`
