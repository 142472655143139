import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import logo from '../assets/logo_home.svg';
import catIcon from '../assets/order.svg';
import calcIcon from '../assets/calc.svg';
import { Link } from 'react-router-dom';
import robotAnim from '../assets/hi_robot_mobile.mov';
import { useRecoilState } from 'recoil';
import exitIcon from '../assets/exit.svg';
import { authState } from "../state/atoms";
import OfferModal from '../components/OfferModal';
import { motion } from 'framer-motion';
import { Element, scroller } from 'react-scroll';

const AnimatedNumber = ({ value, text }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const duration = 2; // Длительность анимации в секундах
    const frameDuration = 1000 / 60; // Продолжительность одного кадра
    const totalFrames = Math.round(duration * 1000 / frameDuration); // Общее количество кадров

    let frame = 0;
    const counter = setInterval(() => {
      frame++;
      const progress = frame / totalFrames;
      setCount(Math.round(value * progress));

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);

    return () => clearInterval(counter);
  }, [value]);

  return <BoxMainText>{count}{text}</BoxMainText>;
};

const Home = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [openModal, setOpenModal] = useState(false);
  const videoRef = useRef(null);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleLogout = () => {
    setAuth(false);
    localStorage.removeItem('authToken');
  };

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.deltaY > 0) {
        scroller.scrollTo('secondSection', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        });
      } else if (e.deltaY < 0) {
        scroller.scrollTo('topSection', {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart'
        });
      }
    };

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <div>
      <Element name="topSection">
        <Wrapper>
          <Nav>
            <img src={logo} alt="logo" />
            <BtnWrapper>
              <Button to={'/base'}>
                <img src={catIcon} alt="table" />
                <span>Каталог</span>
              </Button>
              <Button to={'/calculator'}>
                <img src={calcIcon} alt="calculator" />
                <span>Калькулятор</span>
              </Button>
              {!auth ? (
                <ButtonLog to={'/login'}>
                  <img src={exitIcon} alt="Выход" />
                  <span>Войти</span>
                </ButtonLog>
              ) : (
                <ButtonLog onClick={handleLogout}>
                  <img src={exitIcon} alt="Выход" />
                  <span>Выйти</span>
                </ButtonLog>
              )}
            </BtnWrapper>
          </Nav>
          <MainText>
            Функциональная<br />
            номенклатура<br />
            металлопроката<br />
          </MainText>
          <BoxWrapper>
            <Box>
              <AnimatedNumber value={30} text={'+'} />
              <BoxSmallText>баз металлопроката по всей России</BoxSmallText>
            </Box>
            <Box>
              <AnimatedNumber value={100} text={'K+'} />
              <BoxSmallText>тонн металлопроката в год</BoxSmallText>
            </Box>
            <Box>
              <AnimatedNumber value={1} text={'K+'} />
              <BoxSmallText>наименований</BoxSmallText>
            </Box>
          </BoxWrapper>
          <ActionWrapper>
            <ActionText>
              Увеличьте скорость <br />
              и качество ваших продаж
            </ActionText>
            <ActionBtn onClick={handleOpen}>
              Оформить подписку
            </ActionBtn>
          </ActionWrapper>
          <VideoContainer>
            <Video
              src={robotAnim}
              autoPlay={true}
              loop={true}
              muted={true}
              playsInline={true}
            />
          </VideoContainer>
          {openModal && <OfferModal setOpen={setOpenModal} />}
        </Wrapper>
      </Element>
      <Element name="secondSection">
        <Wrapper2>
          <iframe
            ref={videoRef}
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/6yH-D2ltSUg?autoplay=1&mute=1&loop=1&playlist=6yH-D2ltSUg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </Wrapper2>
      </Element>
    </div>
  );
};

export default Home;

const Wrapper2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 0;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  background: rgba(0, 0, 0, 1);
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 480px){
    padding: 16px 15px;
  }
`;

const Nav = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  img {
    height: 46px;

    @media screen and (max-width: 960px){
      height: 46px;
    }
  
    @media screen and (max-width: 480px){
      height: 46px;
    }

    @media screen and (max-width: 360px){
      height: 42px;
    }
  }

  z-index: 999;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 24px;
  height: fit-content;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
`;

const Button = styled(Link)`
  align-items: center;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #D2D2D5;
  height: fit-content;

  img {
    width: 36px;
    height: 36px;
  }

  span {
    font-family: 'Roboto', sans-serif;
    color: #D2D2D5;
  }

  @media screen and (max-width: 768px){
    span {
      display: none;
    }
  }
`;

const ButtonLog = styled(Link)`
  text-decoration: none;
  align-items: center;
  display: flex;
  cursor: pointer;
  color: rgba(7, 7, 7, 1);
  gap: 8px;
  background: rgba(255, 255, 255, 1);
  padding: 11px 18px;
  border-radius: 20px;
  height: fit-content;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 480px){
      display: none;
    }
  }

  @media screen and (max-width: 480px){
    padding: 8px;
  }
`;

const MainText = styled.h1`
  font-family: "Aeonik Bold", sans-serif;
  font-size: 54px;
  font-weight: 900;
  color: rgba(255, 255, 255, 1);
  z-index: 999;
  margin-top: 143px;

  @media screen and (max-width: 1370px){
    font-size: 44px;
    font-weight: 900;
    margin-top: 60px;
  }

  @media screen and (max-width: 960px){
    font-size: 36px;
    font-weight: 900;
    margin-top: 176px;
  }

  @media screen and (max-width: 768px){
    font-size: 36px;
    font-weight: 900;
    line-height: 52px;
    margin-top: 176px;
  }

  @media screen and (max-width: 480px){
    font-size: 28px;
    line-height: 44px;
    margin-top: 331px;
  }

  @media screen and (max-width: 360px){
    font-size: 24px;
    line-height: 36px;
    margin-top: 248px;
  }
`;

const BoxWrapper = styled.div`
  display: flex;
  gap: 26px;
  z-index: 999;
  margin-top: 94px;

  @media screen and (max-width: 1370px){
    gap: 17px;
    margin-top: 44px;
  }

  @media screen and (max-width: 960px){
    gap: 9px;
    margin-top: 50px;
  }

  @media screen and (max-width: 768px){
    gap: 9px;
    margin-top: 50px;
  }

  @media screen and (max-width: 480px){
    margin-top: 125px;
    flex-wrap: wrap-reverse;
  }

  @media screen and (max-width: 480px){
    margin-top: 375px;
    margin-top: 91px;
    flex-wrap: wrap-reverse;
  }
`;

const Box = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 7px 15px 19px 23px;
  border-radius: 16px;
  border: 1px solid white;
`;

const BoxMainText = styled.h2`
  color: white;
  font-size: 80px;
  font-weight: 500;
  line-height: 104px;
  font-family: 'Aeonik Medium', sans-serif;

  @media screen and (max-width: 1370px){
    font-size: 50px;
    line-height: 54px;
    font-weight: 500;
  }

  @media screen and (max-width: 960px){
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
  }

  @media screen and (max-width: 768px){
    font-size: 40px;
    font-weight: 500;
    line-height: 52px;
  }
`;

const BoxSmallText = styled.h3`
  color: white;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  font-family: 'Aeonik', sans-serif;

  @media screen and (max-width: 1370px){
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  @media screen and (max-width: 960px){
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
  }

  @media screen and (max-width: 768px){
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 56px;
  align-items: center;
  z-index: 999;
  margin-top: 95px;

  @media screen and (max-width: 1370px){
    gap: 32px;
    margin-top: 50px;
  }

  @media screen and (max-width: 960px){
    gap: 19px;
    margin-top: 49px;
  }

  @media screen and (max-width: 768px){
    gap: 19px;
    margin-top: 49px;
  }

  @media screen and (max-width: 480px){
    flex-direction: column;
    gap: 26px;
    margin-top: 36px;
  }

  @media screen and (max-width: 375px){
    flex-direction: column;
    gap: 26px;
    margin-top: 25px;
  }
`;

const ActionText = styled.h3`
  color: white;
  font-family: 'Aeonik Light', sans-serif;
  font-size: 32px;
  font-weight: 250;
  line-height: 41.6px;

  @media screen and (max-width: 1370px){
    font-size: 20px;
    font-weight: 250;
    line-height: 26px;
  }

  @media screen and (max-width: 960px){
    font-size: 20px;
    font-weight: 250;
    line-height: 26px;
  }

  @media screen and (max-width: 768px){
    font-size: 20px;
    font-weight: 250;
    line-height: 26px;
  }

  @media screen and (max-width: 480px){
    font-size: 14px;
    text-align: center;
  }
`;

const ActionBtn = styled.div`
  cursor: pointer;
  box-shadow: 0px 4px 30px 0px rgba(0, 110, 239, 1);
  background: rgba(0, 117, 255, 1);
  padding: 20px 40px;
  color: white;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;

  @media screen and (max-width: 1370px){
    padding: 13px 22px;
  }

  @media screen and (max-width: 480px){
    font-size: 12px;
    padding: 12px 20px;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  right: 40px;
  top: 0;
  height: 165%;

  @media screen and (max-width: 480px){
    top: -9%;
    right: 0;
    height: 100%;
    width: fit-content;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
`;
