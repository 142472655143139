export const tableData = [
    {
        "Vd": "6х1.8",
        "D": "¼″",
        "Nd": 10.2,
        "Dlina": "6м, 7.8м, 10.5м, 12м",
        "photo": "Труба ВГП 6х1.8 мм.png",
    },
    {
        "Vd": "6х2.0",
        "D": "¼″",
        "Nd": 10.2,
        "Dlina": "6м, 7.8м, 10.5м, 12м",
        "photo": "Труба ВГП 6х2 мм.png",
    },
    {
        "Vd": "6х2.5",
        "D": "¼″",
        "Nd": 10.2,
        "Dlina": "6м, 7.8м, 10.5м, 12м",
        "photo": "Труба ВГП 6х2.5 мм.png",
    },
    {
        "Vd": "8х2.0",
        "D": "⅓″",
        "Nd": 13.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м",
        "photo": "Труба ВГП 8х2 мм.png",
    },
    {
        "Vd": "8х2.2",
        "D": "⅓″",
        "Nd": 13.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м",
        "photo": "Труба ВГП 8х2.2 мм.png",
    },
    {
        "Vd": "8х2.8",
        "D": "⅓″",
        "Nd": 13.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м",
        "photo": "Труба ВГП 8х2.8 мм.png",
    },
    {
        "Vd": "10х2.0",
        "D": "2\/5″",
        "Nd": 17,
        "Dlina": "6м, 7.8м, 10.5м, 12м",
        "photo": "Труба ВГП 10х2 мм.png",
    },
    {
        "Vd": "10х2.2",
        "D": "2\/5″",
        "Nd": 17,
        "Dlina": "6м, 7.8м, 10.5м, 12м",
        "photo": "Труба ВГП 10х2.2 мм.png",
    },
    {
        "Vd": "10х2.8",
        "D": "2\/5″",
        "Nd": 17,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 10х2.8 мм.png",
    },
    {
        "Vd": "15х2.5",
        "D": "½″",
        "Nd": 21.3,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 15х2.5 мм.png",
    },
    {
        "Vd": "15х2.8",
        "D": "½″",
        "Nd": 21.3,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 15х2.8 мм.png",
    },
    {
        "Vd": "15х3.2",
        "D": "½″",
        "Nd": 21.3,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 15х3.2 мм.png",
    },
    {
        "Vd": "20х2.5",
        "D": "¾″",
        "Nd": 26.8,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 20х2.5 мм.png",
    },
    {
        "Vd": "20х2.8",
        "D": "¾″",
        "Nd": 26.8,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 20х2.8 мм.png",
    },
    {
        "Vd": "20х3.2",
        "D": "¾″",
        "Nd": 26.8,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 20х3.2 мм.png",
    },
    {
        "Vd": "25х2.8",
        "D": "1″",
        "Nd": 33.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 25х2.8 мм.png",
    },
    {
        "Vd": "25х3.2",
        "D": "1″",
        "Nd": 33.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 25х3.2 мм.png",
    },
    {
        "Vd": "25х4.0",
        "D": "1″",
        "Nd": 33.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 25х4 мм.png",
    },
    {
        "Vd": "32х2.8",
        "D": "1 ¼″",
        "Nd": 42.3,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 32х2.8 мм.png",
    },
    {
        "Vd": "32х3.2",
        "D": "1 ¼″",
        "Nd": 42.3,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 32х3.2 мм.png",
    },
    {
        "Vd": "32х4.0",
        "D": "1 ¼″",
        "Nd": 42.3,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 32х4 мм.png",
    },
    {
        "Vd": "40х3.0",
        "D": "1 ½″",
        "Nd": 48,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 40х3 мм.png",
    },
    {
        "Vd": "40х3.5",
        "D": "1 ½″",
        "Nd": 48,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 40х3.5 мм.png",
    },
    {
        "Vd": "40х4.0",
        "D": "1 ½″",
        "Nd": 48,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 40х4 мм.png",
    },
    {
        "Vd": "50х3.0",
        "D": "2″",
        "Nd": 60,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 50х3 мм.png",
    },
    {
        "Vd": "50х3.5",
        "D": "2″",
        "Nd": 60,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 50х3.5 мм.png",
    },
    {
        "Vd": "50х4.5",
        "D": "2″",
        "Nd": 60,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 50х4.5 мм.png",
    },
    {
        "Vd": "65х3.2",
        "D": "2 ½″",
        "Nd": 75.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 65х3.2.png",
    },
    {
        "Vd": "65х4.0",
        "D": "2 ½″",
        "Nd": 75.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 65х4 мм.png",
    },
    {
        "Vd": "65х4.5",
        "D": "2 ½″",
        "Nd": 75.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 65х4.5 мм.png",
    },
    {
        "Vd": "80х3.5",
        "D": "3″",
        "Nd": 88.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 80х3.5 мм.png",
    },
    {
        "Vd": "80х4.0",
        "D": "3″",
        "Nd": 88.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 80х4 мм.png",
    },
    {
        "Vd": "80х4.5",
        "D": "3″",
        "Nd": 88.5,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 80х4.5 мм.png",
    },
    {
        "Vd": "90х3.5",
        "D": "3 ½″",
        "Nd": 101.3,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 90х3.5 мм.png",
    },
    {
        "Vd": "90х4.0",
        "D": "3 ½″",
        "Nd": 101.3,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 90х4 мм.png",
    },
    {
        "Vd": "90х4.5",
        "D": "3 ½″",
        "Nd": 101.3,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "90.4.5.png",
    },
    {
        "Vd": "100х4.0",
        "D": "4″",
        "Nd": 114,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 100х4 мм.png",
    },
    {
        "Vd": "100х4.5",
        "D": "4″",
        "Nd": 114,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 100х4.5 мм.png",
    },
    {
        "Vd": "100х5.0",
        "D": "4″",
        "Nd": 114,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 100х5 мм.png",
    },
    {
        "Vd": "125х4.0",
        "D": "5″",
        "Nd": 140,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 125х4 мм.png",
    },
    {
        "Vd": "125х4.5",
        "D": "5″",
        "Nd": 140,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 125х4.5 мм.png",
    },
    {
        "Vd": "125х5.5",
        "D": "5″",
        "Nd": 140,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 125х5.5 мм.png",
    },
    {
        "Vd": "150х4.0",
        "D": "6″",
        "Nd": 165,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 150х4 мм.png",
    },
    {
        "Vd": "150х4.5",
        "D": "6″",
        "Nd": 165,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 150х4.5 мм.png",
    },
    {
        "Vd": "150х5.5",
        "D": "6″",
        "Nd": 165,
        "Dlina": "6м, 7.8м, 10.5м, 12м", "photo": "Труба ВГП 150х5.5 мм.png",
    }
]