import React, {useEffect} from 'react';
import Routing from './router/index';
import styled from 'styled-components';
import useAutoLogout from "./hooks/useAutoLogout";
import {useNavigate} from "react-router-dom";
import {useRecoilState, useSetRecoilState} from "recoil";
import {authState, loadDom} from "./state/atoms";
import Preloader from "./components/Preloader/Preloader";

const checkTokenValidity = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        return false;
    }
    try {
        const response = await fetch('/api/auth/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response.ok;
    } catch (error) {
        console.error('Error verifying token:', error);
        return false;
    }
};

const App = () => {
    const setAuth = useSetRecoilState(authState);
    const navigate = useNavigate();
    const [load, setLoad] = useRecoilState(loadDom);

    useAutoLogout();

    useEffect(() => {
        setLoad(true);
        checkTokenValidity().then(isValid => {
           setAuth(isValid);
           setTimeout(()=>{ setLoad(false);},500)
        });
        // eslint-disable-next-line
    }, [navigate]);

    return (
        <Wrapper>

            {load ?
                <LoadWrapper>
                    <Preloader/>
                </LoadWrapper>

                :
                <Routing />
            }
        </Wrapper>
    );
};

export default App;
const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  background: rgba(240, 240, 242, 1);
`
const LoadWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`