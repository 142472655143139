import React, { useState, useEffect} from 'react';
import styled from "styled-components";
import {useRecoilState} from "recoil";
import { selectedCategoryId, openTreeState} from "../../state/atoms";
import xIcon from "../../assets/x.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {category} from '../../data/data';


function findParentIds(category, targetId, parents = []) {
  for (const item of category) {
      if (item.id === targetId) {
          return [...parents, item.id];
      }
      if (item.submenu) {
          const found = findParentIds(item.submenu, targetId, [...parents, item.id]);
          if (found) {
              return found;
          }
      }
  }
  return null;
}


const MenuItemComponent = ({  name, submenu, keyId, id }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [catId,setSelectedId] = useRecoilState(selectedCategoryId);
    const [tree, setTree] = useRecoilState(openTreeState);

    const handleClick =  (key,id) => {
        setIsOpen(!isOpen);
        const parentIds = findParentIds(category, id);
        setTree(parentIds);
        if (key) setSelectedId(key);
    }
  useEffect(()=> {
    console.log(tree);
    if (!tree.includes(id)) setIsOpen(false);
  }, [tree]);

    return (
        <MenuWrapper>
            <MenuItem onClick={() => handleClick(keyId, id)} className={catId === keyId ? 'selected' : ''}>
                <p>{name}</p>
                {submenu && (
                    <Arrow>
                        {!isOpen ?
                            <KeyboardArrowUpIcon  style={{fontSize: '18px',fill: '#D2D2D5', transform: 'rotate(90deg)'}}/> :
                            <KeyboardArrowDownIcon  style={{fontSize: '18px', fill: '#D2D2D5'}}/>}
                    </Arrow>
                )}
            </MenuItem>
            {isOpen && submenu && (
                <SubMenu>
                    {submenu.map((item, index) => (
                        <MenuItemComponent key={index} {...item} />
                    ))}
                </SubMenu>
            )}
        </MenuWrapper>
    );
};

const Category = ({ menuData, show, setShowMenu }) => {
    return (
        <MenuMainWrapper className={show ? 'true' : 'false'}>
            <img src={xIcon} alt="x" onClick={()=> setShowMenu(false)}/>
            {menuData.map((item, index) => (
                <MenuItemComponent key={index} {...item} />
            ))}
        </MenuMainWrapper>
    );
};

export default Category;


const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;

 
`;
const MenuMainWrapper = styled.div`
font-size: 14px;
font-weight: 500;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  color: #070707;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 24px 8px 0;
  width: 232px;
  background: white;
  height: 100vh;
  overflow-y: scroll;

  scrollbar-width: thin; /* Для Firefox */
  scrollbar-color: #D2D2D5 transparent; /* Для Firefox: цвет ползунка и трека */

  &::-webkit-scrollbar {
    width: 2px; /* Ширина скроллбара */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Фон трека скроллбара */
  }

  &::-webkit-scrollbar-thumb {
    background: #0000ff21; /* Цвет ползунка скроллбара */
    border-radius: 10px; /* Радиус скругления */
  }
  img {
    display: none;
  }
 @media screen and (max-width: 1370px){
    width: 188px;
    padding: 24px 8px 0 0;
  } 

  @media screen and (max-width: 960px){
    font-size: 13px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    padding-top: 36px;
    &.false {
      display: none;
    }
    &.true {
      display: flex;
    }
    img {
      display: block;
      width: 24px;
      position: absolute;
      top: 12px;
      right: 24px;
      z-index: 999;
    }

  }
`;
const MenuItem = styled.div`
border-bottom: 1px solid rgba(240, 240, 242, 1);
font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  user-select: none;
  &.selected {
    p {
      font-weight: 500;
      color: #545456;
    }
    background-color: #F0F0F2;
    border-radius: 6px;
  }
  &:hover {
    background-color: #F0F0F2;
    border-radius: 6px;
  }
`;

const SubMenu = styled.div`
  padding-left: 12px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(7, 7, 7, 1);
  * {
  border: none !important;
}
`;
const Arrow = styled.span`
  margin-right: 8px;
  img {
    transition: all 0.3s ease;
    width: 11px;
    transform: rotate(-30deg);
    &.open {  
      width: 11px;
      transform: rotate(60deg);
    }
  }
`;