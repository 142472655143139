import { useEffect } from 'react';
import {useSetRecoilState} from 'recoil';
import {allFilters, authState, filterModal, selectedCategoryId} from '../state/atoms';

 const UseAutoLogout = () => {

     const setX1 = useSetRecoilState(selectedCategoryId);
     const setX2 = useSetRecoilState(filterModal);
     const setX3 = useSetRecoilState(allFilters);
     const setX4 = useSetRecoilState(authState);


    const isTokenExpired = (token) => {
        if (!token) {
            return true;
        }
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const token = localStorage.getItem('authToken');
            if (isTokenExpired(token)) {
                setX1(null);
                setX2(false);
                setX3({});
                setX4(false);
                localStorage.removeItem('authToken');
            }
        }, 60000);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [setX4]);
};
export default UseAutoLogout;