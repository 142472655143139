import React from "react";
import {Route, Routes} from "react-router-dom";
import TablePage from "../pages/TablePage";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import LoginPage from "../pages/LoginPage";
import PrivateLogin from "../components/PrivateRoute/PrivateLogin";
import CalcPage from "../pages/CalcPage";
import Home from "../pages/Home";

const Index = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route
                path="/base"
                element={
                    <PrivateRoute>
                        <TablePage />
                     </PrivateRoute>
                }
            />
 
            <Route 
                path="/calculator" 
                element={
                    <PrivateRoute>
                        <CalcPage/>
                     </PrivateRoute>
                }
            />

            <Route
                path="/login"
                element={
                    <PrivateLogin>
                        <LoginPage />
                    </PrivateLogin>
                }
            />

        </Routes>
    );
};

export default Index;