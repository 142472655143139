export const nastilData = [
    {
        name: "C8-1150",
        disc: "Профнастил имеет гофрированную поверхность в виде трапеций высотой 8 мм, шириной основания 62,5 мм, расстояние между гофрами 53,5 мм.\n" +
            "\n" +
            "Изготовление профнастила происходит методом холодной прокатки тонколистового металла. На специальном оборудовании задаются данные параметры, затем укладывается лист из рулона оцинкованной стали или стали с полимерным покрытием. С заданной скоростью лист движется под воздействием прокатных роликов рабочих валов, в процессе работы он приобретает волнообразную поверхность.\n" +
            "\n" +
            "Где используют профнастил?\n" +
            "\n" +
            "Для возведения заборов, покрытия кровли, несущих конструкций, опалубки, а так же при отделке фасадов и стен изнутри.\n" +
            "Профлист устойчив к перепадам температуры, что позволяет использовать его в различных регионах с различными климатическими условиями. При эксплуатации не составит и большого труда заменить поврежденный лист по истечению срока его службы.",
    },
    {
        name: "C20-1100",
        disc: "Профнастил НС 20 имеет не только легкий вес и надежную прочность, так же у него высокая антикоррозийная стойкость.\n" +
            "\n" +
            "По желанию вы можете выбрать лист с полимерным покрытием, которое будет предавать более эстетичный вид, и усиливать защитные свойства от воздействия внешней среды.\n" +
            "\n" +
            "Буквы НС означают материал несуще-стенового типа.\n" +
            "Листы НС можно использовать практически для любых назначений и конструкций, при этом стоят они дешевле. Ими обшивают кровлю, из них делают навесы и ворота.\n" +
            "\n" +
            "Данный вид профнастила можно назвать промежуточным вариантом между простым профлистом и такими же листами, но уже повышенной жесткости.\n" +
            "\n" +
            "Материал является безопасным и поэтому его рекомендуется применять в любых типах здания.",
    },
    {
        name: "C21-1000",
        disc: "С помощью профлиста С21 можно установить ограждение с высотой более 2,5 метров в высоту, которое будет служить долгим и надежным укрытием от сильных ветров и выглядеть презентабельно.\n" +
            "\n" +
            "Данный вид профнастила используют в различных сферах, например, облицовке стен при реконструкции сооружений.\n" +
            "\n" +
            "Профлист можно использовать на любых конструкциях и при любых углах наклона.\n" +
            "\n" +
            "Лист незаменим при строительстве быстровозводимых промышленных или индивидуальных постройках различной архитектурной формы.",
    },
    {
        name: "H60-845",
        disc: "Если посмотреть на готовый лист Н60, мы увидим на гофрированной поверхности дополнительные впадины – борозды вдоль всей волны.\n" +
            "\n" +
            "За счет этого проф лист становиться еще более прочным и выносливым к различного рода нагрузкам и к агрессивным погодным условиям.\n" +
            "\n" +
            "Благодаря высокому запасу прочности Н60 является очень востребованным материалом.\n" +
            "\n" +
            "Профнастил используют как кровельное покрытие, к примеру, для больших складских помещений, ангаров и т.п.\n" +
            "\n" +
            "Также данный профлист применяется при сооружении несъемной опалубки, несущих конструкций, щитовых ограждений, заборов и многих других работах.",
    },
    {
        name: "H75-750",
        disc: "Профнастил Н75 может быть как стальным оцинкованным, так и с полимерным покрытием.\n" +
            "\n" +
            "Профнастил Н75 вынослив к солидным динамическим и статическим нагрузкам.\n" +
            "\n" +
            "При этом профлист имеет легкий вес, что значительно упрощает работу с данным материалом - нет необходимости возводить стропильную систему для его монтажа.",
    },
    {
        name: "H114-750",
        disc: "Сочетая в себе прочность, легкий вес и долговечность, профилированный стальной лист Н114 очень широко применяется в промышленном строительстве.\n" +
            "\n" +
            "Его можно использовать для возведения стен, формирования каркасных конструкций, в строительстве перегородок зданий, облицовки стен и обустройстве крыш и т.д.",
    },
    {
        name: "HC35-1000",
        disc: "Буквы НС обозначают высокие несущие способности. Лист НС 35 широко применяется в сфере кровельных работ, для обшивки стен, навесов, коттеджных, а так же дачных домов и беседок.\n" +
            "\n" +
            "Высокую несущую способность и механическую прочность листу придают дополнительные ребра жесткости.\n" +
            "\n" +
            "Лист устойчив к коррозионным факторам и имеет высокий срок эксплуатации.\n" +
            "\n" +
            "Его универсальность и уникальность, плюс выгодная цена позволяют использовать материал в максимально широком спектре строительных и отделочных работ. ",
    },
    {
        name: "HC44-1000",
        disc: "Профлист НС44 – это востребованный и популярный материал в промышленном и частном строительстве.\n" +
            "\n" +
            "В совокупности полимер и оцинкованная сталь придают изделию надежную защиту от влияния внешней среды, от образований коррозии и механических повреждений, что продлевает срок его эксплуатационной службы.\n" +
            "\n" +
            "Профнастил легко монтируется на любые типы поверхности, он очень не прихотлив, и не требует дополнительного обслуживания, что делает его очень востребованным на рынке материалом.",
    },
]