import ReactDOM from 'react-dom/client';
import App from './App';
import ScrollToTop from "./utils/ScrollToTop";
import {BrowserRouter} from "react-router-dom";
import GlobalStyle from "./globalStyles";
import {RecoilRoot} from "recoil";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RecoilRoot>
        <BrowserRouter>
            <GlobalStyle />
            <ScrollToTop />
            <App />
        </BrowserRouter>
    </RecoilRoot>
);

