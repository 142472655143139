export const colors = {
    "1000": {
        "code": "1000",
        "scope": "classic",
        "color": {
        "hex": "#CDBA88",
            "websafe": "#cccc99",
            "rgb": {
            "r": 205,
                "g": 186,
                "b": 136
        },
        "hsl": {
            "h": 43,
                "s": 41,
                "l": 67
        },
        "hsb": {
            "h": 43,
                "s": 34,
                "b": 80
        },
        "cmyk": {
            "c": "5",
                "m": "10",
                "y": "40",
                "k": "10"
        }
    },
        "names": {
            "de": "Grünbeige",
                "en": "Green beige",
                "ru" : "Зелёно-бежевый",
                "fr": "Beige vert",
                "es": "Beige verdoso",
                "it": "Beige verdastro",
                "nl": "Groenbeige"
        }
},
    "1001": {
    "code": "1001",
        "scope": "classic",
        "color": {
        "hex": "#D0B084",
            "websafe": "#cc9999",
            "rgb": {
            "r": 208,
                "g": 176,
                "b": 132
        },
        "hsl": {
            "h": 35,
                "s": 45,
                "l": 67
        },
        "hsb": {
            "h": 35,
                "s": 37,
                "b": 82
        },
        "cmyk": {
            "c": "5",
                "m": "20",
                "y": "40",
                "k": "10"
        }
    },
        "names": {
            "de": "Beige",
                "en": "Beige",
                "ru" : 'Бежевый',
                "fr": "Beige",
                "es": "Beige",
                "it": "Beige",
                "nl": "Beige"
        }
},
    "1002": {
    "code": "1002",
        "scope": "classic",
        "color": {
        "hex": "#D2AA6D",
            "websafe": "#cc9966",
            "rgb": {
            "r": 210,
                "g": 170,
                "b": 109
        },
        "hsl": {
            "h": 36,
                "s": 53,
                "l": 63
        },
        "hsb": {
            "h": 36,
                "s": 48,
                "b": 82
        },
        "cmyk": {
            "c": "5",
                "m": "20",
                "y": "50",
                "k": "10"
        }
    },
        "names": {
            "de": "Sandgelb",
                "en": "Sand yellow",
                "ru":'Песочно-желтый',
                "fr": "Jaune sable",
                "es": "Amarillo arena",
                "it": "Giallo sabbia",
                "nl": "Zandgeel"
        }
},
    "1003": {
    "code": "1003",
        "scope": "classic",
        "color": {
        "hex": "#F9A800",
            "websafe": "#ff9900",
            "rgb": {
            "r": 249,
                "g": 168,
                "b": 0
        },
        "hsl": {
            "h": 40,
                "s": 100,
                "l": 49
        },
        "hsb": {
            "h": 40,
                "s": 100,
                "b": 98
        },
        "cmyk": {
            "c": "0",
                "m": "35",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Signalgelb",
            "en": "Signal yellow",
            "fr": "Jaune de sécurité",
            "es": "Amarillo señales",
            "it": "Giallo segnale",
            "nl": "Signaalgeel"
    }
},
    "1004": {
    "code": "1004",
        "scope": "classic",
        "color": {
        "hex": "#E49E00",
            "websafe": "#cc9900",
            "rgb": {
            "r": 228,
                "g": 158,
                "b": 0
        },
        "hsl": {
            "h": 42,
                "s": 100,
                "l": 45
        },
        "hsb": {
            "h": 42,
                "s": 100,
                "b": 89
        },
        "cmyk": {
            "c": "10",
                "m": "35",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Goldgelb",
            "en": "Golden yellow",
            "fr": "Jaune or",
            "es": "Amarillo oro",
            "it": "Giallo oro",
            "nl": "Goudgeel"
    }
},
    "1005": {
    "code": "1005",
        "scope": "classic",
        "color": {
        "hex": "#CB8E00",
            "websafe": "#cc9900",
            "rgb": {
            "r": 203,
                "g": 142,
                "b": 0
        },
        "hsl": {
            "h": 42,
                "s": 100,
                "l": 40
        },
        "hsb": {
            "h": 42,
                "s": 100,
                "b": 80
        },
        "cmyk": {
            "c": "0",
                "m": "30",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Honiggelb",
            "en": "Honey yellow",
            "fr": "Jaune miel",
            "es": "Amarillo miel",
            "it": "Giallo miele",
            "nl": "Honinggeel"
    }
},
    "1006": {
    "code": "1006",
        "scope": "classic",
        "color": {
        "hex": "#E29000",
            "websafe": "#cc9900",
            "rgb": {
            "r": 226,
                "g": 144,
                "b": 0
        },
        "hsl": {
            "h": 38,
                "s": 100,
                "l": 44
        },
        "hsb": {
            "h": 38,
                "s": 100,
                "b": 89
        },
        "cmyk": {
            "c": "0",
                "m": "40",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Maisgelb",
            "en": "Maize yellow",
            "fr": "Jaune maïs",
            "es": "Amarillo maiz",
            "it": "Giallo polenta",
            "nl": "Maisgeel"
    }
},
    "1007": {
    "code": "1007",
        "scope": "classic",
        "color": {
        "hex": "#E88C00",
            "websafe": "#ff9900",
            "rgb": {
            "r": 232,
                "g": 140,
                "b": 0
        },
        "hsl": {
            "h": 36,
                "s": 100,
                "l": 45
        },
        "hsb": {
            "h": 36,
                "s": 100,
                "b": 91
        },
        "cmyk": {
            "c": "0",
                "m": "50",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Narzissengelb",
            "en": "Daffodil yellow",
            "fr": "Jaune narcisse",
            "es": "Amarillo narciso",
            "it": "Giallo narciso",
            "nl": "Narcissengeel"
    }
},
    "1011": {
    "code": "1011",
        "scope": "classic",
        "color": {
        "hex": "#AF804F",
            "websafe": "#999966",
            "rgb": {
            "r": 175,
                "g": 128,
                "b": 79
        },
        "hsl": {
            "h": 31,
                "s": 38,
                "l": 50
        },
        "hsb": {
            "h": 31,
                "s": 55,
                "b": 69
        },
        "cmyk": {
            "c": "20",
                "m": "40",
                "y": "60",
                "k": "0"
        }
    },
    "names": {
        "de": "Braunbeige",
            "en": "Brown beige",
            "fr": "Beige brun",
            "es": "Beige pardo",
            "it": "Beige marrone",
            "nl": "Bruinbeige"
    }
},
    "1012": {
    "code": "1012",
        "scope": "classic",
        "color": {
        "hex": "#DDAF27",
            "websafe": "#cc9933",
            "rgb": {
            "r": 221,
                "g": 175,
                "b": 39
        },
        "hsl": {
            "h": 45,
                "s": 73,
                "l": 51
        },
        "hsb": {
            "h": 45,
                "s": 82,
                "b": 87
        },
        "cmyk": {
            "c": "5",
                "m": "20",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Zitronengelb",
            "en": "Lemon yellow",
            "fr": "Jaune citron",
            "es": "Amarillo limón",
            "it": "Giallo limone",
            "nl": "Citroengeel"
    }
},
    "1013": {
    "code": "1013",
        "scope": "classic",
        "color": {
        "hex": "#E3D9C6",
            "websafe": "#cccccc",
            "rgb": {
            "r": 227,
                "g": 217,
                "b": 198
        },
        "hsl": {
            "h": 39,
                "s": 34,
                "l": 83
        },
        "hsb": {
            "h": 39,
                "s": 13,
                "b": 89
        },
        "cmyk": {
            "c": "5",
                "m": "10",
                "y": "20",
                "k": "0"
        }
    },
    "names": {
        "de": "Perlweiß",
            "en": "Oyster white",
            "fr": "Blanc perlé",
            "es": "Blanco perla",
            "it": "Bianco perla",
            "nl": "Parelwit"
    }
},
    "1014": {
    "code": "1014",
        "scope": "classic",
        "color": {
        "hex": "#DDC49A",
            "websafe": "#cccc99",
            "rgb": {
            "r": 221,
                "g": 196,
                "b": 154
        },
        "hsl": {
            "h": 38,
                "s": 50,
                "l": 74
        },
        "hsb": {
            "h": 38,
                "s": 30,
                "b": 87
        },
        "cmyk": {
            "c": "5",
                "m": "20",
                "y": "50",
                "k": "0"
        }
    },
    "names": {
        "de": "Elfenbein",
            "en": "Ivory",
            "fr": "Ivoire",
            "es": "Marfil",
            "it": "Avorio",
            "nl": "Ivoorkleurig"
    }
},
    "1015": {
    "code": "1015",
        "scope": "classic",
        "color": {
        "hex": "#E6D2B5",
            "websafe": "#ffcccc",
            "rgb": {
            "r": 230,
                "g": 210,
                "b": 181
        },
        "hsl": {
            "h": 36,
                "s": 49,
                "l": 81
        },
        "hsb": {
            "h": 36,
                "s": 21,
                "b": 90
        },
        "cmyk": {
            "c": "5",
                "m": "10",
                "y": "30",
                "k": "0"
        }
    },
    "names": {
        "de": "Hellelfenbein",
            "en": "Light ivory",
            "fr": "Ivoire clair",
            "es": "Marfil claro",
            "it": "Avorio chiaro",
            "nl": "Licht ivoorkleurig"
    }
},
    "1016": {
    "code": "1016",
        "scope": "classic",
        "color": {
        "hex": "#F1DD38",
            "websafe": "#ffcc33",
            "rgb": {
            "r": 241,
                "g": 221,
                "b": 56
        },
        "hsl": {
            "h": 54,
                "s": 87,
                "l": 58
        },
        "hsb": {
            "h": 54,
                "s": 77,
                "b": 95
        },
        "cmyk": {
            "c": "5",
                "m": "0",
                "y": "90",
                "k": "0"
        }
    },
    "names": {
        "de": "Schwefelgelb",
            "en": "Sulfur yellow",
            "fr": "Jaune soufre",
            "es": "Amarillo azufre",
            "it": "Giallo zolfo",
            "nl": "Zwavelgeel"
    }
},
    "1017": {
    "code": "1017",
        "scope": "classic",
        "color": {
        "hex": "#F6A950",
            "websafe": "#ff9966",
            "rgb": {
            "r": 246,
                "g": 169,
                "b": 80
        },
        "hsl": {
            "h": 32,
                "s": 90,
                "l": 64
        },
        "hsb": {
            "h": 32,
                "s": 67,
                "b": 96
        },
        "cmyk": {
            "c": "0",
                "m": "40",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Safrangelb",
            "en": "Saffron yellow",
            "fr": "Jaune safran",
            "es": "Amarillo azafrán",
            "it": "Giallo zafferano",
            "nl": "Saffraangeel"
    }
},
    "1018": {
    "code": "1018",
        "scope": "classic",
        "color": {
        "hex": "#FACA30",
            "websafe": "#ffcc33",
            "rgb": {
            "r": 250,
                "g": 202,
                "b": 48
        },
        "hsl": {
            "h": 46,
                "s": 95,
                "l": 58
        },
        "hsb": {
            "h": 46,
                "s": 81,
                "b": 98
        },
        "cmyk": {
            "c": "0",
                "m": "15",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Zinkgelb",
            "en": "Zinc yellow",
            "fr": "Jaune zinc",
            "es": "Amarillo de zinc",
            "it": "Giallo zinco",
            "nl": "Zinkgeel"
    }
},
    "1019": {
    "code": "1019",
        "scope": "classic",
        "color": {
        "hex": "#A48F7A",
            "websafe": "#999966",
            "rgb": {
            "r": 164,
                "g": 143,
                "b": 122
        },
        "hsl": {
            "h": 30,
                "s": 19,
                "l": 56
        },
        "hsb": {
            "h": 30,
                "s": 26,
                "b": 64
        },
        "cmyk": {
            "c": "0",
                "m": "15",
                "y": "40",
                "k": "45"
        }
    },
    "names": {
        "de": "Graubeige",
            "en": "Grey beige",
            "fr": "Beige gris",
            "es": "Beige agrisado",
            "it": "Beige grigiastro",
            "nl": "Grijsbeige"
    }
},
    "1020": {
    "code": "1020",
        "scope": "classic",
        "color": {
        "hex": "#A08F65",
            "websafe": "#999966",
            "rgb": {
            "r": 160,
                "g": 143,
                "b": 101
        },
        "hsl": {
            "h": 43,
                "s": 24,
                "l": 51
        },
        "hsb": {
            "h": 43,
                "s": 37,
                "b": 63
        },
        "cmyk": {
            "c": "30",
                "m": "25",
                "y": "60",
                "k": "20"
        }
    },
    "names": {
        "de": "Olivgelb",
            "en": "Olive yellow",
            "fr": "Jaune olive",
            "es": "Amarillo oliva",
            "it": "Giallo olivastro",
            "nl": "Olijfgeel"
    }
},
    "1021": {
    "code": "1021",
        "scope": "classic",
        "color": {
        "hex": "#F6B600",
            "websafe": "#ffcc00",
            "rgb": {
            "r": 246,
                "g": 182,
                "b": 0
        },
        "hsl": {
            "h": 44,
                "s": 100,
                "l": 48
        },
        "hsb": {
            "h": 44,
                "s": 100,
                "b": 96
        },
        "cmyk": {
            "c": "0",
                "m": "5",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Rapsgelb",
            "en": "Colza yellow",
            "fr": "Jaune colza",
            "es": "Amarillo colza",
            "it": "Giallo navone",
            "nl": "Koolzaadgeel"
    }
},
    "1023": {
    "code": "1023",
        "scope": "classic",
        "color": {
        "hex": "#F7B500",
            "websafe": "#ffcc00",
            "rgb": {
            "r": 247,
                "g": 181,
                "b": 0
        },
        "hsl": {
            "h": 44,
                "s": 100,
                "l": 48
        },
        "hsb": {
            "h": 44,
                "s": 100,
                "b": 97
        },
        "cmyk": {
            "c": "0",
                "m": "25",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Verkehrsgelb",
            "en": "Traffic yellow",
            "fr": "Jaune signalisation",
            "es": "Amarillo tráfico",
            "it": "Giallo traffico",
            "nl": "Verkeersgeel"
    }
},
    "1024": {
    "code": "1024",
        "scope": "classic",
        "color": {
        "hex": "#BA8F4C",
            "websafe": "#cc9933",
            "rgb": {
            "r": 186,
                "g": 143,
                "b": 76
        },
        "hsl": {
            "h": 37,
                "s": 44,
                "l": 51
        },
        "hsb": {
            "h": 37,
                "s": 59,
                "b": 73
        },
        "cmyk": {
            "c": "5",
                "m": "30",
                "y": "80",
                "k": "30"
        }
    },
    "names": {
        "de": "Ockergelb",
            "en": "Ochre yellow",
            "fr": "Jaune ocre",
            "es": "Amarillo ocre",
            "it": "Giallo ocra",
            "nl": "Okergeel"
    }
},
    "1026": {
    "code": "1026",
        "scope": "classic",
        "color": {
        "hex": "#FFFF00",
            "websafe": "#ffff00",
            "rgb": {
            "r": 255,
                "g": 255,
                "b": 0
        },
        "hsl": {
            "h": 60,
                "s": 100,
                "l": 50
        },
        "hsb": {
            "h": 60,
                "s": 100,
                "b": 100
        },
        "cmyk": {
            "c": "5",
                "m": "0",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Leuchtgelb",
            "en": "Luminous yellow",
            "fr": "Jaune brillant",
            "es": "Amarillo brillante",
            "it": "Giallo brillante",
            "nl": "Briljantgeel"
    }
},
    "1027": {
    "code": "1027",
        "scope": "classic",
        "color": {
        "hex": "#A77F0E",
            "websafe": "#996600",
            "rgb": {
            "r": 167,
                "g": 127,
                "b": 14
        },
        "hsl": {
            "h": 44,
                "s": 85,
                "l": 35
        },
        "hsb": {
            "h": 44,
                "s": 92,
                "b": 65
        },
        "cmyk": {
            "c": "5",
                "m": "10",
                "y": "100",
                "k": "40"
        }
    },
    "names": {
        "de": "Currygelb",
            "en": "Curry",
            "fr": "Jaune curry",
            "es": "Amarillo curry",
            "it": "Giallo curry",
            "nl": "Kerriegeel"
    }
},
    "1028": {
    "code": "1028",
        "scope": "classic",
        "color": {
        "hex": "#FF9B00",
            "websafe": "#ff9900",
            "rgb": {
            "r": 255,
                "g": 155,
                "b": 0
        },
        "hsl": {
            "h": 36,
                "s": 100,
                "l": 50
        },
        "hsb": {
            "h": 36,
                "s": 100,
                "b": 100
        },
        "cmyk": {
            "c": "0",
                "m": "40",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Melonengelb",
            "en": "Melon yellow",
            "fr": "Jaune melon",
            "es": "Amarillo melón",
            "it": "Giallo melone",
            "nl": "Meloengeel"
    }
},
    "1032": {
    "code": "1032",
        "scope": "classic",
        "color": {
        "hex": "#E2A300",
            "websafe": "#cc9900",
            "rgb": {
            "r": 226,
                "g": 163,
                "b": 0
        },
        "hsl": {
            "h": 43,
                "s": 100,
                "l": 44
        },
        "hsb": {
            "h": 43,
                "s": 100,
                "b": 89
        },
        "cmyk": {
            "c": "15",
                "m": "40",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Ginstergelb",
            "en": "Broom yellow",
            "fr": "Jaune genêt",
            "es": "Amarillo retama",
            "it": "Giallo scopa",
            "nl": "Bremgeel"
    }
},
    "1033": {
    "code": "1033",
        "scope": "classic",
        "color": {
        "hex": "#F99A1C",
            "websafe": "#ff9933",
            "rgb": {
            "r": 249,
                "g": 154,
                "b": 28
        },
        "hsl": {
            "h": 34,
                "s": 95,
                "l": 54
        },
        "hsb": {
            "h": 34,
                "s": 89,
                "b": 98
        },
        "cmyk": {
            "c": "0",
                "m": "50",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Dahliengelb",
            "en": "Dahlia yellow",
            "fr": "Jaune dahlia",
            "es": "Amarillo dalia",
            "it": "Giallo dahlien",
            "nl": "Dahliageel"
    }
},
    "1034": {
    "code": "1034",
        "scope": "classic",
        "color": {
        "hex": "#EB9C52",
            "websafe": "#ff9966",
            "rgb": {
            "r": 235,
                "g": 156,
                "b": 82
        },
        "hsl": {
            "h": 29,
                "s": 79,
                "l": 62
        },
        "hsb": {
            "h": 29,
                "s": 65,
                "b": 92
        },
        "cmyk": {
            "c": "0",
                "m": "45",
                "y": "70",
                "k": "0"
        }
    },
    "names": {
        "de": "Pastellgelb",
            "en": "Pastel yellow",
            "fr": "Jaune pastel",
            "es": "Amarillo pastel",
            "it": "Giallo pastello",
            "nl": "Pastelgeel"
    }
},
    "1035": {
    "code": "1035",
        "scope": "classic",
        "color": {
        "hex": "#908370",
            "websafe": "#999966",
            "rgb": {
            "r": 144,
                "g": 131,
                "b": 112
        },
        "hsl": {
            "h": 36,
                "s": 13,
                "l": 50
        },
        "hsb": {
            "h": 36,
                "s": 22,
                "b": 56
        },
        "cmyk": {
            "c": "10",
                "m": "25",
                "y": "40",
                "k": "40"
        }
    },
    "names": {
        "de": "Perlbeige",
            "en": "Pearl beige",
            "fr": "Beige nacré",
            "es": "Beige perlado",
            "it": "Beige perlato",
            "nl": "Parelmoer grijs"
    }
},
    "1036": {
    "code": "1036",
        "scope": "classic",
        "color": {
        "hex": "#80643F",
            "websafe": "#996633",
            "rgb": {
            "r": 128,
                "g": 100,
                "b": 63
        },
        "hsl": {
            "h": 34,
                "s": 34,
                "l": 37
        },
        "hsb": {
            "h": 34,
                "s": 51,
                "b": 50
        },
        "cmyk": {
            "c": "30",
                "m": "30",
                "y": "50",
                "k": "70"
        }
    },
    "names": {
        "de": "Perlgold",
            "en": "Pearl gold",
            "fr": "Or nacré",
            "es": "Oro perlado",
            "it": "Oro perlato",
            "nl": "Parelmoer goud"
    }
},
    "1037": {
    "code": "1037",
        "scope": "classic",
        "color": {
        "hex": "#F09200",
            "websafe": "#ff9900",
            "rgb": {
            "r": 240,
                "g": 146,
                "b": 0
        },
        "hsl": {
            "h": 37,
                "s": 100,
                "l": 47
        },
        "hsb": {
            "h": 37,
                "s": 100,
                "b": 94
        },
        "cmyk": {
            "c": "0",
                "m": "45",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Sonnengelb",
            "en": "Sun yellow",
            "fr": "Jaune soleil",
            "es": "Amarillo sol",
            "it": "Giallo sole",
            "nl": "Zonnegeel"
    }
},
    "2000": {
    "code": "2000",
        "scope": "classic",
        "color": {
        "hex": "#DA6E00",
            "websafe": "#cc6600",
            "rgb": {
            "r": 218,
                "g": 110,
                "b": 0
        },
        "hsl": {
            "h": 30,
                "s": 100,
                "l": 43
        },
        "hsb": {
            "h": 30,
                "s": 100,
                "b": 85
        },
        "cmyk": {
            "c": "0",
                "m": "65",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Gelborange",
            "en": "Yellow orange",
            "fr": "Orangé jaune",
            "es": "Amarillo naranja",
            "it": "Arancio giallastro",
            "nl": "Geeloranje"
    }
},
    "2001": {
    "code": "2001",
        "scope": "classic",
        "color": {
        "hex": "#BA481B",
            "websafe": "#cc3333",
            "rgb": {
            "r": 186,
                "g": 72,
                "b": 27
        },
        "hsl": {
            "h": 17,
                "s": 75,
                "l": 42
        },
        "hsb": {
            "h": 17,
                "s": 85,
                "b": 73
        },
        "cmyk": {
            "c": "10",
                "m": "85",
                "y": "100",
                "k": "10"
        }
    },
    "names": {
        "de": "Rotorange",
            "en": "Red orange",
            "fr": "Orangé rouge",
            "es": "Rojo anaranjado",
            "it": "Arancio rossastro",
            "nl": "Roodoranje"
    }
},
    "2002": {
    "code": "2002",
        "scope": "classic",
        "color": {
        "hex": "#BF3922",
            "websafe": "#cc3333",
            "rgb": {
            "r": 191,
                "g": 57,
                "b": 34
        },
        "hsl": {
            "h": 9,
                "s": 70,
                "l": 44
        },
        "hsb": {
            "h": 9,
                "s": 82,
                "b": 75
        },
        "cmyk": {
            "c": "0",
                "m": "90",
                "y": "100",
                "k": "10"
        }
    },
    "names": {
        "de": "Blutorange",
            "en": "Vermilion",
            "fr": "Orangé sang",
            "es": "Naranja sanguineo",
            "it": "Arancio sanguigno",
            "nl": "Vermiljoen"
    }
},
    "2003": {
    "code": "2003",
        "scope": "classic",
        "color": {
        "hex": "#F67828",
            "websafe": "#ff6633",
            "rgb": {
            "r": 246,
                "g": 120,
                "b": 40
        },
        "hsl": {
            "h": 23,
                "s": 92,
                "l": 56
        },
        "hsb": {
            "h": 23,
                "s": 84,
                "b": 96
        },
        "cmyk": {
            "c": "0",
                "m": "70",
                "y": "90",
                "k": "0"
        }
    },
    "names": {
        "de": "Pastellorange",
            "en": "Pastel orange",
            "fr": "Orangé pastel",
            "es": "Naranja pálido",
            "it": "Arancio pastello",
            "nl": "Pasteloranje"
    }
},
    "2004": {
    "code": "2004",
        "scope": "classic",
        "color": {
        "hex": "#E25303",
            "websafe": "#cc6600",
            "rgb": {
            "r": 226,
                "g": 83,
                "b": 3
        },
        "hsl": {
            "h": 22,
                "s": 97,
                "l": 45
        },
        "hsb": {
            "h": 22,
                "s": 99,
                "b": 89
        },
        "cmyk": {
            "c": "0",
                "m": "75",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Reinorange",
            "en": "Pure orange",
            "fr": "Orangé pur",
            "es": "Naranja puro",
            "it": "Arancio puro",
            "nl": "Zuiver oranje"
    }
},
    "2005": {
    "code": "2005",
        "scope": "classic",
        "color": {
        "hex": "#FF4D06",
            "websafe": "#ff6600",
            "rgb": {
            "r": 255,
                "g": 77,
                "b": 6
        },
        "hsl": {
            "h": 17,
                "s": 100,
                "l": 51
        },
        "hsb": {
            "h": 17,
                "s": 98,
                "b": 100
        },
        "cmyk": {
            "c": "0",
                "m": "70",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Leuchtorange",
            "en": "Luminous orange",
            "fr": "Orangé brillant",
            "es": "Naranja brillante",
            "it": "Arancio brillante",
            "nl": "Briljantoranje"
    }
},
    "2007": {
    "code": "2007",
        "scope": "classic",
        "color": {
        "hex": "#FFB200",
            "websafe": "#ff9900",
            "rgb": {
            "r": 255,
                "g": 178,
                "b": 0
        },
        "hsl": {
            "h": 42,
                "s": 100,
                "l": 50
        },
        "hsb": {
            "h": 42,
                "s": 100,
                "b": 100
        },
        "cmyk": {
            "c": "0",
                "m": "45",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Leuchthell orange",
            "en": "Luminous bright orange",
            "fr": "Orangé clair brillant",
            "es": "Naranja claro brillante",
            "it": "Arancio chiaro brillante",
            "nl": "Briljant lichtoranje"
    }
},
    "2008": {
    "code": "2008",
        "scope": "classic",
        "color": {
        "hex": "#ED6B21",
            "websafe": "#ff6633",
            "rgb": {
            "r": 237,
                "g": 107,
                "b": 33
        },
        "hsl": {
            "h": 22,
                "s": 85,
                "l": 53
        },
        "hsb": {
            "h": 22,
                "s": 86,
                "b": 93
        },
        "cmyk": {
            "c": "0",
                "m": "65",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Hellrotorange",
            "en": "Bright red orange",
            "fr": "Orangé rouge clair",
            "es": "Rojo claro anaranjado",
            "it": "Rosso arancio chiaro",
            "nl": "Licht roodoranje"
    }
},
    "2009": {
    "code": "2009",
        "scope": "classic",
        "color": {
        "hex": "#DE5307",
            "websafe": "#cc6600",
            "rgb": {
            "r": 222,
                "g": 83,
                "b": 7
        },
        "hsl": {
            "h": 21,
                "s": 94,
                "l": 45
        },
        "hsb": {
            "h": 21,
                "s": 97,
                "b": 87
        },
        "cmyk": {
            "c": "0",
                "m": "80",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Verkehrsorange",
            "en": "Traffic orange",
            "fr": "Orangé signalisation",
            "es": "Naranja tráfico",
            "it": "Arancio traffico",
            "nl": "Verkeersoranje"
    }
},
    "2010": {
    "code": "2010",
        "scope": "classic",
        "color": {
        "hex": "#D05D28",
            "websafe": "#cc6633",
            "rgb": {
            "r": 208,
                "g": 93,
                "b": 40
        },
        "hsl": {
            "h": 19,
                "s": 68,
                "l": 49
        },
        "hsb": {
            "h": 19,
                "s": 81,
                "b": 82
        },
        "cmyk": {
            "c": "0",
                "m": "80",
                "y": "100",
                "k": "10"
        }
    },
    "names": {
        "de": "Signalorange",
            "en": "Signal orange",
            "fr": "Orangé de sécurité",
            "es": "Naranja señales",
            "it": "Arancio segnale",
            "nl": "Signaaloranje"
    }
},
    "2011": {
    "code": "2011",
        "scope": "classic",
        "color": {
        "hex": "#E26E0E",
            "websafe": "#cc6600",
            "rgb": {
            "r": 226,
                "g": 110,
                "b": 14
        },
        "hsl": {
            "h": 27,
                "s": 88,
                "l": 47
        },
        "hsb": {
            "h": 27,
                "s": 94,
                "b": 89
        },
        "cmyk": {
            "c": "0",
                "m": "65",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Tieforange",
            "en": "Deep orange",
            "fr": "Orangé foncé",
            "es": "Naranja intenso",
            "it": "Arancio profondo",
            "nl": "Dieporanje"
    }
},
    "2012": {
    "code": "2012",
        "scope": "classic",
        "color": {
        "hex": "#D5654D",
            "websafe": "#cc6666",
            "rgb": {
            "r": 213,
                "g": 101,
                "b": 77
        },
        "hsl": {
            "h": 11,
                "s": 62,
                "l": 57
        },
        "hsb": {
            "h": 11,
                "s": 64,
                "b": 84
        },
        "cmyk": {
            "c": "10",
                "m": "80",
                "y": "70",
                "k": "0"
        }
    },
    "names": {
        "de": "Lachsorange",
            "en": "Salmon orange",
            "fr": "Orangé saumon",
            "es": "Naranja salmón",
            "it": "Arancio salmone",
            "nl": "Zalmoranje"
    }
},
    "2013": {
    "code": "2013",
        "scope": "classic",
        "color": {
        "hex": "#923E25",
            "websafe": "#993333",
            "rgb": {
            "r": 146,
                "g": 62,
                "b": 37
        },
        "hsl": {
            "h": 14,
                "s": 60,
                "l": 36
        },
        "hsb": {
            "h": 14,
                "s": 75,
                "b": 57
        },
        "cmyk": {
            "c": "20",
                "m": "90",
                "y": "100",
                "k": "30"
        }
    },
    "names": {
        "de": "Perlorange",
            "en": "Pearl orange",
            "fr": "Orangé nacré",
            "es": "Naranja perlado",
            "it": "Arancio perlato",
            "nl": "Parelmoer oranje"
    }
},
    "3000": {
    "code": "3000",
        "scope": "classic",
        "color": {
        "hex": "#A72920",
            "websafe": "#993333",
            "rgb": {
            "r": 167,
                "g": 41,
                "b": 32
        },
        "hsl": {
            "h": 4,
                "s": 68,
                "l": 39
        },
        "hsb": {
            "h": 4,
                "s": 81,
                "b": 65
        },
        "cmyk": {
            "c": "10",
                "m": "100",
                "y": "100",
                "k": "20"
        }
    },
    "names": {
        "de": "Feuerrot",
            "en": "Flame red",
            "fr": "Rouge feu",
            "es": "Rojo vivo",
            "it": "Rosso fuoco",
            "nl": "Vuurrood"
    }
},
    "3001": {
    "code": "3001",
        "scope": "classic",
        "color": {
        "hex": "#9B2423",
            "websafe": "#993333",
            "rgb": {
            "r": 155,
                "g": 36,
                "b": 35
        },
        "hsl": {
            "h": 0,
                "s": 63,
                "l": 37
        },
        "hsb": {
            "h": 0,
                "s": 77,
                "b": 61
        },
        "cmyk": {
            "c": "20",
                "m": "100",
                "y": "100",
                "k": "10"
        }
    },
    "names": {
        "de": "Signalrot",
            "en": "Signal red",
            "fr": "Rouge de sécurité",
            "es": "Rojo señales",
            "it": "Rosso segnale",
            "nl": "Signaalrood"
    }
},
    "3002": {
    "code": "3002",
        "scope": "classic",
        "color": {
        "hex": "#9B2321",
            "websafe": "#993333",
            "rgb": {
            "r": 155,
                "g": 35,
                "b": 33
        },
        "hsl": {
            "h": 1,
                "s": 65,
                "l": 37
        },
        "hsb": {
            "h": 1,
                "s": 79,
                "b": 61
        },
        "cmyk": {
            "c": "10",
                "m": "100",
                "y": "100",
                "k": "25"
        }
    },
    "names": {
        "de": "Karminrot",
            "en": "Carmine red",
            "fr": "Rouge carmin",
            "es": "Rojo carmin",
            "it": "Rosso carminio",
            "nl": "Karmijnrood"
    }
},
    "3003": {
    "code": "3003",
        "scope": "classic",
        "color": {
        "hex": "#861A22",
            "websafe": "#993333",
            "rgb": {
            "r": 134,
                "g": 26,
                "b": 34
        },
        "hsl": {
            "h": 356,
                "s": 68,
                "l": 31
        },
        "hsb": {
            "h": 356,
                "s": 81,
                "b": 53
        },
        "cmyk": {
            "c": "30",
                "m": "100",
                "y": "80",
                "k": "20"
        }
    },
    "names": {
        "de": "Rubinrot",
            "en": "Ruby red",
            "fr": "Rouge rubis",
            "es": "Rojo rubí",
            "it": "Rosso rubino",
            "nl": "Robijnrood"
    }
},
    "3004": {
    "code": "3004",
        "scope": "classic",
        "color": {
        "hex": "#6B1C23",
            "websafe": "#663333",
            "rgb": {
            "r": 107,
                "g": 28,
                "b": 35
        },
        "hsl": {
            "h": 355,
                "s": 59,
                "l": 26
        },
        "hsb": {
            "h": 355,
                "s": 74,
                "b": 42
        },
        "cmyk": {
            "c": "30",
                "m": "100",
                "y": "70",
                "k": "40"
        }
    },
    "names": {
        "de": "Purpurrot",
            "en": "Purple red",
            "fr": "Rouge pourpre",
            "es": "Rojo púrpura",
            "it": "Rosso porpora",
            "nl": "Purperrood"
    }
},
    "3005": {
    "code": "3005",
        "scope": "classic",
        "color": {
        "hex": "#59191F",
            "websafe": "#660033",
            "rgb": {
            "r": 89,
                "g": 25,
                "b": 31
        },
        "hsl": {
            "h": 354,
                "s": 56,
                "l": 22
        },
        "hsb": {
            "h": 354,
                "s": 72,
                "b": 35
        },
        "cmyk": {
            "c": "40",
                "m": "100",
                "y": "60",
                "k": "40"
        }
    },
    "names": {
        "de": "Weinrot",
            "en": "Wine red",
            "fr": "Rouge vin",
            "es": "Rojo vino",
            "it": "Rosso vino",
            "nl": "Wijnrood"
    }
},
    "3007": {
    "code": "3007",
        "scope": "classic",
        "color": {
        "hex": "#3E2022",
            "websafe": "#333333",
            "rgb": {
            "r": 62,
                "g": 32,
                "b": 34
        },
        "hsl": {
            "h": 356,
                "s": 32,
                "l": 18
        },
        "hsb": {
            "h": 356,
                "s": 48,
                "b": 24
        },
        "cmyk": {
            "c": "50",
                "m": "100",
                "y": "40",
                "k": "50"
        }
    },
    "names": {
        "de": "Schwarzrot",
            "en": "Black red",
            "fr": "Rouge noir",
            "es": "Rojo negruzco",
            "it": "Rosso nerastro",
            "nl": "Zwartrood"
    }
},
    "3009": {
    "code": "3009",
        "scope": "classic",
        "color": {
        "hex": "#6D342D",
            "websafe": "#663333",
            "rgb": {
            "r": 109,
                "g": 52,
                "b": 45
        },
        "hsl": {
            "h": 7,
                "s": 42,
                "l": 30
        },
        "hsb": {
            "h": 7,
                "s": 59,
                "b": 43
        },
        "cmyk": {
            "c": "40",
                "m": "100",
                "y": "100",
                "k": "30"
        }
    },
    "names": {
        "de": "Oxidrot",
            "en": "Oxide red",
            "fr": "Rouge oxyde",
            "es": "Rojo óxido",
            "it": "Rosso ossido",
            "nl": "Oxyderood"
    }
},
    "3011": {
    "code": "3011",
        "scope": "classic",
        "color": {
        "hex": "#792423",
            "websafe": "#663333",
            "rgb": {
            "r": 121,
                "g": 36,
                "b": 35
        },
        "hsl": {
            "h": 1,
                "s": 55,
                "l": 31
        },
        "hsb": {
            "h": 1,
                "s": 71,
                "b": 47
        },
        "cmyk": {
            "c": "20",
                "m": "100",
                "y": "80",
                "k": "40"
        }
    },
    "names": {
        "de": "Braunrot",
            "en": "Brown red",
            "fr": "Rouge brun",
            "es": "Rojo pardo",
            "it": "Rosso marrone",
            "nl": "Bruinrood"
    }
},
    "3012": {
    "code": "3012",
        "scope": "classic",
        "color": {
        "hex": "#C6846D",
            "websafe": "#cc9966",
            "rgb": {
            "r": 198,
                "g": 132,
                "b": 109
        },
        "hsl": {
            "h": 16,
                "s": 44,
                "l": 60
        },
        "hsb": {
            "h": 16,
                "s": 45,
                "b": 78
        },
        "cmyk": {
            "c": "0",
                "m": "50",
                "y": "50",
                "k": "20"
        }
    },
    "names": {
        "de": "Beigerot",
            "en": "Beige red",
            "fr": "Rouge beige",
            "es": "Rojo beige",
            "it": "Rosso beige",
            "nl": "Beigerood"
    }
},
    "3013": {
    "code": "3013",
        "scope": "classic",
        "color": {
        "hex": "#972E25",
            "websafe": "#993333",
            "rgb": {
            "r": 151,
                "g": 46,
                "b": 37
        },
        "hsl": {
            "h": 5,
                "s": 61,
                "l": 37
        },
        "hsb": {
            "h": 5,
                "s": 75,
                "b": 59
        },
        "cmyk": {
            "c": "0",
                "m": "100",
                "y": "90",
                "k": "30"
        }
    },
    "names": {
        "de": "Tomatenrot",
            "en": "Tomato red",
            "fr": "Rouge tomate",
            "es": "Rojo tomate",
            "it": "Rosso pomodoro",
            "nl": "Tomaatrood"
    }
},
    "3014": {
    "code": "3014",
        "scope": "classic",
        "color": {
        "hex": "#CB7375",
            "websafe": "#cc6666",
            "rgb": {
            "r": 203,
                "g": 115,
                "b": 117
        },
        "hsl": {
            "h": 359,
                "s": 46,
                "l": 62
        },
        "hsb": {
            "h": 359,
                "s": 43,
                "b": 80
        },
        "cmyk": {
            "c": "0",
                "m": "65",
                "y": "40",
                "k": "10"
        }
    },
    "names": {
        "de": "Altrosa",
            "en": "Antique pink",
            "fr": "Vieux rose",
            "es": "Rojo viejo",
            "it": "Rosa antico",
            "nl": "Oudroze"
    }
},
    "3015": {
    "code": "3015",
        "scope": "classic",
        "color": {
        "hex": "#D8A0A6",
            "websafe": "#cc9999",
            "rgb": {
            "r": 216,
                "g": 160,
                "b": 166
        },
        "hsl": {
            "h": 354,
                "s": 42,
                "l": 74
        },
        "hsb": {
            "h": 354,
                "s": 26,
                "b": 85
        },
        "cmyk": {
            "c": "5",
                "m": "40",
                "y": "20",
                "k": "0"
        }
    },
    "names": {
        "de": "Hellrosa",
            "en": "Light pink",
            "fr": "Rose clair",
            "es": "Rosa claro",
            "it": "Rosa chiaro",
            "nl": "Lichtroze"
    }
},
    "3016": {
    "code": "3016",
        "scope": "classic",
        "color": {
        "hex": "#A63D2F",
            "websafe": "#993333",
            "rgb": {
            "r": 166,
                "g": 61,
                "b": 47
        },
        "hsl": {
            "h": 7,
                "s": 56,
                "l": 42
        },
        "hsb": {
            "h": 7,
                "s": 72,
                "b": 65
        },
        "cmyk": {
            "c": "0",
                "m": "90",
                "y": "90",
                "k": "30"
        }
    },
    "names": {
        "de": "Korallenrot",
            "en": "Coral red",
            "fr": "Rouge corail",
            "es": "Rojo coral",
            "it": "Rosso corallo",
            "nl": "Koraalrood"
    }
},
    "3017": {
    "code": "3017",
        "scope": "classic",
        "color": {
        "hex": "#CB555D",
            "websafe": "#cc6666",
            "rgb": {
            "r": 203,
                "g": 85,
                "b": 93
        },
        "hsl": {
            "h": 356,
                "s": 53,
                "l": 56
        },
        "hsb": {
            "h": 356,
                "s": 58,
                "b": 80
        },
        "cmyk": {
            "c": "0",
                "m": "80",
                "y": "50",
                "k": "10"
        }
    },
    "names": {
        "de": "Rosé",
            "en": "Rose",
            "fr": "Rosé",
            "es": "Rosa",
            "it": "Rosato",
            "nl": "Bleekrood"
    }
},
    "3018": {
    "code": "3018",
        "scope": "classic",
        "color": {
        "hex": "#C73F4A",
            "websafe": "#cc3333",
            "rgb": {
            "r": 199,
                "g": 63,
                "b": 74
        },
        "hsl": {
            "h": 355,
                "s": 55,
                "l": 51
        },
        "hsb": {
            "h": 355,
                "s": 68,
                "b": 78
        },
        "cmyk": {
            "c": "5",
                "m": "100",
                "y": "60",
                "k": "0"
        }
    },
    "names": {
        "de": "Erdbeerrot",
            "en": "Strawberry red",
            "fr": "Rouge fraise",
            "es": "Rojo fresa",
            "it": "Rosso fragola",
            "nl": "Aardbeirood"
    }
},
    "3020": {
    "code": "3020",
        "scope": "classic",
        "color": {
        "hex": "#BB1E10",
            "websafe": "#cc3300",
            "rgb": {
            "r": 187,
                "g": 30,
                "b": 16
        },
        "hsl": {
            "h": 5,
                "s": 84,
                "l": 40
        },
        "hsb": {
            "h": 5,
                "s": 91,
                "b": 73
        },
        "cmyk": {
            "c": "0",
                "m": "100",
                "y": "100",
                "k": "10"
        }
    },
    "names": {
        "de": "Verkehrsrot",
            "en": "Traffic red",
            "fr": "Rouge signalisation",
            "es": "Rojo tráfico",
            "it": "Rosso traffico",
            "nl": "Verkeersrood"
    }
},
    "3022": {
    "code": "3022",
        "scope": "classic",
        "color": {
        "hex": "#CF6955",
            "websafe": "#cc6666",
            "rgb": {
            "r": 207,
                "g": 105,
                "b": 85
        },
        "hsl": {
            "h": 10,
                "s": 56,
                "l": 57
        },
        "hsb": {
            "h": 10,
                "s": 59,
                "b": 81
        },
        "cmyk": {
            "c": "0",
                "m": "70",
                "y": "60",
                "k": "5"
        }
    },
    "names": {
        "de": "Lachsrot",
            "en": "Salmon pink",
            "fr": "Rouge saumon",
            "es": "Rojo salmón",
            "it": "Rosso salmone",
            "nl": "Zalmrood"
    }
},
    "3024": {
    "code": "3024",
        "scope": "classic",
        "color": {
        "hex": "#FF2D21",
            "websafe": "#ff3333",
            "rgb": {
            "r": 255,
                "g": 45,
                "b": 33
        },
        "hsl": {
            "h": 3,
                "s": 100,
                "l": 56
        },
        "hsb": {
            "h": 3,
                "s": 87,
                "b": 100
        },
        "cmyk": {
            "c": "0",
                "m": "90",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Leuchtrot",
            "en": "Luminous red",
            "fr": "Rouge brillant",
            "es": "Rojo brillante",
            "it": "Rosso brillante",
            "nl": "Briljantrood"
    }
},
    "3026": {
    "code": "3026",
        "scope": "classic",
        "color": {
        "hex": "#FF2A1B",
            "websafe": "#ff3333",
            "rgb": {
            "r": 255,
                "g": 42,
                "b": 27
        },
        "hsl": {
            "h": 4,
                "s": 100,
                "l": 55
        },
        "hsb": {
            "h": 4,
                "s": 89,
                "b": 100
        },
        "cmyk": {
            "c": "0",
                "m": "80",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Leuchthellrot",
            "en": "Luminous bright red",
            "fr": "Rouge clair brillant",
            "es": "Rojo claro brillante",
            "it": "Rosso chiaro brillante",
            "nl": "Briljant lichtrood"
    }
},
    "3027": {
    "code": "3027",
        "scope": "classic",
        "color": {
        "hex": "#AB273C",
            "websafe": "#993333",
            "rgb": {
            "r": 171,
                "g": 39,
                "b": 60
        },
        "hsl": {
            "h": 350,
                "s": 63,
                "l": 41
        },
        "hsb": {
            "h": 350,
                "s": 77,
                "b": 67
        },
        "cmyk": {
            "c": "0",
                "m": "100",
                "y": "50",
                "k": "20"
        }
    },
    "names": {
        "de": "Himbeerrot",
            "en": "Raspberry red",
            "fr": "Rouge framboise",
            "es": "Rojo frambuesa",
            "it": "Rosso lampone",
            "nl": "Framboosrood"
    }
},
    "3028": {
    "code": "3028",
        "scope": "classic",
        "color": {
        "hex": "#CC2C24",
            "websafe": "#cc3333",
            "rgb": {
            "r": 204,
                "g": 44,
                "b": 36
        },
        "hsl": {
            "h": 3,
                "s": 70,
                "l": 47
        },
        "hsb": {
            "h": 3,
                "s": 82,
                "b": 80
        },
        "cmyk": {
            "c": "0",
                "m": "100",
                "y": "90",
                "k": "0"
        }
    },
    "names": {
        "de": "Reinrot",
            "en": "Pure red",
            "fr": "Rouge pu",
            "es": "Rojo puro ",
            "it": "Rosso puro",
            "nl": "Zuiver rood "
    }
},
    "3031": {
    "code": "3031",
        "scope": "classic",
        "color": {
        "hex": "#A63437",
            "websafe": "#993333",
            "rgb": {
            "r": 166,
                "g": 52,
                "b": 55
        },
        "hsl": {
            "h": 358,
                "s": 52,
                "l": 43
        },
        "hsb": {
            "h": 358,
                "s": 69,
                "b": 65
        },
        "cmyk": {
            "c": "20",
                "m": "100",
                "y": "70",
                "k": "10"
        }
    },
    "names": {
        "de": "Orientrot",
            "en": "Orient red",
            "fr": "Rouge oriental",
            "es": "Rojo oriente",
            "it": "Rosso oriente",
            "nl": "Oriëntrood"
    }
},
    "3032": {
    "code": "3032",
        "scope": "classic",
        "color": {
        "hex": "#701D23",
            "websafe": "#663333",
            "rgb": {
            "r": 112,
                "g": 29,
                "b": 35
        },
        "hsl": {
            "h": 356,
                "s": 59,
                "l": 28
        },
        "hsb": {
            "h": 356,
                "s": 74,
                "b": 44
        },
        "cmyk": {
            "c": "10",
                "m": "100",
                "y": "60",
                "k": "40"
        }
    },
    "names": {
        "de": "Perlrubinrot",
            "en": "Pearl ruby red",
            "fr": "Rouge rubis nacré",
            "es": "Rojo rubí perlado",
            "it": "Rosso rubino perlato",
            "nl": "Parelmoer donkerrood"
    }
},
    "3033": {
    "code": "3033",
        "scope": "classic",
        "color": {
        "hex": "#A53A2D",
            "websafe": "#993333",
            "rgb": {
            "r": 165,
                "g": 58,
                "b": 45
        },
        "hsl": {
            "h": 7,
                "s": 57,
                "l": 41
        },
        "hsb": {
            "h": 7,
                "s": 73,
                "b": 65
        },
        "cmyk": {
            "c": "10",
                "m": "90",
                "y": "90",
                "k": "20"
        }
    },
    "names": {
        "de": "Perlrosa",
            "en": "Pearl pink",
            "fr": "Rose nacré",
            "es": "Rosa perlado",
            "it": "Rosa perlato",
            "nl": "Parelmoer lichtrood"
    }
},
    "4001": {
    "code": "4001",
        "scope": "classic",
        "color": {
        "hex": "#816183",
            "websafe": "#996699",
            "rgb": {
            "r": 129,
                "g": 97,
                "b": 131
        },
        "hsl": {
            "h": 296,
                "s": 15,
                "l": 45
        },
        "hsb": {
            "h": 296,
                "s": 26,
                "b": 51
        },
        "cmyk": {
            "c": "30",
                "m": "60",
                "y": "10",
                "k": "20"
        }
    },
    "names": {
        "de": "Rotlila",
            "en": "Red lilac",
            "fr": "Lilas rouge",
            "es": "Rojo lila",
            "it": "Lilla rossastro",
            "nl": "Roodlila"
    }
},
    "4002": {
    "code": "4002",
        "scope": "classic",
        "color": {
        "hex": "#8D3C4B",
            "websafe": "#993333",
            "rgb": {
            "r": 141,
                "g": 60,
                "b": 75
        },
        "hsl": {
            "h": 349,
                "s": 40,
                "l": 39
        },
        "hsb": {
            "h": 349,
                "s": 57,
                "b": 55
        },
        "cmyk": {
            "c": "25",
                "m": "90",
                "y": "50",
                "k": "30"
        }
    },
    "names": {
        "de": "Rotviolett",
            "en": "Red violet",
            "fr": "Violet rouge",
            "es": "Rojo violeta",
            "it": "Viola rossastro",
            "nl": "Roodpaars"
    }
},
    "4003": {
    "code": "4003",
        "scope": "classic",
        "color": {
        "hex": "#C4618C",
            "websafe": "#cc6699",
            "rgb": {
            "r": 196,
                "g": 97,
                "b": 140
        },
        "hsl": {
            "h": 334,
                "s": 46,
                "l": 57
        },
        "hsb": {
            "h": 334,
                "s": 51,
                "b": 77
        },
        "cmyk": {
            "c": "10",
                "m": "80",
                "y": "5",
                "k": "5"
        }
    },
    "names": {
        "de": "Erikaviolett",
            "en": "Heather violet",
            "fr": "Violet bruyère",
            "es": "Violeta érica",
            "it": "Viola erica",
            "nl": "Heidepaars"
    }
},
    "4004": {
    "code": "4004",
        "scope": "classic",
        "color": {
        "hex": "#651E38",
            "websafe": "#663333",
            "rgb": {
            "r": 101,
                "g": 30,
                "b": 56
        },
        "hsl": {
            "h": 338,
                "s": 54,
                "l": 26
        },
        "hsb": {
            "h": 338,
                "s": 70,
                "b": 40
        },
        "cmyk": {
            "c": "30",
                "m": "100",
                "y": "30",
                "k": "60"
        }
    },
    "names": {
        "de": "Bordeauxviolett",
            "en": "Claret violet",
            "fr": "Violet bordeaux",
            "es": "Burdeos",
            "it": "Viola bordeaux",
            "nl": "Bordeuaxpaars"
    }
},
    "4005": {
    "code": "4005",
        "scope": "classic",
        "color": {
        "hex": "#76689A",
            "websafe": "#666699",
            "rgb": {
            "r": 118,
                "g": 104,
                "b": 154
        },
        "hsl": {
            "h": 257,
                "s": 20,
                "l": 51
        },
        "hsb": {
            "h": 257,
                "s": 32,
                "b": 60
        },
        "cmyk": {
            "c": "50",
                "m": "60",
                "y": "0",
                "k": "0"
        }
    },
    "names": {
        "de": "Blaulila",
            "en": "Blue lilac",
            "fr": "Lilas bleu",
            "es": "Lila azulado",
            "it": "Lilla bluastro",
            "nl": "Blauwlila"
    }
},
    "4006": {
    "code": "4006",
        "scope": "classic",
        "color": {
        "hex": "#903373",
            "websafe": "#993366",
            "rgb": {
            "r": 144,
                "g": 51,
                "b": 115
        },
        "hsl": {
            "h": 319,
                "s": 48,
                "l": 38
        },
        "hsb": {
            "h": 319,
                "s": 65,
                "b": 56
        },
        "cmyk": {
            "c": "40",
                "m": "100",
                "y": "0",
                "k": "15"
        }
    },
    "names": {
        "de": "Verkehrspurpur",
            "en": "Traffic purple",
            "fr": "Pourpre signalisation",
            "es": "Púrpurá tráfico",
            "it": "Porpora traffico",
            "nl": "Verkeerspurper"
    }
},
    "4007": {
    "code": "4007",
        "scope": "classic",
        "color": {
        "hex": "#47243C",
            "websafe": "#333333",
            "rgb": {
            "r": 71,
                "g": 36,
                "b": 60
        },
        "hsl": {
            "h": 319,
                "s": 33,
                "l": 21
        },
        "hsb": {
            "h": 319,
                "s": 49,
                "b": 28
        },
        "cmyk": {
            "c": "70",
                "m": "100",
                "y": "10",
                "k": "70"
        }
    },
    "names": {
        "de": "Purpurviolett",
            "en": "Purple violet",
            "fr": "Violet pourpre",
            "es": "Violeta púrpura",
            "it": "Porpora violetto",
            "nl": "Purperviolet"
    }
},
    "4008": {
    "code": "4008",
        "scope": "classic",
        "color": {
        "hex": "#844C82",
            "websafe": "#993399",
            "rgb": {
            "r": 132,
                "g": 76,
                "b": 130
        },
        "hsl": {
            "h": 302,
                "s": 27,
                "l": 41
        },
        "hsb": {
            "h": 302,
                "s": 42,
                "b": 52
        },
        "cmyk": {
            "c": "50",
                "m": "90",
                "y": "0",
                "k": "5"
        }
    },
    "names": {
        "de": "Signalviolett",
            "en": "Signal violet",
            "fr": "Violet de sécurité",
            "es": "Violeta señales",
            "it": "Violetto segnale",
            "nl": "Signaalviolet"
    }
},
    "4009": {
    "code": "4009",
        "scope": "classic",
        "color": {
        "hex": "#9D8692",
            "websafe": "#999999",
            "rgb": {
            "r": 157,
                "g": 134,
                "b": 146
        },
        "hsl": {
            "h": 329,
                "s": 11,
                "l": 57
        },
        "hsb": {
            "h": 329,
                "s": 15,
                "b": 62
        },
        "cmyk": {
            "c": "35",
                "m": "50",
                "y": "30",
                "k": "10"
        }
    },
    "names": {
        "de": "Pastellviolett",
            "en": "Pastel violet",
            "fr": "Violet pastel",
            "es": "Violeta pastel",
            "it": "Violetto pastello",
            "nl": "Pastelviolet"
    }
},
    "4010": {
    "code": "4010",
        "scope": "classic",
        "color": {
        "hex": "#BC4077",
            "websafe": "#cc3366",
            "rgb": {
            "r": 188,
                "g": 64,
                "b": 119
        },
        "hsl": {
            "h": 333,
                "s": 49,
                "l": 49
        },
        "hsb": {
            "h": 333,
                "s": 66,
                "b": 74
        },
        "cmyk": {
            "c": "15",
                "m": "100",
                "y": "15",
                "k": "10"
        }
    },
    "names": {
        "de": "Telemagenta",
            "en": "Telemagenta",
            "fr": "Telemagenta",
            "es": "Magenta tele",
            "it": "Tele Magenta",
            "nl": "Telemagenta"
    }
},
    "4011": {
    "code": "4011",
        "scope": "classic",
        "color": {
        "hex": "#6E6387",
            "websafe": "#666699",
            "rgb": {
            "r": 110,
                "g": 99,
                "b": 135
        },
        "hsl": {
            "h": 258,
                "s": 15,
                "l": 46
        },
        "hsb": {
            "h": 258,
                "s": 27,
                "b": 53
        },
        "cmyk": {
            "c": "50",
                "m": "60",
                "y": "0",
                "k": "20"
        }
    },
    "names": {
        "de": "Perlviolett",
            "en": "Pearl violet",
            "fr": "Violet nacré",
            "es": "Violeta perlado",
            "it": "Violetto perlato",
            "nl": "Parelmoer donkerviolet"
    }
},
    "4012": {
    "code": "4012",
        "scope": "classic",
        "color": {
        "hex": "#6B6B7F",
            "websafe": "#666666",
            "rgb": {
            "r": 107,
                "g": 107,
                "b": 127
        },
        "hsl": {
            "h": 240,
                "s": 9,
                "l": 46
        },
        "hsb": {
            "h": 240,
                "s": 16,
                "b": 50
        },
        "cmyk": {
            "c": "40",
                "m": "35",
                "y": "0",
                "k": "40"
        }
    },
    "names": {
        "de": "Perlbrombeer",
            "en": "Pearl blackberry",
            "fr": "Mûre nacré",
            "es": "Morado perlado",
            "it": "Mora perlato",
            "nl": "Parelmoer lichtviolet"
    }
},
    "5000": {
    "code": "5000",
        "scope": "classic",
        "color": {
        "hex": "#314F6F",
            "websafe": "#336666",
            "rgb": {
            "r": 49,
                "g": 79,
                "b": 111
        },
        "hsl": {
            "h": 211,
                "s": 39,
                "l": 31
        },
        "hsb": {
            "h": 211,
                "s": 56,
                "b": 44
        },
        "cmyk": {
            "c": "100",
                "m": "80",
                "y": "40",
                "k": "5"
        }
    },
    "names": {
        "de": "Violettblau",
            "en": "Violet blue",
            "fr": "Bleu violet",
            "es": "Azul violeta",
            "it": "Blu violaceo",
            "nl": "Paarsblauw"
    }
},
    "5001": {
    "code": "5001",
        "scope": "classic",
        "color": {
        "hex": "#0F4C64",
            "websafe": "#003366",
            "rgb": {
            "r": 15,
                "g": 76,
                "b": 100
        },
        "hsl": {
            "h": 197,
                "s": 74,
                "l": 23
        },
        "hsb": {
            "h": 197,
                "s": 85,
                "b": 39
        },
        "cmyk": {
            "c": "100",
                "m": "60",
                "y": "30",
                "k": "40"
        }
    },
    "names": {
        "de": "Grünblau",
            "en": "Green blue",
            "fr": "Bleu vert",
            "es": "Azul verdoso",
            "it": "Blu verdastro",
            "nl": "Groenblauw"
    }
},
    "5002": {
    "code": "5002",
        "scope": "classic",
        "color": {
        "hex": "#00387B",
            "websafe": "#003366",
            "rgb": {
            "r": 0,
                "g": 56,
                "b": 123
        },
        "hsl": {
            "h": 213,
                "s": 100,
                "l": 24
        },
        "hsb": {
            "h": 213,
                "s": 100,
                "b": 48
        },
        "cmyk": {
            "c": "100",
                "m": "90",
                "y": "0",
                "k": "10"
        }
    },
    "names": {
        "de": "Ultramarinblau",
            "en": "Ultramarine blue",
            "fr": "Bleu outremer",
            "es": "Azul ultramar",
            "it": "Blu oltremare",
            "nl": "Ultramarijn blauw"
    }
},
    "5003": {
    "code": "5003",
        "scope": "classic",
        "color": {
        "hex": "#1F3855",
            "websafe": "#333366",
            "rgb": {
            "r": 31,
                "g": 56,
                "b": 85
        },
        "hsl": {
            "h": 212,
                "s": 47,
                "l": 23
        },
        "hsb": {
            "h": 212,
                "s": 64,
                "b": 33
        },
        "cmyk": {
            "c": "70",
                "m": "30",
                "y": "10",
                "k": "80"
        }
    },
    "names": {
        "de": "Saphirblau",
            "en": "Sapphire blue",
            "fr": "Bleu saphir",
            "es": "Azul zafiro",
            "it": "Blu zaffiro",
            "nl": "Saffierblauw"
    }
},
    "5004": {
    "code": "5004",
        "scope": "classic",
        "color": {
        "hex": "#191E28",
            "websafe": "#003333",
            "rgb": {
            "r": 25,
                "g": 30,
                "b": 40
        },
        "hsl": {
            "h": 220,
                "s": 23,
                "l": 13
        },
        "hsb": {
            "h": 220,
                "s": 38,
                "b": 16
        },
        "cmyk": {
            "c": "70",
                "m": "30",
                "y": "10",
                "k": "80"
        }
    },
    "names": {
        "de": "Schwarzblau",
            "en": "Black blue",
            "fr": "Bleu noir",
            "es": "Azul negruzco",
            "it": "Blu nerastro",
            "nl": "Zwartblauw"
    }
},
    "5005": {
    "code": "5005",
        "scope": "classic",
        "color": {
        "hex": "#005387",
            "websafe": "#006699",
            "rgb": {
            "r": 0,
                "g": 83,
                "b": 135
        },
        "hsl": {
            "h": 203,
                "s": 100,
                "l": 26
        },
        "hsb": {
            "h": 203,
                "s": 100,
                "b": 53
        },
        "cmyk": {
            "c": "95",
                "m": "60",
                "y": "0",
                "k": "20"
        }
    },
    "names": {
        "de": "Signalblau",
            "en": "Signal blue",
            "fr": "Bleu de sécurité",
            "es": "Azul señales",
            "it": "Blu segnale",
            "nl": "Signaalblauw"
    }
},
    "5007": {
    "code": "5007",
        "scope": "classic",
        "color": {
        "hex": "#376B8C",
            "websafe": "#336699",
            "rgb": {
            "r": 55,
                "g": 107,
                "b": 140
        },
        "hsl": {
            "h": 203,
                "s": 44,
                "l": 38
        },
        "hsb": {
            "h": 203,
                "s": 61,
                "b": 55
        },
        "cmyk": {
            "c": "80",
                "m": "50",
                "y": "20",
                "k": "20"
        }
    },
    "names": {
        "de": "Brillantblau",
            "en": "Brilliant blue",
            "fr": "Bleu brillant",
            "es": "Azul brillante",
            "it": "Blu brillante",
            "nl": "Briljantblauw"
    }
},
    "5008": {
    "code": "5008",
        "scope": "classic",
        "color": {
        "hex": "#2B3A44",
            "websafe": "#333333",
            "rgb": {
            "r": 43,
                "g": 58,
                "b": 68
        },
        "hsl": {
            "h": 204,
                "s": 23,
                "l": 22
        },
        "hsb": {
            "h": 204,
                "s": 37,
                "b": 27
        },
        "cmyk": {
            "c": "100",
                "m": "90",
                "y": "60",
                "k": "30"
        }
    },
    "names": {
        "de": "Graublau",
            "en": "Grey blue",
            "fr": "Bleu gris",
            "es": "Azul grisáceo",
            "it": "Blu grigiastro",
            "nl": "Grijsblauw"
    }
},
    "5009": {
    "code": "5009",
        "scope": "classic",
        "color": {
        "hex": "#225F78",
            "websafe": "#336666",
            "rgb": {
            "r": 34,
                "g": 95,
                "b": 120
        },
        "hsl": {
            "h": 197,
                "s": 56,
                "l": 30
        },
        "hsb": {
            "h": 197,
                "s": 72,
                "b": 47
        },
        "cmyk": {
            "c": "90",
                "m": "40",
                "y": "0",
                "k": "30"
        }
    },
    "names": {
        "de": "Azurblau",
            "en": "Azure blue",
            "fr": "Bleu azur",
            "es": "Azul azur",
            "it": "Blu azzurro",
            "nl": "Azuurblauw"
    }
},
    "5010": {
    "code": "5010",
        "scope": "classic",
        "color": {
        "hex": "#004F7C",
            "websafe": "#006666",
            "rgb": {
            "r": 0,
                "g": 79,
                "b": 124
        },
        "hsl": {
            "h": 202,
                "s": 100,
                "l": 24
        },
        "hsb": {
            "h": 202,
                "s": 100,
                "b": 49
        },
        "cmyk": {
            "c": "100",
                "m": "70",
                "y": "5",
                "k": "15"
        }
    },
    "names": {
        "de": "Enzianblau",
            "en": "Gentian blue",
            "fr": "Bleu gentiane",
            "es": "Azul genciana",
            "it": "Blu genziana",
            "nl": "Gentiaanblauw"
    }
},
    "5011": {
    "code": "5011",
        "scope": "classic",
        "color": {
        "hex": "#1A2B3C",
            "websafe": "#333333",
            "rgb": {
            "r": 26,
                "g": 43,
                "b": 60
        },
        "hsl": {
            "h": 210,
                "s": 40,
                "l": 17
        },
        "hsb": {
            "h": 210,
                "s": 57,
                "b": 24
        },
        "cmyk": {
            "c": "100",
                "m": "100",
                "y": "60",
                "k": "30"
        }
    },
    "names": {
        "de": "Stahlblau",
            "en": "Steel blue",
            "fr": "Bleu acier",
            "es": "Azul acero",
            "it": "Blu acciaio",
            "nl": "Staalblauw"
    }
},
    "5012": {
    "code": "5012",
        "scope": "classic",
        "color": {
        "hex": "#0089B6",
            "websafe": "#0099cc",
            "rgb": {
            "r": 0,
                "g": 137,
                "b": 182
        },
        "hsl": {
            "h": 195,
                "s": 100,
                "l": 36
        },
        "hsb": {
            "h": 195,
                "s": 100,
                "b": 71
        },
        "cmyk": {
            "c": "75",
                "m": "30",
                "y": "0",
                "k": "10"
        }
    },
    "names": {
        "de": "Lichtblau",
            "en": "Light blue",
            "fr": "Bleu clair",
            "es": "Azul luminoso",
            "it": "Blu luce",
            "nl": "Lichtblauw"
    }
},
    "5013": {
    "code": "5013",
        "scope": "classic",
        "color": {
        "hex": "#193153",
            "websafe": "#003366",
            "rgb": {
            "r": 25,
                "g": 49,
                "b": 83
        },
        "hsl": {
            "h": 215,
                "s": 54,
                "l": 21
        },
        "hsb": {
            "h": 215,
                "s": 70,
                "b": 33
        },
        "cmyk": {
            "c": "100",
                "m": "80",
                "y": "10",
                "k": "50"
        }
    },
    "names": {
        "de": "Kobaltblau",
            "en": "Cobalt blue",
            "fr": "Bleu cobalt",
            "es": "Azul cobalto",
            "it": "Blu cobalto",
            "nl": "Kobaltblauw"
    }
},
    "5014": {
    "code": "5014",
        "scope": "classic",
        "color": {
        "hex": "#637D96",
            "websafe": "#666699",
            "rgb": {
            "r": 99,
                "g": 125,
                "b": 150
        },
        "hsl": {
            "h": 209,
                "s": 20,
                "l": 49
        },
        "hsb": {
            "h": 209,
                "s": 34,
                "b": 59
        },
        "cmyk": {
            "c": "50",
                "m": "30",
                "y": "10",
                "k": "30"
        }
    },
    "names": {
        "de": "Taubenblau",
            "en": "Pigeon blue",
            "fr": "Bleu pigeon",
            "es": "Azul colombino",
            "it": "Blu colomba",
            "nl": "Duifblauw"
    }
},
    "5015": {
    "code": "5015",
        "scope": "classic",
        "color": {
        "hex": "#007CB0",
            "websafe": "#006699",
            "rgb": {
            "r": 0,
                "g": 124,
                "b": 176
        },
        "hsl": {
            "h": 198,
                "s": 100,
                "l": 35
        },
        "hsb": {
            "h": 198,
                "s": 100,
                "b": 69
        },
        "cmyk": {
            "c": "90",
                "m": "40",
                "y": "0",
                "k": "0"
        }
    },
    "names": {
        "de": "Himmelblau",
            "en": "Sky blue",
            "fr": "Bleu ciel",
            "es": "Azul celeste",
            "it": "Blu cielo",
            "nl": "Hemelsblauw"
    }
},
    "5017": {
    "code": "5017",
        "scope": "classic",
        "color": {
        "hex": "#005B8C",
            "websafe": "#006699",
            "rgb": {
            "r": 0,
                "g": 91,
                "b": 140
        },
        "hsl": {
            "h": 201,
                "s": 100,
                "l": 27
        },
        "hsb": {
            "h": 201,
                "s": 100,
                "b": 55
        },
        "cmyk": {
            "c": "100",
                "m": "60",
                "y": "0",
                "k": "10"
        }
    },
    "names": {
        "de": "Verkehrsblau",
            "en": "Traffic blue",
            "fr": "Bleu signalisation",
            "es": "Azul tráfico",
            "it": "Blu traffico",
            "nl": "Verkeersblauw"
    }
},
    "5018": {
    "code": "5018",
        "scope": "classic",
        "color": {
        "hex": "#058B8C",
            "websafe": "#009999",
            "rgb": {
            "r": 5,
                "g": 139,
                "b": 140
        },
        "hsl": {
            "h": 180,
                "s": 93,
                "l": 28
        },
        "hsb": {
            "h": 180,
                "s": 96,
                "b": 55
        },
        "cmyk": {
            "c": "80",
                "m": "10",
                "y": "35",
                "k": "20"
        }
    },
    "names": {
        "de": "Türkisblau",
            "en": "Turquoise blue",
            "fr": "Bleu turquoise",
            "es": "Azul turquesa",
            "it": "Blu turchese",
            "nl": "Turkooisblauw"
    }
},
    "5019": {
    "code": "5019",
        "scope": "classic",
        "color": {
        "hex": "#005E83",
            "websafe": "#006699",
            "rgb": {
            "r": 0,
                "g": 94,
                "b": 131
        },
        "hsl": {
            "h": 197,
                "s": 100,
                "l": 26
        },
        "hsb": {
            "h": 197,
                "s": 100,
                "b": 51
        },
        "cmyk": {
            "c": "100",
                "m": "50",
                "y": "15",
                "k": "25"
        }
    },
    "names": {
        "de": "Capriblau",
            "en": "Capri blue",
            "fr": "Bleu capri",
            "es": "Azul capri",
            "it": "Blu Capri",
            "nl": "Capriblauw"
    }
},
    "5020": {
    "code": "5020",
        "scope": "classic",
        "color": {
        "hex": "#00414B",
            "websafe": "#003333",
            "rgb": {
            "r": 0,
                "g": 65,
                "b": 75
        },
        "hsl": {
            "h": 188,
                "s": 100,
                "l": 15
        },
        "hsb": {
            "h": 188,
                "s": 100,
                "b": 29
        },
        "cmyk": {
            "c": "100",
                "m": "10",
                "y": "20",
                "k": "80"
        }
    },
    "names": {
        "de": "Ozeanblau",
            "en": "Ocean blue",
            "fr": "Bleu océan",
            "es": "Azul oceano",
            "it": "Blu oceano",
            "nl": "Oceaanblauw"
    }
},
    "5021": {
    "code": "5021",
        "scope": "classic",
        "color": {
        "hex": "#007577",
            "websafe": "#006666",
            "rgb": {
            "r": 0,
                "g": 117,
                "b": 119
        },
        "hsl": {
            "h": 181,
                "s": 100,
                "l": 23
        },
        "hsb": {
            "h": 181,
                "s": 100,
                "b": 47
        },
        "cmyk": {
            "c": "100",
                "m": "0",
                "y": "40",
                "k": "35"
        }
    },
    "names": {
        "de": "Wasserblau",
            "en": "Water blue",
            "fr": "Bleu d’eau",
            "es": "Azul agua",
            "it": "Blu acqua",
            "nl": "Waterblauw"
    }
},
    "5022": {
    "code": "5022",
        "scope": "classic",
        "color": {
        "hex": "#222D5A",
            "websafe": "#333366",
            "rgb": {
            "r": 34,
                "g": 45,
                "b": 90
        },
        "hsl": {
            "h": 228,
                "s": 45,
                "l": 24
        },
        "hsb": {
            "h": 228,
                "s": 62,
                "b": 35
        },
        "cmyk": {
            "c": "100",
                "m": "90",
                "y": "0",
                "k": "50"
        }
    },
    "names": {
        "de": "Nachtblau",
            "en": "Night blue",
            "fr": "Bleu nocturne",
            "es": "Azul noche",
            "it": "Blu notte",
            "nl": "Nachtblauw"
    }
},
    "5023": {
    "code": "5023",
        "scope": "classic",
        "color": {
        "hex": "#42698C",
            "websafe": "#336699",
            "rgb": {
            "r": 66,
                "g": 105,
                "b": 140
        },
        "hsl": {
            "h": 208,
                "s": 36,
                "l": 40
        },
        "hsb": {
            "h": 208,
                "s": 53,
                "b": 55
        },
        "cmyk": {
            "c": "70",
                "m": "40",
                "y": "10",
                "k": "30"
        }
    },
    "names": {
        "de": "Fernblau",
            "en": "Distant blue",
            "fr": "Bleu distant",
            "es": "Azul lejanía",
            "it": "Blu distante",
            "nl": "Verblauw"
    }
},
    "5024": {
    "code": "5024",
        "scope": "classic",
        "color": {
        "hex": "#6093AC",
            "websafe": "#669999",
            "rgb": {
            "r": 96,
                "g": 147,
                "b": 172
        },
        "hsl": {
            "h": 200,
                "s": 31,
                "l": 53
        },
        "hsb": {
            "h": 200,
                "s": 44,
                "b": 67
        },
        "cmyk": {
            "c": "60",
                "m": "20",
                "y": "5",
                "k": "20"
        }
    },
    "names": {
        "de": "Pastellblau",
            "en": "Pastel blue",
            "fr": "Bleu pastel",
            "es": "Azul pastel",
            "it": "Blu pastello",
            "nl": "Pastelblauw"
    }
},
    "5025": {
    "code": "5025",
        "scope": "classic",
        "color": {
        "hex": "#21697C",
            "websafe": "#336666",
            "rgb": {
            "r": 33,
                "g": 105,
                "b": 124
        },
        "hsl": {
            "h": 193,
                "s": 58,
                "l": 31
        },
        "hsb": {
            "h": 193,
                "s": 73,
                "b": 49
        },
        "cmyk": {
            "c": "80",
                "m": "25",
                "y": "5",
                "k": "60"
        }
    },
    "names": {
        "de": "Perlenzian",
            "en": "Pearl gentian blue",
            "fr": "Gentiane nacré",
            "es": "Gencian perlado",
            "it": "Blu genziana perlato",
            "nl": "Parelmoer blauw"
    }
},
    "5026": {
    "code": "5026",
        "scope": "classic",
        "color": {
        "hex": "#0F3052",
            "websafe": "#003366",
            "rgb": {
            "r": 15,
                "g": 48,
                "b": 82
        },
        "hsl": {
            "h": 210,
                "s": 69,
                "l": 19
        },
        "hsb": {
            "h": 210,
                "s": 82,
                "b": 32
        },
        "cmyk": {
            "c": "100",
                "m": "60",
                "y": "0",
                "k": "45"
        }
    },
    "names": {
        "de": "Perlnachtblau",
            "en": "Pearl night blue",
            "fr": "Bleu nuit nacré",
            "es": "Azul noche perlado",
            "it": "Blu notte perlato",
            "nl": "Parelmoer nachtblauw"
    }
},
    "6000": {
    "code": "6000",
        "scope": "classic",
        "color": {
        "hex": "#3C7460",
            "websafe": "#336666",
            "rgb": {
            "r": 60,
                "g": 116,
                "b": 96
        },
        "hsl": {
            "h": 159,
                "s": 32,
                "l": 35
        },
        "hsb": {
            "h": 159,
                "s": 48,
                "b": 45
        },
        "cmyk": {
            "c": "80",
                "m": "25",
                "y": "60",
                "k": "15"
        }
    },
    "names": {
        "de": "Patinagrün",
            "en": "Patina green",
            "fr": "Vert patine",
            "es": "Verde patina",
            "it": "Verde patina",
            "nl": "Patinagroen"
    }
},
    "6001": {
    "code": "6001",
        "scope": "classic",
        "color": {
        "hex": "#366735",
            "websafe": "#336633",
            "rgb": {
            "r": 54,
                "g": 103,
                "b": 53
        },
        "hsl": {
            "h": 119,
                "s": 32,
                "l": 31
        },
        "hsb": {
            "h": 119,
                "s": 49,
                "b": 40
        },
        "cmyk": {
            "c": "80",
                "m": "30",
                "y": "100",
                "k": "10"
        }
    },
    "names": {
        "de": "Smaragdgrün",
            "en": "Emerald green",
            "fr": "Vert émeraude",
            "es": "Verde esmeralda",
            "it": "Verde smeraldo",
            "nl": "Smaragdgroen"
    }
},
    "6002": {
    "code": "6002",
        "scope": "classic",
        "color": {
        "hex": "#325928",
            "websafe": "#336633",
            "rgb": {
            "r": 50,
                "g": 89,
                "b": 40
        },
        "hsl": {
            "h": 108,
                "s": 38,
                "l": 25
        },
        "hsb": {
            "h": 108,
                "s": 55,
                "b": 35
        },
        "cmyk": {
            "c": "85",
                "m": "30",
                "y": "100",
                "k": "30"
        }
    },
    "names": {
        "de": "Laubgrün",
            "en": "Leaf green",
            "fr": "Vert feuillage",
            "es": "Verde hoja",
            "it": "Verde foglia",
            "nl": "Loofgroen"
    }
},
    "6003": {
    "code": "6003",
        "scope": "classic",
        "color": {
        "hex": "#50533C",
            "websafe": "#666633",
            "rgb": {
            "r": 80,
                "g": 83,
                "b": 60
        },
        "hsl": {
            "h": 68,
                "s": 16,
                "l": 28
        },
        "hsb": {
            "h": 68,
                "s": 28,
                "b": 33
        },
        "cmyk": {
            "c": "45",
                "m": "15",
                "y": "60",
                "k": "70"
        }
    },
    "names": {
        "de": "Olivgrün",
            "en": "Olive green",
            "fr": "Vert olive",
            "es": "Verde oliva",
            "it": "Verde oliva",
            "nl": "Olijfgroen"
    }
},
    "6004": {
    "code": "6004",
        "scope": "classic",
        "color": {
        "hex": "#024442",
            "websafe": "#003333",
            "rgb": {
            "r": 2,
                "g": 68,
                "b": 66
        },
        "hsl": {
            "h": 178,
                "s": 94,
                "l": 14
        },
        "hsb": {
            "h": 178,
                "s": 97,
                "b": 27
        },
        "cmyk": {
            "c": "90",
                "m": "10",
                "y": "45",
                "k": "70"
        }
    },
    "names": {
        "de": "Blaugrün",
            "en": "Blue green",
            "fr": "Vert bleu",
            "es": "Verde azulado",
            "it": "Verde bluastro",
            "nl": "Blauwgroen"
    }
},
    "6005": {
    "code": "6005",
        "scope": "classic",
        "color": {
        "hex": "#114232",
            "websafe": "#003333",
            "rgb": {
            "r": 17,
                "g": 66,
                "b": 50
        },
        "hsl": {
            "h": 160,
                "s": 59,
                "l": 16
        },
        "hsb": {
            "h": 160,
                "s": 74,
                "b": 26
        },
        "cmyk": {
            "c": "80",
                "m": "5",
                "y": "60",
                "k": "70"
        }
    },
    "names": {
        "de": "Moosgrün",
            "en": "Moss green",
            "fr": "Vert mousse",
            "es": "Verde musgo",
            "it": "Verde muschio",
            "nl": "Mosgroen"
    }
},
    "6006": {
    "code": "6006",
        "scope": "classic",
        "color": {
        "hex": "#3C392E",
            "websafe": "#333333",
            "rgb": {
            "r": 60,
                "g": 57,
                "b": 46
        },
        "hsl": {
            "h": 47,
                "s": 13,
                "l": 21
        },
        "hsb": {
            "h": 47,
                "s": 23,
                "b": 24
        },
        "cmyk": {
            "c": "20",
                "m": "0",
                "y": "30",
                "k": "90"
        }
    },
    "names": {
        "de": "Grauoliv",
            "en": "Grey olive",
            "fr": "Olive gris",
            "es": "Oliva grisáceo",
            "it": "Oliva grigiastro",
            "nl": "Grijs olijfgroen"
    }
},
    "6007": {
    "code": "6007",
        "scope": "classic",
        "color": {
        "hex": "#2C3222",
            "websafe": "#333333",
            "rgb": {
            "r": 44,
                "g": 50,
                "b": 34
        },
        "hsl": {
            "h": 83,
                "s": 19,
                "l": 16
        },
        "hsb": {
            "h": 83,
                "s": 32,
                "b": 20
        },
        "cmyk": {
            "c": "80",
                "m": "45",
                "y": "70",
                "k": "80"
        }
    },
    "names": {
        "de": "Flaschengrün",
            "en": "Bottle green",
            "fr": "Vert bouteille",
            "es": "Verde botella",
            "it": "Verde bottiglia",
            "nl": "Flessengroen"
    }
},
    "6008": {
    "code": "6008",
        "scope": "classic",
        "color": {
        "hex": "#37342A",
            "websafe": "#333333",
            "rgb": {
            "r": 55,
                "g": 52,
                "b": 42
        },
        "hsl": {
            "h": 46,
                "s": 13,
                "l": 19
        },
        "hsb": {
            "h": 46,
                "s": 24,
                "b": 22
        },
        "cmyk": {
            "c": "60",
                "m": "50",
                "y": "60",
                "k": "80"
        }
    },
    "names": {
        "de": "Braungrün",
            "en": "Brown green",
            "fr": "Vert brun",
            "es": "Verde parduzco",
            "it": "Verde brunastro",
            "nl": "Bruingroen"
    }
},
    "6009": {
    "code": "6009",
        "scope": "classic",
        "color": {
        "hex": "#27352A",
            "websafe": "#333333",
            "rgb": {
            "r": 39,
                "g": 53,
                "b": 42
        },
        "hsl": {
            "h": 133,
                "s": 15,
                "l": 18
        },
        "hsb": {
            "h": 133,
                "s": 26,
                "b": 21
        },
        "cmyk": {
            "c": "100",
                "m": "30",
                "y": "80",
                "k": "80"
        }
    },
    "names": {
        "de": "Tannengrün",
            "en": "Fir green",
            "fr": "Vert sapin",
            "es": "Verde abeto",
            "it": "Verde abete",
            "nl": "Dennengroen"
    }
},
    "6010": {
    "code": "6010",
        "scope": "classic",
        "color": {
        "hex": "#4D6F39",
            "websafe": "#666633",
            "rgb": {
            "r": 77,
                "g": 111,
                "b": 57
        },
        "hsl": {
            "h": 98,
                "s": 32,
                "l": 33
        },
        "hsb": {
            "h": 98,
                "s": 49,
                "b": 44
        },
        "cmyk": {
            "c": "80",
                "m": "20",
                "y": "100",
                "k": "25"
        }
    },
    "names": {
        "de": "Grasgrün",
            "en": "Grass green",
            "fr": "Vert herbe",
            "es": "Verde hierba",
            "it": "Verde erba",
            "nl": "Grasgroen"
    }
},
    "6011": {
    "code": "6011",
        "scope": "classic",
        "color": {
        "hex": "#6C7C59",
            "websafe": "#666666",
            "rgb": {
            "r": 108,
                "g": 124,
                "b": 89
        },
        "hsl": {
            "h": 87,
                "s": 16,
                "l": 42
        },
        "hsb": {
            "h": 87,
                "s": 28,
                "b": 49
        },
        "cmyk": {
            "c": "50",
                "m": "10",
                "y": "60",
                "k": "45"
        }
    },
    "names": {
        "de": "Resedagrün",
            "en": "Reseda green",
            "fr": "Vert réséda",
            "es": "Verde reseda",
            "it": "Verde reseda",
            "nl": "Resedagroen"
    }
},
    "6012": {
    "code": "6012",
        "scope": "classic",
        "color": {
        "hex": "#303D3A",
            "websafe": "#333333",
            "rgb": {
            "r": 48,
                "g": 61,
                "b": 58
        },
        "hsl": {
            "h": 166,
                "s": 12,
                "l": 21
        },
        "hsb": {
            "h": 166,
                "s": 21,
                "b": 24
        },
        "cmyk": {
            "c": "80",
                "m": "45",
                "y": "50",
                "k": "70"
        }
    },
    "names": {
        "de": "Schwarzgrün",
            "en": "Black green",
            "fr": "Vert noir",
            "es": "Verde negruzco",
            "it": "Verde nerastro",
            "nl": "Zwartgroen"
    }
},
    "6013": {
    "code": "6013",
        "scope": "classic",
        "color": {
        "hex": "#7D765A",
            "websafe": "#666666",
            "rgb": {
            "r": 125,
                "g": 118,
                "b": 90
        },
        "hsl": {
            "h": 48,
                "s": 16,
                "l": 42
        },
        "hsb": {
            "h": 48,
                "s": 28,
                "b": 49
        },
        "cmyk": {
            "c": "45",
                "m": "25",
                "y": "60",
                "k": "50"
        }
    },
    "names": {
        "de": "Schilfgrün",
            "en": "Reed green",
            "fr": "Vert jonc",
            "es": "Verde caña",
            "it": "Verde canna",
            "nl": "Rietgroen"
    }
},
    "6014": {
    "code": "6014",
        "scope": "classic",
        "color": {
        "hex": "#474135",
            "websafe": "#333333",
            "rgb": {
            "r": 71,
                "g": 65,
                "b": 53
        },
        "hsl": {
            "h": 40,
                "s": 15,
                "l": 24
        },
        "hsb": {
            "h": 40,
                "s": 25,
                "b": 28
        },
        "cmyk": {
            "c": "20",
                "m": "20",
                "y": "50",
                "k": "90"
        }
    },
    "names": {
        "de": "Gelboliv",
            "en": "Yellow olive",
            "fr": "Olive jaune",
            "es": "Amarillo oliva",
            "it": "Oliva giallastro",
            "nl": "Geel olijfgroen"
    }
},
    "6015": {
    "code": "6015",
        "scope": "classic",
        "color": {
        "hex": "#3D3D36",
            "websafe": "#333333",
            "rgb": {
            "r": 61,
                "g": 61,
                "b": 54
        },
        "hsl": {
            "h": 60,
                "s": 6,
                "l": 23
        },
        "hsb": {
            "h": 60,
                "s": 11,
                "b": 24
        },
        "cmyk": {
            "c": "25",
                "m": "5",
                "y": "30",
                "k": "90"
        }
    },
    "names": {
        "de": "Schwarzoliv",
            "en": "Black olive",
            "fr": "Olive noir",
            "es": "Oliva negruzco",
            "it": "Oliva nerastro",
            "nl": "Zwart olijfgroen"
    }
},
    "6016": {
    "code": "6016",
        "scope": "classic",
        "color": {
        "hex": "#00694C",
            "websafe": "#006633",
            "rgb": {
            "r": 0,
                "g": 105,
                "b": 76
        },
        "hsl": {
            "h": 163,
                "s": 100,
                "l": 21
        },
        "hsb": {
            "h": 163,
                "s": 100,
                "b": 41
        },
        "cmyk": {
            "c": "100",
                "m": "20",
                "y": "80",
                "k": "25"
        }
    },
    "names": {
        "de": "Türkisgrün",
            "en": "Turquoise green",
            "fr": "Vert turquoise",
            "es": "Verde turquesa",
            "it": "Verde turchese",
            "nl": "Turkooisgroen"
    }
},
    "6017": {
    "code": "6017",
        "scope": "classic",
        "color": {
        "hex": "#587F40",
            "websafe": "#666633",
            "rgb": {
            "r": 88,
                "g": 127,
                "b": 64
        },
        "hsl": {
            "h": 97,
                "s": 33,
                "l": 37
        },
        "hsb": {
            "h": 97,
                "s": 50,
                "b": 50
        },
        "cmyk": {
            "c": "70",
                "m": "5",
                "y": "90",
                "k": "20"
        }
    },
    "names": {
        "de": "Maigrün",
            "en": "May green",
            "fr": "Vert mai",
            "es": "Verde mayo",
            "it": "Verde maggio",
            "nl": "Meigroen"
    }
},
    "6018": {
    "code": "6018",
        "scope": "classic",
        "color": {
        "hex": "#61993B",
            "websafe": "#669933",
            "rgb": {
            "r": 97,
                "g": 153,
                "b": 59
        },
        "hsl": {
            "h": 96,
                "s": 44,
                "l": 42
        },
        "hsb": {
            "h": 96,
                "s": 61,
                "b": 60
        },
        "cmyk": {
            "c": "70",
                "m": "0",
                "y": "90",
                "k": "0"
        }
    },
    "names": {
        "de": "Gelbgrün",
            "en": "Yellow green",
            "fr": "Vert jaune",
            "es": "Verde amarillento",
            "it": "Verde giallastro",
            "nl": "Geelgroen"
    }
},
    "6019": {
    "code": "6019",
        "scope": "classic",
        "color": {
        "hex": "#B9CEAC",
            "websafe": "#cccc99",
            "rgb": {
            "r": 185,
                "g": 206,
                "b": 172
        },
        "hsl": {
            "h": 97,
                "s": 26,
                "l": 74
        },
        "hsb": {
            "h": 97,
                "s": 17,
                "b": 81
        },
        "cmyk": {
            "c": "35",
                "m": "0",
                "y": "40",
                "k": "0"
        }
    },
    "names": {
        "de": "Weißgrün",
            "en": "Pastel green",
            "fr": "Vert blanc",
            "es": "Verde blanquecino",
            "it": "Verde biancastro",
            "nl": "Witgroen"
    }
},
    "6020": {
    "code": "6020",
        "scope": "classic",
        "color": {
        "hex": "#37422F",
            "websafe": "#333333",
            "rgb": {
            "r": 55,
                "g": 66,
                "b": 47
        },
        "hsl": {
            "h": 95,
                "s": 17,
                "l": 22
        },
        "hsb": {
            "h": 95,
                "s": 29,
                "b": 26
        },
        "cmyk": {
            "c": "80",
                "m": "50",
                "y": "90",
                "k": "50"
        }
    },
    "names": {
        "de": "Chromoxidgrün",
            "en": "Chrome green",
            "fr": "Vert oxyde chromique",
            "es": "Verde cromo",
            "it": "Verde cromo",
            "nl": "Chroomoxyde groen"
    }
},
    "6021": {
    "code": "6021",
        "scope": "classic",
        "color": {
        "hex": "#8A9977",
            "websafe": "#999966",
            "rgb": {
            "r": 138,
                "g": 153,
                "b": 119
        },
        "hsl": {
            "h": 86,
                "s": 14,
                "l": 53
        },
        "hsb": {
            "h": 86,
                "s": 22,
                "b": 60
        },
        "cmyk": {
            "c": "45",
                "m": "5",
                "y": "50",
                "k": "25"
        }
    },
    "names": {
        "de": "Blassgrün",
            "en": "Pale green",
            "fr": "Vert pâle",
            "es": "Verde pálido",
            "it": "Verde pallido",
            "nl": "Bleekgroen"
    }
},
    "6022": {
    "code": "6022",
        "scope": "classic",
        "color": {
        "hex": "#3A3327",
            "websafe": "#333333",
            "rgb": {
            "r": 58,
                "g": 51,
                "b": 39
        },
        "hsl": {
            "h": 38,
                "s": 20,
                "l": 19
        },
        "hsb": {
            "h": 38,
                "s": 33,
                "b": 23
        },
        "cmyk": {
            "c": "70",
                "m": "70",
                "y": "100",
                "k": "70"
        }
    },
    "names": {
        "de": "Braunoliv",
            "en": "Olive drab",
            "fr": "Olive brun",
            "es": "Oliva parduzco",
            "it": "Oliva brunastro",
            "nl": "Bruin olijfgroen"
    }
},
    "6024": {
    "code": "6024",
        "scope": "classic",
        "color": {
        "hex": "#008351",
            "websafe": "#009966",
            "rgb": {
            "r": 0,
                "g": 131,
                "b": 81
        },
        "hsl": {
            "h": 157,
                "s": 100,
                "l": 26
        },
        "hsb": {
            "h": 157,
                "s": 100,
                "b": 51
        },
        "cmyk": {
            "c": "90",
                "m": "10",
                "y": "80",
                "k": "10"
        }
    },
    "names": {
        "de": "Verkehrsgrün",
            "en": "Traffic green",
            "fr": "Vert signalisation",
            "es": "Verde tráfico",
            "it": "Verde traffico",
            "nl": "Verkeersgroen"
    }
},
    "6025": {
    "code": "6025",
        "scope": "classic",
        "color": {
        "hex": "#5E6E3B",
            "websafe": "#666633",
            "rgb": {
            "r": 94,
                "g": 110,
                "b": 59
        },
        "hsl": {
            "h": 79,
                "s": 30,
                "l": 33
        },
        "hsb": {
            "h": 79,
                "s": 46,
                "b": 43
        },
        "cmyk": {
            "c": "70",
                "m": "25",
                "y": "100",
                "k": "20"
        }
    },
    "names": {
        "de": "Farngrün",
            "en": "Fern green",
            "fr": "Vert fougère",
            "es": "Verde helecho",
            "it": "Verde felce",
            "nl": "Varengroen"
    }
},
    "6026": {
    "code": "6026",
        "scope": "classic",
        "color": {
        "hex": "#005F4E",
            "websafe": "#006666",
            "rgb": {
            "r": 0,
                "g": 95,
                "b": 78
        },
        "hsl": {
            "h": 169,
                "s": 100,
                "l": 19
        },
        "hsb": {
            "h": 169,
                "s": 100,
                "b": 37
        },
        "cmyk": {
            "c": "100",
                "m": "10",
                "y": "60",
                "k": "50"
        }
    },
    "names": {
        "de": "Opalgrün",
            "en": "Opal green",
            "fr": "Vert opale",
            "es": "Verde opalo",
            "it": "Verde opale",
            "nl": "Opaalgroen"
    }
},
    "6027": {
    "code": "6027",
        "scope": "classic",
        "color": {
        "hex": "#7EBAB5",
            "websafe": "#66cccc",
            "rgb": {
            "r": 126,
                "g": 186,
                "b": 181
        },
        "hsl": {
            "h": 175,
                "s": 30,
                "l": 61
        },
        "hsb": {
            "h": 175,
                "s": 32,
                "b": 73
        },
        "cmyk": {
            "c": "50",
                "m": "0",
                "y": "25",
                "k": "5"
        }
    },
    "names": {
        "de": "Lichtgrün",
            "en": "Light green",
            "fr": "Vert clair",
            "es": "Verde luminoso",
            "it": "Verde chiaro",
            "nl": "Lichtgroen"
    }
},
    "6028": {
    "code": "6028",
        "scope": "classic",
        "color": {
        "hex": "#315442",
            "websafe": "#336633",
            "rgb": {
            "r": 49,
                "g": 84,
                "b": 66
        },
        "hsl": {
            "h": 149,
                "s": 26,
                "l": 26
        },
        "hsb": {
            "h": 149,
                "s": 42,
                "b": 33
        },
        "cmyk": {
            "c": "80",
                "m": "35",
                "y": "70",
                "k": "50"
        }
    },
    "names": {
        "de": "Kieferngrün",
            "en": "Pine green",
            "fr": "Vert pin",
            "es": "Verde pino",
            "it": "Verde pino",
            "nl": "Pijnboomgroen"
    }
},
    "6029": {
    "code": "6029",
        "scope": "classic",
        "color": {
        "hex": "#006F3D",
            "websafe": "#006633",
            "rgb": {
            "r": 0,
                "g": 111,
                "b": 61
        },
        "hsl": {
            "h": 153,
                "s": 100,
                "l": 22
        },
        "hsb": {
            "h": 153,
                "s": 100,
                "b": 44
        },
        "cmyk": {
            "c": "100",
                "m": "5",
                "y": "90",
                "k": "30"
        }
    },
    "names": {
        "de": "Minzgrün",
            "en": "Mint green",
            "fr": "Vert menthe",
            "es": "Verde menta",
            "it": "Verde menta",
            "nl": "Mintgroen"
    }
},
    "6032": {
    "code": "6032",
        "scope": "classic",
        "color": {
        "hex": "#237F52",
            "websafe": "#336666",
            "rgb": {
            "r": 35,
                "g": 127,
                "b": 82
        },
        "hsl": {
            "h": 151,
                "s": 57,
                "l": 32
        },
        "hsb": {
            "h": 151,
                "s": 72,
                "b": 50
        },
        "cmyk": {
            "c": "90",
                "m": "10",
                "y": "80",
                "k": "10"
        }
    },
    "names": {
        "de": "Signalgrün",
            "en": "Signal green",
            "fr": "Vert de sécurité",
            "es": "Verde señales",
            "it": "Verde segnale",
            "nl": "Signaalgroen"
    }
},
    "6033": {
    "code": "6033",
        "scope": "classic",
        "color": {
        "hex": "#46877F",
            "websafe": "#339966",
            "rgb": {
            "r": 70,
                "g": 135,
                "b": 127
        },
        "hsl": {
            "h": 173,
                "s": 32,
                "l": 40
        },
        "hsb": {
            "h": 173,
                "s": 48,
                "b": 53
        },
        "cmyk": {
            "c": "70",
                "m": "15",
                "y": "50",
                "k": "20"
        }
    },
    "names": {
        "de": "Minttürkis",
            "en": "Mint turquoise",
            "fr": "Turquoise menthe",
            "es": "Turquesa menta",
            "it": "Turchese menta",
            "nl": "Mintturquoise"
    }
},
    "6034": {
    "code": "6034",
        "scope": "classic",
        "color": {
        "hex": "#7AACAC",
            "websafe": "#669999",
            "rgb": {
            "r": 122,
                "g": 172,
                "b": 172
        },
        "hsl": {
            "h": 180,
                "s": 23,
                "l": 58
        },
        "hsb": {
            "h": 180,
                "s": 29,
                "b": 67
        },
        "cmyk": {
            "c": "45",
                "m": "0",
                "y": "20",
                "k": "20"
        }
    },
    "names": {
        "de": "Pastelltürkis",
            "en": "Pastel turquoise",
            "fr": "Turquoise pastel",
            "es": "Turquesa pastel",
            "it": "Turchese pastello",
            "nl": "Pastelturquoise"
    }
},
    "6035": {
    "code": "6035",
        "scope": "classic",
        "color": {
        "hex": "#194D25",
            "websafe": "#006633",
            "rgb": {
            "r": 25,
                "g": 77,
                "b": 37
        },
        "hsl": {
            "h": 134,
                "s": 51,
                "l": 20
        },
        "hsb": {
            "h": 134,
                "s": 68,
                "b": 30
        },
        "cmyk": {
            "c": "90",
                "m": "15",
                "y": "100",
                "k": "60"
        }
    },
    "names": {
        "de": "Perlgrün",
            "en": "Pearl green",
            "fr": "Vert nacré",
            "es": "Verde perlado",
            "it": "Verde perlato",
            "nl": "Parelmoer donkergroen"
    }
},
    "6036": {
    "code": "6036",
        "scope": "classic",
        "color": {
        "hex": "#04574B",
            "websafe": "#006633",
            "rgb": {
            "r": 4,
                "g": 87,
                "b": 75
        },
        "hsl": {
            "h": 171,
                "s": 91,
                "l": 18
        },
        "hsb": {
            "h": 171,
                "s": 95,
                "b": 34
        },
        "cmyk": {
            "c": "85",
                "m": "10",
                "y": "60",
                "k": "40"
        }
    },
    "names": {
        "de": "Perlopalgrün",
            "en": "Pearl opal green",
            "fr": "Vert opal nacré",
            "es": "Verde ópalo perlado",
            "it": "Verde opalo perlato",
            "nl": "Parelmoer lichtgroen"
    }
},
    "6037": {
    "code": "6037",
        "scope": "classic",
        "color": {
        "hex": "#008B29",
            "websafe": "#009933",
            "rgb": {
            "r": 0,
                "g": 139,
                "b": 41
        },
        "hsl": {
            "h": 138,
                "s": 100,
                "l": 27
        },
        "hsb": {
            "h": 138,
                "s": 100,
                "b": 55
        },
        "cmyk": {
            "c": "85",
                "m": "0",
                "y": "100",
                "k": "0"
        }
    },
    "names": {
        "de": "Reingrün",
            "en": "Pure green",
            "fr": "Vert pur",
            "es": "Verde puro ",
            "it": "Verde puro",
            "nl": "Zuiver groen "
    }
},
    "6038": {
    "code": "6038",
        "scope": "classic",
        "color": {
        "hex": "#00B51A",
            "websafe": "#00cc33",
            "rgb": {
            "r": 0,
                "g": 181,
                "b": 26
        },
        "hsl": {
            "h": 129,
                "s": 100,
                "l": 35
        },
        "hsb": {
            "h": 129,
                "s": 100,
                "b": 71
        },
        "cmyk": {
            "c": "70",
                "m": "0",
                "y": "90",
                "k": "0"
        }
    },
    "names": {
        "de": "Leuchtgrün",
            "en": "Luminous green",
            "fr": "Vert brillant",
            "es": "Verde brillante ",
            "it": "Verde brillante",
            "nl": "Briljantgroen "
    }
},
    "7000": {
    "code": "7000",
        "scope": "classic",
        "color": {
        "hex": "#7A888E",
            "websafe": "#669999",
            "rgb": {
            "r": 122,
                "g": 136,
                "b": 142
        },
        "hsl": {
            "h": 198,
                "s": 8,
                "l": 52
        },
        "hsb": {
            "h": 198,
                "s": 14,
                "b": 56
        },
        "cmyk": {
            "c": "35",
                "m": "5",
                "y": "10",
                "k": "40"
        }
    },
    "names": {
        "de": "Fehgrau",
            "en": "Squirrel grey",
            "fr": "Gris petit-gris",
            "es": "Gris ardilla",
            "it": "Grigio vaio",
            "nl": "Pelsgrijs"
    }
},
    "7001": {
    "code": "7001",
        "scope": "classic",
        "color": {
        "hex": "#8C969D",
            "websafe": "#999999",
            "rgb": {
            "r": 140,
                "g": 150,
                "b": 157
        },
        "hsl": {
            "h": 205,
                "s": 8,
                "l": 58
        },
        "hsb": {
            "h": 205,
                "s": 11,
                "b": 62
        },
        "cmyk": {
            "c": "25",
                "m": "10",
                "y": "5",
                "k": "45"
        }
    },
    "names": {
        "de": "Silbergrau",
            "en": "Silver grey",
            "fr": "Gris argent",
            "es": "Gris plata",
            "it": "Grigio argento",
            "nl": "Zilvergrijs"
    }
},
    "7002": {
    "code": "7002",
        "scope": "classic",
        "color": {
        "hex": "#817863",
            "websafe": "#996666",
            "rgb": {
            "r": 129,
                "g": 120,
                "b": 99
        },
        "hsl": {
            "h": 42,
                "s": 13,
                "l": 45
        },
        "hsb": {
            "h": 42,
                "s": 23,
                "b": 51
        },
        "cmyk": {
            "c": "0",
                "m": "5",
                "y": "40",
                "k": "60"
        }
    },
    "names": {
        "de": "Olivgrau",
            "en": "Olive grey",
            "fr": "Gris olive",
            "es": "Gris oliva",
            "it": "Grigio olivastro",
            "nl": "Olijfgrijs"
    }
},
    "7003": {
    "code": "7003",
        "scope": "classic",
        "color": {
        "hex": "#7A7669",
            "websafe": "#666666",
            "rgb": {
            "r": 122,
                "g": 118,
                "b": 105
        },
        "hsl": {
            "h": 46,
                "s": 7,
                "l": 45
        },
        "hsb": {
            "h": 46,
                "s": 14,
                "b": 48
        },
        "cmyk": {
            "c": "45",
                "m": "35",
                "y": "45",
                "k": "45"
        }
    },
    "names": {
        "de": "Moosgrau",
            "en": "Moss grey",
            "fr": "Gris mousse",
            "es": "Gris musgo",
            "it": "Grigio muschio",
            "nl": "Mosgrijs"
    }
},
    "7004": {
    "code": "7004",
        "scope": "classic",
        "color": {
        "hex": "#9B9B9B",
            "websafe": "#999999",
            "rgb": {
            "r": 155,
                "g": 155,
                "b": 155
        },
        "hsl": {
            "h": 0,
                "s": 0,
                "l": 61
        },
        "hsb": {
            "h": 0,
                "s": 0,
                "b": 61
        },
        "cmyk": {
            "c": "15",
                "m": "10",
                "y": "5",
                "k": "45"
        }
    },
    "names": {
        "de": "Signalgrau",
            "en": "Signal grey",
            "fr": "Gris de sécurité",
            "es": "Gris señales",
            "it": "Grigio segnale",
            "nl": "Signaalgrijs"
    }
},
    "7005": {
    "code": "7005",
        "scope": "classic",
        "color": {
        "hex": "#6C6E6B",
            "websafe": "#666666",
            "rgb": {
            "r": 108,
                "g": 110,
                "b": 107
        },
        "hsl": {
            "h": 100,
                "s": 1,
                "l": 43
        },
        "hsb": {
            "h": 100,
                "s": 3,
                "b": 43
        },
        "cmyk": {
            "c": "35",
                "m": "20",
                "y": "30",
                "k": "40"
        }
    },
    "names": {
        "de": "Mausgrau",
            "en": "Mouse grey",
            "fr": "Gris souris",
            "es": "Gris ratón",
            "it": "Grigio topo",
            "nl": "Muisgrijs"
    }
},
    "7006": {
    "code": "7006",
        "scope": "classic",
        "color": {
        "hex": "#766A5E",
            "websafe": "#666666",
            "rgb": {
            "r": 118,
                "g": 106,
                "b": 94
        },
        "hsl": {
            "h": 30,
                "s": 11,
                "l": 42
        },
        "hsb": {
            "h": 30,
                "s": 20,
                "b": 46
        },
        "cmyk": {
            "c": "40",
                "m": "40",
                "y": "50",
                "k": "40"
        }
    },
    "names": {
        "de": "Beigegrau",
            "en": "Beige grey",
            "fr": "Gris beige",
            "es": "Gris beige",
            "it": "Grigio beige",
            "nl": "Beigegrijs"
    }
},
    "7008": {
    "code": "7008",
        "scope": "classic",
        "color": {
        "hex": "#745E3D",
            "websafe": "#666633",
            "rgb": {
            "r": 116,
                "g": 94,
                "b": 61
        },
        "hsl": {
            "h": 36,
                "s": 31,
                "l": 35
        },
        "hsb": {
            "h": 36,
                "s": 47,
                "b": 45
        },
        "cmyk": {
            "c": "40",
                "m": "50",
                "y": "90",
                "k": "40"
        }
    },
    "names": {
        "de": "Khakigrau",
            "en": "Khaki grey",
            "fr": "Gris kaki",
            "es": "Gris caqui",
            "it": "Grigio kaki",
            "nl": "Kakigrijs"
    }
},
    "7009": {
    "code": "7009",
        "scope": "classic",
        "color": {
        "hex": "#5D6058",
            "websafe": "#666666",
            "rgb": {
            "r": 93,
                "g": 96,
                "b": 88
        },
        "hsl": {
            "h": 83,
                "s": 4,
                "l": 36
        },
        "hsb": {
            "h": 83,
                "s": 8,
                "b": 38
        },
        "cmyk": {
            "c": "50",
                "m": "35",
                "y": "50",
                "k": "50"
        }
    },
    "names": {
        "de": "Grüngrau",
            "en": "Green grey",
            "fr": "Gris vert",
            "es": "Gris verdoso",
            "it": "Grigio verdastro",
            "nl": "Groengrijs"
    }
},
    "7010": {
    "code": "7010",
        "scope": "classic",
        "color": {
        "hex": "#585C56",
            "websafe": "#666666",
            "rgb": {
            "r": 88,
                "g": 92,
                "b": 86
        },
        "hsl": {
            "h": 100,
                "s": 3,
                "l": 35
        },
        "hsb": {
            "h": 100,
                "s": 7,
                "b": 36
        },
        "cmyk": {
            "c": "50",
                "m": "30",
                "y": "45",
                "k": "60"
        }
    },
    "names": {
        "de": "Zeltgrau",
            "en": "Tarpaulin grey",
            "fr": "Gris tente",
            "es": "Gris lona",
            "it": "Grigio tenda",
            "nl": "Zeildoekgrijs"
    }
},
    "7011": {
    "code": "7011",
        "scope": "classic",
        "color": {
        "hex": "#52595D",
            "websafe": "#666666",
            "rgb": {
            "r": 82,
                "g": 89,
                "b": 93
        },
        "hsl": {
            "h": 202,
                "s": 6,
                "l": 34
        },
        "hsb": {
            "h": 202,
                "s": 12,
                "b": 36
        },
        "cmyk": {
            "c": "40",
                "m": "15",
                "y": "15",
                "k": "70"
        }
    },
    "names": {
        "de": "Eisengrau",
            "en": "Iron grey",
            "fr": "Gris fer",
            "es": "Gris hierro",
            "it": "Grigio ferro",
            "nl": "IJzergrijs"
    }
},
    "7012": {
    "code": "7012",
        "scope": "classic",
        "color": {
        "hex": "#575D5E",
            "websafe": "#666666",
            "rgb": {
            "r": 87,
                "g": 93,
                "b": 94
        },
        "hsl": {
            "h": 189,
                "s": 4,
                "l": 35
        },
        "hsb": {
            "h": 189,
                "s": 7,
                "b": 37
        },
        "cmyk": {
            "c": "30",
                "m": "10",
                "y": "10",
                "k": "70"
        }
    },
    "names": {
        "de": "Basaltgrau",
            "en": "Basalt grey",
            "fr": "Gris basalte",
            "es": "Gris basalto",
            "it": "Grigio basalto",
            "nl": "Bazaltgrijs"
    }
},
    "7013": {
    "code": "7013",
        "scope": "classic",
        "color": {
        "hex": "#575044",
            "websafe": "#666633",
            "rgb": {
            "r": 87,
                "g": 80,
                "b": 68
        },
        "hsl": {
            "h": 38,
                "s": 12,
                "l": 30
        },
        "hsb": {
            "h": 38,
                "s": 22,
                "b": 34
        },
        "cmyk": {
            "c": "30",
                "m": "35",
                "y": "50",
                "k": "70"
        }
    },
    "names": {
        "de": "Braungrau",
            "en": "Brown grey",
            "fr": "Gris brun",
            "es": "Gris parduzco",
            "it": "Grigio brunastro",
            "nl": "Bruingrijs"
    }
},
    "7015": {
    "code": "7015",
        "scope": "classic",
        "color": {
        "hex": "#4F5358",
            "websafe": "#666666",
            "rgb": {
            "r": 79,
                "g": 83,
                "b": 88
        },
        "hsl": {
            "h": 213,
                "s": 5,
                "l": 33
        },
        "hsb": {
            "h": 213,
                "s": 10,
                "b": 35
        },
        "cmyk": {
            "c": "45",
                "m": "30",
                "y": "15",
                "k": "70"
        }
    },
    "names": {
        "de": "Schiefergrau",
            "en": "Slate grey",
            "fr": "Gris ardoise",
            "es": "Gris pizarra",
            "it": "Grigio ardesia",
            "nl": "Leigrijs"
    }
},
    "7016": {
    "code": "7016",
        "scope": "classic",
        "color": {
        "hex": "#383E42",
            "websafe": "#333333",
            "rgb": {
            "r": 56,
                "g": 62,
                "b": 66
        },
        "hsl": {
            "h": 204,
                "s": 8,
                "l": 24
        },
        "hsb": {
            "h": 204,
                "s": 15,
                "b": 26
        },
        "cmyk": {
            "c": "60",
                "m": "30",
                "y": "20",
                "k": "80"
        }
    },
    "names": {
        "de": "Anthrazitgrau",
            "en": "Anthracite grey",
            "fr": "Gris anthracite",
            "es": "Gris antracita",
            "it": "Grigio antracite",
            "nl": "Antracietgrijs"
    }
},
    "7021": {
    "code": "7021",
        "scope": "classic",
        "color": {
        "hex": "#2F3234",
            "websafe": "#333333",
            "rgb": {
            "r": 47,
                "g": 50,
                "b": 52
        },
        "hsl": {
            "h": 204,
                "s": 5,
                "l": 19
        },
        "hsb": {
            "h": 204,
                "s": 10,
                "b": 20
        },
        "cmyk": {
            "c": "60",
                "m": "35",
                "y": "30",
                "k": "90"
        }
    },
    "names": {
        "de": "Schwarzgrau",
            "en": "Black grey",
            "fr": "Gris noir",
            "es": "Gris negruzco",
            "it": "Grigio nerastro",
            "nl": "Zwartgrijs"
    }
},
    "7022": {
    "code": "7022",
        "scope": "classic",
        "color": {
        "hex": "#4C4A44",
            "websafe": "#333333",
            "rgb": {
            "r": 76,
                "g": 74,
                "b": 68
        },
        "hsl": {
            "h": 45,
                "s": 6,
                "l": 28
        },
        "hsb": {
            "h": 45,
                "s": 11,
                "b": 30
        },
        "cmyk": {
            "c": "40",
                "m": "30",
                "y": "40",
                "k": "80"
        }
    },
    "names": {
        "de": "Umbragrau",
            "en": "Umbra grey",
            "fr": "Gris terre d’ombre",
            "es": "Gris sombra",
            "it": "Grigio ombra",
            "nl": "Ombergrijs"
    }
},
    "7023": {
    "code": "7023",
        "scope": "classic",
        "color": {
        "hex": "#808076",
            "websafe": "#999966",
            "rgb": {
            "r": 128,
                "g": 128,
                "b": 118
        },
        "hsl": {
            "h": 60,
                "s": 4,
                "l": 48
        },
        "hsb": {
            "h": 60,
                "s": 8,
                "b": 50
        },
        "cmyk": {
            "c": "5",
                "m": "0",
                "y": "20",
                "k": "50"
        }
    },
    "names": {
        "de": "Betongrau",
            "en": "Concrete grey",
            "fr": "Gris béton",
            "es": "Gris hormigón",
            "it": "Grigio calcestruzzo",
            "nl": "Betongrijs"
    }
},
    "7024": {
    "code": "7024",
        "scope": "classic",
        "color": {
        "hex": "#45494E",
            "websafe": "#333366",
            "rgb": {
            "r": 69,
                "g": 73,
                "b": 78
        },
        "hsl": {
            "h": 213,
                "s": 6,
                "l": 29
        },
        "hsb": {
            "h": 213,
                "s": 12,
                "b": 31
        },
        "cmyk": {
            "c": "30",
                "m": "20",
                "y": "0",
                "k": "80"
        }
    },
    "names": {
        "de": "Graphitgrau",
            "en": "Graphite grey",
            "fr": "Gris graphite",
            "es": "Gris grafita",
            "it": "Grigio grafite",
            "nl": "Grafietgrijs"
    }
},
    "7026": {
    "code": "7026",
        "scope": "classic",
        "color": {
        "hex": "#374345",
            "websafe": "#333333",
            "rgb": {
            "r": 55,
                "g": 67,
                "b": 69
        },
        "hsl": {
            "h": 189,
                "s": 11,
                "l": 24
        },
        "hsb": {
            "h": 189,
                "s": 20,
                "b": 27
        },
        "cmyk": {
            "c": "50",
                "m": "15",
                "y": "15",
                "k": "80"
        }
    },
    "names": {
        "de": "Granitgrau",
            "en": "Granite grey",
            "fr": "Gris granit",
            "es": "Gris granito",
            "it": "Grigio granito",
            "nl": "Granietgrijs"
    }
},
    "7030": {
    "code": "7030",
        "scope": "classic",
        "color": {
        "hex": "#928E85",
            "websafe": "#999999",
            "rgb": {
            "r": 146,
                "g": 142,
                "b": 133
        },
        "hsl": {
            "h": 42,
                "s": 6,
                "l": 55
        },
        "hsb": {
            "h": 42,
                "s": 9,
                "b": 57
        },
        "cmyk": {
            "c": "5",
                "m": "5",
                "y": "15",
                "k": "50"
        }
    },
    "names": {
        "de": "Steingrau",
            "en": "Stone grey",
            "fr": "Gris pierre",
            "es": "Gris piedra",
            "it": "Grigio pietra",
            "nl": "Steengrijs"
    }
},
    "7031": {
    "code": "7031",
        "scope": "classic",
        "color": {
        "hex": "#5B686D",
            "websafe": "#666666",
            "rgb": {
            "r": 91,
                "g": 104,
                "b": 109
        },
        "hsl": {
            "h": 197,
                "s": 9,
                "l": 39
        },
        "hsb": {
            "h": 197,
                "s": 17,
                "b": 43
        },
        "cmyk": {
            "c": "50",
                "m": "20",
                "y": "15",
                "k": "60"
        }
    },
    "names": {
        "de": "Blaugrau",
            "en": "Blue grey",
            "fr": "Gris bleu",
            "es": "Gris azulado",
            "it": "Grigio bluastro",
            "nl": "Blauwgrijs"
    }
},
    "7032": {
    "code": "7032",
        "scope": "classic",
        "color": {
        "hex": "#B5B0A1",
            "websafe": "#cc9999",
            "rgb": {
            "r": 181,
                "g": 176,
                "b": 161
        },
        "hsl": {
            "h": 45,
                "s": 12,
                "l": 67
        },
        "hsb": {
            "h": 45,
                "s": 11,
                "b": 71
        },
        "cmyk": {
            "c": "15",
                "m": "10",
                "y": "25",
                "k": "20"
        }
    },
    "names": {
        "de": "Kieselgrau",
            "en": "Pebble grey",
            "fr": "Gris silex",
            "es": "Gris guijarro",
            "it": "Grigio ghiaia",
            "nl": "Kiezelgrijs"
    }
},
    "7033": {
    "code": "7033",
        "scope": "classic",
        "color": {
        "hex": "#7F8274",
            "websafe": "#669966",
            "rgb": {
            "r": 127,
                "g": 130,
                "b": 116
        },
        "hsl": {
            "h": 73,
                "s": 6,
                "l": 48
        },
        "hsb": {
            "h": 73,
                "s": 11,
                "b": 51
        },
        "cmyk": {
            "c": "25",
                "m": "10",
                "y": "30",
                "k": "40"
        }
    },
    "names": {
        "de": "Zementgrau",
            "en": "Cement grey",
            "fr": "Gris ciment",
            "es": "Gris cemento",
            "it": "Grigio cemento",
            "nl": "Cementgrijs"
    }
},
    "7034": {
    "code": "7034",
        "scope": "classic",
        "color": {
        "hex": "#92886F",
            "websafe": "#999966",
            "rgb": {
            "r": 146,
                "g": 136,
                "b": 111
        },
        "hsl": {
            "h": 43,
                "s": 14,
                "l": 50
        },
        "hsb": {
            "h": 43,
                "s": 24,
                "b": 57
        },
        "cmyk": {
            "c": "15",
                "m": "20",
                "y": "40",
                "k": "40"
        }
    },
    "names": {
        "de": "Gelbgrau",
            "en": "Yellow grey",
            "fr": "Gris jaune",
            "es": "Gris amarillento",
            "it": "Grigio giallastro",
            "nl": "Geelgrijs"
    }
},
    "7035": {
    "code": "7035",
        "scope": "classic",
        "color": {
        "hex": "#C5C7C4",
            "websafe": "#cccccc",
            "rgb": {
            "r": 197,
                "g": 199,
                "b": 196
        },
        "hsl": {
            "h": 100,
                "s": 3,
                "l": 77
        },
        "hsb": {
            "h": 100,
                "s": 2,
                "b": 78
        },
        "cmyk": {
            "c": "20",
                "m": "10",
                "y": "15",
                "k": "5"
        }
    },
    "names": {
        "de": "Lichtgrau",
            "en": "Light grey",
            "fr": "Gris clair",
            "es": "Gris luminoso",
            "it": "Grigio luce",
            "nl": "Lichtgrijs"
    }
},
    "7036": {
    "code": "7036",
        "scope": "classic",
        "color": {
        "hex": "#979392",
            "websafe": "#999999",
            "rgb": {
            "r": 151,
                "g": 147,
                "b": 146
        },
        "hsl": {
            "h": 12,
                "s": 2,
                "l": 58
        },
        "hsb": {
            "h": 12,
                "s": 3,
                "b": 59
        },
        "cmyk": {
            "c": "35",
                "m": "30",
                "y": "30",
                "k": "10"
        }
    },
    "names": {
        "de": "Platingrau",
            "en": "Platinum grey",
            "fr": "Gris platine",
            "es": "Gris platino",
            "it": "Grigio platino",
            "nl": "Platinagrijs"
    }
},
    "7037": {
    "code": "7037",
        "scope": "classic",
        "color": {
        "hex": "#7A7B7A",
            "websafe": "#666666",
            "rgb": {
            "r": 122,
                "g": 123,
                "b": 122
        },
        "hsl": {
            "h": 120,
                "s": 0,
                "l": 48
        },
        "hsb": {
            "h": 120,
                "s": 1,
                "b": 48
        },
        "cmyk": {
            "c": "30",
                "m": "20",
                "y": "20",
                "k": "40"
        }
    },
    "names": {
        "de": "Staubgrau",
            "en": "Dusty grey",
            "fr": "Gris poussière",
            "es": "Gris polvo",
            "it": "Grigio polvere",
            "nl": "Stofgrijs"
    }
},
    "7038": {
    "code": "7038",
        "scope": "classic",
        "color": {
        "hex": "#B0B0A9",
            "websafe": "#999999",
            "rgb": {
            "r": 176,
                "g": 176,
                "b": 169
        },
        "hsl": {
            "h": 60,
                "s": 4,
                "l": 68
        },
        "hsb": {
            "h": 60,
                "s": 4,
                "b": 69
        },
        "cmyk": {
            "c": "5",
                "m": "0",
                "y": "10",
                "k": "30"
        }
    },
    "names": {
        "de": "Achatgrau",
            "en": "Agate grey",
            "fr": "Gris agate",
            "es": "Gris ágata",
            "it": "Grigio agata",
            "nl": "Agaatgrijs"
    }
},
    "7039": {
    "code": "7039",
        "scope": "classic",
        "color": {
        "hex": "#6B665E",
            "websafe": "#666666",
            "rgb": {
            "r": 107,
                "g": 102,
                "b": 94
        },
        "hsl": {
            "h": 37,
                "s": 6,
                "l": 39
        },
        "hsb": {
            "h": 37,
                "s": 12,
                "b": 42
        },
        "cmyk": {
            "c": "25",
                "m": "20",
                "y": "30",
                "k": "60"
        }
    },
    "names": {
        "de": "Quarzgrau",
            "en": "Quartz grey",
            "fr": "Gris quartz",
            "es": "Gris cuarzo",
            "it": "Grigio quarzo",
            "nl": "Kwartsgrijs"
    }
},
    "7040": {
    "code": "7040",
        "scope": "classic",
        "color": {
        "hex": "#989EA1",
            "websafe": "#999999",
            "rgb": {
            "r": 152,
                "g": 158,
                "b": 161
        },
        "hsl": {
            "h": 200,
                "s": 5,
                "l": 61
        },
        "hsb": {
            "h": 200,
                "s": 6,
                "b": 63
        },
        "cmyk": {
            "c": "20",
                "m": "10",
                "y": "5",
                "k": "35"
        }
    },
    "names": {
        "de": "Fenstergrau",
            "en": "Window grey",
            "fr": "Gris fenêtre",
            "es": "Gris ventana",
            "it": "Grigio finestra",
            "nl": "Venstergrijs"
    }
},
    "7042": {
    "code": "7042",
        "scope": "classic",
        "color": {
        "hex": "#8E9291",
            "websafe": "#999999",
            "rgb": {
            "r": 142,
                "g": 146,
                "b": 145
        },
        "hsl": {
            "h": 165,
                "s": 2,
                "l": 56
        },
        "hsb": {
            "h": 165,
                "s": 3,
                "b": 57
        },
        "cmyk": {
            "c": "30",
                "m": "20",
                "y": "30",
                "k": "30"
        }
    },
    "names": {
        "de": "Verkehrsgrau A",
            "en": "Traffic grey A",
            "fr": "Gris signalisation A",
            "es": "Gris tráfico A",
            "it": "Grigio traffico A",
            "nl": "Verkeersgrijs A"
    }
},
    "7043": {
    "code": "7043",
        "scope": "classic",
        "color": {
        "hex": "#4F5250",
            "websafe": "#666666",
            "rgb": {
            "r": 79,
                "g": 82,
                "b": 80
        },
        "hsl": {
            "h": 140,
                "s": 2,
                "l": 32
        },
        "hsb": {
            "h": 140,
                "s": 4,
                "b": 32
        },
        "cmyk": {
            "c": "20",
                "m": "5",
                "y": "0",
                "k": "80"
        }
    },
    "names": {
        "de": "Verkehrsgrau B",
            "en": "Traffic grey B",
            "fr": "Gris signalisation B",
            "es": "Gris tráfico B",
            "it": "Grigio traffico B",
            "nl": "Verkeersgrijs B"
    }
},
    "7044": {
    "code": "7044",
        "scope": "classic",
        "color": {
        "hex": "#B7B3A8",
            "websafe": "#cccc99",
            "rgb": {
            "r": 183,
                "g": 179,
                "b": 168
        },
        "hsl": {
            "h": 44,
                "s": 9,
                "l": 69
        },
        "hsb": {
            "h": 44,
                "s": 8,
                "b": 72
        },
        "cmyk": {
            "c": "20",
                "m": "15",
                "y": "25",
                "k": "15"
        }
    },
    "names": {
        "de": "Seidengrau",
            "en": "Silk grey",
            "fr": "Gris soie",
            "es": "Gris seda",
            "it": "Grigio seta",
            "nl": "Zijdegrijs"
    }
},
    "7045": {
    "code": "7045",
        "scope": "classic",
        "color": {
        "hex": "#8D9295",
            "websafe": "#999999",
            "rgb": {
            "r": 141,
                "g": 146,
                "b": 149
        },
        "hsl": {
            "h": 203,
                "s": 4,
                "l": 57
        },
        "hsb": {
            "h": 203,
                "s": 5,
                "b": 58
        },
        "cmyk": {
            "c": "5",
                "m": "0",
                "y": "0",
                "k": "45"
        }
    },
    "names": {
        "de": "Telegrau 1",
            "en": "Telegrey 1",
            "fr": "Telegris 1",
            "es": "Gris tele 1",
            "it": "Grigio tele 1",
            "nl": "Telegrijs 1"
    }
},
    "7046": {
    "code": "7046",
        "scope": "classic",
        "color": {
        "hex": "#7F868A",
            "websafe": "#669999",
            "rgb": {
            "r": 127,
                "g": 134,
                "b": 138
        },
        "hsl": {
            "h": 202,
                "s": 4,
                "l": 52
        },
        "hsb": {
            "h": 202,
                "s": 8,
                "b": 54
        },
        "cmyk": {
            "c": "30",
                "m": "20",
                "y": "20",
                "k": "40"
        }
    },
    "names": {
        "de": "Telegrau 2",
            "en": "Telegrey 2",
            "fr": "Telegris 2",
            "es": "Gris tele 2",
            "it": "Grigio tele 2",
            "nl": "Telegrijs 2"
    }
},
    "7047": {
    "code": "7047",
        "scope": "classic",
        "color": {
        "hex": "#C8C8C7",
            "websafe": "#cccccc",
            "rgb": {
            "r": 200,
                "g": 200,
                "b": 199
        },
        "hsl": {
            "h": 60,
                "s": 1,
                "l": 78
        },
        "hsb": {
            "h": 60,
                "s": 0,
                "b": 78
        },
        "cmyk": {
            "c": "5",
                "m": "5",
                "y": "5",
                "k": "20"
        }
    },
    "names": {
        "de": "Telegrau 4",
            "en": "Telegrey 4",
            "fr": "Telegris 4",
            "es": "Gris tele 4",
            "it": "Grigio tele 4",
            "nl": "Telegrijs 4"
    }
},
    "7048": {
    "code": "7048",
        "scope": "classic",
        "color": {
        "hex": "#817B73",
            "websafe": "#996666",
            "rgb": {
            "r": 129,
                "g": 123,
                "b": 115
        },
        "hsl": {
            "h": 34,
                "s": 6,
                "l": 48
        },
        "hsb": {
            "h": 34,
                "s": 11,
                "b": 51
        },
        "cmyk": {
            "c": "15",
                "m": "15",
                "y": "25",
                "k": "60"
        }
    },
    "names": {
        "de": "Perlmausgrau",
            "en": "Pearl mouse grey",
            "fr": "Gris souris nacré",
            "es": "Gris musgo perlado",
            "it": "Grigio topo perlato",
            "nl": "Parelmoer muisgrijs"
    }
},
    "8000": {
    "code": "8000",
        "scope": "classic",
        "color": {
        "hex": "#89693E",
            "websafe": "#996633",
            "rgb": {
            "r": 137,
                "g": 105,
                "b": 62
        },
        "hsl": {
            "h": 34,
                "s": 38,
                "l": 39
        },
        "hsb": {
            "h": 34,
                "s": 55,
                "b": 54
        },
        "cmyk": {
            "c": "50",
                "m": "60",
                "y": "90",
                "k": "0"
        }
    },
    "names": {
        "de": "Grünbraun",
            "en": "Green brown",
            "fr": "Brun vert",
            "es": "Pardo verdoso",
            "it": "Marrone verdastro",
            "nl": "Groenbruin"
    }
},
    "8001": {
    "code": "8001",
        "scope": "classic",
        "color": {
        "hex": "#9D622B",
            "websafe": "#996633",
            "rgb": {
            "r": 157,
                "g": 98,
                "b": 43
        },
        "hsl": {
            "h": 29,
                "s": 57,
                "l": 39
        },
        "hsb": {
            "h": 29,
                "s": 73,
                "b": 62
        },
        "cmyk": {
            "c": "30",
                "m": "60",
                "y": "90",
                "k": "10"
        }
    },
    "names": {
        "de": "Ockerbraun",
            "en": "Ochre brown",
            "fr": "Brun terre de Sienne",
            "es": "Pardo ocre",
            "it": "Marrone ocra",
            "nl": "Okerbruin"
    }
},
    "8002": {
    "code": "8002",
        "scope": "classic",
        "color": {
        "hex": "#794D3E",
            "websafe": "#666633",
            "rgb": {
            "r": 121,
                "g": 77,
                "b": 62
        },
        "hsl": {
            "h": 15,
                "s": 32,
                "l": 36
        },
        "hsb": {
            "h": 15,
                "s": 49,
                "b": 47
        },
        "cmyk": {
            "c": "30",
                "m": "70",
                "y": "70",
                "k": "50"
        }
    },
    "names": {
        "de": "Signalbraun",
            "en": "Signal brown",
            "fr": "Brun de sécurité",
            "es": "Marrón señales",
            "it": "Marrone segnale",
            "nl": "Signaalbruin"
    }
},
    "8003": {
    "code": "8003",
        "scope": "classic",
        "color": {
        "hex": "#7E4B26",
            "websafe": "#663333",
            "rgb": {
            "r": 126,
                "g": 75,
                "b": 38
        },
        "hsl": {
            "h": 25,
                "s": 54,
                "l": 32
        },
        "hsb": {
            "h": 25,
                "s": 70,
                "b": 49
        },
        "cmyk": {
            "c": "0",
                "m": "60",
                "y": "80",
                "k": "60"
        }
    },
    "names": {
        "de": "Lehmbraun",
            "en": "Clay brown",
            "fr": "Brun argile",
            "es": "Pardo arcilla",
            "it": "Marrone fango",
            "nl": "Leembruin"
    }
},
    "8004": {
    "code": "8004",
        "scope": "classic",
        "color": {
        "hex": "#8D4931",
            "websafe": "#993333",
            "rgb": {
            "r": 141,
                "g": 73,
                "b": 49
        },
        "hsl": {
            "h": 16,
                "s": 48,
                "l": 37
        },
        "hsb": {
            "h": 16,
                "s": 65,
                "b": 55
        },
        "cmyk": {
            "c": "45",
                "m": "80",
                "y": "100",
                "k": "10"
        }
    },
    "names": {
        "de": "Kupferbraun",
            "en": "Copper brown",
            "fr": "Brun cuivré",
            "es": "Pardo cobre",
            "it": "Marrone rame",
            "nl": "Koperbruin"
    }
},
    "8007": {
    "code": "8007",
        "scope": "classic",
        "color": {
        "hex": "#70452A",
            "websafe": "#663333",
            "rgb": {
            "r": 112,
                "g": 69,
                "b": 42
        },
        "hsl": {
            "h": 23,
                "s": 45,
                "l": 30
        },
        "hsb": {
            "h": 23,
                "s": 63,
                "b": 44
        },
        "cmyk": {
            "c": "40",
                "m": "70",
                "y": "100",
                "k": "50"
        }
    },
    "names": {
        "de": "Rehbraun",
            "en": "Fawn brown",
            "fr": "Brun fauve",
            "es": "Pardo corzo",
            "it": "Marrone capriolo",
            "nl": "Reebruin"
    }
},
    "8008": {
    "code": "8008",
        "scope": "classic",
        "color": {
        "hex": "#724A25",
            "websafe": "#663333",
            "rgb": {
            "r": 114,
                "g": 74,
                "b": 37
        },
        "hsl": {
            "h": 29,
                "s": 51,
                "l": 30
        },
        "hsb": {
            "h": 29,
                "s": 68,
                "b": 45
        },
        "cmyk": {
            "c": "15",
                "m": "50",
                "y": "90",
                "k": "60"
        }
    },
    "names": {
        "de": "Olivbraun",
            "en": "Olive brown",
            "fr": "Brun olive",
            "es": "Pardo oliva",
            "it": "Marrone oliva",
            "nl": "Olijfbruin"
    }
},
    "8011": {
    "code": "8011",
        "scope": "classic",
        "color": {
        "hex": "#5A3826",
            "websafe": "#663333",
            "rgb": {
            "r": 90,
                "g": 56,
                "b": 38
        },
        "hsl": {
            "h": 21,
                "s": 41,
                "l": 25
        },
        "hsb": {
            "h": 21,
                "s": 58,
                "b": 35
        },
        "cmyk": {
            "c": "30",
                "m": "70",
                "y": "90",
                "k": "70"
        }
    },
    "names": {
        "de": "Nussbraun",
            "en": "Nut brown",
            "fr": "Brun noisette",
            "es": "Pardo nuez",
            "it": "Marrone noce",
            "nl": "Notenbruin"
    }
},
    "8012": {
    "code": "8012",
        "scope": "classic",
        "color": {
        "hex": "#66332B",
            "websafe": "#663333",
            "rgb": {
            "r": 102,
                "g": 51,
                "b": 43
        },
        "hsl": {
            "h": 8,
                "s": 41,
                "l": 28
        },
        "hsb": {
            "h": 8,
                "s": 58,
                "b": 40
        },
        "cmyk": {
            "c": "40",
                "m": "90",
                "y": "100",
                "k": "50"
        }
    },
    "names": {
        "de": "Rotbraun",
            "en": "Red brown",
            "fr": "Brun rouge",
            "es": "Pardo rojo",
            "it": "Marrone rossiccio",
            "nl": "Roodbruin"
    }
},
    "8014": {
    "code": "8014",
        "scope": "classic",
        "color": {
        "hex": "#4A3526",
            "websafe": "#333333",
            "rgb": {
            "r": 74,
                "g": 53,
                "b": 38
        },
        "hsl": {
            "h": 25,
                "s": 32,
                "l": 22
        },
        "hsb": {
            "h": 25,
                "s": 49,
                "b": 29
        },
        "cmyk": {
            "c": "30",
                "m": "60",
                "y": "70",
                "k": "80"
        }
    },
    "names": {
        "de": "Sepiabraun",
            "en": "Sepia brown",
            "fr": "Brun sépia",
            "es": "Sepia",
            "it": "Marrone seppia",
            "nl": "Sepiabruin"
    }
},
    "8015": {
    "code": "8015",
        "scope": "classic",
        "color": {
        "hex": "#5E2F26",
            "websafe": "#663333",
            "rgb": {
            "r": 94,
                "g": 47,
                "b": 38
        },
        "hsl": {
            "h": 10,
                "s": 42,
                "l": 26
        },
        "hsb": {
            "h": 10,
                "s": 60,
                "b": 37
        },
        "cmyk": {
            "c": "50",
                "m": "100",
                "y": "100",
                "k": "50"
        }
    },
    "names": {
        "de": "Kastanienbraun",
            "en": "Chestnut brown",
            "fr": "Marron",
            "es": "Castaño",
            "it": "Marrone castagna",
            "nl": "Kastanjebruin"
    }
},
    "8016": {
    "code": "8016",
        "scope": "classic",
        "color": {
        "hex": "#4C2B20",
            "websafe": "#333333",
            "rgb": {
            "r": 76,
                "g": 43,
                "b": 32
        },
        "hsl": {
            "h": 15,
                "s": 41,
                "l": 21
        },
        "hsb": {
            "h": 15,
                "s": 58,
                "b": 30
        },
        "cmyk": {
            "c": "40",
                "m": "80",
                "y": "70",
                "k": "70"
        }
    },
    "names": {
        "de": "Mahagonibraun",
            "en": "Mahogany brown",
            "fr": "Brun acajou",
            "es": "Caoba",
            "it": "Marrone mogano",
            "nl": "Mahoniebruin"
    }
},
    "8017": {
    "code": "8017",
        "scope": "classic",
        "color": {
        "hex": "#442F29",
            "websafe": "#333333",
            "rgb": {
            "r": 68,
                "g": 47,
                "b": 41
        },
        "hsl": {
            "h": 13,
                "s": 25,
                "l": 21
        },
        "hsb": {
            "h": 13,
                "s": 40,
                "b": 27
        },
        "cmyk": {
            "c": "30",
                "m": "70",
                "y": "60",
                "k": "80"
        }
    },
    "names": {
        "de": "Schokoladenbraun",
            "en": "Chocolate brown",
            "fr": "Brun chocolat",
            "es": "Chocolate",
            "it": "Marrone cioccolata",
            "nl": "Chocoladebruin"
    }
},
    "8019": {
    "code": "8019",
        "scope": "classic",
        "color": {
        "hex": "#3D3635",
            "websafe": "#333333",
            "rgb": {
            "r": 61,
                "g": 54,
                "b": 53
        },
        "hsl": {
            "h": 7,
                "s": 7,
                "l": 22
        },
        "hsb": {
            "h": 7,
                "s": 13,
                "b": 24
        },
        "cmyk": {
            "c": "50",
                "m": "50",
                "y": "40",
                "k": "80"
        }
    },
    "names": {
        "de": "Graubraun",
            "en": "Grey brown",
            "fr": "Brun gris",
            "es": "Pardo grisáceo",
            "it": "Marrone grigiastro",
            "nl": "Grijsbruin"
    }
},
    "8022": {
    "code": "8022",
        "scope": "classic",
        "color": {
        "hex": "#1A1718",
            "websafe": "#330000",
            "rgb": {
            "r": 26,
                "g": 23,
                "b": 24
        },
        "hsl": {
            "h": 340,
                "s": 6,
                "l": 10
        },
        "hsb": {
            "h": 340,
                "s": 12,
                "b": 10
        },
        "cmyk": {
            "c": "100",
                "m": "50",
                "y": "30",
                "k": "100"
        }
    },
    "names": {
        "de": "Schwarzbraun",
            "en": "Black brown",
            "fr": "Brun noir",
            "es": "Pardo negruzco",
            "it": "Marrone nerastro",
            "nl": "Zwartbruin"
    }
},
    "8023": {
    "code": "8023",
        "scope": "classic",
        "color": {
        "hex": "#A45729",
            "websafe": "#996633",
            "rgb": {
            "r": 164,
                "g": 87,
                "b": 41
        },
        "hsl": {
            "h": 22,
                "s": 60,
                "l": 40
        },
        "hsb": {
            "h": 22,
                "s": 75,
                "b": 64
        },
        "cmyk": {
            "c": "25",
                "m": "75",
                "y": "100",
                "k": "10"
        }
    },
    "names": {
        "de": "Orangebraun",
            "en": "Orange brown",
            "fr": "Brun orangé",
            "es": "Pardo anaranjado",
            "it": "Marrone arancio",
            "nl": "Oranjebruin"
    }
},
    "8024": {
    "code": "8024",
        "scope": "classic",
        "color": {
        "hex": "#795038",
            "websafe": "#666633",
            "rgb": {
            "r": 121,
                "g": 80,
                "b": 56
        },
        "hsl": {
            "h": 22,
                "s": 37,
                "l": 35
        },
        "hsb": {
            "h": 22,
                "s": 54,
                "b": 47
        },
        "cmyk": {
            "c": "25",
                "m": "60",
                "y": "70",
                "k": "50"
        }
    },
    "names": {
        "de": "Beigebraun",
            "en": "Beige brown",
            "fr": "Brun beige",
            "es": "Pardo beige",
            "it": "Marrone beige",
            "nl": "Beigebruin"
    }
},
    "8025": {
    "code": "8025",
        "scope": "classic",
        "color": {
        "hex": "#755847",
            "websafe": "#666633",
            "rgb": {
            "r": 117,
                "g": 88,
                "b": 71
        },
        "hsl": {
            "h": 22,
                "s": 24,
                "l": 37
        },
        "hsb": {
            "h": 22,
                "s": 39,
                "b": 46
        },
        "cmyk": {
            "c": "40",
                "m": "60",
                "y": "60",
                "k": "45"
        }
    },
    "names": {
        "de": "Blassbraun",
            "en": "Pale brown",
            "fr": "Brun pâle",
            "es": "Pardo pálido",
            "it": "Marrone pallido",
            "nl": "Bleekbruin"
    }
},
    "8028": {
    "code": "8028",
        "scope": "classic",
        "color": {
        "hex": "#513A2A",
            "websafe": "#663333",
            "rgb": {
            "r": 81,
                "g": 58,
                "b": 42
        },
        "hsl": {
            "h": 25,
                "s": 32,
                "l": 24
        },
        "hsb": {
            "h": 25,
                "s": 48,
                "b": 32
        },
        "cmyk": {
            "c": "60",
                "m": "70",
                "y": "90",
                "k": "60"
        }
    },
    "names": {
        "de": "Terrabraun",
            "en": "Terra brown",
            "fr": "Brun terre",
            "es": "Marrón tierra",
            "it": "Marrone terra",
            "nl": "Terrabruin"
    }
},
    "8029": {
    "code": "8029",
        "scope": "classic",
        "color": {
        "hex": "#7F4031",
            "websafe": "#663333",
            "rgb": {
            "r": 127,
                "g": 64,
                "b": 49
        },
        "hsl": {
            "h": 12,
                "s": 44,
                "l": 35
        },
        "hsb": {
            "h": 12,
                "s": 61,
                "b": 50
        },
        "cmyk": {
            "c": "30",
                "m": "80",
                "y": "80",
                "k": "45"
        }
    },
    "names": {
        "de": "Perlkupfer",
            "en": "Pearl copper",
            "fr": "Cuivre nacré",
            "es": "Cobre perlado",
            "it": "Rame perlato",
            "nl": "Parelmoer koper"
    }
},
    "9001": {
    "code": "9001",
        "scope": "classic",
        "color": {
        "hex": "#E9E0D2",
            "websafe": "#ffcccc",
            "rgb": {
            "r": 233,
                "g": 224,
                "b": 210
        },
        "hsl": {
            "h": 37,
                "s": 34,
                "l": 87
        },
        "hsb": {
            "h": 37,
                "s": 10,
                "b": 91
        },
        "cmyk": {
            "c": "5",
                "m": "5",
                "y": "15",
                "k": "0"
        }
    },
    "names": {
        "de": "Cremeweiß",
            "en": "Cream",
            "fr": "Blanc crème",
            "es": "Blanco crema",
            "it": "Bianco crema",
            "nl": "Crèmewit"
    }
},
    "9002": {
    "code": "9002",
        "scope": "classic",
        "color": {
        "hex": "#D7D5CB",
            "websafe": "#cccccc",
            "rgb": {
            "r": 215,
                "g": 213,
                "b": 203
        },
        "hsl": {
            "h": 50,
                "s": 13,
                "l": 82
        },
        "hsb": {
            "h": 50,
                "s": 6,
                "b": 84
        },
        "cmyk": {
            "c": "0",
                "m": "0",
                "y": "10",
                "k": "10"
        }
    },
    "names": {
        "de": "Grauweiß",
            "en": "Grey white",
            "fr": "Blanc gris",
            "es": "Blanco grisáceo",
            "it": "Bianco grigiastro",
            "nl": "Grijswit"
    }
},
    "9003": {
    "code": "9003",
        "scope": "classic",
        "color": {
        "hex": "#ECECE7",
            "websafe": "#ffffff",
            "rgb": {
            "r": 236,
                "g": 236,
                "b": 231
        },
        "hsl": {
            "h": 60,
                "s": 12,
                "l": 92
        },
        "hsb": {
            "h": 60,
                "s": 2,
                "b": 93
        },
        "cmyk": {
            "c": "0",
                "m": "0",
                "y": "0",
                "k": "0"
        }
    },
    "names": {
        "de": "Signalweiß",
            "en": "Signal white",
            "fr": "Blanc de sécurité",
            "es": "Blanco señales",
            "it": "Bianco segnale",
            "nl": "Signaalwit"
    }
},
    "9004": {
    "code": "9004",
        "scope": "classic",
        "color": {
        "hex": "#2B2B2C",
            "websafe": "#333333",
            "rgb": {
            "r": 43,
                "g": 43,
                "b": 44
        },
        "hsl": {
            "h": 240,
                "s": 1,
                "l": 17
        },
        "hsb": {
            "h": 240,
                "s": 2,
                "b": 17
        },
        "cmyk": {
            "c": "35",
                "m": "50",
                "y": "40",
                "k": "90"
        }
    },
    "names": {
        "de": "Signalschwarz",
            "en": "Signal black",
            "fr": "Noir de sécurité",
            "es": "Negro señales",
            "it": "Nero segnale",
            "nl": "Signaalzwart"
    }
},
    "9005": {
    "code": "9005",
        "scope": "classic",
        "color": {
        "hex": "#0E0E10",
            "websafe": "#000000",
            "rgb": {
            "r": 14,
                "g": 14,
                "b": 16
        },
        "hsl": {
            "h": 240,
                "s": 7,
                "l": 6
        },
        "hsb": {
            "h": 240,
                "s": 13,
                "b": 6
        },
        "cmyk": {
            "c": "100",
                "m": "40",
                "y": "50",
                "k": "90"
        }
    },
    "names": {
        "de": "Tiefschwarz",
            "en": "Jet black",
            "fr": "Noir foncé",
            "es": "Negro intenso",
            "it": "Nero intenso",
            "nl": "Gitzwart"
    }
},
    "9006": {
    "code": "9006",
        "scope": "classic",
        "color": {
        "hex": "#A1A1A0",
            "websafe": "#999999",
            "rgb": {
            "r": 161,
                "g": 161,
                "b": 160
        },
        "hsl": {
            "h": 60,
                "s": 1,
                "l": 63
        },
        "hsb": {
            "h": 60,
                "s": 1,
                "b": 63
        },
        "cmyk": {
            "c": "35",
                "m": "30",
                "y": "30",
                "k": "10"
        }
    },
    "names": {
        "de": "Weißaluminium",
            "en": "White aluminium",
            "fr": "Aluminium blanc",
            "es": "Aluminio blanco",
            "it": "Alluminio brillante",
            "nl": "Blank aluminiumkleurig"
    }
},
    "9007": {
    "code": "9007",
        "scope": "classic",
        "color": {
        "hex": "#878581",
            "websafe": "#999999",
            "rgb": {
            "r": 135,
                "g": 133,
                "b": 129
        },
        "hsl": {
            "h": 40,
                "s": 2,
                "l": 52
        },
        "hsb": {
            "h": 40,
                "s": 4,
                "b": 53
        },
        "cmyk": {
            "c": "35",
                "m": "30",
                "y": "30",
                "k": "15"
        }
    },
    "names": {
        "de": "Graualuminium",
            "en": "Grey aluminium",
            "fr": "Aluminium gris",
            "es": "Aluminio gris",
            "it": "Alluminio grigiastro",
            "nl": "Grijs aluminiumkleurig"
    }
},
    "9010": {
    "code": "9010",
        "scope": "classic",
        "color": {
        "hex": "#F1ECE1",
            "websafe": "#ffffcc",
            "rgb": {
            "r": 241,
                "g": 236,
                "b": 225
        },
        "hsl": {
            "h": 41,
                "s": 36,
                "l": 91
        },
        "hsb": {
            "h": 41,
                "s": 7,
                "b": 95
        },
        "cmyk": {
            "c": "0",
                "m": "0",
                "y": "5",
                "k": "0"
        }
    },
    "names": {
        "de": "Reinweiß",
            "en": "Pure white",
            "fr": "Blanc pur",
            "es": "Blanco puro",
            "it": "Bianco puro",
            "nl": "Zuiver wit"
    }
},
    "9011": {
    "code": "9011",
        "scope": "classic",
        "color": {
        "hex": "#27292B",
            "websafe": "#333333",
            "rgb": {
            "r": 39,
                "g": 41,
                "b": 43
        },
        "hsl": {
            "h": 210,
                "s": 5,
                "l": 16
        },
        "hsb": {
            "h": 210,
                "s": 9,
                "b": 17
        },
        "cmyk": {
            "c": "60",
                "m": "45",
                "y": "30",
                "k": "90"
        }
    },
    "names": {
        "de": "Graphitschwarz",
            "en": "Graphite black",
            "fr": "Noir graphite",
            "es": "Negro grafito",
            "it": "Nero grafite",
            "nl": "Grafietzwart"
    }
},
    "9016": {
    "code": "9016",
        "scope": "classic",
        "color": {
        "hex": "#F1F0EA",
            "websafe": "#ffffff",
            "rgb": {
            "r": 241,
                "g": 240,
                "b": 234
        },
        "hsl": {
            "h": 51,
                "s": 20,
                "l": 93
        },
        "hsb": {
            "h": 51,
                "s": 3,
                "b": 95
        },
        "cmyk": {
            "c": "0",
                "m": "0",
                "y": "5",
                "k": "0"
        }
    },
    "names": {
        "de": "Verkehrsweiß",
            "en": "Traffic white",
            "fr": "Blanc signalisation",
            "es": "Blanco tráfico",
            "it": "Bianco traffico",
            "nl": "Verkeerswit"
    }
},
    "9017": {
    "code": "9017",
        "scope": "classic",
        "color": {
        "hex": "#2A292A",
            "websafe": "#333333",
            "rgb": {
            "r": 42,
                "g": 41,
                "b": 42
        },
        "hsl": {
            "h": 300,
                "s": 1,
                "l": 16
        },
        "hsb": {
            "h": 300,
                "s": 2,
                "b": 16
        },
        "cmyk": {
            "c": "50",
                "m": "30",
                "y": "50",
                "k": "100"
        }
    },
    "names": {
        "de": "Verkehrsschwarz",
            "en": "Traffic black",
            "fr": "Noir signalisation",
            "es": "Negro tráfico",
            "it": "Nero traffico",
            "nl": "Verkeerszwart"
    }
},
    "9018": {
    "code": "9018",
        "scope": "classic",
        "color": {
        "hex": "#C8CBC4",
            "websafe": "#cccccc",
            "rgb": {
            "r": 200,
                "g": 203,
                "b": 196
        },
        "hsl": {
            "h": 86,
                "s": 6,
                "l": 78
        },
        "hsb": {
            "h": 86,
                "s": 3,
                "b": 80
        },
        "cmyk": {
            "c": "10",
                "m": "5",
                "y": "15",
                "k": "10"
        }
    },
    "names": {
        "de": "Papyrusweiß",
            "en": "Papyrus white",
            "fr": "Blanc papyrus",
            "es": "Blanco papiro",
            "it": "Bianco papiro",
            "nl": "Papyruswit"
    }
},
    "9022": {
    "code": "9022",
        "scope": "classic",
        "color": {
        "hex": "#858583",
            "websafe": "#999999",
            "rgb": {
            "r": 133,
                "g": 133,
                "b": 131
        },
        "hsl": {
            "h": 60,
                "s": 1,
                "l": 52
        },
        "hsb": {
            "h": 60,
                "s": 2,
                "b": 52
        },
        "cmyk": {
            "c": "35",
                "m": "30",
                "y": "30",
                "k": "20"
        }
    },
    "names": {
        "de": "Perlhellgrau",
            "en": "Pearl light grey",
            "fr": "Gris clair nacré",
            "es": "Gris claro perlado",
            "it": "Grigio chiaro perlato",
            "nl": "Parelmoer lichtgrijs"
    }
},
    "9023": {
    "code": "9023",
        "scope": "classic",
        "color": {
        "hex": "#797B7A",
            "websafe": "#666666",
            "rgb": {
            "r": 121,
                "g": 123,
                "b": 122
        },
        "hsl": {
            "h": 150,
                "s": 1,
                "l": 48
        },
        "hsb": {
            "h": 150,
                "s": 2,
                "b": 48
        },
        "cmyk": {
            "c": "15",
                "m": "10",
                "y": "10",
                "k": "50"
        }
    },
    "names": {
        "de": "Perldunkelgrau",
            "en": "Pearl dark grey",
            "fr": "Gris fonçé nacré",
            "es": "Gris oscuro perlado",
            "it": "Grigio scuro perlato",
            "nl": "Parelmoer donkergrijs"
    }
}
}

export const ralRu = {
    "1000": "Зелёный песок",
    "1001": "Бежевый",
    "1002": "Песчаник",
    "1003": "Сигнальный жёлтый",
    "1004": "Золотисто-жёлтый",
    "1005": "Медно-золотистый",
    "1006": "Кукурузно-жёлтый",
    "1007": "Нарциссово-жёлтый",
    "1011": "Коричнево-бежевый",
    "1012": "Лимонно-жёлтый",
    "1013": "Жемчужно-белый",
    "1014": "Кремовый",
    "1015": "Светло-кремовый",
    "1016": "Жёлтый шафран",
    "1017": "Шафраново-жёлтый",
    "1018": "Цинково-жёлтый",
    "1019": "Серо-бежевый",
    "1020": "Оливково-жёлтый",
    "1021": "Рапсово-жёлтый",
    "1023": "Лимонно-жёлтый",
    "1024": "Окра жёлтая",
    "1026" : 'Люминесцентный желтый',
    "1027": "Карри жёлтый",
    "1028": "Жёлто-оранжевый",
    "1032": "Бурый",
    "1033": "Колокольчик",
    "1034": "Пастельно-жёлтый",
    '1035': "Перламутрово-бежевый",
    "1036": "Перламутрово-золотой",
    "1037": "Желто-золотой",
    "2000": "Жёлто-оранжевый",
    "2001": "Красно-оранжевый",
    "2002": "Киноварь",
    "2003": "Пастельно-оранжевый",
    "2004": "Медово-оранжевый",
    "2005": "Люминесцентный оранжевый",
    "2007": "Карибский оранжевый",
    "2008": "Жёлто-красный",
    "2009": "Трафаретно-жёлтый",
    "2010": "Сигнальный оранжевый",
    "2011": "Тёмно-оранжевый",
    "2012": "Лососево-оранжевый",
    "2013": "Перламутрово-оранжевый",
    "3000": "Огненно-красный",
    "3001": "Сигнальный красный",
    "3002": "Карминно-красный",
    "3003": "Рубиново-красный",
    "3004": "Пурпурно-красный",
    "3005": "Винно-красный",
    "3007": "Чёрно-красный",
    "3009": "Оксидно-красный",
    "3011": "Кораллово-красный",
    "3012": "Бежево-красный",
    "3013": "Томатно-красный",
    "3014": "Античный розовый",
    "3015": "Светло-розовый",
    "3016": "Кораллово-красный",
    "3017": "Розовый",
    "3018": "Красно-розовый",
    "3020": "Лососево-красный",
    "3022": "Лососев",
    "3023": "Рубиново-фиолетовый",
    "3024": "Лососево-красный",
    "3026": "Люминесцентный красный",
    "3027": "Малиновый",
    "3028": "Ярко-красный",
    "3031": "Ориент-красный",
    "3032": "Малиново-красный",
    "3033": "Кораллово-розовый",
    "4001": "Красно-лиловый",
    "4002": "Красно-фиолетовый",
    "4003": "Фиолетово-красный",
    "4004": "Бордовый",
    "4005": "Сине-фиолетовый",
    "4006": "Трафаретно-фиолетовый",
    "4007": "Фиолетовый",
    "4008": "Фиолетово-зелёный",
    "4009": "Фиолетово-серый",
    "4010": "Теллур-фиолетовый",
    "5000": "Фиолетово-синий",
    "5001": "Зелёно-синий",
    "5002": "Ультрамариновый",
    "5003": "Сапфирово-синий",
    "5004": "Чёрно-синий",
    "5005": "Сигнально-синий",
    "5007": "Бриллиантово-синий",
    "5008": "Серо-синий",
    "5009": "Сине-голубой",
    "5010": "Голубой",
    "5011": "Стально-синий",
    "5012": "Голубой",
    "5013": "Тёмно-синий",
    "5014": "Кобальтовый",
    "5015": "Светло-синий",
    "5017": "Транспортно-синий",
    "5018": "Сине-зелёный",
    "5019": "Капелька синего",
    "5020": "Океанно-синий",
    "5021": "Вода",
    "5022": "Ночное небо",
    "5023": "Голубая сталь",
    "5024": "Пастельно-синий",
    "5025": "Перламутрово-синий",
    "5026": "Пастельно-зелёный",
    "6000": "Патина",
    "6001": "Изумрудно-зелёный",
    "6002": "Лиственно-зелёный",
    "6003": "Оливково-зелёный",
    "6004": "Сигнально-зелёный",
    "6005": "Камуфляж",
    "6006": "Серо-оливковый",
    "6007": "Тёмный хаки",
    "6008": "Зелёный коричневый",
    "6009": "Ель",
    "6010": "Травяной зелёный",
    "6011": "Резедово-зелёный",
    "6012": "Чёрный зелёный",
    "6013": "Бамбуково-зелёный",
    "6014": "Жёлто-зелёный",
    "6015": "Чёрное дерево",
    "6016": "Бирюзово-зелёный",
    "6017": "Майский зелёный",
    "6018": "Желтовато-зелёный",
    "6019": "Серо-оливковый",
    "6020": "Хлорофилл",
    "6021": "Палево-зелёный",
    "6022": "Коричневый",
    "6024": "Турмалиново-зелёный",
    "6025": "Зелёный мох",
    "6026": "Бирюзово-зелёный",
    "6027": "Светло-зелёный",
    "6028": "Хаки зелёный",
    "6029": "Мятно-зелёный",
    "6032": "Цинково-зелёный",
    "6033": "Ментолово-зелёный",
    "6034": "Пастельно-зелёный",
    "6035": "Перламутрово-зелёный",
    "6036": "Пастельно-зелёный",
    "6037": "Ярко-зелёный",
    "6038": "Лиственно-зелёный",
    "7000": "Пастельно-серый",
    "7001": "Серо-серый",
    "7002": "Серо-зелёный",
    "7003": "Серо-каштановый",
    "7004": "Сигнально-серый",
    "7005": "Мышиный серый",
    "7006": "Бежево-серый",
    "7008": "Серо-коричневый",
    "7009": "Хаки серый",
    "7010": "Тёмно-серый",
    "7011": "Железо серый",
    "7012": "Базальт серый",
    "7013": "Коричный серый",
    "7015": "Черный серый",
    "7016": "Антрацит",
    "7021": "Чёрно-коричневый",
    "7022": "Серо-чёрный",
    "7023": "Серо-коричневый",
    "7024": "Графит серый",
    "7026": "Сланцево-серый",
    "7030": "Каменно-серый",
    "7031": "Серо-голубой",
    "7032": "Галечно-серый",
    "7033": "Серо-цементный",
    "7034": "Жёлто-серый",
    "7035": "Светло-серый",
    "7036": "Платиново-серый",
    "7037": "Пастельно-серый",
    "7038": "Агат серый",
    "7039": "Кварцево-серый",
    "7040": "Серо-бледно-серый",
    "7042": "Трафаретно-серый",
    "7043": "Серо-зелёный",
    "7044": "Шелк серый",
    "7045": "Теллур серый",
    "7046": "Тёмно-серый",
    "7047": "Тёмно-светло-серый",
    "7048": "Перламутрово-серый",
    "8000": "Зелёно-коричневый",
    "8001": "Охра коричневый",
    "8002": "Сигнально-коричневый",
    "8003": "Глиняно-коричневый",
    "8004": "Медно-коричневый",
    "8007": "Битумно-коричневый",
    "8008": "Оливково-коричневый",
    "8011": "Орехово-коричневый",
    "8012": "Красное дерево",
    "8014": "Красный кирпич",
    "8015": "Каштановый",
    "8016": "Махагон",
    "8017": "Шоколадно-коричневый",
    "8019": "Серо-коричневый",
    "8022": "Чёрный шоколад",
    "8023": "Оранжево-коричневый",
    "8024": "Бежево-коричневый",
    "8025": "Коричневый",
    "8028": "Терракотово-коричневый",
    "8029": "Жёлто-коричневый",
    "9001": "Кремово-белый",
    "9002": "Светло-серый",
    "9003": "Сигнально-белый",
    "9004": "Сигнально-чёрный",
    "9005": "Чёрный",
    "9006": "Белый алюминиевый",
    "9007": "Серо-алюминиевый",
    "9010": "Чисто-белый",
    "9011": "Графитно-чёрный",
    "9016": "Транспортно-белый",
    "9017": "Транспортно-чёрный",
    "9018": "Папирусно-белый",
    "9022": "Перламутрово-серый",
    "9023": "Перламутрово-тёмно-серый"
};