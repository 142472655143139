import React, { useEffect, useState,useRef } from 'react';
import styled from 'styled-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { allFilters, filterModal } from '../../state/atoms';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {  MenuItem, Select} from '@mui/material';
import { SelectedFilter } from '../Table/Table';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const MultiSelectInput = ({ options, selectedOptions, setSelectedOptions, placeholder }) => {
  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const inputRef = useRef(null);

  const handleOptionClick = (option) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  };

  const handleInputClick = () => {
    setShowOptions(!showOptions);
  };

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={inputRef}>
      <StyledInputContainer onClick={handleInputClick}>
        <StyledInput
          readOnly
          value={selectedOptions.join(', ')}
          placeholder={placeholder}
        />
      </StyledInputContainer>
      {showOptions && (
        <OptionsList>
          {options.map((option) => (
            <Option key={option} onClick={() => handleOptionClick(option)}>
              <Checkbox
                style={{color: 'rgba(7, 7, 7, 1)', paddingRight: '12px'}}
                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                checked={selectedOptions.includes(option)}
              />
              {option}
            </Option>
          ))}
        </OptionsList>
      )}
    </Container>
  );
};

const FilterModal = ({ data, tableLength }) => {
    const setOpen = useSetRecoilState(filterModal);

    const [marks, setMarks] = useState([]);
    const [base, setBase] = useState([]);
    const [filters, setFilters] = useRecoilState(allFilters);

    const [selectedMarks, setSelectedMarks] = useState(filters.selectedMarks || []);
    const [selectedBase, setSelectedBase] = useState(filters.selectedBase || []);
    const [r1, setR1] = useState(filters.r1 || '');
    const [r2, setR2] = useState(filters.r2 || '');
    const [r3, setR3] = useState(filters.r3 || '');
    const [sort, setSort] = useState(filters.sort || 'default');

    const clickOutside = (e) => {
        setOpen(false);
    };

    const handleClickInside = (e) => {
        e.stopPropagation();
    };

    function handleInputChange(e, setValue) {
        let inputValue = e.target.value; // Приводим к строке
        const filteredValue = inputValue.replace(/[^\d.]/g, '');
        const dotIndex = filteredValue.indexOf('.');
        if (dotIndex !== -1) {
            inputValue = inputValue.slice(0, dotIndex + 1) + inputValue.slice(dotIndex + 1).replace(/\./g, '');
        }
        setValue(inputValue);
    }

    const cancelFilter = () => {
        setSelectedMarks([]);
        setSelectedBase([]);
        setR1('');
        setR2('');
        setR3('');
        setSort('default');
        setFilters({});
    };

    const handleSubmit = () => {
        const filters = {};
        if (selectedMarks.length > 0) {
            filters.selectedMarks = selectedMarks;
        }
        if (selectedBase.length > 0) {
            filters.selectedBase = selectedBase;
        }
        if (r1) {
            filters.r1 = r1;
        }
        if (r2) {
            filters.r2 = r2;
        }
        if (r3) {
            filters.r3 = r3;
        }
        if (sort !== 'default') {
            filters.sort = sort;
        }
        setFilters(filters);
    };

    useEffect(() => {
        const uniqueMarks = [...new Set(data.map((row) => row['Марка']))].filter((item) => item !== '');
        if (uniqueMarks[0] !== undefined) setMarks(uniqueMarks.sort());
        const uniqueBase = [...new Set(data.map((row) => row['База']))].filter((item) => item !== '');
        if (uniqueBase[0] !== undefined) setBase(uniqueBase.sort());
    }, [data]);

    useEffect(() => {
        setSelectedMarks(filters.selectedMarks || []);
        setSelectedBase(filters.selectedBase || []);
        setR1(filters.r1 || '');
        setR2(filters.r2 || '');
        setR3(filters.r3 || '');
        setSort(filters.sort || 'default');
      }, [filters]);

    return (
        <Wrapper onClick={clickOutside}>
            <Modal onClick={handleClickInside} className={JSON.stringify(filters) !== '{}' ? 'gap' : ''}>
                <CloseBtn onClick={clickOutside} />

                <FirsWrapper>
                    <BigInputWrapper>
                        {marks.length > 0 && (
                            <MultiSelectInput
                                options={marks}
                                selectedOptions={selectedMarks}
                                setSelectedOptions={setSelectedMarks}
                                placeholder="Выберите марки"
                            />
                        )}
                        {base.length > 0 && (
                            <MultiSelectInput
                                options={base}
                                selectedOptions={selectedBase}
                                setSelectedOptions={setSelectedBase}
                                placeholder="Выберите базы"
                            />
                        )}
                        <InputWrapper>
                            <div style={{ display: 'flex', gap: '8px', alignItems: 'center', maxHeight: '36px' }}>
                                <p>R1</p>
                                <SmallInput placeholder={'0'} value={r1} onChange={(e) => handleInputChange(e, setR1)} />
                            </div>
                            {'R2' in data[0] && (
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center', maxHeight: '36px' }}>
                                    <p>R2</p>
                                    <SmallInput placeholder={'0'} value={r2} onChange={(e) => handleInputChange(e, setR2)} />
                                </div>
                            )}
                            {'R3' in data[0] && (
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center', maxHeight: '36px' }}>
                                    <p>R3</p>
                                    <SmallInput placeholder={'0'} value={r3} onChange={(e) => handleInputChange(e, setR3)} />
                                </div>
                            )}
                        </InputWrapper>
                        <div style={{ display: 'flex', gap: '8px', alignItems: 'center', maxHeight: '36px' }}>
                            <p>Сортировка R1</p>
                            <InputWrapper style={{ justifyContent: 'center' }}>
                                <Select
                                    style={{ borderRadius: '10px', fontSize: '14px', width: '180px', maxHeight: '36px' }}
                                    sx={{
                                        '& div': {
                                            padding: '13px'
                                        },
                                        '& .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root': {
                                            fontSize: '14px'
                                        }
                                    }}
                                    value={sort}
                                    onChange={(e) => setSort(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value={'default'} selected>
                                        По умолчанию
                                    </MenuItem>
                                    <MenuItem value={'up'}>От большего R1</MenuItem>
                                    <MenuItem value={'down'}>От меньшего R1</MenuItem>
                                </Select>
                            </InputWrapper>
                        </div>
                    </BigInputWrapper>
                    <ButtonWrapper>
                        <CancelBtn onClick={cancelFilter}>Сбросить</CancelBtn>
                        <SubBtn onClick={handleSubmit}>Применить</SubBtn>
                    </ButtonWrapper>
                </FirsWrapper>
                <SecondWrapper>
                    <SelectedFilter />

                    {JSON.stringify(filters) !== '{}' && (
                        <TableLength>
                            Всего записей: <span>{tableLength}</span>
                        </TableLength>
                    )}
                </SecondWrapper>
            </Modal>
        </Wrapper>
    );
};

export default FilterModal;

const FirsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 32px;
`;
const SecondWrapper = styled.div`
    display: flex;
    gap: 32px;
`;
const TableLength = styled.p`
    font-weight: 400;
    color: rgba(84, 84, 86, 1);
    text-wrap: nowrap;
    white-space: nowrap;
    padding-right: 32px;
    align-self: end;
`;
const ButtonWrapper = styled.div`
    display: flex;
    gap: 20px;
    padding-right: 32px;
    @media screen and (max-width: 1280px) {
        flex-direction: column;
    }
`;
const BigInputWrapper = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
`;
const CancelBtn = styled.div`
    width: 120px;
    padding: 11px 22px;
    text-align: center;
    border-radius: 16px;
    cursor: pointer;
    outline: 1px solid #070707;
    color: #070707;
    font-weight: 400;
    height: fit-content;
`;
const SubBtn = styled.div`
    width: 120px;
    padding: 11px 22px;
    text-align: center;
    border-radius: 16px;
    cursor: pointer;
    background: #070707;
    color: white;
    font-weight: 400;
    height: fit-content;
`;
const InputWrapper = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
`;
const SmallInput = styled.input`
    text-align: center;
    font-size: 14px;
    padding: 10px;
    width: 60px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    max-height: 36px;
`;
const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
const Modal = styled.div`
    width: 100%;
    background: white;
    border-radius: 16px;
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    &.gap {
        gap: 20px;
    }
    @media screen and (max-width: 1280px) {
        padding: 16px;
    }
    @media screen and (max-width: 960px) {
        padding: 12px;
    }
`;
const CloseBtn = styled(CloseRoundedIcon)`
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    transition: background 0.6s ease !important;
    border-radius: 100%;
    width: 28px !important;
    height: 28px !important;
    padding: 4px;
    &:hover {
        background: rgba(89, 89, 89, 0.25);
    }
`;

// MultiSelectInput styles
const Container = styled.div`
    width: 170px;
    font-family: 'Roboto', sans-serif;
`;

const StyledInputContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 8px;
`;

const StyledInput = styled.input`
    border: none;
    outline: none;
    flex: 1;
    min-width: 50px;
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
`;

const OptionsList = styled.ul`
    list-style: none;
    margin: 4px 0 0;
    border: 1px solid rgba(7, 7, 7, 1);
    max-height: 240px;
    overflow-y: auto;
    border-radius: 8px;
    width: max-content;
    position: absolute;
    z-index: 995;
`;

const Option = styled.li`
    padding: 5px 16px 5px 5px;
    cursor: pointer;
    background: ${({ selected }) => (selected ? '#007bff' : '#fff')};
    color: ${({ selected }) => (selected ? '#fff' : '#000')};
    &:hover {
        background: ${({ selected }) => (selected ? '#0056b3' : '#f0f0f0')};
    }
`;
