import React, {useState} from 'react';
import styled from 'styled-components';
import {tableData} from "../../../data/vgpData";

const Vgp = () => {

    const [filterTable, setFilter] = useState(tableData);
    const [selectedItem, setSelectedItem] = useState(null);


    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const closeModal = () => {
        setSelectedItem(null);
    };

  

    return (
        <Wrapper>
        
            <List>
                <Item>
                    <Code>
                        Внутренний диаметр, мм
                    </Code>
                    <Code>
                        Размер в дюймах
                    </Code>
                    <Code>
                        Наружный диаметр, мм
                    </Code>
                    <Code>
                        Длина трубы
                    </Code>
                </Item>
                {filterTable.map((item, index) => (
                    <Item key={index} onClick={() => handleItemClick(item)}>
                        <Code>
                            {item.Vd}
                        </Code>
                        <Code>
                            {item.D}
                        </Code>
                        <Code>
                            {item.Nd}
                        </Code>
                        <Code>
                            {item.Dlina}
                        </Code>
                    </Item>
                ))}
            </List>
            {selectedItem && (
                <ModalBackground onClick={closeModal}>
                    <ModalContent>
                        <img src={`photo_vgp/${selectedItem.photo}`} alt={selectedItem.name} />
                    </ModalContent>
                </ModalBackground>
            )}
        </Wrapper>
    );
};

export default Vgp;
const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Здесь задаем цвет и прозрачность фона */
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 5px;
`;


const Code = styled.div`
    width: 30%;
    padding: 12px 0;
    border-bottom: 1px solid rgba(240, 240, 242, 1);
    border-left: 1px solid rgba(240, 240, 242, 1);
    border-right: 1px solid rgba(240, 240, 242, 1);
    text-align: center;
`

const Item = styled.div`
  width: 100%;
  display: flex;
 
  &:nth-child(odd) {
    background-color: rgba(250, 250, 250, 1);
  }
  &:hover {
    background-color: #D2D2D5;
    cursor: pointer;
  }
  &:first-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: #070707;
    color: white;
    div {
      font-weight: 500;
      color: white;
    }
  }
`
const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

`
const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap:16px;
  width: 100%;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }
`
