import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import closeIcon from '../../assets/closeModal.svg';

const inputFields = [
  { id: '1', title: 'Полное наименование предприятия:', type: 'textarea', initialValue: '' },
  { id: '2', title: 'Сокращенное наименование предприятия:', type: 'input', initialValue: '' },
  { id: '3', title: 'Юридический адрес:', type: 'textarea', initialValue: '' },
  { id: '4', title: 'Почтовый адрес:', type: 'textarea', initialValue: '' },
  { id: '5', title: 'Номер телефона:', type: 'input', initialValue: '' },
  { id: '6', title: 'E-mail:', type: 'input', initialValue: '' },
  { id: '7', title: 'ИНН / КПП', type: 'input', initialValue: '' },
  { id: '8', title: 'ОГРН', type: 'input', initialValue: '' },
  { id: '9', title: 'ОКПО', type: 'input', initialValue: '' },
  { id: '10', title: 'Банковские реквизиты', type: 'textarea', initialValue: '' },
  { id: '11', title: 'Генеральный директор', type: 'input', initialValue: '' },
];

const OfferModal = ({ setOpen }) => {
  const modalRef = useRef(null);
  const [inputValues, setInputValues] = useState(() => {
    const initialState = {};
    inputFields.forEach(field => {
      initialState[field.id] = field.initialValue;
    });
    return initialState;
  });
  const [errors, setErrors] = useState(() => {
    const initialErrors = {};
    inputFields.forEach(field => {
      initialErrors[field.id] = false;
    });
    return initialErrors;
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  const handleChange = (id, value) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [id]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [id]: false
    }));
  };
  const formatMessage = () => {
    const messageLines = inputFields.map(field => `${field.title} ${inputValues[field.id]}`);
    return `❗️ ЗАЯВКА ❗️\n\n${messageLines.join('\n')}`;
  };

  const handleSubmit = async () => {
    const newErrors = {};
    inputFields.forEach(field => {
      if (!inputValues[field.id]) {
        newErrors[field.id] = true;
      }
    });
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Все поля заполнены, можно отправлять данные
    
      try {
        const messageText = formatMessage();
        console.log(messageText)
        const response = await fetch(`https://api.telegram.org/bot6837229218:AAE47oaVpGTO77y4WCI1SEBC2LhOQdofh0Y/sendMessage`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', },
          body: JSON.stringify({
            chat_id: '-1002158090215',
            text: messageText,
          }),
        });

        if (response.ok) {
          setMessage('Заявка отправлена успешно!');
          setInputValues(() => {
            const initialState = {};
            inputFields.forEach(field => {
              initialState[field.id] = field.initialValue;
            });
            return initialState;
          });
          setTimeout(() => {
            setOpen(false);
          
            setMessage('');
            setError('');
          }, 3000);
        } else {
          setError('Ошибка отправки заявки');
        }
      } catch (error) {
        console.error('Error sending message to Telegram:', error);
        setMessage('Ошибка отправки заявки');
      }
    }
  };

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  return (
    <Wrapper>
      <ModalContent ref={modalRef}>
      {message && <Message>{message}</Message>}
      {error && <Error>{error}</Error>}
        <CloseBtn src={closeIcon} alt="close" onClick={() => setOpen(false)} />
        <ScrollContent>
          <SectionTitle>Оформление подписки</SectionTitle>
          {inputFields.map((field) => (
            <FieldWrapper key={field.id}>
              <FieldTitle>{field.title}</FieldTitle>
              {field.type === 'input' ? (
                <SmallInput
                  placeholder={'Введите данные'}
                  value={inputValues[field.id]}
                  onChange={(e) => handleChange(field.id, e.target.value)}
                  isError={errors[field.id]}
                />
              ) : (
                <BigInput
                  placeholder={'Введите данные'}
                  value={inputValues[field.id]}
                  onChange={(e) => handleChange(field.id, e.target.value)}
                  isError={errors[field.id]}
                />
              )}
            </FieldWrapper>
          ))}
          <p style={{ fontSize: '11px', color: '#545456', marginTop: '12px' }}>
            Нажимая кнопку, вы даете согласие на обработку персональных данных
          </p>
          <SubmitButton onClick={handleSubmit}>Оформить подписку</SubmitButton>
         
        </ScrollContent>
      </ModalContent>
    </Wrapper>
  );
};

export default OfferModal;

const CloseBtn = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const SectionTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  margin-bottom: 12px;
`;

const SubmitButton = styled.button`
  background: white;
  padding: 12px 0;
  width: 200px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  font-size: 14px;
  color: #000000;
  margin: 12px auto;
  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  @media screen and (max-width: 768px){
    height: 100%;
  }
`; 

const ModalContent = styled.div`
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background: #212121;
  padding: 40px 32px 52px;
  border-radius: 16px;
  max-width: 540px;
  max-height: 95vh;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  @media screen and (max-width: 768px){
    max-width: none;
    max-height: none;
    height: 100%;
    width:100%;
    border-radius: 0;
    overflow: auto;
    padding: 24px 0 24px;
  }

`;

const ScrollContent = styled.div`
  max-height: 85vh;
  overflow-y: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px){
    max-height: none;
  }
`;

const FieldWrapper = styled.div`
  margin-bottom: 20px;
`;

const FieldTitle = styled.div`
  color: #fff;
  margin-bottom: 6px;
  font-weight: 500;
`;

const SmallInput = styled.input`
  background: transparent;
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  outline: none;
  border: 1px solid ${({ isError }) => (isError ? 'red' : '#d2d2d5')};
  padding: 9px 12px;
  color: #d2d2d5;
  font-family: 'Roboto', sans-serif;
  &:focus {
    border-color: white;
  }
`;

const BigInput = styled.textarea`
  font-family: 'Roboto', sans-serif;
  background: transparent;
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  outline: none;
  border: 1px solid ${({ isError }) => (isError ? 'red' : '#d2d2d5')};
  padding: 9px 12px;
  color: #d2d2d5;
  height: 80px;
  resize: none;
  &:focus {
    border-color: white;
  }
`;

const Message = styled.p`
  font-size: 14px;
  color: green;
  text-align: center;
  margin-top: 12px;
`;
const Error = styled.p`
  font-size: 14px;
  color: red;
  text-align: center;
  margin-top: 12px;
`;

