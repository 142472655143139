import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {gosts} from '../../../data/gosts'

const Gost = () => {
    const catList = Object.keys(gosts);
    const [cat, setCat] = useState(catList[0]);
    const [text, setText] = useState('');
    const [searchedItem, setItems] = useState([]);
    const scrollContainerRef = useRef(null);
    const scrollToTop = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }
    };
    function searchItems(searchTerm) {
        const results = [];
        for (const category in gosts) {
            for (const item of gosts[category]) {
                if (item['name'].toLowerCase().includes(searchTerm) || item['text'].toLowerCase().includes(searchTerm)) {
                    results.push(item);
                }
            }
        }
        return results;
    }
    useEffect(()=>{
        if (text.trim()) {
            const searchResults = searchItems(text.trim().toLowerCase());
            setItems(searchResults);
        } else {
            setItems([]);
        }
    },[text])
    return (
        <Wrapper>
            {/*<Search placeholder="Поиск по названию"/>*/}
            <InnerWrapper>
                <Menu>
                    <Search placeholder={'Поиск по ГОСТам'} onChange={(e) => {setText(e.target.value)}} value={text}/>
                    {text.trim().length > 0 && searchedItem.length > 0 ?
                        <SearchedItems>
                            {searchedItem.map((item, index)=> (
                                <LinkGost key={index} target={'_blank'} href={`pdfs/${item.name}.pdf`}>
                                    <span>{index+1}.</span> {item.name} {item.text}
                                </LinkGost>
                            ))}
                        </SearchedItems>
                        :
                        <>
                            {text.trim().length > 0 && searchedItem.length === 0 ?
                                <p style={{textAlign: 'center', fontSize: '13px'}}>
                                    Ничего не найдено
                                </p>
                                :
                                <>
                                    {catList.map((item,index)=> (
                                        <MenuItem key={index} className={cat === item && 'selected'} onClick={()=> {
                                            setCat(item);
                                            scrollToTop();
                                        }}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </>
                            }
                        </>
                    }
                </Menu>
                <Body  ref={scrollContainerRef}>
                    <Title>{cat}</Title>
                    {gosts[cat].map((item, index)=> (
                        <LinkGost key={index} target={'_blank'} href={`pdfs/${item.name}.pdf`}>
                            <span>{index+1}.</span> {item.name} {item.text}
                        </LinkGost>
                    ))}
                </Body>
            </InnerWrapper>
        </Wrapper>
    );
};

export default Gost;
const SearchedItems = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  gap:10px;
  overflow-y: scroll;

  scrollbar-width: thin; /* Для Firefox */
  scrollbar-color: #D2D2D5 transparent; /* Для Firefox: цвет ползунка и трека */

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0000ff21;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`
const Title = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
    color: rgba(7, 7, 7, 1);
    @media screen and (max-width: 960px){
      font-size: 13px;
     }
`
const LinkGost = styled.a`
font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-decoration: none;
  color: rgba(84, 84, 86, 1);
  font-weight: 400;
  max-width: 100%;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.4s ease;
  span {
    color: rgba(84, 84, 86, 1);
  }
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 960px){
   font-size: 13px;
  }
`
const MenuItem = styled.div`
  cursor: pointer;
  border-radius: 6px;
  padding: 6px 10px;
  font-size: 14px;
  color: #070707;
  font-weight: 400;
  word-break: break-word;
  &.selected {
    font-weight: 500;
    background-color: #F0F0F2
  }
  &:hover {
    font-weight: 500;
    background-color: #F0F0F2
  }
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }
`
const Body = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  width: 100%;
  scrollbar-width: thin; /* Для Firefox */
  scrollbar-color: #F0F0F2 transparent; /* Для Firefox: цвет ползунка и трека */

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0000ff21;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`
const Menu = styled.div`
  font-family: 'Roboto', sans-serif;
  border-right: 1px solid #F0F0F2;
  padding: 8px;
  display: flex;
  flex: 0 0 auto;
  width: 290px;
  height: 100%;
  flex-direction: column;
 @media screen and (max-width: 1370px){
    width: 196px;
  }

`
const InnerWrapper =styled.div`
  display: flex;
  height: 100%;
`
const Wrapper = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  gap:16px;
  width: 100%;
  height: 100%;
`
const Search = styled.input`
  margin-bottom: 10px;
  margin-top: 2px;
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #c5c5c5;
`