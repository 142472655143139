import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {useRecoilState} from "recoil";
import {authState, isMenuWrap} from "../../state/atoms";
import logo from '../../assets/logo.svg';
import logo_text from '../../assets/logo_text.svg';
import calcIcon from '../../assets/calc.svg';
import orderIcon from '../../assets/order.svg';
import homeIcon from '../../assets/home.svg';
import calcIconWhite from '../../assets/calc_white.svg';
import orderIconWhite from '../../assets/order_white.svg';
import homeIconWhite from '../../assets/home_white.svg';
import exitIcon from '../../assets/exit_gray.svg';
import wrapIcon from '../../assets/wrap.svg';
import exitGray from '../../assets/exit_gray.svg';
import exitWhite from '../../assets/exit_white.svg';

const Navbar = () => {
    const [isWrap, setWrap] = useRecoilState(isMenuWrap);
    const [selected, setSelected] = useState(null);
    const location = useLocation();
    const [auth, setAuth] = useRecoilState(authState);

    const handleLogout = () => {
        setAuth(false);
        localStorage.removeItem('authToken');
    }

    useEffect(() => {
        setSelected(location.pathname);
    }, [location]);

    return (
        <Wrapper>
            <LogoWrapper style={{flexDirection:'column', alignItems:'center', gap: '8px'}}>
                <img src={logo} alt="" style={isWrap ? {width: '36px'} : {}}/>
                <img src={logo_text} alt="" style={isWrap ? {display:'none'} : {}}/>
            </LogoWrapper>
            <MenuItems>
                <MenuItem className={`${'/' === selected ? 'selected' : ''} ${isWrap ? 'wrap' : ''}`} to={'/'}>
                    {'/' === selected ? <img src={homeIconWhite}/> : <img src={homeIcon}/>}
                    {isWrap ? '' :  <p>Главная</p>}
                </MenuItem>
                <MenuItem className={`${'/base' === selected ? 'selected' : ''} ${isWrap ? 'wrap' : ''}`}  to={'/base'}>
                    {'/base' === selected ?   <img src={orderIconWhite}/> :   <img src={orderIcon}/>}
                    {isWrap ? '' : <p>Каталог</p>}
                </MenuItem>
                <MenuItem className={`${'/calculator' === selected ? 'selected' : ''} ${isWrap ? 'wrap' : ''}`} to={'/calculator'}>
                    {'/calculator' === selected ?  <img src={calcIconWhite}/> :  <img src={calcIcon}/>}
                    {isWrap ? '' : <p>Калькулятор</p>}
                </MenuItem>

                {!auth ?
                    <LogBtnSmall className={`${'/login' === selected ? 'selected' : ''} ${isWrap? 'wrap': ''}`} to={'/login'}>
                        <img src={'/login' === selected ? exitWhite : exitGray} alt="Вход"/>
                        {isWrap ? '' : <p>Войти</p>}

                    </LogBtnSmall>
                    :
                    <ExtBtnSmall onClick={handleLogout} className={isWrap? 'wrap': ''}>
                        <img src={exitIcon} alt="Выход"/>
                        {isWrap ? '' : <p>Выйти</p>}
                    </ExtBtnSmall>
                }
            </MenuItems>
            <BigWrapper >
                <WrapBtn onClick={() => setWrap(!isWrap)}>
                    <img src={wrapIcon} alt="Свернуть" style={isWrap ? {transform:'rotate(180deg)'} : {}}/>
                    {isWrap ? '' : <p>Свернуть</p>}
                </WrapBtn>
                {!auth ?
                    <LogBtn className={`${'/login' === selected ? 'selected' : ''} ${isWrap? 'wrap': ''}`} to={'/login'}>
                        <img src={exitIcon} alt="Вход"/>
                        {isWrap ? '' : <p>Войти</p>}

                    </LogBtn>
                    :
                    <ExtBtn onClick={handleLogout} className={isWrap? 'wrap': ''}>
                        <img src={exitIcon} alt="Выход"/>
                        {isWrap ? '' : <p>Выйти</p>}
                    </ExtBtn>
                }
            </BigWrapper>
        </Wrapper>
    );
};

export default Navbar;

const WrapBtn = styled.div`
  cursor: pointer;
  display: flex;
  gap: 4px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  p {
    color: #D2D2D5;
  }
  @media screen and (max-width: 960px){
   display: none;
  }
`
const ExtBtn = styled.div`
  cursor: pointer;
  border-radius: 16px;
  display: flex;
  gap: 8px;
  background: #fff;
  padding: 8px 16.5px;
  align-items: center;
  margin: 0 8px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  &.wrap {
    margin:0 6px;
    padding: 8px;
  }
`
const LogoWrapper= styled.div`
  display: flex;
  @media screen and (max-width: 960px){
    display: none;
  }
`
const Wrapper = styled.div`
  height: 100vh;
  background: #070707;
  padding-top: 24px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  transition: all 0.4s ease;
  @media screen and (max-width: 960px){
    height: fit-content;
    width: 100vw;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 0;
    justify-content: start;
    padding: 12px 0 20px;
    z-index: 999;
  }
`
const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
  @media screen and (max-width: 960px){
    flex-direction: row;
    gap: 0;
    width: 100%;
  }
`
const MenuItem = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  font-size: 14px;
  padding: 0 16px;
  text-decoration: none;
  transition: all 0.4s ease;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  @media screen and (max-width: 960px){
    width: 25%;
    padding: 0;
    img {
      width: 36px;
    }
  }
  &.wrap {
    padding: 0 8px;
    img {
      width: 36px;
    }
  }
  p {
    color: #545456;
    font-weight: 400;

  }
  svg {
    fill: #545456;
    font-size: 36px;
  }

  &.selected {
    p {
      color: white;
    }
    img {
      fill: white;
    }
  }
`
const LogBtn = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  border-radius: 16px;
  display: flex;
  gap: 8px;
  background: #fff;
  padding: 8px 16.5px;
  align-items: center;
  margin: 0 8px;
  font-family: 'Roboto', sans-serif;
  color: rgb(7,7,7);
  font-size: 14px;
  &.wrap {
    margin:0 6px;
    padding: 8px;
  }
`
const BigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  @media screen and (max-width: 960px){ 
    display: none;
  }
`
const ExtBtnSmall = styled(ExtBtn)`
  display: none;
  background: none;
  text-decoration: none;
  flex-direction: column;
  gap: 6px;
  margin: 0;
  padding: 0;
  width: 25%;
  p {
    color: #545456;
  }
  img {
    width: 36px;
  }
  @media screen and (max-width: 960px){
    display: flex;
  }
`
const LogBtnSmall = styled(LogBtn)`
  display: none;
  background: none;
  text-decoration: none;
  flex-direction: column;
  gap: 6px;
  margin: 0;
  padding: 0; 
  width: 25%;
  &.selected {
    p {
      color: #fff;
    }
  }
  p {
    color: #545456;
  }
  img {
    width: 33px;
  }
  @media screen and (max-width: 960px){
    display: flex;
   
  }
 
`