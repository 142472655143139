export const category = [
    {
        "name": "Сортовой прокат",
        "submenu": [
            {
                "name": "Арматура",
                "submenu": [
                    {
                        "name": "А1",
                        "submenu": [
                            {
                                "name": "Мотки",
                                "keyId": 1,
                                "id": 3
                            },
                            {
                                "name": "Прутки",
                                "keyId": 2,
                                "id": 4
                            }
                        ],
                        "id": 2
                    },
                    {
                        "name": "А3",
                        "submenu": [
                            {
                                "name": "Мотки",
                                "keyId": 3,
                                "id": 6
                            },
                            {
                                "name": "Прутки",
                                "keyId": 4,
                                "id": 7
                            }
                        ],
                        "id": 5
                    },
                    {
                        "name": "Ат800",
                        "keyId": 5,
                        "id": 8
                    }
                ],
                "id": 1
            },
            {
                "name": "Балка двутавровая",
                "submenu": [
                    {
                        "name": "Балка двутавровая",
                        "keyId": 6,
                        "id": 10
                    },
                    {
                        "name": "Балка низколегированная",
                        "keyId": 7,
                        "id": 11
                    }
                ],
                "id": 9
            },
            {
                "name": "Катанка",
                "keyId": 8,
                "id": 12
            },
            {
                "name": "Квадрат горячекатанный",
                "keyId": 9,
                "id": 13
            },
            {
                "name": "Полоса горячекатанная",
                "keyId": 10,
                "id": 14
            },
            {
                "name": "Уголок",
                "submenu": [
                    {
                        "name": "Равнополочный",
                        "keyId": 11,
                        "id": 16
                    },
                    {
                        "name": "Неравнополочный",
                        "keyId": 12,
                        "id": 17
                    },
                    {
                        "name": "Равнополочный низколегированный",
                        "keyId": 13,
                        "id": 18
                    },
                    {
                        "name": "Неравнополочный низколегированный",
                        "keyId": 14,
                        "id": 19
                    },
                    {
                        "name": "Гнутый",
                        "keyId": 15,
                        "id": 20
                    }
                ],
                "id": 15
            },
            {
                "name": "Швеллер",
                "submenu": [
                    {
                        "name": "Швеллер",
                        "keyId": 16,
                        "id": 22
                    },
                    {
                        "name": "Швеллер гнутый",
                        "keyId": 17,
                        "id": 23
                    },
                    {
                        "name": "Швеллер низколегированный",
                        "keyId": 18,
                        "id": 24
                    }
                ],
                "id": 21
            }
        ],
        "id": 0
    },
    {
        "name": "Листовой прокат",
        "submenu": [
            {
                "name": "Лист горячекатаный",
                "submenu": [
                    {
                        "name": "Обычного качества",
                        "keyId": 19,
                        "id": 27
                    },
                    {
                        "name": "Конструкционный",
                        "keyId": 20,
                        "id": 28
                    },
                    {
                        "name": "Мостостроительная сталь",
                        "keyId": 21,
                        "id": 29
                    },
                    {
                        "name": "Низколегированный",
                        "keyId": 22,
                        "id": 30
                    },
                    {
                        "name": "Судостроительная сталь",
                        "keyId": 23,
                        "id": 31
                    }
                ],
                "id": 26
            },
            {
                "name": "Лист холоднокатаный",
                "keyId": 24,
                "id": 32
            },
            {
                "name": "Лист ПВЛ",
                "keyId": 25,
                "id": 33
            },
            {
                "name": "Лист рифленый",
                "keyId": 26,
                "id": 34
            }
        ],
        "id": 25
    },
    {
        "name": "Трубный прокат",
        "submenu": [
            {
                "name": "Труба водогазопроводная черная",
                "keyId": 27,
                "id": 36
            },
            {
                "name": "Труба горячедеформированная",
                "keyId": 28,
                "id": 37
            },
            {
                "name": "Труба холоднодеформированная",
                "keyId": 29,
                "id": 38
            },
            {
                "name": "Труба электросварная",
                "submenu": [
                    {
                        "name": "Квадратная",
                        "keyId": 30,
                        "id": 40
                    },
                    {
                        "name": "Круглая",
                        "keyId": 31,
                        "id": 41
                    },
                    {
                        "name": "Прямоугольная",
                        "keyId": 32,
                        "id": 42
                    }
                ],
                "id": 39
            }
        ],
        "id": 35
    },
    {
        "name": "Метизы и метсырье",
        "submenu": [
            {
                "name": "Проволока",
                "submenu": [
                    {
                        "name": "Вр-1",
                        "keyId": 33,
                        "id": 45
                    },
                    {
                        "name": "Пружинная",
                        "keyId": 34,
                        "id": 46
                    },
                    {
                        "name": "Нихромовая",
                        "keyId": 35,
                        "id": 47
                    },
                    {
                        "name": "Сварочная",
                        "keyId": 36,
                        "id": 48
                    },
                    {
                        "name": "Торговая",
                        "keyId": 37,
                        "id": 49
                    },
                    {
                        "name": "Проволока для холодной высадки",
                        "keyId": 38,
                        "id": 50
                    }
                ],
                "id": 44
            },
            {
                "name": "Лента упаковочная",
                "keyId": 39,
                "id": 51
            },
            {
                "name": "Сетка",
                "submenu": [
                    {
                        "name": "Плетеная",
                        "keyId": 40,
                        "id": 53
                    },
                    {
                        "name": "Сварная",
                        "keyId": 41,
                        "id": 54
                    },
                    {
                        "name": "Тканая",
                        "keyId": 42,
                        "id": 55
                    }
                ],
                "id": 52
            },
            {
                "name": "Электроды сварочные",
                "keyId": 43,
                "id": 56
            },
            {
                "name": "Канаты",
                "keyId": 44,
                "id": 57
            },
            {
                "name": "Калиброванная сталь",
                "submenu": [
                    {
                        "name": "Круг калиброванный",
                        "keyId": 45,
                        "id": 59
                    },
                    {
                        "name": "Шестигранник калиброванный",
                        "keyId": 46,
                        "id": 60
                    },
                    {
                        "name": "Профиль фасонный квадрат",
                        "keyId": 47,
                        "id": 61
                    },
                    {
                        "name": "Профиль фасонный полоса",
                        "keyId": 48,
                        "id": 62
                    }
                ],
                "id": 58
            }
        ],
        "id": 43
    },
    {
        "name": "Качественный прокат",
        "submenu": [
            {
                "name": "Инструментальная сталь",
                "submenu": [
                    {
                        "name": "Быстрорежущая",
                        "submenu": [
                            {
                                "name": "Круг",
                                "keyId": 49,
                                "id": 66
                            }
                        ],
                        "id": 65
                    },
                    {
                        "name": "Легированная",
                        "submenu": [
                            {
                                "name": "Круг",
                                "keyId": 50,
                                "id": 68
                            }
                        ],
                        "id": 67
                    },
                    {
                        "name": "Углеродистая",
                        "submenu": [
                            {
                                "name": "Круг",
                                "keyId": 51,
                                "id": 70
                            }
                        ],
                        "id": 69
                    }
                ],
                "id": 64
            },
            {
                "name": "Конструкционная сталь",
                "submenu": [
                    {
                        "name": "Конструкционная сталь",
                        "submenu": [
                            {
                                "name": "Круг",
                                "keyId": 52,
                                "id": 73
                            },
                            {
                                "name": "Поковка",
                                "keyId": 53,
                                "id": 74
                            },
                            {
                                "name": "Шестигранник",
                                "keyId": 54,
                                "id": 75
                            }
                        ],
                        "id": 72
                    },
                    {
                        "name": "Никельсодержащая",
                        "submenu": [
                            {
                                "name": "Квадрат",
                                "keyId": 55,
                                "id": 77
                            },
                            {
                                "name": "Круг",
                                "keyId": 56,
                                "id": 78
                            }
                        ],
                        "id": 76
                    }
                ],
                "id": 71
            },
            {
                "name": "Обыкновенного качества сталь",
                "submenu": [
                    {
                        "name": "Круг горячекатанный ",
                        "keyId": 57,
                        "id": 80
                    }
                ],
                "id": 79
            }
        ],
        "id": 63
    },
    {
        "name": "Цветной прокат",
        "submenu": [
            {
                "name": "Алюминий",
                "submenu": [
                    {
                        "name": "Круг",
                        "keyId": 58,
                        "id": 83
                    },
                    {
                        "name": "Лента",
                        "keyId": 59,
                        "id": 84
                    },
                    {
                        "name": "Лист",
                        "keyId": 60,
                        "id": 85
                    },
                    {
                        "name": "Плита",
                        "keyId": 61,
                        "id": 86
                    },
                    {
                        "name": "Тавр",
                        "keyId": 62,
                        "id": 87
                    },
                    {
                        "name": "Труба",
                        "keyId": 63,
                        "id": 88
                    },
                    {
                        "name": "Уголок",
                        "keyId": 64,
                        "id": 89
                    },
                    {
                        "name": "Чушка",
                        "keyId": 65,
                        "id": 90
                    },
                    {
                        "name": "Швеллер",
                        "keyId": 66,
                        "id": 91
                    },
                    {
                        "name": "Шина",
                        "keyId": 67,
                        "id": 92
                    }
                ],
                "id": 82
            },
            {
                "name": "Дюраль",
                "submenu": [
                    {
                        "name": "Квадрат",
                        "keyId": 68,
                        "id": 94
                    },
                    {
                        "name": "Круг",
                        "keyId": 69,
                        "id": 95
                    },
                    {
                        "name": "Лист",
                        "keyId": 70,
                        "id": 96
                    },
                    {
                        "name": "Плита",
                        "keyId": 71,
                        "id": 97
                    },
                    {
                        "name": "Труба",
                        "keyId": 72,
                        "id": 98
                    },
                    {
                        "name": "Уголок",
                        "keyId": 73,
                        "id": 99
                    },
                    {
                        "name": "Шестигранник",
                        "keyId": 74,
                        "id": 100
                    }
                ],
                "id": 93
            },
            {
                "name": "Медь",
                "submenu": [
                    {
                        "name": "Круг",
                        "keyId": 75,
                        "id": 102
                    },
                    {
                        "name": "Лента",
                        "keyId": 76,
                        "id": 103
                    },
                    {
                        "name": "Лист",
                        "keyId": 77,
                        "id": 104
                    },
                    {
                        "name": "Труба",
                        "keyId": 78,
                        "id": 105
                    },
                    {
                        "name": "Шина",
                        "keyId": 79,
                        "id": 106
                    }
                ],
                "id": 101
            },
            {
                "name": "Бронза",
                "submenu": [
                    {
                        "name": "Круг",
                        "keyId": 80,
                        "id": 108
                    }
                ],
                "id": 107
            },
            {
                "name": "Латунь",
                "submenu": [
                    {
                        "name": "Квадрат",
                        "keyId": 81,
                        "id": 110
                    },
                    {
                        "name": "Круг",
                        "keyId": 82,
                        "id": 111
                    },
                    {
                        "name": "Лента",
                        "keyId": 83,
                        "id": 112
                    },
                    {
                        "name": "Лист",
                        "keyId": 84,
                        "id": 113
                    },
                    {
                        "name": "Труба",
                        "keyId": 85,
                        "id": 114
                    },
                    {
                        "name": "Шестигранник",
                        "keyId": 86,
                        "id": 115
                    }
                ],
                "id": 109
            }
        ],
        "id": 81
    },
    {
        "name": "Нержавеющий прокат",
        "submenu": [
            {
                "name": "Лист",
                "submenu": [
                    {
                        "name": "Без никеля",
                        "keyId": 87,
                        "id": 118
                    },
                    {
                        "name": "Никельсодержащий",
                        "keyId": 88,
                        "id": 119
                    },
                    {
                        "name": "ПВЛ нержавеющий",
                        "keyId": 89,
                        "id": 120
                    }
                ],
                "id": 117
            },
            {
                "name": "Труба",
                "submenu": [
                    {
                        "name": "Электросварная",
                        "submenu": [
                            {
                                "name": "Квадратная",
                                "keyId": 90,
                                "id": 123
                            },
                            {
                                "name": "Круглая",
                                "keyId": 91,
                                "id": 124
                            },
                            {
                                "name": "Прямоугольная",
                                "keyId": 92,
                                "id": 125
                            }
                        ],
                        "id": 122
                    },
                    {
                        "name": "Бесшовная",
                        "keyId": 93,
                        "id": 126
                    }
                ],
                "id": 121
            },
            {
                "name": "Сортовой прокат",
                "submenu": [
                    {
                        "name": "Никельсодержащий",
                        "submenu": [
                            {
                                "name": "Квадрат",
                                "keyId": 94,
                                "id": 129
                            },
                            {
                                "name": "Круг",
                                "keyId": 95,
                                "id": 130
                            },
                            {
                                "name": "Полоса",
                                "keyId": 96,
                                "id": 131
                            },
                            {
                                "name": "Уголок",
                                "keyId": 97,
                                "id": 132
                            },
                            {
                                "name": "Швеллер",
                                "keyId": 98,
                                "id": 133
                            },
                            {
                                "name": "Шестигранник",
                                "keyId": 99,
                                "id": 134
                            }
                        ],
                        "id": 128
                    },
                    {
                        "name": "Без никеля",
                        "submenu": [
                            {
                                "name": "Круг",
                                "keyId": 100,
                                "id": 136
                            },
                            {
                                "name": "Шестигранник",
                                "keyId": 101,
                                "id": 137
                            }
                        ],
                        "id": 135
                    }
                ],
                "id": 127
            },
            {
                "name": "Проволока",
                "keyId": 102,
                "id": 138
            },
            {
                "name": "Детали трубопровода",
                "submenu": [
                    {
                        "name": "Отвод",
                        "keyId": 103,
                        "id": 140
                    },
                    {
                        "name": "Переход",
                        "keyId": 104,
                        "id": 141
                    },
                    {
                        "name": "Тройник",
                        "keyId": 105,
                        "id": 142
                    },
                    {
                        "name": "Фланец воротниковый",
                        "keyId": 106,
                        "id": 143
                    },
                    {
                        "name": "Фланец плоский",
                        "keyId": 107,
                        "id": 144
                    }
                ],
                "id": 139
            },
            {
                "name": "Крепеж",
                "submenu": [
                    {
                        "name": "Болт / Винт",
                        "keyId": 108,
                        "id": 146
                    },
                    {
                        "name": "Гайка",
                        "keyId": 109,
                        "id": 147
                    },
                    {
                        "name": "Трос",
                        "keyId": 110,
                        "id": 148
                    },
                    {
                        "name": "Шпилька",
                        "keyId": 111,
                        "id": 149
                    }
                ],
                "id": 145
            },
            {
                "name": "Комплектующие для лестничных ограждений",
                "submenu": [
                    {
                        "name": "Пристенное крепление",
                        "keyId": 112,
                        "id": 151
                    },
                    {
                        "name": "Наконечник соединения поручня со стойкой",
                        "keyId": 113,
                        "id": 152
                    },
                    {
                        "name": "Держатель ригеля",
                        "keyId": 114,
                        "id": 153
                    },
                    {
                        "name": "Декоративный низ стойки",
                        "keyId": 115,
                        "id": 154
                    },
                    {
                        "name": "Заглушка",
                        "keyId": 116,
                        "id": 155
                    },
                    {
                        "name": "Поворот и соединитель труб",
                        "keyId": 117,
                        "id": 156
                    },
                    {
                        "name": "Фланец настенный",
                        "keyId": 118,
                        "id": 157
                    },
                    {
                        "name": "Стеклодержатель",
                        "keyId": 119,
                        "id": 158
                    },
                    {
                        "name": "Крепление стойки",
                        "keyId": 120,
                        "id": 159
                    }
                ],
                "id": 150
            }
        ],
        "id": 116
    },
    {
        "name": "Оцинкованный прокат",
        "submenu": [
            {
                "name": "Круг",
                "keyId": 121,
                "id": 161
            },
            {
                "name": "Лист",
                "submenu": [
                    {
                        "name": "Сталь оцинкованная с лакокрасочным покрытием",
                        "keyId": 122,
                        "id": 163
                    },
                    {
                        "name": "Лист оцинкованный",
                        "keyId": 123,
                        "id": 164
                    },
                    {
                        "name": "Рулон оцинкованный",
                        "keyId": 124,
                        "id": 165
                    }
                ],
                "id": 162
            },
            {
                "name": "Полоса",
                "keyId": 125,
                "id": 166
            },
            {
                "name": "Сетка",
                "submenu": [
                    {
                        "name": "Сварная",
                        "keyId": 126,
                        "id": 168
                    },
                    {
                        "name": "Тканая",
                        "keyId": 127,
                        "id": 169
                    },
                    {
                        "name": "Плетеная",
                        "keyId": 128,
                        "id": 170
                    }
                ],
                "id": 167
            },
            {
                "name": "Труба",
                "submenu": [
                    {
                        "name": "Водогазопроводная",
                        "keyId": 129,
                        "id": 172
                    },
                    {
                        "name": "Квадратная",
                        "keyId": 130,
                        "id": 173
                    },
                    {
                        "name": "Круглая",
                        "keyId": 131,
                        "id": 174
                    },
                    {
                        "name": "Прямоугольная",
                        "keyId": 132,
                        "id": 175
                    }
                ],
                "id": 171
            },
            {
                "name": "Уголок",
                "keyId": 133,
                "id": 176
            }
        ],
        "id": 160
    },
    {
        "name": "Крепеж",
        "submenu": [
            {
                "name": "Анкеры",
                "submenu": [
                    {
                        "name": "Без гайки",
                        "keyId": 134,
                        "id": 179
                    },
                    {
                        "name": "С гайкой",
                        "keyId": 135,
                        "id": 180
                    },
                    {
                        "name": "ВСР (с высокой степеью расклинивания)",
                        "keyId": 136,
                        "id": 181
                    },
                    {
                        "name": "Анкер-клин",
                        "keyId": 137,
                        "id": 182
                    },
                    {
                        "name": "С Г-образным крюком",
                        "keyId": 138,
                        "id": 183
                    },
                    {
                        "name": "С кольцом",
                        "keyId": 139,
                        "id": 184
                    },
                    {
                        "name": "С полукольцом",
                        "keyId": 140,
                        "id": 185
                    },
                    {
                        "name": "Клиновой",
                        "keyId": 141,
                        "id": 186
                    },
                    {
                        "name": "Латунный (цанга)",
                        "keyId": 142,
                        "id": 187
                    },
                    {
                        "name": "Дюбель-гвоздь металлический",
                        "keyId": 143,
                        "id": 188
                    },
                    {
                        "name": "Рамный",
                        "keyId": 144,
                        "id": 189
                    },
                    {
                        "name": "Забивной",
                        "keyId": 145,
                        "id": 190
                    }
                ],
                "id": 178
            },
            {
                "name": "Болты",
                "submenu": [
                    {
                        "name": "Мебельный DIN 603",
                        "keyId": 146,
                        "id": 192
                    },
                    {
                        "name": "Болт Б/П, к.п. 8.8, полная резьба",
                        "keyId": 147,
                        "id": 193
                    },
                    {
                        "name": "Болт без покрытия, неполная резьба",
                        "keyId": 148,
                        "id": 194
                    },
                    {
                        "name": "ГОСТ Р 52644-2004",
                        "keyId": 149,
                        "id": 195
                    },
                    {
                        "name": "Болт без покрытия, полная резьба",
                        "keyId": 150,
                        "id": 196
                    },
                    {
                        "name": "Болт без покрытия, к.п.8.8",
                        "keyId": 151,
                        "id": 197
                    },
                    {
                        "name": "Болты оцинк, к.п.8.8, полная резьба",
                        "keyId": 152,
                        "id": 198
                    },
                    {
                        "name": "Болт оцинк, неполная резьба",
                        "keyId": 153,
                        "id": 199
                    },
                    {
                        "name": "ГОСТ 7805-70 оцинк, к.п. 8.8",
                        "keyId": 154,
                        "id": 200
                    },
                    {
                        "name": "Болт с полной резьбой, оцинк",
                        "keyId": 155,
                        "id": 201
                    },
                    {
                        "name": "Мебельный ГОСТ 7801-81 с усом",
                        "keyId": 156,
                        "id": 202
                    },
                    {
                        "name": "Болты оцинк, к.п.8.8",
                        "keyId": 157,
                        "id": 203
                    },
                    {
                        "name": "Болт лемешный ГОСТ7786-81",
                        "keyId": 158,
                        "id": 204
                    },
                    {
                        "name": "Болты с умен.головой 8.8 цинк",
                        "keyId": 159,
                        "id": 205
                    },
                    {
                        "name": "Болты оцинк, к.п.10.9",
                        "keyId": 160,
                        "id": 206
                    },
                    {
                        "name": "Болт без покрытия, к.п.10.9",
                        "keyId": 161,
                        "id": 207
                    },
                    {
                        "name": "Болты оцинк, к.п.10.9, полная резьба",
                        "keyId": 162,
                        "id": 208
                    }
                ],
                "id": 191
            },
            {
                "name": "Винты",
                "submenu": [
                    {
                        "name": "С полусфер. головкой",
                        "keyId": 163,
                        "id": 210
                    },
                    {
                        "name": "С  цил. гол. и внутр. шестигр.",
                        "keyId": 164,
                        "id": 211
                    },
                    {
                        "name": "С потай головкой и полной резьбой",
                        "keyId": 165,
                        "id": 212
                    },
                    {
                        "name": "С пресс-шайбой",
                        "keyId": 166,
                        "id": 213
                    }
                ],
                "id": 209
            },
            {
                "name": "Гайки",
                "submenu": [
                    {
                        "name": "Гайки без покрытия",
                        "keyId": 168,
                        "id": 215
                    },
                    {
                        "name": "Гайки оцинкованные",
                        "keyId": 169,
                        "id": 216
                    },
                    {
                        "name": "DIN 1587 колпачковая",
                        "keyId": 170,
                        "id": 217
                    },
                    {
                        "name": "DIN 1624 мебельная врезная",
                        "keyId": 171,
                        "id": 218
                    },
                    {
                        "name": "DIN 315  барашковая",
                        "keyId": 172,
                        "id": 219
                    },
                    {
                        "name": "DIN 562 квадратная",
                        "keyId": 173,
                        "id": 220
                    },
                    {
                        "name": "DIN 6334 соединительная",
                        "keyId": 174,
                        "id": 221
                    },
                    {
                        "name": "DIN 6923 шестигр. с фланцем",
                        "keyId": 175,
                        "id": 222
                    },
                    {
                        "name": "DIN 934 оцинк.",
                        "keyId": 176,
                        "id": 223
                    },
                    {
                        "name": "DIN 985 со стопорным кольцом",
                        "keyId": 177,
                        "id": 224
                    },
                    {
                        "name": "Гайки высокопрочные, б/п, к.п. 8",
                        "keyId": 178,
                        "id": 225
                    },
                    {
                        "name": "Гайки высокопрочные, оцинк. к.п. 8",
                        "keyId": 179,
                        "id": 226
                    },
                    {
                        "name": "Гайки высокопрочные, б/п, к.п. 10",
                        "keyId": 180,
                        "id": 227
                    },
                    {
                        "name": "Гайки высокопрочные, оцинк, к.п.10",
                        "keyId": 181,
                        "id": 228
                    },
                    {
                        "name": "DIN 982 самоконтрящаяся высокая",
                        "keyId": 182,
                        "id": 229
                    }
                ],
                "id": 214
            },
            {
                "name": "Гвозди",
                "submenu": [
                    {
                        "name": "Гвозди",
                        "keyId": 183,
                        "id": 231
                    },
                    {
                        "name": "Строительный без покрытия",
                        "keyId": 184,
                        "id": 232
                    },
                    {
                        "name": "Винтовой",
                        "keyId": 185,
                        "id": 233
                    },
                    {
                        "name": "Ершеный",
                        "keyId": 186,
                        "id": 234
                    },
                    {
                        "name": "Кровельный",
                        "keyId": 187,
                        "id": 235
                    },
                    {
                        "name": "Строительный оцинкованный",
                        "keyId": 188,
                        "id": 236
                    },
                    {
                        "name": "Толевый",
                        "keyId": 189,
                        "id": 237
                    },
                    {
                        "name": "Финишный",
                        "keyId": 190,
                        "id": 238
                    },
                    {
                        "name": "Монтажный",
                        "keyId": 191,
                        "id": 239
                    }
                ],
                "id": 230
            },
            {
                "name": "Дюбели",
                "submenu": [
                    {
                        "name": "Для газобетона",
                        "keyId": 192,
                        "id": 241
                    },
                    {
                        "name": "Дюбель-гвоздь",
                        "keyId": 193,
                        "id": 242
                    },
                    {
                        "name": "Для пустотелых конструкций",
                        "keyId": 194,
                        "id": 243
                    },
                    {
                        "name": "Общего назначения",
                        "keyId": 195,
                        "id": 244
                    },
                    {
                        "name": "Универсальный",
                        "keyId": 196,
                        "id": 245
                    }
                ],
                "id": 240
            },
            {
                "name": "Заклепки",
                "submenu": [
                    {
                        "name": "Комбинированная вытяжная",
                        "keyId": 197,
                        "id": 247
                    },
                    {
                        "name": "Окрашенная",
                        "keyId": 198,
                        "id": 248
                    },
                    {
                        "name": "Вытяжная",
                        "keyId": 199,
                        "id": 249
                    }
                ],
                "id": 246
            },
            {
                "name": "Саморезы",
                "submenu": [
                    {
                        "name": "Саморезы",
                        "keyId": 200,
                        "id": 251
                    },
                    {
                        "name": "Саморез унив. с пот. гол.(Жёлт Pozi)",
                        "keyId": 201,
                        "id": 252
                    },
                    {
                        "name": "Для крепления ГВЛ",
                        "keyId": 202,
                        "id": 253
                    },
                    {
                        "name": "Для лаг и реек",
                        "keyId": 203,
                        "id": 254
                    },
                    {
                        "name": "Для крепления ГКЛ (кг)",
                        "keyId": 204,
                        "id": 255
                    },
                    {
                        "name": "Для крепления мет.пофилей",
                        "keyId": 205,
                        "id": 256
                    },
                    {
                        "name": "Для кровельных материалов",
                        "keyId": 206,
                        "id": 257
                    },
                    {
                        "name": "Саморез с П/Ш нак. сверло (кг)",
                        "keyId": 207,
                        "id": 258
                    },
                    {
                        "name": "Винт-конфирмат",
                        "keyId": 208,
                        "id": 259
                    },
                    {
                        "name": "Для оконных профилей",
                        "keyId": 209,
                        "id": 260
                    },
                    {
                        "name": "Для сандвич-панелей",
                        "keyId": 210,
                        "id": 261
                    },
                    {
                        "name": "По металлу",
                        "keyId": 211,
                        "id": 262
                    },
                    {
                        "name": "Саморез унив. с пот. гол.(Белый Pozi)",
                        "keyId": 212,
                        "id": 263
                    },
                    {
                        "name": "Саморез с П/Ш нак. сверло (шт)",
                        "keyId": 213,
                        "id": 264
                    },
                    {
                        "name": "Саморез с П/Ш нак. острый (кг)",
                        "keyId": 214,
                        "id": 265
                    },
                    {
                        "name": "Саморез с П/Ш нак. острый (шт)",
                        "keyId": 215,
                        "id": 266
                    },
                    {
                        "name": "Для кровельных материалов RAL",
                        "keyId": 216,
                        "id": 267
                    },
                    {
                        "name": "Для крепления ГКЛ (желтый цинк)",
                        "keyId": 217,
                        "id": 268
                    },
                    {
                        "name": "Для крепления ГКЛ (шт)",
                        "keyId": 218,
                        "id": 269
                    },
                    {
                        "name": "П/Ш окраш. по RAL с нак. сверло",
                        "keyId": 219,
                        "id": 270
                    },
                    {
                        "name": "П/Ш окраш. по RAL с нак. острый",
                        "keyId": 220,
                        "id": 271
                    },
                    {
                        "name": "Саморез конструкционный по дереву",
                        "keyId": 221,
                        "id": 272
                    }
                ],
                "id": 250
            },
            {
                "name": "Шайбы",
                "submenu": [
                    {
                        "name": "Шайба гроверная",
                        "keyId": 222,
                        "id": 274
                    },
                    {
                        "name": "Шайба плоская оцинк",
                        "keyId": 223,
                        "id": 275
                    },
                    {
                        "name": "Шайба увеличенная",
                        "keyId": 224,
                        "id": 276
                    },
                    {
                        "name": "Шайба ГОСТ 52646-2006",
                        "keyId": 225,
                        "id": 277
                    },
                    {
                        "name": "Шайба плоская без покрытия",
                        "keyId": 226,
                        "id": 278
                    }
                ],
                "id": 273
            },
            {
                "name": "Шпильки",
                "submenu": [
                    {
                        "name": "Резьбовая ОЦ",
                        "keyId": 227,
                        "id": 280
                    },
                    {
                        "name": "Шуруп-шпилька",
                        "keyId": 228,
                        "id": 281
                    },
                    {
                        "name": "Шпилька  к.п. 8.8 ОЦ",
                        "keyId": 229,
                        "id": 282
                    },
                    {
                        "name": "Шпилька  к.п. 8.8 БП",
                        "keyId": 230,
                        "id": 283
                    }
                ],
                "id": 279
            },
            {
                "name": "Шурупы",
                "submenu": [
                    {
                        "name": "По бетону",
                        "keyId": 231,
                        "id": 285
                    },
                    {
                        "name": "С кольцом/полукольцом",
                        "keyId": 232,
                        "id": 286
                    }
                ],
                "id": 284
            },
            {
                "name": "Такелажный крепеж",
                "submenu": [
                    {
                        "name": "Зажим для канатов",
                        "keyId": 233,
                        "id": 288
                    },
                    {
                        "name": "Блок",
                        "keyId": 234,
                        "id": 289
                    },
                    {
                        "name": "Вертлюг",
                        "keyId": 235,
                        "id": 290
                    },
                    {
                        "name": "Коуш",
                        "keyId": 236,
                        "id": 291
                    },
                    {
                        "name": "Карабин",
                        "keyId": 237,
                        "id": 292
                    },
                    {
                        "name": "Крюк",
                        "keyId": 238,
                        "id": 293
                    },
                    {
                        "name": "Рым-болт",
                        "keyId": 239,
                        "id": 294
                    },
                    {
                        "name": "Рым-гайка",
                        "keyId": 240,
                        "id": 295
                    },
                    {
                        "name": "Скоба",
                        "keyId": 241,
                        "id": 296
                    },
                    {
                        "name": "Соединитель цепей",
                        "keyId": 242,
                        "id": 297
                    },
                    {
                        "name": "Талреп",
                        "keyId": 243,
                        "id": 298
                    },
                    {
                        "name": "Цепь",
                        "keyId": 244,
                        "id": 299
                    },
                    {
                        "name": "Трос",
                        "keyId": 245,
                        "id": 300
                    }
                ],
                "id": 287
            },
            {
                "name": "Перфорированный крепеж",
                "submenu": [
                    {
                        "name": "Кронштейн стальной окрашенный",
                        "keyId": 246,
                        "id": 302
                    },
                    {
                        "name": "Перфорированная лента",
                        "keyId": 247,
                        "id": 303
                    },
                    {
                        "name": "Пластины крепёжные",
                        "keyId": 248,
                        "id": 304
                    },
                    {
                        "name": "Уголки крепёжные",
                        "keyId": 249,
                        "id": 305
                    },
                    {
                        "name": "Крепление панелей",
                        "keyId": 250,
                        "id": 306
                    },
                    {
                        "name": "Крепление балок",
                        "keyId": 251,
                        "id": 307
                    }
                ],
                "id": 301
            }
        ],
        "id": 177
    },
    {
        "name": "Профнастил",
        "submenu": [
            {
                "name": "Оцинкованный",
                "keyId": 252,
                "id": 309
            },
            {
                "name": "Окрашенный",
                "keyId": 253,
                "id": 310
            }
        ],
        "id": 308
    }
];
export const ids = {
    '0' :  [0, 24],
    '25' : [25, 34],
    '35' : [35,42],
    '43' : [43,62],
    '63' : [63,79],
    '80' : [80, 115],
    '116' : [116, 159],
    '160' : [160,176],
    '177' : [177,307],
    '308' : [308, 310],
}

export const path = {
    "Сортовой прокат>Арматура>А1>Мотки" : "Сортовой прокат>Арматура>Класс А1>Мотки Класс А1",
    "Сортовой прокат>Арматура>А1>Прутки" : "Сортовой прокат>Арматура>Класс А1>Прутки Класс А1",
    "Сортовой прокат>Арматура>А3>Мотки" : "Сортовой прокат>Арматура>Класс А3>Мотки Класс А3",
    "Сортовой прокат>Арматура>А3>Прутки" : "Сортовой прокат>Арматура>Класс А3>Прутки  Класс А3",
    "Сортовой прокат>Арматура>Ат800" : "Сортовой прокат>Арматура>Класс Ат800>Прутки  Класс Ат800",
    "Сортовой прокат>Балка двутавровая>Балка двутавровая" : "Сортовой прокат>Балки двутавровые>Балки двутавровые",
    "Сортовой прокат>Балка двутавровая>Балка низколегированная" : "Сортовой прокат>Балки двутавровые>Балки  низколегированные",
    "Сортовой прокат>Катанка" : "Сортовой прокат>Катанка",
    "Сортовой прокат>Квадрат горячекатанный" : "Сортовой прокат>Квадрат г/к",
    "Сортовой прокат>Полоса горячекатанная" : "Сортовой прокат>Полоса г/к",
    "Сортовой прокат>Уголок>Равнополочный" : "Сортовой прокат>Уголок>Равнополочный",
    "Сортовой прокат>Уголок>Неравнополочный" : "Сортовой прокат>Уголок>Неравнополочный",
    "Сортовой прокат>Уголок>Равнополочный низколегированный" : "Сортовой прокат>Уголок>Равнополочный низколегированный",
    "Сортовой прокат>Уголок>Неравнополочный низколегированный" : "Сортовой прокат>Уголок>Неравнополочный низколегированный",
    "Сортовой прокат>Уголок>Гнутый" : "Сортовой прокат>Уголок>Гнутый",
    "Сортовой прокат>Швеллер>Швеллер" : "Сортовой прокат>Швеллер>Швеллер",
    "Сортовой прокат>Швеллер>Швеллер гнутый" : "Сортовой прокат>Швеллер>Швеллер гнутый",
    "Сортовой прокат>Швеллер>Швеллер низколегированный" : "Сортовой прокат>Швеллер>Швеллер низколегированный",
    "Листовой прокат>Лист горячекатаный>Обычного качества" : "Листовой прокат>Лист Горячекатаный>Обычного качества",
    "Листовой прокат>Лист горячекатаный>Конструкционный" : "Листовой прокат>Лист Горячекатаный>Конструкционный",
    "Листовой прокат>Лист горячекатаный>Мостостроительная сталь" : "Листовой прокат>Лист Горячекатаный>Мостостроительная сталь",
    "Листовой прокат>Лист горячекатаный>Низколегированный" : "Листовой прокат>Лист Горячекатаный>Низколегированный",
    "Листовой прокат>Лист горячекатаный>Судостроительная сталь" : "Листовой прокат>Лист Горячекатаный>Судостроительная сталь",
    "Листовой прокат>Лист холоднокатаный" : "Листовой прокат>Лист Холоднокатаный>Листовая сталь х/к",
    "Листовой прокат>Лист ПВЛ" : "Листовой прокат>Лист Просечно-вытяжной",
    "Листовой прокат>Лист рифленый" : "Листовой прокат>Лист Рифленый",
    "Трубный прокат>Труба водогазопроводная черная" : "Трубы>Трубы водогазопроводные>Черные",
    "Трубный прокат>Труба горячедеформированная" : "Трубы>Трубы горячедеформированные",
    "Трубный прокат>Труба холоднодеформированная" : "Трубы>Трубы холоднодеформированные",
    "Трубный прокат>Труба электросварная>Квадратная" : "Трубы>Трубы электросварные>Квадрат",
    "Трубный прокат>Труба электросварная>Круглая" : "Трубы>Трубы электросварные>Круглые",
    "Трубный прокат>Труба электросварная>Прямоугольная" : "Трубы>Трубы электросварные>Прямоуг",
    "Метизы и метсырье>Проволока>Вр-1" : "Метизы и метсырье>Проволока>Проволока Вр-1",
    "Метизы и метсырье>Проволока>Пружинная" : "Метизы и метсырье>Проволока>Кач пружинная",
    "Метизы и метсырье>Проволока>Нихромовая" : "Метизы и метсырье>Проволока>Нихромовая",
    "Метизы и метсырье>Проволока>Сварочная" : "Метизы и метсырье>Проволока>О/к сварочная",
    "Метизы и метсырье>Проволока>Торговая" : "Метизы и метсырье>Проволока>О/к торговая",
    "Метизы и метсырье>Проволока>Проволока для холодной высадки" : "Метизы и метсырье>Проволока>Кач х/в",
    "Метизы и метсырье>Лента упаковочная" : "Метизы и метсырье>Лента>Упаковочная",
    "Метизы и метсырье>Сетка>Плетеная" : "Метизы и метсырье>Сетка>Плетеная",
    "Метизы и метсырье>Сетка>Сварная" : "Метизы и метсырье>Сетка>Стальная сварная",
    "Метизы и метсырье>Сетка>Тканая" : "Метизы и метсырье>Сетка>Стальная тканая",
    "Метизы и метсырье>Электроды сварочные" : "Метизы и метсырье>Электроды>Сварочные",
    "Метизы и метсырье>Канаты" : "Метизы и метсырье>Канаты",
    "Метизы и метсырье>Калиброванная сталь>Круг калиброванный" : "Метизы и метсырье>Калибровка>Круг калиброванный",
    "Метизы и метсырье>Калиброванная сталь>Шестигранник калиброванный" : "Метизы и метсырье>Калибровка>Шестигранник калиброванный",
    "Метизы и метсырье>Калиброванная сталь>Профиль фасонный квадрат" : "Метизы и метсырье>Калибровка>Профиль фасонный квадрат",
    "Метизы и метсырье>Калиброванная сталь>Профиль фасонный полоса" : "Метизы и метсырье>Калибровка>Профиль фасонный полоса",
    "Качественный прокат>Инструментальная сталь>Быстрорежущая>Круг" : "Качественный прокат>Инструментальная сталь>Быстрорежущая>Круг",
    "Качественный прокат>Инструментальная сталь>Легированная>Круг" : "Качественный прокат>Инструментальная сталь>Легированная>Круг",
    "Качественный прокат>Инструментальная сталь>Углеродистая>Круг" : "Качественный прокат>Инструментальная сталь>Углеродистая>Круг",
    "Качественный прокат>Конструкционная сталь>Конструкционная сталь>Круг" : "Качественный прокат>Конструкционная сталь>Конструкционная сталь>Круг",
    "Качественный прокат>Конструкционная сталь>Конструкционная сталь>Поковка" : "Качественный прокат>Конструкционная сталь>Конструкционная сталь>Поковка",
    "Качественный прокат>Конструкционная сталь>Конструкционная сталь>Шестигранник" : "Качественный прокат>Конструкционная сталь>Конструкционная сталь>Шестигранник",
    "Качественный прокат>Конструкционная сталь>Никельсодержащая>Квадрат" : "Качественный прокат>Конструкционная сталь>С никелем>Квадрат",
    "Качественный прокат>Конструкционная сталь>Никельсодержащая>Круг" : "Качественный прокат>Конструкционная сталь>С никелем>Круг",
    "Качественный прокат>Обыкновенного качества сталь>Круг горячекатанный " : "Качественный прокат>Обыкновенного качества сталь>Круг г/к",
    "Цветной прокат>Алюминий>Круг" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Круг",
    "Цветной прокат>Алюминий>Лента" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Лента",
    "Цветной прокат>Алюминий>Лист" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Лист",
    "Цветной прокат>Алюминий>Плита" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Плита",
    "Цветной прокат>Алюминий>Тавр" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Тавр",
    "Цветной прокат>Алюминий>Труба" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Труба",
    "Цветной прокат>Алюминий>Уголок" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Уголок",
    "Цветной прокат>Алюминий>Чушка" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Чушка",
    "Цветной прокат>Алюминий>Швеллер" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Швеллер",
    "Цветной прокат>Алюминий>Шина" : "Цветной прокат>Алюминий и алюминиевые сплавы>Алюминий>Шина",
    "Цветной прокат>Дюраль>Квадрат" : "Цветной прокат>Алюминий и алюминиевые сплавы>Дюраль>Квадрат",
    "Цветной прокат>Дюраль>Круг" : "Цветной прокат>Алюминий и алюминиевые сплавы>Дюраль>Круг",
    "Цветной прокат>Дюраль>Лист" : "Цветной прокат>Алюминий и алюминиевые сплавы>Дюраль>Лист",
    "Цветной прокат>Дюраль>Плита" : "Цветной прокат>Алюминий и алюминиевые сплавы>Дюраль>Плита",
    "Цветной прокат>Дюраль>Труба" : "Цветной прокат>Алюминий и алюминиевые сплавы>Дюраль>Труба",
    "Цветной прокат>Дюраль>Уголок" : "Цветной прокат>Алюминий и алюминиевые сплавы>Дюраль>Уголок",
    "Цветной прокат>Дюраль>Шестигранник" : "Цветной прокат>Алюминий и алюминиевые сплавы>Дюраль>Шестигранник",
    "Цветной прокат>Медь>Круг" : "Цветной прокат>Медь и медные сплавы>Медь>Круг",
    "Цветной прокат>Медь>Лента" : "Цветной прокат>Медь и медные сплавы>Медь>Лента",
    "Цветной прокат>Медь>Лист" : "Цветной прокат>Медь и медные сплавы>Медь>Лист",
    "Цветной прокат>Медь>Труба" : "Цветной прокат>Медь и медные сплавы>Медь>Труба",
    "Цветной прокат>Медь>Шина" : "Цветной прокат>Медь и медные сплавы>Медь>Шина",
    "Цветной прокат>Бронза>Круг": "Цветной прокат>Медь и медные сплавы>Бронза>Круг" ,
    "Цветной прокат>Латунь>Квадрат" : "Цветной прокат>Медь и медные сплавы>Латунь>Квадрат",
    "Цветной прокат>Латунь>Круг" : "Цветной прокат>Медь и медные сплавы>Латунь>Круг",
    "Цветной прокат>Латунь>Лента" : "Цветной прокат>Медь и медные сплавы>Латунь>Лента",
    "Цветной прокат>Латунь>Лист" : "Цветной прокат>Медь и медные сплавы>Латунь>Лист",
    "Цветной прокат>Латунь>Труба" : "Цветной прокат>Медь и медные сплавы>Латунь>Труба",
    "Цветной прокат>Латунь>Шестигранник" : "Цветной прокат>Медь и медные сплавы>Латунь>Шестигранник",
    "Нержавеющий прокат>Лист>Без никеля" : "Нержавеющий прокат>Нержавеющий лист>Без никеля",
    "Нержавеющий прокат>Лист>Никельсодержащий" : "Нержавеющий прокат>Нержавеющий лист>Никельсодержащий",
    "Нержавеющий прокат>Лист>ПВЛ нержавеющий" : "Нержавеющий прокат>Нержавеющий лист>ПВЛ нержавеющий",
    "Нержавеющий прокат>Труба>Электросварная>Квадратная" : "Нержавеющий прокат>Нержавеющие трубы>Электросварные>Квадрат",
    "Нержавеющий прокат>Труба>Электросварная>Круглая" : "Нержавеющий прокат>Нержавеющие трубы>Электросварные>Круглые",
    "Нержавеющий прокат>Труба>Электросварная>Прямоугольная" : "Нержавеющий прокат>Нержавеющие трубы>Электросварные>Прямоуг",
    "Нержавеющий прокат>Труба>Бесшовная" : "Нержавеющий прокат>Нержавеющие трубы>Бесшовные",
    "Нержавеющий прокат>Сортовой прокат>Никельсодержащий>Квадрат" : "Нержавеющий прокат>Нержавеющий сорт>Никельсодержащий>Квадрат",
    "Нержавеющий прокат>Сортовой прокат>Никельсодержащий>Круг" : "Нержавеющий прокат>Нержавеющий сорт>Никельсодержащий>Круг",
    "Нержавеющий прокат>Сортовой прокат>Никельсодержащий>Полоса" : "Нержавеющий прокат>Нержавеющий сорт>Никельсодержащий>Полоса",
    "Нержавеющий прокат>Сортовой прокат>Никельсодержащий>Уголок" : "Нержавеющий прокат>Нержавеющий сорт>Никельсодержащий>Уголок нержавеющий",
    "Нержавеющий прокат>Сортовой прокат>Никельсодержащий>Швеллер" : "Нержавеющий прокат>Нержавеющий сорт>Никельсодержащий>Швеллер",
    "Нержавеющий прокат>Сортовой прокат>Никельсодержащий>Шестигранник" : "Нержавеющий прокат>Нержавеющий сорт>Никельсодержащий>Шестигранник",
    "Нержавеющий прокат>Сортовой прокат>Без никеля>Круг" : "Нержавеющий прокат>Нержавеющий сорт>Без никеля>Круг",
    "Нержавеющий прокат>Сортовой прокат>Без никеля>Шестигранник": "Нержавеющий прокат>Нержавеющий сорт>Без никеля>Шестигранник",
    "Нержавеющий прокат>Проволока": "Нержавеющий прокат>Метизная продукция>Проволока",
    "Нержавеющий прокат>Детали трубопровода>Отвод" : "Нержавеющий прокат>Детали трубопровода>Отводы нержавеющие",
    "Нержавеющий прокат>Детали трубопровода>Переход" : "Нержавеющий прокат>Детали трубопровода>Переходы нержавеющие",
    "Нержавеющий прокат>Детали трубопровода>Тройник" : "Нержавеющий прокат>Детали трубопровода>Тройники нержавеющие",
    "Нержавеющий прокат>Детали трубопровода>Фланец воротниковый" : "Нержавеющий прокат>Детали трубопровода>Фланцы нержавеющие воротниковые",
    "Нержавеющий прокат>Детали трубопровода>Фланец плоский" : "Нержавеющий прокат>Детали трубопровода>Фланцы нержавеющие плоские",
    "Нержавеющий прокат>Крепеж>Болт / Винт" : "Нержавеющий прокат>Крепеж из нержавеющей стали>Болты/Винты",
    "Нержавеющий прокат>Крепеж>Гайка" : "Нержавеющий прокат>Крепеж из нержавеющей стали>Гайки",
    "Нержавеющий прокат>Крепеж>Трос" : "Нержавеющий прокат>Крепеж из нержавеющей стали>Трос",
    "Нержавеющий прокат>Крепеж>Шпилька" : "Нержавеющий прокат>Крепеж из нержавеющей стали>Шпильки",
    "Нержавеющий прокат>Комплектующие для лестничных ограждений>Пристенное крепление" : "Нержавеющий прокат>Комплектующие для лестничных ограждений>Пристенные крепления",
    "Нержавеющий прокат>Комплектующие для лестничных ограждений>Наконечник соединения поручня со стойкой" : "Нержавеющий прокат>Комплектующие для лестничных ограждений>Наконечники, соеденения поручня со стойкой",
    "Нержавеющий прокат>Комплектующие для лестничных ограждений>Держатель ригеля" : "Нержавеющий прокат>Комплектующие для лестничных ограждений>Держатель ригеля",
    "Нержавеющий прокат>Комплектующие для лестничных ограждений>Декоративный низ стойки" : "Нержавеющий прокат>Комплектующие для лестничных ограждений>Декоративный низ стойки",
    "Нержавеющий прокат>Комплектующие для лестничных ограждений>Заглушка" : "Нержавеющий прокат>Комплектующие для лестничных ограждений>Заглушки",
    "Нержавеющий прокат>Комплектующие для лестничных ограждений>Поворот и соединитель труб" : "Нержавеющий прокат>Комплектующие для лестничных ограждений>Повороты и соединители труб",
    "Нержавеющий прокат>Комплектующие для лестничных ограждений>Фланец настенный" : "Нержавеющий прокат>Комплектующие для лестничных ограждений>Фланцы настенные",
    "Нержавеющий прокат>Комплектующие для лестничных ограждений>Стеклодержатель" : "Нержавеющий прокат>Комплектующие для лестничных ограждений>Стеклодержатели",
    "Нержавеющий прокат>Комплектующие для лестничных ограждений>Крепление стойки" : "Нержавеющий прокат>Комплектующие для лестничных ограждений>Крепления стоек",
    "Оцинкованный прокат>Круг" : "Оцинкованный прокат>Круг Оцинкованный",
    "Оцинкованный прокат>Лист>Сталь оцинкованная с лакокрасочным покрытием" : "Оцинкованный прокат>Лист Оцинкованный>Сталь оцинк с лакокрас покрытием",
    "Оцинкованный прокат>Лист>Лист оцинкованный" : "Оцинкованный прокат>Лист Оцинкованный>Оцинкованный",
    "Оцинкованный прокат>Лист>Рулон оцинкованный" : "Оцинкованный прокат>Лист Оцинкованный>Рулоны оцинкованные",
    "Оцинкованный прокат>Полоса" :"Оцинкованный прокат>Полоса Оцинкованная" ,
    "Оцинкованный прокат>Сетка>Сварная" : "Оцинкованный прокат>Сетка Оцинкованная>Стальная Сварная",
    "Оцинкованный прокат>Сетка>Тканая" : "Оцинкованный прокат>Сетка Оцинкованная>Стальная тканая",
    "Оцинкованный прокат>Сетка>Плетеная" : "Оцинкованный прокат>Сетка Оцинкованная>Плетеная",
    "Оцинкованный прокат>Труба>Водогазопроводная" : "Оцинкованный прокат>Трубы Оцинкованные>ВГП",
    "Оцинкованный прокат>Труба>Квадратная" : "Оцинкованный прокат>Трубы Оцинкованные>Квадрат",
    "Оцинкованный прокат>Труба>Круглая" : "Оцинкованный прокат>Трубы Оцинкованные>Круглые",
    "Оцинкованный прокат>Труба>Прямоугольная" : "Оцинкованный прокат>Трубы Оцинкованные>Прямоуг",
    "Оцинкованный прокат>Уголок" : "Оцинкованный прокат>Уголок Оцинкованный",
    "Крепеж>Анкеры>Без гайки": "Крепеж>Анкеры>Без гайки",
    "Крепеж>Анкеры>С гайкой" : "Крепеж>Анкеры>С гайкой",
    "Крепеж>Анкеры>ВСР (с высокой степеью расклинивания)" : "Крепеж>Анкеры>ВСР (с высокой степеью расклинивания)",
    "Крепеж>Анкеры>Анкер-клин" : "Крепеж>Анкеры>Анкер-клин",
    "Крепеж>Анкеры>С Г-образным крюком" : "Крепеж>Анкеры>С Г-образным крюком",
    "Крепеж>Анкеры>С кольцом" : "Крепеж>Анкеры>С кольцом",
    "Крепеж>Анкеры>С полукольцом" : "Крепеж>Анкеры>С полукольцом",
    "Крепеж>Анкеры>Клиновой" : "Крепеж>Анкеры>Клиновой",
    "Крепеж>Анкеры>Латунный (цанга)" : "Крепеж>Анкеры>Латунный (цанга)",
    "Крепеж>Анкеры>Дюбель-гвоздь металлический" : "Крепеж>Анкеры>Дюбель-гвоздь металлический",
    "Крепеж>Анкеры>Рамный" : "Крепеж>Анкеры>Рамный",
    "Крепеж>Анкеры>Забивной" : "Крепеж>Анкеры>Забивной",
    "Крепеж>Болты>Мебельный DIN 603" : "Крепеж>Болты>Мебельный DIN 603",
    "Крепеж>Болты>Болт Б/П, к.п. 8.8, полная резьба" : "Крепеж>Болты>Болт Б/П, к.п. 8.8, полная резьба",
    "Крепеж>Болты>Болт без покрытия, неполная резьба" : "Крепеж>Болты>Болт без покрытия, неполная резьба",
    "Крепеж>Болты>ГОСТ Р 52644-2004" : "Крепеж>Болты>ГОСТ Р 52644-2004",
    "Крепеж>Болты>Болт без покрытия, полная резьба" : "Крепеж>Болты>Болт без покрытия, полная резьба",
    "Крепеж>Болты>Болт без покрытия, к.п.8.8" : "Крепеж>Болты>Болт без покрытия, к.п.8.8",
    "Крепеж>Болты>Болты оцинк, к.п.8.8, полная резьба" : "Крепеж>Болты>Болты оцинк, к.п.8.8, полная резьба",
    "Крепеж>Болты>Болт оцинк, неполная резьба" : "Крепеж>Болты>Болт оцинк, неполная резьба",
    "Крепеж>Болты>ГОСТ 7805-70 оцинк, к.п. 8.8" : "Крепеж>Болты>ГОСТ 7805-70 оцинк, к.п. 8.8",
    "Крепеж>Болты>Болт с полной резьбой, оцинк" : "Крепеж>Болты>Болт с полной резьбой, оцинк",
    "Крепеж>Болты>Мебельный ГОСТ 7801-81 с усом" : "Крепеж>Болты>Мебельный ГОСТ 7801-81 с усом",
    "Крепеж>Болты>Болты оцинк, к.п.8.8" : "Крепеж>Болты>Болты оцинк, к.п.8.8",
    "Крепеж>Болты>Болт лемешный ГОСТ7786-81" : "Крепеж>Болты>Болт лемешный ГОСТ7786-81",
    "Крепеж>Болты>Болты с умен.головой 8.8 цинк" : "Крепеж>Болты>Болты с умен.головой 8.8 цинк",
    "Крепеж>Болты>Болты оцинк, к.п.10.9" : "Крепеж>Болты>Болты оцинк, к.п.10.9",
    "Крепеж>Болты>Болт без покрытия, к.п.10.9" : "Крепеж>Болты>Болт без покрытия, к.п.10.9",
    "Крепеж>Болты>Болты оцинк, к.п.10.9, полная резьба" : "Крепеж>Болты>Болты оцинк, к.п.10.9, полная резьба",
    "Крепеж>Винты>С полусфер. головкой" : "Крепеж>Винты>С полусфер. головкой",
    "Крепеж>Винты>С  цил. гол. и внутр. шестигр." : "Крепеж>Винты>С  цил. гол. и внутр. шестигр.",
    "Крепеж>Винты>С потай головкой и полной резьбой" : "Крепеж>Винты>С потай головкой и полной резьбой",
    "Крепеж>Винты>С пресс-шайбой" : "Крепеж>Винты>С пресс-шайбой",
    "Крепеж>Гайки>Эриксона" : "Крепеж>Гайки>Эриксона",
    "Крепеж>Гайки>Гайки без покрытия" : "Крепеж>Гайки>Гайки без покрытия",
    "Крепеж>Гайки>Гайки оцинкованные" : "Крепеж>Гайки>Гайки оцинкованные",
    "Крепеж>Гайки>DIN 1587 колпачковая" : "Крепеж>Гайки>DIN 1587 колпачковая",
    "Крепеж>Гайки>DIN 1624 мебельная врезная" : "Крепеж>Гайки>DIN 1624 мебельная врезная" ,
    "Крепеж>Гайки>DIN 315  барашковая" : "Крепеж>Гайки>DIN 315  барашковая",
    "Крепеж>Гайки>DIN 562 квадратная" : "Крепеж>Гайки>DIN 562 квадратная",
    "Крепеж>Гайки>DIN 6334 соединительная" : "Крепеж>Гайки>DIN 6334 соединительная",
    "Крепеж>Гайки>DIN 6923 шестигр. с фланцем" : "Крепеж>Гайки>DIN 6923 шестигр. с фланцем",
    "Крепеж>Гайки>DIN 934 оцинк." : "Крепеж>Гайки>DIN 934 оцинк.",
    "Крепеж>Гайки>DIN 985 со стопорным кольцом" : "Крепеж>Гайки>DIN 985 со стопорным кольцом",
    "Крепеж>Гайки>Гайки высокопрочные, б/п, к.п. 8" : "Крепеж>Гайки>Гайки высокопрочные, б/п, к.п. 8",
    "Крепеж>Гайки>Гайки высокопрочные, оцинк. к.п. 8" : "Крепеж>Гайки>Гайки высокопрочные, оцинк. к.п. 8" ,
    "Крепеж>Гайки>Гайки высокопрочные, б/п, к.п. 10" : "Крепеж>Гайки>Гайки высокопрочные, б/п, к.п. 10",
    "Крепеж>Гайки>Гайки высокопрочные, оцинк, к.п.10" : "Крепеж>Гайки>Гайки высокопрочные, оцинк, к.п.10",
    "Крепеж>Гайки>DIN 982 самоконтрящаяся высокая" : "Крепеж>Гайки>DIN 982 самоконтрящаяся высокая",
    "Крепеж>Гвозди>Гвозди" : "Крепеж>Гвозди>Гвозди",
    "Крепеж>Гвозди>Строительный без покрытия" : "Крепеж>Гвозди>Строительный без покрытия",
    "Крепеж>Гвозди>Винтовой" : "Крепеж>Гвозди>Винтовой",
    "Крепеж>Гвозди>Ершеный" : "Крепеж>Гвозди>Ершеный",
    "Крепеж>Гвозди>Кровельный" : "Крепеж>Гвозди>Кровельный",
    "Крепеж>Гвозди>Строительный оцинкованный" : "Крепеж>Гвозди>Строительный оцинкованный",
    "Крепеж>Гвозди>Толевый" : "Крепеж>Гвозди>Толевый",
    "Крепеж>Гвозди>Финишный" : "Крепеж>Гвозди>Финишный",
    "Крепеж>Гвозди>Монтажный" : "Крепеж>Гвозди>Монтажный",
    "Крепеж>Дюбели>Для газобетона" : "Крепеж>Дюбели>Для газобетона",
    "Крепеж>Дюбели>Дюбель-гвоздь" : "Крепеж>Дюбели>Дюбель-гвоздь",
    "Крепеж>Дюбели>Для пустотелых конструкций" : "Крепеж>Дюбели>Для пустотелых конструкций" ,
    "Крепеж>Дюбели>Общего назначения" : "Крепеж>Дюбели>Общего назначения",
    "Крепеж>Дюбели>Универсальный" : "Крепеж>Дюбели>Универсальный" ,
    "Крепеж>Заклепки>Комбинированная вытяжная" : "Крепеж>Заклепки>Комбинированная вытяжная",
    "Крепеж>Заклепки>Окрашенная" : "Крепеж>Заклепки>Окрашенная",
    "Крепеж>Заклепки>Вытяжная" : "Крепеж>Заклепки>Вытяжная",
    "Крепеж>Саморезы>Саморезы" : "Крепеж>Саморезы>Саморезы" ,
    "Крепеж>Саморезы>Саморез унив. с пот. гол.(Жёлт Pozi)" : "Крепеж>Саморезы>Саморез унив. с пот. гол.(Жёлт Pozi)",
    "Крепеж>Саморезы>Для крепления ГВЛ" : "Крепеж>Саморезы>Для крепления ГВЛ",
    "Крепеж>Саморезы>Для лаг и реек" : "Крепеж>Саморезы>Для лаг и реек",
    "Крепеж>Саморезы>Для крепления ГКЛ (кг)" : "Крепеж>Саморезы>Для крепления ГКЛ (кг)",
    "Крепеж>Саморезы>Для крепления мет.пофилей" : "Крепеж>Саморезы>Для крепления мет.пофилей",
    "Крепеж>Саморезы>Для кровельных материалов" : "Крепеж>Саморезы>Для кровельных материалов" ,
    "Крепеж>Саморезы>Саморез с П/Ш нак. сверло (кг)" : "Крепеж>Саморезы>Саморез с П/Ш нак. сверло (кг)" ,
    "Крепеж>Саморезы>Винт-конфирмат" : "Крепеж>Саморезы>Винт-конфирмат",
    "Крепеж>Саморезы>Для оконных профилей" : "Крепеж>Саморезы>Для оконных профилей",
    "Крепеж>Саморезы>Для сандвич-панелей" : "Крепеж>Саморезы>Для сандвич-панелей",
    "Крепеж>Саморезы>По металлу" : "Крепеж>Саморезы>По металлу",
    "Крепеж>Саморезы>Саморез унив. с пот. гол.(Белый Pozi)" : "Крепеж>Саморезы>Саморез унив. с пот. гол.(Белый Pozi)",
    "Крепеж>Саморезы>Саморез с П/Ш нак. сверло (шт)" : "Крепеж>Саморезы>Саморез с П/Ш нак. сверло (шт)",
    "Крепеж>Саморезы>Саморез с П/Ш нак. острый (кг)" : "Крепеж>Саморезы>Саморез с П/Ш нак. острый (кг)",
    "Крепеж>Саморезы>Саморез с П/Ш нак. острый (шт)" : "Крепеж>Саморезы>Саморез с П/Ш нак. острый (шт)" ,
    "Крепеж>Саморезы>Для кровельных материалов RAL" : "Крепеж>Саморезы>Для кровельных материалов RAL",
    "Крепеж>Саморезы>Для крепления ГКЛ (желтый цинк)" : "Крепеж>Саморезы>Для крепления ГКЛ (желтый цинк)",
    "Крепеж>Саморезы>Для крепления ГКЛ (шт)" : "Крепеж>Саморезы>Для крепления ГКЛ (шт)",
    "Крепеж>Саморезы>П/Ш окраш. по RAL с нак. сверло" : "Крепеж>Саморезы>П/Ш окраш. по RAL с нак. сверло",
    "Крепеж>Саморезы>П/Ш окраш. по RAL с нак. острый" : "Крепеж>Саморезы>П/Ш окраш. по RAL с нак. острый",
    "Крепеж>Саморезы>Саморез конструкционный по дереву" : "Крепеж>Саморезы>Саморез конструкционный по дереву",
    "Крепеж>Шайбы>Шайба гроверная" : "Крепеж>Шайбы>Шайба гроверная",
    "Крепеж>Шайбы>Шайба плоская оцинк" : "Крепеж>Шайбы>Шайба плоская оцинк",
    "Крепеж>Шайбы>Шайба увеличенная" : "Крепеж>Шайбы>Шайба увеличенная",
    "Крепеж>Шайбы>Шайба ГОСТ 52646-2006" : "Крепеж>Шайбы>Шайба ГОСТ 52646-2006",
    "Крепеж>Шайбы>Шайба плоская без покрытия" : "Крепеж>Шайбы>Шайба плоская без покрытия",
    "Крепеж>Шпильки>Резьбовая ОЦ" : "Крепеж>Шпильки>Резьбовая ОЦ",
    "Крепеж>Шпильки>Шуруп-шпилька" : "Крепеж>Шпильки>Шуруп-шпилька",
    "Крепеж>Шпильки>Шпилька  к.п. 8.8 ОЦ" : "Крепеж>Шпильки>Шпилька  к.п. 8.8 ОЦ",
    "Крепеж>Шпильки>Шпилька  к.п. 8.8 БП" : "Крепеж>Шпильки>Шпилька  к.п. 8.8 БП",
    "Крепеж>Шурупы>По бетону" : "Крепеж>Шурупы>По бетону",
    "Крепеж>Шурупы>С кольцом/полукольцом" : "Крепеж>Шурупы>С кольцом/полукольцом",
    "Крепеж>Такелажный крепеж>Зажим для канатов" : "Крепеж>Такелаж(грузовой крепеж)>Зажим для канатов",
    "Крепеж>Такелажный крепеж>Блок" : "Крепеж>Такелаж(грузовой крепеж)>Блок",
    "Крепеж>Такелажный крепеж>Вертлюг" : "Крепеж>Такелаж(грузовой крепеж)>Вертлюг",
    "Крепеж>Такелажный крепеж>Коуш" : "Крепеж>Такелаж(грузовой крепеж)>Коуш",
    "Крепеж>Такелажный крепеж>Карабин" : "Крепеж>Такелаж(грузовой крепеж)>Карабин",
    "Крепеж>Такелажный крепеж>Крюк" : "Крепеж>Такелаж(грузовой крепеж)>Крюк",
    "Крепеж>Такелажный крепеж>Рым-болт" : "Крепеж>Такелаж(грузовой крепеж)>Рым-болт",
    "Крепеж>Такелажный крепеж>Рым-гайка" : "Крепеж>Такелаж(грузовой крепеж)>Рым-гайка",
    "Крепеж>Такелажный крепеж>Скоба" : "Крепеж>Такелаж(грузовой крепеж)>Скоба",
    "Крепеж>Такелажный крепеж>Соединитель цепей" : "Крепеж>Такелаж(грузовой крепеж)>Соединитель цепей",
    "Крепеж>Такелажный крепеж>Талреп" : "Крепеж>Такелаж(грузовой крепеж)>Талреп",
    "Крепеж>Такелажный крепеж>Цепь" : "Крепеж>Такелаж(грузовой крепеж)>Цепь",
    "Крепеж>Такелажный крепеж>Трос" : "Крепеж>Такелаж(грузовой крепеж)>Трос",
    "Крепеж>Перфорированный крепеж>Кронштейн стальной окрашенный" : "Крепеж>Перфорированный крепеж>Кронштейн стальной окрашенный",
    "Крепеж>Перфорированный крепеж>Перфорированная лента" : "Крепеж>Перфорированный крепеж>Перфорированная лента",
    "Крепеж>Перфорированный крепеж>Пластины крепёжные" : "Крепеж>Перфорированный крепеж>Пластины крепёжные",
    "Крепеж>Перфорированный крепеж>Уголки крепёжные" : "Крепеж>Перфорированный крепеж>Уголки крепёжные",
    "Крепеж>Перфорированный крепеж>Крепление панелей" : "Крепеж>Перфорированный крепеж>Крепление панелей",
    "Крепеж>Перфорированный крепеж>Крепление балок" : "Крепеж>Перфорированный крепеж>Крепление балок",
    "Профнастил>Оцинкованный" : "Профнастил>Профнастил Оцинкованный",
    "Профнастил>Окрашенный" : "Профнастил>Окрашенный",
}
