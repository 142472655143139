import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {colors, ralRu} from "../../../data/ralColors";

const Ral = () => {
    const [text, setText] = useState('');
    const [filterColor, setFilter] = useState(Object.values(colors));

    const handleChange = (e) => {
        setText(e.target.value);
    }

    useEffect(()=>{
        if (text.trim()) {
            const filteredColors = Object.values(colors).filter(color => color.code.includes(text.trim()));
            setFilter(filteredColors);
        } else {
            setFilter(Object.values(colors));
        }
    },[text])

    return (
        <Wrapper>
            <Search value={text} onChange={(e)=> handleChange(e)} placeholder={'Поиск по коду RAL'}/>
            <List>
                {filterColor.map((color, index) => (
                    <Item key={color.code}>
                        <Color style={{background: color.color.hex}}/>
                        <Code style={{padding:'8px 0 0 32px'}}>
                           {color.code}
                        </Code>
                        <Code >
                            {ralRu[color.code]}
                        </Code>
                        <Code >
                            {color.names['en']}
                        </Code>
                    </Item>
                ))}
            </List>
        </Wrapper>
    );
};

export default Ral;
const Code = styled.div`
    width: 30%;
    padding: 8px 0;
  
`
const Color = styled.div`
    width: 30%;
`
const Item = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  color: rgba(7, 7, 7, 1);
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }

  &:nth-child(even) {
    background: rgba(250, 250, 250, 1);
  }
 
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;  
  scrollbar-width: thin; /* Для Firefox */
  scrollbar-color: #D2D2D5 transparent; /* Для Firefox: цвет ползунка и трека */

  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0000ff21;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`
const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap:16px;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
`
const Search = styled.input`
  width: 256px;
  padding: 8px 16px;
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid rgba(210, 210, 213, 1);
`