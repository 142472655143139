import { useSetRecoilState} from 'recoil';
import {allFilters, authState, filterModal, selectedCategoryId} from "../state/atoms";

const Logout = () => {
    const setX1 = useSetRecoilState(selectedCategoryId);
    const setX2 = useSetRecoilState(filterModal);
    const setX3 = useSetRecoilState(allFilters);
    const setX4 = useSetRecoilState(authState);

    setX1(null);
    setX2(false);
    setX3({});
    setX4(false);

    localStorage.removeItem('authToken');
};

export default Logout;