import React from 'react';
import styled from 'styled-components';
import {useState, useEffect} from "react";
import {upperMenu} from "../data/catPageData";
import {koefs} from "../data/calcData";
import CalcInfo from "../components/CalcInfo/CalcInfo";
import arrowIcon from '../assets/arrow.svg';
import Navbar from "../components/Navbar/Navbar";
import menu from '../assets/menu.svg'
import logoWhite from '../assets/logo_white.svg';
import xIcon from '../assets/x.svg';

const CalcPage = () => {
    const [selectedCat, setCat] = useState(upperMenu[0]);
    const [selectedJson, setJson] = useState(koefs[Object.keys(koefs)[0]]);
    const [selectedSubcat, setSubCatIndex] = useState(Object.keys(koefs[Object.keys(koefs)[0]])[0]);
    const [selectedItem, setItem] = useState(koefs[Object.keys(koefs)[0]][Object.keys(koefs[Object.keys(koefs)[0]])[0]][0]);
    const [showMenu, setShowMenu] = useState(false);

    const handleSelect = (cat) => {
        setCat(cat);
        setJson(koefs[`${cat}`] );
        setSubCatIndex(Object.keys(koefs[`${cat}`])[0]);
        setItem(koefs[`${cat}`][Object.keys(koefs[`${cat}`])[0]][0]);
    }

    function isSq() {
        const keywords = /сетка|профнастил|лист|штрипс|рулон/;
        return keywords.test(selectedCat.toLowerCase()) || keywords.test(selectedSubcat.toLowerCase())
    }

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const handleMainCategoryHover = () => {
        setIsDropdownVisible(true);
    };
    const handleMainCategoryLeave = () => {
        setIsDropdownVisible(false);
    };

    const DropdownMenu = styled.ul`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9999;
      width: 100%;
      list-style-type: none;
    `;
    const cvetArr = ['Алюминий-дюраль','Бронза','Латунь','Медь','Свинец','Нихром'];
    return (
        <>

            <Navbar/>

            <Wrapper>
                <MobileNav>
                    <img src={menu} alt="menu" onClick={()=> setShowMenu(true)}/>
                    <img src={logoWhite} alt="logo"/>
                </MobileNav>
               
                    <LeftMenu className={showMenu ? 'true' : 'false'}>
                        <img src={xIcon} alt="x" onClick={()=> setShowMenu(false)}/>
                        <MainLeftMenu>
                            {selectedJson && Object.keys(selectedJson).map((item, index)=> (
                                <MenuItem key={index} onClick={()=> {
                                    setSubCatIndex(item);
                                    setItem(selectedJson[item][0]);
                                }} className={item === selectedSubcat && 'selected'}>
                                    {item}
                                </MenuItem>
                            ))}
                        </MainLeftMenu>
                        <SecondLeftMenu>

                            {selectedSubcat && selectedJson[selectedSubcat].map((item,index)=> (
                                <MenuItemLeft key={index} onClick={()=>{
                                    setItem(item);
                                }} className={JSON.stringify(item) === JSON.stringify(selectedItem)  && 'selected'}>
                                    {item.size}
                                </MenuItemLeft>
                            ))}
                        </SecondLeftMenu>
                    </LeftMenu>
                
                <InnerWrapper>
                    <UpMenu>
                        <UpMenuItem  onClick={()=> handleSelect('Черный металлопрокат')} className={selectedCat === 'Черный металлопрокат' && 'selected'}>
                            Черный металлопрокат
                        </UpMenuItem>
                        <UpMenuItem onClick={()=>  handleSelect('Нержавеющий металлопрокат')} className={selectedCat === 'Нержавеющий металлопрокат' && 'selected'}>
                            Нержавеющий металлопрокат
                        </UpMenuItem>
                        <UpMenuItem  onMouseEnter={handleMainCategoryHover}
                                     onMouseLeave={handleMainCategoryLeave}
                                     className={cvetArr.includes(selectedCat) && 'selected'}>
                            Цветной металлопрокат
                            {isDropdownVisible && (
                                <DropdownMenu>
                                    <UpMenuItemSmall onClick={() => {
                                        handleSelect('Алюминий-дюраль');
                                        setIsDropdownVisible(false);
                                    }}>
                                        Алюминий-дюраль
                                    </UpMenuItemSmall>
                                    <UpMenuItemSmall onClick={() => {
                                        handleSelect('Бронза');
                                        setIsDropdownVisible(false);
                                    }}>
                                        Бронза
                                    </UpMenuItemSmall>
                                    <UpMenuItemSmall onClick={() => {
                                        handleSelect('Латунь');
                                        setIsDropdownVisible(false);
                                    }}>
                                        Латунь
                                    </UpMenuItemSmall>
                                    <UpMenuItemSmall onClick={() => {
                                        handleSelect('Медь');
                                        setIsDropdownVisible(false);
                                    }}>
                                        Медь
                                    </UpMenuItemSmall>
                                    <UpMenuItemSmall onClick={() => {
                                        handleSelect('Свинец');
                                        setIsDropdownVisible(false);
                                    }}>
                                        Свинец
                                    </UpMenuItemSmall>
                                    <UpMenuItemSmall onClick={() => {
                                        handleSelect('Нихром');
                                        setIsDropdownVisible(false);
                                    }}>
                                        Нихром
                                    </UpMenuItemSmall>
                                </DropdownMenu>
                            )}
                        </UpMenuItem>
                        <UpMenuItem onClick={()=> handleSelect('Сетка')} className={selectedCat === 'Сетка' && 'selected'}>
                            Сетка
                        </UpMenuItem>
                        <UpMenuItem onClick={()=> handleSelect('Профнастил')} className={selectedCat === 'Профнастил' && 'selected'}>
                            Профнастил
                        </UpMenuItem>
                    </UpMenu>
                    <Breadcrumbs>
                        {selectedCat}
                        <img src={arrowIcon} alt="arrow"/>
                        {selectedSubcat}
                        <img src={arrowIcon} alt="arrow"/>
                        {selectedItem.size}
                    </Breadcrumbs>
                    <Calculator item={selectedItem} isSq={isSq()}/>
                    <CalcInfo/>
                </InnerWrapper>
            </Wrapper>
        </>
    );
};

const Calculator = ({item, isSq}) => {
    const [tValue, setTValue] = useState(0);
    const [ed, setEd] = useState('Тонны')

    const [tonn,setT] = useState('');
    const [metr,setM] = useState('');
    const [dlina,setD] = useState('');
    const [shirina,setS] = useState('');
    const [kolvo,setK] = useState('');

    const [tooltipText, setTooltipText] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    const handleMouseOut = () => {
        setTooltipText('');
    };
    const handleMouseOver = (e) => {
        const weightInTonsValue = parseFloat(tonn);
        if (!isNaN(weightInTonsValue)) {
            if (weightInTonsValue > 1) {
                setTooltipText('');
                return;
            }
        }

        const weightInKg = parseFloat(tonn) * 1000;
        if (!isNaN(weightInKg)) {
            if (weightInKg >= 1) {
                setTooltipText(`Килограммы: ${weightInKg.toFixed(2)}`);
            } else {
                const weightInGrams = weightInKg * 1000;
                setTooltipText(`Граммы: ${weightInGrams.toFixed(2)}`);
            }
            setTooltipPosition({ x: e.clientX, y: e.clientY });
        }
    };
    const valid = (x) => {
        return !isNaN(x) && x !== 0;
    }

    const tonnChange = (e) => {
        setT(e.target.value);
        const tonnFloat = parseFloat(e.target.value.replace(',', '.'));
        const dlinaFloat = parseFloat(dlina.replace(',', '.'));
        const shirinaFloat = parseFloat(shirina.replace(',', '.'));

        if (!isSq) {
            if (valid(tonnFloat) && valid(dlinaFloat)) {
                setM((1 / tValue * tonnFloat).toFixed(3));
                setK((1 / tValue * tonnFloat / dlinaFloat).toFixed(2));
            } else if (valid(tonnFloat)) {
                setM((1 / tValue * tonnFloat).toFixed(3))
            } else {
                setM('');
                setK('');
            }
        } else {
            if (valid(tonnFloat) && valid(dlinaFloat) && valid(shirinaFloat)) {
                setM((1 / tValue * tonnFloat).toFixed(3));
                setK((1 / tValue * tonnFloat / (dlinaFloat*shirinaFloat)).toFixed(2));
            } else if (valid(tonnFloat)) {
                setM((1 / tValue * tonnFloat).toFixed(3))
            } else {
                setM('');
                setK('');
            }
        }
    }
    const metrChange = (e) => {
        setM(e.target.value);

        const metrFloat = parseFloat(e.target.value.replace(',', '.'));
        const dlinaFloat = parseFloat(dlina.replace(',', '.'));
        const shirinaFloat = parseFloat(shirina.replace(',', '.'));

        if (valid(dlinaFloat) && valid(metrFloat) && valid(shirinaFloat)) {
            setK((metrFloat/(dlinaFloat*shirinaFloat)).toFixed(2));
        }
        else if (valid(dlinaFloat) && valid(metrFloat)) {
            setK((metrFloat/dlinaFloat).toFixed(2));
        }else {
            setK('');
            setT('');
        }

        if (valid(metrFloat)) {
            setT((metrFloat * tValue).toFixed(6));
        }
    }
    const dlinaChange = (e) => {
        setD(e.target.value);

        const metrFloat = parseFloat(metr.replace(',', '.'));
        const dlinaFloat = parseFloat(e.target.value.replace(',', '.'));
        const shirinaFloat = parseFloat(shirina.replace(',', '.'));

        if (isSq) {
            if (valid(metrFloat) && valid(dlinaFloat) && valid(shirinaFloat)) {
                setK((metrFloat/(dlinaFloat*shirinaFloat)).toFixed(2));
            } else {
                setK('');
            }
        } else {
            if (valid(metrFloat) && valid(dlinaFloat)) {
                setK((metrFloat/dlinaFloat).toFixed(2));
            } else {
                setK('');
            }
        }

    }
    const kolvoChange = (e) => {
        setK(e.target.value);
        const dlinaFloat = parseFloat(dlina.replace(',', '.'));
        const kolFloat = parseFloat(e.target.value.replace(',', '.'));
        const shirinaFloat = parseFloat(shirina.replace(',', '.'));

        if (isSq) {
            if (valid(kolFloat) && valid(dlinaFloat) && valid(shirinaFloat)) {
                setM(shirinaFloat * dlinaFloat * kolFloat);
                setT(shirinaFloat * dlinaFloat * kolFloat * tValue)
            }
        } else {
            if (valid(kolFloat) && valid(dlinaFloat)) {
                setM(dlinaFloat * kolFloat);
                setT(dlinaFloat * kolFloat * tValue)
            }
        }


    }
    const shirinaChange = (e) => {
        setS(e.target.value);
        const metrFloat = parseFloat(metr.replace(',', '.'));
        const dlinaFloat = parseFloat(dlina.replace(',', '.'));
        const shirinaFloat = parseFloat(e.target.value.replace(',', '.'));


        if (valid(metrFloat) && valid(dlinaFloat) && valid(shirinaFloat)) {
            setK((metrFloat/(dlinaFloat*shirinaFloat)).toFixed(2));
        } else {
            setK('');
        }

    }

    useEffect(()=>{
        setT('');
        setM('');
        setD('');
        setS('');
        setK('');
        const Koefs = typeof item.koef === 'string' ?  (item.koef).replaceAll(',','.') : item.koef.toString();
        setTValue(parseFloat(Koefs.replace(',', '.'))/parseFloat(item.metrs));

    },[item])
    return (
        <CalcWrapper>
            <InputWrapper>
                {tooltipText && (
                    <Tooltip style={{ top: tooltipPosition.y, left: tooltipPosition.x }}>
                        {tooltipText}
                    </Tooltip>
                )}
                <StyledInput placeholder={'0'} value={tonn} onChange={(e)=>tonnChange(e)}
                             onMouseOver={handleMouseOver}
                             onMouseOut={handleMouseOut}/>
                <p>{ed}</p>
            </InputWrapper>
            <InputWrapper>
                <StyledInput placeholder={'0'} value={metr} onChange={(e)=>metrChange(e)}/>
                <p>{isSq ? 'Кв метры' : 'Метры'}</p>

            </InputWrapper>
            <InputWrapper>
                <StyledInput placeholder={'0'} value={dlina} onChange={(e)=>dlinaChange(e)}/>
                <p>Длина</p>
            </InputWrapper>
            {isSq &&
                <InputWrapper>
                    <StyledInput placeholder={'0'} value={shirina} onChange={(e)=>shirinaChange(e)}/>
                    <p>Ширина</p>
                </InputWrapper>
            }
            <InputWrapper>
                <StyledInput placeholder={'0'} value={kolvo} onChange={(e)=>kolvoChange(e)}/>
                <p>Количество</p>
            </InputWrapper>
        </CalcWrapper>
    )
}
export default CalcPage;
const MobileNav = styled.div`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 998;
  background: black;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  align-items: center;
  justify-content: center;
  img:first-child {
    position: absolute;
    left: 20px;
  }
  @media screen and (max-width: 960px){
    display: flex;
  }
`

const Breadcrumbs = styled.div`
  color: #545456;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: start;
  margin-top: 28px;
  margin-bottom: 8px;
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }
  @media screen and (max-width: 960px){
    margin-top: 16px;
  }
`
const Tooltip = styled.div`
  position: fixed;
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 4px;
  z-index: 999;
`;
const CalcWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  background: #070707;
  display: flex;
  padding: 0 0 12px 0;
  -webkit-box-shadow: 0px 7px 18px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 7px 18px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 7px 18px 0px rgba(34, 60, 80, 0.2);
  margin-bottom: 36px;
  @media screen and (max-width: 960px){
    margin-bottom: 12px;
  }
 
`
const StyledInput = styled.input`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  padding: 10px 16px;
  outline: none;
  border: none;
  rgba(11, 11, 11, 1)
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }
`
const InputWrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-right: 2px solid #070707;
  border-top: 2px solid #070707;
  &:nth-last-child(1) {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    input {
      border-top-right-radius: 14px;
    }
  }
  &:first-child {
    border-left: 2px solid #070707;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    input {
      border-top-left-radius: 14px;
    }
  }
  p {
    color: white;
  }
  @media screen and (max-width: 960px){
    font-size: 13px;
  }
`
const Wrapper= styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background: #D9D9D9;

`
const UpMenu = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const UpMenuItem = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  height: 100%;
  padding: 16px 0;
  width: 20%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  transition: all 0.4s ease;

  background: white;
  border-left: 1px solid #F0F0F2;
  word-break: break-word;
  height: auto;
  &.selected {
    color: #fff;
    background: #070707;
  }
  &:hover {
    color: #fff;
    background: #070707;
  }
  &:nth-last-child(1) {
    border-bottom-right-radius: 24px;
  }
  &:first-child {
    border: none;
    border-bottom-left-radius: 24px;
  }
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }
`
const LeftMenu = styled.div`
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  color: #363636;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  width: fit-content;
  background: white;
  height: 100vh;
  font-size: 14px;
  max-width: 360px;
  max-height: 100vh;
  -webkit-box-shadow: 10px 0px 20px 0 rgba(226, 236, 249, 0.5);
  -moz-box-shadow: 10px 0px 20px 0 rgba(226, 236, 249, 0.5);
  box-shadow: 10px 0px 20px 0 rgba(226, 236, 249, 0.5);
  img {
    display: none;
  }
  @media screen and (max-width: 960px){
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    &.false {
      display: none;
    }
    &.true {
      display: flex;
    }
    img {
      display: block;
      width: 24px;
      position: absolute;
      top: 12px;
      right: 24px;
      z-index: 999;
    }

  }


`
const MainLeftMenu = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 240px;
  height: 100%;
  overflow-y: scroll;

  padding: 32px 8px 8px 8px;
  border-right: 1px solid #F0F0F2;
  scrollbar-width: thin; /* Для Firefox */
  scrollbar-color: #F0F0F2 transparent; /* Для Firefox: цвет ползунка и трека */

  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;

  &::-webkit-scrollbar {
    width: 2px; /* Ширина скроллбара */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Фон трека скроллбара */
  }

  &::-webkit-scrollbar-thumb {
    background: #F0F0F2; /* Цвет ползунка скроллбара */
    border-radius: 10px; /* Радиус скругления */
  }

`
const SecondLeftMenu = styled.div`
  position: relative;
  padding: 32px 8px 8px 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 112px;
  height: 100%;
  overflow-y: scroll;
  font-size: 14px;
  scrollbar-width: thin; /* Для Firefox */
  scrollbar-color: #F0F0F2 transparent; /* Для Firefox: цвет ползунка и трека */

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;

  &::-webkit-scrollbar {
    width: 2px; /* Ширина скроллбара */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Фон трека скроллбара */
  }

  &::-webkit-scrollbar-thumb {
    background: #F0F0F2; /* Цвет ползунка скроллбара */
    border-radius: 10px; /* Радиус скругления */
  }
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }

`
const MenuItem = styled.div`
  cursor: pointer;
  border-radius: 0px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #F0F0F2;

  &:hover {
    background-color: #F0F0F2;
    border-radius: 8px;
  }
  &.selected {
    border-radius: 8px;
    font-weight: 500;
    background-color: #F0F0F2;
  }
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }
`
const MenuItemLeft = styled.div`
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    background-color: #F0F0F2;
  }
  &.selected {
    font-weight: 500;
    background-color: #F0F0F2;
  }
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }
`
const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px; 
  width: 100%;
  overflow: scroll;
  @media screen and (max-width: 960px){
    padding: 50px 12px 0;
    margin-bottom: 90px;
  }
`

const UpMenuItemSmall = styled.div`
  cursor: pointer;
  position: relative;
  height: 100%;
  padding: 16px 0;
  width: 100%;
  text-align: center;
  font-weight: 500;
  transition: all 0.4s ease;
  font-size: 14px;
  background: white;
  color: rgba(7, 7, 7, 1);
  &.selected {
    color: #fff;
    background: #070707;
  }
  &:hover {
    color: #fff;
    background: #070707;
  }
  &:nth-last-child(1) {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }
`

