import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import FilterIcon from '../../assets/filter.svg';
import {IconButton} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {capitalizeFirstLetter, projectShorter, strNorm} from "../../utils/utils";
import Preloader from "../Preloader/Preloader";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {allFilters, filterModal} from "../../state/atoms";
import FilterModal from "../Filters/FilterModal";
import Logout from "../../utils/Logout";

export default function TableComponents ({ data }){
    const [openRowId, setOpenRowId] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [price, setPrice] = useState(null);
    const [filters, setFilters] = useRecoilState(allFilters);
    const [filteredData, setData] = useState([]);
    const open = useRecoilValue(filterModal);

    const toggleRow = async (id,a,b) => {
        setOpenRowId(openRowId === id ? null : id);
        try {
            setPrice(null);
            const res = await fetch(`/api/table/price/${a}/${b}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
                    }
                });

            if (res.status === 403) {
                Logout();
            }
            const data = await res.json();
            setPrice(data);
        } catch (e) {
        }
    };
    const copyToClipboard = (text, position) => {
        if (!text) return;
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            console.log('Text copied to clipboard:', text);
        } catch (error) {
            console.error('Error copying text to clipboard:', error);
            return;
        } finally {
            document.body.removeChild(textarea);
        }
        setTooltipPosition(position);
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 800);
    };

    const handleCellClick = (e) => {
        const text = e.target.innerText;
        const position = { x: e.clientX, y: e.clientY };
        copyToClipboard(text, position);
    };

    const filterData = (dataArray) => {
        const filteredData = dataArray.filter(item => {
            if (filters.selectedMarks && filters.selectedMarks.length > 0) {
                if (!filters.selectedMarks.includes(item['Марка'])) {
                    return false;
                }
            }
            // Проверка соответствия выбранным базам
            if (filters.selectedBase && filters.selectedBase.length > 0) {
                if (!filters.selectedBase.includes(item['База'])) {
                    return false;
                }
            }
            if (filters.r1 && item.R1 !== filters.r1) {
                return false;
            }
            if (filters.r2 && item.R2 !== filters.r2) {
                return false;
            }
            if (filters.r3 && item.R3 !== filters.r3) {
                return false;
            }

            return true;
        });

        if (filters.sort) {
            filteredData.sort((a, b) => {
                const aR1 = parseFloat(a.R1);
                const bR1 = parseFloat(b.R1);

                if (filters.sort === 'down') {
                    return aR1 - bR1;
                } else if (filters.sort === 'up') {
                    return bR1 - aR1;
                }
                return 0;
            });
        }

        return filteredData;
    }

    useEffect(()=>{
        setData(filterData(data.table));
        if (JSON.stringify(filters) === '{}') setData(data.table);
        // eslint-disable-next-line
    },[filters]);

    useEffect(()=>{
        setFilters({});
        // eslint-disable-next-line
    },[]);

    return (
        <Wrapper>

            {showTooltip && (
                <div style={{
                    position: 'fixed',
                    left: `${tooltipPosition.x}px`,
                    top: `${tooltipPosition.y}px`,
                    transform: 'translate(-50%, -100%)',
                    zIndex:'3',
                    padding: '5px',
                    backgroundColor: '#212121',
                    color: 'white',
                    borderRadius: '5px',
                    fontSize: '12px',
                    whiteSpace: 'nowrap',
                }}>
                    Скопировано
                </div>
            )} 
            {open ? <FilterModal data={data.table} tableLength={filteredData.length}/> : 
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <FilterButton />
                <TableLength>
                    Всего записей: <span>{filteredData.length}</span>
                </TableLength>
            </div> }


            <MyTableContainer>
                {filteredData &&
                    <>
                        <Table>
                            <BigThead>
                            <tr>
                                <BigTh style={{width: '3%'}}/>
                                <BigTh style={{width: ''}}>Наименование</BigTh>
                                <BigTh style={{width: '6%'}}>Марка</BigTh>
                                <BigTh style={{width: '3%'}}>R1</BigTh>
                                {data.headers?.includes('R2') && <BigTh style={{width: '3%'}}>R2</BigTh>}
                                {data.headers?.includes('R3') && <BigTh style={{width: '3%'}}>R3</BigTh>}
                                <BigTh style={{width: '3%'}}>L</BigTh>
                                <BigTh style={{width: '5%'}}>Остаток</BigTh>
                                <BigTh style={{width: '3%'}}>ЕИ</BigTh>
                                <BigTh style={{width: '5%'}}>Резерв</BigTh>
                                <BigTh style={{width: '5%'}}>Ост+Рез</BigTh>
                                <BigTh style={{width: '5%'}}>Цена</BigTh>
                                <BigTh style={{width: '8%'}}>ГОСТ</BigTh>
                                <BigTh style={{width: '5%'}}>Страна</BigTh>
                                <BigTh style={{width: '7%'}}>База</BigTh>
                            </tr>
                            </BigThead>
                            <tbody>
                            {filteredData?.map((row, index) => (
                                <React.Fragment key={index}>
                                    <TableRow className={openRowId === index ? 'opened' : ''}>
                                        <TableCell>
                                            <IconButton

                                                aria-label="expand row"
                                                size="small"
                                                onClick={ async () => await toggleRow(index,row.key, row.rowId)}
                                            >
                                                {openRowId === index ? <KeyboardArrowUpIcon  style={{fontSize: '16px',fill: '#D2D2D5'}}/> :
                                                    <KeyboardArrowDownIcon  style={{fontSize: '16px', fill: '#D2D2D5'}}/>}
                                            </IconButton>
                                        </TableCell>
                                        <TableCell
                                            onClick={handleCellClick}>{capitalizeFirstLetter(row['Наименование'])}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center'}}>{row['Марка']}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center'}}>{row['R1']}</TableCell>
                                        {'R2' in row && <TableCell onClick={handleCellClick}
                                                                   style={{textAlign: 'center'}}>{row['R2']}</TableCell>}
                                        {'R3' in row && <TableCell onClick={handleCellClick}
                                                                   style={{textAlign: 'center'}}>{row['R3']}</TableCell>}
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center'}}>{row['L']}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center', background: 'rgba(210, 210, 213, 1)'}}>{row['Остаток']}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center'}}>{row['ЕИ']}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center'}}>{row['Резерв']}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center'}}>{row['Ост+Рез']}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center', background: 'rgba(210, 210, 213, 1)'}}>{row['Цена']}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center'}}>{row['ГОСТ']}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center'}}>{capitalizeFirstLetter(row['Страна'])}</TableCell>
                                        <TableCell onClick={handleCellClick}
                                                   style={{textAlign: 'center'}}>{strNorm(projectShorter(capitalizeFirstLetter(row['База'])))}</TableCell>
                                    </TableRow>
                                    {openRowId === index && (
                                        <TableRow> 
                                            <TableCell colSpan="100%" style={{padding:'12px 16px 12px 3%', background: 'white'}}>
                                                {price ?
                                                    <MiniTableWrapper>
                                                        <div style={{ width: '60%'}}>
                                                            <MiniTable >
                                                                <MiniHeader>
                                                                    <tr>
                                                                        <th style={{width:'30%'}}>Условие</th>
                                                                        <th style={{width:'12%'}}>От</th>
                                                                        <th style={{width:'12%'}}>До</th>
                                                                        <th style={{width:'12%'}}>ЕИ</th>
                                                                        <th style={{width:'17%'}}>Цена</th>
                                                                        <th style={{width:'17%', borderRadius: '0'}}>Макс</th>
                                                                    </tr>
                                                                </MiniHeader>
                                                                <tbody>
                                                                {price && price.price.map((row, index) => (
                                                                    <MiniRow key={index}>
                                                                        <MiniCell onClick={handleCellClick}>{row.name}</MiniCell>
                                                                        <MiniCell onClick={handleCellClick}>{row.from}</MiniCell>
                                                                        <MiniCell onClick={handleCellClick}>{row.to}</MiniCell>
                                                                        <MiniCell onClick={handleCellClick}>{row.ed}</MiniCell>
                                                                        <MiniCell onClick={handleCellClick}>{row.price}</MiniCell>
                                                                        <MiniCell onClick={handleCellClick} style={{ borderRadius: '0'}}>{row.max}</MiniCell>
                                                                    </MiniRow>
                                                                ))}
                                                                </tbody>
                                                            </MiniTable>
                                                        </div>
                                                        <div style={{ width: '40%'}}>
                                                            <MiniTable>
                                                                <MiniHeader>
                                                                    <MiniRow>
                                                                        <th style={{ borderRadius: '0'}}>База</th>
                                                                        <th>Склад</th>
                                                                    </MiniRow>
                                                                </MiniHeader>
                                                                <tbody>
                                                                {price && price.info.map((row, index) => (
                                                                    <MiniRow key={index}>
                                                                        <MiniCell onClick={handleCellClick} style={{ borderRadius: '0'}}>{strNorm(projectShorter(capitalizeFirstLetter(row.pred)))}</MiniCell>
                                                                        <MiniCell onClick={handleCellClick}>{row.sklad || '\u00A0'}</MiniCell>
                                                                    </MiniRow>
                                                                ))}
                                                                </tbody>
                                                            </MiniTable>
                                                        </div>
                                                    </MiniTableWrapper>
                                                    :
                                                    <div style={{height: '150px'}}>
                                                        <Preloader/>
                                                    </div>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))}
                            </tbody>
                        </Table>
                    </>
                }
            </MyTableContainer>

        </Wrapper>
    );
};

const TableLength = styled.p`
    font-weight: 400;
    color: rgba(84, 84, 86, 1);
    text-wrap: nowrap;
    white-space: nowrap;
    padding-right: 32px;
    align-self: end;
`;

const FilterButton = () => {
    const setOpen = useSetRecoilState(filterModal);
    return (
        <ButtonWrapper onClick={()=> setOpen(true)}>
            <img src={FilterIcon} alt="filterIcon" style={{width: '24px'}}/>
            <p style={{fontSize: '14px', color: '#545456'}}>Фильтр</p>
        </ButtonWrapper>
    );
}

export const SelectedFilter = () => {
    const [filters, setFilter] = useRecoilState(allFilters);

    const removeFromFilter = (key, valueToRemove) => {
        if (key === 'selectedMarks' || key === 'selectedBase') {
            const updatedArray = filters[key].filter(value => value !== valueToRemove);
            if (updatedArray.length > 0) {
                setFilter(prevFilters => ({
                    ...prevFilters,
                    [key]: updatedArray,
                }));
            } else {
                const {[key]: removed, ...restFilters} = filters;
                setFilter(restFilters);
            }
        } else {
            const {[key]: removed, ...restFilters} = filters;
            setFilter(restFilters);
        }
    };

    return (
        <MiniWrapper>
            {filters.selectedMarks && (
                <>
                    Марка:
                    {filters.selectedMarks.map((item,index) => (
                        <FilterSmallItem key={index}>
                            {item}
                            <svg
                                onClick={()=>removeFromFilter('selectedMarks', item)}
                                style={{height: '18px'}}
                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconSmall MuiChip-deleteIconColorDefault MuiChip-deleteIconFilledColorDefault css-i4bv87-MuiSvgIcon-root"
                                focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CancelIcon">
                                <path
                                    d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                            </svg>
                        </FilterSmallItem>
                    ))}
                </>
            )} 
            {filters.selectedBase && (
                <>
                    База:
                    {filters.selectedBase.map((item, index) => (
                        <FilterSmallItem key={index}>
                            {item}
                            <svg
                                onClick={()=>removeFromFilter('selectedBase', item)}
                                style={{height: '18px'}}
                                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconSmall MuiChip-deleteIconColorDefault MuiChip-deleteIconFilledColorDefault css-i4bv87-MuiSvgIcon-root"
                                focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CancelIcon">
                                <path
                                    d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                            </svg>
                        </FilterSmallItem>
                    ))}
                </>
            )}

            {filters.r1 && (
                <>
                R1:
                <FilterSmallItem>
                    {filters.r1}
                    <svg
                        onClick={()=>removeFromFilter('r1', filters.r1)}
                        style={{height: '18px'}}
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconSmall MuiChip-deleteIconColorDefault MuiChip-deleteIconFilledColorDefault css-i4bv87-MuiSvgIcon-root"
                        focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CancelIcon">
                        <path
                            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                    </svg>
                </FilterSmallItem>
                </>
            )}

            {filters.r2 && (
                <>
                    R2:
                    <FilterSmallItem>
                        {filters.r2}
                        <svg
                            onClick={()=>removeFromFilter('r2', filters.r2)}
                            style={{height: '18px'}}
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconSmall MuiChip-deleteIconColorDefault MuiChip-deleteIconFilledColorDefault css-i4bv87-MuiSvgIcon-root"
                            focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CancelIcon">
                            <path
                                d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                        </svg>
                    </FilterSmallItem>
                </>
            )}
            {filters.r3 && (
                <>
                    R3:
                    <FilterSmallItem>
                        {filters.r3}
                        <svg
                            onClick={()=>removeFromFilter('r3', filters.r3)}
                            style={{height: '18px'}}
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconSmall MuiChip-deleteIconColorDefault MuiChip-deleteIconFilledColorDefault css-i4bv87-MuiSvgIcon-root"
                            focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CancelIcon">
                            <path
                                d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                        </svg>
                    </FilterSmallItem>
                </>
            )}
            {filters.sort && (
                <>
                    Сортировка R1:
                    <FilterSmallItem>
                        {filters.sort === 'up' ? 'От большего' : 'От меньшего'}
                        <svg
                            onClick={()=>removeFromFilter('sort', filters.sort)}
                            style={{height: '18px'}}
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiChip-deleteIcon MuiChip-deleteIconSmall MuiChip-deleteIconColorDefault MuiChip-deleteIconFilledColorDefault css-i4bv87-MuiSvgIcon-root"
                            focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CancelIcon">
                            <path
                                d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                        </svg>
                    </FilterSmallItem>
                </>
            )}

        </MiniWrapper>
    );
}


 
const FilterSmallItem = styled.div`
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  padding: 5px 3px 5px 8px;
  border-radius: 8px;
  gap: 4px;
  font-weight: 400;
  svg {
    cursor: pointer;
  }
`


const MiniWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  font-weight: 500;
  flex-wrap: wrap;
`
const MiniTable = styled.table `
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    tr:first-of-type th:first-of-type {
        border-top-left-radius: 16px;
      }
      tr:first-of-type th:last-of-type {
        border-top-right-radius: 16px;
      }
      tr:last-of-type td:first-of-type {
        border-bottom-left-radius: 16px;
      }
      tr:last-of-type td:last-of-type {
        border-bottom-right-radius: 16px;
      }
      /* Решение проблемы с артефактами */
      tr:first-of-type th {
        border-top-width: 1px;
      }
      tr:last-of-type td {
        border-bottom-width: 1px;
      }
      tr th:first-of-type,
      tr td:first-of-type {
        border-left-width: 1px;
      }
      tr th:last-of-type,
      tr td:last-of-type {
        border-right-width: 1px;
      }

`
const MiniRow = styled.tr`

`
const Table = styled.table `
  width: 100%;
  border-collapse: collapse;

`
const MiniCell = styled.td`
  padding: 12px 4px;
  border: 1px solid rgba(240, 240, 242, 1);
  min-height: 42px;
`
const MiniHeader = styled.thead`
  background: #D2D2D5;
  text-align: center;

  th {
    font-weight: 400;
    border-right: 1px solid #fff;
    padding: 12px 4px;
    color: #070707;
  }

`
const BigThead = styled.thead`
    background: #070707;
    position: sticky;
    top: 0;
    z-index: 2;
`
const BigTh = styled.th`
  color: white;
  font-weight: 400;
  padding: 16px 4px;
  text-align: left;
  &:not(:first-child) {
    text-align: center;
    border-right: 1px solid #fff;
  }
  &:first-child {
    border-right: 1px solid #070707;
  }
`

const TableRow = styled.tr`
  cursor: pointer;
  font-weight: 400;
    background: rgba(250, 250, 250, 1);
    &:nth-child(odd) {
        background: white;
    }
`;

const TableCell = styled.td`
  padding: 12px 4px;
    border: 1px solid rgba(240, 240, 242, 1);
  &:hover {
    background-color: #f2f2f2;
  }

  &:first-child:hover {
    background: transparent;
  }

  &:first-child {
    text-align: center;
  }

  &:nth-child(2) {
    padding: 0 10px;
  }

`;



const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 12px;
  background-color: #fff;
  user-select: none;
  cursor: pointer;
  font-weight: 400;

  svg {
    fill: #0000ff9c;
  }

  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(63, 63, 63, 0.07);

  }
`
const MiniTableWrapper = styled.div`
  display: flex;
  width: 79.4%;
 @media screen and (max-width: 1370px){
    width: 100%;
  } 
`
const Wrapper = styled.div`
    width: 100%;
    padding-top: 24px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    color: rgba(7, 7, 7, 1);
   @media screen and (max-width: 1370px){
        font-size: 13px; 
      } 
`
const MyTableContainer = styled.div`
  margin-top: 20px;
  background: white;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  overflow: scroll;
 @media screen and (max-width: 1370px){
    margin-top: 16px;
  } 
 @media screen and (max-width: 1370px){
    margin-top: 12px;
  } 
  `