import React, {useState} from 'react';
import styled from 'styled-components';
import {nastilData} from "../../../data/nastilData";

const Nastil = () => {

    const [filterTable] = useState(nastilData);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const closeModal = () => {
        setSelectedItem(null);
    };


    return (
        <Wrapper>
            <List>
                {filterTable.map((item, index) => (
                    <Item key={index} onClick={() => handleItemClick(item)}>
                        <p>{item.name}</p>
                        <Description>{item.disc}</Description>
                    </Item>
                ))}
            </List>
            {selectedItem && (
                <ModalBackground onClick={closeModal}>
                    <ModalContent>
                        <img src={`photo_nastil/${selectedItem.name}.jpg`} alt={selectedItem.name} />
                        <ModalText>
                            <span>Описание</span> <br/> <br/>
                            {selectedItem.disc}
                        </ModalText>
                    </ModalContent>
                </ModalBackground>
            )}
        </Wrapper>
    );
};

export default Nastil;
const Description = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ModalText = styled.p`
  padding: 12px;
  font-size: 14px;
  span {
    font-weight: 500;
    font-size: 16px;
  }
`
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Здесь задаем цвет и прозрачность фона */
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ModalContent = styled.div`
  max-width: 70vw;
  display: flex;
  background-color: white;
  padding: 20px;
  border-radius: 16px;
`;


const Item = styled.div`
  
  color: rgba(7, 7, 7, 1);
  p {
    min-width: 80px; 
  }
  font-weight: 500;
  gap: 12px;
  width: 100%;
  display: flex;
  padding: 8px;
  &:nth-child(odd) {
    background-color:rgba(250, 250, 250, 1);
  }
  &:hover {
    background-color: #D2D2D5;
    cursor: pointer;
  }
  span {
    font-weight: 400;
    color: rgba(84, 84, 86, 1);
  }
`
const List = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

`
const Wrapper = styled.div`
  font-family: 'Roboto', sans-serif;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap:16px;
  width: 100%;
  font-size: 14px;
 @media screen and (max-width: 1370px){
    font-size: 13px;
  }
`
